// @ts-nocheck
/*eslint-disable */
import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  createContext
} from 'react'
import propTypes from 'prop-types'
import moment from 'moment'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import standardStyle from 'assets/css/standardStyle'
import { Modal } from 'ui'
import useGetReservationsForAllSalesRep from 'hooks/useGetReservationsForAllSalesRep'
import { ReactComponent as SorterIcon } from 'assets/icons/sorter.svg'
import { useGetAPI } from 'hooks/useGenericAPIs'
import useDeleteReservationsById from 'hooks/useDeleteReservationsById'
import { toast } from 'react-hot-toast'
import { isMobile } from 'react-device-detect'
import CustomButton from 'components/CustomButton'
import ReservationCard, { ColoredStatus } from 'pages/reservation/ReservationCard'
import CustomTable from 'components/CustomTable'
import { formatMoney } from 'utils'

// @ts-ignore
const LoadingContext = createContext()

const DeleteButton = ({ onClick }) => {
  return null
  // return (
  //   <div
  //     className='font-medium py-1 text-base text-black uppercase text-center border border-black w-32 cursor-pointer bg-red-600'
  //     onClick={onClick}
  //   >
  //     Delete
  //   </div>
  // )
}

const TextField = ({ value }) => (
  <span className='font-medium text-base text-softBlack capitalize truncate'>
    {value}
  </span>
)

const MemoTable = ({
  dataSource,
  columns,
  loading,
  idBeingDeleted,
  deleting
}) => {
  return (
    <CustomTable
      className='rounded-lg'
      dataSource={dataSource}
      columns={columns}
      tailwindTable
      loading={loading}
      idBeingDeleted={idBeingDeleted}
      deleting={deleting}
      isFromContracts
      noMargin
    />
  )
}

const CardComponent = ({ title, count }) => (
  <div
    className='bg-white col-span-1 border rounded-xl shadow'
    style={{ fontFamily: standardStyle.fonts.sfpro }}
  >
    <div className='h-full flex flex-col justify-between space-y-2 p-2 md:p-4'>
      <div className='text-xs text-softBlack_70 font-medium uppercase'>
        {title}
      </div>
      <div className='text-sm font-bold text-softBlack'>{count}</div>
    </div>
  </div>
)

const ProjectReservations = ({ appProject, userObject }) => {
  const [tableDataSource, setTableDataSource] = useState([])
  const [filteredTableDataSource, setFilteredTableDataSource] = useState([])
  const [idBeingDeleted] = useState('')
  const [deletingReservation] = useState(false)
  const {
    reservations,
    loading,
    refetch: refetchReservations
  } = useGetReservationsForAllSalesRep()
  const [isDeleteDialogOpened, setIsDeleteDialogOpened] = useState(-1)

  const history = useHistory()

  const [sortType, setSortType] = useState('asc')
  const [sortByField, setSortByField] = useState('createdAt')
  const { deleteAPI: deleteReservationById, loading: isDeleteInProgress } =
    useDeleteReservationsById()
  const { data: fetchedDataByReservationType, fetch: fetchByReservationType } =
    useGetAPI()
  const { data: fetchedDataByPaymentStatus, fetch: fetchByPaymentStatus } =
    useGetAPI()
  const { data: fetchedDataBySignatureStatus, fetch: fetchBySignatureStatus } =
    useGetAPI()
  const { data: fetchManualReservationReceivedData, fetch: fetchManualReservationReceived } =
    useGetAPI()
  const { data: fetchedManualReservationExpectedData, fetch: fetchManualReservationExpected } =
    useGetAPI()

  useEffect(() => {
    refetchReservations()
    if (appProject) {
      fetchByReservationType(`/analytics/reservation/metrics/groupBy/reservationStatus/${appProject}`)
      fetchByPaymentStatus(`/analytics/reservation/metrics/groupBy/paymentStatus/${appProject}`)
      fetchBySignatureStatus(`/analytics/reservation/metrics/groupBy/primaryBuyerSignatureStatus/${appProject}`)
      fetchManualReservationReceived(`/reservation/stats/manual-reservation-received/${appProject}`)
      fetchManualReservationExpected(`/reservation/stats/manual-reservation-expected/${appProject}`)
    }
  }, [appProject])

  useEffect(() => {
    if (reservations) {
      const updatedReservations = reservations.filter((x) => !x.deleted).map((reservation) => {
        const temBuyers = reservation.reservationHolders.map((user) => (user.firstName && user.lastName) ? user.firstName + ' ' + user.lastName : '-')

        return {
          ...reservation,
          reservation_holders: temBuyers.join(', '),
          contractsSignatureStatus: reservation?.contractsSignatureStatus ?? 'pending'
        }
      }).sort((a, b) => {
        const dateA = new Date(a.createdAt)
        const dateB = new Date(b.createdAt)
        return dateB - dateA
      })
      setTableDataSource(updatedReservations)
    }
  }, [reservations, loading])

  const onSortClicked = useCallback(
    (field) => {
      let newSortType = 'desc'
      if (field === sortByField) {
        newSortType = sortType === 'desc' ? 'asc' : 'desc'
      }
      const sortedData = tableDataSource.sort((a, b) => {
        if (newSortType === 'desc') {
          return a[field]?.localeCompare(b[field])
        } else if (newSortType === 'asc') {
          return b[field]?.localeCompare(a[field])
        } else return 0
      })
      setFilteredTableDataSource([...sortedData])
      setSortByField(field)
      setSortType(newSortType)
    },
    [tableDataSource, sortByField, sortType]
  )

  const tableColumns = useMemo(
    () => {
      return (
        isMobile
          ? [
            {
              Title: (
                <span className='flex items-center space-x-2'>
                  <span className='leading-none'>Reservation</span>
                  <button onClick={() => onSortClicked('manualReservationDate')}>
                    <SorterIcon />
                  </button>
                </span>
              ),
              accessor: 'manualReservationDate',
              dataIndex: 'manualReservationDate',
              // Cell: (props) => <TextField value={formatDate(props.cell.value)} />
              Cell: (props) => (
                <ReservationCard
                  reservationHolders={props.cell.row.original.reservation_holders}
                  date={props.cell.value ?? props.cell.row.original.createdAt}
                  signatureStatus={props.cell.row.original.contractsSignatureStatus}
                  paymentStatus={props.cell.row.original.paymentStatus}
                  reservationStatus={props.cell.row.original.reservationStatus}
                  btnAction={() => history.push(`/admin/reservations/${props.cell.row.original._id}`)}
                  btnText='View/Edit'
                  isTitled
                />
              )
            }
          ]
          : [
            {
              id: 'dateRegistered',
              label: 'Date Registered',
              Title: (<span className='leading-none text-sm font-normal text-[#2E2B2E] text-opacity-70'>Date Registered</span>),
              accessor: 'manualReservationDate',
              dataIndex: 'manualReservationDate',
              isSort: true,
              sortingType: 'string',
              Cell: (props) => <p className='mb-0 text-base font-normal capitalize'>
                {props.cell.value ? moment(props.cell.value).format("MM/DD/YYYY") : moment(props.cell.row.original.createdAt).format("MM/DD/YYYY")}
              </p>
            },
            {
              id: 'reservationHolders',
              label: 'Reservation Holder(s)',
              Title: (<span className='leading-none text-sm font-normal text-[#2E2B2E] text-opacity-70'>Reservation Holder(s)</span>),
              accessor: 'reservation_holders',
              dataIndex: 'reservation_holders',
              isSort: true,
              sortingType: 'string',
              Cell: (props) => <p className='mb-0 text-base font-normal capitalize'>{props.cell.value}</p>
            },
            {
              id: 'manualReservationFee',
              label: 'Reservation Fee',
              Title: (<span className='leading-none text-sm font-normal text-[#2E2B2E] text-opacity-70'>Reservation Fee</span>),
              accessor: 'manualReservationFee',
              dataIndex: 'manualReservationFee',
              isSort: true,
              sortingType: 'number',
              Cell: (props) => <p className='mb-0 text-base font-normal capitalize'>{props.cell.value ? formatMoney(props.cell.value) : '-'}</p>
            },
            {
              id: 'paymentAmount',
              label: 'Payment Amount',
              Title: (<span className='leading-none text-sm font-normal text-[#2E2B2E] text-opacity-70'>Payment Amount</span>),
              accessor: 'paymentAmount',
              dataIndex: 'paymentAmount',
              isSort: true,
              sortingType: 'number',
              Cell: (props) => <p className='mb-0 text-base font-normal capitalize'>{props.cell.value ? formatMoney(props.cell.value) : '-'}</p>
            },
            {
              id: 'contractsSignatureStatus',
              label: 'Signature Status',
              Title: (<span className='leading-none text-sm font-normal text-[#2E2B2E] text-opacity-70'>Signature Status</span>),
              accessor: 'contractsSignatureStatus',
              dataIndex: 'contractsSignatureStatus',
              isSort: true,
              sortingType: 'string',
              Cell: (props) => <ColoredStatus status={props.cell.value} />
            },
            {
              id: 'paymentStatus',
              label: 'Payment Status',
              Title: (<span className='leading-none text-sm font-normal text-[#2E2B2E] text-opacity-70'>Payment Status</span>),
              accessor: 'paymentStatus',
              dataIndex: 'paymentStatus',
              isSort: true,
              sortingType: 'string',
              Cell: (props) => <ColoredStatus status={props.cell.value} />
            },
            {
              id: 'reservationStatus',
              label: 'Reservation Status',
              Title: (<span className='leading-none text-sm font-normal text-[#2E2B2E] text-opacity-70'>Reservation Status</span>),
              accessor: 'reservationStatus',
              dataIndex: 'reservationStatus',
              isSort: true,
              sortingType: 'string',
              Cell: (props) => <ColoredStatus status={props.cell.value} />
            },
            {
              id: 'actionBtn',
              label: 'Action button',
              Title: '',
              accessor: '_id',
              isSort: false,
              Cell: (props) => (
                <div className='flex flex-row gap-2'>
                  <CustomButton
                    handleClick={() =>
                      history.push(`/admin/reservations/${props.cell.value}`)
                    }
                  >
                    <span className='font-medium py-1.5 px-4 text-sm flex gap-2 items-center'>
                      View/Edit
                    </span>
                  </CustomButton>
                  <DeleteButton
                    onClick={() => {
                      console.log('DeleteButton clicked', props.cell.value)
                      setIsDeleteDialogOpened(props.cell.value)
                    }}
                  />
                </div>
              )
            }
          ]
      )
    },
    [onSortClicked]
  )

  const isDataLoading = useMemo(
    () => loading || isDeleteInProgress,
    [loading, isDeleteInProgress]
  )

  const values = useMemo(() => ({}), [])
  const memoTableDataSource = useMemo(() => {
    const values =
      filteredTableDataSource?.length > 0
        ? filteredTableDataSource
        : tableDataSource
    return values.filter((x) => !x.deleted)
  }, [filteredTableDataSource, tableDataSource])

  const deleteSelectedReservation = useCallback(async () => {
    const toastId = toast.loading('Deleting reservation...')
    try {
      const reservationId = isDeleteDialogOpened
      setIsDeleteDialogOpened(-1)
      await deleteReservationById(reservationId)
      refetchReservations()
      toast.dismiss(toastId)
      toast.success('Deleted reservation successfully')
    } catch (err) {
      toast.dismiss(toastId)
      console.error('Failed to delete reservation', err)
    }
  }, [isDeleteDialogOpened, refetchReservations])

  return (
    <LoadingContext.Provider value={values}>
      <div className='h-full w-full flex flex-col items-center'>
        <div className='w-full'>
          <div className='mt-5 grid grid-cols-2 md:grid-cols-5 lg:grid-cols-7 gap-3'>
            {fetchedDataByReservationType
              ?.filter((item) => item._id?.length)
              ?.map((item) => {
                return (
                  <CardComponent
                    title={`Reservation ${item._id}`}
                    count={item.count}
                  />
                )
              })}
            {fetchedDataByPaymentStatus
              ?.filter((item) => item._id?.length)
              ?.map((item) => {
                return (
                  <CardComponent
                    title={`Payment Status - ${item._id}`}
                    count={item.count}
                  />
                )
              })}
            {fetchedDataBySignatureStatus
              ?.filter((item) => item._id?.length)
              ?.map((item) => {
                return (
                  <CardComponent
                    title={`Signature Status - ${item._id}`}
                    count={item.count}
                  />
                )
              })}
            {fetchedManualReservationExpectedData
              ?.map((item, index) => {
                return (
                  <CardComponent
                    key={index}
                    title='Total reservation fees - created + pending'
                    count={formatMoney(item.totalAmount)}
                  />
                )
              })}
            {fetchManualReservationReceivedData
              ?.map((item, index) => {
                return (
                  <CardComponent
                    key={index}
                    title='Total reservation fees - paid - Received'
                    count={formatMoney(item.totalAmount)}
                  />
                )
              })}
          </div>
          <div className='my-5'>
            {isDataLoading && (
              <div className='flex w-full justify-center items-center'>
                loading reservations....
              </div>
            )}
            {!isDataLoading && (
              <MemoTable
                dataSource={memoTableDataSource}
                columns={tableColumns}
                loading={loading}
                idBeingDeleted={idBeingDeleted}
                deleting={deletingReservation}
              />
            )}
            <Modal
              showModal={isDeleteDialogOpened !== -1}
              onCancel={() => setIsDeleteDialogOpened(-1)}
              modalType='alert'
              title='Are you sure?'
              subtitle='Do you want to delete reservation?'
              onOk={deleteSelectedReservation}
            />
          </div>
        </div>
      </div>
    </LoadingContext.Provider>
  )
}

const mapStateToProps = (state) => ({
  appProject: state.appReducer.appProject,
  userObject: state.authReducer.userObject
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectReservations)

ProjectReservations.propTypes = {
  appProject: propTypes.string,
  userObject: propTypes.shape({
    userType: propTypes.string
  }),
  cell: propTypes.shape({
    value: propTypes.string
  }),
  row: propTypes.shape({
    original: propTypes.shape({
      _id: propTypes.string
    })
  })
}
