import React from 'react'
import { ReactComponent as CompanyIcon } from '../../../assets/img/temp_company.svg'
import userImgJpeg from '../../../assets/img/userImgBroker.png'
import CustomButton from 'components/CustomButton'

const BrokerSidePanel = ({
  buttonLabel = 'Open Portal',
  onButtonClick = null
}) => {
  return (
    <div className='bg-white flex-col flex max-w-[360px] w-[360px] h-[455px] items-start content-start justify-between p-6'>
      <h5 className='font-bold uppercase text-2xl -tracking-tighter p-0 m-0'>
        Broker PORTAL
      </h5>
      <img
        src={userImgJpeg}
        className='h-32 w-32 border rounded-full bg-black'
      />
      <p className='captitalize text-xl p-0 m-0 font-bold'>Shannon Mckenzie</p>
      <div className='w-28 h-16'>
        <CompanyIcon />
      </div>
      <CustomButton
        handleClick={onButtonClick}
      >
        <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
          {buttonLabel}
        </span>
      </CustomButton>
    </div>
  )
}

export default BrokerSidePanel
