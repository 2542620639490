import styled from 'styled-components'
import standardStyle from 'assets/css/standardStyle'

export const Image = styled.img`
  border-radius: 4px;
  height: 100%;
  width: 70%;
  object-fit: cover;

  @media ${standardStyle.mediaqueries.xs}, ${standardStyle.mediaqueries.sm} {
    width: 100%;
  }
`
