import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { getBrokerLeads } from 'store/actions/brokerLeadActions'

const useGetBrokerLeadsById = () => {
  // @ts-ignore
  const projectId = useSelector((state) => state.appReducer.appProject)
  const { data, isLoading } = useQuery(
    ['getBrokerLeadsById', projectId],
    ({ queryKey }) => getBrokerLeads(queryKey[1]),
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: false
    }
  )
  return { brokerLeads: data, isLoading }
}

export default useGetBrokerLeadsById
