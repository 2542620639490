import standardStyle from 'assets/css/standardStyle'
import { motion } from 'framer-motion'
import styled from 'styled-components'

export const UnitWrapper = styled.div``
export const BuyerParkingButtonsWrapper = styled.div``

export const PdfWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px 10px;
  
  @media ${standardStyle.mediaqueries.xs}, ${standardStyle.mediaqueries.sm} {
    width: 100%;
    align-items: unset;
    padding: 10px 0px;
    margin: 0px;
  }
`

export const PdfRoundedWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: white;
  box-shadow: 0 0 2px 2px rgba(0,0,0,0.07);
  border-radius: 4px;
  height: 100%;

  @media ${standardStyle.mediaqueries.xs}, ${standardStyle.mediaqueries.sm} {
    padding: 20px;
  }

  :hover{
    cursor: pointer;
  }
`

export const ButtonsWrapper = styled.div``

export const PdfReviwedContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const PdfInternalWrappers = styled.div`
  height: 50%;
  width: 100%;

  display: flex;
  flex-direction: column;

  button {
    margin-top: 10px;
  }
`

export const ContractsWrapper = styled.div``
export const AnimatedStepWrapper = styled(motion.div)`
  /* ::-webkit-scrollbar { display:none }; */
  `

export const ContractSignatureStatusWrapper = styled.div``
