import React from 'react'
import propTypes from 'prop-types'
import standardStyle from 'assets/css/standardStyle'

const Card = ({ ImgSrc, title, selected }) => {
  return (
    <div
      className='relative rounded h-56'
      style={{
        fontFamily: standardStyle.fonts.sfpro
      }}
    >
      <img
        className='absolute w-full h-full top-0 left-0'
        src={ImgSrc}
        alt={title}
      />
      <div
        className={`absolute bg-black w-full h-full top-0 left-0 ${
          selected ? 'opacity-[0.15]' : 'opacity-60'
        }`}
      />
      <div className='absolute left-6 bottom-6'>
        <span className='font-bold text-2xl text-white'>{title}</span>
      </div>
    </div>
  )
}

Card.propTypes = {
  ImgSrc: propTypes.oneOfType([propTypes.node, propTypes.string]),
  title: propTypes.string,
  selected: propTypes.bool
}

export default Card
