// @ts-nocheck
/* eslint-disable no-unused-vars */
import DeleteIcon from 'components/Icons/DeleteIcon'
import React, { useMemo, useState, useContext } from 'react'
import { useSelector } from 'react-redux'
import DeleteFromListModal from './DeleteFromListModal'
import { useHistory } from 'react-router-dom'

import { ContactContext } from 'pages/contacts/context/Contacts'

const TableBody = ({ page, customContacts = null }) => {
  const {
    selected,
    setSelected,
    saveList,
    setSaveList,
    filteredContacts: contacts,
    limit,
    listsChecked
  } = useContext(ContactContext)

  const userObject = useSelector((state) => state.authReducer.userObject)
  const history = useHistory()

  const [openDelFromListModal, setOpenDeleteFromListModal] = useState(false)
  const [targetContact, setTargetContact] = useState(null)

  const listsFilterApplied = listsChecked && Object.values(listsChecked).some(status => !!status)

  const columns = useMemo(() => listsFilterApplied
    ? ([
        { name: 'Name', property: 'firstName' },
        { name: 'User Type', property: 'userType' },
        { name: 'Email', property: 'email' },
        {
          name: 'Phone Number',
          property: { name: 'buyerData', property: 'phoneNumber' }
        },
        {
          name: 'Sales Rep Owner',
          property: { name: 'buyerData', property: 'salesRep' }
        },
        {
          name: 'Buyer Score',
          property: { name: 'buyerData', property: 'rating' }
        },
        {
          name: 'Lead Status',
          property: { name: 'buyerData', property: 'leadStatus' }
        },
        {
          name: 'Remove From List',
          property: { name: 'Delete Button' }
        }
      ])
    : ([
        { name: 'Name', property: 'firstName' },
        { name: 'User Type', property: 'userType' },
        { name: 'Email', property: 'email' },
        {
          name: 'Phone Number',
          property: { name: 'buyerData', property: 'phoneNumber' }
        },
        {
          name: 'Sales Rep Owner',
          property: { name: 'buyerData', property: 'salesRep' }
        },
        {
          name: 'Buyer Score',
          property: { name: 'buyerData', property: 'rating' }
        },
        {
          name: 'Lead Status',
          property: { name: 'buyerData', property: 'leadStatus' }
        }
      ]), [listsFilterApplied])

  // @ts-ignore
  const handleSaveList = (e, contact, idx, index) => {
    if (e.currentTarget.checked) setSaveList([...saveList, contact])
    else setSaveList((prev) => prev.filter((item) => item._id !== contact._id))
    if (e.currentTarget.checked) {
      // @ts-ignore
      return setSelected({
        ...selected,
        [contact._id]: e.currentTarget.checked
      })
    }
    const obj = { ...selected }
    delete obj[contact._id]
    // @ts-ignore
    setSelected(obj)
    // setSelected({ ...selected, [`${page}${idx}${index}`]: e.currentTarget.checked })
  }

  const openDeleteFromListModal = (contact) => {
    setTargetContact(contact)
    setOpenDeleteFromListModal(true)
  }

  const getDeleteButton = () => (
    <button>
      <DeleteIcon height={20} width={20} />
    </button>
  )

  const getStringFromProperty = (contact, col) => {
    if (col.name === 'Phone Number') return ''
    if (col?.property?.name === 'Delete Button') return getDeleteButton()
    if (contact?.userType === 'LeadBroker') {
      return contact?.leadBrokerData?.assignments?.[0]?.salesRep?.firstName
    }
    if (userObject?.userType !== 'SalesRep') { return contact[col?.property?.name]?.assignments[0]?.salesRep?.firstName }
    const assignemnts = contact[col?.property?.name]?.assignments
    if (!assignemnts?.length) return ''
    const found = assignemnts?.some?.(assignment => assignment?.salesRep?._id === userObject?._id)
    if (!found) { return contact[col?.property?.name]?.assignments[0]?.salesRep?.firstName }
    return (
      userObject?.firstName
    )
  }

  return (
    <tbody className='divide-y divide-gray-200 bg-white'>
      <DeleteFromListModal isOpen={openDelFromListModal} contact={targetContact} onClose={() => setOpenDeleteFromListModal(false)} />
      {(customContacts || contacts.slice((page - 1) * limit, limit * page))
        .map((contact, contactIndex) => (
          <tr key={contactIndex}>
            {columns.map((col, columnIndex) => (
              <React.Fragment key={`${contactIndex}-${columnIndex}`}>
                {columnIndex === 0
                  ? (
                    <td
                      key={`${contactIndex}-${columnIndex - 1}`}
                      className='relative w-12 px-6 sm:w-16 sm:px-8'
                    >
                      <div className='absolute inset-y-0 left-0 w-0.5 bg-indigo-600'></div>
                      <input
                        data-id={`${contact._id}`}
                        onChange={(e) =>
                          handleSaveList(e, contact, columnIndex, contactIndex)
                        }
                        checked={selected[contact?._id] ?? false}
                        type='checkbox'
                        className='absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6 hover:cursor-pointer'
                      />
                    </td>
                    )
                  : null}
                {
                  // @ts-ignore
                  col.property.name === 'Delete Button'
                    ? <td
                      style={{ textAlign: 'left', cursor: 'pointer' }}
                      className='whitespace-nowrap w-25 pr-3 text-sm font-medium text-gray-900 pr-6'
                      // @ts-ignore
                      onClick={() => openDeleteFromListModal(contact)}
                    >
                      {typeof col.property === 'string'
                        ? (
                            col.property === 'firstName'
                              ? (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  gap: '16px'
                                }}
                              >
                                <div className='rounded-full bg-gray-200 text-softBlack p-3 my-2 w-14 h-14 flex justify-center items-center font-sans hover:shadow hover:cursor-pointer'>
                                  {`${contact.firstName?.[0] ?? ''}${contact?.lastName?.[0] ?? ''}`}
                                </div>
                                {(contact[col.property] + ' ' + contact?.lastName ?? '').slice(0, 25)}
                              </div>
                                )
                              : (
                                  contact[col.property]?.trim()
                                )
                          )
                        : typeof contact[col.property.name]?.[
                          // @ts-ignore
                          col.property.property
                        ] === 'string'
                          ? (
                            // @ts-ignore
                              contact[col.property.name]?.[col.property.property]
                            )
                          : (
                              getStringFromProperty(contact, col)
                            )}
                    </td>
                    : <td
                      style={{ textAlign: 'left', cursor: 'pointer' }}
                      className='whitespace-nowrap w-25 pr-3 text-sm font-medium text-gray-900'
                      // @ts-ignore
                      onClick={() => history.push({
                        pathname: '/admin/newcontacts',
                        search: `?id=${contact?._id}&userType=${contact.userType}`
                      })}
                    >
                      {typeof col.property === 'string'
                        ? (
                            col.property === 'firstName'
                              ? (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  gap: '16px'
                                }}
                              >
                                <div className='rounded-full bg-gray-200 text-softBlack p-3 my-2 w-14 h-14 flex justify-center items-center font-sans hover:shadow hover:cursor-pointer'>
                                  {`${contact.firstName?.[0] ?? ''}${contact?.lastName?.[0] ?? ''}`}
                                </div>
                                {(contact[col.property] + ' ' + contact?.lastName ?? '').slice(0, 25)}
                              </div>
                                )
                              : (
                                  contact[col.property]?.trim()
                                )
                          )
                        : typeof contact[col.property.name]?.[
                          // @ts-ignore
                          col.property.property
                        ] === 'string'
                          ? (
                            // @ts-ignore
                              contact[col.property.name]?.[col.property.property]
                            )
                          : (
                              getStringFromProperty(contact, col)
                            )}
                    </td>}
              </React.Fragment>
            ))}
          </tr>
        ))}
    </tbody>
  )
}

export default TableBody
