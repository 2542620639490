export const AVAILABILITY_STATUS = {
  available: 'available',
  open: 'Open',
  reserved: 'reserved',
  reservedBroker: 'ReservedBroker',
  reservedBuyer: 'ReservedBuyer',
  sold: 'sold',
  soldCommitted: 'SoldCommitted',
  soldFirm: 'SoldFirm',
  developerHeld: 'DeveloperHeld',
  closed: 'Closed',
  underContract: 'UnderContract',
  offerAccepted: 'OfferAccepted'
}

export const getOfferAlternateStatus = {
  [AVAILABILITY_STATUS.available]: [
    AVAILABILITY_STATUS.available,
    AVAILABILITY_STATUS.open
  ],
  [AVAILABILITY_STATUS.reserved]: [
    AVAILABILITY_STATUS.reserved,
    AVAILABILITY_STATUS.reservedBroker,
    AVAILABILITY_STATUS.reservedBuyer
  ],
  [AVAILABILITY_STATUS.sold]: [
    AVAILABILITY_STATUS.sold,
    AVAILABILITY_STATUS.soldCommitted,
    AVAILABILITY_STATUS.soldFirm,
    AVAILABILITY_STATUS.closed
  ],
  [AVAILABILITY_STATUS.underContract]: [
    AVAILABILITY_STATUS.offerAccepted,
    AVAILABILITY_STATUS.underContract
  ],
  [AVAILABILITY_STATUS.developerHeld]: [AVAILABILITY_STATUS.developerHeld]
}

export const AVAILABILITY_COLOR = {
  available: '#B8E04A', // '#268231',
  reserved: '#EDDF65',
  sold: '#BF5716', // '#AE4300',
  underContract: '#BF5716', // '#AE4300',
  developerHeld: ' #2E2B2EB3' // '#2E2B2EB3'
}

export const AVAILABILITY_BG_COLOR = {
  available: AVAILABILITY_COLOR.available,
  open: AVAILABILITY_COLOR.available,
  reserved: AVAILABILITY_COLOR.reserved,
  reservedbroker: AVAILABILITY_COLOR.reserved,
  reservedbuyer: AVAILABILITY_COLOR.reserved,
  sold: AVAILABILITY_COLOR.sold,
  soldcommitted: AVAILABILITY_COLOR.sold,
  soldfirm: AVAILABILITY_COLOR.sold,
  closed: AVAILABILITY_COLOR.sold,
  undercontract: AVAILABILITY_COLOR.underContract,
  offeraccepted: AVAILABILITY_COLOR.underContract,
  developerheld: AVAILABILITY_COLOR.developerHeld
}

// export const AVAILABILITY_BG_COLOR = {
//   available: `bg-[${AVAILABILITY_COLOR.available}]`,
//   open: `bg-[${AVAILABILITY_COLOR.available}]`,
//   reserved: `bg-[${AVAILABILITY_COLOR.reserved}]`,
//   reservedbroker: `bg-[${AVAILABILITY_COLOR.reserved}]`,
//   reservedbuyer: `bg-[${AVAILABILITY_COLOR.reserved}]`,
//   sold: `bg-[${AVAILABILITY_COLOR.sold}]`,
//   soldcommitted: `bg-[${AVAILABILITY_COLOR.sold}]`,
//   soldfirm: `bg-[${AVAILABILITY_COLOR.sold}]`,
//   contract: `bg-[${AVAILABILITY_COLOR.contract}]`,
//   offeraccepted: `bg-[${AVAILABILITY_COLOR.contract}]`,
//   developerheld: `bg-[${AVAILABILITY_COLOR.developerHeld}]`
// }
