import React from 'react'
import userImgJpeg from '../../../../assets/img/userImg.jpg'
import userImgJpegBroker from '../../../../assets/img/userImgBroker.png'
import standardStyle from 'assets/css/standardStyle'
import { formatMoney } from 'utils'
import { StatsHorizontal } from 'components/StatsHorizontal'
import { ReactComponent as CompanyIcon } from '../../../../assets/img/temp_company.svg'
import { TwitterIcon, FacebookIcon, InstagramIcon } from 'components'
import { LinkedInIcon } from 'components/Icons'
import { ChevronDownIcon } from '@heroicons/react/outline'

const StatusCardItem = ({ IconSrc, title, value, isCurrency = false }) => {
  return (
    <div
      className='bg-white rounded w-[182px] h-[185px] p-6 flex flex-col justify-between'
      style={{ fontFamily: standardStyle.fonts.sfpro }}
    >
      <div className='w-10 h-8'>{IconSrc}</div>
      <p className='flex flex-col mb-0'>
        <span className='text-xs text-softBlack_70 font-medium uppercase mb-0'>
          {title}
        </span>
        <span className='text-xl font-bold text-softBlack pt-1.5 mb-0'>
          {isCurrency ? formatMoney(value) : value}
        </span>
      </p>
    </div>
  )
}

const ItemCardWrapper = ({ children }) => {
  return <div className='bg-white w-[406px] rounded p-[24px]'>{children}</div>
}

const SocialIconWrapper = ({ children }) => {
  return (
    <div className='bg-black w-[26.6px] h-[26.6px] rounded-full text-white flex items-center justify-center'>
      {children}
    </div>
  )
}
const LeadItem = (data) => {
  return (
    <div className='w-[240px] h-[158px] flex flex-col justify-start'>
      <div className='flex flex-row'>
        <img
          alt='userImg'
          src={userImgJpeg}
          className='h-22 w-22 border rounded-full bg-black'
        />
        <p className='mb-0 ml-2.5 h-full flex flex-col justify-center items-start content-start text-start'>
          <span className='text-[#16192C] font-bold text-base'>
            {data.name}
          </span>
          <span className='text-[#425466] font-normal text-xs'>
            {data.address}
          </span>
        </p>
      </div>
      <button
        className='w-[164px] h-[44px] bg-[#30373E] text-[14px] font-semibold text-white rounded-lg mt-[26.15px]'
        onClick={() => {
          console.log('View Profile')
        }}
      >
        View Profile
      </button>
    </div>
  )
}

const ProjectsItem = ({ title, options }) => {
  return (
    <div className='flex flex-col mt-[18px]'>
      <button className='flex flex-row w-full items-center justify-between'>
        <span className='ml-1 text-softBlack_70 text-start text-base font-bold'>
          {title}
        </span>
        <ChevronDownIcon
          aria-hidden='true'
          className={
            'h-5 w-5 text-gray-400 group-hover:text-gray-500 rotate-180 transform'
          }
        />
      </button>
      <div className='z-10 flex flex-col w-full space-y-3 bg-menuGray justify-start py-[21px] px-[18px] border-softBlack border mt-4'>
        {options.map((option, index) => (
          <div
            key={option.value}
            className={`${'flex-1 space-x-2.5 text-softBlack_70'}`}
          >
            <input
              type='checkbox'
              id={`custom-checkbox-${index}`}
              name={option.value}
              value={option.value}
              checked={false}
              disabled={true}
            />
            <label htmlFor='checkbox'>{option?.label}</label>
          </div>
        ))}
      </div>
    </div>
  )
}

const Last5SalesItem = (data) => {
  return (
    <div className='flex flex-row items-center'>
      <span className='text-[13px] font-light text-softBlack mr-[20px]'>
        {data.index}
      </span>
      <img
        alt='userImg sales'
        src={userImgJpeg}
        className='h-[34px] w-[34px] border rounded-full bg-black mr-[13px]'
      />
      <p className='mb-0 ml-2.5 h-full w-full flex flex-row justify-between items-start content-start text-start'>
        <span className='text-[13px] font-light text-softBlack'>
          {data.name}
        </span>
        <span className='text-[13px] font-light text-softBlack'>
          {formatMoney(data.value)}
        </span>
      </p>
    </div>
  )
}
const DashboardScreen = () => {
  const statusCardItems = [
    {
      IconSrc: (
        <svg
          width='40'
          height='32'
          viewBox='0 0 40 32'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M18 26H22V24H24C25.1 24 26 23.1 26 22V16C26 14.9 25.1 14 24 14H18V12H26V8H22V6H18V8H16C14.9 8 14 8.9 14 10V16C14 17.1 14.9 18 16 18H22V20H14V24H18V26ZM36 0H4C1.78 0 0.02 1.78 0.02 4L0 28C0 30.22 1.78 32 4 32H36C38.22 32 40 30.22 40 28V4C40 1.78 38.22 0 36 0ZM36 28H4V4H36V28Z'
            fill='#2E2B2E'
          />
        </svg>
      ),
      title: 'Total Commission',
      value: '379000',
      isCurrency: true
    },
    {
      IconSrc: (
        <svg
          width='32'
          height='32'
          viewBox='0 0 32 32'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <g clipPath='url(#clip0_1275_59943)'>
            <path
              d='M25.3333 4.00016H19.76C19.2 2.4535 17.7333 1.3335 16 1.3335C14.2667 1.3335 12.8 2.4535 12.24 4.00016H6.66667C5.2 4.00016 4 5.20016 4 6.66683V25.3335C4 26.8002 5.2 28.0002 6.66667 28.0002H25.3333C26.8 28.0002 28 26.8002 28 25.3335V6.66683C28 5.20016 26.8 4.00016 25.3333 4.00016ZM16 4.00016C16.7333 4.00016 17.3333 4.60016 17.3333 5.3335C17.3333 6.06683 16.7333 6.66683 16 6.66683C15.2667 6.66683 14.6667 6.06683 14.6667 5.3335C14.6667 4.60016 15.2667 4.00016 16 4.00016ZM12.3867 21.7202L8.93333 18.2668C8.41333 17.7468 8.41333 16.9068 8.93333 16.3868C9.45333 15.8668 10.2933 15.8668 10.8133 16.3868L13.3333 18.8935L21.1733 11.0535C21.6933 10.5335 22.5333 10.5335 23.0533 11.0535C23.5733 11.5735 23.5733 12.4135 23.0533 12.9335L14.2667 21.7202C13.76 22.2402 12.9067 22.2402 12.3867 21.7202Z'
              fill='#2E2B2E'
            />
          </g>
          <defs>
            <clipPath id='clip0_1275_59943'>
              <rect width='32' height='32' fill='white' />
            </clipPath>
          </defs>
        </svg>
      ),
      title: 'TOTAL SALES',
      value: '15182000',
      isCurrency: true
    },
    {
      IconSrc: (
        <svg
          width='32'
          height='32'
          viewBox='0 0 32 32'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <g clipPath='url(#clip0_1275_59948)'>
            <path
              d='M22.6667 14.6667V6.66667C22.6667 5.2 21.4667 4 20 4H12C10.5333 4 9.33333 5.2 9.33333 6.66667V9.33333H6.66667C5.2 9.33333 4 10.5333 4 12V25.3333C4 26.8 5.2 28 6.66667 28H13.3333C14.0667 28 14.6667 27.4 14.6667 26.6667V22.6667H17.3333V26.6667C17.3333 27.4 17.9333 28 18.6667 28H25.3333C26.8 28 28 26.8 28 25.3333V17.3333C28 15.8667 26.8 14.6667 25.3333 14.6667H22.6667ZM9.33333 25.3333H6.66667V22.6667H9.33333V25.3333ZM9.33333 20H6.66667V17.3333H9.33333V20ZM9.33333 14.6667H6.66667V12H9.33333V14.6667ZM14.6667 20H12V17.3333H14.6667V20ZM14.6667 14.6667H12V12H14.6667V14.6667ZM14.6667 9.33333H12V6.66667H14.6667V9.33333ZM20 20H17.3333V17.3333H20V20ZM20 14.6667H17.3333V12H20V14.6667ZM20 9.33333H17.3333V6.66667H20V9.33333ZM25.3333 25.3333H22.6667V22.6667H25.3333V25.3333ZM25.3333 20H22.6667V17.3333H25.3333V20Z'
              fill='#2E2B2E'
            />
          </g>
          <defs>
            <clipPath id='clip0_1275_59948'>
              <rect width='32' height='32' fill='white' />
            </clipPath>
          </defs>
        </svg>
      ),
      title: 'UNITS SOLD',
      value: '22'
    },
    {
      IconSrc: (
        <svg
          width='32'
          height='32'
          viewBox='0 0 32 32'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <g clipPath='url(#clip0_1275_59953)'>
            <path
              d='M24 17.3334H23.0933L20.4267 20.0001H22.9733L25.3333 22.6667H6.66667L9.04 20.0001H11.7733L9.10667 17.3334H8L4 21.3334V26.6667C4 28.1334 5.18667 29.3334 6.65333 29.3334H25.3333C26.8 29.3334 28 28.1467 28 26.6667V21.3334L24 17.3334ZM22.6667 10.6001L16.0667 17.2001L11.3467 12.4801L17.9467 5.88005L22.6667 10.6001ZM17.0133 3.05339L8.52 11.5467C8 12.0667 8 12.9067 8.52 13.4267L15.12 20.0267C15.64 20.5467 16.48 20.5467 17 20.0267L25.48 11.5467C26 11.0267 26 10.1867 25.48 9.66672L18.88 3.06672C18.3733 2.53339 17.5333 2.53339 17.0133 3.05339Z'
              fill='#2E2B2E'
            />
          </g>
          <defs>
            <clipPath id='clip0_1275_59953'>
              <rect width='32' height='32' fill='white' />
            </clipPath>
          </defs>
        </svg>
      ),
      title: 'Pending Sales',
      value: '8'
    }
  ]
  const leadItems = [
    {
      name: 'Stefan Dupuis',
      address: 'Vancouver, Canada'
    },
    {
      name: 'Hugo Lee ',
      address: 'Toronto, Canada'
    },
    {
      name: 'Carla  Palento',
      address: 'Vancouver, Canada'
    }
  ]

  const unitStats = [
    {
      title: 'A',
      units: 18,
      color: '#EDDF65'
    },
    {
      title: 'B',
      units: 40,
      color: 'rgba(46, 43, 46, 0.7)'
    },
    { title: 'C', units: 60, color: 'rgba(46, 43, 46, 0.3)' }
  ]

  const activeProject = [
    {
      value: 'Pike Square - Metrotown',
      label: 'Pike Square - Metrotown'
    },
    {
      value: '901 Turo - Surrey',
      label: '901 Turo - Surrey'
    }
  ]
  const pastProject = [
    {
      value: 'Byrnepark - Burnaby',
      label: 'Byrnepark - Burnaby'
    },
    {
      value: 'Claridge House - Oakridge',
      label: 'Claridge House - Oakridge'
    }
  ]
  const last5Sales = [
    { name: 'Duncan Bator', value: '2452100' },
    { name: 'Charlie Press', value: '1112150' },
    { name: 'Wilson Philips', value: '832450' },
    { name: 'Madelyn Passaquindici Arcand', value: '1261990' },
    { name: 'Justin Culhane', value: '996450' }
  ]

  return (
    <div className='flex flex-col w-full h-full px-12'>
      <h1 className='flex w-full font-bold text-[39px] pl-[28px] mb-0'>
        BROKER PORTAL
      </h1>
      <div className='grid grid-cols-3 w-full h-full gap-7 m-[15px]'>
        <div className='col-span-1 bg-white rounded border border-softBlack p-6 flex flex-col'>
          <div className='flex flex-row items-center'>
            <img
              alt='userImg 2'
              src={userImgJpegBroker}
              className='h-[117px] w-[117px] border rounded-full bg-black'
            />
            <div className='flex flex-row bg-[#EDDF65] p-[17px] w-[166px] h-[88px] rounded justify-between ml-[27px]'>
              <p className='flex flex-col mb-0 space-y-[6px]'>
                <span className='text-xs text-softBlack_70 font-medium uppercase mb-0'>
                  Broker Rating
                </span>
                <span className='text-xl font-bold text-softBlack py-1.5 mb-0'>
                  B - 71.34
                </span>
              </p>
            </div>
          </div>
          <p className='font-bold text-[26px] mt-6 mb-0'>Shannon Mckenzie</p>
          <p className='font-normal text-base mt-[18px] mb-0'>
            smckenzie@sothebys.com
          </p>
          <p className='font-normal text-base mt-[18px] mb-0'>604-132-4491</p>
          <p className='font-normal text-base mt-[18px] mb-0'>
            Vancouver, BC, Canada
          </p>
          <CompanyIcon className='w-[102px] h-[60px] mt-[18px] mb-0' />
          <div className='flex flex-row items-center justify-start text-xl space-x-[14.5px]  mt-[18px]'>
            <SocialIconWrapper>
              <TwitterIcon className='hover:cursor-pointer' />
            </SocialIconWrapper>
            <SocialIconWrapper>
              <FacebookIcon className='hover:cursor-pointer' />
            </SocialIconWrapper>
            <SocialIconWrapper>
              <InstagramIcon className='hover:cursor-pointer' />
            </SocialIconWrapper>
            <SocialIconWrapper>
              <LinkedInIcon className='hover:cursor-pointer' />
            </SocialIconWrapper>
          </div>
          <ProjectsItem title='Active Projects' options={activeProject} />
          <ProjectsItem title='Past Projects' options={pastProject} />
        </div>
        <div className='col-span-2 flex flex-col w-full h-full justify-between'>
          <div className='flex flex-row w-full justify-between gap-[38px]'>
            {statusCardItems.map((item, index) => {
              return <StatusCardItem key={index} {...item} />
            })}
          </div>
          <div className='flex flex-row w-full justify-between gap-[14px]'>
            <ItemCardWrapper>
              <h6 className='text-[17px] font-bold'>Last 5 Sales</h6>
              <p className='flex flex-row w-full justify-between'>
                <span className='text-[13px] font-light text-softBlack ml-5'>
                  Buyer
                </span>
                <span className='text-[13px] font-light text-softBlack'>
                  Sale Amount
                </span>
              </p>
              <hr />
              <div className='flex flex-col h-full gap-[21px] m-auto mt-[21px]'>
                {last5Sales.map((item, index) => {
                  return (
                    <Last5SalesItem key={index} index={index + 1} {...item} />
                  )
                })}
              </div>
            </ItemCardWrapper>
            <ItemCardWrapper>
              <div className='flex flex-row justify-between'>
                <div className='flex flex-row bg-[#D8D8D8] p-[17px] rounded w-[143px] h-[87px]'>
                  <p className='flex flex-col mb-0'>
                    <span className='text-xl font-bold text-softBlack py-1.5 mb-0'>
                      118
                    </span>
                    <span className='text-xs text-softBlack_70 font-medium uppercase mb-0'>
                      Total Contacts
                    </span>
                  </p>
                </div>
                <button
                  className='w-[164px] h-[44px] bg-[#30373E] text-[14px] font-semibold text-white rounded-lg mt-[26.15px]'
                  onClick={() => {
                    console.log('View All Contacts ')
                  }}
                >
                  View All Contacts
                </button>
              </div>
              <StatsHorizontal
                stats={unitStats}
                title={'Lead Score'}
                classes='justify-between'
                tabClasses='w-1/4'
                padding='py-3'
              />
            </ItemCardWrapper>
          </div>
          <div className='flex flex-col w-full h-[283px] bg-white py-4 px-5'>
            <div className='flex w-full font-bold text-[17.20px] mb-0'>
              New Leads
            </div>
            <div className='flex flex-row w-full justify-between mt-[42px]'>
              {leadItems.map((item, index) => {
                return <LeadItem key={index} {...item} />
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboardScreen
