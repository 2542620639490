/* eslint-disable */
// @ts-nocheck
import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import { PlusIcon } from '@heroicons/react/solid'
import { LottieWrapper, PageHeader } from 'components'
import CustomButton from 'components/CustomButton'
import CustomModal from 'ui/customModal'
import CustomInput from 'components/CustomInput'
import CustomTable from 'components/CustomTable'
import {
  createOrUpdateCommunicationTemplate,
  deleteCommunicationTemplate,
  getCommunicationTemplate,
} from 'store/actions/communicationTemplateActions'
import { Eye, Trash } from 'lucide-react'
import successAnimation from 'assets/lottieJsons/avesdoLoading.json'
import { Oval } from 'react-loader-spinner'
import { useSelector } from 'react-redux'
import CustomSelect from 'pages/reservation/NewReservation/components/CustomSelect'
import RichTextField from 'components/RichTextField'
import { getTextToolbarOptions } from 'components/RichTextField/config/config'
import MergeFieldsDrawer from 'components/MergeFieldsDrawer'

export const MemoTable = ({
  dataSource,
  columns,
  loading,
  idBeingDeleted,
  deleting,
  showTablePagination
}) => {
  return (
    <CustomTable
      className='rounded-lg'
      dataSource={dataSource}
      columns={columns}
      tailwindTable
      loading={loading}
      idBeingDeleted={idBeingDeleted}
      deleting={deleting}
      isFromContracts
      showTablePagination={showTablePagination}
      noMargin
    />
  )
}

const TEMPLATE_TYPES = [
  {
    value: 'call_script',
    label: 'Call Script'
  },
  {
    value: 'sms',
    label: 'SMS'
  },
  {
    value: 'email',
    label: 'Email'
  },
  {
    value: 'event_invitation',
    label: 'Event Invitation'
  }
]

const CreateNewCommunicationTemplateButton = ({
  handleNewCommunicationTemplateModalOpen,
}) => {
  return (
    <CustomButton
      handleClick={handleNewCommunicationTemplateModalOpen}
      disabled={false}
    >
      <span className='font-medium py-2 px-4 text-base flex gap-2 items-center'>
        <PlusIcon className='h-5 w-5 text-white' />
        New Communication Template
      </span>
    </CustomButton>
  )
}

const RightContent = memo(({ handleNewCommunicationTemplateModalOpen }) => (
  <div className='flex flex-row items-center gap-2'>
    <CreateNewCommunicationTemplateButton
      handleNewCommunicationTemplateModalOpen={handleNewCommunicationTemplateModalOpen}
    />
  </div>
))

const NewCommunicationTemplateModal = memo(
  ({
    showModal,
    onCancel,
    onOk,
    newTemplate,
    setNewTemplate,
    isLoading,
    setIsMergeFieldsDrawerOpen
  }) => {
    const editorRef = useRef(null)
    const isValidate = useMemo(() => {
      if (!newTemplate.title || newTemplate.title.trim() === '') {
        return false
      } else if (
        !newTemplate.templateType ||
        newTemplate.templateType.trim() === ''
      ) {
        return false
      } else if (!newTemplate.audience || newTemplate.audience.trim() === '') {
        return false
      } else if (!newTemplate.category || newTemplate.category.trim() === '') {
        return false
      } else if (!newTemplate.content || newTemplate.content.trim() === '') {
        return false
      } else {
        return true
      }
    }, [newTemplate])

    return (
      <CustomModal showModal={showModal} onCancel={onCancel} onOk={onOk} minHeight={false}>
        <div className='w-full md:w-[750px] min-h-max flex flex-col gap-4'>
          <div className='flex flex-col gap-1'>
            <h2 className='text-2xl mb-0'>
              {!newTemplate?._id ? 'Create New Template' : 'Update Template'}
            </h2>
            <h3 className='text-sm mb-0 font-light text-gray'>
              Fill the details of your communication template
            </h3>
          </div>
          <div className='grid grid-cols-2 gap-4'>
            <CustomInput
              label='Title *'
              placeholder='Enter title'
              labelClassName='text-xs mb-[6px]'
              value={newTemplate.title ?? ''}
              onChange={(e) => {
                setNewTemplate({
                  ...newTemplate,
                  title: e.target.value,
                });
              }}
              type='text'
              disabled={isLoading}
              containerClasses='col-span-2'
            />
            <CustomSelect
              value={newTemplate?.templateType ?? ''}
              handleChange={(newValue) => {
                setNewTemplate({
                  ...newTemplate,
                  templateType: newValue,
                  subject: newTemplate.templateType === 'email' ? newTemplate?.subject : ''
                });
              }}
              options={TEMPLATE_TYPES}
              showSearch
              placeholder='Select Template Type'
              label='Select Template Type *'
              className='border-none'
            />
            <CustomInput
              label='Select Audience *'
              placeholder='Write about Audience'
              labelClassName='text-xs mb-[6px]'
              value={newTemplate.audience ?? ''}
              onChange={(e) => {
                setNewTemplate({
                  ...newTemplate,
                  audience: e.target.value,
                });
              }}
              type='text'
              disabled={isLoading}
            />
            <CustomInput
              label='Select Category Information*'
              placeholder='Write about Category'
              labelClassName='text-xs mb-[6px]'
              value={newTemplate.category ?? ''}
              onChange={(e) => {
                setNewTemplate({
                  ...newTemplate,
                  category: e.target.value,
                });
              }}
              type='text'
              disabled={isLoading}
              containerClasses='col-span-2'
            />
            {
              newTemplate.templateType === 'email' &&
              <CustomInput
                label='Subject'
                placeholder='Enter Subject'
                labelClassName='text-xs mb-[6px]'
                value={newTemplate.subject ?? ''}
                onChange={(e) => {
                  setNewTemplate({
                    ...newTemplate,
                    subject: e.target.value,
                  });
                }}
                type='text'
                disabled={isLoading}
                containerClasses='col-span-2'
              />
            }

            <div className='col-span-2 flex flex-col gap-2'>
              <div className='w-full flex justify-between items-center min-h-[38px]'>
                <p
                  className='m-0 text-softBlack70 text-xs font-light uppercase'
                  style={{ letterSpacing: '0.16px' }}
                >
                  Content *
                </p>
                {
                  ['sms', 'email'].indexOf(newTemplate.templateType) !== -1 &&
                  <CustomButton
                    handleClick={() => setIsMergeFieldsDrawerOpen(true)}
                  >
                    <span className='font-medium py-1.5 px-3 text-base flex gap-2 items-center'>
                      Use {newTemplate.templateType} merge fields
                    </span>
                  </CustomButton>
                }
              </div>
              <RichTextField
                isHideToolbar={true}
                hasFileAttachments={false}
                editorRef={editorRef}
                onChangeText={(newValue) => {
                  setNewTemplate({
                    ...newTemplate,
                    content: newValue,
                  });
                }}
                text={newTemplate?.content ?? ''}
                placeholder={'Type text here...'}
                height='300px'
                style={{
                  height: '300px',
                }}
                customModules={getTextToolbarOptions()}
              />
            </div>
          </div>
          <div className='flex-1 w-full mt-12 flex items-end justify-center md:justify-end gap-3'>
            <CustomButton
              variant='outline'
              handleClick={onCancel}
              disabled={isLoading}
            >
              <span className='font-medium py-2 px-4 text-base flex gap-2 items-center'>
                Cancel
              </span>
            </CustomButton>
            <CustomButton
              handleClick={onOk}
              disabled={!isValidate || isLoading}
            >
              <span className='font-medium py-2 px-4 text-base flex gap-2 items-center'>
                {isLoading && <Oval height='18' width='18' color='white' />}
                {!newTemplate?._id ? 'Create template' : 'Update Template'}
              </span>
            </CustomButton>
          </div>
        </div>
      </CustomModal>
    );
  },
)

const ConfirmDeleteCommunicationTemplateModal = memo(
  ({ showModal, onCancel, onOk, isLoading }) => (
    <CustomModal showModal={showModal} onCancel={onCancel} onOk={onOk} minHeight={false}>
      <div className='min-w-[95%] md:min-w-[400px] flex flex-col gap-4'>
        <h2 className='text-2xl mb-0'>Are you sure?</h2>
        <h4 className='text-xl mb-2'>
          Do you want to delete template?
        </h4>
        <div className='w-full mt-2 flex items-center justify-center md:justify-end gap-3'>
          <CustomButton
            variant='outline'
            handleClick={onCancel}
            disabled={isLoading}
          >
            <span className='font-medium py-2 px-4 text-base flex gap-2 items-center'>
              Cancel
            </span>
          </CustomButton>
          <CustomButton handleClick={onOk} disabled={isLoading}>
            <span className='font-medium py-2 px-4 text-base flex gap-2 items-center'>
              {isLoading && <Oval height='18' width='18' color='white' />}
              Yes, delete it.
            </span>
          </CustomButton>
        </div>
      </div>
    </CustomModal>
  ),
)

export const PageLoader = () => {
  return (
    <div className='w-full h-[calc(100vh-164px)] flex flex-col items-center justify-center'>
      <div
        role='presentation'
        style={{
          height: 150,
          width: 150,
          margin: '0 auto',
        }}
      >
        <LottieWrapper
          animation={successAnimation}
          autoplay
          loop={true}
          className='-mt-40 md:mt-0 max-w-sm'
        />
      </div>
      <h1 className='mt-4 text-[16px] truncate text-center'>Loading...</h1>
    </div>
  )
}

const ProjectCommunicationTemplates = () => {
  const [isNewCommunicationTemplateModalOpen, setIsNewCommunicationTemplateModalOpen] =
    useState(false)
  const [newTemplate, setNewTemplate] = useState({
    title: '',
    audience: '',
    category: '',
    content: '',
    templateType: '',
    subject: '',
  })
  const [templates, setTemplates] = useState([])
  const [loading, setLoading] = useState(true)
  const [isCreating, setIsCreating] = useState(false)
  const projectId = useSelector((state) => state.appReducer.appProject)
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] =
    useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [selectedTemplate, setSelectedTemplate] = useState()
  const [isMergeFieldsDrawerOpen, setIsMergeFieldsDrawerOpen] = useState(false)

  const getCommunicationTemplates = () => {
    setLoading(true)
    getCommunicationTemplate(projectId)
      .then((templates) => {
        setTemplates(templates)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        console.error('Error:', error)
      })
  }

  useEffect(() => {
    getCommunicationTemplates()
  }, [])

  const closeCommunicationTemplateModal = () => {
    setIsNewCommunicationTemplateModalOpen(false)
    setSelectedTemplate()
    setNewTemplate({
      title: '',
      audience: '',
      category: '',
      content: '',
      templateType: '',
      subject: '',
    });
  }

  const handleTemplateCreate = (e) => {
    setIsCreating(true)
    let params = {
      ...newTemplate,
      project: projectId,
    }
    createOrUpdateCommunicationTemplate(params)
      .then((data) => {
        closeCommunicationTemplateModal();
        setIsCreating(false);
        getCommunicationTemplates();
      })
      .catch((error) => {
        console.error('Error:', error);
        closeCommunicationTemplateModal();
        setIsCreating(false);
      })
  }

  const handleCommunicationTemplateDelete = (_id) => {
    setIsDeleting(true)
    deleteCommunicationTemplate(_id)
      .then(() => {
        setSelectedTemplate()
        setIsDeleting(false)
        getCommunicationTemplates()
        setIsConfirmDeleteModalOpen(false)
      })
      .catch(() => {
        setSelectedTemplate()
        setIsDeleting(false)
        setIsConfirmDeleteModalOpen(false)
      }).finally(() => {
        setNewTemplate({
          title: '',
          audience: '',
          category: '',
          content: '',
          templateType: '',
          subject: '',
        });
      })
  }

  const ActionButton = ({ onClick, openEditModal }) => {
    return (
      <div className='flex flex-row gap-2 items-center'>
        <CustomButton handleClick={openEditModal}>
          <span className='font-normal py-1.5 px-4 text-sm text-center flex items-center gap-2'>
            <Eye className='w-4 h-4' />
            <span className='text-sm'>View</span>
          </span>
        </CustomButton>
        <CustomButton variant='outline' handleClick={onClick}>
          <span className='font-normal py-1.5 px-4 text-sm text-center flex items-center gap-2'>
            <Trash className='w-4 h-4' />
            <span className='text-sm'>Delete</span>
          </span>
        </CustomButton>
      </div>
    )
  }

  const tableColumns = useMemo(
    () => [
      {
        id: 'title',
        label: 'Title',
        Title: (<span className='leading-none text-sm font-normal text-[#2E2B2E] text-opacity-70'>Title</span>),
        accessor: 'title',
        dataIndex: 'title',
        isSort: true,
        sortingType: 'string',
        Cell: (props) => <p className='mb-0 text-base font-normal capitalize'>{props.cell.value}</p>
      },
      {
        id: 'templateType',
        label: 'Type',
        Title: (<span className='leading-none text-sm font-normal text-[#2E2B2E] text-opacity-70'>Type</span>),
        accessor: 'templateType',
        dataIndex: 'templateType',
        isSort: true,
        sortingType: 'string',
        Cell: (props) => <p className='mb-0 text-base font-normal capitalize'>{props.cell.value}</p>
      },
      {
        id: 'audience',
        label: 'Audience',
        Title: (<span className='leading-none text-sm font-normal text-[#2E2B2E] text-opacity-70'>Audience</span>),
        accessor: 'audience',
        dataIndex: 'audience',
        isSort: true,
        sortingType: 'string',
        Cell: (props) => <p className='max-w-[130px] truncate mb-0 text-base font-normal'>{props.cell.value}</p>
      },
      {
        id: 'category',
        label: 'Category',
        Title: (<span className='leading-none text-sm font-normal text-[#2E2B2E] text-opacity-70'>Category</span>),
        accessor: 'category',
        dataIndex: 'category',
        isSort: true,
        sortingType: 'string',
        Cell: (props) => <p className='max-w-[130px] truncate mb-0 text-base font-normal'>{props.cell.value}</p>
      },
      {
        id: 'actionBtn',
        label: 'Action button',
        Title: '',
        accessor: '_id',
        isSort: false,
        Cell: (props) => (
          <div className='flex flex-row gap-2 text-center items-center justify-between'>
            <ActionButton
              text={props.cell.value}
              value={props.cell}
              onClick={() => {
                setSelectedTemplate(props.row.original);
                setIsConfirmDeleteModalOpen(true);
              }}
              openEditModal={() => {
                // openEditModal({ id: props.row.original._id })
                setNewTemplate({
                  ...props.row.original,
                })
                setSelectedTemplate(props.row.original);
                setIsNewCommunicationTemplateModalOpen(true)
              }
              }
            />
            {/* <EditButton
              id={props.row.original._id}
              status={props.row.original.status}
            /> */}
          </div>
        )
      }
    ], [])

  const handleNewCommunicationTemplateModalOpen = () => {
    setSelectedTemplate()
    setNewTemplate({
      title: '',
      audience: '',
      category: '',
      content: '',
      templateType: ''
    });
    setIsNewCommunicationTemplateModalOpen(true)
  }

  return (
    <>
      <NewCommunicationTemplateModal
        showModal={isNewCommunicationTemplateModalOpen}
        onCancel={closeCommunicationTemplateModal}
        onOk={handleTemplateCreate}
        newTemplate={newTemplate}
        setNewTemplate={setNewTemplate}
        isLoading={isCreating}
        setIsMergeFieldsDrawerOpen={setIsMergeFieldsDrawerOpen}
      />
      <ConfirmDeleteCommunicationTemplateModal
        showModal={isConfirmDeleteModalOpen}
        onCancel={() => {
          setSelectedTemplate();
          setIsConfirmDeleteModalOpen(false);
        }}
        onOk={() => handleCommunicationTemplateDelete(selectedTemplate._id)}
        isLoading={isDeleting}
      />
      <PageHeader
        className='md:h-[74px]'
        title='Communication Templates'
        showImage={false}
        rightContent={
          <RightContent
            handleNewCommunicationTemplateModalOpen={
              handleNewCommunicationTemplateModalOpen
            }
          />
        }
      />
      <div className='mx-auto px-4 sm:px-6 lg:px-8 min-h-[calc(100%-100px)]'>
        {loading ? (
          <PageLoader />
        ) : templates.length === 0 ? (
          <div className='h-[calc(100vh-164px)] w-full'>
            <div className='h-full hero-content text-center'>
              <div className='h-full w-full flex flex-col justify-center items-center gap-4'>
                <h2 className='text-3xl font-bold mb-0'>No templates found</h2>
                <p className='mb-0'>
                  Unfortunately, there are no templates available at the moment.
                </p>
                <CreateNewCommunicationTemplateButton
                  handleNewCommunicationTemplateModalOpen={
                    handleNewCommunicationTemplateModalOpen
                  }
                />
              </div>
            </div>
          </div>
        ) : (
          <div className='py-5 w-full'>
            <MemoTable
              dataSource={templates}
              columns={tableColumns}
              loading={loading}
              idBeingDeleted={false}
              deleting={false}
              showTablePagination={true}
            />
          </div>
        )}
      </div>
      {
        ['sms', 'email'].indexOf(newTemplate.templateType) !== -1 &&
        <MergeFieldsDrawer
          type={newTemplate.templateType === 'sms' ? 'smsTemplate' : newTemplate.templateType === 'email' ? 'emailTemplate' : ''}
          open={isMergeFieldsDrawerOpen}
          onClose={() => setIsMergeFieldsDrawerOpen(false)}
        />
      }
    </>
  );
}

export default ProjectCommunicationTemplates
