import React from 'react'
import { ReactComponent as ExternalLink } from 'assets/icons/external.svg'
import { Avatar } from 'antd'

const Row = ({ id, data, showIndex }) => {
  const date = new Date(data.expirationDate).toDateString()
  return (
    <tr className="group" key={data.link}>
      {showIndex && (
        <td className="text-softBlack text-base font-medium font-openSans py-3">
          {id + 1}
        </td>
      )}
      <td className="text-softBlack text-base font-medium font-openSans py-3">
        {data.title}
      </td>
      <td className="text-softBlack text-base font-medium font-openSans py-3">
        <Avatar.Group
          maxCount={4}
          maxPopoverTrigger="click"
          size={42}
          maxStyle={{
            color: '#2E2B2E',
            backgroundColor: '#F7F7F9',
            cursor: 'pointer',
            marginLeft: -15
          }}
          className="flex"
        >
          {data.brokers.map((broker, index) => (
            <Avatar key={index} style={{ marginLeft: index > 0 && -15 }} src={broker} />
          ))}
        </Avatar.Group>
      </td>
      <td className="text-softBlack text-base font-medium font-openSans py-3 flex gap-4 items-center">
        <div className="flex" style={{ width: 160 }}>
          <div
            className="bg-volt rounded-l"
            style={{ width: `${data.percentageSold}%`, height: 32 }}
          ></div>
          <div
            className="bg-softBlack30 rounded-r"
            style={{ width: `${100 - data.percentageSold}%`, height: 32 }}
          ></div>
        </div>
        <div className='text-softBlack text-base font-medium font-openSans'>{data.percentageSold}%</div>
      </td>
      <td className="text-softBlack text-base font-medium font-openSans py-3">
        {date}
      </td>
      <td className="text-softBlack text-base font-medium font-openSans py-3 opacity-0 group-hover:opacity-100">
        <a
          target="_blank"
          href={data.link}
          className="flex justify-end p-0 items-center"
          rel="noreferrer"
        >
          <ExternalLink />
        </a>
      </td>
    </tr>
  )
}

export default Row
