/* eslint-disable no-unused-vars */
import { Select, Checkbox } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { ReactComponent as DownIcon } from 'assets/icons/down.svg'
import { toast } from 'react-hot-toast'
import { useSelector } from 'react-redux'
import { classNames } from 'utils'
import CustomInput from 'components/CustomInput'
import moment from 'moment'
import useGetUsers from 'hooks/useGetUsers'
import CustomButton from 'components/CustomButton'

const RecordPayment = ({
  setStep,
  reservation,
  createOrUpdateReservation,
  selectedReservationHolders,
  refetchReservationDetails
}) => {
  const appProjectSelector = (state) => state.appReducer.appProject
  const projectId = useSelector(appProjectSelector)
  const userIdSelector = (state) => state.authReducer.userObject?._id
  const userId = useSelector(userIdSelector)
  const { users, isLoading } = useGetUsers()
  const [paymentStatus, setPaymentStatus] = useState(reservation?.paymentStatus)
  const [paymentReceivedBy, setPaymentReceivedBy] = useState(
    reservation?.paymentReceivedBy
  )
  const [paymentReceivedDate, setPaymentReceivedDate] = useState(
    moment(reservation?.paymentReceivedDate).format('YYYY-MM-DDTHH:mm')
  )

  const [paymentConsent, setPaymentConsent] = useState(
    reservation?.paymentConsent ?? true
  )
  const [isUpdateInProgress, setIsUpdateInProgress] = useState(false)
  const [filteredUsers, setFilteredUsers] = useState('')
  const PAYMENT_STATUSES = ['Pending', 'Complete']
  const [paymentAmount, setPaymentAmount] = useState(null)

  useEffect(() => {
    if (reservation) {
      setPaymentStatus(reservation?.paymentStatus)
      setPaymentReceivedBy(reservation?.paymentReceivedBy)
      setPaymentReceivedDate(
        moment(reservation?.paymentReceivedDate).format('YYYY-MM-DDTHH:mm')
      )
      setPaymentConsent(reservation?.paymentConsent ?? true)
      setPaymentAmount(reservation?.paymentAmount ?? null)
    }
  }, [reservation])

  useEffect(() => {
    if (selectedReservationHolders.length > 0 && users) {
      const tempUsers = users?.filter(
        (user) => selectedReservationHolders?.includes(user?._id)
      ).map((user) => {
        return (user.firstName && user.lastName) ? user.firstName + ' ' + user.lastName : ''
      }).filter((e) => e).join(', ')
      setFilteredUsers(tempUsers)
    }
  }, [selectedReservationHolders, users])

  const updateDetails = useCallback(async () => {
    if (
      paymentStatus?.length &&
      paymentReceivedDate &&
      paymentReceivedBy?.length &&
      paymentConsent &&
      paymentAmount
    ) {
      const toastId = toast.loading('Updating Details...')

      setIsUpdateInProgress(true)
      try {
        const dataObject = {
          _id: reservation?._id,
          paymentStatus,
          paymentReceivedDate,
          paymentReceivedBy,
          paymentConsent,
          paymentAmount
        }
        await createOrUpdateReservation(projectId, dataObject)
        await refetchReservationDetails?.()
        toast.dismiss(toastId)
        toast.success('Details updated successfully')
      } catch {
        toast.dismiss(toastId)
      }
      setIsUpdateInProgress(false)
      setStep(2)
    } else {
      toast.error('Please fill fields...')
    }
  }, [
    projectId,
    reservation,
    createOrUpdateReservation,
    paymentConsent,
    paymentReceivedBy,
    paymentStatus,
    paymentReceivedDate,
    paymentAmount
  ])

  return (
    <>
      <div
        className={classNames(
          'p-3 text-lg font-bold grid gap-x-12 gap-y-4 h-full w-full grid-cols-1',
          isUpdateInProgress && 'opacity-30'
        )}
      >
        <div className='flex flex-col gap-4 md:gap-10 justify-center items-center rounded-3xl md:p-4'>
          <div className='flex justify-between items-center w-full md:w-4/5 md:pt-5'>
            <h1 className='text-2xl md:text-4xl text-italic mb-0 md:mb-4'>Record Payment</h1>
            <CustomButton variant='outline' handleClick={() => setStep(2)}>
              <span className='font-medium py-2 px-4 text-base md:text-lg flex gap-1 items-center'>
                Exit
              </span>
            </CustomButton>
          </div>
          <div className='flex flex-col gap-3 w-full items-center'>
            <div className='flex flex-col w-full md:w-4/5 items-start justify-start gap-2'>
              <div className='font-normal'>Payment Amount</div>
              <div className='w-full'>
                <CustomInput
                  label=''
                  placeholder='Enter payment amount'
                  value={paymentAmount}
                  onChange={(e) => setPaymentAmount(Number(e.target.value))}
                  type='number'
                  containerClasses={undefined}
                  classes=''
                  required
                />
              </div>
            </div>
            <div className='flex flex-col w-full md:w-4/5 items-start justify-start gap-2'>
              <div className='font-normal'>Payment Status</div>
              <div className='w-full'>
                <Select
                  suffixIcon={<DownIcon />}
                  showArrow
                  placeholder='Select'
                  value={paymentStatus}
                  onChange={(value) => {
                    setPaymentStatus(value)
                  }}
                  style={{ height: '40px', width: '100%' }}
                  className='rating-select flex align-center w-full text-sm p-2 bg-white border border-softBlack30 h-12 rounded text-softBlack placeholder-softBlack70'
                >
                  {PAYMENT_STATUSES.map?.((_item, index) => (
                    <Select.Option value={_item} key={index}>
                      <div
                        className='flex gap-2 items-center'
                        style={{ zIndex: '1500 !important' }}
                      >
                        <span>{_item}</span>
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
            <div className='flex flex-col w-full md:w-4/5 items-start justify-start gap-2'>
              <div className='font-normal'>RECEIVED BY</div>
              <div className='w-full'>
                <CustomInput
                  label=''
                  placeholder='Enter name of reciever'
                  value={paymentReceivedBy}
                  onChange={(e) => {
                    setPaymentReceivedBy(e.target.value)
                  }}
                  type='text'
                  containerClasses={undefined}
                  classes=''
                  required
                />
              </div>
            </div>
            <div className='flex flex-col w-full md:w-4/5 items-start justify-start gap-2'>
              <div className='font-normal'>DATE RECEIVED</div>
              <div className='w-full'>
                <CustomInput
                  label=''
                  value={paymentReceivedDate}
                  onChange={(e) => {
                    setPaymentReceivedDate(
                      moment(e.target.value).format('YYYY-MM-DDTHH:mm')
                    )
                  }}
                  type='datetime-local'
                  classes='uppercase'
                  min={moment().format('YYYY-MM-DD')}
                />
              </div>
            </div>
            <div className='flex items-start justify-start w-full md:w-4/5'>
              <Checkbox
                className='font-normal'
                checked={true}
                onChange={(e) => {
                  setPaymentConsent(true)
                }}
              >
                {`I confirm that I have received payment from ${filteredUsers}`}
              </Checkbox>
            </div>
            <div className='w-full md:w-4/5 flex justify-end'>
              <CustomButton className='hover:bg-zinc-300' handleClick={updateDetails}>
                <span className='font-medium py-2 px-4 text-base md:text-lg flex gap-1 items-center'>
                  Save Payment
                </span>
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default RecordPayment
