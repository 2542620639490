/* eslint-disable */
// @ts-nocheck
import React, { useEffect, useMemo, useState } from 'react';
import { getParkingAllocationTypeHeatMap } from 'store/actions/parkingActions';
import styled from 'styled-components';
import * as d3 from 'd3-hierarchy';
const Container = styled.div`
  height: 100%;
  width: 100%;
  background: white;
  position: relative;
  display: flex;
  gap: 16px;
`;
const Card = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ textColor }) => textColor};
  text-align: center;
  box-sizing: border-box;
  overflow: hidden;
  border: 1px solid white;
  aspect-ratio: 1;
  border-radius: 7px;
  font-weight: 500;
  flex-wrap: wrap;
`;
const gap = 2;
export const ParkingAllocaitonHeatMap = ({ selectedBuilding }) => {
  const [parkingData, setParkingData] = useState([]);
  const [blockPositions, setBlockPositions] = useState([]);
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const handleParkingTypeHeatMap = async () => {
      let data = await getParkingAllocationTypeHeatMap(selectedBuilding);
      setParkingData(data);
    };
    handleParkingTypeHeatMap();
  }, []);
  const totalCount = parkingData.reduce((sum, item) => sum + item.count, 0);
  const percentages = parkingData.map((item) => ({
    ...item,
    allocationType: item.allocationType,
    percentage: (item.count / totalCount) * 100,
    color: item.color,
  }));

  useEffect(() => {
    const calculatePositions = () => {
      const containerWidth =
        document.getElementById('heatmap-container').clientWidth;
      const containerHeight =
        document.getElementById('heatmap-container').clientHeight;
      setContainerSize({ width: containerWidth, height: containerHeight });
      const totalArea = containerWidth * containerHeight;
      const root = {
        name: 'root',
        children: percentages.map((item) => ({
          name: item.allocationType + ' ' + '(' + item.count + ')',
          value: item.percentage,
          color: item.color,
        })),
      };

      const treemap = d3
        .treemap()
        .size([containerWidth, containerHeight])
        .padding(8)
        .round(true);

      const hierarchyRoot = d3
        .hierarchy(root)
        .sum((d) => d.value)
        .sort((a, b) => b.value - a.value);

      treemap(hierarchyRoot);

      const positions = hierarchyRoot.leaves().map((leaf) => ({
        type: leaf.data.name,
        x0: leaf.x0,
        y0: leaf.y0,
        x1: leaf.x1,
        y1: leaf.y1,
        color: leaf.data.color,
      }));
      setBlockPositions(positions);
    };
    calculatePositions();
    window.addEventListener('resize', calculatePositions);

    return () => {
      window.removeEventListener('resize', calculatePositions);
    };
  }, [parkingData]);
  return (
    <Container id='heatmap-container'>
      {blockPositions.map((block, index) => (
        <Card
          key={index}
          bgColor={block?.color?.bgColor || 'white'}
          textColor={block?.color?.textColor || 'black'}
          style={{
            left: `${(block.x0 / containerSize.width) * 100}%`,
            top: `${(block.y0 / containerSize.height) * 100}%`,
            width: `${((block.x1 - block.x0) / containerSize.width) * 100}%`,
            height: `${((block.y1 - block.y0) / containerSize.height) * 100}%`,
          }}
        >
          {block.type}
        </Card>
      ))}
    </Container>
  );
};
