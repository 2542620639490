// @ts-nocheck
import React, { useEffect, useState } from 'react'
import BuyerInfo from '../brokerCreateOpportunitiesScreen/CreatePage/BuyerInfo/BuyerInfo'
import { Formik } from 'formik'
import Form from '../brokerCreateOpportunitiesScreen/CreatePage/Form/Form'
import { createOrUpdateOpportunity, getBuyers, getOpportunityById } from 'store/actions/usersActions'
import { useParams, useHistory } from 'react-router-dom'
import { FadedAnimatedDiv, LottieWrapper } from 'components'
import tableLoading from 'assets/lottieJsons/tableLoading.json'
import { BROKER_OPPORTUNITIES_PAGE } from 'routes'

const EditPage = () => {
  const { id } = useParams()
  const history = useHistory()
  const [initialValues, setInitialValues] = useState({})
  const [selectedBuyers, setSelectedBuyers] = useState(null)
  const onSubmit = (values) => {
    createOrUpdateOpportunity(values).then(() => history.push(BROKER_OPPORTUNITIES_PAGE))
  }

  useEffect(() => {
    if (id) {
      getOpportunityById(id).then((data) => setInitialValues(data))
    }
  }, [])

  useEffect(() => {
    if (initialValues.user) {
      getBuyers().then((res) => {
        setSelectedBuyers(res.filter((elm) => elm._id === initialValues.user)[0])
      })
    }
  }, [initialValues])

  return (
    <>
      {initialValues._id
        ? <Formik
          initialValues={{
            _id: initialValues._id,
            name: initialValues.name,
            user: initialValues.user,
            rating: initialValues.rating,
            stage: initialValues.stage,
            floorPlansInterest: initialValues.floorPlansInterest,
            floorNumbersInterest: initialValues.floorNumbersInterest,
            numberOfBedrooms: initialValues.numberOfBedrooms,
            firstChoiceUnit: initialValues.firstChoiceUnit,
            secondChoiceUnit: initialValues.secondChoiceUnit,
            thirdChoiceUnit: initialValues.thirdChoiceUnit,
            minimumSquareFeet: initialValues.minimumSquareFeet,
            maximumSquareFeet: initialValues.maximumSquareFeet,
            budget: initialValues.budget,
            orientation: initialValues.orientation,
            primaryUse: initialValues.primaryUse,
            needParking: initialValues.needParking,
            numberOfParkingSpots: initialValues.numberOfParkingSpots,
            upgrades: initialValues.upgrades,
            needsFinancing: initialValues.needsFinancing,
            notes: initialValues.notes,
            project: initialValues.project,
            salesRep: initialValues.salesRep
          }}
          onSubmit={onSubmit}
        >
          {() =>
            <div style={{ height: '95vh' }} className='flex flex-col px-14 py-4'>
              <div className='w-full flex justify-between items-center'>
                <h3 className='text-4xl font-bold ml-2 mb-0'>Update Opportunity</h3>
              </div>
              <div className='flex flex-row w-full h-full pt-4 rounded overflow-hidden'>
                <div className='h-full flex flex-col'>
                  <div className='h-full flex justify-between items-start relative'>
                    {selectedBuyers
                      ? <BuyerInfo contact={selectedBuyers} />
                      : <>
                        <FadedAnimatedDiv
                          key='loadingTable'
                          className='w-[350px] h-full flex items-center justify-center flex-col bg-white'
                        >
                          <LottieWrapper
                            animation={tableLoading}
                            loop
                            className='h-16 w-16'
                          />
                          <span className='text-base'>loading...</span>
                        </FadedAnimatedDiv>
                      </>
                    }
                  </div>
                </div>
                <Form isEdit selectedBuyers={selectedBuyers} />
              </div>
            </div>
          }
        </Formik>
        : <FadedAnimatedDiv
          key='loadingTable'
          className='w-full h-full flex items-center justify-center my-10 flex-col'
        >
          <LottieWrapper
            animation={tableLoading}
            loop
            className='h-16 w-16'
          />
          <span className='text-base'>loading...</span>
        </FadedAnimatedDiv>
      }
    </>
  )
}

export default EditPage
