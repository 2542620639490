/* eslint-disable */
// @ts-nocheck
import React, { useCallback, useEffect, useState } from "react";
import propTypes from "prop-types";
import {
  AdminPageWrapper,
  LottieWrapper,
  PageHeader,
  ProjectOffers,
} from "components";
import { TFilledPlusIcon } from "components/Icons";
import { useHistory } from "react-router-dom";
import { ADMIN_OFFER } from "routes";
import { connect, useSelector } from "react-redux";
import { Button } from "components/ShadcnComponents/button";
import { AiFillCaretUp } from "react-icons/ai";
import loadingColorScheme from "assets/lottieJsons/tableLoading.json";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/ShadcnComponents/table";
import { getProjectUnits } from "store/actions/unitActions";
import moment from "moment-timezone";
import { getFilteredDeals } from "store/actions/dealAction";
import { optional } from "glamor";

export const OffersV2Table = (props) => {
  const project = useSelector((state) => state.appReducer.appProject);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const tabMenus = [
    {
      id: "tab-1",
      name: "Leads",
      permission: "Leads",
    },
    {
      id: "tab-2",
      name: "Sales Activity",
      permission: "Sales Activity",
    },
    {
      id: "tab-3",
      name: "Reservations",
      permission: "Reservations",
    },
    {
      id: "tab-4",
      name: "Communications",
      permission: "Communications",
    },
    {
      id: "tab-5",
      name: "Parking",
      permission: "Parking",
    },
  ];
  const [selectedTab, setSelectedTab] = useState("tab-1");

  const TabWrapper = ({ tabMenus, setSelectedTab, selectedTab }) => (
    <div className="w-full flex items-center md:justify-end rounded-lg p-1 text-zinc-600">
      {tabMenus.map((tab, index) => (
        <div
          key={index}
          onClick={() => {
            setSelectedTab(tab.id);
          }}
          className={`flex flex-1 w-full min-w-[110px] text-end md:w-fit md:min-w-[140px] max-w-[160px] justify-center items-center just px-2 py-[6px] cursor-pointer rounded-[4px] font-semibold text-[14px] ${
            selectedTab === tab.id
              ? "bg-[#18181B] text-white shadow-sm"
              : "text-zinc-600 hover:bg-zinc-100"
          } transition-all`}
        >
          {tab.name}
        </div>
      ))}
    </div>
  );

  const ContractsDetailsHeader = () => {
    const typesOfContracts = [
      {
        id: "pendingSignature",
        name: "Pending signature",
        color: "#EDDF65",
        number: 105,
      },
      {
        id: "pendingApproval",
        name: "Pending approval",
        color: "#6D6B6D",
        number: 47,
      },
      {
        id: "recision",
        name: "Recision",
        color: "#C1C0C1",
        number: 23,
      },
      {
        id: "soldFirm",
        name: "Sold firm",
        color: "#2E2B2E",
        number: 14,
      },
    ];
    return (
      <div className="w-full bg-white border  justify-between border-[#E4E4E7] shadow-sm rounded-xl p-6 flex items-center gap-x-10">
        <div>
          <h5 className="text-base font-semibold mb-0">Total offers</h5>
          <h2 className="text-[28px] leading-[28px] font-bold my-4">
            211 units
          </h2>
          <div className="w-fit rounded-md bg-[#D6FABB] flex gap-1 items-center py-1 px-2">
            <AiFillCaretUp className="text-[#44AC2A]" />
            <p className="text-xs font-semibold mb-0">1.6%</p>
          </div>
        </div>
        <div>
          <svg
            width="420"
            height="28"
            viewBox="0 0 420 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_52_918)">
              <mask id="path-2-inside-1_52_918" fill="white">
                <path d="M0 0H185V28H0V0Z" />
              </mask>
              <path d="M0 0H185V28H0V0Z" fill="#EDDF65" />
              <path
                d="M182 0V28H188V0H182Z"
                fill="white"
                mask="url(#path-2-inside-1_52_918)"
              />
              <mask id="path-4-inside-2_52_918" fill="white">
                <path d="M185 0H294V28H185V0Z" />
              </mask>
              <path d="M185 0H294V28H185V0Z" fill="#6D6B6D" />
              <path
                d="M291 0V28H297V0H291Z"
                fill="white"
                mask="url(#path-4-inside-2_52_918)"
              />
              <mask id="path-6-inside-3_52_918" fill="white">
                <path d="M294 0H373V28H294V0Z" />
              </mask>
              <path d="M294 0H373V28H294V0Z" fill="#C1C0C1" />
              <path
                d="M370 0V28H376V0H370Z"
                fill="white"
                mask="url(#path-6-inside-3_52_918)"
              />
              <rect x="373" width="47" height="28" fill="#2E2B2E" />
            </g>
            <defs>
              <clipPath id="clip0_52_918">
                <rect width="420" height="28" rx="6" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div className="flex items-center gap-y-4 flex-wrap">
          {typesOfContracts.map((type, index) => (
            <div className="flex flex-wrap ">
              <div className="h-14 w-px bg-zinc-200 mx-4" />
              <div className="flex flex-col gap-y-1">
                <div className="flex items-start gap-2">
                  <div
                    className="w-3 h-3 rounded-full mt-1"
                    style={{ backgroundColor: type.color }}
                  />
                  <h6 className="text-sm mb-0 font-medium flex-1 text-zinc-600">
                    {type.name}
                  </h6>
                </div>
                <p className="text-xl mb-0 font-bold">{type.number}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const [units, setUnits] = useState([]);
  const [avesdoData, setAvesdoData] = useState([]);

  useEffect(() => {
    let data = getFilteredDeals().then((data) => {
      setAvesdoData(data);
    });
  }, []);

  useEffect(() => {
    getProjectUnits(project).then((data) => {
      let mapped = data.map((unit) => {
        const matchedUnit = avesdoData.find(
          (avesdoUnit) =>
            String(avesdoUnit.unitNumber) === String(unit?.unitNumber)
        );

        const formatDate = (date) =>
          date ? moment(date).format("MM-DD-YYYY") : "";

        const getOptionValue = (fieldName, optionName) => {
          const option = matchedUnit?.options?.find(
            (opt) => opt.label === optionName
          );
          return option
            ? {
                [`${fieldName
                  .toLowerCase()
                  .replace(/[^a-z0-9]/g, "")}_${optionName
                  .toLowerCase()
                  .replace(/[^a-z0-9]/g, "")}`]: "Yes",
                [`${fieldName
                  .toLowerCase()
                  .replace(/[^a-z0-9]/g, "")}_${optionName
                  .toLowerCase()
                  .replace(/[^a-z0-9]/g, "")}price`]: option.price || 0,
              }
            : {
                [`${fieldName
                  .toLowerCase()
                  .replace(/[^a-z0-9]/g, "")}_${optionName
                  .toLowerCase()
                  .replace(/[^a-z0-9]/g, "")}`]: "",
                [`${fieldName
                  .toLowerCase()
                  .replace(/[^a-z0-9]/g, "")}_${optionName
                  .toLowerCase()
                  .replace(/[^a-z0-9]/g, "")}price`]: undefined,
              };
        };

        return {
          _stratalot: unit?.strataLotNumber,
          _unit: unit?.unitNumber,
          _status: unit?.status,
          listing_orientation: unit?.orientation,
          listing_civicaddress: unit?.fullAddress,
          listing_area: unit?.area,
          listing_outdoorarea: unit?.exteriorArea,
          listing_floorplan: unit?.floorPlan?.name,
          listing_beds: unit?.numberOfBedrooms,
          listing_den: unit?.numberOfDens,
          listing_flex: unit?.numberOfFlex,
          listing_bath: unit?.numberOfBathrooms,
          listing_halfbath: unit?.numberOfHalfBathrooms,
          dates_offer: unit?.offerDate,
          dates_accepted: unit?.acceptedDate,
          "dates_firm(rescissionperiodenddate)": formatDate(
            unit?.reservedUntil
          ),
          dates_estimatedcompletion: formatDate(unit?.estimatedCompletionDate),
          dates_actualcompletion: formatDate(unit?.actualCompletionDate),
          "dates_completion(setfromcontracts)": formatDate(
            unit?.completionDate
          ),
          dates_possession: formatDate(unit?.estimatedPossessionDate),
          dates_adjustment: formatDate(unit?.adjustmentDate),
          color_scheme: unit?.colorScheme?.name,
          color_schemeprice: unit?.colorScheme?.price,
          parking_included: unit?.numberParkingIncluded,
          parking_additional: unit?.numberParkingOptional,
          parking_additionalcost: unit?.numberParkingOptionalCost,
          financialinformation_listprice: unit?.listPrice,
          financialinformation_listpricesqft: unit?.listPricePerSquareFoot,
          financialinformation_benchmarkprice: unit?.benchmarkPrice,
          financialinformation_benchmarkpricesqft:
            unit?.benchmarkPricePerSquareFoot,
          financialinformation_soldprice: unit?.soldPrice,
          financialinformation_soldpricesqft: unit?.soldPricePerSquareFoot,
          financialinformation_upgradecost: unit?.upgradeCost,
          financialinformation_parkinglocker: unit?.parkingLockerCost,
          financialinformation_additionalcost: unit?.additionalCost,
          ...getOptionValue(
            "Optional Upgrades",
            "Engineered Hardwood throughout - 2 Bedroom"
          ),
          ...getOptionValue(
            "Optional Upgrades",
            "Engineered Hardwood throughout - 3 Bedroom"
          ),
          ...getOptionValue("Optional Upgrades", "Air Conditioning"),
          ...getOptionValue(
            "Parking Standard Stalls",
            "One EV Ready Parking Stall (1)"
          ),
          ...getOptionValue(
            "Parking Standard Stalls",
            "Additional Second (2) EV Ready Parking Stall"
          ),
          ...getOptionValue(
            "Parking Tandem Stalls + Vault",
            "1 EV ready parking stall included; 2nd Stall in tandem with connected 87 sq ft. Storage Vault Package Deal (tandem + storage)"
          ),
          ...getOptionValue(
            "Parking Standard + Vault",
            "One EV Ready Parking Stall (included, not a second stall) w/ connected 83 Sq.Ft. Storage Vault."
          ),
          ...getOptionValue(
            "Parking Waitlist",
            "Waitlist - Second (2) EV Ready Parking Stall"
          ),
          ...getOptionValue(
            "Lockers",
            "Storage Locker - Approximately 29 Square Feet"
          ),
          ...getOptionValue(
            "Lockers",
            "Storage Locker - Approximately 38 Square Feet"
          ),
          ...getOptionValue(
            "Lockers",
            "Storage Locker - Approximately 51 Square Feet"
          ),
        };
      });
      if (process.env.REACT_APP_AVESDO_FLAG === undefined) {
        mapped = mapped.map((unit) => {
          return Object.fromEntries(
            Object.entries(unit).filter(([key, value]) => {
              if (
                !key.startsWith("optionalupgrades_") &&
                !key.startsWith("parkingstandardstalls_") &&
                !key.startsWith("parkingtandemstallsvault_") &&
                !key.startsWith("parkingstandardvault_") &&
                !key.startsWith("parkingwaitlist_") &&
                !key.startsWith("lockers_")
              ) {
                return true;
              }
              return value && value !== "" && value !== "No" && value !== 0;
            })
          );
        });
      }
      setUnits(mapped);
      setLoading(false);
    });
  }, [project, avesdoData]);

  const data = [
    {
      stratalot: 1,
      unit: "101",
      status: "Open",
      civicaddress: "101",
      floorplan: "101",
      beds: 0,
      bath: 0,
    },
    {
      strataLot: 1,
      unit: "777",
      status: "UnderContract",
      civicAddress: "777",
      floorPlan: "101",
      beds: 0,
      bath: 0,
    },
    {
      strataLot: 1,
      unit: "999",
      status: "Open",
      civicAddress: "999",
      floorPlan: "101",
      beds: 0,
      bath: 0,
    },
    {
      strataLot: 2,
      unit: "102",
      status: "Open",
      civicAddress: "102",
      floorPlan: "101",
      beds: 0,
      bath: 0,
    },
    {
      strataLot: 2,
      unit: "333",
      status: "UnderContract",
      civicAddress: "333",
      floorPlan: "101",
      beds: 0,
      bath: 0,
    },
  ];

  let tableHeaders = [
    {
      group: "",
      color: "#eaf44d",
      columns: ["Strata Lot", "Unit", "Status"],
    },
    {
      group: "Listing",
      color: "#f09a6a",
      columns: [
        "Orientation",
        "Civic Address",
        "Area",
        "Outdoor Area",
        "Floor Plan",
        "Beds",
        "Den",
        "Flex",
        "Bath",
        "Half Bath",
      ],
    },
    {
      group: "Purchasers",
      color: "#eddf65",
      columns: [
        "Name",
        "First Name of Purchaser 1",
        "Last Name of Purchaser 1",
        "Phone",
        "Phone 2",
        "Email",
        "Email 2",
        "Address",
        "City",
        "Postal Code",
        "Residency",
        "Country of Residence",
        "ID Type",
        "ID Number",
        "Occupation",
      ],
    },
    {
      group: "Assignee",
      color: "#bce0c3",
      columns: [
        "Name",
        "First Name",
        "Last Name",
        "Phone",
        "Phone 2",
        "Email",
        "Email 2",
        "Address",
        "City",
        "Postal Code",
        "Residency",
        "Country of Residence",
        "ID Type",
        "ID Number",
        "Occupation",
      ],
    },
    {
      group: "Realtor",
      color: "#f09a6a",
      columns: [
        "Name",
        "Brokerage Name",
        "Brokerage Address",
        "Realtor City",
        "Realtor Postal Code",
        "Brokerage Phone",
        "E-mail",
        "Rate 1",
        "Rate 2",
        "Bonus",
        "Total Commission + Bonus",
      ],
    },
    {
      group: "Managing Broker",
      color: "#eaf44d",
      columns: ["Name", "Business Phone", "Managing Broker Phone", "E-mail"],
    },
    {
      group: "Sales Rep",
      color: "#bce0c3",
      columns: ["Name", "Phone", "E-mail"],
    },
    {
      group: "Dates",
      color: "#bbe6e8",
      columns: [
        "Offer",
        "Accepted",
        "Firm (Rescission Period End Date)",
        "Estimated Completion",
        "Actual Completion",
        "Completion (Set from Contracts)",
        "Possession",
        "Adjustment",
      ],
    },
    {
      group: "Color",
      color: "#e6eb8a",
      columns: ["Scheme", "Scheme Price"],
    },
    {
      group: "Parking",
      color: "#caf4a1",
      columns: ["Included", "Additional", "Additional Cost"],
    },
    {
      group: "Bicycle",
      color: "#caf4a1",
      columns: ["Included", "Additional", "Additional Cost"],
    },
    {
      group: "Financial Information",
      color: "#d8e5b5",
      columns: [
        "List Price",
        "List Price/sq ft",
        "Benchmark Price",
        "Benchmark Price/sq ft",
        "Sold Price",
        "Sold Price/sq ft",
        "Upgrade Cost",
        "Parking + Locker",
        "Additional Cost",
        "Credit",
        "Sold Price - Credit",
        "Net Price",
        "Realtor Com.",
        "Net after Com.",
        "Credit to Purchaser from Realtor Com.",
        "Assignment Fee",
      ],
    },
    {
      group: "Deposit",
      color: "#d8e5b5",
      columns: [
        "1st Deposit",
        "1st Deposit Date",
        "1st Deposit Rec.",
        "2nd Deposit",
        "2nd Deposit Date",
        "2nd Deposit Rec.",
        "3rd Deposit",
        "3rd Deposit Date",
        "3rd Deposit Rec.",
        "4th Deposit",
        "4th Deposit Date",
        "4th Deposit Rec.",
        "5th Deposit",
        "5th Deposit Date",
        "5th Deposit Rec.",
      ],
    },
    {
      group: "Optional Upgrades",
      color: "#d4e1f5",
      columns: [
        "Engineered Hardwood throughout - 2 Bedroom",
        "Engineered Hardwood throughout - 2 Bedroom Price",
        "Engineered Hardwood throughout - 3 Bedroom",
        "Engineered Hardwood throughout - 3 Bedroom Price",
        "Air Conditioning",
        "Air Conditioning Price",
      ],
    },
    {
      group: "Parking Standard Stalls",
      color: "#fae7d2",
      columns: [
        "One EV Ready Parking Stall (1)",
        "One EV Ready Parking Stall (1) Price",
        "Additional Second (2) EV Ready Parking Stall",
        "Additional Second (2) EV Ready Parking Stall Price",
      ],
    },
    {
      group: "Parking Tandem Stalls + Vault",
      color: "#fae7d2",
      columns: [
        "1 EV ready parking stall included; 2nd Stall in tandem with connected 87 sq ft. Storage Vault Package Deal (tandem + storage)",
        "1 EV ready parking stall included; 2nd Stall in tandem with connected 87 sq ft. Storage Vault Package Deal (tandem + storage) Price",
      ],
    },
    {
      group: "Parking Standard + Vault",
      color: "#fae7d2",
      columns: [
        "One EV Ready Parking Stall (included, not a second stall) w/ connected 83 Sq.Ft. Storage Vault.",
        "One EV Ready Parking Stall (included, not a second stall) w/ connected 83 Sq.Ft. Storage Vault. Price",
      ],
    },
    {
      group: "Parking Waitlist",
      color: "#fae7d2",
      columns: [
        "Waitlist - Second (2) EV Ready Parking Stall",
        "Waitlist - Second (2) EV Ready Parking Stall Price",
      ],
    },
    {
      group: "Lockers",
      color: "#d4e1f5",
      columns: [
        "Storage Locker - Approximately 29 Square Feet",
        "Storage Locker - Approximately 29 Square Feet Price",
        "Storage Locker - Approximately 38 Square Feet",
        "Storage Locker - Approximately 38 Square Feet Price",
        "Storage Locker - Approximately 51 Square Feet",
        "Storage Locker - Approximately 51 Square Feet Price",
      ],
    },
    {
      group: "Notes",
      color: "#f09a6a",
      columns: ["Deposit Notes", "Deal Notes"],
    },
  ];

  return (
    <div className="mt-4">
      {loading ? (
        <div className="flex border border-lg justify-center flex-col items-center w-full h-40">
          <LottieWrapper
            animation={loadingColorScheme}
            className="h-20 w-38"
            loop
          />
          <div>
            <p className="text-lg font-semibold text-black">Loading...</p>
          </div>
        </div>
      ) : (
        <CustomTable
          headers={
            process.env.REACT_APP_AVESDO_FLAG === undefined
              ? tableHeaders.filter(
                  (header) =>
                    !header.group.includes("Optional Upgrades") &&
                    !header.group.includes("Parking Standard Stalls") &&
                    !header.group.includes("Parking Tandem Stalls + Vault") &&
                    !header.group.includes("Parking Standard + Vault") &&
                    !header.group.includes("Parking Waitlist") &&
                    !header.group.includes("Lockers")
                )
              : tableHeaders
          }
          data={units}
        />
      )}
    </div>
  );
};

const CustomTable = ({ headers, data }) => {
  return (
    <Table className="w-full">
      <TableHeader>
        <TableRow>
          {headers.map((group, index) => (
            <TableHead
              key={index}
              className="border border-[#333333] p-2 font-bold text-black text-center"
              style={{ backgroundColor: group.color }}
              colSpan={group.columns.length}
            >
              {group.group}
            </TableHead>
          ))}
        </TableRow>
        <TableRow>
          {headers.flatMap((group, groupIndex) =>
            group.columns.map((column, columnIndex) => (
              <TableHead
                key={`${groupIndex}_${Math}`}
                className="border border-[#333333]  py-2 px-4 font-bold text-black whitespace-nowrap"
                style={{
                  backgroundColor: group.color,
                  width: "auto",
                  height: 80,
                }}
              >
                {column}
              </TableHead>
            ))
          )}
        </TableRow>
      </TableHeader>
      <TableBody>
        {data?.map((row, rowIndex) => (
          <TableRow key={rowIndex}>
            {headers.flatMap((group) =>
              group.columns.map((column, columnIndex) => {
                const key = `${group.group
                  .toLowerCase()
                  .replace(/[^a-z0-9]/g, "")}_${column
                  .toLowerCase()
                  .replace(/[^a-z0-9]/g, "")}`;
                return (
                  <TableCell
                    key={`${rowIndex}_${Math.random() * 9999}}`}
                    className="border p-2 border-[#333333]"
                  >
                    {row[key] || ""}
                  </TableCell>
                );
              })
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
