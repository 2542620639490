// @ts-nocheck
/* eslint-disable */
import { Checkbox } from 'antd'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useSelector } from 'react-redux'
import { classNames, formatMoney } from 'utils'
import CustomInput from 'components/CustomInput'
import moment from 'moment'
import CustomButton from 'components/CustomButton'
import CustomModal from 'ui/customModal'
import CustomSelect from 'pages/reservation/NewReservation/components/CustomSelect'
import { Plus } from 'lucide-react'
import { Oval } from 'react-loader-spinner'
import { createOrUpdateDeposit } from 'store/actions/depositActions'
import { Drawer, makeStyles } from '@material-ui/core'
import LargeUnitCard from 'components/LargeUnitCard'
import { getUnits } from 'store/actions/unitActions'
import UserInfoCard from 'components/UserInfoCard'
import OptionCard from 'components/OptionCard'

const RecordPaymentModal = ({
  isModalOpen,
  handleCancel,
  crrDeposit,
  refetch
}) => {
  const appProjectSelector = (state) => state.appReducer.appProject
  const projectId = useSelector(appProjectSelector)
  const [paymentStatus, setPaymentStatus] = useState(null)
  const [paymentMethodType, setPaymentMethodType] = useState(null)
  const [paymentReceivedBy, setPaymentReceivedBy] = useState(null)
  const [paymentReceivedAt, setPaymentReceivedAt] = useState(null)
  const [paymentConsent, setPaymentConsent] = useState(false)
  const [isUpdateInProgress, setIsUpdateInProgress] = useState(false)
  const [paymentAmount, setPaymentAmount] = useState(null)
  const [unit, setUnit] = useState(null)
  const PAYMENT_STATUSES = [{ label: 'Pending', value: 'Pending' }, { label: 'Received', value: 'Received' }]
  const PAYMENT_METHOD_TYPES = [{ label: 'Credit Card', value: 'Credit Card' }, { label: 'Cash', value: 'Cash' }, { label: 'Wire', value: 'Wire' }, { label: 'Check', value: 'Check' }]

  const useStyles = makeStyles((theme) => ({
    hide: {
      display: 'none'
    },
    drawer: {
      width: 'calc(100% - 78px)',
      flexShrink: 0
    },
    drawerPaper: {
      width: 'calc(100% - 78px)',
      height: '100vh',
      overflow: 'hidden',
      boxShadow: '-10px 0px 15px 0px rgba(46, 43, 46, 0.05)'
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end'
    }
  }))

  const classes = useStyles()

  useEffect(() => {
    if (crrDeposit) {
      setPaymentStatus(crrDeposit?.isReceived ? 'Received' : 'Pending')
      setPaymentReceivedBy(crrDeposit?.paymentReceivedBy)
      setPaymentReceivedAt(moment(crrDeposit?.paymentReceivedAt).format('YYYY-MM-DDTHH:mm'))
      setPaymentConsent(crrDeposit?.paymentConsent ?? true)
      setPaymentAmount(crrDeposit?.amount ?? null)
    }
  }, [crrDeposit])

  const isValid = useMemo(() => {
    return (
      paymentStatus &&
      paymentReceivedAt &&
      paymentReceivedBy?.length &&
      paymentConsent &&
      paymentMethodType?.length
    )
  }, [paymentStatus, paymentReceivedBy, paymentReceivedAt, paymentConsent, paymentMethodType])

  const updateDetails = useCallback(async () => {
    if (isValid) {
      const toastId = toast.loading('Updating Details...')

      setIsUpdateInProgress(true)
      try {
        const dataObject = {
          _id: crrDeposit?._id,
          isReceived: !!(paymentStatus === 'Received'),
          paymentReceivedAt,
          paymentReceivedBy,
          paymentMethodType
        }
        await createOrUpdateDeposit(dataObject)
        await refetch?.()
        handleCancel()
        toast.dismiss(toastId)
        toast.success('Details updated successfully')
      } catch {
        handleCancel()
        toast.dismiss(toastId)
      }
      setIsUpdateInProgress(false)
    } else {
      toast.error('Please fill fields...')
    }
  }, [
    projectId,
    crrDeposit,
    paymentConsent,
    paymentReceivedBy,
    paymentStatus,
    paymentReceivedAt,
    paymentAmount
  ])

  const _getUnit = useCallback(() => {
    getUnits(crrDeposit?.depositDetails?.offer?.unit)
      .then((units) => {
        setUnit(units)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  useEffect(() => {
    if (crrDeposit?.depositDetails?.offer?.unit?.length > 0) {
      _getUnit()
    }
  }, [crrDeposit])

  return (
    <Drawer
      open={isModalOpen}
      variant='persistent'
      anchor='right'
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <div
        className={classNames(
          'w-full text-lg font-bold grid gap-x-12 gap-y-4 h-full grid-cols-1 relative pt-[64px]',
          isUpdateInProgress && 'opacity-30'
        )}
      >
        <div className='w-full h-full grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6 justify-center items-center p-4 md:p-6 overflow-hidden'>
          <div className='col-span-1 h-full flex flex-col gap-4 md:gap-6 justify-center border rounded-xl shadow-md p-2 md:p-5 overflow-hidden'>
            <div className='flex items-center gap-3 w-full h-fit'>
              <CustomButton variant='ghost' handleClick={handleCancel}>
                <span className='font-medium p-2 text-base md:text-lg flex gap-1 items-center'>
                  <Plus className='w-6 h-6 rotate-45' />
                </span>
              </CustomButton>
              <h1 className='text-2xl md:text-4xl text-italic mb-0'>Offer details</h1>
            </div>
            <div className='flex-1 overflow-y-auto'>
              {
                unit?.unitNumber?.length > 0 &&
                <div className='pt-4 border-t'>
                  <p className='text-softBlack font-bold mb-0' style={{ fontSize: 22 }}>
                    Unit Details
                  </p>
                  <LargeUnitCard
                    imgsrc={unit?.floorPlan?.image}
                    unitName={`#${unit?.unitNumber}`}
                    noOfBedrooms={unit?.numberOfBedrooms}
                    noOfBathrooms={unit?.numberOfBathrooms}
                    balcony={unit?.balcony}
                    noOfParkingSlot={unit?.floorPlan?.parkingQty}
                    building={unit?.building?.name}
                    price={unit?.price}
                  />
                </div>
              }
              {
                crrDeposit?.depositDetails?.offer?.options?.length > 0 &&
                <div className='my-6 pt-4 border-t'>
                  {
                    crrDeposit?.depositDetails?.offer?.options?.map((option) => (
                      <OptionCard
                        key={option._id}
                        imgSrc={option?.images?.[0]}
                        unitName={option?.name}
                        description={option?.description}
                        price={option?.value}
                        additionalFields={option?.additionalFields}
                        type={option?.type}
                        isReview
                      />
                    ))
                  }
                </div>
              }
              {
                crrDeposit?.depositDetails?.offer?.buyer?.length > 0 &&
                <div className='my-6 pt-4 border-t'>
                  <p className='text-softBlack font-bold' style={{ fontSize: 22 }}>
                    Buyer
                  </p>
                  <div className='flex gap-4 flex-wrap'>
                    {crrDeposit?.depositDetails?.offer?.buyer?.map((buyer) => (
                      <UserInfoCard
                        key={buyer?._id}
                        firstName={buyer?.firstName}
                        lastName={buyer?.lastName}
                        email={buyer?.email}
                        selected={false}
                        userAvatar={buyer?.userAvatar}
                        showSelectedRing={false}
                        className={'w-72'}
                      />
                    ))}
                  </div>
                </div>
              }
              {
                crrDeposit?.depositDetails?.offer?.deposit &&
                <div className='my-6 pt-4 border-t'>
                  <p className='text-softBlack font-bold' style={{ fontSize: 22 }}>
                    Deposits
                  </p>
                  <div className='grid grid-cols-5 gap-4 mt-3'>
                    {crrDeposit?.depositDetails?.offer?.deposit?.installments?.map((item) => {
                      return (
                        <CustomInput
                          label={item.name}
                          defaultValue={`${item.percentage}`}
                          disabled
                        />
                      )
                    })}
                    <CustomInput
                      label={'DISCOUNT'}
                      defaultValue={`${crrDeposit?.depositDetails?.offer?.deposit?.discount.percentage}`}
                      disabled
                    />
                  </div>
                  <div className='grid grid-cols-5 gap-4 mt-3'>
                    {crrDeposit?.depositDetails?.offer?.deposit?.installments?.map((item) => {
                      return (
                        <CustomInput
                          label={item.name}
                          defaultValue={`${item.value}`}
                          disabled
                        />
                      )
                    })}

                    <CustomInput
                      label={'DISCOUNT'}
                      defaultValue={`${crrDeposit?.depositDetails?.offer?.deposit?.discount.value}`}
                      disabled
                    />
                  </div>
                </div>
              }
            </div>
          </div>
          <div className='col-span-1 h-full flex flex-col gap-4 md:gap-6 justify-center border rounded-xl shadow-md p-2 md:p-5'>
            <div className='flex justify-between items-center w-full'>
              <h1 className='text-2xl md:text-4xl text-italic mb-0'>Record Payment</h1>
            </div>
            <div className='flex flex-col gap-3 w-full items-center'>
              <div className='w-full flex items-center gap-2'>
                Payable Amount:
                <span>{formatMoney(paymentAmount ?? 0)}</span>
              </div>
              <CustomSelect
                options={PAYMENT_STATUSES}
                handleChange={(value) => setPaymentStatus(value)}
                value={paymentStatus}
                placeholder='Select Payment status'
                label='Payment Status *'
                className='border-none'
              />
              <CustomSelect
                options={PAYMENT_METHOD_TYPES}
                handleChange={(value) => setPaymentMethodType(value)}
                value={paymentMethodType}
                placeholder='Select payment method type'
                label='payment method type *'
                className='border-none'
              />
              <CustomInput
                label='RECEIVED BY *'
                placeholder='Enter name of receiver'
                labelClassName='text-xs'
                value={paymentReceivedBy ?? null}
                onChange={(e) => setPaymentReceivedBy(e.target.value)}
                type='text'
                required
                containerClasses='w-full'
              />
              <CustomInput
                label='DATE RECEIVED *'
                value={paymentReceivedAt}
                onChange={(e) => setPaymentReceivedAt(moment(e.target.value).format('YYYY-MM-DDTHH:mm'))}
                type='datetime-local'
                classes='uppercase'
                min={moment().format('YYYY-MM-DD')}
                containerClasses='w-full'
              />
            </div>
            <div className='flex items-start justify-start w-full'>
              <Checkbox
                className='font-normal'
                checked={paymentConsent}
                onChange={(e) => setPaymentConsent(!paymentConsent)}
              >
                {`I confirm that I have received payment from ${crrDeposit?.buyer ?? '-'}`}
              </Checkbox>
            </div>
            <div className='flex-1 w-full flex justify-end items-end'>
              <CustomButton handleClick={updateDetails} disabled={!isValid}>
                <span className='font-medium py-2 px-4 text-base md:text-lg flex gap-1 items-center'>
                  {isUpdateInProgress && <Oval height='18' width='18' color='white' />}
                  Save Payment
                </span>
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  )
}
export default RecordPaymentModal
