import React, { createContext, useEffect, useState } from 'react'
import useDynamicDropdown from 'hooks/useDynamicDropdown'
import { convertToLowerCaseString } from 'utils/converters'

export const DynamicDropdownContext = createContext({
  label: 'null',
  initialValues: null,
  setInitalValues: (val) => {},
  limit: 6,
  page: 1,
  setPage: (num) => {},
  filteredData: [],
  setFilteredData: (data) => {},
  search: '',
  setSearch: (str) => {},
  isEdit: false,
  setIsEdit: (bool) => {},
  oldName: '',
  setOldName: (name) => {},
  deleteModal: false,
  setDeleteModal: (bool) => {},
  dataToDelete: '',
  setDataToDelete: (name) => {},
  loading: null,
  update: null,
  deleteItem: null,
  updateItem: null,
  formattedData: null,
  data: null
})

const DynamicDropdownContextProvider = ({ children, itemKey }) => {
  const [initialValues, setInitalValues] = useState({ name: '' })
  const [filteredData, setFilteredData] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [limit] = useState(6)
  const [oldName, setOldName] = useState('')
  const [deleteModal, setDeleteModal] = useState(false)
  const [dataToDelete, setDataToDelete] = useState('')
  const { data, loading, update, deleteItem, updateItem, formattedData } =
    useDynamicDropdown(itemKey)

  useEffect(() => {
    setFilteredData(
      data?.values?.filter?.((dataItem) =>
        dataItem?.toLowerCase()?.includes?.(search?.toLocaleLowerCase())
      )
    )
  }, [search, data])

  return (
    <DynamicDropdownContext.Provider
      value={{
        initialValues,
        setInitalValues,
        limit,
        page,
        setPage,
        filteredData,
        setFilteredData,
        search,
        setSearch,
        isEdit,
        setIsEdit,
        oldName,
        setOldName,
        deleteModal,
        setDeleteModal,
        dataToDelete,
        setDataToDelete,
        label: convertToLowerCaseString(itemKey),
        update,
        deleteItem,
        updateItem,
        formattedData,
        loading,
        data
      }}
    >
      {children}
    </DynamicDropdownContext.Provider>
  )
}

export default DynamicDropdownContextProvider
