import React, { createContext } from 'react'
import useGetBuildings from 'hooks/useGetBuildigs'
import { CardView, ListView } from './components'
import useGetParkings from 'hooks/useGetParkings'
import ParkingInventoryMapWrapper from 'pages/desktopMode/parkingInventoryMap'

const LoadingContext = createContext(null)

const ProjectParkingsInventory = ({ viewType }) => {
  const { loading, parkings, refetch: parkingsRefetch } = useGetParkings()
  const { buildings, loading: getBuildings } = useGetBuildings()

  return (
    <LoadingContext.Provider value={null}>
      {viewType === 'cardView' && (
        <CardView
          {...{ parkings, loading, buildings, getBuildings, parkingsRefetch }}
        />
      )}
      {viewType === 'listView' && (
        <ListView
          {...{ parkings, loading, buildings, getBuildings, parkingsRefetch }}
        />
      )}
      {viewType === 'mapView' && (
        <ParkingInventoryMapWrapper />
      )}
    </LoadingContext.Provider>
  )
}

export default ProjectParkingsInventory
