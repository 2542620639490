// @ts-nocheck
/* eslint-disable */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import picture from "../../../../assets/img/buyerBackgroundImage.jpg";
import EditIcon from "../../../../assets/icons/edit.svg";
import { TFilledLocationMarkerIcon } from "components/Icons";

import { ADMIN_ADD_BUILDING, ADMIN_PROJECT_INVENTORY_SETTINGS } from "routes";

import LargeTabs from "components/LargeTabs";
import AddBlock from "components/AddBlock";
import useGetBuildings from "hooks/useGetBuildigs";
import AddFloorPlanModal from "../Forms/AddFloorPlan";
import AddUnitGroupModal from "../Forms/AddUnitGroupModal";
import Button from "components/Buttons";
import useGetFloorPlans from "hooks/useGetFloorPlans";
import { ImageUploaderBlock } from "components/ImageUploader";
import useGetUnitGroups from "hooks/useGetUnitGroups";
import CustomUnitDetail from "../components/CustomUnitDetail";
import useGetUnits from "hooks/useGetUnits";
import { deleteFloorPlan } from "store/actions/floorPlanActions";
import { deleteUnitGroup } from "store/actions/unitGroupsActions";
import { deleteUnit, downloadUnitsCSV } from "store/actions/unitActions";
import { FadedAnimatedDiv } from "components";
import Searching from "pages/contacts/Searching";
// import { AddButton } from 'components/AddBlock/styles'
import AddBulkUnits from "components/AddBulkUnits";
import CustomParkingDetail from "../components/CustomParkingDetail";
import AddParkingModal from "../components/AddParkingModal";
import AddUnitModal from "../Forms/AddUnitModal";
import AddBulkParkings from "components/AddBulkParkings";
import useGetParkings from "hooks/useGetParkings";
import {
  deleteParking,
  downloadParkingsCSV,
} from "store/actions/parkingActions";
import DeleteModal from "components/DeleteModal";
import useGetStorages from "hooks/useGetStorages";
import CustomStorageDetail from "../components/CustomStorageDetail";
import AddStorageModal from "../components/AddStorageModal";
import { deleteStorage } from "store/actions/storageActions";
import AddBulkStorages from "components/AddBulkStorages";
import demoUnitImage from "assets/img/unit-1201.png";
import AddParkingInventoryModal from "../components/AddParkingInventory";
import AddStorageInventoryModal from "../components/AddStorageInventory";
import AddOrUpdateMapInventoryModal from "../components/AddOrUpdateMapInventoryModal";
import { PencilLine, Plus } from "lucide-react";
import CustomButton from "components/CustomButton";
import AddInventoryModal from "../Forms/AddInventoryModal";
import useGetInventories from "hooks/useGetInventories";
import {
  deleteInventory,
  downloadInventoryCSV,
  getInventorySettings,
} from "store/actions/inventoryActions";
import AddBulkInventory from "components/AddBulkInventory";
import { useSelector } from "react-redux";

const AddButton = ({ onClick, children }) => (
  <CustomButton handleClick={onClick}>
    <span className="font-medium py-2 px-3 text-base flex gap-2 items-center">
      {children}
    </span>
  </CustomButton>
);

const LoadingDetails = ({ title }) => (
  <FadedAnimatedDiv className="h-full w-full flex flex-col items-center mt-20">
    <Searching title={title} />
  </FadedAnimatedDiv>
);

const NotFoundView = ({ text }) => (
  <div className="p-4 py-6 w-full flex justify-center items-center text-xl font-semibold">
    {text}
  </div>
);

const AdminEditBuilding = (props) => {
  const buildingId = props.history.location.state.id;
  const {
    loading: inventoryLoading,
    inventories,
    refetch: inventoryRefetch,
  } = useGetInventories(`building/${buildingId}`);
  const projectId = useSelector((state) => state.appReducer.appProject);
  const [inventoryTypeName, setInventoryTypeName] = useState("Inventory");

  useEffect(() => {
    fetchInventorySettings();
  }, [projectId]);

  const fetchInventorySettings = async () => {
    try {
      const settings = await getInventorySettings(projectId);
      if (settings && settings.length > 0) {
        const data = settings[0];
        if (data.type) {
          setInventoryTypeName(data.type.toUpperCase());
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const types = [
    "Floor Plans",
    "Unit Groups",
    process.env.REACT_APP_INENVETORY_SETTINGS_UI ? inventoryTypeName : "Units",
    "Parking",
    "Storages",
  ];
  const history = useHistory();

  // const { appProject: projectId } = useSelector((state) => state.appReducer);

  const {
    loading,
    buildings: { data: buildings },
  } = useGetBuildings(buildingId);
  const { loading: floorsLoading, floorPlans, refetch } = useGetFloorPlans();
  const {
    loading: unitGroupLoading,
    unitGroups,
    refetch: unitGroupsRefetch,
  } = useGetUnitGroups();
  const {
    loading: unitsLoading,
    units,
    refetch: unitsRefetch,
  } = useGetUnits(`building/${buildingId}`);

  const {
    loading: parkingsLoading,
    parkings,
    refetch: parkingsRefetch,
  } = useGetParkings(`building/${buildingId}`);

  const { loadingStorages, storages, refetchStorages } = useGetStorages();
  const [active, setActive] = useState(types[0]);

  const [showFloorModal, setFloorModal] = useState(false);
  const [showUnitGroupModal, setUnitGroupModal] = useState(false);
  const [showUnitModal, setUnitModal] = useState(false);
  const [showParkingModal, setParkingModal] = useState(false);
  const [showStorageModal, setStorageModal] = useState(false);
  const [showBulkUnitModal, setShowBulkUnitModal] = useState(false);
  const [showBulkParkingModal, setShowBulkParkingModal] = useState(false);
  const [showParkingInventoryModal, setShowParkingInventoryModal] =
    useState(false);
  const [showStorageInventoryModal, setShowStorageInventoryModal] =
    useState(false);
  const [showBulkStoragesModal, setShowBulkStoragesModal] = useState(false);
  const [modelDetails, setModelDetails] = useState({});
  const [showMapInventorySettingModal, setShowMapInventorySettingModal] =
    useState(false);
  const [selectedMapInventorySettingType, setSelectedMapInventorySettingType] =
    useState("");

  const [downloadingUnits, setDownloadingUnits] = useState(false);
  const [downloadingParkings, setDownloadingParkings] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [selectedElmForDeleting, setSelectedElmForDeleting] = useState({
    id: "",
    type: "",
  });
  const [isDeleteBtnLoading, setIsDeleteBtnLoading] = useState(false);

  const downloadUnits = useCallback(async () => {
    setDownloadingUnits(true);
    if (buildingId?.length > 0) {
      try {
        if (process.env.REACT_APP_INENVETORY_SETTINGS_UI) {
          await downloadInventoryCSV(buildingId, projectId);
        } else {
          await downloadUnitsCSV(buildingId);
        }
      } catch (e) {}
    }
    setDownloadingUnits(false);
  }, [buildingId]);

  const downloadParkings = useCallback(async () => {
    setDownloadingParkings(true);
    if (buildingId?.length > 0) {
      try {
        await downloadParkingsCSV(buildingId);
      } catch (e) {}
    }
    setDownloadingParkings(false);
  }, [buildingId]);

  const RightContent = () => (
    <div className="flex flex-row items-center">
      <CustomButton
        variant="ghost"
        handleClick={() => history.push(ADMIN_ADD_BUILDING)}
      >
        <span className="font-medium py-3 px-4 text-base flex gap-2 items-center">
          <Plus className="w-5 h-5" />
          Add Building
        </span>
      </CustomButton>
    </div>
  );

  const handleUnitEdit = (item) => {
    if (process.env.REACT_APP_INENVETORY_SETTINGS_UI) {
      setModelDetails({
        type: item.type,
        _id: item._id,
        [item.type === "lot"
          ? "lotData"
          : item.type === "townhome"
          ? "townhomeData"
          : "condoData"]: item[item.type + "Data"],
      });
    } else {
      setModelDetails({
        type: "Units",
        _id: item._id,
        unitNumber: item.unitNumber,
        fullAddress: item?.fullAddress || "",
        strataLotNumber: item?.strataLotNumber || "",
        status: item.status,
        price: item.price,
        priceCurrencyType: item.priceCurrencyType,
        floorPlan: item.floorPlan,
        floorNumber: item.floorNumber,
        numberOfBathrooms: item.numberOfBathrooms,
        numberOfBedrooms: item.numberOfBedrooms,
        balcony: item.balcony,
        unitGroup: item.unitGroup,
        lotData: item.lotData ?? {},
        images: item.images ?? [""],
      });
    }
    setUnitModal(true);
  };

  const handleParkingEdit = (item) => {
    setModelDetails({
      type: "Parking",
      ...item,
    });
    setParkingModal(true);
  };

  const handleStorageEdit = (item) => {
    setModelDetails({
      editType: "Storage",
      ...item,
    });
    setStorageModal(true);
  };

  const handleDeleteUnit = (id) => {
    setIsDeleteBtnLoading(true);
    if (process.env.REACT_APP_INENVETORY_SETTINGS_UI) {
      deleteInventory(id)
        .then(() => {
          inventoryRefetch();
          setIsDeleteBtnLoading(false);
          setIsDeleteModal(false);
          setSelectedElmForDeleting(null);
        })
        .catch((err) => {
          setIsDeleteBtnLoading(false);
          console.log(err);
        });
    } else {
      deleteUnit(id)
        .then(() => {
          unitsRefetch();
          setIsDeleteBtnLoading(false);
          setIsDeleteModal(false);
          setSelectedElmForDeleting(null);
        })
        .catch((err) => {
          setIsDeleteBtnLoading(false);
          console.log(err);
        });
    }
  };

  const handleDeleteParking = (id) => {
    setIsDeleteBtnLoading(true);
    deleteParking(id)
      .then(() => {
        parkingsRefetch();
        setIsDeleteBtnLoading(false);
        setIsDeleteModal(false);
        setSelectedElmForDeleting(null);
      })
      .catch((err) => {
        setIsDeleteBtnLoading(false);
        console.log(err);
      });
  };

  const handleDeleteStorage = (id) => {
    setIsDeleteBtnLoading(true);
    deleteStorage(id)
      .then(() => {
        refetchStorages();
        setIsDeleteBtnLoading(false);
        setIsDeleteModal(false);
        setSelectedElmForDeleting(null);
      })
      .catch((err) => {
        setIsDeleteBtnLoading(false);
        console.log(err);
      });
  };

  const handleFloorPlanSelect = (floorPlan) => {
    setModelDetails({
      type: "Floor Plans",
      _id: floorPlan._id,
      name: floorPlan.name,
      parkingQty: floorPlan.parkingQty,
      parkingType: floorPlan.parkingType,
      image: floorPlan.image,
    });
    setFloorModal(true);
  };

  const handleDeleteFloorPlan = (id) => {
    setIsDeleteBtnLoading(true);
    deleteFloorPlan(id)
      .then(() => {
        refetch();
        setIsDeleteBtnLoading(false);
        setIsDeleteModal(false);
        setSelectedElmForDeleting(null);
      })
      .catch((err) => {
        setIsDeleteBtnLoading(false);
        console.log(err);
      });
  };

  const handleDeleteUnitGroups = (id) => {
    setIsDeleteBtnLoading(true);
    deleteUnitGroup(id)
      .then(() => {
        unitGroupsRefetch();
        setIsDeleteBtnLoading(false);
        setIsDeleteModal(false);
        setSelectedElmForDeleting(null);
      })
      .catch((err) => {
        setIsDeleteBtnLoading(false);
        console.log(err);
      });
  };
  const handleUnitGroupSelect = (unitGroup) => {
    setModelDetails({
      type: "Unit Groups",
      _id: unitGroup._id,
      name: unitGroup.name,
      description: unitGroup.description,
    });
    setUnitGroupModal(true);
  };

  const renderFloorPlans = useCallback(() => {
    if (active !== "Floor Plans") {
      return null;
    }

    if (floorsLoading) {
      return <LoadingDetails title={"Loading Floor Plans"} />;
    }
    return (
      <>
        <div className="w-full flex justify-center py-8">
          <AddButton onClick={() => setFloorModal(true)}>
            <Plus className="w-5 h-5" />
            Add Floor Plan
          </AddButton>
        </div>
        {floorPlans?.length > 0 ? (
          <>
            {floorPlans?.map((floorPlan) => (
              <ImageUploaderBlock
                key={floorPlan._id}
                imgsrc={floorPlan.image}
                imgTitle={floorPlan.name}
                edit
                description={`${floorPlan.parkingQty} Parking Slot, ${floorPlan.parkingType}`}
                parking
                onUpload={() => handleFloorPlanSelect(floorPlan)}
                onDelete={() => {
                  setIsDeleteModal(true);
                  setSelectedElmForDeleting({
                    id: floorPlan._id,
                    type: "FloorPlan",
                  });
                }}
              />
            ))}
          </>
        ) : (
          <NotFoundView text="Floor plans not found" />
        )}
      </>
    );
  }, [floorsLoading, floorPlans, active]);

  const renderUnitGroups = useCallback(() => {
    if (active !== "Unit Groups") {
      return null;
    }

    if (unitGroupLoading) {
      return <LoadingDetails title="Loading unit groups" />;
    }
    return (
      <>
        {/* <AddBlock
          label={`Click to start adding ${active.toLowerCase()}`}
          btnText={`Add ${active.slice(0, -1)}`}
          setAddBlockClick={() => setUnitGroupModal(true)}
          showButtonOnly
          backgroundColor={unitGroups.length > 0 ? 'transparent' : '#fff'}
        /> */}
        <div className="w-full flex justify-center py-8">
          <AddButton onClick={() => setUnitGroupModal(true)}>
            <Plus className="w-5 h-5" />
            Add Unit Group
          </AddButton>
        </div>
        {unitGroups?.length > 0 ? (
          <>
            {unitGroups?.map((unitGroup) => (
              <div className="mt-4">
                <CustomUnitDetail
                  key={unitGroup._id}
                  unit={unitGroup}
                  handleEdit={() => handleUnitGroupSelect(unitGroup)}
                  handleDelete={() => {
                    setIsDeleteModal(true);
                    setSelectedElmForDeleting({
                      id: unitGroup._id,
                      type: "UnitGroup",
                    });
                  }}
                />
              </div>
            ))}
          </>
        ) : (
          <NotFoundView text="Unit groups not found" />
        )}
      </>
    );
  }, [unitGroupLoading, unitGroups, active]);

  const renderUnits = useCallback(() => {
    if (process.env.REACT_APP_INENVETORY_SETTINGS_UI) {
      if (active !== inventoryTypeName) {
        return null;
      }
    } else {
      if (active !== "Units") {
        return null;
      }
    }
    if (unitsLoading) {
      return <LoadingDetails title="Loading units" />;
    }
    return (
      <>
        <div className="flex justify-center items-center content-center gap-3 mb-3">
          <AddButton onClick={() => setUnitModal(true)}>
            <Plus className="w-5 h-5" />
            Add{" "}
            {process.env.REACT_APP_INENVETORY_SETTINGS_UI
              ? inventoryTypeName
              : "Units"}
          </AddButton>
          <AddButton onClick={() => setShowBulkUnitModal(true)}>
            <Plus className="w-5 h-5" />
            Add Bulk{" "}
            {process.env.REACT_APP_INENVETORY_SETTINGS_UI
              ? inventoryTypeName
              : "Units"}
          </AddButton>
          <AddButton onClick={() => downloadUnits()}>
            {downloadingUnits
              ? "Downloading..."
              : `Download ${
                  process.env.REACT_APP_INENVETORY_SETTINGS_UI
                    ? inventoryTypeName
                    : "Units"
                }`}
          </AddButton>
          <AddButton
            onClick={() => {
              setShowMapInventorySettingModal(true);
              setSelectedMapInventorySettingType("Unit");
            }}
          >
            Manage Map Setting
          </AddButton>
        </div>
        {process.env.REACT_APP_INENVETORY_SETTINGS_UI ? (
          inventoryTypeName === "Inventory" ? (
            <div
            className="flex w-full flex-col items-center justify-center"
            >
              <NotFoundView text="Please Setup your inventory" />
              <CustomButton
                handleClick={() => {
                  history.push(ADMIN_PROJECT_INVENTORY_SETTINGS);
                }}
              >
                <span className="font-medium py-3 px-4 text-base flex gap-2 items-center">
                  Go To Inventory Settings
                </span>
              </CustomButton>
            </div>
          ) : inventories?.length > 0 ? (
            <>
              {inventories?.map((item) => {
                let key = {
                  lot: "lotData",
                  townhome: "townhomeData",
                  condo: "condoData",
                }[item.type];
                return (
                  <CustomUnitDetail
                    key={item._id}
                    unit={{
                      name: item[key]?.number
                        ? `${inventoryTypeName} #${item[key].number}`
                        : item[key]?.condoNumber
                        ? `${inventoryTypeName} #${item[key].condoNumber}`
                        : item[key]?.lotNumber
                        ? `${inventoryTypeName} #${item[key].lotNumber}`
                        : item[key]?.townhomeNumber
                        ? `${inventoryTypeName} #${item[key].townhomeNumber}`
                        : `${inventoryTypeName} #N/A`,
                      price: item[key]?.price
                        ? `$${item[key].price}`
                        : item[key]?.condoPrice
                        ? `$${item[key].condoPrice}`
                        : item[key]?.lotPrice
                        ? `$${item[key].lotPrice}`
                        : item[key]?.townhomePrice
                        ? `$${item[key].townhomePrice}`
                        : "$N/A",
                      status: item[key]?.status
                        ? item[key].status
                        : item[key]?.condoStatus
                        ? item[key].condoStatus
                        : item[key]?.lotStatus
                        ? item[key].lotStatus
                        : item[key]?.townhomeStatus
                        ? item[key].townhomeStatus
                        : "N/A",
                      image:
                        item[key]?.image ||
                        item[key]?.condoimage ||
                        item[key]?.lotimage ||
                        item[key]?.townhomeimage ||
                        demoUnitImage,
                    }}
                    handleEdit={() => handleUnitEdit(item)}
                    handleDelete={() => {
                      setIsDeleteModal(true);
                      setSelectedElmForDeleting({
                        id: item._id,
                        type: "Unit",
                      });
                    }}
                  />
                );
              })}
            </>
          ) : (
            <NotFoundView text={`${inventoryTypeName} not found`} />
          )
        ) : units?.length > 0 ? (
          <>
            {units?.map((item) => (
              <CustomUnitDetail
                key={item._id}
                unit={{
                  name: "Unit #" + item.unitNumber,
                  price: item.priceCurrencyType + "$" + item.price,
                  status: item.status,
                  bedrooms: item.bedrooms,
                  bathrooms: item.bathrooms,
                  balcony: item.balcony,
                  image: item.images[0] ?? demoUnitImage,
                }}
                handleEdit={() => handleUnitEdit(item)}
                handleDelete={() => {
                  setIsDeleteModal(true);
                  setSelectedElmForDeleting({
                    id: item._id,
                    type: "Unit",
                  });
                }}
              />
            ))}
          </>
        ) : (
          <NotFoundView text="Units not found" />
        )}
      </>
    );
  }, [unitsLoading, units, active, downloadingUnits, inventories]);

  const renderParkings = useCallback(() => {
    if (active !== "Parking") {
      return null;
    }
    if (parkingsLoading) {
      return <LoadingDetails title="Loading parking" />;
    }
    return (
      <>
        <div className="flex justify-center items-center content-center gap-3 mb-3">
          <AddButton onClick={() => setParkingModal(true)}>
            <Plus className="w-5 h-5" />
            Add Parking
          </AddButton>
          <AddButton onClick={() => setShowBulkParkingModal(true)}>
            <Plus className="w-5 h-5" />
            Add Bulk Parking
          </AddButton>
          <AddButton onClick={() => downloadParkings()}>
            {downloadingParkings ? "Downloading..." : "Download Parking"}
          </AddButton>
          <AddButton onClick={() => setShowParkingInventoryModal(true)}>
            Inventory
          </AddButton>
          <AddButton
            onClick={() => {
              setShowMapInventorySettingModal(true);
              setSelectedMapInventorySettingType("Parking");
            }}
          >
            Manage Map Setting
          </AddButton>
        </div>
        {parkings?.length > 0 ? (
          <>
            {parkings?.map((item) => (
              <CustomParkingDetail
                key={item._id}
                parking={item}
                handleEdit={() => handleParkingEdit(item)}
                handleDelete={() => {
                  setIsDeleteModal(true);
                  setSelectedElmForDeleting({
                    id: item._id,
                    type: "Parking",
                  });
                }}
              />
            ))}
          </>
        ) : (
          <NotFoundView text="Parking not Found" />
        )}
      </>
    );
  }, [parkingsLoading, parkings, active, downloadingParkings]);

  const renderStorages = useCallback(() => {
    if (active !== "Storages") {
      return null;
    }
    if (loadingStorages) {
      return <LoadingDetails title="Loading storages" />;
    }
    return (
      <>
        <div className="flex justify-center items-center content-center gap-3 mb-3">
          <AddButton onClick={() => setStorageModal(true)}>
            <Plus className="w-5 h-5" />
            Add Storage
          </AddButton>
          <AddButton onClick={() => setShowBulkStoragesModal(true)}>
            <Plus className="w-5 h-5" />
            Add Bulk Storages
          </AddButton>
          <AddButton onClick={() => setShowStorageInventoryModal(true)}>
            Inventory
          </AddButton>
          {/* <AddButton onClick={() => downloadParkings()}>
            {downloadingParkings ? 'Downloading...' : 'Download Storages'}
          </AddButton> */}
          <AddButton
            onClick={() => {
              setShowMapInventorySettingModal(true);
              setSelectedMapInventorySettingType("Storage");
            }}
          >
            Manage Map Setting
          </AddButton>
        </div>
        {storages?.length > 0 ? (
          <>
            {storages?.map((item) => (
              <CustomStorageDetail
                key={item._id}
                storage={item}
                handleEdit={() => handleStorageEdit(item)}
                handleDelete={() => {
                  setIsDeleteModal(true);
                  setSelectedElmForDeleting({
                    id: item._id,
                    type: "Storage",
                  });
                }}
              />
            ))}
          </>
        ) : (
          <NotFoundView text="Storages not found" />
        )}
      </>
    );
  }, [loadingStorages, storages, active]);

  const RenderTabs = () => (
    <div className="mt-10 mb-6">
      <LargeTabs
        types={types}
        active={active}
        setActive={setActive}
        styles={{
          fontWeight: 300,
          padding: "20px 0px",
          fontFamily: "Open Sans",
          fontStyle: "italic",
        }}
      />
      <div className="mt-8">
        {renderFloorPlans()}
        {renderUnitGroups()}
        {renderUnits()}
        {renderParkings()}
        {renderStorages()}
      </div>
      {showFloorModal && (
        <AddFloorPlanModal
          show={showFloorModal}
          setShow={setFloorModal}
          editDetails={modelDetails}
          setEditDetails={setModelDetails}
          refetch={refetch}
        />
      )}
      {showUnitGroupModal && (
        <AddUnitGroupModal
          show={showUnitGroupModal}
          setShow={setUnitGroupModal}
          editDetails={modelDetails}
          setEditDetails={setModelDetails}
          refetch={unitGroupsRefetch}
        />
      )}
      {showUnitModal &&
        (process.env.REACT_APP_INENVETORY_SETTINGS_UI ? (
          <AddInventoryModal
            show={showUnitModal}
            setShow={setUnitModal}
            editDetails={modelDetails}
            setEditDetails={setModelDetails}
            refetch={inventoryRefetch}
            building={buildingId}
          />
        ) : (
          <AddUnitModal
            show={showUnitModal}
            setShow={setUnitModal}
            editDetails={modelDetails}
            setEditDetails={setModelDetails}
            refetch={unitsRefetch}
            building={buildingId}
          />
        ))}
      {showParkingModal && (
        <AddParkingModal
          show={showParkingModal}
          setShow={setParkingModal}
          editDetails={modelDetails}
          setEditDetails={setModelDetails}
          refetch={parkingsRefetch}
          building={buildingId}
        />
      )}
      {showParkingInventoryModal && (
        <AddParkingInventoryModal
          show={showParkingInventoryModal}
          setShow={setShowParkingInventoryModal}
          editDetails={modelDetails}
          setEditDetails={setModelDetails}
          building={buildingId}
        />
      )}
      {showStorageInventoryModal && (
        <AddStorageInventoryModal
          show={showStorageInventoryModal}
          setShow={setShowStorageInventoryModal}
          editDetails={modelDetails}
          setEditDetails={setModelDetails}
          building={buildingId}
        />
      )}
      {showStorageModal && (
        <AddStorageModal
          show={showStorageModal}
          setShow={setStorageModal}
          editDetails={modelDetails}
          building={buildingId}
          setEditDetails={setModelDetails}
          refetch={refetchStorages}
        />
      )}
      {showBulkUnitModal &&
        (process.env.REACT_APP_INENVETORY_SETTINGS_UI ? (
          <AddBulkInventory
            show={showBulkUnitModal}
            setShow={setShowBulkUnitModal}
            onSuccess={inventoryRefetch}
            building={buildingId}
          />
        ) : (
          <AddBulkUnits
            show={showBulkUnitModal}
            setShow={setShowBulkUnitModal}
            onSuccess={unitsRefetch}
            building={buildingId}
          />
        ))}
      {showBulkParkingModal && (
        <AddBulkParkings
          building={buildingId}
          show={showBulkParkingModal}
          setShow={setShowBulkParkingModal}
          onSuccess={parkingsRefetch}
        />
      )}
      {showBulkStoragesModal && (
        <AddBulkStorages
          building={buildingId}
          show={showBulkStoragesModal}
          setShow={setShowBulkStoragesModal}
          onSuccess={refetchStorages}
        />
      )}
      <DeleteModal
        title="Confirm deletion?"
        description={`Are you sure to delete this ${
          selectedElmForDeleting?.type ?? ""
        }?`}
        confirmButtonTitle="Yes"
        cancelButtonTitle="No"
        isOpen={isDeleteModal}
        setIsOpen={setIsDeleteModal}
        onConfirm={() => {
          if (selectedElmForDeleting.type === "Parking") {
            handleDeleteParking(selectedElmForDeleting.id);
          } else if (selectedElmForDeleting.type === "Unit") {
            handleDeleteUnit(selectedElmForDeleting.id);
          } else if (selectedElmForDeleting.type === "UnitGroup") {
            handleDeleteUnitGroups(selectedElmForDeleting.id);
          } else if (selectedElmForDeleting.type === "FloorPlan") {
            handleDeleteFloorPlan(selectedElmForDeleting.id);
          } else if (selectedElmForDeleting.type === "Storage") {
            handleDeleteStorage(selectedElmForDeleting.id);
          }
        }}
        isBtnLoading={isDeleteBtnLoading}
      />
      {showMapInventorySettingModal && (
        <AddOrUpdateMapInventoryModal
          show={showMapInventorySettingModal}
          setShow={setShowMapInventorySettingModal}
          building={buildingId}
          type={selectedMapInventorySettingType}
          setType={setSelectedMapInventorySettingType}
        />
      )}
    </div>
  );

  const RenderContent = () => (
    <div className="p-6 bg-grey-8 rounded">
      {/* <img src={picture} alt='building' className='h-80 w-full' />
      <div className='border-b-2 border-black mt-5' /> */}
      <div className="flex flex-col">
        <div>
          <div className="font-bold text-2xl text-softBlack font-openSans">
            {buildings?.name}
          </div>
          <div className="font-light font-openSans italic text-softBlack text-base">
            {buildings?.totalDollarValueCurrencyType}${" "}
            {buildings?.totalDollarValue}
          </div>
        </div>
        <CustomButton
          variant="outline"
          handleClick={() =>
            props.history.push({
              pathname: ADMIN_ADD_BUILDING,
              state: { id: buildingId },
            })
          }
          className="self-end absolute mt-6"
        >
          <span className="font-medium py-3 px-4 text-base flex gap-2 items-center">
            <PencilLine className="w-5 h-5" fill />
            Edit Building
          </span>
        </CustomButton>
        <div className="mt-4 flex text-softBlack font-normal text-base font-openSans leading-none">
          <TFilledLocationMarkerIcon className="h-4 w-4 mr-1" />
          {buildings?.fullAddress}
        </div>
      </div>
      <RenderTabs />
    </div>
  );

  return (
    <>
      <div className="bg-white min-h-screen relative">
        <div className="p-6 flex justify-between items-center">
          <span className="font-bold text-3xl font-openSans">Buildings</span>
          <RightContent />
        </div>
        <div className="border-b-2 border-black" />
        {loading && (
          <div
            className="flex items-center justify-center w-full h-screen"
            style={{ gridColumn: "1 / -1" }}
          >
            <LoadingDetails title="Loading building details" />
          </div>
        )}
        {!loading && (
          <div className="p-8">
            <RenderContent />
          </div>
        )}
      </div>
    </>
  );
};

export default AdminEditBuilding;
