import useGetTasks from 'hooks/useGetTasks'
import React, { createContext, useState } from 'react'

export const DashboardContext = createContext({
  openSidePanel: false,
  setOpenSidePanel: (bool) => {},
  contactId: '',
  setContactId: (id) => {},
  task: null,
  setTask: (task) => {},
  tasksList: null
})

const DashboardProvider = ({ children }) => {
  const [openSidePanel, setOpenSidePanel] = useState(false)
  const [contactId, setContactId] = useState('')
  const [task, setTask] = useState(null)
  const tasksList = useGetTasks()

  return (
    <DashboardContext.Provider
      value={{
        openSidePanel,
        setOpenSidePanel,
        contactId,
        setContactId,
        task,
        setTask,
        tasksList
      }}
    >
      {children}
    </DashboardContext.Provider>
  )
}

export default DashboardProvider
