import { useState, useEffect, useRef } from 'react'

import { getBuyers } from 'store/actions/usersActions'

const useBuyers = () => {
  const mountedRef = useRef(true)
  const [buyers, setBuyers] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  const getData = async () => {
    if (!mountedRef.current) return null
    setLoading(true)
    try {
      const data = await getBuyers()
      if (!mountedRef.current) return null
      setBuyers(data)
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  return { loading, buyers, getBuyersData: getData }
}

export default useBuyers
