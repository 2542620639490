import React, { useCallback, useState } from 'react'

import ProgressSteps from 'pages/offer/components/ProgressSteps'
import Header from './Header'

import { CreateOfferProvider } from 'pages/offer/context/CreateOfferContext'
import SignContract from 'pages/offer/components/SignContract'
import SelectStorage from 'pages/offer/components/SelectStorage'

const offerSteps = [
  { text: 'Select Storage', Component: SelectStorage },
  { text: 'Sign Contract', Component: SignContract },
  { text: 'Pay Deposit', Component: SelectStorage }
]

const PurchaseAdditionalStorage = () => {
  const [step, setStep] = useState(0)

  const getComponent = useCallback(() => {
    const currentStep = offerSteps[step]
    const Component = currentStep.Component
    return <Component setStep={setStep} key={currentStep.text} />
  }, [step])

  return (
    <CreateOfferProvider>
      <div>
        <Header />
        <div className='px-8 pt-33.25 pb-8'>
          <ProgressSteps
            steps={offerSteps}
            currentStepIndex={step}
            className='w-70 fixed'
          />
          <div className='pl-82.25 w-full'>
            <div
              className='bg-white rounded p-6 grow w-full overflow-auto'
              style={{ maxHeight: 'calc(100vh - 170px)' }}
            >
              {getComponent()}
            </div>
          </div>
        </div>
      </div>
    </CreateOfferProvider>
  )
}

export default PurchaseAdditionalStorage
