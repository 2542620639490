/* eslint-disable no-restricted-globals */
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SHOW_HIDE_SIDEBAR } from 'store/actions/actionTypes'
import { AiOutlineMenu } from 'react-icons/ai'

const OpenSidebarButton = () => {
  const dispatch = useDispatch()
  const show = useSelector(state => state.appReducer.show)

  return (
    <div
      onClick={() => {
        dispatch({ type: SHOW_HIDE_SIDEBAR, payload: true })
      }}
      style={{ top: '22px', left: '18px' }}
      className={`absolute w-[20px] h-[20px] cursor-pointer ${!show ? 'block' : 'hidden'} transition-all ease-in-out duration-300`}
    >
      <AiOutlineMenu fontSize="20px" />
    </div>
  )
}

export default OpenSidebarButton
