import { FadedAnimatedDiv } from 'components'
import BuildingCard from 'components/BuildingCard'
import useGetBuildings from 'hooks/useGetBuildigs'
import Searching from 'pages/contacts/Searching'
import { formatValue } from 'utils/formatters'
import React from 'react'
import picture from '../../../../../../assets/img/buyerBackgroundImage.jpg'
import CustomButton from 'components/CustomButton'

export const LoadingDetails = ({ title }) => (
  <FadedAnimatedDiv className='h-full w-full flex flex-col items-center mt-20'>
    <Searching title={title} />
  </FadedAnimatedDiv>
)

const Building = ({ selected, onSelect, setStep }) => {
  const { loading, buildings } = useGetBuildings()

  return (
    <div>
      <div className='pb-4 border-b-2 border-softBlack'>
        <p className='my-0 text-xl font-bold font-openSans'>Building</p>
      </div>
      <p className='my-0 mt-4 mb-5 text-softBlack text-base font-openSans'>
        Select the building which allocation inventory will be executed for
      </p>
      <div className='grid grid-cols-none lg:grid-cols-2 gap-4 w-full'>
        {loading && (
          <div
            className='flex items-center justify-center w-full h-screen'
            style={{ gridColumn: '1 / -1' }}
          >
            <LoadingDetails title='Loading buildings' />
          </div>
        )}
        {!loading &&
          buildings.length > 0 &&
          buildings.map((item, index) => (
            <div
              key={item._id}
              onClick={() => {
                onSelect(item)
              }}
              className={`rounded-md border-2 ${selected === item?._id
                ? 'border-softBlack'
                : 'border-transparent'
                }`}
            >
              <BuildingCard
                containerClasses='p-4'
                id={item._id}
                index={index}
                propertyName={item.name}
                address={item.fullAddress}
                imgsrc={picture}
                price={` ${item.totalDollarValueCurrencyType?.substring(0, 2) + '$'
                  }${formatValue(item.totalDollarValue)}`}
                noOfUnits={item.numberOfUnits}
                redirectToDetailPage={false}
              />
            </div>
          ))}
      </div>
      {!loading && (
        <div className='flex justify-end my-8'>
          <CustomButton
            handleClick={() => setStep((prev) => prev + 1)}
            disabled={selected === undefined || selected?.id?.length === 0}
          >
            <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
              Next
            </span>
          </CustomButton>
        </div>
      )}
    </div>
  )
}

export default Building
