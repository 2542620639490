// AUTH - LOGIN
export const CHANGE_USER_LOGGED_STATUS = 'CHANGE_USER_LOGGED_STATUS'
export const CHANGE_USER_OBJECT = 'CHANGE_USER_OBJECT'

// TABS
export const CHANGE_USER_SELECTED_TAB = 'CHANGE_USER_SELECTED_TAB'
export const CHANGE_PROJECT_MANAGEMENT_SELECTED_TAB = 'CHANGE_PROJECT_MANAGEMENT_SELECTED_TAB'

// APP
export const CLEAR_APP_REDUCER = 'CLEAR_APP_REDUCER'
export const CHANGE_PINNED_HEADER = 'CHANGE_PINNNED_HEADER'
export const CHANGE_APP_PROJECT = 'CHANGE_APP_PROJECT'
export const UPDATE_LIST_OF_PROJECTS = 'UPDATE_LIST_OF_PROJECTS'
export const SHOW_HIDE_SIDEBAR = 'SHOW_HIDE_SIDEBAR'
export const HOVER_TOGGLER = 'HOVER_TOGGLER'

// CREATE OFFER
export const CREATE_OFFER_CHANGE_UNIT = 'CREATE_OFFER_CHANGE_UNIT'
export const CREATE_OFFER_CHANGE_BUYER = 'CREATE_OFFER_CHANGE_BUYER'
export const CREATE_OFFER_CHANGE_BROKER = 'CREATE_OFFER_CHANGE_BROKER'
export const CREATE_OFFER_CHANGE_PARKING = 'CREATE_OFFER_CHANGE_PARKING'
export const CREATE_OFFER_CHANGE_COLOR_SCHEME = 'CREATE_OFFER_CHANGE_COLOR_SCHEME'
export const CREATE_OFFER_CHANGE_OPTIONS = 'CREATE_OFFER_CHANGE_OPTIONS'
export const CREATE_OFFER_CHANGE_FINTRAC = 'CREATE_OFFER_CHANGE_FINTRAC'
export const CREATE_OFFER_CHANGE_BROKER_COMMISSION = 'CREATE_OFFER_CHANGE_BROKER_COMMISSION'
export const CREATE_OFFER_CLEAR_REDUCER = 'CREATE_OFFER_CLEAR_REDUCER'

// CREATE PROJECT
export const CREATE_PROJECT_MANAGE_SUBDRAWER = 'CREATE_PROJECT_MANAGE_SUBDRAWER'
export const CREATE_PROJECT_CLOSE_SUBDRAWER = 'CREATE_PROJECT_CLOSE_SUBDRAWER'
export const CREATE_PROJECT_MANAGE_THIRDRAWER = 'CREATE_PROJECT_MANAGE_THIRDRAWER'
export const CREATE_PROJECT_CLOSE_THIRDRAWER = 'CREATE_PROJECT_CLOSE_THIRDRAWER'

export const UPDATE_CUSTOM_SECTIONS = 'UPDATE_CUSTOM_SECTIONS'
