import React from 'react'
import propTypes from 'prop-types'
import styled from 'styled-components'

import {
  QuestionCircleOutlined, DashboardOutlined,
  UserOutlined, EditOutlined,
  DeleteOutlined,
  ProjectOutlined, TeamOutlined,
  PartitionOutlined, ScheduleOutlined,
  SettingOutlined, DeploymentUnitOutlined,
  UnorderedListOutlined, FileTextOutlined,
  AppstoreAddOutlined, DesktopOutlined,
  CheckCircleOutlined, BarChartOutlined,
  IdcardOutlined, UserAddOutlined,
  CheckOutlined, CloseOutlined,
  CameraOutlined, FolderOutlined,
  // MinusOutlined, PlusOutlined,
  // MinusCircleFilled, PlusCircleFilled,
  MinusCircleOutlined, PlusCircleOutlined,
  ExpandOutlined, ZoomInOutlined, ZoomOutOutlined,
  WechatOutlined, ExclamationCircleOutlined,
  UserSwitchOutlined, TwitterOutlined,
  LinkedinOutlined,
  InstagramFilled
} from '@ant-design/icons'

import { MdDashboard } from 'react-icons/md'
import {
  FaRegMoneyBillAlt,
  FaDollarSign,
  FaUserTie,
  FaCar,
  FaSquareFull,
  FaBath,
  FaInfoCircle
} from 'react-icons/fa'

import {
  BsBuilding,
  BsGrid1X2Fill,
  BsCardImage,
  BsClock
} from 'react-icons/bs'

import {
  IoIosColorPalette,
  IoIosBed,
  IoIosArrowUp,
  IoIosArrowDown,
  IoIosArrowBack,
  IoIosArrowForward
} from 'react-icons/io'

import { FiMoreVertical, FiSquare } from 'react-icons/fi'

import { RiWechatPayFill } from 'react-icons/ri'

import { GrDocumentPdf, GrFacebookOption } from 'react-icons/gr'

import { BiMailSend } from 'react-icons/bi'

import { VscPin, VscPinned } from 'react-icons/vsc'

import { AiOutlineLoading } from 'react-icons/ai'

export const QuestionIcon = styled(QuestionCircleOutlined)``
export const DashboardIcon = styled(DashboardOutlined)``
export const UserIcon = styled(UserOutlined)``
export const EditIcon = styled(EditOutlined)``
export const DeleteIcon = styled(DeleteOutlined)``
export const ProjectIcon = styled(ProjectOutlined)``
export const PipelineIcon = styled(MdDashboard)``
export const TeamIcon = styled(TeamOutlined)``
export const PartitionIcon = styled(PartitionOutlined)``
export const ScheduleIcon = styled(ScheduleOutlined)``
export const SettingsIcon = styled(SettingOutlined)``
export const DeploymentUnitIcon = styled(DeploymentUnitOutlined)``
export const MoneyIcon = styled(FaRegMoneyBillAlt)``
export const DollarIcon = styled(FaDollarSign)``
export const UnorderedListIcon = styled(UnorderedListOutlined)``
export const FileIcon = styled(FileTextOutlined)``
export const MarketIcon = styled(AppstoreAddOutlined)``
export const BuildingIcon = styled(BsBuilding)``
export const BrokerIcon = styled(FaUserTie)``
export const DesktopIcon = styled(DesktopOutlined)``
export const CarIcon = styled(FaCar)``
export const ColorPalleteIcon = styled(IoIosColorPalette)``
export const CheckCircleIcon = styled(CheckCircleOutlined)``
export const AreaIcon = styled(FaSquareFull)``
export const BathIcon = styled(FaBath)``
export const BedIcon = styled(IoIosBed)``
export const ArrowUpIcon = styled(IoIosArrowUp)``
export const ArrowDownIcon = styled(IoIosArrowDown)``
export const ArrowLeftIcon = styled(IoIosArrowBack)``
export const ArrowRightIcon = styled(IoIosArrowForward)``
export const LayoutModeSelectorIcon = styled(BsGrid1X2Fill)``
export const ProjectStatsIcon = styled(BarChartOutlined)``
export const UserIdIcon = styled(IdcardOutlined)``
export const AddUserIcon = styled(UserAddOutlined)``
export const SwitchUserIcon = styled(UserSwitchOutlined)``
export const CheckIcon = styled(CheckOutlined)``
export const CloseIcon = styled(CloseOutlined)``
export const CameraIcon = styled(CameraOutlined)``
export const FolderIcon = styled(FolderOutlined)``
export const MinusIcon = styled(MinusCircleOutlined)``
export const PlusIcon = styled(PlusCircleOutlined)``
export const ExpandIcon = styled(ExpandOutlined)``
export const RoundedSquareIcon = styled(FiSquare)``
export const MoreIcon = styled(FiMoreVertical)``
export const ImageIcon = styled(BsCardImage)``
export const ZoomInIcon = styled(ZoomInOutlined)``
export const ZoomOutIcon = styled(ZoomOutOutlined)``
export const WeChatIcon = styled(WechatOutlined)``
export const WeChatPayIcon = styled(RiWechatPayFill)``
export const PdfIcon = styled(GrDocumentPdf)``
export const ExclamationCircleIcon = styled(ExclamationCircleOutlined)``
export const SendMailIcon = styled(BiMailSend)``
export const InfoIcon = styled(FaInfoCircle)``
export const ClockIcon = styled(BsClock)``
export const PinIcon = ({ onClick, className }) => <VscPin onClick={onClick} className={className} />
export const PinnedIcon = ({ className }) => <VscPinned className={className} />
export const TwitterIcon = ({ className }) => <TwitterOutlined className={className} />
export const FacebookIcon = ({ className }) => <GrFacebookOption className={className} />
export const InstagramIcon = ({ className }) => <InstagramFilled className={className} />
export const LinkedInIcon = ({ className }) => <LinkedinOutlined className={className} />

// Tailwind Icons
export const TDashboardIcon = ({ className }) => (
  <svg className={className} xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z' />
    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z' />
  </svg>
)

export const TUserIcon = ({ className }) => (
  <svg className={className} xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z' />
  </svg>
)

export const TProjectIcon = ({ className }) => (
  <svg className={className} xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z' />
  </svg>
)

export const TExclamationCircleIcon = ({ circleBackground, className }) => (
  <div className={`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full ${circleBackground} sm:mx-0 sm:h-10 sm:w-10`}>
    <svg className={className} xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor' aria-hidden='true'>
      <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z' />
    </svg>
  </div>
)

export const TCheckCircleIcon = ({ circleBackground, className }) => (
  <svg className={`flex-shrink-0 mr-1.5 h-5 w-5 ${circleBackground} ${className}`} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor' aria-hidden='true'>
    <path fillRule='evenodd' d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z' clipRule='evenodd' />
  </svg>
)

export const TCloseCircleIcon = ({ circleBackground, className }) => (
  <div className={`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full ${circleBackground} sm:mx-0 sm:h-10 sm:w-10`}>
    <svg className={className} xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
      <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M6 18L18 6M6 6l12 12' />
    </svg>
  </div>
)

export const TSearchIcon = ({ className }) => (
  <svg className={className} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor' aria-hidden='true'>
    <path fillRule='evenodd' d='M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z' clipRule='evenodd' />
  </svg>
)

export const TNotificationIcon = ({ className }) => (
  <svg className={className} xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor' aria-hidden='true'>
    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9' />
  </svg>
)

export const TPlusPrimaryIcon = ({ className }) => (
  <svg className={className} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="20" height="20" transform="translate(0.5)" fill="none"/>
    <path d="M16.3337 10.8337H11.3337V15.8337H9.66699V10.8337H4.66699V9.16699H9.66699V4.16699H11.3337V9.16699H16.3337V10.8337Z" fill="currentColor"/>
  </svg>
)

export const TFilledEyeIcon = ({ className }) => (
  <svg className={className} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.99967 3.75C5.83301 3.75 2.27467 6.34167 0.833008 10C2.27467 13.6583 5.83301 16.25 9.99967 16.25C14.1663 16.25 17.7247 13.6583 19.1663 10C17.7247 6.34167 14.1663 3.75 9.99967 3.75ZM9.99967 14.1667C7.69967 14.1667 5.83301 12.3 5.83301 10C5.83301 7.7 7.69967 5.83333 9.99967 5.83333C12.2997 5.83333 14.1663 7.7 14.1663 10C14.1663 12.3 12.2997 14.1667 9.99967 14.1667ZM9.99967 7.5C8.61634 7.5 7.49967 8.61667 7.49967 10C7.49967 11.3833 8.61634 12.5 9.99967 12.5C11.383 12.5 12.4997 11.3833 12.4997 10C12.4997 8.61667 11.383 7.5 9.99967 7.5Z" fill="currentColor"/>
  </svg>

)

export const TMenuIcon = ({ className }) => (
  <svg className={className} xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M4 6h16M4 12h16M4 18h16' />
  </svg>
)

export const TCloseIcon = ({ className }) => (
  <svg className={className} fill='none' viewBox='0 0 12 12'>
    <path d='M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
)

export const TThinCloseIcon = ({ className }) => (
  <svg className={className} xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M6 18L18 6M6 6l12 12' />
  </svg>
)

export const PInfoIcon = ({ iconStyle }) => (
  <svg style={ iconStyle } viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.7092 8.20917L14.2817 3.78167C13.9425 3.4425 13.475 3.25 12.9892 3.25H4.58333C3.575 3.25 2.75 4.075 2.75 5.08333V17.9167C2.75 18.925 3.575 19.75 4.58333 19.75H17.4167C18.425 19.75 19.25 18.925 19.25 17.9167V9.51083C19.25 9.025 19.0575 8.5575 18.7092 8.20917ZM6.41667 6.91667H12.8333V8.75H6.41667V6.91667ZM15.5833 16.0833H6.41667V14.25H15.5833V16.0833ZM15.5833 12.4167H6.41667V10.5833H15.5833V12.4167Z" fill="currentColor"/>
  </svg>
)

export const PImageIcon = ({ iconStyle }) => (
  <svg style={ iconStyle } viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.25 15.1169V2.28353C17.25 1.2752 16.425 0.450195 15.4167 0.450195H2.58333C1.575 0.450195 0.75 1.2752 0.75 2.28353V15.1169C0.75 16.1252 1.575 16.9502 2.58333 16.9502H15.4167C16.425 16.9502 17.25 16.1252 17.25 15.1169ZM5.79167 10.0752L8.08333 12.8344L11.2917 8.7002L15.4167 14.2002H2.58333L5.79167 10.0752Z" fill="currentColor" />
  </svg>
)

export const PParkingIcon = ({ iconStyle }) => (
  <svg style={ iconStyle } viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.7242 3.65039H7.33333C6.325 3.65039 5.5 4.47539 5.5 5.48372V18.3171C5.5 19.3254 6.325 20.1504 7.33333 20.1504C8.34167 20.1504 9.16667 19.3254 9.16667 18.3171V14.6504H11.9167C15.1892 14.6504 17.8017 11.7812 17.3708 8.42622C17.0133 5.65789 14.52 3.65039 11.7242 3.65039ZM12.1 10.9837H9.16667V7.31706H12.1C13.1083 7.31706 13.9333 8.14206 13.9333 9.15039C13.9333 10.1587 13.1083 10.9837 12.1 10.9837Z" fill="currentColor"/>
  </svg>
)

export const PColorIcon = ({ iconStyle }) => (
  <svg style={ iconStyle } viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 2.84961C6.44417 2.84961 2.75 6.54378 2.75 11.0996C2.75 15.6554 6.44417 19.3496 11 19.3496C11.7608 19.3496 12.375 18.7354 12.375 17.9746C12.375 17.6171 12.2375 17.2963 12.0175 17.0488C11.8067 16.8104 11.6692 16.4896 11.6692 16.1413C11.6692 15.3804 12.2833 14.7663 13.0442 14.7663H14.6667C17.1967 14.7663 19.25 12.7129 19.25 10.1829C19.25 6.13128 15.5558 2.84961 11 2.84961ZM5.95833 11.0996C5.1975 11.0996 4.58333 10.4854 4.58333 9.72461C4.58333 8.96378 5.1975 8.34961 5.95833 8.34961C6.71917 8.34961 7.33333 8.96378 7.33333 9.72461C7.33333 10.4854 6.71917 11.0996 5.95833 11.0996ZM8.70833 7.43294C7.9475 7.43294 7.33333 6.81878 7.33333 6.05794C7.33333 5.29711 7.9475 4.68294 8.70833 4.68294C9.46917 4.68294 10.0833 5.29711 10.0833 6.05794C10.0833 6.81878 9.46917 7.43294 8.70833 7.43294ZM13.2917 7.43294C12.5308 7.43294 11.9167 6.81878 11.9167 6.05794C11.9167 5.29711 12.5308 4.68294 13.2917 4.68294C14.0525 4.68294 14.6667 5.29711 14.6667 6.05794C14.6667 6.81878 14.0525 7.43294 13.2917 7.43294ZM16.0417 11.0996C15.2808 11.0996 14.6667 10.4854 14.6667 9.72461C14.6667 8.96378 15.2808 8.34961 16.0417 8.34961C16.8025 8.34961 17.4167 8.96378 17.4167 9.72461C17.4167 10.4854 16.8025 11.0996 16.0417 11.0996Z" fill="currentColor" />
  </svg>
)

export const PUpgradeIcon = ({ iconStyle }) => (
  <svg style={ iconStyle } viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.1668 8.54948L11.0002 2.13281L1.8335 8.54948H10.0835V20.4661H11.9168V8.54948H20.1668Z" fill="currentColor" />
    <path d="M3.79569 11.2998L1.99902 11.639L2.75069 15.6448V20.4665H4.58402L4.60236 16.7998H6.41736V20.4665H8.25069V14.9665H4.49236L3.79569 11.2998Z" fill="currentColor" />
    <path d="M17.5083 14.9665H13.75V20.4665H15.5833V16.7998H17.3983L17.4167 20.4665H19.25V15.6448L20.0017 11.639L18.205 11.2998L17.5083 14.9665Z" fill="currentColor" />
  </svg>
)

export const PCardIcon = ({ iconStyle }) => (
  <svg style={ iconStyle } viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.4165 13.3337V6.00033C17.4165 4.99199 16.5915 4.16699 15.5832 4.16699H2.74984C1.7415 4.16699 0.916504 4.99199 0.916504 6.00033V13.3337C0.916504 14.342 1.7415 15.167 2.74984 15.167H15.5832C16.5915 15.167 17.4165 14.342 17.4165 13.3337ZM9.1665 12.417C7.64484 12.417 6.4165 11.1887 6.4165 9.66699C6.4165 8.14533 7.64484 6.91699 9.1665 6.91699C10.6882 6.91699 11.9165 8.14533 11.9165 9.66699C11.9165 11.1887 10.6882 12.417 9.1665 12.417ZM21.0832 6.91699V17.0003C21.0832 18.0087 20.2582 18.8337 19.2498 18.8337H3.6665V17.0003H19.2498V6.91699H21.0832Z" fill="currentColor" />
  </svg>
)

export const TFullFilledRightArrowIcon = ({ className }) => (
  <svg
    className={className}
    viewBox='0 0 20 20'
    aria-hidden='true'
  >
    <path d='M6 6L14 10L6 14V6Z' fill='currentColor' />
  </svg>
)

export const TFilledTrashIcon = ({ className }) => (
  <svg
    className={className}
    viewBox='0 0 12 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M1.0013 13.6488C1.0013 14.5654 1.7513 15.3154 2.66797 15.3154H9.33464C10.2513 15.3154 11.0013 14.5654 11.0013 13.6488V3.64876H1.0013V13.6488ZM11.8346 1.14876H8.91797L8.08464 0.31543H3.91797L3.08464 1.14876H0.167969V2.81543H11.8346V1.14876Z'
      fill='currentColor'
    />
  </svg>
)

export const TCheckIcon = ({ className }) => (
  <svg className={className} fill='currentColor' viewBox='0 0 12 12'>
    <path d='M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z' />
  </svg>
)

export const TCompanyIcon = ({ className }) => (
  <svg className={className} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor' aria-hidden='true'>
    <path fillRule='evenodd' d='M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z' clipRule='evenodd' />
  </svg>
)

export const TFilledEmailIcon = ({ className }) => (
  <svg className={className} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor'>
    <path d='M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z' />
    <path d='M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z' />
  </svg>
)

export const TFilledUserGroup = ({ className }) => (
  <svg className={className} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor'>
    <path d='M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z' />
  </svg>
)

export const TFilledPlusIcon = ({ className }) => (
  <svg className={className} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor'>
    <path fillRule='evenodd' d='M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z' clipRule='evenodd' />
  </svg>
)

export const SlimPlusIcon = ({ className }) => (
  <svg className={className} viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.8346 7.33366H6.83464V12.3337H5.16797V7.33366H0.167969V5.66699H5.16797V0.666992H6.83464V5.66699H11.8346V7.33366Z" fill="#2E2B2E"/>
  </svg>
)

export const SquareFilledPlusIcon = ({ className }) => (
  <svg className={className} viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5010_27484)">
    <path d="M5.83464 8.00033H3.16797V26.667C3.16797 28.1337 4.36797 29.3337 5.83464 29.3337H24.5013V26.667H5.83464V8.00033ZM27.168 2.66699H11.168C9.7013 2.66699 8.5013 3.86699 8.5013 5.33366V21.3337C8.5013 22.8003 9.7013 24.0003 11.168 24.0003H27.168C28.6346 24.0003 29.8346 22.8003 29.8346 21.3337V5.33366C29.8346 3.86699 28.6346 2.66699 27.168 2.66699ZM25.8346 14.667H20.5013V20.0003H17.8346V14.667H12.5013V12.0003H17.8346V6.66699H20.5013V12.0003H25.8346V14.667Z" fill="#2E2B2E"/>
    </g>
    <defs>
    <clipPath id="clip0_5010_27484">
    <rect className={className} fill="white" transform="translate(0.5)"/>
    </clipPath>
    </defs>
  </svg>

)

export const TFilleMinusIcon = ({ className }) => (
  <svg className={className} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule='evenodd' d="M16.5837 10.8337H4.91699V9.16699H16.5837V10.8337Z"fill='currentColor' />
  </svg>
)

export const TFilledLocationMarkerIcon = ({ className }) => (
  <svg className={className} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor'>
    <path fillRule='evenodd' d='M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z' clipRule='evenodd' />
  </svg>
)

export const TFilledChevronLeftIcon = ({ className }) => (
  <svg className={className} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor'>
    <path fillRule='evenodd' d='M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z' clipRule='evenodd' />
  </svg>
)

export const TFilledChevronUpDownIcon = ({ className }) => (
  <svg className={className} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor' aria-hidden='true'>
    <path fillRule='evenodd' d='M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z' clipRule='evenodd' />
  </svg>
)

export const TFilledPenIcon = ({ className }) => (
  <svg className={className} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor'>
    <path d='M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z' />
  </svg>
)

export const TFilledUserCircle = ({ className, id }) => (
  <svg id={id} className={className} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor'>
    <path id={id} fillRule='evenodd' d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z' clipRule='evenodd' />
  </svg>
)

export const TConfigIcon = ({ className }) => (
  <svg className={className} xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor' aria-hidden='true'>
    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z' />
    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M15 12a3 3 0 11-6 0 3 3 0 016 0z' />
  </svg>
)

export const TInventoryAllocation = () => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#a)" fill="#2E2B2E">
      <path d="m18.058 15.142-3.933-3.933c-.4-.4-.825-.492-1.317-.492l-2.117 2.117c0 .491.092.925.492 1.316l3.933 3.934a.83.83 0 0 0 1.175 0l1.767-1.767a.83.83 0 0 0 0-1.175Z" />
      <path d="M13.858 7.908a.83.83 0 0 0 1.175 0l.591-.591 1.767 1.766a2.5 2.5 0 0 0 0-3.533l-2.358-2.358a.83.83 0 0 0-1.175 0l-.592.591V1.667c0-.517-.633-.792-1.008-.417L10.14 3.367c-.375.375-.1 1.008.417 1.008h2.117l-.592.592a.83.83 0 0 0 0 1.175l.291.291-2.408 2.409L6.541 5.4v-.833a.86.86 0 0 0-.242-.592L4.616 2.283a.83.83 0 0 0-1.175 0L2.258 3.467a.83.83 0 0 0 0 1.175L3.94 6.333a.825.825 0 0 0 .592.242h.833l3.442 3.442-.709.708H7.016c-.442 0-.867.175-1.175.492L1.908 15.15a.83.83 0 0 0 0 1.175l1.766 1.767a.83.83 0 0 0 1.175 0l3.934-3.934c.316-.316.491-.733.491-1.175v-1.075l4.292-4.291.292.291Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
)

export const TLocationPinIcon = ({ className }) => (
  <svg className={className} viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.9987 9.00033C7.91536 9.00033 8.66536 8.25033 8.66536 7.33366C8.66536 6.41699 7.91536 5.66699 6.9987 5.66699C6.08203 5.66699 5.33203 6.41699 5.33203 7.33366C5.33203 8.25033 6.08203 9.00033 6.9987 9.00033ZM6.9987 0.666992C10.4987 0.666992 13.6654 3.35033 13.6654 7.50033C13.6654 10.1503 11.6237 13.267 7.5487 16.8587C7.23203 17.1337 6.75703 17.1337 6.44036 16.8587C2.3737 13.267 0.332031 10.1503 0.332031 7.50033C0.332031 3.35033 3.4987 0.666992 6.9987 0.666992Z" fill="#2E2B2E"/>
  </svg>
)

export const TDocumentIcon = ({ className }) => (
  <svg className={className} xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z' />
  </svg>
)

export const TUserCircleIcon = ({ className }) => (
  <svg className={className} xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor' aria-hidden='true'>
    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z' />
  </svg>
)

export const TLogoutIcon = ({ className }) => (
  <svg className={className} xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1' />
  </svg>
)

export const TCreditCardIcon = ({ className }) => (
  <svg className={className} xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor' aria-hidden='true'>
    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z' />
  </svg>
)

export const InventoryBuildingIcon = ({ className }) => (
  <svg width={22} height={23} fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#a)" fill="#2E2B2E">
      <path d="M18.332 3.25h-7.333a.92.92 0 0 0-.917.917v1.476l.01.009 4.582 4.125c.578.513.908 1.265.908 2.044v.596h1.833v1.833h-1.833v1.833h1.833v1.834h-1.833v1.833h2.75a.92.92 0 0 0 .917-.917V4.167a.92.92 0 0 0-.917-.917Zm-4.583 3.667h-1.834V5.083h1.834v1.834Zm3.666 3.666h-1.833V8.75h1.833v1.833Zm0-3.666h-1.833V5.083h1.833v1.834Z" />
      <path d="M13.75 18.834V11.82a.917.917 0 0 0-.303-.678L8.864 7.018a.88.88 0 0 0-.614-.239c-.22 0-.44.083-.614.239l-4.583 4.125a.888.888 0 0 0-.303.678v7.013a.92.92 0 0 0 .917.916H5.5a.92.92 0 0 0 .917-.916v-3.667h3.666v3.667a.92.92 0 0 0 .917.916h1.833a.92.92 0 0 0 .917-.916Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(0 .5)" d="M0 0h22v22H0z" />
      </clipPath>
    </defs>
  </svg>
)

export const TBookmarkIcon = ({ className }) => (
  <svg className={className} xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z' />
  </svg>
)

export const TFilledChevronDownIcon = ({ className, id }) => (
  <svg className={className} id={id} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor'>
    <path id={id} fillRule='evenodd' d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z' clipRule='evenodd' />
  </svg>
)

export const TFilledCellphoneIcon = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
    <path fillRule="evenodd" d="M7 2a2 2 0 00-2 2v12a2 2 0 002 2h6a2 2 0 002-2V4a2 2 0 00-2-2H7zm3 14a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
  </svg>
)

export const TFilledPhoneIcon = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
    <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
  </svg>
)

export const TFilledHomeIcon = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
    <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
  </svg>
)

export const IndividualBroker = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="98.416" height="147.624" viewBox="0 0 98.416 147.624">
    <g id="Grupo_1723" data-name="Grupo 1723" transform="translate(-158.407 -144.876)">
      <g id="Grupo_1721" data-name="Grupo 1721" transform="translate(158.407 144.876)">
        <path id="Caminho_1153" data-name="Caminho 1153" d="M371.613,217.084v3.453a39.371,39.371,0,0,0,2.02,12.448l7.822,23.465h14.762V202.321h-9.841A14.763,14.763,0,0,0,371.613,217.084Z" transform="translate(-356.851 -197.401)" fill="#5a4146"/>
        <path id="Caminho_1154" data-name="Caminho 1154" d="M445.664,223.97l-8.327,29.144-39.367-34.445a9.842,9.842,0,0,1-9.842-9.842h0a14.763,14.763,0,0,1,14.762-14.762h34.445a9.842,9.842,0,0,1,9.842,9.842v9.249A39.351,39.351,0,0,1,445.664,223.97Z" transform="translate(-363.525 -194.065)" fill="#694b4b"/>
        <rect id="Retângulo_109" data-name="Retângulo 109" width="29.525" height="21.779" transform="translate(34.442 73.814)" fill="#e6af78"/>
        <path id="Caminho_1155" data-name="Caminho 1155" d="M404.645,326.974a39.043,39.043,0,0,0,29.525,0v-9.039H404.645v9.039Z" transform="translate(-370.2 -244.123)" fill="#d29b6e"/>
        <path id="Caminho_1156" data-name="Caminho 1156" d="M434.548,344.751l-23.74-6.782-14.761,9.8-14.762-9.8-23.739,6.783a14.762,14.762,0,0,0-10.707,14.194v35.977a4.921,4.921,0,0,0,4.921,4.921h88.574a4.921,4.921,0,0,0,4.921-4.921V358.945A14.762,14.762,0,0,0,434.548,344.751Z" transform="translate(-346.839 -252.219)" fill="#d7e2c7"/>
        <path id="Caminho_1157" data-name="Caminho 1157" d="M431,406.517h-9.842l2.46-52.033h4.921Z" transform="translate(-376.874 -258.894)" fill="#445220"/>
        <path id="Caminho_1158" data-name="Caminho 1158" d="M409.4,289.524h0A29.525,29.525,0,0,1,379.871,260v-5.765a9.843,9.843,0,0,1,2.882-6.959l8.923-8.923a9.726,9.726,0,0,1,7.144-2.892c15.73.5,29.653,3.866,37.264,11.3a9.715,9.715,0,0,1,2.836,6.969V260A29.525,29.525,0,0,1,409.4,289.524Z" transform="translate(-360.188 -210.792)" fill="#f0c087"/>
        <path id="Caminho_1159" data-name="Caminho 1159" d="M394.633,255.454a9.822,9.822,0,0,1,10.432-9.827c9.934.634,24.675,2.294,33.718,6.644a9.512,9.512,0,0,0-2.7-5.513c-7.611-7.434-21.535-10.8-37.264-11.3h0a9.727,9.727,0,0,0-7.143,2.892l-8.923,8.923a9.84,9.84,0,0,0-2.883,6.959V260a29.518,29.518,0,0,0,21.344,28.351,29.281,29.281,0,0,1-6.582-18.51V255.454Z" transform="translate(-360.188 -210.791)" fill="#e6af78"/>
        <path id="Caminho_1160" data-name="Caminho 1160" d="M478.968,382.109a14.762,14.762,0,0,1,4.324-10.439L495.958,359a14.7,14.7,0,0,1,2.693,8.443v35.977a4.921,4.921,0,0,1-4.921,4.921H478.968V382.109Z" transform="translate(-400.235 -260.72)" fill="#d7e2c7"/>
        <path id="Caminho_1161" data-name="Caminho 1161" d="M428.542,364.326h-4.921a2.461,2.461,0,0,1-2.46-2.46v-7.381H431v7.381A2.46,2.46,0,0,1,428.542,364.326Z" transform="translate(-376.874 -258.894)" fill="#959cb5"/>
      </g>
      <g id="Grupo_1717" data-name="Grupo 1717" transform="translate(187.411 226.717)">
        <path id="Caminho_1162" data-name="Caminho 1162" d="M415.716,345.115l-8.341,6.371a2.952,2.952,0,0,1-4.382-.928l-7.482-13.663,3.012-4.594a1.968,1.968,0,0,1,2.985-.364Z" transform="translate(-395.512 -331.41)" fill="#c7cfe2"/>
        <path id="Caminho_1163" data-name="Caminho 1163" d="M429.419,345.115l8.341,6.371a2.952,2.952,0,0,0,4.382-.928l7.482-13.663-3.012-4.594a1.969,1.969,0,0,0-2.985-.364Z" transform="translate(-409.215 -331.411)" fill="#c7cfe2"/>
      </g>
    </g>
  </svg>
)

export const MultibrokerCompetetive = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="210.449" height="152.746" viewBox="0 0 210.449 152.746">
  <g id="team_2_" data-name="team (2)" transform="translate(0 -70.193)">
    <path id="Caminho_1153" data-name="Caminho 1153" d="M371.613,212.5v2.382a27.157,27.157,0,0,0,1.393,8.587l5.4,16.186h10.183V202.321H381.8A10.183,10.183,0,0,0,371.613,212.5Z" transform="translate(-218.868 -77.819)" fill="#5a4146"/>
    <path id="Caminho_1154" data-name="Caminho 1154" d="M427.816,214.693l-5.744,20.1-27.155-23.76a6.789,6.789,0,0,1-6.789-6.789h0a10.183,10.183,0,0,1,10.183-10.183h23.76a6.789,6.789,0,0,1,6.789,6.789v6.38A27.145,27.145,0,0,1,427.816,214.693Z" transform="translate(-228.595 -72.957)" fill="#694b4b"/>
    <rect id="Retângulo_109" data-name="Retângulo 109" width="20.366" height="15.023" transform="translate(166.32 172.025)" fill="#e6af78"/>
    <path id="Caminho_1155" data-name="Caminho 1155" d="M404.645,324.17a26.932,26.932,0,0,0,20.366,0v-6.235H404.645v6.235Z" transform="translate(-238.322 -145.912)" fill="#d29b6e"/>
    <path id="Caminho_1156" data-name="Caminho 1156" d="M407.34,342.647l-16.376-4.678-10.182,6.757L370.6,337.968l-16.375,4.679a10.183,10.183,0,0,0-7.385,9.791v24.817a3.394,3.394,0,0,0,3.394,3.394h61.1a3.394,3.394,0,0,0,3.394-3.394V352.438A10.183,10.183,0,0,0,407.34,342.647Z" transform="translate(-204.277 -157.711)" fill="#d7e2c7"/>
    <path id="Caminho_1157" data-name="Caminho 1157" d="M427.95,390.376h-6.789l1.7-35.892h3.394Z" transform="translate(-248.05 -167.438)" fill="#445220"/>
    <path id="Caminho_1158" data-name="Caminho 1158" d="M400.237,272.752h0a20.366,20.366,0,0,1-20.366-20.366v-3.977a6.79,6.79,0,0,1,1.988-4.8l6.155-6.155a6.709,6.709,0,0,1,4.928-1.995c10.85.342,20.455,2.667,25.7,7.794a6.7,6.7,0,0,1,1.956,4.807v4.325A20.366,20.366,0,0,1,400.237,272.752Z" transform="translate(-223.731 -97.334)" fill="#f0c087"/>
    <path id="Caminho_1159" data-name="Caminho 1159" d="M390.054,249.25a6.775,6.775,0,0,1,7.2-6.779c6.852.437,17.021,1.582,23.259,4.583a6.561,6.561,0,0,0-1.862-3.8c-5.25-5.128-14.854-7.452-25.7-7.794h0a6.71,6.71,0,0,0-4.927,1.995l-6.155,6.155a6.787,6.787,0,0,0-1.989,4.8v3.977a20.362,20.362,0,0,0,14.723,19.557,20.2,20.2,0,0,1-4.54-12.768V249.25Z" transform="translate(-223.731 -97.333)" fill="#e6af78"/>
    <path id="Caminho_1160" data-name="Caminho 1160" d="M478.968,374.942a10.183,10.183,0,0,1,2.982-7.2L490.688,359a10.143,10.143,0,0,1,1.857,5.824v24.817a3.394,3.394,0,0,1-3.394,3.394H478.968v-18.1Z" transform="translate(-282.096 -170.1)" fill="#d7e2c7"/>
    <path id="Caminho_1161" data-name="Caminho 1161" d="M426.252,361.273h-3.394a1.7,1.7,0,0,1-1.7-1.7v-5.091h6.789v5.091A1.7,1.7,0,0,1,426.252,361.273Z" transform="translate(-248.05 -167.438)" fill="#959cb5"/>
    <g id="Grupo_1717" data-name="Grupo 1717" transform="translate(162.569 177.562)">
      <path id="Caminho_1162" data-name="Caminho 1162" d="M409.449,340.864l-5.753,4.394a2.036,2.036,0,0,1-3.023-.64l-5.161-9.425,2.077-3.169a1.358,1.358,0,0,1,2.059-.251Z" transform="translate(-395.512 -331.41)" fill="#c7cfe2"/>
      <path id="Caminho_1163" data-name="Caminho 1163" d="M429.419,340.864l5.753,4.395a2.037,2.037,0,0,0,3.023-.64l5.161-9.425-2.077-3.169a1.358,1.358,0,0,0-2.059-.251Z" transform="translate(-415.482 -331.411)" fill="#c7cfe2"/>
    </g>
    <path id="Caminho_1164" data-name="Caminho 1164" d="M61.972,248.9c-2.9-7.686-5.2-20.9-5.7-27.776-.945-13.09-10.81-23.76-24.144-23.76s-23.2,10.671-24.144,23.76c-.5,6.878-2.8,20.09-5.7,27.776a3.381,3.381,0,0,0,1.767,4.319,64.832,64.832,0,0,0,17.728,5.243h20.7A65.4,65.4,0,0,0,60.2,253.218,3.381,3.381,0,0,0,61.972,248.9Z" transform="translate(-1.212 -74.899)" fill="#5a4146"/>
    <path id="Caminho_1165" data-name="Caminho 1165" d="M88.791,253.219a3.381,3.381,0,0,0,1.767-4.319c-2.9-7.685-5.2-20.9-5.7-27.776-.945-13.09-10.81-23.76-24.144-23.76h-.1c-10.232.041-13.848,14-5.04,19.2a9.366,9.366,0,0,0,.868.463l7.715,41.432h6.9A65.446,65.446,0,0,0,88.791,253.219Z" transform="translate(-29.799 -74.9)" fill="#694b4b"/>
    <path id="Caminho_1166" data-name="Caminho 1166" d="M55.469,331.426l-10.984-5.492a6.788,6.788,0,0,1-3.753-6.073V309.678H20.366v10.184a6.788,6.788,0,0,1-3.753,6.072L5.629,331.426A10.182,10.182,0,0,0,0,340.533v20.059a3.394,3.394,0,0,0,3.394,3.394H57.7a3.394,3.394,0,0,0,3.394-3.394V340.533A10.182,10.182,0,0,0,55.469,331.426Z" transform="translate(0 -141.049)" fill="#e6af78"/>
    <path id="Caminho_1167" data-name="Caminho 1167" d="M59.469,323.254a26.829,26.829,0,0,0,10.365-2.095,6.691,6.691,0,0,1-.183-1.3V309.677H49.285v10.184a6.683,6.683,0,0,1-.183,1.305A26.964,26.964,0,0,0,59.469,323.254Z" transform="translate(-28.919 -141.048)" fill="#d29b6e"/>
    <path id="Caminho_1168" data-name="Caminho 1168" d="M55.469,356.981l-7.825-3.913a20.338,20.338,0,0,1-34.189,0l-7.825,3.913A10.182,10.182,0,0,0,0,366.088v20.059a3.394,3.394,0,0,0,3.394,3.394H57.7a3.394,3.394,0,0,0,3.394-3.394V366.089A10.182,10.182,0,0,0,55.469,356.981Z" transform="translate(0 -166.604)" fill="#d5dced"/>
    <path id="Caminho_1169" data-name="Caminho 1169" d="M45.34,278.354h0a20.37,20.37,0,0,1-20.2-17.73,3.423,3.423,0,0,1,1.814-3.528,28.334,28.334,0,0,0,6.008-4.058,19.6,19.6,0,0,0,4.644-6.111,3.36,3.36,0,0,1,3.827-1.86,58.481,58.481,0,0,1,23.04,11.8,3.284,3.284,0,0,1,1.168,2.729A20.369,20.369,0,0,1,45.34,278.354Z" transform="translate(-14.791 -102.936)" fill="#f0c087"/>
    <path id="Caminho_1170" data-name="Caminho 1170" d="M64.476,256.87a58.477,58.477,0,0,0-23.04-11.8,3.36,3.36,0,0,0-3.827,1.86,19.431,19.431,0,0,1-3.1,4.528s0,.007,0,.012a18.21,18.21,0,0,1-1.54,1.572,28.318,28.318,0,0,1-6.008,4.058,3.424,3.424,0,0,0-1.814,3.529,20.4,20.4,0,0,0,17.109,17.5,14.861,14.861,0,0,1-7.1-13.345v-4.84c.771-.569,1.548-1.137,2.328-1.833a27.023,27.023,0,0,0,4.854-5.727A51.261,51.261,0,0,1,60.03,262c.643.564,2.24,2.035,4.107,3.788a20.211,20.211,0,0,0,1.506-6.189A3.284,3.284,0,0,0,64.476,256.87Z" transform="translate(-14.791 -102.936)" fill="#e6af78"/>
    <path id="Caminho_1171" data-name="Caminho 1171" d="M2.069,369.859A10.161,10.161,0,0,0,0,375.978v20.059a3.394,3.394,0,0,0,3.394,3.394H13.577v-17.1a6.789,6.789,0,0,0-2.548-5.3Z" transform="translate(0 -176.493)" fill="#c7cfe2"/>
    <path id="Caminho_1172" data-name="Caminho 1172" d="M222.083,336.52l-28.4-10.328-20.366,6.789-20.366-6.789-28.4,10.328a13.577,13.577,0,0,0-8.938,12.76v21.039a3.394,3.394,0,0,0,3.394,3.394H227.626a3.394,3.394,0,0,0,3.394-3.394V349.28A13.577,13.577,0,0,0,222.083,336.52Z" transform="translate(-68.092 -150.775)" fill="#ff507d"/>
    <path id="Caminho_1173" data-name="Caminho 1173" d="M242.717,359.226l-3.233,33.943h13.577l-3.233-33.943Z" transform="translate(-141.048 -170.231)" fill="#707487"/>
    <path id="Caminho_1174" data-name="Caminho 1174" d="M251.052,354.161h-7.127a2.376,2.376,0,0,1-2.376-2.376v-9.076h11.879v9.076A2.376,2.376,0,0,1,251.052,354.161Z" transform="translate(-142.264 -160.503)" fill="#5b5d6e"/>
    <path id="Caminho_1175" data-name="Caminho 1175" d="M363.551,359.855a13.562,13.562,0,0,1,3.654,9.251v21.039a3.394,3.394,0,0,1-3.394,3.394H346.839V382.191a13.576,13.576,0,0,1,3.977-9.6Z" transform="translate(-204.277 -170.601)" fill="#d23c69"/>
    <path id="Caminho_1176" data-name="Caminho 1176" d="M239.837,105.417V76.982a6.789,6.789,0,0,0-6.789-6.789H192.316a27.155,27.155,0,0,0-27.155,27.155v8.069a33.94,33.94,0,0,0,1.742,10.734l1.13,3.39a10.182,10.182,0,0,1,.522,3.22V124.5h67.887V122.76a10.181,10.181,0,0,1,.522-3.22l1.13-3.39A33.931,33.931,0,0,0,239.837,105.417Z" transform="translate(-97.274)" fill="#5a4146"/>
    <path id="Caminho_1177" data-name="Caminho 1177" d="M206.452,83.982a13.789,13.789,0,0,0,13.789,13.789h1.909l.922,26.73h37.689V122.76a10.183,10.183,0,0,1,.522-3.22l1.13-3.39a33.939,33.939,0,0,0,1.742-10.734V76.982a6.789,6.789,0,0,0-6.789-6.789H220.241A13.789,13.789,0,0,0,206.452,83.982Z" transform="translate(-121.593)" fill="#694b4b"/>
    <rect id="Retângulo_110" data-name="Retângulo 110" width="40.733" height="30.548" transform="translate(84.858 151.659)" fill="#e6af78"/>
    <path id="Caminho_1178" data-name="Caminho 1178" d="M206.451,279.864a40.5,40.5,0,0,0,40.732,0V268.386H206.451v11.478Z" transform="translate(-121.593 -116.729)" fill="#d29b6e"/>
    <g id="Grupo_1718" data-name="Grupo 1718" transform="translate(78.07 169.766)">
      <path id="Caminho_1179" data-name="Caminho 1179" d="M217.09,324.883l-10.826,10.826a3.394,3.394,0,0,1-5.1-.348L189.936,320.57l2.936-6.187a3.394,3.394,0,0,1,4.847-1.435Z" transform="translate(-189.936 -312.443)" fill="#d23c69"/>
      <path id="Caminho_1180" data-name="Caminho 1180" d="M256,324.883l10.826,10.826a3.394,3.394,0,0,0,5.1-.348l11.225-14.791-2.936-6.187a3.394,3.394,0,0,0-4.847-1.435Z" transform="translate(-228.846 -312.443)" fill="#d23c69"/>
    </g>
    <path id="Caminho_1181" data-name="Caminho 1181" d="M207.363,201.273h0a33.944,33.944,0,0,1-33.944-33.944v-3.977a6.79,6.79,0,0,1,1.988-4.8l2.812-2.812a6.789,6.789,0,0,0,1.988-4.8V142.8a3.367,3.367,0,0,1,2.585-3.3c8.028-1.9,33.009-6.387,50.01,6.116a4.355,4.355,0,0,1,1.714,3.516v1.8a6.79,6.79,0,0,0,1.988,4.8l2.812,2.812a6.789,6.789,0,0,1,1.988,4.8v3.977A33.942,33.942,0,0,1,207.363,201.273Z" transform="translate(-102.138 -39.432)" fill="#f0c087"/>
    <path id="Caminho_1182" data-name="Caminho 1182" d="M232.8,145.629c-11.957-8.794-27.84-9.175-39.017-7.971a91.552,91.552,0,0,0-10.992,1.855,3.367,3.367,0,0,0-2.585,3.3v8.136a6.789,6.789,0,0,1-1.989,4.8l-2.812,2.812a6.789,6.789,0,0,0-1.989,4.8v3.976a33.931,33.931,0,0,0,29.842,33.672,33.8,33.8,0,0,1-9.476-23.489v-24.81a6.793,6.793,0,0,1,5.971-6.743c8.146-1.021,23.066-1.635,34.761,4.981v-1.8A4.354,4.354,0,0,0,232.8,145.629Z" transform="translate(-102.138 -39.441)" fill="#e6af78"/>
    <path id="Caminho_1183" data-name="Caminho 1183" d="M119.267,359.855a13.562,13.562,0,0,0-3.654,9.251v21.039a3.394,3.394,0,0,0,3.394,3.394h16.972V382.191a13.576,13.576,0,0,0-3.977-9.6Z" transform="translate(-68.092 -170.601)" fill="#d23c69"/>
  </g>
</svg>
)

export const MultibrokerSequenced = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="269.596" height="152.746" viewBox="0 0 269.596 152.746">
  <g id="team_2_" data-name="team (2)" transform="translate(36.947 -64.49)">
    <g id="Grupo_1722" data-name="Grupo 1722" transform="translate(76.361 97.824)">
      <g id="Grupo_1721" data-name="Grupo 1721" transform="translate(0 0)">
        <path id="Caminho_1153" data-name="Caminho 1153" d="M371.613,214.263v2.793a31.847,31.847,0,0,0,1.634,10.069l6.327,18.98h11.941V202.321h-7.961A11.942,11.942,0,0,0,371.613,214.263Z" transform="translate(-359.672 -198.342)" fill="#5a4146"/>
        <path id="Caminho_1154" data-name="Caminho 1154" d="M434.668,218.255l-6.735,23.574L396.09,213.967a7.961,7.961,0,0,1-7.961-7.961h0a11.941,11.941,0,0,1,11.941-11.941h27.862a7.961,7.961,0,0,1,7.961,7.961v7.482A31.832,31.832,0,0,1,434.668,218.255Z" transform="translate(-368.227 -194.065)" fill="#694b4b"/>
        <rect id="Retângulo_109" data-name="Retângulo 109" width="23.882" height="17.617" transform="translate(27.86 59.707)" fill="#e6af78"/>
        <path id="Caminho_1155" data-name="Caminho 1155" d="M404.645,325.246a31.582,31.582,0,0,0,23.882,0v-7.311H404.645v7.311Z" transform="translate(-376.783 -258.23)" fill="#d29b6e"/>
        <path id="Caminho_1156" data-name="Caminho 1156" d="M417.785,343.455l-19.2-5.486-11.94,7.924L374.7,337.968l-19.2,5.487a11.941,11.941,0,0,0-8.661,11.482v29.1a3.98,3.98,0,0,0,3.98,3.98h71.647a3.98,3.98,0,0,0,3.98-3.98v-29.1A11.941,11.941,0,0,0,417.785,343.455Z" transform="translate(-346.839 -268.607)" fill="#d7e2c7"/>
        <path id="Caminho_1157" data-name="Caminho 1157" d="M429.122,396.572h-7.961l1.99-42.088h3.98Z" transform="translate(-385.338 -277.162)" fill="#445220"/>
        <path id="Caminho_1158" data-name="Caminho 1158" d="M403.753,279.191h0a23.882,23.882,0,0,1-23.882-23.882v-4.663a7.962,7.962,0,0,1,2.332-5.629l7.218-7.218a7.868,7.868,0,0,1,5.779-2.339c12.724.4,23.986,3.127,30.143,9.14a7.858,7.858,0,0,1,2.294,5.637v5.072A23.882,23.882,0,0,1,403.753,279.191Z" transform="translate(-363.95 -215.505)" fill="#f0c087"/>
        <path id="Caminho_1159" data-name="Caminho 1159" d="M391.812,251.632a7.945,7.945,0,0,1,8.438-7.949c8.035.513,19.959,1.856,27.274,5.374a7.694,7.694,0,0,0-2.183-4.459c-6.157-6.013-17.419-8.739-30.143-9.14h0a7.868,7.868,0,0,0-5.778,2.339l-7.217,7.217a7.959,7.959,0,0,0-2.332,5.629v4.663a23.877,23.877,0,0,0,17.265,22.933,23.685,23.685,0,0,1-5.324-14.972V251.632Z" transform="translate(-363.95 -215.505)" fill="#e6af78"/>
        <path id="Caminho_1160" data-name="Caminho 1160" d="M478.968,377.693a11.941,11.941,0,0,1,3.5-8.444L492.711,359a11.893,11.893,0,0,1,2.178,6.829v29.1a3.98,3.98,0,0,1-3.98,3.98H478.968V377.693Z" transform="translate(-415.282 -279.504)" fill="#d7e2c7"/>
        <path id="Caminho_1161" data-name="Caminho 1161" d="M427.131,362.445h-3.98a1.99,1.99,0,0,1-1.99-1.99v-5.97h7.961v5.97A1.99,1.99,0,0,1,427.131,362.445Z" transform="translate(-385.338 -277.162)" fill="#959cb5"/>
      </g>
      <g id="Grupo_1717" data-name="Grupo 1717" transform="translate(23.46 66.2)">
        <path id="Caminho_1162" data-name="Caminho 1162" d="M411.855,342.5l-6.747,5.153a2.388,2.388,0,0,1-3.545-.751l-6.052-11.052,2.436-3.716a1.592,1.592,0,0,1,2.414-.294Z" transform="translate(-395.512 -331.41)" fill="#c7cfe2"/>
        <path id="Caminho_1163" data-name="Caminho 1163" d="M429.419,342.5l6.747,5.154a2.388,2.388,0,0,0,3.545-.751l6.052-11.052-2.436-3.716a1.592,1.592,0,0,0-2.414-.294Z" transform="translate(-413.076 -331.411)" fill="#c7cfe2"/>
      </g>
    </g>
    <g id="Grupo_1720" data-name="Grupo 1720" transform="translate(-36.947 64.49)">
      <path id="Caminho_1164" data-name="Caminho 1164" d="M93.142,275.711c-4.41-11.684-7.907-31.769-8.663-42.227-1.437-19.9-16.434-36.121-36.706-36.121s-35.269,16.222-36.706,36.121c-.755,10.457-4.253,30.543-8.663,42.227a5.14,5.14,0,0,0,2.686,6.566,98.56,98.56,0,0,0,26.951,7.971H63.5a99.429,99.429,0,0,0,26.951-7.971A5.139,5.139,0,0,0,93.142,275.711Z" transform="translate(-0.772 -197.363)" fill="#5a4146"/>
      <path id="Caminho_1165" data-name="Caminho 1165" d="M108.663,282.278a5.139,5.139,0,0,0,2.686-6.566c-4.409-11.684-7.907-31.769-8.662-42.227-1.437-19.9-16.434-36.121-36.705-36.121h-.153c-15.555.063-21.052,21.277-7.662,29.192a14.238,14.238,0,0,0,1.32.7l11.728,62.987h10.5A99.493,99.493,0,0,0,108.663,282.278Z" transform="translate(-18.98 -197.363)" fill="#694b4b"/>
      <path id="Caminho_1166" data-name="Caminho 1166" d="M84.327,342.74l-16.7-8.349a10.32,10.32,0,0,1-5.705-9.232V309.678H30.961V325.16a10.32,10.32,0,0,1-5.705,9.231l-16.7,8.35A15.479,15.479,0,0,0,0,356.585v30.5a5.16,5.16,0,0,0,5.16,5.16H87.724a5.16,5.16,0,0,0,5.16-5.16V356.586A15.48,15.48,0,0,0,84.327,342.74Z" transform="translate(0 -239.495)" fill="#e6af78"/>
      <path id="Caminho_1167" data-name="Caminho 1167" d="M64.862,330.318a40.787,40.787,0,0,0,15.757-3.184,10.171,10.171,0,0,1-.277-1.975V309.677H49.381v15.482a10.16,10.16,0,0,1-.279,1.985A40.992,40.992,0,0,0,64.862,330.318Z" transform="translate(-18.419 -239.495)" fill="#d29b6e"/>
      <path id="Caminho_1168" data-name="Caminho 1168" d="M84.327,359.017l-11.9-5.948a30.919,30.919,0,0,1-51.976,0l-11.9,5.949A15.48,15.48,0,0,0,0,372.862v30.5a5.16,5.16,0,0,0,5.16,5.16H87.724a5.16,5.16,0,0,0,5.16-5.16V372.863A15.48,15.48,0,0,0,84.327,359.017Z" transform="translate(0 -255.772)" fill="#d5dced"/>
      <path id="Caminho_1169" data-name="Caminho 1169" d="M55.863,295.723h0a30.967,30.967,0,0,1-30.7-26.954,5.2,5.2,0,0,1,2.758-5.364,43.078,43.078,0,0,0,9.134-6.169,29.8,29.8,0,0,0,7.06-9.291,5.108,5.108,0,0,1,5.818-2.828,88.906,88.906,0,0,1,35.026,17.946,4.992,4.992,0,0,1,1.775,4.149A30.966,30.966,0,0,1,55.863,295.723Z" transform="translate(-9.42 -215.221)" fill="#f0c087"/>
      <path id="Caminho_1170" data-name="Caminho 1170" d="M84.954,263.063a88.9,88.9,0,0,0-35.026-17.946,5.108,5.108,0,0,0-5.818,2.828,29.542,29.542,0,0,1-4.715,6.884c0,.006,0,.011,0,.017a27.68,27.68,0,0,1-2.341,2.39,43.05,43.05,0,0,1-9.134,6.169,5.206,5.206,0,0,0-2.758,5.365,31.01,31.01,0,0,0,26.01,26.6c-6.178-4.2-10.787-9.983-10.787-20.287v-7.359c1.172-.865,2.353-1.728,3.539-2.787a41.081,41.081,0,0,0,7.38-8.706A77.93,77.93,0,0,1,78.2,270.864c.978.857,3.4,3.094,6.244,5.758a30.725,30.725,0,0,0,2.289-9.409A4.992,4.992,0,0,0,84.954,263.063Z" transform="translate(-9.42 -215.22)" fill="#e6af78"/>
      <path id="Caminho_1171" data-name="Caminho 1171" d="M3.146,369.859A15.448,15.448,0,0,0,0,379.161v30.5a5.16,5.16,0,0,0,5.16,5.16H20.641v-26a10.321,10.321,0,0,0-3.874-8.059Z" transform="translate(0 -262.071)" fill="#c7cfe2"/>
    </g>
    <g id="Grupo_1719" data-name="Grupo 1719" transform="translate(176.393 142.778)">
      <path id="Caminho_1172" data-name="Caminho 1172" d="M167.513,331.226l-13.844-5.034-9.928,3.309-9.928-3.309-13.844,5.034a6.618,6.618,0,0,0-4.357,6.22V347.7a1.655,1.655,0,0,0,1.655,1.655h52.947a1.655,1.655,0,0,0,1.655-1.655V337.446A6.618,6.618,0,0,0,167.513,331.226Z" transform="translate(-115.613 -274.899)" fill="#ff507d"/>
      <path id="Caminho_1173" data-name="Caminho 1173" d="M241.06,359.226l-1.576,16.546H246.1l-1.576-16.546Z" transform="translate(-214.665 -301.315)" fill="#707487"/>
      <path id="Caminho_1174" data-name="Caminho 1174" d="M246.182,348.292h-3.474a1.158,1.158,0,0,1-1.158-1.158v-4.424h5.791v4.424A1.158,1.158,0,0,1,246.182,348.292Z" transform="translate(-216.316 -288.107)" fill="#5b5d6e"/>
      <path id="Caminho_1175" data-name="Caminho 1175" d="M354.986,359.855a6.611,6.611,0,0,1,1.781,4.509V374.62a1.655,1.655,0,0,1-1.655,1.655h-8.273v-5.532a6.618,6.618,0,0,1,1.939-4.68Z" transform="translate(-300.51 -301.818)" fill="#d23c69"/>
      <path id="Caminho_1176" data-name="Caminho 1176" d="M201.562,87.363V73.5a3.309,3.309,0,0,0-3.309-3.309H178.4A13.237,13.237,0,0,0,165.161,83.43v3.933a16.544,16.544,0,0,0,.849,5.232l.551,1.652a4.963,4.963,0,0,1,.255,1.57v.849h33.092v-.849a4.963,4.963,0,0,1,.255-1.57l.551-1.652A16.54,16.54,0,0,0,201.562,87.363Z" transform="translate(-155.233 -70.193)" fill="#5a4146"/>
      <path id="Caminho_1177" data-name="Caminho 1177" d="M206.452,76.915a6.722,6.722,0,0,0,6.722,6.722h.931l.449,13.03h18.372v-.849a4.963,4.963,0,0,1,.255-1.57l.551-1.652a16.544,16.544,0,0,0,.849-5.232V73.5a3.309,3.309,0,0,0-3.309-3.309h-18.1A6.722,6.722,0,0,0,206.452,76.915Z" transform="translate(-188.251 -70.193)" fill="#694b4b"/>
      <rect id="Retângulo_110" data-name="Retângulo 110" width="19.856" height="14.891" transform="translate(18.2 39.711)" fill="#e6af78"/>
      <path id="Caminho_1178" data-name="Caminho 1178" d="M206.451,273.981a19.74,19.74,0,0,0,19.855,0v-5.595H206.451v5.595Z" transform="translate(-188.25 -228.676)" fill="#d29b6e"/>
      <g id="Grupo_1718" data-name="Grupo 1718" transform="translate(14.892 48.538)">
        <path id="Caminho_1179" data-name="Caminho 1179" d="M203.173,318.507l-5.277,5.277a1.655,1.655,0,0,1-2.488-.17l-5.472-7.21,1.431-3.016a1.654,1.654,0,0,1,2.363-.7Z" transform="translate(-189.936 -312.443)" fill="#d23c69"/>
        <path id="Caminho_1180" data-name="Caminho 1180" d="M256,318.507l5.277,5.277a1.654,1.654,0,0,0,2.488-.17l5.472-7.21-1.431-3.016a1.654,1.654,0,0,0-2.363-.7Z" transform="translate(-242.763 -312.443)" fill="#d23c69"/>
      </g>
      <path id="Caminho_1181" data-name="Caminho 1181" d="M189.965,168.4h0a16.546,16.546,0,0,1-16.546-16.546V149.92a3.31,3.31,0,0,1,.969-2.34l1.371-1.371a3.309,3.309,0,0,0,.969-2.34V139.9a1.641,1.641,0,0,1,1.26-1.608c3.913-.924,16.091-3.114,24.378,2.981a2.123,2.123,0,0,1,.836,1.714v.879a3.31,3.31,0,0,0,.969,2.34l1.371,1.371a3.309,3.309,0,0,1,.969,2.34v1.939A16.545,16.545,0,0,1,189.965,168.4Z" transform="translate(-161.837 -123.73)" fill="#f0c087"/>
      <path id="Caminho_1182" data-name="Caminho 1182" d="M202.366,141.288c-5.829-4.287-13.571-4.472-19.019-3.885a44.63,44.63,0,0,0-5.358.9,1.641,1.641,0,0,0-1.26,1.608v3.966a3.309,3.309,0,0,1-.969,2.34l-1.371,1.371a3.309,3.309,0,0,0-.969,2.34v1.938a16.54,16.54,0,0,0,14.547,16.414,16.478,16.478,0,0,1-4.619-11.45V144.74a3.311,3.311,0,0,1,2.91-3.287c3.971-.5,11.244-.8,16.945,2.428V143A2.122,2.122,0,0,0,202.366,141.288Z" transform="translate(-161.837 -123.742)" fill="#e6af78"/>
      <path id="Caminho_1183" data-name="Caminho 1183" d="M117.394,359.855a6.611,6.611,0,0,0-1.781,4.509V374.62a1.655,1.655,0,0,0,1.655,1.655h8.273v-5.532a6.618,6.618,0,0,0-1.939-4.68Z" transform="translate(-115.613 -301.818)" fill="#d23c69"/>
    </g>
  </g>
</svg>
)

export const ChevronDivider = ({ className }) => (
  <svg
    className={`h-20 w-2 text-gray-200 ${className}`}
    viewBox="0 0 24 44"
    preserveAspectRatio="none"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
  </svg>
)

export const LoadingIcon = ({ className }) => <AiOutlineLoading className={`animate-spin ${className}`} />

PinIcon.propTypes = {
  onClick: propTypes.func,
  className: propTypes.string
}

TwitterIcon.propTypes = {
  className: propTypes.string
}

FacebookIcon.propTypes = {
  className: propTypes.string
}

InstagramIcon.propTypes = {
  className: propTypes.string
}

PinnedIcon.propTypes = {
  className: propTypes.string
}

TDashboardIcon.propTypes = {
  className: propTypes.string
}

TUserIcon.propTypes = {
  className: propTypes.string
}

TProjectIcon.propTypes = {
  className: propTypes.string
}

TExclamationCircleIcon.propTypes = {
  circleBackground: propTypes.string,
  className: propTypes.string
}

TCheckCircleIcon.propTypes = {
  circleBackground: propTypes.string,
  className: propTypes.string
}

TCloseCircleIcon.propTypes = {
  circleBackground: propTypes.string,
  className: propTypes.string
}

TSearchIcon.propTypes = {
  className: propTypes.string
}

TNotificationIcon.propTypes = {
  className: propTypes.string
}

TMenuIcon.propTypes = {
  className: propTypes.string
}

TCloseIcon.propTypes = {
  className: propTypes.string
}

TThinCloseIcon.propTypes = {
  className: propTypes.string
}

TFullFilledRightArrowIcon.propTypes = {
  className: propTypes.string
}

TCheckIcon.propTypes = {
  className: propTypes.string
}

TCompanyIcon.propTypes = {
  className: propTypes.string
}

TFilledEmailIcon.propTypes = {
  className: propTypes.string
}

TFilledUserGroup.propTypes = {
  className: propTypes.string
}

TFilledPlusIcon.propTypes = {
  className: propTypes.string
}

SquareFilledPlusIcon.propTypes = {
  className: propTypes.string
}

SlimPlusIcon.propTypes = {
  className: propTypes.string
}

TFilledLocationMarkerIcon.propTypes = {
  className: propTypes.string
}

TFilledChevronLeftIcon.propTypes = {
  className: propTypes.string
}

TFilledChevronUpDownIcon.propTypes = {
  className: propTypes.string
}

TFilledPenIcon.propTypes = {
  className: propTypes.string
}

TFilledUserCircle.propTypes = {
  className: propTypes.string,
  id: propTypes.string
}

TConfigIcon.propTypes = {
  className: propTypes.string
}

TDocumentIcon.propTypes = {
  className: propTypes.string
}

TUserCircleIcon.propTypes = {
  className: propTypes.string
}

TLogoutIcon.propTypes = {
  className: propTypes.string
}

TCreditCardIcon.propTypes = {
  className: propTypes.string
}

TBookmarkIcon.propTypes = {
  className: propTypes.string
}

TFilledChevronDownIcon.propTypes = {
  className: propTypes.string,
  id: propTypes.string
}

TFilledCellphoneIcon.propTypes = {
  className: propTypes.string
}

TFilledPhoneIcon.propTypes = {
  className: propTypes.string
}

TFilledHomeIcon.propTypes = {
  className: propTypes.string
}

IndividualBroker.propTypes = {
  className: propTypes.string
}

MultibrokerCompetetive.propTypes = {
  className: propTypes.string
}

MultibrokerSequenced.propTypes = {
  className: propTypes.string
}

ChevronDivider.propTypes = {
  className: propTypes.string
}

LoadingIcon.propTypes = {
  className: propTypes.string
}
