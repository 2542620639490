import React from 'react'
import ProjectCustomSection from '../components/ProjectCustomSection'
import { useParams } from 'react-router-dom'

const EditProjectCustomSection = () => {
  const { _id } = useParams()

  return (
    <div className='bg-white min-h-screen relative'>
      <div className='p-6 flex justify-between items-center'>
        <span className='font-bold text-3xl font-openSans'>Project Management</span>
      </div>
      <div className='border-b-2 border-black' />
      <div className='m-6'>
        <ProjectCustomSection _id={_id} />
      </div>
    </div>
  )
}

export default EditProjectCustomSection
