import React, { useCallback } from 'react'
import ReactDOM from 'react-dom'
import { Transition } from '@headlessui/react'
import { classNames } from 'utils'

let modalRoot = document.getElementById('modal')

if (!modalRoot) {
  modalRoot = document.createElement('div')
  modalRoot.setAttribute('id', 'modal')
  modalRoot.className = 'fixed z-9999'
  document.body.appendChild(modalRoot)
} else {
  // @ts-ignore
  modalRoot.style = { zIndex: 9999 }
}

const CustomModal = ({
  showModal,
  children,
  onCancel,
  canDismiss = true,
  fullHeight = false,
  fullWidth = false,
  minHeight = true
}) => {
  const renderSimpleModal = useCallback(() => children, [children])

  return ReactDOM.createPortal(
    <Transition
      show={showModal}
      className='h-screen fixed z-9999 inset-0 overflow-y-auto w-full md:w-auto flex items-center justify-center'
    >
      <div
        className={classNames(
          `${
            fullHeight && 'h-full'
          } flex items-center justify-center pt-4 px-4 pb-20 text-center sm:block sm:p-0 w-full ${
            !fullWidth && 'md:w-auto'
          }`
        )}
      >
        <Transition.Child
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
          className='fixed inset-0 transition-opacity w-full md:w-auto'
          aria-hidden='true'
        >
          <div
            onClick={canDismiss && onCancel}
            className='fixed inset-0 transition-opacity'
            aria-hidden='true'
          >
            <div className='absolute inset-0 bg-black opacity-75' />
          </div>
        </Transition.Child>

        <span
          className='hidden sm:inline-block sm:align-middle sm:h-screen'
          aria-hidden='true'
        >
          &#8203;
        </span>
        <Transition.Child
          enter='ease-out duration-300'
          enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          enterTo='opacity-100 translate-y-0 sm:scale-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100 translate-y-0 sm:scale-100'
          leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          className='inline-block align-bottom rounded-lgn text-left overflow-hidden transform transition-all sm:align-middle w-full h-full'
          role='dialog'
          id='firstDiv'
        >
          <div
            id='secondDiv'
            className='h-full inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-full'
            role='dialog'
            aria-modal='true'
            aria-labelledby='modal-headline'
          >
            <div
              className={classNames(
                'bg-white overflow-auto p-4 md:p-6 w-full max-h-[95vh]',
                minHeight && 'min-h-[60vh]'
              )}
            >
              {renderSimpleModal()}
            </div>
          </div>
        </Transition.Child>
      </div>
    </Transition>,
    modalRoot
  )
}

export default CustomModal
