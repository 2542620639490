import React from 'react'
import { Button, TButton } from 'ui'
import propTypes from 'prop-types'
import standardStyle from 'assets/css/standardStyle'

export const SaveButton = (props) => {
  const {
    onClick, loading, parentIsLoading,
    size, label
  } = props

  return (
    <Button
      onClick={onClick}
      background={standardStyle.colors.green}
      hoverColor={standardStyle.colors.hoverGreen}
      loading={loading}
      parentIsLoading={parentIsLoading}
      size={size}
    >
      {label}
    </Button>
  )
}

export const TSaveButton = (props) => {
  const {
    onClick, parentIsLoading,
    children, className, loading,
    disabled
  } = props

  return (
    <TButton
      onClick={onClick}
      parentIsLoading={parentIsLoading}
      backgroundColor='bg-green-600'
      backgroundHoverColor='bg-green-700'
      focus='focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500'
      border='border-none'
      shadow='shadow-sm'
      className={`${className}`}
      loading={loading}
      disabled={disabled}
    >
      {children}
    </TButton>
  )
}

export const CancelButton = (props) => {
  const {
    onClick, style, parentIsLoading,
    size, label
  } = props

  return (
    <Button
      onClick={onClick}
      background={standardStyle.colors.cancelButton}
      hoverColor={standardStyle.colors.cancelButtonHover}
      style={style}
      parentIsLoading={parentIsLoading}
      size={size}
    >
      {label}
    </Button>
  )
}

export const TCancelButton = (props) => {
  const {
    onClick, parentIsLoading,
    children, className, disabled
  } = props

  return (
    <TButton
      onClick={onClick}
      parentIsLoading={parentIsLoading}
      backgroundColor='bg-gray-700'
      backgroundHoverColor='bg-gray-800'
      focus='focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600'
      border='border-none'
      shadow='shadow-sm'
      className={`${className}`}
      disabled={disabled}
    >
      {children}
    </TButton>
  )
}

export const TDeleteButton = (props) => {
  const {
    onClick, parentIsLoading,
    children, className, loading,
    disabled
  } = props

  return (
    <TButton
      onClick={onClick}
      parentIsLoading={parentIsLoading}
      backgroundColor='bg-red-500'
      backgroundHoverColor='bg-red-600'
      focus='focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-400'
      border='border-none'
      shadow='shadow-sm'
      className={`${className}`}
      loading={loading}
      disabled={disabled}
    >
      {children}
    </TButton>
  )
}

SaveButton.defaultProps = {
  label: 'Save'
}

CancelButton.defaultProps = {
  label: 'Cancel'
}

SaveButton.propTypes = {
  onClick: propTypes.func,
  loading: propTypes.bool,
  parentIsLoading: propTypes.bool,
  size: propTypes.string,
  label: propTypes.string
}

TSaveButton.propTypes = {
  onClick: propTypes.func,
  parentIsLoading: propTypes.bool,
  loading: propTypes.bool,
  children: propTypes.node,
  className: propTypes.string,
  disabled: propTypes.bool
}

CancelButton.propTypes = {
  onClick: propTypes.func,
  style: propTypes.object,
  parentIsLoading: propTypes.bool,
  size: propTypes.string,
  label: propTypes.string
}

TCancelButton.propTypes = {
  onClick: propTypes.func,
  parentIsLoading: propTypes.bool,
  children: propTypes.node,
  className: propTypes.string,
  disabled: propTypes.bool
}

TDeleteButton.propTypes = {
  onClick: propTypes.func,
  parentIsLoading: propTypes.bool,
  children: propTypes.node,
  className: propTypes.string,
  loading: propTypes.bool,
  disabled: propTypes.string
}
