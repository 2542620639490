import React from 'react'
import { Select } from 'antd'
import { isMobile } from 'react-device-detect'

const SalesRepSelect = ({
  salesRep,
  salesrep,
  handleSalesRepChange,
  setSalesRep,
  developerAdminId,
  projectId,
  buyerEmail,
  userType,
  buyerData,
  forceMobile = false
}) => {
  return (
    (userType === 'Buyer' || userType === 'LeadBroker') && (
          <div
            className={`${isMobile || forceMobile ? 'mt-10' : ''}`}
            style={{
              position: 'relative',
              overflow: 'visible',
              marginBottom: '20px',
              marginRight: '10px'
            }}
          >
                  <div
                    className='text-softBlack70 absolute w-full text-lg top-6 font-light ml-2.5 '
                    style={{
                      top: '-25px',
                      fontSize: '18px',
                      marginBottom: '10px'
                    }}
                  >
              Sales Rep
            </div>
            <Select
              showSearch={true}
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toString()
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              value={`${salesrep?.firstName ?? ''} ${salesrep?.lastName ?? ''}`}
              onChange={(salesRepId) => {
                handleSalesRepChange({ create: !salesrep?.firstName }, { salesRep: salesRepId, developerAdmin: developerAdminId, project: projectId, buyer: buyerEmail })
              }}
              className='rating-select text-sm bg-white border border-softBlack30 h-8 w-48 rounded text-softBlack placeholder-softBlack70 py-1'
            >
              {salesRep?.map?.((_item, index) => (
                _item?.firstName &&
                <Select.Option value={_item?._id} key={index} label={`${_item?.firstName} ${_item?.lastName}`}>
                  <div className='flex gap-2 items-center'>
                    {_item?.firstName} {_item?.lastName}
                  </div>
                </Select.Option>
              ))}
            </Select>
          </div>
    )
  )
}

export default SalesRepSelect
