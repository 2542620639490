/*eslint-disable */
// @ts-nocheck
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { Oval } from "react-loader-spinner";
import * as Yup from "yup";
import { message, Select } from "antd";
import { Button } from "@material-ui/core";
import CustomInput from "components/CustomInput";
import CustomRadio from "components/CustomRadio";
import CustomModal from "components/Modal";
import { ReactComponent as CrossIcon } from "assets/icons/cross.svg";
import { ContactContext } from "pages/contacts/context/Contacts";
import useGetLeadBrokers from "hooks/useGetLeadBrokers";
import useGetSalesReps from "hooks/useGetSalesReps";
import useBrokerageCompanies from "hooks/useBrokerageCompanies";
import useUpdateUsers from "hooks/useUpdateUsers";
import useLeadSource from "hooks/useLeadSource";
import { isMobile } from "react-device-detect";
import CustomButton from "components/CustomButton";
import useContactTypes from "hooks/useContactTypes";
import useContactContext from "pages/newContacts/hooks/useContactContext";
import Checkbox from "pages/offer/components/Checkbox";
import { getCorporatDemandNotes } from "store/actions/demandNotes";
import { getProjects } from "store/actions/projectActions";
import CustomSelect from "components/VerticalCardProfile/components/CustomSelect";
import useGetLists from "hooks/useGetLists";
import toast from "react-hot-toast";

const initialEmptyValues = {
  firstName: "",
  lastName: "",
  email: "",
  notes: "",
  phoneNumber: "",
  userType: "Buyer",
  projectInterests: [],
};

const buttonStyle = {
  backgroundColor: "#EDDF65",
  height: "45px",
};

const AddOrEditContactNew = ({
  show,
  setShow,
  contact,
  onSuccess,
  broker = "",
  userType,
  invalideCacheOnUpdate = false,
}) => {
  const { createOrUpdateUser } = useUpdateUsers(invalideCacheOnUpdate);
  const projectSelector = (state) => state.appReducer.appProject;
  const appProject = useSelector(projectSelector);
  const userSelector = (state) => state.authReducer.userObject;
  const { view } = useContactContext();
  const userObject = useSelector(userSelector);
  const initialValues = {
    ...initialEmptyValues,
    userType: userType?.length ? userType : "Buyer",
    projectInterests: view === "corporate" ? [] : [
        appProject
    ],
  };
  const { setSelectedContact, setIsLoading } = useContext(ContactContext);
  const { data: brokersData, refetchBrokers } = useGetLeadBrokers();
  const { formattedLeadsources: leadSources } = useLeadSource();
  const [selectedLeadSource, setSelectedLeadSource] = useState("None");
  const [brokers, setBrokers] = useState([]);
  const [selectedBroker, setSelectedBroker] = useState("");
  const [selectedContactType, setSelectedContactType] = useState("");
  const [brokerageCompany, setBrokerageCompany] = useState("");
  const [state, setState] = useState({ ...initialValues });
  const [loading, setLoading] = useState(false);
  const [selectedSalesRep, setSelectedSalesRep] = useState(null);
  const [newBrokerageCompany, setNewBrokerageCompany] = useState("");
  const { salesReps, refetch } = useGetSalesReps();
  const { companies, update } = useBrokerageCompanies();
  const { contactTypeFormattedData } = useContactTypes();
  const [formOptions, setFormOptions] = useState([]);
  const [hearAboutUsOptions, setHearAboutUsOptions] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [contactType, setContactType] = useState("Buyer");
  const [lookForWilderLabelId, setLookForWilderLabelId] = useState("");
  const [hearAboutUsLabelId, setHearAboutUsLabelId] = useState("");
  const { lists } = useGetLists();
  const [listData, setListData] = useState([]);

  useEffect(() => {
    getProjects().then((res) => {
      let mapped = res.map((item) => {
        return {
          label: item.projectName,
          value: item._id,
          _id: item._id,
        };
      });
      setProjectList(mapped);
    });
  }, []);

  useEffect(() => {
    getCorporatDemandNotes().then((res) => {
      let mapped = res
        .filter((item) => item.showInForm === true)
        .map((item) => {
          return {
            title: item.label,
            value: item._id,
            isMulti: item.isMulti,
            options: item.options.map((item) => {
              return {
                label: item.label,
                value: item.id,
                _id: item.id,
              };
            }),
          };
        });
      setFormOptions(mapped);
    });
  }, []);

  // useEffect(() => {
  //   getCorporatDemandNotes("How did you hear about us?").then((res) => {
  //     setHearAboutUsLabelId(res._id);
  //     let mapped = res?.options?.map((item) => {
  //       return {
  //         label: item.label,
  //         value: item.id,
  //       };
  //     });
  //     setHearAboutUsOptions(mapped);
  //   });
  // }, []);

  useEffect(() => {
    setBrokers(brokersData);
  }, [brokersData]);

  useEffect(() => {
    setSelectedBroker(broker);
  }, [broker]);

  useEffect(() => {
    if (show && !brokersData?.length) refetchBrokers();
    if (
      show &&
      !salesReps?.length &&
      userObject?.userType === "DeveloperAdmin"
    ) {
      refetch();
    }
  }, [
    brokersData?.length,
    refetch,
    refetchBrokers,
    salesReps?.length,
    show,
    userObject?.userType,
  ]);

  const onChange = useCallback(
    (fieldName, value) => {
      setState((prevState) => ({ ...prevState, [fieldName]: value }));
    },
    [setState]
  );

  const handleClose = () => {
    if (loading) {
      return;
    }
    setState({ ...initialValues });
    setShow(false);
  };

  const onValidate = () => {
    const requiredFields = ["firstName"];
    for (let i = 0; i < requiredFields.length; i++) {
      if (state[requiredFields[i]] === "") {
        return false;
      }
    }
    if (
      (state.phoneNumber === undefined || state.phoneNumber === "") &&
      state.email === ""
    ) {
      return false;
    }
    return true;
  };

  const checkValidation = async () => {
    const isEmailValid = await Yup.string().email().isValid(state.email);
    if (!isEmailValid) {
      message.error("Please fill all the required field");
      return false;
    }
    return true;
  };

  const onSave = async () => {
    if (loading || !(await checkValidation())) {
      return;
    }
    if(!onValidate()) {
      toast.error("Please fill all the required field");
      return;
    }
    let success = false;
    // If string has no valid number than return true
    if (!isNaN(parseInt(selectedLeadSource))) {
      success = true;
    }
    let leadSource;
    if (!success) {
      leadSource = leadSources?.[0]?.label;
    } else {
      leadSource = leadSources?.[selectedLeadSource]?.label;
    }
    let finalCorporateDemands = Object.keys(state?.corporateDemands || {}).map(
      (item) => {
        console.log(item, "item");
        return {
          label: item,
          values: state.corporateDemands[item].filter((item) => item !== null),
        };
      }
    );
    const salesRep =
      userObject?.userType === "SalesRep" && state.userType !== "Buyer"
        ? userObject?._id
        : selectedSalesRep;

    const buyerParams = {
      firstName: state.firstName,
      lastName: state.lastName,
      email: state.email,
      project: state.projectInterests,
      userType: state.userType,
      buyerData: {
        phoneNumber: state.phoneNumber,
        additionalFields: [
          {
            fieldType: "LeadForm",
            name: "Notes",
            project: appProject,
            value: state.notes,
          },
        ],
        leadSource,
        assignments: [
          {
            salesRep,
          },
        ],
        contactType: selectedContactType || undefined,
        corporateDemands: finalCorporateDemands,
      },
      leadBroker: selectedBroker || undefined,
      salesRep,
    };
    const brokerParams = {
      firstName: state.firstName,
      lastName: state.lastName,
      email: state.email,
      userType: state.userType,
      leadBrokerData: {
        phoneNumber: state.phoneNumber,
        notes: state.notes,
        leadSource,
        brokerageCompany: brokerageCompany || undefined,
        assignments: [
          {
            salesRep
          }
        ],
        corporateDemands: finalCorporateDemands,
      },
      project: state.projectInterests,
      salesRep,
    };
    const params = state.userType === "Buyer" ? buyerParams : brokerParams;
    try {
      setLoading(true);
      const res = await createOrUpdateUser(params);
      if (!contact?._id) {
        setState({ ...initialEmptyValues });
      }
      try {
        setShow?.(false);
        setLoading(false);
        onSuccess?.(res._id);
        setSelectedContact?.(res._id);
        setIsLoading(true);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  useEffect(() => {
    if (newBrokerageCompany) {
      setBrokerageCompany(newBrokerageCompany);
    }
  }, [companies, newBrokerageCompany]);

  const heading = useMemo(() => {
    if (userType?.length) {
      if (userType === "LeadBroker") {
        return contact?._id ? "Update Broker" : "New Broker";
      } else if (userType === "Buyer") {
        return contact?._id ? "Update Buyer" : "New Buyer";
      }
    }
    return contact?._id ? "Update Contact" : "New Contact";
  }, [contact?._id, userType]);

  useEffect(() => {
    onChange("userType", userType);
  }, [onChange, userType]);

  return (
    <CustomModal
      isOpen={show}
      closeModal={handleClose}
      contentLabel="Add Or Edit Contact Modal"
    >
      <div className="font-openSans relative bg-white min-w-[600px] z-[-10] p-4 md:p-7 lg:p-10 rounded shadow-lg w-full">
        <button
          id="close-new-contact-form"
          className="absolute right-4 md:right-7 lg:right-10"
          onClick={handleClose}
        >
          <CrossIcon />
        </button>
        <div className="flex flex-col gap-y-4">
          <h1 className="font-bold text-xl p-0 m-0">Select Contact Type</h1>
          <div className="flex mt-2">
            <div
              className="inline-flex rounded-[8px] p-1 border border-gray-300"
              role="group"
            >
              {["Buyer", "LeadBroker"].map((type, index) => (
                <button
                  key={type}
                  type="button"
                  className={`px-6 py-2 text-sm font-medium transition-colors duration-200
          ${
            state.userType === type
              ? "bg-blue-50 text-black"
              : "bg-transparent text-black"
          }
          ${index === 0 ? "rounded-[6px]" : ""}
          ${index === 1 ? "rounded-[6px]" : ""}
          focus:outline-none`}
                  onClick={() => onChange("userType", type)}
                >
                  {type}
                </button>
              ))}
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <CustomInput
              label="Name *"
              placeholder="Enter first name"
              value={state.firstName}
              onChange={(e) => onChange("firstName", e.target.value)}
            />
            <CustomInput
              label="Last Name"
              placeholder="Enter last name"
              value={state.lastName}
              onChange={(e) => onChange("lastName", e.target.value)}
            />
            <CustomInput
              label="Email *"
              placeholder="Enter email"
              value={state.email}
              onChange={(e) => onChange("email", e.target.value)}
              type="email"
            />
            <CustomInput
              label="Phone Number"
              placeholder="Enter phone number"
              value={state.phoneNumber}
              onChange={(e) => onChange("phoneNumber", e.target.value)}
              type="tel"
            />
          </div>
          <div className="flex flex-col gap-y-6">
            {formOptions.map((item) => (
              <div className="">
                <CustomSelect
                  label={item.title}
                  options={item.options}
                  value={state[`${item.value}`]}
                  handleChange={(val) => {
                    let value = val;
                    let isArray = Array.isArray(value);
                    if (!isArray) {
                      value = [value];
                    }
                    setState((prevState) => ({
                      ...prevState,
                      corporateDemands: {
                        ...prevState.corporateDemands,
                        [item.value]: value,
                      },
                    }));
                  }}
                  mode={item.isMulti ? "tags" : "single"}
                  optionFilterProp="label"
                />
              </div>
            ))}
          </div>
          <CustomSelect
            label="Which project(s) are you interested in?"
            options={projectList}
            value={state.projectInterests}
            handleChange={(value) => {
              let selectedProjects = Array.isArray(value) ? value : [value];
              setState((prevState) => ({
                ...prevState,
                projectInterests: selectedProjects,
              }));
            }}
            mode="tags"
            optionFilterProp="label"
          />
        </div>

        <CustomButton
          className="w-full h-[45px] mt-8"
          handleClick={onSave}
          // disabled={!onValidate()}
        >
          {loading ? (
            <Oval
              height={20}
              width={20}
              color="#fff"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
            />
          ) : (
            "Save"
          )}
        </CustomButton>
      </div>
    </CustomModal>
  );
};

export default AddOrEditContactNew;
