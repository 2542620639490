/*eslint-disable */
// @ts-nocheck
import React, { useState } from 'react';
import { deleteTask } from 'store/actions/taskActions';
import DeleteModal from 'components/DeleteModal';

import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { ReactComponent as TaskIcon } from 'assets/icons/task.svg';

import moment from 'moment';
import { classNames } from 'utils';
import { ContactIcon } from 'lucide-react';
import TaskModal from 'pages/tasks/components/TaskModal';

const TaskRow = ({ task, refetch, showPadding = true }) => {
  const { title, description, status, contact } = task;
  const [open, setOpen] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const deleteTheTask = () => {
    setDeleteLoading(true);
    deleteTask(task?._id)
      .then(() => {
        setIsDeleteModal(false);
        setDeleteLoading(false);
        refetch?.();
      })
      .catch(() => {
        setDeleteLoading(false);
      });
  };

  return (
    <>
      <div
        className={classNames(
          'group border border-softBlack_10 bg-white flex gap-4 rounded',
          showPadding ? 'p-6' : 'p-2'
        )}
      >
        <div>
          <TaskIcon />
        </div>
        <div className='w-full'>
          <div className='flex justify-between w-full'>
            <div className='font-semibold text-base text-softBlack leading-none'>
              {title}
            </div>
            <div className='flex gap-4'>
              <button onClick={() => setOpen(true)}>
                <EditIcon />
              </button>
              <button onClick={() => setIsDeleteModal(true)}>
                <TrashIcon />
              </button>
            </div>
          </div>
          <div className='text-base font-normal text-softBlack_70 mt-1'>
            {description}
          </div>
          <div className='flex flex-row gap-2 items-center mt-4'>
            <div
              className='bg-volt px-1 text-sm font-semibold h-6.75 w-fit flex justify-center items-center'
              style={{ letterSpacing: '0.16px', borderRadius: '2px' }}
            >
              {status}
            </div>
            <div>
              {task?.dueDate && (
                <div className='flex flex-row gap-4'>
                  <div className='font-extrabold italic text-md ml-2'>
                    DUE DATE
                  </div>
                  <div className='font-extrabold italic text-md'>
                    {moment(task.dueDate).format('DD/MM/YYYY')}
                  </div>
                </div>
              )}
            </div>
            <div className='flex justify-end flex-1 self-end text-end items-end w-full'>
              {task?.user?.firstName?.length > 0 && (
                <div className='self-stretch justify-start items-center gap-2 inline-flex'>
                  <div className='px-2 py-1 bg-[#F0EEE9] rounded-md justify-center items-center gap-1 flex'>
                    <div className='w-[22px] h-[22px] relative bg-zinc-800 bg-opacity-20 rounded-full flex items-center justify-center text-xs'>
                      {task?.user.firstName[0]}
                      {task?.user.lastName[0]}
                    </div>
                    <div className='text-zinc-800 text-sm font-normal leading-[21px]'>
                      {task?.user.firstName + ' ' + task?.user.lastName}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <TaskModal
        show={open}
        setClose={handleClose}
        contact={contact}
        task={task}
        refetch={refetch}
      />
      <DeleteModal
        title='Confirm deletion?'
        description='This task will be deleted permanently!'
        confirmButtonTitle='Delete Task'
        cancelButtonTitle='Cancel'
        isOpen={isDeleteModal}
        setIsOpen={setIsDeleteModal}
        onConfirm={deleteTheTask}
        loading={deleteLoading}
      />
    </>
  );
};

export default TaskRow;
