import { ContactProvider } from 'pages/contacts/context/Contacts'
import React from 'react'
import EditPage from './EditPage'
import { CreateOpportunityProvider } from '../context/CreateOpportunityContext'

const EditOpportunity = () => {
  return (
    <CreateOpportunityProvider>
        <ContactProvider>
          <EditPage />
        </ContactProvider>
    </CreateOpportunityProvider>
  )
}

export default EditOpportunity
