import { useState, useCallback } from 'react'
import { api, ApiErrors, getTokenRequestHeader } from 'services/api'

const useSendBuyerToSign = () => {
  const [loading, setLoading] = useState(false)

  const signDocument = useCallback(
    async (id) => {
      setLoading(true)
      const token = getTokenRequestHeader()
      // @ts-ignore
      api.setHeader('Content-Type', '', 'Authorization', token)
      const response = await api.post(`reservation/contracts/reservation/${id}`)
      setLoading(false)
      if (!response.ok) {
        return ApiErrors(response)
      }
    },
    [api]
  )

  return { signDocument, loading }
}

export default useSendBuyerToSign
