import React from 'react'
import SingularDocumentsSign from './SingularDocumentsSign'

const Contract = ({ singularContract, onClose, handleGoBack }) => {
  const contractId = singularContract?.contracts?.[0]?._id ?? null
  return (
    <SingularDocumentsSign
      contractId={contractId}
      isPublicPath={true}
      userObject={{ role: 'Buyer' }}
      // contracts={contractData?.offer?.contracts}
      setCloseDocument={onClose}
      handleGoBack={handleGoBack}
    />
  )
}

export default Contract
