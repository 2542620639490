// @ts-nocheck
/* eslint-disable */
import { Drawer, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { useSelector } from "react-redux";
import useContactContext from "../hooks/useContactContext";
import useGetProject from "hooks/useGetProjects";
import { isMobile } from "react-device-detect";
import { RiPencilFill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import useProjectsMutation from "hooks/useProjectMutations";

const AddToProjectDrawer = () => {
  const { state, closeAddToProjectDrawer, toggleProjectList } = useContactContext();
  const { projects, isLoading, refetch } = useGetProject();
  const [width80, setWidth80] = useState(window.innerWidth);
  const [search, setSearch] = useState("");
  const { addUsersToProject } = useProjectsMutation()

  const [selectedProject, setSelectedProject] = useState(null);

  useEffect(() => {
    setWidth80(
      window.innerWidth < 1400
        ? window.innerWidth * 0.55
        : window.innerWidth * 0.75
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerWidth]);

  useEffect(() => {
    refetch();
  }, []);

  const useStyles = makeStyles((theme) => ({
    hide: {
      display: "none",
    },
    drawer: {
      width: isMobile ? "100%" : width80,
      flexShrink: 0,
    },
    drawerPaper: {
      width: isMobile ? "100%" : width80,
      height: "100%",
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-end",
    },
  }));

  const classes = useStyles();
  useEffect(() => {
    refetch();
  }, []);

  const handleAddToProject = () => {
    const listOfUsers = Object.keys(state.checkedContacts).filter(key => state.checkedContacts[key] === true)
    const listOfIds = Object.keys(state.checkedProjects).filter(key => state.checkedProjects[key] === true)
    const previousData = {}
    projects?.filter(project => listOfIds.includes(project?._id)).forEach(list => {
        previousData[list?._id] = [
            ...listOfUsers
        ]
    })
    addUsersToProject({ previousData })
  }

  return (
    <Drawer
      open={state.isAddToProjectDrawerOpen}
      variant="persistent"
      anchor="right"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className="h-screen mt-16 relative overflow-hidden">
        <button
          onClick={closeAddToProjectDrawer}
          className="absolute top-3 right-5"
        >
          <IoMdClose fontSize="22px" />
        </button>
        <div className="h-full w-full flex flex-col p-5">
          <div className="flex flex-1 flex-col gap-4">
            <div className="text-3xl font-bold">ADD TO PROJECTS</div>
            <div className="flex w-full">
              <input
                placeholder="search"
                onChange={(e) => setSearch(e.currentTarget.value)}
              />
            </div>
            <div className="w-full flex items-center justify-end">
              <button
                disabled={
                  !Object.values(state.checkedContacts).filter(
                    (val) => val === true
                  ).length ||
                  !Object.values(state.checkedProjects).filter(
                    (val) => val === true
                  ).length
                }
                onClick={handleAddToProject}
                className="py-2 px-4 text-lg bg-[#18181B] text-white rounded-lg font-medium flex gap-2 items-center justify-center"
              >
                Add to Project
              </button>
            </div>
            <div
              style={{ maxHeight: "50%" }}
              className="flex flex-col gap-2 overflow-y-auto pr-4"
            >
              <div className="flex flex-col gap-2">
                {isLoading && "Loading projects..."}
                {!isLoading &&
                  projects?.length &&
                  projects
                    .filter((project) =>
                      search
                        ? project.projectName
                            .toLowerCase()
                            .includes(search.toLowerCase().trim())
                        : true
                    )
                    .reverse()
                    .map((project) => (
                        <div className='flex flex-row w-full justify-between items-center gap-5 border-2 border-gray-2 rounded p-2'>
                        <div className='flex flex-row items-center gap-2'>
                        <input checked={state.checkedProjects?.[project?._id] ?? false} onChange={e => toggleProjectList(project?._id)} type="checkbox" className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6 hover:cursor-pointer' />
                          <div>{project.projectName}</div>
                        </div>
                      </div>
                    ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default AddToProjectDrawer;
