import React from 'react'
import CustomButton from 'components/CustomButton'

// const CustomBadge = ({ text = '', bgColor = '#DC2626', textColor = '#FFFFFF' }) => (
//   <div
//     className='px-2 py-1 rounded-md shadow justify-start items-center gap-1 inline-flex'
//     style={{ backgroundColor: bgColor, color: textColor }}
//   >
//     <div className='text-center text-sm font-medium leading-none'>{text}</div>
//   </div>
// )

const CreatingReservation = ({ handleReservation, loading = false, reservationStatuses }) => {
  return (
    <>
      <div className='p-3 md:p-6 w-full flex justify-center items-center gap-2 md:gap-6 border-b border-[#E5E5E5]'>
        <CustomButton variant='outline' disabled={loading}>
          <span className='font-medium py-2 px-4 text-sm md:text-lg flex gap-1 items-center'>
            Save Draft
          </span>
        </CustomButton>
        <CustomButton className='flex-1 md:w-fit' handleClick={handleReservation} disabled={loading}>
          <span className='font-medium py-2 px-4 text-sm md:text-lg flex gap-1 items-center'>
            Approve & Send for Signature
          </span>
        </CustomButton>
      </div>
      {/* <div className='w-full flex flex-col gap-8 p-6 thin-scrollbar overflow-y-auto'>
        {
          reservationStatuses.map((status) => (
            <div key={status.id} className='w-full flex-col justify-center items-start gap-4 inline-flex'>
              <div className='text-zinc-950 text-lg font-semibold leading-normal'>{status.title}</div>
              {
                status.subFields.length > 0 &&
                <>
                  {
                    status.subFields.map((elm) => (
                      <div key={elm.id} className='flex-col justify-center items-start gap-2 flex'>
                        <div className='text-zinc-950 text-base font-medium leading-tight'>{elm.title}</div>
                        {
                          elm.badges.length > 0 &&
                          <>
                            {
                              elm.badges.map((badge) => (
                                <CustomBadge key={badge.if} text={badge.text} bgColor={badge.bgColor} textColor={badge.textColor} />
                              ))
                            }
                          </>
                        }
                      </div>
                    ))
                  }
                </>
              }
            </div>
          ))
        }
      </div> */}
    </>
  )
}

export default CreatingReservation
