import useDynamicDropdown from './useDynamicDropdown'

const useReasonForBuyerLost = () => {
  const {
    data: reasonsForBuyerLost,
    loading,
    update,
    deleteItem: deleteReasonForBuyerLost,
    updateItem: updateSpecific,
    formattedData: formattedReasonForBuyerLost
  } = useDynamicDropdown('reasonForLost')

  return {
    reasonsForBuyerLost,
    loading,
    update,
    deleteReasonForBuyerLost,
    updateSpecific,
    formattedReasonForBuyerLost
  }
}

export default useReasonForBuyerLost
