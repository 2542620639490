/* eslint multiline-ternary: ["error", "never"] */
// @ts-nocheck
/* eslint-disable */
import WhietCapLogo from '../assets/img/white-cap-logo.png';
import WhietCapImage from '../assets/img/white-cap-img.png';
import HighMarkLogo from '../assets/img/highmark-logo.png';
import HighMarkImage from '../assets/img/highmark-background-img.jpg';
import PacificaImage from '../assets/img/pacifica-img.png';
import PacificaLogo from '../assets/img/pacifica-logo.png';

export const ProjectData = [
  {
    name: 'Whitecap North Padre Island',
    logo: WhietCapLogo,
    id: 'whitecap',
    backgroundImg: WhietCapImage,
  },
  {
    name: '1818 Pacifica',
    logo: PacificaLogo,
    id: 'pacifica',
    backgroundImg: PacificaImage,
  },
  {
    name: 'HighMark',
    logo: HighMarkLogo,
    id: 'highmarkhomes',
    backgroundImg: HighMarkImage,
  },
];
