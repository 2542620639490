import CustomInput from 'components/CustomInput'
import React from 'react'
import LineText from './LineText'

const AdditionalBuyerItem = ({
  buyerTag,
  defaultData,
  onChange,
  editMode = true
}) => {
  return (
    <>
      <LineText title={`Additional ${buyerTag}`} />
      <div className='max-w-full text-center rounded relative pl-0'>
        <div className='grid grid-cols-2 gap-6'>
          <CustomInput
            classes='max-h-14'
            label={buyerTag + 'Full Name'}
            placeholder='Enter full name'
            onChange={(e) => onChange('fullName', e.target.value)}
            value={defaultData.fullName || ''}
            disabled={!editMode}
            containerClasses='col-span-2'
          />
          <CustomInput
            classes='max-h-14'
            label={buyerTag + 'Email'}
            placeholder='Enter email'
            onChange={(e) => onChange('email', e.target.value)}
            value={defaultData.email || ''}
            containerClasses='col-span-1'
            disabled={!editMode}
          />
          <CustomInput
            classes='max-h-14'
            label={buyerTag + 'Telephone'}
            placeholder='Enter telephone'
            onChange={(e) => onChange('telephone', e.target.value)}
            value={defaultData.telephone || ''}
            containerClasses='col-span-1'
            disabled={!editMode}
          />
          <CustomInput
            classes='max-h-14'
            label={buyerTag + 'Address'}
            placeholder='Enter address'
            onChange={(e) => onChange('address', e.target.value)}
            value={defaultData.address || ''}
            containerClasses='col-span-2'
            disabled={!editMode}
          />
          <CustomInput
            classes='max-h-14'
            label={buyerTag + 'Notification Address'}
            placeholder='Enter notification address'
            onChange={(e) => onChange('notificationAddress', e.target.value)}
            value={defaultData.notificationAddress || ''}
            containerClasses='col-span-2'
            disabled={!editMode}
          />
          <CustomInput
            classes='max-h-14'
            label={buyerTag + 'RFC'}
            placeholder='Enter RFC'
            onChange={(e) => onChange('rfc', e.target.value)}
            value={defaultData.rfc || ''}
            containerClasses={undefined}
            disabled={!editMode}
          />
          <CustomInput
            classes='max-h-14'
            label={buyerTag + 'CURP'}
            placeholder='Enter CURP'
            onChange={(e) => onChange('curp', e.target.value)}
            value={defaultData.curp || ''}
            containerClasses={undefined}
            disabled={!editMode}
          />
          <CustomInput
            type='datetime-local'
            classes='max-h-14'
            label={buyerTag + 'Birth Date'}
            placeholder='Enter birth date'
            onChange={(e) => onChange('birthDate', e.target.value)}
            value={defaultData.birthDate || ''}
            containerClasses={undefined}
            disabled={!editMode}
          />
          <CustomInput
            classes='max-h-14'
            label={buyerTag + 'Place of Birth'}
            placeholder='Enter place of birth'
            onChange={(e) => onChange('placeOfBirth', e.target.value)}
            value={defaultData.placeOfBirth || ''}
            containerClasses={undefined}
            disabled={!editMode}
          />
          <CustomInput
            classes='max-h-14'
            label={buyerTag + 'Marital Status'}
            placeholder='Enter marital status'
            onChange={(e) => onChange('maritalStatus', e.target.value)}
            value={defaultData.maritalStatus || ''}
            containerClasses={undefined}
            disabled={!editMode}
          />
          <CustomInput
            classes='max-h-14'
            label={buyerTag + 'Nationality'}
            placeholder='Enter Nationality'
            onChange={(e) => onChange('nationality', e.target.value)}
            value={defaultData.nationality || ''}
            containerClasses={undefined}
            disabled={!editMode}
          />
        </div>
      </div>
    </>
  )
}

export default AdditionalBuyerItem
