/* eslint-disable */
import React, { useCallback, useMemo } from 'react'
import Skeleton from 'react-loading-skeleton'
import {
  sanitizeCode
} from 'utils'
import EmailAttachments from './EmailAttachments'
import moment from 'moment'
import { isMobile } from 'react-device-detect'
import CustomButton from 'pages/contacts/CustomButton'
import { ChevronDown, ChevronLeft, ChevronUp, Forward, MailPlus, Reply } from 'lucide-react'

// const EmailNavigationButton = ({
//   onClick,
//   title,
//   icon: Icon,
//   className,
//   disabled
// }) => {
//   return (
//     <button
//       onClick={onClick}
//       className={classNames(
//         'relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-blue-600',
//         className
//       )}
//       disabled={disabled}
//     >
//       <span className='sr-only'>{title}</span>
//       <Icon className='h-5 w-5' aria-hidden='true' />
//     </button>
//   )
// }

const SelectedMailSection = ({
  gettingThreadContent,
  selectedEmailIndex,
  setSelectedEmailIndex,
  showEmailBox,
  setShowMessageList,
  setShowEmailBox,
  selectedThreadContent,
  setComposingEmailRecipient,
  setComposingEmailBody,
  setComposingEmailSubject,
  listOfEmails,
  selectedEmail
}) => {
  const reversedSelectedEmail = useMemo(() => {
    if (selectedEmail?.length) {
      return selectedEmail.reverse()
    }
    return []
  }, [selectedEmail])


  const renderSelectedEmailThread = useCallback(() => {
    if (gettingThreadContent) {
      return (
        <div className='h-full flex flex-col gap-6 py-4 px-6 bg-white'>
          <Skeleton height={100} />
        </div>
      )
    }

    if (!selectedThreadContent || !Object.keys(selectedThreadContent).length) return null
    return (
      <div className='min-h-0 flex-1 flex flex-col overflow-hidden bg-white'>
        <ul className=' flex-1 divide-y overflow-y-auto thin-scrollbar'>
          {selectedThreadContent.items.map((item, index) => (
            <li
              key={item.id}
              className='bg-white flex flex-col gap-y-6'
            >
              <div className='flex flex-col p-4 border-b border-gray-200'>
                <div className='w-full flex justify-between items-center'>
                  <h2 className='text-xl font-medium text-[#2E2B2E] m-0 flex gap-1'>
                    <span className='text-gray-900'>From:</span>
                    <span className='text-gray-900 capitalize'>{selectedThreadContent.senderName}</span>
                  </h2>
                  <p className='text-sm font-normal text-softBlack_70/50 m-0 flex flex-wrap gap-1'>{moment(item.datetime * 1000).format('DD MMM, hh:mm a')}</p>
                </div>
                {
                  reversedSelectedEmail &&
                  <p className='text-sm font-normal text-softBlack_70/50 m-0 flex flex-wrap gap-1'>
                    <span>
                      To: {reversedSelectedEmail[index]?.to[0]?.email}{reversedSelectedEmail[index]?.cc.length ? ',' : ''}
                    </span>
                    {
                      reversedSelectedEmail[index]?.cc.length > 0 &&
                      <span>
                        cc: {reversedSelectedEmail[index]?.cc.map((elm) => elm.email).join(', ')}
                      </span>
                    }
                  </p>
                }
              </div>
              <div className=' p-4'>
                <h3
                  id='message-heading'
                  className='text-2xl font-semibold text-gray-900 mb-5'
                >
                  {selectedThreadContent.subject}
                </h3>
                <div
                  className='mt-4 space-y-6 text-sm text-gray-800'
                  dangerouslySetInnerHTML={{ __html: item.body }}
                />
              </div>
              <EmailAttachments files={item.files} threadId={item.messageId} message={item.snippet} />
            </li>
          ))}
        </ul>
      </div>
    )
  }, [selectedThreadContent, gettingThreadContent])

  const onReplyForwardClick = useCallback(
    (action) => {
      // Get most recent email of the threa
      if (selectedThreadContent.items && selectedThreadContent.items.length) {
        const latestEmailBody = selectedThreadContent.items[0].body

        setComposingEmailBody(sanitizeCode(latestEmailBody))
        if (action === 'reply') {
          setComposingEmailRecipient([
            {
              name: selectedThreadContent.senderName,
              email: selectedThreadContent.senderEmail
            }
          ])
        }
        setComposingEmailSubject(
          `${action === 'reply' ? 'Re' : 'Fwd'}: ${selectedThreadContent.subject
          }`
        )
        setShowEmailBox(true)
      }
    },
    [selectedThreadContent]
  )

  return (
    <section
      aria-labelledby='message-heading'
      className='min-w-0 flex-1 h-full flex flex-col overflow-hidden'
    >
      <div className='w-full flex items-center p-2 justify-between border-b border-gray-200 gap-2 bg-white'>
        <div className='flex items-center gap-2 w-full justify-between'>
          {isMobile && (
            <CustomButton variant='ghost' handleClick={() => setShowMessageList(true)} disabled={gettingThreadContent}>
              <span className='p-2 px-3'>
                <ChevronLeft className='w-5 h-5' />
              </span>
            </CustomButton>
          )}
        </div>
        <div className='w-full md:w-fit flex items-center justify-end md:justify-start'>
          <CustomButton variant='ghost' handleClick={() => setShowEmailBox(true)} disabled={gettingThreadContent}>
            <span className='p-2 px-3'>
              <MailPlus className='w-5 h-5' />
            </span>
          </CustomButton>
          <CustomButton variant='ghost' handleClick={() => onReplyForwardClick('reply')} disabled={gettingThreadContent}>
            <span className='p-2 px-3'>
              <Reply className='w-5 h-5' />
            </span>
          </CustomButton>
          <CustomButton variant='ghost' handleClick={() => onReplyForwardClick('forward')} disabled={gettingThreadContent}>
            <span className='p-2 px-3'>
              <Forward className='w-5 h-5' />
            </span>
          </CustomButton>
          <div data-orientation="vertical" role="none" className="shrink-0 bg-black70 w-[1px] mx-2 h-6" />
          <CustomButton
            variant='ghost'
            handleClick={() => {
              if (selectedEmailIndex > 0) {
                setSelectedEmailIndex(selectedEmailIndex - 1)
              }
            }}
            disabled={selectedEmailIndex <= 0}
          >
            <span className='p-2 px-3'>
              <ChevronUp className='w-5 h-5' />
            </span>
          </CustomButton>
          <CustomButton
            variant='ghost'
            handleClick={() => {
              if (selectedEmailIndex < listOfEmails?.length - 1) {
                setSelectedEmailIndex(selectedEmailIndex + 1)
              }
            }}
            disabled={selectedEmailIndex >= listOfEmails?.length - 1}
          >
            <span className='p-2 px-3'>
              <ChevronDown className='w-5 h-5' />
            </span>
          </CustomButton>
        </div>
      </div>
      {renderSelectedEmailThread()}
    </section>
  )
}

export default SelectedMailSection
