import React from 'react'
import { CgChevronLeft } from 'react-icons/cg'
import { useHistory } from 'react-router-dom'

const BackButton = () => {
  const history = useHistory()
  return (
    <button
      onClick={() => {
        history.goBack()
      }}
      className='flex content-center items-center font-semibold text-base'
    >
      <CgChevronLeft className='w-8 h-8' />
      <span className='ml-[19.45px]'>BACK</span>
    </button>
  )
}

export default BackButton
