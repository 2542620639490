import React from 'react'
import InventoryContextProvider from 'components/Inventory/context/InventoryContext'
import ProjectInventory from 'components/Inventory'

const ParkingInventoryWrapper = ({ appProject }) => {
  return (
    <InventoryContextProvider>
      <ProjectInventory/>
    </InventoryContextProvider>
  )
}

export default ParkingInventoryWrapper
