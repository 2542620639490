/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { getAllEscrowAgents } from 'store/actions/usersActions'

const useGetEscrowAgents = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  const getUsers = async () => {
    setLoading(true)
    getAllEscrowAgents()
      .then((response) => {
        setData(
          response.sort((a, b) => {
            if (
              `${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}`
            ) {
              return 1
            } else if (
              `${a.firstName} ${a.lastName}` < `${b.firstName} ${b.lastName}`
            ) {
              return -1
            }
            return 0
          })
        )
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    getUsers()
  }, [])

  return { escrowAgents: data, loadingEscrowAgents: loading, refetchEscrowAgents: getUsers }
}

export default useGetEscrowAgents
