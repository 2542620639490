import { motion } from 'framer-motion'
import styled from 'styled-components'

export const GetPhotoIdMethodButton = styled(motion.div)`
  background: ${props => props.background};
  color: white;
  /* position: absolute; */
  height: 100%;
  align-items: center;
  justify-content: center;
  /* width: 50%; */
  flex-direction: column;
  z-index: 10;
  padding: 0px 10px;
  text-align: center;

  :hover{
    cursor: pointer;
  }
`
