import styled from 'styled-components'
import { Row } from 'antd'
import { DeleteIcon } from 'components/Icons'
import standardStyle from 'assets/css/standardStyle'
import { motion } from 'framer-motion'

export const ButtonsWrapper = styled(Row)`
  font-size: 23px;
  
  span {
    margin: 0px 5px;
  }
`

export const CustomDeleteIcon = styled(DeleteIcon)`
  :hover {
    color: ${standardStyle.colors.danger};
    cursor: pointer;
  }

`

export const EditIconWrapper = styled(motion.div)`
  display: flex;
  align-items: center;

  :hover {
    cursor: pointer;
  }
`
