import styled from 'styled-components'
import { Row, Col } from 'antd'
import standardStyle from 'assets/css/standardStyle'

export const ButtonWrapper = styled(Row)`
  align-items: center;
  margin: 60px 0px;

  @media ${standardStyle.mediaqueries.xs}, ${standardStyle.mediaqueries.sm} {
    margin: 20px 0px;
    justify-content: center;
  }
`

export const TabsWrapper = styled(Col)``

export const SwitchContainer = styled.div`
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${standardStyle.mediaqueries.xs}, ${standardStyle.mediaqueries.sm} {
    margin-top: 20px;
  }
`

export const UploadMethodsWrapper = styled.div``
