import { useEffect, useState } from 'react'
import { getReservedUnitInterests } from 'store/actions/reservationActions'

const useGetReservedUnitInterests = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  const getData = async (id) => {
    setLoading(true)
    try {
      const data = await getReservedUnitInterests()
      setData(data)
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return { loading, data, refetch: getData }
}

export default useGetReservedUnitInterests
