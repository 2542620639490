export const defaultRatingOptions = [
  {
    label: 'A',
    value: 'A',
    type: 'contact_rating_status',
    color: 'rgb(184, 224, 74)',
    bgColor: 'rgb(184, 224, 74, 0.3)'
  },
  {
    label: 'B',
    value: 'B',
    type: 'contact_rating_status',
    color: 'rgb(237, 223, 101)',
    bgColor: 'rgb(237, 223, 101, 0.3)'
  },
  {
    label: 'C',
    value: 'C',
    type: 'contact_rating_status',
    color: 'rgb(250, 173, 20)',
    bgColor: 'rgb(250, 173, 20, 0.3)'
  },
  {
    label: 'D',
    value: 'D',
    type: 'contact_rating_status',
    color: 'rgb(191, 87, 22)',
    bgColor: 'rgb(191, 87, 22, 0.3)'
  },
  {
    label: 'Unrated',
    value: 'Unrated',
    type: 'contact_rating_status',
    color: 'rgb(45, 42, 45)',
    bgColor: 'rgb(240, 238, 233)'
  }
]
