/* eslint-disable */

import React, { useState } from 'react';
import InventoryListView from './InventoryListView';
import { classNames } from 'utils';
import InventoryEditDrawer from './InventoryEditDrawer';
import InventoryEditDrawerUnit from './InventoryEditDrawerUnit';

const InventoryListViewWrapper = ({
  inventoryItems,
  loading,
  type = '',
  refetch,
  isFullHeight = false,
  selectedBuilding,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [editDetails, setEditDetails] = useState({});

  return (
    <div className='w-full h-full'>
      <div className='relative flex w-full h-full'>
        <div
          className={classNames(
            'w-full relative transition-all',
            isDrawerOpen ? 'mr-[350px]' : 'mr-0'
          )}
        >
          <InventoryListView
            inventoryItems={inventoryItems}
            loading={loading}
            type={type}
            onSelect={(val) => {
              setEditDetails(val);
              setIsDrawerOpen(true);
            }}
          />
        </div>
        {type === 'Unit' ? (
          <InventoryEditDrawerUnit
            editDetails={editDetails}
            setEditDetails={setEditDetails}
            closeInfoDrawer={() => setIsDrawerOpen(false)}
            isInfoDrawerOpen={isDrawerOpen}
            isAbleToPurchase
            refetch={refetch}
            type={type}
            selectedBuilding={selectedBuilding}
            isFullHeight={isFullHeight}
          />
        ) : (
          <InventoryEditDrawer
            editDetails={editDetails}
            setEditDetails={setEditDetails}
            closeInfoDrawer={() => setIsDrawerOpen(false)}
            isInfoDrawerOpen={isDrawerOpen}
            isAbleToPurchase
            refetch={refetch}
            type={type}
            selectedBuilding={selectedBuilding}
            isFullHeight={isFullHeight}
          />
        )}
      </div>
    </div>
  );
};

export default InventoryListViewWrapper;
