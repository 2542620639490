/* eslint-disable no-unused-vars */
import React from 'react'
import { ReactComponent as DownIcon } from 'assets/icons/down.svg'
import { useFormikContext } from 'formik'
import { Select } from 'antd'
import useDynamicDropdown from 'hooks/useDynamicDropdown'

const FormSelect = ({
  title = '',
  options = [],
  fieldName = '',
  placeholder = '',
  multi = false,
  customOptionSchema = false,
  customeLabelAccessor = 'label',
  customeValueAccessor = 'value',
  dynamic = false
}) => {
  const { handleChange, setFieldValue, values } = useFormikContext()
  const { data } = useDynamicDropdown(fieldName)
  return (
    <div key={fieldName} className='flex flex-1 flex-col justify-left'>
        <div className='font-bold text-softBlack_70 italic'>{title}</div>
        <Select
            suffixIcon={<DownIcon />}
            showArrow
            onChange={multi ? (e) => setFieldValue(fieldName, e) : handleChange(fieldName)}
            defaultValue={values[fieldName]}
            placeholder={placeholder}
            mode={multi ? 'multiple' : undefined}
            className='rating-select text-sm bg-white border border-softBlack30 rounded text-softBlack placeholder-softBlack70 h-10'
        >
            {dynamic && data?.values.map(option => <Select.Option value={customOptionSchema ? !!option[customeValueAccessor] : option}>
                <div className='flex gap-2 items-center'>
                    <span className='h-full'>{customOptionSchema ? option[customeLabelAccessor] : option}</span>
                </div>
            </Select.Option>)}
            {!dynamic && options.map(option => <Select.Option value={customOptionSchema ? !!option[customeValueAccessor] : option}>
                <div className='flex gap-2 items-center'>
                    <span className='h-full'>{customOptionSchema ? option[customeLabelAccessor] : option}</span>
                </div>
            </Select.Option>)}
        </Select>
    </div>
  )
}

export default FormSelect
