import React, { useMemo, useState } from 'react'

import useGetProjectUnits from 'hooks/useGetProjectUnits'

import InventoryListViewWrapper from '../InventoryListViewWrapper'
import { groupBy } from 'components/Inventory'

const UnitsInventoryListView = ({ projectId, buildingId, isFullHeight = false }) => {
  const { units, unitsLoading, refetchUnits } = useGetProjectUnits(projectId)
  const [searchQuery, setSearchQuery] = useState('')

  const filteredUnits = useMemo(() => {
    const unitsData = units
      .filter((elm) => elm.building._id === buildingId)
      .filter((elm) => searchQuery ? elm.unitNumber.toString().includes(searchQuery.toLowerCase()) : true)
      .sort((a, b) => a.unitNumber - b.unitNumber)

    const uCards = [...unitsData]
    const grouped = groupBy(uCards, (inventoryItem) => inventoryItem.floorNumber)
    // @ts-ignore
    const groupedKeyValuePair = {}
    grouped.forEach((value, key) => {
      return (groupedKeyValuePair[key] = value)
    })
    return ({ ...groupedKeyValuePair })
  }, [units, buildingId, refetchUnits, searchQuery])

  return (
    <>
      <div className='ml-6 py-2'>
        <input
          type='text'
          placeholder='Search by unit number...'
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className='w-[300px] p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500'
        />
      </div>
      <InventoryListViewWrapper
        inventoryItems={filteredUnits}
        loading={unitsLoading}
        type='Unit'
        refetch={refetchUnits}
        isFullHeight={isFullHeight}
        selectedBuilding={buildingId}
      />
    </>
  )
}

export default UnitsInventoryListView
