import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { getSearchContacts } from 'store/actions/usersActions'

const useGetSearchUsers = (search, userType, filters) => {
  // @ts-ignore
  const userId = useSelector((state) => state.authReducer?.userObject?._id)

  const getModifiedFilters = () => {
    return filters ?? {}
  }

  const onSuccess = (searchUsers) => {
    if (searchUsers === null || !searchUsers?.data?.length) return console.error('No users were found')
  }

  const { data, isLoading, isFetching } = useQuery(
    ['getSearchUsers', { search, userType, ...getModifiedFilters() }],
    () =>
      getSearchContacts({
        ...getModifiedFilters(),
        userType,
        search
      }),
    {
      onSuccess,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: false,
      enabled:
        !!userId && !!(Object.keys(getModifiedFilters()).length || search || userType)
    })
  return { searchUsers: data, searching: isLoading, isFetching }
}

export default useGetSearchUsers
