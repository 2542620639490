// @ts-nocheck
import { message, Row } from 'antd'
import { Camera, CameraButton, FadedAnimatedDiv, FileSelector, FormTabsWrapper, FormWrapper, ImageUploadMethodsButtons } from 'components'
import CustomInput from 'components/CustomInput'
import moment from 'moment'
import CustomSelect from 'components/CustomSelect'
import { TSaveButton } from 'components/DefaultButtons'
import { AnimatePresence, motion } from 'framer-motion'
import { isArray, isObject } from 'lodash'
import propTypes from 'prop-types'
import React, {
  forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState
} from 'react'
import { connect } from 'react-redux'
import { getDevelopers } from 'store/actions/developersActions'
import { getProjects } from 'store/actions/projectActions'
import { changeUserImages, deleteUserImage, getUsers } from 'store/actions/usersActions'
import { Button, InlineInput, Tabs } from 'ui'
import { countryList, getFileUrl, getUserType, validateRequiredInputs } from 'utils'
import { createUploadFileList } from 'utils/createUploadFileList'
import useUpdateUsers from 'hooks/useUpdateUsers'

const ratingData = [
  { id: '', value: '' },
  { id: 'New', value: 'New' },
  { id: 'Agent', value: 'Agent' },
  { id: 'Hot', value: 'Hot' },
  { id: 'Warm', value: 'Warm' },
  { id: 'Cold', value: 'Cold' }
]
const preferredContactData = [
  { id: '', value: '' },
  { id: 'Any', value: 'Any' },
  { id: 'Email', value: 'Email' },
  { id: 'Work', value: 'Work' },
  { id: 'Fax', value: 'Fax' },
  { id: 'Agent', value: 'Agent' },
  { id: 'Text Message', value: 'Text Message' }
]

const residentData = [
  { id: false, value: 'No' },
  { id: true, value: 'Yes' }
]

const prefixData = [
  { id: 'mr', value: 'Mr.' },
  { id: 'miss', value: 'Miss.' },
  { id: 'ms', value: 'Ms.' },
  { id: 'mrs', value: 'Mrs.' }
]

const GET_PHOTO_ID_FROM_CAMERA = 'get_photo_id_from_camera'
const GET_PHOTO_ID_FROM_LIBRARY = 'get_photo_id_from_library'

const cameraButtonsAnimationValues = {
  buttonExit: (direction) => ({
    x: direction === 'left' ? -1000 : 1000
  }),
  buttonEnter: {
    x: 0
  },
  cameraExitY: {
    y: -1000
  },
  cameraEnterY: {
    y: 0
  },
  cameraExitX: {
    x: 1000
  },
  cameraEnterX: {
    x: 0
  },
  uploadError: {

  }
}

const TabHeader = ({ title, subtitle }) => (
  <div className=''>
    <h3 className='font-black text-md pt-3'>
      {title && title}
    </h3>
    <div className="border-b-2 border-black my-2"></div>
    <h2>
      {subtitle && subtitle}
    </h2>
  </div>
)

const TabContent = ({ children }) => (
  <div className='mt-6'>
    <dl className='divide-y divide-gray-200'>
      {children}
    </dl>
  </div>
)

const UserData = forwardRef((props, ref) => {
  const {
    id, externalObject, userType: externalUserType,
    userObject: {
      userType: loggedUserType
    }, appProject,
    disabled, executeAfterSave
  } = props
  const { createOrUpdateUser } = useUpdateUsers()
  const [email, setEmail] = useState('')
  const [prefix, setPrefix] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [legalName, setLegalName] = useState('')
  const [userType, setUserType] = useState('')
  const [address, setAddress] = useState('')
  const [city, setCity] = useState('')
  const [province, setProvince] = useState('')
  const [country, setCountry] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [resident, setResident] = useState('')
  const [mobileNumber, setMobileNumber] = useState('')
  const [workNumber, setWorkNumber] = useState('')
  const [employer, setEmployer] = useState('')
  const [fax, setFax] = useState('')
  const [bus, setBus] = useState('')
  const [sin, setSin] = useState('')
  const [iQ11Token, setiQ11Token] = useState('')
  const [occupation, setOccupation] = useState('')
  const [stripeCustomerId, setStripeCustomerId] = useState('')
  const [payorId, setPayorId] = useState('')
  const [typeformData, setTypeformData] = useState({})
  const [rating, setRating] = useState('')
  const [preferredContact, setPreferredContact] = useState('')
  const [assignments, setAssignments] = useState([])
  const [additionalFields, setAdditionalFields] = useState([])
  const [developerCompany, setDeveloperCompany] = useState('')
  const [twitter, setTwitter] = useState('')
  const [webSite, setWebSite] = useState('')
  const [projects, setProjects] = useState([])
  const [licenseNumber, setLicenseNumber] = useState('')
  const [licenseBrokerage, setLicenseBrokerage] = useState('')
  const [licenseExpiration, setLicenseExpiration] = useState('')
  const [idNumber, setIdNumber] = useState('')
  const [idType, setIdType] = useState('')
  const [birthday, setBirthday] = useState('')
  const [idExpireDate, setIdExpireDate] = useState('')
  const [allowAdditionalServices, setAllowAdditionalServices] = useState(false)
  const [allowSurveys, setAllowSurveys] = useState(false)

  const [gettingData, setGettingData] = useState(false)
  const [developersData, setDevelopersData] = useState([])
  const [projectsData, setProjectsData] = useState([])
  const [gettingDevelopers, setGettingDevelopers] = useState(false)
  const [gettingProjects, setGettingProjects] = useState(false)

  const [chosenPhotoIdMethod, setChosenPhotoIdMethod] = useState('')
  const [selectedPhotoIdSource, setSelectedPhotoIdSource] = useState('')
  const [frontPhotoId, setFrontPhotoId] = useState([])
  const [frontPhotoIdFile, setFrontPhotoIdFile] = useState('')
  const [frontPhotoIdUrl, setFrontPhotoIdUrl] = useState('')
  const [backPhotoId, setBackPhotoId] = useState([])
  const [backPhotoIdFile, setBackPhotoIdFile] = useState('')
  const [backPhotoIdUrl, setBackPhotoIdUrl] = useState('')
  const [cameraOpened, setCameraOpened] = useState(false)
  const [photoToSet, setPhotoToSet] = useState('front')
  const [selectedTab, setSelectedTab] = useState('personal')
  const [uploadingFrontPhotoId, setUploadingFrontPhotoId] = useState(false)
  const [uploadingBackPhotoId, setUploadingBackPhotoId] = useState(false)

  const [saving, setSaving] = useState(false)

  const webcamRef = useRef(null)

  useImperativeHandle(ref, () => ({
    getUserData () {
      const params = _getUserData()

      return ({
        params,
        extraData: {
          frontPhotoId: frontPhotoIdFile || frontPhotoId,
          backPhotoId: backPhotoIdFile || backPhotoId
        }
      })
    }
  }))

  useEffect(() => {
    if (externalUserType) {
      setUserType(externalUserType)
    }
  }, [externalUserType])

  useEffect(() => {
    if (externalObject && Object.keys(externalObject).length) {
      Object.entries(externalObject).forEach((element) => {
        const field = element[0]
        const value = element[1]

        if (field === 'firstName') setFirstName(value)
        if (field === 'lastName') setLastName(value)
        if (field === 'address') setAddress(value)
        if (field === 'chosenPhotoIdMethod') setChosenPhotoIdMethod(value)
        if (field === 'projects') setProjects(value)

        if (field === 'frontPhotoId') {
          const tmpFrontPhotoId = createUploadFileList(value)
          setFrontPhotoId(tmpFrontPhotoId)
        }
      })
    }
  }, [externalObject])

  // When mounted, get the list of developers to fill Developers Select
  useEffect(() => {
    if ((userType === 'DeveloperAdmin') ||
    (userType === 'SalesRep') ||
    (userType === 'CoopBroker')) {
      setGettingDevelopers(true)

      getDevelopers()
        .then((developers) => {
          const newDevelopersData =
        developers.map((developer) => ({
          id: developer._id, value: developer.companyName
        }))

          setDevelopersData(newDevelopersData)
          setGettingDevelopers(false)
        })
        .catch(() => {
          setGettingDevelopers(false)
        })
    }

    setGettingProjects(true)
    getProjects('')
      .then((projects) => {
        const tmpProjects = projects.map((project) => ({
          id: project._id, value: project.projectName
        }))

        setProjectsData(tmpProjects)
        setGettingProjects(false)
      })
      .catch(() => {
        setGettingProjects(false)
      })
  }, [userType])

  const getUserData = useCallback(() => {
    if (id) {
      setGettingData(true)

      // Get user data from id
      getUsers(id)
        .then((user) => {
          const {
            email,
            firstName,
            lastName,
            userType,
            buyerData,
            brokerData,
            developerAdminData,
            salesRepData,
            project
          } = user

          setEmail(email)
          setFirstName(firstName)
          setLastName(lastName)
          setUserType(userType)

          // Buyer
          if (buyerData) {
            const {
              city,
              province,
              country,
              phoneNumber,
              prefix,
              occupation,
              address,
              postalCode,
              bus,
              fax,
              sin,
              resident,
              iQ11Token,
              stripeCustomerId,
              payorId,
              frontPhotoId,
              backPhotoId,
              typeformData,
              rating,
              legalName,
              preferredContact,
              workNumber,
              mobileNumber,
              employer,
              socialMedia,
              assignments,
              additionalFields,
              birthday,
              idType,
              idNumber,
              idExpireDate,
              allowAdditionalServices,
              allowSurveys
            } = buyerData

            let twitter = ''
            let webSite = ''

            if (socialMedia) {
              twitter = buyerData.socialMedia.twitter
              webSite = buyerData.socialMedia.webSite
            }

            if (project) {
              setProjects(project)
            }

            if (frontPhotoId) {
              const tmpFrontPhotoId = createUploadFileList(frontPhotoId)
              setFrontPhotoId(tmpFrontPhotoId)
              setFrontPhotoIdUrl(frontPhotoId)
            }

            if (backPhotoId) {
              const tmpBackPhotoId = createUploadFileList(backPhotoId)
              setBackPhotoId(tmpBackPhotoId)
              setBackPhotoIdUrl(backPhotoId)
            }

            if (frontPhotoId || backPhotoId) {
              // Just to show the imaes, and not the upload method selector
              setChosenPhotoIdMethod(GET_PHOTO_ID_FROM_LIBRARY)
            }

            if (twitter) {
              setTwitter(twitter)
            }

            if (webSite) {
              setWebSite(webSite)
            }

            setCity(city)
            setProvince(province)
            setCountry(country)
            setPhoneNumber(phoneNumber)
            setPrefix(prefix)
            setOccupation(occupation)
            setAddress(address)
            setPostalCode(postalCode)
            setBus(bus)
            setFax(fax)
            setSin(sin)
            setResident(resident)
            setiQ11Token(iQ11Token)
            setStripeCustomerId(stripeCustomerId)
            setPayorId(payorId)
            setTypeformData(typeformData)
            setRating(rating)
            setLegalName(legalName)
            setPreferredContact(preferredContact)
            setWorkNumber(workNumber)
            setMobileNumber(mobileNumber)
            setEmployer(employer)
            setAssignments(assignments)
            setAdditionalFields(additionalFields)
            setBirthday(birthday)
            setIdType(idType)
            setIdExpireDate(idExpireDate)
            setIdNumber(idNumber)
            setAllowAdditionalServices(allowAdditionalServices)
            setAllowSurveys(allowSurveys)
          }

          // Coop Broker
          if (brokerData) {
            const {
              licenseNumber,
              licenseBrokerage,
              licenseExpiration,
              developerCompany,
              city,
              province,
              country
            } = brokerData

            setLicenseNumber(licenseNumber)
            setLicenseBrokerage(licenseBrokerage)
            setLicenseExpiration(licenseExpiration)

            setCity(city)
            setProvince(province)
            setCountry(country)

            const tmpDeveloperCompanies = []
            if (developerCompany.length) {
              developerCompany.forEach((element) => tmpDeveloperCompanies.push(element._id))
            }

            setDeveloperCompany(tmpDeveloperCompanies)
          }

          // Developer Admin
          if (developerAdminData) {
            const { developerCompany } = developerAdminData

            if (developerCompany) {
              setDeveloperCompany(developerCompany._id)
            }
          }

          // Sales Rep
          if (salesRepData) {
            const {
              developerCompany,
              projects
            } = salesRepData

            if (developerCompany) {
              setDeveloperCompany(developerCompany._id)
            }

            setProjects(projects)
          }

          setGettingData(false)
        })
    } else {
      // cleanValues()
      setGettingData(false)
    }
  }, [id])

  useEffect(() => {
    getUserData()
  }, [getUserData])

  const _getUserData = useCallback(() => {
    let baseObject = {
      id,
      email,
      firstName,
      lastName,
      userType
    }

    let params = {}

    if (userType === 'Buyer' || userType === 'Lead') {
      baseObject = Object.assign({}, baseObject, { project: projects.length ? projects : appProject })

      params = Object.assign({}, baseObject, {
        buyerData: {
          city,
          province,
          country,
          phoneNumber,
          prefix,
          occupation,
          address,
          postalCode,
          bus,
          fax,
          sin,
          resident,
          iQ11Token,
          stripeCustomerId,
          payorId,
          legalName,
          mobileNumber,
          workNumber,
          employer,
          socialMedia: {
            twitter,
            webSite
          },
          typeformData,
          rating,
          preferredContact,
          assignments,
          additionalFields,
          frontPhotoId: frontPhotoIdUrl,
          backPhotoId: backPhotoIdUrl,
          birthday,
          idType,
          idNumber,
          idExpireDate,
          allowAdditionalServices,
          allowSurveys
        }
      })
    }

    if (userType === 'DeveloperAdmin') {
      params = Object.assign({}, baseObject, {
        // developerAdminData: {
        //   developerCompany: developerCompany
        // }
      })
    }

    if (userType === 'SalesRep') {
      params = Object.assign({}, baseObject, {
        salesRepData: {
          developerCompany: developerCompany,
          projects: projects
        }
      })
    }

    if (userType === 'CoopBroker') {
      params = Object.assign({}, baseObject, {
        brokerData: {
          licenseNumber: licenseNumber,
          licenseBrokerage: licenseBrokerage,
          licenseExpiration: licenseExpiration,
          developerCompany: developerCompany,
          city,
          province,
          country
        }
      })
    }

    return params
  }, [
    address, bus, city, country, developerCompany, email, employer, fax, firstName, iQ11Token,
    id, lastName, legalName, licenseBrokerage, licenseExpiration, licenseNumber, mobileNumber,
    occupation, payorId, phoneNumber, postalCode, prefix, projects, province, resident,
    sin, stripeCustomerId, twitter, userType,
    webSite, workNumber, additionalFields, assignments, preferredContact, rating, typeformData, birthday,
    idType, idNumber, idExpireDate, frontPhotoIdUrl, backPhotoIdUrl, appProject,
    allowAdditionalServices, allowSurveys
  ])

  const _onSaveClick = useCallback(() => new Promise((resolve, reject) => {
    const params = _getUserData()

    const _frontPhotoId = frontPhotoIdFile || frontPhotoId
    const _backPhotoId = backPhotoIdFile || backPhotoId

    validateRequiredInputs('createUser')
      .then(async () => {
        setSaving(true)

        const createdUser = await createOrUpdateUser(params)
          .catch(() => {
            setSaving(false)

            reject()
          })

        // If is creating/editing a buyer
        if (createdUser && createdUser._id) {
          if (params.buyerData) {
            const { _id } = createdUser

            // If is creating a new buyer, upload the images with the new id
            let imageParams = { userId: _id }

            if (isArray(_frontPhotoId) ||
              isObject(_frontPhotoId)
            ) {
              imageParams = Object.assign({}, imageParams,
                {
                  frontPhotoId: isArray(_frontPhotoId)
                    ? _frontPhotoId[0]
                    : _frontPhotoId
                })
            }

            if (isArray(_backPhotoId) ||
              isObject(_backPhotoId)
            ) {
              imageParams = Object.assign({}, imageParams,
                {
                  backPhotoId: isArray(_backPhotoId)
                    ? _backPhotoId[0]
                    : _backPhotoId
                })
            }

            if (!id && Object.keys(imageParams).length > 1) {
              message.loading('Uploading Photo ID...')
              await changeUserImages(imageParams, true)
            }
          }

          setSaving(false)
          if (executeAfterSave) {
            executeAfterSave(createdUser)
          }
          getUserData()
          resolve()
        }
      })
      .catch(() => reject())
  }), [
    _getUserData, backPhotoId, backPhotoIdFile, frontPhotoId,
    frontPhotoIdFile, id, getUserData, executeAfterSave
  ])

  // const _onSaveClick = useCallback(() => {
  //   _onSaveClick()
  // }, [_onSaveClick])

  const setPhoto = useCallback(async (photo) => {
    let setter = ''
    let fileSetter = ''
    let changeUserImagesParams = {}
    const objUrl = await getFileUrl(photo)
    const tmpPhotoId = createUploadFileList(objUrl)

    if (id) {
      changeUserImagesParams = Object.assign({}, { userId: id })
    }

    if (photoToSet === 'front') {
      setter = setFrontPhotoId
      fileSetter = setFrontPhotoIdFile
      changeUserImagesParams = Object.assign({},
        changeUserImagesParams,
        {
          frontPhotoId: photo
        }
      )
    } else {
      setter = setBackPhotoId
      fileSetter = setBackPhotoIdFile
      changeUserImagesParams = Object.assign({},
        changeUserImagesParams,
        {
          backPhotoId: photo
        }
      )
    }

    setter(tmpPhotoId)
    fileSetter(photo)
    setCameraOpened(false)

    if (id) {
      if (photoToSet === 'front') setUploadingFrontPhotoId(true)
      else setUploadingBackPhotoId(true)

      const userData = await changeUserImages(changeUserImagesParams, true)

      setUploadingFrontPhotoId(false)
      setUploadingBackPhotoId(false)

      if (executeAfterSave) {
        executeAfterSave(userData)
      }
    }
  }, [id, photoToSet, executeAfterSave])

  const renderPersonalTab = useCallback(() => (
    <div className={`${selectedTab !== 'personal' && 'sr-only'}`}>
      <TabHeader
        title='Personal'
        subtitle='To edit, just click at the data you want to change and then, press Update.'
      />
      <TabContent>
        <div className="grid grid-cols-2  gap-6 border-0 p-0 mt-10 rounded-none shadow-none">
          {
            userType === 'Buyer' && (
              <CustomSelect
                label='Prefix'
                selectedOption={prefix}
                options={prefixData}
                setSelectedOption={(e) => setPrefix(e)}
              />
            )
          }
          <CustomInput
            label='First name'
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            placeholder='first name'
          />
          <CustomInput
            label='Last name'
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            placeholder='last name'
          />
          {
            userType === 'Buyer' && (
              <CustomInput
                label='Legal name'
                value={legalName}
                onChange={(e) => setLegalName(e.target.value)}
                placeholder='legal name'
              />
            )
          }
          <CustomInput
            label='Email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder='email'
            disabled={id || disabled}
          />
          {
            userType === 'Buyer' && (
              <>
                <CustomInput
                  label='Birthday'
                  value={birthday}
                  type='date'
                  onChange={(e) => setBirthday(e.target.value)}
                  placeholder='birthday'
                  classes="uppercase"
                  min={moment().format('YYYY-MM-DD')}
                />
                <CustomInput
                  label='Occupation'
                  value={occupation}
                  onChange={(e) => setOccupation(e.target.value)}
                  placeholder='occupation'
                />
                <CustomInput
                  label='Employer'
                  value={employer}
                  onChange={(e) => setEmployer(e.target.value)}
                  placeholder='employer'
                />
                <CustomInput
                  label='Id number'
                  value={idNumber}
                  onChange={(e) => setIdNumber(e.target.value)}
                  placeholder='id number'
                />
                <CustomInput
                  label='Id type'
                  value={idType}
                  onChange={(e) => setIdType(e.target.value)}
                  placeholder='id type'
                />
                <CustomInput
                  label='Id expiry date'
                  value={idExpireDate}
                  type='date'
                  onChange={(e) => setIdExpireDate(e.target.value)}
                  placeholder='id expiry date'
                  classes="uppercase"
                  min={moment().format('YYYY-MM-DD')}
                />
              </>
            )
          }

          {/* <div className='py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5'>
              <dt className='text-sm font-medium text-gray-500'>
                Photo
              </dt>
              <dd className='mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                <span className='flex-grow'>
                  <img className='h-8 w-8 rounded-full' src='https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80' alt='' />
                </span>
                <span className='ml-4 flex-shrink-0 flex items-start space-x-4'>
                  <button type='button' className='bg-white rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500'>
                    Update
                  </button>
                  <span className='text-gray-300' aria-hidden='true'>|</span>
                  <button type='button' className='bg-white rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500'>
                    Remove
                  </button>
                </span>
              </dd>
            </div>
            */}
            <button
              onClick={id ? _onSaveClick : null}
              className='col-span-2 mx-auto bg-volt px-4 py-2 rounded font-semibold'>
              Save
            </button>
        </div>
      </TabContent>
    </div>
  ), [
    id, email, firstName, lastName, legalName, prefix,
    userType, employer, occupation, selectedTab, gettingData,
    _onSaveClick, disabled, birthday, idNumber, idType, idExpireDate
  ])

  const renderAddressTab = useCallback(() => (
    <div className={`${selectedTab !== 'address' && 'sr-only'}`}>
      <TabHeader
        title='Address'
      />
      <TabContent>
        {
          userType === 'Buyer' && (
            <InlineInput
              key='address'
              label='Address'
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              placeholder='address'
              onClickUpdate={id ? _onSaveClick : null}
              loading={gettingData}
              disabled={disabled}
            />
          )
        }

        <InlineInput
          key='city'
          label='City'
          value={city}
          onChange={(e) => setCity(e.target.value)}
          placeholder='city'
          onClickUpdate={id ? _onSaveClick : null}
          loading={gettingData}
          disabled={disabled}
        />
        <InlineInput
          key='province'
          label='Province'
          value={province}
          onChange={(e) => setProvince(e.target.value)}
          placeholder='province'
          onClickUpdate={id ? _onSaveClick : null}
          loading={gettingData}
          disabled={disabled}
        />
        <InlineInput
          key='country'
          label='Country'
          value={country}
          onChange={(e) => setCountry(e)}
          placeholder='country'
          onClickUpdate={id ? _onSaveClick : null}
          loading={gettingData}
          disabled={disabled}
          typeInput='select'
          data={countryList}
        />

        {
          userType === 'Buyer' && (
            <>
              <InlineInput
                key='postalCode'
                label='Postal code'
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
                placeholder='postal code'
                onClickUpdate={id ? _onSaveClick : null}
                loading={gettingData}
                disabled={disabled}
              />
              <InlineInput
                key='resident'
                label='Resident'
                value={resident}
                onChange={(e) => setResident(e)}
                placeholder='resident'
                data={residentData}
                typeInput='select'
                loading={gettingData}
                onClickUpdate={id ? _onSaveClick : null}
                disabled={disabled}
              />
            </>
          )
        }

      </TabContent>
    </div>
  ), [
    id, address, city, province, country, postalCode,
    resident, selectedTab, gettingData, _onSaveClick,
    disabled, userType
  ])

  const renderContactsTab = useCallback(() => (
    <div className={`${selectedTab !== 'contacts' && 'sr-only'}`}>
      <TabHeader
        title='Contacts'
      />
      <TabContent>
        <InlineInput
          key='phoneNumber'
          type='tel'
          label='Phone number'
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          placeholder='phone number'
          onClickUpdate={id ? _onSaveClick : null}
          loading={gettingData}
          disabled={disabled}
        />
        <InlineInput
          key='mobileNumber'
          type='tel'
          label='Mobile number'
          value={mobileNumber}
          onChange={(e) => setMobileNumber(e.target.value)}
          placeholder='mobile number'
          onClickUpdate={id ? _onSaveClick : null}
          loading={gettingData}
          disabled={disabled}
        />
        <InlineInput
          key='workNumber'
          type='tel'
          label='Work number'
          value={workNumber}
          onChange={(e) => setWorkNumber(e.target.value)}
          placeholder='work number'
          onClickUpdate={id ? _onSaveClick : null}
          loading={gettingData}
          disabled={disabled}
        />
        <InlineInput
          key='fax'
          type='tel'
          label='Fax number'
          value={fax}
          onChange={(e) => setFax(e.target.value)}
          placeholder='fax number'
          onClickUpdate={id ? _onSaveClick : null}
          loading={gettingData}
          disabled={disabled}
        />
        <InlineInput
          key='busNumber'
          type='tel'
          label='Bus number'
          value={bus}
          onChange={(e) => setBus(e.target.value)}
          placeholder='bus number'
          onClickUpdate={id ? _onSaveClick : null}
          loading={gettingData}
          disabled={disabled}
        />
        <InlineInput
          key='sin'
          type='tel'
          label='Sin number'
          value={sin}
          onChange={(e) => setSin(e.target.value)}
          placeholder='sin number'
          onClickUpdate={id ? _onSaveClick : null}
          loading={gettingData}
          disabled={disabled}
        />
      </TabContent>
    </div>
  ), [
    id, bus, fax, mobileNumber, phoneNumber, sin,
    workNumber, selectedTab, gettingData, _onSaveClick,
    disabled
  ])

  const renderSocialsTab = useCallback(() => (
    <div className={`${selectedTab !== 'socials' && 'sr-only'}`}>
      <TabHeader
        title='Socials'
      />
      <TabContent>
        <InlineInput
          key='twitter'
          label='Twitter'
          value={twitter}
          onChange={(e) => setTwitter(e.target.value)}
          placeholder='twitter profile'
          onClickUpdate={id ? _onSaveClick : null}
          loading={gettingData}
          disabled={disabled}
        />
        <InlineInput
          key='personalWebSite'
          label='Personal website'
          value={webSite}
          onChange={(e) => setWebSite(e.target.value)}
          placeholder='personal website'
          onClickUpdate={id ? _onSaveClick : null}
          loading={gettingData}
          disabled={disabled}
        />
      </TabContent>
    </div>
  ), [
    id, twitter, webSite, selectedTab,
    gettingData, _onSaveClick, disabled
  ])

  const renderPhotosTab = useCallback(() => (
    <div className={`${selectedTab !== 'photos' && 'sr-only'}`}>
      <TabHeader
        title='Photos'
        subtitle="Here you can set the user's ID Photos"
      />
      <TabContent>
        <AnimatePresence exitBeforeEnter initial={false}>
          {
            !chosenPhotoIdMethod
              ? (
                <FadedAnimatedDiv
                  key='choosingUploadMethod'
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: 30
                  }}
                >
                  <div
                    style={{ height: 150, width: 330 }}
                    className='flex items-center justify-center
                    flex-row-reverse overflow-hidden rounded-md'
                  >
                    <ImageUploadMethodsButtons
                      lottieClassName='max-w-18'
                      style={{ padding: '5px 0px', zIndex: 0 }}
                      animate={false}
                      onCameraMethodClick={() => {
                        setSelectedPhotoIdSource(GET_PHOTO_ID_FROM_CAMERA)
                      }}
                      onLibraryMethodClick={() => {
                        setSelectedPhotoIdSource(GET_PHOTO_ID_FROM_LIBRARY)
                      }}
                      cameraSelected={selectedPhotoIdSource === GET_PHOTO_ID_FROM_CAMERA}
                      librarySelected={selectedPhotoIdSource === GET_PHOTO_ID_FROM_LIBRARY}
                    />
                  </div>
                  <Button
                    style={{ marginTop: 15 }}
                    onClick={() => setChosenPhotoIdMethod(selectedPhotoIdSource)}
                  >
                    Select
                  </Button>
                </FadedAnimatedDiv>
                )
              : (
                <FadedAnimatedDiv
                  key='uploadButtons'
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  {
                    chosenPhotoIdMethod === GET_PHOTO_ID_FROM_LIBRARY
                      ? (
                        <Row className='space-x-10 my-5'>
                          <FileSelector
                            disabled={disabled}
                            title='Front Photo ID'
                            listOfFiles={frontPhotoId}
                            loading={gettingData || uploadingFrontPhotoId}
                            onRemoveFile={({ fileList }) => {
                              if (!fileList.length) {
                                setFrontPhotoId([])

                                if (id) {
                                  deleteUserImage({ userId: id, photoSide: 'front' })
                                    .then((userData) => {
                                      if (executeAfterSave) {
                                        executeAfterSave(userData)
                                      }
                                    })
                                    .catch(() => {})
                                }
                              }
                            }}
                            onSelectFile={(fileList) => {
                              if (fileList) {
                                setFrontPhotoId(fileList)

                                if (id) {
                                  const file = fileList[0]
                                  setUploadingFrontPhotoId(true)
                                  changeUserImages({ userId: id, frontPhotoId: file }, true)
                                    .then((userData) => {
                                      setUploadingFrontPhotoId(false)

                                      getUserData()

                                      if (executeAfterSave) {
                                        executeAfterSave(userData)
                                      }
                                    })
                                    .catch(() => setUploadingFrontPhotoId(false))
                                }
                              }
                            }}
                          />

                          <FileSelector
                            disabled={disabled}
                            title='Back Photo ID'
                            listOfFiles={backPhotoId}
                            loading={gettingData || uploadingBackPhotoId}
                            onRemoveFile={({ fileList }) => {
                              if (!fileList.length) {
                                setBackPhotoId([])

                                if (id) {
                                  deleteUserImage({ userId: id, photoSide: 'back' })
                                    .then((userData) => {
                                      if (executeAfterSave) {
                                        executeAfterSave(userData)
                                      }
                                    })
                                    .catch(() => {})
                                }
                              }
                            }}
                            onSelectFile={(fileList) => {
                              if (fileList) {
                                setBackPhotoId(fileList)

                                if (id) {
                                  const file = fileList[0]
                                  setUploadingBackPhotoId(true)
                                  changeUserImages({ userId: id, backPhotoId: file }, true)
                                    .then((userData) => {
                                      setUploadingBackPhotoId(false)

                                      getUserData()

                                      if (executeAfterSave) {
                                        executeAfterSave(userData)
                                      }
                                    })
                                    .catch(() => setUploadingBackPhotoId(false))
                                }
                              }
                            }}
                          />
                        </Row>
                        )
                      : (
                        <Row className='space-x-10 my-5'>
                          <AnimatePresence exitBeforeEnter initial={false}>
                            {
                              !cameraOpened
                                ? (
                                <>
                                  <motion.div
                                    key='frontPhotoIdCameraButton'
                                    variants={cameraButtonsAnimationValues}
                                    initial='buttonExit'
                                    animate='buttonEnter'
                                    exit='buttonExit'
                                    custom='left'
                                    transition={{
                                      x: { type: 'spring', stiffness: 300, damping: 30 }
                                    }}
                                  >
                                    <CameraButton
                                      disabled={disabled}
                                      photo={frontPhotoId}
                                      onClick={() => {
                                        setPhotoToSet('front')
                                        setCameraOpened(true)
                                      }}
                                      onRemovePhoto={() => {
                                        setFrontPhotoId([])
                                        setFrontPhotoIdFile('')

                                        if (id) {
                                          deleteUserImage({ userId: id, photoSide: 'front' })
                                            .then((userData) => {
                                              if (executeAfterSave) {
                                                executeAfterSave(userData)
                                              }
                                            })
                                            .catch(() => {})
                                        }
                                      }}
                                      title='Photograph the Front Photo ID'
                                      loading={uploadingFrontPhotoId}
                                    />
                                  </motion.div>

                                  <motion.div
                                    key='backPhotoIdCameraButton'
                                    variants={cameraButtonsAnimationValues}
                                    initial='buttonExit'
                                    animate='buttonEnter'
                                    exit='buttonExit'
                                    custom='right'
                                    transition={{
                                      x: { type: 'spring', stiffness: 300, damping: 30 }
                                    }}
                                  >
                                    <CameraButton
                                      disabled={disabled}
                                      photo={backPhotoId}
                                      onClick={() => {
                                        setPhotoToSet('back')
                                        setCameraOpened(true)
                                      }}
                                      onRemovePhoto={() => {
                                        setBackPhotoId([])
                                        setBackPhotoIdFile('')

                                        if (id) {
                                          deleteUserImage({ userId: id, photoSide: 'front' })
                                            .then((userData) => {
                                              if (executeAfterSave) {
                                                executeAfterSave(userData)
                                              }
                                            })
                                            .catch(() => {})
                                        }
                                      }}
                                      title='Photograph the Back Photo ID'
                                      loading={uploadingBackPhotoId}
                                    />
                                  </motion.div>

                                </>
                                  )
                                : (
                                  <Camera
                                    key='cameraComponent'
                                    ref={webcamRef}
                                    width='100%'
                                    onTakePhoto={(photo) => setPhoto(photo)}
                                    onClickCloseButton={() => setCameraOpened(false)}
                                  />
                                  )
                            }

                          </AnimatePresence>
                        </Row>
                        )
                  }
                  <Button onClick={() => {
                    setChosenPhotoIdMethod('')
                    setCameraOpened(false)
                  }}
                  >
                    Change Upload Method
                  </Button>
                </FadedAnimatedDiv>
                )
          }
        </AnimatePresence>
      </TabContent>
    </div>
  ), [
    backPhotoId, cameraOpened, chosenPhotoIdMethod, frontPhotoId,
    getUserData, id, selectedPhotoIdSource, setPhoto, selectedTab,
    disabled, executeAfterSave, gettingData, uploadingBackPhotoId,
    uploadingFrontPhotoId
  ])

  const renderUserRelationsTab = useCallback(() => (
    <div className={`${selectedTab !== 'relations' && 'sr-only'}`}>
      <TabHeader
        title='Relations'
        subtitle="User can have it's relations and you can configure it here"
      />
      <TabContent>
        {
          ((userType === 'Buyer' && !id) ||
            (userType === 'SalesRep')) &&
          (
            <InlineInput
              key='projects'
              label={userType === 'SalesRep' ? 'Projects' : 'Project'}
              value={!projects ? [] : projects}
              onChange={(id) => setProjects(id)}
              placeholder='project'
              typeInput='select'
              mode={userType === 'SalesRep' && 'multiple'}
              data={projectsData}
              required
              validateKey='createUser'
              validateExtraMessage='at the Relations tab'
              loading={gettingData || gettingProjects}
              onClickUpdate={id ? _onSaveClick : null}
              disabled={disabled}
            />
          )
        }
        {
          ((userType === 'DeveloperAdmin') ||
            (userType === 'SalesRep') ||
            (userType === 'CoopBroker')) &&
          (
            <>
              <InlineInput
                key='developerCompany'
                label='Developer company'
                value={!developerCompany ? [] : developerCompany}
                onChange={(id) => setDeveloperCompany(id)}
                placeholder='is the user related to any Developer Company?'
                loading={gettingDevelopers || gettingData}
                typeInput='select'
                data={developersData}
                required
                validateKey='createUser'
                mode={userType === 'CoopBroker' && 'multiple'}
                onClickUpdate={id ? _onSaveClick : null}
                disabled={disabled}
              />
            </>
          )
        }
      </TabContent>
    </div>
  ), [
    projects, projectsData, userType, developerCompany,
    developersData, gettingData, gettingDevelopers, selectedTab,
    gettingProjects, id, _onSaveClick, disabled
  ])

  const renderLicenseTab = useCallback(() => (
    <div className={`${selectedTab !== 'license' && 'sr-only'}`}>
      <TabHeader
        title='License'
      />
      <TabContent>
        <InlineInput
          key='licenseNumber'
          label='License number'
          value={licenseNumber}
          onChange={(e) => setLicenseNumber(e.target.value)}
          placeholder='license number'
          loading={gettingData}
          onClickUpdate={id ? _onSaveClick : null}
          disabled={disabled}
        />
        <InlineInput
          key='licenseBrokerage'
          label='License brokerage'
          value={licenseBrokerage}
          onChange={(e) => setLicenseBrokerage(e.target.value)}
          placeholder='license brokerage'
          loading={gettingData}
          onClickUpdate={id ? _onSaveClick : null}
          disabled={disabled}
        />
        <InlineInput
          key='licenseExpiration'
          label='License expiration'
          value={licenseExpiration}
          onChange={(e) => setLicenseExpiration(e || '')}
          placeholder='license expiration'
          loading={gettingData}
          typeInput='datepicker'
          onClickUpdate={id ? _onSaveClick : null}
          disabled={disabled}
        />
      </TabContent>
    </div>
  ), [
    gettingData, licenseBrokerage, licenseExpiration,
    licenseNumber, selectedTab, id, _onSaveClick, disabled
  ])

  const renderUserIdsTab = useCallback(() => (
    <div className={`${selectedTab !== 'userIds' && 'sr-only'}`}>
      <TabHeader
        title='User IDs'
        subtitle='This tab is only available to Super Admins'
      />
      <TabContent>
        {
          userType === 'Buyer' && (
            <>
              <InlineInput
                key='iQ11Token'
                label='iQ11Token number'
                value={iQ11Token}
                onChange={(e) => setiQ11Token(e.target.value)}
                placeholder='buyer iQ11Token'
                loading={gettingData}
                disabled
              />
              <InlineInput
                key='stripeCustomerId'
                label='Stripe customer ID'
                value={stripeCustomerId}
                onChange={(e) => setStripeCustomerId(e.target.value)}
                placeholder='buyer stripe customer id'
                loading={gettingData}
                disabled
              />
              <InlineInput
                key='payorId'
                label='Payor ID'
                value={payorId}
                onChange={(e) => setPayorId(e.target.value)}
                placeholder='buyer payor id'
                loading={gettingData}
                disabled
              />
            </>
          )
        }
        <InlineInput
          key='userId'
          label='User ID'
          value={id}
          onChange={(e) => {}}
          placeholder='user ID'
          loading={gettingData}
          disabled
        />
      </TabContent>
    </div>
  ), [gettingData, iQ11Token, payorId, stripeCustomerId, selectedTab, id, userType])

  const renderGeneralTab = useCallback(() => (
    <div className={`${selectedTab !== 'general' && 'sr-only'}`}>
      <TabHeader
        title='General'
        subtitle='All other infos related to the buyer'
      />
      <TabContent>
        <InlineInput
          key='rating'
          label='Rating'
          value={rating}
          onChange={(e) => setRating(e)}
          placeholder='buyer rating'
          typeInput='select'
          data={ratingData}
          loading={gettingData}
          onClickUpdate={id ? _onSaveClick : null}
          disabled={disabled}
        />
        <InlineInput
          key='preferredContact'
          label='Preferred contact'
          value={preferredContact}
          onChange={(e) => setPreferredContact(e)}
          placeholder='preferred way of contact'
          typeInput='select'
          data={preferredContactData}
          loading={gettingData}
          onClickUpdate={id ? _onSaveClick : null}
          disabled={disabled}
        />
      </TabContent>
    </div>
  ), [
    _onSaveClick, disabled, gettingData, id, rating, selectedTab, preferredContact
  ])

  const getMenus = useCallback(() => {
    let menus = [
      { key: 'personal', title: 'Personal' }
    ]

    if (userType === 'Buyer') {
      menus = menus.concat(
        { key: 'address', title: 'Address' },
        { key: 'contacts', title: 'Contacts' },
        { key: 'socials', title: 'Socials' },
        { key: 'photos', title: 'Photos' },
        { key: 'general', title: 'General' },
        { key: 'secondBuyer', title: 'Second buyer' }
      )

      if (!id) {
        menus.push(
          { key: 'relations', title: 'Relations' }
        )
      }
    }

    if (
      userType === 'DeveloperAdmin' ||
      userType === 'SalesRep' ||
      userType === 'CoopBroker'
    ) {
      menus.push(
        { key: 'relations', title: 'Relations' }
      )
    }

    if (userType === 'CoopBroker') {
      menus.push(
        { key: 'license', title: 'License' },
        { key: 'address', title: 'Address' }
      )
    }

    if (loggedUserType === 'SuperAdmin' && Boolean(id)) {
      menus.push(
        { key: 'userIds', title: 'User IDs' }
      )
    }

    return menus
  }, [userType, loggedUserType, id])

  return (
    <FormWrapper>
      <FormTabsWrapper>
        <Tabs
          menus={getMenus()}
          tabSelected={selectedTab}
          onClick={(tab) => setSelectedTab(tab)}
        />
        {
          !id && (
            <TSaveButton
              loading={saving}
              className='ml-3'
              onClick={_onSaveClick}
            >
              Save {getUserType(userType)}
            </TSaveButton>
          )
        }
      </FormTabsWrapper>

      <div className='p-4'>
        {renderPersonalTab()}
        {renderAddressTab()}
        {renderContactsTab()}
        {renderSocialsTab()}
        {renderPhotosTab()}
        {renderGeneralTab()}
        {renderUserRelationsTab()}
        {renderLicenseTab()}
        {renderUserIdsTab()}
      </div>
    </FormWrapper>
  )
})

const mapStateToProps = (state) => ({
  userObject: state.authReducer.userObject,
  appProject: state.appReducer.appProject
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(UserData)

UserData.defaultProps = {
  showPaymentTokens: true,
  disabled: false
}

UserData.propTypes = {
  id: propTypes.string,
  userObject: propTypes.shape({
    userType: propTypes.string
  }),
  userType: propTypes.string,
  externalObject: propTypes.object,
  disabled: propTypes.bool,
  executeAfterSave: propTypes.func,
  appProject: propTypes.string
}

TabHeader.propTypes = {
  title: propTypes.string,
  subtitle: propTypes.string
}

TabContent.propTypes = {
  children: propTypes.node
}
