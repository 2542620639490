/*eslint-disable */
// @ts-nocheck
import React, { useCallback, useContext, useEffect } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { Divider, Drawer, makeStyles } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import { CheckCircle, ChevronLeft, Edit, Info, TrashIcon } from 'lucide-react';
import CustomButton from 'components/CustomButton';
import { completedTasksStatus, pendingTasksStatus } from 'pages/tasks';
import ContactItem from 'pages/contacts/ContactItem';
import { ContactContext } from 'pages/contacts/context/Contacts';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Tooltip } from 'antd';

const ContactIcon = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.1133 8.75333C12.0266 9.37333 12.6666 10.2133 12.6666 11.3333V13.3333H15.3333V11.3333C15.3333 9.87999 12.9533 9.01999 11.1133 8.75333Z'
        fill='#2E2B2E'
        fillOpacity='0.5'
      />
      <path
        d='M5.99943 7.99999C7.47219 7.99999 8.6661 6.80608 8.6661 5.33332C8.6661 3.86056 7.47219 2.66666 5.99943 2.66666C4.52667 2.66666 3.33276 3.86056 3.33276 5.33332C3.33276 6.80608 4.52667 7.99999 5.99943 7.99999Z'
        fill='#2E2B2E'
        fillOpacity='0.5'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.99995 7.99999C11.4733 7.99999 12.6666 6.80666 12.6666 5.33332C12.6666 3.85999 11.4733 2.66666 9.99995 2.66666C9.68661 2.66666 9.39328 2.73332 9.11328 2.82666C9.66661 3.51332 9.99995 4.38666 9.99995 5.33332C9.99995 6.27999 9.66661 7.15332 9.11328 7.83999C9.39328 7.93332 9.68661 7.99999 9.99995 7.99999Z'
        fill='#2E2B2E'
        fillOpacity='0.5'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.99971 8.66666C4.21971 8.66666 0.666382 9.55999 0.666382 11.3333V13.3333H11.333V11.3333C11.333 9.55999 7.77971 8.66666 5.99971 8.66666Z'
        fill='#2E2B2E'
        fillOpacity='0.5'
      />
    </svg>
  );
};

const CalenderIcon = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.3336 1.99999H12.667V0.666656H11.3336V1.99999H4.66695V0.666656H3.33362V1.99999H2.66695C1.93362 1.99999 1.33362 2.59999 1.33362 3.33332V14C1.33362 14.7333 1.93362 15.3333 2.66695 15.3333H13.3336C14.067 15.3333 14.667 14.7333 14.667 14V3.33332C14.667 2.59999 14.067 1.99999 13.3336 1.99999ZM13.3336 14H2.66695V5.33332H13.3336V14Z'
        fill='#2E2B2E'
        fillOpacity='0.5'
      />
    </svg>
  );
};

const StatusIcon = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clip-path='url(#clip0_106_5382)'>
      <path
        d='M4.66641 6.66663L7.99975 9.99996L11.3331 6.66663H4.66641Z'
        fill='#2E2B2E'
        fill-opacity='0.5'
      />
    </g>
    <defs>
      <clipPath id='clip0_106_5382'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

const SettingIcon = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M9.75 8C9.75 8.34612 9.64736 8.68446 9.45507 8.97225C9.26278 9.26003 8.98947 9.48434 8.6697 9.61679C8.34993 9.74924 7.99806 9.7839 7.65859 9.71638C7.31913 9.64885 7.00731 9.48218 6.76256 9.23744C6.51782 8.9927 6.35115 8.68088 6.28363 8.34141C6.2161 8.00194 6.25076 7.65008 6.38321 7.33031C6.51566 7.01053 6.73997 6.73722 7.02775 6.54493C7.31554 6.35264 7.65388 6.25 8 6.25C8.46413 6.25 8.90925 6.43438 9.23744 6.76256C9.56563 7.09075 9.75 7.53587 9.75 8ZM8 4.75C8.34612 4.75 8.68446 4.64737 8.97225 4.45507C9.26004 4.26278 9.48434 3.98947 9.61679 3.6697C9.74924 3.34993 9.7839 2.99806 9.71638 2.65859C9.64885 2.31913 9.48218 2.00731 9.23744 1.76256C8.9927 1.51782 8.68088 1.35115 8.34141 1.28363C8.00194 1.2161 7.65008 1.25076 7.33031 1.38321C7.01053 1.51566 6.73722 1.73997 6.54493 2.02775C6.35264 2.31554 6.25 2.65388 6.25 3C6.25 3.46413 6.43438 3.90925 6.76256 4.23744C7.09075 4.56563 7.53587 4.75 8 4.75ZM8 11.25C7.65388 11.25 7.31554 11.3526 7.02775 11.5449C6.73997 11.7372 6.51566 12.0105 6.38321 12.3303C6.25076 12.6501 6.2161 13.0019 6.28363 13.3414C6.35115 13.6809 6.51782 13.9927 6.76256 14.2374C7.00731 14.4822 7.31913 14.6489 7.65859 14.7164C7.99806 14.7839 8.34993 14.7492 8.6697 14.6168C8.98947 14.4843 9.26278 14.26 9.45507 13.9722C9.64736 13.6845 9.75 13.3461 9.75 13C9.75 12.5359 9.56563 12.0908 9.23744 11.7626C8.90925 11.4344 8.46413 11.25 8 11.25Z'
      fill='#2E2B2E'
    />
  </svg>
);

const TaskDetailsDrawer = ({
  isTaskDrawerOpen,
  handleTaskDrawerClose,
  selectedTask,
  isInfoDrawerOpen,
  setIsInfoDrawerOpen,
  handleInfoDrawerOpen,
  contact,
  setIsMarkCompleteConfirmationModalOpen,
  setIsCreateOrUpdateTaskModalOpen,
  setIsDeleteModal,
}) => {
  const { selectedContact, selectedContactObject } = useContext(ContactContext);
  const history = useHistory();
  const useStyles = makeStyles((theme) => ({
    drawerPaper: {
      width: isMobile ? '100%' : 'calc(100% - 80px)',
      height: '100vh',
      overflow: 'hidden',
      boxShadow: '-10px 0px 15px 0px rgba(46, 43, 46, 0.05)',
    },
  }));

  useEffect(() => {
    if (contact?._id) {
      history.push({
        search: `?id=${contact?._id}&tab=tasks`,
      });
    }
  }, [contact]);

  const classes = useStyles();

  const DrawerContent = useCallback(() => {
    return (
      <>
        {!selectedTask?.title?.length ? (
          <div className='w-full md:w-[350px] flex justify-center items-center text-lg font-medium'>
            Loading Task Details....
          </div>
        ) : (
          <div className='w-full md:w-[350px] flex flex-col'>
            <div className='w-full p-2 border-b flex justify-between items-stretch'>
              <CustomButton
                className='mr-1 w-fit'
                variant='ghost'
                handleClick={() => {
                  handleTaskDrawerClose();
                  history.push({
                    search: '',
                  });
                }}
              >
                <span className='font-medium p-2 text-base flex gap-2 items-center'>
                  <ChevronLeft className='w-5 h-5' />
                </span>
              </CustomButton>
            </div>
            <div
              className={`flex-1 relative w-full p-5 ${
                pendingTasksStatus.includes(selectedTask?.status)
                  ? 'pt-7'
                  : 'pt-1'
              } md:px-6 md:py-12 bg-white flex-col justify-start items-start gap-6 md:gap-12 inline-flex`}
            >
              <div className='text-zinc-800 text-[28px] font-semibold leading-[42px] ps-4 md:ps-0'>
                {selectedTask?.title}
              </div>
              <div className='self-stretch bg-white justify-start items-start gap-6 inline-flex'>
                <div className='w-[102px] bg-white flex-col justify-start items-start gap-2 inline-flex'>
                  {selectedTask?.buyer?.firstName?.length > 0 && (
                    <div className='h-9 justify-start items-center gap-1 inline-flex'>
                      <ContactIcon />
                      <div className='text-zinc-800 text-opacity-50 text-sm font-normal leading-[21px]'>
                        Contact
                      </div>
                    </div>
                  )}
                  <div className='h-9 justify-start items-center gap-1 inline-flex'>
                    <CalenderIcon />
                    <div className='text-zinc-800 text-opacity-50 text-sm font-normal leading-[21px]'>
                      Due date
                    </div>
                  </div>
                  <div className='h-9 justify-start items-center gap-1 inline-flex'>
                    <StatusIcon />
                    <div className='text-zinc-800 text-opacity-50 text-sm font-normal leading-[21px]'>
                      Status
                    </div>
                  </div>
                  <div className=' inline-flex items-center h-9 text-opacity-50 text-zinc-800 text-sm font-normal leading-[21px]'>
                    Description
                  </div>
                </div>
                <div className='grow shrink basis-0 bg-white flex-col justify-start items-start gap-3 inline-flex'>
                  {selectedTask?.buyer?.firstName?.length > 0 && (
                    <div
                      className='h-9 bg-white justify-end items-center gap-1 inline-flex cursor-pointer md:cursor-default'
                      onClick={() => {
                        if (isMobile) {
                          setIsInfoDrawerOpen(true);
                        }
                      }}
                    >
                      <div className='px-2 py-1 bg-[#F0EEE9] rounded-md justify-center items-center gap-1 flex'>
                        <div className='w-6 h-6 relative bg-zinc-800 bg-opacity-20 rounded-full flex items-center justify-center text-xs'>
                          {selectedTask?.buyer?.firstName[0]}
                          {selectedTask?.buyer?.lastName[0]}
                        </div>
                        <p className='text-zinc-800 text-sm font-normal leading-[21px] m-0'>
                          {selectedTask?.buyer?.firstName +
                            ' ' +
                            selectedTask?.buyer?.lastName}
                        </p>
                      </div>
                      {isMobile && <Info className='w-5 h-5' />}
                    </div>
                  )}
                  <div className='h-7 px-2 py-1 bg-[#F0EEE9] rounded-md justify-center items-center gap-1 inline-flex'>
                    <p className='text-zinc-800 text-sm font-normal leading-[21px] m-0'>
                      {moment(selectedTask?.dueDate).format('MMM DD')}
                    </p>
                  </div>
                  <div className='h-9 bg-white justify-end items-center gap-1 inline-flex'>
                    <div
                      className={clsx(
                        'px-2 py-1 rounded-md justify-center items-center gap-1 flex',
                        pendingTasksStatus.includes(selectedTask?.status)
                          ? 'bg-[#FF3D0040]/25'
                          : 'bg-lime-100'
                      )}
                    >
                      <p className='text-zinc-800 text-sm font-normal leading-[21px] capitalize m-0'>
                        {pendingTasksStatus.includes(selectedTask?.status)
                          ? 'Not started'
                          : completedTasksStatus.includes(selectedTask?.status)
                          ? 'Completed'
                          : selectedTask?.status}
                      </p>
                    </div>
                  </div>
                  <div className='h-9 inline-flex items-center text-zinc-800 text-sm font-normal leading-[21px]'>
                    {selectedTask?.description}
                  </div>
                </div>
              </div>
             <div className='flex flex-col gap-y-4 w-full'>
              {!completedTasksStatus.includes(selectedTask?.status) && (
                  <div className='flex flex-col w-full gap-y-3'>
                    <CustomButton
                      handleClick={() =>
                        setIsMarkCompleteConfirmationModalOpen(true)
                      }
                    >
                      <span className='font-medium py-2 px-4 text-sm flex gap-2 items-center'>
                        <CheckCircle className='white h-5 w-5' />
                        Mark complete
                      </span>
                    </CustomButton>
                  </div>
              )}
              <div className='flex justify-between gap-x-6 items-center w-full'>
                <CustomButton
                  variant='outline'
                  className='w-full'
                  handleClick={(e) => {
                    setIsCreateOrUpdateTaskModalOpen(true);
                  }}
                >
                  <Tooltip className='text-black' title='Edit'>
                    <span className='font-medium p-2 px-3 text-base flex gap-2 items-center'>
                      <Edit className='white h-5 w-5' />
                    </span>
                  </Tooltip>
                </CustomButton>
                <CustomButton
                  variant='outline'
                  className='w-full'
                  onClick={(e) => {
                    setIsDeleteModal(true);
                  }}
                >
                  <Tooltip title='Delete'>
                    <span className='font-medium p-2 px-3 text-base flex gap-2 items-center'>
                      <TrashIcon className='white h-5 w-5' />
                    </span>
                  </Tooltip>
                </CustomButton>
              </div>
             </div>
            </div>
          </div>
        )}
      </>
    );
  }, [selectedTask]);

  return (
    <>
      <Drawer
        open={isTaskDrawerOpen}
        variant='persistent'
        anchor='right'
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className='pt-16 relative h-full'>
          <div className='w-full h-full flex'>
            <DrawerContent />
            {!isMobile && (
              <div className='flex-1 border-l border-softBlack_70/30'>
                {selectedContact && selectedContactObject?._id && (
                  <div className='w-[calc(100vw-430px)] h-full'>
                    <ContactItem showBackButton={false} />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </Drawer>
      <Drawer
        open={isInfoDrawerOpen}
        variant='persistent'
        anchor='right'
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className='pt-16 relative h-full'>
          <button
            onClick={handleInfoDrawerOpen}
            className='absolute left-[0.85rem] z-[10] top-[5.1rem]'
          >
            <ChevronLeft className='w-5 h-5' />
          </button>
          {selectedContact && selectedContactObject?._id && (
            <ContactItem showBackButton={false} />
          )}
        </div>
      </Drawer>
    </>
  );
};

export default TaskDetailsDrawer;
