import React from 'react'
import '../../assets/css/main.css'

const ProgressBar = ({
  currentPercent = 0.5,
  progressColor = 'yellow-300',
  backgroundColor = 'gray-400',
  displayPercent = false,
  height = ''
}) => {
  return (
    <div className='flex h-12 items-center'>
      <div className='h-full w-full rounded-lg' style={{ backgroundColor }}>
        <div
          className='h-full rounded-l-md box-border rounded-r-md'
          style={{
            width: `${currentPercent * 100}%`,
            backgroundColor: progressColor,
            height: height.length > 0 && height
          }}
        ></div>
      </div>
      {displayPercent && (
        <span className='font-bold text-right w-14'>
          {currentPercent * 100}%
        </span>
      )}
    </div>
  )
}

export default ProgressBar
