// @ts-nocheck
import React, { useEffect, useState } from 'react'
import CustomInput from '../../CustomInput'
import CustomSelect from '../../CustomSelect'
import { getUsers } from 'store/actions/usersActions'
import { H5 } from 'ui'
import SubText from '../../SubText'

const prefixData = [
  { id: 'mr', value: 'Mr.' },
  { id: 'miss', value: 'Miss.' },
  { id: 'ms', value: 'Ms.' },
  { id: 'mrs', value: 'Mrs.' }
]

const ReviewBuyerDetailsTab = ({ buyerId }) => {
  const tabMenus = [
    {
      id: 'tab-1',
      name: 'Individual'
    },
    {
      id: 'tab-2',
      name: 'Corporation'
    }
  ]
  const [selectedTad, setSelectedTab] = useState('tab-1')
  const [values, setValues] = useState({
    id: '',
    prefix: '',
    firstName: '',
    lastName: '',
    legalName: '',
    phoneNumber: '',
    birthday: '',
    placeOfBirth: '',
    sinNumber: ''
  })

  useEffect(() => {
    getUsers(buyerId)
      .then((res) => setValues({
        id: res._id,
        prefix: res.buyerData.prefix,
        firstName: res.firstName,
        lastName: res.lastName,
        legalName: res.buyerData.legalName,
        phoneNumber: res.buyerData.phoneNumber,
        birthday: res.buyerData.birthday,
        placeOfBirth: res.buyerData.placeOfBirth
      }))
      .catch((err) => console.error(err))
  }, [])

  const onChange = (field, value) => {
    setValues({ ...values, [field]: value })
  }

  const demoMissingFields = [
    {
      title: 'Foreign tax number',
      bgColor: '#DC2626'
    },
    {
      title: 'Something (Corporation)',
      bgColor: '#DC2626'
    },
    {
      title: 'Place of birth',
      bgColor: '#DC2626'
    }
  ]

  const MissingFieldsBadges = ({ field: { title, bgColor } }) => (
    <div
      className='px-2 py-0.5 rounded-md shadow justify-start items-center gap-1 flex'
      style={{ backgroundColor: bgColor }}
    >
      <div className='text-center text-white text-xs font-medium leading-[16px]'>{title}</div>
    </div>
  )

  return (
    <div className='flex-1 w-full px-8 py-4 flex-col justify-start items-center gap-6 inline-flex'>
      <div className='w-full py-4 flex-col justify-center items-start gap-4 inline-flex'>
        <H5 className='w-full text-zinc-950 text-base font-semibold leading-normal mb-0'>
          Buyer details
        </H5>
        {
          demoMissingFields.length > 0 &&
          <div className='justify-start items-center gap-2 inline-flex'>
            <SubText
              className='text-zinc-950 text-sm font-medium leading-tight'
              label='Missing fields'
            />
            {
              demoMissingFields.map((field) => (
                <MissingFieldsBadges key={field.title} field={field} />
              ))
            }
          </div>
        }
      </div>
      <div className='w-[710px] p-6 bg-white dark:bg-black rounded-lg border border-zinc-200 dark:border-zinc-500 flex-col justify-start items-start gap-4 inline-flex'>
        <div className='flex items-center gap-x-2 p-1 rounded-lg bg-[#C1C0C1] dark:bg-zinc-800 bg-opacity-20'>
          {
            tabMenus.map((tab) => (
              <div
                key={tab.id}
                onClick={() => setSelectedTab(tab.id)}
                className={`flex items-center px-2 py-1 cursor-pointer rounded-md font-medium dark:text-white ${selectedTad === tab.id ? 'bg-white dark:bg-zinc-600 shadow-sm' : 'text-sm'} transition-all`}
              >
                {tab.name}
              </div>
            ))
          }
        </div>
        <div>
          <H5 className='text-lg font-semibold mb-4'>
            Personal Info
          </H5>
          <div>
            <div className='grid grid-cols-7 gap-6 pb-6'>
              <div className='col-span-1'>
                <CustomSelect
                  label='Prefix'
                  labelClasses='text-left text-sm font-normal capitalize dark:text-white'
                  inputClasses='h-10 bg-white text-sm font-normal dark:bg-zinc-900 dark:text-white dark:border-zinc-300'
                  fonts='text-sm font-normal dark:text-darkModeText'
                  selectedOption={values.prefix}
                  options={prefixData}
                  setSelectedOption={(e) => onChange('prefix', e)}
                  defaultValue={undefined}
                  isDarkFont
                />
              </div>
              <CustomInput
                classes='max-h-14 dark:bg-zinc-900 dark:text-white dark:border-zinc-300'
                label='First Name'
                placeholder='Enter first name'
                onChange={(e) => onChange('firstName', e.target.value)}
                value={values.firstName || ''}
                containerClasses='col-span-2'
                labelClassName='text-sm font-normal capitalize dark:text-white'
                isDarkFont
              />
              <CustomInput
                classes='max-h-14 dark:bg-zinc-900 dark:text-white dark:border-zinc-300'
                label='Last Name'
                placeholder='Enter last name'
                onChange={(e) => onChange('lastName', e.target.value)}
                value={values.lastName || ''}
                containerClasses='col-span-2'
                labelClassName='text-sm font-normal capitalize dark:text-white'
                isDarkFont
              />
              <CustomInput
                classes='max-h-14 dark:bg-zinc-900 dark:text-white dark:border-zinc-300'
                label='Legal Name'
                placeholder='Enter legal name'
                onChange={(e) => onChange('legalName', e.target.value)}
                value={values.legalName || ''}
                containerClasses='col-span-2'
                labelClassName='text-sm font-normal capitalize dark:text-white'
                isDarkFont
              />
            </div>
            <div>
              <div className='grid grid-cols-2 gap-6 pb-6'>
                <CustomInput
                  type='email'
                  classes='max-h-14 dark:bg-zinc-900 dark:text-white dark:border-zinc-300'
                  label='Email'
                  placeholder='Enter email'
                  onChange={(e) => onChange('email', e.target.value)}
                  value={values.email || ''}
                  containerClasses={undefined}
                  labelClassName='text-sm font-normal capitalize dark:text-white'
                  isDarkFont
                />
                <CustomInput
                  type='phone'
                  classes='max-h-14 dark:bg-zinc-900 dark:text-white dark:border-zinc-300'
                  label='Phone'
                  placeholder='Enter phone number'
                  onChange={(e) => onChange('phoneNumber', e.target.value)}
                  value={values.phoneNumber || ''}
                  containerClasses={undefined}
                  labelClassName='text-sm font-normal capitalize dark:text-white'
                  isDarkFont
                />
              </div>
            </div>
            <div>
              <div className='grid grid-cols-2 gap-6 pb-6'>
                <CustomInput
                  classes='max-h-14 dark:bg-zinc-900 dark:text-white dark:border-zinc-300'
                  label='Birthday'
                  placeholder='DD/MM/YYYY'
                  type='date'
                  onChange={(e) => onChange('birthday', e.target.value)}
                  value={values.birthday || ''}
                  containerClasses={undefined}
                  labelClassName='text-sm font-normal capitalize dark:text-white'
                  isDarkFont
                />
                <CustomInput
                  classes='max-h-14 dark:bg-zinc-900 dark:text-white dark:border-zinc-300'
                  label='Place Of Birth'
                  value={values.placeOfBirth}
                  onChange={(e) => onChange('placeOfBirth', e.target.value)}
                  placeholder='Place Of Birth'
                  containerClasses={undefined}
                  labelClassName='text-sm font-normal capitalize dark:text-white'
                  isDarkFont
                />
              </div>
            </div>
            <div>
              <div className='grid grid-cols-2 gap-6'>
                <CustomInput
                  classes='max-h-14 dark:bg-zinc-900 dark:text-white dark:border-zinc-300'
                  label='Sin Number'
                  placeholder='Enter sin number'
                  type='text'
                  onChange={(e) => onChange('sinNumber', e.target.value)}
                  value={values.sinNumber || ''}
                  containerClasses={undefined}
                  labelClassName='text-sm font-normal capitalize dark:text-white'
                  isDarkFont
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReviewBuyerDetailsTab
