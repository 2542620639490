import React, { useEffect, useState } from 'react'
import { getUsers } from 'store/actions/usersActions'
import { defaultRatingOptions } from 'utils'

const RatingIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.6562 7.14875L11.8375 9.60875L12.6819 13.2712C12.7266 13.4627 12.7138 13.6631 12.6452 13.8474C12.5766 14.0316 12.4552 14.1916 12.2962 14.3072C12.1372 14.4229 11.9477 14.4891 11.7513 14.4976C11.5548 14.5061 11.3603 14.4565 11.1919 14.355L7.9975 12.4175L4.81 14.355C4.6416 14.4565 4.44703 14.5061 4.2506 14.4976C4.05418 14.4891 3.86462 14.4229 3.70562 14.3072C3.54662 14.1916 3.42524 14.0316 3.35665 13.8474C3.28806 13.6631 3.27531 13.4627 3.32 13.2712L4.16312 9.6125L1.34375 7.14875C1.19463 7.02014 1.0868 6.85036 1.03378 6.66071C0.980764 6.47107 0.98492 6.26999 1.04573 6.08269C1.10654 5.89539 1.22129 5.73022 1.37559 5.60788C1.5299 5.48554 1.71689 5.41149 1.91312 5.395L5.62937 5.07312L7.08 1.61312C7.15575 1.43157 7.28353 1.27649 7.44724 1.16741C7.61095 1.05833 7.80327 1.00012 8 1.00012C8.19672 1.00012 8.38904 1.05833 8.55275 1.16741C8.71647 1.27649 8.84424 1.43157 8.92 1.61312L10.375 5.07312L14.09 5.395C14.2862 5.41149 14.4732 5.48554 14.6275 5.60788C14.7818 5.73022 14.8966 5.89539 14.9574 6.08269C15.0182 6.26999 15.0224 6.47107 14.9693 6.66071C14.9163 6.85036 14.8085 7.02014 14.6594 7.14875H14.6562Z" fill="#2E2B2E" fillOpacity="0.5" />
  </svg>
)

const UserIcon = () => (
  <svg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M16.4375 14.9136C16.39 14.9958 16.3217 15.0641 16.2395 15.1115C16.1573 15.159 16.064 15.184 15.9691 15.1839H2.99253C2.89766 15.1838 2.8045 15.1588 2.72238 15.1113C2.64026 15.0638 2.57208 14.9955 2.52469 14.9133C2.4773 14.8312 2.45237 14.738 2.45239 14.6431C2.45242 14.5482 2.4774 14.4551 2.52483 14.3729C3.55417 12.5934 5.14042 11.3173 6.9916 10.7124C6.07592 10.1673 5.3645 9.3367 4.96658 8.34813C4.56866 7.35956 4.50625 6.26769 4.78893 5.24022C5.07161 4.21274 5.68376 3.30647 6.53136 2.66057C7.37896 2.01467 8.41515 1.66486 9.4808 1.66486C10.5465 1.66486 11.5826 2.01467 12.4302 2.66057C13.2779 3.30647 13.89 4.21274 14.1727 5.24022C14.4554 6.26769 14.3929 7.35956 13.995 8.34813C13.5971 9.3367 12.8857 10.1673 11.97 10.7124C13.8212 11.3173 15.4074 12.5934 16.4368 14.3729C16.4843 14.455 16.5094 14.5482 16.5096 14.6432C16.5097 14.7381 16.4848 14.8313 16.4375 14.9136Z' fill='#2E2B2E' fillOpacity='0.5' />
  </svg>
)

const ContactDetailsGridView = ({ rating, userType, salesRep, brokerId }) => {
  const [broker, setBroker] = useState()
  const [cardColor, setCardColor] = useState({
    color: '',
    bgColor: ''
  })

  useEffect(() => {
    setCardColor(defaultRatingOptions.filter((elm) => elm.value === rating)[0])
  }, [defaultRatingOptions, rating])

  useEffect(() => {
    if (brokerId?.length) {
      getUsers(brokerId).then((res) => setBroker(res))
    }
  }, [brokerId])

  const Content = ({ Icon, title, label, type = '' }) => (
    <>
      <div className='col-span-1 justify-start items-center gap-1 inline-flex'>
        {Icon}
        <h6 className='text-zinc-800 text-opacity-50 text-base font-normal leading-snug m-0'>{title}</h6>
      </div>
      <div className='col-span-2 bg-white items-center gap-1 inline-flex'>
        <div
          className='px-2 py-1 rounded-md justify-center items-center gap-1 flex'
          style={{ background: type === 'rating' ? cardColor?.bgColor : '#F0EEE9' }}
        >
          {
            !label
              ? <div className='py-0.5 px-8 text-zinc-800 text-sm font-normal m-0'>-</div>
              : <>
                {
                  type === 'rating'
                    ? <div className='w-3 h-3 rounded-full' style={{ background: cardColor?.color ?? 'white' }} />
                    : <div className='h-6 w-6 p-1 border rounded-full flex items-center justify-center text-xs bg-zinc-300 font-medium'>
                      {label?.firstName[0]?.toUpperCase()}
                      {label?.lastName[0]?.toUpperCase()}
                    </div>
                }
                <h6 className='text-zinc-800 text-sm font-normal leading-tight m-0 py-0.5'>
                  {
                    type === 'rating' && label
                      ? label
                      : label?.firstName + ' ' + label?.lastName
                  }
                </h6>
              </>
          }
        </div>
      </div>
    </>
  )

  return (
    <div className='w-full grid grid-cols-3 gap-3 px-4 pb-4'>
      <Content
        Icon={<RatingIcon />}
        title='Rating'
        type='rating'
        label={rating}
      />
      {
        !(userType === 'broker') &&
        <Content
          Icon={<UserIcon />}
          title='Broker'
          label={broker}
        />
      }
      <Content
        Icon={<UserIcon />}
        title='Sales Rep'
        label={salesRep}
      />
    </div>
  )
}

export default ContactDetailsGridView
