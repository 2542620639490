import Logo from 'components/LoginPage/Logo'
import React from 'react'
import background from 'assets/img/bg.png'

const Thankyou = () => {
  return (
    <div className='h-screen w-full flex'>
      <div className='p-8 bg-stone-100 w-full md:w-1/3'>
        <div className='relative flex flex-col justify-center items-center h-full'>
          <Logo />
          <div className='max-w-md w-full' style={{ margin: 'auto' }}>
            <h1 className='text-2xl font-bold'>Thank you!</h1>
            <p className='text-sm mt-3'>
              You have completed your contract signing we will be in touch with
              next steps
            </p>
            {/* <a href={'https://app.aires.ai/'}>Go To ONE ZEN's Website</a> */}
          </div>
        </div>
      </div>
      <div className='w-0 md:w-2/3 md:flex h-full'>
        <img
          className='h-full w-full object-left-top'
          alt='background'
          src={background}
        />
      </div>
    </div>
  )
}

export default Thankyou
