import { useQuery } from '@tanstack/react-query'
import { getReferralAgents } from 'store/actions/usersActions'
import useProjectId from './useProjectId'
import useUser from './useUser'

const useReferralAgents = () => {
  const { user } = useUser()
  const { projectId } = useProjectId()
  const { data, isFetching } = useQuery(['referralAgents', projectId], ({ queryKey }) => getReferralAgents(queryKey[1]),
    { refetchOnMount: false, refetchOnWindowFocus: false, refetchOnReconnect: false, enabled: !!user?._id })
  return { referralAgents: data, isFetchingReferralAgents: isFetching }
}

export default useReferralAgents
