// @ts-nocheck
/* eslint-disable */
import React, { useEffect, useState, useMemo, useCallback } from 'react'
import Skeleton from 'react-loading-skeleton'

import useGetParticipantsMeetings from 'hooks/useGetParticipantsMeetings'

import NewMeeting from 'components/NewMeeting'
import MeetingCard from 'components/MeetingCard'
import useBuyers from 'hooks/useGetBuyers'
import moment from 'moment'
import { classNames, getCalenderDateMomentUsingObjectType } from 'utils'
import { ChevronLeftIcon, ChevronRightIcon, PlusIcon } from '@heroicons/react/solid'
import CustomButton from './CustomButton'

const days = [
  { date: '2021-12-27' },
  { date: '2021-12-28' },
  { date: '2021-12-29' },
  { date: '2021-12-30' },
  { date: '2021-12-31' },
  { date: '2022-01-01', isCurrentMonth: true },
  { date: '2022-01-02', isCurrentMonth: true },
  { date: '2022-01-03', isCurrentMonth: true },
  { date: '2022-01-04', isCurrentMonth: true },
  { date: '2022-01-05', isCurrentMonth: true },
  { date: '2022-01-06', isCurrentMonth: true },
  { date: '2022-01-07', isCurrentMonth: true },
  { date: '2022-01-08', isCurrentMonth: true },
  { date: '2022-01-09', isCurrentMonth: true },
  { date: '2022-01-10', isCurrentMonth: true },
  { date: '2022-01-11', isCurrentMonth: true },
  { date: '2022-01-12', isCurrentMonth: true, isToday: true },
  { date: '2022-01-13', isCurrentMonth: true },
  { date: '2022-01-14', isCurrentMonth: true },
  { date: '2022-01-15', isCurrentMonth: true },
  { date: '2022-01-16', isCurrentMonth: true },
  { date: '2022-01-17', isCurrentMonth: true },
  { date: '2022-01-18', isCurrentMonth: true },
  { date: '2022-01-19', isCurrentMonth: true },
  { date: '2022-01-20', isCurrentMonth: true },
  { date: '2022-01-21', isCurrentMonth: true, isSelected: true },
  { date: '2022-01-22', isCurrentMonth: true },
  { date: '2022-01-23', isCurrentMonth: true },
  { date: '2022-01-24', isCurrentMonth: true },
  { date: '2022-01-25', isCurrentMonth: true },
  { date: '2022-01-26', isCurrentMonth: true },
  { date: '2022-01-27', isCurrentMonth: true },
  { date: '2022-01-28', isCurrentMonth: true },
  { date: '2022-01-29', isCurrentMonth: true },
  { date: '2022-01-30', isCurrentMonth: true },
  { date: '2022-01-31', isCurrentMonth: true },
  { date: '2022-02-01' },
  { date: '2022-02-02' },
  { date: '2022-02-03' },
  { date: '2022-02-04' },
  { date: '2022-02-05' },
  { date: '2022-02-06' },
]

const MeetingTab = ({ contact }) => {
  const { buyers, getBuyersData } = useBuyers()
  const { loading, meetings, refetch } = useGetParticipantsMeetings(contact._id)
  const [createMeeting, setCreateMeeting] = useState(false)

  const filteredMeetings = useMemo(() => {
    console.log(meetings.data)
    if (!meetings.data?.length || loading) { return [] }
    return meetings.data.filter((_meeting) => _meeting?.participants?.filter(_part => _part?.email === contact?.email).length)
  }, [meetings.data, loading])

  const groupByMeetings = useCallback((meetings) => {
    const filteredMeetingsDateWise = meetings.reduce((groups, item) => {
      const dateFormat = 'MM/DD/YYYY'
      const startDate = moment(getCalenderDateMomentUsingObjectType(item.when.object, item.when.start_time))
      if (!groups[startDate.format(dateFormat)]) {
        groups[startDate.format(dateFormat)] = []
      }
      groups[startDate.format(dateFormat)].push(item)
      return groups
    }, {})
    return {
      data: filteredMeetingsDateWise,
      total: meetings.length
    }
  }, [])

  const sortedMeetings = useMemo(() => {
    let clone = [...filteredMeetings]
    clone = clone.map((elm) => ({
      ...elm,
      timestamp: moment(getCalenderDateMomentUsingObjectType(elm.when.object, elm.when.start_time)).unix(),
      testTime: moment(getCalenderDateMomentUsingObjectType(elm.when.object, elm.when.start_time)).format()
    }))
    clone.sort((a, b) => a.timestamp - b.timestamp)

    const upcomingDates = []
    const pastDates = []

    const currentDate = parseInt(new Date().getTime() / 1000);
    clone.forEach((obj) => {
      if (obj.timestamp > currentDate) {
        upcomingDates.push(obj)
      } else {
        pastDates.push(obj)
      }
    });
    return {
      upcoming: groupByMeetings(upcomingDates),
      past: groupByMeetings(pastDates)
    }
  }, [filteredMeetings])

  useEffect(() => {
    getBuyersData()
  }, [])

  const openCreateModal = () => {
    setCreateMeeting(true)
  }

  const onSuccess = () => {
    refetch()
  }

  const CalendarUI = () => {
    return (
      <div>
        <div className="flex items-center">
          <h2 className="flex-auto text-xl font-medium text-gray-900">November</h2>
          <button
            type="button"
            className="-my-1.5 flex flex-none items-center justify-center p-1.5 text-gray-900"
          >
            <span className="sr-only">Previous month</span>
            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
          </button>
          <button
            type="button"
            className="-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center p-1.5 text-gray-900"
          >
            <span className="sr-only">Next month</span>
            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
        <div className="mt-4 grid grid-cols-7 text-center text-xs leading-6 text-gray-500">
          <div>M</div>
          <div>T</div>
          <div>W</div>
          <div>T</div>
          <div>F</div>
          <div>S</div>
          <div>S</div>
        </div>
        <div className="mt-2 grid grid-cols-7 text-sm">
          {days.map((day, dayIdx) => (
            <div key={day.date}>
              <button
                type="button"
                className={classNames(
                  day.isSelected && 'text-white',
                  !day.isSelected && !day.isToday && !day.isCurrentMonth && 'text-gray-400',
                  day.isSelected && !day.isToday && 'bg-[#1C64F2]',
                  !day.isSelected && 'hover:bg-gray-200',
                  'mx-auto flex h-8 w-8 text-[10px] font-medium items-center justify-center rounded-full'
                )}
              >
                <time dateTime={day.date}>{day.date.split('-').pop()}</time>
              </button>
            </div>
          ))}
        </div>
      </div>
    )
  }

  const MeetingWithDateByWrapper = ({ title, meetings }) => {
    return (
      <div>
        <h6 className='m-0 text-base font-semibold flex gap-1 pb-2'>
          <span>{moment(title).format('MMM DD, YYYY')}</span>
          <span className='font-normal text-softBlack_70'>{moment(title).format('dddd')}</span>
        </h6>
        <div className='flex flex-col gap-y-4'>
          {
            meetings.map((meeting, index) => <MeetingCard meeting={meeting} key={`${meeting._id}_${index}`} onSuccess={onSuccess} contact={contact} buyers={buyers} />)
          }
        </div>
      </div>
    )
  }

  const MeetingWrapper = ({ meetings }) => {
    return (
      <div className='flex flex-col gap-y-6'>
        {
          meetings.upcoming &&
          <div className='flex flex-col gap-y-6'>
            <h6 className='text-xl font-semibold m-0'>Upcoming events ({meetings.upcoming.total})</h6>
            {
              Object.keys(meetings.upcoming.data).map((meetingsByDate) => <MeetingWithDateByWrapper title={meetingsByDate} meetings={meetings.upcoming.data[meetingsByDate]} />)
            }
          </div>
        }
        {
          meetings.past &&
          <div className='flex flex-col gap-y-6'>
            <h6 className='text-xl font-semibold m-0'>Past events ({meetings.past.total})</h6>
            {
              Object.keys(meetings.past.data).map((meetingsByDate) => <MeetingWithDateByWrapper title={meetingsByDate} meetings={meetings.past.data[meetingsByDate]} />)
            }
          </div>
        }

      </div>
    )
  }

  return (
    <div className='h-full'>
      {loading && (
        <div className='flex flex-col gap-6 m-4 mt-8'>
          <Skeleton height={100} />
        </div>
      )}
      {!loading && !filteredMeetings?.length && (
        <div className='font-openSans flex flex-col justify-center items-center h-full w-full bg-white rounded'>
          <svg
            width='22'
            height='24'
            viewBox='0 0 18 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M16 2H15V0H13V2H5V0H3V2H2C0.89 2 0.00999999 2.9 0.00999999 4L0 18C0 19.1 0.89 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM16 18H2V7H16V18ZM4 9H9V14H4V9Z'
              fill='#2E2B2E'
            />
          </svg>
          <div className='font-semibold mb-2 mt-4'>You don't have any meetings yet</div>
          <div className='text-sm text-softBlack70 mb-6'>
            Schedule your first meeting with {contact?.firstName || ''} {' '} {contact?.lastName || ''}
          </div>
          <CustomButton handleClick={openCreateModal}>
            <span className='font-semibold px-4 py-2 rounded text-base'>
              Schedule Meeting
            </span>
          </CustomButton>
        </div>
      )}
      {!loading && filteredMeetings.length > 0 && (
        <div className='w-full h-full flex'>
          <div className='flex-1 p-4 overflow-y-auto'>
            {/* <div className='font-openSans flex flex-col gap-y-4 my-8'>
              {filteredMeetings?.map((_meeting, index) => <MeetingCard meeting={_meeting} key={`${_meeting._id}_${index}`} onSuccess={onSuccess} contact={contact} buyers={buyers} />)}
            </div> */}
            <MeetingWrapper meetings={sortedMeetings} />
          </div>
          <div className='w-[350px] h-full border-l flex flex-col p-6'>
            <CalendarUI />
            <div className='text-center flex flex-col py-4 gap-y-2'>
              <button
                onClick={() => {
                  setCreateMeeting(true)
                }}
                className='bg-volt px-1.5 py-3 rounded flex justify-center items-center gap-x-2'
              >
                <PlusIcon className='h-[22px] w-[22px]' />
                <h6 className='italic text-sm font-medium m-0 text-center'>Schedule Meeting</h6>
              </button>
              <button
                // onClick={() => {
                //   setCreateMeeting(true)
                // }}
                className='bg-white border border-softBlack_70/100 px-1.5 py-3 rounded flex justify-center items-center gap-x-2'
              >
                <PlusIcon className='h-[22px] w-[22px]' />
                <h6 className='italic text-sm font-medium m-0 text-center'>Add key date</h6>
              </button>
            </div>
            <div className='py-6 flex flex-col gap-y-2'>
              <h4 className='m-0 text-xl font-medium'>Key dates</h4>
              <div className='grid grid-cols-7 gap-y-2 gap-x-6'>
                <div className='col-span-2 text-sm font-normal text-softBlack_70/50'>Deposit</div>
                <div className='col-span-5 text-sm font-normal text-softBlack_70/100'>Date</div>
                <div className='col-span-2 text-sm font-normal text-softBlack_70/50'>Move in</div>
                <div className='col-span-5 text-sm font-normal text-softBlack_70/100'>Date</div>
              </div>
            </div>
          </div>
        </div>
      )}
      {createMeeting ? <NewMeeting isOpen={createMeeting} setOpen={setCreateMeeting} contact={contact} onSuccess={onSuccess} buyers={buyers} /> : null}
    </div>
  )
}

export default MeetingTab
