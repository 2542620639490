export const userTypes = [
  { id: 'Buyer', value: 'Buyer' },
  { id: 'SuperAdmin', value: 'Super Admin' },
  { id: 'DeveloperAdmin', value: 'Developer Admin' },
  { id: 'SalesRep', value: 'Sales Rep' },
  { id: 'CoopBroker', value: 'Broker' },
  { id: 'Developer', value: 'Developer' },
  { id: 'Lead', value: 'Lead' },
  { id: 'CorporateBuyer', value: 'Corporate Buyer' }
]

export const getUserType = (userType) => {
  let tmpUserType = ''

  if (userType) {
    tmpUserType = userTypes.filter(element => element.id === userType)[0].value
  }
  return tmpUserType
}
