import { useState, useEffect, useRef, useCallback } from 'react'
import { getAllEmailContacts } from 'store/actions/usersActions'

const useGetAllUserEmailContacts = () => {
  const mountedRef = useRef(true)
  const [contacts, setContacts] = useState([])
  const [loading, setLoading] = useState(false)

  const getData = useCallback(async () => {
    if (!mountedRef.current) return null
    setLoading(true)
    try {
      const data = await getAllEmailContacts()
      if (!mountedRef.current) return null
      setContacts(data)
      setLoading(false)
    } catch (err) {
      if (!mountedRef.current) return null
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    if (window.location.pathname === '/admin') return
    getData()
  }, [getData])

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  return { loading, contacts, refetch: getData }
}

export default useGetAllUserEmailContacts
