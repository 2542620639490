/* eslint-disable no-unused-vars */
import React, { useContext } from 'react'
import Form from 'components/Form/Form'
import FormInput from 'components/Form/FormInput'
import { Box } from '@material-ui/core'
import { DynamicDropdownContext } from '../context'
import CreateButton from './CreateButton'

const AddForm = () => {
  const {
    initialValues,
    setInitalValues,
    isEdit,
    setIsEdit,
    oldName,
    label,
    update,
    updateItem
  } = useContext(DynamicDropdownContext)

  const onSubmit = async (values) => {
    if (isEdit) {
      await updateItem({ ...values, oldName })
      setIsEdit(false)
    } else {
      await update(values)
    }
    setInitalValues({ name: '' })
  }

  return (
    <Form
      wrapperStyle={{ maxWidth: '500px' }}
      className='flex flex-col justify-center items-center w-full bg-white p-4 h-max-content gap-4 mt-8'
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      <Box
        style={{
          fontWeight: 'bold',
          fontSize: '18px'
        }}
      >
        {isEdit ? 'Update' : 'Create New'} {label}
      </Box>
      <FormInput
        wrapperClass='w-full'
        inputClass='flex-0'
        fieldName='name'
        placeholder='Enter name'
        title={`${label?.toUpperCase()}*`}
        titleClass='font-normal'
      />
      <CreateButton />
    </Form>
  )
}

export default AddForm
