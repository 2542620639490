import { Drawer } from 'antd'
import CustomButton from 'components/CustomButton'
import React, { useState } from 'react'
import { sendTestEmailToEmails } from 'store/actions/emailTemplateActions'

const SendTestEmail = ({ onClose, templateId, open }) => {
  const [subject, setSubject] = useState('')
  const [recipients, setRecipients] = useState([])
  const [isSending, setIsSending] = useState(false)

  const send = () => {
    const _subject = subject
    const _recipients = recipients
    setIsSending(true)
    sendTestEmailToEmails(templateId, _subject, _recipients).then(() => {
      alert('Email sent successfully')
    }).finally(() => {
      setIsSending(false)
    })
  }
  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }

  if (!templateId) return null
  return (
    <>
      <Drawer closable={false} placement="right" onClose={() => {
        onClose()
      }} open={open}>
        <div className='px-2 py-8'>
          <span className='text-xs'>
            ({templateId})
          </span>
          <h1 className='text-2xl font-semibold'>
            Send Test Email
          </h1>
          <div className='flex flex-col gap-6 my-10 mb-14'>
            <div className='flex flex-1 flex-col justify-left'>
              <div className='font-bold text-softBlack_70 italic'>Email Subject</div>
              <input
                id='subject'
                onChange={(e) => setSubject(e.target.value)}
                placeholder='Email Subject'
              />
            </div>
            <div className='flex flex-1 flex-col justify-left'>
              <div className='font-bold text-softBlack_70 italic'>Recipients</div>
              <input
                id='recipients'
                onChange={(e) => {
                  let emails = e.target.value.split(',')
                  emails = emails.map((email) => email.trim())
                  emails = emails.filter((email) => email)
                  // check if email is valid
                  emails = emails.filter((email) => isValidEmail(email))
                  setRecipients(emails)
                }}
                placeholder='mike@example.com,moll@example.com'
              />
            </div>
            <div className='flex flex-col gap-y-1'>
              {recipients.map((recipient, index) => (
                <div key={recipient} className='text-sm flex gap-x-1'>
                  <span>
                    ({index + 1})
                  </span>
                  <span>
                    {recipient}
                  </span>
                </div>
              ))}
            </div>
            <div className='flex justify-end gap-2'>
              <CustomButton
                variant='outline'
                handleClick={onClose}
              >
                <span className='font-medium py-2 px-6 text-base flex gap-2 items-center'>
                  Cancel
                </span>
              </CustomButton>
              <CustomButton
                handleClick={send}
                disabled={!subject || !recipients.length || isSending}
              >
                <span className='font-medium py-2 px-6 text-base flex gap-2 items-center'>
                  {isSending ? 'Sending...' : 'Send'}
                </span>
              </CustomButton>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  )
}

export default SendTestEmail
