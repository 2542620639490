import { api, ApiErrors } from 'services/api'

export const getBrokerLeads = (id = '') =>
  new Promise((resolve, reject) => {
    api.get(`landing-page?project=${id}`).then((response) => {
      if (response.ok) {
        resolve(response?.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getBrokerLeadDetails = (id = '') =>
  new Promise((resolve, reject) => {
    api.get(`landing-page/${id}`).then((response) => {
      if (response.ok) {
        resolve(response?.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const setBrokerLeadDetails = (params) =>
  new Promise((resolve, reject) => {
    const _id = params._id
    const verb = _id ? 'put' : 'post'
    const url = _id ? `landing-page/${_id}` : 'landing-page'
    api[verb](url, params).then((response) => {
      if (response.ok) {
        resolve(response?.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })
