import React, { useState, useEffect, useCallback } from 'react'
import propTypes from 'prop-types'
import { HeaderLogo, SurveyStepWrapper, SurveyTitle, SurveySubTitle, SurveyFooter } from './surveyComponents'
import { externalGetBrokers } from 'store/actions/usersActions'
import { InputLabel, TButton } from 'ui'
import { UserRoundedImage } from 'components'

const Realtor = ({ onClickContinue, selectedRealtor, onSelectRealtor }) => {
  const [gettingRealtors, setGettingRealtors] = useState(false)
  const [realtors, setRealtors] = useState([])
  const [originalRealtors, setOriginalRealtors] = useState([])
  const [realtorName, setRealtorName] = useState('')

  useEffect(() => {
    if (Object.keys(selectedRealtor).length) {
      setRealtorName(`${selectedRealtor.firstName} ${selectedRealtor.lastName}`)
    }
  }, [selectedRealtor, onSelectRealtor])

  useEffect(() => {
    setGettingRealtors(true)

    externalGetBrokers('', '?userType=CoopBroker')
      .then((tmpRealtors) => {
        setGettingRealtors(false)
        setOriginalRealtors(tmpRealtors)
        setRealtors(tmpRealtors)
      })
      .catch(() => setGettingRealtors(false))
  }, [])

  const filterRealtors = useCallback((text) => {
    let filteredRealtors = [...originalRealtors]

    if (text) {
      filteredRealtors = filteredRealtors.filter(user => {
        const { firstName, lastName } = user
        const fullName = `${firstName} ${lastName}`

        return fullName.toLowerCase().includes(text.toLowerCase())
      })
    }

    setRealtors(filteredRealtors)
  }, [originalRealtors])

  useEffect(() => {
    filterRealtors(realtorName)
  }, [realtorName, filterRealtors])

  const renderRealtors = useCallback((selectedRealtor) => (
    realtors.map((realtor) => {
      const { brokerData: { city, province, developerCompany }, firstName, lastName, userAvatar, _id } = realtor

      let developerLogo = ''

      if (developerCompany.length) {
        developerLogo = developerCompany[0].logoImage
      }

      const selected = selectedRealtor._id === _id
      const fullName = `${firstName} ${lastName}`

      return (
        <div
          className={'flex flex-col bg-white rounded-md shadow-md px-10 py-5 items-center'}
          style={{ borderColor: selected && '#3f8f9d', borderWidth: selected && 3 }}
        >
          <div className='flex flex-row items-center'>
            <UserRoundedImage src={userAvatar} className='h-18 w-18' />
            <div className='flex flex-col px-4'>
              <span className='text-xl'>{fullName}</span>
              <span>{`${city}, ${province}`}</span>
            </div>
            {developerLogo && (
              <img src={developerLogo} alt='Realtor Developer' className='h-18 w-36' />
            )}
          </div>
          <TButton
            className='font-bold mt-4 w-24'
            style={{ background: '#3f8f9d' }}
            onClick={() => onSelectRealtor({ firstName, lastName, _id })}
          >
            Select
          </TButton>
        </div>
      )
    })
  ), [realtors, onSelectRealtor])

  return (
    <SurveyStepWrapper>
      <HeaderLogo className='rounded-full w-20 h-20'/>
      <SurveyTitle className='my-7'>Working With a Realtor?</SurveyTitle>
      <SurveySubTitle>Let's get them caught up. Work seamlessly side by side.</SurveySubTitle>

      <InputLabel
        key='realtorName'
        id='realtorName'
        label=''
        placeholder='Search Realtors by name...'
        value={realtorName}
        onChange={(e) => {
          const value = e.target.value

          setRealtorName(value)

          if (!value) {
            onSelectRealtor('')
          }
        }}
        inputWrapperClassName='mt-5 w-full'
        className='h-36'
        loading={gettingRealtors}
      />

      <div className='flex flex-col w-full space-y-6 h-60 overflow-y-auto mt-5'>
        {realtorName.trim() && renderRealtors(selectedRealtor)}
      </div>

      <SurveyFooter onClickContinue={onClickContinue} />
    </SurveyStepWrapper>
  )
}

export default Realtor

Realtor.propTypes = {
  onClickContinue: propTypes.func,
  selectedRealtor: propTypes.object,
  onSelectRealtor: propTypes.func
}
