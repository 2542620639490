export const NAME_LOCALSTORAGE = 'pxp.accounts.accessToken'

export const isAuthenticated = () => {
  const token = localStorage.getItem(NAME_LOCALSTORAGE)

  if (token !== null) return token

  return false
}

export const login = (token) => localStorage.setItem(NAME_LOCALSTORAGE, token)
export const logout = () => localStorage.removeItem(NAME_LOCALSTORAGE)
