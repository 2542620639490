import styled from 'styled-components'

export const UploadButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const StyledImage = styled.img`
  width: 100%;  
  height: inherit;
  object-fit: contain;
`
