import React from 'react'
import propTypes from 'prop-types'
import { classNames } from 'utils'
import { Wrapper } from './styles'

const AdminPageWrapper = ({ children, className, style, fullscreen }) => (
  <Wrapper
    className={classNames(className, 'h-full w-full bg-white')}
    style={style && style}
  >
    <div
      className={`mx-auto px-4 sm:px-6 lg:px-8 h-full ${
        fullscreen ? '' : 'max-w-7xl'
      }`}
    >
      {children}
    </div>
  </Wrapper>
)

export default AdminPageWrapper

AdminPageWrapper.defaultProps = {
  className: '',
  fullscreen: false
}

AdminPageWrapper.propTypes = {
  children: propTypes.node,
  className: propTypes.string,
  style: propTypes.object,
  fullscreen: propTypes.bool
}
