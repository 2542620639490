import React from 'react'
import propTypes from 'prop-types'
import { LottieWrapper } from 'components'

import createOfferSuccessAnimation from 'assets/lottieJsons/createOfferSuccess.json'
import { SuccessPageWrapper, SuccessPageButtonsWrapper } from './styles'
import { motion, useAnimation } from 'framer-motion'
import { TButton } from 'ui'

const animatedVariants = {
  hidden: {
    opacity: 0
  },
  show: {
    opacity: 1
  }
}

const SuccessPage = (props) => {
  const { executeWhenDone, nextButtonClassName } = props

  const animationControl = useAnimation()
  const titleControl = useAnimation()
  const subtitleControl = useAnimation()
  const buttonControl = useAnimation()

  animationControl.start('show')

  setTimeout(() => {
    titleControl.start('show')
  }, 3000)

  setTimeout(() => {
    subtitleControl.start('show')
  }, 4500)

  setTimeout(() => {
    buttonControl.start('show')
  }, 4800)

  const hideAndExecuteWhenDone = () => {
    executeWhenDone()
  }

  return (
    <SuccessPageWrapper style={{ textAlign: 'center' }}>
      <motion.div
        variants={animatedVariants}
        initial='hidden'
        animate={animationControl}
        style={{ marginTop: -80 }}
      >
        <LottieWrapper
          animation={createOfferSuccessAnimation}
          isStopped={false}
          isPaused={false}
          loop={false}
          autoplay
          className='max-w-lg'
        />
      </motion.div>

      <motion.div
        variants={animatedVariants}
        initial='hidden'
        animate={titleControl}
        style={{ marginTop: -80 }}
        className='mx-20 md:mx-0'
      >
        <span className='text-2xl'>The Offer was created successfully!</span>
      </motion.div>

      <SuccessPageButtonsWrapper
        variants={animatedVariants}
        initial='hidden'
        animate={buttonControl}
        style={{ marginTop: 25 }}
      >
        <TButton
          onClick={hideAndExecuteWhenDone}
          className={nextButtonClassName}
        >
          Next
        </TButton>
      </SuccessPageButtonsWrapper>
    </SuccessPageWrapper>
  )
}

export default SuccessPage

SuccessPage.propTypes = {
  executeWhenDone: propTypes.func,
  nextButtonClassName: propTypes.string
}
