import React from 'react'
import { Input } from 'antd'
import styled from 'styled-components'

const { TextArea } = Input

const TextAreaCustom = props => <TextArea {...props} />
const CustomTextArea = styled(TextAreaCustom)`
`

export default CustomTextArea
