import React, { useState } from 'react'
import propTypes from 'prop-types'
import { Radio } from 'antd'
import { LottieWrapper } from 'components'
import standardStyle from 'assets/css/standardStyle'
import cameraSnapshotAnimation from 'assets/lottieJsons/cameraSnapshot.json'
import openingFolderAnimation from 'assets/lottieJsons/openingFolder.json'

import {
  GetPhotoIdMethodButton
} from './styles'
import { classNames } from 'utils'

const confirmationButtonsVariants = {
  initial: {
    x: 1000
  },
  confirmButtonEnter: {
    x: '20%'
  },
  cameraButtonEnter: {
    x: '50%'
  },
  cancelButtonEnter: (custom) => ({
    x: '-120%',
    transition: {
      delay: custom,
      x: { type: 'spring', stiffness: 300, damping: 30 }
    }
  }),
  libraryButtonEnter: (custom) => ({
    x: '-50%',
    transition: {
      delay: custom,
      x: { type: 'spring', stiffness: 265, damping: 30 }
    }
  }),
  exit: (custom) => ({
    x: 1000,
    transition: { delay: custom }
  })
}

const ImageUploadMethodsButtons = (props) => {
  const [cameraLottieDirection, setCameraLottieDirection] = useState(1)
  const [cameraLottieStopped, setCameraLottieStopped] = useState(true)
  const [libraryLottieDirection, setLibraryLottieDirection] = useState(1)
  const [libraryLottieStopped, setLibraryLottieStopped] = useState(true)

  const {
    onLibraryMethodClick, onCameraMethodClick,
    cameraSelected, librarySelected,
    animate, style, wrapperClassName,
    lottieClassName
  } = props

  const renderCameraUploadMethod = () => (
    <GetPhotoIdMethodButton
      key='cameraButton'
      initial={animate ? 'initial' : 'unset'}
      animate={animate ? 'cameraButtonEnter' : 'unset'}
      exit={animate ? 'exit' : 'unset'}
      variants={confirmationButtonsVariants}
      transition={{ duration: 0.2 }}
      custom={0}
      className='rounded-r flex w-2/4'
      onClick={(e) => {
        setCameraLottieDirection(1)
        setCameraLottieStopped(false)
        setLibraryLottieDirection(-1)
        // chosePhotoIDMethod(e, GET_PHOTO_ID_FROM_CAMERA)
        e.stopPropagation()
        onCameraMethodClick()
      }}
      background={standardStyle.colors.gray}
      style={{ zIndex: 10, ...style }}
    >
      <LottieWrapper
        animation={cameraSnapshotAnimation}
        isStopped={cameraLottieStopped}
        autoplay={false}
        direction={cameraLottieDirection}
        className={classNames('w-3/5', lottieClassName)}
      />
      <span className='text-base'>Get Photo ID from camera</span>
      <Radio checked={cameraSelected} />
    </GetPhotoIdMethodButton>
  )

  const renderLibraryUploadMethod = () => (
    <GetPhotoIdMethodButton
      key='libraryButton'
      initial={animate ? 'initial' : 'unset'}
      animate={animate ? 'libraryButtonEnter' : 'unset'}
      exit={animate ? 'exit' : 'unset'}
      variants={confirmationButtonsVariants}
      transition={{ duration: 0.2 }}
      className='rounded-l flex w-2/4'
      onClick={(e) => {
        setLibraryLottieDirection(1)
        setLibraryLottieStopped(false)
        setCameraLottieDirection(1)
        setCameraLottieStopped(true)
        e.stopPropagation()
        onLibraryMethodClick()
      }}
      custom={0.01}
      background='#d2a679'
      style={{ zIndex: 5, ...style }}
    >
      <LottieWrapper
        animation={openingFolderAnimation}
        isStopped={libraryLottieStopped}
        autoplay={false}
        direction={libraryLottieDirection}
        className={classNames('w-2/5', lottieClassName)}
      />
      <span className='text-base'>Get Photo ID from library</span>
      <Radio checked={librarySelected} />
    </GetPhotoIdMethodButton>
  )

  return (
    <div className={classNames(`flex flex-row w-full h-full items-center justify-center overflow-hidden ${wrapperClassName}`)}>
      {renderCameraUploadMethod()}
      {renderLibraryUploadMethod()}
    </div>
  )
}
export default ImageUploadMethodsButtons

ImageUploadMethodsButtons.defaultProps = {
  wrapperClassName: '',
  lottieClassName: ''
}

ImageUploadMethodsButtons.propTypes = {
  onLibraryMethodClick: propTypes.func,
  onCameraMethodClick: propTypes.func,
  cameraSelected: propTypes.bool,
  librarySelected: propTypes.bool,
  animate: propTypes.bool,
  style: propTypes.object,
  wrapperClassName: propTypes.string,
  lottieClassName: propTypes.string
}
