import React from 'react'
import propTypes from 'prop-types'
import { motion } from 'framer-motion'

const itemsWrapper = {
  hidden: { opacity: 1, scale: 0 },
  visible: (custom) => ({
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: custom
    }
  })
}

const slideBottomItem = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1
  }
}

const AnimatedWrapper = ({ className, children, entranceSpeed }) => (
  <motion.ul
    variants={itemsWrapper}
    initial='hidden'
    animate='visible'
    className={className}
    custom={entranceSpeed}
  >
    {children}
  </motion.ul>
)

const AnimatedItem = ({ children, animationObject, className }) => (
  <motion.li
    variants={animationObject}
    className={className}
    transition={{
      x: { type: 'spring', stiffness: 300, damping: 30 }
    }}
  >
    { children }
  </motion.li>
)

const AnimatedList = ({ wrapperClassName, children, entrance, itemClassName, entranceSpeed }) => (
  <AnimatedWrapper className={wrapperClassName} entranceSpeed={entranceSpeed}>
    {
      children?.map((element, index) =>
        <AnimatedItem
          key={index}
          animationObject={entrance}
          className={itemClassName}
        >
          {element}
        </AnimatedItem>
      )
    }
  </AnimatedWrapper>
)

export default AnimatedList

AnimatedList.defaultProps = {
  entrance: slideBottomItem,
  entranceSpeed: 0.1
}

AnimatedList.propTypes = {
  wrapperClassName: propTypes.string,
  children: propTypes.array,
  entrance: propTypes.object,
  itemClassName: propTypes.string,
  entranceSpeed: propTypes.number
}

AnimatedWrapper.propTypes = {
  className: propTypes.string,
  children: propTypes.node,
  entranceSpeed: propTypes.number
}

AnimatedItem.propTypes = {
  children: propTypes.node,
  animationObject: propTypes.object,
  className: propTypes.string
}
