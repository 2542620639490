import DeleteIcon from 'components/Icons/DeleteIcon'
import TrophieIcon from 'components/Icons/TrophieIcon'
import UploadIcon from 'components/Icons/UploadIcon'
import { useFormikContext } from 'formik'
import React from 'react'
import TempImage from '../tempImage'

const FormHeader = () => {
  const { values } = useFormikContext()
  return (
    <div className='w-full flex flex-row justify-between items-center p-4'>
        <div className='flex flex-row gap-4 w-max-content'>
            <TempImage />
            <div className='flex flex-col items-start justify-between'>
                <div className='font-bold text-2xl'>{`${values.firstName} ${values.lastName}`}</div>
                <div className='font-normal text-md flex flex-row gap-2'><TrophieIcon width={20} height={20} />Sales Rep</div>
            </div>
        </div>
        <div className='flex flex-row w-max-content gap-4'>
            <button className='flex flex-row gap-2 items-center justify-center p-3 border-black border rounded italic text-black'>
                <DeleteIcon width={20} height={20} />Delete
            </button>
            <button className='flex flex-row gap-2 items-center justify-center p-3 border-black border rounded italic text-black'>
                <UploadIcon width={20} height={20} />Upload
            </button>
        </div>
    </div>
  )
}

export default FormHeader
