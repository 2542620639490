import React, { useMemo, useState } from 'react'
import morrisonWalkNorthVancouverBg from '../../assets/img/morrison-walk-north-vancouver.jpg'
import morrisonWalkLogo from '../../assets/img/morrison-walk-logo.jpeg'
import { AiFillCaretUp } from 'react-icons/ai'
import { BsCircleFill } from 'react-icons/bs'
import { CheckIcon } from '@heroicons/react/outline'
import SubText from './components/Subtext'
import { H5 } from 'ui'
import { KeyRound } from 'lucide-react'
import { isMobile } from 'react-device-detect'
import '../../assets/css/tailwind.css'

const AdminNewDashboard = () => {
  const tabMenus = [
    {
      id: 'tab-1',
      name: 'Sales'
    },
    {
      id: 'tab-2',
      name: 'Parking'
    },
    {
      id: 'tab-3',
      name: 'Waitlist'
    },
    {
      id: 'tab-4',
      name: 'Report'
    }
  ]
  const tabMenusMobile = [
    {
      id: 'tab-1',
      name: 'Sales'
    },
    {
      id: 'tab-2',
      name: 'Inbox'
    }
  ]
  const [selectedTad, setSelectedTab] = useState('tab-1')

  const getActivities = useMemo(() => {
    return [
      {
        id: 'activity_1',
        title: 'Initial inquiry',
        details: 'Initial inquiry made by the buyer {{name}}.',
        Icon: CheckIcon,
        date: 'Jan 2, 2024',
        time: '10:00 AM'
      },
      {
        id: 'activity_2',
        title: 'Document signing',
        details: 'Buyer signed the initial documents',
        Icon: CheckIcon,
        date: 'Jan 2, 2024',
        time: '10:00 AM'
      },
      {
        id: 'activity_3',
        title: 'Payments',
        details: 'Initial inquiry made by the buyer {{name}}.',
        Icon: CheckIcon,
        date: 'Jan 2, 2024',
        time: '10:00 AM'
      },
      {
        id: 'activity_4',
        title: 'Approvals',
        details: 'Initial inquiry made by the buyer {{name}}.',
        Icon: CheckIcon,
        date: 'Jan 2, 2024',
        time: '10:00 AM'
      },
      {
        id: 'activity_5',
        title: 'Ownership transfer',
        details: 'Initial inquiry made by the buyer {{name}}.',
        Icon: CheckIcon,
        date: 'Jan 2, 2024',
        time: '10:00 AM'
      },
      {
        id: 'activity_6',
        title: 'Initial inquiry',
        details: 'Initial inquiry made by the buyer {{name}}.',
        Icon: CheckIcon,
        date: 'Jan 2, 2024',
        time: '10:00 AM'
      },
      {
        id: 'activity_7',
        title: 'Document signing',
        details: 'Buyer signed the initial documents',
        Icon: CheckIcon,
        date: 'Jan 2, 2024',
        time: '10:00 AM'
      },
      {
        id: 'activity_8',
        title: 'Payments',
        details: 'Initial inquiry made by the buyer {{name}}.',
        Icon: CheckIcon,
        date: 'Jan 2, 2024',
        time: '10:00 AM'
      },
      {
        id: 'activity_9',
        title: 'Approvals',
        details: 'Initial inquiry made by the buyer {{name}}.',
        Icon: CheckIcon,
        date: 'Jan 2, 2024',
        time: '10:00 AM'
      },
      {
        id: 'activity_10',
        title: 'Ownership transfer',
        details: 'Initial inquiry made by the buyer {{name}}.',
        Icon: CheckIcon,
        date: 'Jan 2, 2024',
        time: '10:00 AM'
      }
    ]
  }, [])

  const getDocumentsToSign = useMemo(() => {
    return [
      {
        id: 'document_1',
        title: 'Harry Bones',
        code: '#001'
      },
      {
        id: 'document_2',
        title: 'Alice Crawly',
        code: '#004'
      },
      {
        id: 'document_3',
        title: 'Julia Cho',
        code: '#010'
      },
      {
        id: 'document_4',
        title: 'Nazeri Kumite',
        code: '#040'
      },
      {
        id: 'document_5',
        title: 'Harry Bones',
        code: '#001'
      },
      {
        id: 'document_6',
        title: 'Alice Crawly',
        code: '#004'
      },
      {
        id: 'document_7',
        title: 'Julia Cho',
        code: '#010'
      },
      {
        id: 'document_8',
        title: 'Nazeri Kumite',
        code: '#040'
      }
    ]
  }, [])

  const ActivityItem = ({
    activity: { id, title, details, Icon, date, time },
    index
  }) => (
    <div
      key={id}
      className='self-stretch py-1 justify-between items-center inline-flex'
    >
      <div className='justify-start items-center flex-1 gap-2 flex'>
        <div className='relative w-9 h-9 px-1.5 py-1.5 bg-zinc-200 rounded-full'>
          <Icon className='w-6 h-6 relative flex-col justify-start items-start flex text-zinc-500' />
          {!(getActivities?.length - 1 === index) && (
            <div className='absolute w-[0px] h-[22.5px] left-4 top-9 border border-zinc-400 border-dashed' />
          )}
        </div>
        <div className='flex-col justify-start items-start gap-0.5 inline-flex'>
          <H5 className='text-zinc-950 text-sm font-semibold leading-tight mb-0'>
            {title}
          </H5>
          <SubText variant='light' label={details} />
        </div>
      </div>
      <div className='text-right text-zinc-500 text-xs  font-normal leading-none flex flex-col gap-1'>
        <SubText variant='light' label={date} />
        <SubText variant='light' label={time} />
      </div>
    </div>
  )

  const OffersStats = () => {
    const typesOfContracts = [
      {
        id: 'pendingSignature',
        name: 'Pending signature',
        color: '#EDDF65',
        number: 105
      },
      {
        id: 'pendingApproval',
        name: 'Pending approval',
        color: '#6D6B6D',
        number: 47
      },
      {
        id: 'rescission',
        name: 'Rescission',
        color: '#C1C0C1',
        number: 23
      },
      {
        id: 'soldFirm',
        name: 'Sold firm',
        color: '#2E2B2E',
        number: 14
      }
    ]

    return (
      <div className='bg-white border border-[#E4E4E7] shadow-sm rounded-2xl p-8 flex flex-col gap-y-8'>
        <div className='flex items-center gap-x-10'>
          <div>
            <h5 className='text-sm sm:text-lg font-semibold mb-0'>Total offers</h5>
            <h2 className='text-3xl sm:text-4xl font-bold mb-0'>211 units</h2>
            <div className='w-fit rounded-md bg-[#D6FABB] flex gap-1 items-center py-1 px-2'>
              <AiFillCaretUp className='text-[#44AC2A]' />
              <p className='text-xs font-semibold mb-0'>1.6%</p>
            </div>
          </div>
          <div className='flex-1 h-[30px] sm:h-[40px] flex gap-1 items-center rounded-lg overflow-hidden'>
            <div className='w-[43%] h-full bg-volt' />
            <div className='w-[27%] h-full bg-zinc-500' />
            <div className='w-[17%] h-full bg-zinc-300' />
            <div className='w-[13%] h-full bg-softBlack' />
          </div>
        </div>
        <div className='flex-1 flex items-center gap-x-4 overflow-hidden'>
          {typesOfContracts.map((type, index) => (
            <div
              key={type.id}
              className={`flex flex-col gap-y-1 px-4 ${
                index !== 0 ? 'border-l border-[#2E2B2E] border-opacity-25' : ''
              } overflow-hidden`}
            >
              <div className='flex gap-2'>
                <svg
                  className='mt-1'
                  width='15'
                  height='15'
                  viewBox='0 0 12 12'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <circle cx='6' cy='6' r='6' fill={type.color} />
                </svg>
                <h6 className='flex-1 truncate text-base mb-0 font-medium'>
                  {type.name}
                </h6>
              </div>
              <p className='text-2xl mb-0 font-bold'>{type.number}</p>
            </div>
          ))}
        </div>
      </div>
    )
  }

  return (
    <div className='relative w-full p-10 sm:p-12 pt-14'>
      <div
        className='absolute z-[-1] w-full h-[30vh] sm:h-[70vh] top-0 left-0 bg-cover bg-no-repeat brightness-[30%]'
        style={{ backgroundImage: `url(${morrisonWalkNorthVancouverBg})` }}
      ></div>
      <img
        className='mix-blend-screen hidden sm:block saturate-50 sepia-[10%] invert w-[220px]'
        src={morrisonWalkLogo}
        alt='morrison-walk-north-vancouver'
      />
      <div className='w-full flex justify-center'>
        <img
          className='mix-blend-screen block sm:hidden saturate-50 sepia-[10%] invert w-[140px]'
          src={morrisonWalkLogo}
          alt='morrison-walk-north-vancouver'
        />
      </div>
      <div
        className={`pt-8 w-full  ${isMobile ? 'hidden' : 'flex'} justify-end`}
      >
        <div className='w-fit flex items-center gap-x-2 p-1 rounded-md bg-white'>
          {tabMenus.map((tab) => (
            <div
              key={tab.id}
              onClick={() => setSelectedTab(tab.id)}
              className={`flex items-center px-14 py-1 cursor-pointer rounded-[4px] font-semibold text-xs ${
                selectedTad === tab.id
                  ? 'bg-softBlack text-white shadow-sm'
                  : 'text-zinc-500'
              } transition-all`}
            >
              {tab.name}
            </div>
          ))}
        </div>
      </div>
      <div
        className={`pt-10 w-full  ${
          isMobile ? 'flex' : 'hidden'
        } justify-center`}
      >
        <div className='w-fit flex items-center gap-x-2 p-1 rounded-md bg-white'>
          {tabMenusMobile.map((tab) => (
            <div
              key={tab.id}
              onClick={() => setSelectedTab(tab.id)}
              className={`flex items-center px-14 py-1 cursor-pointer rounded-[4px] font-semibold text-lg ${
                selectedTad === tab.id
                  ? 'bg-softBlack text-white shadow-sm'
                  : 'text-zinc-500'
              } transition-all`}
            >
              {tab.name}
            </div>
          ))}
        </div>
      </div>
      <div className={`${isMobile ? 'hidden' : 'grid'} flex-col  grid-cols-5 gap-12 pt-10 sm:pt-12`}>
          <div className='col-span-3 flex flex-col gap-6'>
            <OffersStats />
            <div className='flex flex-col bg-white rounded-2xl'>
              <div className='w-full self-stretch h-14 p-3 xl:p-4 border-b border-zinc-200 justify-between items-center inline-flex'>
                <div className='justify-start items-center gap-2 flex'>
                  <div className='w-9 h-9 p-1.5 bg-yellow-200 rounded-full justify-center items-center flex'>
                    <KeyRound className='w-6 h-6 relative flex-col justify-start items-start flex' />
                  </div>
                  <div className='flex-col justify-start items-start gap-0.5 inline-flex'>
                    <H5 className='text-zinc-950 text-sm font-semibold leading-tight mb-0'>
                      Documents to Sign
                    </H5>
                  </div>
                </div>
                <button className='p-2 xl:px-4 xl:py-2 bg-zinc-900 rounded-md shadow justify-center items-center gap-2 flex cursor-pointer'>
                  <div className='text-white text-xs xl:text-sm font-medium leading-tight'>
                    18 Documents
                  </div>
                </button>
              </div>
              <div className='flex flex-col p-6 gap-6'>
                {getDocumentsToSign.map((document) => (
                  <div key={document.id} className='flex justify-between'>
                    <H5 className='text-zinc-950 text-base font-semibold leading-tight mb-0'>
                      {document.title}
                    </H5>
                    <SubText
                      variant='light'
                      className='text-sm font-semibold leading-tight mb-0'
                      label={document.code}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
         <div className='bg-white col-span-2 rounded-2xl p-8'>
            <div className='flex flex-col gap-2'>
              <div className='w-fit rounded-md bg-[#D6FABB] flex gap-2 items-center py-1 px-3.5'>
                <BsCircleFill className='w-3 h-3 text-[#44AC2A]' />
                <p className='text-base font-semibold mb-0'>Live</p>
              </div>
              <h2 className='text-4xl font-semibold mb-0'>Recent Activity</h2>
              <h5 className='text-base font-medium mb-0 text-zinc-400'>
                last updated 30 seconds ago
              </h5>
            </div>
            <div className='mt-3 w-full flex-col justify-start items-start gap-4 inline-flex'>
              {getActivities.map((activity, index) => (
                <ActivityItem
                  key={activity.id}
                  activity={activity}
                  index={index}
                />
              ))}
            </div>
          </div>
      </div>
      <div className={`${isMobile ? 'flex' : 'hidden'} flex-col  grid-cols-5 gap-12 pt-10 sm:pt-12`}>
        {selectedTad === 'tab-1' && (
          <div className='col-span-3 flex flex-col gap-6'>
            <OffersStats />
            <div className='flex flex-col bg-white rounded-2xl'>
              <div className='w-full self-stretch h-14 p-3 xl:p-4 border-b border-zinc-200 justify-between items-center inline-flex'>
                <div className='justify-start items-center gap-2 flex'>
                  <div className='w-9 h-9 p-1.5 bg-yellow-200 rounded-full justify-center items-center flex'>
                    <KeyRound className='w-6 h-6 relative flex-col justify-start items-start flex' />
                  </div>
                  <div className='flex-col justify-start items-start gap-0.5 inline-flex'>
                    <H5 className='text-zinc-950 text-sm font-semibold leading-tight mb-0'>
                      Documents to Sign
                    </H5>
                  </div>
                </div>
                <button className='p-2 xl:px-4 xl:py-2 bg-zinc-900 rounded-md shadow justify-center items-center gap-2 flex cursor-pointer'>
                  <div className='text-white text-xs xl:text-sm font-medium leading-tight'>
                    18 Documents
                  </div>
                </button>
              </div>
              <div className='flex flex-col p-6 gap-6'>
                {getDocumentsToSign.map((document) => (
                  <div key={document.id} className='flex justify-between'>
                    <H5 className='text-zinc-950 text-base font-semibold leading-tight mb-0'>
                      {document.title}
                    </H5>
                    <SubText
                      variant='light'
                      className='text-sm font-semibold leading-tight mb-0'
                      label={document.code}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        {selectedTad === 'tab-2' && (
          <div className='bg-white col-span-2 rounded-2xl p-8'>
            <div className='flex flex-col gap-2'>
              <div className='w-fit rounded-md bg-[#D6FABB] flex gap-2 items-center py-1 px-3.5'>
                <BsCircleFill className='w-3 h-3 text-[#44AC2A]' />
                <p className='text-base font-semibold mb-0'>Live</p>
              </div>
              <h2 className='text-4xl font-semibold mb-0'>Recent Activity</h2>
              <h5 className='text-base font-medium mb-0 text-zinc-400'>
                last updated 30 seconds ago
              </h5>
            </div>
            <div className='mt-3 w-full flex-col justify-start items-start gap-4 inline-flex'>
              {getActivities.map((activity, index) => (
                <ActivityItem
                  key={activity.id}
                  activity={activity}
                  index={index}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default AdminNewDashboard
