/* eslint-disable */
// @ts-nocheck
import React, { useEffect, useState, createContext } from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'

import pwaAvailabilityOverlayColor from '../../assets/img/pwaAvailabilityOverlayColor.png'
import pwaAvailabilityImage from '../../assets/img/pwaAvailabilityImage.png'

import { Card, Floor, UnitsList as UnitsListLayout } from './components'
import { SelectMultiple, ViewSwitcher } from 'components'
import LargeUnitCardModal from 'components/LargeUnitCardModal'
import useGetProjectUnits from 'hooks/useGetProjectUnits'
import { ADMIN_RESERVE_UNIT } from 'routes'
import { useHistory } from 'react-router-dom'

const LoadingContext = createContext()

const ProjectAvailability = ({ appProject, userObject }) => {
  const history = useHistory()
  const { units, loading, refetchUnits } = useGetProjectUnits()

  const [viewType, setViewType] = useState('cardView')
  const [selectedFloorOption, setSelectedFloorOption] = useState('')
  const [floorsOptions, setFloorsOptions] = useState([])
  const [currentFloor, setCurrentFloor] = useState([])
  const [floors, setFloors] = useState([])

  const [openModal, setOpenModal] = useState(false)
  const [tempCardToOpen, setTempCardToOpen] = useState(null)

  useEffect(() => {
    if (units.length > 0) {
      const options = [
        {
          value: 'Floor 1-10',
          label: 'Floor 1-10',
          floors: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
        },
        {
          value: 'Floor 11-20',
          label: 'Floor 11-20',
          floors: [11, 12, 13, 14, 15, 16, 17, 18, 19, 20]
        },
        {
          value: 'Floor 21-26',
          label: 'Floor 21-26',
          floors: [21, 22, 23, 24, 25, 26]
        }
      ]
      setFloorsOptions(options)
      setTempCardToOpen(units[0])
    }
  }, [units])

  useEffect(() => {
    const _option = structuredClone(
      floorsOptions.filter((x) => x.value === selectedFloorOption)?.[0]
    )

    if (_option?.floors?.length > 0) {
      const newFloors = _option?.floors.reverse()
      setFloors(newFloors)
      setCurrentFloor(newFloors[0])
    }
  }, [floorsOptions, selectedFloorOption])

  if (loading) {
    return null
  }

  return (
    <LoadingContext.Provider value={null}>
      <div className='h-full w-full flex flex-col mt-6 items-center'>
        <div className='grid grid-cols-1 md:grid-cols-4 gap-4 w-full'>
          <Card
            ImgSrc='https://pxpdemo-images.s3.us-east-2.amazonaws.com/1624653558009-destaque-live.jpeg'
            title='North Tower'
            selected
          />
          <Card
            ImgSrc='https://pxpdemo-images.s3.us-east-2.amazonaws.com/1624653558009-destaque-live.jpeg'
            title='South Tower'
          />
          <Card
            ImgSrc='https://pxpdemo-images.s3.us-east-2.amazonaws.com/1624653558009-destaque-live.jpeg'
            title='West Tower'
          />
          <Card
            ImgSrc='https://pxpdemo-images.s3.us-east-2.amazonaws.com/1624653558009-destaque-live.jpeg'
            title='South-West Tower'
          />
        </div>
        <div className='flex w-full mt-12 justify-between items-center'>
          <div className='w-64'>
            {viewType == 'cardView' && floorsOptions?.length > 0 ? (
              <SelectMultiple
                buttonLabel={floorsOptions?.[0]?.name}
                onChange={(newValue) => {
                  if (newValue.length > 0 && newValue[0].length > 0) {
                    setSelectedFloorOption(newValue[0])
                  }
                }}
                options={floorsOptions?.map((floor) => ({
                  value: floor?.value,
                  label: floor?.value
                }))}
                multiple={false}
                buttonClass='font-bold text-softBlack text-4xl'
                iconClass='text-softBlack text-4xl w-8 h-8'
                itemClass='flex-1 space-x-2.5 text-softBlack font-bold text-lg'
                autoSelectFirst
              />
            ) : null}
          </div>
          <ViewSwitcher
            view={viewType}
            onChange={(_viewType) => {
              setViewType(_viewType)
            }}
          />
        </div>
        {viewType == 'cardView' ? (
          <div className='flex flex-row w-full mt-12 h-[836px] space-x-16'>
            <div className='flex flex-col w-1/4 h-full'>
              {floors?.length > 0
                ? floors.map((value, index) => {
                    return (
                      <Floor
                        key={value}
                        units={[0, 1, 2, 3]}
                        selected={value === currentFloor}
                        floorNumber={value}
                        showHeader={index === 0}
                        onClicked={() => {
                          setCurrentFloor(value)
                        }}
                      />
                    )
                  })
                : null}
            </div>
            <div className='flex w-3/4 bg-white'>
              <div
                className='relative flex mx-7 my-12 w-full'
                onClick={() => {
                  setOpenModal(true)
                }}
              >
                <img
                  src={pwaAvailabilityImage}
                  className='absolute top-0 left-0 w-full h-full'
                />
                <img
                  src={pwaAvailabilityOverlayColor}
                  className='absolute top-0 left-0 w-full h-full'
                />
              </div>
            </div>
          </div>
        ) : null}
        {viewType == 'listView' ? (
          <UnitsListLayout
            units={units}
            loading={loading}
            refetchUnits={refetchUnits}
          />
        ) : null}
        <LargeUnitCardModal
          {...{
            openModal,
            setOpenModal,
            tempCardToOpen,
            onReserveClicked: () => {
              history.push({
                pathname: ADMIN_RESERVE_UNIT,
                state: {
                  unit: tempCardToOpen.unitNumber
                }
              })
            },
            onPurchaseClicked: () => {
              console.log('On Unit Purchase Clicked', {
                unit: tempCardToOpen.unitNumber
              })
            }
          }}
        />
      </div>
    </LoadingContext.Provider>
  )
}

const mapStateToProps = (state) => ({
  appProject: state.appReducer.appProject,
  userObject: state.authReducer.userObject
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectAvailability)

ProjectAvailability.propTypes = {
  appProject: propTypes.string,
  userObject: propTypes.shape({
    userType: propTypes.string
  })
}
