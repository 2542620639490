export const countriesOptions = [
  { label: 'Canada', value: 'canada' },
  { label: 'USA', value: 'usa' }
]

export const timeOptions = [
  { label: 'Today', value: 'day' },
  { label: 'This Month', value: 'month' },
  { label: 'Year', value: 'year' }
]
export const brokerOptions = [
  { label: '1 broker', value: '1 broker' },
  { label: '2 brokers', value: '2 brokers' },
  { label: '3 brokers', value: '3 brokers' }
]

export const data = [
  {
    timestamp: 1578682800000,
    value: 10
  },
  {
    timestamp: 1581361200000,
    value: 20
  },
  {
    timestamp: 1583866800000,
    value: 80
  },
  {
    timestamp: 1586545200000,
    value: 90
  },
  {
    timestamp: 1589137200000,
    value: 90
  },
  {
    timestamp: 1591815600000,
    value: 70
  },
  {
    timestamp: 1594407600000,
    value: 70
  },
  {
    timestamp: 1597086000000,
    value: 95
  },
  {
    timestamp: 1599764400000,
    value: 40
  },
  {
    timestamp: 1602356400000,
    value: 70
  },
  {
    timestamp: 1605034800000,
    value: 60
  },
  {
    timestamp: 1607626800000,
    value: 10
  },
  {
    timestamp: 1609441200000,
    value: 70
  },
  {
    timestamp: 1610218800000,
    value: 60
  },
  {
    timestamp: 1611082800000,
    value: 10
  },
  {
    timestamp: 1611946800000,
    value: 70
  },
  {
    timestamp: 1612897200000,
    value: 60
  },
  {
    timestamp: 1612897200000,
    value: 10
  },
  {
    timestamp: 1614366000000,
    value: 70
  },
  {
    timestamp: 1614538800000,
    value: 60
  },
  {
    timestamp: 1615316400000,
    value: 30
  },
  {
    timestamp: 1615748400000,
    value: 70
  },
  {
    timestamp: 1616180400000,
    value: 90
  },
  {
    timestamp: 1616612400000,
    value: 30
  }
]
