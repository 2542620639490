import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { getSalesrepDashboardData } from 'store/actions/usersActions'

export const useSalesrepDashboard = () => {
  const initialValues = {
    opportunitiesWon: 0,
    contactsToday: 0,
    totalContacts: 0,
    opportunitiesLost: 0,
    totalOpportunities: 0,
    totalReservations: 0,
    newContactsToday: 0
  }
  // @ts-ignore
  const userObject = useSelector((state) => state.authReducer.userObject)
  const [salesrepData, setSalesrepData] = useState({ ...initialValues })

  const fetchSalesrepData = useRef(async () => {})
  fetchSalesrepData.current = async () => {
    const data = await getSalesrepDashboardData(userObject?._id)
    setSalesrepData(data)
  }

  useEffect(() => {
    fetchSalesrepData.current()
  }, [])

  return { ...salesrepData }
}
