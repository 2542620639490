import React from 'react'

const UserType = ({ userType = '' }) => (
    <div className='flex space-x-2 mt-2'>
        <svg
        width='14'
        height='14'
        viewBox='0 0 14 14'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        >
        <path
            d='M6.99967 6.99992C8.84134 6.99992 10.333 5.50825 10.333 3.66659C10.333 1.82492 8.84134 0.333252 6.99967 0.333252C5.15801 0.333252 3.66634 1.82492 3.66634 3.66659C3.66634 5.50825 5.15801 6.99992 6.99967 6.99992ZM6.99967 8.66658C4.77467 8.66658 0.333008 9.78325 0.333008 11.9999V12.8333C0.333008 13.2916 0.708008 13.6666 1.16634 13.6666H12.833C13.2913 13.6666 13.6663 13.2916 13.6663 12.8333V11.9999C13.6663 9.78325 9.22467 8.66658 6.99967 8.66658Z'
            fill='#2E2B2E'
        />
        </svg>
        <span>Type: {userType}</span>
    </div>
)

export default UserType
