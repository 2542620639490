/* eslint-disable no-undef */
// @ts-nocheck
import React from 'react'

export const useD3 = (renderChartFn, dependencies) => {
  const ref = React.useRef()

  React.useEffect(() => {
    renderChartFn(d3.select(ref.current))
    return () => {}
  }, dependencies)
  return ref
}
