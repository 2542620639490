import React, { useState } from 'react'
import { TableContainer, Table, Paper } from '@material-ui/core'

import { ReactComponent as DownIcon } from 'assets/icons/down.svg'
import { ReactComponent as LeftArrow } from 'assets/icons/left-arrow.svg'

import EnhancedTableBody from './EnhancedTableBody'
import EnhancedTableHeader from './EnhancedTableHeader'

const DashboardTable = ({
  title,
  data,
  showMore = false,
  showIndex = false,
  learnMore = false,
  columnNames,
  RowToRender,
  defaultRowsCount = 4,
  eraseHeading = false,
  showEditDeleteIcons = false,
  onEditClick = (p) => {},
  onDeleteClick = (p) => {},
  onRowClick = (id) => {}
}) => {
  const [isAllRows, setIsAllRows] = useState(false)

  const toggleRows = () => {
    setIsAllRows(prev => !prev)
  }

  return (
    <div className="bg-grey-8 p-6 rounded font-openSans">
      <h1 className="font-bold text-xl pb-4 mb-6 border-b-2 border-softBlack">
        {title}
      </h1>
      <TableContainer
        component={Paper}
        style={{ backgroundColor: 'inherit', boxShadow: 'none' }}
      >
        <Table aria-label="simple table">
          {!eraseHeading && <EnhancedTableHeader
            columnNames={columnNames}
            showIndex={showIndex}
          />}
          <EnhancedTableBody
            data={data}
            toShowRows={(showMore && !isAllRows) ? defaultRowsCount : data.length }
            RowToRender={RowToRender}
            showIndex={showIndex}
            onEditClick={onEditClick}
            onDeleteClick={onDeleteClick}
            showEditDeleteIcons={showEditDeleteIcons}
            onRowClick={onRowClick}
          />
        </Table>
      </TableContainer>
      {learnMore && (
        <button
          className="mt-7 flex justify-center items-center gap-1.5 w-fit mx-auto"
        >
          <p className="p-0 m-0 font-medium text-base font-openSans">
          Learn more
          </p>
          <LeftArrow />
        </button>
      )}
      {showMore && (
        <button
          onClick={toggleRows}
          className="mt-7 flex justify-center items-center w-fit mx-auto"
          style={{ gap: 6 }}
        >
          <p className="p-0 m-0 font-medium text-base font-openSans">{isAllRows ? 'Show Less' : 'Show more'}</p>
          <div className={`transition duration-200 ${isAllRows && 'rotate-180'}`}>
            <DownIcon />
          </div>
        </button>
      )}
    </div>
  )
}

export default DashboardTable
