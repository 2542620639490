import React from 'react'
import propTypes from 'prop-types'
import { CameraIcon } from 'components'
import { CloseIcon } from 'components/Icons'
import Webcam from 'react-webcam'
import { getRandomInteger } from 'utils'
import {
  CameraWrapper,
  CameraButtonWrapper,
  CloseButtonWrapper
} from './styles'

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: 'environment'
}

const cameraButtonsAnimationValues = {
  cameraExit: {
    y: -400
  },
  cameraEnter: {
    y: 0
  }
}

const Camera = React.forwardRef((props, ref) => {
  const {
    height, width, onTakePhoto, onClickCloseButton,
    key, wrapperAnimationVariants = { ...cameraButtonsAnimationValues }
  } = props

  const takePhoto = () => {
    const photo = ref.current.getScreenshot()
    fetch(photo)
      .then(res => res.blob())
      .then(blob => {
        onTakePhoto(new File([blob], getRandomInteger(), { type: 'image/png' }))
      })
  }

  return (
    <CameraWrapper
      key={key}
      initial='cameraExit'
      animate='cameraEnter'
      exit='cameraExit'
      variants={wrapperAnimationVariants}
      transition={{ duration: 0.2 }}
    >
      <Webcam
        audio={false}
        ref={ref}
        screenshotFormat='image/jpeg'
        videoConstraints={videoConstraints}
        style={{ height: height, width: width }}
      />
      <CameraButtonWrapper onClick={takePhoto}>
        <CameraIcon style={{ fontSize: 40, color: 'white' }} />
      </CameraButtonWrapper>
      <CloseButtonWrapper onClick={onClickCloseButton}>
        <CloseIcon style={{ fontSize: 20, color: 'white' }} />
      </CloseButtonWrapper>
    </CameraWrapper>
  )
})

export default Camera

Camera.propTypes = {
  height: propTypes.number,
  width: propTypes.number,
  onTakePhoto: propTypes.func,
  onClickCloseButton: propTypes.func,
  key: propTypes.number,
  wrapperAnimationVariants: propTypes.object
}
