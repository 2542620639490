import React, { createContext } from 'react'
import useGetBuildings from 'hooks/useGetBuildigs'
import { CardView, ListView } from './components'
import useGetStorages from 'hooks/useGetStorages'
import StorageInventoryMapWrapper from 'pages/desktopMode/storageInventoryMap'

const LoadingContext = createContext(null)

const ProjectStoragesInventory = ({ viewType }) => {
  const { loadingStorages, storages, refetchStorages } = useGetStorages()
  const { buildings, loading: getBuildings } = useGetBuildings()

  return (
    <LoadingContext.Provider value={null}>
      {viewType === 'cardView' && (
        <CardView
          {...{ storages, loading: loadingStorages, buildings, getBuildings, refetchStorages }}
        />
      )}
      {viewType === 'listView' && (
        <ListView
          {...{ storages, loading: loadingStorages, buildings, getBuildings, refetchStorages }}
        />
      )}
      {viewType === 'mapView' && (
        <StorageInventoryMapWrapper />
      )}
    </LoadingContext.Provider>
  )
}

export default ProjectStoragesInventory
