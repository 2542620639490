import React, { useEffect } from 'react'

const AvailabilityBoard = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div />
  )
}

export default AvailabilityBoard
