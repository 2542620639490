import styled from 'styled-components'

import standardStyle from 'assets/css/standardStyle'

const Text = styled.p`
  color: ${props => props.color === 'secundary' ? standardStyle.colors.secundary : standardStyle.colors.primary};
  font-size: 16px;
  font-weight: 800;
  font-family: ${standardStyle.fonts.text};
  font-style: ${props => props.fontStyle || 'normal'};
  margin: 0px;
`

export default Text
