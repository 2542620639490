/* eslint-disable */
// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import { Oval } from 'react-loader-spinner';
import { Card, Modal } from '@material-ui/core';
import CustomInput from 'components/CustomInput';
import { api } from 'services/api';
import { PlusSmIcon } from '@heroicons/react/outline';
import { ArrowLeft, EditIcon } from 'lucide-react';
import CustomButton from 'components/CustomButton';

const AddStorageInventoryModal = ({ show, setShow, building }) => {
  // @ts-ignore
  const [openModal, setOpenModal] = useState(false);
  const [optionName, setOptionName] = useState('');
  const [optionNameAllocate, setOptionNameAllocate] = useState('');
  const [editIndex, setEditIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editIndexAllocate, setEditIndexAllocate] = useState(null);
  const [editedName, setEditedName] = useState('');
  const [editedNameAllocate, setEditedNameAllocate] = useState('');
  const [totalAssignedStorages, setTotalAssignedStorages] = useState(null);
  const [totalAssignedStorageAllocate, setTotalAssignedStorgeAllocate] =
    useState(0);
  const [totalStorages, setTotalStorages] = useState(null);
  const [storageTypeData, setStorageTypeData] = useState([{}]);
  const [storageError, setStorageError] = useState({});
  const [storageErrorAllocate, setStorageErrorAllocate] = useState({});
  const [storageInventoryState, setStorageInventoryState] = useState({
    step: 1,
  });
  const initialEmptyValues = {
    building,
    options: [],
    storageLevels: ['P1', 'P2'],
    totalStorages: null,
  };

  const editTotalParking = async () => {
    setValues({ ...value, totalStorages: null });
  };
  const [value, setValues] = useState({
    ...initialEmptyValues,
  });

  const storageColor = ['#59b099', 'red', 'yellow', 'black'];

  const storageTypeArray = () => {
    const storageStatus = [
      { name: 'Available', value: value.totalStorages || 0 },
      { name: 'Sold', value: 0 },
      { name: 'Allocated', value: 0 },
      { name: 'Assigend', value: 0 },
    ];
    const parkingData = storageStatus.map((storage, index) => {
      const color = storageColor[index % storageColor.length];
      return { name: storage.name, value: storage.value, color: color };
    });
    setStorageTypeData(parkingData);
  };

  const [storageAllocationValues, setStorageAllocationValues] = useState({});

  const handleEditOption = (index) => {
    setEditIndex(index);
    setEditedName(value.options[index].name);
  };

  const handleEditOptionAllocate = (index) => {
    setEditIndexAllocate(index);
    setEditedNameAllocate(storageAllocationValues.options[index].name);
  };

  const storageAllocationStatus = [
    {
      name: 'Allocated',
      value: totalAssignedStorageAllocate,
    },
    {
      name: 'Unallocated',
      value:
        storageAllocationValues.allocatedStorages -
        totalAssignedStorageAllocate,
    },
  ];

  const handleSaveOption = () => {
    const updatedOptions = value.options.map((op, i) =>
      i === editIndex ? { ...op, name: editedName } : op
    );
    setValues({ ...value, options: updatedOptions });
    setEditIndex(null);
    setEditedName('');
  };

  const handleSaveOptionAllocate = () => {
    const updatedOptions = storageAllocationValues.options.map((op, i) =>
      i === editIndexAllocate ? { ...op, name: editedNameAllocate } : op
    );
    setStorageAllocationValues({ ...value, options: updatedOptions });
    setEditIndexAllocate(null);
    setEditedNameAllocate('');
  };
  //   const TEMP_AVAILABILITY_STATUS = {
  //     available: 'Available',
  //     assigned: 'Assigned',
  //     reserved: 'Reserved',
  //     allocated: 'Allocated',
  //     recision: 'Recision',
  //     sold: 'Sold'
  //   }

  //   const STATUS_OPTIONS = [
  //     ...Object.values(TEMP_AVAILABILITY_STATUS).map((value) => {
  //       return { value: value, id: value }
  //     })
  //   ]

  //   const isInsideBuildingOptions = [
  //     { id: 'true', value: 'Yes' },
  //     { id: 'false', value: 'No' }
  //   ]

  const AddOption = async () => {
    setValues((prevState) => ({
      ...prevState,
      options: [
        ...prevState.options,
        { name: optionName, value: [], isCustom: true },
      ],
    }));
    setOptionName('');
    setOpenModal(false);
  };
  const AddOptionAllocate = async () => {
    setStorageAllocationValues((prevState) => ({
      ...prevState,
      options: [
        ...prevState.options,
        {
          name: optionNameAllocate,
          storageTypes: value.storageLevels.map((level, index) => ({
            storageLevel: level,
            options: value.options.map((option) => ({
              name: option.name,
              value: 0,
            })),
          })),
          isCustom: true,
        },
      ],
    }));
    setOptionNameAllocate('');
    setOpenModal(false);
  };

  const handleTotalParkingsAllocate = () => {
    const totalStorages = storageAllocationValues.options.reduce((sum, obj) => {
      const objSum = obj.storageTypes.reduce((objSum, storageType) => {
        const typeSum = storageType.options.reduce(
          (typeSum, option) => typeSum + Number(option.value),
          0
        );
        return objSum + typeSum;
      }, 0);
      return sum + objSum;
    }, 0);
    setTotalAssignedStorgeAllocate(totalStorages || 0);
  };

  const handleSubmit = async () => {
    try {
      if (Number(value.totalStorages) === totalAssignedStorages) {
        setLoading(true);
        setValues({ ...value });
        setStorageError({});
        const temp = {
          // @ts-ignore
          ...value,
          buildingId: building,
        };
        const { data } = await api.get(
          `/storage/${building}/allocateInventory`
        );
        if (
          data.data &&
          Array.isArray(data.data) &&
          data.data.length > 0 &&
          data.data[0].options
        ) {
          const storageLevels = value.storageLevels;
          const fetchedOptions = data.data[0].options;
          const mergedOption = fetchedOptions.map((option, i) => {
            return {
              name: option.name,
              isCustom: option.isCustom || false,
              storageTypes: storageLevels.map((level, index) => ({
                storageLevel: level,
                options: value.options.map((op, OIndex) =>
                ({
                  name: op.name,
                  value:
                    option.storageTypes[index]?.options[OIndex]?.value || 0,
                })
                ),
              })),
            };
          });
          setStorageAllocationValues({
            storageLevels: storageLevels,
            building: building,
            options: mergedOption,
            allocatedStorages: totalAssignedStorages,
          });
          const allocatedObject = {
            building: building,
            storageLevels: storageLevels,
            options: mergedOption,
            allocatedStorages: totalAssignedStorages,
          };
          await api.post(`/storage/allocateInventory`, {
            data: allocatedObject,
          });
        } else {
          console.log('not found');
          const storageLevels = value.storageLevels;
          const newOptions = [
            {
              name: 'UNITS',
              storageTypes: value.storageLevels.map((level, index) => ({
                storageLevel: level,
                options: value.options.map((option) => ({
                  name: option.name,
                })),
              })),
            },
            {
              name: 'For Sale (extra storage)',
              storageTypes: value.storageLevels.map((level, index) => ({
                storageLevel: level,
                options: value.options.map((option) => ({
                  name: option.name,
                })),
              })),
            },
          ];

          const allocatedObject = {
            building: building,
            storageLevels: storageLevels,
            options: newOptions,
            allocatedStorages: totalAssignedStorages,
          };
          setStorageAllocationValues({
            storageLevels: storageLevels,
            building: building,
            options: newOptions,
            allocatedStorages: totalAssignedStorages,
          });

          await api.post(`/storage/allocateInventory`, {
            data: allocatedObject,
          });
        }
        await api.post(`/storage/inventory`, {
          data: temp,
        });

        setStorageInventoryState({ step: 2 });
        setLoading(false);
      } else {
        setLoading(false);
        setStorageError({
          error: 'Total storages are not accounted',
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitAllocate = async () => {
    try {
      if (
        storageAllocationValues.allocatedStorages ===
        totalAssignedStorageAllocate
      ) {
        setStorageErrorAllocate({});
        setLoading(true);
        setStorageError({});
        const temp = {
          // @ts-ignore
          ...storageAllocationValues,
          building: building,
        };
        await api.post(`/storage/allocateInventory`, {
          data: temp,
        });
        setLoading(false);
      } else {
        setStorageErrorAllocate({ error: 'Total parkings are not allocated' });
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleClose = () => {
    setShow(false);
  };

  const handleRemoveOption = (index) => {
    setValues((prevState) => ({
      ...prevState,
      options: prevState.options.filter((_, i) => i !== index),
    }));
  };

  const handleRemoveAllocation = (index) => {
    setStorageAllocationValues((prevState) => ({
      ...prevState,
      options: prevState.options.filter((_, i) => i !== index),
    }));
  };
  const handleTotalStorages = () => {
    const totalStorages = value.options.reduce((sum, obj) => {
      const objSum = obj.value.reduce((objSum, val) => objSum + Number(val), 0);
      return sum + objSum;
    }, 0);
    setTotalAssignedStorages(totalStorages);
  };

  const handleRemoveStorageLevel = (indexToRemove) => {
    setValues((prevState) => {
      const updatedParkingLevels = prevState.storageLevels.filter(
        (_, index) => index !== indexToRemove
      );
      const updatedOptions = prevState.options.map((option) => ({
        ...option,
        value: option.value.filter((_, index) => index !== indexToRemove),
      }));
      return {
        ...prevState,
        options: updatedOptions,
        storageLevels: updatedParkingLevels,
      };
    });
  };

  useEffect(() => {
    const handleBuildingInventory = async () => {
      try {
        const { data } = await api.get(`/storage/${building}/inventory`);
        if (data.data && data.data.length > 0) {
          setValues(data.data[0]);
        } else {
          console.log('not found');
          setValues({ ...initialEmptyValues });
        }
      } catch (error) {
        console.error('Error fetching building inventory:', error);
      }
    };
    handleBuildingInventory();
    storageTypeArray();
  }, []);

  useEffect(() => {
    handleTotalStorages();
  }, [value.options]);

  useEffect(() => {
    storageTypeArray();
  }, [value.totalStorages]);

  useEffect(() => {
    if (storageInventoryState.step === 2) {
      handleTotalParkingsAllocate();
    }
  }, [storageAllocationValues, storageInventoryState.step]);
  return (
    <>
      {storageInventoryState.step === 1 && (
        <Modal
          open={show}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
          className='h-full'
        >
          <div
            className='font-openSans flex flex-col overflow-scroll relative bg-grey-8 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-10 rounded shadow-lg'
            style={{ width: '100vw', height: '100%' }}
          >
            <button className='absolute right-10' onClick={handleClose}>
              <CrossIcon />
            </button>
            <h1 className='font-bold text-xl text-center p-0 m-0 mb-6'>
              Add Storage Inventory
            </h1>
            <Card className='!shadow-none !overflow-visible mb-24 py-8 px-12 w-fit border-[2px] border-[rgba(0,0,0,0.2)] rounded-[12px]'>
              <div className='font-bold text-[20px]'>Storage Inventory</div>
              <div className='flex gap-x-16 mt-6'>
                <div className='font-bold text-[24px]'>
                  {value.totalStorages || 0} Storages
                </div>
                <div className='h-full'>
                  <div className='flex flex-wrap h-full gap-x-4'>
                    {storageTypeData.map((storage, index) => (
                      <>
                        <div className='flex flex-col'>
                          <div className='flex items-center gap-x-2'>
                            <div
                              style={{
                                backgroundColor: storage.color,
                              }}
                              className={`rounded-full h-3 w-3`}
                            ></div>
                            <div className='text-sm col-span-2'>
                              {storage.name}
                            </div>
                          </div>
                          <div className='text-xl col-span-2 font-bold'>
                            {storage.value}
                          </div>
                        </div>
                        {storageTypeData.length - 1 != index && (
                          <div className='w-[2px] bg-[rgba(0,0,0,0.4)]'></div>
                        )}
                      </>
                    ))}
                  </div>
                </div>
              </div>
              <div className='flex gap-x-20 items-center mt-6'>
                <div>
                  <div className='font-bold text-[16px] '>Storage Types</div>
                  <div className='flex gap-x-4 mt-6'>
                    {value.options.map((storage, index) => (
                      <>
                        <div className='flex flex-col'>
                          <div className='flex items-center gap-x-2'>
                            <div
                              style={{
                                backgroundColor:
                                  storageColor[index % storageColor.length],
                              }}
                              className={`rounded-full h-3 w-3`}
                            ></div>
                            <div className='text-sm col-span-2'>
                              {storage.name}
                            </div>
                          </div>
                          <div className='text-xl col-span-2 font-bold'>
                            {storage.value.reduce(
                              (sum, currentValue) =>
                                Number(sum) + Number(currentValue),
                              0
                            )}
                          </div>
                        </div>
                        {value.options.length - 1 != index && (
                          <div className='w-[2px] bg-[rgba(0,0,0,0.4)]'></div>
                        )}
                      </>
                    ))}
                  </div>
                </div>
                <div>
                  <div className='font-bold text-[16px]'>Storage Types</div>
                  <div className='flex gap-x-4 mt-6'>
                    {value.storageLevels.map((storage, index) => (
                      <>
                        <div className='flex flex-col'>
                          <div className='flex items-center gap-x-2'>
                            <div
                              style={{
                                backgroundColor:
                                  storageColor[index % storageColor.length],
                              }}
                              className={`rounded-full h-3 w-3`}
                            ></div>
                            <div className='text-sm col-span-2'>{storage}</div>
                          </div>
                          <div className='text-xl col-span-2 font-bold'>
                            {value.options.reduce((sum, op) => {
                              const opValue = op.value[index];
                              return (
                                sum + (isNaN(opValue) ? 0 : Number(opValue))
                              );
                            }, 0)}
                          </div>
                        </div>
                        {value.storageLevels.length - 1 != index && (
                          <div className='w-[2px] bg-[rgba(0,0,0,0.4)]'></div>
                        )}
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </Card>
            <div className=''>
              {value.totalStorages === null ? (
                <div className='items-center flex gap-x-6'>
                  <div className='text-lg font-semibold'>
                    Add Total Storage
                  </div>
                  <div className=''>
                    <CustomInput
                      label=''
                      placeholder='Total Storage slots'
                      type='text'
                      classes='font-openSans text-base col-span-2 !m-0'
                      value={totalStorages}
                      onChange={(e) => setTotalStorages(e.target.value)}
                    />
                  </div>
                  <CustomButton handleClick={() => setValues({ ...value, totalStorages })} className='w-fit'>
                    <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
                      Save
                    </span>
                  </CustomButton>
                </div>
              ) : (
                <div className='flex gap-x-48 items-center'>
                  <div className='text-xl flex gap-x-4 items-center  pl-6 font-bold'>
                    Total Storages - {value.totalStorages}
                    <div className='cursor-pointer'>
                      <EditIcon height={'20px'} onClick={editTotalParking} />
                    </div>
                  </div>
                  {value.totalStorages == totalAssignedStorages && (
                    <div className='font-bold text-lg'>
                      All Storages Accounted For
                    </div>
                  )}
                  {totalAssignedStorages > value.totalStorages && (
                    <div className='text-[#8a2c2c] font-bold text-xl'>
                      You can not assign more storages than total storages
                    </div>
                  )}
                  {totalAssignedStorages < value.totalStorages && (
                    <div className='text-[#8a2c2c] font-bold text-xl'>
                      {value.totalStorages - totalAssignedStorages} storage
                      missing
                    </div>
                  )}
                </div>
              )}
              <div className='grid grid-cols-12 col-span-12 px-6 pt-8 gap-x-10 gap-y-6'>
                <ul
                  className={`grid col-span-12 items-center text-[#323232] font-bold grid-cols-12 ${value.storageLevels.length >= 5 ? 'gap-x-4' : 'gap-x-10'
                    }`}
                >
                  <li className='col-span-2 text-[20px] text-black'>Options</li>
                  {value.storageLevels.map((level, index) => {
                    return (
                      <li
                        key={index}
                        className={`${value.storageLevels.length > 2 &&
                          value.storageLevels.length < 5 &&
                          'col-span-2'
                          } ${value.storageLevels.length >= 5 && 'col-span-1'}
                      ${value.storageLevels.length <= 2 && 'col-span-3'}
                      text-[20px] flex items-center gap-x-4 justify-center  text-center text-black`}
                      >
                        {level}
                        {value.storageLevels.length > 1 && (
                          <div
                            className='cursor-pointer'
                            onClick={() => {
                              handleRemoveStorageLevel(index);
                            }}
                          >
                            <svg
                              width='14'
                              height='22'
                              viewBox='0 0 14 18'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M1.50016 15.4167C1.50016 16.425 2.32516 17.25 3.3335 17.25H10.6668C11.6752 17.25 12.5002 16.425 12.5002 15.4167V4.41667H1.50016V15.4167ZM13.4168 1.66667H10.2085L9.29183 0.75H4.7085L3.79183 1.66667H0.583496V3.5H13.4168V1.66667Z'
                                fill='#2E2B2E'
                              />
                            </svg>
                          </div>
                        )}
                      </li>
                    );
                  })}
                  <li className='col-span-1 text-center'>
                    <button
                      onClick={() => {
                        setValues({
                          ...value,
                          storageLevels: [
                            ...value.storageLevels,
                            'P' + (value.storageLevels.length + 1),
                          ],
                        });
                      }}
                      className='flex cursor-pointer gap-x-2'
                    >
                      <PlusSmIcon height={34} className='' />
                    </button>
                  </li>
                </ul>
                {value.options.map((option, index) => (
                  <ul
                    key={index}
                    className={`grid col-span-12 overflow-visible !mb-0 items-center text-[#323232] font-bold grid-cols-12 ${value.storageLevels.length >= 5 ? 'gap-x-4' : 'gap-x-12'
                      }`}
                  >
                    <li
                      key={index}
                      className='col-span-2 flex gap-x-8 items-center text-[20px] text-black '
                    >
                      <div className='cursor-pointer'>
                        {editIndex === index ? (
                          <div className='flex gap-x-2 items-center'>
                            <CustomInput
                              label=''
                              placeholder=''
                              type='text'
                              value={editedName}
                              onChange={(e) => setEditedName(e.target.value)}
                              classes='!m-0'
                            />
                            <CustomButton handleClick={handleSaveOption} className='w-fit'>
                              <span className='font-medium py-2.5 px-3.5 text-base flex gap-2 items-center'>
                                Save
                              </span>
                            </CustomButton>
                          </div>
                        ) : (
                          <div className='flex gap-x-6 items-center'>
                            {option.name}
                            {option.isCustom && (
                              <EditIcon
                                height={'20px'}
                                onClick={() => handleEditOption(index)}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </li>
                    {value.storageLevels.map((level, i) => {
                      return (
                        <li
                          key={i}
                          className={`${value.storageLevels.length > 2 &&
                            value.storageLevels.length < 5 &&
                            'col-span-2'
                            } ${value.storageLevels.length >= 5 && 'col-span-1'}
                        ${value.storageLevels.length <= 2 && 'col-span-3'}
                        `}
                        >
                          <CustomInput
                            type='number'
                            label=''
                            placeholder=''
                            classes='font-openSans text-base col-span-2'
                            style={{ maxHeight: '48px' }}
                            value={option.value[i] || ''}
                            onChange={(e) => {
                              const updatedOptions = value.options.map(
                                (op, j) => {
                                  if (j === index) {
                                    const newValue = [...op.value];
                                    newValue[i] = e.target.value;
                                    return {
                                      ...op,
                                      value: newValue,
                                    };
                                  }
                                  return op;
                                }
                              );
                              setValues({ ...value, options: updatedOptions });
                            }}
                          />
                        </li>
                      );
                    })}
                    <li className='col-span-1'>
                      {option.isCustom && (
                        <div
                          className='cursor-pointer'
                          onClick={() => {
                            handleRemoveOption(index);
                          }}
                        >
                          <svg
                            width='16'
                            height='24'
                            viewBox='0 0 14 18'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M1.50016 15.4167C1.50016 16.425 2.32516 17.25 3.3335 17.25H10.6668C11.6752 17.25 12.5002 16.425 12.5002 15.4167V4.41667H1.50016V15.4167ZM13.4168 1.66667H10.2085L9.29183 0.75H4.7085L3.79183 1.66667H0.583496V3.5H13.4168V1.66667Z'
                              fill='#2E2B2E'
                            />
                          </svg>
                        </div>
                      )}
                    </li>
                  </ul>
                ))}
              </div>
              <button
                onClick={() => {
                  setOpenModal(true);
                }}
                className='flex cursor-pointer items-center ml-4 mt-4 gap-x-2'
              >
                <PlusSmIcon height={34} className='' />
                <div className='text-[20px]'>Add Storage Type</div>
              </button>
              {openModal && (
                <div className='ml-4 mt-4'>
                  <CustomInput
                    type='text'
                    label=''
                    placeholder='Enter Option Name'
                    classes='font-openSans text-base max-w-xl col-span-2'
                    style={{ maxHeight: '48px' }}
                    onChange={(e) => {
                      setOptionName(e.target.value);
                    }}
                  />
                  <CustomButton handleClick={AddOption} className='w-fit my-3'>
                    <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
                      Add
                    </span>
                  </CustomButton>
                </div>
              )}
              <CustomButton handleClick={handleSubmit} className='w-fit h-fit mx-auto'>
                <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
                  {loading && <Oval height='18' width='18' color='white' />}
                  Update Storage
                </span>
              </CustomButton>
              <div className='text-lg font-[500] text-[#8a2c2c]'>
                {storageError.error}
              </div>
            </div>
          </div>
        </Modal>
      )}
      {storageInventoryState.step === 2 && (
        <Modal
          open={show}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
          className='h-full'
        >
          <div
            className='font-openSans relative overflow-scroll bg-grey-8 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-10 rounded shadow-lg'
            style={{ width: '100vw', height: '100%' }}
          >
            <button className='absolute right-10' onClick={handleClose}>
              <CrossIcon />
            </button>
            <div
              className='absolte left-10'
              onClick={() => {
                setStorageInventoryState({ step: 1 });
              }}
            >
              <ArrowLeft height={24} />
            </div>
            <h1 className='font-bold text-xl text-center p-0 m-0 mb-6'>
              Allocate storage
            </h1>
            <div className='grid grid-cols-6 lg:grid-cols-12 col-span-12 gap-y-10 gap-x-10 mb-6'>
              <Card className='!shadow-none col-span-6 p-3 md:py-8 md:px-8 w-full border-[2px] border-[rgba(0,0,0,0.2)] rounded-[12px]'>
                <div className='flex justify-between'>
                  <div className='font-bold text-[18px]'>
                    Storage Allocations
                  </div>
                  {storageAllocationValues.allocatedStorages !=
                    totalAssignedStorageAllocate ? (
                    <div className='bg-[#F6E4CA] max-w-[200px]  rounded-[8px]  gap-x-4 flex items-center pl-4 pr-3 py-2'>
                      <div className='h-4 w-4 rounded-full bg-[#DA952C]'></div>
                      <div className='text-[16px] flex-1 font-[500] leading-[20px]'>
                        {storageAllocationValues.allocatedStorages -
                          totalAssignedStorageAllocate}{' '}
                        Inventory Unaccounted For
                      </div>
                    </div>
                  ) : (
                    <div className='bg-[#D6FABB] max-w-[200px]  rounded-[8px]  gap-x-4 flex items-center pl-4 pr-3 py-2'>
                      <div className='h-4 w-4 rounded-full bg-[#60AD83]'></div>
                      <div className='text-[16px] flex-1 font-[500] leading-[20px]'>
                        All Inventory Accounted for
                      </div>
                    </div>
                  )}
                </div>
                <div className='flex'>
                  <div className='flex mt-4 md:mt-0 flex-col'>
                    <div className='flex gap-x-6'>
                      <div className='h-full'>
                        <div className='flex flex-wrap h-full gap-x-4'>
                          {storageAllocationStatus.map((storage, index) => (
                            <>
                              <div className='flex flex-col'>
                                <div className='flex items-center gap-x-2'>
                                  <div
                                    style={{
                                      backgroundColor:
                                        storageColor[
                                        index % storageColor.length
                                        ],
                                    }}
                                    className={`rounded-full h-3 w-3`}
                                  ></div>
                                  <div className='text-sm col-span-2'>
                                    {storage.name}
                                  </div>
                                </div>
                                <div className='text-xl col-span-2 font-bold'>
                                  {storage.value}
                                </div>
                              </div>
                              {storageAllocationStatus.length - 1 != index && (
                                <div className='w-[2px] bg-[rgba(0,0,0,0.4)]'></div>
                              )}
                            </>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className='flex gap-x-20 items-center mt-6'>
                      <div>
                        <div className='font-bold text-[16px] '>
                          Storage Types
                        </div>
                        <div className='flex flex-col md:flex-row gap-x-4 mt-6'>
                          {storageAllocationValues.options.map(
                            (storage, index) => (
                              <>
                                <div className='flex flex-row gap-x-4 md:gap-x-0 md:flex-col'>
                                  <div className='flex items-center gap-x-2'>
                                    <div
                                      style={{
                                        backgroundColor:
                                          storageColor[
                                          index % storageColor.length
                                          ],
                                      }}
                                      className={`rounded-full h-3 w-3`}
                                    ></div>
                                    <div className='text-sm col-span-2'>
                                      {storage.name}
                                    </div>
                                  </div>
                                  <div className='text-xl col-span-2 font-bold'>
                                    {storage.storageTypes.reduce(
                                      (sum, storageType) =>
                                        sum +
                                        storageType.options.reduce(
                                          (subSum, option) =>
                                            subSum + Number(option.value),
                                          0
                                        ),
                                      0
                                    )}
                                  </div>
                                </div>
                                {storageAllocationValues.options.length - 1 !=
                                  index && (
                                    <div className='w-[2px] bg-[rgba(0,0,0,0.4)]'></div>
                                  )}
                              </>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
              <Card className='!shadow-none col-span-6 !overflow-visible p-3 md:py-8 md:px-8 w-full border-[2px] border-[rgba(0,0,0,0.2)] rounded-[12px]'>
                <div className='font-bold text-[20px]'>Storage Inventory</div>
                <div className='flex gap-x-16 mt-6'>
                  <div className='font-bold text-[24px]'>
                    {value.totalStorages} Storages
                  </div>
                  <div className='h-full'>
                    <div className='flex flex-wrap h-full gap-y-4 gap-x-4'>
                      {storageTypeData.map((storage, index) => (
                        <>
                          <div className='flex flex-row gap-x-4 md:gap-x-0 md:flex-col'>
                            <div className='flex items-center gap-x-2'>
                              <div
                                style={{
                                  backgroundColor: storage.color,
                                }}
                                className={`rounded-full h-3 w-3`}
                              ></div>
                              <div className='text-sm col-span-2'>
                                {storage.name}
                              </div>
                            </div>
                            <div className='text-xl col-span-2 font-bold'>
                              {storage.value}
                            </div>
                          </div>
                          {storageTypeData.length - 1 != index && (
                            <div className='w-0 md:w-[2px] bg-[rgba(0,0,0,0.4)]'></div>
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                </div>
                <div className='flex gap-x-10 flex-wrap gap-y-6 items-center mt-6'>
                  <div>
                    <div className='font-bold text-[16px] '>Storage Types</div>
                    <div className='flex flex-col md:flex-row gap-x-4 mt-6'>
                      {value.options.map((storage, index) => (
                        <>
                          <div className='flex flex-row gap-x-4 md:gap-x-0 md:flex-col'>
                            <div className='flex items-center gap-x-2'>
                              <div
                                style={{
                                  backgroundColor:
                                    storageColor[index % storageColor.length],
                                }}
                                className={`rounded-full h-3 w-3`}
                              ></div>
                              <div className='text-sm col-span-2'>
                                {storage.name}
                              </div>
                            </div>
                            <div className='text-xl col-span-2 font-bold'>
                              {storage.value.reduce(
                                (sum, currentValue) =>
                                  Number(sum) + Number(currentValue),
                                0
                              )}
                            </div>
                          </div>
                          {value.options.length - 1 != index && (
                            <div className='w-0 md:w-[2px] bg-[rgba(0,0,0,0.4)]'></div>
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                  <div>
                    <div className='font-bold text-[16px]'>Storage Types</div>
                    <div className='flex flex-col md:flex-row gap-x-4 mt-6'>
                      {value.storageLevels.map((storage, index) => (
                        <>
                          <div className='flex flex-row gap-x-4 md:gap-x-0 md:flex-col'>
                            <div className='flex items-center gap-x-2'>
                              <div
                                style={{
                                  backgroundColor:
                                    storageColor[index % storageColor.length],
                                }}
                                className={`rounded-full h-3 w-3`}
                              ></div>
                              <div className='text-sm col-span-2'>
                                {storage}
                              </div>
                            </div>
                            <div className='text-xl col-span-2 font-bold'>
                              {value.options.reduce((sum, op) => {
                                const opValue = op.value[index];
                                return (
                                  sum + (isNaN(opValue) ? 0 : Number(opValue))
                                );
                              }, 0)}
                            </div>
                          </div>
                          {value.storageLevels.length - 1 != index && (
                            <div className='w-0 md:w-[2px] bg-[rgba(0,0,0,0.4)]'></div>
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              </Card>
            </div>
            <div
              style={{
                gridTemplateColumns: 'minmax(400px, 1fr) minmax(400px, 1fr)',
              }}
              className='grid grid-cols-12 overflow-x-auto col-span-12 px-6 pt-8 gap-x-10 gap-y-6'
            >
              <ul
                className={`grid col-span-12 items-center text-[#323232] font-bold grid-cols-12 ${value.storageLevels.length >= 5 ? 'gap-x-4' : 'gap-x-10'
                  }`}
              >
                <li className='col-span-2 text-[20px] text-black'>Options</li>
                {value.storageLevels.map((level, index) => {
                  return (
                    <li
                      key={index}
                      className={`${value.storageLevels.length > 2 &&
                        value.storageLevels.length < 5 &&
                        'col-span-2'
                        } ${value.storageLevels.length >= 5 && 'col-span-1'
                        } text-[20px] col-span-3 text-center text-black`}
                    >
                      {level}
                    </li>
                  );
                })}
              </ul>
              {storageAllocationValues.options.map((option, index) => (
                <ul
                  className={`grid col-span-12 overflow-visible !mb-0 items-center text-[#323232] font-bold grid-cols-12 ${storageAllocationValues.storageLevels.length >= 5
                    ? 'gap-x-4'
                    : 'gap-x-10'
                    }`}
                >
                  <li
                    key={index}
                    className='col-span-2 flex gap-x-8 items-center text-[20px] text-black '
                  >
                    <div className='cursor-pointer'>
                      {editIndexAllocate === index ? (
                        <div className='flex gap-x-2 items-center'>
                          <CustomInput
                            label=''
                            placeholder=''
                            type='text'
                            value={editedNameAllocate}
                            onChange={(e) =>
                              setEditedNameAllocate(e.target.value)
                            }
                          />
                          <button
                            className='font-light text-[18px]'
                            onClick={handleSaveOptionAllocate}
                          >
                            Save
                          </button>
                        </div>
                      ) : (
                        <div className='flex gap-x-5 items-center'>
                          {option.name}
                          {option.isCustom && (
                            <EditIcon
                              height={'20px'}
                              onClick={() => handleEditOptionAllocate(index)}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </li>
                  {storageAllocationValues.options[index].storageTypes.map(
                    (option, optionIndex) => (
                      <div key={optionIndex} className='col-span-3'>
                        <li
                          key={optionIndex}
                          className={`${storageAllocationValues.storageLevels.length > 2 &&
                            storageAllocationValues.storageLevels.length < 5 &&
                            'col-span-2'
                            } ${storageAllocationValues.storageLevels.length >= 5 &&
                            'col-span-1'
                            } col-span-3`}
                        >
                          <div className='flex gap-x-4'>
                            {option.options.map((op, opIndex) => {
                              return (
                                <div
                                  key={opIndex}
                                  className='flex flex-col text-center'
                                >
                                  {index == 0 && (
                                    <div className='text-[18px]'>{op.name}</div>
                                  )}
                                  <CustomInput
                                    type='number'
                                    label=''
                                    placeholder=''
                                    classes='font-openSans text-base col-span-2'
                                    style={{ maxHeight: '48px' }}
                                    value={op.value || ''}
                                    onChange={(e) => {
                                      const newValue = e.target.value;
                                      const updatedOptions =
                                        storageAllocationValues.options.map(
                                          (opt, optIndex) => {
                                            if (optIndex === index) {
                                              const updatedTypes =
                                                opt.storageTypes.map((pt) => {
                                                  if (
                                                    pt.storageLevel ===
                                                    storageAllocationValues
                                                      .storageLevels[
                                                    optionIndex
                                                    ]
                                                  ) {
                                                    const updatedOptions =
                                                      pt.options.map(
                                                        (option) => {
                                                          if (
                                                            option.name ===
                                                            storageAllocationValues
                                                              .options[index]
                                                              .storageTypes[
                                                              optionIndex
                                                            ].options[opIndex]
                                                              .name
                                                          ) {
                                                            return {
                                                              ...option,
                                                              value: newValue,
                                                            };
                                                          }
                                                          return option;
                                                        }
                                                      );
                                                    return {
                                                      ...pt,
                                                      options: updatedOptions,
                                                    };
                                                  }
                                                  return pt;
                                                });
                                              return {
                                                ...opt,
                                                storageTypes:
                                                  updatedTypes,
                                              };
                                            }
                                            return opt;
                                          }
                                        );
                                      setStorageAllocationValues({
                                        ...storageAllocationValues,
                                        options: updatedOptions,
                                      });
                                    }}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </li>
                      </div>
                    )
                  )}

                  <li className='col-span-1'>
                    {option.isCustom && (
                      <div
                        className='cursor-pointer'
                        onClick={() => {
                          handleRemoveAllocation(index);
                        }}
                      >
                        <svg
                          width='16'
                          height='24'
                          viewBox='0 0 14 18'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M1.50016 15.4167C1.50016 16.425 2.32516 17.25 3.3335 17.25H10.6668C11.6752 17.25 12.5002 16.425 12.5002 15.4167V4.41667H1.50016V15.4167ZM13.4168 1.66667H10.2085L9.29183 0.75H4.7085L3.79183 1.66667H0.583496V3.5H13.4168V1.66667Z'
                            fill='#2E2B2E'
                          />
                        </svg>
                      </div>
                    )}
                  </li>
                </ul>
              ))}
            </div>

            <button
              onClick={() => {
                setOpenModal(true);
              }}
              className='flex cursor-pointer items-center ml-4 mt-4 gap-x-2'
            >
              <PlusSmIcon height={34} className='' />
              <div className='text-[20px]'>Add Storage Type</div>
            </button>
            {openModal && (
              <div className='ml-4 mt-4'>
                <CustomInput
                  type='text'
                  label=''
                  placeholder='Enter Option Name'
                  classes='font-openSans text-base max-w-xl col-span-2'
                  style={{ maxHeight: '48px' }}
                  onChange={(e) => {
                    setOptionNameAllocate(e.target.value);
                  }}
                />
                <CustomButton handleClick={AddOptionAllocate} className='my-4'>
                  <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
                    Add
                  </span>
                </CustomButton>
              </div>
            )}
            <CustomButton
              handleClick={handleSubmitAllocate}
            >
              <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
                {loading && <Oval height='18' width='18' color='black' />}
                Update Storage
              </span>
            </CustomButton>
            <div className='text-lg font-[500] text-[#8a2c2c]'>
              {storageErrorAllocate.error}
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default AddStorageInventoryModal;
