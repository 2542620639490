/* eslint-disable space-before-function-paren */
import CustomButton from 'components/CustomButton'
import TableGrid from 'components/TableGrid/Table'
import useCustomForms, { useGetCustomForms } from 'hooks/useCustomForms'
import useProjectId from 'hooks/useProjectId'
import { BarChartIcon, PlusCircle } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import FormActionButtons from './formActionButtons'

// eslint-disable-next-line space-before-function-paren
export default function FormManagement() {
  const { projectId } = useProjectId()
  const [isCorporate] = useState(
    !!window.location.href.includes('corporate')
  )
  const { forms, isLoading: isLoadingList, refetch: refetchItems } = useGetCustomForms(
    {
      projectId, isCorporate
    }
  )
  const history = useHistory()
  useEffect(() => {
    refetchItems()
  }, [refetchItems, projectId])
  const { createCustomForm } = useCustomForms()

  if (isLoadingList) return <div>Loading...</div>

  function createNewFormAndRedirect() {
    const data = {
      projectId,
      isCorporate
    }
    createCustomForm(data).then((response) => {
      const redirectUrl = '/admin/forms/edit'
      history.push(`${redirectUrl}?formId=${response._id}`)
    })
  }

  return (
    <div className='flex flex-col gap-y-2 px-4 py-3'>
      <div className='flex justify-between items-center'>
        <h1>Forms</h1>
        <div>
          <CustomButton color="primary" onClick={() => { createNewFormAndRedirect() }}
            className='font-bold py-2 px-4'
          >
            <PlusCircle size={18} />  Create Form
          </CustomButton>
        </div>
      </div>
      <TableGrid
        pagination={true}
        style={{
          height: '650px',
          minHeight: '650px'
        }}
        columnDefs={[
          {
            headerName: '',
            field: '_id',
            width: 110,
            cellRenderer: ({ value }) => {
              return <a href={`/admin/forms/analytics?formId=${value}`}>
                <CustomButton
                  variant='secondary'
                >
                  <BarChartIcon size={15} />  Analytics
                </CustomButton>
              </a>
            }
          },
          {
            headerName: 'Form Title',
            field: 'name',
            flex: 1,
            onCellClicked: (params) => {
              const url = `/admin/forms/edit?formId=${params.data._id}`
              history.push(url)
            }
          },
          {
            headerName: 'Fields Count',
            field: 'fields',
            cellRenderer: ({ value }) => {
              return <div>
                {value?.length}
              </div>
            }
          },
          {
            headerName: 'Created On',
            field: 'createdAt',
            sortable: true,
            cellRenderer: ({ value }) => {
              return new Date(value).toLocaleString()
            }
          },
          {
            headerName: 'Updated On',
            field: 'updatedAt',
            sortable: true,
            cellRenderer: ({ value }) => {
              return new Date(value).toLocaleString()
            }
          },
          {
            headerName: 'Actions',
            field: '_id',
            width: 275,
            cellRenderer: (data) => {
              console.log(data.data)
              return <FormActionButtons form={data.data} />
            }
          }
        ]}
        getData={(filter, pagination, sorting) => {
          return new Promise((resolve) => {
            resolve(forms)
          })
        }}
      />
    </div>
  )
}
