import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { BrokerSidePanel, CompanyLogo } from 'pages/leadBrokerPages/components'
import { useHistory } from 'react-router-dom'
import { BROKER_HOME_PAGE } from 'routes'
import { EmailAttachmentSVGIcon } from 'pages/contacts/EmailModal'
import { getAllFiles } from 'store/actions/fileActions'
import { useSelector } from 'react-redux'

const marketingDocsFileTagName = 'Broker-Portal'

const FileItem = ({ _id, documentName, fileName, onClick }) => {
  return (
    <div
      key={_id}
      className='flex flex-col bg-white w-[153.68px] h-[150.26px] rounded cursor-pointer pl-5 pt-5'
      onClick={onClick}
    >
      <div className='flex w-[57px] h-[68px] justify-center items-center'>
        {EmailAttachmentSVGIcon(fileName.split('.').pop())}
      </div>
      <h2 className='w-full text-start justify-center content-center items-center text-[10px] font-bold text-softBlack_70 mt-3 mb-0'>
        {documentName}
      </h2>
    </div>
  )
}

const DocumentScreen = () => {
  const history = useHistory()
  // @ts-ignore
  const userObject = useSelector((state) => state.authReducer.userObject)
  const [selectedFileUrl, setSelectedFileUrl] = useState()
  const [files, setFiles] = useState([])

  const _getAllFiles = useCallback(() => {
    getAllFiles(userObject?.leadBrokerData?.project)
      .then((res) => {
        setFiles(res)
      })
      .catch((error) => {
        console.error('error: ', error)
      })
  }, [userObject])

  useEffect(() => {
    if (userObject?.leadBrokerData?.project) {
      _getAllFiles()
    }
  }, [userObject])

  const fileItems = useMemo(() => {
    return files?.filter((elm) => elm.tags.includes(marketingDocsFileTagName)).filter(elm => elm.fileName.endsWith('.pdf'))
  }, [files])

  return (
    <div className='flex flex-row text-softBlack justify-center items-center content-center h-full w-full'>
      <div className='flex flex-col space-y-24 mx-12'>
        <CompanyLogo />
        <div className='flex-1 w-full h-full justify-center items-center content-center'>
          <div className='flex justify-center content-center'>
            <BrokerSidePanel
              buttonLabel='Home'
              onButtonClick={() => {
                history.push(BROKER_HOME_PAGE)
              }}
            />
          </div>
        </div>
      </div>
      <div className='flex-1 h-full p-0 bg-[#E9E9E9]'>
        <div className='flex flex-col items-center content-start justify-start gap-7 pt-11 h-full'>
          <h1 className='text-[51px] font-bold m-0'>Marketing Docs</h1>
          <div className='flex flex-row justify-start items-start w-full h-full px-6 space-x-11 overflow-hidden'>
            <div className=' flex flex-col h-full justify-start items-center overflow-y-auto'>
              <p className='m-0 text-lg font-normal mb-6 flex flex-row gap-2'>
                <span>
                  <svg
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M11.4 2.4C13.8912 2.4 15.9912 4.08796 16.6129 6.38461C16.7372 6.84388 17.1184 7.18696 17.5862 7.26389C19.8629 7.63829 21.6 9.6174 21.6 12C21.6 13.7752 20.637 15.3269 19.1992 16.1586C18.6254 16.4904 18.4295 17.2246 18.7613 17.7982C19.0931 18.3719 19.8272 18.5678 20.4008 18.236C22.5498 16.993 24 14.6665 24 12C24 8.68154 21.756 5.88919 18.7027 5.05438C17.592 2.10185 14.7427 0 11.4 0C7.12577 0 3.65452 3.43793 3.60064 7.69937C1.48162 8.62499 0 10.7383 0 13.2C0 15.7199 1.55308 17.8742 3.74974 18.7634C4.36406 19.0121 5.06365 18.7157 5.31233 18.1014C5.56099 17.487 5.26458 16.7874 4.65026 16.5388C3.32873 16.0038 2.4 14.7089 2.4 13.2C2.4 11.5158 3.55762 10.0991 5.12221 9.70752C5.70611 9.56136 6.09953 9.00565 6.03275 8.39908C5.68684 5.25704 8.22161 2.4 11.4 2.4ZM12 10.8C12.6628 10.8 13.2 11.3372 13.2 12L13.2 19.9006L14.7521 18.3508C15.2212 17.8825 15.9809 17.8831 16.4492 18.3521C16.9175 18.8212 16.9169 19.5809 16.4479 20.0492L13.0598 23.4319C12.7561 23.7352 12.4607 24 12 24C11.5393 24 11.2439 23.7352 10.9402 23.4319L7.55214 20.0492C7.08314 19.5809 7.08254 18.8212 7.55081 18.3521C8.01907 17.8831 8.77886 17.8825 9.24786 18.3508L10.8 19.9006L10.8 12C10.8 11.3372 11.3372 10.8 12 10.8Z'
                      fill='#09244B'
                    />
                  </svg>
                </span>
                Download Files
              </p>
              <div className='flex flex-col h-full overflow-y-auto space-y-9 px-5 pb-6'>
                {fileItems?.map((data) => {
                  return <FileItem {...data} onClick={() => setSelectedFileUrl(data.url)} />
                })}
              </div>
            </div>
            <div className='flex-1 h-full w-full flex justify-center items-center'>
              {!selectedFileUrl &&
                <p className='text-center font-medium text-xl'>No files selected.</p>
              }
              {selectedFileUrl &&
                <iframe src={selectedFileUrl} className='w-19/20 h-19/20 bg-white rounded object-contain' />
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DocumentScreen
