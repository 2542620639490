// @ts-nocheck
import React, { useEffect, useState } from 'react'
import useInventory from '../hooks/useInventory'
import { LottieWrapper } from 'components'
import successAnimation from 'assets/lottieJsons/avesdoLoading.json'

const InventoryMapHeader = ({
  type = '',
  totalCount = 0,
  statusCountsData = [],
  isLoading = false
}) => {
  const { state: { statusColors } } = useInventory()
  const [filteredStatusData, setFilteredStatusData] = useState([])

  useEffect(() => {
    setFilteredStatusData(statusCountsData.filter((elm) => elm.percentage !== '0%'))
  }, [statusCountsData])

  return (
    <div className='bg-white border border-zinc-200 shadow-sm rounded-xl p-6 flex items-center gap-10 min-h-[260px]'>
      {
        !isLoading && (statusCountsData.length > 0)
          ? <>
            <div className='h-full flex flex-col gap-2 justify-start'>
              <h5 className='text-base font-semibold mb-0 truncate'>{type} inventory</h5>
              <h2 className='text-3xl font-bold mb-0'>{totalCount} {type.toLowerCase()}s</h2>
            </div>
            <div className='flex-1 flex flex-col gap-3 overflow-hidden'>
              <div className='h-[27px] flex gap-1 items-center rounded-md overflow-hidden'>
                {
                  filteredStatusData.map(({ status, percentage }) => (
                    <div
                      key={status}
                      className='h-full'
                      style={{ width: percentage, backgroundColor: statusColors[status.toLowerCase()]?.bgColor }}
                    />
                  ))
                }
              </div>
              <div className='flex-1 flex items-center gap-x-4 overflow-x-auto'>
                {
                  statusCountsData.map((elm, index) => (
                    <div key={elm.status} className={`flex flex-col gap-y-1 px-4 ${index !== 0 ? 'border-l border-[#2E2B2E] border-opacity-25' : ''} overflow-hidden`}>
                      <div className='flex gap-1'>
                        <svg className='mt-1' width='11' height='11' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <circle cx='6' cy='6' r='6' fill={statusColors[elm.status.toLowerCase()]?.bgColor} />
                        </svg>
                        <h6 className='flex-1 truncate text-sm mb-0 font-medium transition-all'>{statusColors[elm.status.toLowerCase()]?.name}</h6>
                      </div>
                      <p className='text-xl mb-0 font-bold'>{elm.count}</p>
                    </div>
                  ))
                }
              </div>
            </div>
            <div className='p-5 bg-white rounded-lg shadow border border-zinc-200 flex flex-col gap-7 hidden'>
              <div className='text-zinc-950 text-sm font-medium leading-[14.17px]'>Total sales(Static data)</div>
              <div className='justify-start items-end gap-[8.50px] flex'>
                <div className='text-zinc-950 text-[28.33px] font-bold leading-snug'>$545,000</div>
              </div>
              <div className='justify-between items-start flex'>
                <div className='px-4 pl-0 border-opacity-25 flex-col justify-start items-start gap-[7.51px] inline-flex'>
                  <div className='justify-start items-start gap-[3.76px] inline-flex'>
                    <div className='justify-start items-start flex'>
                      <div className='w-[11.27px] h-[11.27px] bg-neutral-500 rounded-full'></div>
                    </div>
                    <div className='text-zinc-950 text-[13.04px] font-medium leading-[18.78px]'>Single</div>
                  </div>
                  <div className='justify-start items-end gap-[5.63px] inline-flex'>
                    <div className='text-zinc-950 text-xl font-bold leading-[30.05px]'>15</div>
                  </div>
                </div>
                <div className='px-4 border-l border-zinc-800 border-opacity-25 flex-col justify-start items-start gap-[7.51px] inline-flex'>
                  <div className='justify-start items-start gap-[3.76px] inline-flex'>
                    <div className='justify-start items-start flex'>
                      <div className='w-[11.27px] h-[11.27px] bg-stone-300 rounded-full'></div>
                    </div>
                    <div className='text-zinc-950 text-[13.04px] font-medium leading-[18.78px]'>Tandem</div>
                  </div>
                  <div className='justify-start items-end gap-[5.63px] inline-flex'>
                    <div className='text-zinc-950 text-xl font-bold leading-[30.05px]'>2</div>
                  </div>
                </div>
                <div className='px-4 border-l border-zinc-800 border-opacity-25 flex-col justify-start items-start gap-[7.51px] inline-flex'>
                  <div className='justify-start items-start gap-[3.76px] inline-flex'>
                    <div className='justify-start items-start flex'>
                      <div className='w-[11.27px] h-[11.27px] bg-zinc-800 rounded-full'></div>
                    </div>
                    <div className='text-zinc-950 text-[13.04px] font-medium leading-[18.78px]'>Electric</div>
                  </div>
                  <div className='justify-start items-end gap-[5.63px] inline-flex'>
                    <div className='text-zinc-950 text-xl font-bold leading-[30.05px]'>10</div>
                  </div>
                </div>
              </div>
            </div>
          </>
          : <div className='text-softBlack text-center text-xl font-medium min-h-[207px] w-full flex flex-col justify-center items-center'>
            <div className='h-[100px] w-[100px]'>
              <LottieWrapper
                animation={successAnimation}
                autoplay
                loop={true}
                className='-mt-40 md:mt-0 max-w-sm'
              />
            </div>
            <h1 className='mt-4 text-[16px] truncate text-center'>
              Loading stats...
            </h1>
          </div>
      }
    </div>
  )
}

export default InventoryMapHeader
