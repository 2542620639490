import styled from 'styled-components'
import globals from 'assets/css/standardStyle'

export const LoaderWrapper = styled.div`
  background:rgba(255,255,255,0.8);
  width:100%;
  height:100%;
  position:absolute;
  left:0;
  top:0;
  z-index:2000;
  transition: opacity .2s ease;
  opacity: ${props => props.isActive ? 1 : 0};
  pointer-events: ${props => props.isActive ? 'inherit' : 'none'};
`

export const TextWrapper = styled.div`
  position:absolute;
  left:50%;
  top:50%;
  transform: translate3d(-50%, -50%, 0);
  margin-top:80px;
  font-weight: bold;
  font-family: ${globals.fonts.text}, ${globals.fonts.sansDefault};
`

export const Loader = styled.div`
  margin: 20px auto;
  width: 40px;
  height: 40px;
  position: absolute;
  left:50%;
  top:50%;
  transform: translate3d(-50%, -50%, 0) rotateZ(45deg);
  
  .sk-cube {
    float: left;
    width: 50%;
    height: 50%;
    position: relative;
    -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
            transform: scale(1.1); 
    
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: ${globals.colors.primary};
      -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
              animation: sk-foldCubeAngle 2.4s infinite linear both;
      -webkit-transform-origin: 100% 100%;
          -ms-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
    }
  }



.sk-cube2 {
  -webkit-transform: scale(1.1) rotateZ(90deg);
          transform: scale(1.1) rotateZ(90deg);
}
.sk-cube3 {
  -webkit-transform: scale(1.1) rotateZ(180deg);
          transform: scale(1.1) rotateZ(180deg);
}
.sk-cube4 {
  -webkit-transform: scale(1.1) rotateZ(270deg);
          transform: scale(1.1) rotateZ(270deg);
}
.sk-cube2:before {
  background: ${globals.colors.primary};
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
.sk-cube3:before {
  background: ${globals.colors.primary};
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s; 
}
.sk-cube4:before {
  background: ${globals.colors.primary};
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
}
@-webkit-keyframes sk-foldCubeAngle {
    0%, 10% {
      -webkit-transform: perspective(140px) rotateX(-180deg);
              transform: perspective(140px) rotateX(-180deg);
      opacity: 0; 
    } 25%, 75% {
      -webkit-transform: perspective(140px) rotateX(0deg);
              transform: perspective(140px) rotateX(0deg);
      opacity: 1; 
    } 90%, 100% {
      -webkit-transform: perspective(140px) rotateY(180deg);
              transform: perspective(140px) rotateY(180deg);
      opacity: 0; 
    } 
  }

  @keyframes sk-foldCubeAngle {
    0%, 10% {
      -webkit-transform: perspective(140px) rotateX(-180deg);
              transform: perspective(140px) rotateX(-180deg);
      opacity: 0; 
    } 25%, 75% {
      -webkit-transform: perspective(140px) rotateX(0deg);
              transform: perspective(140px) rotateX(0deg);
      opacity: 1; 
    } 90%, 100% {
      -webkit-transform: perspective(140px) rotateY(180deg);
              transform: perspective(140px) rotateY(180deg);
      opacity: 0; 
    }
  }
`
