import React, { useState } from 'react'
import {
  // AdminPageWrapper,
  PageHeader
} from 'components'
import { connect, useSelector } from 'react-redux'
import ProjectParkingsInventory from 'components/ProjectParkingsInventory'
import NewViewSwitcher from 'components/NewViewSwitcher'

const ParkingsInventory = () => {
  const [viewType, setViewType] = useState('mapView')
  // @ts-ignore
  const projectId = useSelector((state) => state.appReducer.appProject)
  const RightContent = () => (
    <div className='flex items-stretch gap-2'>
      {!window?.location?.href?.includes('public/inventory') && (
        <div className='flex justify-center items-center font-bold w-36 border border-black rounded cursor-pointer'>
          <a
            href={`${window.location.origin
              }/public/parking_inventory?projectId=${btoa(projectId)}`}
            target='_blank'
            className='py-2'
          >
            Open Public URL
          </a>
        </div>
      )}
      <NewViewSwitcher
        view={viewType}
        onChange={(_viewType) => {
          setViewType(_viewType)
        }}
      />
    </div>
  )

  return (
    <>
      <PageHeader title='Parking Inventory' rightContent={<RightContent />} />
      {/* <AdminPageWrapper fullscreen> */}
        <div className='mx-auto px-4 sm:px-6 lg:px-8 w-full h-[calc(100%-100px)]'>
          <ProjectParkingsInventory viewType={viewType} />
        </div>
      {/* </AdminPageWrapper> */}
    </>
  )
}

const mapStateToProps = (state) => ({
  // appProject: state.appReducer.appProject
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ParkingsInventory)

ParkingsInventory.propTypes = {
  // appProject: propTypes.string
}
