/* eslint-disable no-unused-vars */
// @ts-nocheck
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from 'react'

import CustomModal from 'components/Modal'
import Button from 'components/Buttons'
import { COLORS } from 'pages/pwaMode/createOfferNew/constants/colors'
import { ContactContext } from 'pages/contacts/context/Contacts'

import { CloseIcon } from 'components/Icons'
import { getUserLists, updateUserLists } from 'store/actions/usersActions'
import useGetLists from 'hooks/useGetLists'

const AddToListModal = ({
  open,
  setOpen,
  selectedContactId = '',
  showCreateListButton = false,
  setOpenCreateListModal = (val) => { },
  refetchLists = () => { }
}) => {
  const { saveList, selectedContact, contactsLists, getContacts } = useContext(ContactContext)
  const [_lists, setLists] = useState([])
  const { lists } = useGetLists()
  const [loading, setLoading] = useState(true)
  const [allSelected, setAllSelected] = useState(false)
  const [selectedLists, setSelectedLists] = useState({})
  const fetchLists = useRef(async () => { })

  fetchLists.current = async (open) => {
    if (open && !contactsLists?.length) await getContacts()
    setLists(contactsLists)
  }

  const handleUpdateLists = () => {
    const previousData = {}
    lists.forEach(list => (previousData[list._id] = list.users))
    const listOfUsers = selectedContactId ? [selectedContactId] : saveList.map(selectedUser => selectedUser._id)
    const listOfIds = Object.keys(selectedLists).filter(id => selectedLists[id])
    updateUserLists(listOfIds, listOfUsers, previousData)
    setOpen(false)
    refetchLists?.()
  }

  const handleSelectAll = () => {
    if (!allSelected) {
      setAllSelected(true)
      const allLists = {}
      lists.forEach(list => (allLists[list._id] = true))
      setSelectedLists(allLists)
    } else {
      setAllSelected(false)
      setSelectedLists({})
    }
  }

  const handleSelect = (list) => {
    setSelectedLists({ ...selectedLists, [list._id]: !selectedLists?.[list._id] })
    setAllSelected(false)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const Checkbox = ({ placeholder, onChange = (e) => e, ...props }) => {
    return (
      <label className='flex items-center mt-2'>
        <input
          type='checkbox'
          className='mr-2 rounded'
          onChange={onChange}
          {...props}
        />
        {placeholder}
      </label>
    )
  }

  return (
    <CustomModal isOpen={open} closeModal={handleClose}>
      {' '}
      {!loading && !lists?.length
        ? <div className='flex justify-center items-center text-lg w-136 max-w-full p-10 text-center rounded bg-grey-8'>
          You have no lists
        </div>
        : <div
          style={{ backgroundColor: 'rgb(242, 242, 241)', width: '290px' }}
          className='max-w-full p-10 text-center rounded bg-grey-8'
        >
          <div
            className='flex justify-end hover:cursor-pointer'
            onClick={handleClose}
          >
            <CloseIcon />
          </div>
          <div style={{ maxHeight: '600px' }} className='flex flex-col gap-2 overflow-y-auto'>
            <div className='flex flex-row gap-3'>
              <Checkbox
                key='-1'
                placeholder=''
                checked={allSelected}
                onChange={handleSelectAll}
              />
              <div className='mt-2'>All</div>
            </div>
            {
              lists?.map?.((list, index) =>
                <div className='flex flex-row gap-3' key={index}>
                  <Checkbox
                    placeholder=''
                    checked={selectedLists?.[list._id] ?? list?.users?.includes(selectedContact) ?? false}
                    onChange={() => handleSelect(list)}
                  />
                  <div className='mt-2'>{list.title}</div>
                </div>)
            }
          </div>
          <div className='flex justify-center gap-2'>
            {showCreateListButton && <Button
              buttonClasses='justify-self-center whitespace-nowrap font-openSans px-4 py-4 mt-4'
              label='Create List'
              type='Primary'
              textColor={COLORS.softBlack}
              buttonBorder={`1px solid ${COLORS.volt}`}
              fontWeight='400'
              labelSize='16px'
              handleClick={() => {
                setOpen(false)
                setOpenCreateListModal(true)
              }}
              labelFontStyle='italic'
            />}
            <Button
              buttonClasses='justify-self-center whitespace-nowrap font-openSans px-4 py-4 mt-4'
              label='Add to Lists'
              type='Primary'
              textColor={COLORS.softBlack}
              buttonBorder={`1px solid ${COLORS.volt}`}
              fontWeight='400'
              labelSize='16px'
              handleClick={handleUpdateLists}
              labelFontStyle='italic'
            />
          </div>
        </div>}
    </CustomModal>
  )
}

export default AddToListModal
