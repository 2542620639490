import { ItemType } from './components/AllAgentsSalesReport'
import moment from 'moment'

export const DataForAllReports = [
  {
    title: 'Today\'s Summary - All Sales Reps',
    type: ItemType.NORMAL,
    date: `${moment().format('MMMM DD')}th ${moment().format('YYYY')}`,
    data: [
      {
        title: 'NEW LEADS',
        value: '531(static)',
        type: ItemType.NORMAL
      },
      {
        title: 'BUYER NEW LEADS',
        value: '411(static)',
        type: ItemType.NORMAL
      },
      {
        title: 'BROKER NEW LEAD',
        value: '120(static)',
        type: ItemType.NORMAL
      },
      {
        title: 'PROSPECT - 2 WAY COMMUNICATIONS',
        value: '120(static)',
        type: ItemType.NORMAL
      },
      {
        value: {
          data: [
            {
              title: 'FEMALE',
              value: '7(static)',
              progressColor: '#2E2B2E',
              progressBackgroundColor: 'inherit'
            },
            {
              title: 'MALE',
              value: '3(static)',
              progressColor: '#EDDF65',
              progressBackgroundColor: 'inherit'
            }
          ],
          total: 10
        },
        type: ItemType.GRAPH
      },
      {
        title: 'OPPORTUNITIES',
        value: '0(static)',
        type: ItemType.NORMAL
      },
      {
        title: 'RESERVATIONS',
        value: '0(static)',
        type: ItemType.NORMAL
      },
      {
        title: 'CONTRACTS',
        value: '0(static)',
        type: ItemType.NORMAL
      }
    ]
  },
  {
    title: 'Today\'s Leads By Source - All Sales Reps',
    type: ItemType.NORMAL,
    date: `${moment().format('MMMM DD')}th ${moment().format('YYYY')}`,
    data: [
      {
        title: 'WEBSITE FORM',
        value: '42(static)',
        type: ItemType.NORMAL
      },
      {
        title: 'LIST IMPORT',
        value: '367(static)',
        type: ItemType.NORMAL
      },
      {
        title: 'BROKER REFERRAL',
        value: '83(static)',
        type: ItemType.NORMAL
      },
      {
        title: 'WALK IN',
        value: '21(static)',
        type: ItemType.NORMAL
      }
    ]
  },
  {
    title: 'Today\'s Buyer Rating - All Sales Reps',
    type: ItemType.NORMAL,
    date: `${moment().format('MMMM DD')}th ${moment().format('YYYY')}`,
    data: [
      {
        title: 'WEBSITE FORM',
        value: '42(static)',
        type: ItemType.NORMAL
      },
      {
        title: 'LIST IMPORT',
        value: '367(static)',
        type: ItemType.NORMAL
      },
      {
        title: 'BROKER REFERRAL',
        value: '83(static)',
        type: ItemType.NORMAL
      },
      {
        title: 'WALK IN',
        value: '21(static)',
        type: ItemType.NORMAL
      }
    ]
  },
  {
    title: 'Today\'s Activity - All Sales Reps',
    type: ItemType.NORMAL,
    date: `${moment().format('MMMM DD')}th ${moment().format('YYYY')}`,
    data: [
      {
        title: 'CALLS',
        value: '134(static)',
        type: ItemType.NORMAL
      },
      {
        title: 'EMAILS',
        value: '67(static)',
        type: ItemType.NORMAL
      },
      {
        title: 'SMS / WHATS APP',
        value: '83(static)',
        type: ItemType.NORMAL
      },
      {
        title: 'TASK',
        value: '32(static)',
        type: ItemType.NORMAL
      },
      {
        title: 'VIRTUAL MEETING BOOKED',
        value: '13(static)',
        type: ItemType.NORMAL
      },
      {
        title: 'PRESENTATION CENTER APPOINMENTS BOOKED',
        value: '5(static)',
        type: ItemType.NORMAL
      }
    ]
  },
  {
    title: 'Today\'s Leads By Stage - All Sales Reps (static)',
    type: ItemType.NORMAL,
    date: `${moment().format('MMMM DD')}th ${moment().format('YYYY')}`,
    data: [
      {
        title: 'LEAD',
        value: 267,
        type: ItemType.NORMAL
      },
      {
        title: 'LOST',
        value: 17,
        type: ItemType.NORMAL
      },
      {
        title: 'PROSPECT - 2 WAY COMMUNICATION',
        value: 14,
        type: ItemType.NORMAL
      },
      {
        title: 'PROSPECT WITH OPPORTUNITY',
        value: 5,
        type: ItemType.NORMAL
      },
      {
        title: 'RESERVATION HOLDER',
        value: 0,
        type: ItemType.NORMAL
      },
      {
        title: 'CONTRACT',
        value: 0,
        type: ItemType.NORMAL
      },
      {
        title: 'OWNER',
        value: 0,
        type: ItemType.NORMAL
      }
    ]
  },
  {
    title: 'Today\'s Prospect Rating - All Sales Reps (static)',
    type: ItemType.GRAPH,
    date: `${moment().format('MMMM DD')}th ${moment().format('YYYY')}`,
    data: [
      { name: 'A', value: 6 },
      { name: 'B', value: 16 },
      { name: 'C', value: 24 },
      { name: 'D', value: 56 },
      { name: 'Unrated', value: 249 }
    ]
  },
  {
    title: 'All time\'s Summary - All Sales Reps',
    type: ItemType.NORMAL,
    date: `${moment().format('MMMM DD')}th ${moment().format('YYYY')}`,
    data: [
      {
        title: 'NEW LEADS',
        value: '531(static)',
        type: ItemType.NORMAL
      },
      {
        title: 'BUYER NEW LEADS',
        value: '411(static)',
        type: ItemType.NORMAL
      },
      {
        title: 'BROKER NEW LEAD',
        value: '120(static)',
        type: ItemType.NORMAL
      },
      {
        title: 'PROSPECT - 2 WAY COMMUNICATIONS',
        value: '120(static)',
        type: ItemType.NORMAL
      },
      {
        title: 'CONTACTS',
        value: '200(static)',
        type: ItemType.NORMAL
      },
      {
        value: {
          data: [
            {
              title: 'FEMALE',
              value: '7(static)',
              progressColor: '#2E2B2E',
              progressBackgroundColor: 'inherit'
            },
            {
              title: 'MALE',
              value: '3(static)',
              progressColor: '#EDDF65',
              progressBackgroundColor: 'inherit'
            }
          ],
          total: 10
        },
        type: ItemType.GRAPH
      },
      {
        title: 'OPPORTUNITIES',
        value: '0(static)',
        type: ItemType.NORMAL
      },
      {
        title: 'RESERVATIONS',
        value: '0(static)',
        type: ItemType.NORMAL
      },
      {
        title: 'CONTRACTS',
        value: '0(static)',
        type: ItemType.NORMAL
      }
    ]
  },
  {
    title: 'All Time\'s Leads By Source - All Sales Reps',
    type: ItemType.NORMAL,
    date: `${moment().format('MMMM DD')}th ${moment().format('YYYY')}`,
    data: [
      {
        title: 'WEBSITE FORM',
        value: '42(static)',
        type: ItemType.NORMAL
      },
      {
        title: 'LIST IMPORT',
        value: '367(static)',
        type: ItemType.NORMAL
      },
      {
        title: 'BROKER REFERRAL',
        value: '83(static)',
        type: ItemType.NORMAL
      },
      {
        title: 'WALK IN',
        value: '21(static)',
        type: ItemType.NORMAL
      }
    ]
  },
  {
    title: 'All Time\'s Demand Units - All Sales Reps',
    type: ItemType.NORMAL,
    date: `${moment().format('MMMM DD')}th ${moment().format('YYYY')}`,
    data: [
      {
        title: 'WEBSITE FORM',
        value: '42(static)',
        type: ItemType.NORMAL
      },
      {
        title: 'LIST IMPORT',
        value: '367(static)',
        type: ItemType.NORMAL
      },
      {
        title: 'BROKER REFERRAL',
        value: '83(static)',
        type: ItemType.NORMAL
      },
      {
        title: 'WALK IN',
        value: '21(static)',
        type: ItemType.NORMAL
      }
    ]
  },
  {
    title: 'All Time\'s Demand Budget - All Sales Reps',
    type: ItemType.NORMAL,
    date: `${moment().format('MMMM DD')}th ${moment().format('YYYY')}`,
    data: [
      {
        title: 'WEBSITE FORM',
        value: '42(static)',
        type: ItemType.NORMAL
      },
      {
        title: 'LIST IMPORT',
        value: '367(static)',
        type: ItemType.NORMAL
      },
      {
        title: 'BROKER REFERRAL',
        value: '83(static)',
        type: ItemType.NORMAL
      },
      {
        title: 'WALK IN',
        value: '21(static)',
        type: ItemType.NORMAL
      }
    ]
  },
  {
    title: 'All Time\'s Project Interests - All Sales Reps',
    type: ItemType.NORMAL,
    date: `${moment().format('MMMM DD')}th ${moment().format('YYYY')}`,
    data: [
      {
        title: 'WEBSITE FORM',
        value: '42(static)',
        type: ItemType.NORMAL
      },
      {
        title: 'LIST IMPORT',
        value: '367(static)',
        type: ItemType.NORMAL
      },
      {
        title: 'BROKER REFERRAL',
        value: '83(static)',
        type: ItemType.NORMAL
      },
      {
        title: 'WALK IN',
        value: '21(static)',
        type: ItemType.NORMAL
      }
    ]
  },
  {
    title: 'All Time\'s Floor Interests - All Sales Reps',
    type: ItemType.NORMAL,
    date: `${moment().format('MMMM DD')}th ${moment().format('YYYY')}`,
    data: [
      {
        title: 'WEBSITE FORM',
        value: '42(static)',
        type: ItemType.NORMAL
      },
      {
        title: 'LIST IMPORT',
        value: '367(static)',
        type: ItemType.NORMAL
      },
      {
        title: 'BROKER REFERRAL',
        value: '83(static)',
        type: ItemType.NORMAL
      },
      {
        title: 'WALK IN',
        value: '21(static)',
        type: ItemType.NORMAL
      }
    ]
  },
  {
    title: 'All Time\'s Timeline to Purchase - All Sales Reps',
    type: ItemType.NORMAL,
    date: `${moment().format('MMMM DD')}th ${moment().format('YYYY')}`,
    data: [
      {
        title: 'WEBSITE FORM',
        value: '42(static)',
        type: ItemType.NORMAL
      },
      {
        title: 'LIST IMPORT',
        value: '367(static)',
        type: ItemType.NORMAL
      },
      {
        title: 'BROKER REFERRAL',
        value: '83(static)',
        type: ItemType.NORMAL
      },
      {
        title: 'WALK IN',
        value: '21(static)',
        type: ItemType.NORMAL
      }
    ]
  },
  {
    title: 'All Time\'s Needs Financing - All Sales Reps',
    type: ItemType.NORMAL,
    date: `${moment().format('MMMM DD')}th ${moment().format('YYYY')}`,
    data: [
      {
        title: 'WEBSITE FORM',
        value: '42(static)',
        type: ItemType.NORMAL
      },
      {
        title: 'LIST IMPORT',
        value: '367(static)',
        type: ItemType.NORMAL
      },
      {
        title: 'BROKER REFERRAL',
        value: '83(static)',
        type: ItemType.NORMAL
      },
      {
        title: 'WALK IN',
        value: '21(static)',
        type: ItemType.NORMAL
      }
    ]
  },
  {
    title: 'All Time\'s Activity - All Sales Reps',
    type: ItemType.NORMAL,
    date: `${moment().format('MMMM DD')}th ${moment().format('YYYY')}`,
    data: [
      {
        title: 'CALLS',
        value: '134(static)',
        type: ItemType.NORMAL
      },
      {
        title: 'EMAILS',
        value: '67(static)',
        type: ItemType.NORMAL
      },
      {
        title: 'SMS / WHATS APP',
        value: '83(static)',
        type: ItemType.NORMAL
      },
      {
        title: 'TASK',
        value: '32(static)',
        type: ItemType.NORMAL
      },
      {
        title: 'VIRTUAL MEETING BOOKED',
        value: '13(static)',
        type: ItemType.NORMAL
      },
      {
        title: 'PRESENTATION CENTER APPOINMENTS BOOKED',
        value: '5(static)',
        type: ItemType.NORMAL
      }
    ]
  },
  {
    title: 'All Time\'s Leads By Stage - All Sales Reps',
    type: ItemType.NORMAL,
    date: `${moment().format('MMMM DD')}th ${moment().format('YYYY')}`,
    data: [
      {
        title: 'LEAD',
        value: 267,
        type: ItemType.NORMAL
      },
      {
        title: 'LOST',
        value: 17,
        type: ItemType.NORMAL
      },
      {
        title: 'PROSPECT - 2 WAY COMMUNICATION',
        value: 14,
        type: ItemType.NORMAL
      },
      {
        title: 'PROSPECT WITH OPPORTUNITY',
        value: 5,
        type: ItemType.NORMAL
      },
      {
        title: 'RESERVATION HOLDER',
        value: 0,
        type: ItemType.NORMAL
      },
      {
        title: 'CONTRACT',
        value: 0,
        type: ItemType.NORMAL
      },
      {
        title: 'OWNER',
        value: 0,
        type: ItemType.NORMAL
      }
    ]
  },
  {
    title: 'All Time\'s Prospect Rating - All Sales Reps (static)',
    type: ItemType.GRAPH,
    date: `${moment().format('MMMM DD')}th ${moment().format('YYYY')}`,
    data: [
      { name: 'A', value: 6 },
      { name: 'B', value: 16 },
      { name: 'C', value: 24 },
      { name: 'D', value: 56 },
      { name: 'Unrated', value: 249 }
    ]
  }
]

export const DataForRickDavis = [
  {
    title: 'Today\'s Summary - Rick Davis',
    type: ItemType.NORMAL,
    date: `${moment().format('MMMM DD')}th ${moment().format('YYYY')}`,
    data: [
      {
        title: 'NEW LEADS',
        value: 75,
        type: ItemType.NORMAL
      },
      {
        title: 'BUYER NEW LEADS',
        value: 45,
        type: ItemType.NORMAL
      },
      {
        title: 'BROKER NEW LEAD',
        value: 25,
        type: ItemType.NORMAL
      },
      {
        title: 'PROSPECT - 2 WAY COMMUNICATIONS',
        value: 16,
        type: ItemType.NORMAL
      },
      {
        value: {
          data: [
            {
              title: 'FEMALE',
              value: 7 ?? 0,
              progressColor: '#2E2B2E',
              progressBackgroundColor: 'inherit'
            },
            {
              title: 'MALE',
              value: 3 ?? 0,
              progressColor: '#EDDF65',
              progressBackgroundColor: 'inherit'
            }
          ],
          total: 10
        },
        type: ItemType.GRAPH
      },
      {
        title: 'OPPORTUNITIES',
        value: 5,
        type: ItemType.NORMAL
      },
      {
        title: 'RESERVATIONS',
        value: 0,
        type: ItemType.NORMAL
      },
      {
        title: 'CONTRACTS',
        value: 0,
        type: ItemType.NORMAL
      }
    ]
  },
  {
    title: 'Today\'s Leads By Source - Rick Davis',
    type: ItemType.NORMAL,
    date: `${moment().format('MMMM DD')}th ${moment().format('YYYY')}`,
    data: [
      {
        title: 'WEBSITE FORM',
        value: 5,
        type: ItemType.NORMAL
      },
      {
        title: 'LIST IMPORT',
        value: 42,
        type: ItemType.NORMAL
      },
      {
        title: 'BROKER REFERRAL',
        value: 14,
        type: ItemType.NORMAL
      },
      {
        title: 'WALK IN',
        value: 7,
        type: ItemType.NORMAL
      }
    ]
  },
  {
    title: 'Today\'s Leads By Stage - Rick Davis',
    type: ItemType.NORMAL,
    date: `${moment().format('MMMM DD')}th ${moment().format('YYYY')}`,
    data: [
      {
        title: 'LEAD',
        value: 267,
        type: ItemType.NORMAL
      },
      {
        title: 'LOST',
        value: 17,
        type: ItemType.NORMAL
      },
      {
        title: 'PROSPECT - 2 WAY COMMUNICATION',
        value: 14,
        type: ItemType.NORMAL
      },
      {
        title: 'PROSPECT WITH OPPORTUNITY',
        value: 5,
        type: ItemType.NORMAL
      },
      {
        title: 'RESERVATION HOLDER',
        value: 0,
        type: ItemType.NORMAL
      },
      {
        title: 'CONTRACT',
        value: 0,
        type: ItemType.NORMAL
      },
      {
        title: 'OWNER',
        value: 0,
        type: ItemType.NORMAL
      }
    ]
  },
  {
    title: 'Today\'s Prospect Rating - Rick Davis',
    type: ItemType.GRAPH,
    date: `${moment().format('MMMM DD')}th ${moment().format('YYYY')}`,
    data: [
      { name: 'A', value: 6 },
      { name: 'B', value: 16 },
      { name: 'C', value: 24 },
      { name: 'D', value: 56 },
      { name: 'Unrated', value: 249 }
    ]
  }
]
