import { isArray } from 'lodash'
import { getRandomInteger } from './randomInteger'

export const createUploadFileList = (src, returnAsArray = true) => {
  const baseObject = ({ element, index }) => {
    return {
      uid: `image${index}`,
      name: `image${index}`,
      status: 'done',
      url: element
    }
  }

  if (returnAsArray) {
    if (isArray(src)) {
      return src.map((element, index) => {
        return baseObject({ element, index })
      })
    } else {
      return [baseObject({ element: src, index: getRandomInteger() })]
    }
  } else {
    return baseObject({ element: src, index: getRandomInteger() })
  }
}
