/* eslint-disable */
// @ts-nocheck
import React, { useContext, useEffect } from "react";
import { Box, Button } from "@material-ui/core";
import AddForm from "./AddForm";
import Table from "./Table";
import ConfirmDelete from "./confirmDelete";
import { useGetCorporateDemandNotes } from "hooks/useGetCorporateDemandNotes";
import CustomButton from "components/CustomButton";

// import PageHeader from './PageHeader';

const CorporateDemandNotesDropdown = () => {
  const { data, isLoading, refetch } = useGetCorporateDemandNotes();
  const [open, setOpen] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [initialValues, setInitialValues] = React.useState({
    label: "",
    options: [],
    isMulti: false,
  });
  const [deleteModal, setDeleteModal] = React.useState(false);
  return (
    <div className="h-full w-full flex flex-col">
      <ConfirmDelete
        refetch={refetch}
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
        deleteNoteId={initialValues._id}
        setInitialValues={setInitialValues}
      />
      <div className="w-full p-5 flex flex-row items-center font-normal text-xl border-b-2 border-black">
        Corporate Demand Notes DropDown Settings
      </div>
      <div className="h-full w-full flex flex-row">
        <Box display="flex" padding="20px" flex={0.6}>
          <Table
            setDeleteModal={setDeleteModal}
            deleteModal={deleteModal}
            data={data}
            setIsEdit={setIsEdit}
            setInitialValues={setInitialValues}
          />
        </Box>
        <div className="mt-12 max-w-[200px]">
          <CustomButton
            handleClick={() => {
              setOpen(!open);
            }}
          >
            <span className="font-medium py-2 px-4 text-base">Create New</span>
          </CustomButton>
        </div>
        {(open || isEdit) && (
          <AddForm
            initialValues={initialValues}
            setInitialValues={setInitialValues}
            open={open || isEdit}
            setOpen={setOpen}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            refetch={refetch}
          />
        )}
      </div>
    </div>
  );
};

export default CorporateDemandNotesDropdown;
