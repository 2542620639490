import { useQuery } from '@tanstack/react-query'
import { getUsersByParam } from 'store/actions/usersActions'
import useProjectId from './useProjectId'
import useUser from './useUser'

const useGetUsersByParam = (paramName, paramId, userType, dataType) => {
  const { user } = useUser()
  const { projectId } = useProjectId()
  const { data, isFetching } = useQuery(['usersByParam', paramName, paramId, userType, dataType],
    ({ queryKey }) => getUsersByParam(queryKey[1], queryKey[2], queryKey[3], queryKey[4]),
    { refetchOnReconnect: false, refetchOnMount: false, refetchOnWindowFocus: false, enabled: !!user?._id && !!projectId && !!paramId && !!paramName && !!userType && !!dataType })
  return { usersByParam: data, isFetchingUsersByParam: isFetching }
}

export default useGetUsersByParam
