import { motion } from 'framer-motion'
import styled from 'styled-components'

export const ProjectWrapper = styled(motion.div)`
  height: 100vh;
  width: 100vw;
  position: relative;
  background-position: center;
  background-size: cover;

  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: center;
`

export const CarouselContentWrapper = styled.div`
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
`

export const CustomArrowIconWrapper = styled.div`
  position: absolute;
  font-size: 50px;
  top: calc(50% - 25px);
  z-index: 10;
  color: white;

  :hover {
    cursor: pointer;
  }
`
