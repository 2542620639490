import React, { useMemo, useState } from 'react'
import { ChevronDownIcon } from '@heroicons/react/solid'

const AccordionItem = ({ item: { _id, title } }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div
      key={_id}
      className={`flex flex-col justify-start items-start rounded-lg overflow-hidden w-full border border-[#2E2B2E] border-opacity-[15%] ${isOpen ? 'shadow' : ''}`}
    >
      <div
        onClick={() => setIsOpen(!isOpen)}
        className='bg-[#F4F4F4] w-full flex justify-between items-center p-5 cursor-pointer'
      >
        <div className='flex items-center flex-wrap gap-2'>
          <input
            className='w-6 h-6 rounded-sm'
            type='checkbox'
          />
          <span className='text-[#2E2B2E] text-xl font-medium leading-none'>
            {title}
          </span>
        </div>
        <ChevronDownIcon className={`h-5 w-5 transition-all ${isOpen ? 'rotate-180' : ''}`} />
      </div>
      {
        isOpen &&
        <div className='self-stretch p-5 bg-white flex-col justify-start items-start gap-4 flex'>
          <p className='self-stretch text-softBlack_70/100 text-base font-normal leading-normal m-0'>Please review the document below for approval</p>
          <p className='self-stretch text-softBlack_70/100 text-base font-normal leading-normal m-0'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
          <div className='self-stretch pt-4 justify-end items-start gap-3 inline-flex'>
            <button className='px-4 py-2 rounded border border-softBlack_70/100 border-opacity-70 justify-start items-center gap-2 flex'>
              <svg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <g clipPath='url(#clip0_100_332)'>
                  <path d='M3.25033 10.4999C3.25033 9.07492 4.40866 7.91658 5.83366 7.91658H9.16699V6.33325H5.83366C3.53366 6.33325 1.66699 8.19992 1.66699 10.4999C1.66699 12.7999 3.53366 14.6666 5.83366 14.6666H9.16699V13.0833H5.83366C4.40866 13.0833 3.25033 11.9249 3.25033 10.4999ZM6.66699 11.3333H13.3337V9.66659H6.66699V11.3333ZM14.167 6.33325H10.8337V7.91658H14.167C15.592 7.91658 16.7503 9.07492 16.7503 10.4999C16.7503 11.9249 15.592 13.0833 14.167 13.0833H10.8337V14.6666H14.167C16.467 14.6666 18.3337 12.7999 18.3337 10.4999C18.3337 8.19992 16.467 6.33325 14.167 6.33325Z' fill='#2E2B2E' />
                </g>
                <defs>
                  <clipPath id='clip0_100_332'>
                    <rect width='20' height='20' fill='white' transform='translate(0 0.5)' />
                  </clipPath>
                </defs>
              </svg>
              <span className='text-center text-softBlack_70/100 text-sm font-medium leading-[21px]'>Share document</span>
            </button>
            <button className='px-4 py-2 rounded border border-softBlack_70/100 border-opacity-70 justify-start items-center gap-2 flex'>
              <svg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <g clipPath='url(#clip0_1_2680)'>
                  <path d='M7.50033 7.16667H12.5003V12.1667H15.8337L10.0003 18L4.16699 12.1667H7.50033V7.16667ZM4.16699 5.5H15.8337V3.83333H4.16699V5.5Z' fill='#323232' />
                </g>
                <defs>
                  <clipPath id='clip0_1_2680'>
                    <rect width='20' height='20' fill='white' transform='matrix(1 0 0 -1 0 20.5)' />
                  </clipPath>
                </defs>
              </svg>
              <span className='text-center text-softBlack_70/100 text-sm font-medium leading-[21px]'>Download document</span>
            </button>
            <button className='self-stretch px-4 py-2 bg-volt rounded justify-start items-center gap-2 flex'>
              <svg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <g clipPath='url(#clip0_100_154)'>
                  <path d='M7.49994 14L4.58327 11.0833C4.25827 10.7583 3.7416 10.7583 3.4166 11.0833C3.0916 11.4083 3.0916 11.925 3.4166 12.25L6.90827 15.7416C7.23327 16.0666 7.75827 16.0666 8.08327 15.7416L16.9166 6.91664C17.2416 6.59164 17.2416 6.07498 16.9166 5.74998C16.5916 5.42498 16.0749 5.42498 15.7499 5.74998L7.49994 14Z' fill='#2E2B2E' />
                </g>
                <defs>
                  <clipPath id='clip0_100_154'>
                    <rect width='20' height='20' fill='white' transform='translate(0 0.5)' />
                  </clipPath>
                </defs>
              </svg>
              <span className='text-center text-softBlack_70/100 text-sm font-medium leading-[21px]'>Approve</span>
            </button>
          </div>
        </div>
      }
    </div>
  )
}

const AccordionWrapper = ({ items }) => {
  return (
    <>
      {
        items.length &&
        <div className='w-full flex-col justify-start items-start gap-3.5 inline-flex'>
          {
            items.map((item) => <AccordionItem item={item} />)
          }
        </div>
      }
    </>
  )
}

const ComplianceTab = () => {
  const getComplianceData = useMemo(() => {
    return [
      {
        _id: 'approveDraft',
        title: 'Approve draft'
      },
      {
        _id: 'approveSignedContracts',
        title: 'Approve signed contracts'
      },
      {
        _id: 'kycAndBackgroundCheck',
        title: 'KYC and background check'
      }
    ]
  }, [])

  return (
    <div className='w-full'>
      <AccordionWrapper items={getComplianceData} />
    </div>
  )
}

export default ComplianceTab
