/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback, useRef } from 'react'
import { connect } from 'react-redux'
import { ChromePicker } from 'react-color'
import propTypes from 'prop-types'
import { InlineInput, InputLabel, Modal, Table, Tabs } from 'ui'
import {
  FadedAnimatedDiv,
  FormTabsWrapper,
  FormWrapper,
  GridFormWrapper,
  ImageUpload
} from 'components'

import {
  createUploadFileList,
  formatMoney,
  getRandomInteger,
  validateRequiredInputs
} from 'utils'
import { TSaveButton } from 'components/DefaultButtons'
import { getDevelopers } from 'store/actions/developersActions'
import { getContractTemplates } from 'store/actions/contractTemplateActions'
import {
  changeProjectImages,
  createOrUpdateProject,
  deleteProjectImage,
  deleteProjectOption,
  getProjects
} from 'store/actions/projectActions'
import { isArray } from 'lodash'
import { message } from 'antd'
import {
  LogoAndBackgroundImageWrapper,
  LogoImageWrapper,
  BackgroundImageWrapper,
  ProjectImagesWrapper,
  PanelContentWrapper
} from './styles'
import { ImageIcon } from 'components/Icons'
import ProjectOptionData from 'components/ProjectOptionData'
import { AnimatePresence } from 'framer-motion'

const PARKING = 'parking'
const COLOR = 'color'
const ADDITIONAL_OPTIONS = 'additionalOptions'
const PAYMENT_ROUNDS = 'paymentRounds'
const DOCUMENTS = 'documents'

const optionsColumns = [
  {
    Title: 'Name',
    accessor: 'name',
    show: false
  },
  {
    Title: 'Description',
    accessor: 'description'
  },
  {
    Title: 'Value',
    accessor: 'value',
    Cell: ({
      row: {
        original: { value, currencyType }
      }
    }) => formatMoney(value, currencyType)
  }
]

const paymentRoundColumns = [
  {
    Title: 'Days',
    accessor: 'days'
  },
  {
    Title: 'Percent %',
    accessor: 'perc'
  }
]

const TabHeader = ({ title, subtitle, extraContent }) => (
  <div className='space-y-1 flex flex-row justify-between items-center'>
    <div className='flex flex-col'>
      <h3 className='text-lg leading-6 font-medium text-gray-900'>
        {title && title}
      </h3>
      <p className='max-w-2xl text-sm text-gray-500'>{subtitle && subtitle}</p>
    </div>
    {extraContent}
  </div>
)

const TabContent = ({ children, className }) => (
  <div className={`mt-6 ${className}`}>
    <dl className='divide-y divide-gray-200'>{children}</dl>
  </div>
)

const Upload = ({
  title,
  name,
  buttonText,
  width,
  showUploadButton,
  showUploadList,
  uploadProps
}) => (
  <ImageUpload
    uploadProps={{
      ...uploadProps,
      name: name,
      listType: 'picture-card',
      className: 'avatar-uploader',
      showUploadList: showUploadList
    }}
    title={title}
    uploadButtonText={buttonText}
    uploadButtonStyle={{ height: 100 }}
    uploadButtonIcon={<ImageIcon style={{ fontSize: 30 }} />}
    uploadWrapperStyle={{ width: width }}
    showUploadButton={showUploadButton}
  />
)

const ProjectData = (props) => {
  const {
    _id,
    userObject: { userType: loggedUserType }
  } = props

  const [localProjectId, setLocalProjectId] = useState('')
  const [projectName, setProjectName] = useState('')
  const [websiteUrl, setwebsiteUrl] = useState('')
  const [city, setCity] = useState('')
  const [province, setProvince] = useState('')
  const [country, setCountry] = useState('')
  const [totalDollarValue, setTotalDollarValue] = useState('')
  const [totalDollarValueCurrencyType, setTotalDollarValueCurrencyType] =
    useState('')
  const [numberOfUnits, setNumberOfUnits] = useState('')
  const [developer, setDeveloper] = useState('')
  const [reservationMaxDays, setReservationMaxDays] = useState('')
  const [projectBackgroundColor, setProjectBackgroundColor] = useState('')
  const [originalProjectBackgroundColor, setOriginalProjectBackgroundColor] =
    useState('')
  const [logoImage, setLogoImage] = useState([])
  const [backgroundImage, setBackgroundImage] = useState([])
  const [options, setOptions] = useState([])
  const [projectImages, setProjectImages] = useState([])
  const [developersData, setDevelopersData] = useState([])
  const [gettingData, setGettingData] = useState(false)
  const [gettingDevelopersData, setGettingDevelopersData] = useState(false)
  const [paymentRounds, setPaymentRounds] = useState([])
  const [colorSchemes, setColorSchemes] = useState([])
  const [parkings, setParkings] = useState([])
  const [additionalOptions, setAdditionalOptions] = useState([])
  const [contracts, setContracts] = useState([])
  const [gettingContracts, setGettingContracts] = useState(false)
  const [contractsData, setContractsData] = useState([])
  const [selectedOptionType, setSelectedOptionType] = useState('')
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [entityNameBeingDeleted, setEntityNameBeingDeleted] = useState('')
  const [idBeingDeleted, setIdBeingDeleted] = useState('')
  const [deleting, setDeleting] = useState(false)
  const [showManagePaymentRoundModal, setShowManagePaymentRoundModal] =
    useState(false)
  const [paymentRoundId, setPaymentRoundId] = useState('')
  const [paymentRoundDays, setPaymentRoundDays] = useState('')
  const [paymentRoundPerc, setPaymentRoundPerc] = useState('')
  const [managingPaymentRound, setManagingPaymentRound] = useState(false)
  const [showColorPickerModal, setShowColorPickerModal] = useState(false)
  const [showMustHaveProjectCreatedModal, setShowMustHaveProjectCreatedModal] =
    useState(false)
  const [brokerMaxReservationDays, setBrokerMaxReservationDays] = useState(0)
  const [signerName, setSignerName] = useState('')
  const [signerEmail, setSignerEmail] = useState('')

  const [selectedTab, setSelectedTab] = useState('general')
  const [saving, setSaving] = useState(false)

  const [optionId, setOptionId] = useState('')

  const [creatingEditingOption, setCreatingEditingOption] = useState(false)
  const [creatingEditingDocument, setCreatingEditingDocument] = useState(false)

  const paymentRoundsRef = useRef(paymentRounds)

  const updatePaymentRounds = (newState) => {
    paymentRoundsRef.current = newState
    setPaymentRounds(newState)
  }

  useEffect(() => {
    if (_id) {
      setLocalProjectId(_id)
    }
  }, [_id])

  // When mounted
  useEffect(() => {
    const fetchData = async () => {
      setGettingDevelopersData(true)
      setGettingContracts(true)

      const tmpDevelopers = await getDevelopers('')
      const tmpContracts = await getContractTemplates(_id)

      // Get developers and map it to fill the select components for the user
      const tmpDevelopersData = tmpDevelopers.map((developer) => ({
        id: developer._id,
        value: developer.companyName
      }))
      setDevelopersData(tmpDevelopersData)
      setGettingDevelopersData(false)

      // Get contracts and map it to fill the select components for the user
      const tmpContractsData = tmpContracts.map((contract) => ({
        id: contract._id,
        value: contract.name
      }))
      setContractsData(tmpContractsData)
      setGettingContracts(false)
    }
    if (_id?.length > 0) fetchData()
  }, [_id])

  const getProjectData = useCallback(() => {
    if (localProjectId) {
      setGettingData(true)

      // Get project data from id
      getProjects(localProjectId).then((project) => {
        const {
          projectName,
          websiteUrl,
          city,
          province,
          country,
          totalDollarValue,
          totalDollarValueCurrencyType,
          numberOfUnits,
          paymentRounds,
          options,
          logoImage,
          backgroundImage,
          images,
          developer,
          contracts,
          reservationMaxDays,
          projectBackgroundColor,
          brokerMaxReservationDays,
          signer
        } = project

        const tmpOptions = options
          ? options.map((option) => ({
            ...option,
            currencyType: totalDollarValueCurrencyType
          }))
          : []

        if (logoImage) {
          const tmpLogoImage = createUploadFileList(logoImage)
          setLogoImage(tmpLogoImage)
        }

        if (backgroundImage) {
          const tmpBackgroundImage = createUploadFileList(backgroundImage)
          setBackgroundImage(tmpBackgroundImage)
        }

        if (images) {
          const tmpProjectImages = createUploadFileList(images)
          setProjectImages(tmpProjectImages)
        }

        if (signer && Object.keys(signer).length) {
          const { name, email } = signer
          setSignerName(name)
          setSignerEmail(email)
        }

        setProjectName(projectName)
        setwebsiteUrl(websiteUrl)
        setCity(city)
        setProvince(province)
        setCountry(country)
        setTotalDollarValue(totalDollarValue)
        setTotalDollarValueCurrencyType(totalDollarValueCurrencyType)
        setNumberOfUnits(numberOfUnits)
        setDeveloper(developer._id)
        setReservationMaxDays(reservationMaxDays)
        setBrokerMaxReservationDays(brokerMaxReservationDays)
        setGettingData(false)
        updatePaymentRounds(paymentRounds)
        setOptions(tmpOptions)
        setContracts(contracts)
        setProjectBackgroundColor(projectBackgroundColor)
        setOriginalProjectBackgroundColor(projectBackgroundColor)
      })
    } else {
      setGettingData(false)
    }
  }, [localProjectId])

  useEffect(() => {
    getProjectData()
  }, [getProjectData])

  useEffect(() => {
    if (isArray(options)) {
      const tmpParkings = options.filter((option) => option.type === PARKING)

      const tmpColorSchemes = options.filter((option) => option.type === COLOR)

      const tmpAdditionalOptions = options.filter(
        (option) => option.type === ADDITIONAL_OPTIONS
      )

      setParkings(tmpParkings)
      setColorSchemes(tmpColorSchemes)
      setAdditionalOptions(tmpAdditionalOptions)
    }
  }, [options])

  const _managePaymentRounds = useCallback(
    () =>
      new Promise((resolve) => {
        const object = {
          _id: paymentRoundId || getRandomInteger(),
          days: paymentRoundDays,
          perc: paymentRoundPerc
        }

        const tmpPaymentRounds = [...paymentRoundsRef.current]

        // Check if the array of payments already has this payment round
        const paymentIndex = tmpPaymentRounds.findIndex(
          (payment) => payment._id === object._id
        )

        // If show, replace the object with the new one edited
        if (~paymentIndex) {
          tmpPaymentRounds[paymentIndex] = object
        } else {
          tmpPaymentRounds.push(object)
        }

        updatePaymentRounds(tmpPaymentRounds)

        resolve()
      }),
    [paymentRoundDays, paymentRoundId, paymentRoundPerc]
  )

  const deletePaymentRound = useCallback(
    () =>
      new Promise((resolve) => {
        const tmpPaymentRounds = paymentRoundsRef.current.filter(
          (paymentRound) => paymentRound._id !== idBeingDeleted
        )

        updatePaymentRounds(tmpPaymentRounds)
        resolve()
      }),
    [idBeingDeleted]
  )

  const _onSaveClick = useCallback(
    () =>
      new Promise((resolve, reject) => {
        if (reservationMaxDays < 1 || reservationMaxDays > 14) {
          message.warning(
            'Days to keep unit Reserved must be between 1 day to 14 days'
          )
          reject()
          return
        }

        if (brokerMaxReservationDays < 1 || brokerMaxReservationDays > 14) {
          message.warning(
            'Days to keep unit Reserved must be between 1 day to 14 days'
          )
          reject()
          return
        }

        // Filter the paymentRounds before calling the API
        const tmpPaymentRounds = paymentRoundsRef.current.map(
          (paymentRound) => {
            const tmpPaymentRound = { ...paymentRound }
            // Check if it's a temporary ID
            if (
              tmpPaymentRound._id &&
              String(tmpPaymentRound._id).length < 24
            ) {
              delete tmpPaymentRound._id
            }

            return tmpPaymentRound
          }
        )

        validateRequiredInputs('createProject').then(() => {
          setSaving(true)

          const params = {
            _id: localProjectId,
            projectName,
            websiteUrl,
            city,
            province,
            country,
            totalDollarValue,
            totalDollarValueCurrencyType,
            numberOfUnits,
            developer,
            paymentRounds: tmpPaymentRounds,
            contracts,
            reservationMaxDays,
            brokerMaxReservationDays,
            projectBackgroundColor,
            signer: {
              name: signerName,
              email: signerEmail
            }
          }

          createOrUpdateProject({
            params,
            extraData: {
              logoImage,
              backgroundImage,
              projectImages
            }
          })
            .then((projectData) => {
              setSaving(false)
              resolve(projectData)
            })
            .catch(() => {
              setSaving(false)
              getProjectData()
              reject()
            })
        })
      }),
    [
      localProjectId,
      backgroundImage,
      city,
      contracts,
      country,
      developer,
      getProjectData,
      logoImage,
      numberOfUnits,
      projectBackgroundColor,
      projectImages,
      projectName,
      province,
      reservationMaxDays,
      totalDollarValue,
      totalDollarValueCurrencyType,
      websiteUrl,
      brokerMaxReservationDays,
      signerName,
      signerEmail
    ]
  )

  const renderOption = useCallback(
    ({ dataSource, columns, onEditClick, paymentRoundOption = false }) => {
      return (
        <PanelContentWrapper>
          <Table
            loading={gettingData}
            className='rounded-lg'
            dataSource={dataSource}
            columns={columns}
            tailwindTable
            onViewEditClick={({ original: { _id, days, perc } }) => {
              setOptionId(_id)
              onEditClick({ _id, days, perc })
            }}
            deleting={deleting}
            idBeingDeleted={idBeingDeleted}
            onDeleteClick={({ original: { _id, name, days, perc } }) => {
              setIdBeingDeleted(_id)
              if (!paymentRoundOption) {
                setEntityNameBeingDeleted(name)
              } else {
                setEntityNameBeingDeleted('Payment round')
              }

              setShowDeleteModal(true)
            }}
          />
        </PanelContentWrapper>
      )
    },
    [gettingData, deleting, idBeingDeleted]
  )

  const renderGeneralTab = useCallback(
    () => (
      <div className={`${selectedTab !== 'general' && 'sr-only'}`}>
        <TabHeader
          title='General'
          subtitle='Here are all infos related to the project'
        />
        <TabContent>
          <InlineInput
            id='projectName'
            key='projectName'
            type='text'
            label='Project name'
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
            placeholder={"first, the project's name"}
            required
            validateKey='createProject'
            loading={gettingData}
            onClickUpdate={localProjectId ? _onSaveClick : null}
          />
          <InlineInput
            id='websiteUrl'
            name='websiteUrl'
            type='text'
            label={"Project's Website URL"}
            value={websiteUrl}
            onChange={(e) => setwebsiteUrl(e.target.value)}
            placeholder={"the project's website"}
            required
            validateKey='createProject'
            loading={gettingData}
            onClickUpdate={localProjectId ? _onSaveClick : null}
          />
          <InlineInput
            id='city'
            name='city'
            type='text'
            label='City'
            value={city}
            onChange={(e) => setCity(e.target.value)}
            placeholder='now, the city of the project'
            required
            validateKey='createProject'
            loading={gettingData}
            onClickUpdate={localProjectId ? _onSaveClick : null}
          />
          <InlineInput
            id='province'
            name='province'
            type='text'
            label='Province'
            value={province}
            onChange={(e) => setProvince(e.target.value)}
            placeholder='and province'
            required
            validateKey='createProject'
            loading={gettingData}
            onClickUpdate={localProjectId ? _onSaveClick : null}
          />
          <InlineInput
            id='country'
            name='country'
            type='text'
            label='Country'
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            placeholder='the country'
            required
            validateKey='createProject'
            loading={gettingData}
            onClickUpdate={localProjectId ? _onSaveClick : null}
          />
          <InlineInput
            id='totalDollarValue'
            name='totalDollarValue'
            type='tel'
            label='Total dollar value'
            value={totalDollarValue}
            onChange={(e) => setTotalDollarValue(e.value)}
            placeholder={"project's total value"}
            required
            validateKey='createProject'
            typeInput='numberinput'
            loading={gettingData}
            prefix={totalDollarValueCurrencyType}
            thousandSeparator
            onClickUpdate={localProjectId ? _onSaveClick : null}
          />
          <InlineInput
            id='totalDollarValueCurrencyType'
            name='totalDollarValueCurrencyType'
            type='text'
            label='Currency type'
            value={totalDollarValueCurrencyType}
            onChange={(e) => setTotalDollarValueCurrencyType(e.target.value)}
            placeholder='currency type'
            required
            validateKey='createProject'
            loading={gettingData}
            onClickUpdate={localProjectId ? _onSaveClick : null}
          />
          <InlineInput
            id='numberOfUnits'
            name='numberOfUnits'
            type='text'
            label='Number of units'
            value={numberOfUnits}
            onChange={(e) => setNumberOfUnits(e.target.value)}
            placeholder='how many units?'
            required
            validateKey='createProject'
            loading={gettingData}
            onClickUpdate={localProjectId ? _onSaveClick : null}
          />
          <InlineInput
            id='developer'
            name='developer'
            type='text'
            label='Developer'
            value={developer}
            onChange={(id) => setDeveloper(id)}
            placeholder={"project's developer"}
            loading={gettingData || gettingDevelopersData}
            typeInput='select'
            data={developersData}
            required
            validateKey='createProject'
            onClickUpdate={localProjectId ? _onSaveClick : null}
          />
          <InlineInput
            id='reservationMaxDays'
            name='reservationMaxDays'
            type='tel'
            label='Days to keep unit Reserved'
            value={reservationMaxDays}
            onChange={(e) => setReservationMaxDays(e.value)}
            placeholder='how many days will the unit be kept as reserved?'
            required
            validateKey='createProject'
            loading={gettingData}
            hintMessage='Buyer can ask Sales Rep to reserve a unit. Here you can configure
          how many days the unit will be kept as Reserved. (From 1 day to 14 days)'
            typeInput='numberinput'
            onClickUpdate={localProjectId ? _onSaveClick : null}
          />
          <InlineInput
            id='brokerReservationMaxDays'
            name='brokerReservationMaxDays'
            type='tel'
            label='Inventory allocation days'
            value={brokerMaxReservationDays}
            onChange={(e) => setBrokerMaxReservationDays(e.value)}
            placeholder='how many days will the unit be kept as reserved at the Inventory Allocation?'
            required
            validateKey='createProject'
            loading={gettingData}
            hintMessage='Brokers can hold the Inventory. Here you can configure
          how many days the unit will be kept as Reserved. (From 1 day to 14 days)'
            typeInput='numberinput'
            onClickUpdate={localProjectId ? _onSaveClick : null}
          />
          <InlineInput
            id='projectBackgroundColor'
            name='projectBackgroundColor'
            type='tel'
            label='Project Background Color'
            value={projectBackgroundColor}
            onChange={(e) => setProjectBackgroundColor(e.value)}
            placeholder='what is the Project Background Color?'
            required
            validateKey='createProject'
            loading={gettingData}
            inputBackgroundColor={projectBackgroundColor}
            onClick={() => setShowColorPickerModal(!showColorPickerModal)}
            onClickUpdate={localProjectId ? _onSaveClick : null}
          />
          <InlineInput
            id='projectContracts'
            name='projectContracts'
            type='text'
            label='Contracts'
            value={!contracts ? [] : contracts}
            onChange={(id) => setContracts(id)}
            placeholder='what are the contracts of this project?'
            loading={gettingContracts || gettingData}
            typeInput='select'
            data={contractsData}
            mode='multiple'
            onClickUpdate={localProjectId ? _onSaveClick : null}
          />
          <InlineInput
            id='signerName'
            name='signerName'
            type='text'
            label='Signer full name'
            value={signerName}
            onChange={(e) => setSignerName(e.target.value)}
            placeholder='Fill here who is the responsible to sign the Offer documents'
            loading={gettingData}
            onClickUpdate={localProjectId ? _onSaveClick : null}
          />
          <InlineInput
            id='signerEmail'
            name='signerEmail'
            type='text'
            label='Signer email'
            value={signerEmail}
            onChange={(e) => setSignerEmail(e.target.value)}
            placeholder='What is the email of the Signer?'
            loading={gettingData}
            onClickUpdate={localProjectId ? _onSaveClick : null}
          />
        </TabContent>
      </div>
    ),
    [
      localProjectId,
      _onSaveClick,
      city,
      contracts,
      contractsData,
      country,
      developer,
      developersData,
      showColorPickerModal,
      gettingContracts,
      gettingData,
      gettingDevelopersData,
      numberOfUnits,
      projectBackgroundColor,
      projectName,
      province,
      reservationMaxDays,
      selectedTab,
      totalDollarValue,
      totalDollarValueCurrencyType,
      websiteUrl,
      brokerMaxReservationDays,
      signerName,
      signerEmail
    ]
  )

  const renderProjectImagesTab = useCallback(
    () => (
      <div className={`${selectedTab !== 'projectImages' && 'sr-only'}`}>
        <TabHeader
          title='Images'
          subtitle='You can set the images of the project here'
        />
        <TabContent>
          <LogoAndBackgroundImageWrapper className='w-full flex flex-col md:flex-row'>
            <LogoImageWrapper className='w-full mr-0 md:mr-3 md:w-3/6'>
              {Upload({
                title: "Project's Logo",
                name: 'logoUpload',
                buttonText: "Click or drag here the Project's Logo",
                width: '100%',
                showUploadButton: logoImage.length <= 0,
                uploadProps: {
                  fileList: logoImage,
                  showUploadList: logoImage.length > 0,
                  onChange: ({ fileList }) => {
                    setLogoImage(fileList)
                  },
                  beforeUpload: () => {
                    if (!localProjectId) {
                      return false
                    }
                  },
                  customRequest: (componentData) => {
                    if (localProjectId) {
                      const { file } = componentData
                      changeProjectImages({
                        projectId: localProjectId,
                        logoImage: file
                      })
                        .then(() => {
                          getProjectData(localProjectId)
                        })
                        .catch(() => {})
                    }
                  }
                }
              })}
            </LogoImageWrapper>
            <BackgroundImageWrapper className='w-full ml-0 md:ml-3 md:w-3/6'>
              {Upload({
                title: "Project's Background",
                name: 'backgroundUpload',
                buttonText: "Click or drag here the Project's Background",
                width: '100%',
                showUploadButton: backgroundImage.length <= 0,
                uploadProps: {
                  fileList: backgroundImage,
                  showUploadList: backgroundImage.length > 0,
                  onChange: ({ fileList }) => {
                    setBackgroundImage(fileList)
                  },
                  beforeUpload: () => {
                    if (!localProjectId) {
                      return false
                    }
                  },
                  customRequest: (componentData) => {
                    if (localProjectId) {
                      const { file } = componentData
                      changeProjectImages({
                        projectId: localProjectId,
                        backgroundImage: file
                      })
                        .then(() => {
                          getProjectData(localProjectId)
                        })
                        .catch(() => {})
                    }
                  }
                }
              })}
            </BackgroundImageWrapper>
          </LogoAndBackgroundImageWrapper>
          <ProjectImagesWrapper className='w-full mt-3'>
            {Upload({
              title: "Project's Images (at least seven images)",
              name: 'projectImagesUpload',
              buttonText: "Click or drag here the Project's Images",
              width: '100%',
              showUploadButton: true,
              uploadProps: {
                fileList: projectImages,
                showUploadList: projectImages.length > 0,
                multiple: true,
                onChange: ({ fileList }) => {
                  setProjectImages(fileList)
                },
                beforeUpload: () => {
                  if (!localProjectId) {
                    return false
                  }
                },
                customRequest: (componentData) => {
                  if (localProjectId) {
                    const { file } = componentData
                    changeProjectImages({
                      projectId: localProjectId,
                      images: file
                    })
                      .then(() => {
                        getProjectData(localProjectId)
                      })
                      .catch(() => {})
                  }
                },
                onRemove: (file) => {
                  if (localProjectId) {
                    deleteProjectImage({
                      projectId: localProjectId,
                      url: file.url
                    })
                      .then(() => {
                        getProjectData(localProjectId)
                      })
                      .catch(() => {})
                  }
                }
              }
            })}
          </ProjectImagesWrapper>
        </TabContent>
      </div>
    ),
    [
      selectedTab,
      backgroundImage,
      getProjectData,
      logoImage,
      localProjectId,
      projectImages
    ]
  )

  const renderProjectOptionData = useCallback(
    (validationAux, saveButtonText) => {
      return (
        <ProjectOptionData
          _id={optionId}
          projectId={localProjectId}
          optionType={selectedOptionType}
          validationAux={validationAux}
          saveButtonText={saveButtonText}
          currencyType={totalDollarValueCurrencyType}
          loading={saving}
          executeAfterSave={() => {
            getProjectData()
          }}
          executeOnBack={() => {
            setOptionId('')
            setCreatingEditingOption(false)
          }}
        />
      )
    },
    [
      localProjectId,
      optionId,
      totalDollarValueCurrencyType,
      selectedOptionType,
      getProjectData,
      saving
    ]
  )

  const CreateNewButton = useCallback(
    ({ optionName, onClick, className, optionType }) => {
      return (
        <TSaveButton
          onClick={() => {
            setSelectedOptionType(optionType)
            if (!localProjectId && optionType !== PAYMENT_ROUNDS) {
              setShowMustHaveProjectCreatedModal(true)
            } else {
              onClick()
            }
          }}
          className={className}
        >
          {`Create new ${optionName}`}
        </TSaveButton>
      )
    },
    [localProjectId]
  )

  const renderParkingTab = useCallback(
    () => (
      <div className={`${selectedTab !== 'parking' && 'sr-only'}`}>
        <AnimatePresence exitBeforeEnter initial={false}>
          {creatingEditingOption
            ? (
            <FadedAnimatedDiv key='creatingNewParking'>
              {renderProjectOptionData(PARKING, 'parking option')}
            </FadedAnimatedDiv>
              )
            : (
            <FadedAnimatedDiv key='parkingTable'>
              <TabHeader
                title='Parking'
                subtitle='Fill below all the available parking options for this project'
                extraContent={
                  <CreateNewButton
                    optionName='parking'
                    optionType={PARKING}
                    onClick={() => setCreatingEditingOption(true)}
                  />
                }
              />
              <TabContent>
                {renderOption({
                  dataSource: parkings,
                  columns: optionsColumns,
                  onEditClick: () => {
                    setSelectedOptionType(PARKING)
                    setCreatingEditingOption(true)
                  }
                })}
              </TabContent>
            </FadedAnimatedDiv>
              )}
        </AnimatePresence>
      </div>
    ),
    [
      selectedTab,
      parkings,
      renderOption,
      creatingEditingOption,
      renderProjectOptionData
    ]
  )

  const renderColorsTab = useCallback(
    () => (
      <div className={`${selectedTab !== 'colors' && 'sr-only'}`}>
        <AnimatePresence exitBeforeEnter initial={false}>
          {creatingEditingOption
            ? (
            <FadedAnimatedDiv key='creatingNewColorScheme'>
              {renderProjectOptionData(COLOR, 'color scheme')}
            </FadedAnimatedDiv>
              )
            : (
            <FadedAnimatedDiv key='parkingTable'>
              <TabHeader
                title='Color Schemes'
                subtitle='You can set here all the available color schemes'
                extraContent={
                  <CreateNewButton
                    optionName='color scheme'
                    optionType={COLOR}
                    onClick={() => setCreatingEditingOption(true)}
                  />
                }
              />
              <TabContent>
                {renderOption({
                  dataSource: colorSchemes,
                  columns: optionsColumns,
                  onEditClick: () => {
                    setSelectedOptionType(COLOR)
                    setCreatingEditingOption(true)
                  }
                })}
              </TabContent>
            </FadedAnimatedDiv>
              )}
        </AnimatePresence>
      </div>
    ),
    [
      selectedTab,
      colorSchemes,
      renderOption,
      creatingEditingOption,
      renderProjectOptionData
    ]
  )

  const renderUpgradeOptionsTab = useCallback(
    () => (
      <div className={`${selectedTab !== 'upgradeOptions' && 'sr-only'}`}>
        <AnimatePresence exitBeforeEnter initial={false}>
          {creatingEditingOption
            ? (
            <FadedAnimatedDiv key='creatingNewOption'>
              {renderProjectOptionData(
                ADDITIONAL_OPTIONS,
                'upgrade and option'
              )}
            </FadedAnimatedDiv>
              )
            : (
            <FadedAnimatedDiv key='optionsTable'>
              <TabHeader
                title='Upgrades and Options'
                subtitle='Does this project has some Upgrades and Options? Fill it here!'
                extraContent={
                  <CreateNewButton
                    optionName='upgrade and option'
                    optionType={ADDITIONAL_OPTIONS}
                    onClick={() => setCreatingEditingOption(true)}
                  />
                }
              />
              <TabContent>
                {renderOption({
                  dataSource: additionalOptions,
                  columns: optionsColumns,
                  onEditClick: () => {
                    setSelectedOptionType(ADDITIONAL_OPTIONS)
                    setCreatingEditingOption(true)
                  }
                })}
              </TabContent>
            </FadedAnimatedDiv>
              )}
        </AnimatePresence>
      </div>
    ),
    [
      selectedTab,
      additionalOptions,
      renderOption,
      creatingEditingOption,
      renderProjectOptionData
    ]
  )

  const renderPaymentRoundsTab = useCallback(
    () => (
      <div className={`${selectedTab !== 'paymentRounds' && 'sr-only'}`}>
        <AnimatePresence exitBeforeEnter initial={false}>
          {creatingEditingOption
            ? (
            <FadedAnimatedDiv key='creatingNewPaymentRound'>
              {renderProjectOptionData('PAYMENT_ROUNDS', 'payment round')}
            </FadedAnimatedDiv>
              )
            : (
            <FadedAnimatedDiv key='parkingTable'>
              <TabHeader
                title='Payment Rounds'
                subtitle='Set up the payment rounds here'
                extraContent={
                  <CreateNewButton
                    optionName='payment round'
                    optionType={PAYMENT_ROUNDS}
                    onClick={() => {
                      setPaymentRoundId('')
                      setPaymentRoundDays('')
                      setPaymentRoundPerc('')
                      setShowManagePaymentRoundModal(true)
                    }}
                  />
                }
              />
              <TabContent>
                {renderOption({
                  dataSource: paymentRoundsRef.current,
                  columns: paymentRoundColumns,
                  paymentRoundOption: true,
                  onEditClick: ({ _id, days, perc }) => {
                    setPaymentRoundId(_id)
                    setPaymentRoundDays(days)
                    setPaymentRoundPerc(perc)
                    setShowManagePaymentRoundModal(true)
                  }
                })}
              </TabContent>
            </FadedAnimatedDiv>
              )}
        </AnimatePresence>
      </div>
    ),
    [selectedTab, renderOption, creatingEditingOption, renderProjectOptionData]
  )

  const renderDocumentsTab = useCallback(
    () => (
      <div className={`${selectedTab !== 'documents' && 'sr-only'}`}>
        <AnimatePresence exitBeforeEnter initial={false}>
          {creatingEditingDocument
            ? (
            <FadedAnimatedDiv key='creatingNewDocument'>
              {renderProjectOptionData(DOCUMENTS, 'document')}
            </FadedAnimatedDiv>
              )
            : (
            <FadedAnimatedDiv key='documentsTable'>
              <TabHeader
                title='Documents'
                subtitle='Fill below the documents you will need to have at the Offer.'
                extraContent={
                  <CreateNewButton
                    optionName='document'
                    optionType={DOCUMENTS}
                    onClick={() => setCreatingEditingDocument(true)}
                  />
                }
              />
              <TabContent>
                {renderOption({
                  dataSource: additionalOptions,
                  columns: optionsColumns,
                  onEditClick: () => {
                    setSelectedOptionType(ADDITIONAL_OPTIONS)
                    setCreatingEditingOption(true)
                  }
                })}
              </TabContent>
            </FadedAnimatedDiv>
              )}
        </AnimatePresence>
      </div>
    ),
    [
      selectedTab,
      additionalOptions,
      renderOption,
      creatingEditingDocument,
      renderProjectOptionData
    ]
  )

  const renderIdTab = useCallback(
    () => (
      <div className={`${selectedTab !== 'id' && 'sr-only'}`}>
        <TabHeader
          title='ID'
          subtitle='This tab is only available to Super Admins'
        />
        <TabContent>
          <InlineInput
            key='projectId'
            label='Project ID'
            value={localProjectId}
            onChange={(e) => {}}
            placeholder='project ID'
            loading={gettingData}
            disabled
          />
        </TabContent>
      </div>
    ),
    [gettingData, localProjectId, selectedTab]
  )

  const getMenus = useCallback(() => {
    const menus = [
      { key: 'general', title: 'General' },
      { key: 'projectImages', title: 'Images' },
      { key: 'parking', title: 'Parking' },
      { key: 'colors', title: 'Colors' },
      { key: 'upgradeOptions', title: 'Upgrades and Options' },
      { key: 'paymentRounds', title: 'Payment Rounds' }
      // { key: 'documents', title: 'Documents' }
    ]

    if (loggedUserType === 'SuperAdmin' && Boolean(localProjectId)) {
      menus.push({ key: 'id', title: 'ID' })
    }

    return menus
  }, [loggedUserType, localProjectId])

  const cancelColorSelection = useCallback(() => {
    setProjectBackgroundColor(originalProjectBackgroundColor)
    setShowColorPickerModal(false)
  }, [originalProjectBackgroundColor])

  const updateOriginalBackgroundColor = useCallback(() => {
    setOriginalProjectBackgroundColor(projectBackgroundColor)
    setShowColorPickerModal(false)
  }, [projectBackgroundColor])

  const deleteEntity = useCallback(() => {
    setShowDeleteModal(false)
    setDeleting(true)

    deleteProjectOption({ projectId: localProjectId, optionId: idBeingDeleted })
      .then(() => {
        setDeleting(false)
        getProjectData()
        setEntityNameBeingDeleted('')
        setIdBeingDeleted('')
      })
      .catch(() => setDeleting(false))
  }, [localProjectId, getProjectData, idBeingDeleted])

  const CustomModal = useCallback(
    ({
      showModal,
      onCancel,
      modalType,
      title,
      subtitle,
      onOk,
      children,
      okText = 'Yes',
      okLoading = false
    }) => (
      <Modal
        showModal={showModal}
        onCancel={onCancel}
        modalType={modalType}
        title={title}
        subtitle={subtitle}
        onOk={onOk}
        okText={okText}
        okLoading={okLoading}
      >
        {children}
      </Modal>
    ),
    []
  )

  const renderDeleteModal = useCallback(
    () => (
      <CustomModal
        showModal={showDeleteModal}
        onCancel={() => {
          setShowDeleteModal(false)
          setTimeout(() => {
            setEntityNameBeingDeleted('')
            setIdBeingDeleted('')
          }, 150)
        }}
        modalType='danger'
        title='Confirm deletion?'
        subtitle={`${entityNameBeingDeleted} will be deleted!`}
        onOk={
          selectedTab === 'paymentRounds'
            ? () => {
                deletePaymentRound().then(() => {
                  setShowDeleteModal(false)
                  _onSaveClick()
                })
              }
            : deleteEntity
        }
      />
    ),
    [
      showDeleteModal,
      entityNameBeingDeleted,
      deleteEntity,
      deletePaymentRound,
      selectedTab,
      _onSaveClick
    ]
  )

  const renderManagePaymentRoundModal = useCallback(
    () => (
      <CustomModal
        showModal={showManagePaymentRoundModal}
        onCancel={() => {
          setShowManagePaymentRoundModal(false)
        }}
        modalType='custom'
        onOk={() => {
          setManagingPaymentRound(true)
          _managePaymentRounds().then(() => {
            setTimeout(() => {
              setShowManagePaymentRoundModal(false)
              setManagingPaymentRound(false)
              setOptionId('')
              if (localProjectId) {
                _onSaveClick()
              }
            }, 600)
          })
        }}
        okText='Save'
        okLoading={managingPaymentRound}
      >
        <div className='w-96'>
          <span className='text-xl'>Editing payment round</span>
          <GridFormWrapper className='mt-6'>
            <InputLabel
              id='days'
              name='days'
              type='number'
              label='Days'
              xs={24}
              sm={24}
              md={12}
              lg={12}
              value={paymentRoundDays}
              onChange={(e) => setPaymentRoundDays(e.target.value)}
              placeholder='days within to deposit'
              required
              validateKey='createPaymentRound'
            />
            <InputLabel
              id='perc'
              name='perc'
              type='number'
              label='Deposit percent'
              xs={24}
              sm={24}
              md={12}
              lg={12}
              value={paymentRoundPerc}
              typeInput='numberinput'
              onChange={(e) => setPaymentRoundPerc(e.value)}
              placeholder={"deposit's percent"}
              required
              prefix='%'
              validateKey='createPaymentRound'
            />
          </GridFormWrapper>
        </div>
      </CustomModal>
    ),
    [
      showManagePaymentRoundModal,
      paymentRoundDays,
      paymentRoundPerc,
      _managePaymentRounds,
      managingPaymentRound,
      _onSaveClick,
      localProjectId
    ]
  )

  const renderColorPickerModal = useCallback(
    () => (
      <CustomModal
        showModal={showColorPickerModal}
        onCancel={() => cancelColorSelection()}
        modalType='custom'
        onOk={() => updateOriginalBackgroundColor()}
        okText='Ok'
      >
        <ChromePicker
          disableAlpha
          color={projectBackgroundColor}
          onChange={(color) => setProjectBackgroundColor(color.hex)}
        />
      </CustomModal>
    ),
    [
      cancelColorSelection,
      projectBackgroundColor,
      showColorPickerModal,
      updateOriginalBackgroundColor
    ]
  )

  const renderMustHaveProjectCreatedModal = useCallback(
    () => (
      <CustomModal
        showModal={showMustHaveProjectCreatedModal}
        onCancel={() => {
          setShowMustHaveProjectCreatedModal(false)
        }}
        modalType='alert'
        title='Create project before the option?'
        subtitle='You must have an already existing project before creating the option. Do you want to create the project now?'
        onOk={() => {
          setShowMustHaveProjectCreatedModal(false)
          _onSaveClick().then((projectCreated) => {
            const { _id } = projectCreated
            setLocalProjectId(_id)
            setCreatingEditingOption(true)
          })
        }}
      />
    ),
    [_onSaveClick, showMustHaveProjectCreatedModal]
  )

  return (
    <>
      {renderDeleteModal()}
      {renderManagePaymentRoundModal()}
      {renderColorPickerModal()}
      {renderMustHaveProjectCreatedModal()}
      <FormWrapper>
        <FormTabsWrapper>
          <Tabs
            menus={getMenus()}
            tabSelected={selectedTab}
            onClick={(tab) => setSelectedTab(tab)}
          />
          {!localProjectId && (
            <TSaveButton
              loading={saving}
              className='ml-3'
              onClick={_onSaveClick}
            >
              Save project
            </TSaveButton>
          )}
        </FormTabsWrapper>

        <div className='mt-7'>
          {renderGeneralTab()}
          {renderProjectImagesTab()}
          {renderParkingTab()}
          {renderColorsTab()}
          {renderUpgradeOptionsTab()}
          {renderPaymentRoundsTab()}
          {/* {renderDocumentsTab()} */}
          {renderIdTab()}
        </div>
      </FormWrapper>
    </>
  )
}

const mapStateToProps = (state) => ({
  userObject: state.authReducer.userObject
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectData)

ProjectData.propTypes = {
  _id: propTypes.string,
  userObject: propTypes.shape({
    userType: propTypes.string
  })
}

TabHeader.propTypes = {
  title: propTypes.string,
  subtitle: propTypes.string,
  extraContent: propTypes.node
}

TabContent.propTypes = {
  children: propTypes.node,
  className: propTypes.string
}

Upload.propTypes = {
  title: propTypes.string,
  name: propTypes.string,
  buttonText: propTypes.string,
  width: propTypes.string,
  showUploadButton: propTypes.bool,
  showUploadList: propTypes.bool,
  uploadProps: propTypes.object
}
