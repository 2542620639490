import React from 'react'

const LineText = ({ title }) => {
  return (
    <div className="relative flex items-center my-10">
      <div className="flex-grow border-t border-softBlack30"></div>
      <span className="flex-shrink px-6 text-softBlack text-base font-medium">{title}</span>
      <div className="flex-grow border-t border-softBlack30"></div>
    </div>
  )
}

export default LineText
