import CustomButton from 'components/CustomButton'
import CancelModal from 'pages/offer/components/CancelModal'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { ADMIN_CREATE_INVENTORY_ALLOCATION, ADMIN_PROJECT_ALLOCATIONS, ALLOCATIONS } from 'routes'

const AllocateInventoryHeader = () => {
  const history = useHistory()
  const [isOpenCancelModal, setIsOpenCancelModal] = useState(false)

  const onCancel = () => {
    setIsOpenCancelModal(true)
  }

  const onConfirm = () => {
    setIsOpenCancelModal(false)
    let nextRoute = ''
    if (history.location.pathname === ADMIN_CREATE_INVENTORY_ALLOCATION) {
      nextRoute = ALLOCATIONS
    } else {
      nextRoute = ADMIN_PROJECT_ALLOCATIONS
    }
    history.push(nextRoute)
  }

  return (
    <>
      <div className='bg-white w-full fixed flex justify-between items-center px-8 py-6 border-b-2 border-softBlack'>
        <p className='my-0 text-4xl font-bold'>Allocate Inventory</p>
        <CustomButton
          variant='ghost'
          handleClick={onCancel}
        >
          <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
            Cancel
          </span>
        </CustomButton>
      </div>
      <CancelModal {...{ isOpenCancelModal, setIsOpenCancelModal, onConfirm }} />
    </>
  )
}

export default AllocateInventoryHeader
