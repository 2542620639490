import React, { useEffect, useState } from 'react'
import FormSelect from 'components/Form/FormSelect'
import FormInput from 'components/Form/FormInput'
import Form from 'components/Form/Form'
import Header from './Header/Header'
import FormHeader from './FormHeader/FormHeader'
import { useSelector } from 'react-redux'
import { staticLanguages } from './tempData'
import FormButton from './FormButton/FormButton'
import { updateSalesrepProfile } from 'store/actions/usersActions'

const Profile = () => {
  // @ts-ignore
  const userObject = useSelector(state => state.authReducer.userObject)
  const [initialValues, setInitialValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    zoomLink: '',
    bookingLink: '',
    city: '',
    location: '',
    country: '',
    developerCompany: '',
    spokenLanguages: [],
    phoneNumber: ''
  })

  const salesRepDataKeys = {
    developerCompany: '',
    spokenLanguages: [],
    zoomLink: '',
    bookingLink: '',
    country: '',
    city: '',
    location: ''
  }
  const defaultKeys = {
    email: '',
    firstName: '',
    lastName: ''
  }

  const handleSubmit = values => {
    const payload = {}
    delete payload.password
    // payload.salesRepData = {}
    Object.keys(salesRepDataKeys).forEach(key => (payload[`salesRepData.${key}`] = values[key]))
    Object.keys(defaultKeys).forEach(key => (payload[key] = values[key]))
    updateSalesrepProfile({ params: payload, userId: userObject?._id })
  }

  useEffect(() => {
    const initValues = { ...initialValues }
    Object.keys(salesRepDataKeys).forEach(key => {
      if (userObject.salesRepData?.[key]) { initValues[key] = userObject.salesRepData[key] }
    })
    Object.keys(defaultKeys).forEach(key => {
      if (userObject?.[key]) { initValues[key] = userObject[key] }
    })
    setInitialValues(initValues)
  }, [userObject])

  return (
    <div className='relative'>
        <Header />
        <div className='p-4 w-full h-max-content flex flex-col items-center justify-center'>
            <Form className='flex flex-col w-full bg-grey-8 p-4' initialValues={initialValues} onSubmit={handleSubmit}>
                <FormHeader />
                <div className='flex flex-row justify-center items-center flex-wrap p-4 pb-0 gap-4'>
                    <FormInput titleClass='font-normal' title='FIRST NAME*' fieldName='firstName' placeholder='firstName' />
                    <FormInput titleClass='font-normal' title='LAST NAME*' fieldName='lastName' placeholder='lastName' />
                </div>
                <div className='flex flex-row justify-center items-center flex-wrap p-4 pb-0 gap-4'>
                    <FormInput titleClass='font-normal' title='EMAIL*' fieldName='email' placeholder='Email' />
                    <FormSelect titleClass='font-normal' title='DEVELOPER COMPANY*' fieldName='developerCompany' placeholder='Developer Company' />
                </div>
                <div className='flex flex-row justify-center items-center flex-wrap p-4 pb-0 gap-4'>
                    <FormInput titleClass='font-normal' title='BOOKING LINK' fieldName='bookingLink' placeholder='Booking Link' />
                    <FormInput titleClass='font-normal' title='ZOOM LINK' fieldName='zoomLink' placeholder='Zoom Link' />
                </div>
                <div className='flex flex-row justify-center items-center flex-wrap p-4 pb-0 gap-4'>
                    <FormInput titleClass='font-normal' title='EMAIL*' fieldName='email' placeholder='Email' />
                    <FormSelect titleClass='font-normal' title='SPOKEN LANGUAGES' multi fieldName='spokenLanguages' placeholder='Spoken Languages' options={staticLanguages} />
                </div>
                <div className='flex flex-row justify-center items-center flex-wrap p-4 pb-0 gap-4'>
                    <FormInput titleClass='font-normal' title='COUNTRY*' fieldName='country' placeholder='Country' />
                    <FormInput titleClass='font-normal' title='CITY*' fieldName='city' placeholder='City' />
                </div>
                <div className='flex flex-row justify-center items-center flex-wrap p-4 pb-0 gap-4'>
                    <FormInput titleClass='font-normal' title='PHONE NUMBER' fieldName='phoneNumber' placeholder='Phone Number' />
                    <FormInput titleClass='font-normal' title='LOCATION' fieldName='location' placeholder='Location' />
                </div>
                <FormButton />
            </Form>
        </div>
    </div>
  )
}

export default Profile
