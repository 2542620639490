import useDynamicDropdown from './useDynamicDropdown'

const useCurrencyList = () => {
  const {
    data: currencyList,
    loading,
    update,
    deleteItem: deleteCurrencyItem,
    updateItem: updateSpecific,
    formattedData: formattedCurrencyList
  } = useDynamicDropdown('currencyList')

  return {
    currencyList,
    loading,
    update,
    deleteCurrencyItem,
    updateSpecific,
    formattedCurrencyList
  }
}

export default useCurrencyList
