import { api, ApiErrors } from 'services/api'

export const getAllCampaigns = (filter = '') =>
  new Promise((resolve, reject) => {
    api.get('/leads/mailchimp/campaigns').then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getCampaign = (id) =>
  new Promise((resolve, reject) => {
    api.get(`/leads/mailchimp/campaigns/${id}`).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })
export const getCampaignReport = (id) =>
  new Promise((resolve, reject) => {
    api.get(`/campaigns/${id}/report`).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getCampaignActivity = (id, {
  page = 1,
  limit = 100
}) =>
  new Promise((resolve, reject) => {
    api.get(`/campaigns/${id}/activity?page=${page}&limit=${limit}`).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getCampaignActivityById = (id, userId) =>
  new Promise((resolve, reject) => {
    api.get(`/campaigns/${id}/${userId}/activity`).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getCampaignSubscriberActivity = (id) =>
  new Promise((resolve, reject) => {
    api.get(`/leads/mailchimp/campaigns/${id}/activity`).then((response) => {
      if (response.ok) {
        resolve(response.data.emails)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getSubscriberActivity = (email) =>
  new Promise((resolve, reject) => {
    api.get(`/leads/mailchimp/campaigns/activity/${email}`).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })
