import React from 'react'
import standardStyle from 'assets/css/standardStyle'

const CardButton = ({ text, imgSrc, onClickHandler = (_value) => {} }) => {
  return (
    <>
      <div className="flex justify-center items-center flex-col hover:cursor-pointer"
          style={{ width: '300px', height: '240px', backgroundColor: standardStyle.colors.mainBackground, borderRadius: '4px' }}
          onClick={() => onClickHandler(true)}
        >
        <div className="mb-4">
          <img src={imgSrc} alt="" />
        </div>
        <div>
          <h6 className="font-bold" style={{ fontSize: '16px', fontFamily: standardStyle.fonts.openSans }}>{text}</h6>
        </div>
      </div>
    </>
  )
}

export default CardButton
