export const getToolbarOptions = (hasFileAttachments) => {
  const container = [
    [{ size: ['small', false, 'large', 'huge'] }],
    ['bold', 'italic', 'underline', 'link'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['image']
  ]
  if (hasFileAttachments) {
    container.push(['attachment'])
  }
  return container
}

export const getTextToolbarOptions = () => {
  const container = [
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }]
    // ['blockquote', 'code-block'],
    // ['clean']
  ]

  return container
}

export const attachmentIcon =
  '<svg width="24" height="26" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_5564_38342)"><path d="M16.5 6V17.5C16.5 19.71 14.71 21.5 12.5 21.5C10.29 21.5 8.5 19.71 8.5 17.5V5C8.5 3.62 9.62 2.5 11 2.5C12.38 2.5 13.5 3.62 13.5 5V15.5C13.5 16.05 13.05 16.5 12.5 16.5C11.95 16.5 11.5 16.05 11.5 15.5V6H10V15.5C10 16.88 11.12 18 12.5 18C13.88 18 15 16.88 15 15.5V5C15 2.79 13.21 1 11 1C8.79 1 7 2.79 7 5V17.5C7 20.54 9.46 23 12.5 23C15.54 23 18 20.54 18 17.5V6H16.5Z" fill="#2E2B2E"/></g><defs><clipPath id="clip0_5564_38342"> <rect width="24" height="24" fill="white"/> </clipPath> </defs> </svg>'
