/* eslint-disable */
// @ts-nocheck
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Select } from "antd";
import { useGetContactLists } from "../../../hooks/useGetContactLists";
// import useGetUnitGroups from '../../../hooks/useGetUnitGroups'
import useGetLeadBrokers from "../../../hooks/useGetLeadBrokers";
import useGetSalesReps from "../../../hooks/useGetSalesReps";
import useBrokerageCompanies from "hooks/useBrokerageCompanies";
import useReferralAgents from "hooks/useReferralAgents";
import useLeadSource from "hooks/useLeadSource";
import useLeadStatus from "hooks/useLeadStatus";
import useBuyerScore from "hooks/useBuyerScore";
import { getBrokerageCompanies } from "store/actions/usersActions";

const DefaultFilterList = ({ selectedState, setSelectedState }) => {
  const [list, setList] = useState(null);
  const units = useMemo(
    () => [
      { id: 1, name: "Studio" },
      { id: 2, name: "1 Bed" },
      { id: 3, name: "2 Bed" },
      { id: 4, name: "3 Bed" },
      { id: 5, name: "Townhouse" },
      { id: 6, name: "Penthouse" },
    ],
    []
  );
  const [salesRepList, setSalesRepList] = useState([]);
  const [leadBrokers, setLeadBrokers] = useState([]);
  const [companies, setCompany] = useState([]);
  const { contactsLists, getContacts } = useGetContactLists();
  // const { unitGroups } = useGetUnitGroups()
  const { data: leadBrokersData, refetchBrokers } = useGetLeadBrokers();
  const { salesReps, refetch: refetchSalesReps } = useGetSalesReps();
  const { referralAgents } = useReferralAgents();
  const { loading: isLeadSourceLoading, formattedLeadsources: leadSources } =
    useLeadSource();
  const { loading: isLeadStatusLoading, formattedLeadStatuses: leadStatuses } =
    useLeadStatus();

  const { loading: isBuyerScoreLoading, formattedScores: buyerScores } =
    useBuyerScore();

  useEffect(() => {
    if (!contactsLists.length) getContacts();
    refetchBrokers();
    refetchSalesReps();
  }, []);

  useEffect(() => {
    getBrokerageCompanies().then((res) => {
      let mapped = res?.values?.map((company, index) => {
        return { id: index, name: company };
      });
      setCompany(mapped);
    });
  }, []);

  useEffect(() => {
    if (salesReps.length) {
      const salesRepList = salesReps.map((el) => ({
        id: el._id,
        name: `${el.firstName} ${el.lastName}`,
      }));
      setSalesRepList(salesRepList);
    }
  }, [salesReps]);

  useEffect(() => {
    if (leadBrokersData.length) {
      const leadBrokersList = leadBrokersData.map((el) => ({
        id: el._id,
        name: `${el.firstName} ${el.lastName}`,
      }));
      setLeadBrokers(leadBrokersList);
    }
  }, [leadBrokersData]);

  useEffect(() => {
    const arr = [];
    contactsLists.forEach((el) => {
      if (!arr.find((item) => el.title === item.name)) {
        arr.push({ id: el._id, name: el.title });
      }
    });
    setList(arr);
  }, [contactsLists]);

  const filterOption = useCallback((input, option) => {
    return (option?.label ?? "")
      .toString()
      .toLowerCase()
      .includes(input.toLowerCase());
  }, []);

  return (
    <>
      <Select
        showSearch={true}
        loading={isLeadStatusLoading}
        placeholder="Lead Status"
        value={selectedState?.leadStatus}
        onChange={(value) =>
          setSelectedState({
            ...selectedState,
            leadStatus: value,
          })
        }
      >
        <Select.Option label="All" value={""}>
          All
        </Select.Option>
        {leadStatuses?.map((el) => (
          <Select.Option key={el.value} label={el.label} value={el.value}>
            {el.label}
          </Select.Option>
        ))}
      </Select>
      <Select
        placeholder="Score"
        loading={isBuyerScoreLoading}
        value={selectedState?.rating}
        onChange={(value) =>
          setSelectedState({
            ...selectedState,
            rating: value,
          })
        }
      >
        <Select.Option label="All" value={""}>
          All
        </Select.Option>
        {buyerScores?.map((el) => (
          <Select.Option key={el.value} label={el.label} value={el.value}>
            {el.label}
          </Select.Option>
        ))}
      </Select>
      <Select
        showSearch={true}
        placeholder="Brokerage Company"
        value={selectedState?.brokerageCompany}
        filterOption={(input, option) =>
          (option?.label ?? "")
            .toString()
            .toLowerCase()
            .includes(input.toLowerCase())
        }
        onChange={(value) =>
          setSelectedState({
            ...selectedState,
            brokerageCompany: value,
          })
        }
      >
        <Select.Option label="All" value={""}>
          All
        </Select.Option>
        {companies?.map((el) => (
          <Select.Option key={el.id} label={el.name} value={el.name}>
            {el?.name}
          </Select.Option>
        ))}
      </Select>
      <Select
        placeholder="Lists"
        showSearch={true}
        value={selectedState?.lists}
        filterOption={(input, option) =>
          (option?.label ?? "")
            .toString()
            .toLowerCase()
            .includes(input.toLowerCase())
        }
        onChange={(value) =>
          setSelectedState({
            ...selectedState,
            lists: value,
          })
        }
      >
        <Select.Option label="All" value={""}>
          All
        </Select.Option>
        {list?.map((el) => (
          <Select.Option key={el.id} label={el.name} value={el.id}>
            {el.name}
          </Select.Option>
        ))}
      </Select>
      <Select
        showSearch={true}
        placeholder="Unit Interest"
        value={selectedState?.unitInterest}
        filterOption={(input, option) =>
          (option?.label ?? "")
            .toString()
            .toLowerCase()
            .includes(input.toLowerCase())
        }
        onChange={(value) =>
          setSelectedState({
            ...selectedState,
            unitInterest: value,
          })
        }
      >
        <Select.Option label="All" value={""}>
          All
        </Select.Option>
        {units?.map((el) => (
          <Select.Option key={el.id} label={el.name} value={el.id}>
            {el.name}
          </Select.Option>
        ))}
      </Select>
      <Select
        showSearch={true}
        placeholder="Sales Rep Owner"
        value={selectedState?.salesRepOwner}
        filterOption={(input, option) =>
          (option?.label ?? "")
            .toString()
            .toLowerCase()
            .includes(input.toLowerCase())
        }
        onChange={(value) =>
          setSelectedState({
            ...selectedState,
            salesRepOwner: value,
          })
        }
      >
        <Select.Option label="All" value={""}>
          All
        </Select.Option>
        {salesRepList?.map((el) => (
          <Select.Option key={el.id} label={el.name} value={el.id}>
            {el.name}
          </Select.Option>
        ))}
      </Select>
      <Select
        showSearch={true}
        placeholder="Realtor"
        value={selectedState?.leadBroker}
        filterOption={(input, option) =>
          (option?.label ?? "")
            .toString()
            .toLowerCase()
            .includes(input.toLowerCase())
        }
        onChange={(value) =>
          setSelectedState({
            ...selectedState,
            leadBroker: value,
          })
        }
      >
        <Select.Option label="All" value={""}>
          All
        </Select.Option>
        {leadBrokers?.map?.((el) => (
          <Select.Option key={el.id} label={el.name} value={el.id}>
            {el.name}
          </Select.Option>
        ))}
      </Select>
      <Select
        showSearch={true}
        placeholder="Referral Agent"
        value={selectedState?.referralAgent}
        filterOption={(input, option) =>
          (option?.label ?? "")
            .toString()
            .toLowerCase()
            .includes(input.toLowerCase())
        }
        onChange={(value) =>
          setSelectedState({
            ...selectedState,
            referralAgent: value,
          })
        }
      >
        <Select.Option label="All" value={""}>
          All
        </Select.Option>
        {referralAgents?.map?.((referralAgent) => (
          <Select.Option
            key={referralAgent._id}
            label={referralAgent.firstName}
            value={referralAgent._id}
          >
            {`${referralAgent.firstName} ${referralAgent.lastName}`}
          </Select.Option>
        ))}
      </Select>
      <Select
        showSearch={true}
        loading={isLeadSourceLoading}
        placeholder="Lead Source"
        value={selectedState?.leadSource}
        filterOption={(input, option) =>
          (option?.label ?? "")
            .toString()
            .toLowerCase()
            .includes(input.toLowerCase())
        }
        onChange={(value) =>
          setSelectedState({
            ...selectedState,
            leadSource: value,
          })
        }
      >
        <Select.Option key={"all"} label="All" value={""}>
          All
        </Select.Option>
        {leadSources?.map((el) => (
          <Select.Option key={el.value} label={el.label} value={el.value}>
            {el.label}
          </Select.Option>
        ))}
      </Select>
    </>
  );
};

export default DefaultFilterList;
