import { api, ApiErrors } from 'services/api'
import { message } from 'antd'

export const getLookuplist = ({ type, key, _id = 0 }) => new Promise((resolve, reject) => {
  const filter = _id || `?type=${type}&key=${key}`

  api.get(`lookuplists/${filter}`)
    .then((response) => {
      if (response.ok) {
        resolve(response.data.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const createOrUpdateLookupList = (props, showSuccessMessage = true) => new Promise((resolve, reject) => {
  const { _id, ...params } = props

  // If id, so we have to update the record, or we're gonna create
  const verb = _id ? 'put' : 'post'
  const url = _id ? `lookuplists/${_id}` : 'lookuplists'

  api[verb](url, params)
    .then((response) => {
      if (response.ok) {
        if (showSuccessMessage) {
          message.success(`${params.name} was ${_id ? 'successfully updated' : 'created successfully'}`)
        }

        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const deleteLookuplist = (id) => new Promise((resolve, reject) => {
  api.delete(`lookuplists/${id}`)
    .then((response) => {
      if (response.ok) {
        message.success('Item deleted successfully')
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
})
