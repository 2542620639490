/* eslint-disable no-unused-vars */
import standardStyle from 'assets/css/standardStyle'
import loadingUnitsAnimation from 'assets/lottieJsons/unitDashboardLoading.json'
import { BuyerSelector, LottieWrapper, TableFilter, UnitSummarizedData } from 'components'
import FadedAnimatedDiv from 'components/FadedAnimatedDiv'
import propTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { createOfferChangeBuyer } from 'store/actions/offerActions'
import { getProjects } from 'store/actions/projectActions'
import {
  getProjectUnits,
  reserveUnitToBuyer, unreserveUnit
} from 'store/actions/unitActions'
import { H5, Modal, TButton, TSecondaryButton } from 'ui'
import { classNames, getUnitStatus, pluralWords, removeLastCommaFromStrings } from 'utils'
import {
  HeaderWrapper, LoadingUnitsWrapper, PanelWrapper,
  UnitCard, Wrapper
} from './styles'

const tabs = [
  { name: 'All Units', key: 'allUnits' },
  { name: 'My Units', key: 'myUnits' },
  { name: 'Offers', key: 'offers' }
]

const UnitsRealTimeDashboard = (props) => {
  const [gettingData, setGettingData] = useState(false)
  const [originalUnits, setOriginalUnits] = useState([])
  const [units, setUnits] = useState(originalUnits)
  const [selectedUnit, setSelectedUnit] = useState({})
  const [modalVisible, setModalVisible] = useState(false)
  const [buyerSelectorModalVisible, setBuyerSelectorModalVisible] = useState(false)
  // const [building, setBuilding] = useState('')
  const [reservingUnreservingUnit, setReservingUnreservingUnit] = useState('')
  const [offerHasMultiBuyers, setOfferHasMultiBuyers] = useState(false)
  const [selectedTabFilter, setSelectedTabFilter] = useState('allUnits')
  const [projectData, setProjectData] = useState({})
  const [showUnreserveModal, setShowUnreserveModal] = useState(false)
  const [showConfirmPurchasingReservedModal, setShowConfirmPurchasingReservedModal] = useState(false)
  const [reserveButtonDisabled, setReserveButtonDisabled] = useState(false)
  const [willJoinWaitlist, setWillJoinWaitlist] = useState(false)

  const {
    executeOnSelectUnit,
    offerId, selectedUnit: externalSelectedUnit,
    executeOnSelectReservedUnit,
    createOfferChangeBuyer, userObject, title, showModalButtons,
    selectedBuyers, showTabsFilter, appProject
  } = props

  useEffect(() => {
    const tmpSelectedBuyers = selectedBuyers.filter(buyer => buyer._id !== -1)
    setReserveButtonDisabled(!tmpSelectedBuyers.length)
  }, [selectedBuyers])

  useEffect(() => {
    if (externalSelectedUnit) {
      setSelectedUnit(externalSelectedUnit)
    }
  }, [externalSelectedUnit])

  const checkUnitPermission = useCallback((unit, selectedTabFilter) => {
    const { _id } = userObject
    const { brokerAssigned, reservedTo } = unit

    const tmpBrokerAssigned = brokerAssigned || []

    return Boolean(
      (selectedTabFilter !== 'myUnits' && unit.status !== 'ReservedBroker' && unit.status !== 'ReservedBuyer') ||
      ((unit.status === 'ReservedBroker' || unit.status === 'ReservedBuyer') &&
      ((tmpBrokerAssigned && tmpBrokerAssigned.length && tmpBrokerAssigned[0] !== 'HIDDEN') ||
      (reservedTo && reservedTo.length && reservedTo[0] !== 'HIDDEN') ||
      (tmpBrokerAssigned.filter((broker) => broker === _id).length)))
    )
  }, [userObject])

  useEffect(() => {
    let unitsToSet = [...originalUnits]

    // If selected My Units tabs, filter units with the logged coopbroker at the brokerAssigned field
    if (selectedTabFilter === 'myUnits') {
      unitsToSet = unitsToSet.filter((unit) => checkUnitPermission(unit, selectedTabFilter))
    }

    setUnits(unitsToSet)
  }, [selectedTabFilter, originalUnits, checkUnitPermission])

  const getUnitProps = useCallback((unit, hasPermission) => {
    const { _id } = userObject

    return getUnitStatus(unit, hasPermission, _id)
  }, [userObject])

  const _getProjectUnits = useCallback(async () => {
    if (appProject) {
      setUnits([])
      setOriginalUnits([])
      setProjectData({})

      setGettingData(true)

      let projectUnits = await getProjectUnits(appProject)
      const projectData = await getProjects(appProject)

      projectUnits = projectUnits.map((unit) => ({ ...unit, hasPermission: checkUnitPermission(unit) }))

      Promise.all([projectUnits, projectData])
        .then(() => {
          if (projectUnits.length) {
            setSelectedTabFilter('allUnits')
            // setBuilding(projectUnits[0].building._id)
            setUnits(projectUnits)
            setOriginalUnits(projectUnits)
          }

          if (Object.keys(projectData).length) {
            setProjectData(projectData)
          }

          setGettingData(false)
        })
    }
  }, [appProject, checkUnitPermission])

  // const updateUnits = useCallback((data) => {
  //   const { _id, status } = data

  //   const newUnits = units.map(unit =>
  //     (unit._id === _id) ? { ...unit, status } : unit
  //   )

  //   // setUnits(newUnits)
  //   // setOriginalUnits(newUnits)
  // }, [units])

  useEffect(() => {
    _getProjectUnits()
  }, [_getProjectUnits])

  const openModal = (unit) => {
    setSelectedUnit(unit)
    setModalVisible(true)
  }

  const handleOk = () => {
    setModalVisible(false)

    setTimeout(() => {
      setSelectedUnit({})
    }, 200)
  }

  const handleCancel = () => {
    setModalVisible(false)

    setTimeout(() => {
      setSelectedUnit({})
    }, 200)
  }

  const showBuyerSelector = (wantsToJoinWaitlist) => {
    // If is a unit already reserved, so will join the waitlist and not reserve directly
    if (wantsToJoinWaitlist) {
      setWillJoinWaitlist(wantsToJoinWaitlist)
    }

    setBuyerSelectorModalVisible(true)
  }

  const selectReservedUnit = useCallback(() => {
    const {
      reservedTo: {
        _id,
        firstName,
        lastName,
        buyerData: {
          backPhotoId,
          frontPhotoId,
          salesRep
        }
      }
    } = selectedUnit

    let salesRepName = ''

    if (Object.entries(salesRep || {}).length) {
      const { firstName, lastName } = salesRep
      salesRepName = `${firstName} ${lastName}`
    } else {
      const { firstName, lastName } = userObject
      salesRepName = `${firstName} ${lastName}`
    }

    setModalVisible(false)
    executeOnSelectUnit(selectedUnit)
    executeOnSelectReservedUnit({
      _id,
      firstName,
      lastName,
      salesRepName,
      frontPhotoId,
      backPhotoId
    })
  }, [executeOnSelectReservedUnit, executeOnSelectUnit, selectedUnit, userObject])

  const renderConfirmPurchasingReservedUnit = useCallback((selectedUnit) => {
    if (selectedUnit && selectedUnit.reservedTo) {
      const { reservedTo } = selectedUnit

      let buyersNames = reservedTo.reduce((ac, buyer) => `${ac} ${buyer.firstName} ${buyer.lastName}, `, '')
      buyersNames = removeLastCommaFromStrings(buyersNames)

      return (
        <Modal
          modalType='alert'
          title='Confirm Purchasing the Reserved Unit?'
          subtitle={`by doing this, the offer will be created and ${buyersNames}
          will be the selected Buyer`}
          onOk={() => selectReservedUnit()}
          showModal={showConfirmPurchasingReservedModal}
          onCancel={() => setShowConfirmPurchasingReservedModal(false)}
        />
      )
    }

    return null
  }, [selectReservedUnit, showConfirmPurchasingReservedModal])

  const handlePurchase = useCallback(() => {
    if (selectedUnit.status === 'ReservedBuyer') {
      setShowConfirmPurchasingReservedModal(true)
    } else {
      // If already has an unit selected, check if it's at ReservedBuyer status
      // If so, clean the previous selected user
      if (Object.keys(externalSelectedUnit).length) {
        const { status } = externalSelectedUnit
        if (status === 'ReservedBuyer') {
          createOfferChangeBuyer({})
        }
      }

      setModalVisible(false)
      executeOnSelectUnit(selectedUnit)
    }
  }, [createOfferChangeBuyer, executeOnSelectUnit, externalSelectedUnit, selectedUnit])

  const LoadingUnits = () => (
    <LottieWrapper
      animation={loadingUnitsAnimation}
      autoplay={false}
      loop={false}
      isStopped={false}
      className='h-full w-full'
    />
  )

  const _reserveUnit = useCallback((buyers) => {
    const { _id: unitId } = selectedUnit

    const baseSource = buyers || selectedBuyers

    const tmpBuyers = baseSource.filter(buyer => buyer._id !== -1)

    // Create the buyer list for the reservation
    const buyer = tmpBuyers.map((buyer) => buyer._id)

    setBuyerSelectorModalVisible(false)
    setReservingUnreservingUnit(true)

    reserveUnitToBuyer(unitId, buyer)
      .then(() => {
        setReservingUnreservingUnit(false)
        handleCancel()
        _getProjectUnits()
        createOfferChangeBuyer([{ _id: -1 }])
      })
      .catch(() => {
        setReservingUnreservingUnit(false)
      })
  }, [_getProjectUnits, selectedBuyers, selectedUnit, createOfferChangeBuyer])

  const _unreserveUnit = useCallback(() => {
    setShowUnreserveModal(false)
    setReservingUnreservingUnit(true)

    const { _id } = selectedUnit

    unreserveUnit(_id)
      .then(() => {
        setReservingUnreservingUnit(false)
        handleCancel()
        _getProjectUnits()
      })
      .catch(() => {
        setReservingUnreservingUnit(false)
      })
  }, [_getProjectUnits, selectedUnit])

  const renderUnreserveUnit = () => (
    <Modal
      modalType='alert'
      title='Confirm you want to Unserve the Unit?'
      subtitle='by doing this, the unit will return to Open Status'
      onOk={() => _unreserveUnit()}
      showModal={showUnreserveModal}
      onCancel={() => setShowUnreserveModal(false)}
    />
  )

  const renderFirstModal = useCallback(() => {
    const { hasPermission, status, reservedBy } = selectedUnit

    return (
      <Modal
        showModal={modalVisible}
        onCancel={handleCancel}
        modalType='custom'
        fullHeight
        cancelButtonClassname='text-base'
        extraButtons={
          showModalButtons &&
            <>
              {
                hasPermission
                  ? (
                    <>
                      <TSecondaryButton
                        key='reserve'
                        onClick={() => status === 'ReservedBuyer' ? setShowUnreserveModal(true) : showBuyerSelector(false)}
                        color={standardStyle.colors.darkText}
                        className='text-base'
                        disabled={(
                          (reservedBy && (reservedBy._id !== userObject._id)) ||
                          (status === 'SoldFirm') ||
                          (status === 'SoldCommitted') ||
                          (status === 'ReservedBroker' && hasPermission)
                        )}
                        loading={reservingUnreservingUnit}
                      >
                        {status === 'ReservedBuyer' ? 'Unreserve' : 'Reserve'}
                      </TSecondaryButton>
                      <TButton
                        key='purchase'
                        onClick={handlePurchase}
                        className='text-base'
                        disabled={status === 'SoldFirm' || status === 'SoldCommitted'}
                      >
                        Purchase
                      </TButton>
                    </>
                    )
                  : (
                    <TButton
                      key='joinWaitList'
                      onClick={() => showBuyerSelector(true)}
                      loading={reservingUnreservingUnit}
                      className='text-base'
                    >
                      Join the waitlist
                    </TButton>
                    )
              }

            </>
        }
      >
        <UnitSummarizedData
          unit={selectedUnit}
          hasPermission={hasPermission}
        />
      </Modal>
    )
  }, [handlePurchase, modalVisible, reservingUnreservingUnit, selectedUnit, showModalButtons, userObject._id])

  const onAddNewBuyerToOffer = useCallback(() => {
    const tmpSelectedBuyers = [...selectedBuyers]

    // Only creates a new item in the array to render at the buyer step
    tmpSelectedBuyers.push({ _id: -1 })

    createOfferChangeBuyer(tmpSelectedBuyers)
  }, [createOfferChangeBuyer, selectedBuyers])

  const removeBuyerFromOffer = useCallback((index) => {
    const tmpSelectedBuyers = [...selectedBuyers]

    // Remove the element based on the index
    tmpSelectedBuyers.splice(index, 1)

    createOfferChangeBuyer(tmpSelectedBuyers)
  }, [createOfferChangeBuyer, selectedBuyers])

  const _changeSelectedBuyer = useCallback(({ buyer, indexToUpdate, offerHasMultiBuyers }) => new Promise((resolve) => {
    let tmpSelectedBuyers = []

    if (offerHasMultiBuyers) {
      tmpSelectedBuyers = [...selectedBuyers]

      tmpSelectedBuyers.splice(indexToUpdate, 1, { ...buyer })
    } else {
      tmpSelectedBuyers.push(buyer)
    }

    createOfferChangeBuyer(tmpSelectedBuyers)
    resolve(tmpSelectedBuyers)
  }), [createOfferChangeBuyer, selectedBuyers])

  const renderSecondModal = useCallback(() => {
    return (
      <Modal
        showModal={buyerSelectorModalVisible}
        modalType='custom'
        onCancel={() => setBuyerSelectorModalVisible(false)}
        fullHeight
        extraButtons={
          <TButton
            key='purchase'
            size='large'
            onClick={() => _reserveUnit()}
            disabled={reserveButtonDisabled}
            loading={reservingUnreservingUnit}
          >
            {willJoinWaitlist ? 'Confirm join the waitlist' : 'Confirm reservation'}
          </TButton>
        }
      >
        {
          Object.keys(projectData).length && (
            <BuyerSelector
              buyerIsA='Reservation Requester'
              confirmationMessage={
                willJoinWaitlist
                  ? 'This buyer will be selected to join the waitlist'
                  : `The unit will be kept as Reserved by
                  ${projectData.reservationMaxDays} ${pluralWords(projectData.reservationMaxDays, 'day')}
                  `
              }
              // executeOnSelectBuyer={(buyerProps) => _reserveUnit(buyerProps)}
              executeOnSelectBuyer={({ indexToUpdate, offerHasMultiBuyers, ...buyer }) =>
                _changeSelectedBuyer({ buyer, indexToUpdate, offerHasMultiBuyers })
                  .then((buyers) => {
                    if (!willJoinWaitlist) {
                      _reserveUnit(buyers)
                    }
                  })}
              validateSelectedUserWhenMount={false}
              executeOnAddNewBuyerToOffer={() => onAddNewBuyerToOffer()}
              executeOnRemoveBuyerFromOffer={(indexToRemove) => removeBuyerFromOffer(indexToRemove)}
              offerHasMultiBuyers={offerHasMultiBuyers}
              onChangeMultiBuyers={() => setOfferHasMultiBuyers(!offerHasMultiBuyers)}
            />
          )
        }
      </Modal>
    )
  }, [
    _changeSelectedBuyer, buyerSelectorModalVisible, offerHasMultiBuyers,
    onAddNewBuyerToOffer, removeBuyerFromOffer, _reserveUnit, projectData,
    reserveButtonDisabled, willJoinWaitlist, reservingUnreservingUnit
  ])

  const renderNoUnits = useCallback(() => {
    let message = 'Please, select a project to see it\'s units'

    if (appProject) message = 'No units to show from this project'
    if (selectedTabFilter === 'myUnits' && !units.length && appProject) message = 'No units reserved for you at this moment'

    return (
      <FadedAnimatedDiv key='noUnits' className='w-full'>
        <H5 className='text-center'>{message}</H5>
      </FadedAnimatedDiv>
    )
  }, [appProject, selectedTabFilter, units.length])

  const renderUnits = () => (
    <div className='grid grid-cols-6 w-full gap-3 xl:gap-5 h-full'>
      {
        units.map((unit) => {
          const { _id, unitNumber, hasPermission } = unit
          return (
            <UnitCard
              className={`justify-center flex flex-col h-22 px-1 items-center rounded 
              opacity-100 text-center bg-${getUnitProps(unit, hasPermission).primary} text-${getUnitProps(unit, hasPermission).secondary}`}
              whileHover={{ scale: 1.10 }}
              onClick={() => openModal(unit)}
              offerId={offerId}
              externalSelectedUnit={externalSelectedUnit}
              _id={_id}
            >
              <span className='h-2/4 flex items-end bold text-2xl'>{unitNumber}</span>

              <div className='mb-1 h-2/4 overflow-y-auto'>
                {getUnitProps(unit, hasPermission).value}
              </div>
            </UnitCard>
          )
        })
      }
    </div>
  )

  const renderTabsFilter = useCallback(() => {
    if (showTabsFilter) {
      return (
        <div className='px-32 md:px-36 lg:px-44 pb-5'>
          <nav className='relative z-0 rounded-lg shadow flex divide-x divide-gray-200' aria-label='Tabs'>
            {tabs.map((tab, tabIdx) => {
              const selected = tab.key === selectedTabFilter

              return (
                <div
                  key={tab.name}
                  aria-current={selected ? 'page' : undefined}
                  className={classNames(
                    selected ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700',
                    tabIdx === 0 ? 'rounded-l-lg' : '',
                    tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
                    'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-6 text-sm font-medium text-center hover:bg-gray-50 focus:z-10 hover:cursor-pointer'
                  )}
                  onClick={() => setSelectedTabFilter(tab.key)}
                >
                  <span>{tab.name}</span>
                  <span
                    aria-hidden='true'
                    className={classNames(
                      selected ? 'bg-indigo-500' : 'bg-transparent',
                      'absolute inset-x-0 bottom-0 h-0.5'
                    )}
                  />
                </div>
              )
            })}
          </nav>
        </div>
      )
    }
  }, [selectedTabFilter, showTabsFilter])

  const renderTableFilter = useCallback(() => (
    <div className='w-4/6 mb-10'>
      <TableFilter
        dataToFilter={originalUnits}
        setData={setUnits}
        placeHolder='You can filter the units below by spacing words'
        label=''
        loading={gettingData}
      />
    </div>
  ), [gettingData, originalUnits])

  return (
    <Wrapper span={24}>
      <HeaderWrapper className='flex mt-6 mb-8 flex-col items-center w-full'>
        <h2 className='text-2xl text-center'>
          {title}
        </h2>
      </HeaderWrapper>
      {
        gettingData
          ? (
            <LoadingUnitsWrapper>
              <LoadingUnits />
              <LoadingUnits />
              <LoadingUnits />
            </LoadingUnitsWrapper>
            )
          : (
            <PanelWrapper className='flex flex-col items-center justify-center'>
              {units.length
                ? (
                  <>
                    {renderTabsFilter()}
                    {renderTableFilter()}
                    {renderUnits()}
                  </>
                  )
                : (
                  <>
                    {renderTabsFilter()}
                    {renderNoUnits()}
                  </>
                  )}
            </PanelWrapper>
            )
      }

      {renderFirstModal()}
      {renderSecondModal()}
      {renderUnreserveUnit()}
      {renderConfirmPurchasingReservedUnit(selectedUnit)}
    </Wrapper>
  )
}

UnitsRealTimeDashboard.defaultProps = {
  executeOnSelectUnit: () => {},
  offerId: '',
  selectedUnit: {
    externalSelectedUnit: {}
  },
  title: 'Select Unit',
  showModalButtons: true,
  showTabsFilter: false
}

UnitsRealTimeDashboard.propTypes = {
  appProject: propTypes.string,
  executeOnSelectUnit: propTypes.func,
  executeOnSelectReservedUnit: propTypes.func,
  offerId: propTypes.string,
  selectedUnit: propTypes.shape({
    externalSelectedUnit: propTypes.object,
    status: propTypes.string,
    _id: propTypes.string,
    reservedTo: propTypes.array,
    reservedBy: propTypes.shape({
      _id: propTypes.string
    }),
    unitNumber: propTypes.string,
    hasPermission: propTypes.bool
  }),
  createOfferChangeBuyer: propTypes.func,
  userObject: propTypes.object,
  title: propTypes.string,
  showModalButtons: propTypes.bool,
  selectedBuyers: propTypes.array,
  showTabsFilter: propTypes.bool
}

const mapStateToProps = (state) => ({
  userObject: state.authReducer.userObject,
  selectedBuyers: state.createOfferReducer.selectedBuyers,
  appProject: state.appReducer.appProject
})

const mapDispatchToProps = {
  createOfferChangeBuyer
}

export default connect(mapStateToProps, mapDispatchToProps)(UnitsRealTimeDashboard)
