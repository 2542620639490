// @ts-nocheck
import { LottieWrapper } from 'components'
import InventoryEditDrawer from 'components/Inventory/components/InventoryEditDrawer'
import useGetProjectUnits from 'hooks/useGetProjectUnits'
import useGetReservedUnitInterests from 'hooks/useGetReservedUnitInterests'
import { DollarSign } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { formatMoney } from 'utils'
import successAnimation from 'assets/lottieJsons/avesdoLoading.json'

const UnitCard = ({ unit, handleClick }) => (
  <div
    className='col-span-1 min-h-[100px] p-4 rounded-[7.07px] flex-col justify-between items-start inline-flex cursor-pointer'
    style={{
      backgroundColor: unit?.heatMapData?.bgColor ?? '#FFF',
      border: `1px solid ${unit?.heatMapData?.bgColor ?? '#000000'}`,
      color: unit?.heatMapData?.textColor ?? '#000000'
    }}
    onClick={handleClick}
  >
    <div className='flex-col justify-start items-start gap-1 flex'>
      <div className='text-base font-semibold leading-normal truncate'>Unit #
        {unit?.unitNumber}
      </div>
      <div className='justify-start items-start gap-4 inline-flex'>
        <div className='justify-start items-center gap-1 flex'>
          <DollarSign className='w-4 h-4 relative' />
          <div className='text-xs font-normal leading-none truncate'>{formatMoney(unit?.price)}</div>
        </div>
      </div>
    </div>
  </div>
)

const ReservationDemandView = ({ projectId }) => {
  const { units, unitsLoading, refetchUnits } = useGetProjectUnits(projectId)
  const { data, loading, refetch: refetchUnitInterestsData } = useGetReservedUnitInterests()
  const [isLoading, setIsLoading] = useState(false)
  const [allUnitsDemandHeatData, setAllUnitsDemandHeatData] = useState([])
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [editDetails, setEditDetails] = useState({})

  useEffect(() => {
    if (units && data) {
      setIsLoading(true)
      const temp = units.map((unit) => ({
        ...unit,
        heatMapData: data?.[unit._id] ?? {}
      })).filter(elm => elm?.heatMapData?.count > 0)
      setAllUnitsDemandHeatData(temp.sort((a, b) => b.heatMapData.count - a.heatMapData.count))
      setIsLoading(false)
    }
  }, [units, data])

  return (
    <>
      <div className='w-full'>
        <div className='flex flex-col gap-3 rounded-md p-6'>
          <div className='text-[17px] font-bold'>Reservation Demand</div>
          <div className='w-full h-[1px] bg-zinc-200 mb-2' />
          <div className='w-full'>
            {
              isLoading || unitsLoading || loading
                ? <div className='flex flex-col items-center justify-center w-full h-[300px]'>
                  <div
                    role='presentation'
                    style={{
                      height: 150,
                      width: 150,
                      margin: '0 auto'
                    }}
                  >
                    <LottieWrapper
                      animation={successAnimation}
                      autoplay
                      loop={true}
                      className='-mt-40 md:mt-0 max-w-sm'
                    />
                  </div>
                  <h1 className='mt-4 text-[16px] truncate text-center'>
                    Loading...
                  </h1>
                </div>
                : <div className='w-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-6 gap-5'>
                  {
                    allUnitsDemandHeatData.map((unitData) => (
                      <UnitCard
                        key={unitData._id}
                        unit={unitData}
                        handleClick={() => {
                          setEditDetails(unitData)
                          setIsDrawerOpen(true)
                        }} />
                    ))
                  }
                </div>
            }
          </div>
        </div>
      </div >
      <InventoryEditDrawer
        editDetails={editDetails}
        setEditDetails={setEditDetails}
        closeInfoDrawer={() => setIsDrawerOpen(false)}
        isInfoDrawerOpen={isDrawerOpen}
        isAbleToPurchase
        refetch={() => {
          refetchUnits()
          refetchUnitInterestsData()
        }}
        type={'Unit'}
        isFullHeight
      />
    </>
  )
}

export default ReservationDemandView
