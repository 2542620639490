import React, { useState, useEffect } from 'react'
import propTypes from 'prop-types'
import { FadedAnimatedDiv, LottieWrapper, OfferPaymentSuccess, PaymentMethods } from 'components'

import {
  ChosingPaymentMethodWrapper,
  PaymentMethodAnimationTitleWrappers,
  PaymentMethodsWrapper
} from './styles'
import currencyCoinAnimation from 'assets/lottieJsons/currencyCoin.json'
import { AnimatePresence } from 'framer-motion'

const AnimatedPayment = ({
  offer, executeAfterSuccessPage, doneButtonClassName
}) => {
  const [showPaymentMethods, setShowPaymentMethods] = useState('')
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('')
  const [paymentSucceed, setPaymentSucceed] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setShowPaymentMethods(true)
    }, 5500)
  }, [])

  const _executeWhenSuccess = () => {
    setPaymentSucceed(true)
  }

  return (
    <AnimatePresence exitBeforeEnter>
      {
        !paymentSucceed
          ? (
            <FadedAnimatedDiv
              key='chosingPaymentMethod'
              style={{
                display: 'flex',
                height: '100%',
                overflow: 'hidden',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center'
              }}
            >
              <ChosingPaymentMethodWrapper>
                <PaymentMethodAnimationTitleWrappers
                  xs={24}
                  sm={24}
                  md={showPaymentMethods ? 8 : 24}
                  lg={showPaymentMethods ? 8 : 24}
                  showPaymentMethods={showPaymentMethods}
                  className={`text-center ${!showPaymentMethods ? '-mt-40' : 'mt-10'} md:mt-0`}
                >
                  <LottieWrapper
                    animation={currencyCoinAnimation}
                    autoplay
                    loop={false}
                    className='h-80'
                    id='lottieCurrency'
                  />
                  <FadedAnimatedDiv
                    transition={{ delay: 2.3 }}
                    key='firstText'
                  >
                    <span className='text-lg'>
                      Now, it's time to make your first payment.
                    </span>
                  </FadedAnimatedDiv>

                  <FadedAnimatedDiv
                    transition={{ delay: 4.3 }}
                    key='secondText'
                  >
                    <span className='text-lg'>
                      What would be the better option for you?
                    </span>
                  </FadedAnimatedDiv>
                </PaymentMethodAnimationTitleWrappers>

                {
                  showPaymentMethods && (
                    <PaymentMethodsWrapper
                      xs={24}
                      sm={24}
                      md={16}
                      lg={16}
                      showPaymentMethods={showPaymentMethods}
                    >
                      <FadedAnimatedDiv
                        transition={{ delay: 0.7 }}
                        style={{ height: '100%' }}
                        className='w-full px-2 2xl:px-28'
                      >
                        <PaymentMethods
                          selectedPaymentMethod={selectedPaymentMethod}
                          onSelectPaymentMethod={(method) => setSelectedPaymentMethod(method) }
                          offer={offer}
                          executeWhenSuccess={_executeWhenSuccess}
                        />
                      </FadedAnimatedDiv>
                    </PaymentMethodsWrapper>
                  )
                }
              </ChosingPaymentMethodWrapper>
            </FadedAnimatedDiv>
            )
          : (
            <FadedAnimatedDiv
              key='allDocsSigned'
              style={{
                height: '100%',
                width: '100%',
                padding: 20
              }}
              transition={{ duration: 0.2 }}
            >
              <OfferPaymentSuccess
                offer={offer}
                doneButtonClassName={doneButtonClassName}
                executeOnFinish={() => {
                  executeAfterSuccessPage()
                }}
              />
            </FadedAnimatedDiv>
            )
      }
    </AnimatePresence>
  )
}

export default AnimatedPayment

AnimatedPayment.defaultProps = {
  doneButtonClassName: ''
}

AnimatedPayment.propTypes = {
  offer: propTypes.object,
  executeAfterSuccessPage: propTypes.func,
  doneButtonClassName: propTypes.string
}
