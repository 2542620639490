import React, { useEffect, useState } from 'react'
import propTypes from 'prop-types'
import { AnimatedPayment, BuyerPageWithImage } from 'components'
import { useHistory } from 'react-router'
import { BUYER_DASHBOARD } from 'routes'

const BuyerOfferPayment = (props) => {
  const { offerData, projectData } = props
  const [offer, setOffer] = useState('')

  const history = useHistory()

  useEffect(() => {
    if (Object.keys(offerData).length) {
      setOffer(offerData)
    }
  }, [offerData])

  return (
    <BuyerPageWithImage
      image={projectData && projectData.images[4]}
    >
      <AnimatedPayment
        offer={offer}
        doneButtonClassName='h-9 md:h-16 text-base md:text-2xl px-6'
        executeAfterSuccessPage={() => history.push(BUYER_DASHBOARD)}
      />
    </BuyerPageWithImage>
  )
}

export default BuyerOfferPayment

BuyerOfferPayment.propTypes = {
  offerData: propTypes.object,
  projectData: propTypes.object
}
