import React from 'react'
// @ts-ignore
import { getCustomForCustomField, getDemandNoteFieldSetting } from './setting'

// @ts-ignore
const Component = React.forwardRef((props, ref) => {
  // @ts-ignore
  const label = props.label
  // @ts-ignore
  const options = props.options
  // @ts-ignore
  const name = props.name
  // @ts-ignore
  const isMultiple = props.isMultiple
  const inputType = isMultiple ? 'checkbox' : 'radio'
  const nameKeyWithoutUUID = String(name).split('____')[0]
  return <div>
    <div>
      {label}
    </div>
    <div>
      {options.map((option, index) => {
        return <div key={index} className='flex gap-x-2 items-center'>
          <input
            type={inputType}
            name={nameKeyWithoutUUID}
            value={option.id}
          />
          <label className='m-0 p-0'>{option.label}</label>
        </div>
      })
      }
    </div >
  </div >
})

// eslint-disable-next-line space-before-function-paren
export default function getDemandNoteOption(item) {
  const id = item.id || item._id
  const title = item.label ?? item.name
  const options = item.options
  const type = item.type ?? 'project'
  const isMultiple = item.isMulti ?? false
  const field = getDemandNoteFieldSetting(item)
  const config = getCustomForCustomField(field, Component, {
    icon: 'fa fa-check-square',
    props: {
      label: title,
      name: id,
      type,
      options: options.map(e => {
        return {
          id: e.id ?? e._id,
          label: e.label
        }
      }),
      isMultiple
    }
  })
  return config
}
