// @ts-nocheck
import React, { useState } from 'react'
import Skeleton from 'react-loading-skeleton'

import useGetProjectBuyers from 'hooks/useGetProjectBuyers'

import UserInfoCard from 'components/UserInfoCard'
import BuyerCard from 'components/BuyerCard'

import { ReactComponent as UserIcon } from 'assets/icons/user.svg'
import { ReactComponent as CorporationIcon } from 'assets/icons/corporation.svg'
import { ReactComponent as LinkIcon } from 'assets/icons/link.svg'
import LargeTabs from 'components/LargeTabs'

const SelectBuyer = () => {
  const tabes = ['Buyers', 'Corporations']
  const [activeTab, setActiveTab] = useState(tabes[0])
  const [activeBuyerIds, setActiveBuyerIds] = useState([])

  const { loading, buyers } = useGetProjectBuyers()

  const selectBuyer = (buyer) => {
    if (checkIfAlreadyExists(buyer._id)) {
      if (activeBuyerIds.length > 0) {
        const filteredArray = activeBuyerIds.filter(
          (item) => item._id !== buyer._id
        )
        setActiveBuyerIds(filteredArray)
      }
    } else {
      const temp = [...activeBuyerIds, buyer]
      setActiveBuyerIds(temp)
    }
  }

  const checkIfAlreadyExists = (id) => {
    return activeBuyerIds.some((buyer) => buyer._id === id)
  }

  const createNewBuyer = () => {}
  const createNewCorporation = () => {}
  const linkExistingBuyer = () => {}
  return (
    <div>
      <div className='pb-4 border-b-2 border-softBlack'>
        <p className='my-0 text-xl font-bold'>Select Buyers</p>
      </div>
      <p className='my-0 mt-4 mb-10 text-softBlack text-base'>
        You can add new buyers or select from the recent ones
      </p>
      <LargeTabs types={tabes} active={activeTab} setActive={setActiveTab} />
      <div className='grid grid-cols-4 gap-6 mt-10'>
        {loading
          ? Array.from(Array(4).keys()).map(() => (
              <Skeleton height={180} width='100%' />
          ))
          : buyers
            ?.map((_buyer, index) => (
                <UserInfoCard
                  {..._buyer}
                  key={index}
                  onSelect={() => selectBuyer(_buyer)}
                  isActive={checkIfAlreadyExists(_buyer._id)}
                  className=''
                />
            ))}
      </div>
      <div className='relative flex items-center my-13'>
        <div className='flex-grow border-t border-softBlack30'></div>
        <span className='flex-shrink px-6 text-softBlack text-base font-medium'>
          or create new buyers
        </span>
        <div className='flex-grow border-t border-softBlack30'></div>
      </div>
      <div className='flex gap-6 w-full justify-between'>
        <BuyerCard
          title='Create New Buyer'
          Icon={UserIcon}
          onClick={createNewBuyer}
          classes='w-1/3'
        />
        <BuyerCard
          title='Create New Corporation'
          Icon={CorporationIcon}
          onClick={createNewCorporation}
          classes='w-1/3'
        />
        <BuyerCard
          title='Link with Existing Buyer'
          Icon={LinkIcon}
          onClick={linkExistingBuyer}
          classes='w-1/3'
        />
      </div>
      <div className='block justify-center mt-18 h-12 content-center text-center'>
        {activeBuyerIds.length > 0
          ? (
            <>
              <span className='block'>
                Confirm DB Real Estate as a Reservation Requester?
              </span>
              <span className='block'>
                The unit will be kept as Reserved by 2 days
              </span>
            </>
            )
          : null
        }
      </div>
      <div className='flex justify-center mt-8'>
        <button
          className='w-52 h-12 rounded bg-volt border py-3 px-6'
          disabled={activeBuyerIds.length <= 0}
        >
          Confirm Reservation
        </button>
      </div>
    </div>
  )
}

export default SelectBuyer
