import useDynamicDropdown from './useDynamicDropdown'

const useFileTags = () => {
  const {
    data: fileTags,
    loading,
    update,
    deleteItem: deleteFileTag,
    updateItem: updateSpecific,
    formattedData: formatteedFileTags
  } = useDynamicDropdown('fileTags')

  return {
    fileTags,
    loading,
    update,
    deleteFileTag,
    updateSpecific,
    formatteedFileTags
  }
}

export default useFileTags
