import ProgressSteps from 'components/ProgressSteps'
import NoHeader from 'components/Header/NoHeader'
import React, { useState } from 'react'
import GeneralInfo from './GeneralInfo'
import AddImages from './AddImages'
import AddParking from './AddParking'

import { ADMIN_DASHBOARD } from 'routes'
import { PColorIcon, PUpgradeIcon, PParkingIcon, PImageIcon, PInfoIcon, PCardIcon } from 'components/Icons'
import AddColors from './AddColors'
import AddUpgrades from './AddUpgrade'
import AddPayments from './AddPayments'
import CreateProjectContext from '../context/CreateProjectProvider'

const CreateProject = () => {
  const steps = [
    { text: 'General', icon: PInfoIcon },
    { text: 'Images', icon: PImageIcon },
    { text: 'Parking', icon: PParkingIcon },
    { text: 'Colors', icon: PColorIcon },
    { text: 'Upgrades', icon: PUpgradeIcon },
    { text: 'Payment', icon: PCardIcon }
  ]
  const [step, setStep] = useState(0)
  const [newProjectId, setNewProjectId] = useState('')

  const iconStyle = {
    width: '20px',
    height: '20px'
  }
  const ActiveIconStyle = {
    width: '20px',
    height: '20px',
    opacity: '0.3'
  }

  return (
    <div>
      <NoHeader redirect={ADMIN_DASHBOARD} title={'Create Project'} />
      <CreateProjectContext.Provider
          value={{
            setNewProjectId,
            newProjectId
          }}
        >
      <div className="px-8 pb-8" style={{ paddingTop: '7.5rem' }}>
        <ProgressSteps
          steps={steps}
          currentStepIndex={step}
          className="w-70 fixed p-7"
          iconStyle={iconStyle}
          ActiveIconStyle={ActiveIconStyle}
        />
        <div className="pl-82.25 w-full">
          <div
            className="bg-white rounded grow w-full"
          >
            {step === 0 && <GeneralInfo setStep={setStep} />}
            {step === 1 && <AddImages setStep={setStep} />}
            {step === 2 && <AddParking setStep={setStep} />}
            {step === 3 && <AddColors setStep={setStep} />}
            {step === 4 && <AddUpgrades setStep={setStep} />}
            {step === 5 && <AddPayments setStep={setStep} />}
          </div>
        </div>
      </div>
      </CreateProjectContext.Provider>
    </div>
  )
}

export default CreateProject
