// @ts-nocheck
/* eslint-disable */
import React, { useEffect, useState } from 'react';
import useContactContext from '../hooks/useContactContext';
import SearchBox from './SearchBox/SearchBox';
import FilterToggle from './FilterToggle/FilterToggle';
import { HiOutlineDownload } from 'react-icons/hi';
import UserTable from './UserTable/UserTable';
import ContactModal from './ContactModal/ContactModal';
import FilterDrawer from '../FilterDrawer/FilterDrawer';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { AiFillCaretUp } from 'react-icons/ai';
import { getLeadsCountByProject } from 'store/actions/usersActions';
import { set } from 'lodash';
import CustomButton from 'components/CustomButton';
import UserTableV2 from './UserTable/UserTableV2';

const ContactsTable = () => {
  const { state, openAddToListDrawer, openAssignSalesRep } = useContactContext();
  const show = useSelector((state) => state.appReducer.show);
  const projectId = useSelector((state) => state.appReducer.appProject);
  const [leadsCountData, setLeadsCountData] = useState([]);
  const [totalLeads, setTotalLeads] = useState(0);
  const buttonsList = [
    // { title: 'Change Status' },
    // { title: 'Assignments' },
    { title: 'Manage List', onClick: () => openAddToListDrawer() },
    { title: 'Assign Sales Rep', onClick: () => openAssignSalesRep() },
    // { title: 'Send Email' },
    // { title: 'Archive' }
  ];

  const color = ['#dc2626', '#f97316', '#16a34a', '#2563eb', '#7c3aed', '#171717', '#facc15', '#e11d48'];


  useEffect(() => {
    if (projectId) {
      getLeadsCountByProject(projectId).then((data) => {
        setLeadsCountData(data);
        let total = 0;
        data.forEach((item) => (total += item.count));
        setTotalLeads(total);
      });
    }
  }, [projectId]);

  const LeadStatusStats = () => {
    return (
      <div className='bg-white border border-[#E4E4E7] shadow-sm rounded-2xl p-4 md:p-6 lg:p-8 flex flex-col gap-y-8'>
        <div className='flex flex-col md:flex-row md:items-center gap-4 md:gap-x-10'>
          <div>
            <h5 className='text-sm sm:text-lg font-semibold mb-0'>
              Project Contacts
            </h5>
            <h2 className='text-2xl font-bold mb-0'>{totalLeads} Contact</h2>
          </div>
          <div className='md:flex-1 h-[10px] sm:h-[24px] flex gap-1 items-center rounded-lg overflow-hidden'>
            {leadsCountData.length > 0 &&
              leadsCountData.map((item, index) => {
                return (
                  <div
                    className='h-full'
                    style={{
                      width: `${(item.count / totalLeads) * 100}%`,
                      backgroundColor: color[index] || '#000000',
                    }}
                  />
                );
              })}
            {/* <div className='w-[43%] h-full bg-volt' />
            <div className='w-[27%] h-full bg-zinc-500' />
            <div className='w-[17%] h-full bg-zinc-300' />
            <div className='w-[13%] h-full bg-softBlack' /> */}
          </div>
        </div>
        <div className='flex-1 flex flex-wrap items-center gap-x-4 gap-y-6 overflow-hidden'>
          {leadsCountData.map((type, index) => (
            <div
              key={type.id}
              className={`flex flex-col w-fit md:w-[200px] gap-y-1 pl-4 ${'border-l border-[#2E2B2E] border-opacity-25'
                } overflow-hidden`}
            >
              <div className='flex gap-2'>
                <svg
                  className='mt-1'
                  width='15'
                  height='15'
                  viewBox='0 0 12 12'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <circle
                    cx='6'
                    cy='6'
                    r='6'
                    fill={color[index] ?? '#000000'}
                  />
                </svg>
                <h6 className='flex-1 truncate capitalize text-base mb-0 font-medium'>
                  {type._id}
                </h6>
              </div>
              <p className='text-xl md:text-2xl mb-0 font-semibold md:font-bold'>{type.count}</p>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className='w-full'>
      <div className='flex'>
        <FilterDrawer />
        <div
          className={`flex flex-col p-4 w-full max-h-full overflow-auto ${state.isFilterDrawerOpen && !isMobile ? 'md:ml-[280px]' : 'ml-0'
            } transition-all`}
        >
          <LeadStatusStats />
          <div
            className={`flex flex-row flex-wrap items-center gap-3 md:gap-4 justify-start ${state.isContactDetailDrawerOpen && !isMobile
              ? 'pb-4'
              : 'py-3 md:py-4'
              }`}
          >
            <div className='w-full md:w-fit flex gap-3 md:gap-4'>
              <FilterToggle />
              <SearchBox />
            </div>
            {/* {!state.isContactDetailDrawerOpen && !state.isAddToListDrawerOpen && buttonsList.map(({ title, onClick }, index) => <button  className='w-[129px] h-[45px] px-3 py-2 bg-volt rounded justify-center items-center inline-flex font-medium' key={index} onClick={onClick}><HiOutlineDownload /><div className='ml-2 italic'>{title}</div></button>)} */}
            <div className='w-full md:w-fit grid grid-cols-2 md:flex gap-3 md:gap-4'>
              {buttonsList.map(({ title, onClick }, index) => (
                <CustomButton key={index} className='col-span-1 md:w-fit' handleClick={onClick}>
                  <span className='font-medium py-2 px-4 text-base flex gap-2 items-center'>
                    <HiOutlineDownload className='w-4 h-4' />
                    {title}
                  </span>
                </CustomButton>
              ))}
              <ContactModal />
            </div>
          </div>
          <UserTable />
        </div>
      </div>
    </div>
  );
};

export default ContactsTable;
