/* eslint-disable */
// @ts-nocheck
import React, {
  useState,
  useMemo,
  useEffect,
} from 'react'
import propTypes from 'prop-types'
import moment from 'moment'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import CustomTable from 'components/CustomTable'
import CreateSinglePageCampaign from './components/CreateSinglePageCampaign/CreateSinglePageCampaign'
import { getSingularContracts } from 'store/actions/singularContractsAction'
import SingularDocumentsSign from 'pages/buyerSignSinglePageContract/SingularDocumentsSign'
import CustomButton from 'components/CustomButton'
import { Eye, X } from 'lucide-react'
import { Modal } from '@material-ui/core'
import { isMobile } from 'react-device-detect'

const SIGN_STATUS = ['sign', 'signed', 'Sign', 'Signed']
const SEND_STATUS = ['Sent']

export const SignStatus = ({ status }) => {
  return (
    <div
      className={`w-fit font-semibold text-xs flex items-center gap-x-1.5 px-3 py-1 rounded-md capitalize
        ${SIGN_STATUS.indexOf(status) !== -1 ? 'bg-green-400' : 'bg-yellow-400'} text-center bg-opacity-20
      `}
    >
      <div className={`w-[9px] h-[9px] ${SIGN_STATUS.indexOf(status) !== -1 ? 'bg-green-600' : 'bg-yellow-600'} rounded-full`} />
      {status}
    </div>
  )
}

const ProjectSingleSendContractDocuments = ({ appProject, userObject, isCreateSinglePageCampaign, setIsCreateSinglePageCampaign }) => {
  const [isFetchingDocuments, setIsFetchingDocuments] = useState(true)
  const [documents, setDocuments] = useState([])
  const [selectedContractId, setSelectedContractId] = useState(null)

  const getDocuments = async () => {
    setIsFetchingDocuments(true)
    const tempDocuments = await getSingularContracts()

    setDocuments([
      ...tempDocuments.map((doc) => ({
        ...doc,
        buyerName: doc?.buyer?.fullName?.length > 0 ? doc.buyer?.fullName : doc?.buyer?.firstName + ' ' + doc?.buyer?.lastName,
        contractTemplateName: doc?.contractTemplate?.name ?? '-',
        isSigned: doc?.contract?.status ?? '-',
      }))
    ])
    setIsFetchingDocuments(false)
  }

  useEffect(() => {
    getDocuments()
  }, [])

  const tableColumns = useMemo(
    () => [
      {
        id: 'dateRegistered',
        label: 'Date Registered',
        Title: (<span className='leading-none text-sm font-normal text-[#2E2B2E] text-opacity-70'>Date Registered</span>),
        accessor: 'createdAt',
        dataIndex: 'createdAt',
        isSort: true,
        sortingType: 'string',
        Cell: (props) => (
          <div className='w-full flex flex-col'>
            <p className='mb-0 truncate text-base font-normal'>{moment(props.cell.value).format("MM/DD/YYYY, LT")}</p>
          </div>
        )
      },
      {
        id: 'buyerName',
        label: 'Buyer',
        Title: (<span className='leading-none text-sm font-normal text-[#2E2B2E] text-opacity-70'>Buyer</span>),
        accessor: 'buyerName',
        dataIndex: 'buyerName',
        isSort: true,
        sortingType: 'string',
        Cell: (props) => <p className='mb-0 text-base font-normal capitalize'>{props.cell.value}</p>
      },
      {
        id: 'contractTemplateName',
        label: 'Contract template',
        Title: (<span className='leading-none text-sm font-normal text-[#2E2B2E] text-opacity-70'>Contract template</span>),
        accessor: 'contractTemplateName',
        dataIndex: 'contractTemplateName',
        isSort: true,
        sortingType: 'string',
        Cell: (props) => <p className='mb-0 text-base font-normal capitalize'>{props.cell.value}</p>
      },
      {
        id: 'sendStatus',
        label: 'Email status',
        Title: (<span className='leading-none text-sm font-normal text-[#2E2B2E] text-opacity-70'>Email status</span>),
        accessor: 'sendStatus',
        dataIndex: 'sendStatus',
        isSort: true,
        sortingType: 'string',
        Cell: (props) => (
          <div
            className={`w-fit font-semibold text-xs flex items-center gap-x-1.5 px-3 py-1 rounded-md capitalize ${props.cell.value === 'Sent' ? 'bg-green-400' : props.cell.value === 'Pending' ? 'bg-yellow-400' : 'bg-red-400'} text-center bg-opacity-20 `}
          >
            <div className={`w-[9px] h-[9px] ${props.cell.value === 'Sent' ? 'bg-green-600' : props.cell.value === 'Pending' ? 'bg-yellow-600' : 'bg-red-600'} rounded-full`} />
            {props.cell.value ?? 'Failed'}
          </div>
        )
      },
      {
        id: 'isSigned',
        label: 'Sign status',
        Title: (<span className='leading-none text-sm font-normal text-[#2E2B2E] text-opacity-70'>Sign status</span>),
        accessor: 'isSigned',
        dataIndex: 'isSigned',
        isSort: true,
        sortingType: 'string',
        Cell: (props) => <SignStatus status={props.cell.value} />
      },
      {
        id: 'actionBtn',
        label: 'Action button',
        Title: '',
        accessor: 'contract',
        isSort: false,
        Cell: (props) => (
          <div className='flex flex-row gap-2 text-center items-center justify-between'>
            {
              SEND_STATUS.indexOf(props?.cell?.row?.original?.sendStatus) >= 0 &&
              <CustomButton handleClick={() => setSelectedContractId(props.cell.value?._id)}>
                <span className='font-normal py-1.5 px-4 text-sm text-center flex items-center gap-2'>
                  <Eye className='w-4 h-4' />
                  <span className='text-sm'>
                    View
                  </span>
                </span>
              </CustomButton>
            }
          </div>
        )
      }
    ], [])

  return (
    <>
      <div className='relative h-full w-full py-5 flex flex-col items-center'>
        <CustomTable
          className='rounded-lg'
          dataSource={documents}
          columns={tableColumns}
          tailwindTable
          loading={isFetchingDocuments}
          isFromContracts
          showTablePagination={true}
          noMargin
          refetchData={getDocuments}
        />
        <Modal
          open={selectedContractId?.length > 0}
          onClose={() => setSelectedContractId(null)}
          aria-labelledby='Offer Documents'
          aria-describedby='Offer documents list'
        >
          <div
            className={`font-openSans relative bg-white py-0 rounded shadow-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-[calc(100%-5px)] overflow-auto ${isMobile ? 'w-full' : 'w-[calc(100%-5px)]'}`}
          >
            <button
              className='absolute right-6 top-6.5'
              onClick={() => setSelectedContractId(null)}
            >
              <X />
            </button>
            <SingularDocumentsSign
              contractId={selectedContractId}
              isPublicPath={true}
              userObject={{ role: 'Buyer' }}
              // contracts={contractData?.offer?.contracts}
              setCloseDocument={() => setSelectedContractId(null)}
            />
          </div>
        </Modal>
      </div>
      <CreateSinglePageCampaign
        isOpen={isCreateSinglePageCampaign}
        onClose={() => setIsCreateSinglePageCampaign(false)}
        onSuccess={getDocuments}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  appProject: state.appReducer.appProject,
  userObject: state.authReducer.userObject
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSingleSendContractDocuments)

ProjectSingleSendContractDocuments.propTypes = {
  appProject: propTypes.string,
  userObject: propTypes.shape({
    userType: propTypes.string
  }),
  cell: propTypes.shape({
    value: propTypes.string
  }),
  row: propTypes.shape({
    original: propTypes.shape({
      _id: propTypes.string
    })
  }),
  isCreateSinglePageCampaign: propTypes.bool,
  setIsCreateSinglePageCampaign: propTypes.func
}
