// @ts-nocheck
/* eslint-disable */
import React, { useState } from 'react'
import { Modal } from '@material-ui/core'
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg'
import UseCSV from '@usecsv/react'
import { api } from 'services/api'
import CustomButton from 'components/CustomButton'

const dynamicColumnsForParking = [
  {
    name: 'stall',
    displayName: 'Stall',
    example: '1',
    mustBeMatched: true,
    valueCannotBeBlank: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'price',
    displayName: 'Price',
    example: '1000',
    mustBeMatched: true,
    valueCannotBeBlank: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'parkingType',
    displayName: 'Parking Type',
    example: 'Regular',
    // mustBeMatched: true,
    // valueCannotBeBlank: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'allocationType',
    displayName: 'Allocation Type',
    example: 'UNITS',
    // mustBeMatched: true,
    // valueCannotBeBlank: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'parkingLevel',
    displayName: 'Parking Level',
    example: 'P1',
    // mustBeMatched: true,
    // valueCannotBeBlank: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'floorNumber',
    displayName: 'Floor Number',
    example: '10',
    // mustBeMatched: true,
    // valueCannotBeBlank: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'unit',
    displayName: 'Unit',
    example: '101',
    // mustBeMatched: true,
    // valueCannotBeBlank: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'status',
    displayName: 'Status',
    example: 'open',
    // mustBeMatched: true,
    // valueCannotBeBlank: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'paymentStatus',
    displayName: 'Payment Status',
    example: 'pending',
    // mustBeMatched: true,
    // valueCannotBeBlank: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'signatureStatus',
    displayName: 'Signature Status',
    example: 'pending',
    // mustBeMatched: true,
    // valueCannotBeBlank: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'paymentReceivedBy',
    displayName: 'Payment Received By',
    example: '2023-05-04T13:09:56.399Z',
    // mustBeMatched: true,
    // valueCannotBeBlank: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'paymentReceivedAt',
    displayName: 'Payment Received At',
    example: '2023-05-04T13:09:56.399Z',
    // mustBeMatched: true,
    // valueCannotBeBlank: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'inBuilding',
    displayName: 'In Building',
    example: 'true',
    // mustBeMatched: true,
    valueCannotBeBlank: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'building',
    displayName: 'Building',
    example: '1818 Pacifica',
    mustBeMatched: true,
    valueCannotBeBlank: true,
    validationFormat: { type: 'string' }
  }
]


const AddBulkParkings = ({ show, onSuccess, setShow, building }) => {
  const buttonStyle = {
    backgroundColor: '#EDDF65',
    height: '45px',
    paddingLeft: '24px',
    paddingRight: '24px'
  }

  const [isCSVModalDisplay, setIsCSVModalDisplay] = useState(false)
  const handleDownload = async () => {
    try {
      const response = await api.get(
        `/parking/${building}/xlsx-file`,
        {},
        { responseType: 'blob' }
      )
      if (response.ok) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'ExampleFile.xlsx')
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
      } else {
        console.error('Error fetching file:', response.status)
      }
    } catch (error) {
      console.error('Error fetching file:', error)
    }
  }

  const renderButton = (openModal) => {
    return (
      <>
        {
          !isCSVModalDisplay
          && <CustomButton
            handleClick={() => {
              openModal()
              setIsCSVModalDisplay(true)
            }}
            className='m-auto'
          >
            <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
              Start importing bulk parkings
            </span>
          </CustomButton>
        }
      </>
    )
  }

  const handleClose = () => {
    setShow(false)
    setIsCSVModalDisplay(false)
    onSuccess()
  }

  return (
    <Modal
      open={show}
      onClose={handleClose}
      aria-labelledby='Add Or Edit Bulk Parking Modal'
      aria-describedby='Add Or Edit Bulk Parking description'
    >
      <div
        className='relative flex flex-col font-openSans bg-grey-8 p-10 rounded shadow-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'
        style={{ width: 'calc(100% - 50px)', height: 'calc(100% - 50px)' }}
      >
        <button className='absolute right-10' onClick={handleClose}>
          <CrossIcon />
        </button>

        <h1 className='font-bold text-xl text-center'>Bulk Upload Parking</h1>
        <CustomButton handleClick={handleDownload} className='w-fit'>
          <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
            Download Example
          </span>
        </CustomButton>
        <div className='relative w-full h-full flex justify-center items-center'>
          <UseCSV
            downloadExampleButton={false}
            importerKey={process.env.REACT_APP_BULK_PARKING_IMPORTER_KEY}
            dynamicColumns={dynamicColumnsForParking}
            render={(openModal) => renderButton(openModal)}
            importerDisplay='inline'
            onClose={handleClose}
            metadata={{
              buildingId: building
            }}
          />
          {
            isCSVModalDisplay &&
            <div
              style={{
                position: 'relative',
                width: '100%',
                height: '100%',
                marginTop: '20px',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              id='usecsv-importer-inline-wrapper'
            />
          }
        </div>
      </div>
    </Modal>
  )
}

export default AddBulkParkings
