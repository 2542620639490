// @ts-nocheck
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { getProjectUnits } from 'store/actions/unitActions'

const useGetProjectUnits = (projectId = '') => {
  // const mountedRef = useRef(true)
  let appProject = useSelector((state) => state.appReducer.appProject)
  appProject = appProject?.length > 0 ? appProject : projectId
  const [units, setUnits] = useState([])
  const [soldUnits, setSoldUnits] = useState([])
  const [availableUnits, setAvailableUnits] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (appProject) {
      getData()
    }
  }, [appProject])

  const getData = async () => {
    // if (!mountedRef.current) return null
    setLoading(true)
    try {
      const data = await getProjectUnits(appProject, projectId?.length > 0)
      // if (!mountedRef.current) return null
      setUnits(data)
      setLoading(false)

      const soldData = data.filter((_unit) =>
        _unit.status.toLowerCase().includes('sold')
      )
      setSoldUnits([...soldData])
      const availableData = data.filter(
        (_unit) => !_unit.status.toLowerCase().includes('sold')
      )
      setAvailableUnits([...availableData])
    } catch (err) {
      // if (!mountedRef.current) return null
      setLoading(false)
    }
  }
  const unitsLoading = loading
  return { unitsLoading, units, soldUnits, availableUnits, refetchUnits: getData }
}

export default useGetProjectUnits
