import { removeLastCommaFromStrings } from './cleaners'
import { dateDiffInDays } from './dateUtils'
import { pluralWords } from './formatters'

export const listOfStatus = {
  ReservedBroker: { value: 'Reserved for another Broker', primary: 'blue-500', secondary: 'white' },
  ReservedBuyer: { value: 'Reserved for another Buyer', primary: 'purple-500', secondary: 'white' },
  ReservedMe: { value: 'Reserved for you', primary: 'cyan-500', secondary: 'white' },
  Open: { value: 'Open', primary: 'green-500', secondary: 'white' },
  DeveloperHeld: { value: 'Developer Held', primary: 'red-500', secondary: 'white' },
  SoldCommitted: { value: 'Sold - Committed', primary: 'orange500', secondary: 'white' },
  SoldFirm: { value: 'Sold - Firm', primary: 'red-500', secondary: 'white' },
  Closed: { value: 'Closed', primary: 'gray-700', secondary: 'white' }
}

export const getUnitStatus = (unit, hasPermission, loggedUserId, showAnotherWord = true, fixedStatus = '') => {
  let status = ''

  const { status: unitStatus } = unit
  status = fixedStatus || unitStatus

  let tmpStatus = listOfStatus[status].value

  const today = new Date()

  let tmpReservedAt = ''
  let tmpReservedUntil = ''
  let reservationDuration = ''
  let calcReservationDuration = false
  let reservedMe = false

  if (hasPermission) {
    if (status === 'ReservedBuyer') {
      let buyerRequester = ''

      if (unit.reservedTo) {
        const { reservedTo, reservedUntil, buyerReservedAt } = unit

        buyerRequester = reservedTo.reduce((ac, { firstName, lastName }) => `${ac} ${firstName} ${lastName}, `, '')
        buyerRequester = removeLastCommaFromStrings(buyerRequester)

        tmpReservedAt = buyerReservedAt
        tmpReservedUntil = reservedUntil
        calcReservationDuration = true

        tmpStatus = `${tmpStatus.replace('another Buyer', buyerRequester)}`
      }
    }

    if (status === 'ReservedBroker') {
      const { brokerAssigned, brokerAssignedUntil, brokerReservedAt } = unit

      if (brokerAssigned.filter((broker) => broker === loggedUserId).length) {
        tmpStatus = 'Reserved for you'
        reservedMe = true
      }

      tmpReservedAt = brokerReservedAt
      tmpReservedUntil = brokerAssignedUntil
      calcReservationDuration = true
    }

    if (calcReservationDuration) {
      const durationDurationDays = dateDiffInDays(tmpReservedAt, tmpReservedUntil)
      const remainingDays = dateDiffInDays(today, tmpReservedUntil)

      const remainingDaysDescription = remainingDays
        ? `${remainingDays} ${pluralWords(remainingDays, 'day')} remaining`
        : 'it will expire today!'

      reservationDuration =
        `for ${durationDurationDays} ${pluralWords(durationDurationDays, 'day')} - ${remainingDaysDescription}`
    }
  }

  if (!showAnotherWord) {
    tmpStatus = tmpStatus.replace('another', '')
  }

  const objectReturn = Object.assign({ ...listOfStatus[reservedMe ? 'ReservedMe' : status] }, { value: `${tmpStatus} ${reservationDuration}` })

  return objectReturn
}
