import React, { createContext } from 'react'
import propTypes from 'prop-types'

import useGetProjectUnits from 'hooks/useGetProjectUnits'
import useGetBuildings from 'hooks/useGetBuildigs'

import { CardView, ListView } from './components'
import UnitInventoryMapWrapper from 'pages/desktopMode/unitInventoryMap'

const LoadingContext = createContext(null)

const ProjectUnitsInventory = ({ viewType }) => {
  const { units, loading, refetchUnits } = useGetProjectUnits()
  const { buildings, loading: getBuildings } = useGetBuildings()

  return (
    <LoadingContext.Provider value={null}>
      {viewType === 'cardView' && (
        <CardView
          {...{ units, gettingUnits: loading, buildings, getBuildings, refetchUnits }}
        />
      )}
      {viewType === 'listView' && (
        <ListView
          {...{ units, gettingUnits: loading, buildings, getBuildings, refetchUnits }}
        />
      )}
      {viewType === 'mapView' && (
        <UnitInventoryMapWrapper />
      )}
    </LoadingContext.Provider>
  )
}

export default ProjectUnitsInventory

ProjectUnitsInventory.propTypes = {
  viewType: propTypes.string
}
