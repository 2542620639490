import React, { useState } from 'react'
import propTypes from 'prop-types'

import {
  MethodsWrapper,
  SelectedMethodWrapper
} from './styles'
import { FadedAnimatedDiv, Option, Stripe, Vopay } from 'components'
import { AnimatePresence } from 'framer-motion'
import { requestVopayUrl } from 'store/actions/paymentActions'
import { createOfferPayment } from 'store/actions/offerActions'
import { TCancelButton, TSaveButton } from 'components/DefaultButtons'

const CREDIT_CARD_PAYMENT_METHOD = 'CardHold'
const EFT_PAYMENT_METHOD = 'EFT'
const DIRECT_WIRE_PAYMENT_METHOD = 'DirectWire'
const MONEY_PAYMENT_METHOD = 'Money'
const CHECK_PAYMENT_METHOD = 'Check'
const WECHAT_PAYMENT_METHOD = 'WeChat'

// PAYMENT STATUS
const SHOWING_PAYMENT_METHODS = 'showing_payment_methods'
const SHOWING_STRIPE_COMPONENT = 'showing_stripe_component'
const SHOWING_VOPAY_COMPONENT = 'showing_vopay_component'

const options = {
  Card: { title: 'Credit Card', component: SHOWING_STRIPE_COMPONENT },
  CardHold: { title: 'Card Hold', component: SHOWING_STRIPE_COMPONENT },
  EFT: { title: 'Online Banking', component: SHOWING_VOPAY_COMPONENT },
  DirectWire: { title: 'Direct Wire', component: '' },
  Money: { title: 'Cash', component: '' },
  Check: { title: 'Check', component: '' },
  Payor: { title: 'Payor', component: '' },
  WeChat: { title: 'WeChat Pay', component: SHOWING_STRIPE_COMPONENT },
  '': { title: 'Select a Payment Method below', component: SHOWING_PAYMENT_METHODS }
}

const OptionsWrapper = ({ children }) => (
  <div className='grid grid-cols-1 md:grid-cols-2 gap-3 mt-3 md:mr-3 mx-3'>
    {children}
  </div>
)

const PaymentMethods = (props) => {
  const {
    selectedPaymentMethod, onSelectPaymentMethod,
    offer, executeWhenSuccess
  } = props

  const { _id: offerId, paymentRounds } = offer

  const [requestingVopayUrl, setRequestingVopayUrl] = useState(false)
  const [vopayUrl, setVopayUrl] = useState('')
  const [paymentStatus, setPaymentStatus] = useState(SHOWING_PAYMENT_METHODS)
  const [chargePrice, setChargePrice] = useState('')
  const [requestingPayment, setRequestingPayment] = useState(false)
  const [paymentData, setPaymentData] = useState('')
  const [succeeded, setSucceeded] = useState(false)

  const selectOption = (e) => {
    onSelectPaymentMethod(e.target.id)
  }

  const _requestVopayUrl = async () => {
    // If selected method is EFT, call the eft endpoints before create the payment
    if (selectedPaymentMethod === 'EFT') {
      setRequestingVopayUrl(true)
      const tmpVopayUrl = await requestVopayUrl()
        .catch(() => {
          setRequestingVopayUrl(false)
        })

      if (tmpVopayUrl) {
        setVopayUrl(tmpVopayUrl.url)
        setChargePrice(paymentRounds[0].value)
        setRequestingVopayUrl(false)

        if (options[selectedPaymentMethod].component) {
          setPaymentStatus(options[selectedPaymentMethod].component)
        }
      }
    }
  }

  const stripeStartProcess = () => {
    createFirstPaymentRound()
      .then(() => {
        if (options[selectedPaymentMethod].component) {
          setPaymentStatus(options[selectedPaymentMethod].component)
        }
      })
      .catch(() => {})
  }

  const createFirstPaymentRound = () => new Promise((resolve, reject) => {
    const { days, perc, value } = paymentRounds[0]

    setChargePrice(value)

    const payment = {
      days, perc, value, type: selectedPaymentMethod
    }

    setRequestingPayment(true)

    createOfferPayment({ offerId, payment })
      .then((response) => {
        setPaymentData(response)

        setRequestingPayment(false)
        resolve()
      })
      .catch(() => {
        setRequestingPayment(false)
        reject()
      })
  })

  const paymentMethodsClick = {
    Card: { onClick: stripeStartProcess },
    CardHold: { onClick: stripeStartProcess },
    EFT: { onClick: _requestVopayUrl },
    DirectWire: { onClick: () => console.log('You choose Direct Wire') },
    Money: { onClick: () => console.log('You choose Cash') },
    Check: { onClick: () => console.log('You choose Check') },
    Payor: { onClick: () => console.log('You choose Payor') },
    WeChat: { onClick: stripeStartProcess }
  }

  const _executeWhenSuccess = () => {
    setSucceeded(true)
    executeWhenSuccess()
  }

  const BackToPaymentMethodsButton = () => (
    <TCancelButton
      onClick={() => setPaymentStatus(SHOWING_PAYMENT_METHODS)}
      className='mt-5 px-6'
    >
      Back
    </TCancelButton>
  )

  return (
    <MethodsWrapper>
      <AnimatePresence exitBeforeEnter initial={false}>
        {
          paymentStatus === SHOWING_PAYMENT_METHODS && (
            <FadedAnimatedDiv key='paymentMethods'>
              <SelectedMethodWrapper>
                <AnimatePresence exitBeforeEnter initial={false}>
                  {
                    selectedPaymentMethod
                      ? (
                        <FadedAnimatedDiv
                          key='selectMethodBelow'
                        >
                          <TSaveButton
                            onClick={() => paymentMethodsClick[selectedPaymentMethod].onClick()}
                            loading={requestingVopayUrl || requestingPayment}
                            className='h-12 text-base'
                          >
                            {`Pay with: ${options[selectedPaymentMethod].title}`}
                          </TSaveButton>
                        </FadedAnimatedDiv>
                        )
                      : (
                        <FadedAnimatedDiv key='selectedMethodTitle' className='text-center'>
                          <span className='text-2xl'>{options[selectedPaymentMethod].title}</span>
                        </FadedAnimatedDiv>
                        )
                  }
                </AnimatePresence>
              </SelectedMethodWrapper>

              <OptionsWrapper>
                <Option
                  title='Online Banking'
                  id={EFT_PAYMENT_METHOD}
                  selected={selectedPaymentMethod === EFT_PAYMENT_METHOD}
                  onClick={selectOption}
                />
                <Option
                  title='Direct Wire'
                  id={DIRECT_WIRE_PAYMENT_METHOD}
                  selected={selectedPaymentMethod === DIRECT_WIRE_PAYMENT_METHOD}
                  onClick={selectOption}
                />
                <Option
                  title='Credit Card'
                  id={CREDIT_CARD_PAYMENT_METHOD}
                  selected={selectedPaymentMethod === CREDIT_CARD_PAYMENT_METHOD}
                  onClick={selectOption}
                />
                <Option
                  title='Cash'
                  id={MONEY_PAYMENT_METHOD}
                  selected={selectedPaymentMethod === MONEY_PAYMENT_METHOD}
                  onClick={selectOption}
                />
                <Option
                  title='Check'
                  id={CHECK_PAYMENT_METHOD}
                  selected={selectedPaymentMethod === CHECK_PAYMENT_METHOD}
                  onClick={selectOption}
                />
                <Option
                  title='WeChat Pay'
                  id={WECHAT_PAYMENT_METHOD}
                  selected={selectedPaymentMethod === WECHAT_PAYMENT_METHOD}
                  onClick={selectOption}
                />
              </OptionsWrapper>
            </FadedAnimatedDiv>
          )
        }

        {
          paymentStatus === SHOWING_STRIPE_COMPONENT && (
            <FadedAnimatedDiv
              key='stripeComponent'
              style={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <Stripe
                paymentData={paymentData}
                executeWhenError={() => setPaymentStatus(SHOWING_PAYMENT_METHODS)}
                executeWhenSuccess={_executeWhenSuccess}
                succeeded={succeeded}
                offer={offer}
                chargePrice={chargePrice}
                paymentMethod={selectedPaymentMethod}
              />
              <BackToPaymentMethodsButton />
            </FadedAnimatedDiv>
          )
        }

        {
          paymentStatus === SHOWING_VOPAY_COMPONENT && (
            <FadedAnimatedDiv
              key='vopayComponent'
              className='md:py-5'
              style={{
                display: 'flex',
                height: '100%',
                width: '100%',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <Vopay
                vopayUrl={vopayUrl}
                executeWhenSuccess={() => {
                  createFirstPaymentRound()
                    .then(() => {
                      _executeWhenSuccess()
                    })
                }}
                offer={offer}
                chargePrice={chargePrice}
              />
              <BackToPaymentMethodsButton />
            </FadedAnimatedDiv>
          )
        }
      </AnimatePresence>

    </MethodsWrapper>
  )
}

export default PaymentMethods

PaymentMethods.defaultProps = {
  selectedPaymentMethod: ''
}

PaymentMethods.propTypes = {
  selectedPaymentMethod: propTypes.number,
  onSelectPaymentMethod: propTypes.func,
  offer: propTypes.object,
  executeWhenSuccess: propTypes.func
}

OptionsWrapper.propTypes = {
  children: propTypes.node
}
