import React from 'react'
import clsx from 'clsx'

import getNameFirstLetters from 'utils/getNameFirstLetters'

import { TFilledLocationMarkerIcon } from 'components/Icons'
import CustomInput from 'components/CustomInput'

const UserInfoCard = ({ onSelect, userAvatar, firstName, lastName, email, location, selectDuration, isActive, className, onDurationChange, durationValue, backgroundColor = '' }) => {
  const name = `${firstName} ${lastName}`

  return (
    <div onClick={onSelect} className={clsx(`border-2 cursor-pointer bg-menuGray p-6 flex flex-col justify-center items-center rounded hover:bg-slate-200 transition-all duration-200 ${className}`, {
      'border-softBlack': isActive,
      'border-transparent': !isActive
    })}
    style={{ backgroundColor: backgroundColor.length > 0 && backgroundColor }}
    >
      {userAvatar
        ? <img src={userAvatar} alt="avatar" className='rounded-full w-16 h-16'/>
        : <div className='w-16 h-16 rounded-full bg-gray-1 flex items-center justify-center text-softBlack30 font-bold text-base uppercase'>
            {getNameFirstLetters(name)}
          </div>
      }
      <p className='text-softBlack text-lg font-bold m-0 mt-4'>{name}</p>
      <p className='text-softBlack70 text-sm m-0 mt-1.5'>{email}</p>
      {location && (
        <div className='flex gap-2 mt-4'>
          <TFilledLocationMarkerIcon className='h-4' />
          <p className='text-softBlack text-sm m-0'>{location}</p>
        </div>
      )}
      {selectDuration && (
        <div className='mt-2'>
          <CustomInput
                type='date'
                label={'From'}
                value={durationValue}
                onChange={onDurationChange}
                placeholder={'Select duration'}
                containerClasses={'w-60'}
                classes={undefined}
              />
        </div>
      )}
    </div>
  )
}

export default UserInfoCard
