import { Row, Col } from 'antd'
import standardStyle from 'assets/css/standardStyle'
import styled from 'styled-components'

export const PaymentSuccessWrapper = styled(Row)`
  height: 100%;
`

export const PaymentSuccessTextWrapper = styled(Col)`
  justify-content: center;
  display: flex;
  height: 100%;
  flex-direction: column;

  @media ${standardStyle.mediaqueries.sm}, ${standardStyle.mediaqueries.xs} {
    display: unset;
    height: unset;
  } 
`

export const PaymentSuccessPlantImageWrapper = styled(Col)`
  justify-content: center;
  display: flex;
  height: 100%;
  align-items: center;

  @media ${standardStyle.mediaqueries.sm}, ${standardStyle.mediaqueries.xs} {
    align-items: start;
    margin-top: 25px;
  } 
`
