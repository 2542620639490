import React from 'react'
import propTypes from 'prop-types'
import {
  HeaderLogo, SurveyStepWrapper, SurveySubTitle, SurveyFooter
} from './surveyComponents'

import { MailIcon } from '@heroicons/react/outline'

const RealtorNotified = ({ onClickContinue, selectedRealtor }) => (
    <SurveyStepWrapper>
      <HeaderLogo className='rounded-full w-20 h-20'/>

      <div className='w-88 bg-white rounded-md shadow-md flex flex-col items-center justify-center mt-20 py-5'>
        <div style={{ background: '#3f8f9d' }} className='rounded-full p-10'>
          <MailIcon className='h-18 w-18 text-white' />
        </div>

        <SurveySubTitle className='mt-5'>Realtor Notified!</SurveySubTitle>

        <span className='text-2xl text-center px-4 mt-5'>{`${selectedRealtor.firstName} has been contacted and informed of your preferences.`}</span>
        <SurveyFooter onClickContinue={onClickContinue} className='mb-0 mt-10' />
      </div>
    </SurveyStepWrapper>
)

export default RealtorNotified

RealtorNotified.propTypes = {
  onClickContinue: propTypes.func,
  selectedRealtor: propTypes.object
}
