/* eslint-disable no-unused-vars */
import React, { createContext, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { getAllDropDowns } from 'store/actions/usersActions'
import { STEP } from '../steps'

export const CreateBrokerOpportunityContext = createContext(null)

export const CreateBrokerOpportunityProvider = ({ children }) => {
  const [step, setStep] = useState(STEP.ONE)
  const [selectedBuyers, setSelectedBuyers] = useState([])
  const [dropDowns, setDropDowns] = useState([])
  // @ts-ignore
  // const projectId = useSelector((state) => state.appReducer.appProject)
  const [projectId] = useState('607605e70de444001a71cfc8')
  const fetchDropDowns = useRef(async () => { })

  fetchDropDowns.current = async () => {
    const data = await getAllDropDowns(projectId)
    setDropDowns(data)
  }

  useEffect(() => {
    fetchDropDowns.current()
  }, [])

  return (
    <CreateBrokerOpportunityContext.Provider
      value={{ step, setStep, selectedBuyers, setSelectedBuyers, dropDowns }}
    >
      {children}
    </CreateBrokerOpportunityContext.Provider>
  )
}
