import React from 'react'
import CustomModal from 'components/Modal'
import CustomInput from 'components/CustomInput'

const AllocationEnterNameModal = ({ open, setOpen, setAllocationName }) => {
  const closeModal = () => {
    setOpen(false)
  }

  const setName = (e) => {
    setAllocationName(e.target.value)
  }

  return (
    <CustomModal
      isOpen={open}
      closeModal={closeModal}
      contentLabel='Cancel Modal'
    >
      <div className='w-136 max-w-full p-10 text-center rounded bg-grey-8'>
        <h1 className='text-softBlack font-bold text-xl mb-6 font-openSans'>
          Allocation Name{' '}
        </h1>
        <CustomInput
          label={undefined}
          value={undefined}
          onChange={(e) => setName(e)}
          placeholder={'Enter Allocation Name'}
          containerClasses={undefined}
          classes={undefined}
        />
        <button
          className='rounded w-37.5 bg-volt text-base font-medium h-12 mt-6'
          onClick={closeModal}
        >
          Done
        </button>
      </div>
    </CustomModal>
  )
}

export default AllocationEnterNameModal
