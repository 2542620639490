/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import CustomModal from 'components/Modal'
import React, { useEffect, useState } from 'react'
import { Button, CircularProgress, Box } from '@material-ui/core'
import { buttonStyle } from './style'
import { useGetContactLists } from 'hooks/useGetContactLists'

const DeleteFromListModal = ({ isOpen, contact, onClose }) => {
  const { contactsLists, getContacts, loading } = useGetContactLists()
  const [listsWithContact, setListsWithContact] = useState([])
  const [checked, setChecked] = useState({})

  useEffect(() => {
    if (!isOpen) return setListsWithContact([])
    getContacts()
  }, [isOpen])

  useEffect(() => {
    setListsWithContact(contactsLists.filter(list => list?.users?.includes(contact?._id)))
  }, [contactsLists])

  return (
    <CustomModal
        isOpen={isOpen}
        closeModal={onClose}
    >
        {
          loading
            ? <div style={{ width: '600px', maxWidth: '600px', height: '400px' }} className='text-volt flex justify-center items-center'>
              <CircularProgress color="inherit" />
            </div>
            : <div style={{ width: '600px', maxWidth: '600px', maxHeight: '100%' }} className='flex flex-col gap-2 h-max-content p-4'>
            <>
              <div className='w-full text-center'>Please select the list names that you want to remove this contact from</div>
              <div className='w-full flex flex-row gap-2 flex-wrap'>
                {listsWithContact.map(list => <div className='p-2 flex flex-row gap-2'>
                  <input type='checkbox' onChange={(e) => setChecked({ ...checked, [list?._id]: e.currentTarget.checked })} />{list.title}
                </div>)}
              </div>
            </>
            <Button style={buttonStyle} variant="contained">Remove this contact from the selected list</Button>
          </div>
        }
    </CustomModal>
  )
}

export default DeleteFromListModal
