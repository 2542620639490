/* eslint-disable react-hooks/exhaustive-deps */
import { StatsHorizontal } from 'components/StatsHorizontal'
import React, { useEffect, useState } from 'react'
import {
  AgentsByRatingContainer,
  AllAgentsRatingByContactsRatingContainer,
  GrayText,
  TextBold
} from '../styles'

import { isMobile } from 'react-device-detect'

const RATINGS = [
  { name: 'Agent: B Warm', number: 5 },
  { name: 'Agent: C Mild', number: 9 },
  { name: 'Agent: D Cold', number: 4 },
  { name: 'Agent: New', number: 463 },
  { name: 'Agent: Not Qualified', number: 1 }
]

const AllAgentsRatingByContactsRating = () => {
  const AgentsRatingCard = ({ name = '', title = '', ratings = [] }) => {
    const [total, setTotal] = useState(0)

    const addRatings = () => {
      let totalTemp = 0
      ratings?.forEach((rating) => {
        totalTemp += rating?.number
      })
      if (totalTemp !== 0) {
        setTotal(totalTemp)
      }
    }

    useEffect(() => {
      addRatings()
    }, [])

    return (
      <AllAgentsRatingByContactsRatingContainer>
        <div className='p-6' style={{ width: isMobile ? '90%' : '' }}>
          <TextBold
            style={{ fontSize: 20, fontWeight: 700, lineHeight: '30px' }}
          >
            {name.length > 0 && name + ' - '}{' '}
            {title.length > 0 ? title : 'All Agents by Rating (static)'}
          </TextBold>
          <GrayText style={{ marginTop: 8 }}>Contacts by rating</GrayText>
          <div className="border-b-2 border-black mt-4" />
          <div className="mt-12">
            <div className="flex justify-between mx-2">
              <GrayText style={{ fontSize: 12, lineHeight: '18px' }}>
                RATING
              </GrayText>
              <GrayText style={{ fontSize: 12, lineHeight: '18px' }}>
                CONTACTS
              </GrayText>
            </div>
            <div className="border-b-2 border-menuGray mt-4" />
            <div className="flex flex-col mt-8" style={{ gap: 36 }}>
              {ratings.map((rating) => (
                <div className="flex justify-between">
                  <TextBold style={{ letterSpacing: '0.24px' }}>
                    {rating?.name}
                  </TextBold>
                  <TextBold>{rating?.number}</TextBold>
                </div>
              ))}
              <div className="flex justify-between">
                <TextBold style={{ letterSpacing: '0.24px' }}>Total</TextBold>
                <TextBold>{total}</TextBold>
              </div>
            </div>
          </div>
        </div>
      </AllAgentsRatingByContactsRatingContainer>
    )
  }

  const AgentsByRating = ({ ratings, title }) => {
    return (
      <AgentsByRatingContainer style={{ minHeight: 297, paddingLeft: 0, paddingRight: 0 }}>
        <TextBold style={{ fontSize: 20, lineHeight: '150%', fontWeight: 700, paddingLeft: 24 }}>
          {`${title} (static)`}
        </TextBold>
        <GrayText style={{ marginTop: 8, paddingLeft: 24 }}>Contacts by rating</GrayText>
        <div>
          <StatsHorizontal
            stats={[
              { units: 74.3, color: '#59B099', title: 'LEAD: NEW' },
              { units: 28.7, color: '#54B5D1', title: 'LEAD: NEW' },
              { units: 32, color: 'rgba(46, 43, 46, 0.3)', title: 'LEAD: NEW' },
              { units: 43, color: '#646264', title: 'LEAD: NEW' },
              { units: 68.8, color: '#DF6D50', title: 'LEAD: NEW' },
              { units: 22.2, color: '#7E969A', title: 'LEAD: NEW' },
              { units: 74.3, color: '#EDDF65', title: 'LEAD: NEW' }
            ]}
            title={''} classes={'justify-start gap-10 flex flex-wrap'} tabClasses={'w-1/4'}
            showPercentage
            />
          </div>
      </AgentsByRatingContainer>
    )
  }

  return (
    <div style={{ margin: isMobile ? '' : '64px 64px 0px 64px' }}>
      <div className="flex gap-6 flex-wrap m-w-full overflow-hidden">
        <AgentsRatingCard ratings={RATINGS} />
        <AgentsRatingCard name={'Bob'} ratings={RATINGS} />
        <AgentsRatingCard name={'Josh'} ratings={RATINGS} />
      </div>
      <AgentsByRating title={'All Agents by Rating'} ratings={RATINGS} />
      <div className='m-w-full overflow-hidden' style={{ marginTop: 40, flexGrow: 1 }}>
        <AgentsRatingCard
          title="All Agents by Interactions (static)"
          ratings={RATINGS}
        />
      </div>
      <AgentsByRating title={'All Agents by Interactions'} ratings={RATINGS} />
      <div style={{ marginTop: 64 }} className="flex flex-wrap gap-6 m-w-full overflow-hidden">
        <AgentsRatingCard
          name={'Bob'}
          ratings={RATINGS}
          title={'All Agents by Interactions (static)'}
        />
        <AgentsRatingCard
          name={'Josh'}
          ratings={RATINGS}
          title={'All Agents by Interactions (static)'}
        />
      </div>
    </div>
  )
}

export default AllAgentsRatingByContactsRating
