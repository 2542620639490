/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react'
import { getAllReferralAgents } from 'store/actions/usersActions'

const useGetReferralAgent = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  const getUsers = async () => {
    setLoading(true)
    getAllReferralAgents()
      .then((response) => {
        setData(
          response.sort((a, b) => {
            if (
              `${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}`
            ) {
              return 1
            } else if (
              `${a.firstName} ${a.lastName}` < `${b.firstName} ${b.lastName}`
            ) {
              return -1
            }
            return 0
          })
        )
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return { data, loading, refetch: getUsers }
}

export default useGetReferralAgent
