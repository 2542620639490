import { useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { setReservationDetails } from 'store/actions/reservationActions'

const useCreateReservation = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  // @ts-ignore
  const userObject = useSelector((state) => state.authReducer.userObject)

  const callApi = useCallback(
    async (projectId, NewReservation) => {
      setLoading(true)
      try {
        const updatedObject = {
          ...NewReservation,
          primaryBuyer: NewReservation?.name,
          project: projectId,
          createdBy: userObject?._id,
          paymentMethod: NewReservation?.paymentMethod,
          contractLanguage: NewReservation?.contractLanguage,
          address: NewReservation?.address,
          email: NewReservation?.email,
          notes: NewReservation.notes,
          bankNumber: NewReservation.bankNumber,
          nameOnAccount: NewReservation.accountName,
          bankName: NewReservation.bankName,
          currencyType: NewReservation.currency,
          clabe: NewReservation.clabe,
          phoneNumber: NewReservation.phoneNumber,
          reservationHolders: NewReservation.reservationHolders,
          paymentStatus: NewReservation?.paymentStatus ?? '',
          paymentReceivedDate: NewReservation?.paymentReceivedDate,
          paymentReceivedBy: NewReservation?.paymentReceivedBy ?? '',
          paymentAmount: NewReservation?.paymentAmount
        }
        if (updatedObject) {
          const filteredObj = Object.keys(updatedObject).reduce((acc, key) => {
            if (updatedObject[key] !== undefined) {
              acc[key] = updatedObject[key]
            }
            return acc
          }, {})
          const response = await setReservationDetails(filteredObj)
          setData(response)
        }
      } catch (e) {
        console.log('Failed to load data for', e)
      }
      setLoading(false)
    },
    [userObject]
  )

  return [callApi, loading, data]
}

export default useCreateReservation
