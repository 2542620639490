// @ts-nocheck
import React, { useState } from 'react'
import { Modal } from '@material-ui/core'
import clsx from 'clsx'
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg'
import { ReactComponent as BulkContact } from 'assets/icons/bulkContact.svg'
import { ReactComponent as SingleContact } from 'assets/icons/singleContact.svg'
import { isMobile } from 'react-device-detect'
import CustomButton from 'components/CustomButton'

const CardRadio = ({ id, title, Icon, onClick, selected }) => {
  return (
    <div
      id={id}
      onClick={onClick}
      className={clsx(
        'group p-4 md:p-0 cursor-pointer flex flex-col w-full items-center justify-center h-[120px] bg-menuGray rounded overflow-hidden border',
        selected ? 'border-softBlack' : 'border-transparent'
      )}
    >
      {Icon && (
        <span className='px-1.5 text-softBlack flex items-center justify-center'>
          {Icon}
        </span>
      )}
      <span className='mt-4 font-light text-base text-softBlack italic'>
        {title}
      </span>
    </div>
  )
}

const ContactEditMethodModal = ({
  show,
  setShow,
  onMethodSelected = (selected) => { }
}) => {
  const [selectedRadio, setSelectedRadio] = useState('')

  const handleClose = () => {
    setShow()
  }

  return (
    <Modal
      open={show}
      onClose={handleClose}
      aria-labelledby='Contact Edit Method Selection Modal'
      aria-describedby='Contact Edit Method Selection Modal description'
    >
      <div
        className='font-openSans relative bg-white p-5 md:p-7 lg:p-10 text-center flex flex-col items-center justify-center text-softBlack rounded shadow-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 overflow-hidden'
        style={{
          width: isMobile ? '95%' : 1015,
          maxHeight: isMobile ? '95vh' : 400
        }}
      >
        <button className='absolute right-5 md:right-7 lg:right-10 top-5 md:top-7 lg:top-10' onClick={handleClose}>
          <CrossIcon />
        </button>
        <h1 className='font-bold text-xl text-softBlack'>New Contact</h1>
        <p className='font-normal text-base text-softBlack mt-2 md:mt-8'>
          Choose the method you want to use to create the buyer
        </p>
        <div className='w-full flex flex-col md:flex-row md:mx-10 mt-2 md:mt-8 gap-4 md:gap-x-6'>
          <CardRadio
            id='create-buyer'
            key='Buyer'
            title='Create Buyer'
            selected={selectedRadio === 'Buyer'}
            onClick={() => {
              setSelectedRadio('Buyer')
            }}
            Icon={<SingleContact />}
          />
          <CardRadio
            id='create-broker'
            key='LeadBroker'
            title='Create Broker'
            selected={selectedRadio === 'LeadBroker'}
            onClick={() => {
              setSelectedRadio('LeadBroker')
            }}
            Icon={<SingleContact />}
          />
          <CardRadio
            id='create-referral-agent'
            key='referral'
            title='Referral Agent'
            selected={selectedRadio === 'referral'}
            onClick={() => {
              setSelectedRadio('referral')
            }}
            Icon={<SingleContact />}
          />
          <CardRadio
            id='create-bulk'
            key='bulk'
            title='Bulk Upload Create Contacts'
            selected={selectedRadio === 'bulk'}
            onClick={() => {
              setSelectedRadio('bulk')
            }}
            Icon={<BulkContact />}
          />
        </div>
        <div className='flex mt-8 justify-center'>
          <CustomButton
            id='new-contact-continue-button'
            className='w-fit mx-auto'
            handleClick={() => {
              handleClose()
              onMethodSelected(selectedRadio)
            }}
          >
            <span className='font-medium py-2 px-6 text-base flex gap-2 items-center'>
              Continue
            </span>
          </CustomButton>
        </div>
      </div>
    </Modal >
  )
}

export default ContactEditMethodModal
