import React, { useContext, useEffect, useState } from 'react'
import { DynamicDropdownContext } from '../context'

const Search = () => {
  const { setSearch } = useContext(DynamicDropdownContext)
  const [text, setText] = useState('')

  useEffect(() => {
    const id = setTimeout(() => setSearch(text), 500)
    return () => clearTimeout(id)
  }, [text, setSearch])

  return (
    <div className='flex flex-row gap-2 justify-center items-center w-full p-2'>
      <input
        value={text}
        onChange={(e) => setText(e.target.value)}
        placeholder='Search'
      />
    </div>
  )
}

export default Search
