import React from 'react'
import propTypes from 'prop-types'

const BuyerPageWithImage = ({ children, image }) => {
  return (
    // <div className='h-full w-full grid grid-cols-1 md:grid-cols-24 min-h-0 min-w-0' id='BuyerPageWithImage'>
    //   <div className='col-span-1 md:col-span-12 h-full w-full overflow-y-auto'>
    //     {children}
    //   </div>
    //   <div className='col-span-1 md:col-span-12 overflow-hidden min-w-0'>
    //     <img src={image} className='h-full w-full object-cover' alt='Project' />
    //   </div>
    // </div>
    <div className='h-full w-full flex-col-reverse flex md:flex-row min-h-0 min-w-0' id='BuyerPageWithImage'>
      <div className='flex-1 h-full w-full overflow-y-auto'>
        {children}
      </div>
      <div className='flex-none h-20 md:flex-1 md:h-full overflow-hidden min-w-0 sticky top-0'>
        <img src={image} className='h-full w-full object-cover' alt='Project' />
      </div>
    </div>
  )
}

export default BuyerPageWithImage

BuyerPageWithImage.propTypes = {
  children: propTypes.node,
  image: propTypes.object
}
