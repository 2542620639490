import React from 'react'

const BuyerCard = ({ buyer, onClick = (buyer) => {}, selectedBuyerId }) => {
  return (
    <div onClick={() => onClick(buyer)} style={{ height: '150px', minWidth: '300px' }} className={`cursor-pointer bg-gray-2 rounder flex flex-col rounded justify-center items-center flex-1 ${selectedBuyerId === buyer?._id ? 'border-2 border-black' : ''}`}>
        <div style={{ height: '70px', width: '70px', borderRadius: '100vh' }} className='flex justify-center items-center bg-gray-1 text-black font-bold mb-1'>
          {`${buyer?.firstName?.[0]} ${buyer?.lastName?.[0]}`}
        </div>
        <div className='text-lg font-bold'>{`${buyer?.firstName} ${buyer?.lastName}`}</div>
        <div className='text-grey-3'>{buyer?.email}</div>
    </div>
  )
}

export default BuyerCard
