import React from 'react'
import { connect } from 'react-redux'

const AdminLeadReport = (props) => {
  return (
    <div className='w-full relative px-7 py-4 '>
      <div className='flex gap-10 w-full'>
        <div>
          <div>
            <h3 className=' text-4xl font-bold'>BROKER PROFILE</h3>
          </div>
          <div className='bg-white flex-col  max-w-[360px] flex gap-[18px] px-6 py-6 rounded border border-black h-fit '>
            <div className='flex gap-6'>
              <img
                src='/shannon-mckenzie.png'
                alt='Mask group'
                className='rounded-full h-28 w-28'
              />
              <div className='bg-[#EDDF65] py-[17px] px-6'>
                <div className='font-bold text-[20px]'>A</div>
                <div className='italic font-bold text-[12px] '>
                  BROKER RATING
                </div>
              </div>
            </div>

            <div className='text-2xl leading-[33px] font-bold text-[#2e2b2e]  h-[37px] w-[258px]'>
              Shannon Mckenzie
            </div>
            <div className='w-full flex items-center gap-1 text-base text-[#2e2b2e] '>
              <div>smckenzie@sothebys.com</div>
            </div>
            <div className='w-full flex items-center gap-1 text-base text-[#2e2b2e] '>
              <div>604-132-4491</div>
            </div>
            <div className='w-full flex items-center gap-1 text-base text-[#2e2b2e] '>
              <div>Vancouver, BC, Canada</div>
            </div>
            <img
              className='rounded-[0.8946173787117004px] object-cover h-[60px] w-[102px]'
              alt='116722798_10157593294621485_2505944590931644383_n 1'
              src='/sotheby-canada.png'
            />
            <div className='flex gap-4'>
              <a href='/'>
                <img src='/facebook.png' />
              </a>
              <a href='/'>
                <img src='/instagram.png' />
              </a>
              <a href='/'>
                <img src='/twitter.png' />
              </a>
              <a href='/'>
                <img src='/linked-in.png' />
              </a>
            </div>

            <div className='w-full flex items-center gap-1 text-sm text-[#2e2b2e] '>
              <div className='leading-[21px]'>Vancouver, BC, Canada</div>
            </div>
            <>
              <div className='w-full flex justify-between items-center pl-1 text-base font-bold text-[#2e2b2e] '>
                <div>ACTIVE PROJECTS</div>
                <button>
                  <img src='/up-arrow.svg' alt='expand_less' />
                </button>
              </div>
              <div className='bg-[#f2f2f1] flex-col w-full flex gap-4 px-4 py-4 border border-[#2e2b2e] text-base text-[#2e2b2e] '>
                <div className='w-full flex items-center gap-2'>
                  <input type='checkbox' />
                  <div>1818 Pacifica - Vancouver</div>
                </div>
                <div className='w-full flex items-center gap-2'>
                  <input type='checkbox' />
                  <div>Aba Square - Metrotown</div>
                </div>
              </div>
            </>
            <>
              <div className='w-full flex justify-between items-center pl-1 text-base font-bold text-[#2e2b2e] '>
                <div>PAST PROJECTS</div>
                <button>
                  <img src='/up-arrow.svg' alt='expand_less' />
                </button>
              </div>
              <div className='bg-[#f2f2f1] flex-col w-full flex gap-4 px-4 py-4 border border-[#2e2b2e] text-base text-[#2e2b2e] '>
                <div className='w-full flex items-center gap-2'>
                  <input type='checkbox' />
                  <div>1112 Hute - North Vancouver</div>
                </div>
                <div className='w-full flex items-center gap-2'>
                  <input type='checkbox' />
                  <div>East 110 North Vancouver</div>
                </div>
              </div>
            </>
          </div>
        </div>
        <div className=' w-full'>
          <div className='flex gap-6'>
            <h3 className=' text-4xl font-bold'>360 View</h3>
            <div>
              <select className='border-[1px] border-[#2e2b2e] w-48 rounded-[4px] py-3 pl-4'>
                <option>All Time</option>
              </select>
            </div>
          </div>
          <div className='grid grid-cols-12 col-span-12 gap-10  justify-between'>
            <div className='col-span-3 bg-white z-99 px-5 py-4 rounded-[4px]  flex flex-col top-20 justify-between  h-44'>
              <div>
                <img src='/commision.svg' />
              </div>
              <div className='flex flex-col'>
                <div className='text-[12px] italic font-[300] uppercase'>
                  TOTAL COMMISION
                </div>
                <div className='text-[#2E2B2E] font-bold text-[20px]'>
                  CA$ 379,000
                </div>
              </div>
            </div>
            <div className='bg-white col-span-3 z-99 px-5 py-4 rounded-[4px]  flex flex-col top-20 justify-between  h-44'>
              <div>
                <img src='/total-sale.svg' />
              </div>
              <div className='flex flex-col'>
                <div className='text-[12px] italic font-[300] uppercase'>
                  TOTAL SALES
                </div>
                <div className='text-[#2E2B2E] font-bold text-[20px]'>
                  CA$15,182,000
                </div>
              </div>
            </div>
            <div className='bg-white col-span-3 z-99 px-5 py-4 rounded-[4px]  flex flex-col top-20 justify-between  h-44'>
              <div>
              <img src='/unit-sold.png' />
              </div>
              <div className='flex flex-col'>
                <div className='text-[12px] italic font-[300] uppercase'>
                  UNITS SOLD
                </div>
                <div className='text-[#2E2B2E] font-bold text-[20px]'>22</div>
              </div>
            </div>
            <div className='bg-white col-span-3  z-99 px-5 py-4 rounded-[4px]  flex flex-col top-20 justify-between  h-44'>
              <div>
                <img src='/pending-sales.png' />
              </div>
              <div className='flex flex-col'>
                <div className='text-[12px] italic font-[300] uppercase'>
                  Pending Sales
                </div>
                <div className='text-[#2E2B2E] font-bold text-[20px]'>8</div>
              </div>
            </div>
          </div>
          <div className='flex flex-col mt-8  p-6 bg-white '>
            <div className='text-[17px] font-bold'>All Project Activity</div>
            <div className='w-full '>
              <table className='mt-2 w-full whitespace-nowrap text-left'>
                <thead className='border-b italic relative text-[#2E2B2EB3] border-white/10 text-[12px] leading-6'>
                  <tr>
                    <th
                      scope='col'
                      className='py-2 pl-8  pr-8  uppercase font-semibold italic'
                    >
                      project
                    </th>
                    <th
                      scope='col'
                      className='py-2  pr-8  uppercase font-semibold italic'
                    >
                      leads
                    </th>
                    <th
                      scope='col'
                      className='py-2  pr-8  uppercase font-semibold italic'
                    >
                      sales
                    </th>
                    <th
                      scope='col'
                      className='py-2  pr-8  uppercase font-semibold italic'
                    >
                      Recinded offers
                    </th>
                  </tr>
                  <div className='absolute  bottom-0 h-[1px] w-full bg-[#2E2B2E]'></div>
                </thead>
                <tbody className='divide-y italic font-light text-[#2E2B2E] divide-[#2E2B2E]'>
                  <tr>
                    <td className='py-4 pr-8'>
                      <div className='flex items-center gap-x-4'>
                        1
                        <div className=' text-sm font-bold leading-6'>
                          Wynwood Green
                        </div>
                      </div>
                    </td>
                    <td className=' py-4 pl-0 text-[#2E2B2E] pr-4 sm:table-cell sm:pr-8'>
                      <div className='flex gap-x-3'>
                        <div className='text-sm leading-6'>48</div>
                      </div>
                    </td>
                    <td className='py-4 pl-0 pr-4 text-sm leading-6 sm:pr-8 lg:pr-20'>
                      <div className='flex items-center  gap-x-2 sm:justify-start'>
                        <div>9</div>
                      </div>
                    </td>
                    <td className=' py-4 pl-0 pr-8 text-sm leading-6md:table-cell lg:pr-20'>
                      4
                    </td>
                  </tr>
                  <tr>
                    <td className='py-4 pr-8'>
                      <div className='flex items-center gap-x-4'>
                        2
                        <div className=' text-sm font-bold leading-6'>
                          Station Square
                        </div>
                      </div>
                    </td>
                    <td className=' py-4 pl-0 text-[#2E2B2E] pr-4 sm:table-cell sm:pr-8'>
                      <div className='flex gap-x-3'>
                        <div className='text-sm leading-6'>22</div>
                      </div>
                    </td>
                    <td className='py-4 pl-0 pr-4 text-sm leading-6 sm:pr-8 lg:pr-20'>
                      <div className='flex items-center  gap-x-2 sm:justify-start'>
                        <div>6</div>
                      </div>
                    </td>
                    <td className=' py-4 pl-0 pr-8 text-sm leading-6md:table-cell lg:pr-20'>
                      2
                    </td>
                  </tr>
                  <tr>
                    <td className='py-4 pr-8'>
                      <div className='flex items-center gap-x-4'>
                        3
                        <div className=' text-sm font-bold leading-6'>Park</div>
                      </div>
                    </td>
                    <td className=' py-4 pl-0 text-[#2E2B2E] pr-4 sm:table-cell sm:pr-8'>
                      <div className='flex gap-x-3'>
                        <div className='text-sm leading-6'>18</div>
                      </div>
                    </td>
                    <td className='py-4 pl-0 pr-4 text-sm leading-6 sm:pr-8 lg:pr-20'>
                      <div className='flex items-center  gap-x-2 sm:justify-start'>
                        <div>5</div>
                      </div>
                    </td>
                    <td className=' py-4 pl-0 pr-8 text-sm leading-6md:table-cell lg:pr-20'>
                      1
                    </td>
                  </tr>
                  <tr>
                    <td className='py-4 pr-8'>
                      <div className='flex items-center gap-x-4'>
                        4
                        <div className=' text-sm font-bold leading-6'>
                          South Yards
                        </div>
                      </div>
                    </td>
                    <td className=' py-4 pl-0 text-[#2E2B2E] pr-4 sm:table-cell sm:pr-8'>
                      <div className='flex gap-x-3'>
                        <div className='text-sm leading-6'>7</div>
                      </div>
                    </td>
                    <td className='py-4 pl-0 pr-4 text-sm leading-6 sm:pr-8 lg:pr-20'>
                      <div className='flex items-center  gap-x-2 sm:justify-start'>
                        <div>2</div>
                      </div>
                    </td>
                    <td className=' py-4 pl-0 pr-8 text-sm leading-6md:table-cell lg:pr-20'>
                      0
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div>
            <div className='text-[24px] py-2 font-bold'>
              Recent Sales - 1818 Pacifica
            </div>
          </div>
          <div className='flex justify-between gap-4 w-full'>
            <div className='flex flex-col p-2  bg-white gap-y-4'>
              <div className=''>
                <img src='/unit-1201.png' />
              </div>
              <div className='w-full h-[1px] bg-black'></div>
              <div className='text-[#2E2B2E] flex flex-col gap-y-1'>
                <div>
                  <button className='bg-[#EDDF65] italic font-light text-[10px]'>
                    SOLD FIRM
                  </button>
                </div>
                <div className='text-[18px] font-bold'>Unit #1201</div>
                <div className='text-[16px] flex items-center gap-x-1'>
                  <div>
                    <img src='/residence-icon.png' />
                  </div>
                  <div>1818 Pacifica</div>
                </div>
              </div>
            </div>
            <div className='flex flex-col  p-2 bg-white gap-y-4'>
              <div className=''>
                <img src='/unit-801.png' />
              </div>
              <div className='w-full h-[1px] bg-black'></div>
              <div className='text-[#2E2B2E] flex flex-col gap-y-1'>
                <div>
                  <button className='bg-[#FFA500] italic font-light text-[10px]'>
                    RESCISSION PERIOD
                  </button>
                </div>
                <div className='text-[18px] font-bold'>Unit #801</div>
                <div className='text-[16px] flex items-center gap-x-1'>
                  <div>
                    <img src='/residence-icon.png' />
                  </div>
                  <div>1818 Pacifica</div>
                </div>
              </div>
            </div>
            <div className='flex flex-col  p-2 bg-white gap-y-4'>
              <div className=''>
                <img src='/unit-302.png' />
              </div>
              <div className='w-full h-[1px] bg-black'></div>
              <div className='text-[#2E2B2E] flex flex-col gap-y-1'>
                <div>
                  <button className='bg-[#EDDF65] italic font-light text-[10px]'>
                    SOLD FIRM
                  </button>
                </div>
                <div className='text-[18px] font-bold'>Unit #302</div>
                <div className='text-[16px] flex items-center gap-x-1'>
                  <div>
                    <img src='/residence-icon.png' />
                  </div>
                  <div>1818 Pacifica</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  userObject: state.authReducer.userObject
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(AdminLeadReport)
