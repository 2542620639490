import { api, ApiErrors } from 'services/api'
import { message } from 'antd'

export const getParkingPolygons = (id = '') =>
  new Promise((resolve, reject) => {
    api.get(`parking/polygon/${id.length ? id : 'all'}`).then((response) => {
      if (response.ok) {
        resolve(response.data.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const createOrUpdateParkingPolygon = (props) =>
  new Promise((resolve, reject) => {
    api.post(`parking/polygon/${props.parking._id}`, props).then((response) => {
      if (response.ok) {
        message.success(`${props.parking.stall} was created successfully`)
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const deleteParkingPolygon = (id) =>
  new Promise((resolve, reject) => {
    api.delete(`parking/polygon/${id}`).then((response) => {
      if (response.ok) {
        message.success('Parking-polygon deleted successfully')
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })
