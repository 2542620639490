import { api, ApiErrors } from 'services/api'

export const getLeadsCounter = (startDate, endDate, projectId) => new Promise((resolve, reject) => {
  const params = {
    startDate,
    endDate,
    projectId
  }

  api.post('dashboard/leads', params)
    .then((response) => {
      if (response.ok) {
        resolve(response.data.total)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const getLeadsByCountry = (startDate, endDate, projectId) => new Promise((resolve, reject) => {
  const params = {
    startDate,
    endDate,
    projectId
  }

  api.post('dashboard/country/leads', params)
    .then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const getUnitsDemand = (startDate, endDate, projectId) => new Promise((resolve, reject) => {
  const params = {
    startDate,
    endDate,
    projectId
  }

  api.post('dashboard/unit/unittypes', params)
    .then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const getTopBrokers = (startDate, endDate, projectId) => new Promise((resolve, reject) => {
  const params = {
    startDate,
    endDate,
    projectId
  }

  api.post('dashboard/broker/leaderboard', params)
    .then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const getTopFloorPlans = (startDate, endDate, projectId) => new Promise((resolve, reject) => {
  const params = {
    startDate,
    endDate,
    projectId
  }

  api.post('dashboard/unit/floorplans', params)
    .then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const getRealTimeVisitors = () => new Promise((resolve, reject) => {
  api.get('dashboard/visitors/realtime')
    .then((response) => {
      if (response.ok) {
        resolve(response.data.realTimeUsers)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const getVisitorsInAPeriod = (startDate, endDate, projectId) => new Promise((resolve, reject) => {
  const params = {
    startDate,
    endDate,
    projectId
  }

  api.post('dashboard/visitors/timeseries', params)
    .then((response) => {
      if (response.ok) {
        resolve(response.data.results)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})
