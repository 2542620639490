import React, { useState } from 'react'
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar
} from 'recharts'
import moment from 'moment'

import Selection from './Selection'

import { data, timeOptions, countriesOptions } from './constants'

const CustomTooltip = (data) => {
  const { active, payload } = data
  if (active && payload && payload.length) {
    return (
      <div
        style={{ marginLeft: -25, marginTop: -10 }}
        className="relative flex w-max flex-col items-center justify-center rounded bg-grey-8 px-4 py-2 shadow-lg z-100"
      >
        <p className="text-softBlack top-4 z-10 m-0 p-0 text-base font-medium">
          {`${payload[0].value} leads`}
        </p>
        <p
          style={{
            backgroundColor: 'white',
            position: 'absolute',
            bottom: -20,
            transform: 'rotate(45deg)',
            height: 12,
            width: 12
          }}
        ></p>
      </div>
    )
  }
  return null
}

export const Chart = () => {
  const [time, setTime] = useState(timeOptions?.[0]?.value)
  const [country, setCountry] = useState(countriesOptions?.[0]?.value)
  const [posData, setPosData] = useState({})

  return (
    <div className="bg-grey-8 p-6 rounded">
      <div className='pb-4 mb-4 border-b-2 border-softBlack flex justify-between items-center'>
        <h1 className="font-bold text-xl mb-0 capitalize">
          {`Total Leads by ${time}`}
        </h1>
        <div className='flex gap-6'>

          <Selection value={country} onChangeValue={(e) => setCountry(e.target.value)} Options={countriesOptions} />
          <Selection value={time} onChangeValue={(e) => setTime(e.target.value)} Options={timeOptions}/>
        </div>
      </div>
      <ResponsiveContainer width="100%" height={242}>
        <BarChart data={data}>
          <CartesianGrid
            strokeDasharray="2"
            horizontal={true}
            vertical={false}
          />
          <XAxis tickFormatter={(timestamp) => moment(timestamp).format('DD MMM')} dataKey="timestamp" axisLine={false} tickLine={false} padding={{ left: 5, right: 5 }} stroke="#2E2B2E" />
          <YAxis width={30} axisLine={false} tickLine={false} tickCount={6} stroke="#2E2B2E" />
          <Tooltip
            content={<CustomTooltip />}
            cursor={false}
            position={{ x: posData.x, y: posData.y - 40 }}
          />
          <Bar
            dataKey="value"
            fill="#2E2B2E"
            radius={[40, 40, 40, 40]}
            onMouseOver={(data) => {
              setPosData(data)
            }}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}
