// @ts-nocheck
import React from 'react'
import { Modal } from '@material-ui/core'
import { X } from 'lucide-react'
import ReservationDocuments from 'pages/buyerSignReservationContract/ReservationDocuments'
import { isMobile } from 'react-device-detect'
import { useSelector } from 'react-redux'

const ContractSigningModal = ({ showingContracts, setShowingContracts, gettingContracts, reservationId, onClose }) => {
  const userSelector = state => state.authReducer.userObject
  const userObject = useSelector(userSelector)

  return (
    <Modal
      open={showingContracts}
      onClose={() => setShowingContracts(false)}
      aria-labelledby='Reservation Documents'
      aria-describedby='Reservation documents list'
    >
      <div
        className={`font-openSans relative bg-white py-0 rounded shadow-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-[calc(100%-5px)] overflow-auto ${isMobile ? 'w-full' : 'w-[calc(100%-5px)]'}`}
      >
        <button
          className='absolute right-6 top-6.5'
          onClick={() => setShowingContracts(false)}
        >
          <X />
        </button>
        <ReservationDocuments
          userObject={userObject}
          setCloseDocument={onClose}
          reservationId={reservationId}
        />
      </div>
    </Modal>
  )
}

export default ContractSigningModal
