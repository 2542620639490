import { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getProjects } from 'store/actions/projectActions'
import { changeCurrencyType } from 'utils/formatters'

const useProjectChange = () => {
  const projectSelector = (state) => state.appReducer.appProject
  const appProject = useSelector(projectSelector)

  const onProjectChange = useCallback((projectId) => {
    getProjects(projectId).then((response) => {
      changeCurrencyType(response.totalDollarValueCurrencyType)
    })
  }, [])

  const onCurrencyChange = useCallback((currencyType) => {
    changeCurrencyType(currencyType)
  }, [])

  useEffect(() => {
    if (appProject) {
      onProjectChange(appProject)
    }
  }, [appProject])

  return { onProjectChange, onCurrencyChange }
}

export default useProjectChange
