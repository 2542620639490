import {
  CREATE_PROJECT_MANAGE_SUBDRAWER,
  CREATE_PROJECT_CLOSE_SUBDRAWER,
  CREATE_PROJECT_MANAGE_THIRDRAWER,
  CREATE_PROJECT_CLOSE_THIRDRAWER
} from 'store/actions/actionTypes'

export const initialState = {
  subDrawerStatus: {
    subDrawerObject: '',
    subDrawerTitle: '',
    subDrawerEditingId: 0,
    showSubDrawer: false
  },
  thirdDrawerStatus: {
    thirdDrawerObject: '',
    thirdDrawerTitle: '',
    thirdDrawerEditingId: 0,
    showThirdDrawer: false
  }
}

const createProjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_PROJECT_MANAGE_SUBDRAWER:
      return {
        ...state,
        subDrawerStatus: { ...action.payload }
      }

    case CREATE_PROJECT_CLOSE_SUBDRAWER:
      return initialState

    case CREATE_PROJECT_MANAGE_THIRDRAWER:
      return {
        ...state,
        thirdDrawerStatus: { ...action.payload }
      }

    case CREATE_PROJECT_CLOSE_THIRDRAWER:
      return {
        ...state,
        thirdDrawerStatus: { ...initialState.thirdDrawerStatus }
      }
    default: return state
  }
}

export default createProjectReducer
