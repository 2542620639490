import React from 'react'
import LeadsRegistrationForm from 'components/LeadsRegistrationForm'
import { useParams } from 'react-router-dom'

const HiddenHillsWebsiteForm = () => {
  const { leadSource } = useParams()

  return (
    <LeadsRegistrationForm
      leadSource={leadSource ?? 'Website'}
      projectName='Hidden Hills'
      project='669618bd196a0f0297cf52f8'
    />
  )
}

export default HiddenHillsWebsiteForm
