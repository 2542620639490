import ProjectImages from '../components/ProjectImages'
import React from 'react'

const AddImages = ({ setStep }) => {
  return (
    <ProjectImages setStep={setStep} />
  )
}

export default AddImages
