import React, { useEffect, useState, useCallback } from 'react'
import propTypes from 'prop-types'
import { BuyerPageWithImage, FadedAnimatedDiv, LottieWrapper, UnitSummarizedData } from 'components'
import { AnimatePresence } from 'framer-motion'
import { InputLabel, TButton } from 'ui'
import { UnitWrapper } from './styles'
import { formatDate } from 'utils'
import { BuyerButton, BuyerTitle } from './standardComponents'
import loading from 'assets/lottieJsons/loading.json'
import { BUYER_DASHBOARD } from 'routes'
import { useHistory } from 'react-router'

const UnitFeature = ({ children }) => (
  <li style={{ textIndent: '-0.4em', marginBottom: '0.5em' }}>{children}</li>
)

const offerStatus = {
  SoldFirm: 'Sold Firm',
  Signed: 'First payment',
  Paid: 'Paid'
}

const CenteredContent = ({ children, key }) => (
  <FadedAnimatedDiv
    key={key}
    className='h-full w-full text-center flex flex-col items-center justify-center'
  >
    {children}
  </FadedAnimatedDiv>
)

const BuyerUnits = (props) => {
  const {
    buyerUnits, projects, projectData, selectedProject,
    buyerName, creationType, onChangeProject, onSelectUnit,
    selectedUnit, offerId, gettingUnits, gettingProjects
  } = props
  const [showUnits, setShowUnits] = useState(Boolean(Object.keys(selectedUnit).length) || false)

  const history = useHistory()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    // Check if has already an unit selected
    if (Object.keys(selectedUnit).length) {
      setShowUnits(true)
    }
  }, [selectedUnit])

  useEffect(() => {
    if (showUnits) {
      window.scrollTo(0, 0)
    }
  }, [showUnits])

  const getOfferButtonText = (status) => {
    let tmpText = ''
    tmpText = offerStatus[status]

    if (!tmpText) {
      tmpText = 'Sign the docs'
    }

    return tmpText
  }

  const renderUnits = () => {
    return buyerUnits
      .filter((element) => {
        const baseSource = element.unit || element

        return baseSource.building.project._id === selectedProject
      })
      .map((element, index) => {
        const baseSource = element.unit || element

        const {
          reservedUntil,
          _id
        } = baseSource

        const unitSelected = ((!offerId) || (offerId && _id === selectedUnit._id))

        return (
          <UnitWrapper
            key={index}
            className={`px-2 py-5
            ${unitSelected ? 'opacity-100' : 'opacity-60'}`}
          >
            <UnitSummarizedData
              unit={baseSource}
              mainButtonClick={
                !offerId
                  ? () => onSelectUnit(creationType === 'offerCreated' ? element : baseSource)
                  : null
              }
              mainButtonText={creationType === 'offerCreated' ? getOfferButtonText(element.status) : 'Choose this unit'}
              mainButtonSubText={reservedUntil ? `Until ${formatDate(reservedUntil, false)}` : ''}
              wrapperClassname=''
            />
          </UnitWrapper>
        )
      })
  }

  const renderWelcomeDiv = useCallback(() => {
    if (projectData) {
      const { logoImage, projectName } = projectData

      return (
        <>
          <img src={logoImage} className='max-h-56 w-56' alt='project logo' />
          <span className='text-4xl md:text-5xl text-black my-5'>{buyerName}, Congratulations.</span>
          <span className='mt-7 text-2xl'>We invite you to join our community of owners.</span>
          <span className='text-2xl'>Make {projectName} your home.</span>
          <BuyerButton
            onClick={() => setShowUnits(true)}
            className='mt-7 w-full'
          >
            {creationType === 'offerCreated' ? 'See your Offers' : 'See your reserved Units'}
          </BuyerButton>
        </>
      )
    }

    return <div />
  }, [projectData, buyerName, creationType])

  const renderProjectsInput = useCallback(() => {
    return (
      <InputLabel
        typeInput='select'
        value={selectedProject}
        onChange={(project) => onChangeProject(project)}
        data={projects}
        inputWrapperClassName='inline-flex w-52 h-7'
      />
    )
  }, [onChangeProject, projects, selectedProject])

  return (
    <AnimatePresence exitBeforeEnter>
      {
        (gettingProjects || gettingUnits) && (
          <CenteredContent
            key='buyerLoadingWrapper'
          >
            <span className='text-4xl'>Getting things ready...</span>
            <LottieWrapper
              animation={loading}
              loop
              autoplay
              className='max-w-2xl'
            />
          </CenteredContent>
        )
      }

      {
        !buyerUnits.length && !gettingUnits && (
          <CenteredContent
            key='emptyUnits'
          >
            <span className='text-2xl'>
              There are no {creationType === 'offerCreated' ? 'offers' : 'reservations'} to be shown for you.
            </span>
            <span className='text-2xl'>Please, contact your Sales Rep.</span>

            <TButton
              className='mt-10 text-lg'
              onClick={() => history.push(BUYER_DASHBOARD)}
            >
              Back to Home
            </TButton>
          </CenteredContent>
        )
      }

      {
        (
          projectData &&
          Object.keys(projectData).length) &&
          buyerUnits.length &&
          !gettingUnits &&
          (
            <FadedAnimatedDiv
              key='buyerUnitsStep'
              className='h-full w-full'
            >
              <BuyerPageWithImage
                image={projectData && projectData.images[0]}
              >
                <AnimatePresence exitBeforeEnter>
                  {
                    !showUnits
                      ? (
                        <FadedAnimatedDiv
                          key='welcomeOffers'
                          className='flex flex-col items-center justify-center h-full text-center px-12 md:px-24'
                        >
                          {renderWelcomeDiv()}
                        </FadedAnimatedDiv>
                        )
                      : (
                        <FadedAnimatedDiv
                          key='buyerUnits'
                          className='flex flex-col h-full'
                        >
                          <div className='h-2/6 flex flex-col px-7 md:px-20 justify-end'>
                            <BuyerTitle className='text-left mb-5'>{buyerName},</BuyerTitle>
                            <span className='text-2xl'>
                              Here {buyerUnits.length > 1 ? 'are' : 'is'} your exclusive {buyerUnits.length > 1 ? 'opportunities' : 'oportunity'} to
                              acquire your residence at {projects.length > 1 ? renderProjectsInput() : projectData.projectName}
                            </span>
                          </div>
                          <div className='grid grid-cols-1 md:grid-cols-1 gap-5 overflow-y-auto px-5 md:px-18 mt-3 h-4/6'>
                            {renderUnits()}
                          </div>
                        </FadedAnimatedDiv>
                        )
                  }
                </AnimatePresence>
              </BuyerPageWithImage>
            </FadedAnimatedDiv>
          )
      }
    </AnimatePresence>
  )
}

export default BuyerUnits

UnitFeature.propTypes = {
  children: propTypes.node
}

BuyerUnits.propTypes = {
  buyerUnits: propTypes.array,
  projectData: propTypes.object,
  selectedProject: propTypes.string,
  buyerName: propTypes.string,
  creationType: propTypes.string,
  projects: propTypes.bool,
  onChangeProject: propTypes.func,
  onSelectUnit: propTypes.func,
  selectedUnit: propTypes.object,
  offerId: propTypes.string,
  gettingUnits: propTypes.bool,
  gettingProjects: propTypes.bool
}

CenteredContent.propTypes = {
  key: propTypes.string,
  children: propTypes.node
}
