import React, { useState } from 'react'
import CustomModal from 'components/Modal'
import { Oval } from 'react-loader-spinner'
import TableFilter from 'components/TableFilter'
import Skeleton from 'react-loading-skeleton'
import UserInfoCard from 'components/UserInfoCard'

const CustomSelectBuyerModal = ({
  isOpen,
  setIsOpen,
  loading,
  buyers,
  selectedBuyers,
  setSelectedBuyers
}) => {
  const [filteredBuyers, setFilteredBuyers] = useState([])

  const selectBuyer = (id) => {
    if (checkIfAlreadyExists(id)) {
      if (selectedBuyers.length > 0) {
        const filteredArray = selectedBuyers.filter(
          (item) => item !== id
        )
        setSelectedBuyers(filteredArray)
      }
    } else {
      const temp = [...selectedBuyers, id]
      setSelectedBuyers(temp)
    }
  }

  const checkIfAlreadyExists = (id) => {
    return selectedBuyers.some((buyer) => buyer === id)
  }

  const closeModal = () => {
    setSelectedBuyers([])
    setIsOpen(false)
  }

  return (
    <CustomModal isOpen={isOpen} closeModal={closeModal} contentLabel="Cancel Modal">
      {loading && (
        <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
          <Oval height="50" width="50" color="black" />
        </div>
      )}
      <div className='h-[90vh] min-w-[970px] p-10 text-center rounded bg-grey-8 overflow-hidden flex flex-col justify-between'>
        <div className='flex-1 flex flex-col overflow-hidden'>
          <div className='pb-4 border-b-2 border-softBlack'>
            <p className='my-0 text-xl font-bold'>Select Additional Buyers</p>
          </div>
          <div className='my-10 px-0.5'>
            <TableFilter
              dataToFilter={buyers}
              setData={setFilteredBuyers}
              placeHolder='You can filter the buyers here'
              label=''
              loading={loading}
            />
          </div>
          <div className='flex-1 overflow-y-auto'>
            <div className=' grid grid-cols-3 gap-6'>
              {loading &&
                Array.from(Array(3).keys()).map((a, index) => (
                  <Skeleton key={index} height={180} width='100%' />
                ))}
              {!loading &&
                filteredBuyers?.map((_buyer, index) => (
                  <UserInfoCard
                    {..._buyer}
                    key={index}
                    onSelect={() => selectBuyer(_buyer._id)}
                    isActive={checkIfAlreadyExists(_buyer._id)}
                    className=''
                  />
                ))}
            </div>
          </div>
        </div>
        <div className='flex justify-center items-center gap-6 mt-8'>
          <button className='border rounded border-softBlack70 w-37.5 text-base font-medium h-12' onClick={closeModal}>Cancel</button>
          <button
            disabled={!(selectedBuyers.length > 0)}
            className='rounded w-37.5 bg-volt text-base font-medium h-12'
            onClick={() => setIsOpen(false)}>
            Select{selectedBuyers.length > 0 && `(${selectedBuyers.length})`}
          </button>
        </div>
      </div>
    </CustomModal>
  )
}

export default CustomSelectBuyerModal
