import React from 'react'
import { connect } from 'react-redux'
import {
  Bar,
  BarChart as BarChartComponent,
  Cell,
  LabelList
} from 'recharts'
const AdminReportSalesActivity = (props) => {
  const colors = ['#EDDF65', '#59B099', '#54B5D1', '#DF6D50', '#646264']
  const data = [
    {
      name: 'A',
      uv: 6
    },
    {
      name: 'B',
      uv: 16
    },
    {
      name: 'C',
      uv: 24
    },
    {
      name: 'D',
      uv: 56
    },
    {
      name: 'E',
      uv: 249
    }
  ]
  return (
    <div className='w-full relative'>
      <img
        src='/sales-report-dashboard-img.png'
        className='object-cover w-full h-[640px]'
      />
      <div className='absolute top-[400px] rounded-[4px] w-full px-6'>
        <div className='bg-white p-6'>
          <div className='text-black mb-6 text-3xl font-bold leading-10'>
            1818 Pacifica - CRM Report - Daily Summary - All Sales Reps
          </div>
          <div className='w-full h-[2px] bg-[#2E2B2E]'></div>
          <div className='text-[2#E2B2E] mt-6 text-3xl font-bold leading-10'>
            February 1st 2023
          </div>
          <div className='flex justify-between my-6 gap-6'>
            <div className='basis-1/2 flex flex-col gap-6'>
              <div className='bg-[#FCFCFA] p-6 flex flex-col gap-4'>
                <div className='text-2xl font-bold'>
                  Today's Summary - All Sales Reps
                </div>
                <div className='text-[#646264] text-lg'>February 1st 2023</div>
                <div className='w-full h-[1px] bg-[#2E2B2E]'></div>
                <div>
                  <div className='text-[22px] font-bold text-[#2e2b2e] '>
                    531
                  </div>
                  <div className='uppercase text-[18px] text-[#2e2b2eb3]'>
                    new leads
                  </div>
                </div>
                <div className='w-full h-[1px] bg-[#2e2b2e4d]'></div>
                <div>
                  <div className='text-[22px] font-bold text-[#2e2b2e] '>
                    411
                  </div>
                  <div className='uppercase text-[18px] text-[#2e2b2eb3]'>
                    BUYER New Leads
                  </div>
                </div>
                <div className='w-full h-[1px] bg-[#2e2b2e4d]'></div>
                <div>
                  <div className='text-[22px] font-bold text-[#2e2b2e] '>
                    120
                  </div>
                  <div className='uppercase text-[18px] text-[#2e2b2eb3]'>
                    BROKER NEW LEADS
                  </div>
                </div>
                <div className='w-full h-[1px] bg-[#2e2b2e4d]'></div>
                <div>
                  <div className='text-[22px] font-bold text-[#2e2b2e] '>
                    1290
                  </div>
                  <div className='uppercase text-[18px] text-[#2e2b2eb3]'>
                    Prospect - 2 Way communication
                  </div>
                </div>
                <div className='w-full h-[1px] bg-[#2e2b2e4d]'></div>
                <div className='flex flex-col gap-3 w-full'>
                  <div className='flex gap-3'>
                    <div className='flex gap-2'>
                      <div className='h-4 w-4  bg-[#2e2b2e]'></div>
                      <div>FEMALE</div>
                    </div>
                    <div className='flex gap-2'>
                      <div className='h-4 w-4  bg-[#EDDF65]'></div>
                      <div>MALE</div>
                    </div>
                  </div>
                  <div className='bg-[#2E2B2E] h-11 rounded-[4px] w-[30%]'></div>
                  <div className='bg-[#EDDF65] h-11  rounded-[4px] w-[50%]'></div>
                </div>
                <div className='w-full h-[1px] bg-[#2e2b2e4d]'></div>
                <div>
                  <div className='text-[22px] font-bold text-[#2e2b2e] '>0</div>
                  <div className='uppercase text-[18px] text-[#2e2b2eb3]'>
                    Opportunities
                  </div>
                </div>
                <div className='w-full h-[1px] bg-[#2e2b2e4d]'></div>
                <div>
                  <div className='text-[22px] font-bold text-[#2e2b2e] '>0</div>
                  <div className='uppercase text-[18px] text-[#2e2b2eb3]'>
                    Reservations
                  </div>
                </div>
                <div className='w-full h-[1px] bg-[#2e2b2e4d]'></div>
                <div>
                  <div className='text-[22px] font-bold text-[#2e2b2e] '>0</div>
                  <div className='uppercase text-[18px] text-[#2e2b2eb3]'>
                    Contracts
                  </div>
                </div>
                <div className='w-full h-[1px] bg-[#2e2b2e4d]'></div>
              </div>
              <div className='bg-[#FCFCFA] p-6 flex flex-col gap-4'>
                <div className='text-2xl font-bold'>
                  Today's Leads By Stage - All Sales Reps
                </div>
                <div className='text-[#646264] text-lg'>February 1st 2023</div>
                <div className='w-full h-[1px] bg-[#2E2B2E]'></div>
                <div>
                  <div className='text-[22px] font-bold text-[#2e2b2e] '>
                    267
                  </div>
                  <div className='uppercase text-[18px] text-[#2e2b2eb3]'>
                    leads
                  </div>
                </div>
                <div className='w-full h-[1px] bg-[#2e2b2e4d]'></div>
                <div>
                  <div className='text-[22px] font-bold text-[#2e2b2e] '>
                    17
                  </div>
                  <div className='uppercase text-[18px] text-[#2e2b2eb3]'>
                    lost
                  </div>
                </div>
                <div className='w-full h-[1px] bg-[#2e2b2e4d]'></div>
                <div>
                  <div className='text-[22px] font-bold text-[#2e2b2e] '>
                    14
                  </div>
                  <div className='uppercase text-[18px] text-[#2e2b2eb3]'>
                    Prospect - 2 Way Communication
                  </div>
                </div>
                <div className='w-full h-[1px] bg-[#2e2b2e4d]'></div>
                <div>
                  <div className='text-[22px] font-bold text-[#2e2b2e] '>5</div>
                  <div className='uppercase text-[18px] text-[#2e2b2eb3]'>
                    prospect with Opportunity
                  </div>
                </div>
                <div className='w-full h-[1px] bg-[#2e2b2e4d]'></div>
                <div>
                  <div className='text-[22px] font-bold text-[#2e2b2e] '>0</div>
                  <div className='uppercase text-[18px] text-[#2e2b2eb3]'>
                    Reservation Holder
                  </div>
                </div>
                <div className='w-full h-[1px] bg-[#2e2b2e4d]'></div>
                <div>
                  <div className='text-[22px] font-bold text-[#2e2b2e] '>0</div>
                  <div className='uppercase text-[18px] text-[#2e2b2eb3]'>
                    Contracts
                  </div>
                </div>
                <div className='w-full h-[1px] bg-[#2e2b2e4d]'></div>
                <div>
                  <div className='text-[22px] font-bold text-[#2e2b2e] '>0</div>
                  <div className='uppercase text-[18px] text-[#2e2b2eb3]'>
                    Owner
                  </div>
                </div>
                <div className='w-full h-[1px] bg-[#2e2b2e4d]'></div>
              </div>
            </div>
            <div className='basis-1/2 flex flex-col gap-6'>
              <div className='bg-[#FCFCFA] p-6 flex flex-col gap-4'>
                <div className='text-2xl font-bold'>
                  Today's Leads By Source - All Sales Reps
                </div>
                <div className='text-[#646264] text-lg'>February 1st 2023</div>
                <div className='w-full h-[1px] bg-[#2E2B2E]'></div>
                <div>
                  <div className='text-[22px] font-bold text-[#2e2b2e] '>
                    42
                  </div>
                  <div className='uppercase text-[18px] text-[#2e2b2eb3]'>
                    website form
                  </div>
                </div>
                <div className='w-full h-[1px] bg-[#2e2b2e4d]'></div>
                <div>
                  <div className='text-[22px] font-bold text-[#2e2b2e] '>
                    367
                  </div>
                  <div className='uppercase text-[18px] text-[#2e2b2eb3]'>
                    list import
                  </div>
                </div>
                <div className='w-full h-[1px] bg-[#2e2b2e4d]'></div>
                <div>
                  <div className='text-[22px] font-bold text-[#2e2b2e] '>
                    83
                  </div>
                  <div className='uppercase text-[18px] text-[#2e2b2eb3]'>
                    Broker Referral
                  </div>
                </div>
                <div className='w-full h-[1px] bg-[#2e2b2e4d]'></div>
                <div>
                  <div className='text-[22px] font-bold text-[#2e2b2e] '>
                    21
                  </div>
                  <div className='uppercase text-[18px] text-[#2e2b2eb3]'>
                    Walk in
                  </div>
                </div>
                <div className='w-full h-[1px] bg-[#2e2b2e4d]'></div>
              </div>
              <div className='bg-[#FCFCFA] p-6 flex flex-col gap-4'>
                <div className='text-2xl font-bold'>
                  Today's Activity - All Sales Reps
                </div>
                <div className='text-[#646264] text-lg'>February 1st 2023</div>
                <div className='w-full h-[1px] bg-[#2E2B2E]'></div>
                <div>
                  <div className='text-[22px] font-bold text-[#2e2b2e] '>
                    134
                  </div>
                  <div className='uppercase text-[18px] text-[#2e2b2eb3]'>
                    calls
                  </div>
                </div>
                <div className='w-full h-[1px] bg-[#2e2b2e4d]'></div>
                <div>
                  <div className='text-[22px] font-bold text-[#2e2b2e] '>
                    67
                  </div>
                  <div className='uppercase text-[18px] text-[#2e2b2eb3]'>
                    emails
                  </div>
                </div>
                <div className='w-full h-[1px] bg-[#2e2b2e4d]'></div>
                <div>
                  <div className='text-[22px] font-bold text-[#2e2b2e] '>
                    83
                  </div>
                  <div className='uppercase text-[18px] text-[#2e2b2eb3]'>
                    SMS / WHats App
                  </div>
                </div>
                <div className='w-full h-[1px] bg-[#2e2b2e4d]'></div>
                <div>
                  <div className='text-[22px] font-bold text-[#2e2b2e] '>
                    32
                  </div>
                  <div className='uppercase text-[18px] text-[#2e2b2eb3]'>
                    tasks
                  </div>
                </div>
                <div className='w-full h-[1px] bg-[#2e2b2e4d]'></div>
                <div>
                  <div className='text-[22px] font-bold text-[#2e2b2e] '>
                    13
                  </div>
                  <div className='uppercase text-[18px] text-[#2e2b2eb3]'>
                    Virtual Meetings Booked
                  </div>
                </div>
                <div className='w-full h-[1px] bg-[#2e2b2e4d]'></div>
                <div>
                  <div className='text-[22px] font-bold text-[#2e2b2e] '>5</div>
                  <div className='uppercase text-[18px] text-[#2e2b2eb3]'>
                    Presentation Centre Appointments Booked
                  </div>
                </div>
                <div className='w-full h-[1px] bg-[#2e2b2e4d]'></div>
              </div>
              <div className=' bg-[#FCFCFA] p-6 flex flex-col gap-4'>
                <div className='text-2xl font-bold'>
                  Today's Prospect Rating - All Sales Reps
                </div>
                <div className='text-[#646264] text-lg'>February 1st 2023</div>
                <div className='w-full h-[1px] bg-[#2E2B2E]'></div>
                <div className='flex flex-col  w-full py-6 '>
                  <BarChartComponent
                    data={data}
                    barSize={94}
                    width={600}
                    height={600}
                    barCategoryGap={20}
                  >
                    <Bar
                      radius={4}
                      isAnimationActive={false}
                      data={data}
                      dataKey='uv'
                    >
                      {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                      ))}
                      <LabelList
                        className='text-xl pb-1'
                        dataKey='uv'
                        fill='black'
                        position='top'
                      />
                      {/* <LabelList
                        className='text-xl pb-1'
                        dataKey='name'
                        position='bottom'
                      /> */}
                    </Bar>
                  </BarChartComponent>
                  <div className='flex mt-2 ml-4 gap-7 '>
                    <div className='flex items-center  gap-5 w-[94px] '>
                      <div className='rounded-[4px] w-6 h-6 bg-[#EDDF65]'></div>
                      <div className='text-[26px] font-bold'>A</div>
                    </div>
                    <div className='flex items-center  gap-5 w-[94px] '>
                      <div className=' rounded-[4px] w-6 h-6 bg-[#59B099]'></div>
                      <div className='text-[26px] font-bold'>B</div>
                    </div>
                    <div className='flex items-center  gap-5 w-[94px] '>
                      <div className=' rounded-[4px] w-6 h-6 bg-[#54B5D1]'></div>
                      <div className='text-[26px] font-bold'>C</div>
                    </div>
                    <div className='flex items-center  gap-5 w-[94px] '>
                      <div className=' rounded-[4px] w-6 h-6 bg-[#DF6D50]'></div>
                      <div className='text-[26px] font-bold'>D</div>
                    </div>
                    <div className='flex flex-1  gap-5 items-center '>
                      <div className='rounded-[4px] w-6 h-6 bg-[#646264]'></div>
                      <div className='text-[26px] font-bold'>Unrated</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  userObject: state.authReducer.userObject
})

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminReportSalesActivity)
