/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { getProjectBuyers } from 'store/actions/usersActions'

const useGetProjectBuyers = () => {
  const appProject = useSelector((state) => state.appReducer.appProject)

  const [buyers, setBuyers] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getData()
  }, [appProject])

  const getData = async () => {
    setLoading(true)
    try {
      const data = await getProjectBuyers(appProject)
      setBuyers(data)
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  return { loading, buyers, refetch: getData }
}

export default useGetProjectBuyers
