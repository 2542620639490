import React from 'react'
import { Loader, LoaderWrapper, TextWrapper } from './styles'
import propTypes from 'prop-types'

const LoaderContainer = props => {
  const { isActive, children } = props
  return (
    <LoaderWrapper isActive={isActive}>
      <Loader className='sk-folding-cube'>
        <div className='sk-cube1 sk-cube' />
        <div className='sk-cube2 sk-cube' />
        <div className='sk-cube4 sk-cube' />
        <div className='sk-cube3 sk-cube' />
      </Loader>
      <TextWrapper>{children}</TextWrapper>
    </LoaderWrapper>
  )
}

LoaderContainer.defaultProps = {
  isActive: false
}

LoaderContainer.propTypes = {
  isActive: propTypes.bool,
  children: propTypes.node
}

export default LoaderContainer
