import React from 'react'
import CustomModal from 'components/Modal'
import { ReactComponent as SuccessIcon } from 'assets/icons/check-mark.svg'

const CreateOfferSuccessModal = ({ open, setOpen, onConfirm }) => {
  const closeModal = () => {
    setOpen(false)
  }

  return (
    <CustomModal isOpen={open} closeModal={closeModal} contentLabel="Cancel Modal">
      <div className='w-136 max-w-full p-10 text-center rounded bg-grey-8'>
        <div className='mb-3 flex justify-center'>
          <SuccessIcon />
        </div>
        <h1 className='text-softBlack font-bold text-xl mb-0'>Success</h1>
        <p className='mb-0 mt-6 text-softBlack text-base'>Your offer is created. Now you can send it to the Buyer</p>
        <div className='flex justify-center items-center gap-6 mt-8'>
          <button className='rounded w-37.5 bg-volt text-base font-medium h-12' onClick={onConfirm}>Go to Contract</button>
        </div>
      </div>
    </CustomModal>
  )
}

export default CreateOfferSuccessModal
