import React from 'react'
import propTypes from 'prop-types'
import { TButton } from 'ui'

export const BuyerTitle = ({ children, className = '' }) =>
  <span className={`text-4xl ${className}`}>{children}</span>

export const BuyerButton = ({ className = '', ...props }) => (
  <TButton {...props} className={`h-9 md:h-16 text-base md:text-2xl px-10 ${className}`} />
)

export const BuyerFullScreenWrapper = ({ className, children }) => (
  <div className={`flex flex-col h-full w-full ${className}`}>
    {children}
  </div>
)

export const BuyerRoundedOptionWrapper = ({ className = '', children }) => (
  <li className={`flex flex-col rounded-lg shadow-2xl overflow-hidden my-0 ${className}`}>
    {children}
  </li>
)

export const BuyerOptionImageWrapper = ({ className, children }) => (
  <div className={`h-1/2 ${className}`}>
    {children}
  </div>
)

export const BuyerOptionImage = ({ className, src, alt }) => (
  <img className={`h-full w-full object-cover ${className}`} alt={alt} src={src} />
)

export const BuyerOptionDataWrapper = ({ className, children, onClick }) => (
  <div
    onClick={onClick}
    className={`flex flex-col text-center p-2 items-center h-1/2 hover:cursor-pointer ${className}`}
  >
    {children}
  </div>
)

export const BuyerOptionsWrapper = ({ className = '', style, children }) => (
  <ul
    className={`grid grid-cols-3 gap-10 mb-0 ${className}`}
    style={style}
  >
    {children}
  </ul>
)

BuyerTitle.propTypes = {
  children: propTypes.string,
  className: propTypes.string
}

BuyerButton.propTypes = {
  className: propTypes.string
}

BuyerFullScreenWrapper.propTypes = {
  className: propTypes.string,
  children: propTypes.node
}

BuyerRoundedOptionWrapper.propTypes = {
  className: propTypes.string,
  children: propTypes.node
}

BuyerOptionImageWrapper.propTypes = {
  className: propTypes.string,
  children: propTypes.node
}

BuyerOptionImage.propTypes = {
  className: propTypes.string,
  src: propTypes.string,
  alt: propTypes.string
}

BuyerOptionDataWrapper.propTypes = {
  className: propTypes.string,
  children: propTypes.node,
  onClick: propTypes.func
}

BuyerOptionsWrapper.propTypes = {
  className: propTypes.string,
  children: propTypes.node,
  style: propTypes.object
}
