import { api, ApiErrors } from 'services/api'
import { message } from 'antd'

export const getSingularContracts = (id = '') => new Promise((resolve, reject) => {
  api.get(`singularcontracts/${id}`)
    .then((response) => {
      if (response.ok) {
        resolve(response.data.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const createOrUpdateSingularContract = (props) => new Promise((resolve, reject) => {
  const { _id, ...params } = props

  const verb = _id ? 'put' : 'post'
  const url = _id ? `singularcontracts/${_id}` : 'singularcontracts'

  api[verb](url, params)
    .then((response) => {
      if (response.ok) {
        message.success(`Singular contracts ${_id ? 'successfully updated' : 'created successfully'}`)
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const deleteSingularContract = (id) => new Promise((resolve, reject) => {
  api.delete(`/singularcontracts/${id}`)
    .then((response) => {
      if (response.ok) {
        message.success('Singular contracts deleted successfully')
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const createMassSingularContract = (body) => new Promise((resolve, reject) => {
  api.post('/singularcontracts/mass', body)
    .then((response) => {
      if (response.ok) {
        message.success('Singular contracts created successfully')
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const getSingularDocumentPreview = (_id = '') =>
  new Promise((resolve, reject) => {
    api
      .get(`singularcontracts/contract/preview/${_id}`)
      .then((response) => {
        if (response.ok) {
          resolve(response?.data)
        } else {
          ApiErrors(response)
          reject()
        }
      })
  })

export const getPublicSingularContractData = (token) =>
  new Promise((resolve, reject) => {
    api.setHeaders({
      'Content-Type': 'application/json'
    })
    api.get('/singularcontracts/contract/public', { token }).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })
