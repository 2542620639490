/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useState } from 'react'
import { getLeadsReport } from 'store/actions/usersActions'

const useGetLeadsReport = () => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)

  const getAll = useCallback(async (filter) => {
    setLoading(true)
    getLeadsReport(filter)
      .then((response) => {
        setData(response)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return { data, loading, refetchAllLeads: getAll }
}

export default useGetLeadsReport
