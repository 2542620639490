import React, { useContext } from 'react'
import { DynamicDropdownContext } from '../context'

const PageHeader = () => {
  const { label } = useContext(DynamicDropdownContext)
  return (
    <div className='w-full p-5 flex flex-row items-center font-normal text-xl border-b-2 border-black'>
      {label?.toUpperCase()} | Drop Down Settings
    </div>
  )
}

export default PageHeader
