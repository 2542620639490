/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import FloorItem from './FloorItem'
import useGetReservedUnitInterests from 'hooks/useGetReservedUnitInterests'

const TableTitle = ({ title, span = 2 }) => {
  return (
    <div
      className={
        'flex flex-1 bg-[#FFF] items-center justify-center'
      }
    >
      <span className='text-[#A1A1AA] font-medium text-sm capitalize'>
        {title}
      </span>
    </div>
  )
}

const InventoryListView = ({
  inventoryItems,
  loading,
  type,
  onSelect,
  isAllocateInventory = false
}) => {
  // @ts-ignore
  const [availabilityFilter] = useState([])
  const [floorSortByAsc] = useState(false)
  const { data } = useGetReservedUnitInterests()
  return (
    <div className='h-full flex flex-col rounded bg-[#FFF] overflow-hidden'>
      <div className='flex flex-row w-full bg-[#FFF] py-2 border-t border-b border-[#E4E4E7]'>
        <div
          className='opacity-0 flex w-22 h-full items-center justify-center cursor-default'
        >
          Floor
        </div>
        <div className='flex-1 flex flex-row w-full h-full'>
          <TableTitle title='north' />
          <TableTitle title='east' />
          <TableTitle title='south' />
          <TableTitle title='west' />
        </div>
      </div>
      <div className='flex-1 overflow-y-auto thin-scrollbar bg-gray-2'>
        {loading && (
          <div className='bg-[#FFF] flex flex-col gap-6 px-8 py-4 border-b border-[#E4E4E7]'>
            <Skeleton height={130} />
            <Skeleton height={130} />
            <Skeleton height={130} />
          </div>
        )}
        {!loading && Object.keys(inventoryItems).length === 0 && (
          <div className='h-[300px] bg-[#FFF] flex items-center justify-center text-center font-bold text-2xl border-b border-[#E4E4E7]'>{type} not found.</div>
        )}
        {!loading &&
          <div className='bg-[#FFF] border-b border-[#E4E4E7]'>
            {Object.keys(inventoryItems).length > 0 &&
              Object.keys(inventoryItems)
                .sort((a, b) => {
                  // @ts-ignore
                  return floorSortByAsc ? a - b : b - a
                })
                .map((key) => {
                  return (
                    <FloorItem
                      heatMapData={data}
                      key={key}
                      data={inventoryItems[key]}
                      floorNUmber={parseInt(key)}
                      availabilityFilter={availabilityFilter}
                      onSelect={onSelect}
                      isAllocateInventory={isAllocateInventory}
                      type={type}
                    />
                  )
                })}
          </div>
        }
      </div>
    </div>
  )
}

export default InventoryListView
