import React from 'react'
import propTypes from 'prop-types'
import { HeaderLogo, SurveyStepWrapper, SurveyTitle, SurveySubTitle } from './surveyComponents'
import AttendantImage from 'assets/img/surveyAttendant.jpeg'
import { PhoneIcon, MicrophoneIcon } from '@heroicons/react/outline'

const Option = ({ title }) => (
  <div className='w-full rounded-md shadow-md text-center p-5 hover:cursor-pointer bg-white'>
    <span className='text-lg font-bold'>{title}</span>
  </div>
)

const CallIcon = ({ title, icon: Icon, background }) => (
  <div className='flex flex-col items-center justify-center hover:cursor-pointer'>
    <div className='h-16 w-16 rounded-full items-center flex justify-center' style={{ background: background }}>
      <Icon className='h-10 w-10 text-white' />
    </div>
    <span className='text-white font-bold text-lg mt-3'>{title}</span>
  </div>
)

const FinalOptions = ({ onClickDoLater }) => (
    <SurveyStepWrapper>
      <div className='flex flex-row'>
        <div className='flex flex-col items-center'>
          <HeaderLogo className='rounded-full w-20 h-20'/>
          <SurveyTitle className='my-7'>Thank you, Stefan!</SurveyTitle>
          <SurveySubTitle>Here are some Next Steps</SurveySubTitle>
          <div className='flex flex-col w-full space-y-5 mt-16 mb-10'>
            <Option title='BOOK A SALES CALL' />
            <Option title='VISIT PRESENTATION CENTRE' />
            <Option title='EXPLORE BUILDING' />
          </div>
          <span onClick={onClickDoLater} className='text-xl underline hover:cursor-pointer'>I will do this later</span>
        </div>
        <div className='flex flex-col items-center ml-28'>
          <SurveyTitle className='my-7'>Got some burning Questions?</SurveyTitle>
          <SurveySubTitle>Chat with a Sales Representative now!</SurveySubTitle>
          <div className='relative w-4/5 justify-center flex'>
            <img src={AttendantImage} className='rounded-lg w-full my-10' alt='' />
            <div className='flex flex-row items-center justify-center absolute bottom-14 space-x-9'>
              <CallIcon title='START CALL' icon={PhoneIcon} background='#3f8f9d' />
              <CallIcon title='ENABLE MIC' icon={MicrophoneIcon} background='gray' />

            </div>
          </div>
          <SurveySubTitle>Kondi Guirele - San Francisco</SurveySubTitle>
        </div>
      </div>

    </SurveyStepWrapper>
)

export default FinalOptions

FinalOptions.propTypes = {
  onClickDoLater: propTypes.func
}

Option.propTypes = {
  title: propTypes.string
}

CallIcon.propTypes = {
  title: propTypes.string,
  icon: propTypes.node,
  background: propTypes.string
}
