import React, { memo, useCallback, useState } from 'react'
import propTypes from 'prop-types'

import { AdminPageWrapper } from 'components'
import { Tabs } from 'ui'
import {
  Wrapper, Title, SelectedTabWrapper
} from './styles'

import Ratings from './Ratings'

const settingsMenus = [
  { key: 'contacts', title: 'Contacts' }
]

const MemoTabs = memo(({ menus, tabSelected, onClick }) => (
  <Tabs
    menus={menus}
    tabSelected={tabSelected}
    onClick={(tab) => onClick(tab)}
  />
))

const TabHeader = memo(({ title, subtitle }) => (
  <div className='space-y-1'>
    <h3 className='text-lg leading-6 font-medium text-gray-900'>
      {title && title}
    </h3>
    <p className='max-w-2xl text-sm text-gray-500'>
      {subtitle && subtitle}
    </p>
  </div>
))

const TabContent = memo(({ children }) => (
  <div className='mt-6'>
    <dl className='divide-y divide-gray-200'>
      {children}
    </dl>
  </div>
))

const ContactsTab = memo(({ selectedTab }) => (
  <div className={`${selectedTab !== 'contacts' && 'sr-only'}`}>
    <TabHeader
      title='Contacts'
      subtitle='Here you can setup the settings related to the Contacts'
    />
    <TabContent>
      <Ratings />
    </TabContent>
  </div>
))

const SelectedTab = memo(({ selectedTab }) => (
  <SelectedTabWrapper className='mt-7'>
    <ContactsTab selectedTab={selectedTab} />
  </SelectedTabWrapper>
))

const Settings = () => {
  const [selectedTab, setSelectedTab] = useState('contacts')

  const onChangeTab = useCallback((tab) => {
    setSelectedTab(tab)
  }, [])

  return (
    <AdminPageWrapper>
      <Wrapper className='mt-10'>
        <Title className='text-3xl font-extrabold text-gray-900'>Settings</Title>

        <MemoTabs
          menus={settingsMenus}
          tabSelected={selectedTab}
          onClick={onChangeTab}
        />

        <SelectedTab selectedTab={selectedTab} />
      </Wrapper>
    </AdminPageWrapper>
  )
}

export default Settings

Settings.propTypes = {

}

MemoTabs.propTypes = {
  menus: propTypes.array,
  tabSelected: propTypes.string,
  onClick: propTypes.func
}

TabHeader.propTypes = {
  title: propTypes.string,
  subtitle: propTypes.string
}

TabContent.propTypes = {
  children: propTypes.node
}

ContactsTab.propTypes = {
  selectedTab: propTypes.string
}

SelectedTab.propTypes = {
  selectedTab: propTypes.string
}
