import React from 'react'
import propTypes from 'prop-types'
import { LineWrapper, RightText, SkeletonLineWrapper } from './styles'
import Skeleton from 'react-loading-skeleton'

const ReportLine = props => {
  const {
    leftText, rightText, bgColor,
    size, color, loading,
    leftTextBold, rightTextBold
  } = props

  const renderRightText = () => {
    const isReactObject = typeof rightText === 'object'
    return isReactObject
      ? rightTextBold ? <strong>{rightText}</strong> : rightText
      : <span style={{ fontWeight: rightTextBold ? 'bold' : 'normal' }}>{rightText}</span>
  }

  return (
    loading
      ? (
          <SkeletonLineWrapper>
            <Skeleton height={17} />
          </SkeletonLineWrapper>
        )
      : (
          <LineWrapper bgColor={bgColor} color={color} size={size}>
            <div>
              <span style={{ paddingRight: 6, fontWeight: leftTextBold ? 'bold' : 'normal' }}>
                {leftText}:
              </span>
            </div>
            <RightText>
              {renderRightText()}
            </RightText>
          </LineWrapper>
        )
  )
}

ReportLine.defaultProps = {
  rightText: 'Loading',
  size: 'medium',
  bgColor: 'white',
  color: 'red',
  loading: false,
  leftTextBold: false,
  rightTextBold: false
}

ReportLine.propTypes = {
  size: propTypes.oneOf(['small', 'medium', 'large']),
  leftText: propTypes.string.isRequired,
  rightText: propTypes.oneOfType([
    propTypes.string,
    propTypes.number,
    propTypes.element
  ]),
  bgColor: propTypes.string,
  color: propTypes.string,
  loading: propTypes.bool,
  leftTextBold: propTypes.bool,
  rightTextBold: propTypes.bool
}

export default ReportLine
