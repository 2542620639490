import React from 'react'
import styled from 'styled-components'
import propTypes from 'prop-types'

import { ButtonLink, Button } from 'ui'

const ButtonFooter = (props) => {
  const { to, label, children, background, typeButton, onClick, disabled, loading } = props

  return (
    <Container>
      {typeButton === 'link'
        ? (
            <ButtonLink to={to} background={background} onClick={onClick} disabled={disabled} loading={loading}>
              {label}{children}
            </ButtonLink>
          )
        : (
            <Button background={background} onClick={onClick} disabled={disabled} loading={loading}>
              {label}{children}
            </Button>
          )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: 20px;
`

ButtonFooter.propTypes = {
  to: propTypes.string,
  typeButton: propTypes.string,
  background: propTypes.string,
  label: propTypes.string.isRequired,

  children: propTypes.node,

  onClick: propTypes.func.isRequired,
  disabled: propTypes.bool,
  loading: propTypes.bool
}

export default ButtonFooter
