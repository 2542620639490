/*eslint-disable */
// @ts-nocheck
import CustomButton from "components/CustomButton";
import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  getSalesRepAndDevAdmin,
  updatePermissions,
} from "store/actions/usersActions";
import {
  ADMIN_DASHBOARD,
  ADMIN_PROJECT_MANAGEMENT,
  SALES_PIPELINE,
  ADMIN_CONTACTS,
  ALLOCATIONS,
  ADMIN_OFFERS,
  PAGE_UNDER_CONSTRUCTION,
  EMAIL_INBOX,
  CALENDAR,
  CONTRACT_TEMPLATES,
  UNITS_INVENTORY,
  ADMIN_TEAM_SALES_REPS,
  ADMIN_TEAM_COOP_BROKER,
  ADMIN_TEAM_DEV_ADMINS,
  ADMIN_PROJECT_IMAGES,
  ADMIN_PROJECT_PARKING,
  ADMIN_PROJECT_COLORS,
  ADMIN_PROJECT_UPGRADES,
  ADMIN_PROJECT_PAYMENTS,
  ADMIN_BUILDINGS,
  ADMIN_PROJECT_UNIT_INVENTORY,
  ADMIN_PROJECT_UNIT_INVENTORY_GRAPH,
  ADMIN_PROJECT_ALLOCATIONS,
  ADMIN_PROJECT_FILES,
  ADMIN_REPORTS,
  ADMIN_OPPORTUNITIES,
  ADMIN_PROFILE_PAGE,
  ADMIN_PROJECT_DROPDOWNS,
  ADMIN_NEW_CONTACTS,
  ADMIN_EMAIL_CAMPAIGNS,
  ADMIN_RESERVATIONS,
  ADMIN_RESERVATION_NEW,
  ADMIN_BROKER_LEADS,
  ADMIN_BROKER_LEADS_NEW,
  ADMIN_PROJECT_PARKING_INVENTORY,
  PARKINGS_INVENTORY,
  // ADMIN_SCHEDULER_MEETINGS,
  // ADMIN_SCHEDULER_SETTINGS,
  ADMIN_DEPOSIT_PAYMENT,
  ADMIN_SALES_REPORT,
  ADMIN_LEADREPORT,
  ADMIN_EMAIL_CAMPAIGNS_BUILDER,
  ADMIN_EVENT_PAGE,
  ADMIN_EMAIL_CUSTOM_CAMPAIGNS_BUILDER,
  ADMIN_DASHBOARD_COLUMBIA_REPORT,
  ADMIN_PROSPECT_QUALIFICATION,
  ADMIN_SALES_TRANSACTION,
  ADMIN_POST_SALES_SUCCESS,
  ADMIN_SMS_BROADCAST,
  TASKS,
  ADMIN_NEGOTIATE,
  ADMIN_OPPORTUNITY,
  ADMIN_PROJECT_MAP,
  ADMIN_PARKING_SALES_AND_WAIT_LIST,
  ADMIN_MASTER_REPORT,
  ADMIN_DASHBOARD_DEALS,
  ADMIN_PROJECT_STORAGE_INVENTORY,
  ADMIN_REMINDER_SETTINGS,
  STORAGES_INVENTORY,
  INVENTORIES,
  ADMIN_DASHBOARD_SALESREP,
  ADMIN_DASHBOARD_WHITECAP,
  TASKS_CALENDAR,
  DEMAND_NOTES_SETTINGS,
  ADMIN_UNIT_INVENTORY_MAP,
  ADMIN_PROJECT_OFFER_SETTING,
  ADMIN_PROJECT_DEPOSIT_OPTIONS,
  ADMIN_PROJECT_COMMUNICATION_TEMPLATES,
  ADMIN_LISTS,
  ADMIN_CUSTOM_FORMS,
  ADMIN_SINGLE_SEND_CONTACT_DOCUMENTS,
  ADMIN_PROJECT_INVENTORY_SETTINGS
} from "routes";
import { convertToUpperCaseStringForDropdown } from "utils/converters";

import { ReactComponent as DashboardIconSVG } from "../../assets/icons/icn_dashboard.svg";
import { ReactComponent as EmailIcon } from "../../assets/icons/icn_email.svg";
import { ReactComponent as ContactsIcon } from "../../assets/icons/icn_contacts.svg";
import { ReactComponent as CalendarIcon } from "../../assets/icons/icn_calendar.svg";
import { ReactComponent as OpportunitiesIcon } from "../../assets/icons/icn_reduce_capacity.svg";
import { ReactComponent as ReservationsIcon } from "../../assets/icons/icn_dns.svg";
import { ReactComponent as BrokerIcon } from "../../assets/icons/icn_text_snippet.svg";
import { ReactComponent as ContractsIcon } from "../../assets/icons/icn_local_offer.svg";
import { ReactComponent as DepositsIcon } from "../../assets/icons/icn_local_atm.svg";
import { ReactComponent as InventoryIcon } from "../../assets/icons/icn_card_view.svg";
import { ReactComponent as EmailCampaignsIcon } from "../../assets/icons/icn_email_sent.svg";
import { ReactComponent as ContractTemplatesIcon } from "../../assets/icons/icn_sticky_note_2.svg";
import { ReactComponent as ReportsIcon } from "../../assets/icons/icn_assessment.svg";
import { ReactComponent as ProfileIcon } from "../../assets/icons/icn_person.svg";
import { ReactComponent as TeamIcon } from "../../assets/icons/icn_people.svg";
import { ReactComponent as BuildingsIcon } from "../../assets/icons/icn_apartment.svg";
import { ReactComponent as ProjectManagementIcon } from "../../assets/icons/icn_ballot.svg";
import { ReactComponent as DropDownSettingsIcon } from "../../assets/icons/icn_bulleted_list.svg";
import { ReactComponent as EventsIcon } from "../../assets/icons/icn_presentation.svg";
import { ReactComponent as LeadGenerationIcon } from "../../assets/icons/icn_link.svg";
import { ReactComponent as PostSalesSuccessIcon } from "../../assets/icons/icn_check_circle.svg";
import { ClipboardTypeIcon, UserCog2 } from "lucide-react";
import CustomSelect from "pages/reservation/NewReservation/components/CustomSelect";
// import { SIDE_BAR_MENU_ARR } from 'components/AdminSideBar/sideBarMenu';
const AdminPermissionManage = () => {
  const [dynamicComponent, setDynamicComponent] = React.useState(null);
  const [selectedUser, setSelectedUser] = React.useState("");
  const [selectedPermissions, setSelectedPermissions] = React.useState({});
  const [selectUserObject, setSelectUserObject] = React.useState({});
  const [users, setUsers] = React.useState([]);
  const [selectedCategory, setSelectedCategory] = React.useState("general");
  const user = useSelector((state) => state.authReducer.userObject);
  // useEffect(() => {
  //   if (location.pathname === '/admin/permissions') {
  //     import('components/AdminSideBar/useSideBarMenuV2')
  //       .then((module) => {
  //         setDynamicComponent(module);
  //       })
  //       .catch((error) => {
  //         console.error('Failed to load module:', error);
  //       });
  //   }
  // }, []);

  const [customSectionMenus, setCustomSectionsMenus] = React.useState([]);
  const customSections = useSelector(
    (state) => state.appReducer.customSections
  );
  useEffect(() => {
    const tempCustomSectionMenus = customSections?.map((section) => {
      return {
        key: section._id,
        title: section.title,
        hasSubMenus: false,
        onClick: () =>
          history.push(`/admin/project/custom-section/${section._id}`),
        route: `/admin/project/custom-section/${section._id}`,
        permissions: section._id,
      };
    });
    setCustomSectionsMenus(tempCustomSectionMenus);
  }, [customSections]);

  const SIDE_BAR_MENU = {
    general: [
      {
        menu: [
          {
            key: "dashboard",
            title: "Dashboard",
            hasSubMenus: false,
            Icon: DashboardIconSVG,
            onClick: () =>
              history.push(
                process.env.REACT_APP_AVESDO_FLAG === "true"
                  ? ADMIN_DASHBOARD_DEALS
                  : user.userType === "SalesRep"
                    ? ADMIN_DASHBOARD_SALESREP
                    : ADMIN_DASHBOARD_WHITECAP
              ),
            route:
              process.env.REACT_APP_AVESDO_FLAG === "true"
                ? ADMIN_DASHBOARD_DEALS
                : user.userType === "SalesRep"
                  ? ADMIN_DASHBOARD_SALESREP
                  : ADMIN_DASHBOARD_WHITECAP,
            permissions: "DASHBOARD",
            sub_permissions: [
              "Leads",
              "Sales Activity",
              "Reservations",
              "Communications",
              "Dashboard Tab",
              "Deals",
              "Deposits",
              "Parking",
              "Parking Avesdo",
              "Parking Waitlist",
              "Storage",
              "Storage Waitlist",
              "Goal",
              "Traffic Report"
            ],
          },
          // {
          //   key: 'contacts',
          //   title: 'Contacts',
          //   hasSubMenus: false,
          //   Icon: DashboardIcon,
          //   onClick: () => history.push(ADMIN_CONTACTS),
          //   route: ADMIN_CONTACTS
          // },
          // {
          //   key: 'newcontacts',
          //   title: 'New Contacts',
          //   hasSubMenus: false,
          //   Icon: ContactsIcon,
          //   onClick: () => history.push(ADMIN_NEW_CONTACTS),
          //   route: ADMIN_NEW_CONTACTS
          // },
          {
            key: "inventory",
            title: "Inventory",
            hasSubMenus: true,
            Icon: InventoryIcon,
            subMenus: [
              {
                key: "inventories",
                title: "Inventories",
                hasSubMenus: false,
                onClick: () => history.push(INVENTORIES),
                route: INVENTORIES,
                permissions: "INVENTORIES",
              },
              // {
              //   key: ADMIN_UNIT_INVENTORY_MAP,
              //   title: 'Unit Inventory map',
              //   hasSubMenus: false,
              //   onClick: () => history.push(ADMIN_UNIT_INVENTORY_MAP),
              //   route: ADMIN_UNIT_INVENTORY_MAP,
              //   permissions: 'UNIT_INVENTORY_MAP',
              // },
              // {
              //   key: 'units inventory',
              //   title: 'Unit Inventory',
              //   hasSubMenus: false,
              //   onClick: () => history.push(UNITS_INVENTORY),
              //   route: UNITS_INVENTORY,
              //   permissions: 'UNITS_INVENTORY',
              // },
              // {
              //   key: ADMIN_PARKING_INVENTORY_MAP,
              //   title: 'Parking Inventory map',
              //   hasSubMenus: false,
              //   onClick: () => history.push(ADMIN_PARKING_INVENTORY_MAP),
              //   route: ADMIN_PARKING_INVENTORY_MAP,
              //   permissions: 'PARKING_INVENTORY_MAP',
              // },
              // {
              //   key: 'parkings inventory',
              //   title: 'Parking Inventory',
              //   hasSubMenus: false,
              //   onClick: () => history.push(PARKINGS_INVENTORY),
              //   route: PARKINGS_INVENTORY,
              //   permissions: 'PARKINGS_INVENTORY',
              // },
              // {
              //   key: 'storage inventory',
              //   title: 'Storage Inventory',
              //   hasSubMenus: false,
              //   onClick: () => history.push(STORAGES_INVENTORY),
              //   route: STORAGES_INVENTORY,
              //   permissions: 'STORAGES_INVENTORY',
              // },
              // {
              //   key: ADMIN_PROJECT_MAP,
              //   title: 'Project  Map',
              //   hasSubMenus: false,
              //   onClick: () => history.push(ADMIN_PROJECT_MAP),
              //   route: ADMIN_PROJECT_MAP,
              //   permissions: 'PROJECT_MAP',
              // },
              {
                key: "allocations",
                title: "Allocations",
                hasSubMenus: false,
                onClick: () => history.push(ALLOCATIONS),
                route: ALLOCATIONS,
                permissions: "ALLOCATIONS",
                permissions: "INVENTORY_ALLOCATIONS",
              },
              {
                key: "buildings",
                title: "Building Set up",
                hasSubMenus: false,
                Icon: BuildingsIcon,
                onClick: () => history.push(ADMIN_BUILDINGS),
                route: ADMIN_BUILDINGS,
                permissions: "BUILDINGS",
              },
            ],
          },
          {
            key: "contacts",
            title: "Contacts",
            hasSubMenus: false,
            Icon: ContactsIcon,
            onClick: () => history.push(ADMIN_CONTACTS),
            route: ADMIN_CONTACTS,
            permissions: "CONTACTS",
          },
          {
            key: "lists",
            title: "Lists Management",
            hasSubMenus: false,
            Icon: ContactsIcon,
            onClick: () => history.push(ADMIN_LISTS),
            route: ADMIN_LISTS,
            permissions: "LISTS",
          },
          {
            key: 'forms',
            title: 'Forms',
            hasSubMenus: false,
            Icon: ClipboardTypeIcon,
            onClick: () => history.push(ADMIN_CUSTOM_FORMS),
            route: ADMIN_CUSTOM_FORMS,
            permissions: 'FORMS',
          },
          {
            key: "email inbox",
            title: "Inbox",
            hasSubMenus: false,
            Icon: EmailIcon,
            onClick: () => history.push(EMAIL_INBOX),
            route: EMAIL_INBOX,
            permissions: "EMAIL_INBOX",
          },
          {
            key: "calendar",
            title: "Calendar",
            hasSubMenus: false,
            Icon: CalendarIcon,
            onClick: () => history.push(CALENDAR),
            route: CALENDAR,
            permissions: "CALENDAR",
          },
          {
            key: "tasks",
            title: "Tasks",
            hasSubMenus: true,
            Icon: PostSalesSuccessIcon,
            subMenus: [
              {
                key: "tasks-list",
                title: "List",
                hasSubMenus: false,
                onClick: () => history.push(TASKS),
                route: TASKS,
                permissions: "LIST",
              },
              {
                key: "tasks-calender",
                title: "Calendar",
                hasSubMenus: false,
                onClick: () => history.push(TASKS_CALENDAR),
                route: TASKS_CALENDAR,
                permissions: "TASK_CALENDAR",
              },
            ],
          },
          {
            key: "sms broadcast",
            title: "SMS Broadcast",
            hasSubMenus: false,
            Icon: EmailIcon,
            onClick: () => history.push(ADMIN_SMS_BROADCAST),
            route: ADMIN_SMS_BROADCAST,
            permissions: "SMS_BROADCAST",
          },
          {
            key: ADMIN_EVENT_PAGE,
            title: "Events & RSVPS",
            hasSubMenus: false,
            Icon: EventsIcon,
            onClick: () => history.push(ADMIN_EVENT_PAGE),
            route: ADMIN_EVENT_PAGE,
            permissions: "EVENTS_RSVP",
          },
          // {
          //   key: 'brokerLeads',
          //   title: 'Broker Landing Pages',
          //   hasSubMenus: false,
          //   Icon: BrokerIcon,
          //   onClick: () => history.push(ADMIN_BROKER_LEADS),
          //   route: ADMIN_BROKER_LEADS,
          //   permissions: 'BROKER_LANDING_PAGES',
          // },
          // {
          //   key: 'opportunities',
          //   title: 'Opportunities',
          //   hasSubMenus: false,
          //   Icon: OpportunitiesIcon,
          //   onClick: () => history.push(ADMIN_OPPORTUNITIES),
          //   route: ADMIN_OPPORTUNITIES,
          //   permissions: 'OPPORTUNITIES',
          // },
          // {
          //   key: 'reservations',
          //   title: 'Reservations',
          //   hasSubMenus: false,
          //   Icon: ReservationsIcon,
          //   onClick: () => history.push(ADMIN_RESERVATIONS),
          //   route: ADMIN_RESERVATIONS,
          //   permissions: 'RESERVATIONS',
          // },
          // {
          //   key: 'brokerLeads',
          //   title: 'Broker Landing Pages',
          //   hasSubMenus: false,
          //   Icon: BrokerIcon,
          //   onClick: () => history.push(ADMIN_BROKER_LEADS),
          //   route: ADMIN_BROKER_LEADS,
          //   permissions: 'BROKER_LEADS',
          // },
          {
            key: ADMIN_DEPOSIT_PAYMENT,
            title: "Deposits",
            hasSubMenus: false,
            Icon: DepositsIcon,
            onClick: () => history.push(ADMIN_DEPOSIT_PAYMENT),
            route: ADMIN_DEPOSIT_PAYMENT,
            permissions: "ADMIN_DEPOSITS",
          },
          {
            key: "contract templates",
            title: "Contract Templates",
            hasSubMenus: false,
            Icon: ContractTemplatesIcon,
            onClick: () => history.push(CONTRACT_TEMPLATES),
            route: CONTRACT_TEMPLATES,
            permissions: "CONTRACT_TEMPLATES",
          },
          {
            key: "team",
            title: "Team",
            hasSubMenus: true,
            Icon: TeamIcon,
            subMenus: [
              {
                key: "developer-admins",
                title: "Developer Admins",
                hasSubMenus: false,
                onClick: () => history.push(ADMIN_TEAM_DEV_ADMINS),
                route: ADMIN_TEAM_DEV_ADMINS,
                permissions: "TEAM_DEV_ADMINS",
              },
              {
                key: "sales-reps",
                title: "Sales Reps",
                hasSubMenus: false,
                onClick: () => history.push(ADMIN_TEAM_SALES_REPS),
                route: ADMIN_TEAM_SALES_REPS,
                permissions: "TEAM_SALES_REPS",
              },
              {
                key: "coop-brokers",
                title: "Coop Brokers",
                hasSubMenus: false,
                onClick: () => history.push(ADMIN_TEAM_COOP_BROKER),
                route: ADMIN_TEAM_COOP_BROKER,
                permissions: "TEAM_COOP_BROKERS",
              },
            ],
          },
        ],
      },
      {
        menu: [
          // {
          //   key: 'sales pipeline',
          //   title: 'Sales Pipeline',
          //   hasSubMenus: false,
          //   Icon: DashboardIcon,
          //   onClick: () => history.push(SALES_PIPELINE),
          //   route: SALES_PIPELINE,
          //   permissions: 'SALES_PIPELINE',
          // },
          // {
          //   key: 'notifications',
          //   title: 'Notifications',
          //   hasSubMenus: false,
          //   Icon: DashboardIcon,
          //   onClick: () => history.push(PAGE_UNDER_CONSTRUCTION),
          //   route: 'notifications',

          // },
          // {
          //   key: 'reports',
          //   title: 'Reports',
          //   hasSubMenus: false,
          //   Icon: ReportsIcon,
          //   onClick: () => history.push(ADMIN_REPORTS),
          //   route: 'reports',
          //   permissions: 'REPORTS',
          // },
          // {
          //   key: 'reports',
          //   title: 'Reports',
          //   hasSubMenus: true,
          //   Icon: ReportsIcon,
          //   subMenus: [
          //     {
          //       key: ADMIN_SALES_REPORT,
          //       title: 'Sales Rep Activity',
          //       hasSubMenus: false,
          //       onClick: () => history.push(ADMIN_SALES_REPORT),
          //       route: ADMIN_SALES_REPORT
          //     },
          //     {
          //       key: ADMIN_LEADREPORT,
          //       title: 'Lead Reports',
          //       hasSubMenus: false,
          //       onClick: () => history.push(ADMIN_LEADREPORT),
          //       route: ADMIN_LEADREPORT
          //     }
          //   ]
          // },
          {
            key: "leadGeneration",
            title: "Lead Generation",
            hasSubMenus: true,
            Icon: LeadGenerationIcon,
            subMenus: [
              {
                key: "brokerLeads",
                title: "Broker Landing Pages",
                hasSubMenus: false,
                Icon: BrokerIcon,
                onClick: () => history.push(ADMIN_BROKER_LEADS),
                route: ADMIN_BROKER_LEADS,
                permissions: "LEAD_BROKER_LEADS",
              },
            ],
          },
          {
            key: ADMIN_PROSPECT_QUALIFICATION,
            title: "Prospect Qualification",
            hasSubMenus: true,
            Icon: ContactsIcon,
            subMenus: [
              {
                key: "opportunities",
                title: "Opportunities",
                hasSubMenus: false,
                Icon: OpportunitiesIcon,
                onClick: () => history.push(ADMIN_OPPORTUNITIES),
                route: ADMIN_OPPORTUNITIES,
                permissions: "ADMIN_OPPORTUNITIES",
              },
              {
                key: "reservations",
                title: "Reservations",
                hasSubMenus: false,
                Icon: ReservationsIcon,
                onClick: () => history.push(ADMIN_RESERVATIONS),
                route: ADMIN_RESERVATIONS,
                permissions: "ADMIN_RESERVATIONS",
              },
            ],
          },
          {
            key: ADMIN_SALES_TRANSACTION,
            title: "Sales Transaction",
            hasSubMenus: true,
            Icon: ReportsIcon,
            subMenus: [
              {
                key: "allocations",
                title: "Allocations",
                hasSubMenus: false,
                onClick: () => history.push(ALLOCATIONS),
                route: ALLOCATIONS,
                permissions: "SALES_ALLOCATIONS",
              },
              {
                key: "offers",
                title: "Transactions",
                hasSubMenus: false,
                Icon: ContractsIcon,
                onClick: () => history.push(ADMIN_OFFERS),
                route: ADMIN_OFFERS,
                permissions: "OFFERS",
              },
              {
                key: 'single-send-contract-documents',
                title: 'Single Send Contract Documents',
                hasSubMenus: false,
                Icon: ContractsIcon,
                onClick: () => history.push(ADMIN_SINGLE_SEND_CONTACT_DOCUMENTS),
                route: ADMIN_SINGLE_SEND_CONTACT_DOCUMENTS,
                permissions: 'SINGLE_SEND_CONTACT_DOCUMENTS',
              },
              {
                key: "parkings",
                title: "Parking Sales & Waitlist",
                hasSubMenus: false,
                Icon: ContractsIcon,
                onClick: () => history.push(ADMIN_PARKING_SALES_AND_WAIT_LIST),
                route: ADMIN_PARKING_SALES_AND_WAIT_LIST,
                permissions: "PARKING_SALES_WAITLIST",
              },
              {
                key: "negotiate",
                title: "Negotiate",
                hasSubMenus: false,
                Icon: ContractsIcon,
                onClick: () => history.push(ADMIN_NEGOTIATE),
                route: ADMIN_NEGOTIATE,
                permissions: "NEGOTIATE",
              },
              {
                key: ADMIN_DEPOSIT_PAYMENT,
                title: "Deposits",
                hasSubMenus: false,
                Icon: DepositsIcon,
                onClick: () => history.push(ADMIN_DEPOSIT_PAYMENT),
                route: ADMIN_DEPOSIT_PAYMENT,
                permissions: "DEPOSITS",
              },
            ],
          },
          {
            key: ADMIN_POST_SALES_SUCCESS,
            title: "Post Sales Success",
            hasSubMenus: false,
            Icon: PostSalesSuccessIcon,
            onClick: () => history.push(ADMIN_POST_SALES_SUCCESS),
            route: ADMIN_POST_SALES_SUCCESS,
            permissions: "POST_SALES_SUCCESS",
          },
        ],
      },
      {
        menu: [
          {
            key: "email_Campaigns",
            title: "Email Campaigns",
            hasSubMenus: true,
            Icon: DashboardIconSVG,
            // onClick: () => history.push(ADMIN_EMAIL_CAMPAIGNS),
            // route: 'emailCampaigns'
            subMenus: [
              // {
              //   key: 'campaigns',
              //   title: 'Email Campaigns Reports',
              //   hasSubMenus: false,
              //   Icon: EmailCampaignsIcon,
              //   onClick: () => history.push(ADMIN_EMAIL_CAMPAIGNS),
              //   route: ADMIN_EMAIL_CAMPAIGNS
              // },
              {
                key: ADMIN_EMAIL_CAMPAIGNS_BUILDER,
                title: "Email Campaigns Builder",
                hasSubMenus: false,
                Icon: EmailIcon,
                onClick: () => history.push(ADMIN_EMAIL_CAMPAIGNS_BUILDER),
                route: ADMIN_EMAIL_CAMPAIGNS_BUILDER,
                permissions: "EMAIL_CAMPAIGNS_BUILDER",
              },
              {
                key: ADMIN_EMAIL_CUSTOM_CAMPAIGNS_BUILDER,
                title: "Email Campaigns Reports",
                hasSubMenus: false,
                Icon: EmailCampaignsIcon,
                onClick: () =>
                  history.push(ADMIN_EMAIL_CUSTOM_CAMPAIGNS_BUILDER),
                route: ADMIN_EMAIL_CUSTOM_CAMPAIGNS_BUILDER,
                permissions: "EMAIL_CAMPAIGNS_REPORTS",
              },
            ],
          },
          {
            key: "report",
            title: "Reports",
            hasSubMenus: false,
            Icon: ReportsIcon,
            onClick: () => history.push(ADMIN_MASTER_REPORT),
            route: ADMIN_MASTER_REPORT,
            permissions: "ADMIN_MASTER_REPORT",
          },
        ],
      },
      {
        menu: [
          {
            key: "permissions",
            title: "Permissions",
            hasSubMenus: false,
            onClick: () => history.push("/admin/permissions"),
            route: "/admin/permissions",
            permissions: "PERMISSIONS",
            Icon: UserCog2,
          },
        ],
      },
      // {
      // menu: [
      //   {
      //     key: 'profile',
      //     title: 'Settings',
      //     hasSubMenus: false,
      //     Icon: ProfileIcon,
      //     onClick: () => history.push(ADMIN_PROFILE_PAGE),
      //     route: 'profile'
      // },
      // {
      //   key: 'Scheduler Meetings',
      //   title: 'Scheduler Meetings',
      //   hasSubMenus: false,
      //   Icon: DashboardIcon,
      //   onClick: () => history.push(ADMIN_SCHEDULER_MEETINGS),
      //   route: 'Scheduler Meetings'
      // },  {
      //   key: 'Scheduler Settings',
      //   title: 'Scheduler Settings',
      //   hasSubMenus: false,
      //   Icon: DashboardIcon,
      //   onClick: () => history.push(ADMIN_SCHEDULER_SETTINGS),
      //   route: 'Scheduler Settings'
      // }
      // ]
      // }
    ],
    project: [
      {
        menu: [
          {
            key: "project management",
            title: "Project Management",
            hasSubMenus: true,
            Icon: ProjectManagementIcon,
            subMenus: [
              {
                key: "general",
                title: "General",
                hasSubMenus: false,
                onClick: () => history.push(ADMIN_PROJECT_MANAGEMENT),
                route: ADMIN_PROJECT_MANAGEMENT,
                permissions: "PROJECT_MANAGEMENT_GENERAL",
              },
              {
                key: "images",
                title: "Images",
                hasSubMenus: false,
                onClick: () => history.push(ADMIN_PROJECT_IMAGES),
                route: ADMIN_PROJECT_IMAGES,
                permissions: "PROJECT_MANAGEMENT_IMAGES",
              },
              {
                key: "parking",
                title: "Parking",
                hasSubMenus: false,
                onClick: () => history.push(ADMIN_PROJECT_PARKING),
                route: ADMIN_PROJECT_PARKING,
                permissions: "PROJECT_MANAGEMENT_PARKING",
              },
              {
                key: "colors",
                title: "Colors",
                hasSubMenus: false,
                onClick: () => history.push(ADMIN_PROJECT_COLORS),
                route: ADMIN_PROJECT_COLORS,
                permissions: "PROJECT_MANAGEMENT_COLORS",
              },
              {
                key: "upgrades",
                title: "Upgrades",
                hasSubMenus: false,
                onClick: () => history.push(ADMIN_PROJECT_UPGRADES),
                route: ADMIN_PROJECT_UPGRADES,
                permissions: "PROJECT_MANAGEMENT_UPGRADES",
              },
              // {
              //   key: 'payment',
              //   title: 'Payment',
              //   hasSubMenus: false,
              //   onClick: () => history.push(ADMIN_PROJECT_PAYMENTS),
              //   route: ADMIN_PROJECT_PAYMENTS,
              //   permissions: 'PROJECT_MANAGEMENT_PAYMENT',
              // },
              {
                key: ADMIN_PROJECT_DEPOSIT_OPTIONS,
                title: "Deposit Options",
                hasSubMenus: false,
                onClick: () => history.push(ADMIN_PROJECT_DEPOSIT_OPTIONS),
                route: ADMIN_PROJECT_DEPOSIT_OPTIONS,
                permissions: "PROJECT_MANAGEMENT_UPGRADES",
              },
              {
                key: ADMIN_PROJECT_FILES,
                title: "Files",
                hasSubMenus: false,
                onClick: () => history.push(ADMIN_PROJECT_FILES),
                route: ADMIN_PROJECT_FILES,
                permissions: "PROJECT_MANAGEMENT_FILES",
              },
              {
                key: ADMIN_REMINDER_SETTINGS,
                title: "Reminders",
                hasSubMenus: false,
                onClick: () => history.push(ADMIN_REMINDER_SETTINGS),
                route: ADMIN_REMINDER_SETTINGS,
                permissions: "PROJECT_MANAGEMENT_REMINDERS",
              },
              ...customSectionMenus,
            ],
          },
          {
            key: "buildings",
            title: "Buildings",
            hasSubMenus: false,
            Icon: BuildingsIcon,
            onClick: () => history.push(ADMIN_BUILDINGS),
            route: ADMIN_BUILDINGS,
            permissions: "PROJECT_BUILDINGS",
          },
          // {
          //   key: 'inventory',
          //   title: 'Inventory',
          //   hasSubMenus: true,
          //   Icon: InventoryIcon,
          //   subMenus: [
          //     {
          //       key: 'units inventory',
          //       title: 'Unit Inventory',
          //       hasSubMenus: false,
          //       onClick: () => history.push(ADMIN_PROJECT_UNIT_INVENTORY),
          //       route: ADMIN_PROJECT_UNIT_INVENTORY
          //     },
          //     {
          //       key: ADMIN_PROJECT_UNIT_INVENTORY_GRAPH,
          //       title: 'Unit Inventory Graph',
          //       hasSubMenus: false,
          //       onClick: () => history.push(ADMIN_PROJECT_UNIT_INVENTORY_GRAPH),
          //       route: ADMIN_PROJECT_UNIT_INVENTORY_GRAPH
          //     },
          //     {
          //       key: 'parkings inventory',
          //       title: 'Parking Inventory',
          //       hasSubMenus: false,
          //       onClick: () => history.push(ADMIN_PROJECT_PARKING_INVENTORY),
          //       route: ADMIN_PROJECT_PARKING_INVENTORY
          //     },
          //     {
          //       key: 'storages inventory',
          //       title: 'Storage Inventory',
          //       hasSubMenus: false,
          //       onClick: () => history.push(ADMIN_PROJECT_STORAGE_INVENTORY),
          //       route: ADMIN_PROJECT_STORAGE_INVENTORY
          //     },
          //     {
          //       key: 'allocations',
          //       title: 'Allocations',
          //       hasSubMenus: false,
          //       onClick: () => history.push(ADMIN_PROJECT_ALLOCATIONS),
          //       route: ADMIN_PROJECT_ALLOCATIONS
          //     }
          //   ]
          // },
          {
            key: "inventories",
            title: "Inventories",
            hasSubMenus: false,
            Icon: InventoryIcon,
            onClick: () => history.push(INVENTORIES),
            route: INVENTORIES,
            permissions: "PROJECT_INVENTORIES",
          },
          {
            key: "Drop Down Settings",
            title: "Drop Down Settings",
            hasSubMenus: true,
            Icon: DropDownSettingsIcon,
            permissions: "DROP_DOWN_SETTINGS",
            subMenus: [
              ...ADMIN_PROJECT_DROPDOWNS.map((dropdown) => ({
                key: dropdown.key,
                title: convertToUpperCaseStringForDropdown(
                  dropdown.key,
                  dropdown.type
                ),
                hasSubMenus: false,
                onClick: () => history.push(dropdown.path),
                route: dropdown.path,
              })),
            ],
          },
          {
            key: "demandnotes",
            title: "Demand Notes",
            hasSubMenus: false,
            Icon: DropDownSettingsIcon,
            onClick: () => history.push(DEMAND_NOTES_SETTINGS),
            route: DEMAND_NOTES_SETTINGS,
            permissions: "DEMAND_NOTES_SETTINGS",
          },
          {
            key: ADMIN_PROJECT_OFFER_SETTING,
            title: "Offer Setting",
            hasSubMenus: false,
            Icon: DropDownSettingsIcon,
            onClick: () => history.push(ADMIN_PROJECT_OFFER_SETTING),
            route: ADMIN_PROJECT_OFFER_SETTING,
            permissions: "OFFER_SETTING",
          },
          {
            key: ADMIN_PROJECT_COMMUNICATION_TEMPLATES,
            title: "Communication Templates",
            hasSubMenus: false,
            Icon: DropDownSettingsIcon,
            onClick: () => history.push(ADMIN_PROJECT_COMMUNICATION_TEMPLATES),
            route: ADMIN_PROJECT_COMMUNICATION_TEMPLATES,
            permissions: "COMMUNICATION_TEMPLATES",
          },
          {
            key: ADMIN_PROJECT_INVENTORY_SETTINGS,
            title: 'Inventory Settings',
            hasSubMenus: false,
            onClick: () => history.push(ADMIN_PROJECT_INVENTORY_SETTINGS),
            route: ADMIN_PROJECT_INVENTORY_SETTINGS,
            permissions: 'PROJECT_INVENTORY_SETTINGS'
          },
        ],
      },
    ],
  };
  const transformMenu = (menu) => {
    return menu.map((item) => {
      const { title, permissions, subMenus, sub_permissions } = item;

      if (sub_permissions) {
        return {
          title,
          permissions,
          sub_permissions,
        };
      }

      if (subMenus) {
        return {
          title,
          subMenus: transformMenu(subMenus),
        };
      }

      return { title, permissions };
    });
  };

  const generalMenu = SIDE_BAR_MENU.general.flatMap((section) =>
    transformMenu(section.menu)
  );
  const projectMenu = SIDE_BAR_MENU.project.flatMap((section) =>
    transformMenu(section.menu)
  );
  const getAllUsers = async () => {
    const data = await getSalesRepAndDevAdmin();
    const result = data.map((item) => {
      return {
        ...item,
        label: (item?.firstName ?? "") + " " + (item?.lastName ?? ""),
        value: item?._id,
      };
    });
    setUsers(result);
  };
  useEffect(() => {
    getAllUsers();
  }, []);

  useEffect(() => {
    if (selectedUser !== "") {
      console.log(selectedUser);
      let user = users.find((item) => item._id === selectedUser);
      setSelectedPermissions({
        ...user?.permissions,
      });
      setSelectUserObject(user);
    }
  }, [selectedUser]);

  const handleSave = async () => {
    const filteredPermissions = Object.keys(selectedPermissions)
      .filter((key) => selectedPermissions[key] === true)
      .reduce((obj, key) => {
        obj[key] = true;
        return obj;
      }, {});
    await updatePermissions(selectedPermissions, selectedUser);
  };
  return (
    <div className="px-12 py-4 flex flex-col">
      <h3 className="text-center text-[28px] uppercase">
        Manage User Permissions
      </h3>
      <div className="flex gap-x-5 justify-between items-end">
        <div>
          <button
            type="button"
            onClick={() => {
              setSelectedCategory("general");
            }}
            className={`h-full max-w-[350px] uppercase py-2 px-4  text-base font-medium overflow-hidden font-sans ${selectedCategory === "general"
              ? "border-b-softBlack border-[1px] border-t-0 border-l-0 border-r-0 text-softBlack"
              : "bg-transparent text-softBlack"
              }`}
          >
            General
          </button>
          <button
            type="button"
            onClick={() => {
              setSelectedCategory("project");
            }}
            className={`h-full max-w-[350px] uppercase py-2 px-4 text-base font-medium overflow-hidden font-sans ${selectedCategory === "project"
              ? "border-b-softBlack border-[1px] border-t-0 border-l-0 border-r-0 text-softBlack"
              : "bg-transparent text-softBlack"
              }`}
          >
            Project
          </button>
        </div>

        <div className="w-[300px] text-start mb-2 flex flex-col gap-y-1">
          <CustomSelect
            label="Select User"
            className="border-none"
            value={selectedUser === "" ? "Select User" : selectedUser}
            handleChange={(e) => {
              console.log(e);
              setSelectedUser(e);
            }}
            options={users}
          />
        </div>
      </div>

      <div className="w-full h-[1px] mb-4 bg-[#bfbfbf]"></div>
      <div className="h-[73vh] relative overflow-auto  bg-white rounded-[8px]">
        {selectedUser === "" ? (
          <div className="text-center mt-10 uppercase text-[24px]">
            Please select a user to view permissions
          </div>
        ) : (
          <div className="flex w-full items-center py-8 justify-center gap-x-8">
            {selectedCategory === "general" && (
              <div className="flex flex-col px-8 xl:basis-1/2">
                <div className="flex items-center justify-between">
                  <div className="text-[20px] my-4 text-start font-[600]">
                    GENERAL | {selectUserObject?.label} ({" "}
                    {selectUserObject?.userType} )
                  </div>
                  <div className="flex items-center gap-x-2">
                    <input
                      className="!mb-0"
                      name={"select_all_general"}
                      value={"select_all_general"}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedPermissions({
                            ...selectedPermissions,
                            ...generalMenu.reduce((acc, item) => {
                              if (item.permissions) {
                                acc[item.permissions] = true;
                              }
                              if (item.subMenus) {
                                item.subMenus.forEach((subItem) => {
                                  acc[subItem.permissions] = true;
                                });
                              }
                              return acc;
                            }, {}),
                          });
                        } else {
                          setSelectedPermissions({
                            ...selectedPermissions,
                            ...generalMenu.reduce((acc, item) => {
                              if (item.permissions) {
                                acc[item.permissions] = false;
                              }
                              if (item.subMenus) {
                                item.subMenus.forEach((subItem) => {
                                  acc[subItem.permissions] = false;
                                });
                              }
                              return acc;
                            }, {}),
                          });
                        }

                        // setSelectedPermissions({
                        //   ...selectedPermissions,
                        //   [e.target.value]: e.target.checked,
                        // });
                      }}
                      checked={generalMenu.every((item) => {
                        if (item.permissions) {
                          return selectedPermissions[item.permissions] === true;
                        }
                        return item.subMenus.every((subItem) => {
                          return (
                            selectedPermissions[subItem.permissions] === true
                          );
                        });
                      })}
                      // checked={
                      //   selectedPermissions[
                      //     permission?.permissions || permission?.title
                      //   ] === true
                      // }
                      type="checkbox"
                    />
                    <div className="text-[16px]  text-softBlack_70">
                      Select All
                    </div>
                  </div>
                </div>
                <div className="w-full h-[1px] bg-[#E5E5E5]"></div>
                {generalMenu?.map((permission, index) => {
                  return (
                    <div className="w-full">
                      <div
                        key={index}
                        className="grid col-span-4 gap-x-4 grid-cols-4  py-4"
                      >
                        <div className="text-[18px]  text-softBlack_70 col-span-1 font-[500]">
                          {permission.title}
                        </div>

                        {permission.permissions && (
                          <div
                            style={{
                              cursor:
                                permission.permissions === "PERMISSIONS" &&
                                  selectUserObject.userType === "SalesRep"
                                  ? "not-allowed"
                                  : "auto",
                            }}
                            key={index}
                            className="flex gap-x-3 col-span-3 items-center"
                          >
                            <input
                              style={{
                                cursor:
                                  permission.permissions === "PERMISSIONS" &&
                                    selectUserObject.userType === "SalesRep"
                                    ? "not-allowed"
                                    : "auto",
                              }}
                              name={
                                permission?.permissions || permission?.title
                              }
                              value={
                                permission?.permissions || permission?.title
                              }
                              onChange={(e) => {
                                setSelectedPermissions({
                                  ...selectedPermissions,
                                  [e.target.value]: e.target.checked,
                                });
                              }}
                              checked={
                                selectedPermissions[
                                permission?.permissions || permission?.title
                                ] === true
                              }
                              className="!mb-0"
                              type="checkbox"
                              disabled={
                                permission.permissions === "PERMISSIONS" &&
                                selectUserObject.userType === "SalesRep"
                              }
                            />
                            <div className="text-[16px]  text-softBlack_70">
                              {permission.title}
                            </div>
                          </div>
                        )}
                        {permission.sub_permissions &&
                          selectedPermissions[permission.permissions] ===
                          true && (
                            <div className="flex flex-col col-start-2 mt-2 gap-y-3">
                              {permission.sub_permissions.map((item, index) => (
                                <div
                                  key={index}
                                  className="flex gap-x-3 col-span-3 items-center"
                                >
                                  <input
                                    name={item}
                                    value={item}
                                    onChange={(e) => {
                                      setSelectedPermissions({
                                        ...selectedPermissions,
                                        [e.target.value]: e.target.checked,
                                      });
                                    }}
                                    className="!mb-0"
                                    checked={selectedPermissions[item] === true}
                                    type="checkbox"
                                  />
                                  <div className="text-[16px]  text-softBlack_70">
                                    {item}
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        {permission.subMenus && (
                          <div className="flex flex-col gap-y-4">
                            {permission.subMenus.map((item, index) => (
                              <div
                                key={index}
                                className="flex gap-x-4  items-center"
                              >
                                <input
                                  name={
                                    item?.permissions || item?.title + index
                                  }
                                  value={
                                    item?.permissions || item?.title + index
                                  }
                                  onChange={(e) => {
                                    setSelectedPermissions({
                                      ...selectedPermissions,
                                      [e.target.value]: e.target.checked,
                                    });
                                  }}
                                  className="!mb-0"
                                  checked={
                                    selectedPermissions[item?.permissions] ===
                                    true
                                  }
                                  type="checkbox"
                                />
                                <div className="text-[16px]  text-softBlack_70">
                                  {item.title}
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                      <div className="w-full h-[1px] bg-[#e5e5e5]"></div>
                    </div>
                  );
                })}
              </div>
            )}
            {selectedCategory === "project" && (
              <div className="flex flex-col px-8 xl:basis-1/2">
                <div className="flex items-center justify-between">
                  <div className="text-[20px] my-4 text-start font-[600]">
                    PROJECT | {selectUserObject?.label} ({" "}
                    {selectUserObject?.userType} )
                  </div>
                  <div className="flex items-center gap-x-2">
                    <input
                      name={"select_all_project"}
                      value={"select_all_project"}
                      className="!mb-0"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedPermissions({
                            ...selectedPermissions,
                            ...projectMenu.reduce((acc, item) => {
                              if (item.permissions) {
                                acc[item.permissions] = true;
                              }
                              if (item.subMenus) {
                                item.subMenus.forEach((subItem) => {
                                  acc[subItem.permissions] = true;
                                });
                              }
                              return acc;
                            }, {}),
                            DROP_DOWN_SETTINGS: true,
                          });
                        } else {
                          setSelectedPermissions({
                            ...selectedPermissions,
                            ...projectMenu.reduce((acc, item) => {
                              if (item.permissions) {
                                acc[item.permissions] = false;
                              }
                              if (item.subMenus) {
                                item.subMenus.forEach((subItem) => {
                                  acc[subItem.permissions] = false;
                                });
                              }
                              return acc;
                            }, {}),
                            DROP_DOWN_SETTINGS: false,
                          });
                        }
                        // setSelectedPermissions({
                        //   ...selectedPermissions,
                        //   [e.target.value]: e.target.checked,
                        // });
                      }}
                      checked={
                        projectMenu.every((item) => {
                          if (item.permissions) {
                            return (
                              selectedPermissions[item.permissions] === true
                            );
                          }
                          return item.subMenus.every((subItem) => {
                            return (
                              selectedPermissions[subItem.permissions] === true
                            );
                          });
                        }) && selectedPermissions["DROP_DOWN_SETTINGS"] === true
                      }
                      // checked={
                      //   selectedPermissions[
                      //     permission?.permissions || permission?.title
                      //   ] === true
                      // }
                      type="checkbox"
                    />
                    <div className="text-[16px]  text-softBlack_70">
                      Select All
                    </div>
                  </div>
                </div>
                <div className="w-full h-[1px] bg-[#E5E5E5]"></div>
                {projectMenu?.map((permission, index) => (
                  <div className="w-full">
                    <div
                      key={index}
                      className="grid col-span-3 gap-x-4 grid-cols-3  py-4"
                    >
                      <div className="text-[18px]  text-softBlack_70 col-span-1 2xl:col-span-1 font-[500]">
                        {permission.title}
                      </div>
                      {permission.permissions && (
                        <div
                          key={index}
                          className="flex gap-x-3 col-span-2 2xl:col-span-2 items-center"
                        >
                          <input
                            className="!mb-0"
                            name={permission?.permissions}
                            value={permission?.permissions}
                            onChange={(e) => {
                              setSelectedPermissions({
                                ...selectedPermissions,
                                [e.target.value]: e.target.checked,
                              });
                            }}
                            checked={
                              selectedPermissions[
                              permission?.permissions || permission?.title
                              ] === true
                            }
                            type="checkbox"
                          />
                          <div className="text-[16px]  text-softBlack_70">
                            {permission.title}
                          </div>
                        </div>
                      )}
                      {permission.subMenus &&
                        permission.title !== "Drop Down Settings" && (
                          <div className="flex flex-col gap-y-4">
                            {permission.subMenus.map((item, index) => (
                              <div
                                key={index}
                                className="flex gap-x-4 items-center"
                              >
                                <input
                                  className="!mb-0"
                                  name={item?.permissions}
                                  value={item?.permissions}
                                  onChange={(e) => {
                                    setSelectedPermissions({
                                      ...selectedPermissions,
                                      [e.target.value]: e.target.checked,
                                    });
                                  }}
                                  checked={
                                    selectedPermissions[
                                    item?.permissions || item?.title
                                    ] === true
                                  }
                                  type="checkbox"
                                />
                                <div className="text-[16px]  text-softBlack_70">
                                  {item.title}
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      {permission.subMenus &&
                        permission.title === "Drop Down Settings" && (
                          <div className="flex flex-col gap-y-4">
                            <div
                              key={index}
                              className="flex gap-x-4 items-center"
                            >
                              <input
                                className="!mb-0"
                                name={"DROP_DOWN_SETTINGS"}
                                value={"DROP_DOWN_SETTINGS"}
                                onChange={(e) => {
                                  setSelectedPermissions({
                                    ...selectedPermissions,
                                    ["DROP_DOWN_SETTINGS"]: e.target.checked,
                                  });
                                }}
                                checked={
                                  selectedPermissions["DROP_DOWN_SETTINGS"] ===
                                  true
                                }
                                type="checkbox"
                              />
                              <div className="text-[16px]  text-softBlack_70">
                                {permission.title}
                              </div>
                            </div>
                          </div>
                        )}
                    </div>
                    <div className="w-full h-[1px] bg-[#e5e5e5]"></div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
        <CustomButton
          disabled={selectedUser === "" ? true : false}
          handleClick={handleSave}
          className="fixed right-[100px] bottom-[60px]"
        >
          <span className="font-semibold px-6 py-2 rounded text-base">
            Save
          </span>
        </CustomButton>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userObject: state.authReducer.userObject,
});
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminPermissionManage);
