import React from 'react'
import propTypes from 'prop-types'

const PagesWrapper = ({ children }) => (
  <main className="flex-1 relative z-0 overflow-y-auto">{children}</main>
)

export default PagesWrapper

PagesWrapper.propTypes = {
  children: propTypes.node
}
