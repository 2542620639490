/*eslint-disable */
// @ts-nocheck
import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Oval } from 'react-loader-spinner';
import CustomModal from 'components/Modal';
import { ReactComponent as DownIcon } from '../../../../assets/icons/down.svg';
import CustomInput from 'components/CustomInput';
import CustomTextArea from 'components/CustomTextArea';

import { createOrUpdateTask } from 'store/actions/taskActions';

import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';

import { isMobile } from 'react-device-detect';
import useBuyers from 'hooks/useGetBuyers';
import CustomSelect from '../CustomSelect';
import CustomButton from 'components/CustomButton';
import {
  getAllSalesRepByProject,
  getSearchContacts,
} from 'store/actions/usersActions';
import { useSelector } from 'react-redux';
import { Modal } from '@material-ui/core';
import { Select } from 'antd';

const emptyStates = {
  title: '',
  description: '',
  status: '',
  notifyDate: '',
  dueDate: '',
  buyer: '',
  assignedTo: null,
  taskType: '',
};

export const statusOptions = [
  { label: 'Pending', value: 'pending' },
  { label: 'Closed', value: 'closed' },
];

export const taskTypes = [
  { label: 'Email', value: 'email' },
  { label: 'Call', value: 'call' },
  { label: 'Other', value: 'other' },
];

export const tempTaskTitles = [
  { label: 'WhatsApp Message', value: 'WhatsApp Message' },
  { label: 'Not Started', value: 'Not Started' },
  { label: 'In Progress', value: 'In Progress' },
  { label: 'Completed', value: 'Completed' },
  { label: 'Waiting on someone else', value: 'Waiting on someone else' },
  { label: 'Deferred', value: 'Deferred' },
  {
    label: 'Preview Book Review & Reservation',
    value: 'Preview Book Review & Reservation',
  },
  { label: 'Call', value: 'Call' },
  { label: 'Send Letter', value: 'Send Letter' },
  { label: 'Other', value: 'Other' },
  { label: 'Send CMA', value: 'Send CMA' },
  {
    label: 'Follow-up with Comps/ Listings',
    value: 'Follow-up with Comps/ Listings',
  },
  { label: 'Follow-up', value: 'Follow-up' },
  { label: 'Schedule Showing', value: 'Schedule Showing' },
  { label: 'Incoming Call', value: 'Incoming Call' },
  { label: 'Outgoing Call', value: 'Outgoing Call' },
  { label: 'Appointment', value: 'Appointment' },
  { label: 'Email', value: 'Email' },
  { label: 'Appointment - New Customer', value: 'Appointment - New Customer' },
  { label: 'Appointment - New Realtor', value: 'Appointment - New Realtor' },
  { label: 'Visit', value: 'Visit' },
  { label: 'Introduction Call', value: 'Introduction Call' },
  { label: 'Follow Up Call', value: 'Follow Up Call' },
  { label: 'Meeting', value: 'Meeting' },
  { label: 'Introduction Meeting', value: 'Introduction Meeting' },
  { label: 'Follow Up Meeting', value: 'Follow Up Meeting' },
  { label: 'Task', value: 'Task' },
  { label: 'Follow Up Task', value: 'Follow Up Task' },
  { label: 'Contract Update', value: 'Contract Update' },
  { label: 'Financing Update', value: 'Financing Update' },
  { label: 'Deposit Update', value: 'Deposit Update' },
  { label: 'Closing Update', value: 'Closing Update' },
  { label: 'First Contact Attempt', value: 'First Contact Attempt' },
  { label: 'Second Contact Attempt', value: 'Second Contact Attempt' },
  { label: 'Third Contact Attempt', value: 'Third Contact Attempt' },
  { label: 'Fourth Contact Attempt', value: 'Fourth Contact Attempt' },
  { label: 'Owner Visit', value: 'Owner Visit' },
];

const TaskModal = ({ show, setClose, task, refetch, contact }) => {
  const { buyers, getBuyersData, loading: isBuyerListLoading } = useBuyers();
  const projectId = useSelector((state) => state.appReducer.appProject);
  const userObject = useSelector((state) => state.authReducer.userObject);
  const initialStates = {
    title: task?.title || emptyStates.title,
    description: task?.description || emptyStates.description,
    status: task?.status || emptyStates.status,
    notifyDate: task?.notifyDate
      ? moment(task?.notifyDate).format('YYYY-MM-DDTHH:mm')
      : emptyStates.notifyDate,
    dueDate: task?.dueDate
      ? moment(task?.dueDate).format('YYYY-MM-DDTHH:mm')
      : emptyStates.dueDate,
    buyer: task?.buyer?._id || contact?._id || emptyStates.buyer,
    taskType: task?.taskType || emptyStates.taskType,
    assignedTo:
      task?.assignedTo?._id || userObject._id || emptyStates.assignedTo,
    project: projectId,
  };

  useEffect(() => {
    if (!buyers?.length && !task?._id) {
      getBuyersData();
    }
  }, [task]);

  const showTimeInitialValue = !!task?.notifyDate;

  const [taskDetails, setTaskDetails] = useState({
    ...initialStates,
  });
  const [loading, setLoading] = useState(false);
  const [showTime, setShowTime] = useState(showTimeInitialValue);
  const [buyerDetails] = useState(task?.buyer || {});
  const [salesRepData, setSalesRepData] = useState([]);
  const [taskStatusConfirmModal, setTaskStatusConfirmModal] = useState(false);
  const [taskStatusConfirmation, setTaskStatusConfirmation] = useState(false);
  const [searchedUsers, setSearchedUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const onChange = (fieldName, value) => {
    if (fieldName === 'status' && value === 'closed' && taskStatusConfirmModal === false)
      setTaskDetails({ ...taskDetails, [fieldName]: emptyStates.status });
    else {
      setTaskDetails({ ...taskDetails, [fieldName]: value });
    }
  };

  const handleStatusChange = (value) => {
    if (value === 'closed') {
      setTaskStatusConfirmModal(true);
    } else {
      onChange('status', value);
    }
  };
  const handleSearchUser = async (value, setSearchedUsers) => {
    let { data } = await getSearchContacts({
      userType: 'Buyer',
      search: value,
    });

    const formattedData = data.map((user) => {
      return {
        value: user._id,
        label: `${user.firstName} ${user.lastName}`,
        _id: user._id,
        id: user._id,
      };
    });
    setSearchedUsers(formattedData);
  };

  useEffect(() => {
    const FirstFetch = async () => {
      let { data } = await getSearchContacts({
        userType: 'Buyer',
        search: 'A',
        limit: 10,
      });

      const formattedData = data.map((user) => {
        return {
          value: user._id,
          label: `${user.firstName} ${user.lastName}`,
          _id: user._id,
          id: user._id,
        };
      });
      setSearchedUsers(formattedData);
    };

    FirstFetch();
  }, []);
  const handleConfirm = (confirmed) => {
    if (confirmed) {
      onChange('status', 'closed');
    }
    setTaskStatusConfirmModal(false);
  };

  const onValidate = useMemo(() => {
    if (task?._id) return true;
    for (const key in taskDetails) {
      if (
        taskDetails[key] === '' &&
        key !== 'notifyDate' &&
        key !== 'assignedTo' &&
        key !== 'description'
      ) {
        return false;
      }
    }
    return true;
  }, [taskDetails]);

  const onSave = async () => {
    let date = null;
    if (!taskDetails.dueDate) {
      const temp = new Date();
      temp.setHours(11);
      temp.setMinutes(59);
      temp.setMilliseconds(0);
      date = temp;
    }
    const params = {
      _id: task?._id,
      ...taskDetails,
      dueDate: new Date(taskDetails.dueDate).toISOString(),
      notifyDate: showTime ? new Date(taskDetails.notifyDate) : null,
    };
    if (!taskDetails.dueDate) params.dueDate = date;
    setLoading(true);
    try {
      await createOrUpdateTask(params).then((res) => {
        if (task?._id) {
          refetch?.({
            ...res,
            buyer: buyerDetails,
          });
        } else {
          refetch();
        }
      });
      setLoading(false);
      if (!task?._id) {
        setTaskDetails({ ...initialStates });
      }
      setClose(true);
    } catch (err) {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setShowTime(showTimeInitialValue);
    setTaskDetails({ ...initialStates });
    setClose(false);
  };

  useEffect(() => {
    getAllSalesRepByProject(projectId).then((res) => {
      setSalesRepData(res);
    });
  }, []);

  return (
    <Modal
      open={show}
      onClose={setClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div
        className='bg-grey-8 p-5 md:p-10 relative h-[95%] overflow-auto rounded shadow-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'
        style={{ width: isMobile ? '95vw' : 544 }}
      >
        <button className='absolute right-5 md:right-10' onClick={handleClose}>
          <CrossIcon />
        </button>
        <h1 className='font-bold text-xl text-center p-0 m-0'>
          {task?._id ? 'Update Task' : 'New Task'}
        </h1>
        <div className={`flex flex-col ${isMobile ? 'gap-4' : 'pt-8 gap-6'}`}>
          <div className={'flex flex-col gap-2'}>
            <CustomInput
              label='Title *'
              placeholder='Enter title'
              labelClassName='mt-0 mb-2 text-softBlack70 text-xs font-medium uppercase'
              value={taskDetails.title}
              classes='text-sm'
              onChange={(e) => onChange('title', e.target.value)}
            />
            <p className='text-softBlack70 uppercase font-bold text-sm text-center mb-0'>
              OR
            </p>
            <CustomSelect
              label='Title Dropdown'
              value={
                taskDetails.title === '' ? 'Select title' : taskDetails.title
              }
              handleChange={(value) => {
                onChange('title', value);
              }}
              options={tempTaskTitles}
            />
          </div>
          <div>
            {!task?._id && !contact?._id ? (
              <div className='w-full flex flex-col gap-y-1.5'>
                <div className='text-softBlack70 text-xs font-medium uppercase'>
                  Buyer *
                </div>
                <Select
                  style={{
                    width: '100%',
                  }}
                  showArrow
                  placeholder='Search connections'
                  getPopupContainer={(node) => node.parentNode}
                  onChange={(value) => {
                    onChange('buyer', value);
                  }}
                  value={
                    taskDetails.buyer === ''
                      ? 'Select Buyer'
                      : taskDetails.buyer
                  }
                  className='ant-picker-input1'
                  suffixIcon={<DownIcon />}
                  showSearch={true}
                  onSearch={(value) => {
                    handleSearchUser(value, setSearchedUsers);
                  }}
                  filterOption={false}
                  optionFilterProp='_id'
                >
                  {searchedUsers?.map((contact, index) => (
                    <Select.Option key={index} label={contact.label} value={contact.value}>
                      {contact.label}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            ) : (
              <div className='w-full flex flex-col gap-y-1.5'>
                <div className='text-softBlack70 text-xs font-medium uppercase'>
                  Buyer *
                </div>
                <Select
                  style={{
                    width: '100%',
                  }}
                  showArrow
                  placeholder='Search connections'
                  getPopupContainer={(node) => node.parentNode}
                  onChange={(value) => {
                    onChange('buyer', value);
                  }}
                  value={
                    taskDetails.buyer === ''
                      ? 'Select Buyer'
                      : taskDetails.buyer
                  }
                  className='ant-picker-input1'
                  suffixIcon={<DownIcon />}
                  showSearch={true}
                  onSearch={(value) => {
                    handleSearchUser(value, setSearchedUsers);
                  }}
                  filterOption={false}
                  optionFilterProp='_id'
                  disabled
                >
                  {searchedUsers?.map((contact) => (
                    <Select.Option label={contact.label} value={contact.value}>
                      {contact.label}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            )}
          </div>
          {/* <div>
            {!task?._id && !contact?._id ? (
              <CustomSelect
                label='Buyer *'
                value={
                  taskDetails.buyer === '' ? 'Select Buyer' : taskDetails.buyer
                }
                handleChange={(value) => onChange('buyer', value)}
                options={buyers}
                isName
                disabled={isBuyerListLoading}
              />
            ) : (
              <CustomInput
                containerClasses='opacity-60'
                label='Buyer *'
                labelClassName='mt-0 mb-2 text-softBlack70 text-xs font-medium uppercase'
                value={
                  task?._id
                    ? task.buyer?.firstName + ' ' + task.buyer?.lastName
                    : contact?.firstName + ' ' + contact?.lastName
                }
                classes='text-sm cursor-not-allowed'
                disabled
              />
            )}
          </div> */}
          <div>
            <CustomSelect
              label='Assigned To'
              value={
                taskDetails.assignedTo === '' || !taskDetails.assignedTo
                  ? 'Select Assigned To'
                  : taskDetails.assignedTo
              }
              handleChange={(value) => onChange('assignedTo', value)}
              options={salesRepData}
              isName
            />
          </div>
          <CustomTextArea
            containerClasses='flex flex-col'
            label='description'
            labelClassName='text-xs'
            placeholder='Enter description'
            value={taskDetails.description}
            onChange={(e) => {
              onChange('description', e.target.value);
            }}
            classes='w-full text-sm'
            style={{ color: '#2E2B2E' }}
          />
          <CustomSelect
            label='Status *'
            value={
              taskDetails.status === '' ? 'Select status' : taskDetails.status
            }
            handleChange={handleStatusChange}
            options={statusOptions}
          />
          <CustomSelect
            label='Task Type *'
            value={
              taskDetails.taskType === ''
                ? 'Select Task Type'
                : taskDetails.taskType
            }
            handleChange={(value) => {
              onChange('taskType', value);
            }}
            options={taskTypes}
          />
          <CustomInput
            label='Due Date *'
            labelClassName='mt-0 mb-2 text-softBlack70 text-xs font-medium uppercase'
            value={moment(taskDetails.dueDate).format('YYYY-MM-DDTHH:mm')}
            onChange={(e) => {
              let isoString = new Date(e.target.value).toISOString();
              onChange('dueDate', isoString)
            }}
            type='datetime-local'
            classes='w-full uppercase text-base'
            min={null}
            format-value="YYYY-MM-DD"
          />
          {showTime && (
            <CustomInput
              label='Enter date & time *'
              value={moment(taskDetails.notifyDate).format('YYYY-MM-DD')}
              onChange={(e) => onChange('notifyDate', moment(e.target.value).format())}
              type='datetime-local'
              classes='w-full uppercase text-base'
              min={moment().format('YYYY-MM-DD')}
            />
          )}
          <CustomButton handleClick={onSave} disabled={!onValidate}>
            <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
              {loading && <Oval height='18' width='18' color='white' />}
              {task?._id ? 'Update' : 'Save'}
            </span>
          </CustomButton>
        </div>
        {taskStatusConfirmModal && (
          <Modal open={taskStatusConfirmModal}>
            <div
              style={{ width: isMobile ? '95vw' : 400 }}
              className='bg-grey-8 p-2 md:p-8 relative top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'
            >
              <button
                className='absolute right-5'
                onClick={() => setTaskStatusConfirmModal(false)}
              >
                <CrossIcon />
              </button>
              <div className='flex flex-col gap-y-6 justify-center items-center'>
                <div className='mt-6 text-[18px]'>
                  Are you sure you want set task as Completed?
                </div>
                <div className='flex gap-x-6'>
                  <CustomButton
                    handleClick={() => {
                      handleConfirm(true);
                    }}
                  >
                    <span className='font-medium py-2 px-4 text-base flex gap-2 items-center'>
                      Yes
                    </span>
                  </CustomButton>
                  <CustomButton
                    handleClick={() => {
                      handleConfirm(false);
                    }}
                  >
                    <span className='font-medium py-2 px-4 text-base flex gap-2 items-center'>
                      No
                    </span>
                  </CustomButton>
                </div>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </Modal>
  );
};

export default TaskModal;
