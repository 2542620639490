// @ts-nocheck
/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";

import { ReactComponent as CrossIcon } from "assets/icons/cross.svg";
import { Oval } from "react-loader-spinner";

import { Modal } from "@material-ui/core";
import CustomInput from "components/CustomInput";
import CustomSelect from "components/CustomSelect";
import { createOrUpdateUnit } from "store/actions/unitActions";
import useGetFloorPlans from "hooks/useGetFloorPlans";
import useGetUnitGroups from "hooks/useGetUnitGroups";
import { useSelector } from "react-redux";
import { isObject } from "lodash";
import useCurrencyList from "hooks/useCurrencyList";
import ProjectFileSelector from "components/ProjectFileSelector";
import CustomButton from "components/CustomButton";
import {
  createOrUpdateInventory,
  getInventorySettings,
} from "store/actions/inventoryActions";
import { off } from "process";

const UnitEditViewV2 = ({
  show,
  setShow,
  editDetails,
  setEditDetails,
  refetch,
  building,
}) => {
  // @ts-ignore
  const appProject = useSelector((state) => state.appReducer.appProject);
  const mountedRef = useRef(true);
  const [inventoryFields, setInventoryFields] = useState([]);
  const { floorPlans } = useGetFloorPlans();
  const [inventoryType, setInventoryType] = useState("");
  const { unitGroups } = useGetUnitGroups();
  const { formattedCurrencyList: currencyData } = useCurrencyList();
  const initialEmptyValues = {
    project: appProject,
    lotData: {},
    condoData: {},
    townhomeData: {},
    building: building,
  };
  const [value, setValues] = useState({
    ...initialEmptyValues,
  });

  useEffect(() => {
    getInventorySettings(appProject).then((data) => {
      let type = data[0].type;
      setInventoryType(type);
      let fields = Object.keys(data[0][type + "Data"]).reduce((acc, key) => {
        if (key === "additionalFields") {
          const additionalFieldsMapped = data[0][type + "Data"][key].map(
            (item) => {
              if (item.enabled === true) {
                return {
                  label: item.name,
                  key: item.id,
                  flag: "additionalFields",
                  type: item?.type || 'text',
                  placeholder: `Enter ${item.name}`,
                };
              }
            }
          );
          return [...acc, ...additionalFieldsMapped];
        } else {
          if (data[0][type + "Data"][key].enabled === true) {
            return [
              ...acc,
              {
                label: key,
                key: key,
                type: data[0][type + "Data"][key].type || 'text',
                placeholder: `Enter ${key}`,
              },
            ];
          } else {
            return acc;
          }
        }
      }, []);
      let filter = fields.filter((item) => item !== undefined);
      setInventoryFields(filter);
    });
  }, [appProject, building]);

  const TEMP_AVAILABILITY_STATUS = {
    available: "available",
    open: "Open",
    sold: "sold",
    soldFirm: "SoldFirm",
    developerHeld: "DeveloperHeld",
    reserved: "reserved",
    reservedBroker: "ReservedBroker",
    reservedBuyer: "ReservedBuyer",
    closed: "Closed",
    underContract: "UnderContract",
    offerAccepted: "OfferAccepted",
  };

  const STATUS_OPTIONS = [
    ...Object.values(TEMP_AVAILABILITY_STATUS).map((value) => {
      return { value: value, id: value };
    }),
  ];

  const balconyOptions = [
    { id: "true", value: "Yes" },
    { id: "false", value: "No" },
  ];

  const LOT_TYPE_OPTION = [
    { id: "Large", value: "Large" },
    { id: "Standard", value: "Standard" },
    { id: "Medium", value: "Medium" },
    { id: "Villa", value: "Villa" },
  ];

  const COLLECTION_OPTION = [
    { id: "Manor", value: "Manor" },
    { id: "Estate", value: "Estate" },
    { id: "Villa", value: "Villa" },
  ];

  const DOCK_TYPE_OPTION = [
    { id: "dock-type-1", value: "Dock type 1" },
    { id: "dock-type-2", value: "Dock type 2" },
    { id: "dock-type-3", value: "Dock type 3" },
  ];

  const YES_NO_OPTION = [
    { id: true, value: "Yes" },
    { id: false, value: "No" },
  ];

  const STR_OPTION = [
    { id: "STR", value: "STR" },
    { id: "NON-STR", value: "NON-STR" },
  ];

  const MOORING_LINE_TYPE_OPTIONS = [
    { id: "B", value: "B" },
    { id: "C", value: "C" },
    { id: "D", value: "D" },
    { id: "E", value: "E" },
    { id: "N/A", value: "N/A" },
  ];

  const handleSubmit = () => {
    // if (validate()) {
    // setLoading(true)
    const temp = {
      // @ts-ignore
      ...value,
      type: inventoryType,
    };
    createOrUpdateInventory(temp)
      .then((data) => {
        refetch();
        setLoading(false);
        setShow(false);
        setEditDetails({});
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const onChange = (fieldName, newValue) => {
    if (inventoryType === "condo") {
      setValues({
        ...value,
        condoData: {
          ...value.condoData,
          [fieldName]: newValue,
        },
      });
    } else if (inventoryType === "townhome") {
      setValues({
        ...value,
        townhomeData: {
          ...value.townhomeData,
          [fieldName]: newValue,
        },
      });
    } else {
      setValues({
        ...value,
        lotData: {
          ...value.lotData,
          [fieldName]: newValue,
        },
      });
    }
  };

  const onLotDataChange = (fieldName, newValue) => {
    setValues({
      ...value,
      lotData: {
        ...value.lotData,
        [fieldName]: newValue,
      },
    });
  };
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setShow(false);
    setEditDetails({});
  };

  const validate = () => {
    // for (const key in inventoryFields) {
    //   if (!value[inventoryFields[key].key]) {
    //     return false
    //   }
    // }
    return true;
  };


  useEffect(() => {
    if (process.env.REACT_APP_INENVETORY_SETTINGS_UI) {
      let key = {
        lot: "lotData",
        condo: "condoData",
        townhome: "townhomeData",
      }[editDetails?.type];
      let additionalFields =
        editDetails?.additionalFields;
      let obj;
      setValues({
        ...value,
        ...editDetails,
        [key]: {
          ...value[key],
          ...editDetails[key],
        },
      });
    } else {
      if (editDetails?.type === "Units") {
        setValues({
          ...value,
          ...editDetails,
          status: STATUS_OPTIONS.filter(
            ({ id }) => id === editDetails.status
          )?.[0]?.id,
          balcony: editDetails.balcony ? "true" : "false",
        });
      } else {
        setValues(initialEmptyValues);
      }
    }
  }, [editDetails, setEditDetails]);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const handleStatusChange = (id) => {
    const temp = STATUS_OPTIONS.filter((option) => option.id === id)[0];
    onChange("status", temp.id);
  };

  const handleContractPDFChange = (contractObject) => {
    onChange("contractPdf", contractObject._id ?? contractObject.id);
  };

  const handleParkingPDFChange = (parkingObject) => {
    onChange("parkingPdf", parkingObject._id ?? parkingObject.id);
  };

  const handleCurrencyChange = (id) => {
    const temp = currencyData.filter((option) => option.id === id)[0];
    onChange("priceCurrencyType", temp.id);
  };

  const handleBalconyChange = (id) => {
    const temp = balconyOptions.filter((option) => option.id === id)[0];
    onChange("balcony", temp.id);
  };

  const handleImageChange = (image) => {
    onChange("images", [image.url]);
  };

  const handleLotDataSelectChange = (collection, fieldName, id) => {
    const temp = collection.filter((option) => option.id === id)[0];
    onLotDataChange(fieldName, temp.id);
  };

  return (
    <div className="p-4 relative flex flex-col h-[calc(100%-32px)] overflow-hidden rounded-lg border border-black/[20%]">
      <div className="w-full px-2 grid grid-cols-6 gap-x-3 h-full overflow-y-auto thin-scrollbar">
        {inventoryFields.map((field) => {
          return (
            <div className="grid col-span-3 gap-6 mb-4">
                {field.type === "text" || field.type === 'number' ? (
                  <CustomInput
                    label={field?.label}
                    placeholder={field?.placeholder}
                    labelClassName="italic"
                    type={field?.type}
                    value={
                      field?.flag === "additionalFields"
                        ? value[
                            inventoryType === "condo"
                              ? "condoData"
                              : inventoryType === "townhome"
                              ? "townhomeData"
                              : "lotData"
                          ]?.additionalFields?.[field?.key] || ''
                        : value[
                            inventoryType === "condo"
                              ? "condoData"
                              : inventoryType === "townhome"
                              ? "townhomeData"
                              : "lotData"
                          ]?.[field?.key]
                    }
                    onChange={(e) => {
                      if (field.flag === "additionalFields") {
                        let newValue = e.target.value;
                        let id = field.key;
                        setValues({
                          ...value,
                          [inventoryType + "Data"]: {
                            ...value[inventoryType + "Data"],
                            additionalFields: {
                              ...value[inventoryType + "Data"].additionalFields,
                              [id]: newValue,
                            },
                          },
                        });
                      } else {
                        onChange(field.key, e.target.value);
                      }
                    }}
                    required={"required"}
                    errorMessage={`Please enter ${field?.label}`}
                    containerClasses={undefined}
                    classes={undefined}
                  />
                ) : (
                  <CustomSelect
                    label={field?.label}
                    inputClasses="h-11"
                    labelClasses={undefined}
                    options={STATUS_OPTIONS}
                    selectedOption={value[
                      inventoryType === "condo"
                        ? "condoData"
                        : inventoryType === "townhome"
                        ? "townhomeData"
                        : "lotData"
                    ][field?.key]}
                    setSelectedOption={(option) => {
                      onChange(field.key, option);
                    }}
                  />
                )}
              </div>
          );
        })}
        {/* <div className='grid grid-cols-2 gap-6 mb-6'>
            <div className='grid grid-cols-2 gap-6'>
              <CustomInput
                label='strata lot number *'
                placeholder='Enter number'
                labelClassName='italic'
                value={value.strataLotNumber}
                onChange={(e) => {
                  onChange('strataLotNumber', e.target.value)
                }}
                required='required'
                errorMessage='Please enter lot number'
                containerClasses={undefined}
                classes={undefined}
              />
              <CustomSelect
                label='status *'
                inputClasses='h-11'
                labelClasses={undefined}
                options={STATUS_OPTIONS}
                selectedOption={value.status}
                setSelectedOption={(option) => {
                  handleStatusChange(option)
                }}
              />
            </div>
          </div> */}
      </div>
      <div className="flex justify-start items-start w-full">
        <CustomButton
          handleClick={handleSubmit}
          disabled={!validate() || loading}
        >
          <span className="font-medium py-3 px-6 text-base flex gap-2 items-center">
            {loading && <Oval height="18" width="18" color="white" />}
            {value?._id ? "Update" : "Add"} Unit
          </span>
        </CustomButton>
      </div>
    </div>
  );
};

export default UnitEditViewV2;
