import React, {
  useState, useEffect,
  forwardRef, useImperativeHandle,
  useCallback, useRef
} from 'react'
import propTypes from 'prop-types'
import { Button, InputLabel, TButton } from 'ui'
import { Camera, CameraButton, DrawerFooterButtons, FadedAnimatedDiv, FileSelector, GridFormWrapper, ImageUpload, ImageUploadMethodsButtons, UserIdIcon } from 'components'
import DrawerChildrenWrapper from 'components/DrawerChildrenWrapper'
import { changeUserImages, getUsers } from 'store/actions/usersActions'
import { getDevelopers } from 'store/actions/developersActions'
import { Row } from 'antd'
import { getProjects } from 'store/actions/projectActions'
import { AnimatePresence, motion } from 'framer-motion'

import {
  UploadMethodsWrapper
} from './styles'
import { createUploadFileList } from 'utils/createUploadFileList'
import { getFileUrl } from 'utils'

const residentData = [
  { id: false, value: 'No' },
  { id: true, value: 'Yes' }
]

const prefixData = [
  { id: 'mr', value: 'Mr.' },
  { id: 'miss', value: 'Miss.' },
  { id: 'ms', value: 'Ms.' },
  { id: 'mrs', value: 'Mrs.' }
]

const GET_PHOTO_ID_FROM_CAMERA = 'get_photo_id_from_camera'
const GET_PHOTO_ID_FROM_LIBRARY = 'get_photo_id_from_library'

const cameraButtonsAnimationValues = {
  buttonExit: (direction) => ({
    x: direction === 'left' ? -1000 : 1000
  }),
  buttonEnter: {
    x: 0
  },
  cameraExitY: {
    y: -1000
  },
  cameraEnterY: {
    y: 0
  },
  cameraExitX: {
    x: 1000
  },
  cameraEnterX: {
    x: 0
  },
  uploadError: {

  }
}

const FrontSidePhotoIdUploader = ({ onClick, uploadProps, frontPhotoId }) => (
  <ImageUpload
    title='Front Photo ID'
    uploadProps={{
      listType: 'picture-card',
      className: 'avatar-uploader',
      style: { height: '100%', width: '100%' },
      ...uploadProps
    }}
    uploadWrapperStyle={{ margin: 10 }}
    // uploadingImage={uploadingFrontPhotoId}
    uploadButtonIcon={<UserIdIcon style={{ fontSize: 30 }} />}
    uploadButtonText='Add Front Side Photo ID'
    uploadButtonStyle={{ padding: 20 }}
    showUploadButton={!frontPhotoId.length}
    onClick={(e) => {
      if (onClick) {
        e.stopPropagation()
        onClick()
      }
    }}
  />
)

const BackSidePhotoIdUploader = ({ onClick, uploadProps, backPhotoId }) => (
  <ImageUpload
    title='Back Photo ID'
    uploadProps={{
      listType: 'picture-card',
      className: 'avatar-uploader',
      style: { height: '100%', width: '100%' },
      ...uploadProps
    }}
    uploadWrapperStyle={{ margin: 10 }}
    // uploadingImage={uploadingBackPhotoId}
    uploadButtonIcon={<UserIdIcon style={{ fontSize: 30 }} />}
    uploadButtonText='Add Back Side Photo ID'
    uploadButtonStyle={{ padding: 20 }}
    showUploadButton={!backPhotoId.length}
    onClick={(e) => {
      if (onClick) {
        e.stopPropagation()
        onClick()
      }
    }}
  />
)

const CreateUser = forwardRef((props, ref) => {
  const {
    id, onSaveClick, onCancelClick,
    saving, userType, showPaymentTokens,
    showFooterButtons, loggedUserType,
    externalObject, onChangeFrontPhotoIdFile,
    onChangeBackPhotoIdFile
  } = props

  const [email, setEmail] = useState('')
  const [prefix, setPrefix] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [userAvatar, setUserAvatar] = useState('')
  const [legalName, setLegalName] = useState('')
  const [address, setAddress] = useState('')
  const [city, setCity] = useState('')
  const [province, setProvince] = useState('')
  const [country, setCountry] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [resident, setResident] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [mobileNumber, setMobileNumber] = useState('')
  const [workNumber, setWorkNumber] = useState('')
  const [employer, setEmployer] = useState('')
  const [fax, setFax] = useState('')
  const [bus, setBus] = useState('')
  const [sin, setSin] = useState('')
  const [iQ11Token, setiQ11Token] = useState('')
  const [occupation, setOccupation] = useState('')
  const [stripeCustomerId, setStripeCustomerId] = useState('')
  const [payorId, setPayorId] = useState('')
  const [typeformData, setTypeformData] = useState({})
  const [rating, setRating] = useState('')
  const [preferredContact, setPreferredContact] = useState('')
  const [assignments, setAssignments] = useState([])
  const [additionalFields, setAdditionalFields] = useState([])
  const [developerCompany, setDeveloperCompany] = useState('')
  const [twitter, setTwitter] = useState('')
  const [webSite, setWebSite] = useState('')
  const [salesRep, setSalesRep] = useState('')
  const [projects, setProjects] = useState([])
  const [licenseNumber, setLicenseNumber] = useState('')
  const [licenseBrokerage, setLicenseBrokerage] = useState('')
  const [licenseExpiration, setLicenseExpiration] = useState('')
  const [idNumber, setIdNumber] = useState('')
  const [idType, setIdType] = useState('')
  const [birthday, setBirthday] = useState('')
  const [idExpireDate, setIdExpireDate] = useState('')
  const [allowAdditionalServices, setAllowAdditionalServices] = useState(false)
  const [allowSurveys, setAllowSurveys] = useState(false)

  const [gettingData, setGettingData] = useState(false)
  const [developersData, setDevelopersData] = useState([])
  const [salesRepData, setSalesRepData] = useState([])
  const [projectsData, setProjectsData] = useState([])
  const [gettingDevelopers, setGettingDevelopers] = useState(false)
  const [gettingSalesReps, setGettingSalesReps] = useState(false)
  const [gettingProjects, setGettingProjects] = useState(false)
  const [salesRepVisible, setSalesRepVisible] = useState(true)

  const [chosenPhotoIdMethod, setChosenPhotoIdMethod] = useState('')
  const [selectedPhotoIdSource, setSelectedPhotoIdSource] = useState('')
  const [frontPhotoId, setFrontPhotoId] = useState([])
  const [frontPhotoIdUrl, setFrontPhotoIdUrl] = useState('')
  const [backPhotoId, setBackPhotoId] = useState([])
  const [backPhotoIdUrl, setBackPhotoIdUrl] = useState('')
  const [cameraOpened, setCameraOpened] = useState(false)
  const [photoToSet, setPhotoToSet] = useState('front')
  const [projectsDisabled, setProjectDisabled] = useState(false)
  const [uploadingFrontPhotoId, setUploadingFrontPhotoId] = useState(false)
  const [uploadingBackPhotoId, setUploadingBackPhotoId] = useState(false)

  const webcamRef = useRef(null)

  const refLegalName = useRef(legalName)

  const updateLegalName = (value) => {
    refLegalName.current = value
    setLegalName(value)
  }

  useImperativeHandle(ref, () => ({
    getUserData () {
      const params = _getUserData()

      return ({
        params,
        extraData: {
          frontPhotoId: frontPhotoId,
          backPhotoId: backPhotoId
        }
      })
    }
  }))

  useEffect(() => {
    if (externalObject && Object.keys(externalObject).length) {
      Object.entries(externalObject).forEach((element) => {
        const field = element[0]
        const value = element[1]

        if (field === 'firstName') {
          setFirstName(value)
          updateLegalName(value)
        }

        if (field === 'lastName') {
          setLastName(value)
          updateLegalName(`${refLegalName.current} ${value}`)
        }

        if (field === 'address') setAddress(value)
        if (field === 'chosenPhotoIdMethod') setChosenPhotoIdMethod(value)

        if (field === 'projects') {
          setProjects(value)
          setProjectDisabled(true)
        }

        if (field === 'frontPhotoId') {
          const tmpFrontPhotoId = createUploadFileList(value)
          setFrontPhotoId(tmpFrontPhotoId)
        }

        if (field === 'province') setProvince(value)
        if (field === 'country') setCountry(value)
        if (field === 'postalCode') setPostalCode(value)
        if (field === 'idNumber') setIdNumber(value)
        if (field === 'idType') setIdType(value)
        if (field === 'birthday') setBirthday(value)
        if (field === 'idExpireDate') setIdExpireDate(value)
      })
    }
  }, [externalObject])

  useEffect(() => {
    if (loggedUserType === 'SalesRep' || loggedUserType === 'CoopBroker') {
      setSalesRepVisible(false)
    }
  }, [loggedUserType])

  // When mounted, get the list of developers to fill Developers Select
  useEffect(() => {
    if ((userType === 'DeveloperAdmin') ||
        (userType === 'SalesRep') ||
        (userType === 'CoopBroker')) {
      setGettingDevelopers(true)

      getDevelopers()
        .then((developers) => {
          const newDevelopersData =
        developers.map((developer) => ({
          id: developer._id, value: developer.companyName
        }))
          setDevelopersData(newDevelopersData)
          setGettingDevelopers(false)
        })
        .catch(() => {
          setGettingDevelopers(false)
        })
    }

    if (userType === 'Buyer' && loggedUserType !== 'CoopBroker') {
      setGettingSalesReps(true)
      getUsers('', '?userType=SalesRep')
        .then((users) => {
          const newSalesRep = users.map((user) => ({
            id: user._id, value: `${user.firstName} ${user.lastName}`
          }))

          setSalesRepData(newSalesRep)
          setGettingSalesReps(false)
        })
        .catch(() => {
          setGettingSalesReps(false)
        })
    }

    setGettingProjects(true)
    getProjects('')
      .then((projects) => {
        const tmpProjects = projects.map((project) => ({
          id: project._id, value: project.projectName
        }))

        setProjectsData(tmpProjects)
        setGettingProjects(false)
      })
      .catch(() => {
        setGettingProjects(false)
      })
  }, [userType, loggedUserType])

  const getUserData = useCallback(() => {
    if (id) {
      setGettingData(true)

      // Get user data from id
      getUsers(id)
        .then((user) => {
          const {
            email,
            firstName,
            lastName,
            userAvatar,
            buyerData,
            brokerData,
            developerAdminData,
            salesRepData,
            project
          } = user

          setEmail(email)
          setFirstName(firstName)
          setLastName(lastName)
          setUserAvatar(userAvatar)

          // Buyer
          if (buyerData) {
            const {
              city,
              province,
              country,
              phoneNumber,
              prefix,
              occupation,
              address,
              postalCode,
              bus,
              fax,
              sin,
              resident,
              iQ11Token,
              stripeCustomerId,
              payorId,
              frontPhotoId,
              backPhotoId,
              typeformData,
              rating,
              legalName,
              preferredContact,
              workNumber,
              mobileNumber,
              employer,
              socialMedia,
              assignments,
              additionalFields,
              birthday,
              idType,
              idNumber,
              idExpireDate,
              allowAdditionalServices,
              allowSurveys
            } = buyerData

            let twitter = ''
            let webSite = ''

            if (socialMedia) {
              twitter = buyerData.socialMedia.twitter
              webSite = buyerData.socialMedia.webSite
            }

            if (project) {
              setProjects(project)
            }

            if (frontPhotoId) {
              const tmpFrontPhotoId = createUploadFileList(frontPhotoId)
              setFrontPhotoId(tmpFrontPhotoId)
              setFrontPhotoIdUrl(frontPhotoId)
            }

            if (backPhotoId) {
              const tmpBackPhotoId = createUploadFileList(backPhotoId)
              setBackPhotoId(tmpBackPhotoId)
              setBackPhotoIdUrl(backPhotoId)
            }

            if (frontPhotoId || backPhotoId) {
              // Just to show the images, and not the upload method selector
              setChosenPhotoIdMethod(GET_PHOTO_ID_FROM_LIBRARY)
            }

            if (twitter) {
              setTwitter(twitter)
            }

            if (webSite) {
              setWebSite(webSite)
            }

            setCity(city)
            setProvince(province)
            setCountry(country)
            setPhoneNumber(phoneNumber)
            setPrefix(prefix)
            setOccupation(occupation)
            setAddress(address)
            setPostalCode(postalCode)
            setBus(bus)
            setFax(fax)
            setSin(sin)
            setResident(resident)
            setiQ11Token(iQ11Token)
            setStripeCustomerId(stripeCustomerId)
            setPayorId(payorId)
            setTypeformData(typeformData)
            setRating(rating)
            updateLegalName(legalName)
            setPreferredContact(preferredContact)
            setWorkNumber(workNumber)
            setMobileNumber(mobileNumber)
            setEmployer(employer)
            setAssignments(assignments)
            setAdditionalFields(additionalFields)
            setBirthday(birthday)
            setIdType(idType)
            setIdExpireDate(idExpireDate)
            setIdNumber(idNumber)
            setAllowAdditionalServices(allowAdditionalServices)
            setAllowSurveys(allowSurveys)
          }

          // Coop Broker
          if (brokerData) {
            const {
              licenseNumber,
              licenseBrokerage,
              licenseExpiration,
              developerCompany,
              city,
              province,
              country
            } = brokerData

            setLicenseNumber(licenseNumber)
            setLicenseBrokerage(licenseBrokerage)
            setLicenseExpiration(licenseExpiration)
            setDeveloperCompany(developerCompany)

            setCity(city)
            setProvince(province)
            setCountry(country)
          }

          // Developer Admin
          if (developerAdminData) {
            const { developerCompany } = developerAdminData
            setDeveloperCompany(developerCompany)
          }

          // Sales Rep
          if (salesRepData) {
            const {
              developerCompany: {
                _id: developerCompanyId
              },
              projects
            } = salesRepData

            setDeveloperCompany(developerCompanyId)
            setProjects(projects)
          }

          setGettingData(false)
        })
    } else {
      // cleanValues()
      setGettingData(false)
    }
  }, [id])

  useEffect(() => {
    getUserData()
  }, [getUserData])

  const _getUserData = () => {
    let baseObject = {
      id,
      email,
      firstName,
      lastName,
      userType,
      userAvatar
    }

    let params = {}

    if (userType === 'Buyer' || userType === 'Lead') {
      baseObject = Object.assign({}, baseObject, { project: projects })

      params = Object.assign({}, baseObject, {
        buyerData: {
          city,
          province,
          country,
          phoneNumber,
          prefix,
          occupation,
          address,
          postalCode,
          bus,
          fax,
          sin,
          resident,
          iQ11Token,
          stripeCustomerId,
          payorId,
          legalName,
          mobileNumber,
          workNumber,
          employer,
          socialMedia: {
            twitter,
            webSite
          },
          typeformData,
          rating,
          preferredContact,
          assignments,
          additionalFields,
          frontPhotoId: frontPhotoIdUrl,
          backPhotoId: backPhotoIdUrl,
          birthday,
          idType,
          idNumber,
          idExpireDate,
          allowAdditionalServices,
          allowSurveys
        }
      })
    }

    if (userType === 'DeveloperAdmin') {
      params = Object.assign({}, baseObject, {
        developerAdminData: {
          developerCompany: developerCompany
        }
      })
    }

    if (userType === 'SalesRep') {
      params = Object.assign({}, baseObject, {
        salesRepData: {
          developerCompany: developerCompany,
          projects: projects
        }
      })
    }

    if (userType === 'CoopBroker') {
      params = Object.assign({}, baseObject, {
        brokerData: {
          licenseNumber: licenseNumber,
          licenseBrokerage: licenseBrokerage,
          licenseExpiration: licenseExpiration,
          developerCompany: developerCompany
        }
      })
    }

    return params
  }

  const _onSaveClick = () => {
    const params = _getUserData()

    onSaveClick({
      params,
      extraData: {
        frontPhotoId: frontPhotoId,
        backPhotoId: backPhotoId
      },
      // After the record is saved, clean all the fields
      executeWhenDone: () => console.log('done')
    })
  }

  const _onCancelClick = () => {
    // cleanValues()
    onCancelClick()
  }

  const setPhoto = useCallback(async (photo) => {
    let setter = ''
    let changeUserImagesParams = {}
    const objUrl = await getFileUrl(photo)
    const tmpPhotoId = createUploadFileList(objUrl)

    if (id) {
      changeUserImagesParams = Object.assign({}, { userId: id })
    }

    if (photoToSet === 'front') {
      setter = setFrontPhotoId

      onChangeFrontPhotoIdFile(photo)
      changeUserImagesParams = Object.assign({},
        changeUserImagesParams,
        {
          frontPhotoId: photo
        }
      )
    } else {
      setter = setBackPhotoId

      onChangeBackPhotoIdFile(photo)
      changeUserImagesParams = Object.assign({},
        changeUserImagesParams,
        {
          backPhotoId: photo
        }
      )
    }

    setter(tmpPhotoId)
    setCameraOpened(false)

    if (id) {
      if (photoToSet === 'front') setUploadingFrontPhotoId(true)
      else setUploadingBackPhotoId(true)

      await changeUserImages(changeUserImagesParams, true)
      setUploadingFrontPhotoId(false)
      setUploadingBackPhotoId(false)
    }
  }, [id, photoToSet, onChangeBackPhotoIdFile, onChangeFrontPhotoIdFile])

  return (
    <>
      <DrawerChildrenWrapper>
        <span className='text-2xl'>Personal Info</span>
        <GridFormWrapper>
          {
            userType === 'Buyer' && (
              <InputLabel
                id='prefix'
                name='prefix'
                type='text'
                label='Prefix'
                xs={24}
                sm={24}
                md={3}
                lg={3}
                value={prefix}
                onChange={(e) => setPrefix(e)}
                placeholder='user prefix'
                loading={gettingData}
                typeInput='select'
                data={prefixData}
              />
            )
          }

          <InputLabel
            id='firstName'
            name='firstName'
            type='text'
            label='First name'
            xs={24}
            sm={24}
            md={userType === 'Buyer' ? 7 : 8}
            lg={userType === 'Buyer' ? 7 : 8}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            placeholder='the first name of the user'
            required
            validateKey='createUser'
            loading={gettingData}
          />
          <InputLabel
            id='lastName'
            name='lastName'
            type='text'
            label='Last name'
            xs={24}
            sm={24}
            md={userType === 'Buyer' ? 7 : 8}
            lg={userType === 'Buyer' ? 7 : 8}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            placeholder={'and it\'s last name'}
            required
            validateKey='createUser'
            loading={gettingData}
          />
          {
            userType === 'Buyer' && (
              <InputLabel
                id='legalName'
                name='legalName'
                type='text'
                label='Legal name'
                xs={24}
                sm={24}
                md={7}
                lg={7}
                value={refLegalName.current}
                onChange={(e) => updateLegalName(e.target.value)}
                placeholder="buyer's legal name"
                required
                validateKey='createUser'
                loading={gettingData}
              />
            )
          }

          {
            userType === 'Buyer' && (
              <>
                <InputLabel
                  id='birthday'
                  name='birthday'
                  typeInput='datepicker'
                  label='Birthday'
                  xs={24}
                  sm={24}
                  md={6}
                  lg={6}
                  value={birthday}
                  onChange={(e) => setBirthday(e)}
                  placeholder="buyer's birthday"
                  required
                  validateKey='createUser'
                  loading={gettingData}
                />

                <InputLabel
                  id='idNumber'
                  name='idNumber'
                  type='text'
                  label='Id number'
                  xs={24}
                  sm={24}
                  md={6}
                  lg={6}
                  value={idNumber}
                  onChange={(e) => setIdNumber(e.target.value)}
                  placeholder="buyer's id's number"
                  required
                  validateKey='createUser'
                  loading={gettingData}
                />
                <InputLabel
                  id='idType'
                  name='idType'
                  type='text'
                  label='Id type'
                  xs={24}
                  sm={24}
                  md={6}
                  lg={6}
                  value={idType}
                  onChange={(e) => setIdType(e.target.value)}
                  placeholder="buyer's id type"
                  required
                  validateKey='createUser'
                  loading={gettingData}
                />
                <InputLabel
                  id='idExpiryDate'
                  name='idExpiryDate'
                  typeInput='datepicker'
                  label='Id expiry date'
                  xs={24}
                  sm={24}
                  md={6}
                  lg={6}
                  value={idExpireDate}
                  onChange={(e) => setIdExpireDate(e)}
                  placeholder="buyer's id's expiry date"
                  required
                  validateKey='createUser'
                  loading={gettingData}
                />
              </>
            )
          }

          {
            ((userType === 'DeveloperAdmin') ||
              (userType === 'SalesRep') ||
              (userType === 'CoopBroker')) &&
              (
                <>
                  <InputLabel
                    id='developerCompany'
                    name='developerCompany'
                    type='text'
                    label='Developer company'
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    value={!developerCompany ? [] : developerCompany}
                    onChange={(id) => setDeveloperCompany(id)}
                    placeholder='what is the company of the Developer Admin?'
                    loading={gettingDevelopers || gettingData}
                    typeInput='select'
                    data={developersData}
                    required
                    validateKey='createUser'
                    mode={userType === 'CoopBroker' && 'multiple'}
                  />

                  {
                    userType === 'CoopBroker' && (
                      <>
                        <InputLabel
                          id='licenseNumber'
                          name='licenseNumber'
                          type='text'
                          label='License number'
                          xs={24}
                          sm={24}
                          md={8}
                          lg={8}
                          value={licenseNumber}
                          onChange={(e) => setLicenseNumber(e.target.value)}
                          placeholder='license number'
                          loading={gettingData}
                        />
                        <InputLabel
                          id='licenseBrokerage'
                          name='licenseBrokerage'
                          type='text'
                          label='License brokerage'
                          xs={24}
                          sm={24}
                          md={8}
                          lg={8}
                          value={licenseBrokerage}
                          onChange={(e) => setLicenseBrokerage(e.target.value)}
                          placeholder='license brokerage'
                          loading={gettingData}
                        />
                        <InputLabel
                          id='licenseExpiration'
                          name='licenseExpiration'
                          type='text'
                          label='License expiration'
                          xs={24}
                          sm={24}
                          md={8}
                          lg={8}
                          value={licenseExpiration}
                          onChange={(e) => setLicenseExpiration(e || '')}
                          placeholder='license expiration'
                          loading={gettingData}
                          typeInput='datepicker'
                        />
                      </>
                    )
                  }
                </>
              )
          }
        </GridFormWrapper>

        {
          userType === 'Buyer' && (
            <>
              <div className='mt-5'>
              <span className='text-2xl '>Address</span>

              <GridFormWrapper>
                <InputLabel
                  id='address'
                  name='address'
                  type='text'
                  label='Address'
                  xs={24}
                  sm={24}
                  md={7}
                  lg={7}
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  placeholder={'the buyer\'s address'}
                  loading={gettingData}
                />
                <InputLabel
                  id='city'
                  name='city'
                  type='text'
                  label='City'
                  xs={24}
                  sm={24}
                  md={6}
                  lg={6}
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  placeholder={'it\'s city'}
                  loading={gettingData}
                />
                <InputLabel
                  id='province'
                  name='province'
                  type='text'
                  label='Province'
                  xs={24}
                  sm={24}
                  md={6}
                  lg={6}
                  value={province}
                  onChange={(e) => setProvince(e.target.value)}
                  placeholder={'and it\'s province'}
                  loading={gettingData}
                />
                <InputLabel
                  id='country'
                  name='country'
                  type='text'
                  label='Country'
                  xs={24}
                  sm={24}
                  md={5}
                  lg={5}
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  placeholder='the country'
                  loading={gettingData}
                />
                <InputLabel
                  id='postalCode'
                  name='postalCode'
                  type='text'
                  label='Postal code'
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  value={postalCode}
                  onChange={(e) => setPostalCode(e.target.value)}
                  placeholder='fill the postal code'
                  loading={gettingData}
                />
                <InputLabel
                  id='resident'
                  name='resident'
                  type='text'
                  typeInput='select'
                  label='Resident'
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  value={resident}
                  onChange={(e) => setResident(e)}
                  placeholder='buyer is resident'
                  loading={gettingData}
                  data={residentData}
                />
              </GridFormWrapper>
            </div>

            <div className='mt-5'>
              <span className='text-2xl my-5'>Contacts</span>

              <GridFormWrapper>
                <InputLabel
                  id='phoneNumber'
                  name='phoneNumber'
                  type='tel'
                  label='Phone number'
                  xs={24}
                  sm={24}
                  md={4}
                  lg={4}
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  placeholder='fill the number phone'
                  loading={gettingData}
                />
                <InputLabel
                  id='mobileNumber'
                  name='mobileNumber'
                  type='tel'
                  label='Mobile number'
                  xs={24}
                  sm={24}
                  md={4}
                  lg={4}
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                  placeholder='fill the mobile phone'
                  loading={gettingData}
                />
                <InputLabel
                  id='workNumber'
                  name='workNumber'
                  type='tel'
                  label='Work number'
                  xs={24}
                  sm={24}
                  md={4}
                  lg={4}
                  value={workNumber}
                  onChange={(e) => setWorkNumber(e.target.value)}
                  placeholder='fill the work phone number'
                  loading={gettingData}
                />
                <InputLabel
                  id='fax'
                  name='fax'
                  type='tel'
                  label='Fax'
                  xs={24}
                  sm={24}
                  md={4}
                  lg={4}
                  value={fax}
                  onChange={(e) => setFax(e.target.value)}
                  placeholder='fill the fax number'
                  loading={gettingData}
                />
                <InputLabel
                  id='bus'
                  name='bus'
                  type='text'
                  label='Bus'
                  xs={24}
                  sm={24}
                  md={4}
                  lg={4}
                  value={bus}
                  onChange={(e) => setBus(e.target.value)}
                  placeholder='fill the bus'
                  loading={gettingData}
                />
                <InputLabel
                  id='sin'
                  name='sin'
                  type='text'
                  label='Sin'
                  xs={24}
                  sm={24}
                  md={4}
                  lg={4}
                  value={sin}
                  onChange={(e) => setSin(e.target.value)}
                  placeholder='fill the sin'
                  loading={gettingData}
                />
              </GridFormWrapper>

              <GridFormWrapper>
                {
                  userType === 'Buyer' && (
                    <>
                      <InputLabel
                        id='email'
                        name='email'
                        type='email'
                        label='Email'
                        xs={24}
                        sm={24}
                        md={8}
                        lg={8}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder={'the buyer\'s email'}
                        required
                        validateKey='createUser'
                        loading={gettingData}
                      />
                      <InputLabel
                        id='occupation'
                        name='occupation'
                        type='text'
                        label='Occupation'
                        xs={24}
                        sm={24}
                        md={8}
                        lg={8}
                        value={occupation}
                        onChange={(e) => setOccupation(e.target.value)}
                        placeholder={'the buyer\'s occupation'}
                        loading={gettingData}
                        required
                        validateKey='createUser'
                      />
                      <InputLabel
                        id='employer'
                        name='employer'
                        type='text'
                        label='Employer'
                        xs={24}
                        sm={24}
                        md={8}
                        lg={8}
                        value={employer}
                        onChange={(e) => setEmployer(e.target.value)}
                        placeholder={'the buyer\'s employer'}
                        loading={gettingData}
                      />

                      {salesRepVisible && (
                        <InputLabel
                          id='salesRep'
                          name='salesRep'
                          type='text'
                          label='Sales rep'
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          typeInput='select'
                          placeholder='is the buyer related to any Sales Rep?'
                          onChange={(id) => setSalesRep(id)}
                          loading={gettingSalesReps || gettingData}
                          data={salesRepData}
                          value={salesRep}
                        />
                      )}

                      {
                        showPaymentTokens && (
                          <>
                            <InputLabel
                              id='iQ11Token'
                              name='iQ11Token'
                              type='text'
                              label='iQ11Token'
                              xs={24}
                              sm={24}
                              md={8}
                              lg={8}
                              value={iQ11Token}
                              onChange={(e) => setiQ11Token(e.target.value)}
                              placeholder='iQ11Token of the buyer'
                              loading={gettingData}
                              readOnly
                            />
                            <InputLabel
                              id='stripeCustomerId'
                              name='stripeCustomerId'
                              type='text'
                              label='Stripe customer ID'
                              xs={24}
                              sm={24}
                              md={8}
                              lg={8}
                              value={stripeCustomerId}
                              onChange={(e) => setStripeCustomerId(e.target.value)}
                              placeholder='the stripe customer ID'
                              loading={gettingData}
                              readOnly
                            />
                            <InputLabel
                              id='payorId'
                              name='payorId'
                              type='text'
                              label='Payor ID'
                              xs={24}
                              sm={24}
                              md={8}
                              lg={8}
                              value={payorId}
                              onChange={(e) => setPayorId(e.target.value)}
                              placeholder='payor ID'
                              loading={gettingData}
                              readOnly
                            />
                          </>
                        )
                      }
                    </>
                  )
                }
              </GridFormWrapper>

              {
                (
                  (userType === 'Buyer') ||
                  (userType === 'SalesRep')
                ) && (
                  <InputLabel
                    id='projects'
                    name='projects'
                    type='text'
                    label={userType === 'SalesRep' ? 'Projects' : 'Project'}
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    value={!projects ? [] : projects}
                    onChange={(id) => setProjects(id)}
                    placeholder='project'
                    loading={gettingProjects || gettingData}
                    typeInput='select'
                    mode={userType === 'SalesRep' && 'multiple'}
                    required
                    validateKey='createUser'
                    data={projectsData}
                    disabled={projectsDisabled}
                    inputWrapperClassName='mt-3'
                  />
                )
              }
            </div>
          </>
          )}

        {userType === 'Buyer' && (
          <div className='mt-5'>
            <span className='text-2xl my-5'>Buyer Photo ID</span>

            <AnimatePresence exitBeforeEnter initial={false}>
              {
                !chosenPhotoIdMethod
                  ? (
                      <FadedAnimatedDiv
                        key='choosingUploadMethod'
                        id='choosingUploadMethod'
                        className='flex flex-col items-center justify-center relative h-full w-full'
                      >
                        <UploadMethodsWrapper
                          className='flex items-center justify-center flex-row-reverse overflow-hidden rounded w-88 h-52'
                          // style={{ width: 350, height: 200 }}
                        >
                          <ImageUploadMethodsButtons
                            style={{ padding: '5px 0px', zIndex: 0 }}
                            animate={false}
                            onCameraMethodClick={() => {
                              setSelectedPhotoIdSource(GET_PHOTO_ID_FROM_CAMERA)
                            }}
                            onLibraryMethodClick={() => {
                              setSelectedPhotoIdSource(GET_PHOTO_ID_FROM_LIBRARY)
                            }}
                            cameraSelected={selectedPhotoIdSource === GET_PHOTO_ID_FROM_CAMERA}
                            librarySelected={selectedPhotoIdSource === GET_PHOTO_ID_FROM_LIBRARY}
                          />
                        </UploadMethodsWrapper>
                        <TButton
                          className='mt-4'
                          onClick={() => setChosenPhotoIdMethod(selectedPhotoIdSource)}
                        >
                          Select
                        </TButton>
                      </FadedAnimatedDiv>
                    )
                  : (
                      <FadedAnimatedDiv
                        key='uploadButtons'
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        {
                          chosenPhotoIdMethod === GET_PHOTO_ID_FROM_LIBRARY
                            ? (
                              <Row className='space-x-10 my-5'>
                                <FileSelector
                                  title='Front Photo ID'
                                  listOfFiles={frontPhotoId}
                                  loading={gettingData || uploadingFrontPhotoId}
                                  onRemoveFile={({ fileList }) => {
                                    if (!fileList.length) {
                                      setFrontPhotoId([])
                                      onChangeFrontPhotoIdFile('')
                                    }
                                  }}
                                  onSelectFile={(fileList) => {
                                    if (fileList) {
                                      const file = fileList[0]

                                      setFrontPhotoId(fileList)
                                      onChangeFrontPhotoIdFile(file)

                                      if (id) {
                                        setUploadingFrontPhotoId(true)
                                        changeUserImages({ userId: id, frontPhotoId: file }, true)
                                          .then(() => {
                                            setUploadingFrontPhotoId(false)
                                          })
                                          .catch(() => setUploadingFrontPhotoId(false))
                                      }
                                    }
                                  }}
                                />

                                <FileSelector
                                  title='Back Photo ID'
                                  listOfFiles={backPhotoId}
                                  loading={gettingData || uploadingBackPhotoId}
                                  onRemoveFile={({ fileList }) => {
                                    if (!fileList.length) {
                                      setBackPhotoId([])
                                      onChangeBackPhotoIdFile('')
                                    }
                                  }}
                                  onSelectFile={(fileList) => {
                                    if (fileList) {
                                      const file = fileList[0]

                                      setBackPhotoId(fileList)
                                      onChangeBackPhotoIdFile(file)

                                      if (id) {
                                        setUploadingBackPhotoId(true)
                                        changeUserImages({ userId: id, backPhotoId: file }, true)
                                          .then(() => {
                                            setUploadingBackPhotoId(false)
                                          })
                                          .catch(() => setUploadingBackPhotoId(false))
                                      }
                                    }
                                  }}
                                />
                              </Row>
                              )
                            : (
                              <Row>
                                <AnimatePresence exitBeforeEnter initial={false}>
                                  {
                                    !cameraOpened
                                      ? (
                                      <div className='space-x-10 flex flex-row my-10'>
                                        <motion.div
                                          key='frontPhotoIdCameraButton'
                                          variants={cameraButtonsAnimationValues}
                                          initial='buttonExit'
                                          animate='buttonEnter'
                                          exit='buttonExit'
                                          custom='left'
                                          transition={{
                                            x: { type: 'spring', stiffness: 300, damping: 30 }
                                          }}
                                        >
                                          <CameraButton
                                            photo={frontPhotoId}
                                            onClick={() => {
                                              setPhotoToSet('front')
                                              setCameraOpened(true)
                                            }}
                                            onRemovePhoto={() => {
                                              setFrontPhotoId([])
                                              onChangeFrontPhotoIdFile('')
                                              setFrontPhotoIdUrl('')
                                            }}
                                            title='Photograph the Front Photo ID'
                                            loading={uploadingFrontPhotoId}
                                          />
                                        </motion.div>

                                        <motion.div
                                          key='backPhotoIdCameraButton'
                                          variants={cameraButtonsAnimationValues}
                                          initial='buttonExit'
                                          animate='buttonEnter'
                                          exit='buttonExit'
                                          custom='right'
                                          transition={{
                                            x: { type: 'spring', stiffness: 300, damping: 30 }
                                          }}
                                        >
                                          <CameraButton
                                            photo={backPhotoId}
                                            onClick={() => {
                                              setPhotoToSet('back')
                                              setCameraOpened(true)
                                            }}
                                            onRemovePhoto={() => {
                                              setBackPhotoId([])
                                              onChangeBackPhotoIdFile('')
                                              setBackPhotoIdUrl('')
                                            }}
                                            title='Photograph the Back Photo ID'
                                            loading={uploadingBackPhotoId}
                                          />
                                        </motion.div>
                                      </div>
                                        )
                                      : (
                                        <Camera
                                          key='cameraComponent'
                                          ref={webcamRef}
                                          width='100%'
                                          onTakePhoto={(photo) => setPhoto(photo)}
                                          onClickCloseButton={() => setCameraOpened(false)}
                                        />
                                        )
                                  }

                                </AnimatePresence>
                              </Row>
                              )
                        }
                        <Button onClick={() => {
                          setChosenPhotoIdMethod('')
                          setCameraOpened(false)
                        }}
                        >
                          Change Upload Method
                        </Button>
                      </FadedAnimatedDiv>
                    )
              }
            </AnimatePresence>
          </div>
        )}

      </DrawerChildrenWrapper>

      {
        showFooterButtons && (
          <DrawerFooterButtons
            onCancelClick={_onCancelClick}
            onSaveClick={() => _onSaveClick(false)}
            saving={saving}
            parentIsLoading={gettingData}
          />
        )
      }
    </>
  )
})

export default CreateUser

CreateUser.defaultProps = {
  showPaymentTokens: true,
  showFooterButtons: true
}

CreateUser.propTypes = {
  onSaveClick: propTypes.func,
  onCancelClick: propTypes.func,
  saving: propTypes.bool,
  id: propTypes.string,
  userType: propTypes.string,
  showPaymentTokens: propTypes.bool,
  showFooterButtons: propTypes.bool,
  loggedUserType: propTypes.string,
  externalObject: propTypes.object,
  onChangeFrontPhotoIdFile: propTypes.func,
  onChangeBackPhotoIdFile: propTypes.func
}

FrontSidePhotoIdUploader.propTypes = {
  onClick: propTypes.func,
  uploadProps: propTypes.object,
  frontPhotoId: propTypes.array
}

BackSidePhotoIdUploader.propTypes = {
  onClick: propTypes.func,
  uploadProps: propTypes.object,
  backPhotoId: propTypes.array
}
