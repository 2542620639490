/* eslint multiline-ternary: ["error", "never"] */
// @ts-nocheck
/* eslint-disable */
import CustomButton from 'components/CustomButton';
import React, { useEffect, useState } from 'react';
import { getNylasSchedulerPages } from 'store/actions/usersActions';
import { Link } from 'react-router-dom';
import CustomModal from 'components/Modal';
import { CloseIcon, NylasSchedulerEditor } from '@nylas/react';
import toast from 'react-hot-toast';
const ShadCNWrapper = ({ children, className = '' }) => (
  <div
    className={`rounded-xl border bg-card text-card-foreground shadow bg-white overflow-hidden ${className}`}
  >
    {children}
  </div>
);
const SchedulerMeetings = ({ open, handleClose }) => {
  const [meetings, setMeetings] = useState([]);
  const [schedulerModal, setSchedulerModal] = useState(false);
  const [configId, setConfigId] = useState(null);
  const [copyLinkState, setCopyLinkState] = useState('Copy Link');

  useEffect(() => {
    getNylasSchedulerPages()
      .then((data) => {
        setMeetings([...data.data]);
      })
      .catch((error) => {
        console.error('getNylasSchedulerPages', error);
      });
  }, []);

  useEffect(() => {
    if (copyLinkState === 'Copied') {
      const timeoutId = setTimeout(() => {
        setCopyLinkState('Copy Link');
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [copyLinkState]);

  return (
    <CustomModal isOpen={open} closeModal={() => handleClose(false)}>
      <div className='p-6 min-w-[50vw]'>
        <div
          onClick={() => {
            handleClose(false);
          }}
          className='absolute cursor-pointer right-3 top-3'
        >
          <CloseIcon />
        </div>
        <div className='text-center mt-2 text-[22px] font-bold mb-6'>
          Scheduled Meetings
        </div>
        <div className='flex flex-col gap-y-3'>
          {meetings?.map((data, index) => {
            return (
              <ShadCNWrapper
                key={index}
                className='p-4 items-center shadow-none flex justify-between'
              >
                <div className='font-semibold'>
                  {data.event_booking.title}{' '}
                  <span className='font-normal'>
                    ( {data?.participants?.[0].name ?? ''} )
                  </span>
                </div>
                <div className='flex gap-x-2 items-center'>
                  <CustomButton
                    handleClick={() => {
                      setConfigId(data.id);
                      setSchedulerModal(true);
                    }}
                    variant='outline'
                    className='px-3 py-1.5'
                  >
                    Edit Meeting
                  </CustomButton>
                  <CopyLinkButton
                    meetingUrl={`https://book.nylas.com/us/${process.env.REACT_APP_NYLAS_CLIENT_ID}/${data.slug}`}
                  />
                  <CustomButton className='px-3 py-1.5'>
                    <a
                      href={`https://book.nylas.com/us/${process.env.REACT_APP_NYLAS_CLIENT_ID}/${data.slug}`}
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-white hover:!text-white'
                      title='Go To Meeting'
                    >
                      Go To Meeting
                    </a>
                  </CustomButton>
                </div>
              </ShadCNWrapper>
            );
          })}
        </div>
        {schedulerModal && (
          <CustomModal
            customStyles={{
              backgroundColor: '#fcfcfd',
              display: 'flex',
              flexDirection: 'column',
            }}
            isOpen={schedulerModal}
            closeModal={() => setSchedulerModal(false)}
          >
            <div className='flex flex-col w-full h-full pb-16 gap-y-4 '>
              <NylasSchedulerEditor
                onInit={(e) => {
                  console.log('onInit', e);
                }}
                style={{
                  boxShadow: 'none',
                  border: '1px solid #E5E5E5',
                }}
                configurationId={configId}
                onSchedulerConfigCloseClicked={(e) => {
                  e.preventDefault();
                  setSchedulerModal(false);
                }}
                schedulerPreviewLink={`https://book.nylas.com/us/${process.env.REACT_APP_NYLAS_CLIENT_ID}/{slug}`}
                nylasSessionsConfig={{
                  clientId: process.env.REACT_APP_NYLAS_CLIENT_ID,
                  redirectUri: `${window.location.origin}/admin/calendar`,
                  domain: 'https://api.us.nylas.com/v3',
                  accessType: 'offline',
                }}
                requiresSlug={true}
                defaultSchedulerConfigState={{
                  selectedConfiguration: {
                    requires_session_auth: false,
                    scheduler: {
                      rescheduling_url: `https://book.nylas.com/us/reschedule/:booking_ref`,
                      cancellation_url: `https://book.nylas.com/us/cancel/:booking_ref`,
                    },
                  },
                }}
              />
            </div>
          </CustomModal>
        )}
      </div>
    </CustomModal>
  );
};

export default SchedulerMeetings;

const CopyLinkButton = ({ meetingUrl }) => {
  const [copyLinkState, setCopyLinkState] = useState('Copy Link');

  useEffect(() => {
    if (copyLinkState === 'Copied') {
      const timeoutId = setTimeout(() => {
        setCopyLinkState('Copy Link');
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [copyLinkState]);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(meetingUrl);
    setCopyLinkState('Copied');
    toast.success('Link Copied');
  };

  return (
    <CustomButton handleClick={handleCopyLink} className='px-3 py-1.5'>
      {copyLinkState === 'Copy Link' ? (
        <span>Copy Link</span>
      ) : (
        <span>Copied</span>
      )}
    </CustomButton>
  );
};
