import { ContactProvider } from 'pages/contacts/context/Contacts'
import React from 'react'
import EditPage from './EditPage'
import { CreateBrokerOpportunityProvider } from '../brokerCreateOpportunitiesScreen/context/CreateBrokerOpportunityContext'

const EditOpportunity = () => {
  return (
    <CreateBrokerOpportunityProvider>
      <ContactProvider>
        <EditPage />
      </ContactProvider>
    </CreateBrokerOpportunityProvider>
  )
}

export default EditOpportunity
