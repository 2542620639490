import { useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { setBrokerLeadDetails } from 'store/actions/brokerLeadActions'

const useCreateBrokerLeads = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  // @ts-ignore
  const userObject = useSelector((state) => state.authReducer.userObject)

  const callApi = useCallback(
    async (projectId, NewBrokerLead) => {
      try {
        setLoading(true)

        let updatedObject = null
        if (!NewBrokerLead?._id) {
          updatedObject = {
            project: projectId,
            createdBy: userObject?._id,
            ...NewBrokerLead
          }
        }

        const response = await setBrokerLeadDetails(
          !NewBrokerLead?._id ? updatedObject : NewBrokerLead
        )
        setData(response)
        setLoading(false)
        return response
      } catch (e) {
        setLoading(false)
        console.log('Failed to load data for', e)
      }
    },
    [userObject]
  )

  return { createOrUpdateBrokerLead: callApi, loading, data }
}

export default useCreateBrokerLeads
