import React from 'react'
import propTypes from 'prop-types'
import glassStyles from './glassStyles.module.scss'

const GlassedWrapper = ({ children, className }) => {
  return (
    <div className={`${glassStyles.glassMenu} ${className}`}>
      <div className='z-10 w-full'>
        {children}
      </div>
    </div>
  )
}

export default GlassedWrapper

GlassedWrapper.propTypes = {
  children: propTypes.node,
  className: propTypes.string
}
