import propTypes from 'prop-types'
// @ts-nocheck
import React from 'react'
import { DocumentPagination } from './../styles'
import { onlyNumbers } from 'utils'
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons'

const PdfPagination = ({
  pdfPageNumber,
  setPdfPageNumber,
  onChangePdfPage,
  pdfNumPages,
  decreaseSelectedContractPage,
  increaseSelectedContractPage
}) => {
  return (
    <DocumentPagination className='mt-1' justify='center'>
      <ArrowLeftOutlined
        className='mx-2'
        onClick={(e) => decreaseSelectedContractPage(e)}
      />
      <span className='text-base mx-1'>Page</span>
      <input
        value={pdfPageNumber}
        type='text'
        onChange={(e) => {
          setPdfPageNumber(onlyNumbers(e.target.value))
          onChangePdfPage(onlyNumbers(e.target.value))
        }}
        disabled={pdfNumPages === 1}
        className='bg-transparent border-0 p-0 my-1 w-auto text-center max-w-10 mx-auto'
      />
      <span className='text-base'>{`of ${pdfNumPages}`}</span>
      <ArrowRightOutlined
        className='mx-2'
        onClick={(e) => increaseSelectedContractPage(e)}
      />
    </DocumentPagination>
  )
}
export default React.memo(PdfPagination)

PdfPagination.propTypes = {
  pdfPageNumber: propTypes.number,
  setPdfPageNumber: propTypes.number,
  onChangePdfPage: propTypes.func,
  pdfNumPages: propTypes.number,
  decreaseSelectedContractPage: propTypes.func,
  increaseSelectedContractPage: propTypes.func
}
