// @ts-nocheck
import React, { useCallback, useState, useMemo } from 'react'
// import standardStyle from 'assets/css/standardStyle'
import {
  ContractThumbnailWrapper,
  PageControlWrapper,
  ContractThumbnailDocumentWrapper
  // ContractSignatureStatusWrapper
} from './../styles'
import PdfDocument from './PdfDocument'
import propTypes from 'prop-types'
// import AnimatedList from 'components/AnimatedList'
import PdfPagination from './PdfPagination'
// import { ReactComponent as TickIcon } from 'assets/icons/tickk.svg'
import { isSelectedContractSignedByUser } from '..'
import { getUserType } from 'utils'
import { Check } from 'lucide-react'

// const contractVariants = {
//   hidden: {
//     x: -100,
//     opacity: 0
//   },
//   enter: (custom) => ({
//     x: 0,
//     opacity: 1,
//     background: 'transparent',
//     transition: {
//       delay: custom * 0.08
//     }
//   }),
//   selected: (custom) => ({
//     x: 0,
//     opacity: 1,
//   background: standardStyle.colors.mainBackground,
//     transition: {
//       delay: custom * 0.08
//     }
//   })
// }

const signatureStatus = {
  awaiting: 'Pending',
  awaiting_signature: 'Pending',
  signed: 'Signed',
  canceled: 'Canceled'
}

const ContractThumbnail = ({
  contractData,
  index,
  selected,
  onSelectDoc,
  onChangePdfPage,
  onLoadSuccess,
  isPublicPath = false,
  isGroupedContracts = false
}) => {
  const isContractSigned = useMemo(() => {
    return (
      (isPublicPath ||
        isSelectedContractSignedByUser(contractData, 'DeveloperAdmin')) &&
      isSelectedContractSignedByUser(contractData, 'Buyer')
    )
  }, [contractData])

  const { url, signatures, contractTemplate } = contractData
  const [pdfPageNumber, setPdfPageNumber] = useState(1)
  const [pdfNumPages, setPdfNumPages] = useState(0)

  const DocumentStatus = useCallback(
    ({ signature }) => {
      if (!signatures?.length) {
        return null
      }
      const status = signature.status
      const signatureUserType = signature.userType
      return (<div className='w-full mx-auto flex gap-2 items-center'>
        <div className='rounded font-semibold truncate'>
          {signatureUserType}:
        </div>
        <div className={`rounded-full px-3 text-white font-semibold ${signature.status === 'signed' ? 'bg-green-500' : 'bg-yellow-600'}`}>
          {signatureStatus[status]}
        </div>
      </div>)
    },
    [signatures]
  )

  const decreaseSelectedContractPage = useCallback(
    (e) => {
      e.stopPropagation()
      if (pdfPageNumber > 1) {
        const newNumber = Number(pdfPageNumber) - 1
        setPdfPageNumber(newNumber)
        onChangePdfPage(newNumber)
      }
    },
    [pdfPageNumber]
  )

  const increaseSelectedContractPage = useCallback(
    (e) => {
      e.stopPropagation()
      if (pdfPageNumber < pdfNumPages) {
        const newNumber = Number(pdfPageNumber) + 1
        setPdfPageNumber(newNumber)
        onChangePdfPage(newNumber)
      }
    },
    [pdfPageNumber, pdfNumPages]
  )

  const showSignatureStatus = !isPublicPath && signatures?.length
  return (
    <ContractThumbnailWrapper
      key={index}
      // variants={contractVariants}
      // initial='hidden'
      // animate={selected ? 'selected' : 'enter'}
      // custom={index}
      onClick={() => {
        setPdfPageNumber(1)
        onSelectDoc(contractData)
      }}
      className={`w-full flex flex-col gap-2 bg-white border-2 ${selected && 'border-zinc-900'} rounded-md transition-all`}
    >
      {
        isGroupedContracts &&
        <span className='w-full text-left text-base font-bold'>{`${index + 1}. ${contractTemplate?.name}`}</span>
      }
      <ContractThumbnailDocumentWrapper className='min-h-[152px] min-w-[115px] flex justify-center items-center border shadow-md'>
        <PageControlWrapper
          pdfPageNumber={pdfPageNumber}
          pdfNumPages={pdfNumPages}
        >
          <PdfDocument
            documentProps={{ file: url }}
            pageNumber={selected ? pdfPageNumber : 1}
            pageProps={{ height: 150 }}
            onLoadSuccess={(pdf) => {
              setPdfNumPages(pdf?._pdfInfo?.numPages)
              onLoadSuccess(pdf)
            }}
          />
          {isContractSigned && (
            <div className='absolute shadow-lg h-16 w-16 rounded-full bg-green-500 flex justify-center items-center p-3'>
              <Check className='w-10 h-10 text-white' strokeWidth={2.5} />
            </div>
          )}
        </PageControlWrapper>
      </ContractThumbnailDocumentWrapper>
      {selected && !isContractSigned && (
        <PdfPagination
          pdfPageNumber={pdfPageNumber}
          setPdfPageNumber={setPdfPageNumber}
          onChangePdfPage={onChangePdfPage}
          pdfNumPages={pdfNumPages}
          decreaseSelectedContractPage={decreaseSelectedContractPage}
          increaseSelectedContractPage={increaseSelectedContractPage}
        />
      )}
      <div className='w-full mt-4 flex flex-col gap-2'>
        {signatures
          ?.filter(
            (signature) =>
              showSignatureStatus || signature.userType === getUserType('Buyer')
          )
          ?.map((signature, index) => (
            <DocumentStatus key={index} signature={signature} />
          ))}
      </div>
    </ContractThumbnailWrapper>
  )
}
export default React.memo(ContractThumbnail)

ContractThumbnail.propTypes = {
  contractData: propTypes.shape({
    description: propTypes.string,
    url: propTypes.any,
    pdfNumPages: propTypes.number,
    contractTemplateId: propTypes.string,
    signatures: propTypes.array
  }),
  index: propTypes.number,
  selected: propTypes.bool,
  onSelectDoc: propTypes.func,
  onChangePdfPage: propTypes.func,
  onLoadSuccess: propTypes.func,
  isPublicPath: propTypes.bool
}
