import React from 'react'
import { Oval } from 'react-loader-spinner'

const Button = ({
  btnText = '',
  Icon = null,
  handleClick = () => { },
  type = 'primary',
  className = '',
  disabled = false,
  loading = false
}) => {
  return (
    <button
      className={`px-4 py-2 rounded-md shadow justify-center items-center gap-2 inline-flex ${type === 'primary' &&
        'bg-zinc-900 text-white'
        } ${type === 'outline' &&
        'bg-white border border-[#09090B] text-[#09090B]'
        } ${className}`}
      onClick={handleClick}
      disabled={disabled}
    >
      {
        (Icon && !loading) &&
        <Icon className='w-4 h-4' />
      }
      {
        loading &&
        <Oval height='18' width='18' color='white' />
      }
      {
        btnText?.length > 0 &&
        <p className='text-sm font-medium leading-none mb-0'>{btnText}</p>
      }
    </button>
  )
}

export default Button
