// @ts-nocheck
/* eslint-disable */
import React, { useState } from 'react'
import { Modal } from '@material-ui/core'
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg'
import UseCSV from '@usecsv/react'
import { displayName } from 'react-quill'
import CustomButton from 'components/CustomButton'

const dynamicColumnsForUnit = [
  {
    name: 'floorNumber',
    displayName: 'Floor Number',
    example: '1',
    // mustBeMatched: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'fullAddress',
    displayName: 'Address',
    example: 'address',
    // mustBeMatched: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'unitNumber',
    displayName: 'Unit Number',
    example: 'A101',
    valueCannotBeBlank: true,
    mustBeMatched: true,

    
    validationFormat: { type: 'string' }
  },
  {
    name: 'strataLotNumber',
    displayName: 'Strata Lot Number',
    example: '1234',
    // mustBeMatched: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'unitGroup',
    displayName: 'Unit Group',
    example: 'A',
    // mustBeMatched: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'price',
    displayName: 'Price',
    example: '500000',
    // mustBeMatched: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'numberOfBedrooms',
    displayName: 'Number Of Bedrooms',
    example: '3',
    // mustBeMatched: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'numberOfBathrooms',
    displayName: 'Number Of Bathrooms',
    example: '2',
    // mustBeMatched: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'building',
    displayName: 'Building',
    example: 'Building A',
    // mustBeMatched: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'floorPlan',
    displayName: 'Floor Plan',
    example: 'Plan A',
    // mustBeMatched: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'devLotNumber',
    displayName: 'Lot Number',
    example: '1234',
    // mustBeMatched: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'plat',
    displayName: 'Plat',
    example: 'Plat A',
    // mustBeMatched: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'subphase',
    displayName: 'Subphase',
    example: 'Plat A',
    // mustBeMatched: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'neighborhood',
    displayName: 'Neighborhood',
    example: 'neighborhood',
    // mustBeMatched: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'block',
    displayName: 'Block',
    example: 'block',
    // mustBeMatched: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'lotType',
    displayName: 'Lot Type',
    example: 'Lot Type 1',
    // mustBeMatched: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'lotWidth',
    displayName: 'Lot Width',
    example: '45',
    // mustBeMatched: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'platLotNumber',
    displayName: 'Plat Lot Number',
    example: '1234',
    // mustBeMatched: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'salesLotNumber',
    displayName: 'Sales Lot Number',
    example: '1234',
    // mustBeMatched: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'collection',
    displayName: 'Collection',
    example: 'Collection A',
    // mustBeMatched: true,
    validationFormat: { type: 'string' }
  },

  {
    name: 'squareFootage',
    displayName: 'Square Footage',
    example: '1234',
    // mustBeMatched: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'lotSquareFootage',
    displayName: 'Lot Square Footage',
    example: '1234',
    // mustBeMatched: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'dimensions',
    displayName: 'Dimensions',
    example: '1234',
    // mustBeMatched: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'buildableSquareFootage',
    displayName: 'Buildable Square Footage',
    example: '1234',
    // mustBeMatched: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'buildableDimensions',
    displayName: 'Buildable Dimensions',
    example: '1234',
    // mustBeMatched: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'frontYardDimension',
    displayName: 'Front Yard Dimension',
    example: '1234',
    // mustBeMatched: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'leftYardDimension',
    displayName: 'Left Yard Dimension',
    example: '1234',
    // mustBeMatched: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'rightYardDimension',
    displayName: 'Right Yard Dimension',
    example: '1234',
    // mustBeMatched: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'rearYardDimension',
    displayName: 'Rear Yard Dimension',
    example: '1234',
    // mustBeMatched: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'mooringDimension',
    displayName: 'Mooring Dimension',
    example: '1234',
    // mustBeMatched: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'mooringLineType',
    displayName: 'Mooring LineType',
    example: '1234',
    // mustBeMatched: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'mooringLine',
    displayName: 'Mooring Line',
    example: 'N/A',
    // mustBeMatched: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'waterAccess',
    displayName: 'Water Access',
    example: 'true',
    // mustBeMatched: true,
    validationFormat: { type: 'string' },
  },
  {
    name: 'dockType',
    displayName: 'Dock Type',
    example: 'Dock Type 1',
    // mustBeMatched: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'dockAllowed',
    displayName: 'Dock Allowed',
    example: 'Yes',
    // mustBeMatched: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'homeHeight',
    displayName: 'Home Height',
    example: '1234',
    // mustBeMatched: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'pool',
    displayName: 'Pool',
    example: 'true',
    // mustBeMatched: true,
    validationFormat: { type: 'string' },
  },
  {
    name: 'STR',
    displayName: 'STR',
    example: 'true',
    // mustBeMatched: true,
    validationFormat: { type: 'string' },
  }, {
    name: 'projectKey',
    displayName: 'Project Key',
    example: '1234',
    validationFormat: { type: 'string' }
  },
  {
    name: 'buildingNumber',
    displayName: 'Building Number',
    example: '1234',
    validationFormat: { type: 'string' }
  },
  {
    name: 'bldgTypeKey',
    displayName: 'Building Type Key',
    example: '1234',
    validationFormat: { type: 'string' }
  },
  {
    name: 'floorPlanKey',
    displayName: 'Floor Plan Key',
    example: '1234',
    validationFormat: { type: 'string' }
  },
  {
    name: 'stage',
    displayName: 'Stage',
    example: '1234',
    validationFormat: { type: 'string' }
  },
  {
    name: 'maxHouseWidthFt',
    displayName: 'Max House Width Ft',
    example: '1234',
    validationFormat: { type: 'string' }
  },
  {
    name: 'irregular',
    displayName: 'Irregular',
    example: 'true',
    validationFormat: { type: 'string' }
  },
  {
    name: 'maxHouseWidthFtFormatted',
    displayName: 'Max House Width Ft Formatted',
    example: '1234',
    validationFormat: { type: 'string' }
  },
  {
    name: 'maxHouseWidthM',
    displayName: 'Max House Width M',
    example: '1234',
    validationFormat: { type: 'string' }
  },
  {
    name: 'maxHouseWidthMFormatted',
    displayName: 'Max House Width M Formatted',
    example: '1234',
    validationFormat: { type: 'string' }
  },
  {
    name: 'areaSqFt',
    displayName: 'Area Sq Ft',
    example: '123',
    validationFormat: { type: 'string' }
  },
  {
    name: 'areaSqFtFormatted',
    displayName: 'Area Sq Ft Formatted',
    example: '123',
    validationFormat: { type: 'string' }
  },
  {
    name: 'areaSqM',
    displayName: 'Area Sq M',
    example: '123',
    validationFormat: { type: 'string' }
  },
  {
    name: 'areaSqMFormatted',
    displayName: 'Area Sq M Formatted',
    example: '123',
    validationFormat: { type: 'string' }
  },
  {
    name: 'styleKey',
    displayName: 'Style Key',
    example: '123',
    validationFormat: { type: 'string' }
  },
  {
    name: 'style',
    displayName: 'Style',
    example: '123',
    validationFormat: { type: 'string' }
  },
  {
    name: 'gradeKey',
    displayName: 'Grade Key',
    example: '123',
    validationFormat: { type: 'string' }
  },
  {
    name: 'grade',
    displayName: 'Grade',
    example: '123',
    validationFormat: { type: 'string' }
  },
  {
    name: 'listPrice',
    displayName: 'List Price',
    example: '123',
    validationFormat: { type: 'string' }
  },
  {
    name: 'listPriceFormatted',
    displayName: 'List Price Formatted',
    example: '123',
    validationFormat: { type: 'string' }
  },
  {
    name: 'statusKey',
    displayName: 'Status Key',
    example: '123',
    validationFormat: { type: 'string' }
  },
  {
    name: 'notes',
    displayName: 'Notes',
    example: '123',
    validationFormat: { type: 'string' }
  },
  {
    name: 'ETA',
    displayName: 'ETA',
    example: '123',
    validationFormat: { type: 'string' }
  },
  {
    name: 'ETAMonth',
    displayName: 'ETA Month',
    example: '123',
    validationFormat: { type: 'string' }
  },
]

const AddBulkUnits = ({ show, onSuccess, setShow, building }) => {
  const [isCSVModalDisplay, setIsCSVModalDisplay] = useState(false)

  const renderButton = (openModal) => {
    return (
      <>
        {
          !isCSVModalDisplay
          && <CustomButton
            handleClick={() => {
              openModal()
              setIsCSVModalDisplay(true)
            }}
            className='m-auto'
          >
            <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
              Start importing bulk units
            </span>
          </CustomButton>
        }
      </>
    )
  }

  const handleClose = () => {
    setShow(false)
    setIsCSVModalDisplay(false)
    onSuccess()
  }

  return (
    <Modal
      open={show}
      onClose={handleClose}
      aria-labelledby='Add Or Edit Bulk Units Modal'
      aria-describedby='Add Or Edit Bulk Units description'
    >
      <div
        className='relative flex flex-col font-openSans bg-grey-8 p-10 rounded shadow-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'
        style={{ width: 'calc(100% - 50px)', height: 'calc(100% - 50px)' }}
      >
        <button className='absolute right-10' onClick={handleClose}>
          <CrossIcon />
        </button>
        <h1 className='font-bold text-xl text-center'>Bulk Upload Units</h1>
        <div className='relative w-full h-full flex justify-center items-center'>
          <UseCSV
            importerKey={process.env.REACT_APP_BULK_UNIT_IMPORTER_KEY}
            dynamicColumns={dynamicColumnsForUnit}
            render={(openModal) => renderButton(openModal)}
            importerDisplay="inline"
            onClose={handleClose}
            metadata={{
              buildingId: building
            }}
          />
          {
            isCSVModalDisplay &&
            <div
              style={{
                position: "relative",
                width: "100%",
                height: "100%",
                marginTop: "20px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              id="usecsv-importer-inline-wrapper"
            />
          }
        </div>
      </div>
    </Modal>
  )
}

export default AddBulkUnits
