import React, { useContext } from 'react'
import { CreateOpportunityContext } from './context/CreateOpportunityContext'
import CreatePage from './CreatePage/CreatePage'
import SelectBuyer from './SelectBuyer/SelectBuyer'
import { STEP } from './steps'

const CreateOpportunityWrapper = () => {
  const { step } = useContext(CreateOpportunityContext)

  return step === STEP.ONE ? <SelectBuyer /> : <CreatePage />
}

export default CreateOpportunityWrapper
