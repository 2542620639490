import standardStyle from 'assets/css/standardStyle'

const smallDevice = (window.matchMedia(standardStyle.mediaqueries.xs).matches ||
  window.matchMedia(standardStyle.mediaqueries.sm).matches)

function initStackCardsResize (element) { // detect resize to reset gallery
  element.element.addEventListener('resize-stack-cards', () => {
    setStackCards(element)
    animateStackCards.bind(element)
  })
}

function getIntegerFromProperty (element) {
  const node = document.createElement('div')
  if (smallDevice) {
    node.setAttribute('style', `opacity:0; visibility: hidden;position: absolute; height:${element.marginY}`)
    element.element.appendChild(node)
    element.marginY = parseInt(getComputedStyle(node).getPropertyValue('height'))
    element.element.removeChild(node)
  } else {
    node.setAttribute('style', `opacity:0; visibility: hidden;position: absolute; width:${element.marginX}`)
    element.element.appendChild(node)
    element.marginX = parseInt(getComputedStyle(node).getPropertyValue('width'))
    element.element.removeChild(node)
  }
}

function setStackCards (element) {
  // store wrapper properties
  if (smallDevice) {
    element.marginY = getComputedStyle(element.element).getPropertyValue('--stack-cards-gap')
    getIntegerFromProperty(element) // convert element.marginY to integer (px value)
    element.elementHeight = element.element.offsetHeight
  } else {
    element.marginX = getComputedStyle(element.element).getPropertyValue('--stack-cards-gap')
    getIntegerFromProperty(element) // convert element.marginY to integer (px value)
    element.elementWidth = element.element.offsetWidth
  }

  // Verify if has cards
  if (element.items.length > 0) {
    // store card properties
    const cardStyle = getComputedStyle(element.items[0])
    if (smallDevice) {
      element.cardTop = Math.floor(parseFloat(cardStyle.getPropertyValue('top')))
      element.cardHeight = Math.floor(parseFloat(cardStyle.getPropertyValue('height')))
    } else {
      element.cardLeft = Math.floor(parseFloat(cardStyle.getPropertyValue('left')))
      element.cardWidth = Math.floor(parseFloat(cardStyle.getPropertyValue('width')))
    }

    // store window property
    if (smallDevice) {
      element.windowHeight = window.innerHeight
    } else {
      element.windowWidth = window.innerWidth
    }

    // reset margin + translate values
    if (smallDevice) {
      if (isNaN(element.marginY)) {
        element.element.style.paddingBottom = '0px'
      } else {
        element.element.style.paddingBottom = `${element.marginY * (element.items.length - 1)}px`
      }
    } else {
      if (isNaN(element.marginX)) {
        element.element.style.paddingRight = '0px'
      } else {
        element.element.style.paddingRight = `${element.marginX * (element.items.length - 1)}px`
      }
    }

    for (let i = 0; i < element.items.length; i++) {
      if (smallDevice) {
        if (isNaN(element.marginY)) {
          element.items[i].style.transform = 'none;'
        } else {
          element.items[i].style.transform = `translateY(${element.marginY * i}px)`
        }
      } else {
        if (isNaN(element.marginX)) {
          element.items[i].style.transform = 'none;'
        } else {
          element.items[i].style.transform = `translateX(${element.marginX * i}px)`
        }
      }
    }
  }
}

function animateStackCards () {
  if (smallDevice) {
    if (isNaN(this.marginY)) { // --stack-cards-gap not defined - do not trigger the effect
      this.scrolling = false
      return
    }

    const { top } = this.element.getBoundingClientRect()

    if (this.cardTop - top + this.element.windowHeight - this.elementHeight - this.cardHeight + this.marginY + this.marginY * this.items.length > 0) {
      this.scrolling = false
      return
    }

    for (let i = 0; i < this.items.length; i++) { // use only scale
      const scrolling = this.cardTop - top - i * (this.cardHeight + this.marginY)

      if (scrolling > 0) {
        const scaling = i === this.items.length - 1 ? 1 : (this.cardHeight - scrolling * 0.05) / this.cardHeight
        this.items[i].style.transform = `translateY(${this.marginY * i}px) scale(${scaling})`
      } else {
        this.items[i].style.transform = `translateY(${this.marginY * i}px)`
      }
    }

    this.scrolling = false
  } else {
    if (isNaN(this.marginX)) { // --stack-cards-gap not defined - do not trigger the effect
      this.scrolling = false
      return
    }

    const { left } = this.element.getBoundingClientRect()

    if (this.cardLeft - left + this.element.windowWidth - this.elementWidth - this.cardWidth + this.marginX + this.marginX * this.items.length > 0) {
      this.scrolling = false
      return
    }

    for (let i = 0; i < this.items.length; i++) { // use only scale
      // const scrolling = this.cardLeft - left - i * (this.cardWidth + this.marginX)

      const { left: itemLeft } = this.items[i].getBoundingClientRect()
      const newScrolling = this.marginX - left - itemLeft * i + (this.cardWidth + this.marginX)

      if (newScrolling > 0) {
        const scaling = i === this.items.length - 1 ? 1 : (this.cardWidth - newScrolling * 0.05) / this.cardWidth
        this.items[i].style.transform = `translateX(${this.marginX * i}px) scale(${scaling})`
      } else {
        this.items[i].style.transform = `translateX(${this.marginX * i}px)`
      }
    }

    this.scrolling = false
  }
}

function stackCardsScrolling () {
  if (this.scrolling) return
  this.scrolling = true
  window.requestAnimationFrame(animateStackCards.bind(this))
}

function stackCardsInitEvent (element) {
  element.scrollingFn = stackCardsScrolling.bind(element)

  if (smallDevice) {
    window.addEventListener('scroll', element.scrollingFn)
  } else {
    const stackCardsWrapper = window.document.getElementsByClassName('js-stack-cards')

    if (stackCardsWrapper.length) {
      stackCardsWrapper[0].addEventListener('scroll', element.scrollingFn)
    }
  }
}

function stackCardsCallback (entries) {
  if (entries[0].isIntersecting) {
    if (this.scrollingFn) return // listener for scroll event already added
    stackCardsInitEvent(this)
  } else {
    if (!this.scrollingFn) return // listener for scroll event already removed
    window.removeEventListener('scroll', this.scrollingFn)
    this.scrollingFn = false
  }
}

function initStackCardsEffect (element) {
  setStackCards(element) // store cards CSS properties
  const observer = new IntersectionObserver(stackCardsCallback.bind(element), { threshold: [0, 1] })
  observer.observe(element.element)
}

const StackCards = function (element) {
  this.element = element
  this.items = this.element.getElementsByClassName('js-stack-cards__item')
  this.scrollingFn = false
  this.scrolling = false
  initStackCardsEffect(this)
  initStackCardsResize(this)
}

const osHasReduceMotion = function () {
  if (!window.matchMedia) return false
  const matchMediaObj = window.matchMedia('(prefers-reduced-motion: reduce)')
  if (matchMediaObj) return matchMediaObj.matches
  return false // return false if not supported
}

export function initCardAnimator (element) {
  // initialize StackCards object
  const stackCards = document.getElementsByClassName('js-stack-cards')
  const intersectionObserverSupported = ('IntersectionObserver' in window && 'IntersectionObserverEntry' in window && 'intersectionRatio' in window.IntersectionObserverEntry.prototype)
  const reducedMotion = osHasReduceMotion()

  if (stackCards.length > 0 && intersectionObserverSupported && !reducedMotion) {
    const stackCardsArray = []
    for (let i = 0; i < stackCards.length; i++) {
      (function (i) {
        stackCardsArray.push(new StackCards(stackCards[i]))
      }(i))
    }

    let resizingId = false
    const customEvent = new CustomEvent('resize-stack-cards')

    window.addEventListener('resize', () => {
      clearTimeout(resizingId)
      resizingId = setTimeout(doneResizing, 500)
    })

    function doneResizing () {
      for (let i = 0; i < stackCardsArray.length; i++) {
        (function (i) { stackCardsArray[i].element.dispatchEvent(customEvent) }(i))
      }
    }
  }
}

/* ------------------------------------------------------------------------------------------ */

// function initStackCardsResize (element) { // detect resize to reset gallery
//   element.element.addEventListener('resize-stack-cards', () => {
//     setStackCards(element)
//     animateStackCards.bind(element)
//   })
// }

// function getIntegerFromProperty (element) {
//   const node = document.createElement('div')
//   node.setAttribute('style', `opacity:0; visbility: hidden;position: absolute; height:${element.marginY}`)
//   element.element.appendChild(node)
//   element.marginY = parseInt(getComputedStyle(node).getPropertyValue('height'))
//   element.element.removeChild(node)
// }

// function setStackCards (element) {
//   // store wrapper properties
//   element.marginY = getComputedStyle(element.element).getPropertyValue('--stack-cards-gap')
//   getIntegerFromProperty(element) // convert element.marginY to integer (px value)
//   element.elementHeight = element.element.offsetHeight

//   // Verify if has cards
//   if (element.items.length > 0) {
//     // store card properties
//     const cardStyle = getComputedStyle(element.items[0])
//     element.cardTop = Math.floor(parseFloat(cardStyle.getPropertyValue('top')))
//     element.cardHeight = Math.floor(parseFloat(cardStyle.getPropertyValue('height')))

//     // store window property
//     element.windowHeight = window.innerHeight

//     // reset margin + translate values
//     if (isNaN(element.marginY)) {
//       element.element.style.paddingBottom = '0px'
//     } else {
//       element.element.style.paddingBottom = `${element.marginY * (element.items.length - 1)}px`
//     }

//     for (let i = 0; i < element.items.length; i++) {
//       if (isNaN(element.marginY)) {
//         element.items[i].style.transform = 'none;'
//       } else {
//         element.items[i].style.transform = `translateY(${element.marginY * i}px)`
//       }
//     }
//   }
// }

// function animateStackCards () {
//   if (isNaN(this.marginY)) { // --stack-cards-gap not defined - do not trigger the effect
//     this.scrolling = false
//     return
//   }

//   const { top } = this.element.getBoundingClientRect()

//   if (this.cardTop - top + this.element.windowHeight - this.elementHeight - this.cardHeight + this.marginY + this.marginY * this.items.length > 0) {
//     this.scrolling = false
//     return
//   }

//   for (let i = 0; i < this.items.length; i++) { // use only scale
//     const scrolling = this.cardTop - top - i * (this.cardHeight + this.marginY)

//     if (scrolling > 0) {
//       const scaling = i === this.items.length - 1 ? 1 : (this.cardHeight - scrolling * 0.05) / this.cardHeight
//       this.items[i].style.transform = `translateY(${this.marginY * i}px) scale(${scaling})`
//     } else {
//       this.items[i].style.transform = `translateY(${this.marginY * i}px)`
//     }
//   }

//   this.scrolling = false
// }

// function stackCardsScrolling () {
//   if (this.scrolling) return
//   this.scrolling = true
//   window.requestAnimationFrame(animateStackCards.bind(this))
// }

// function stackCardsInitEvent (element) {
//   element.scrollingFn = stackCardsScrolling.bind(element)

//   window.addEventListener('scroll', element.scrollingFn)
// }

// function stackCardsCallback (entries) {
//   if (entries[0].isIntersecting) {
//     if (this.scrollingFn) return // listener for scroll event already added
//     stackCardsInitEvent(this)
//   } else {
//     if (!this.scrollingFn) return // listener for scroll event already removed
//     window.removeEventListener('scroll', this.scrollingFn)
//     this.scrollingFn = false
//   }
// }

// function initStackCardsEffect (element) {
//   setStackCards(element) // store cards CSS properties
//   const observer = new IntersectionObserver(stackCardsCallback.bind(element), { threshold: [0, 1] })
//   observer.observe(element.element)
// }

// const StackCards = function (element) {
//   this.element = element
//   this.items = this.element.getElementsByClassName('js-stack-cards__item')
//   this.scrollingFn = false
//   this.scrolling = false
//   initStackCardsEffect(this)
//   initStackCardsResize(this)
// }

// const osHasReduceMotion = function () {
//   if (!window.matchMedia) return false
//   const matchMediaObj = window.matchMedia('(prefers-reduced-motion: reduce)')
//   if (matchMediaObj) return matchMediaObj.matches
//   return false // return false if not supported
// }

// export function initCardAnimator (element) {
//   // initialize StackCards object
//   const stackCards = document.getElementsByClassName('js-stack-cards')
//   const intersectionObserverSupported = ('IntersectionObserver' in window && 'IntersectionObserverEntry' in window && 'intersectionRatio' in window.IntersectionObserverEntry.prototype)
//   const reducedMotion = osHasReduceMotion()

//   if (stackCards.length > 0 && intersectionObserverSupported && !reducedMotion) {
//     const stackCardsArray = []
//     for (let i = 0; i < stackCards.length; i++) {
//       (function (i) {
//         stackCardsArray.push(new StackCards(stackCards[i]))
//       }(i))
//     }

//     let resizingId = false
//     const customEvent = new CustomEvent('resize-stack-cards')

//     window.addEventListener('resize', () => {
//       clearTimeout(resizingId)
//       resizingId = setTimeout(doneResizing, 500)
//     })

//     function doneResizing () {
//       for (let i = 0; i < stackCardsArray.length; i++) {
//         (function (i) { stackCardsArray[i].element.dispatchEvent(customEvent) }(i))
//       }
//     }
//   }
// }
