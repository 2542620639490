import styled from 'styled-components'
import { Col } from 'antd'
import { motion } from 'framer-motion'

export const LoginWrapper = styled(motion.div)`
  height: 100vh;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  position: absolute;
`

export const LoginRoundedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: rgba(217, 217, 217, 0.9);
  border-radius: 10px;
  padding: 30px;
`

export const FieldsWrapper = styled(Col)``

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const BackgroundVideo = styled.video`
  position: absolute;
  background: black;
  height: 100vh;
  object-fit: cover;
`

export const VideoWrapper = styled(motion.div)``
