import React, { useState } from 'react'
import propTypes from 'prop-types'
import { motion } from 'framer-motion'
import { LottieWrapper } from 'components'
import alertAnimation from 'assets/lottieJsons/alert.json'
import { TButton } from 'ui'
import { startOAuthAuthorization } from 'store/actions/usersActions'

const itemsWrapper = {
  hidden: { opacity: 1, scale: 0 },
  visible: (custom) => ({
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.3
    }
  })
}

const slideBottomItem = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1
  }
}

const AnimatedItem = ({ children, className, delay }) => (
  <motion.div
    // key={key}
    variants={slideBottomItem}
    className={className}
    transition={delay && { delay: delay }}
  >
    { children }
  </motion.div>
)

const StartExternalProviderConfiguration = ({ pageName }) => {
  const [requestingAuthorizationURL, setRequestingAuthorizationURL] = useState(false)

  const requestNylasURL = () => {
    setRequestingAuthorizationURL(true)

    startOAuthAuthorization()
      .then((url) => {
        setRequestingAuthorizationURL(false)
        window.open(url, '_blank').focus()
      })
      .catch(() => setRequestingAuthorizationURL(false))
  }

  return (
    <motion.ul
      variants={itemsWrapper}
      initial='hidden'
      animate='visible'
      className='flex flex-col h-full w-full items-center justify-center'
    >
      <AnimatedItem>
        <LottieWrapper
          animation={alertAnimation}
          className='w-88 -mt-20'
        />
      </AnimatedItem>
      <AnimatedItem><span className='text-2xl'>As it's your first time accessing your {pageName}, we need your permission</span></AnimatedItem>
      <AnimatedItem className='mt-1'>
        <span className='text-lg'>
          Please, press the button below to start your authorization process through our partner
          <a className='ml-1' target='_blank' href='https://www.nylas.com/' rel="noreferrer">Nylas</a>
        </span>
      </AnimatedItem>
      <AnimatedItem className='mt-20' delay={2}>
        <div className='flex flex-col items-center justify-center'>
          <TButton
            loading={requestingAuthorizationURL}
            onClick={requestNylasURL}
            >
            Start your authorization process
          </TButton>
          <div className='flex flex-row items-center justify-center mt-20 space-x-2'>
            <span className='text-2xl'>PxP Platform</span>
            <span className='text-3xl'>+</span>
            <img src='https://www.nylas.com/wp-content/themes/nylas/assets/images/logos/nylas_logo_black.svg' className='h-12' alt='' />
          </div>
        </div>
      </AnimatedItem>
    </motion.ul>
  )
}

export default StartExternalProviderConfiguration

AnimatedItem.propTypes = {
  children: propTypes.node,
  className: propTypes.string,
  delay: propTypes.number

}

StartExternalProviderConfiguration.propTypes = {
  pageName: propTypes.string
}
