const standardStyle = {
  colors: {
    primary: '#4f46e5',
    secundary: '#a97d52',
    danger: '#fa3434',
    alert: '#EEAD2D',
    gray: '#bebebe',
    secondaryGray: '#CECECC',
    softBlack: '#2E2B2E',
    softBlack_hover: '#4D4B4D',
    softBlack_pressed: '#ODODOD',
    softBlack_10: '#E3E3E1',
    softBlack_30: 'rgba(46, 43, 46, 0.3)',
    softBlack_70: 'rgba(46, 43, 46, 0.7)',
    mainBackground: '#F2F2F1', // grey //
    grey_hover: '#F9F9F9',
    grey_pressed: '#F1F1F1',
    volt: '#EDDF65',
    volt_hover: '#FCEE76',
    volt_pressed: '#EBDA45',
    available: '#B8E04A', // green //
    reserved: '#EDDF65', // yellow //
    sold: '#BF5716', // red //
    lightGray: '#e6e6e6',
    green: '#519735',
    hoverGreen: '#65bd42',
    default: '#fff',
    skeletonGray: '#eeeeee',
    primaryHover: '#24355c',
    secundaryHover: '#b48c64',
    cancelButton: '#808080',
    cancelButtonHover: '#999999',
    gray_700: 'rgba(46, 43, 46, 0.7)',
    gray_300: 'rgba(46, 43, 46, 0.3)',
    yellow: '#FCEE76',
    white: 'white',
    white30: '#FFFFFF80',
    darkGray30: '#2E2B2E4D',
    input: {
      background: '#f4f4f4',
      border: '#c3c3c3'
    },
    darkText: '#343a40'
  },
  inputs: {
    borderRadius: {
      medium: '.25rem'
    }
  },
  fonts: {
    title: 'Fira Sans',
    text: 'Fira Sans',
    sansDefault:
      "Fira Sans, -apple-system, BlinkMacSystemFont, Helvetica, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif",
    reenieBeanie: 'Reenie Beanie',
    sfpro: "SF Pro Display, 'Noto Sans', sans-serif",
    openSans: 'Open Sans'
  },
  mediaqueries: {
    // mediaqueries copiadas do ant design
    xs: 'screen and (max-width: 575px)',
    sm: 'screen and (min-width: 576px) and (max-width: 767px)',
    md: 'screen and (min-width: 768px) and (max-width: 991px)',
    lg: 'screen and (min-width: 992px) and (max-width: 1199px)',
    xl: 'screen and (min-width: 1200px)'
  },
  headings: {
    // todo: abstrair props para media queries
    h1: '68px',
    h2: '42px',
    h3: '32px',
    h4: '26px',
    h5: '20px',
    h6: '18px',
    h7: '14px'
  }
}

export default standardStyle
