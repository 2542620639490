/* eslint-disable no-restricted-globals */
import StatsCard from 'components/StatsCard'
import { StatsHorizontal } from 'components/StatsHorizontal'
import { StatsLarge } from 'components/StatsLarge'
import React, { useMemo } from 'react'
import { Chart } from './components/Chart'
import TopUnitsTable from './components/TopUnitsTable'
import { ReactComponent as PriceTagIcon } from 'assets/icons/price-tag.svg'
import { ReactComponent as UnitIcon } from 'assets/icons/unit.svg'
import { ReactComponent as SalesIcon } from 'assets/icons/sales.svg'
import RecentSalesTable from './components/RecentSalesTable'
import TopBrokersTable from './components/TopBrokersTable'
import useGetProjectUnits from 'hooks/useGetProjectUnits'
import useGetProjectStatistics from 'hooks/useGetProjectStatistics'
import { useSelector } from 'react-redux'
import SalesRepDashboard from './SalesRepDashboard'
import TopAllocationsTable from './components/TopAllocationsTable'
import SalesChart from './SalesChart'
import { Oval } from 'react-loader-spinner'

const leadStats = [
  { title: 'Brokers', units: 65, color: '#EDDF65' },
  { title: 'Buyers', units: 35, color: 'rgba(46, 43, 46, 0.3)' }
]
const trafficStats = [
  { title: 'Online', units: 80, color: '#EDDF65' },
  { title: 'Walk-in', units: 20, color: 'rgba(46, 43, 46, 0.3)' }
]

const brokerVolumeStats = [
  { title: 'HIGH SCORE', units: 80, color: '#EDDF65' },
  { title: 'NEUTRAL SCORE', units: 21, color: 'rgba(46, 43, 46, 0.3)' },
  { title: 'COLD SCORE', units: 11, color: 'rgba(46, 43, 46, 0.7)' }
]

const reservationsStats = [
  { title: 'INTERESTED', units: 35, color: '#EDDF65' },
  { title: 'RESERVED', units: 65, color: 'rgba(46, 43, 46, 0.3)' }
]

const RCStats = [
  { title: 'CLOSED SALES', units: 80, color: '#EDDF65' },
  { title: 'RECISIONED', units: 20, color: 'rgba(46, 43, 46, 0.3)' }
]

const DashboardNew = () => {
  // const mountedRef = useRef(true)
  const { soldUnits, availableUnits, unitsLoading } = useGetProjectUnits()
  const { states, loading } = useGetProjectStatistics()
  // @ts-ignore
  const userType = useSelector((state) => state.authReducer.userObject.userType)

  const isLoading = useMemo(() => {
    return loading || unitsLoading
  }, [loading, unitsLoading])

  const unitStats = useMemo(() => {
    return [
      {
        title: 'available units',
        units: availableUnits.length ?? 0,
        color: '#EDDF65'
      },
      {
        title: 'reserved units',
        units: states?.counters?.counterUnitReserved ?? 0,
        color: 'rgba(46, 43, 46, 0.7)'
      },
      { title: 'offered units', units: 11, color: 'rgba(46, 43, 46, 0.3)' },
      { title: 'sold units', units: soldUnits?.length ?? 0, color: '#2E2B2E' }
    ]
  }, [availableUnits, states, soldUnits])

  const offersStats = useMemo(() => {
    return [
      {
        title: 'CREATED OFFERS',
        units: states?.counters?.counterOfferCreated ?? 0,
        color: '#EDDF65'
      },
      {
        title: 'REJECTED OFFERS',
        units: states?.counters?.counterOfferRejected ?? 0,
        color: 'rgba(46, 43, 46, 0.7)'
      },
      { title: 'PAID OFFERS', units: 11, color: 'rgba(46, 43, 46, 0.3)' },
      { title: 'NOT PAID OFFERS', units: 28, color: '#2E2B2E' }
    ]
  }, [states])

  const progressStats = useMemo(() => {
    return [
      {
        title: 'sold',
        value: soldUnits?.length ?? 0,
        progressColor: '#2E2B2E',
        progressBackgroundColor: 'inherit'
      },
      {
        title: 'remaining',
        value: availableUnits.length ?? 0,
        progressColor: '#EDDF65',
        progressBackgroundColor: 'inherit'
      }
    ]
  }, [soldUnits, availableUnits])

  const stats = useMemo(() => {
    return {
      'REMAINING UNITS': availableUnits.length ?? 0,
      'TOTAL AMOUNT': 'CA$13,150,000',
      'AVE PRICE PER SQ. FT': 'CA$950'
    }
  }, [availableUnits])

  if (!userType?.length) return null
  if (userType === 'SalesRep') return <SalesRepDashboard />
  return (
    <>
      <div className='bg-menuGray min-h-screen font-openSans overscroll-none'>
        <h1 className='font-bold text-4xl px-8 py-6 border-b-2 border-softBlack pl-10'>
          Dashboard
        </h1>
        <section className='p-8 w-full'>
          <div className='flex items-center flex-col lg:flex-row w-full gap-6.25 mb-6'>
            <div className='flex-grow'>
              <div className='grid grid-cols-3 gap-5 mb-6'>
                <StatsCard
                  title='total sales'
                  // price={states?.counters?.sumTotal ?? 0}
                  price={12450000}
                  Icon={SalesIcon}
                />
                <StatsCard
                  title='units sold'
                  count={soldUnits.length}
                  Icon={UnitIcon}
                />
                <StatsCard
                  title='AVE PRICE PER SQ. FT'
                  price={850}
                  Icon={PriceTagIcon}
                />
              </div>
              <div>
                <StatsHorizontal
                  stats={unitStats}
                  title={'Units per Status'}
                  classes='justify-between'
                  tabClasses='w-1/4'
                />
              </div>
            </div>
            <div className='w-full lg:w-81.25'>
              <StatsLarge progressStats={progressStats} stats={stats} />
            </div>
          </div>
          <Chart />
          <div className='grid grid-cols-1 lg:grid-cols-2 mt-6 gap-6'>
            <StatsHorizontal
              stats={leadStats}
              title={'Lead Type'}
              classes='justify-start gap-10'
              tabClasses='w-1/2'
              showPercentage
              showCountries
              showTime
            />
            <StatsHorizontal
              stats={trafficStats}
              title={'Traffic per'}
              classes='justify-start gap-10'
              tabClasses='w-1/2'
              showPercentage
              showCountries
              showTime
              timeInTitle
            />
          </div>
          <div className='mt-6'>
            <StatsHorizontal
              stats={brokerVolumeStats}
              title={'Volume of Brokers'}
              classes='justify-between'
              tabClasses=''
            />
            <div className='-mt-2'>
              <TopBrokersTable />
            </div>
          </div>
          <div className='mt-6'>
            <StatsHorizontal
              stats={offersStats}
              title={'Offers'}
              classes='justify-between'
              tabClasses='w-1/4'
            />
          </div>
          <div className='mt-6'>
            <TopUnitsTable />
          </div>
          <div className='mt-6'>
            <RecentSalesTable />
          </div>
          <div className='grid grid-cols-1 lg:grid-cols-2 mt-6 gap-6'>
            <StatsHorizontal
              stats={reservationsStats}
              title={'Reservations'}
              classes='justify-start gap-10'
              tabClasses='w-1/2'
              showPercentage={true}
              showBrokers={true}
              showTime={true}
            />
            <StatsHorizontal
              stats={RCStats}
              title={'Rescission & Closing'}
              classes='justify-start gap-10'
              tabClasses='w-1/2'
              showPercentage={true}
              showCountries={true}
              showTime={true}
            />
          </div>
          {isLoading && (
            <div className='flex h-18 w-full self-center justify-center content-center p-8'>
              <Oval height='50' width='50' color='black' />
            </div>
          )}
          {!isLoading && (
            <>
              <div className='mt-6'>
                <TopAllocationsTable />
              </div>
              <div className='bg-grey-8 p-6 mt-6'>
                <h1 className='font-bold text-xl pb-4 mb-6 border-b-2 border-softBlack'>
                  Total Sales & Leads
                </h1>
                <div className='flex justify-center items-center'>
                  <SalesChart />
                </div>
              </div>
            </>
          )}
        </section>
      </div>
    </>
  )
}

export default DashboardNew
