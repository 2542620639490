import { useState } from 'react'
import { getReservationDetail } from 'store/actions/reservationActions'

const useGetReservationDetail = () => {
  const [reservation, setReservation] = useState([])
  const [loading, setLoading] = useState(false)

  const getData = async (id) => {
    setLoading(true)
    try {
      const data = await getReservationDetail(id)
      setReservation(data)
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  return { loading, reservation, refetch: getData }
}

export default useGetReservationDetail
