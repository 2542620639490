import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import propTypes from 'prop-types'
import { TButton } from 'ui'
import { Wrapper } from './styles'
import { DocumentAddIcon, PhotographIcon } from '@heroicons/react/outline'
import { AnimatePresence } from 'framer-motion'
import { FadedAnimatedDiv } from 'components'
import { classNames, isArray, isFile } from 'utils'
import FileListModal from './fileListModal'

const fileTypes = {
  File: { icon: DocumentAddIcon, title: 'Select your file' },
  Image: { icon: PhotographIcon, title: 'Select your image' }
}

const Icon = memo(({ fileType, className, style, fileList }) => {
  const LocalIcon = fileTypes[fileType].icon

  const [iconUrl, setIconUrl] = useState('')

  useEffect(() => {
    if (fileList.length === 1 && fileType === 'Image') {
      setIconUrl(fileList[0].url)
    } else {
      setIconUrl('')
    }
  }, [fileList, fileType])

  if (iconUrl) {
    return <img src={iconUrl} alt='file preview' className={classNames('h-14 w-28 rounded-md object-fill', className)} />
  }

  return <LocalIcon style={style} className={classNames('h-16 w-16 text-indigo-600', className)} />
})

const Title = memo(({ title }) => <span className='text-lg text-center'>{title}</span>)

const FilesIcons = memo(({ fileList, fileType }) => (
  <div className='relative'>
    <Icon fileType={fileType} className='my-3' fileList={fileList} />
    <AnimatePresence exitBeforeEnter>
      {
        Boolean(fileList.length) && fileList.length > 1 &&
        <FadedAnimatedDiv
          key='fileListCounter'
          className='absolute rounded-full bg-yellow-500 top-3 h-7 w-7 flex items-center justify-center -right-2 text-lg'
        >
          <span>{fileList.length}</span>
        </FadedAnimatedDiv>
      }
    </AnimatePresence>
  </div>
))

const FileSelector = ({ fileType, title, multiple, loading, onSelectFile, listOfFiles, onRemoveFile, accept, disabled }) => {
  const [localListOfFiles, setLocalListOfFiles] = useState([])
  const [showListOfFilesModal, setShowListOfFilesModal] = useState(false)

  const fileSelector = useRef(null)

  const getBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.onerror = error => reject('FR error', error)
  })

  const mountListOfFiles = useCallback((localListOfFiles) => new Promise((resolve) => {
    const imagesToSet = localListOfFiles
      .map(async (file) => {
        const tmpFile = file

        const url = tmpFile.url

        if (isFile(tmpFile) && !url) {
          const objUrl = await getBase64(file)
          tmpFile.url = objUrl
        }

        return tmpFile
      })

    Promise.all(imagesToSet).then((completed) => {
      resolve(completed)
    })
  }), [])

  useEffect(() => {
    if (listOfFiles !== undefined) {
      let tmpListOfFiles = listOfFiles

      // Confirm if received an array
      if (!isArray(tmpListOfFiles)) {
        tmpListOfFiles = [{ listOfFiles }]
      }

      mountListOfFiles(tmpListOfFiles)
        .then((newListOfFiles) => setLocalListOfFiles(newListOfFiles))
    }
  }, [listOfFiles, mountListOfFiles])

  const openFileSelector = useCallback(() => {
    fileSelector.current.click()
  }, [])

  const openListOfFilesModal = useCallback(() => {
    setShowListOfFilesModal(true)
  }, [])

  const _onSelectFile = useCallback((e) => {
    if (e.target.files) {
      let tmpListOfFiles = [...localListOfFiles]
      tmpListOfFiles = tmpListOfFiles.concat(Array.from(e.target.files))

      mountListOfFiles(tmpListOfFiles)
        .then((newListOfFiles) => {
          setLocalListOfFiles(newListOfFiles)
          if (onSelectFile) onSelectFile(newListOfFiles)
        })
    }
  }, [mountListOfFiles, localListOfFiles, onSelectFile])

  const onOkFileListModal = useCallback(() => {
    setShowListOfFilesModal(false)
  }, [])

  const confirmDelete = useCallback((file) => {
    let tmpListOfFiles = [...localListOfFiles]
    tmpListOfFiles = tmpListOfFiles.filter(element => element.name !== file.name)

    mountListOfFiles(tmpListOfFiles)
      .then((newListOfFiles) => {
        setLocalListOfFiles(newListOfFiles)
        onRemoveFile({ fileList: newListOfFiles, removedFile: file })
      })

    fileSelector.current.value = ''

    if (!tmpListOfFiles.length) {
      setShowListOfFilesModal(false)
    }
  }, [mountListOfFiles, localListOfFiles, onRemoveFile])

  return (
    <Wrapper className='flex items-center justify-center rounded-md border border-gray-200 py-8 px-12 flex-col'>
      <FileListModal
        showModal={showListOfFilesModal}
        onOk={onOkFileListModal}
        onDeleteItem={confirmDelete}
        fileList={localListOfFiles}
      />

      <Title title={title || fileTypes[fileType].title} />

      <FilesIcons fileList={localListOfFiles} fileType={fileType} />

      <TButton
        disabled={(!multiple && localListOfFiles.length) || disabled}
        loading={loading}
        onClick={openFileSelector}
      >
        {localListOfFiles.length ? 'Add more' : 'Select'}
      </TButton>

      <AnimatePresence>
        { localListOfFiles.length &&
          <FadedAnimatedDiv
            className='mt-2'
            key='manageButton'
          >
            <TButton disabled={disabled} loading={loading} onClick={openListOfFilesModal}>
              Manage
            </TButton>
          </FadedAnimatedDiv>
        }
      </AnimatePresence>

      <input
        id='file-input'
        type='file'
        name='fileSelector'
        className='hidden'
        ref={fileSelector}
        onChange={_onSelectFile}
        multiple={multiple}
        accept={accept}
      />
    </Wrapper>

  )
}

export default FileSelector

FileSelector.defaultProps = {
  fileType: 'Image',
  multiple: false,
  accept: '',
  disabled: false
}

FileSelector.propTypes = {
  fileType: propTypes.string,
  title: propTypes.string,
  multiple: propTypes.bool,
  accept: propTypes.string
}
