import React from 'react'
import propTypes from 'prop-types'
import { TButton, TSecondaryButton } from 'ui'

export const CreateOfferStepFullScreenWrapper = ({ className, children }) => (
  <div className={`flex flex-col h-full w-full ${className}`}>
    {children}
  </div>
)

export const CrateOfferStepTitle = ({ children, className = '' }) =>
  <span className={`text-4xl ${className}`}>{children}</span>

const buttonSizes = {
  small: 'text-base md:text-lg px-5',
  large: 'h-9 md:h-16 text-base md:text-2xl px-10'
}

export const CreateOfferStepPrimaryButton = ({ className = '', buttonSize, ...props }) =>
  <TButton {...props} className={`${buttonSizes[buttonSize]} ${className}`} />

export const CreateOfferStepSecondaryButton = ({ className = '', buttonSize, ...props }) =>
  <TSecondaryButton {...props} className={`${buttonSizes[buttonSize]} ${className}`} />

CreateOfferStepFullScreenWrapper.propTypes = {
  className: propTypes.string,
  children: propTypes.node
}

CrateOfferStepTitle.propTypes = {
  children: propTypes.string,
  className: propTypes.string
}

CreateOfferStepPrimaryButton.defaultProps = {
  buttonSize: 'large'
}

CreateOfferStepPrimaryButton.propTypes = {
  className: propTypes.string,
  buttonSize: propTypes.oneOf(['small', 'large'])
}

CreateOfferStepSecondaryButton.defaultProps = {
  buttonSize: 'large'
}

CreateOfferStepSecondaryButton.propTypes = {
  className: propTypes.string,
  buttonSize: propTypes.oneOf(['small', 'large'])
}
