/* eslint-disable no-unused-vars */
import React, { createContext, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { getAllDropDowns } from 'store/actions/usersActions'
import { STEP } from '../steps'

export const CreateReservationContext = createContext(null)

export const CreateReservationProvider = ({ children }) => {
  const [step, setStep] = useState(STEP.ONE)
  const [selectedBuyers, setSelectedBuyers] = useState([])
  const [secondaryBuyers, setSecondaryBuyers] = useState([])
  const [dropDowns, setDropDowns] = useState([])
  // // @ts-ignore
  // const projectId = useSelector((state) => state.appReducer.appProject)
  // const fetchDropDowns = useRef(async () => {})

  // fetchDropDowns.current = async () => {
  //   const data = await getAllDropDowns(projectId)
  //   setDropDowns(data)
  // }

  // useEffect(() => {
  //   fetchDropDowns.current()
  // }, [])

  return (
    <CreateReservationContext.Provider
      value={{ step, setStep, selectedBuyers, setSelectedBuyers, dropDowns, secondaryBuyers, setSecondaryBuyers }}
    >
      {children}
    </CreateReservationContext.Provider>
  )
}
