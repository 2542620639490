import React from 'react'
import propTypes from 'prop-types'
import styled from 'styled-components'
import { Button as ButtonAntd } from 'antd'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'

import standardStyle from 'assets/css/standardStyle'
import { LoadingIcon } from 'components'
import { classNames } from 'utils'

const heightByProps = {
  small: '26px',
  default: '32px',
  large: '46px',
  pwa: '10vh'
}

const fontSizeByProps = {
  small: '14px',
  default: '14px',
  large: '14px',
  pwa: '25px'
}

const style = (element) => styled(element)`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-family: ${standardStyle.fonts.text}, ${standardStyle.fonts.sansDefault};
  background-color: ${props => props.parentIsLoading ? standardStyle.colors.skeletonGray : (props.background || standardStyle.colors.primary)};
  color: ${props => props.parentIsLoading ? standardStyle.colors.skeletonGray : (props.color || 'white')};
  border-color: ${props => props.parentIsLoading ? standardStyle.colors.skeletonGray : (props.background || standardStyle.colors.primary)};
  border-radius: 4px;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  height: ${props => heightByProps[props.size]};
  line-height: 1.5715;
  padding: ${props => props.size ? '0px 15px' : '4px 15px'};
  font-size: ${props => fontSizeByProps[props.size]};
  box-shadow: 0 ${props => props.hideShadow ? '0px 0px 0px' : '2px 4px 4px'} rgba(0,0,0,0.05),
              0 ${props => props.hideShadow ? '0px 0px 0px' : '5px 8px 8px'} rgba(0,0,0,0.05),
              0 ${props => props.hideShadow ? '0px 0px 0px' : '8px 12px 12px'} rgba(0,0,0,0.05),
              0 ${props => props.hideShadow ? '0px 0px 0px' : '10px 16px 16px'} rgba(0,0,0,0.05);

  &:hover {
    background-color: ${props => props.hoverColor || standardStyle.colors.primaryHover};
    color: ${props => props.color || 'white'};
    border: 1px solid ${props => props.hoverColor || standardStyle.colors.primaryHover} !important;
  }

  &.ant-btn-background-ghost {
    color: ${standardStyle.colors.primary};
  }

  &.ant-btn-block {
    text-align: center;

    > * {
      margin:0 auto;
    }
  }
`

export const TButton = ({
  children, parentIsLoading, onClick, backgroundColor,
  backgroundHoverColor, border, shadow, focus, className,
  type, disabled, loading, id, style
}) => (
  <button
    id={id}
    disabled={disabled ? 'disabled' : '' || loading}
    type={type}
    onClick={onClick}
    style={style}
    className={classNames('relative inline-flex justify-center text-sm font-semibold text items-center',
      parentIsLoading ? 'bg-skeletonGray' : backgroundColor,
      parentIsLoading ? 'text-skeletonGray' : 'text-white',
      'rounded-md whitespace-nowrap text-center py-2 px-4',
      shadow,
      focus,
      `hover:${parentIsLoading ? 'bg-skeletonGray' : backgroundHoverColor}`,
      '',
      border,
      className
    )}
  >
    {loading && <LoadingIcon className='mr-2' />}
    {children}
  </button>
)

export const TSecondaryButton = ({
  children, parentIsLoading, onClick, backgroundColor,
  backgroundHoverColor, border, shadow, focus, className,
  type, disabled, loading, id, style
}) => (
  <button
    id={id}
    disabled={disabled || loading}
    type={type}
    onClick={onClick}
    style={style}
    className={classNames(
      'relative text-white inline-flex justify-center text-sm font-medium text items-center',
      parentIsLoading ? 'bg-softBlack' : backgroundColor,
      parentIsLoading ? 'text-white/[70%]' : 'text-white',
      'rounded-md whitespace-nowrap text-center py-2 px-4',
      shadow,
      focus,
      `hover:${parentIsLoading ? 'bg-skeletonGray' : backgroundHoverColor}`,
      '',
      border,
      className
    )}
  >
    {loading && <LoadingIcon className='mr-2' />}
    {children}
  </button>
)

export const Button = style(ButtonAntd)
export const ButtonLink = style(Link)
export const MotionButton = style(motion.button)

TButton.defaultProps = {
  backgroundColor: 'bg-softBlack',
  backgroundHoverColor: 'bg-softBlack',
  border: 'border-transparent',
  shadow: 'shadow-sm',
  focus: 'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
  className: '',
  type: 'button',
  disabled: false,
  loading: false,
  style: {}
}

TButton.propTypes = {
  children: propTypes.node,
  parentIsLoading: propTypes.bool,
  onClick: propTypes.func,
  backgroundColor: propTypes.string,
  backgroundHoverColor: propTypes.string,
  border: propTypes.string,
  shadow: propTypes.string,
  focus: propTypes.string,
  className: propTypes.string,
  type: propTypes.string,
  disabled: propTypes.bool,
  loading: propTypes.bool,
  id: propTypes.string,
  style: propTypes.object
}

TSecondaryButton.defaultProps = {
  backgroundColor: 'bg-indigo-100',
  backgroundHoverColor: 'bg-indigo-200',
  border: 'border-transparent',
  shadow: 'shadow-sm',
  focus: 'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
  className: '',
  type: 'button',
  disabled: false,
  loading: false,
  style: {}
}

TSecondaryButton.propTypes = {
  children: propTypes.node,
  parentIsLoading: propTypes.bool,
  onClick: propTypes.func,
  backgroundColor: propTypes.string,
  backgroundHoverColor: propTypes.string,
  border: propTypes.string,
  shadow: propTypes.string,
  focus: propTypes.string,
  className: propTypes.string,
  type: propTypes.string,
  disabled: propTypes.bool,
  loading: propTypes.bool,
  id: propTypes.string,
  style: propTypes.object
}
