import React from 'react'
import propTypes from 'prop-types'

import {
  FacebookIcon,
  InstagramIcon,
  TwitterIcon
} from 'components'

import { HeaderLogo, SurveyFooter, SurveyStepWrapper } from './surveyComponents'

const SocialMediaIcons = () => (
  <div className='flex flex-row items-center justify-center fixed bottom-10 right-20 text-xl space-x-3'>
    <TwitterIcon className='hover:cursor-pointer' />
    <FacebookIcon className='hover:cursor-pointer' />
    <InstagramIcon className='hover:cursor-pointer' />
  </div>
)

const Welcome = ({ onClickContinue }) => (
    <div className='relative w-full'>
      <SurveyStepWrapper>
        <HeaderLogo className='rounded-full w-44 h-44'/>
        <span className='text-5xl font-bold py-12'>Got 2 minutes Stefan?</span>
        <span className='text-3xl text-gray-700 py-3'>Add a few insights to your account</span>
        <span className='text-3xl text-gray-700 py-3'>to increase change of receiving the unit you want.</span>

        <SurveyFooter onClickContinue={onClickContinue} />
        <span className='text-xl underline hover:cursor-pointer'>I will do this later</span>
      </SurveyStepWrapper>
      <SocialMediaIcons />
    </div>
)

export default Welcome

Welcome.propTypes = {
  onClickContinue: propTypes.func
}
