import React from 'react'
import { ReactComponent as UnitSVG } from 'assets/icons/floorUnit.svg'

const Floor = ({ units, selected, floorNumber, showHeader, onClicked }) => {
  return (
    <div
      className='flex flex-row items-center justify-between'
      onClick={onClicked}
    >
      <div>
        {showHeader ? <div className='bg-softBlack_70 h-3' /> : null}
        <div
          className={`flex flex-row space-x-6 p-3 mx-2 ${
            selected ? 'bg-volt' : 'bg-white'
          }`}
        >
          {units.map((index) => {
            return <UnitSVG key={index} />
          })}
        </div>
      </div>
      <span className='font-bold text-[22px] text-softBlack_70 ml-6'>
        {floorNumber}
      </span>
    </div>
  )
}

export default Floor
