// @ts-nocheck
import React from 'react'
import ReservationDocuments from './ReservationDocuments'

const Contract = ({ contractData, onClose }) => {
  return (
    <ReservationDocuments
      userObject={contractData.buyer}
      setCloseDocument={onClose}
      reservationId={contractData._id}
      isPublicPath
    />
  )
}

export default Contract
