/* eslint-disable no-unused-vars */
// @ts-nocheck
import { Select } from 'antd'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ReactComponent as DownIcon } from 'assets/icons/down.svg'
import { toast } from 'react-hot-toast'
import { useSelector } from 'react-redux'
import { classNames } from 'utils'
import PaymentReceipt from './PaymentReceipt'
import { getDocuSealDocumentPreview } from 'store/actions/reservationActions'

const DocumentUploadButton = ({ title, onClick, onDelete, value }) => {
  if (value?.url?.length > 0) {
    return (
      <div className='flex-1 flex-col'>
        <p className='text-lg text-center font-bold mt-0 mb-4'>{title}</p>
        <div className='mb-6 col-span-2'>
          <div className='border relative'>
            <div className='h-auto min-h-48 flex flex-col items-center p-1 bg-white font-openSans rounded border border-dashed border-softBlack_30 overflow-hidden'>
              <div className='flex flex-col gap-2 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-center'>
                <a
                  href={value.url}
                  target='_blank'
                  style={{
                    textDecoration: 'none'
                  }}
                  className='px-6 py-5 text-sm font-openSans italic bg-volt rounded-lg font-bold w-48 uppercase text-black'
                >
                  {value.fileName.includes('pdf') ? 'OPEN PDF' : 'OPEN IMAGE'}
                </a>
                <button
                  onClick={() => onDelete?.()}
                  className='px-6 py-5 text-sm font-openSans italic bg-volt rounded-lg font-bold w-48 uppercase'
                >
                  Delete
                </button>
              </div>
              {!value.fileName.includes('pdf') && (
                <img src={value.url} className='object-fill' />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className='flex-1 flex-col'>
      <p className='text-lg text-center font-bold mt-0 mb-4'>{title}</p>
      <div className='mb-6 col-span-2'>
        <div className='border'>
          <div className='flex flex-col items-center p-6 bg-white font-openSans rounded border border-dashed border-softBlack_30'>
            <svg
              width='26'
              height='26'
              viewBox='0 0 26 26'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M21.8333 7.33329V11.32C21.8333 11.32 19.18 11.3333 19.1667 11.32V7.33329H15.1667C15.1667 7.33329 15.18 4.67996 15.1667 4.66663H19.1667V0.666626H21.8333V4.66663H25.8333V7.33329H21.8333ZM17.8333 12.6666V8.66663H13.8333V4.66663H3.16667C1.7 4.66663 0.5 5.86663 0.5 7.33329V23.3333C0.5 24.8 1.7 26 3.16667 26H19.1667C20.6333 26 21.8333 24.8 21.8333 23.3333V12.6666H17.8333ZM3.16667 23.3333L7.16667 18L9.83333 22L13.8333 16.6666L19.1667 23.3333H3.16667Z'
                fill='#2E2B2E'
              />
            </svg>
            <h1 className='font-light text-sm mt-4 font-openSans italic'>
              {'Upload Files Here'}
            </h1>
            <div className='font-semibold font-openSans'>
              <button
                onClick={() => onClick?.()}
                className='px-8 py-2 border border-softBlack_70 font-light italic mt-1 font-openSans bg-volt'
                style={{
                  borderRadius: '4px',
                  border: '1px solid rgba(46, 43, 46, 0.7)'
                }}
              >
                Browse
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const PreViewButton = ({ reservationId }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState(null)
  useEffect(() => {
    ;(async () => {
      setIsLoading(true)
      try {
        const data = await getDocuSealDocumentPreview(reservationId)
        setData(data)
        setIsLoading(false)
      } catch (err) {
        setIsLoading(false)
      }
    })()
  }, [reservationId])

  const previewURL = data?.preview?.url ?? null
  if (isLoading && !previewURL?.length) {
    return null
  }

  return (
    <div className='flex justify-end w-1/3'>
      <div className='flex justify-center items-center font-bold py-3  w-full ml-8 border border-black rounded cursor-pointer'>
        <a href={previewURL} target='_blank'>
          Preview
        </a>
      </div>
    </div>
  )
}
const SendBuyer = ({
  onClick,
  setStep,
  reservation,
  createOrUpdateReservation,
  refetchReservationDetails,
  deleteReservation,
  cancelReservation
}) => {
  const projectId = useSelector((state) => state.appReducer.appProject)
  const [status, setStatus] = useState(reservation?.reservationStatus ?? '')
  const [isUpdateInProgress, setIsUpdateInProgress] = useState(false)
  const [isDeleteInProgress, setIsDeleteInProgress] = useState(false)
  const [showDialogDocumentUpload, setShowDialogDocumentUpload] =
    useState(false)
  const [
    showDialogReservationAgreementUpload,
    setShowDialogReservationAgreementUpload
  ] = useState(false)
  const [showDialogPaymentReceiptUpload, setShowDialogPaymentReceiptUpload] =
    useState(false)

  const statusArr = ['Pending', 'Complete', 'Cancelled']
  useEffect(() => {
    setStatus(reservation?.reservationStatus)
  }, [reservation])

  const updateDetails = useCallback(
    async (_status) => {
      if (reservation?.reservationStatus?.indexOf(_status) === -1) {
        const toastId = toast.loading('Updating Details...')
        setIsUpdateInProgress(true)
        try {
          const dataObject = {
            _id: reservation?._id,
            reservationStatus: _status
          }
          await createOrUpdateReservation(projectId, dataObject)
          refetchReservationDetails?.()
          toast.dismiss(toastId)
          toast.success('Details updated successfully')
        } catch {
          toast.dismiss(toastId)
        }
        setIsUpdateInProgress(false)
      }
    },
    [
      projectId,
      reservation,
      createOrUpdateReservation,
      refetchReservationDetails
    ]
  )

  const _cancelReservation = useCallback(async () => {
    const toastId = toast.loading('Cancelling Reservation...')
    setIsUpdateInProgress(true)
    try {
      await cancelReservation(reservation?._id)
      refetchReservationDetails?.()
      toast.dismiss(toastId)
      toast.success('Cancelled reservation successfully')
    } catch {
      toast.dismiss(toastId)
    }
    setIsUpdateInProgress(false)
  }, [reservation])

  const uploadedDoc = useMemo(() => {
    const result = reservation?.documents?.filter(
      (doc) =>
        doc?.documentName?.includes('ID') || doc?.type?.includes('id_document')
    )?.[0]
    return result
  }, [reservation])

  const uploadedReceipt = useMemo(() => {
    const result = reservation?.documents?.filter(
      (doc) =>
        doc?.documentName?.includes('Receipt') ||
        doc?.type?.includes('payment_receipt')
    )?.[0]
    return result
  }, [reservation])

  const uploadedAgreement = useMemo(() => {
    const result = reservation?.documents?.filter(
      (doc) =>
        doc?.documentName?.includes('Agreement') ||
        doc?.type?.includes('agreement')
    )?.[0]
    return result
  }, [reservation])

  const isSignatureMode = useMemo(
    () =>
      reservation?.signatureMode?.length
        ? reservation?.signatureMode === 'docusign'
        : true,
    [reservation]
  )

  const deleteDocument = useCallback(
    async (documentId) => {
      const toastId = toast.loading('Deleting document...')
      setIsDeleteInProgress(true)
      try {
        await deleteReservation(reservation?._id, documentId)
        refetchReservationDetails?.()
        toast.dismiss(toastId)
        toast.success('Document deleted successfully')
      } catch {
        toast.dismiss(toastId)
      }
      setIsDeleteInProgress(false)
    },
    [reservation, deleteReservation, refetchReservationDetails]
  )

  return (
    <>
      <div
        className={classNames(
          'p-3 text-lg font-bold grid gap-x-12 gap-y-4 h-full w-1/2 grid-cols-1',
          (isUpdateInProgress || isDeleteInProgress) && 'opacity-30'
        )}
      >
        <div className='bg-gray-100 flex flex-col gap-10 justify-center items-center rounded-3xl p-4'>
          <div className='flex flex-col gap-6 w-full items-center'>
            <div
              className='flex items-center justify-start'
              style={{ width: '90%' }}
            >
              <div className='w-1/3'>Reservation Status</div>
              <div className='w-1/3'>
                <Select
                  suffixIcon={<DownIcon />}
                  showArrow
                  placeholder='Select'
                  value={status}
                  onChange={(value) => {
                    setStatus(value)
                    if (value.includes('Cancelled')) {
                      _cancelReservation()
                    } else {
                      updateDetails(value)
                    }
                  }}
                  style={{ height: '40px', width: '100%' }}
                  className='rating-select flex align-center w-full text-sm p-2 bg-white border border-softBlack30 h-12 rounded text-softBlack placeholder-softBlack70'
                >
                  {statusArr.map?.((_item, index) => (
                    <Select.Option value={_item} key={index}>
                      <div
                        className='flex gap-2 items-center'
                        style={{ zIndex: '1500 !important' }}
                      >
                        <span>{_item}</span>
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
            {isSignatureMode && (
              <div className='flex items-center' style={{ width: '90%' }}>
                <div className='w-1/3'>
                  Signature Status
                  <p className='font-normal text-sm'>
                    {reservation?.primaryBuyerSignatureDate?.length > 0 &&
                      new Date(
                        reservation?.primaryBuyerSignatureDate
                      ).toLocaleString()}
                  </p>
                </div>
                <div className='flex w-1/3'>
                  <div
                    className={classNames(
                      'flex justify-center items-center font-bold py-4 w-full  rounded cursor-pointer',
                      reservation?.primaryBuyerSignatureStatus?.indexOf(
                        'Signed'
                      ) === -1
                        ? 'bg-gray-300'
                        : 'bg-volt'
                    )}
                  >
                    {reservation?.primaryBuyerSignatureStatus}
                  </div>
                </div>
                {reservation?.primaryBuyerSignatureStatus?.indexOf('Signed') ===
                  -1 && (
                  <>
                    <div className='flex justify-end w-1/3'>
                      <div
                        className='flex justify-center items-center font-bold py-3  w-full ml-8 border border-black rounded cursor-pointer'
                        onClick={onClick}
                      >
                        Resend
                      </div>
                    </div>
                  </>
                )}
                {reservation?.primaryBuyerSignatureStatus?.indexOf('Signed') !==
                  -1 && <PreViewButton reservationId={reservation?._id} />}
              </div>
            )}
            <div className='flex items-center' style={{ width: '90%' }}>
              <div className='w-1/3'>
                Payment Status
                <p className='font-normal text-sm'>
                  {reservation?.paymentReceivedDate?.length > 0 &&
                    new Date(reservation?.paymentReceivedDate).toLocaleString()}
                </p>
              </div>
              <div className='flex  w-1/3'>
                <div
                  className={classNames(
                    'flex justify-center items-center font-bold py-4 w-full  rounded cursor-pointer',
                    reservation?.paymentStatus?.indexOf('Complete') === -1
                      ? 'bg-gray-300'
                      : 'bg-volt'
                  )}
                >
                  {reservation?.paymentStatus}
                </div>
              </div>
              <div className='flex justify-end w-1/3'>
                <div
                  className='flex justify-center items-center text-center font-bold py-3 w-full ml-8 border border-black rounded cursor-pointer'
                  onClick={() => setStep(3)}
                >
                  Record Payment
                </div>
              </div>
            </div>
            <div className='flex w-full flex-col gap-2 mt-5'>
              <DocumentUploadButton
                value={uploadedDoc}
                title='ID Document'
                onClick={() => {
                  setShowDialogDocumentUpload(true)
                }}
                onDelete={() => {
                  deleteDocument(uploadedDoc?._id)
                }}
              />
              <DocumentUploadButton
                value={uploadedReceipt}
                title='Payment Receipt'
                onClick={() => {
                  setShowDialogPaymentReceiptUpload(true)
                }}
                onDelete={() => {
                  deleteDocument(uploadedReceipt?._id)
                }}
              />
              <DocumentUploadButton
                value={uploadedAgreement}
                title='Reservation agreement'
                onClick={() => {
                  setShowDialogReservationAgreementUpload(true)
                }}
                onDelete={() => {
                  deleteDocument(uploadedAgreement?._id)
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {showDialogDocumentUpload && (
        <PaymentReceipt
          title='Document Upload'
          type='id_document'
          show={true}
          projectId={projectId}
          onClose={() => {
            setShowDialogDocumentUpload(false)
            // setPaymentReceiptData(null)
          }}
          onSucess={(data) => {
            setShowDialogDocumentUpload(false)
            refetchReservationDetails?.()
          }}
          reservation={reservation}
        />
      )}
      {showDialogReservationAgreementUpload && (
        <PaymentReceipt
          title='Reservation Agreement Upload'
          type='agreement'
          show={true}
          projectId={projectId}
          onClose={() => {
            setShowDialogReservationAgreementUpload(false)
            // setPaymentReceiptData(null)
          }}
          onSucess={(data) => {
            setShowDialogReservationAgreementUpload(false)
            refetchReservationDetails?.()
          }}
          reservation={reservation}
        />
      )}
      {showDialogPaymentReceiptUpload && (
        <PaymentReceipt
          title='Payment Receipt Upload'
          show={true}
          type='payment_receipt'
          projectId={projectId}
          onClose={() => {
            setShowDialogPaymentReceiptUpload(false)
            // setPaymentReceiptData(null)
          }}
          onSucess={(data) => {
            setShowDialogPaymentReceiptUpload(false)
            refetchReservationDetails?.()
          }}
          reservation={reservation}
        />
      )}
    </>
  )
}
export default SendBuyer
