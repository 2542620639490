import React, { useEffect, useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { AnimatePresence } from 'framer-motion'
import HelloSign from 'hellosign-embedded'
import propTypes from 'prop-types'

import { Button, H5 } from 'ui'
import {
  LoadContractButton,
  HelloSignWrapper
} from './styles'
import { FadedAnimatedDiv } from 'components'

const client = new HelloSign()

const HelloSignDoc = (props) => {
  const { clientId, helloSignUrl, executeAfterSign, onUserCancel } = props
  const [userCanceled, setUserCanceled] = useState(false)

  // When finished the process of signature, redirect to startPayment
  client.on('finish', () => _executeAfterSign())
  client.on('cancel', () => onUserCancel())
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const loadDoc = useCallback(() => {
    const isProduction = (process.env.REACT_APP_ENVIRONMENT ?? '')?.includes(
      'prod'
    )
    client.open(helloSignUrl, {
      clientId: clientId,
      skipDomainVerification: !isProduction,
      container: document.getElementById('hellosign-wrapper')
    })
  }, [clientId, helloSignUrl])

  // When mounted, open the doc from API
  useEffect(() => {
    loadDoc()
  }, [loadDoc])

  const _executeAfterSign = useCallback(() => {
    executeAfterSign()
  }, [executeAfterSign])

  return (
    <AnimatePresence exitBeforeEnter initial={false}>
      {
        userCanceled
          ? (
            <FadedAnimatedDiv key='loadContract'>
              <LoadContractButton justify='center'>
                <H5>Click below to reload your contract</H5>
                <Button
                  onClick={() => {
                    setUserCanceled(false)
                    setTimeout(() => {
                      loadDoc()
                    }, 1000)
                  }}
                  showShadow
                >
                  Load contract
                </Button>
              </LoadContractButton>
            </FadedAnimatedDiv>
            )
          : (
            <FadedAnimatedDiv
              style={{
                height: '100%',
                width: '100%'
              }}
              key='helloSignWrapper'
              className='mt-10 md:mt-0'
            >
              <HelloSignWrapper id='hellosign-wrapper' />
            </FadedAnimatedDiv>
            )
      }
    </AnimatePresence>
  )
}

const mapStateToProps = state => ({
  signDocUnitId: state.appReducer.signDocUnitId
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(HelloSignDoc)

HelloSignDoc.propTypes = {
  clientId: propTypes.string,
  helloSignUrl: propTypes.string,
  executeAfterSign: propTypes.func,
  onUserCancel: propTypes.func
}
