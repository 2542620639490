import React from 'react'
import { Select } from 'antd'
import { ReactComponent as DownIcon } from 'assets/icons/down.svg'

const CustomSelect = ({
  label,
  value,
  handleChange,
  options,
  showSearch = false,
  isLeadStatus = false,
  isName = false,
  disabled = false,
  optionFilterProp = 'label'
}) => {
  return (
    <div
      className='w-full flex flex-col gap-y-2'
    >
      <div
        className='text-softBlack70 text-xs font-medium uppercase'
      >
        {label}
      </div>
      <Select
        showArrow
        value={value}
        onChange={handleChange}
        className='ant-picker-input1'
        getPopupContainer={(node) => node.parentNode}
        suffixIcon={<DownIcon />}
        showSearch={showSearch}
        disabled={disabled}
        optionFilterProp={optionFilterProp}
      >
        {options?.map((_item, index) => (
          <Select.Option value={isName ? _item._id : _item.value} key={index} label={isName ? _item?.firstName + ' ' + _item?.lastName : _item.label}>
            {
              isLeadStatus
                ? <div>
                  {
                    value === _item.value
                      ? <div className='w-fit flex items-center justify-center gap-x-1 p-1 px-3 rounded-full bg-green-100'>
                        <div className='h-3 w-3 bg-green-500 rounded-full' />
                        <h6 className='text-xs font-medium m-0 text-green-800'>{value}</h6>
                      </div>
                      : <>{_item.label}</>
                  }
                </div>
                : <div className='flex gap-2 items-center'>
                  {isName ? _item?.firstName + ' ' + _item?.lastName : _item.label}
                </div>
            }
          </Select.Option>
        ))}
      </Select>
    </div>
  )
}

export default CustomSelect
