import React, { useCallback, useState, memo, useEffect } from 'react'
import propTypes from 'prop-types'
import { ChromePicker } from 'react-color'
import { Sider } from 'components'
import { InputLabel, Modal } from 'ui'
import { validateRequiredInputs } from 'utils'
import { createOrUpdateLookupList, getLookuplist } from 'store/actions/lookuplistActions'

const ColorPickerModal = memo(({ showModal, onCancelColorSelection, onOkModal, color, onChangeColor }) => (
  <Modal
    showModal={showModal}
    onCancel={onCancelColorSelection}
    modalType='custom'
    onOk={onOkModal}
    okText='Ok'
  >
    <ChromePicker
      disableAlpha
      color={color}
      onChange={(newColor) => onChangeColor(newColor.hex)}
    />
  </Modal>
))

const CreateEditRatingSideBar = memo(({
  _id, showSideBar, setShowSideBar, executeAfterSave, executeOnCancel,
  developerCompanyId
}) => {
  const [name, setName] = useState('')
  const [color, setColor] = useState('')
  const [originalColor, setOriginalColor] = useState('')
  const [savingRating, setSavingRating] = useState(false)
  const [gettingRating, setGettingRating] = useState(false)
  const [showColorPickerModal, setShowColorPickerModal] = useState(false)

  useEffect(() => {
    if (_id) {
      setGettingRating(true)

      getLookuplist({ _id })
        .then((rating) => {
          const { name, value } = rating

          setName(name)
          setColor(value)
          setOriginalColor(value)
          setGettingRating(false)
        })
        .catch(() => setGettingRating(false))
    }
  }, [_id])

  const clearValues = useCallback(() => {
    setName('')
    setColor('')
    setOriginalColor('')
  }, [])

  const createEditRating = useCallback(() => {
    validateRequiredInputs('rating')
      .then(() => {
        setSavingRating(true)

        const params = {
          _id,
          name: name,
          type: 'contact_rating_status',
          key: developerCompanyId,
          value: color
        }

        createOrUpdateLookupList(params)
          .then(() => {
            setSavingRating(false)
            clearValues()
            setShowSideBar()
            executeAfterSave()
          })
          .catch(() => setSavingRating(false))
      })
  }, [developerCompanyId, name, color, executeAfterSave, setShowSideBar, _id, clearValues])

  const onCancelClick = useCallback(() => {
    clearValues()
    executeOnCancel()
    setShowSideBar()
  }, [setShowSideBar, clearValues, executeOnCancel])

  const cancelColorSelection = useCallback(() => {
    setColor(originalColor)
    setShowColorPickerModal(false)
  }, [originalColor])

  const updateOriginalBackgroundColor = useCallback(() => {
    setOriginalColor(color)
    setShowColorPickerModal(false)
  }, [color])

  return (
    <Sider
      title='New rating'
      subtitle='Fill the rating Name and Color below'
      showMenu={showSideBar}
      setShowMenu={setShowSideBar}
      onSaveClick={createEditRating}
      onCancelClick={onCancelClick}
      position='right'
      fixed={false}
      allowToPopup
      saving={savingRating}
      initPoppedOut
    >
      <div className='px-5 space-y-5 min-w-xs'>
        <ColorPickerModal
          showModal={showColorPickerModal}
          onCancelColorSelection={cancelColorSelection}
          onOkModal={updateOriginalBackgroundColor}
          color={color}
          onChangeColor={(color) => setColor(color)}
        />

        <InputLabel
          id='name'
          name='name'
          type='text'
          label='Name'
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Rating name"
          required
          validateKey='rating'
          loading={gettingRating || savingRating}
        />
        <InputLabel
          id='color'
          name='color'
          label='Color'
          value={color}
          onChange={(e) => setColor(e.value)}
          placeholder='What is the rating color?'
          required
          validateKey='rating'
          loading={gettingRating || savingRating}
          inputBackgroundColor={color}
          onClick={() => setShowColorPickerModal(!showColorPickerModal)}
        />
      </div>
    </Sider>
  )
})

export default CreateEditRatingSideBar

CreateEditRatingSideBar.propTypes = {
  _id: propTypes.string,
  showSideBar: propTypes.bool,
  setShowSideBar: propTypes.func,
  executeAfterSave: propTypes.func,
  executeOnCancel: propTypes.func,
  developerCompanyId: propTypes.string
}

ColorPickerModal.propTypes = {
  showModal: propTypes.bool,
  onCancelColorSelection: propTypes.bool,
  onOkModal: propTypes.bool,
  color: propTypes.bool,
  onChangeColor: propTypes.bool
}
