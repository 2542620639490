import { PageHeader } from 'components'
import React from 'react'

const PostSalesSuccess = () => {
  return (
    <div>
      <PageHeader title='Post Sales Success' />
    </div>
  )
}

export default PostSalesSuccess
