import React, {
  useState, useEffect,
  useCallback
} from 'react'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
import { InlineInput, Modal, Table, Tabs } from 'ui'
import { FadedAnimatedDiv, FormTabsWrapper, FormWrapper, ImageUpload, ProjectOptionContractData } from 'components'
import { changeProjectOptionImages, createOrUpdateProjectOption, getProjectOption, deleteProjectOptionImage, deleteProjectOptionContractDetail } from 'store/actions/projectActions'

import { createUploadFileList, validateRequiredInputs } from 'utils'
import { TCancelButton, TSaveButton } from 'components/DefaultButtons'
import { ImageIcon } from 'components/Icons'
import { AnimatePresence } from 'framer-motion'

const TabHeader = ({ title, subtitle, extraContent }) => (
  <div className='space-y-1 flex flex-row justify-between items-center'>
    <div className='flex flex-col'>
      <h3 className='text-lg leading-6 font-medium text-gray-900'>
        {title && title}
      </h3>
      <p className='max-w-2xl text-sm text-gray-500'>
        {subtitle && subtitle}
      </p>
    </div>
    {extraContent}
  </div>
)

const TabContent = ({ children }) => (
  <div className='mt-6'>
    <dl className='divide-y divide-gray-200'>
      {children}
    </dl>
  </div>
)

const optionContractColumns = [
  {
    Title: 'Template',
    accessor: 'templateName'
  },
  {
    Title: 'Field',
    accessor: 'field',
    Cell: ({ row: { original: { field } } }) => {
      if (field && Object.entries(field).length) {
        const { name, type, value } = field
        return `${name} | ${type} | ${value}`
      } else {
        return 'All fields'
      }
    }
  }
]

const CreateNewButton = ({ optionName, onClick, className, loading }) => {
  return (
    <TSaveButton
      onClick={onClick}
      className={className}
      loading={loading}
    >
      {`Create new ${optionName}`}
    </TSaveButton>
  )
}

const ProjectOptionData = (props) => {
  const {
    _id,
    userObject: {
      userType: loggedUserType
    },
    projectId,
    optionType,
    executeOnBack,
    currencyType,
    executeAfterSave,
    validationAux,
    saveButtonText,
    loading
  } = props

  const [localOptionId, setLocalOptionId] = useState('')
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [value, setValue] = useState('')
  const [images, setImages] = useState([])
  const [contractDetail, setContractDetail] = useState([])
  const [gettingData, setGettingData] = useState(false)
  const [optionContractId, setOptionContractId] = useState('')
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showMustHaveOptionCreatedModal, setShowMustHaveOptionCreatedModal] = useState(false)
  const [entityNameBeingDeleted, setEntityNameBeingDeleted] = useState('')
  const [idBeingDeleted, setIdBeingDeleted] = useState('')
  const [deleting, setDeleting] = useState(false)

  const [creatingEditingContract, setCreatingEditingContract] = useState(false)

  const [selectedTab, setSelectedTab] = useState('general')

  const [saving, setSaving] = useState(false)

  useEffect(() => {
    if (_id) {
      setLocalOptionId(_id)
    }
  }, [_id])

  const getProjectOptionData = useCallback(() => {
    if (localOptionId && projectId) {
      setGettingData(true)

      // Get project data from id
      getProjectOption({ projectId, optionId: localOptionId })
        .then((projectOption) => {
          const { name, description, value, images, contractDetail } = projectOption

          const tmpImages = createUploadFileList(images)
          const tmpContractDetail = contractDetail.map((contract) => ({
            _id: contract._id,
            field: contract.field,
            templateName: contract.contractTemplate ? contract.contractTemplate.name : '',
            templateDescription: contract.contractTemplate ? contract.contractTemplate.description : '',
            templateId: contract.contractTemplate ? contract.contractTemplate._id : ''
          }))

          setName(name)
          setDescription(description)
          setValue(value)
          setImages(tmpImages)
          setContractDetail(tmpContractDetail)
          setGettingData(false)
        })
    } else {
      setGettingData(false)
    }
  }, [localOptionId, projectId])

  useEffect(() => {
    getProjectOptionData()
  }, [getProjectOptionData])

  const _onSaveClick = useCallback((backAfterSave = true) => new Promise((resolve, reject) => {
    validateRequiredInputs(`createOption${validationAux}`)
      .then(() => {
        setSaving(true)

        let params = {
          _id: localOptionId,
          type: optionType,
          projectId,
          name,
          description,
          value,
          contractDetail
        }

        if (!localOptionId) {
          params = Object.assign({}, params, { images })
        }

        createOrUpdateProjectOption(params)
          .then((optionCreated) => {
            setSaving(false)

            if (executeAfterSave) {
              executeAfterSave()
            }

            // If it's creating and allow to back after save go back to previous page
            if (!localOptionId && backAfterSave) {
              executeOnBack()
            }

            resolve(optionCreated)
          })
          .catch(() => {
            setSaving(false)
            getProjectOptionData()
            reject()
          })
      })
  }), [
    localOptionId, contractDetail, description, images, name, optionType,
    projectId, value, getProjectOptionData, executeAfterSave, validationAux,
    executeOnBack
  ])

  const renderGeneralTab = useCallback(() => (
    <div className={`${selectedTab !== 'general' && 'sr-only'}`}>
      <TabHeader
        title='General'
        subtitle='Here are all infos related to the option'
      />
      <TabContent>
        <InlineInput
          id={`name${validationAux}`}
          name={`name${validationAux}`}
          type='text'
          label='Name'
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder={'the option\'s name'}
          required
          validateKey={`createOption${validationAux}`}
          loading={gettingData}
          onClickUpdate={localOptionId ? _onSaveClick : null}
        />
        <InlineInput
          id={`value${validationAux}`}
          name={`value${validationAux}`}
          type='number'
          label='Value'
          value={value}
          typeInput='numberinput'
          onChange={(e) => setValue(e.value)}
          placeholder={'the option\'s value'}
          required
          prefix={currencyType}
          validateKey={`createOption${validationAux}`}
          loading={gettingData}
          onClickUpdate={localOptionId ? _onSaveClick : null}
        />
        <InlineInput
          id={`description${validationAux}`}
          name={`description${validationAux}`}
          type='text'
          label='Description'
          typeInput='textarea'
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder='you can put a description here that will be shown at the offer creation'
          required
          validateKey={`createOption${validationAux}`}
          loading={gettingData}
          onClickUpdate={localOptionId ? _onSaveClick : null}
        />
      </TabContent>
    </div>
  ), [
    localOptionId, _onSaveClick, currencyType, description, gettingData,
    name, selectedTab, value, validationAux
  ])

  const renderOptionImagesTab = useCallback(() => (
    <div className={`${selectedTab !== 'images' && 'sr-only'}`}>
      <TabHeader
        title='Image'
        subtitle='Click at the area below or drag an image here to update the option images'
      />
      <TabContent>
      <ImageUpload
          uploadProps={{
            name: 'imageUpload',
            fileList: images,
            listType: 'picture-card',
            className: 'avatar-uploader',
            showUploadList: images.length > 0,
            multiple: true,
            onChange: ({ fileList }) => {
              setImages(fileList)
            },
            beforeUpload: () => {
              if (!localOptionId) {
                return false
              }
            },
            customRequest: (componentData) => {
              if (localOptionId) {
                const { file } = componentData
                changeProjectOptionImages({ projectId, optionId: localOptionId, images: file })
                  .then(() => {
                    getProjectOptionData()
                  })
                  .catch(() => {})
              }
            },
            onRemove: (file) => {
              deleteProjectOptionImage({
                projectId,
                optionId: localOptionId,
                url: file.url
              })
                .then(() => {
                  getProjectOptionData()
                })
                .catch(() => {})
            },
            style: { height: '100%', width: '100%' }
          }}
          uploadButtonText={'Click or drag here the Option\'s Images'}
          uploadButtonIcon={<ImageIcon style={{ fontSize: 30 }} />}
          uploadWrapperStyle={{
            width: '100%',
            marginRight: 0,
            marginBottom: 25
          }}
          loading={gettingData}
          skeletonHeight={100}
        />
      </TabContent>
    </div>
  ), [localOptionId, gettingData, images, projectId, selectedTab, getProjectOptionData])

  const startContractCreation = useCallback(() => {
    setOptionContractId(0)
    setCreatingEditingContract(true)
  }, [])

  const renderOptionContractsTab = useCallback(() => (
    <div className={`${selectedTab !== 'contracts' && 'sr-only'}`}>
      <AnimatePresence exitBeforeEnter initial={false}>
        {
          creatingEditingContract
            ? (
                <FadedAnimatedDiv key='creatingNewContract'>
                  <ProjectOptionContractData
                    _id={optionContractId}
                    projectId={projectId}
                    optionId={localOptionId}
                    executeOnBack={() => {
                      getProjectOptionData()
                      setOptionContractId('')
                      setCreatingEditingContract(false)
                    }}
                    executeAfterSave={() => {
                      getProjectOptionData()

                      if (!optionContractId) {
                        setCreatingEditingContract(false)
                      }
                    }}
                  />
                </FadedAnimatedDiv>
              )
            : (
                <FadedAnimatedDiv key='contractsTable'>
                  <TabHeader
                    title='Contracts'
                    subtitle='Set up below the contracts related to this option'
                    extraContent={
                      <CreateNewButton
                        optionName='contract'
                        loading={loading}
                        onClick={() => {
                          if (!localOptionId) {
                            setShowMustHaveOptionCreatedModal(true)
                          } else {
                            startContractCreation()
                          }
                        }}
                      />
                    }
                  />
                  <TabContent>
                    <Table
                      loading={gettingData}
                      className='rounded-lg'
                      dataSource={contractDetail}
                      columns={optionContractColumns}
                      tailwindTable
                      onViewEditClick={({ original: { _id } }) => {
                        setOptionContractId(_id)
                        setCreatingEditingContract(true)
                      }}
                      deleting={deleting}
                      idBeingDeleted={idBeingDeleted}
                      onDeleteClick={
                        ({ original: { _id, templateName } }) => {
                          setIdBeingDeleted(_id)
                          setEntityNameBeingDeleted(templateName)
                          setShowDeleteModal(true)
                        }
                      }
                    />
                  </TabContent>
                </FadedAnimatedDiv>
              )
        }
      </AnimatePresence>
    </div>
  ), [
    contractDetail, gettingData, selectedTab,
    creatingEditingContract, localOptionId, projectId,
    optionContractId, deleting, idBeingDeleted,
    startContractCreation, getProjectOptionData,
    loading
  ])

  const renderIdTab = useCallback(() => (
    <div className={`${selectedTab !== 'id' && 'sr-only'}`}>
      <TabHeader
        title='ID'
        subtitle='This tab is only available to Super Admins'
      />
      <TabContent>
        <InlineInput
          key='optionId'
          label='Option ID'
          value={localOptionId}
          onChange={(e) => {}}
          placeholder='option ID'
          loading={gettingData}
          disabled
        />
      </TabContent>
    </div>
  ), [gettingData, localOptionId, selectedTab])

  const getMenus = useCallback(() => {
    const menus = [
      { key: 'general', title: 'General' },
      { key: 'images', title: 'Images' },
      { key: 'contracts', title: 'Contracts' }
    ]

    if (loggedUserType === 'SuperAdmin' && Boolean(localOptionId)) {
      menus.push(
        { key: 'id', title: 'ID' }
      )
    }

    return menus
  }, [loggedUserType, localOptionId])

  const deleteContract = useCallback(() => {
    setShowDeleteModal(false)
    setDeleting(true)

    deleteProjectOptionContractDetail({ projectId, optionId: localOptionId, contractDetailId: idBeingDeleted })
      .then(() => {
        setDeleting(false)
        getProjectOptionData()
        setEntityNameBeingDeleted('')
        setIdBeingDeleted('')
      })
      .catch(() => setDeleting(false))
  }, [
    localOptionId, getProjectOptionData, idBeingDeleted, projectId
  ])

  const CustomModal = useCallback(({ showModal, onCancel, modalType, title, subtitle, onOk }) => (
    <Modal
      showModal={showModal}
      onCancel={onCancel}
      modalType={modalType}
      title={title}
      subtitle={subtitle}
      onOk={onOk}
    />
  ), [])

  const renderDeleteModal = useCallback(() => (
    <CustomModal
      showModal={showDeleteModal}
      onCancel={() => {
        setShowDeleteModal(false)
        setTimeout(() => {
          setEntityNameBeingDeleted('')
          setIdBeingDeleted('')
        }, 150)
      }}
      modalType='danger'
      title='Confirm deletion?'
      subtitle={`${entityNameBeingDeleted} will be removed from this option!`}
      onOk={deleteContract}
    />
  ), [entityNameBeingDeleted, deleteContract, showDeleteModal])

  const renderMustHaveOptionCreatedModal = useCallback(() => (
    <CustomModal
      showModal={showMustHaveOptionCreatedModal}
      onCancel={() => {
        setShowMustHaveOptionCreatedModal(false)
      }}
      modalType='alert'
      title='Create option before the contract?'
      subtitle={'You must have an already existing option before creating the contract. Do you want to create the option now?'}
      onOk={() => {
        setShowMustHaveOptionCreatedModal(false)
        _onSaveClick(false)
          .then((optionCreated) => {
            const { optionId } = optionCreated
            setLocalOptionId(optionId)
            setCreatingEditingContract(true)
          })
      }}
    />
  ), [_onSaveClick, showMustHaveOptionCreatedModal])

  return (
    <>
      { renderDeleteModal() }
      { renderMustHaveOptionCreatedModal() }
      <FormWrapper>
        <FormTabsWrapper>
          <Tabs
            menus={getMenus()}
            tabSelected={selectedTab}
            onClick={(tab) => setSelectedTab(tab)}
          />
          <div>
            {
              !localOptionId && (
                <TSaveButton
                  loading={saving}
                  className='ml-3'
                  onClick={_onSaveClick}
                >
                  Save {saveButtonText}
                </TSaveButton>
              )
            }
            <TCancelButton
              loading={saving}
              className='ml-3'
              onClick={executeOnBack}
            >
              Back
            </TCancelButton>
          </div>
        </FormTabsWrapper>

        <div className='mt-7'>
          {renderGeneralTab()}
          {renderOptionImagesTab()}
          {renderOptionContractsTab()}
          {renderIdTab()}
      </div>
      </FormWrapper>
    </>
  )
}

const mapStateToProps = (state) => ({
  userObject: state.authReducer.userObject
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectOptionData)

ProjectOptionData.propTypes = {
  _id: propTypes.string,
  userObject: propTypes.shape({
    userType: propTypes.string
  }),
  projectId: propTypes.string,
  optionType: propTypes.string,
  executeOnBack: propTypes.func,
  currencyType: propTypes.string,
  executeAfterSave: propTypes.func,
  validationAux: propTypes.string,
  saveButtonText: propTypes.string,
  loading: propTypes.bool
}

TabHeader.propTypes = {
  title: propTypes.string,
  subtitle: propTypes.string,
  extraContent: propTypes.node
}

TabContent.propTypes = {
  children: propTypes.node
}

CreateNewButton.propTypes = {
  optionName: propTypes.string,
  onClick: propTypes.func,
  className: propTypes.string,
  loading: propTypes.bool
}
