import React, { useCallback, useMemo, useRef } from 'react'
import sitePlan from '../../assets/img/site-plan-georgia.png' // pxp-reactjs-admin/src/assets/img/site-plan-georgia.png
import { fabric } from 'fabric'

const ProjectUnitsInventoryGraph = () => {
  const canvasRef = useRef(null)
  const fabricCanvas = useRef(null)

  const myCanvas = useMemo(() => {
    if (!fabricCanvas.current && canvasRef.current) {
      fabricCanvas.current = new fabric.Canvas(canvasRef.current)
    }
    return fabricCanvas.current
  }, [canvasRef.current, fabric])

  const addRect = useCallback(() => {
    console.log('myCanvas', myCanvas)
    if (myCanvas) {
      myCanvas.add(
        new fabric.Rect({ width: 50, height: 50, fill: 'red', opacity: 0.2, hasborder: true })
      )
    }
  }, [myCanvas])

  return (
    <div className='flex h-screen w-screen flex-row'>
      <div
        style={{
          height: 540,
          width: 960,
          backgroundImage: `url(${sitePlan})`,
          backgroundRepeat: 'no-repeat',
          backgroundClip: 'content-box',
          backgroundPosition: 'center',
          backgroundSize: 'cover'
        }}
      >
        <canvas ref={canvasRef} height={540} width={960} />
      </div>
      <div className='flex-1'>
        <button
          onClick={() => {
            addRect()
          }}
        >
          Add Rect
        </button>
      </div>
    </div>
  )
}

export default ProjectUnitsInventoryGraph
