// @ts-nocheck
import React, { useMemo, useRef, useState } from 'react'
import CustomModal from 'components/Modal'
import { deleteUser, forgotPassword, getUsers } from 'store/actions/usersActions'
import NewDevAdmin from './new'
import CustomButton from 'components/CustomButton'
import { Pen, Plus, Trash } from 'lucide-react'
import moment from 'moment'
import CustomTable from 'components/CustomTable'
import { Oval } from 'react-loader-spinner'
import { message } from 'antd'

const DeveloperAdmins = () => {
  const mountedRef = useRef(true)
  const [isLoading, setIsLoading] = useState(true)
  const [developerAdmins, setDeveloperAdmins] = useState([])
  const [viewMode, setViewMode] = useState('list')
  const [selectedDevAdmin, setSelectedDevAdmin] = useState('')
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false)
  const userTypeFilter = '?userType=DeveloperAdmin'
  const [isSendingResetLink, setIsSendingResetLink] = useState(false)

  React.useEffect(() => {
    getUsers('', userTypeFilter).then((users) => {
      if (!mountedRef.current) return null
      getDevAdmins()
    })
    return () => {
      mountedRef.current = false
    }
  }, [])

  const getDevAdmins = () => {
    setIsLoading(true)
    getUsers('', userTypeFilter).then((users) => {
      setDeveloperAdmins([
        ...users.sort((a, b) => {
          const dateA = new Date(a?.createdAt)
          const dateB = new Date(b?.createdAt)
          return dateB - dateA
        }).map((user) => ({
          ...user,
          fullName: user?.firstName + ' ' + user?.lastName ?? '',
          developerCompanyName: user?.developerAdminData?.developerCompany?.companyName
        }))
      ])
      setIsLoading(false)
    })
  }

  const selectedUser = developerAdmins.find((user) => user.id === selectedDevAdmin)

  const onSendResetLink = async (email) => {
    setIsSendingResetLink(true)
    await forgotPassword(email).then(() => {
      setIsSendingResetLink(false)
      message.success('Successfully sent reset link to developer admin.')
    }).catch(() => {
      setIsSendingResetLink(false)
      message.success('Failed sent reset link to developer admin.')
    })
  }

  const tableColumns = useMemo(
    () => [
      {
        id: 'dateRegistered',
        label: 'Date Registered',
        Title: (<span className='leading-none text-sm font-normal text-[#2E2B2E] text-opacity-70'>Date Registered</span>),
        accessor: 'createdAt',
        dataIndex: 'createdAt',
        isSort: true,
        sortingType: 'string',
        Cell: (props) => (
          <div className='w-full flex flex-col'>
            <p className='mb-0 truncate text-base font-normal'>{moment(props.cell.value).format('MM/DD/YYYY')}</p>
          </div>
        )
      },
      {
        id: 'name',
        label: 'Name',
        Title: (<span className='leading-none text-sm font-normal text-[#2E2B2E] text-opacity-70'>Name</span>),
        accessor: 'fullName',
        dataIndex: 'fullName',
        isSort: true,
        sortingType: 'string',
        Cell: (props) => (
          <span className='flex items-center space-x-4'>
            {props.cell.row.original?.imgsrc
              ? <img src={props.cell.row.original.imgsrc} alt='Avatar' className='h-12 w-12 border rounded-full' />
              : <div className='h-12 w-12 border rounded-full flex items-center justify-center bg-gray-200 text-text-gray-500 font-bold'>
                {props.cell.row.original.firstName[0]}
                {props.cell.row.original.lastName[0]}
              </div>
            }
            <span className='text-base font-normal capitalize'>{props.cell.value}</span>
          </span>
        )
      },
      {
        id: 'email',
        label: 'Email',
        Title: (<span className='leading-none text-sm font-normal text-[#2E2B2E] text-opacity-70'>Email</span>),
        accessor: 'email',
        dataIndex: 'email',
        isSort: true,
        sortingType: 'string',
        Cell: (props) => <p className='mb-0 text-base font-normal'>{props.cell.value}</p>
      },
      {
        id: 'developerCompany',
        label: 'Developer Company',
        Title: (<span className='leading-none text-sm font-normal text-[#2E2B2E] text-opacity-70'>Developer Company</span>),
        accessor: 'developerCompanyName',
        dataIndex: 'developerCompanyName',
        isSort: true,
        sortingType: 'string',
        Cell: (props) => <p className='mb-0 text-base font-normal capitalize'>{props.cell.value}</p>
      },
      {
        id: 'actionBtn',
        label: 'Action button',
        Title: '',
        accessor: '_id',
        isSort: false,
        Cell: (props) => (
          <div className='flex flex-row gap-2 text-center items-center justify-end'>
            <CustomButton
              variant='outline'
              handleClick={() => onSendResetLink(props.cell.row.original.email)}
              disabled={isSendingResetLink}
            >
              <span className='p-2 px-3 text-base font-semibold flex items-center gap-2'>
                Send reset link
              </span>
            </CustomButton>
            <CustomButton
              handleClick={() => {
                setSelectedDevAdmin(props.cell.value)
                setViewMode('new')
              }}
            >
              <span className='p-2.5 text-base font-semibold flex items-center gap-2'>
                <Pen className='w-5 h-5' />
              </span>
            </CustomButton>
            <CustomButton
              variant='danger'
              handleClick={() => {
                setSelectedDevAdmin(props.cell.value)
                setIsShowDeleteModal(true)
              }}
            >
              <span className='p-2.5 text-base font-semibold flex items-center gap-2'>
                <Trash className='w-5 h-5' />
              </span>
            </CustomButton>
          </div>
        )
      }
    ]
    , [isSendingResetLink])

  const DevAdminList = () => (
    <>
      <div className='p-6 flex justify-between items-center'>
        <span className='font-bold text-3xl'>Developer Admins</span>
        <CustomButton handleClick={() => setViewMode('new')}>
          <span className='p-2 px-4 text-base font-semibold flex items-center gap-2'>
            <Plus className='w-5 h-5' />
            Add Developer Admins
          </span>
        </CustomButton>
      </div>
      <div className='border-b-2 border-black' />
      <div className='p-6'>
        <CustomTable
          className='rounded-lg'
          dataSource={developerAdmins}
          columns={tableColumns}
          tailwindTable
          loading={isLoading}
          isFromContracts
          noMargin
        />
      </div>
    </>
  )

  const handleModalClose = () => {
    setSelectedDevAdmin('')
    setIsShowDeleteModal(false)
  }

  const handleDevAdminDelete = () => {
    setIsLoading(true)
    deleteUser(selectedDevAdmin)
      .then(() => {
        getDevAdmins()
        setIsLoading(false)
        handleModalClose()
      })
      .catch(() => {
        setIsLoading(false)
        handleModalClose()
      })
  }

  return (
    <div className='bg-gray-100 min-h-screen'>
      {viewMode === 'list' && <DevAdminList />}
      {viewMode === 'new' && (
        <NewDevAdmin
          devAdmin={selectedUser}
          cancel={() => {
            setSelectedDevAdmin('')
            setViewMode('list')
          }}
          update={getDevAdmins}
        />
      )}
      <CustomModal isOpen={isShowDeleteModal} closeModal={handleModalClose}>
        <div className='py-10 px-20 flex flex-col items-center'>
          <h1 className='font-bold text-black'>Delete Developer Admin</h1>
          <p className='py-2'>
            Are you sure you want to delete
            <strong className='ml-1'>
              {selectedUser?.firstName} {selectedUser?.lastName}
            </strong>
            ?
          </p>
          <div className='flex font-semibold space-x-4'>
            <CustomButton
              variant='outline'
              handleClick={handleModalClose}
            >
              <span className='p-2 px-3 text-base font-semibold flex items-center gap-2'>
                Cancel
              </span>
            </CustomButton>
            <CustomButton
              handleClick={handleDevAdminDelete}
              disabled={isLoading}
            >
              <span className='p-2 px-3 text-base font-semibold flex items-center gap-2'>
                {
                  isLoading
                    ? <Oval height='20' width='20' color='white' />
                    : <Trash className='w-5 h-5' />
                }
                Delete
              </span>
            </CustomButton>
          </div>
        </div>
      </CustomModal>
    </div>
  )
}

export default DeveloperAdmins
