// @ts-nocheck
/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { isJson } from 'utils/isJson';
import moment from 'moment';
export default function NotesLists({
  notesList,
  setSelectedNote,
  selectedNote,
  contact,
  user,
  setNotesList,
  setNewCard,
  refetch,
  onFocusOutNewNoteBox,
  onDeleteNewNote,
  isEditable = true,
}) {
  const [notes, setNotes] = useState([]);
  useEffect(() => {
    if (notesList.length > 0) {
      let data = notesList.map((note) => {
        return {
          ...note,
          buyer: {
            firstName: contact?.firstName,
            lastName: contact?.lastName,
            _id: contact?._id,
            id: contact?._id,
          },
          noteType: 'General',
          contact: contact?._id,
          notes: {
            notes: note
              ? isJson(note.notes)
                ? JSON.parse(note.notes).notes
                : note.notes
              : '',
            title:
              (
                (note &&
                  (isJson(note.notes)
                    ? JSON.parse(note.notes).title
                    : note.notes)) ||
                ''
              ).trim() ||
              (
                (note &&
                  (isJson(note.notes)
                    ? JSON.parse(note.notes).notes
                    : note.notes)) ||
                ''
              ).substring(0, 5),
          },
          user,
          id: note?._id,
          _id: note?._id,
          edit: true,
          note: '',
        };
      });
      if (isEditable) {
        setSelectedNote(data[0]);
        if (selectedNote?._id === null || selectedNote?._id === undefined) {
          setSelectedNote(data[0]);
        } else {
          setSelectedNote(selectedNote);
        }
      }

      setNotes(data);
    }
  }, [notesList]);

  const formatDate = (createdAt) => {
    const currentDay = moment();
    const createdDay = moment(createdAt);
    if (currentDay.diff(createdDay, 'days') > 1) {
      return createdDay.format('lll');
    } else {
      return createdDay.format('LT');
    }
  };
  return (
    <div className='flex col-span-3 overflow-auto flex-col gap-y-3'>
      {notes.length > 0 &&
        notes.map((_note, index) => {
          return (
            <>
              <div
                onClick={() => {
                  if (isEditable) {
                    setSelectedNote(_note);
                  }
                }}
                className='flex flex-col cursor-pointer bg-gray-200 p-3 rounded-[8px]'
                style={{
                  backgroundColor:
                    selectedNote?._id === _note?._id ? '#F0F0F0' : '#FFFFFF',
                }}
              >
                <div className='font-bold text-[18px]'>
                  {_note?.notes?.title?.length > 20
                    ? _note?.notes?.title?.slice(0, 20) + '...'
                    : _note?.notes?.title}
                </div>
                <div className='text-[14px]'>
                  <span className='mr-2'>{formatDate(_note?.createdAt)}</span>
                  {_note?.notes?.notes?.length > 20
                    ? _note?.notes?.notes?.slice(0, 20) + '...'
                    : _note?.notes?.notes}
                </div>
              </div>
              <div className='w-full h-[1px] bg-slate-200'></div>
            </>
          );
        })}
    </div>
  );
}
