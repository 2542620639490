import React from 'react'
import { ReactComponent as TickIcon } from 'assets/icons/tickk.svg'

const ReservationCreated = ({ onSentToSignClicked, onManuallySignClicked }) => {
  return (
    <>
      <div className=' p-3 text-lg font-bold grid gap-x-12 gap-y-4 h-full w-1/2 grid-cols-1'>
        <div
          style={{ height: '75vh', borderRadius: '25px' }}
          className='bg-gray-100 flex flex-col gap-8 justify-center items-center '
        >
          <div className='flex flex-col items-center'>
            <h1 className='text-4xl text-center'>Reservation</h1>
            <h1 className='text-4xl text-center'>Succesfully Created</h1>
          </div>
          <div className='bg-volt h-36 w-36 rounded-lg flex justify-center items-center'>
            <TickIcon />
          </div>
          <div
            className='flex justify-center items-center font-bold py-4 px-12 bg-volt rounded cursor-pointer'
            onClick={onSentToSignClicked}
          >
            Send to buyer to sign
          </div>

          <div
            className='flex justify-center items-center font-bold py-4 px-12  rounded cursor-pointer border border-softBlack_70'
            onClick={onManuallySignClicked}
          >
            Manually Sign
          </div>
        </div>
      </div>
    </>
  )
}
export default ReservationCreated
