import { useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import {
  createDropDown,
  getDyanamicDropdownData,
  updateDropDown
} from 'store/actions/usersActions'
import { toast } from 'react-hot-toast'

const useDynamicDropdown = (key) => {
  // @ts-ignore
  const projectId = useSelector((state) => state.appReducer.appProject)
  const [formattedData, setFormattedData] = useState([])
  const client = useQueryClient()
  const { data, isLoading } = useQuery(
    [key, projectId],
    ({ queryKey }) => {
      return getDyanamicDropdownData(queryKey[1], queryKey[0])
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!projectId
    }
  )

  useEffect(() => {
    setFormattedData(
      data?.values?.map?.((val) => ({ label: val, value: val, id: val })) ||
        null
    )
  }, [data])

  const onSuccess = () => {
    client.invalidateQueries({
      predicate: (query) =>
        query.queryKey[0] === key && query.queryKey[1] === projectId
    })
    toast.success(
      `Successfully ${data?.values?.length ? 'updated' : 'created'} ${key}`
    )
  }

  const { mutateAsync: makeDropDown } = useMutation(createDropDown, {
    onSuccess
  })
  const { mutateAsync: changeDropDown } = useMutation(updateDropDown, {
    onSuccess
  })

  const update = ({ name }) => {
    if (data?._id) {
      return changeDropDown({ ...data, values: [...data.values, name] })
    }
    makeDropDown({ project: projectId, name: key, values: [name] })
  }

  const updateItem = ({ name, oldName }) => {
    const values = [...data.values]
    const index = values.findIndex((item) => item === oldName)
    values[index] = name
    changeDropDown({ ...data, values })
  }

  const deleteItem = ({ name }) => {
    changeDropDown({
      ...data,
      values: data.values.filter((val) => val !== name)
    })
  }

  return {
    data,
    loading: isLoading,
    update,
    deleteItem,
    updateItem,
    formattedData
  }
}

export default useDynamicDropdown
