import React from 'react'
import propTypes from 'prop-types'

const Tabs = ({ menus, onClick, tabSelected, showBottomBorder }) => {
  const selectChange = () => {
    const selected = document.getElementById('selected-tab').value

    onClick(selected)
  }

  return (
    <>
      <div className='lg:hidden w-full'>
        <label htmlFor='selected-tab' className='sr-only'>Select a tab</label>
        <select
          onChange={selectChange}
          id='selected-tab'
          name='selected-tab'
          className='mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300
                focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm rounded-md'
        >
          {menus.map(menu => (
            <option
              key={menu.key}
              value={menu.key}
              selected={tabSelected === menu.key}
            >
              {menu.title}
            </option>
          ))}
        </select>
      </div>
      <div className='hidden lg:block'>
        <div className={showBottomBorder && 'border-b border-gray-200'}>
          <nav className='-mb-px flex'>
            {menus.map(menu => {
              const selected = tabSelected === menu.key

              return (
                <button
                  key={menu.key}
                  onClick={() => onClick(menu.key)}
                  className={`${selected
                    ? 'border-purple-500 text-purple-600'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'}
                  whitespace-nowrap py-4 px-1 border-b-2 mr-8
                  focus:outline-none`}
                >
                  {menu.title}
                </button>
              )
            })}
          </nav>
        </div>
      </div>
    </>
  )
}

export default Tabs

Tabs.defaultProps = {
  showBottomBorder: true
}

Tabs.propTypes = {
  menus: propTypes.arrayOf({
    key: propTypes.string,
    title: propTypes.string
  }),
  onClick: propTypes.func,
  tabSelected: propTypes.string,
  showBottomBorder: propTypes.bool
}
