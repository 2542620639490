/* eslint-disable */
// @ts-nocheck
import { Select } from 'antd'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { ReactComponent as DownIcon } from 'assets/icons/down.svg'
import CustomTextArea from 'components/CustomTextArea'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import useCreateReservation from 'hooks/useCreateReservation'
import { classNames } from 'utils'
import useBuyers from 'hooks/useGetBuyers'
import useGetSearchUsers from 'hooks/useGetSearchUsers'
import useSendBuyerToSign from 'hooks/useSendBuyerToSign'
import useGetReservationDetail from 'hooks/useGetReservationDetail'
import { CreateBrokerReservationContext } from '../context/CreateReservationContext'
import ReservationCreated from '../DocumentSign/ReservationCreated'
import SendBuyer from '../DocumentSign/SendBuyer'
import RecordPayment from '../DocumentSign/RecordPayment'
import { toast } from 'react-hot-toast'
import { getUsers } from 'store/actions/usersActions'
import useUpdateReservationDocument from 'hooks/useUpdateReservationDocument'
import useCancelReservation from 'hooks/useCancelReservation'

const { Option } = Select

const NewReservation = () => {
  const { id } = useParams()
  const { step, setStep } = useContext(CreateBrokerReservationContext)
  const {
    loading: isReservationLoading,
    reservation,
    refetch: getReservationDetail
  } = useGetReservationDetail()
  const history = useHistory()
  const { signDocument, loading: signInLoading } = useSendBuyerToSign()
  const [createOrUpdateReservation, loading, data] = useCreateReservation()
  const [cancelReservation, cancelInLoading] = useCancelReservation()
  const { deleteAPI } = useUpdateReservationDocument()
  const [reservationId, setReservationId] = useState()
  const { buyers, getBuyersData } = useBuyers()
  const [filteredBuyers, setFilteredBuyers] = useState([])
  const [selectedBuyer, setSelectedBuyer] = useState()
  const [notes, setNotes] = useState()
  const [currency, setCurrency] = useState()
  const [paymentMethod, setPaymentMethod] = useState()
  const [contractLanguage, setContractLanguage] = useState('English')
  const [searchValue, setSearchValue] = useState('')
  const [isDocSignInProgress, setIsDocSignInProgress] = useState(false)
  const projectId = useSelector((state) => state.appReducer.appProject)

  const isCreatingReservation = useMemo(() => {
    return id?.indexOf('new') !== -1
  }, [id])

  const refetchReservationDetails = useCallback(() => {
    getReservationDetail(id)
  }, [id])

  useEffect(() => {
    if (!isCreatingReservation) {
      // get reservation detail and set it.
      refetchReservationDetails(id)
    }
  }, [id, isCreatingReservation])

  useEffect(() => {
    if (reservation) {
      const data = reservation.data
      if (data) {
        setSelectedBuyer(data.primaryBuyer)
        const buyerId = data.primaryBuyer
        // get buyer detail
        getUsers(buyerId).then((buyer) => {
          if (buyer && buyer.buyerData && Object.keys(buyer.buyerData).length) {
            setSelectedBuyer(buyer)
          }
        })
        setCurrency(data.currencyType)
        setPaymentMethod(data.paymentMethod)
        setContractLanguage(data.contractLanguage)
        setNotes(data.notes)
        setStep(2)
      }
    }
  }, [reservation])

  const paymentMethods = ['Cash', 'Wire', 'Credit Card', 'Check']
  const contractLanguages = ['English', 'Spanish']
  const currencyArr = [
    { id: 'Mexicon Pesos', value: 'MXN' },
    { id: 'USD', value: 'USD' }
  ]

  const isUpdateInProgress = useMemo(() => {
    return signInLoading || loading || cancelInLoading
  }, [signInLoading, loading, cancelInLoading])

  const handleReservation = () => {
    if (isUpdateInProgress) {
      return
    }
    const NewReservation = {
      name: selectedBuyer?._id,
      currency,
      paymentMethod,
      contractLanguage,
      notes
    }
    createOrUpdateReservation(projectId, NewReservation)
    setStep(1)
  }

  useEffect(() => {
    setReservationId(data?._id)
  }, [data])

  const handleDocumentSign = useCallback(async () => {
    if (isUpdateInProgress) {
      return
    }
    const toastId = toast.loading('Sending document to buyer...')
    setIsDocSignInProgress(true)
    try {
      await signDocument(reservationId)
      toast.dismiss(toastId)
      toast.success('Document sent to buyer successfully')
      history.replace(`/lead-broker/reservations/${reservationId}`)
      setStep(2)
    } catch {
      toast.dismiss(toastId)
    }
    setIsDocSignInProgress(false)
  }, [toast, reservationId, isUpdateInProgress])

  const handleManuallySign = useCallback(async () => {
    if (isUpdateInProgress) {
      return
    }
    setIsDocSignInProgress(true)
    try {
      const dataObject = {
        _id: reservationId,
        signatureMode: 'manual'
      }
      await createOrUpdateReservation(projectId, dataObject)
      history.replace(`/lead-broker/reservations/${reservationId}`)
      setStep(2)
    } catch { }
    setIsDocSignInProgress(false)
  }, [reservationId, isUpdateInProgress])

  useEffect(() => {
    getBuyersData()
  }, [])

  const { searchUsers, isFetching: searching } = useGetSearchUsers(
    searchValue,
    'Buyer'
  )
  useEffect(() => {
    if (buyers && !searchUsers) {
      setFilteredBuyers({ data: buyers })
    }
  }, [buyers])

  useEffect(() => {
    if (searchUsers) {
      setFilteredBuyers(searchUsers)
    }
  }, [searchUsers])

  const onChange = (value) => {
    setSelectedBuyer(filteredBuyers?.data?.find?.((usr) => usr?._id === value))
  }

  const handleSearch = (value) => {
    setSearchValue(value)
  }

  return (
    <div className={classNames(isDocSignInProgress && 'disabled opacity-30', 'py-4 px-14')}>
      <div className='w-full flex justify-between items-center'>
        <h3 className='text-4xl font-bold ml-2 mb-0 uppercase'>
          {selectedBuyer && (!isCreatingReservation || data)
            ? `Reservation - ${selectedBuyer.firstName ?? '...'} ${selectedBuyer.lastName ?? '...'
            }`
            : 'Create Reservation'}
        </h3>
        <div>
          {selectedBuyer && (!isCreatingReservation || data) && (
            <button
              className={
                'flex justify-center items-center p-3 rounded font-bold cursor-pointer w-56 border border-black'
              }
              onClick={() => history.push('/lead-broker/reservations')}
            >
              Go to Reservation List
            </button>
          )}
          {!(selectedBuyer && (!isCreatingReservation || data)) && (
            <>
              <div className='flex flex-row gap-x-12'>
                <button
                  className={
                    'flex justify-center items-center p-3 rounded font-bold cursor-pointer w-32 border border-black'
                  }
                  onClick={() => history.push('/lead-broker/reservations')}
                >
                  Cancel
                </button>
                <button
                  className={
                    'flex justify-center items-center p-3 font-bold rounded cursor-pointer w-32 border border-black disabled:opacity-50 disabled:cursor-not-allowed'
                  }
                  disabled={true}
                  onClick={() => {
                    console.log('On Save-Draft Clicked')
                  }}
                >
                  Save Draft
                </button>
                <button
                  className={
                    'flex justify-center items-center font-bold p-3 bg-volt rounded cursor-pointer w-48'
                  }
                  onClick={handleReservation}
                >
                  Create Reservation
                </button>
              </div>
            </>
          )}
        </div>
      </div>
      <div className='w-full pt-4 h-full'>
        <div className='w-full h-full p-5 bg-white flex flex-row gap-10 rounded'>
          <div
            className={classNames(
              ' p-3 text-lg font-bold grid gap-x-12 gap-y-4 h-full',
              !isCreatingReservation || data
                ? 'w-1/2 grid-cols-1'
                : 'w-full grid-cols-2'
            )}
          >
            <div className='col-span-1'>
              <div className='w-full'>
                <div className='text-lg font-bold'>
                  Reservation Holder Details
                  <div className='border-b-2 border-black w-full mt-3'></div>
                </div>
                <div className='mt-6'>
                  <p
                    className={
                      'mt-0 mb-2 text-softBlack70 text-xs font-medium uppercase'
                    }
                    style={{ letterSpacing: '0.16px' }}
                  >
                    RESERVATION HOLDER
                  </p>
                  <Select
                    showSearch
                    style={{
                      width: '100%',
                      textDecoration: 'none',
                      borderColor: 'transparent'
                    }}
                    placeholder='Select a buyer'
                    optionFilterProp='children'
                    value={selectedBuyer?._id}
                    onSearch={handleSearch}
                    onChange={onChange}
                    className='text-sm'
                    loading={searching}
                  >
                    {filteredBuyers?.data?.map((buyer) => (
                      <Option key={buyer?._id} value={buyer?._id}>
                        {buyer?.firstName} {buyer?.lastName}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className='mt-6'>
                  <p
                    className={
                      'mt-0 mb-2 text-softBlack70 text-xs font-medium uppercase'
                    }
                    style={{ letterSpacing: '0.16px' }}
                  >
                    Contract Language*
                  </p>
                  <Select
                    suffixIcon={<DownIcon />}
                    showArrow
                    placeholder='Select'
                    value={contractLanguage}
                    onChange={(value) => {
                      setContractLanguage(value)
                    }}
                    style={{ height: '40px' }}
                    className='rating-select flex align-center w-full text-sm p-2 bg-white border border-softBlack30 h-12 rounded text-softBlack placeholder-softBlack70'
                  >
                    {contractLanguages.map?.((_item, index) => (
                      <Select.Option value={_item} key={index}>
                        <div
                          className='flex gap-2 items-center'
                          style={{ zIndex: '1500 !important' }}
                        >
                          <span>{_item}</span>
                        </div>
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className='w-full mt-9'>
                <div className='text-lg font-bold'>
                  Payment Details
                  <div className='border-b-2 border-black w-full mt-3'></div>
                </div>
                <div className='mt-6'>
                  <p
                    className={
                      'mt-0 mb-2 text-softBlack70 text-xs font-medium uppercase'
                    }
                    style={{ letterSpacing: '0.16px' }}
                  >
                    Currency
                  </p>
                  <Select
                    suffixIcon={<DownIcon />}
                    showArrow
                    placeholder='Select'
                    value={currency}
                    onChange={(value) => {
                      setCurrency(value)
                    }}
                    style={{ height: '40px' }}
                    className='rating-select flex align-center w-full text-sm p-2 bg-white border border-softBlack30 h-12 rounded text-softBlack placeholder-softBlack70'
                  >
                    {currencyArr.map?.((_item) => (
                      <Select.Option value={_item.value} key={_item.id}>
                        <div
                          className='flex gap-2 items-center'
                          style={{ zIndex: '1500 !important' }}
                        >
                          <span>{_item.id}</span>
                        </div>
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                <div className='mt-6'>
                  <p
                    className={
                      'mt-0 mb-2 text-softBlack70 text-xs font-medium uppercase'
                    }
                    style={{ letterSpacing: '0.16px' }}
                  >
                    Payment Method
                  </p>
                  <Select
                    suffixIcon={<DownIcon />}
                    showArrow
                    placeholder='Select'
                    value={paymentMethod}
                    onChange={(value) => {
                      setPaymentMethod(value)
                    }}
                    style={{ height: '40px' }}
                    className='rating-select flex align-center w-full text-sm p-2 bg-white border border-softBlack30 h-12 rounded text-softBlack placeholder-softBlack70'
                  >
                    {paymentMethods.map?.((_item, index) => (
                      <Select.Option value={_item} key={index}>
                        <div
                          className='flex gap-2 items-center'
                          style={{ zIndex: '1500 !important' }}
                        >
                          <span>{_item}</span>
                        </div>
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                <div className='mt-6'>
                  <CustomTextArea
                    label='Reservation Notes'
                    placeholder='Enter Description'
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    style={{ color: '#2E2B2E' }}
                    labelClasses={undefined}
                    textareaClasses='h-36'
                    containerClasses={undefined}
                    errorMessage={undefined}
                  />
                </div>
              </div>
            </div>
          </div>
          {selectedBuyer && (data || !isCreatingReservation) && (
            <>
              {step === 1 && (
                <ReservationCreated
                  onSentToSignClicked={handleDocumentSign}
                  onManuallySignClicked={handleManuallySign}
                />
              )}
              {step === 2 && (
                <SendBuyer
                  onClick={handleDocumentSign}
                  setStep={setStep}
                  reservation={reservation?.data}
                  createOrUpdateReservation={createOrUpdateReservation}
                  refetchReservationDetails={refetchReservationDetails}
                  cancelReservation={cancelReservation}
                  deleteReservation={deleteAPI}
                />
              )}
              {step === 3 && (
                <RecordPayment
                  setStep={setStep}
                  reservation={reservation?.data}
                  createOrUpdateReservation={createOrUpdateReservation}
                  selectedBuyer={selectedBuyer}
                  refetchReservationDetails={refetchReservationDetails}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}
export default NewReservation
