import React, { useEffect, useState } from 'react'

import { PRIVACIES } from '../constants/Privacies'

import ToggleSwitch from 'components/ToggleSwitch'

const PWAPrivacyConsent = ({ setButtonState }) => {
  useEffect(() => {
    setButtonState('Default')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [privacies, setPrivacies] = useState(PRIVACIES)

  const onChangePrivacies = (privacy) => {
    const privacyClone = [...privacies]
    for (let i = 0; i < privacyClone.length; i++) {
      const privacyTemp = privacyClone[i]
      if (privacyTemp.id === privacy.id) {
        privacyTemp.isActive = !privacyTemp.isActive
        break
      }
    }
    setPrivacies(privacyClone)
  }

  const RenderPrivacy = ({ privacy }) => (
    <div
      style={{ height: 174 }}
      className='w-full bg-grey-8 text-softBlack font-light italic text-lg font-openSans p-6'
    >
      <div className='mb-6'>{privacy.text}</div>
      <ToggleSwitch
        checked={privacy.isActive}
        setChecked={() => onChangePrivacies(privacy)}
      />
    </div>
  )

  return (
    <>
      <div className='mx-20'>
        <div
          style={{ marginBottom: 445 }}
          className='mt-12 flex justify-center gap-6'
        >
          {privacies.map((privacy) => (
            <RenderPrivacy privacy={privacy} key={privacy.id} />
          ))}
        </div>
      </div>
    </>
  )
}

export default PWAPrivacyConsent
