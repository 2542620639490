import styled from 'styled-components'
import { Row } from 'antd'

export const FeatureWrapper = styled(Row)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 10px;
`

export const FeaturesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: rgba(0, 0, 0, 0.4);
  margin-top: 10px;
`
