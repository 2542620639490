import React from 'react'

const AddToListButton = ({
  setOpenAddToList = (bool) => {}
}) => {
  return (
    <button
        onClick={() => setOpenAddToList(true)}
        type='button'
        style={{ fontStyle: 'italic', padding: '5px' }}
        className='inline-flex items-center justify-left rounded-md border border-transparent bg-volt px-4 py-2 text-sm font-medium text-black shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:w-auto mt-4'
    >
        <span
        style={{
          marginLeft: '10px',
          fontSize: '14px',
          marginRight: '10px'
        }}
        >
        + Add To List
        </span>
    </button>
  )
}

export default AddToListButton
