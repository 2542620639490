import React, { memo, useCallback, useState } from 'react'
import propTypes from 'prop-types'
import {
  AppHeader,
  PagesWrapper,
  AdminSideMenu
} from 'components'
import { connect, useSelector } from 'react-redux'

const MemoAdminSideMenu = memo(({ showMenu, setShowMenu }) => (
  <AdminSideMenu showMenu={showMenu} setShowMenu={setShowMenu} />
))

const MemoAppHeader = memo(({ setShowMenu }) => (
  <AppHeader setShowMenu={setShowMenu} />
))

// const router = useNavigate()
const HeaderAndPageWrapper = memo(({ children }) => {
  const show = useSelector((state) => state.appReducer)
  return (
    <div className={`flex flex-col w-full flex-1 overflow-hidden bg-white transition-all duration-300 ease-in-out delay-0 ${(show.show && !show.isHover) ? 'md:ml-[281px]' : 'ml-0'}`}>
      {children}
    </div>
  )
})

const Children = memo(({ children }) => (
  <div className="w-full" style={{ height: 'calc(100% - 64px)' }}>
    {children}
  </div>
))
const AdminDashboardContainer = (props) => {
  const [showMenu, setShowMenu] = useState(false)
  const { children } = props
  const _setShowMenu = useCallback(() => {
    setShowMenu(!showMenu)
  }, [showMenu])

  return (
    <div className=" h-screen flex overflow-hidden bg-white" id="platformWrapper">
      <MemoAdminSideMenu showMenu={showMenu} setShowMenu={_setShowMenu} />
      <HeaderAndPageWrapper>
        <PagesWrapper>
          <Children>{children}</Children>
        </PagesWrapper>
      </HeaderAndPageWrapper>
    </div>
  )
}

const mapStateToProps = (state) => ({
  userObject: state.authReducer.userObject
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboardContainer)

AdminDashboardContainer.defaultProps = {
  isUserLoggedIn: false,
  hideBackButton: false,
  renderContainer: true
}

AdminDashboardContainer.propTypes = {
  children: propTypes.node.isRequired
  // listOfProjects: propTypes.array
}

MemoAdminSideMenu.propTypes = {
  showMenu: propTypes.bool,
  setShowMenu: propTypes.func
  // listOfProjects: propTypes.array
}

MemoAppHeader.propTypes = {
  setShowMenu: propTypes.func
}

HeaderAndPageWrapper.propTypes = {
  children: propTypes.node
}

Children.propTypes = {
  children: propTypes.node
}
