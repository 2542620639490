import React, { useContext, useEffect, useState } from 'react'
import { MdModeEdit } from 'react-icons/md'
import { useFormikContext } from 'formik'
import { CreateOpportunityContext } from 'pages/opportunity/context/CreateOpportunityContext'
import { STEP } from 'pages/opportunity/steps'
import FormSelect from '../FormSelect/FormSelect'
import { opportunityRatings, opportunityStages } from '../fieldData'
import FormInput from '../FormInput/FormInput'
import { useHistory } from 'react-router-dom'
import { ADMIN_OPPORTUNITIES } from 'routes'
import BooleanFormSelect from '../BooleanFormSelect/BooleanFormSelect'
import { getBuyers } from 'store/actions/usersActions'
import SelectUnitInterest from '../SelectUnitInterest/SelectUnitInterest'
import SelectFloorPlans from '../SelectFloorPlans/SelectFloorPlans'
import SelectParkingSlot from '../SelectParkingSlot/SelectParkingSlot'

const Form = ({ isEdit }) => {
  const history = useHistory()
  const [buyer, setBuyer] = useState(null)
  const { setStep } = useContext(CreateOpportunityContext)
  const { handleSubmit, handleChange, values } = useFormikContext()
  useEffect(() => {
    if (values.user) {
      getBuyers().then((res) => {
        setBuyer(res.filter((elm) => elm._id === values.user)[0])
      })
    }
  }, [values])

  return (
        <div className='w-auto flex-1 h-full bg-grey-8 overflow-auto'>
            <div className='w-full'>
                <div className='w-full p-4'>
                    <div className='w-full flex flex-row justify-between items-center border-b-2 border-black p-4'>
                        <div className='text-xl font-bold'>{isEdit ? 'Edit' : 'Create'} Opportunity</div>
                        <div className='flex flex-row gap-2'>
                            <div onClick={() => { isEdit ? history.push(ADMIN_OPPORTUNITIES) : setStep(STEP.ONE) }} className='cursor-pointer p-2 text-md'>Cancel</div>
                            { // @ts-ignore
                                <div onClick={handleSubmit} className='cursor-pointer p-2 text-md bg-volt rounded flex flex-row gap-2 justify-center items-center'><MdModeEdit />{isEdit ? 'Save' : 'Create Opportunity'}</div>}
                        </div>
                    </div>
                </div>
                <div className='flex flex-row justify-center items-center flex-wrap p-4 pb-0 gap-4'>
                    <FormInput title='OPPORTUNITY NAME*' fieldName='name' placeholder='Opportunity Name' />
                    <FormSelect title='OPPORTUNITY STAGE*' fieldName='stage' placeholder='Select Stage' options={opportunityStages} />
                </div>
                <div className='flex flex-row justify-center items-center flex-wrap p-4 gap-4'>
                    <div className='flex flex-1 flex-col justify-left'>
                        <div className='font-bold text-softBlack_70 italic'>BUYER NAME</div>
                        <input value={buyer ? buyer?.firstName + ' ' + buyer?.lastName : ''} placeholder='Buyer Name' disabled />
                    </div>
                    <FormSelect title='OPPORTUNITY RATING*' fieldName='rating' placeholder='Select Rating' options={opportunityRatings} />
                </div>
                <div className='flex flex-row justify-center items-center flex-wrap p-4 gap-4'>
                    <div className='flex flex-1 flex-col justify-left'>
                        <div className='font-bold text-softBlack_70 italic'>Opportunity Notes</div>
                        <textarea onChange={handleChange('notes')} value={values.notes} className='rounded resize-none' rows={4} placeholder='Enter your notes here'></textarea>
                    </div>
                </div>
            </div>
            <div className='w-full'>
                <div className='w-full p-4'>
                    <div className='w-full flex flex-row justify-left items-center border-b-2 border-black p-4'>
                        <div className='text-xl font-bold'>Unit Interest</div>
                    </div>
                </div>
                <div className='flex flex-row justify-center items-center flex-wrap p-4 pb-0 gap-4'>
                    <SelectUnitInterest title='1ST CHOICE UNIT INTEREST' fieldName='firstChoiceUnit' placeholder='#12021' />
                    <FormSelect title='FLOOR NUMBERS' fieldName='floorNumbersInterest' placeholder='Select Floor numbers' multi dynamic/>
                </div>
                <div className='flex flex-row justify-center items-center flex-wrap p-4 pb-0 gap-4'>
                    <SelectUnitInterest title='2ND CHOICE UNIT INTEREST' fieldName='secondChoiceUnit' placeholder='#12022' />
                    <FormSelect title='ORIENTATION' fieldName='orientation' placeholder='Select Orientation' dynamic />
                </div>
                <div className='flex flex-row justify-center items-center flex-wrap p-4 pb-0 gap-4'>
                    <SelectUnitInterest title='3RD CHOICE UNIT INTEREST' fieldName='thirdChoiceUnit' placeholder='#12023' />
                    <SelectFloorPlans title='FLOOR PLANS' fieldName='floorPlansInterest' placeholder='Select Floor Plans' />
                </div>
                <div className='flex flex-row justify-center items-center flex-wrap p-4 pb-0 gap-4'>
                    <FormSelect title='UPGRADES' fieldName='upgrades' placeholder='Select Upgrade Options' dynamic multi />
                    <BooleanFormSelect title='NEED PARKING' placeholder='Select Option' fieldName='needParking' />
                </div>
                <div className='flex flex-row justify-center items-center flex-wrap p-4 pb-0 gap-4'>
                    <FormSelect title='NUMBER OF BEDROOMS' placeholder='3' fieldName='numberOfBedrooms' dynamic multi/>
                    <SelectParkingSlot title='NUMBER OF PARKING SLOTS' placeholder='2' fieldName='numberOfParkingSpots' />
                </div>
                <div className='flex flex-row justify-center items-center flex-wrap p-4 pb-0 gap-4'>
                    <FormSelect title='MIN SQUARE FEET' placeholder='500 SF' fieldName='minimumSquareFeet' dynamic />
                    <FormSelect title='MAX SQUARE FEET' placeholder='2500 SF' fieldName='maximumSquareFeet' dynamic />
                </div>
                <div className='w-full p-4'>
                    <div className='w-full flex flex-row justify-left items-center border-b-2 border-black p-4'>
                        <div className='text-xl font-bold'>Budget, Timing & Financing </div>
                    </div>
                </div>
                <div className='flex flex-row justify-center items-center flex-wrap p-4 pb-0 gap-4'>
                    <FormSelect title='BUDGET' placeholder='500K - 1M' fieldName='budget' dynamic />
                    {/* <FormSelect title='PRIMARY USE' fieldName='primaryUse' placeholder='owner occupier' dynamic multi/> */}
                </div>
                <div className='flex flex-row justify-center items-center flex-wrap p-4 pb-8 gap-4'>
                    <BooleanFormSelect title='Need Financing?' placeholder='Select Option' fieldName='needsFinancing' />
                    <FormSelect title='PRIMARY USE' fieldName='primaryUse' placeholder='owner occupier' dynamic multi />
                </div>
            </div>
        </div>
  )
}

export default Form
