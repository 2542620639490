import React from 'react'
import propTypes from 'prop-types'
// import { TFilledPlusIcon } from 'components/Icons'
// import { useHistory } from 'react-router-dom'
// import { ADMIN_OPPORTUNITY } from 'routes'
import { connect } from 'react-redux'
import ParkingInventoryMap from 'components/ParkingInventoryMap'
import ParkingInventoryMapContextProvider from 'components/ParkingInventoryMap/context/ParkingInventoryMapContext'
// import { isMobile } from 'react-device-detect'

const Button = (props) => {
  return (
    <button
      className='text-softBlack text-center text-base font-medium py-1.5 flex items-center'
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  )
}

const ParkingInventoryMapWrapper = ({ appProject }) => {
  return (
    <ParkingInventoryMapContextProvider>
      <ParkingInventoryMap />
    </ParkingInventoryMapContextProvider>
  )
}

const mapStateToProps = (state) => ({
  appProject: state.appReducer.appProject
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ParkingInventoryMapWrapper)

ParkingInventoryMapWrapper.propTypes = {
  appProject: propTypes.string
}

Button.propTypes = {
  disabled: propTypes.bool,
  onClick: propTypes.func,
  children: propTypes.node
}
