import styled from 'styled-components'
import { Row } from 'antd'

import { Button, H7, H6 } from 'ui'
import standardStyle from 'assets/css/standardStyle'

export const Wrapper = styled.div``

export const ImgWrapper = styled.div``

export const DetailsWrapper = styled.div``

export const RowDetail = styled.div``

export const Thumbnail = styled.img``

export const LeftColumn = styled.div``

export const OfferTitle = styled.span``

export const FinancingContaineer = styled(Row)`
  color: ${standardStyle.colors.primary};
  margin-top: 5px;
`

export const FinancingStatus = styled.span`
  margin-left: 5px;
  font-weight: 600;
  font-size: 13px;
`

export const UnitStatusWrapper = styled.div``

export const CheckButtonWrapper = styled(Row)``

export const CheckOfferDetailsButton = styled(Button)`
  font-size: 18px;
  margin-top: 10px;
  width: 100%;
`

export const DownPaymentValue = styled(H6)`
  margin-bottom: 0;
  font-weight: bold;
`

export const DownPaymentText = styled(H7)`
  margin-bottom: 0;
`
