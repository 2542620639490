/* eslint-disable no-unused-vars */
import React, { createContext, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { getAllDropDowns } from 'store/actions/usersActions'
import { STEP } from '../steps'

export const CreateOpportunityContext = createContext(null)

export const CreateOpportunityProvider = ({ children }) => {
  const [step, setStep] = useState(STEP.ONE)
  const [selectedBuyers, setSelectedBuyers] = useState([])
  const [dropDowns, setDropDowns] = useState([])
  // @ts-ignore
  const projectId = useSelector((state) => state.appReducer.appProject)
  const fetchDropDowns = useRef(async () => {})

  fetchDropDowns.current = async () => {
    const data = await getAllDropDowns(projectId)
    setDropDowns(data)
  }

  useEffect(() => {
    fetchDropDowns.current()
  }, [])

  return (
    <CreateOpportunityContext.Provider
      value={{ step, setStep, selectedBuyers, setSelectedBuyers, dropDowns }}
    >
      {children}
    </CreateOpportunityContext.Provider>
  )
}
