import React, { useEffect } from 'react'
import propTypes from 'prop-types'
import { BuyerPageWithImage, OfferSummary } from 'components'

const BuyerOfferSummary = (props) => {
  const { projectData } = props

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <BuyerPageWithImage
      image={projectData && projectData.images[2]}
    >
      <OfferSummary
        {...props}
      />
    </BuyerPageWithImage>
  )
}

export default BuyerOfferSummary

BuyerOfferSummary.propTypes = {
  projectData: propTypes.object
}
