import React from 'react'
import styled from 'styled-components'
import propTypes from 'prop-types'
import { TButton } from 'ui'

import royalLogo from 'assets/img/royalLogo.png'

export const SurveyWrapper = styled.div``

export const SurveyStepWrapper = ({ children, className }) => <div className={`flex flex-col items-center justify-center ${className}`}>{children}</div>

export const HeaderLogo = ({ className }) => <img src={royalLogo} className={className} alt='Royal'/>

export const SurveyFooter = ({ onClickContinue, footerButtonDisabled, className }) => (
  <>
    <TButton
      disabled={footerButtonDisabled}
      onClick={onClickContinue}
      className={`h-10 w-56 text-lg font-bold my-12 ${className}`}
      style={{ background: '#3f8f9d' }}
    >
      Continue
    </TButton>
  </>
)

export const SurveyTitle = ({ children, className }) => <span className={`text-3xl font-bold ${className}`}>{children}</span>
export const SurveySubTitle = ({ children, className }) => <span className={`text-2xl font-bold ${className}`}>{children}</span>

SurveyStepWrapper.defaultProps = {
  className: ''
}

SurveyFooter.defaultProps = {
  footerButtonDisabled: false,
  className: ''
}

SurveyStepWrapper.propTypes = {
  children: propTypes.node,
  className: propTypes.string
}

HeaderLogo.propTypes = {
  className: propTypes.string
}

SurveyFooter.propTypes = {
  onClickContinue: propTypes.func,
  footerButtonDisabled: propTypes.bool,
  className: propTypes.bool
}

SurveyTitle.propTypes = {
  children: propTypes.node,
  className: propTypes.string
}

SurveySubTitle.propTypes = {
  children: propTypes.node,
  className: propTypes.string
}
