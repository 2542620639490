import { api, ApiErrors } from 'services/api'

export const getCustomForms = (projectId) => {
  return new Promise((resolve, reject) => {
    let query = ''
    if (projectId) {
      query = `projectId=${projectId}`
    }
    api.get(`/customforms?${query}`).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })
}

export const getCustomForm = (id) => {
  return new Promise((resolve, reject) => {
    api.get(`/customforms/${id}`).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })
}

export const createCustomForm = (data) => {
  return new Promise((resolve, reject) => {
    api.post('/customforms', data).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })
}

export const updateCustomForm = (id, data) => {
  return new Promise((resolve, reject) => {
    api.put(`/customforms/${id}`, data).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })
}

export const updateCustomFormSettings = (id, data) => {
  return new Promise((resolve, reject) => {
    api.put(`/customforms/${id}/settings`, data).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })
}

export const submitCustomForm = (id, data) => {
  return new Promise((resolve, reject) => {
    api.post(`/customforms/${id}/submit`, data).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })
}

export const getCustomFormSubmissions = (id, data) => {
  return new Promise((resolve, reject) => {
    api.get(`/customforms/${id}/submissions`).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })
}
