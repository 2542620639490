/* eslint-disable */
// @ts-nocheck
import React, { useContext } from 'react';
import { Button } from '@material-ui/core';
import CustomModal from 'components/Modal';
import { DemandNotesContext } from '../context';
import { deleteDemandNote } from 'store/actions/demandNotes';

const ConfirmDelete = () => {
  const {
    deleteModal,
    setDeleteModal,
    dataToDelete,
    setDataToDelete,
    setRefresh,
    deleteItem,
  } = useContext(DemandNotesContext);
  return (
    <CustomModal isOpen={deleteModal} closeModal={() => setDeleteModal(false)}>
      <div className='flex flex-col justify-center items-center p-5 gap-5'>
        <div className='text-lg font-bold'>
          Are you sure you want to delete?
        </div>
        <div className='flex flex-row gap-4 justify-center items-center'>
          <Button
            onClick={() => {
              setDataToDelete('');
              setDeleteModal(false);
            }}
          >
            No
          </Button>
          <Button
            onClick={async () => {
              await deleteDemandNote(dataToDelete);
              setDataToDelete('');
              setDeleteModal(false);
              setRefresh((prev) => !prev);
            }}
            style={{
              backgroundColor: '#EDDF65',
              width: '100%',
            }}
          >
            Delete
          </Button>
        </div>
      </div>
    </CustomModal>
  );
};

export default ConfirmDelete;
