// @ts-nocheck
/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import InputMask from 'react-input-mask'
import * as Yup from 'yup'
import { message } from 'antd'

import CustomModal from 'components/Modal'
import CustomInput from 'components/CustomInput'

import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg'
import useUpdateUsers from 'hooks/useUpdateUsers'

const schema = {
  email: Yup.string().required(),
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  country: Yup.string().required(),
  licenseNumber: Yup.string().required(),
  licenseBrokerage: Yup.string().required()
}

const NewBroker = ({ showCreateModal, setShowCreateModal, isBroker, onSuccess }) => {
  const { createOrUpdateUser } = useUpdateUsers()
  const appProject = useSelector((state) => state.appReducer.appProject)
  const appDeveloper = useSelector((state) => state.appReducer.appDeveloper)
  const [values, setValues] = useState({
    email: '',
    firstName: '',
    lastName: '',
    country: '',
    licenseNumber: '',
    licenseBrokerage: '',
    licenseExpiration: '',
    brokerCommission: 0,
    brokerCommissionOnBalance: 0
  })

  const onChange = (field, value) => {
    setValues({ ...values, [field]: value })
  }

  const onCreate = async () => {
    const isValid = await Yup.object()
      .shape(schema)
      .isValid({ ...values })

    if (!isValid) {
      message.error('please fill all required fields')
      return
    }

    const isEmailValid = await Yup.string().email().isValid(values.email)
    if (!isEmailValid) {
      message.error('please enter valid email.')
      return
    }

    let params = {}

    if (isBroker) {
      params = {
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        userType: 'CoopBroker',
        brokerData: {
          developerCompany: [appDeveloper],
          licenseNumber: values.licenseNumber,
          licenseBrokerage: values.licenseBrokerage,
          licenseExpiration: values.licenseExpiration
        }
      }
    } else {
      params = {
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        userType: 'SalesRep',
        salesRepData: {
          projects: [appProject],
          developerCompany: [appDeveloper],
          licenseNumber: values.licenseNumber,
          licenseBrokerage: values.licenseBrokerage,
          licenseExpiration: values.licenseExpiration
        }
      }
    }

    await createOrUpdateUser(params, false, false)
    setShowCreateModal(false)
    onSuccess()
  }

  const closeModal = () => {
    setShowCreateModal(false)
  }

  return (
    <CustomModal isOpen={showCreateModal} closeModal={closeModal} contentLabel="New Broker">
      <div className='w-253.75 max-w-full p-10 text-center rounded bg-grey-8 relative'>
        <h1 className='text-xl font-bold mb-8'>
          {isBroker ? 'New Broker' : 'New Sales Rep'}
        </h1>
        <div className='absolute top-10 right-10 cursor-pointer' onClick={closeModal}>
          <CrossIcon />
        </div>
        <div>
          <div className='grid grid-cols-2 gap-6 pb-6'>
            <CustomInput label='First Name *' placeholder='Enter first name' onChange={(e) => onChange('firstName', e.target.value)} />
            <CustomInput label='Last Name *' placeholder='Enter last name' onChange={(e) => onChange('lastName', e.target.value)} />
          </div>
          <div className='grid grid-cols-2 gap-6 pb-6'>
            <CustomInput label='Email *' placeholder='Enter email' onChange={(e) => onChange('email', e.target.value)} />
            <CustomInput label='Country *' placeholder='Enter country' onChange={(e) => onChange('country', e.target.value)} />
          </div>
          <div className='grid grid-cols-3 gap-6 pb-8'>
            <CustomInput label='License Number *' placeholder='Enter number' onChange={(e) => onChange('licenseNumber', e.target.value)} />
            <CustomInput label='License Brokerage *' placeholder='Enter license' onChange={(e) => onChange('licenseBrokerage', e.target.value)} />
            <CustomInput label='Licence Expiration' placeholder='DD/MM/YYYY' type="date" onChange={(e) => onChange('licenseExpiration', e.target.value)} />
          </div>

          <p className='text-lg text-left font-bold mt-0 mb-6'>Commission</p>
          <div className='flex gap-4 items-center text-base text-softBlack'>
            Broker will receive
            <InputMask mask="** %" maskPlaceholder=" " value={values.brokerCommission} onChange={(e) => onChange('brokerCommission', e.target.value)} className='w-19.25 border border-softBlack30 h-12 rounded text-softBlack placeholder-softBlack70' />
            on the first $100,000 and
            <InputMask mask="** %" maskPlaceholder=" " value={values.brokerCommissionOnBalance} onChange={(e) => onChange('brokerCommissionOnBalance', e.target.value)} className='w-19.25 border border-softBlack30 h-12 rounded text-softBlack placeholder-softBlack70' />
            on balance.
          </div>
          <button onClick={onCreate} className='bg-volt h-12 rounded px-6 mt-8 text-base font-medium'>
            {isBroker ? 'Create Broker' : 'Create Sales Rep'}
          </button>
        </div>
      </div>
    </CustomModal>
  )
}

export default NewBroker
