import React from 'react'
import { Modal } from '@material-ui/core'

import ElectronicBillIcon from 'assets/icons/money_bill.svg'
import WireTransferIcon from 'assets/icons/wire_transfer.svg'
import CreditCardICon from 'assets/icons/credit_card.svg'
import BillCheckIcon from 'assets/icons/check_bill.svg'
import CryptoCurrencyIcon from 'assets/icons/crypto_currency.svg'
import WeChatIcon from 'assets/icons/we_chat.svg'
import CardButton from 'components/CardButton'
import CustomButton from 'components/CustomButton'

const PaymentTypeModal = ({ showPaymentTypeModal, setShowPaymentTypeModal, setShowPaymentMethodModal, setPaymentMethodId, setSuccessfulPayment }) => {
  const paymentMethods = [
    {
      text: 'Electronic Fund Transfer',
      imgSrc: ElectronicBillIcon,
      id: 1,
      onClickHandler: () => {
        setShowPaymentTypeModal(false)
        setPaymentMethodId(1)
        setShowPaymentMethodModal(true)
      }
    },
    {
      id: 2,
      text: 'Pay with Credit Card',
      imgSrc: CreditCardICon,
      onClickHandler: () => {
        setShowPaymentTypeModal(false)
        setPaymentMethodId(2)
        setShowPaymentMethodModal(true)
      }
    },
    {
      id: 3,
      text: 'Pay with Cheque',
      imgSrc: BillCheckIcon,
      onClickHandler: () => {
        setShowPaymentTypeModal(false)
        setShowPaymentMethodModal(true)
        setSuccessfulPayment(true)
      }
    },
    {
      id: 4,
      text: 'Wechat / AliPay',
      imgSrc: WeChatIcon,
      onClickHandler: () => {
        setShowPaymentTypeModal(false)
        setShowPaymentMethodModal(true)
        setSuccessfulPayment(true)
      }
    },
    {
      id: 5,
      text: 'Cryptocurrency',
      imgSrc: CryptoCurrencyIcon,
      onClickHandler: () => {
        setShowPaymentTypeModal(false)
        setShowPaymentMethodModal(true)
        setSuccessfulPayment(true)
      }
    },
    {
      id: 6,
      text: 'Traditional Wire Transfer',
      imgSrc: WireTransferIcon,
      onClickHandler: () => {
        setShowPaymentTypeModal(false)
        setShowPaymentMethodModal(true)
        setSuccessfulPayment(true)
      }
    }
  ]

  return (
    <Modal
      key={'99'}
      open={showPaymentTypeModal}
      onClose={() => setShowPaymentTypeModal(false)}
      aria-labelledby='Offer Documents'
      aria-describedby='Offer documents list'
    >
      <div
        className='w-[1376px] h-[830px] font-openSans bg-grey-8 absolute p-10 rounded shadow-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 overflow-y-auto'
      >
        <div className='flex justify-center items-center flex-col'>
          <h2 className='font-openSans font-bold text-xl'>Select Payment method</h2>
          <p className='mt-1 font-openSans font-normal text-base'>The contract was sent to the Buyer</p>
          <div className='flex gap-6 flex-wrap justify-center m-8 mt-2'>
            {paymentMethods.map((method, index) => (
              <CardButton key={method.id} text={method.text} imgSrc={method.imgSrc} onClickHandler={method.onClickHandler} />
            ))}
          </div>
          <CustomButton variant='outline' handleClick={() => setShowPaymentTypeModal(false)}>
            <span className='font-medium placeholder py-3 px-6 text-base flex gap-2 items-center'>
              Close
            </span>
          </CustomButton>
        </div>
      </div>
    </Modal>
  )
}

export default PaymentTypeModal
