import React, { useState, useCallback, useEffect } from 'react'
import propTypes from 'prop-types'
import { AnimatePresence } from 'framer-motion'

import { SurveyWrapper } from './surveyComponents'

import Welcome from './welcome'
import UnitTypes from './unitTypes'
import PriceRange from './priceRange'
import Realtor from './realtor'
import RealtorNotified from './realtorNotified'
import FinalOptions from './finalOptions'

import { FadedAnimatedDiv } from 'components'

import OneBedroomIcon from 'assets/img/one_bed.svg'
import TwoBedroomsIcon from 'assets/img/two_beds.svg'
import ThreeBedroomsIcon from 'assets/img/three_beds.svg'
import PenthouseIcon from 'assets/img/apartment.svg'

import { ChevronLeftIcon } from '@heroicons/react/solid'
import { Modal } from 'ui'
import { capitalizeWords, formatMoney } from 'utils'
import { userSendSurvey } from 'store/actions/usersActions'

const AnimatedDiv = ({ children, key }) =>
  <FadedAnimatedDiv
    key={key}
    className='h-full w-full flex flex-col items-center justify-center'
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
  >
    {children}
  </FadedAnimatedDiv>

const originalUnitTypes = [
  { title: 'ONE BEDROOM', icon: <img src={OneBedroomIcon} className='h-18 w-18' alt='unit type one bedroom' />, selected: false, value: 'one_bedroom' },
  { title: 'TWO BEDROOMS', icon: <img src={TwoBedroomsIcon} className='h-24 w-24' alt='unit type two bedrooms' />, selected: false, value: 'two_bedroooms' },
  { title: 'THREE BEDROOMS', icon: <img src={ThreeBedroomsIcon} className='h-24 w-24' alt='unit type three bedrooms' />, selected: false, value: 'three_bedroooms' },
  { title: 'PENTHOUSE', icon: <img src={PenthouseIcon} className='h-18 w-18' alt='unit type penthouse' />, selected: false, value: 'penthouse' }
]

const ReviewLine = ({ title, children }) => (
  <div className='flex flex-col mb-7'>
    <span className='text-2xl'>{title}</span>
    {children}
  </div>
)

const BuyerDiscoverySurvey = () => {
  const [currentStep, setCurrentStep] = useState(1)
  const [unitTypes, setUnitTypes] = useState(originalUnitTypes)
  const [priceRange, setPriceRange] = useState([1000000, 5000000])
  const [selectedRealtor, setSelectedRealtor] = useState('')
  const [unitTypesContinueButtonDisabled, setUnitTypesContinueButtonDisabled] = useState(true)
  const [showReviewModal, setShowReviewModal] = useState(false)
  const [sendingSurvey, setSendingSurvey] = useState(false)

  useEffect(() => {
    const selectedUnitTypes = unitTypes.filter((unitType) => unitType.selected)

    setUnitTypesContinueButtonDisabled(!selectedUnitTypes.length)
  }, [unitTypes])

  const manageUnitTypes = useCallback((unitType) => {
    let tmpUnitTypes = [...unitTypes]

    tmpUnitTypes = tmpUnitTypes.map((tmpUnitType) => ({ ...tmpUnitType, selected: unitType === tmpUnitType.value ? !tmpUnitType.selected : tmpUnitType.selected }))

    setUnitTypes(tmpUnitTypes)
  }, [unitTypes])

  const BackButton = () => (
    <ChevronLeftIcon
      className={`h-14 w-14 text-gray-800 absolute top-5 left-5 hover:cursor-pointer 
      ${(currentStep === 1 || currentStep === 5 || currentStep === 6) && 'hidden'}`}
      onClick={() => setCurrentStep(currentStep - 1)}
    />
  )

  const sendSurvey = useCallback(() => {
    setSendingSurvey(true)

    const fields = []

    unitTypes
      .filter((unitType) => unitType.selected)
      .forEach((unitType) => fields.push({ name: 'unit_type', value: unitType.value }))

    fields.push({ name: 'min_budget', value: priceRange[0] })
    fields.push({ name: 'max_budget', value: priceRange[1] })
    fields.push({ name: 'broker', value: selectedRealtor._id })

    const params = {
      project: '607605e70de444001a71cfc8',
      fieldType: 'Survey',
      userId: '602ffcf5b10f6a0019d8b9db',
      fields
    }

    userSendSurvey(params)
      .then(() => {
        setSendingSurvey(false)
        setShowReviewModal(false)

        setCurrentStep(5)
      })
      .catch(() => setSendingSurvey(false))
  }, [unitTypes, priceRange, selectedRealtor])

  const renderReviewModal = useCallback(() => (
    <Modal
      showModal={showReviewModal}
      onCancel={() => {
        setShowReviewModal(false)
      }}
      modalType='custom'
      okText='Finish'
      okLoading={sendingSurvey}
      onOk={sendSurvey}
    >
      <div className='flex flex-col'>
        <div className='flex flex-col items-center mb-10'>
          <span style={{ color: '#3f8f9d' }} className='text-3xl'>Survey Review</span>
          <span className='text-xl'>Review the infos below and finish your survey</span>
        </div>

        <ReviewLine title='Chosen Unit Types'>
          {
            unitTypes
              .filter((unitType) => unitType.selected)
              .map(({ title }) => <span>{capitalizeWords(title)}</span>)
          }
        </ReviewLine>

        <ReviewLine title='Prince Range'>
          <div className=''>
            {formatMoney(priceRange[0])}
            <span className='mx-1'>-</span>
            {formatMoney(priceRange[1])}
          </div>
        </ReviewLine>

        <ReviewLine title='Realtor'>
          <span>{`${selectedRealtor.firstName} ${selectedRealtor.lastName}`}</span>
        </ReviewLine>
      </div>
    </Modal>
  ), [showReviewModal, unitTypes, priceRange, selectedRealtor, sendingSurvey, sendSurvey])

  return (
    <SurveyWrapper style={{ background: '#f8f8f9' }} className='h-screen w-screen'>
      {renderReviewModal()}

      <BackButton />
      <AnimatePresence exitBeforeEnter initial={false}>
        { currentStep === 1 && (
          <AnimatedDiv key='welcome'>
            <Welcome onClickContinue={() => setCurrentStep(2)} />
          </AnimatedDiv>
        )}

        { currentStep === 2 && (
          <AnimatedDiv key='unitTypes'>
            <UnitTypes
              onClickContinue={() => setCurrentStep(3)}
              unitTypesContinueButtonDisabled={unitTypesContinueButtonDisabled}
              unitTypes={unitTypes}
              onSelectUnitType={(unitType) => manageUnitTypes(unitType)}
            />
          </AnimatedDiv>
        )}

        { currentStep === 3 && (
          <AnimatedDiv key='priceRange'>
            <PriceRange
              onClickContinue={() => setCurrentStep(4)}
              priceRange={priceRange}
              onChangePriceRange={setPriceRange}
            />
          </AnimatedDiv>
        )}

        { currentStep === 4 && (
          <AnimatedDiv key='realtor'>
            <Realtor
              selectedRealtor={selectedRealtor}
              onSelectRealtor={setSelectedRealtor}
              onClickContinue={() => setShowReviewModal(true)}
            />
          </AnimatedDiv>
        )}

        { currentStep === 5 && (
          <AnimatedDiv key='realtorNotified'>
            <RealtorNotified
              onClickContinue={() => setCurrentStep(6)}
              selectedRealtor={selectedRealtor}
            />
          </AnimatedDiv>
        )}

        { currentStep === 6 && (
          <AnimatedDiv key='finalOptions'>
            <FinalOptions onClickDoLater={() => {
              setUnitTypes(originalUnitTypes)
              setPriceRange([1000000, 5000000])
              setSelectedRealtor('')
              setCurrentStep(1)
            }}/>
          </AnimatedDiv>
        )}
      </AnimatePresence>
    </SurveyWrapper>
  )
}

export default BuyerDiscoverySurvey

AnimatedDiv.propTypes = {
  children: propTypes.node,
  key: propTypes.string
}

ReviewLine.propTypes = {
  title: propTypes.string,
  children: propTypes.node
}
