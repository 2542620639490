/* eslint-disable */
// @ts-nocheck
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import morrisonWalkNorthVancouverBg from '../../assets/img/morrison-walk-north-vancouver.jpg';
import morrisonWalkLogo from '../../assets/img/morrison-walk-logo.jpeg';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { api } from 'services/api';
import { Button, Card } from '@material-ui/core';
import '../../assets/css/ag-theme-balham.css';
import { LottieWrapper } from 'components';
import successAnimation from 'assets/lottieJsons/avesdoLoading.json';
import DashboardTab from './components/DashboardTab';
import InventoryContextProvider from 'components/Inventory/context/InventoryContext';
import { useSelector } from 'react-redux';
import placeHolderBg from '../../assets/img/bg.png';
import PacificaLogo from '../../assets/img/pacifica-logo.png';

const AdminDashboardDeals = () => {
  const gridRef = useRef(null);
  const userObject = useSelector((state) => state.authReducer.userObject);
  const permissionFalse = useMemo(() => {
    return Object.keys(userObject.permissions || {}).filter(
      (key) => !userObject.permissions[key]
    );
  }, [userObject.permissions]);
  const permissionTrue = useMemo(() => {
    return Object.keys(userObject.permissions || {}).filter(
      (key) => userObject.permissions[key]
    );
  }, [userObject.permissions]);

  const [projectLogo, setProjectLogo] = useState(null);
  const appReducer = useSelector((state) => state.appReducer);
  const [projectName, setProjectName] = useState('');
  const [projectBackgroundImage, setProjectBackgroundImage] = useState('');

  useEffect(() => {
    const list = appReducer?.listOfProjects;
    const selectedProjectId = appReducer?.appProject;

    if (!list || !selectedProjectId) return;

    const activeProject = list.find(
      (project) => project._id === selectedProjectId
    );
    setProjectName(activeProject?.projectName);
    setProjectBackgroundImage(activeProject?.backgroundImage);
    setProjectLogo(activeProject?.logoImage);
  }, [appReducer]);

  const oldTabMenus = [
    {
      id: 'tab-7',
      name: 'Dashboard',
      permission: 'Dashboard Tab',
    },
    {
      id: 'tab-1',
      name: 'Deals',
      permission: 'Deals',
    },
    {
      id: 'tab-6',
      name: 'Deposits',
      permission: 'Deposits',
    },
    {
      id: 'tab-2',
      name: 'Parking',
      permission: 'Parking Avesdo',
    },
    {
      id: 'tab-3',
      name: 'Parking Waitlist',
      permission: 'Parking Waitlist',
    },
    {
      id: 'tab-4',
      name: 'Storage',
      permission: 'Storage',
    },
    {
      id: 'tab-5',
      name: 'Storage Waitlist',
      permission: 'Storage Waitlist',
    },
  ];
  const [tabMenus, setFilteredTabMenus] = useState([]);
  const [selectedTab, setSelectedTab] = useState(undefined);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const filteredFalse = oldTabMenus.filter((tab) => {
      const tabNameLower = tab.permission.toLowerCase();
      return !permissionFalse.some(
        (perm) => perm.toLowerCase() === tabNameLower
      );
    });

    const filteredTrue = oldTabMenus.filter((tab) => {
      const tabNameLower = tab.permission.toLowerCase();
      return permissionTrue.some((perm) => perm.toLowerCase() === tabNameLower);
    });

    if (filteredTrue.length === 0 && filteredFalse.length === 0) {
      setFilteredTabMenus(filteredFalse);
    }
    if (filteredTrue.length > 0) {
      setFilteredTabMenus(filteredTrue);
      if (filteredTrue.length > 0) {
        setSelectedTab(filteredTrue[0].id);
      } else {
        setSelectedTab(undefined);
      }
    } else {
      if (filteredFalse.length > 0) {
        setFilteredTabMenus(filteredFalse);
      }
      if (filteredFalse.length > 0) {
        setSelectedTab(filteredFalse[0].id);
      } else {
        setSelectedTab(undefined);
      }
    }

    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [permissionFalse, permissionTrue]);

  const backgroundColor = ['#59b099', 'red', 'yellow', 'black'];
  // const tabMenus = [
  //   {
  //     id: 'tab-7',
  //     name: 'Dashboard',
  //   },
  //   {
  //     id: 'tab-1',
  //     name: 'Deals',
  //   },
  //   {
  //     id: 'tab-6',
  //     name: 'Deposits',
  //   },
  //   {
  //     id: 'tab-2',
  //     name: 'Parking',
  //   },
  //   {
  //     id: 'tab-3',
  //     name: 'Parking Waitlist',
  //   },
  //   {
  //     id: 'tab-4',
  //     name: 'Storage',
  //   },
  //   {
  //     id: 'tab-5',
  //     name: 'Storage Waitlist',
  //   },
  // ];
  const [parkingTypeData, setParkingTypeData] = useState([{}]);
  const [storageTypeData, setStorageTypeData] = useState([{}]);
  const [totalParkings, setTotalParkings] = useState(0);
  const [totalStorages, setTotalStorages] = useState(0);
  const [stallSoldCount, setStallSoldCount] = useState(0);
  const [parkingTotalPrice, setParkingTotalPrice] = useState(0);
  const dateFormate = (date) => {
    if (date == null) {
      return undefined;
    } else {
      const d = new Date(date);
      const day = d.getDate() < 10 ? '0' + d.getDate() : d.getDate();
      const month =
        d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1;
      const year = d.getFullYear();
      const hours = d.getHours();
      const minutes = d.getMinutes();
      const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}`;
      return formattedDate;
    }
  };

  const onBtShowNoRows = useCallback(() => {
    gridRef.current.api.showNoRowsOverlay();
  }, []);

  const onBtHide = useCallback(() => {
    gridRef.current.api.hideOverlay();
  }, []);

  const onBtShowLoading = useCallback(() => {
    gridRef.current.api.showLoadingOverlay();
  }, []);

  const columnDefs = [
    { headerName: 'Unit', field: 'unitNumber', width: 120, resizable: true },
    { headerName: 'Buyer Name', field: 'buyer', width: 150, resizable: true },
    { headerName: 'Status', field: 'status', width: 140, resizable: true },
    {
      headerName: 'Created Date',
      field: 'createdDate',
      width: 150,
      resizable: true,
      valueFormatter: (p) => dateFormate(p.value),
      // filter: 'agDateColumnFilter',
      // filterParams: filterParams,
    },
    {
      headerName: 'Accepted Date',
      field: 'acceptedDate',
      valueFormatter: (p) => dateFormate(p.value),
      width: 180,
      resizable: true,
    },
    {
      headerName: 'Rescission Expire Date',
      field: 'rescissionExpireDate',
      width: 230,
      resizable: true,
      valueFormatter: (p) => dateFormate(p.value),
    },
    {
      headerName: 'Sold Price',
      field: 'soldPrice',
      valueFormatter: (p) => currencyFormate(p.value),
      width: 150,
      resizable: true,
    },
    {
      headerName: 'Included Parking',
      field: 'parkingIncludedBoolean',
      width: 200,
      resizable: true,
    },
    {
      headerName: 'Additional Parking',
      field: 'parkingAdditionalBoolean',
      width: 200,
      resizable: true,
    },
    {
      headerName: 'Additional Parking Price',
      field: 'parkingAdditionalPrice',
      width: 230,
      resizable: true,
    },
    {
      headerName: 'Included Storage',
      field: 'storageIncludedBoolean',
      width: 200,
      resizable: true,
    },
    {
      headerName: 'Additional Storage',
      field: 'storageAdditionalBoolean',
      width: 200,
      resizable: true,
    },
    {
      headerName: 'Additional StoragePrice',
      field: 'storageAdditionalPrice',
      width: 230,
      resizable: true,
    },
  ];

  const columnDefsDeposits = [
    {
      headerName: 'Unit',
      field: 'unitNumber',
      width: 150,
      resizable: true,
    },
    {
      headerName: 'Buyer',
      field: 'buyer',
      width: 200,
      resizable: true,
    },
    {
      headerName: 'Deposit',
      field: 'depositName',
      width: 150,
      resizable: true,
    },
    {
      headerName: 'Created Date',
      field: 'createdDate',
      width: 200,
      resizable: true,
      valueFormatter: (p) => dateFormate(p.value),
    },
    {
      headerName: 'Due Date',
      field: 'dueDate',
      width: 200,
      resizable: true,
      valueFormatter: (p) => dateFormate(p.value),
    },
    {
      headerName: 'Deposit Types',
      field: 'depositType',
      width: 180,
      resizable: true,
    },
    {
      headerName: 'Deposit Order',
      field: 'depositOrder',
      width: 180,
      resizable: true,
    },
    {
      headerName: 'Amount',
      field: 'amount',
      width: 150,
      resizable: true,
      flex: 1,
      valueFormatter: (p) => currencyFormate(p.value),
    },
    {
      headerName: 'Received',
      field: 'isRecivedFormated',
      width: 150,
      resizable: true,
      flex: 1,
    },
  ];
  const columnDefsParkings = [
    {
      headerName: 'Unit',
      field: 'unitNumber',
      width: 150,
      resizable: true,
    },
    {
      headerName: 'Buyer',
      field: 'buyer',
      width: 200,
      resizable: true,
    },
    {
      headerName: 'Price',
      field: 'price',
      width: 150,
      resizable: true,
      valueFormatter: (p) => currencyFormate(p.value),
    },
    {
      headerName: 'Option Name',
      field: 'optionName',
      width: 150,
      resizable: true,
    },
    {
      headerName: 'Label',
      field: 'label',
      width: 200,
      flex: 1,
      resizable: true,
    },
    {
      headerName: 'Created Date',
      field: 'createdDate',
      width: 200,
      resizable: true,
      valueFormatter: (p) => dateFormate(p.value),
    },
    {
      headerName: 'Modified Date',
      field: 'lastModified',
      width: 200,
      resizable: true,
      valueFormatter: (p) => dateFormate(p.value),
    },
  ];
  const columnDefsStorages = [
    {
      headerName: 'Unit',
      field: 'unitNumber',
      width: 150,
      resizable: true,
    },
    {
      headerName: 'Buyer',
      field: 'buyer',
      width: 200,
      resizable: true,
    },
    {
      headerName: 'Price',
      field: 'price',
      width: 150,
      resizable: true,
      valueFormatter: (p) => currencyFormate(p.value),
    },
    {
      headerName: 'Option Name',
      field: 'optionName',
      width: 150,
      resizable: true,
    },
    {
      headerName: 'Label',
      field: 'label',
      width: 200,
      flex: 1,
      resizable: true,
    },
    {
      headerName: 'Created Date',
      field: 'createdDate',
      width: 200,
      resizable: true,
      valueFormatter: (p) => dateFormate(p.value),
    },
    {
      headerName: 'Modified Date',
      field: 'lastModified',
      width: 200,
      resizable: true,
      valueFormatter: (p) => dateFormate(p.value),
    },
  ];
  const buttonStyle = {
    backgroundColor: '#EDDF65',
    height: '45px',
    paddingLeft: '24px',
    paddingRight: '24px',
  };

  const [dealData, setDealData] = useState(null);
  const [dealDepositData, setDealDepositData] = useState(null);
  const [dealParkingData, setDealParkingData] = useState(null);
  const [dealStorageData, setDealStorageData] = useState(null);
  // const [dealLabelGroups, setLabelGroups] = useState(null)
  const [dealLabelName, setLabelName] = useState([]);

  const style = {
    height: '680px',
    width: '100%',
  };
  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      filter: true,
      floatingFilter: true,
      resizable: true,
    };
  }, []);

  const loadingOverlayComponent = useMemo(() => {
    return CustomLoadingCellRenderer;
  }, []);
  const handleRefresh = async () => {
    onBtShowLoading();
    const { data } = await api.post('/deals', {}, { responseType: 'json' });
    onBtHide();
    setSelectedTab('tab-1');
    setDealData(data);
  };
  const handleDealData = async (state) => {
    if (state === 'tab-1') {
      onBtShowLoading();
      const { data } = await api.get('/deals', {}, { responseType: 'json' });
      if (data.data.length === 0) {
        setDealData([]);
        onBtShowNoRows();
      } else {
        setDealData(data.data);
        onBtHide();
      }
    } else if (state === 'tab-2') {
      onBtShowLoading();
      fetchDataByGroupParking();
      const { data } = await api.get('/deals', {}, { responseType: 'json' });
      const filteredDeals = data.data.filter(
        (deal) =>
          deal.options.some((option) =>
            option.label.toLowerCase().includes('parking')
          ) && deal.formatedAcceptedDate !== ''
      );
      let allParkings = [];
      filteredDeals.forEach((deal) => {
        const { options } = deal;
        const modifiedParkings = options.map((option, index) => ({
          ...option,
          buyer: deal.buyer,
          unitNumber: deal.unitNumber,
          optionName: `Option ${index + 1}`,
          label: option.label,
        }));
        allParkings = allParkings.concat(modifiedParkings);
      });

      if (allParkings.length === 0) {
        setDealParkingData([]);
        onBtShowNoRows();
      } else {
        setDealParkingData(allParkings);
        onBtHide();
      }
    } else if (state === 'tab-3') {
      onBtShowLoading();
      const { data } = await api.get('/deals', {}, { responseType: 'json' });
      const filteredDeals = data.data.filter(
        (deal) =>
          deal.options.some((option) => option.header === 'Parking Waitlist') &&
          deal.formatedAcceptedDate !== ''
      );
      if (filteredDeals.length === 0) {
        setDealData([]);
        onBtShowNoRows();
      } else {
        setDealData(filteredDeals);
        onBtHide();
        return filteredDeals;
      }
    } else if (state === 'tab-4') {
      onBtShowLoading();
      fetchDataByGroupStorage();
      const { data } = await api.get('/deals', {}, { responseType: 'json' });
      const filteredDeals = data.data.filter(
        (deal) =>
          deal.options.some((option) =>
            option.label.toLowerCase().includes('locker')
          ) && deal.formatedAcceptedDate !== ''
      );
      let allStorages = [];
      filteredDeals.forEach((deal) => {
        const { options } = deal;
        const modifiedStorages = options
          .filter((option) => option.label.toLowerCase().includes('locker'))
          .map((option, index) => ({
            ...option,
            buyer: deal.buyer,
            unitNumber: deal.unitNumber,
            optionName: `Option ${index + 1}`,
            label: option.label,
          }));
        allStorages = allStorages.concat(modifiedStorages);
      });
      if (allStorages.length === 0) {
        setDealStorageData([]);
        onBtShowNoRows();
      } else {
        setDealStorageData(allStorages);
        onBtHide();
      }
    } else if (state === 'tab-5') {
      onBtShowLoading();
      const { data } = await api.get('/deals', {}, { responseType: 'json' });
      const filteredDeals = data.data.filter(
        (deal) =>
          deal.options.some((option) => option.header === 'Lockers Waitlist') &&
          deal.formatedAcceptedDate !== ''
      );
      if (filteredDeals.length === 0) {
        setDealData([]);
        onBtShowNoRows();
      } else {
        setDealData(filteredDeals);
        onBtHide();
        return filteredDeals;
      }
    } else if (state === 'tab-6') {
      onBtShowLoading();
      const { data } = await api.get('/deals', {}, { responseType: 'json' });
      let allDeposits = [];
      data.data.forEach((deal) => {
        const { deposits } = deal;
        const modifiedDeposits = deposits.map((deposit, index) => ({
          ...deposit,
          buyer: deal.buyer,
          unitNumber: deal.unitNumber,
          depositName: `Deposit${index + 1}`,
          isRecivedFormated: deposit.isReceived ? 'Yes' : 'No', // Add your custom field to the deposit object
        }));
        allDeposits = allDeposits.concat(modifiedDeposits);
      });
      setDealDepositData(allDeposits);
      onBtHide();
    } else if (state === 'tab-7') {
      let allParkings = [];
      const { data } = await api.get('/deals', {}, { responseType: 'json' });
      const filteredDeals = data.data.filter(
        (deal) =>
          deal.options.some((option) =>
            option.label.toLowerCase().includes('parking')
          ) && deal.formatedAcceptedDate !== ''
      );
      filteredDeals.forEach((deal) => {
        const { options } = deal;
        const modifiedParkings = options.map((option, index) => ({
          ...option,
          buyer: deal.buyer,
          unitNumber: deal.unitNumber,
          optionName: `Option ${index + 1}`,
          label: option.label,
        }));
        allParkings = allParkings.concat(modifiedParkings);
      });
      const countPrice = allParkings.filter((parking) => parking.price > 19000);
      const totalPrice = countPrice.reduce(
        (total, parking) => total + parking.price,
        0
      );
      setParkingTotalPrice(totalPrice);
    }
  };

  const fetchDataByGroupParking = async () => {
    const { data } = await api.get('/deals', {}, { responseType: 'json' });
    const filteredDeals = data.data.filter(
      (deal) => deal.formatedAcceptedDate !== ''
    );
    let allGroups = [];
    filteredDeals.forEach((deal) => {
      const { options } = deal;
      const modifiedGroups = options
        .filter(
          (option) =>
            option.label.toLowerCase().includes('parking') &&
            option.label !==
              'One EV Ready Parking Stall (included, not a second stall) w/ connected 83 Sq.Ft. Storage Vault.'
        )
        .map((option, index) => ({
          label: option.label,
          price: option.price,
          index: index,
        }));

      allGroups = allGroups.concat(modifiedGroups);
    });
    const labelData = allGroups.reduce((group, obj) => {
      if (!group[obj.label]) {
        group[obj.label] = { total: obj.price, count: 1 };
      } else {
        group[obj.label].total += obj.price;
        group[obj.label].count++;
      }
      return group;
    }, []);
    const uniqueLabels = Object.keys(labelData).map((label) => ({
      label,
      amount: labelData[label].total,
      count: labelData[label].count,
    }));
    // setLabelGroups(allGroups)
    setLabelName(uniqueLabels);
  };

  const fetchDataByGroupStorage = async () => {
    const { data } = await api.get('/deals', {}, { responseType: 'json' });
    const filteredDeals = data.data.filter(
      (deal) => deal.formatedAcceptedDate !== ''
    );
    let allGroups = [];
    filteredDeals.forEach((deal) => {
      const { options } = deal;
      const modifiedGroups = options
        .filter((option) => option.label.toLowerCase().includes('locker'))
        .map((option, index) => ({
          label: option.label,
          price: option.price,
          index: index,
        }));
      allGroups = allGroups.concat(modifiedGroups);
    });
    const labelData = allGroups.reduce((group, obj) => {
      if (!group[obj.label]) {
        group[obj.label] = { total: obj.price, count: 1 };
      } else {
        group[obj.label].total += obj.price;
        group[obj.label].count++;
      }
      return group;
    }, []);

    const uniqueLabels = Object.keys(labelData).map((label) => ({
      label,
      amount: labelData[label].total,
      count: labelData[label].count,
    }));
    setLabelName(uniqueLabels);
  };

  const currencyFormate = (value) => {
    if (value == null) return undefined;
    else {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });

      const formattedValue = formatter.format(value);
      return formattedValue;
    }
  };

  const getInitialData = async () => {
    const { data } = await api.get('/deals', {}, { responseType: 'json' });
    if (data.data.length === 0) {
      setDealData([]);
      onBtShowNoRows();
    } else {
      setDealData(data.data);
      onBtHide();
      return data.data;
    }
  };

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    const parkingTypeArray = async () => {
      const deals = await api.get('/deals', {}, { responseType: 'json' });
      const filteredDeals = deals.data.data.filter(
        (deal) => deal.formatedAcceptedDate !== ''
      );
      let allGroups = [];
      filteredDeals.forEach((deal) => {
        const { options } = deal;
        const modifiedGroups = options
          .filter((option) => option.label.toLowerCase().includes('parking'))
          .map((option, index) => ({
            label: option.label,
            price: option.price,
            index: index,
          }));

        allGroups = allGroups.concat(modifiedGroups);
      });
      const { data } = await api.get('/parking', {}, { responseType: 'json' });
      setTotalParkings(data.length);
      const parkingStatus = [
        {
          name: 'Available',
          value: Number(data.length) - Number(allGroups.length),
        },
        { name: 'Sold', value: Number(allGroups.length) },
        { name: 'Allocated', value: 0 },
        { name: 'Assigend', value: 0 },
      ];
      const parkingData = parkingStatus.map((parking, index) => {
        const color = backgroundColor[index % backgroundColor.length];
        return { name: parking.name, value: parking.value, color: color };
      });
      setParkingTypeData(parkingData);
    };
    parkingTypeArray();
  }, []);

  useEffect(() => {
    const storageTypeArray = async () => {
      const deals = await api.get('/deals', {}, { responseType: 'json' });
      const filteredDeals = deals.data.data.filter(
        (deal) => deal.formatedAcceptedDate !== ''
      );
      let allGroups = [];
      filteredDeals.forEach((deal) => {
        const { options } = deal;
        const modifiedGroups = options
          .filter((option) => option.label.toLowerCase().includes('locker'))
          .map((option, index) => ({
            label: option.label,
            price: option.price,
            index: index,
          }));

        allGroups = allGroups.concat(modifiedGroups);
      });
      const { data } = await api.get('/storage', {}, { responseType: 'json' });
      if(data.totalRecords != 0){
        setTotalStorages(data.length);
      }
      const storageStatus = [
        {
          name: 'Available',
          value: Number(data.length || 0) - Number(allGroups.length),
        },
        { name: 'Sold', value: Number(allGroups.length) },
        { name: 'Allocated', value: 0 },
        { name: 'Assigend', value: 0 },
      ];
      const storageData = storageStatus.map((storage, index) => {
        const color = backgroundColor[index % backgroundColor.length];
        return { name: storage.name, value: storage.value, color: color };
      });
      setStorageTypeData(storageData);
    };
    storageTypeArray();
  }, []);

  return (
    <div className='relative w-full p-12 pt-14'>
      <div
        className='absolute z-[-1] w-full h-[70vh] top-0 left-0 bg-cover bg-no-repeat'
        style={{ backgroundImage: `url(${
          projectBackgroundImage?.length > 0
            ? projectBackgroundImage
            : placeHolderBg
      })`, }}
      />
      <img
        className='mb-4 w-[220px]'
        src={projectLogo ?? PacificaLogo}
        alt='morrison-walk-north-vancouver'
      />
      {loading ? (
        <div></div>
      ) : (
        <>
          {selectedTab == 'tab-2' && (
            <div className=' justify-center w-full md:justify-end flex items-end'>
              <div className='flex flex-col md:flex-row gap-y-2 md:gap-y-0 w-full py-2 items-center px-4 md:w-fit flex-wrap   bg-white rounded-[8px] gap-x-4  md:gap-x-8'>
                <div className='flex flex-row md:flex-col gap-x-3 md:gap-x-0'>
                  <div className='text-lg font-bold'>Total Parking</div>
                  <div className='text-2xl font-bold ml-2'>{totalParkings}</div>
                </div>
                {parkingTypeData.map((parking, index) => (
                  <>
                    <div className='flex flex-row md:flex-col gap-x-4'>
                      <div className='flex items-center gap-x-2'>
                        <div
                          style={{
                            backgroundColor: parking.color,
                          }}
                          className={`rounded-full h-3 w-3`}
                        ></div>
                        <div className='text-sm col-span-2'>{parking.name}</div>
                      </div>
                      <div className='text-xl col-span-2 font-bold'>
                        {parking.value}
                      </div>
                    </div>
                    {parkingTypeData.length - 1 != index && (
                      <div className='w-0 md:w-[2px] h-0 md:h-[40px]  bg-[rgba(0,0,0,0.4)]'></div>
                    )}
                    {parkingTypeData.length - 1 != index && (
                      <div className='w-full h-[2px] block md:hidden bg-[rgba(0,0,0,0.4)]'></div>
                    )}
                  </>
                ))}
              </div>
            </div>
          )}
          {selectedTab == 'tab-4' && (
            <div className=' justify-center w-full md:justify-end flex items-end'>
              <div className='flex flex-col md:flex-row gap-y-2 md:gap-y-0 w-full py-2 items-center px-4 md:w-fit flex-wrap   bg-white rounded-[8px] gap-x-4  md:gap-x-8'>
                <div className='flex flex-row md:flex-col gap-x-3 md:gap-x-0'>
                  <div className='text-lg font-bold'>Total Storages</div>
                  <div className='text-2xl font-bold ml-2'>{totalStorages}</div>
                </div>
                {storageTypeData.map((storage, index) => (
                  <>
                    <div className='flex flex-row md:flex-col gap-x-4'>
                      <div className='flex items-center gap-x-2'>
                        <div
                          style={{
                            backgroundColor: storage.color,
                          }}
                          className={`rounded-full h-3 w-3`}
                        ></div>
                        <div className='text-sm col-span-2'>{storage.name}</div>
                      </div>
                      <div className='text-xl col-span-2 font-bold'>
                        {storage.value}
                      </div>
                    </div>
                    {storageTypeData.length - 1 != index && (
                      <div className='w-0 md:w-[2px] h-0 md:h-[40px]  bg-[rgba(0,0,0,0.4)]'></div>
                    )}
                    {parkingTypeData.length - 1 != index && (
                      <div className='w-full h-[2px] block md:hidden bg-[rgba(0,0,0,0.4)]'></div>
                    )}
                  </>
                ))}
              </div>
            </div>
          )}
          <div className='pt-8 w-full gap-y-4  flex flex-col md:flex-row items-center gap-x-4 justify-center md:justify-between'>
            <Button
              className='flex items-start self-start'
              onClick={handleRefresh}
              style={buttonStyle}
            >
              Refresh
            </Button>
            <div className=' md:w-fit items-center justify-center text-center flex flex-wrap gap-y-1 md:gap-x-2 p-1 rounded-md bg-white'>
              {tabMenus.map((tab, index) => (
                <div
                  key={index}
                  onClick={() => {
                    setSelectedTab(tab.id);
                    handleDealData(tab.id);
                  }}
                  className={`flex w-full md:w-fit text-center justify-center items-center just px-14 py-1 cursor-pointer rounded-[4px] font-semibold text-xs ${
                    selectedTab === tab.id
                      ? 'bg-softBlack text-white shadow-sm'
                      : 'text-zinc-500'
                  } transition-all`}
                >
                  {tab.name}
                </div>
              ))}
            </div>
          </div>
          <div className='w-full bg-white h-full mt-8 rounded-[8px]'>
            {(selectedTab === 'tab-2' || selectedTab === 'tab-4') && (
              <table className='w-full '>
                <thead className='border-b-[1px] divide-gray-300 bg-white'>
                  <tr>
                    {selectedTab === 'tab-2' && (
                      <th className='text-start pl-10'>Parking</th>
                    )}
                    {selectedTab === 'tab-4' && (
                      <th className='text-start pl-10'>Storage</th>
                    )}
                    <th className='text-start'>Count</th>
                    <th className='text-start'>Amount</th>
                  </tr>
                </thead>
                <tbody className='divide-y divide-gray-200 bg-white'>
                  {dealLabelName.map((label, index) => {
                    return (
                      <tr key={index} className=''>
                        <td className='pl-10 max-w-md'>{label.label}</td>
                        <td>{label.count}</td>
                        <td>{currencyFormate(label.amount)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
          <div style={style} className='w-full h-[100%] ag-theme-alpine  pt-8'>
            {selectedTab === 'tab-6' && (
              <AgGridReact
                ref={gridRef}
                pagination={true}
                paginationPageSize={25}
                columnDefs={columnDefsDeposits}
                loadingOverlayComponent={loadingOverlayComponent}
                rowData={dealDepositData}
                rowHeight={50}
                paginateChildRows
                defaultColDef={defaultColDef}
              />
            )}
            {selectedTab === 'tab-2' && (
              <AgGridReact
                ref={gridRef}
                pagination={true}
                paginationPageSize={25}
                columnDefs={columnDefsParkings}
                loadingOverlayComponent={loadingOverlayComponent}
                rowData={dealParkingData}
                rowHeight={50}
                paginateChildRows
                defaultColDef={defaultColDef}
              />
            )}
            {selectedTab === 'tab-4' && (
              <AgGridReact
                ref={gridRef}
                pagination={true}
                paginationPageSize={25}
                columnDefs={columnDefsStorages}
                loadingOverlayComponent={loadingOverlayComponent}
                rowData={dealStorageData}
                rowHeight={50}
                paginateChildRows
                defaultColDef={defaultColDef}
              />
            )}
            {(selectedTab === 'tab-1' ||
              selectedTab === 'tab-3' ||
              selectedTab === 'tab-5') && (
              <AgGridReact
                ref={gridRef}
                pagination={true}
                paginationPageSize={25}
                columnDefs={columnDefs}
                loadingOverlayComponent={loadingOverlayComponent}
                rowData={dealData}
                rowHeight={50}
                paginateChildRows
                defaultColDef={defaultColDef}
              />
            )}
            {selectedTab == 'tab-7' && (
              <InventoryContextProvider>
                <DashboardTab totalSales={parkingTotalPrice} />
              </InventoryContextProvider>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default AdminDashboardDeals;

const CustomLoadingCellRenderer = (props) => {
  const [messages, setMessages] = useState([
    'Connecting to Avesdo',
    'Connected',
    'Data Updated',
  ]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fade, setFade] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(true);
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % messages.length);
        setFade(false);
      }, 500);
    }, 2000);

    return () => clearInterval(interval);
  }, [messages]);

  useEffect(() => {
    setMessages((prevMessages) => [...prevMessages]);
  }, []);
  return (
    <div role='presentation'>
      <div
        role='presentation'
        style={{
          height: 100,
          width: 100,
          margin: '0 auto',
        }}
      >
        <LottieWrapper
          animation={successAnimation}
          autoplay
          loop={true}
          className='-mt-40 md:mt-0 max-w-sm'
        />
        <h1 className={fade ? 'fade mt-4 text-[16px]' : 'mt-4 text-[16px]'}>
          {messages[currentIndex]}
        </h1>
      </div>
    </div>
  );
};
