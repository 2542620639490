import React from 'react'
import { TableTitle } from 'pages/desktopMode/AdminReports/components/AllAgentsSalesReport'

const GraphDataWrapper = ({
  children,
  isStatic = false,
  title = '',
  date = '',
  showBorder = true
}) => {
  return (
    <div className='flex flex-col p-4 bg-[#fcfcfa] gap-4 my-2 gap-y-2'>
      <TableTitle title={title} date={date} isStatic={isStatic} />
      {showBorder && (
        <div className='flex border-b-2 border-black items-center justify-center content-center' />
      )}
      {children}
    </div>
  )
}

export default GraphDataWrapper
