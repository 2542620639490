import { useState, useCallback } from 'react'
import { deleteReservation } from 'store/actions/reservationActions'

const useDeleteReservationsById = () => {
  const [loading, setLoading] = useState(false)
  const deleteAPI = useCallback(async (reservationId) => {
    try {
      setLoading(true)
      const response = await deleteReservation(reservationId)
      setLoading(false)
      return response
    } catch (e) {
      setLoading(false)
      console.log('Failed to delete reservation', e)
      return null
    }
  }, [])

  return { deleteAPI, loading }
}

export default useDeleteReservationsById
