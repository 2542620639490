// @ts-nocheck
/* eslint-disable */
import React, { useState, useEffect, useCallback } from 'react'
import propTypes from 'prop-types'
import Skeleton from 'react-loading-skeleton'
import SelectButtonWrapper from './SelectWrapper'
// import { ADMIN_OFFER } from 'routes'

// import { useHistory } from 'react-router-dom'
import { SelectMultiple } from 'components'

import { isMobile } from 'react-device-detect'
// import useRealtimeUnitUpdate from 'hooks/useRealtimeUnitUpdate'
import { useSelector } from 'react-redux'
import StorageCardView from './StorageCardView'
import StorageInfoDrawer from 'components/StorageInventoryMap/components/StorageInfoDrawer'
import { classNames } from 'utils'
// import LargeParkingCardModal from 'components/LargeParkingCardModal'
// import AddStorageModal from 'pages/desktopMode/buildings/components/AddStorageModal'

const STATUS_OPTIONS = [
  { label: 'Open', value: 'Open' },
  { label: 'Reserved', value: 'reserved' },
  { label: 'Sold', value: 'sold' },
  { label: 'Sold Committed', value: 'SoldCommitted' },
  { label: 'Reserved Broker', value: 'ReservedBroker' }
]

const CardView = ({
  storages,
  loading,
  buildings,
  getBuildings,
  refetchStorages
}) => {
  const projectId = useSelector((state) => state.appReducer.appProject)
  // useRealtimeUnitUpdate(projectId)
  // const history = useHistory()
  const [cards, setCards] = useState([...storages])
  const [availability, setAvailability] = useState([])
  const [building, setBuilding] = useState([])
  const [search, setSearch] = useState('')
  // const [openModal, setOpenModal] = useState(false)
  // const [tempCardToOpen, setTempCardToOpen] = useState(null)
  // const [showStorageEditModal, setShowStorageEditModal] = useState(false)
  const [isStorageInfoDrawerOpen, setIsStorageInfoDrawerOpen] = useState(false)
  const [editDetails, setEditDetails] = useState({})

  useEffect(() => {
    if (storages) {
      handleCards()
    }
  }, [storages, availability, building, search])

  const handleCards = () => {
    let uCards = [...storages]
    if (availability?.length > 0) {
      uCards = uCards.filter((_uCard) => availability.includes(_uCard.status))
    }
    if (building.length > 0) {
      uCards = uCards.filter((_uCard) =>
        building.includes(_uCard?.building._id)
      )
    }
    if (search.length > 0) {
      const uSearch = search.toLowerCase()
      uCards = uCards.filter((_uCard) =>
        _uCard?.unitNumber.toLowerCase().includes(uSearch)
      )
    }
    setCards([...uCards])
  }

  const updateStorage = useCallback(
    (newStorageData) => {
      const index = cards.findIndex((u) => u._id === newStorageData._id)
      const newCards = [...cards]
      newCards[index] = newStorageData
      setCards(newCards)
    },
    [cards]
  )

  return (
    <>
      <div className='flex w-full h-full'>
        <div
          className={classNames(
            isStorageInfoDrawerOpen ? 'mr-[300px]' : 'mr-0',
            'relative w-full transition-all'
          )}
        >
          <div
            className={`grid ${isMobile ? 'grid-cols-1' : 'grid-cols-3 h-11'
              } gap-7 justify-around content-center items-center mt-8 mb-12`}
          >
            <SelectButtonWrapper>
              <div className='flex flex-row text-softBlack_70 w-full items-center'>
                <div className='w-5 h-5'>
                  <svg
                    width='22'
                    height='22'
                    viewBox='0 0 22 22'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M9.96875 17.1875C13.9556 17.1875 17.1875 13.9556 17.1875 9.96875C17.1875 5.98194 13.9556 2.75 9.96875 2.75C5.98194 2.75 2.75 5.98194 2.75 9.96875C2.75 13.9556 5.98194 17.1875 9.96875 17.1875Z'
                      stroke='#2E2B2E'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M15.0742 15.0732L19.2508 19.2499'
                      stroke='#2E2B2E'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </div>
                <input
                  type='text'
                  placeholder='Search Storage'
                  className='border-0 ring-0 rounded-none focus:ring-0 focus:border-0 bg-transparent'
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value)
                  }}
                />
              </div>
            </SelectButtonWrapper>
            <SelectButtonWrapper>
              <SelectMultiple
                buttonLabel='Building'
                onChange={(newValue) => setBuilding(newValue)}
                options={buildings?.map((building) => ({
                  value: building?._id,
                  label: building?.name
                }))}
                multiple={false}
              />
            </SelectButtonWrapper>
            <SelectButtonWrapper>
              <SelectMultiple
                buttonLabel='Availability Status'
                onChange={(newValue) => setAvailability(newValue)}
                options={STATUS_OPTIONS}
              />
            </SelectButtonWrapper>
          </div>
          {loading && (
            <div className='grid grid-cols-3 gap-x-7 gap-y-8 items-stretch pb-8'>
              <Skeleton height={250} />
              <Skeleton height={250} />
              <Skeleton height={250} />
            </div>
          )}
          <div
            className={` ${isMobile
              ? 'grid grid-cols-1'
              : 'flex flex-wrap flex-row justify-between content-center'
              // : 'grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 4xl:grid-cols-7'
              } gap-x-7 gap-y-8 pb-8`}
          >
            {cards?.map((card, index) => (
              <div
                key={index}
                className='cursor-pointer max-w-96 w-96'
                onClick={() => {
                  setEditDetails(card)
                  setIsStorageInfoDrawerOpen(true)
                }}
              >
                <StorageCardView
                  {...{
                    ...card,
                    address: card.building?.name ?? undefined,
                    totalValue: card?.price ?? undefined,
                    tag: card?.status,
                    name: card?.unitNumber
                  }}
                  onUpdate={updateStorage}
                  key={index}
                />
              </div>
            ))}
          </div>
          {!loading && cards.length === 0 && (
            <div className='text-center font-bold text-2xl'>No Result</div>
          )}
        </div>
        <StorageInfoDrawer
          editDetails={editDetails}
          closeStorageInfoDrawer={() => setIsStorageInfoDrawerOpen(false)}
          isStorageInfoDrawerOpen={isStorageInfoDrawerOpen}
          isAbleToPurchase
        />
      </div>
      {/* <LargeParkingCardModal
        {...{
          openModal,
          setOpenModal,
          tempCardToOpen,
          onReserveClicked: () => {
            setOpenModal(false)
            setShowStorageEditModal(true)
          },
          onPurchaseClicked: () => {
            history.push({
              pathname: ADMIN_OFFER
            })
          }
        }}
      />
      {showStorageEditModal && (
        <AddStorageModal
          show={showStorageEditModal}
          setShow={setShowStorageEditModal}
          editDetails={{ ...tempCardToOpen, type: 'Storage' }}
          setEditDetails={() => { }}
          refetch={() => {
            refetchStorages?.()
          }}
        />
      )} */}
    </>
  )
}

export default CardView

CardView.propTypes = {
  appProject: propTypes.string,
  userObject: propTypes.shape({
    userType: propTypes.string
  })
}
