/* eslint-disable no-unused-vars */
import React, { useCallback } from 'react'
import propTypes from 'prop-types'
import { AdminPageWrapper, PageHeader, ProjectReservations } from 'components'
import { TFilledPlusIcon } from 'components/Icons'
import { useHistory } from 'react-router-dom'
import { ADMIN_RESERVATION_NEW } from 'routes'
import { connect } from 'react-redux'

import { isMobile } from 'react-device-detect'
import CustomButton from 'components/CustomButton'

const Button = (props) => {
  return (
    <button
      className='text-softBlack text-center text-base font-medium py-1.5 flex items-center'
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  )
}

const Reservations = (props) => {
  const { appProject } = props
  const history = useHistory()

  const createReservation = useCallback(() => {
    history.push({
      pathname: ADMIN_RESERVATION_NEW,
      state: {
        projectId: appProject
      }
    })
  }, [appProject, history])

  const RightContent = () => (
    <div className='flex flex-row items-center'>
      <CustomButton variant='ghost' className='hover:bg-zinc-300' handleClick={() => createReservation()} disabled={!appProject}>
        <span className='font-medium py-2 px-4 text-base flex gap-1 items-center'>
          <TFilledPlusIcon className='h-6 w-6' />
          {isMobile ? 'Create' : 'Create Reservation'}
        </span>
      </CustomButton>
    </div>
  )

  return (
    <>
      <PageHeader showImage={false} title='Reservations' rightContent={<RightContent />} />
      <AdminPageWrapper style={{ height: 'calc(100vh - 100px)' }} fullscreen>
        <ProjectReservations />
      </AdminPageWrapper>
    </>
  )
}

const mapStateToProps = (state) => ({
  appProject: state.appReducer.appProject
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Reservations)

Reservations.propTypes = {
  appProject: propTypes.string
}

Button.propTypes = {
  disabled: propTypes.bool,
  onClick: propTypes.func,
  children: propTypes.node
}
