/* eslint-disable */
import React, { useState } from 'react'
import { Popover } from '@headlessui/react'
import { usePopper } from 'react-popper'
import { AllocationTableUnits } from 'components/AllocationTable'
import { ChevronRightIcon } from '@heroicons/react/solid'

const DetailsPanel = ({ units }) => {
  const [referenceElement, setReferenceElement] = useState()
  const [popperElement, setPopperElement] = useState()
  const { styles, attributes } = usePopper(referenceElement, popperElement)

  return (
    <Popover>
      {({ open }) => (
        <>
          <Popover.Button
            // @ts-ignore
            ref={setReferenceElement}
          >
            <ChevronRightIcon
              className={`w-6 h-6 ${open ? 'transform rotate-90' : ''}`}
            />
          </Popover.Button>
          {open ? (
            <>
              <Popover.Panel
                className='absolute right-0 w-full z-10 h-auto overflow-auto mt-6 mb-6 bg-menuGray border border-black ring-1 ring-black ring-opacity-5 divide-y divide-gray-100'
                role='menu'
                aria-orientation='vertical'
                aria-labelledby='options-menu'
                // @ts-ignore
                ref={setPopperElement}
                style={{ ...styles.popper }}
                {...attributes.popper}
              >
                {({ close }) => (
                  <>
                    <AllocationTableUnits units={units} />
                  </>
                )}
              </Popover.Panel>
            </>
          ) : null}
        </>
      )}
    </Popover>
  )
}

export default DetailsPanel
