/* eslint-disable no-unused-expressions */

import React from 'react'

import {
  CREATE_PROJECT_COLORS_STEP,
  CREATE_PROJECT_PAYMENT_STEP
} from '../constant'
import ProjectOptions from './ProjectOptions'

const ProjectUpgrades = ({ setStep = () => {}, type = 'create' }) => {
  const UPGRADES = 'upgrades'
  return (
    <div className='bg-white min-h-screen relative mb-4'>
      <div className='p-3 rounded-xl border bg-card text-card-foreground shadow bg-white'>
        <h1 className='font-black text-lg font-openSans'>Upgrades</h1>
        <div className='border-b-2 border-black my-2'></div>
        <h2 className='font-openSans'>
          Does this project has some Upgrades and Options? Fill it here!
        </h2>
        <div className='mt-4 col-span-2'>
          <ProjectOptions viewMode={type} type={UPGRADES} setStep={setStep} prev={CREATE_PROJECT_COLORS_STEP} next={CREATE_PROJECT_PAYMENT_STEP} />
        </div>
      </div>
    </div>
  )
}

export default ProjectUpgrades
