import React from 'react'

const EnhancedTableBody = ({ data, toShowRows, RowToRender, showIndex, onRowClick, showEditDeleteIcons = false, onEditClick = (p) => {}, onDeleteClick = (p) => {} }) => {
  return (
    <tbody>
      {data.map(
        (row, index) =>
          index <= toShowRows && (
            <RowToRender
              key={index}
              showIndex={showIndex}
              id={index}
              data={row}
              onEditClick={onEditClick}
              onDeleteClick={onDeleteClick}
              onRowClick={onRowClick}
              showEditDeleteIcons={showEditDeleteIcons}
            />
          )
      )}
    </tbody>
  )
}

export default EnhancedTableBody
