import React from 'react'
import LeadsRegistrationForm from 'components/LeadsRegistrationForm'
import { useParams } from 'react-router-dom'

const PondSideWebsiteForm = () => {
  const { leadSource } = useParams()

  return (
    <LeadsRegistrationForm
      leadSource={leadSource ?? 'Website'}
      projectName='Pond Side Landing'
      project='66be6823ce7623d8f40db616'
    />
  )
}

export default PondSideWebsiteForm
