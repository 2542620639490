import React from 'react'
import { ReactComponent as TickIcon } from 'assets/icons/tickk.svg'
import Button from '../NewReservation/components/Button'

const ReservationCreated = ({ onSentToSignClicked, onManuallySignClicked, loading }) => {
  return (
    <>
      <div className=' p-3 text-lg font-bold grid gap-x-12 gap-y-4 h-full w-full grid-cols-1'>
        <div
          style={{ height: '75vh', borderRadius: '25px' }}
          className='flex flex-col gap-8 justify-center items-center '
        >
          <div className='flex flex-col items-center'>
            <h1 className='text-4xl text-center'>Reservation</h1>
            <h1 className='text-4xl text-center'>Successfully Created</h1>
          </div>
          <div className='bg-volt h-36 w-36 rounded-lg flex justify-center items-center'>
            <TickIcon />
          </div>
          <div className='flex flex-col gap-4'>
            <Button
              handleClick={onSentToSignClicked}
              btnText='Sign now'
              className='py-4.5 px-12 font-bold rounded'
              disabled={loading}
              loading={loading}
            />
            <Button
              handleClick={onManuallySignClicked}
              btnText='Manually Sign'
              type='outline'
              className='font-bold py-4 px-12 rounded'
              disabled={loading}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </>
  )
}
export default ReservationCreated
