import React, { memo } from 'react'
import propTypes from 'prop-types'
import { InputLabel } from 'ui'
import { PageHeader } from 'components'
import { getDayPeriod } from 'utils'

const UserParam = ({ icon, name, label }) => (
  <>
    <dt className='sr-only'>{name}</dt>
    <dd className='flex items-center text-sm text-gray-500 font-medium sm:mr-6'>
      {icon}
      {label}
    </dd>
  </>
)

const WelcomeHeader = ({
  name, developerCompany, email, userType,
  selectedPeriod, onChangePeriod,
  periodsData, image
}) => {
  const RightContent = () => (
    <div className='flex flex-row w-full justify-end'>
      <InputLabel
        label='Select the stats period'
        typeInput='select'
        value={selectedPeriod}
        onChange={period => onChangePeriod(period)}
        data={periodsData}
        inputWrapperClassName='max-w-40'
      />
    </div>
  )

  return (
    <PageHeader
      title={`Good ${getDayPeriod()}, ${name}`}
      showParameters
      rightContent={<RightContent />}
      image={image}
    />
  )
}

export default memo(WelcomeHeader)

WelcomeHeader.propTypes = {
  name: propTypes.string,
  developerCompany: propTypes.string,
  email: propTypes.string,
  userType: propTypes.string,
  selectedPeriod: propTypes.string,
  onChangePeriod: propTypes.string,
  periodsData: propTypes.array,
  image: propTypes.string
}

UserParam.propTypes = {
  icon: propTypes.node,
  name: propTypes.string,
  label: propTypes.string
}
