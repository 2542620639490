import ParkingDocuments from 'pages/parkings/components/ParkingDocuments'
import React, { useState } from 'react'

const Contract = ({ contractData, onClose }) => {
  const [parkingRes] = useState({
    contracts: []
  })
  const [parkingId] = useState('')

  return (
    <ParkingDocuments
      contracts={parkingRes?.contracts}
      btnsShow={true}
      setCloseDocument={onClose}
      parkingId={parkingId}
      isPublicPath={true}
    />
  )
}

export default Contract
