import { message, notification } from 'antd'

export const validateRequiredInputs = (validateKey, typeOfMessage) => new Promise(
  (resolve, reject) => {
    let allFilled = true
    const errors = []
    const elementSelect = document.getElementsByClassName(validateKey)

    // Get the inputs of the screen
    Array.from(elementSelect).forEach((element) => {
      const parent = element
      let parentLabel = ''
      let parentElement = ''
      let extraMessage = ''

      const labelComponent = parent.firstElementChild

      if (labelComponent.childNodes[2]) {
        parentLabel = labelComponent.childNodes[2].data
      }

      parentElement = parent.lastElementChild

      let requiredValue = ''

      // Input Component
      if (parentElement.firstElementChild.nodeName === 'INPUT' ||
          parentElement.firstElementChild.nodeName === 'TEXTAREA'
      ) {
        const inputComponent = parentElement.firstElementChild

        extraMessage = inputComponent.getAttribute('validateExtraMessage')
        requiredValue = inputComponent.value
      }

      // Select Component
      if (parentElement.firstElementChild.className.includes('validateSelect')) {
        const selectWrapper = parentElement.firstElementChild.firstElementChild
        const selectButton = selectWrapper.firstElementChild

        extraMessage = selectButton.getAttribute('validateExtraMessage')

        if (selectButton.firstElementChild.id !== 'placeHolder') {
          requiredValue = 'Filled'
        }
      }

      // Date Picker
      if (parentElement.firstElementChild.className.includes('ant-picker')) {
        const datePickerWrapper = parentElement.firstElementChild.firstElementChild
        const datePicker = datePickerWrapper.firstElementChild

        requiredValue = datePicker.value
      }

      if (!requiredValue) {
        errors.push(`Please, fill the ${parentLabel.toLowerCase()}${extraMessage ? ` ${extraMessage}` : ''}!`)
        allFilled = false
      }
    })

    if (allFilled) {
      resolve()
    } else {
      errors.forEach((error) => {
        if (typeOfMessage === 'message') {
          message.warning(error, 3)
        } else {
          notification.warning({
            message: 'Attention',
            description: error,
            duration: 3
          })
        }
      })
      reject()
    }
  }
)
