/* eslint-disable */
import React, { useCallback, useState } from 'react'
import propTypes from 'prop-types'
import { TFilledPlusIcon } from 'components/Icons'
import { useHistory } from 'react-router-dom'
import { BROKER_CREATE_RESERVATION_PAGE } from 'routes'
import { connect } from 'react-redux'

import { isMobile } from 'react-device-detect'
import ProjectReservations from 'pages/leadBrokerPages/components/ProjectReservations'

const Button = (props) => {
  return (
    <button className='text-softBlack text-center text-lg font-medium py-1.5 px-2 flex items-center bg-white rounded'
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  )
}

const Reservations = () => {
  const [appProject] = useState('607605e70de444001a71cfc8')
  const history = useHistory()

  const createReservation = useCallback(() => {
    history.push({
      pathname: BROKER_CREATE_RESERVATION_PAGE,
      state: {
        projectId: appProject
      }
    })
  }, [appProject, history])

  return (
    <div className='px-14 py-4'>
      <div className='w-full flex justify-between items-center'>
        <h3 className='text-4xl font-bold ml-2 mb-0'>BROKER RESERVATIONS</h3>
        <Button disabled={!appProject} onClick={() => createReservation()}>
          <TFilledPlusIcon className='h-6 w-3' />
          {isMobile ? 'Create' : 'Create Reservation'}
        </Button>
      </div>
      <ProjectReservations />
    </div>
  )
}

const mapStateToProps = (state) => ({
  appProject: state.appReducer.appProject
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Reservations)

Reservations.propTypes = {
  appProject: propTypes.string
}

Button.propTypes = {
  disabled: propTypes.bool,
  onClick: propTypes.func,
  children: propTypes.node
}
