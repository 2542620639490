import React, {
  useState, useEffect,
  useCallback
} from 'react'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
import { InlineInput, Tabs } from 'ui'
import { FormTabsWrapper, FormWrapper, ImageUpload } from 'components'

import { createUploadFileList, validateRequiredInputs } from 'utils'
import { TSaveButton } from 'components/DefaultButtons'
import { ImageIcon } from 'components/Icons'
import { createOrUpdateDeveloper, getDevelopers } from 'store/actions/developersActions'

const TabHeader = ({ title, subtitle }) => (
  <div className='space-y-1'>
    <h3 className='text-lg leading-6 font-medium text-gray-900'>
      {title && title}
    </h3>
    <p className='max-w-2xl text-sm text-gray-500'>
      {subtitle && subtitle}
    </p>
  </div>
)

const TabContent = ({ children }) => (
  <div className='mt-6'>
    <dl className='divide-y divide-gray-200'>
      {children}
    </dl>
  </div>
)

const DeveloperData = (props) => {
  const {
    _id,
    userObject: {
      userType: loggedUserType
    }
  } = props

  const [companyName, setCompanyName] = useState('')
  const [websiteUrl, setWebsiteUrl] = useState('')
  const [city, setCity] = useState('')
  const [province, setProvince] = useState('')
  const [country, setCountry] = useState('')
  const [logoImage, setLogoImage] = useState([])

  const [gettingData, setGettingData] = useState(false)
  const [selectedTab, setSelectedTab] = useState('general')
  const [saving, setSaving] = useState(false)

  const getDeveloperData = useCallback(() => {
    if (_id) {
      setGettingData(true)

      // Get developer data from id
      getDevelopers(_id)
        .then((developer) => {
          const {
            companyName, websiteUrl,
            city, province, country,
            logoImage
          } = developer

          if (logoImage) {
            const tmpLogoImage = createUploadFileList(logoImage)
            setLogoImage(tmpLogoImage)
          }

          setCompanyName(companyName)
          setWebsiteUrl(websiteUrl)
          setCity(city)
          setProvince(province)
          setCountry(country)

          setGettingData(false)
        })
    } else {
      setGettingData(false)
    }
  }, [_id])

  useEffect(() => {
    getDeveloperData()
  }, [getDeveloperData])

  const _onSaveClick = useCallback(() => new Promise((resolve, reject) => {
    validateRequiredInputs('createDeveloper')
      .then(() => {
        setSaving(true)

        const params = {
          _id,
          companyName,
          websiteUrl,
          city,
          province,
          country,
          logoImage
        }

        createOrUpdateDeveloper(params)
          .then(() => {
            setSaving(false)
            resolve()
          })
          .catch(() => {
            setSaving(false)
            getDeveloperData()
            reject()
          })
      })
  }), [
    _id, city, companyName, country, getDeveloperData, logoImage,
    province, websiteUrl
  ])

  // If changed the image, call the update
  useEffect(() => {
    if (_id && logoImage.length) {
      // Check if it's a new file that was attached to the developer, if so, update the developer
      if (logoImage[0].originFileObj) {
        _onSaveClick()
      }
    }
  }, [_id, _onSaveClick, logoImage])

  const renderGeneralTab = useCallback(() => (
    <div className={`${selectedTab !== 'general' && 'sr-only'}`}>
      <TabHeader
        title='General'
        subtitle='Here are all infos related to the developer'
      />
      <TabContent>
        <InlineInput
          id='companyName'
          key='companyName'
          type='text'
          label='Company name'
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
          placeholder={'first, fill the name of the company'}
          required
          validateKey='createDeveloper'
          loading={gettingData}
          onClickUpdate={_id ? _onSaveClick : null}
        />
        <InlineInput
          id='websiteUrl'
          key='websiteUrl'
          type='text'
          label='Website URL'
          value={websiteUrl}
          onChange={(e) => setWebsiteUrl(e.target.value)}
          placeholder={'what\'s the developer\'s website?'}
          required
          validateKey='createDeveloper'
          loading={gettingData}
          onClickUpdate={_id ? _onSaveClick : null}
        />
        <InlineInput
          id='city'
          key='city'
          type='text'
          label='City'
          value={city}
          onChange={(e) => setCity(e.target.value)}
          placeholder='the city'
          required
          validateKey='createDeveloper'
          loading={gettingData}
          onClickUpdate={_id ? _onSaveClick : null}
        />
        <InlineInput
          id='province'
          key='province'
          type='text'
          label='Province'
          value={province}
          onChange={(e) => setProvince(e.target.value)}
          placeholder='province'
          required
          validateKey='createDeveloper'
          loading={gettingData}
          onClickUpdate={_id ? _onSaveClick : null}
        />
        <InlineInput
          id='country'
          key='country'
          type='text'
          label='Country'
          value={country}
          onChange={(e) => setCountry(e.target.value)}
          placeholder='country'
          required
          validateKey='createDeveloper'
          loading={gettingData}
          onClickUpdate={_id ? _onSaveClick : null}
        />
      </TabContent>
    </div>
  ), [
    _id, _onSaveClick, city, companyName, country, gettingData,
    province, selectedTab, websiteUrl
  ])

  const renderDeveloperLogoImageTab = useCallback(() => (
    <div className={`${selectedTab !== 'developerLogoImage' && 'sr-only'}`}>
      <TabHeader
        title='Image'
        subtitle='Click at the area below or drag an image here to update the developer logo image'
      />
      <TabContent>
        <ImageUpload
          uploadProps={{
            name: 'imageUpload',
            listType: 'picture-card',
            className: 'avatar-uploader',
            showUploadList: logoImage.length > 0,
            fileList: logoImage,
            onChange: ({ fileList }) => {
              setLogoImage(fileList)
            },
            beforeUpload: () => {
              return false
            },
            style: { height: '100%', width: '100%' }
          }}
          title='Developer Logo Image'
          uploadButtonText='Click or drag here the Developer Logo Image'
          uploadButtonIcon={<ImageIcon style={{ fontSize: 30 }} />}
          uploadWrapperStyle={{ width: '100%', marginRight: 0 }}
          // images={}
          showUploadButton={logoImage.length <= 0}
        />
      </TabContent>
    </div>
  ), [selectedTab, logoImage])

  const renderIdTab = useCallback(() => (
    <div className={`${selectedTab !== 'id' && 'sr-only'}`}>
      <TabHeader
        title='ID'
        subtitle='This tab is only available to Super Admins'
      />
      <TabContent>
        <InlineInput
          key='developerId'
          label='Developer ID'
          value={_id}
          onChange={(e) => {}}
          placeholder='developer ID'
          loading={gettingData}
          disabled
        />
      </TabContent>
    </div>
  ), [gettingData, _id, selectedTab])

  const getMenus = useCallback(() => {
    const menus = [
      { key: 'general', title: 'General' },
      { key: 'developerLogoImage', title: 'Developer Logo Image' }
    ]

    if (loggedUserType === 'SuperAdmin' && Boolean(_id)) {
      menus.push(
        { key: 'id', title: 'ID' }
      )
    }

    return menus
  }, [loggedUserType, _id])

  return (
    <FormWrapper>
      <FormTabsWrapper>
        <Tabs
          menus={getMenus()}
          tabSelected={selectedTab}
          onClick={(tab) => setSelectedTab(tab)}
        />
        {
          !_id && (
            <TSaveButton
              loading={saving}
              className='ml-3'
              onClick={_onSaveClick}
            >
              Save developer
            </TSaveButton>
          )
        }
      </FormTabsWrapper>

      <div className='mt-7'>
        {renderGeneralTab()}
        {renderDeveloperLogoImageTab()}
        {renderIdTab()}
     </div>
    </FormWrapper>
  )
}

const mapStateToProps = (state) => ({
  userObject: state.authReducer.userObject
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(DeveloperData)

DeveloperData.propTypes = {
  _id: propTypes.string,
  userObject: propTypes.shape({
    userType: propTypes.string
  })
}

TabHeader.propTypes = {
  title: propTypes.string,
  subtitle: propTypes.string
}

TabContent.propTypes = {
  children: propTypes.node
}
