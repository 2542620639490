import React, { useEffect, useState } from 'react'

import { ReactComponent as SearchIcon } from 'assets/icons/searchIcon.svg'

import CustomSelect from 'components/CustomSelect'
import LargeUnitCard from 'components/LargeUnitCard'

import useGetProjectUnits from 'hooks/useGetProjectUnits'
import { LoadingDetails } from 'pages/desktopMode/inventory/Allocation/AllocateInventory/Steps/Building'

const PWAUnitsInventory = ({
  setButtonState,
  selectedUnit,
  setSelectedUnit
}) => {
  const { units, unitsLoading } = useGetProjectUnits()
  const [selectedOption, setSelectedOption] = useState({ id: 0 })
  const [filteredUnits, setFilteredUnits] = useState(units)
  const [allocationData] = useState([
    { id: 1, value: 'Unit 1' },
    { id: 2, value: 'Unit 2' }
  ])

  const [search, setSearch] = useState('')

  useEffect(() => {
    if (search !== '') {
      setFilteredUnits(
        filteredUnits.filter(
          (unit) =>
            unit.unitNumber.toLowerCase().startsWith(search.toLowerCase()) ||
            unit.unitNumber.toLowerCase() === search.toLowerCase()
        )
      )
    } else {
      setFilteredUnits(units)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  useEffect(() => {
    if (units?.length > 0) {
      setFilteredUnits(units)
    }
  }, [units])

  const setAllocationName = (e) => {
    setSelectedOption({ id: e })
  }

  if (unitsLoading) {
    return (
      <div
        className='flex items-center justify-center w-full h-screen'
        style={{ gridColumn: '1 / -1' }}
      >
        <LoadingDetails title='Loading Units' />
      </div>
    )
  }

  return (
    <>
      <div
        style={{
          margin: '48px 86px 64px 80px',
          width: '90%'
        }}
        className='flex justify-between'
      >
        <div>
          <p className='font-bold font-openSans text-4xl text-softBlack'>
            Units
          </p>
        </div>
        <div className='flex gap-7'>
          <div
            style={{ height: 56 }}
            className='flex flex-row text-softBlack_70 w-full items-center border-b-softBlack border-b'
          >
            <div className='w-5 h-5'>
              <SearchIcon />
            </div>
            <input
              type='text'
              placeholder='Search Unit'
              className='border-0 ring-0 rounded-none focus:ring-0 focus:border-0 bg-transparent w-80'
              value={search}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
            />
          </div>
          <div>
            <CustomSelect
              label=''
              options={allocationData}
              selectedOption={selectedOption?.id}
              setSelectedOption={(e) => setAllocationName(e)}
              labelClasses={'text-left'}
              inputClasses={
                'border-0 w-80 border-b-softBlack border-b rounded-none'
              }
              defaultValue={undefined}
              fieldName='Units'
            />
          </div>
        </div>
      </div>
      <div style={{ margin: '0px 96px 0px 96px' }}>
        <div
          className={
            'mt-8 grid grid-cols-none lg:grid-cols-2 2xl:grid-cols-3 gap-4'
          }
        >
          {filteredUnits?.map((unit, key) => (
            <div
              className={`${
                selectedUnit.id === unit.id
                  ? 'border-2 border-softBlack'
                  : 'border-transparent'
              }`}
              key={key}
              onClick={() => {
                setButtonState('Default')
                setSelectedUnit(unit)
              }}
            >
              <LargeUnitCard
                imgsrc={unit.floorPlan?.image}
                unitName={`#${unit?.unitNumber}`}
                noOfBedrooms={unit?.numberOfBedrooms}
                noOfBathrooms={unit?.numberOfBathrooms}
                balcony={unit?.balcony}
                noOfParkingSlot={unit?.floorPlan?.parkingQty}
                building={unit?.building?.name}
                price={unit?.price}
                sizeInSquareFeet={undefined}
                address={unit?.fullAddress}
                pricePerSq={undefined}
                tag={undefined}
                type={'grid'}
                showViewDetails={false}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default PWAUnitsInventory
