import { Popover } from 'antd'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import React from 'react'

import { CustomUserIcon, LoggedUserWrapper } from './styles'
import { H6, H7 } from 'ui'

const userTypes = {
  Buyer: 'Buyer',
  SuperAdmin: 'Super Admin',
  DeveloperAdmin: 'Developer Admin',
  SalesRep: 'Sales Rep',
  CoopBroker: 'Coop Broker'
}

const LoggedUserButton = (props) => {
  const {
    userObject: {
      firstName,
      lastName,
      email,
      userType
    }
  } = props

  const content = () => (
    <LoggedUserWrapper>
      <H6>{`${firstName} ${lastName}`}</H6>
      <H7>{`${email} - ${userTypes[userType]}`}</H7>
    </LoggedUserWrapper>
  )

  return (
    <Popover
      placement='bottomRight'
      content={content}
      trigger='click'
      arrowPointAtCenter
    >
      <CustomUserIcon />
    </Popover>
  )
}

const mapStateToProps = state => ({
  userObject: state.authReducer.userObject
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(LoggedUserButton)

LoggedUserButton.propTypes = {
  userObject: propTypes.shape
}
