/* eslint-disable */
// @ts-nocheck
import React, { useEffect, useMemo, useState } from "react";
import useGetProjectUnits from "hooks/useGetProjectUnits";
import InventoryListViewWrapper from "../InventoryListViewWrapper";
import { groupBy } from "components/Inventory";
import useGetInventories from "hooks/useGetInventories";
import InventoryItem from "../InventoryItem";
import Skeleton from "react-loading-skeleton";
import InventoryItemV2 from "../InventoryItemV2";
import InventoryListViewWrapperV2 from "../NewInventory/InventoryListViewWrapperV2";

const InventoryListViewMain = ({
  projectId,
  buildingId,
  isFullHeight = false,
}) => {
  const { inventories, refetch, loading } = useGetInventories(
    `building/${buildingId}`
  );
  const [searchQuery, setSearchQuery] = useState("");

  function removePrefix(str, prefix) {
    return str.replace(new RegExp(`^${prefix}`, "i"), "").replace("Data", "");
  }

  useEffect(() => {
    refetch();
  }, [buildingId]);

  const filteredUnits = useMemo(() => {
    const keyMain = {
      lot: "lotData",
      condo: "condoData",
      townhome: "townhomeData",
    }[inventories[0]?.type];
    const unitsData = inventories.filter((elm) =>
      searchQuery
        ? elm[keyMain].number.toString().includes(searchQuery.toLowerCase())
        : true
    );
    const uCards = [...unitsData];
    let mapped = uCards.map((item) => {
      const key = {
        lot: "lotData",
        condo: "condoData",
        townhome: "townhomeData",
      }[item.type];

      const extractedData = item[key]
        ? Object.entries(item[key]).reduce((acc, [field, value]) => {
            const newFieldName = field;
            acc[newFieldName.toLowerCase()] = value;
            return acc;
          }, {})
        : {};

      return {
        ...item,
        ...extractedData,
      };
    });
    return mapped.sort((a, b) => a?.number - b?.number);
  }, [inventories, buildingId, refetch, searchQuery]);

  return (
    <>
      <div className="ml-6 py-2">
        <input
          type="text"
          placeholder="Search by unit number..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-[300px] p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
      <InventoryListViewWrapperV2
        inventoryItems={filteredUnits}
        loading={loading}
        type="Unit"
        refetch={refetch}
        isFullHeight={isFullHeight}
        selectedBuilding={buildingId}
      />
    </>
  );
};

export default InventoryListViewMain;
