export const opportunityStages = [
  'CREATED',
  'RESERVATION',
  'OFFER',
  'WON-SOLD FIRM',
  'LOST'
]

export const opportunityRatings = [
  'A',
  'B',
  'C',
  'D',
  'E'
]

// export const needParkings = [
//   'Yes',
//   'No'
// ]

// export const needsFinancings = [
//   'Yes',
//   'No'
// ]

// export const testOptions = [
//   'Test Select Value'
// ]

// export const orientationValues = [
//   'N',
//   'NE',
//   'NW',
//   'E',
//   'SE',
//   'SW',
//   'W',
//   'S'
// ]

export const fields = [
  'floorNumbers',
  'orientation',
  'floorPlans',
  'unitInterests',
  'upgrades',
  'numberOfBedrooms',
  'numberOfParkingSpots',
  'minSquareFeet',
  'maxSquareFeet'
]

export const unitIds = [
  '6453aef8301626b5801fbde0',
  '645ca41852fd26392ced7c16',
  '6464da1f8f4eaf1338dc73fb',
  '6464db958f4eaf1338dc838c',
  '6464dbb28f4eaf1338dc854e'
]
