import { EmailAttachmentSVGIcon } from 'pages/contacts/EmailModal'
import React, { useCallback, useState } from 'react'
import { downloadAttachementNewV3, formatBytes } from 'utils/emailUtils'
import { TiDownload as DownIcon } from 'react-icons/ti'

const EmailAttachmentItem = ({ file, threadId, message }) => {
  // console.log(file)
  const [downloading, setDownloading] = useState(false)

  const download = useCallback(async (file) => {
    setDownloading(true)
    try {
      await downloadAttachementNewV3(file, threadId, message)
    } catch (e) {
    }
    setDownloading(false)
  }, [])

  return <>
    <li key={file.id} className='relative bg-white p-2 shadow-lg sm:rounded-lg h-[118px] w-[178px] overflow-hidden'
      onClick={() => download(file)}>
      <div className='flex flex-row gap-2'>
        {EmailAttachmentSVGIcon(file?.content_type || file?.contentType)}
        <div className='flex flex-1 flex-col overflow-hidden space-y-2'>
          <span className='text-sm font-semibold eclipse'>{file?.filename?.substring(0, 45) || 'attachment'}</span>
          <span className='text-xs'>{formatBytes(file.size)}</span>
          {downloading
            ? <span className='absolute right-0 bottom-0 p-3'>
              <span className='text-xs'>Downloading...</span>
            </span>
            : <span className='absolute right-0 bottom-0 p-3'>
              <DownIcon className='w-4 h-4' />
            </span>
          }
        </div>
      </div>
    </li>
  </>
}

export default EmailAttachmentItem
