/* eslint-disable */
// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react';
import { useGetCorporateDemandNotes } from 'hooks/useGetCorporateDemandNotes';

const TableBody = ({ setIsEdit, setInitialValues, data, setDeleteModal, deleteModal }) => {
  return (
    <tbody className='divide-y divide-gray-200 bg-white'>
      {data &&
        data.map((item, index) => {
          return (
            <tr>
              <td className='relative w-25 px-6 sm:w-16 sm:px-8'>
                <div className='absolute inset-y-0 left-0 w-0.5 bg-indigo-600'></div>
                <input
                  type='checkbox'
                  className='absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6 hover:cursor-pointer'
                />
              </td>
              <td className='flex flex-row gap-3 items-center whitespace-nowrap w-25 text-sm font-medium text-gray-900 pr-6 py-5'>
                {item.label}
              </td>
              <td className='whitespace-nowrap w-25 text-sm font-medium text-gray-900 pr-6'>
                <div
                  className='underline cursor-pointer'
                  onClick={() => {
                    setInitialValues({
                      label: item.label,
                      options: item.options,
                      _id: item._id,
                      isMulti: item.isMulti,
                    });
                    setIsEdit(true);
                  }}
                >
                  Edit
                </div>
              </td>
              <td className='whitespace-nowrap w-25 text-sm font-medium text-gray-900 pr-6'>
                <div
                  onClick={() => {
                    setDeleteModal(true);
                    setInitialValues({
                      label: item.label,
                      options: item.options,
                      _id: item._id
                    });
                  }}
                  className='underline cursor-pointer'
                >
                  Delete
                </div>
              </td>
            </tr>
          );
        })}
    </tbody>
  );
};

export default TableBody;
