import { ProjectOffers, PwaWrapper } from 'components'
import React from 'react'
import BackButton from '../components/BackButton'

const PwaProjectOffers = () => {
  return (
    <PwaWrapper>
      <div
        className='relative w-screen h-screen'
        style={{ backgroundColor: '#F2F2F1', overflow: 'scroll' }}
      >
        <div className='h-64 top-0 right-0 left-0 bg-softBlack'>
          <div className='pl-20 pt-5 flex-col text-white'>
            <BackButton />
            <span className='block mt-5 font-bold text-4xl'>Hudson 8</span>
            <span className='block mt-4 font-normal text-2xl'>
              Main / Stats &amp; Offers
            </span>
          </div>
        </div>
        <div className='absolute w-full top-44 px-20 pb-6'>
          <ProjectOffers />
        </div>
      </div>
    </PwaWrapper>
  )
}

export default PwaProjectOffers
