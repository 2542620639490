// @ts-nocheck
import React, { useCallback, useContext, useEffect, useState } from 'react'
import CustomInput from 'components/CustomInput'
import CustomModal from 'components/Modal'
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg'
import { getUsers } from 'store/actions/usersActions'
import { getDevelopers } from 'store/actions/developersActions'
import { Oval } from 'react-loader-spinner'
// import { message } from 'antd'
import CustomSelect from 'components/CustomSelect'
import { ImageUploaderBrowse } from 'components/ImageUploader'
import { createUploadFileList } from 'utils/createUploadFileList'
import { ContactContext } from './context/Contacts'
import useUpdateUsers from 'hooks/useUpdateUsers'
import CustomButton from './CustomButton'
// const ratingData = [
//   { id: '', value: '' },
//   { id: 'New', value: 'New' },
//   { id: 'Agent', value: 'Agent' },
//   { id: 'Hot', value: 'Hot' },
//   { id: 'Warm', value: 'Warm' },
//   { id: 'Cold', value: 'Cold' }
// ]
// const preferredContactData = [
//   { id: '', value: '' },
//   { id: 'Any', value: 'Any' },
//   { id: 'Email', value: 'Email' },
//   { id: 'Work', value: 'Work' },
//   { id: 'Fax', value: 'Fax' },
//   { id: 'Agent', value: 'Agent' },
//   { id: 'Text Message', value: 'Text Message' }
// ]

const residentData = [
  { id: false, value: 'No' },
  { id: true, value: 'Yes' }
]

const prefixData = [
  { id: 'mr', value: 'Mr.' },
  { id: 'miss', value: 'Miss.' },
  { id: 'ms', value: 'Ms.' },
  { id: 'mrs', value: 'Mrs.' }
]

// const GET_PHOTO_ID_FROM_CAMERA = 'get_photo_id_from_camera'
// const GET_PHOTO_ID_FROM_LIBRARY = 'get_photo_id_from_library'

// const cameraButtonsAnimationValues = {
//   buttonExit: (direction) => ({
//     x: direction === 'left' ? -1000 : 1000
//   }),
//   buttonEnter: {
//     x: 0
//   },
//   cameraExitY: {
//     y: -1000
//   },
//   cameraEnterY: {
//     y: 0
//   },
//   cameraExitX: {
//     x: 1000
//   },
//   cameraEnterX: {
//     x: 0
//   },
//   uploadError: {

//   }
// }

const EditContactModal = ({ showCreateModal, setShowCreateModal, contact, onSuccess, updateContact }) => {
  const { createOrUpdateUser } = useUpdateUsers()
  const [values, setValues] = useState({
    id: contact?.id,
    email: contact?.email,
    userType: contact?.userType,
    prefix: contact?.buyerData?.prefix ?? '',
    firstName: contact?.firstName ?? '',
    lastName: contact?.lastName ?? '',
    legalName: contact?.buyerData?.legalName ?? '',
    address: contact?.buyerData?.address,
    city: contact?.buyerData?.city,
    province: contact?.buyerData?.province,
    country: contact?.buyerData?.country,
    postalCode: contact?.buyerData?.postalCode,
    resident: contact?.buyerData?.resident,
    stripeCustomerId: contact?.buyerData?.stripeCustomerId,
    iQ11Token: contact?.buyerData?.iQ11Token,
    phoneNumber: contact?.buyerData?.phoneNumber,
    mobileNumber: contact?.buyerData?.mobileNumber,
    workNumber: contact?.buyerData?.workNumber,
    bus: contact?.buyerData?.bus,
    sin: contact?.buyerData?.sin,
    fax: contact?.buyerData?.fax,
    idNumber: contact?.buyerData?.idNumber ?? '',
    idType: contact?.buyerData?.idType ?? '',
    idExpireDate: contact?.buyerData?.idExpireDate?.slice(0, contact?.buyerData?.idExpireDate.indexOf('T')) ?? '',
    birthday: contact?.buyerData?.birthday?.slice(0, contact?.buyerData?.birthday.indexOf('T')) ?? '',
    employer: contact?.buyerData?.employer,
    occupation: contact?.buyerData?.occupation,
    frontPhotoIdFile: contact?.buyerData?.frontPhotoIdFile,
    frontPhotoIdUrl: contact?.buyerData?.frontPhotoIdUrl,
    backPhotoId: contact?.buyerData?.backPhotoId,
    backPhotoIdFile: contact?.buyerData?.backPhotoIdFile,
    backPhotoIdUrl: contact?.buyerData?.backPhotoIdUrl,
    selectedPhotoIdSource: contact?.buyerData?.selectedPhotoIdSource,
    payorId: contact?.buyerData?.payorId,
    frontPhotoId: contact?.buyerData?.frontPhotoId,
    typeformData: contact?.buyerData?.typeformData,
    rating: contact?.buyerData?.rating,
    preferredContact: contact?.buyerData?.preferredContact,
    socialMedia: contact?.buyerData?.socialMedia,
    assignments: contact?.buyerData?.assignments,
    additionalFields: contact?.buyerData?.additionalFields,
    allowAdditionalServices: contact?.buyerData?.allowAdditionalServices,
    allowSurveys: contact?.buyerData?.allowSurveys,
    twitter: contact?.buyerData?.socialMedia?.twitter ?? '',
    webSite: contact?.buyerData?.socialMedia?.webSite ?? '',
    licenseNumber: contact?.brokerData?.licenseNumber,
    licenseBrokerage: contact?.brokerData?.licenseBrokerage,
    licenseExpiration: contact?.brokerData?.licenseExpiration,
    developerCompany: {},
    project: contact?.brokerData?.project,
    projects: {}
  })
  const { projects } = useContext(ContactContext)
  const [errors, setErrors] = useState({
    email: false,
    firstName: false,
    lastName: false
  })
  const [loading, setLoading] = useState(false)

  const onChange = (field, value) => {
    setValues({ ...values, [field]: value })
    validate()
  }

  // When mounted, get the list of developers to fill Developers Select
  useEffect(() => {
    if (!projects) return
    if ((values.userType === 'DeveloperAdmin') ||
    (values.userType === 'SalesRep') ||
    (values.userType === 'CoopBroker')) {
      getDevelopers()
        .then((developers) => {
          const newDevelopersData =
        developers.map((developer) => ({
          id: developer._id, value: developer.companyName
        }))
          setValues({ ...values, developersData: newDevelopersData })
        })
        .catch(() => {
        })
    }
    const tmpProjects = projects.map((project) => ({
      id: project._id, value: project.projectName
    }))
    setValues({ ...values, projects: tmpProjects })
  }, [values.userType, projects])

  const getUserData = useCallback(() => {
    if (values.id) {
      // Get user data from id
      getUsers(values.id)
        .then((user) => {
          const {
            buyerData,
            brokerData,
            developerAdminData,
            salesRepData,
            project
          } = user
          if (buyerData) {
            const {
              frontPhotoId,
              backPhotoId
            } = buyerData
            // Buyer
            if (buyerData) {
              if (project) {
                setValues({ ...values, project })
              }

              if (frontPhotoId) {
                const tmpFrontPhotoId = createUploadFileList(frontPhotoId)
                setValues({ ...values, frontPhotoId: tmpFrontPhotoId })
                setValues({ ...values, frontPhotoIdUrl: frontPhotoId })
              }

              if (backPhotoId) {
                const tmpBackPhotoId = createUploadFileList(backPhotoId)
                setValues({ ...values, backPhotoId: tmpBackPhotoId })
                setValues({ ...values, backPhotoIdUrl: backPhotoId })
              }

              // if (frontPhotoId || backPhotoId) {
              // // Just to show the imaes, and not the upload method selector
              //   setChosenPhotoIdMethod(GET_PHOTO_ID_FROM_LIBRARY)
              // }
            }

            // Coop Broker
            if (brokerData) {
              const {
                developerCompany,
                city,
                province,
                country
              } = brokerData
              setValues({ ...values, city })
              setValues({ ...values, province })
              setValues({ ...values, country })

              const tmpDeveloperCompanies = []
              if (developerCompany.length) {
                developerCompany.forEach((element) => tmpDeveloperCompanies.push(element._id))
              }
              setValues({ ...values, developerCompany: tmpDeveloperCompanies })
            }

            // Developer Admin
            if (developerAdminData) {
              const { developerCompany } = developerAdminData

              if (developerCompany) {
                setValues({ ...values, developerCompany: developerCompany._id })
              }
            }

            // Sales Rep
            if (salesRepData) {
              const {
                developerCompany,
                projects
              } = salesRepData

              if (developerCompany) {
                setValues({ ...values, developerCompany: developerCompany._id })
              }
              setValues({ ...values, projects })
            }
          }
        })
    } else {
      // cleanValues()
    }
  }, [values.id])

  useEffect(() => {
    getUserData()
  }, [getUserData])

  const validate = () => {
    let isValid = true
    Object.entries(values).forEach(function ([key, val]) {
      if (key in errors) {
        if (val === '' || val === undefined) {
          errors[key] = true
          setErrors(...[errors])
        } else {
          errors[key] = false
          setErrors(...[errors])
        }
      }
    })
    Object.entries(errors).forEach(function ([key, val]) {
      if (val === true) {
        isValid = false
      }
    })
    return isValid
  }
  const onCreate = async () => {
    const isValid = validate()
    if (isValid) {
      let baseObject = {
        id: values.id,
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        userType: values.userType
      }

      let params = {}

      if (values.userType === 'Buyer' || values.userType === 'Lead') {
        baseObject = Object.assign({}, baseObject, { project: values.projects.length ? values.projects : [] })

        params = Object.assign({}, baseObject, {
          buyerData: {
            city: values.city,
            province: values.province,
            country: values.country,
            phoneNumber: values.phoneNumber,
            prefix: values.prefix,
            occupation: values.occupation,
            address: values.address,
            postalCode: values.postalCode,
            bus: values.bus,
            fax: values.fax,
            sin: values.sin,
            resident: values.resident,
            iQ11Token: values.iQ11Token,
            stripeCustomerId: values.stripeCustomerId,
            payorId: values.payorId,
            legalName: values.legalName,
            mobileNumber: values.mobileNumber,
            workNumber: values.workNumber,
            employer: values.employer,
            socialMedia: {
              twitter: values?.buyerData?.socialMedia?.twitter,
              website: values?.buyerData?.socialMedia?.webSite
            },
            typeformData: values.typeformData,
            rating: values.rating,
            preferredContact: values.preferredContact,
            assignments: values.assignments,
            additionalFields: values.additionalFields,
            frontPhotoId: values.frontPhotoIdUrl,
            backPhotoId: values.backPhotoIdUrl,
            birthday: values.birthday,
            idType: values.idType,
            idNumber: values.idNumber,
            idExpireDate: values.idExpireDate,
            allowAdditionalServices: values.allowAdditionalServices,
            allowSurveys: values.allowSurveys
          }
        })
      }

      if (values.userType === 'DeveloperAdmin') {
        params = Object.assign({}, baseObject, {
          developerAdminData: {
            developerCompany: values.developerCompany
          }
        })
      }

      if (values.userType === 'SalesRep') {
        params = Object.assign({}, baseObject, {
          salesRepData: {
            developerCompany: values.developerCompany,
            projects: values.projects
          }
        })
      }

      if (values.userType === 'CoopBroker') {
        params = Object.assign({}, baseObject, {
          brokerData: {
            licenseNumber: values.licenseNumber,
            licenseBrokerage: values.licenseBrokerage,
            licenseExpiration: values.licenseExpiration,
            developerCompany: values.developerCompany,
            city: values.city,
            province: values.province,
            country: values.country
          }
        })
      }

      if (values.userType === 'SuperAdmin') {
        params = Object.assign({}, baseObject, {})
      }

      setLoading(true)
      const data = await createOrUpdateUser(params, false, false)
      updateContact(data)
      setLoading(false)

      setShowCreateModal(false)
      onSuccess()
    }
  }

  const closeModal = () => {
    setShowCreateModal(false)
  }
  // console.log(errors)
  return (
    <CustomModal isOpen={showCreateModal} closeModal={closeModal} contentLabel="New Broker">
      <div className='w-253.75 max-w-full p-10 text-center rounded bg-grey-8 relative'>
        <h2 className='text-xl font-bold mb-8'>
          Edit Buyer
        </h2>
        <div className='absolute top-10 right-10 cursor-pointer' onClick={closeModal}>
          <CrossIcon />
        </div>
        <div>
          <span className="border-red"></span>
          <div className='grid grid-cols-4 gap-6 pb-6'>
            <CustomSelect
              label='Prefix'
              labelClasses='text-left'
              inputClasses='h-10 bg-white'
              selectedOption={values.prefix}
              options={prefixData}
              setSelectedOption={(e) => onChange('prefix', e)}
            />
            <CustomInput classes={`max-h-14 ${errors.firstName && 'border-red'}`} label='First Name *' placeholder='Enter first name' onChange={(e) => onChange('firstName', e.target.value)} value={values.firstName || ''} required="required" error={errors.firstName} />
            <CustomInput classes={`max-h-14 ${errors.lastName && 'border-red'}`} label='Last Name *' placeholder='Enter last name' onChange={(e) => onChange('lastName', e.target.value)} value={values.lastName || ''} required="required" error={errors.lastName} />
            <CustomInput classes={`max-h-14 ${errors.legalName && 'border-red'}`} label='Legal Name *' placeholder='Enter legal name' onChange={(e) => onChange('legalName', e.target.value)} value={values.legalName || ''} />
          </div>
          <div className='grid grid-cols-4 gap-6 pb-6'>
            <CustomInput classes={`max-h-14 ${errors.birthday && 'border-red'}`} label='Birthday *' placeholder='DD/MM/YYYY' type="date" onChange={(e) => onChange('birthday', e.target.value)} value={values.birthday || ''} />
            <CustomInput classes={`max-h-14 ${errors.idNumber && 'border-red'}`} label='Id Number *' placeholder='Enter id' onChange={(e) => onChange('idNumber', e.target.value)} value={values.idNumber || ''} />
            <CustomInput classes={`max-h-14 ${errors.idType && 'border-red'}`} label='Id Type *' placeholder='Enter type' onChange={(e) => onChange('idType', e.target.value)} value={values.idType || ''} />
            <CustomInput classes={`max-h-14 ${errors.idExpireDate && 'border-red'}`} label='Id Expiry Date *' placeholder='DD/MM/YYYY' type="date" onChange={(e) => onChange('idExpireDate', e.target.value)} value={values.idExpireDate || ''} />
          </div>

          <p className='text-lg text-left font-bold mt-0 mb-6'>Representative Information</p>
          <div className='grid grid-cols-2 gap-6 pb-8'>
            <CustomInput classes='max-h-14' label='Address' placeholder='Enter address' onChange={(e) => onChange('address', e.target.value)} value={values.address || ''} />
            <CustomInput classes='max-h-14' label='City' placeholder='Enter city' onChange={(e) => onChange('city', e.target.value)} value={values.city || ''} />
            <CustomInput classes='max-h-14' label='Province' placeholder='Enter province' onChange={(e) => onChange('province', e.target.value)} value={values.province || ''} />
            <CustomInput classes='max-h-14' label='Country' placeholder='Enter country' onChange={(e) => onChange('country', e.target.value)} value={values.country || ''} />

            <CustomInput classes='max-h-14' label='Postal Code' placeholder='Enter code' onChange={(e) => onChange('postalCode', e.target.value)} value={values.postalCode || ''} />
            <CustomSelect
              label='Resident'
              labelClasses='text-left'
              inputClasses='h-10 bg-white'
              selectedOption={values.resident}
              options={residentData}
              setSelectedOption={(e) => onChange('resident', e)}
            />
          </div>

          <p className='text-lg text-left font-bold mt-0 mb-6'>Contacts</p>
          <div className='grid grid-cols-2 gap-6 pb-8'>
            <CustomInput classes='max-h-14' label='Phone Number' placeholder='Enter phone number' onChange={(e) => onChange('phoneNumber', e.target.value)} value={values.phoneNumber || ''} type='phone' />
            <CustomInput classes='max-h-14' label='Mobile Number' placeholder='Enter mobile number' onChange={(e) => onChange('mobileNumber', e.target.value)} value={values.mobileNumber || ''} type='phone' />
            <CustomInput classes='max-h-14' label='Work Number' placeholder='Enter work number' onChange={(e) => onChange('workNumber', e.target.value)} value={values.workNumber || ''} />
            <CustomInput classes='max-h-14' label='Fax' placeholder='Enter fax' onChange={(e) => onChange('fax', e.target.value)} value={values.fax || ''} />
            <CustomInput classes='max-h-14' label='Business Number' placeholder='Enter business number' onChange={(e) => onChange('bus', e.target.value)} value={values.bus || ''} />
            <CustomInput classes='max-h-14' label='Social Insurance Number' placeholder='Enter number' onChange={(e) => onChange('sin', e.target.value)} value={values.sin || ''} />
            <CustomInput type="email" classes='max-h-14' label='Email' placeholder='Enter email' onChange={(e) => onChange('email', e.target.value)} value={values.email || ''} />
            <CustomInput classes='max-h-14' label='Occupation' placeholder='Enter occupation' onChange={(e) => onChange('occupation', e.target.value)} value={values.occupation || ''} />
            <CustomInput classes='max-h-14' label='Employer' placeholder='Enter employer' onChange={(e) => onChange('employer', e.target.value)} value={values.employer || ''} />
          </div>
          <p className='text-lg text-left font-bold mt-0 mb-4'>Upload Front Photo ID *</p>
          <div className='mb-6 col-span-2'>
            <div className='border'>
              <ImageUploaderBrowse
                value={values.frontPhotoIdFile || ''}
                onFileChange={(e) =>
                  setValues((prev) => {
                    return { ...prev, frontPhotoIdFile: e?.target?.value || '' }
                  })
                }
              />
            </div>
          </div>
          <p className='text-lg text-left font-bold mt-0 mb-4'>Upload Back Photo ID *</p>
          <div className='mb-6 col-span-2'>
            <div className='border'>
              <ImageUploaderBrowse
                value={values.backPhotoIdFile || ''}
                onFileChange={(e) =>
                  setValues((prev) => {
                    return { ...prev, backPhotoIdFile: e?.target?.value || '' }
                  })
                }
              />
            </div>
          </div>
          <CustomButton
            handleClick={onCreate}
          >
            <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
              {loading && <Oval height='18' width='18' color='white' />}
              Update Contact
            </span>
          </CustomButton>
        </div>
      </div>
    </CustomModal>
  )
}

export default EditContactModal
