import styled from 'styled-components'
import { Col } from 'antd'

export const LoadContractButton = styled(Col)``

export const HelloSignWrapper = styled.div`
  height: 100%;
  width: 100%;
  padding: 0px 15px 15px 15px;
`
