// @ts-nocheck
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Oval } from 'react-loader-spinner'

import CustomInput from 'components/CustomInput'
import CustomSelect from 'components/CustomSelect'
import { getProjectUnits } from 'store/actions/unitActions'
import { useSelector } from 'react-redux'
import { createOrUpdateParking } from 'store/actions/parkingActions'
import { ADMIN_OFFER } from 'routes'
import { useHistory } from 'react-router-dom'
import CustomBadge from './CustomBadge'
import { Check, ShoppingBag, Trash2 } from 'lucide-react'
import useInventory from '../hooks/useInventory'
import DeleteModal from 'components/DeleteModal'
import { deleteParkingPolygon } from 'store/actions/parkingPolygonActions'

const TEMP_AVAILABILITY_STATUS = {
  available: 'Available',
  assigned: 'Assigned',
  reserved: 'Reserved',
  allocated: 'Allocated',
  rescission: 'Rescission',
  sold: 'Sold'
}

const ParkingEditView = ({
  editDetails,
  setEditDetails,
  refetch,
  building,
  closeParkingInfoDrawer
}) => {
  // @ts-ignore
  const history = useHistory()
  const projectSelector = state => state.appReducer.appProject
  const appProject = useSelector(projectSelector)
  const userSelector = state => state.authReducer.userObject
  const userObject = useSelector(userSelector)
  const [unitOptions, setUnitOptions] = useState([])
  const { state: { statusColors, selectedInventoryView }, allParkingLevels } = useInventory()
  const [isDeleteModal, setIsDeleteModal] = useState(false)

  useEffect(() => {
    _getProjectUnits()
  }, [appProject])

  const _getProjectUnits = async () => {
    if (appProject) {
      setUnitOptions([])

      let projectUnits = await getProjectUnits(appProject)

      projectUnits = projectUnits.map((unit) => ({
        ...unit,
        hasPermission: checkUnitPermission(unit)
      }))

      Promise.all([projectUnits]).then(() => {
        if (projectUnits.length) {
          setUnitOptions(projectUnits.map((unit) => (
            {
              value: unit.unitNumber,
              id: unit._id
            }
          )))
        }
      })
    }
  }

  const checkUnitPermission = useCallback(
    (unit, selectedTabFilter) => {
      const { _id } = userObject
      const { brokerAssigned, reservedTo } = unit

      const tmpBrokerAssigned = brokerAssigned || []

      return Boolean(
        (selectedTabFilter !== 'myUnits' &&
          unit.status !== 'ReservedBroker' &&
          unit.status !== 'ReservedBuyer') ||
        ((unit.status === 'ReservedBroker' ||
          unit.status === 'ReservedBuyer') &&
          ((tmpBrokerAssigned &&
            tmpBrokerAssigned.length &&
            tmpBrokerAssigned[0] !== 'HIDDEN') ||
            (reservedTo && reservedTo.length && reservedTo[0] !== 'HIDDEN') ||
            tmpBrokerAssigned.filter((broker) => broker === _id).length))
      )
    },
    [userObject]
  )

  const initialEmptyValues = {
    stall: null,
    price: null,
    floorNumber: null,
    unit: '',
    status: '',
    inBuilding: null,
    building,
    parkingLevel: null
  }

  const [value, setValues] = useState({
    ...initialEmptyValues
  })

  const STATUS_OPTIONS = [
    ...Object.values(TEMP_AVAILABILITY_STATUS).map((value) => {
      return { value: value, id: value }
    })
  ]

  const isInsideBuildingOptions = [
    { id: 'true', value: 'Yes' },
    { id: 'false', value: 'No' }
  ]

  const filterUniqueValues = useMemo(() => {
    const uniqueValues = {}

    Object.keys(value).forEach((key) => {
      if (value[key] !== editDetails[key]) {
        uniqueValues[key] = value[key]
      }
    })

    return uniqueValues
  }, [value])

  const handleSubmit = () => {
    // if (validate()) {
    setLoading(true)
    const updatedValues = { _id: value?._id ?? '', ...filterUniqueValues }

    createOrUpdateParking(updatedValues)
      .then((data) => {
        refetch()
        setLoading(false)
        setEditDetails(data)
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
      })
    // }
  }

  const onChange = (fieldName, newValue) => {
    setValues({ ...value, [fieldName]: newValue })
  }
  const [loading, setLoading] = useState(false)

  // const validate = () => {
  //   for (const key in initialEmptyValues) {
  //     if (!value[key]) {
  //       return false
  //     }
  //   }
  //   return true
  // }

  useEffect(() => {
    if (editDetails?.inventoryType === 'Parking') {
      setValues({
        ...value,
        ...editDetails,
        status: STATUS_OPTIONS.filter(
          ({ id }) => id === editDetails.status
        )?.[0]?.id,
        inBuilding: editDetails.inBuilding ? 'true' : 'false'
      })
    } else {
      setValues(initialEmptyValues)
    }
  }, [editDetails])

  const handleStatusChange = (id) => {
    const temp = STATUS_OPTIONS.filter((option) => option.id === id)[0]
    onChange('status', temp.id)
  }

  const handleUnitChange = (id) => {
    const temp = unitOptions.filter((option) => option.id === id)[0]
    onChange('unit', temp.id)
  }

  const handleIsInsideBuildingChange = (id) => {
    const temp = isInsideBuildingOptions.filter((option) => option.id === id)[0]
    onChange('inBuilding', temp.id)
  }

  const handleParkingLevelChange = (id) => {
    const temp = allParkingLevels.filter((option) => option.id === id)[0]
    onChange('parkingLevel', temp.id)
  }

  const deletePolygon = () => {
    setLoading(true)
    deleteParkingPolygon(editDetails?.polygonId)
      .then(() => {
        setLoading(false)
        refetch()
        setIsDeleteModal(false)
      })
      .catch((error) => {
        setLoading(false)
        setIsDeleteModal(false)
        console.err(error)
      })
  }

  return (
    <div
      className='p-2 relative flex flex-col h-[calc(100%-32px)] overflow-hidden rounded-lg border border-black/[20%]'
    >
      <div className='p-2 flex flex-col gap-6'>
        <div>
          <h3 className='font-medium text-3xl mb-1'>#{value.stall}</h3>
          <p className='font-medium text-xl mb-0'>Single Stall</p>
        </div>
        <CustomBadge
          title={value.status}
          bgColor={statusColors[value?.status?.toLowerCase()]?.borderColor ?? '#000000'}
        />
      </div>
      <div className='p-2 flex-1 flex flex-col gap-4 overflow-y-auto thin-scrollbar'>
        <CustomInput
          label='Stall'
          placeholder='Enter number'
          labelClassName=''
          value={value.stall}
          onChange={(e) => {
            onChange('stall', e.target.value)
          }}
          type='number'
          // required='required'
          // errorMessage='Please enter stall number'
          containerClasses={undefined}
          classes={undefined}
        />
        <CustomSelect
          label='status'
          inputClasses='h-11'
          labelClasses={undefined}
          options={STATUS_OPTIONS}
          selectedOption={value.status}
          setSelectedOption={(option) => {
            handleStatusChange(option)
          }}
        />
        <CustomInput
          label='price'
          placeholder='Enter amount'
          labelClassName=''
          value={value.price}
          onChange={(e) => {
            onChange('price', e.target.value)
          }}
          type='number'
          // required='required'
          // errorMessage='Please enter price'
          containerClasses={undefined}
          classes={undefined}
        />
        <CustomSelect
          label='Unit'
          inputClasses='h-11'
          labelClasses={undefined}
          options={unitOptions}
          selectedOption={value.unit}
          setSelectedOption={(option) => {
            handleUnitChange(option)
          }}
        />
        <CustomSelect
          label='Parking Level'
          labelClasses='italic'
          inputClasses='h-11'
          options={allParkingLevels}
          setSelectedOption={(value) => {
            handleParkingLevelChange(value)
          }}
          selectedOption={value.parkingLevel}
        />
        <CustomInput
          label='floor number'
          placeholder='Enter number'
          labelClassName=''
          value={value.floorNumber}
          onChange={(e) => {
            onChange('floorNumber', e.target.value)
          }}
          type='number'
          // required='required'
          // errorMessage='Please enter floor number'
          containerClasses={undefined}
          classes={undefined}
        />
        <CustomSelect
          label='Is inside building'
          labelClasses=''
          inputClasses='h-11'
          options={isInsideBuildingOptions}
          setSelectedOption={(value) => {
            handleIsInsideBuildingChange(value)
          }}
          selectedOption={value.inBuilding}
        />
      </div>
      <div className='p-2 mt-2 border-t border-softBlack/[30%] flex flex-col gap-3'>
        <div className='mt-2 flex items-center gap-3'>
          <button
            onClick={closeParkingInfoDrawer}
            className='w-full py-2.5 px-5 text-lg rounded-lg font-medium border border-[#E4E4E7]'
          >
            Cancel
          </button>
          <button
            className='w-full py-2.5 px-5 text-lg bg-[#18181B] border border-[#18181B] text-white rounded-lg font-medium m-auto flex gap-2 items-center justify-center'
            disabled={loading}
            // disabled={!validate() || loading}
            onClick={handleSubmit}
          >
            {loading ? <Oval height='23' width='23' color='white' /> : <Check height='23' width='23' color='white' />}
            <p className='mb-0'>{value?._id ? 'Update' : 'Add'}</p>
          </button>
        </div>
        {
          userObject?.userType === 'DeveloperAdmin' && selectedInventoryView === 'map'
            ? <button
              className='w-full py-2.5 px-5 text-lg bg-[#18181B] border border-[#18181B] text-white rounded-lg font-medium m-auto flex gap-2 items-center justify-center'
              disabled={loading}
              onClick={() => setIsDeleteModal(true)}
            >
              {loading ? <Oval height='23' width='23' color='white' /> : <Trash2 height='23' width='23' color='white' />}
              <p className='mb-0'>Delete</p>
            </button>
            : <button
              className='w-full py-2.5 px-5 text-lg bg-[#18181B] border border-[#18181B] text-white rounded-lg font-medium m-auto flex gap-2 items-center justify-center'
              disabled={loading}
              onClick={() => history.push(ADMIN_OFFER)}
            >
              {loading ? <Oval height='23' width='23' color='white' /> : <ShoppingBag height='23' width='23' color='white' />}
              <p className='mb-0'>Purchase</p>
            </button>
        }
      </div>
      <DeleteModal
        title='Confirm deletion?'
        description={`Are you sure? do you want to delete #${value.stall} parking-polygon?`}
        confirmButtonTitle='Yes, delete it'
        cancelButtonTitle='No'
        isOpen={isDeleteModal}
        setIsOpen={setIsDeleteModal}
        onConfirm={deletePolygon}
        loading={false}
      />
    </div>
  )
}

export default ParkingEditView
