import React from 'react'
import propTypes from 'prop-types'
import { motion } from 'framer-motion'

const PwaWrapper = ({ animate, initial, variants, children, style }) => {
  return (
    <motion.div
      animate={animate}
      initial={initial}
      variants={variants}
      style={style}
      className='h-screen w-screen flex flex-col'
    >
      {
        children
      }
    </motion.div>
  )
}

export default PwaWrapper

PwaWrapper.propTypes = {
  animate: propTypes.bool,
  initial: propTypes.bool,
  variants: propTypes.object,
  children: propTypes.node,
  style: propTypes.object
}
