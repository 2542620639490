import React, { useState } from 'react'
import propTypes from 'prop-types'
import { message, Row } from 'antd'
import { Spin, TButton } from 'ui'
import {
  CardElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js'
import './stripe.css'
import QRCode from 'qrcode.react'

import { StripeForm, StripeWrapper } from './styles'
import standardStyle from 'assets/css/standardStyle'
import { formatMoney } from 'utils'
import { WeChatPayIcon } from 'components/Icons'
import { TSaveButton } from 'components/DefaultButtons'
import { getOffers } from 'store/actions/offerActions'

const cardStyle = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: 'Arial, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#32325d'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
}

const Stripe = (props) => {
  const {
    paymentData: {
      clientSecret,
      payment: {
        captureId,
        wechatQRCode
      }
    },
    offer: {
      unit: {
        unitNumber
      },
      _id: offerId
    },
    chargePrice,
    paymentMethod,
    executeWhenError,
    executeWhenSuccess,
    succeeded
  } = props

  const [processing, setProcessing] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [error, setError] = useState(null)
  const [checkingWeChatPayment, setCheckingWeChatPayment] = useState(false)

  const elements = useElements()
  const stripe = useStripe()

  const handleSubmit = async ev => {
    ev.preventDefault()
    if (!stripe || !elements) {
      return
    }

    setProcessing(true)
    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: ev.target.name.value
        }
      }
    })

    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`)
      setProcessing(false)
    } else {
      setError(null)
      setProcessing(false)

      executeWhenSuccess()
    }
  }

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty)
    setError(event.error ? event.error.message : '')
  }

  const checkWeChatPayment = () => {
    setCheckingWeChatPayment(true)

    if (offerId) {
      getOffers(offerId)
        .then((offer) => {
          const { payments } = offer
          if (payments.length) {
            const payment = payments.filter((payment) => payment.captureId === captureId)

            if (payment.length) {
              setCheckingWeChatPayment(false)

              if (payment[0].status === 'Canceled') {
                message.error('WeChat Pay was refused')
                executeWhenError()
              }

              if (payment[0].status === 'Pending') {
                message.warning('We are still waiting the confirmation of your WeChat Pay')
              }

              if (payment[0].status === 'Completed') {
                executeWhenSuccess()
              }
            }
          }
        })
    } else {
      message.warning('There is no offer to be checked')
    }
  }

  return (
    <StripeWrapper>
      <span className='text-xl'><strong>1st Payment - {unitNumber}</strong></span>
      <span className='text-xl'>You will be charged {formatMoney(chargePrice)}</span>
      {
        paymentMethod === 'WeChat'
          ? (
          <>
              <QRCode
                value={wechatQRCode}
                size={160}
                className='mt-10 mb-5'
              />
              <div className='flex flex-row items-center mb-10'>
                Scan your
                <WeChatPayIcon style={{ color: '#1aad19', fontSize: 35, margin: '0px 3px' }} />
                QR Code
              </div>
              <TSaveButton
                onClick={checkWeChatPayment}
                loading={checkingWeChatPayment}
                className='text-base'
              >
                Check your payment status
              </TSaveButton>
            </>
            )
          : (
          <StripeForm id='payment-form' onSubmit={handleSubmit}>
            <CardElement id='card-element' options={cardStyle} onChange={handleChange} />
            <button
              disabled={processing || disabled || succeeded}
              id='submit'
              className='paybutton'
              style={{ background: standardStyle.colors.primary }}
            >
              <span id='button-text'>
                {processing
                  ? (
                  <Spin spinColor='white' style={{ justifyContent: 'center' }} />
                    )
                  : (
                  <span style={{ color: 'white' }}>Pay</span>
                    )}
              </span>
            </button>
            {/* Show any error that happens when processing the payment */}
            {error && (
              <div className='card-error' role='alert'>
                {error}
              </div>
            )}

            <Row justify='center'>
              <TButton
                className='mt-8'
              >
                Pay Later
              </TButton>
            </Row>
          </StripeForm>
            )
      }

    </StripeWrapper>
  )
}

export default Stripe

Stripe.propTypes = {
  paymentData: propTypes.shape({
    clientSecret: propTypes.string,
    payment: propTypes.shape({
      wechatQRCode: propTypes.string,
      captureId: propTypes.object
    })
  }),
  executeWhenSuccess: propTypes.func,
  executeWhenError: propTypes.func,
  succeeded: propTypes.bool,
  unitNumber: propTypes.string,
  chargePrice: propTypes.string,
  paymentMethod: propTypes.string,
  offer: propTypes.shape({
    unit: propTypes.shape({
      unitNumber: propTypes.string
    }),
    _id: propTypes.string
  })
}
