import { ContactProvider } from 'pages/contacts/context/Contacts'
import React from 'react'
import { CreateOpportunityProvider } from './context/CreateOpportunityContext'
import CreateOpportunityWrapper from './Wrapper'

const CreateOpportunity = () => {
  return (
    <CreateOpportunityProvider>
        <ContactProvider>
          <CreateOpportunityWrapper />
        </ContactProvider>
    </CreateOpportunityProvider>
  )
}

export default CreateOpportunity
