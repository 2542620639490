// @ts-nocheck
import { Select } from 'antd'
import CustomInput from 'components/CustomInput'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import useGetBrokerLeadsDetail from 'hooks/useGetBrokerLeadsDetail'
import useCreateBrokerLeads from 'hooks/useCreateBrokerLeads'
import useGetLeadBrokers from 'hooks/useGetLeadBrokers'
import useGetSalesReps from 'hooks/useGetSalesReps'
import toast from 'react-hot-toast'
import CustomButton from 'components/CustomButton'

const { Option } = Select
const initialStateOfValues = {
  leadBroker: '',
  salesRep: '',
  baseURL: process.env.REACT_APP_BROKER_PAGE_URL,
  sku: ''
}

const NewBrokerLeadPage = () => {
  // @ts-ignore
  const { id } = useParams()
  const history = useHistory()
  const {
    createOrUpdateBrokerLead,
    // loading: isLeadCreationInProgress,
    createdLeadsData
  } = useCreateBrokerLeads()
  // const { buyers, getBuyersData } = useBuyers()
  const {
    data: brokers,
    loading: isBrokersLoading,
    refetchBrokers
  } = useGetLeadBrokers()

  const {
    salesReps,
    loading: isSaleRepLoading,
    refetch: refetchSalesRep
  } = useGetSalesReps()

  const [values, setValues] = useState(initialStateOfValues)
  const selectedBroker = values.leadBroker

  const onChange = (field, value) => {
    setValues({ ...values, [field]: value })
  }

  const projectId = useSelector((state) => state.appReducer.appProject)
  const { leads: brokerLead, refetch: getBrokerLeadsDetail } =
    useGetBrokerLeadsDetail()

  const isCreatingBrokerLead = useMemo(() => {
    return id?.indexOf('new') !== -1
  }, [id])

  useEffect(() => {
    if (!isCreatingBrokerLead) {
      getBrokerLeadsDetail(id)
    }
  }, [id, isCreatingBrokerLead])

  useEffect(() => {
    refetchSalesRep()
    refetchBrokers()
  }, [])

  useEffect(() => {
    const newData = {
      // @ts-ignore
      leadBroker: brokerLead?.leadBroker?._id,
      // @ts-ignore
      salesRep: brokerLead?.salesRep?._id,
      // @ts-ignore
      baseURL: brokerLead?.baseURL,
      // @ts-ignore
      sku: brokerLead?.sku
    }
    setValues({ ...newData })
  }, [brokerLead])

  const handleBrokerLead = async () => {
    const NewLead = {
      ...values
    }
    const toastId = toast.loading('Creating Broker Lead Page...')
    try {
      const result = await createOrUpdateBrokerLead(projectId, NewLead)
      toast.dismiss(toastId)
      toast.success('Broker Lead Created successfully')
      history.replace(`/admin/brokerLeads/${result._id} `)
    } catch {
      toast.dismiss(toastId)
    }
  }

  const onValidate = () => {
    const requiredFields = ['leadBroker', 'salesRep', 'sku']
    for (let i = 0; i < requiredFields.length; i++) {
      if (values[requiredFields[i]] === '') {
        return false
      }
    }
    return true
  }

  const CreatePageButton = () => {
    if (!isCreatingBrokerLead) return null
    return (
      <CustomButton
        className='w-fit'
        handleClick={handleBrokerLead}
        disabled={!onValidate()}
      >
        <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
          Create Page
        </span>
      </CustomButton>
    )
  }

  return (
    <div>
      <div
        className='flex flex-row justify-between align-center w-full p-5 border-b-2 border-black h-full'
        style={{ alignItems: 'center' }}
      >
        <div style={{ fontSize: '40px' }} className='font-bold'>
          <>
            {isCreatingBrokerLead
              ? 'Create Broker Lead Page'
              : 'Broker Lead Page'}
          </>
        </div>
        {selectedBroker && (!isCreatingBrokerLead || createdLeadsData) && (
          <CustomButton
            className='w-fit'
            handleClick={() => history.push('/admin/brokerLeads')}
          >
            <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
              Go to Leads List
            </span>
          </CustomButton>
        )}
        {!(selectedBroker && (!isCreatingBrokerLead || createdLeadsData)) && (
          <>
            <div className='flex flex-row gap-x-4'>
              <CustomButton
                variant='outline'
                className='w-fit'
                handleClick={() => history.push('/admin/brokerLeads')}
              >
                <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
                  Cancel
                </span>
              </CustomButton>
              <CreatePageButton />
            </div>
          </>
        )}
      </div>
      <div className='w-full p-4 h-full'>
        <div className='w-full h-full p-5 bg-white flex flex-row gap-10'>
          <div className=' p-3 text-lg font-bold grid gap-x-12 gap-y-4 h-screen w-full grid-cols-2'>
            <div className='col-span-1'>
              <div className='w-full'>
                <div className='text-lg font-bold'>
                  Broker Info
                  <div className='border-b-2 border-black w-full mt-3'></div>
                </div>
                <div className='mt-6'>
                  <p
                    className={
                      'mt-0 mb-2 text-softBlack70 text-xs font-medium uppercase'
                    }
                    style={{ letterSpacing: '0.16px' }}
                  >
                    Broker Name
                  </p>
                  <Select
                    showSearch
                    loading={isBrokersLoading}
                    placeholder='Select a Broker'
                    value={values.leadBroker}
                    onChange={(e) => onChange('leadBroker', e)}
                    className='text-sm w-full'
                    disabled={!isCreatingBrokerLead}
                  >
                    {brokers?.map((_broker) => (
                      <Option key={_broker?._id} value={_broker?._id}>
                        {_broker?.firstName} {_broker?.lastName}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className='mt-6'>
                  <p
                    className={
                      'mt-0 mb-2 text-softBlack70 text-xs font-medium uppercase'
                    }
                    style={{ letterSpacing: '0.16px' }}
                  >
                    Sales Rep Name
                  </p>
                  <Select
                    showSearch
                    loading={isSaleRepLoading}
                    placeholder='Select a sales rep'
                    value={values.salesRep}
                    onChange={(e) => onChange('salesRep', e)}
                    className='text-sm w-full'
                    disabled={!isCreatingBrokerLead}
                  >
                    {salesReps?.map((_salesRep) => (
                      <Option key={_salesRep?._id} value={_salesRep?._id}>
                        {_salesRep?.firstName} {_salesRep?.lastName}
                      </Option>
                    ))}
                  </Select>
                </div>
                {isCreatingBrokerLead && (
                  <div className='flex w-full justify-stretch items-stretch mt-6 flex-row gap-x-1'>
                    <div className='flex-1'>
                      <CustomInput
                        label='Custom URL*'
                        placeholder='Custom URL'
                        value={values.baseURL}
                        onChange={(e) => onChange('baseURL', e.target.value)}
                        type='text'
                        classes='disabled:opacity-50'
                        disabled
                      />
                    </div>
                    <div className='flex-1'>
                      <CustomInput
                        label='Edit Here*'
                        placeholder='Edit Text Here for custom URL'
                        value={values.sku}
                        onChange={(e) => onChange('sku', e.target.value)}
                        type='text'
                        classes='disabled:opacity-50'
                        disabled={!isCreatingBrokerLead}
                      />
                    </div>
                  </div>
                )}
                {!isCreatingBrokerLead && brokerLead?.url?.length > 0 && (
                  <div className='flex w-full flex-row gap-2 mt-6 items-end'>
                    <div className='flex-1'>
                      <CustomInput
                        label='URL'
                        value={brokerLead?.url}
                        type='text'
                        classes='disabled:opacity-50'
                        disabled
                      />
                    </div>
                    <button
                      className={
                        'flex justify-center items-center p-2 rounded font-bold cursor-pointer w-48 h-11 bg-[#54B5D1]'
                      }
                      onClick={() =>
                        navigator.clipboard.writeText(brokerLead?.url)
                      }
                    >
                      Copy Link
                    </button>
                  </div>
                )}
                <div className='flex justify-end content-end items-end mt-6'>
                  <CreatePageButton />
                </div>
              </div>
            </div>
            <div className='col-span-1 flex flex-col'>
              <p
                className={
                  'mt-0 mb-2 text-softBlack70 text-xs font-medium uppercase'
                }
                style={{ letterSpacing: '0.16px' }}
              >
                Preview Page
              </p>
              <iframe
                title='Vopay'
                src={initialStateOfValues.baseURL}
                width='100%'
                height='100%'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default NewBrokerLeadPage
