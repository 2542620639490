import React, { useState } from 'react'

const FilterRadioGroup = ({ options, currentValue, onValueChange }) => {
  const [values, setValues] = useState(currentValue)

  const onUpdateValues = (_value) => {
    let updatedValues = []
    if (isSelected(_value)) {
      updatedValues = [...values.filter((x) => x !== _value)]
    } else {
      updatedValues = [...values, _value]
    }
    setValues([...updatedValues])
    onValueChange([...updatedValues])
  }

  const isSelected = (_value) => {
    return values.indexOf(_value) !== -1
  }

  return (
    <div className='flex space-x-4 justify-around h-[37px]'>
      {options.map((item, index) => {
        return (
          <button
            value={item.value}
            key={`${item.value}-${index}`}
            className={`flex w-[113px] rounded-sm items-center h-full content-center capitalize
            ${
              isSelected(item.value) ? 'border border-softBlack' : 'border-none'
            }`}
            onClick={() => {
              onUpdateValues(item.value)
            }}
            style={{
              backgroundColor: item.bgColor,
              opacity: isSelected(item.value) ? 1.0 : 0.5
            }}
          >
            <span
              className={
                'w-full text-center font-medium text-sm text-softBlack'
              }
            >
              {item.value}
            </span>
          </button>
        )
      })}
    </div>
  )
}

export default FilterRadioGroup
