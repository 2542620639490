import React, { useCallback, useEffect, useState } from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { message, Popconfirm, Steps } from 'antd'
import { useHistory } from 'react-router-dom'
import { ClipboardCheckIcon, DocumentTextIcon, LockClosedIcon } from '@heroicons/react/outline'
import {
  CreateOfferWrapper,
  CreateOfferStepsWrapper,
  StepsWrapper, CustomStep,
  CarouselWrapper, CarouselContentWrapper,
  ContentsWrapper,
  AnimatedProjectBanner,
  StepDescription
} from './styles'
import {
  BuildingIcon, UserIcon,
  UnitsRealTimeDashboard, CarIcon,
  ColorPalleteIcon,
  FadedAnimatedDiv,
  AllDocsSigned,
  AnimatedPayment,
  OfferPaymentSuccess,
  CreateOfferColorSchemeStep,
  CreateOfferAdditionalOptionsStep,
  CreateOfferParkingStep,
  OfferSummary,
  BrokerIcon,
  OfferDocuments,
  PrivacyConsentStep
} from 'components'
import { QuestionIcon, UnorderedListIcon } from 'components/Icons'
import { AnimatePresence, motion } from 'framer-motion'
import {
  createOfferChangeBuyer, createOfferChangeUnit,
  createOfferChangeParking, createOfferChangeColorScheme,
  createOfferChangeOptions, sendOfferToBuyer,
  createOfferClearReducer, getOffers,
  createOfferChangeBroker, createOfferChangeFintrac,
  createOfferChangeBrokerCommissions
} from 'store/actions/offerActions'
import { H5, TButton, Modal } from 'ui'
import { getProjects } from 'store/actions/projectActions'

import Buyer from './buyer'
import Broker from './broker'
import Fintrac from './Fintrac'

import { TCancelButton } from 'components/DefaultButtons'
import standardStyle from 'assets/css/standardStyle'
import { capitalizeWords, isObject, pluralWords, removeLastCommaFromStrings } from 'utils'

const { Step } = Steps

const scrollVariants = {
  customenter: (direction) => {
    return {
      y: direction > 0 ? 100 : -100,
      opacity: 0
    }
  },
  enter: (direction) => {
    return {
      y: direction > 0 ? 400 : -400,
      opacity: 0
    }
  },
  center: {
    zIndex: 1,
    y: 0,
    opacity: 1
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      y: direction < 0 ? 400 : -400,
      opacity: 0
    }
  },
  customexit: (direction) => {
    return {
      zIndex: 0,
      y: direction < 0 ? 100 : -100,
      opacity: 0
    }
  }
}

// MODAL INNER CONTAINER STATUS
const SHOWING_DOCS = 'showing_docs'
const ALL_DOCS_SIGNED = 'all_docs_signed'
const CHOOSING_PAYMENT_METHOD = 'choosing_payment_method'
const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS'

const StepTitle = ({ title }) => <H5>{title}</H5>

const AnimatedStepDescription = ({ index, children }) => (
  <motion.div
    initial={{ opacity: 0, x: 15 }}
    animate={{ opacity: 1, x: 0 }}
    transition={{ duration: 0.3, delay: index * 0.2 }}
  >
    {children}
  </motion.div>
)

const CreateOffer = (props) => {
  const {
    createOfferChangeUnit, selectedUnit,
    createOfferChangeBuyer, selectedBuyers,
    createOfferChangeBroker, selectedBroker,
    createOfferChangeParking, selectedParking,
    createOfferChangeColorScheme, /* selectedColorScheme, */
    createOfferChangeOptions,
    createOfferChangeFintrac, selectedFintracOptions,
    createOfferChangeBrokerCommissions, brokerCommissionFirstRange,
    brokerCommissionSecondRange,
    createOfferClearReducer, offerId: externalOfferId,
    externalSelectedUnit, appProject
  } = props

  const [projectData, setProjectData] = useState('')
  const [[page, direction], setPage] = useState([0, 0])
  const [selectedOptionDescription, setSelectedOptionDescription] = useState('')
  const [paymentRounds, setPaymentRounds] = useState([])
  const [showingContracts, setShowingContracts] = useState(false)
  const [offerId, setOfferId] = useState('')
  const [offerData, setOfferData] = useState('')
  const [sendingInfosToBuyer, setSendingInfosToBuyer] = useState(false)
  const [gettingContracts, setGettingContracts] = useState(false)
  const [modalInnerContainer, setModalInnerContainer] = useState(SHOWING_DOCS)
  const [alreadyShownCreateOfferAnimation, setAlreadyShownCreateOfferAnimation] = useState()
  const [offerHasMultiBuyers, setOfferHasMultiBuyers] = useState(false)

  const [selectedParkingOption, setSelectedParkingOption] = useState('')
  const [selectedColorScheme, setSelectedColorScheme] = useState('')
  const [selectedOptionsUpgrade, setSelectedOptionsUpgrade] = useState([])

  const [projectHasColorSchemeOptions, setProjectHasColorSchemeOptions] = useState(false)
  const [projectHasParkingOptions, setProjectHasParkingOptions] = useState(false)
  const [projectHasOptionsAndUpgrades, setProjectHasOptionsAndUpgrades] = useState(false)
  const [allowAdditionalServices, setAllowAdditionalServices] = useState(false)
  const [allowSurveys, setAllowSurveys] = useState(false)

  const history = useHistory()

  useEffect(() => {
    const hasOptions = projectData.options && projectData.options.length

    let hasColorSchemeOptions = false
    let hasParkingOptions = false
    let hasOptionsAndUpgrades = false

    if (hasOptions) {
      hasColorSchemeOptions = Boolean(projectData.options.filter(option => option.type === 'color').length)
      hasParkingOptions = Boolean(projectData.options.filter(option => option.type === 'parking').length)
      hasOptionsAndUpgrades = Boolean(projectData.options.filter(option => option.type === 'additionalOptions').length)
    }

    setProjectHasColorSchemeOptions(hasColorSchemeOptions)
    setProjectHasParkingOptions(hasParkingOptions)
    setProjectHasOptionsAndUpgrades(hasOptionsAndUpgrades)
  }, [projectData])

  useEffect(() => {
    if (externalOfferId) {
      setOfferId(externalOfferId)
      setAlreadyShownCreateOfferAnimation(true)
    }
  }, [externalOfferId])

  // if arrived with a unit pre selected, change it in the reducer
  useEffect(() => {
    if (externalSelectedUnit && Object.keys(externalSelectedUnit).length) {
      createOfferChangeUnit(externalSelectedUnit)
    }
  }, [externalSelectedUnit, createOfferChangeUnit])

  useEffect(() => {
    return () => {
      createOfferClearReducer()
    }
  }, [createOfferClearReducer])

  useEffect(() => {
    if (offerData) {
      const { unit, buyer, options } = offerData

      const selectedParkingOptions = options.filter((option) => option.type === 'parking')
      const selectedColorOptions = options.filter((option) => option.type === 'color')
      const selectedAdditionalOptions =
        options
          .map((element) => ({ ...element, selected: true }))
          .filter((option) => option.type === 'additionalOptions')

      createOfferChangeUnit(unit)
      createOfferChangeBuyer(buyer)

      if (selectedColorOptions.length) {
        setSelectedColorScheme(selectedColorOptions[0])
      }

      if (selectedParkingOptions.length) {
        setSelectedParkingOption(selectedParkingOptions[0])
      }

      if (selectedAdditionalOptions.length) {
        setSelectedOptionsUpgrade(selectedAdditionalOptions)
      }
    }
  }, [
    offerData, createOfferChangeBuyer, createOfferChangeParking,
    createOfferChangeUnit, createOfferChangeColorScheme, createOfferChangeOptions
  ])

  // If offer id changed, get the offerData
  useEffect(() => {
    if (offerId) {
      getOffers(offerId)
        .then((response) => {
          setOfferData(response)
        })
        .catch(() => {})
    }
  }, [offerId])

  // If set offerHasMultiBuyers to false, reset the selectedBuyers
  useEffect(() => {
    if (!offerHasMultiBuyers) {
      createOfferChangeBuyer([{ _id: -1 }])
    }
  }, [offerHasMultiBuyers, createOfferChangeBuyer])

  useEffect(() => {
    if (selectedOptionsUpgrade) {
      const tmpSelectedOptionDescription = selectedOptionsUpgrade.reduce((acumulator, option, index) => {
        return acumulator + `${option.name}${index < selectedOptionsUpgrade.length - 1 ? ', ' : ''}`
      }, '')

      setSelectedOptionDescription(tmpSelectedOptionDescription)
    }
  }, [selectedOptionsUpgrade])

  const offerSteps = useCallback(() => {
    const menus = [
      {
        title: <StepTitle title='Unit' />,
        icon: <BuildingIcon />,
        description: <StepDescription>{selectedUnit.unitNumber}</StepDescription>
      },
      {
        title: <StepTitle title={pluralWords(selectedBuyers, 'Buyer')} />,
        icon: <UserIcon />,
        description: (
          <StepDescription>
            {
              removeLastCommaFromStrings(
                selectedBuyers
                  .filter((buyer) => buyer.firstName)
                  .reduce((ac, buyer) => `${ac} ${buyer.firstName} ${buyer.lastName}, `, '')
              )
            }
          </StepDescription>
        )
      },
      {
        title: <StepTitle title='Broker' />,
        icon: <BrokerIcon />,
        description: (
          <StepDescription>
            {
              Object.keys(selectedBroker).length
                ? `${selectedBroker.firstName} ${selectedBroker.lastName}`
                : ''
            }
          </StepDescription>
        )
      },
      {
        title: <StepTitle title='Privacy Consent' />,
        icon: <LockClosedIcon className='h-7 w-7' />,
        description: (
          <StepDescription>
            {
              selectedBuyers && selectedBuyers.filter(buyer => buyer.firstName).length
                ? `Allow additional services: ${allowAdditionalServices ? 'Yes' : 'No'} Allow participate in surveys: ${allowSurveys ? 'Yes' : 'No'}`
                : null
            }
          </StepDescription>
        )
      },
      {
        title: <StepTitle title='Fintrac' />,
        icon: <DocumentTextIcon className='h-7 w-7' />,
        description: (
          <StepDescription>
            {
              Boolean(selectedFintracOptions && Object.keys(selectedFintracOptions).length) && (
                `Third party: ${selectedFintracOptions.thirdPart} 
                 Client risk: ${capitalizeWords(selectedFintracOptions.clientRisk)}
                 Business Relationship: ${selectedFintracOptions.businessRelationship.map(option => capitalizeWords(option.replaceAll('_', ' ')))}
                `
              )
            }
          </StepDescription>
        )
      },
      {
        title: <StepTitle title='Review' />,
        icon: <ClipboardCheckIcon className='h-7 w-7' />
      }
    ]

    if (projectHasOptionsAndUpgrades) {
      menus.splice(3, 0,
        {
          title: <StepTitle title='Options and Upgrades' />,
          icon: <UnorderedListIcon />,
          description: <StepDescription>{selectedOptionDescription}</StepDescription>
        })
    }

    if (projectHasParkingOptions) {
      menus.splice(3, 0,
        {
          title: <StepTitle title='Parking' />,
          icon: <CarIcon />,
          description: (
            <StepDescription>
              {
                Object.keys(selectedParkingOption).length
                  ? `${selectedParkingOption.name} Parking Selected`
                  : ''
              }
            </StepDescription>
          )
        }
      )
    }

    if (projectHasColorSchemeOptions) {
      menus.splice(3, 0,
        {
          title: <StepTitle title='Color Scheme' />,
          icon: <ColorPalleteIcon />,
          description: (
            <StepDescription>
              {
                Object.keys(selectedColorScheme).length
                  ? `${selectedColorScheme.name} Color Scheme Selected`
                  : ''
              }
            </StepDescription>
          )
        }
      )
    }

    return menus
  }, [
    projectHasColorSchemeOptions, projectHasOptionsAndUpgrades, projectHasParkingOptions,
    selectedBroker, selectedBuyers, selectedColorScheme, selectedOptionDescription, selectedParkingOption,
    selectedUnit.unitNumber, allowAdditionalServices, allowSurveys, selectedFintracOptions
  ])

  const animatedCarouselWrapper = useCallback(({ key, component }) => (
    <CarouselWrapper
      key={key}
      custom={direction}
      initial='enter'
      animate='center'
      exit='exit'
      transition={{
        opacity: { duration: 0.2 }
      }}
      variants={scrollVariants}
    >
      {component}
    </CarouselWrapper>
  ), [direction])

  const paginate = useCallback((newDirection) => {
    setPage([page + newDirection, newDirection])
  }, [page])

  const _changeSelectedUnit = useCallback((unit) => {
    // If has a parking selected
    if (Object.entries(selectedParking).length &&
        unit.floorPlan
    ) {
      const tmpObject = { ...selectedParking }
      tmpObject.unitSlots = unit.floorPlan.parkingQty

      createOfferChangeParking(tmpObject)
    }

    createOfferChangeUnit(unit)
    paginate(1)
  }, [createOfferChangeParking, createOfferChangeUnit, paginate, selectedParking])

  const _changeSelectedBuyer = useCallback(({ buyer, indexToUpdate, offerHasMultiBuyers }) => {
    let tmpSelectedBuyers = []

    if (offerHasMultiBuyers) {
      tmpSelectedBuyers = [...selectedBuyers]

      tmpSelectedBuyers.splice(indexToUpdate, 1, { ...buyer })
    } else {
      tmpSelectedBuyers.push(buyer)
      paginate(1)
    }

    // Check if the buyer allowSurveys and setAllowAdditionalServices
    if (tmpSelectedBuyers.length && tmpSelectedBuyers[0].buyerData) {
      const { allowSurveys, allowAdditionalServices } = tmpSelectedBuyers[0].buyerData

      setAllowSurveys(allowSurveys)
      setAllowAdditionalServices(allowAdditionalServices)
    }

    createOfferChangeBuyer(tmpSelectedBuyers)

    // If has a broker assigned to the select project, fill it at this moment
    const projectAssignment = tmpSelectedBuyers
      .map((buyer) =>
        buyer.buyerData && buyer.buyerData.assignments && buyer.buyerData.assignments
          .filter(assignment => assignment.project === appProject && isObject(assignment.broker))[0]
      )

    if (projectAssignment.length && projectAssignment[0]) {
      const brokerParams = { ...projectAssignment[0].broker }
      createOfferChangeBroker(brokerParams)
    }
  }, [
    appProject, createOfferChangeBroker, createOfferChangeBuyer, paginate, selectedBuyers
  ])

  const _changeSelectedBroker = useCallback(({ broker, ...commisionValues }) => {
    createOfferChangeBroker(broker)
    createOfferChangeBrokerCommissions(commisionValues)

    paginate(1)
  }, [createOfferChangeBroker, paginate, createOfferChangeBrokerCommissions])

  const selectParkingOption = useCallback((parkingOption) => {
    setSelectedParkingOption(parkingOption)
    paginate(1)
  }, [setSelectedParkingOption, paginate])

  const selectColorScheme = useCallback((colorScheme) => {
    setSelectedColorScheme(colorScheme)
    paginate(1)
  }, [setSelectedColorScheme, paginate])

  const selectOptionsUpgrade = useCallback((optionsUpgrade) => {
    setSelectedOptionsUpgrade(optionsUpgrade)
    paginate(1)
  }, [setSelectedOptionsUpgrade, paginate])

  const onAddNewBuyerToOffer = useCallback(() => {
    const tmpSelectedBuyers = [...selectedBuyers]

    // Only creates a new item in the array to render at the buyer step
    tmpSelectedBuyers.push({ _id: -1 })

    createOfferChangeBuyer(tmpSelectedBuyers)
  }, [selectedBuyers, createOfferChangeBuyer])

  const removeBuyerFromOffer = useCallback((index) => {
    const tmpSelectedBuyers = [...selectedBuyers]

    // Remove the element based on the index
    tmpSelectedBuyers.splice(index, 1)

    createOfferChangeBuyer(tmpSelectedBuyers)
  }, [selectedBuyers, createOfferChangeBuyer])

  useEffect(() => {
    if (appProject) {
      getProjects(appProject)
        .then((project) => {
          setProjectData(project)
          setPaymentRounds(project.paymentRounds)
        })
        .catch(() => {})
    }
  }, [appProject])

  const renderUnitsRealTimeDashboard = useCallback(() => {
    return (
      <UnitsRealTimeDashboard
        executeOnSelectUnit={(unit) => _changeSelectedUnit(unit)}
        executeOnSelectReservedUnit={(buyer) => _changeSelectedBuyer({ buyer, index: 0, offerHasMultiBuyers: false })}
        offerId={offerId}
        selectedUnit={selectedUnit}
        renderProjectSelector={false}
        title={offerId ? 'Unit Selected' : 'Select Unit'}
      />
    )
  }, [_changeSelectedBuyer, _changeSelectedUnit, offerId, selectedUnit])

  const selectFintrac = useCallback((fintracOptions) => {
    createOfferChangeFintrac(fintracOptions)
    paginate(1)
  }, [createOfferChangeFintrac, paginate])

  const pages = useCallback(() => {
    const pages = [
      animatedCarouselWrapper({
        key: 'units',
        component: (
            <CarouselContentWrapper
              style={{
                width: '90%',
                height: '90%'
              }}
            >
              {renderUnitsRealTimeDashboard()}
            </CarouselContentWrapper>
        )
      }),
      animatedCarouselWrapper({
        key: 'buyer',
        component: (
            <CarouselContentWrapper
              style={{
                width: '90%',
                height: '90%'
              }}
            >
              <Buyer
                executeOnSelectBuyer={({ indexToUpdate, offerHasMultiBuyers, ...buyer }) => _changeSelectedBuyer({ buyer, indexToUpdate, offerHasMultiBuyers })}
                executeOnAddNewBuyerToOffer={() => onAddNewBuyerToOffer()}
                executeOnRemoveBuyerFromOffer={(indexToRemove) => removeBuyerFromOffer(indexToRemove)}
                offerHasMultiBuyers={offerHasMultiBuyers}
                onChangeMultiBuyers={() => setOfferHasMultiBuyers(!offerHasMultiBuyers)}
                offerId={offerId}
              />
            </CarouselContentWrapper>
        )
      }),
      animatedCarouselWrapper({
        key: 'broker',
        component: (
            <CarouselContentWrapper
              style={{
                width: '90%',
                height: '90%'
              }}
            >
              <Broker
                executeOnSelectBroker={(broker) => _changeSelectedBroker(broker)}
              />
            </CarouselContentWrapper>
        )
      }),
      animatedCarouselWrapper({
        key: 'privacyConsent',
        component: (
            <CarouselContentWrapper
              style={{
                width: '90%',
                height: '90%'
              }}
            >
              <PrivacyConsentStep
                selectedBuyers={selectedBuyers}
                onClickContinue={({ allowSurveys, allowAdditionalServices }) => {
                  setAllowSurveys(allowSurveys)
                  setAllowAdditionalServices(allowAdditionalServices)
                  paginate(1)
                }}
              />
            </CarouselContentWrapper>
        )
      }),
      animatedCarouselWrapper({
        key: 'fintrac',
        component: (
            <CarouselContentWrapper
              style={{
                width: '90%',
                height: '90%'
              }}
            >
              <Fintrac onContinueClick={selectFintrac}/>
            </CarouselContentWrapper>
        )
      }),
      animatedCarouselWrapper({
        key: 'review',
        component: (
            <CarouselContentWrapper
              style={{
                width: '90%',
                height: '90%'
              }}
            >
              <OfferSummary
                projectData={projectData}
                paymentRounds={paymentRounds}
                selectedUnit={selectedUnit}
                selectedBuyers={selectedBuyers}
                selectedBroker={selectedBroker}
                selectedParking={selectedParkingOption}
                selectedColorScheme={selectedColorScheme}
                selectedAdditionalOptions={selectedOptionsUpgrade}
                selectedFintracOptions={selectedFintracOptions}
                brokerCommissionFirstRange={brokerCommissionFirstRange}
                brokerCommissionSecondRange={brokerCommissionSecondRange}
                offerId={offerId}
                executeWhenOfferCreated={({ offerData }) => {
                  const { _id: offerId } = offerData

                  setOfferId(offerId)
                }}
                alreadyShownCreateOfferAnimation={alreadyShownCreateOfferAnimation}
                onClickShowDocs={({ paymentRounds }) => {
                  setPaymentRounds(paymentRounds)
                  setShowingContracts(true)
                  setAlreadyShownCreateOfferAnimation(true)
                }}
                modalInnerContainer={modalInnerContainer}
                allowDepositsEdition={false}
                saveButtonClassName='h-9 text-lg'
              />
            </CarouselContentWrapper>
        )
      })
    ]

    if (projectHasOptionsAndUpgrades) {
      pages.splice(3, 0,
        animatedCarouselWrapper({
          key: 'options',
          component: (
              <CarouselContentWrapper
                style={{
                  width: '90%',
                  height: '90%'
                }}
              >
                <CreateOfferAdditionalOptionsStep
                  projectData={projectData}
                  onSelectOptionUpgrade={(optionUpgrade) => selectOptionsUpgrade(optionUpgrade)}
                  selectedOptionsUpgrade={selectedOptionsUpgrade}
                  offerId={offerId}
                  titleClassName='text-2xl'
                  titleWrapperClassName='justify-center'
                  buttonsClassName='text-base font-semibold px-4 mx-4 md:mx-10'
                  buttonSize='small'
                  numberOfItemsToSlide={2}
                />
              </CarouselContentWrapper>
          )
        })
      )
    }

    if (projectHasParkingOptions) {
      pages.splice(3, 0,
        animatedCarouselWrapper({
          key: 'parking',
          component: (
              <CarouselContentWrapper
                style={{
                  width: '90%',
                  height: '90%'
                }}
              >
                <CreateOfferParkingStep
                  projectData={projectData}
                  onSelectParkingOption={(parkingOption) => selectParkingOption(parkingOption)}
                  selectedUnit={selectedUnit}
                  selectedParkingOption={selectedParkingOption}
                  offerId={offerId}
                  pageWithSideImage={false}
                  titleClassName='text-2xl'
                  titleWrapperClassName='justify-center'
                  buttonsClassName='text-base font-semibold px-4 mx-4 md:mx-10'
                  buttonSize='small'
                  numberOfItemsToSlide={2}
                />
              </CarouselContentWrapper>
          )
        })
      )
    }

    if (projectHasColorSchemeOptions) {
      pages.splice(3, 0,

        animatedCarouselWrapper({
          key: 'colorScheme',
          component: (
            <CarouselContentWrapper
              style={{
                width: '90%',
                height: '90%'
              }}
            >
              <CreateOfferColorSchemeStep
                projectData={projectData}
                onSelectColorScheme={(colorScheme) => selectColorScheme(colorScheme)}
                selectedColorScheme={selectedColorScheme}
                offerId={offerId}
                titleClassName='text-2xl'
                titleWrapperClassName='justify-center'
                buttonsClassName='text-base font-semibold px-4 mx-4 md:mx-10'
                buttonSize='small'
                numberOfItemsToSlide={2}
              />
            </CarouselContentWrapper>
          )
        })

      )
    }

    return pages
  }, [
    _changeSelectedBroker, _changeSelectedBuyer, alreadyShownCreateOfferAnimation, animatedCarouselWrapper, modalInnerContainer,
    offerHasMultiBuyers, offerId, onAddNewBuyerToOffer, paymentRounds, projectData, projectHasColorSchemeOptions,
    projectHasOptionsAndUpgrades, projectHasParkingOptions, removeBuyerFromOffer, renderUnitsRealTimeDashboard,
    selectColorScheme, selectOptionsUpgrade, selectParkingOption, selectedBroker, selectedBuyers, selectedColorScheme,
    selectedOptionsUpgrade, selectedParkingOption, selectedUnit, paginate, selectFintrac, selectedFintracOptions,
    brokerCommissionFirstRange, brokerCommissionSecondRange
  ])

  const changePage = (current) => {
    paginate(current - page)
  }

  const sendContractsToBuyer = useCallback(() => {
    // Will send the offer/contracts to the buyer
    setSendingInfosToBuyer(true)
    sendOfferToBuyer(offerId)
      .then(() => {
        setTimeout(() => {
          createOfferClearReducer()
          setSendingInfosToBuyer(false)
          history.goBack()
        }, 1000)
      })
      .catch(() => {
        setSendingInfosToBuyer(false)
      })
  }, [createOfferClearReducer, history, offerId])

  const renderStepsWrapper = () => {
    return (
      <StepsWrapper>

        <CustomStep
          current={page}
          direction='vertical'
          size='small'
          onChange={changePage}
        >

          {
            offerSteps().map((item, index) =>
              <Step
                key={item.title}
                title={item.title}
                icon={item.icon}
                description={<AnimatedStepDescription index={index}>{item.description}</AnimatedStepDescription>}
              />
            )
          }
        </CustomStep>
      </StepsWrapper>
    )
  }

  const SecondModalButton = () => {
    let popConfirmTitle = 'Confirms sending the contracts to the buyer?'
    let onClick = sendContractsToBuyer
    let buttonLabel = 'Send Contracts to Buyer'

    if (modalInnerContainer === CHOOSING_PAYMENT_METHOD) {
      popConfirmTitle = popConfirmTitle.replace('contracts', 'payment')
      onClick = () => message.info('We are working on that :)')
      buttonLabel = buttonLabel.replace('Contracts', 'Payment')
    }

    return (
      <Popconfirm
        key='reserve'
        title={popConfirmTitle}
        okText='Yes'
        cancelText='No'
        placement='top'
        onConfirm={onClick}
        icon={
          <QuestionIcon style={{ color: standardStyle.colors.primary }} />
        }
      >
        <TButton
          className='text-base'
          loading={sendingInfosToBuyer}
        >
          {buttonLabel}
        </TButton>
      </Popconfirm>
    )
  }

  const stepsContents = () => {
    return (
      <ContentsWrapper className='flex flex-col items-center justify-center flex-1 h-full overflow-hidden'>
        <AnimatePresence exitBeforeEnter initial={false} custom={direction}>
          {pages()[page]}
          {/* {renderUnitsRealTimeDashboard()} */}
        </AnimatePresence>
      </ContentsWrapper>
    )
  }

  return (
    <CreateOfferWrapper>
      {projectData && (
        <AnimatePresence initial={false} custom={direction}>
          <AnimatedProjectBanner
            key={page}
            src={projectData.images[page]}
            custom={direction}
            variants={scrollVariants}
            initial='customenter'
            animate='center'
            exit='customexit'
            transition={{
              y: { type: 'spring', stiffness: 800, damping: 110, delay: 0.1 },
              opacity: { duration: 0.2, delay: 0.2 }
            }}
          />
        </AnimatePresence>
      )}

      <CreateOfferStepsWrapper>
        {renderStepsWrapper()}

        {stepsContents()}
      </CreateOfferStepsWrapper>

      <Modal
        showModal={showingContracts}
        modalType='custom'
        canDismiss={false}
        cancelText='Close'
        fullHeight
        fullWidth
        extraButtons={
          ((!gettingContracts) &&
           ((modalInnerContainer === SHOWING_DOCS) ||
            (modalInnerContainer === CHOOSING_PAYMENT_METHOD)
           )
          )
            ? [
              <SecondModalButton key='secondModalButton' />,
              <TCancelButton
                key='cancel'
                onClick={() => setShowingContracts(false)}
              >
                Go back to Offer Review
              </TCancelButton>
              ]
            : null
        }
        onCancel={() => setShowingContracts(false)}
        showPaddingHorizontal={modalInnerContainer !== SHOWING_DOCS || gettingContracts}
      >
        <AnimatePresence exitBeforeEnter initial={false}>
          {
            modalInnerContainer === SHOWING_DOCS && (
              <FadedAnimatedDiv
                key='contractsReview'
                style={{ height: '100%' }}
              >
                <OfferDocuments
                  offerId={offerId}
                  onGettingContracts={(gettingContracts) => {
                    setGettingContracts(gettingContracts)
                  }}
                  // executeWhenAllDocsSigned={() => setModalInnerContainer(ALL_DOCS_SIGNED)}
                />
              </FadedAnimatedDiv>

            )
          }

          {
            modalInnerContainer === ALL_DOCS_SIGNED && (
              <AllDocsSigned
                nextButtonClassName='h-9 text-lg'
                onNextButtonClick={() => setModalInnerContainer(CHOOSING_PAYMENT_METHOD) }
              />
            )
          }

          {
            modalInnerContainer === CHOOSING_PAYMENT_METHOD && (
              <AnimatedPayment
                offer={offerData}
                doneButtonClassName='h-9 text-lg'
                executeAfterSuccessPage={() => {
                  createOfferClearReducer()
                  setSendingInfosToBuyer(false)
                  history.goBack()
                }}
              />
            )
          }

          {
            modalInnerContainer === PAYMENT_SUCCESS && (
              <FadedAnimatedDiv
                key='allDocsSigned'
                style={{
                  height: '100%',
                  width: '100%',
                  padding: 20
                }}
              >
                <OfferPaymentSuccess
                  offer={offerData}
                  executeOnFinish={() => {
                    createOfferClearReducer()
                    setSendingInfosToBuyer(false)
                    history.goBack()
                  }}
                />
              </FadedAnimatedDiv>
            )
          }
        </AnimatePresence>
      </Modal>
    </CreateOfferWrapper>
  )
}

const mapStateToProps = (state) => ({
  selectedUnit: state.createOfferReducer.selectedUnit,
  selectedBuyers: state.createOfferReducer.selectedBuyers,
  selectedBroker: state.createOfferReducer.selectedBroker,
  selectedParking: state.createOfferReducer.selectedParking,
  selectedColorScheme: state.createOfferReducer.selectedColorScheme,
  selectedFintracOptions: state.createOfferReducer.selectedFintracOptions,
  selectedAdditionalOptions: state.createOfferReducer.selectedAdditionalOptions,
  appProject: state.appReducer.appProject,
  brokerCommissionFirstRange: state.createOfferReducer.brokerCommissionFirstRange,
  brokerCommissionSecondRange: state.createOfferReducer.brokerCommissionSecondRange
})

const mapDispatchToProps = {
  createOfferChangeUnit,
  createOfferChangeBuyer,
  createOfferChangeBroker,
  createOfferChangeParking,
  createOfferChangeColorScheme,
  createOfferChangeOptions,
  createOfferChangeFintrac,
  createOfferChangeBrokerCommissions,
  createOfferClearReducer
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateOffer)

CreateOffer.propTypes = {
  appProject: propTypes.string,
  createOfferChangeUnit: propTypes.func,
  selectedUnit: propTypes.object,
  createOfferChangeBuyer: propTypes.func,
  selectedBuyers: propTypes.object,
  createOfferChangeBroker: propTypes.func,
  selectedBroker: propTypes.object,
  createOfferChangeParking: propTypes.func,
  selectedParking: propTypes.object,
  createOfferChangeColorScheme: propTypes.func,
  createOfferChangeOptions: propTypes.func,
  createOfferChangeFintrac: propTypes.func,
  createOfferChangeBrokerCommissions: propTypes.func,
  selectedFintracOptions: propTypes.object,
  createOfferClearReducer: propTypes.func,
  offerId: propTypes.string,
  externalSelectedUnit: propTypes.object,
  brokerCommissionFirstRange: propTypes.number,
  brokerCommissionSecondRange: propTypes.number
}

StepTitle.propTypes = {
  title: propTypes.string
}

AnimatedStepDescription.propTypes = {
  index: propTypes.number,
  children: propTypes.node
}
