import Selection from 'pages/dashboard/components/Selection'
import React, { useMemo, useState } from 'react'
import {
  timeOptions,
  countriesOptions,
  brokerOptions
} from 'pages/dashboard/components/constants'
export const StatsHorizontal = ({
  stats,
  title,
  classes,
  tabClasses,
  showPercentage = false,
  showTime = false,
  showCountries = false,
  showBrokers = false,
  timeInTitle = false,
  padding = ''
}) => {
  const [time, setTime] = useState(timeOptions?.[0]?.value)
  const [country, setCountry] = useState(countriesOptions?.[0]?.value)
  const [broker, setBroker] = useState(brokerOptions?.[0]?.value)

  const sum = useMemo(() => {
    let _sum = 0
    stats.forEach(({ units }) => (_sum = _sum + units))
    return _sum
  }, [stats])

  const length = useMemo(() => {
    return Object.keys(stats).length - 1
  }, [stats])

  return (
    <div
      className={`flex flex-col gap-10 bg-grey-8 w-full ${
        padding?.length > 0 ? padding : 'p-6'
      }`}
      style={{ borderRadius: 4 }}
    >
      <div className='font-bold text-xl border-b border-softBlack pb-4  flex justify-between items-center'>
        <div className='capitalize'>
          {title} {showTime && timeInTitle && time}{' '}
        </div>

        <div className='flex gap-6'>
          {showCountries && (
            <Selection
              value={country}
              onChangeValue={(e) => setCountry(e.target.value)}
              Options={countriesOptions}
            />
          )}
          {showBrokers && (
            <Selection
              value={broker}
              onChangeValue={(e) => setBroker(e.target.value)}
              Options={brokerOptions}
            />
          )}
          {showTime && (
            <Selection
              value={time}
              onChangeValue={(e) => setTime(e.target.value)}
              Options={timeOptions}
            />
          )}
        </div>
      </div>

      <div className={`flex ${classes} items-center`}>
        {stats.map((stat, index) => (
          <div
            key={index}
            className={`pl-3 ${tabClasses}`}
            style={{
              borderLeftWidth: 4,
              borderColor: stat.color
            }}
          >
            <div
              className='text-lg font-bold text-softBlack'
              style={{ marginBottom: 6 }}
            >
              {stat.units}
              {showPercentage && '%'}
            </div>
            <div className='text-xs font-medium text-softBlack70 uppercase'>
              {stat.title}
            </div>
          </div>
        ))}
      </div>

      <div className='flex h-12'>
        {stats.map((stat, index) => (
          <div
            key={index}
            className={`h-full ${index === 0 && 'rounded-l-md'} ${
              index === length && 'rounded-r-md'
            }`}
            style={{
              width: `${(stat.units / sum) * 100}%`,
              backgroundColor: stat.color
            }}
          ></div>
        ))}
      </div>
    </div>
  )
}
