import React from 'react'
import { useHistory } from 'react-router-dom'

import standardStyle from 'assets/css/standardStyle'
import { ADMIN_EDIT_BUILDING } from 'routes'
import { TLocationPinIcon } from 'components/Icons'

const BuildingCard = ({
  redirectToDetailPage = true,
  imgsrc,
  propertyName,
  price,
  noOfUnits,
  address,
  index,
  id,
  containerClasses = 'p-8'
}) => {
  const history = useHistory()
  return (
    <div
      className={`rounded-md flex flex-col text-gray-500 ${containerClasses} ${
        index % 2 === 0 && 'pr-7'
      }`}
      style={{ fontFamily: standardStyle.fonts.sfpro }}
      onClick={() =>
        redirectToDetailPage && history.push({ pathname: ADMIN_EDIT_BUILDING, state: { id } })
      }
    >
      <img src={imgsrc} alt='building' className='h-80 w-full' />
      <div className='border-gray-400 w-full h-4 border-t-2 mb-2 my-4'></div>
      <div className='font-bold text-2xl text-softBlack font-openSans capitalize'>
        {propertyName}
      </div>
      <div className='my-2 flex items-center font-light italic gap-2 text-softBlack text-sm font-openSans leading-none'>
        {price} / {noOfUnits} {noOfUnits > 0 ? 'units' : 'unit'}
      </div>
      {address && (
        <div className='flex items-center gap-2 text-softBlack font-normal text-base font-openSans leading-none'>
          <TLocationPinIcon className='w-4 h-4' />
          {address}
        </div>
      )}
    </div>
  )
}

export default BuildingCard
