// @ts-nocheck
/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { getNylasSchedulerSessionID } from 'store/actions/usersActions';
import { isAuthenticated } from 'services/auth'
import {
  NylasSchedulerEditor,
  NylasScheduling,
  NylasCalendarPicker,
  NylasListConfigurations,
} from '@nylas/react';
import { LOGIN } from 'routes';
import { Redirect } from 'react-router-dom';

const BookMeeting = () => {
  const [loading, setLoading] = useState(true);
  const [configId, setConfigId] = useState(null);
  const [sessionId, setSessionId] = useState('');
  const [rescheduleBookingRef, setRescheduleBookingRef] = useState('');
  const [cancleBookingRef, setCancleBookingRef] = useState('');

  useEffect(()=>{
    if (!isAuthenticated()) {
      return <Redirect to={LOGIN} />
    }
  },[])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const configId = urlParams.get('config_id') || '';
    setConfigId(configId);
    setLoading(false);
  }, []);

  // useEffect(() => {
  //   setLoading(true);
  //   if (configId) {
  //     getNylasSchedulerSessionID(configId).then((data) => {
  //       setSessionId(data.accessToken.session_id);
  //     });
  //   }
  //   setLoading(false);
  // }, [configId]);

  return (
    <div className='p-2 flex flex-col items-center min-h-[100vh] bg-[rgb(206,206,204)]  gap-y-6 pt-6'>
        <p className='uppercase text-[32px]'>Book a meeting</p>
        {loading && <p>Loading...</p>}
        {!loading && configId && (
          <NylasScheduling
            schedulerApiUrl='https://api.us.nylas.com'
            onNylasSchedulerError={(error) => console.error(error)}
            onBookingCreated={(bookingRef) => console.log('Booking created', bookingRef)}
            cancelBookingRef={cancleBookingRef}
            slug={configId}
          />
        )}
    </div>
  );
};

export default BookMeeting;
