import { Upload } from 'antd'
import styled from 'styled-components'

export const UploadWrapper = styled.div`
  /* height: 150px; 
  width: 150px; */
`

export const CustomUpload = styled(Upload)`

`
