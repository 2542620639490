import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import {
  PageWrapper,
  TitleWrapper,
  ThirPartyButtonsWrapper,
  ClientRiskWrapper,
  ClientRiskButtonsWrapper,
  BusinessRelationshipWrapper,
  BusinessRelationshipOptionsWrapper
} from './styles'
import propTypes from 'prop-types'
import { FadedAnimatedDiv, GridFormWrapper, OfferFooterButtons, TCancelButton, TDeleteButton, TSaveButton } from 'components'
import { classNames, validateRequiredInputs } from 'utils'
import { Checkbox, InputLabel } from 'ui'
import { AnimatePresence } from 'framer-motion'
import { message } from 'antd'
import { connect } from 'react-redux'

const Title = memo(() => (
  <TitleWrapper className='w-full text-center mt-11'>
    <span className='text-2xl'>Fintrac</span>
  </TitleWrapper>
))

const buttonClass = 'transform duration-75 scale-125 ease-in'

const ThirdPartyButtons = memo(({ thirdPart, onSelectYes, onSelectNo }) => (
  <ThirPartyButtonsWrapper className='flex flex-row items-center space-x-5 my-5'>
    <TSaveButton onClick={onSelectYes} className={classNames(thirdPart === 'Yes' && buttonClass, 'text-lg')}>Yes</TSaveButton>
    <TDeleteButton onClick={onSelectNo} className={classNames(thirdPart !== '' && thirdPart === 'No' && buttonClass, 'text-lg')}>No</TDeleteButton>
  </ThirPartyButtonsWrapper>
))

const ThirdPartyDescription = memo(({ value, onChange }) => (
  <InputLabel
    id='thirPartyDescription'
    key='thirPartyDescription'
    value={value}
    typeInput='textarea'
    onChange={(e) => onChange(e.target.value)}
    placeholder='Describe why you think your client may be acting on behalf a third party'
  />
))

const ThirdPartyRecord = memo(({ onChangeThirdPartyRecord, selectedFintracOptions }) => {
  const [name, setName] = useState('')
  const [address, setAddress] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [birthday, setBirthday] = useState('')
  const [occupation, setOccupation] = useState('')
  const [incorporationNumber, setIncorporationNumber] = useState('')
  const [incorporationJurisdiction, setIncorporationJurisdiction] = useState('')
  const [incorporationCountry, setIncorporationCountry] = useState('')
  const [relationship, setRelationship] = useState('')

  useEffect(() => {
    if (selectedFintracOptions && Object.keys(selectedFintracOptions).length) {
      const {
        name,
        address,
        phoneNumber,
        birthday,
        occupation,
        incorporationNumber,
        incorporationJurisdiction,
        incorporationCountry,
        relationship
      } = selectedFintracOptions.thirdPartRecord

      setName(name)
      setAddress(address)
      setPhoneNumber(phoneNumber)
      setBirthday(birthday)
      setOccupation(occupation)
      setIncorporationNumber(incorporationNumber)
      setIncorporationJurisdiction(incorporationJurisdiction)
      setIncorporationCountry(incorporationCountry)
      setRelationship(relationship)
    }
  }, [selectedFintracOptions])

  useEffect(() => {
    onChangeThirdPartyRecord({
      name,
      address,
      phoneNumber,
      birthday,
      occupation,
      incorporationNumber,
      incorporationJurisdiction,
      incorporationCountry,
      relationship
    })
  }, [
    onChangeThirdPartyRecord,
    name,
    address,
    phoneNumber,
    birthday,
    occupation,
    incorporationNumber,
    incorporationJurisdiction,
    incorporationCountry,
    relationship,
    selectedFintracOptions
  ])

  return (
    <FadedAnimatedDiv style={{ marginTop: 40 }} key='thirdPartyRecord'>
      <span className='text-xl'>Third Party Record</span>

      <GridFormWrapper className='mt-3'>
        <InputLabel
          id='thirdPartName'
          key='thirdPartName'
          value={name}
          onChange={(e) => setName(e.target.value)}
          label='Name of other entity'
          // placeholder='Name of other entity'
          xs={24}
          sm={24}
          md={6}
          lg={6}
          required
          validateKey='thirdPartyRecord'
        />
        <InputLabel
          id='thirdPartAddress'
          key='thirdPartAddress'
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          label='Address'
          // placeholder='Address'
          xs={24}
          sm={24}
          md={6}
          lg={6}
          required
          validateKey='thirdPartyRecord'
        />
        <InputLabel
          id='thirdPartPhoneNumber'
          key='thirdPartPhoneNumber'
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          label='Phone number'
          // placeholder='Phone number'
          xs={24}
          sm={24}
          md={6}
          lg={6}
          required
          validateKey='thirdPartyRecord'
        />
        <InputLabel
          id='thirdPartBirthday'
          key='thirdPartBirthday'
          value={birthday}
          typeInput='datepicker'
          onChange={(e) => setBirthday(e)}
          label='Date of birth (if applicable)'
          // placeholder='Date of birth (if applicable)'
          xs={24}
          sm={24}
          md={6}
          lg={6}
        />
        <InputLabel
          id='thirdPartOccupation'
          key='thirdPartOccupation'
          value={occupation}
          onChange={(e) => setOccupation(e.target.value)}
          label='Nature of Principal Business or Occupation'
          // placeholder='Nature of Principal Business or Occupation'
          xs={24}
          sm={24}
          md={24}
          lg={24}
          required
          validateKey='thirdPartyRecord'
        />
        <InputLabel
          id='thirdPartIncorporationNumber'
          key='thirdPartIncorporationNumber'
          value={incorporationNumber}
          onChange={(e) => setIncorporationNumber(e.target.value)}
          label='Registration or incorporation number (if applicable)'
          // placeholder='Registration or incorporation number (if applicable)'
          xs={24}
          sm={24}
          md={8}
          lg={8}
        />
        <InputLabel
          id='thirdPartIncorporationJurisdiction'
          key='thirdPartIncorporationJurisdiction'
          value={incorporationJurisdiction}
          onChange={(e) => setIncorporationJurisdiction(e.target.value)}
          label='Jurisdiction that issued that number (if applicable)'
          // placeholder='Jurisdiction that issued that number (if applicable)'
          xs={24}
          sm={24}
          md={8}
          lg={8}
        />
        <InputLabel
          id='thirdPartIncorporationCountry'
          key='thirdPartIncorporationCountry'
          value={incorporationCountry}
          onChange={(e) => setIncorporationCountry(e.target.value)}
          label='Country that issued that number (if applicable)'
          // placeholder='Country that issued that number (if applicable)'
          xs={24}
          sm={24}
          md={8}
          lg={8}
        />
        <InputLabel
          id='thirdPartRelationship'
          key='thirdPartRelationship'
          value={relationship}
          onChange={(e) => setRelationship(e.target.value)}
          label='Relationship between third party and client'
          // placeholder='Relationship between third party and client'
          xs={24}
          sm={24}
          md={24}
          lg={24}
          required
          validateKey='thirdPartyRecord'
        />
      </GridFormWrapper>
    </FadedAnimatedDiv>
  )
})

const lowRiskOptions = [
  { id: 'resident_present', value: 'Canadian Citizen or Resident Physically Present' },
  { id: 'resident_not_present', value: 'Canadian Citizen or Resident Not Physically Present' },
  { id: 'resident_high_crime_area', value: 'Canadian Citizen or Resident – High Crime Area – No Other Higher Risk Factors Evident' },
  { id: 'foreign_citizen', value: 'Foreign Citizen or Resident that does not Operate in a High Risk Country (physically present or not)' },
  { id: 'explain', value: 'Other, explain:' }
]

const mediumRiskOptions = [
  { id: 'explain', value: 'Explain' }
]
const highRiskOptions = [
  { id: 'foreign_citizen', value: 'Foreign Citizen or Resident that operates in a High Risk Country (physically present or not) ' },
  { id: 'explain', value: 'Other Explain' }
]

const ClientRisk = memo(({ onOptionsChange, selectedFintracOptions }) => {
  const [clientRisk, setClientRisk] = useState('')
  const [optionsData, setOptionsData] = useState('')
  const [selectedRiskOption, setSelectedRiskOption] = useState('')
  const [riskOptionExplanation, setRiskOptionExplanation] = useState('')

  useEffect(() => {
    if (selectedFintracOptions && Object.keys(selectedFintracOptions).length) {
      const { clientRisk } = selectedFintracOptions

      let tmpOption = ''
      let tmpOptionExplanation = ''

      if (clientRisk === 'Low') {
        tmpOption = selectedFintracOptions.lowOption
        tmpOptionExplanation = selectedFintracOptions.lowOptionExplanation
      }

      if (clientRisk === 'Medium') {
        tmpOption = selectedFintracOptions.mediumOption
        tmpOptionExplanation = selectedFintracOptions.mediumRiskExplanation
      }

      if (clientRisk === 'High') {
        tmpOption = selectedFintracOptions.highOption
        tmpOptionExplanation = selectedFintracOptions.highRiskExplanation
      }

      setClientRisk(clientRisk)
      setSelectedRiskOption(tmpOption)
      setRiskOptionExplanation(tmpOptionExplanation)
    }
  }, [selectedFintracOptions])

  useEffect(() => {
    onOptionsChange({
      clientRisk,
      selectedRiskOption,
      riskOptionExplanation
    })
  }, [
    onOptionsChange,
    clientRisk,
    selectedRiskOption,
    riskOptionExplanation
  ])

  useEffect(() => {
    if (clientRisk === 'Low') setOptionsData(lowRiskOptions)

    if (clientRisk === 'Medium') {
      setOptionsData(mediumRiskOptions)
      setSelectedRiskOption('explain')
    }

    if (clientRisk === 'High') setOptionsData(highRiskOptions)
  }, [clientRisk])

  return (
    <ClientRiskWrapper className='mt-10 flex flex-col'>
      <span className='text-xl font-bold'>Client Risk</span>
      <span className='text-base'>
        Determine the level of risk of a money laundering or terrorist financing offence for this client by determining the
        appropriate cluster of client in your policies and procedures manual this
        client falls into and choose one of the options below.
      </span>
      <ClientRiskButtonsWrapper className='mt-5 space-x-5 '>
        <TSaveButton
          className={classNames(clientRisk === 'Low' && buttonClass, 'text-lg')}
          onClick={() => {
            setClientRisk('Low')
            setSelectedRiskOption('')
          }}
        >
          Low Risk
        </TSaveButton>
        <TCancelButton
          className={classNames(clientRisk === 'Medium' && buttonClass, 'text-lg')}
          onClick={() => {
            setClientRisk('Medium')
            setSelectedRiskOption('')
          }}
        >
          Medium Risk
        </TCancelButton>
        <TDeleteButton
          className={classNames(clientRisk === 'High' && buttonClass, 'text-lg')}
          onClick={() => {
            setClientRisk('High')
            setSelectedRiskOption('')
          }}
        >
          High Risk
        </TDeleteButton>
      </ClientRiskButtonsWrapper>

      {
        Boolean(optionsData.length) && (
          <InputLabel
            id='risksOptions'
            key='risksOptions'
            label={`${clientRisk} risk options`}
            typeInput='select'
            data={optionsData}
            value={selectedRiskOption}
            onChange={(e) => {
              setSelectedRiskOption(e)
              setRiskOptionExplanation('')
            }}
            inputWrapperClassName='mt-5'
            placeholder='Select an option'
          />
        )
      }

      {
        selectedRiskOption === 'explain' && (
          <InputLabel
            id='riskOptionExplanation'
            key='riskOptionExplanation'
            label='Explain'
            typeInput='textarea'
            value={riskOptionExplanation}
            onChange={(e) => setRiskOptionExplanation(e.target.value)}
            inputWrapperClassName='mt-5'
            placeholder='Explain here...'
          />
        )
      }
    </ClientRiskWrapper>
  )
})

const FooterButton = memo(({ continueButtonClick }) => <OfferFooterButtons continueButtonClick={continueButtonClick} />)

const BusinessRelationship = memo(({ onOptionsChange, selectedFintracOptions }) => {
  const [selectedOptions, setSelectedOptions] = useState([])
  const [otherExplanation, setOtherExplanation] = useState('')
  const [showOtherExplanation, setShowOtherExplanation] = useState('')

  const refSelectedOptions = useRef(selectedOptions)

  const updateSelectedOptions = (newValue) => {
    refSelectedOptions.current = newValue
    setSelectedOptions(newValue)
  }

  useEffect(() => {
    if (selectedFintracOptions && Object.keys(selectedFintracOptions).length) {
      const { businessRelationship, businessRelationshipExplanation } = selectedFintracOptions

      updateSelectedOptions(businessRelationship)

      if (businessRelationshipExplanation) {
        setShowOtherExplanation(true)
        setOtherExplanation(businessRelationshipExplanation)
      }
    }
  }, [selectedFintracOptions])

  useEffect(() => {
    if (onOptionsChange) {
      onOptionsChange({ businessRelationship: selectedOptions, businessRelationshipExplanation: otherExplanation })
    }
  }, [selectedOptions, onOptionsChange, otherExplanation])

  const changeSelectedOptions = useCallback((e) => {
    let tmpSelectedOptions = [...refSelectedOptions.current]

    const { value } = e.target

    const isSelected = tmpSelectedOptions.findIndex(element => element === value)

    if (isSelected >= 0) {
      tmpSelectedOptions = tmpSelectedOptions.filter(element => element !== value)
    } else {
      tmpSelectedOptions.push(value)
    }

    updateSelectedOptions(tmpSelectedOptions)

    if (value === 'other') {
      const selectedOtherCheckbox = refSelectedOptions.current.findIndex(element => element === 'other') !== -1

      if (selectedOtherCheckbox) {
        setShowOtherExplanation(true)
      } else {
        setShowOtherExplanation(false)
        setOtherExplanation('')
      }
    }
  }, [])

  const BusinessCheckbox = memo(({ title, value }) => (
    <Checkbox
      value={value}
      onClick={changeSelectedOptions}
      checked={refSelectedOptions.current.findIndex(element => element === value) !== -1}
    >
      {title}
    </Checkbox>
  ))

  BusinessCheckbox.propTypes = {
    title: propTypes.string,
    value: propTypes.string
  }

  return (
    <BusinessRelationshipWrapper className='mt-10 flex flex-col'>
      <span className='text-xl font-bold'>Business Relationship</span>
      <span className='text-lg'>Purpose and Intended Nature of the Business Relationship</span>
      <span className='text-base mt-2'>Check the appropriate boxes.</span>
      <span className='text-base mt-5'>Acting as an agent for the purchase or sale of:</span>

      <BusinessRelationshipOptionsWrapper className='grid grid-cols-2 gap-5 mt-2 w-full'>
        <BusinessCheckbox value='residential_property' title='Residential property' />
        <BusinessCheckbox value='residential_property_income' title='Residential property for income purposes' />
        <BusinessCheckbox value='commercial' title='Commercial property' />
        <BusinessCheckbox value='land_commercial' title='Land for Commercial use' />
        <BusinessCheckbox value='other' title='Other:' />
      </BusinessRelationshipOptionsWrapper>

      {
        showOtherExplanation && (
          <InputLabel
            id='businessRelationShipExplanation'
            key='businessRelationShipExplanation'
            label='Explain'
            typeInput='textarea'
            value={otherExplanation}
            onChange={(e) => setOtherExplanation(e.target.value)}
            inputWrapperClassName='mt-5'
            placeholder='Explain here...'
          />
        )
      }
    </BusinessRelationshipWrapper>
  )
})

const Fintrac = ({ onContinueClick, selectedFintracOptions }) => {
  const [thirdPart, setThirdPart] = useState('')
  const [thirdPartDescription, setThirdPartDescription] = useState('')
  const [thirdPartRecord, setThirdPartRecord] = useState({})
  const [riskOptions, setRiskOptions] = useState({})
  const [businessOptions, setBusinessOptions] = useState({})

  useEffect(() => {
    if (selectedFintracOptions && Object.keys(selectedFintracOptions).length) {
      const { thirdPart, thirdPartDescription } = selectedFintracOptions

      setThirdPart(thirdPart)
      setThirdPartDescription(thirdPartDescription)
    }
  }, [selectedFintracOptions])

  const onThirPartySelectYes = useCallback(() => {
    setThirdPart('Yes')
  }, [])

  const onThirPartySelectNo = useCallback(() => {
    setThirdPart('No')
    setThirdPartRecord({})
  }, [])

  const onChangeThirdPartyDescription = useCallback((description) => {
    setThirdPartDescription(description)
  }, [])

  const onChangeThirdPartyRecord = useCallback((thirdPartyRecord) => {
    setThirdPartRecord(thirdPartyRecord)
  }, [])

  const onChangeRiskOptions = useCallback((riskOptions) => {
    setRiskOptions(riskOptions)
  }, [])

  const onChangeBusinessOptions = useCallback((businessOptions) => {
    setBusinessOptions(businessOptions)
  }, [])

  const _onContinueClick = useCallback(() => {
    const errors = []

    if (!thirdPart) {
      errors.push('You must select the 3rd party option!')
    }

    if (!Object.keys(riskOptions).length || !riskOptions.selectedRiskOption) {
      errors.push('You must select Client Risk option!')
    }

    if (Object.keys(riskOptions).length && riskOptions.selectedRiskOption === 'explain' && !riskOptions.riskOptionExplanation) {
      errors.push('Please, give the explanation about the risk option selected!')
    }

    if (businessOptions && !Object.keys(businessOptions.businessRelationship).length) {
      errors.push('You must select Business Relationship option!')
    }

    if (businessOptions && Object.keys(businessOptions).length && businessOptions.businessRelationship.length) {
      if (
        businessOptions.businessRelationship.findIndex(element => element === 'other') !== -1 &&
        !businessOptions.businessRelationshipExplanation.trim()
      ) {
        errors.push('Please, give the explanation about the Other Business Relationship option selected!')
      }
    }

    if (errors.length) {
      errors.forEach((error) => message.warning(error))
      return
    }

    validateRequiredInputs('thirdPartyRecord')
      .then(() => {
        const { incorporationNumber, incorporationJurisdiction, incorporationCountry } = thirdPartRecord

        const incorporation = {
          number: incorporationNumber,
          jurisdiction: incorporationJurisdiction,
          country: incorporationCountry
        }

        let localRiskOptions = {
          clientRisk: riskOptions.clientRisk
        }

        let riskOption = {}

        if (localRiskOptions.clientRisk === 'Low') riskOption = { lowOption: riskOptions.selectedRiskOption, lowOptionExplanation: riskOptions.riskOptionExplanation }
        if (localRiskOptions.clientRisk === 'Medium') riskOption = { mediumOption: riskOptions.selectedRiskOption, mediumRiskExplanation: riskOptions.riskOptionExplanation }
        if (localRiskOptions.clientRisk === 'High') riskOption = { highOption: riskOptions.selectedRiskOption, highRiskExplanation: riskOptions.riskOptionExplanation }

        localRiskOptions = Object.assign(
          {},
          { ...localRiskOptions },
          { ...riskOption }
        )

        const fintrac = {
          thirdPart,
          thirdPartDescription,
          thirdPartRecord: Object.assign({}, { ...thirdPartRecord }, { incorporation }),
          businessRelationship: businessOptions.businessRelationship,
          businessRelationshipExplanation: businessOptions.businessRelationshipExplanation,
          ...localRiskOptions
        }

        onContinueClick(fintrac)
      })
  }, [thirdPart, thirdPartDescription, thirdPartRecord, riskOptions, onContinueClick, businessOptions])

  return (
    <PageWrapper className='h-full w-full flex flex-col'>
      <Title />

      <div className='overflow-y-auto h-full px-10 py-10'>
        <span className='text-xl font-bold'>Are you buying this unit for a 3rd party?</span>

        <ThirdPartyButtons
          thirdPart={thirdPart}
          onSelectYes={onThirPartySelectYes}
          onSelectNo={onThirPartySelectNo}
        />

        <ThirdPartyDescription
          value={thirdPartDescription}
          onChange={onChangeThirdPartyDescription}
        />

        <AnimatePresence>
          { thirdPart === 'Yes' && <ThirdPartyRecord
            onChangeThirdPartyRecord={onChangeThirdPartyRecord}
            selectedFintracOptions={selectedFintracOptions}
          /> }
        </AnimatePresence>

        <ClientRisk
          onOptionsChange={onChangeRiskOptions}
          selectedFintracOptions={selectedFintracOptions}
        />

        <BusinessRelationship
          onOptionsChange={onChangeBusinessOptions}
          selectedFintracOptions={selectedFintracOptions}
        />
      </div>

      <FooterButton continueButtonClick={_onContinueClick} />
    </PageWrapper>
  )
}

const mapStateToProps = state => ({
  selectedFintracOptions: state.createOfferReducer.selectedFintracOptions
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Fintrac)

ThirdPartyButtons.propTypes = {
  thirdPart: propTypes.string,
  onSelectYes: propTypes.func,
  onSelectNo: propTypes.string
}

ThirdPartyDescription.propTypes = {
  value: propTypes.string,
  onChange: propTypes.func
}

ThirdPartyRecord.propTypes = {
  onChangeThirdPartyRecord: propTypes.func,
  selectedFintracOptions: propTypes.shape({
    thirdPartRecord: propTypes.shape({
      name: propTypes.string,
      address: propTypes.string,
      phoneNumber: propTypes.string,
      birthday: propTypes.string,
      occupation: propTypes.string,
      incorporationNumber: propTypes.string,
      incorporationJurisdiction: propTypes.string,
      incorporationCountry: propTypes.string,
      relationship: propTypes.string
    })
  })
}

ClientRisk.propTypes = {
  onOptionsChange: propTypes.func,
  selectedFintracOptions: propTypes.shape({
    clientRisk: propTypes.string,
    lowOption: propTypes.string,
    lowOptionExplanation: propTypes.string,
    mediumOption: propTypes.string,
    mediumRiskExplanation: propTypes.string,
    highOption: propTypes.string,
    highRiskExplanation: propTypes.string
  })
}

Fintrac.propTypes = {
  onContinueClick: propTypes.func,
  selectedFintracOptions: propTypes.shape({
    thirdPart: propTypes.string,
    thirdPartDescription: propTypes.string
  })
}

BusinessRelationship.propTypes = {
  onOptionsChange: propTypes.func,
  selectedFintracOptions: propTypes.shape({
    businessRelationship: propTypes.array,
    businessRelationshipExplanation: propTypes.string
  })
}

FooterButton.propTypes = {
  continueButtonClick: propTypes.func
}
