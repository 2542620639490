import React from 'react'

const Header = () => {
  return (
    <h1 className="font-bold text-4xl px-8 py-6 border-b-2 border-softBlack">
        Dashboard
    </h1>
  )
}

export default Header
