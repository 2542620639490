/* eslint-disable */
// @ts-nocheck
import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import { Select } from 'antd';
import useUnitInventoryMapContext from '../hooks/useUnitInventoryMapContext';

const buttonStyles = {
  backgroundColor: '#EDDF65',
};

const lotStatuses = [
  { key: 'Available', value: 'Available', label: 'Available' },
  { key: 'Hold Back', value: 'Hold Back', label: 'Hold Back' },
  { key: 'Closed', value: 'Closed', label: 'Closed' },
  { key: 'Under Contract', value: 'Under Contract', label: 'Under Contract' },
];

const homeConstructionStatuses = [
  {
    key: 'Not Under Construction',
    value: 'Not Under Construction',
    label: 'Not Under Construction',
  },
  {
    key: 'Under Construction',
    value: 'Under Construction',
    label: 'Under Construction',
  },
];

const buildersList = [
  { key: 'Chesmar Homes', value: 'Chesmar Homes', label: 'Chesmar Homes' },
  { key: 'lennar', value: 'lennar', label: 'lennar' },
  {
    key: 'Taylor Morrison',
    value: 'Taylor Morrison',
    label: 'Taylor Morrison',
  },
  { key: 'Shea Homes', value: 'Shea Homes', label: 'Shea Homes' },
  { key: 'David Weekly', value: 'David Weekly', label: 'David Weekly' },
];

const productTypes = [
  { key: 'A', value: 'A', label: 'A' },
  { key: 'B', value: 'B', label: 'B' },
];

const homeSalesStatues = [
  { key: 'Available', value: 'Available', label: 'Available' },
  { key: 'Sold', value: 'Sold', label: 'Sold' },
];

const MUD = [
  { key: 'A', value: 'A', label: 'A' },
  { key: 'B', value: 'B', label: 'B' },
];

const FilterDrawer = () => {
  const { state, closeFilterDrawer, setFilters, resetFilters } =
  useUnitInventoryMapContext();
  const [selectedState, setSelectedState] = useState({});

  const handleResetFilters = () => {
    resetFilters(true);
    setSelectedState({});
  };

  useEffect(() => {
    if (state.search) {
      setSelectedState({});
    }
  }, [state.search]);

  return (
    <div
      className={`fixed flex flex-col h-screen overflow-y-auto overflow-x-hidden gap-4 w-[280px] bg-white border-r-2 border-softBlack ${
        state.isFilterDrawerOpen ? 'translate-x-0' : '-translate-x-[600px]'
      } transition-all duration-300 ease-in-out`}
    >
      <div className='flex flex-col w-full max-h-full overflow-y-auto overflow-x-hidden p-6 gap-4'>
        <div className='flex flex-row w-full justify-between items-center mb-4'>
          <div className='text-xl font-bold'>Filter</div>
          <button onClick={() => closeFilterDrawer()}>
            <IoMdClose fontSize='22px' />
          </button>
        </div>
        <div className='flex flex-col w-full gap-2'>
          <Select
            showSearch={true}
            loading={false}
            placeholder='Lot Status'
            value={selectedState?.lotStatus}
            filterOption={(input, option) =>
              (option?.label ?? '')
                .toString()
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            onChange={(value) =>
              setSelectedState({
                ...selectedState,
                leadStatus: value,
              })
            }
          >
            {lotStatuses?.map((el) => (
              <Select.Option key={el.value} label={el.label} value={el.value}>
                {el.label}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className='flex flex-col w-full gap-2'>
          <Select
            showSearch={true}
            loading={false}
            placeholder='Home Construction Status'
            value={selectedState?.homeConstructionStatus}
            filterOption={(input, option) =>
              (option?.label ?? '')
                .toString()
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            onChange={(value) =>
              setSelectedState({
                ...selectedState,
                leadStatus: value,
              })
            }
          >
            {homeConstructionStatuses?.map((el) => (
              <Select.Option key={el.value} label={el.label} value={el.value}>
                {el.label}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className='flex flex-col w-full gap-2'>
          <Select
            showSearch={true}
            loading={false}
            placeholder='Builder List'
            value={selectedState?.builder}
            filterOption={(input, option) =>
              (option?.label ?? '')
                .toString()
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            onChange={(value) =>
              setSelectedState({
                ...selectedState,
                leadStatus: value,
              })
            }
          >
            {buildersList?.map((el) => (
              <Select.Option key={el.value} label={el.label} value={el.value}>
                {el.label}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className='flex flex-col w-full gap-2'>
          <Select
            showSearch={true}
            loading={false}
            placeholder='Product Type'
            value={selectedState?.productType}
            filterOption={(input, option) =>
              (option?.label ?? '')
                .toString()
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            onChange={(value) =>
              setSelectedState({
                ...selectedState,
                leadStatus: value,
              })
            }
          >
            {productTypes?.map((el) => (
              <Select.Option key={el.value} label={el.label} value={el.value}>
                {el.label}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className='flex flex-col w-full gap-2'>
          <Select
            showSearch={true}
            loading={false}
            placeholder='Home Sales Status'
            value={selectedState?.homeSalesStatus}
            filterOption={(input, option) =>
              (option?.label ?? '')
                .toString()
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            onChange={(value) =>
              setSelectedState({
                ...selectedState,
                leadStatus: value,
              })
            }
          >
            {homeSalesStatues?.map((el) => (
              <Select.Option key={el.value} label={el.label} value={el.value}>
                {el.label}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className='flex flex-col w-full gap-2'>
          <Select
            showSearch={true}
            loading={false}
            placeholder='MUD'
            value={selectedState?.mud}
            filterOption={(input, option) =>
              (option?.label ?? '')
                .toString()
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            onChange={(value) =>
              setSelectedState({
                ...selectedState,
                leadStatus: value,
              })
            }
          >
            {MUD?.map((el) => (
              <Select.Option key={el.value} label={el.label} value={el.value}>
                {el.label}
              </Select.Option>
            ))}
          </Select>
        </div>
        <Button
          variant='solid'
          style={buttonStyles}
          onClick={() => setFilters(selectedState)}
        >
          Filter List
        </Button>
        <Button
          variant='info'
          class='border-softBlack border-2 p-2'
          onClick={handleResetFilters}
        >
          Reset Filters
        </Button>
      </div>
    </div>
  );
};

export default FilterDrawer;
