// @ts-nocheck
/* eslint-disable */
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { addUsersToProject } from 'store/actions/projectActions' // Import your new action
import { toast } from 'react-hot-toast'
import useContactContext from 'pages/newContacts/hooks/useContactContext'

const useProjectsMutation = () => {
  const client = useQueryClient()
  const { emptyCheckedSelections, emptyProjectList } = useContactContext()
  let id = null
  let updateId = null

  const onSuccess = (data) => {
    toast.dismiss(id)
    toast.success('Successfully added users to project')
    emptyCheckedSelections()
    client.invalidateQueries(['getProjects']) // Adjust query key as needed
  }

  const onMutate = () => {
    id = toast.loading('please wait while we add users to the project')
  }

  const onError = () => {
    toast.dismiss(id)
    toast.error('Unable to add users to project')
  }

  const { mutateAsync } = useMutation(addUsersToProject, { onSuccess, onMutate, onError })
  
  return { addUsersToProject: mutateAsync }
}

export default useProjectsMutation
