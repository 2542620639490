import React, { createContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getBuildingsByProjectId } from 'store/actions/buildingActions'
import { getParkingInventory } from 'store/actions/parkingActions'
import { getStorageInventory } from 'store/actions/storageActions'

export const InventoryContext = createContext({
  handleInventoryChange: (val) => { },
  handleInventoryViewChange: (val) => { },
  setIsReportScreenOpen: () => { },
  state: {
    selectedInventory: 'unit',
    selectedInventoryView: 'availabilityStatus',
    selectedInventoryItem: {},
    statusColors: {},
    demandHeatColors: [],
    isReportScreenOpen: false
  },
  selectedBuilding: '',
  setSelectedBuilding: (building) => { },
  buildingOptions: [],
  setBuildingOptions: (buildingOptions) => { },
  allParkingLevels: [],
  setAllParkingLevels: (parking) => { },
  selectedParkingLevel: {},
  setSelectedParkingLevel: (parking) => { },
  allStorageLevels: [],
  setAllStorageLevels: (storage) => { },
  selectedStorageLevel: {},
  setSelectedStorageLevel: (storage) => { }
})

const InventoryContextProvider = ({ children }) => {
  // @ts-ignore
  const projectId = useSelector((state) => state.appReducer.appProject)
  const [state, setState] = useState({
    selectedInventory: 'unit',
    selectedInventoryView: 'availabilityStatus',
    selectedInventoryItem: {},
    statusColors: {
      open: {
        name: 'Open',
        bgColor: '#60AD83',
        borderColor: '#60AD83',
        textColor: '#18181B'
      },
      available: {
        name: 'Available',
        bgColor: '#60AD83',
        borderColor: '#60AD83',
        textColor: '#18181B'
      },
      assigned: {
        name: 'Assigned',
        bgColor: '#DC7633',
        borderColor: '#DC7633',
        textColor: '#FAFAFA'
      },
      reserved: {
        name: 'Reserved',
        bgColor: '#EDDF65',
        borderColor: '#EDDF65',
        textColor: '#18181B'
      },
      reservedbroker: {
        name: 'Reserved by Broker',
        bgColor: '#EDDF65',
        borderColor: '#EDDF65',
        textColor: '#18181B'
      },
      reservedbuyer: {
        name: 'Reserved by Buyer',
        bgColor: '#EDDF65',
        borderColor: '#EDDF65',
        textColor: '#18181B'
      },
      allocated: {
        name: 'Allocated',
        bgColor: '#EDDF65',
        borderColor: '#EDDF65',
        textColor: '#18181B'
      },
      offeraccepted: {
        name: 'Offer Accepted',
        bgColor: '#EDDF65',
        borderColor: '#EDDF65',
        textColor: '#18181B'
      },
      sold: {
        name: 'Sold',
        bgColor: '#E29A8A',
        borderColor: '#E29A8A',
        textColor: '#18181B'
      },
      soldfirm: {
        name: 'Sold Firm',
        bgColor: '#E29A8A',
        borderColor: '#E29A8A',
        textColor: '#18181B'
      },
      soldcommitted: {
        name: 'Sold Committed',
        bgColor: '#E29A8A',
        borderColor: '#E29A8A',
        textColor: '#18181B'
      },
      'under contract': {
        name: 'Under Contract',
        bgColor: '#B91C1C',
        borderColor: '#B91C1C',
        textColor: '#FAFAFA'
      },
      undercontract: {
        name: 'Under Contract',
        bgColor: '#B91C1C',
        borderColor: '#B91C1C',
        textColor: '#FAFAFA'
      },
      developerheld: {
        name: 'Developer Held',
        bgColor: '#18181B',
        borderColor: '#18181B',
        textColor: '#FAFAFA'
      },
      closed: {
        name: 'Closed',
        bgColor: '#18181B',
        borderColor: '#18181B',
        textColor: '#FAFAFA'
      },
      rescission: {
        name: 'Rescission',
        bgColor: '#18181B',
        borderColor: '#18181B',
        textColor: '#FAFAFA'
      },
      recision: {
        name: 'Rescission',
        bgColor: '#18181B',
        borderColor: '#18181B',
        textColor: '#FAFAFA'
      },
      occupied: {
        name: 'Occupied',
        bgColor: '#DC7633 ',
        borderColor: '#60AD83',
        textColor: '#FAFAFA'
      }
    },
    demandHeatColors: [
      {
        bgColor: '#E8F5F8',
        borderColor: '#E4E4E7',
        textColor: '#18181B'
      },
      {
        bgColor: '#8ECAB7',
        borderColor: '#E4E4E7',
        textColor: '#18181B'
      },
      {
        bgColor: '#60AD83',
        borderColor: '#60AD83',
        textColor: '#18181B'
      },
      {
        bgColor: '#2F713E',
        borderColor: '#2F713E',
        textColor: '#FFF'
      }
    ],
    isReportScreenOpen: false
  })
  const [allParkingLevels, setAllParkingLevels] = useState([])
  const [selectedParkingLevel, setSelectedParkingLevel] = useState({})
  const [allStorageLevels, setAllStorageLevels] = useState([])
  const [selectedStorageLevel, setSelectedStorageLevel] = useState({})
  const [selectedBuilding, setSelectedBuilding] = useState('')
  const [buildingOptions, setBuildingOptions] = useState([])

  const handleInventoryChange = (val) => {
    setState({
      ...state,
      selectedInventory: val,
      selectedInventoryView: 'availabilityStatus',
      isReportScreenOpen: false
    })
  }

  const handleInventoryViewChange = (val) => {
    setState({
      ...state,
      isReportScreenOpen: false,
      selectedInventoryView: val
    })
  }

  const setIsReportScreenOpen = (value) => {
    setState({
      ...state,
      selectedInventoryView: '',
      isReportScreenOpen: value
    })
  }

  useEffect(() => {
    if (projectId.length > 0) {
      getBuildingsByProjectId(projectId)
        .then((buildings) => {
          const temp = buildings?.map((building) => ({
            value: building?._id,
            label: building?.name
          }))
          setBuildingOptions(temp)
          setSelectedBuilding(temp?.length > 0 ? temp[0]?.value : '')
        })
        .catch((error) => console.error(error))
    }
  }, [projectId])

  useEffect(() => {
    if (selectedBuilding?.length > 0) {
      _getParkingType()
      _getStorageType()
    }
  }, [projectId, selectedBuilding])

  const _getParkingType = async () => {
    if (projectId) {
      getParkingInventory(selectedBuilding).then((res) => {
        const parkingInventory = res.map((parking) => ({
          parkingType: parking.options.map((op) => op.name),
          parkingLevels: parking.parkingLevels
        }))
        const temp = parkingInventory[0].parkingLevels.map((pl) => {
          return {
            id: pl,
            label: pl,
            parkingLevel: pl,
            value: pl
          }
        })
        setAllParkingLevels(temp)
        setSelectedParkingLevel(temp[0])
      })
    }
  }

  const _getStorageType = async () => {
    if (projectId) {
      getStorageInventory(selectedBuilding).then((res) => {
        const storageInventory = res.map((storage) => ({
          storageType: storage.options.map((op) => op.name),
          storageLevels: storage.storageLevels
        }))
        const temp = storageInventory[0].storageLevels.map((pl) => {
          return {
            id: pl,
            label: pl,
            storageLevel: pl,
            value: pl
          }
        })
        setAllStorageLevels(temp)
        setSelectedStorageLevel(temp[0])
      })
    }
  }

  return (
    <InventoryContext.Provider
      value={{
        state,
        allParkingLevels,
        setAllParkingLevels,
        selectedParkingLevel,
        setSelectedParkingLevel,
        allStorageLevels,
        setAllStorageLevels,
        selectedStorageLevel,
        setSelectedStorageLevel,
        handleInventoryChange,
        handleInventoryViewChange,
        setIsReportScreenOpen,
        selectedBuilding,
        setSelectedBuilding,
        buildingOptions,
        setBuildingOptions
      }}
    >
      {children}
    </InventoryContext.Provider>
  )
}

export default InventoryContextProvider
