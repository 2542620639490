/* eslint-disable */
import { ReactComponent as TFilledBasketIcon } from 'assets/icons/deleteIcon.svg'
import { ReactComponent as TFilledPencilIcon } from 'assets/icons/editIcon.svg'
import Tag from 'components/Tags';
import React from 'react';
import demoStorage from 'assets/img/sample-storage.jpeg'
import { formatMoney } from 'utils';

const Button = ({ onClick, children, className = '', disabled = false }) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={
        'px-4 py-2 border border-gray-700 text-sm rounded flex items-center justify-center hover:bg-gray-100 ' +
        className
      }
    >
      {children}
    </button>
  );
};

const CustomStorageDetail = ({
  storage: {
    status,
    unitNumber,
    storageNumber,
    type,
    description,
    price,
    images
  },
  handleEdit,
  handleDelete,
}) => {
  return (
    <div className='p-4 flex space-x-4 text-softBlack bg-white rounded border border-dashed border-softBlack_30 relative'>
      <div className='w-48 h-36 overflow-hidden flex items-center'>
        <img src={demoStorage} alt='Uploaded Image' className='object-cover' />
      </div>
      <div>
        {storageNumber?.length > 0 && (
          <h1 className='font-bold mb-0' style={{ fontSize: 22 }}>
            Storage #{storageNumber || unitNumber}
          </h1>
        )}
        {status && (
          <Tag
            label={status}
            classes='italic'
            containerClasses='absolute top-4 right-4'
            type='Reserved'
          />
        )}
        {price && (
          <h1
            className='font-light text-softBlack italic text-sm mb-0'
          >
            {formatMoney(price)}
          </h1>
        )}
        {description && (
          <div className='text-xs space-x-2 mt-1 font-openSans flex items-center gap-1 text-black'>
            {description}
          </div>
        )}
        <div className='mt-6 space-x-4 flex'>
          <Button className='font-openSans italic' onClick={handleEdit}>
            <TFilledPencilIcon className='w-5 h-5 mr-2' />
            Edit
          </Button>
          <Button className='font-openSans italic' onClick={handleDelete}>
            <TFilledBasketIcon />
            Delete
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CustomStorageDetail;
