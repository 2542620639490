import React, { useState, useEffect } from 'react'
import propTypes from 'prop-types'
import { InputLabel } from 'ui'

const TableFilter = (props) => {
  const {
    dataToFilter = [], setData, styleCol, placeHolder, label,
    loading, inputBackgroundColor, className,
    inputWrapperClassName
  } = props
  const [filterText, setFilterText] = useState('')

  // When filterText is changed, means that user is filtering the data
  useEffect(() => {
    if (filterText !== '') {
      // Create an array of words
      const filterWords = filterText.split(' ')

      const filteredData = dataToFilter.filter((record) =>
        filterWords.every((word) =>
          Object.values(record).some((value) => String(value).toLocaleLowerCase().includes(word.toLowerCase()))
        )
      )

      setData(filteredData)
    } else {
      setData(dataToFilter)
    }
  }, [dataToFilter, filterText, setData])

  return (
    <InputLabel
      id='search'
      name='search'
      label={label}
      placeholder={placeHolder}
      xs={24}
      sm={24}
      md={24}
      lg={24}
      value={filterText}
      onChange={(e) => setFilterText(e.target.value)}
      styleCol={{ ...styleCol }}
      loading={loading}
      inputBackgroundColor={inputBackgroundColor}
      className={className}
      inputWrapperClassName={inputWrapperClassName}
    />
  )
}

export default TableFilter

TableFilter.defaultProps = {
  styleCol: {},
  placeHolder: 'You can filter the table below by spacing words'
}

TableFilter.propTypes = {
  dataToFilter: propTypes.array,
  setData: propTypes.func,
  styleCol: propTypes.object,
  placeHolder: propTypes.string,
  label: propTypes.string,
  loading: propTypes.bool,
  inputBackgroundColor: propTypes.string,
  className: propTypes.string,
  inputWrapperClassName: propTypes.string
}
