import React, { useCallback, useState } from 'react'

import Header from './components/Header'
import ProgressSteps from './components/ProgressSteps'

import { BuildingIcon } from 'components/Icons'
import SelectParking from './components/SelectParking'
import { PurchaseParkingProvider } from './context/PurchaseParkingContext'
import Step1 from './components/Step1'
import SelectBuyer from './components/SelectBuyer'
import SelectUpgrades from './components/SelectUpgradeAndOptions'
import SelectReview from './components/SelectReview'

const purchaseParkingSteps = [
  { text: 'Select Unit', Icon: <BuildingIcon />, Component: Step1 },
  { text: 'Select Buyer', Component: SelectBuyer },
  { text: 'Select Parking', Component: SelectParking },
  { text: 'Select Upgrade', Component: SelectUpgrades },
  { text: 'Review', Component: SelectReview }
]

const PurchaseParking = () => {
  const [step, setStep] = useState(0)

  const getComponent = useCallback(() => {
    const currentStep = purchaseParkingSteps[step]
    const Component = currentStep.Component
    return <Component setStep={setStep} key={currentStep.text} />
  }, [step])

  return (
    <PurchaseParkingProvider>
      <div>
        <Header />
        <div className='px-8 pt-33.25 pb-8'>
          <ProgressSteps
            steps={purchaseParkingSteps}
            currentStepIndex={step}
            className='w-70 fixed'
          />
          <div className='pl-82.25 w-full'>
            <div
              className='bg-white rounded p-6 grow w-full overflow-auto'
              style={{ maxHeight: 'calc(100vh - 170px)' }}
            >
              {getComponent()}
            </div>
          </div>
        </div>
      </div>
    </PurchaseParkingProvider>
  )
}

export default PurchaseParking
