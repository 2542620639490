import React from 'react'
import propTypes from 'prop-types'
import { HeaderLogo, SurveyStepWrapper, SurveyTitle, SurveySubTitle, SurveyFooter } from './surveyComponents'

const UnitTypesWrapper = ({ children, className }) => (
  <div className={`flex flex-row items-center justify-center space-x-5 ${className}`}>{children}</div>
)

const UnitType = ({ title, icon, selected, onClick }) => (
  <div
    className={`bg-white h-44 w-44 flex flex-col items-center shadow-md justify-evenly rounded-md hover:cursor-pointer ${selected && 'transform scale-110'}`}
    style={{ borderColor: selected && '#3f8f9d', borderWidth: selected && 3 }}
    onClick={onClick}
  >
    <div className='flex flex-1 justify-center items-center'>
      {icon}
    </div>
    <div className='flex flex-1 justify-center items-center'>
      <span className='text-xl text-center px-6'>{title}</span>
    </div>
  </div>
)

const UnitTypes = ({ onClickContinue, unitTypes, onSelectUnitType, unitTypesContinueButtonDisabled }) => (
    <SurveyStepWrapper>
      <HeaderLogo className='rounded-full w-20 h-20'/>
      <SurveyTitle className='my-7'>Great!</SurveyTitle>
      <SurveySubTitle>What unit types are you interested in purchasing, Stefan?</SurveySubTitle>
      <span className='text-sm text-gray-600 font-bold mt-6 mb-4'>CHOOSE AS MANY AS YOU LIKE</span>
      <UnitTypesWrapper>
        {
          unitTypes.map((unitType) => {
            const { title, icon, selected, value } = unitType

            return (
              <UnitType key={value} title={title} icon={icon} selected={selected} onClick={() => onSelectUnitType(value)} />
            )
          })
        }
      </UnitTypesWrapper>
      <SurveyFooter
        onClickContinue={onClickContinue}
        footerButtonDisabled={unitTypesContinueButtonDisabled}
      />
    </SurveyStepWrapper>
)

export default UnitTypes

UnitTypesWrapper.propTypes = {
  children: propTypes.node,
  className: propTypes.string
}

UnitType.propTypes = {
  title: propTypes.string,
  icon: propTypes.node,
  selected: propTypes.bool,
  onClick: propTypes.func
}

UnitTypes.propTypes = {
  onClickContinue: propTypes.func,
  unitTypes: propTypes.array,
  onSelectUnitType: propTypes.array,
  unitTypesContinueButtonDisabled: propTypes.bool
}
