import { createGlobalStyle } from 'styled-components'
import standardStyle from 'assets/css/standardStyle'

const GlobalStyle = createGlobalStyle`
@tailwind base;
@tailwind components;
@tailwind utilities;
  * {
    font-family: ${standardStyle.fonts.text}, ${
  standardStyle.fonts.sansDefault
};
    ::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }
    

    th,
    td {
      padding: 16px;
      padding-left:0px
    }

    ::-webkit-scrollbar-track {
      border-radius: 10px;
      background: #eceeec;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: rgba(0,0,0,0.25);
    }
  }

  .ant-select-selector{
    min-height: 44px !important;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background-color: transparent !important;
    border: 0px solid ${standardStyle.colors.input.border} !important;
    border-radius: .25rem !important;
    line-height: 1.5 !important;
    padding-top: 0.2rem !important;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 36px !important;
  }

  .ant-select-multiple .ant-select-selector {
    color: ${standardStyle.colors.primary} !important;
    font-family: ${standardStyle.fonts.text} !important;
    font-size: 14px !important;
  }

  ${
    '' /* .ant-select-multiple .ant-select-selector {
    background-color: ${standardStyle.colors.input.background} !important;
    border: 1px solid ${standardStyle.colors.input.border} !important;
    border-radius: .25rem !important;
    line-height: 1.5 !important;
  } */
  }

  .ant-select-multiple .ant-select-selection-item {
    background: #dbdbdb !important;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector{

    border: 1px solid #2e2b2e4d !important;
    border-radius:4px;
  }

.ant-select-selection-overflow{
  color:black;
}
  .ant-picker-input > input {
    color: ${standardStyle.colors.primary} !important;
  }

  .ant-notification {
    font-family: ${standardStyle.fonts.text} !important;
    z-index: 2200;
  }

  .ant-modal-body {
    font-family: ${standardStyle.fonts.text} !important;
  }

  .ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon  {
    color: ${standardStyle.colors.primary} !important;
  }

  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: ${standardStyle.colors.primary} !important;
  }

  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: ${standardStyle.colors.primary} !important;
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: rgba(0,0,0,0.65) !important;
  }

  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: rgba(0,0,0,0.65) !important;
  }

  .ant-upload {
    width: 100%;
  }

  .ant-upload.ant-upload-select-picture-card {
    width: -webkit-fill-available !important;
    margin-right: 0px;
    border: 1px dashed ${standardStyle.colors.softBlack_30};
  }

  .ant-message {
    z-index: 2200;
  }

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px;
  }

  .ant-picker-dropdown {
    z-index: 2010;
  }
  .filter-none-important {
    filter: none !important;
  }
  .ant-picker-input1 .ant-select-selection-placeholder {
    color: #2d2a2db3;
    font-size: 16px;
  }
  input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }
  input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }
  .rating-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    min-height: unset !important;
    height: 40px !important;
    border: unset !important;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding-top: 0.2rem !important
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input{
    height: 40px !important;
   box-shadow: none !important

  }
  
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input:focus{
   border: none !important
   box-shadow: none !important
  }
}
`

export default GlobalStyle
