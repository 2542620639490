/* eslint-disable */
// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { api } from 'services/api';
import DollarIcon from 'assets/icons/dollar-icon.svg';
import SqftIcon from 'assets/icons/sqft-icon.svg';
import useInventory from '../hooks/useInventory';
import TableViewParking from './TableViewParking';
import TableView from './TableView';

const ShadCNWrapper = ({ children, className = '' }) => (
  <div
    className={`rounded-xl border bg-card text-card-foreground shadow bg-white overflow-hidden ${className}`}
  >
    {children}
  </div>
);


const ParkingReportView = ({ appProject: projectId, buildingId }) => {
  const [recentSaleData, setRecentSaleData] = useState([]);
  const [parkingRevenue, setParkingRevenue] = useState(null);
  const [stallSoldCount, setStallSoldCount] = useState(0);
  const [parkingDataByUnit, setParkingDataByUnit] = useState([]);
  const [parkingInventoryType, setParkingInventoryType] = useState([]);
  const [assignedParkingData, setAssignedParkingData] = useState([]);
  const [assignedParkings, setAssignedParkings] = useState(0);
  const [unitCountData, setUnitCountData] = useState([]);
  const [allParkings, setAllParkings] = useState([]);
  const [parkingAllocateInventoryType, setParkingAllocateInventoryType] =
    useState(null);
  const [totalParking, setTotalParking] = useState(0);
  const [totalAllocatedParking, setTotalAllocatedParking] = useState(0);
  const parkingInventoryColors = ['black', 'grey'];
  const [assignedParkingDataIsMore, setAssignedParkingDataIsMore] =
  useState(false);
  const [parkingByUnitIsMore, setParkingByUnitIsMore] = useState(false);
  const {
    state: { statusColors },
  } = useInventory();

  const parkingTypes = [
    {
      type: 'Allocated',
      count: totalAllocatedParking,
    },
    {
      type: 'Assigned',
      count: assignedParkings,
    },
    {
      type: 'Available',
      count:
        Math.sign(totalParking - totalAllocatedParking - assignedParkings) === 1
          ? totalParking - totalAllocatedParking - assignedParkings
          : 0,
    },
    {
      type: 'Rescission',
      count: 0,
    },
    {
      type: 'Reserved',
      count: 0,
    },
    {
      type: 'Sold',
      count: 0,
    },
  ];

  const currencyFormate = (value) => {
    if (value == null) return undefined;
    else {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });

      const formattedValue = formatter.format(value);
      return formattedValue;
    }
  };

  const formatDateDifference = (endDateStr) => {
    const endDate = new Date(endDateStr);
    const today = new Date();
    const difference = endDate.getTime() - today.getTime();
    const daysDifference = Math.ceil(difference / (1000 * 60 * 60 * 24));

    return `${daysDifference} days`;
  };

  const getParkingInventoryDataByBuilding = async () => {
    const { data } = await api.get(
      `/parking/${buildingId}/inventory`,
      {},
      { responseType: 'json' }
    );
    if (data.data.length > 0) {
      const totalParkings = data.data[0].totalParkings;
      const parkingInventoryTypes = data.data[0].options.map((op, index) => {
        return {
          type: op.name,
          key: op._id,
          count: op.value.map((v) => v).reduce((a, b) => a + b, 0),
        };
      });
      setParkingInventoryType(parkingInventoryTypes);
      setTotalParking(totalParkings);
    }
  };
  const getParkingAllocateInventoryDataByBuilding = async () => {
    const { data } = await api.get(
      `/parking/${buildingId}/allocateInventory`,
      {},
      { responseType: 'json' }
    );
    if (data.data.length > 0) {
      setParkingAllocateInventoryType(data.data[0]);
      const totalAllocatedParking = data.data[0].options.reduce((sum, obj) => {
        const objSum = obj.parkingTypes.reduce((objSum, parkingType) => {
          const typeSum = parkingType.options.reduce(
            (typeSum, option) => typeSum + Number(option.value),
            0
          );
          return objSum + typeSum;
        }, 0);
        return sum + objSum;
      }, 0);
      setTotalAllocatedParking(totalAllocatedParking);
    }
  };

  const getAssignedParkingsFromUnit = async () => {
    const { data } = await api.get(`/parking/building/${buildingId}`);
    if (data.length > 0) {
      let assignedParkings = data.filter((parking) => {
        return parking.unit;
      });
      console.log(assignedParkings);
      setAssignedParkingData(assignedParkings);
      setAssignedParkings(data.length);
      const counts = assignedParkings.reduce((acc, obj) => {
        const { unit } = obj;
        const { _id, unitNumber } = unit;

        if (!acc[_id]) {
          acc[_id] = { count: 1, unitNumber };
        } else {
          acc[_id].count++;
        }
        return acc;
      }, {});
      const unitCountArray = Object.entries(counts).map(
        ([_id, { count, unitNumber }]) => ({
          _id,
          count,
          unitNumber,
        })
      );
      setUnitCountData(unitCountArray);
      const unitByBuilding = await api.get(
        `/units/building/${buildingId}`,
        {},
        { responseType: 'json' }
      );
      const parkingsDataByUnit = unitByBuilding.data.data.map((item, index) => {
        return {
          unitNumber: item.unitNumber,
          key: index,
          parkings:
            (item?.numberParkingIncluded || 0) +
            (item?.numberParkingOptional || 0),
        };
      });
      unitCountArray.forEach(({ unitNumber, count }) => {
        const unit = parkingsDataByUnit.find(
          (item) => item.unitNumber === unitNumber
        );
        if (unit) {
          if (Math.sign(unit.parkings === -1)) unit.parkings = 0;
        }
      });
      setParkingDataByUnit(parkingsDataByUnit);
    }
  };
  // const getParkingDataByProjectId = async () => {
  //   const { data } = await api.get(
  //     `/parking/projectId`,
  //     {},
  //     { responseType: 'json' }
  //   );
  //   let parkingIdData = [];
  //   parkingIdData = data.data.filter(
  //     (parking) => parking.building.project.id === projectId
  //   );
  //   setProjectParkings(parkingIdData);
  // };

  const fetchDataByGroupParking = async () => {
    const { data } = await api.get('/deals', {}, { responseType: 'json' });
    const filteredDeals = data.data.filter(
      (deal) => deal.formatedAcceptedDate !== ''
    );
    let parkingGroup = [];
    filteredDeals.forEach((deal) => {
      const { options } = deal;
      const modifiedGroups = options
        .filter((option) => option.label.toLowerCase().includes('parking'))
        .map((option, index) => ({
          label: option.label,
          price: option.price,
          index: index,
        }));
      parkingGroup = parkingGroup.concat(modifiedGroups);
    });
    const totalPrice = parkingGroup.reduce(
      (total, obj) => total + obj.price,
      0
    );
    setStallSoldCount(parkingGroup.length);
    setParkingRevenue(totalPrice);
  };

  useEffect(() => {
    const recentSalesData = async () => {
      const { data } = await api.get('/deals', {}, { responseType: 'json' });
      if (data.data.length === 0) {
        setRecentSaleData([]);
      } else {
        const latestThreeSales = data.data.slice(0, 3);
        setRecentSaleData(latestThreeSales);
        return data.data;
      }
    };
    recentSalesData();
    fetchDataByGroupParking();
  }, []);

  useEffect(() => {
    getParkingInventoryDataByBuilding();
    getParkingAllocateInventoryDataByBuilding();
    getAssignedParkingsFromUnit();
  }, []);

  return (
    <div className='w-full h-full border-t border-[#E4E4E7]'>
      <div className='flex w-full h-full'>
        <div className='relative flex flex-col w-full transition-all'>
          <div style={{ width: '100%', height: '95%' }} className=''>
            <div className='grid grid-cols-12 gap-x-6 cols-span-12 h-full'>
              <div className='col-span-12 h-full'>
                <div className='bg-white rounded-[8px] flex flex-col gap-y-6 px-6 py-8'>
                  <div className='bg-white rounded-[8px] grid grid-cols-10 col-span-10 py-4 border-[1px] border-[rgb(0,0,0,0.2)] gap-x-4  px-4'>
                    <div className='p-5 col-span-3 bg-white rounded-lg border border-zinc-200 flex flex-col gap-7'>
                      <div className='text-zinc-950 text-lg font-medium leading-[14.17px]'>
                        Total Sales
                      </div>
                      <div className='justify-start items-end gap-[8.50px] flex'>
                        <div className='text-zinc-950 text-[28.33px] font-bold leading-snug'>
                          {currencyFormate(parkingRevenue)}
                        </div>
                      </div>
                      <div className='justify-between items-start flex'>
                        <div className='px-4 pl-0 border-opacity-25 flex gap-x-4 flex-wrap justify-start items-start gap-[7.51px]'>
                          {parkingInventoryType === [] && 'Loading...'}
                          {parkingInventoryType.map((parkingType, index) => {
                            return (
                              <div key={index} className='flex'>
                                <div className='flex flex-col'>
                                  <div className='justify-start items-center gap-[3.76px] inline-flex'>
                                    <div className='justify-start items-start flex'>
                                      <div
                                        style={{
                                          backgroundColor:
                                            index % 2 === 0
                                              ? parkingInventoryColors[0]
                                              : parkingInventoryColors[1],
                                        }}
                                        className='w-[11.27px] h-[11.27px] rounded-full'
                                      ></div>
                                    </div>
                                    <div className='text-zinc-950 text-[13.04px] font-medium leading-[18.78px]'>
                                      {parkingType.type}
                                    </div>
                                  </div>
                                  <div className='justify-start items-end gap-[5.63px] inline-flex'>
                                    <div className='text-zinc-950 text-xl font-bold leading-[30.05px]'>
                                      {parkingType.count}
                                    </div>
                                  </div>
                                </div>
                                {index !== parkingInventoryType.length - 1 && (
                                  <div className='w-[1px] h-[44px] ml-4 bg-neutral-500'></div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <div className='col-span-7 flex flex-col gap-x-4'>
                      <div className='h-full flex  flex-col gap-2 justify-start'>
                        <h5 className='text-base font-semibold mb-0 truncate'>
                          Parking Inventory
                        </h5>
                        <h2 className='text-3xl font-bold mb-0'>
                          {totalParking || 0} parkings
                        </h2>
                      </div>
                      <div className='flex flex-1 w-[100%] mt-10 flex-col gap-3 '>
                        <div className='h-[27px] flex w-full gap-1 items-center rounded-md '>
                          {parkingTypes.map((pt, index) => (
                            <div
                              key={index}
                              className='h-full'
                              style={{
                                width: `${(pt.count / totalParking) * 100}%`,
                                backgroundColor:
                                  statusColors[pt.type.toLowerCase()]?.bgColor,
                              }}
                            />
                          ))}
                        </div>
                        <div className='flex flex-wrap items-center gap-y-2'>
                          {parkingTypes.map((elm, index) => (
                            <div
                              key={index}
                              className={`flex flex-col gap-y-1 px-4 ${
                                index !== 0
                                  ? 'border-l border-[#2E2B2E] border-opacity-25'
                                  : ''
                              } overflow-hidden`}
                            >
                              <div className='flex gap-1'>
                                <svg
                                  className='mt-1'
                                  width='11'
                                  height='11'
                                  viewBox='0 0 12 12'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <circle
                                    cx='6'
                                    cy='6'
                                    r='6'
                                    fill={
                                      statusColors[elm.type.toLowerCase()]
                                        ?.bgColor
                                    }
                                  />
                                </svg>
                                <h6 className='truncate text-sm mb-0 font-medium transition-all'>
                                  {statusColors[elm.type.toLowerCase()]?.name}
                                </h6>
                              </div>
                              <p className='text-xl mb-0 font-bold'>
                                {elm.count}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='bg-white rounded-[8px] grid grid-cols-9 col-span-9 py-4 border-[1px] border-[rgb(0,0,0,0.2)] gap-x-4  px-4'>
                    <div className='p-5 col-span-3 bg-white rounded-lg border border-zinc-200 flex flex-col gap-y-4'>
                      <h5 className='text-xl font-semibold mb-0 truncate'>
                        Allocation
                      </h5>
                      <div className='flex flex-col gap-y-2 max-[400px] overflow-auto'>
                        {parkingAllocateInventoryType === null
                          ? 'No Data'
                          : parkingAllocateInventoryType.options.map(
                              (parking, index) => (
                                <div key={index}>
                                  <div className='flex flex-row gap-x-4 md:gap-x-0 md:flex-col'>
                                    <div className='flex items-center gap-x-2'>
                                      <div
                                        style={{
                                          backgroundColor:
                                            index % 2 === 0
                                              ? parkingInventoryColors[0]
                                              : parkingInventoryColors[1],
                                        }}
                                        className={`rounded-full h-3 w-3`}
                                      ></div>
                                      <div className='text-sm col-span-2'>
                                        {parking.name}
                                      </div>
                                    </div>
                                    <div className='text-xl col-span-2 font-bold'>
                                      {parking.parkingTypes.reduce(
                                        (sum, parkingType) =>
                                          sum +
                                          parkingType.options.reduce(
                                            (subSum, option) =>
                                              subSum + Number(option.value),
                                            0
                                          ),
                                        0
                                      )}
                                    </div>
                                  </div>
                                  {parkingAllocateInventoryType.options.length -
                                    1 !=
                                    index && (
                                    <div className='w-[2px] bg-[rgba(0,0,0,0.4)]'></div>
                                  )}
                                </div>
                              )
                            )}
                      </div>
                    </div>
                    <ShadCNWrapper className='col-span-3'>
                      <div className='p-6 flex flex-col gap-y-2'>
                        <h5 className='text-lg uppercase font-semibold mb-0 truncate'>
                          Assignment PARKING - UNIT
                        </h5>
                        <div className='my-2 border-b border-zinc-200'>
                          <ul className='flex'>
                            <li className='basis-1/3 leading-none text-sm text-left font-normal text-[#2E2B2E] text-opacity-70 capitalize'>
                              Parking Stall
                            </li>
                            <li className='basis-1/3 leading-none text-sm text-center font-normal text-[#2E2B2E] text-opacity-70 capitalize'>
                              Status
                            </li>
                            <li className='basis-1/3 leading-none text-sm text-center font-normal text-[#2E2B2E] text-opacity-70 capitalize'>
                              Unit
                            </li>
                          </ul>
                        </div>
                        <div>
                          {assignedParkingData === []
                            ? 'No Data Available'
                            : !assignedParkingDataIsMore
                            ? assignedParkingData
                                .slice(0, 5)
                                .map((item, index) => {
                                  return (
                                    <ul key={index} className='flex'>
                                      <li className='uppercase basis-1/3 text-[14px] font-semibold'>
                                        Parking #{item.stall}
                                      </li>
                                      <li className='uppercase basis-1/3 text-center text-[14px] font-semibold'>
                                        Assigned
                                      </li>
                                      <li className='uppercase basis-1/3 text-center text-[14px] font-semibold'>
                                        {item?.unit?.unitNumber}
                                      </li>
                                    </ul>
                                  );
                                })
                            : assignedParkingData.map((item, index) => {
                                return (
                                  <ul key={index} className='flex'>
                                    <li className='uppercase basis-1/3 text-[14px] font-semibold'>
                                      Parking #{item.stall}
                                    </li>
                                    <li className='uppercase basis-1/3 text-center text-[14px] font-semibold'>
                                      Assigned
                                    </li>
                                    <li className='uppercase basis-1/3 text-center text-[14px] font-semibold'>
                                      {item?.unit?.unitNumber}
                                    </li>
                                  </ul>
                                );
                              })}
                        </div>
                        {assignedParkingData.length > 5 && (
                          <div className='flex mx-auto mt-[10px]'>
                            <div
                              onClick={() => {
                                setAssignedParkingDataIsMore(
                                  !assignedParkingDataIsMore
                                );
                              }}
                              className='cursor-pointer flex gap-x-2 items-center mx-auto'
                            >
                              <div className='text-[#2E2B2E] font-[300]'>
                                {!assignedParkingDataIsMore
                                  ? 'Show more'
                                  : 'Show less'}
                              </div>
                              <div>
                                {!assignedParkingDataIsMore ? (
                                  <img src='/down-arrow.png' />
                                ) : (
                                  <img src='/up-arrow.svg' />
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </ShadCNWrapper>
                    <ShadCNWrapper className='col-span-3'>
                      <div className='p-6 flex flex-col gap-y-2'>
                        <h5 className='text-lg uppercase font-semibold mb-0 truncate'>
                          Allocated
                        </h5>
                        <div className='my-2 border-b border-zinc-200'>
                          <ul className='flex'>
                            <li className='basis-1/3 leading-none text-sm text-left font-normal text-[#2E2B2E] text-opacity-70 capitalize'>
                              unit
                            </li>
                            <li className='basis-1/3 leading-none text-sm text-center font-normal text-[#2E2B2E] text-opacity-70 capitalize'>
                              Status
                            </li>
                            <li className='basis-1/3 leading-none text-sm text-center font-normal text-[#2E2B2E] text-opacity-70 capitalize'>
                              Assignments
                            </li>
                          </ul>
                        </div>
                        <div>
                          {parkingDataByUnit === []
                            ? 'No Data Available'
                            : !parkingByUnitIsMore
                            ? parkingDataByUnit
                                .slice(0, 5)
                                .map((item, index) => {
                                  return (
                                    <ul key={index} className='flex'>
                                      <li className='uppercase basis-1/3 text-[14px] font-semibold'>
                                        {item.unitNumber}
                                      </li>
                                      <li className='uppercase basis-1/3 text-center text-[14px] font-semibold'>
                                        pending
                                      </li>
                                      <li className='uppercase basis-1/3 text-center text-[14px] font-semibold'>
                                        {item?.parkings}
                                      </li>
                                    </ul>
                                  );
                                })
                            : parkingDataByUnit.map((item, index) => {
                                return (
                                  <ul key={index} className='flex'>
                                    <li className='uppercase basis-1/3 text-[14px] font-semibold'>
                                      {item.unitNumber}
                                    </li>
                                    <li className='uppercase basis-1/3 text-center text-[14px] font-semibold'>
                                      pending
                                    </li>
                                    <li className='uppercase basis-1/3 text-center text-[14px] font-semibold'>
                                      {item?.parkings}
                                    </li>
                                  </ul>
                                );
                              })}
                        </div>
                        {parkingDataByUnit.length > 5 && (
                          <div className='flex mx-auto mt-[10px]'>
                            <div
                              onClick={() => {
                                setParkingByUnitIsMore(!parkingByUnitIsMore);
                              }}
                              className='cursor-pointer flex gap-x-2 items-center mx-auto'
                            >
                              <div className='text-[#2E2B2E] font-[300]'>
                                {!parkingByUnitIsMore
                                  ? 'Show more'
                                  : 'Show less'}
                              </div>
                              <div>
                                {!parkingByUnitIsMore ? (
                                  <img src='/down-arrow.png' />
                                ) : (
                                  <img src='/up-arrow.svg' />
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </ShadCNWrapper>
                  </div>
                  <TableViewParking
                    selectedBuilding={buildingId}
                    projectId={projectId}
                  />
                </div>
              </div>
              <div className='col-span-12 mt-8'>
                <TableView
                  showCards={false}
                  selectedBuilding={buildingId}
                  projectId={projectId}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParkingReportView;
