import { UserIcon } from 'components/Icons'
import styled from 'styled-components'

export const CustomUserIcon = styled(UserIcon)`
  font-size: 21px;
  color: white;
  margin: 0px 10px;
  
  :hover {
    cursor: pointer;
  }
`

export const LoggedUserWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
