import { useGetAPI } from 'hooks/useGenericAPIs'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area
} from 'recharts'
import GraphDataWrapper from './GraphDataWrapper'

const AllTimeLeadsTimeSpans = ['year', 'month', 'day']
const defaultAllTimeLeadsTimeSpans = () => AllTimeLeadsTimeSpans[0]

const AreaTotalBuyerContactsOverTime = ({ height = 400, width = '100%' }) => {
  const {
    data: fetchedAllTimeLeadsOverTime,
    fetch: fetchAllTimeLeadsOverTime
  } = useGetAPI()

  const [currentAllTimeLeadsTimeSpans, setCurrentllTimeLeadsTimeSpans] =
    useState(defaultAllTimeLeadsTimeSpans)

  useEffect(() => {
    fetchAllTimeLeadsOverTime(
      `/analytics/leads/${currentAllTimeLeadsTimeSpans}`
    )
  }, [currentAllTimeLeadsTimeSpans])

  const _TotalBuyerContactsOverTime = useMemo(() => {
    const result = {
      title: 'Total buyer Contacts Over Time',
      type: 'Graph',
      date: moment().format('MMM DD YYYY'),
      data: []
    }
    if (fetchedAllTimeLeadsOverTime?.length) {
      result.data = fetchedAllTimeLeadsOverTime.map((_item) => {
        return {
          name: _item.label,
          value: _item.count
        }
      })
    }
    return result
  }, [fetchedAllTimeLeadsOverTime])

  return (
    <GraphDataWrapper {..._TotalBuyerContactsOverTime}>
      <div className='w-full flex flex-row px-6'>
        {AllTimeLeadsTimeSpans.map((item, index) => {
          return (
            <div
              key={item}
              onClick={() => setCurrentllTimeLeadsTimeSpans(item)}
              className={`flex flex-1 justify-center border-2 border-softBlack p-2 cursor-pointer ${
                currentAllTimeLeadsTimeSpans === item
                  ? 'bg-softBlack text-white'
                  : ''
              }`}
            >
              {item}
            </div>
          )
        })}
      </div>
      <div style={{ width, height }}>
        <ResponsiveContainer height={height}>
          <AreaChart
            height={height}
            data={_TotalBuyerContactsOverTime.data}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0
            }}
          >
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='name' />
            <YAxis />
            <Tooltip />
            <Area
              type='monotone'
              dataKey='value'
              stroke='#EDDF65'
              fill='#EDDF65'
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </GraphDataWrapper>
  )
}

export default AreaTotalBuyerContactsOverTime
