import styled from 'styled-components'

export const ButtonsWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  padding: 10px 16px;
  border-top: 1px solid #e9e9e9;
  background: #fff;
  display: flex;
  justify-content: space-between;
`
