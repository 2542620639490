/* eslint-disable multiline-ternary */
import standardStyle from 'assets/css/standardStyle'
import React from 'react'
import { AVAILABILITY_BG_COLOR } from 'utils'

import { cadFormatter, formatMoney } from 'utils/formatters'
import { isMobile } from 'react-device-detect'

const IconWrapper = ({ children }) => {
  return <div className='mr-2 w-4 my-auto'>{children}</div>
}

const IconLabelWrapper = ({ children }) => {
  return <span className='flex text-center items-center'>{children}</span>
}

const Separator = () => {
  return <div className='border border-softBlack30 my-6' />
}

const LargeUnitCard = ({
  imgsrc,
  unitName,
  description = '',
  sizeInSquareFeet,
  noOfBedrooms,
  noOfBathrooms,
  balcony,
  noOfParkingSlot,
  address,
  building,
  price,
  pricePerSq,
  tagText = null,
  tag,
  leftButtonLabel = '',
  type = '',
  onLeftButtonClick = () => { },
  rightButtonLabel = '',
  onRightButtonClick = () => { },
  showViewDetails = true
}) => {
  const isGrid = type === 'grid'
  return (
    <div
      className={`flex ${isGrid ? `${isMobile ? 'w-full' : 'w-1/3'}` : 'w-full'
        } flex-col`}
      style={{ fontFamily: standardStyle.fonts.sfpro }}
    >
      <div
        className={`flex flex-wrap w-full ${isGrid && 'flex-col'} ${isMobile ? '' : 'gap-6'
          } text-gray-700`}
      >
        <div
          className={`flex ${isMobile ? 'w-full' : 'w-[436px]'
            }  h-[321px] justify-center content-center item-center object-cover`}
        >
          <img src={imgsrc} alt='Plan' className='object-contain' />
        </div>
        <div
          className={`flex flex-col grow ${isMobile ? 'w-full mt-4' : 'w-[475px]'
            }`}
        >
          <div className='flex justify-between'>
            <h1 className='text-softBlack text-2xl font-bold p-0 m-0'>
              {unitName}
            </h1>
            {tagText && tag?.length > 0 && (
              <div
                className={`flex items-center justify-center ${isMobile ? '' : 'min-w-[80px]'
                  } w-fit h-[27px] px-2 uppercase`}
                style={{
                  backgroundColor: AVAILABILITY_BG_COLOR[tag?.toLowerCase()]
                }}
              >
                <span className='font-medium text-sm text-softBlack'>
                  {tagText}
                </span>
              </div>
            )}
          </div>
          {description?.length > 0 && !isGrid && (
            <span className='mt-6 text-sm'>{description}</span>
          )}
          {isGrid && (
            <div className='flex flex-col'>
              <div className='flex justify-between items-baseline'>
                {price && (
                  <span className='mt-1 font-light text-sm font-openSans italic text-softBlack'>
                    {formatMoney(price)}
                  </span>
                )}
                {pricePerSq && (
                  <span className='font-light text-sm font-openSans italic text-softBlack'>
                    {' '}
                    / {cadFormatter.format(pricePerSq)} PER SQ. FT
                  </span>
                )}
              </div>
              {address && (
                <div className='mt-2'>
                  <IconLabelWrapper>
                    <IconWrapper>
                      <svg
                        width='20'
                        height='20'
                        viewBox='0 0 20 20'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M9.9987 10.0003C10.9154 10.0003 11.6654 9.25033 11.6654 8.33366C11.6654 7.41699 10.9154 6.66699 9.9987 6.66699C9.08203 6.66699 8.33203 7.41699 8.33203 8.33366C8.33203 9.25033 9.08203 10.0003 9.9987 10.0003ZM9.9987 1.66699C13.4987 1.66699 16.6654 4.35033 16.6654 8.50033C16.6654 11.1503 14.6237 14.267 10.5487 17.8587C10.232 18.1337 9.75703 18.1337 9.44036 17.8587C5.3737 14.267 3.33203 11.1503 3.33203 8.50033C3.33203 4.35033 6.4987 1.66699 9.9987 1.66699Z'
                          fill='#2E2B2E'
                        />
                      </svg>
                    </IconWrapper>
                    {address}
                  </IconLabelWrapper>
                </div>
              )}
            </div>
          )}
          {
            (sizeInSquareFeet || balcony || noOfBedrooms || noOfParkingSlot || noOfBathrooms) &&
            <div
              className={`${!isGrid
                ? `grid grid-cols-2 mt-6 ${isMobile ? '' : 'gap-3'} text-sm`
                : `flex flex-row justify-start ${isMobile ? '' : 'gap-4'} mt-4`
                }`}
            >
              {sizeInSquareFeet && (
                <IconLabelWrapper>
                  <IconWrapper>
                    <svg
                      width='20'
                      height='20'
                      viewBox='0 0 20 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M15.832 10H14.1654V12.5H11.6654V14.1667H15.832V10ZM5.83203 7.5H8.33203V5.83333H4.16536V10H5.83203V7.5ZM17.4987 2.5H2.4987C1.58203 2.5 0.832031 3.25 0.832031 4.16667V15.8333C0.832031 16.75 1.58203 17.5 2.4987 17.5H17.4987C18.4154 17.5 19.1654 16.75 19.1654 15.8333V4.16667C19.1654 3.25 18.4154 2.5 17.4987 2.5ZM17.4987 15.8417H2.4987V4.15833H17.4987V15.8417V15.8417Z'
                        fill='#2E2B2E'
                      />
                    </svg>
                  </IconWrapper>
                  {sizeInSquareFeet} sq. ft
                </IconLabelWrapper>
              )}
              {balcony && !isGrid && (
                <IconLabelWrapper>
                  <IconWrapper>
                    <svg
                      width='21'
                      height='20'
                      viewBox='0 0 21 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M11.3346 15H9.66797V13.3333H11.3346V15ZM13.0013 11.6667H8.0013V16.6667H13.0013V11.6667ZM16.3346 7.75V3.33333H13.8346V5.5L10.5013 2.5L2.16797 10H4.66797L10.5013 4.74167L16.3346 10H18.8346L16.3346 7.75Z'
                        fill='#2E2B2E'
                      />
                    </svg>
                  </IconWrapper>
                  Balcony
                </IconLabelWrapper>
              )}
              {noOfBedrooms && (
                <IconLabelWrapper>
                  <IconWrapper>
                    <svg
                      width='20'
                      height='20'
                      viewBox='0 0 20 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M16.668 8.33366V5.83366C16.668 4.91699 15.918 4.16699 15.0013 4.16699H5.0013C4.08464 4.16699 3.33464 4.91699 3.33464 5.83366V8.33366C2.41797 8.33366 1.66797 9.08366 1.66797 10.0003V14.167H2.7763L3.33464 15.8337H4.16797L4.7263 14.167H15.2846L15.8346 15.8337H16.668L17.2263 14.167H18.3346V10.0003C18.3346 9.08366 17.5846 8.33366 16.668 8.33366ZM9.16797 8.33366H5.0013V5.83366H9.16797V8.33366ZM15.0013 8.33366H10.8346V5.83366H15.0013V8.33366Z'
                        fill='#2E2B2E'
                      />
                    </svg>
                  </IconWrapper>
                  {noOfBedrooms} {isGrid ? 'Br' : 'Bedrooms'}
                </IconLabelWrapper>
              )}
              {noOfParkingSlot && (
                <IconLabelWrapper>
                  <IconWrapper>
                    <svg
                      width='21'
                      height='20'
                      viewBox='0 0 21 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M11.1583 2.5H7.16667C6.25 2.5 5.5 3.25 5.5 4.16667V15.8333C5.5 16.75 6.25 17.5 7.16667 17.5C8.08333 17.5 8.83333 16.75 8.83333 15.8333V12.5H11.3333C14.3083 12.5 16.6833 9.89167 16.2917 6.84167C15.9667 4.325 13.7 2.5 11.1583 2.5ZM11.5 9.16667H8.83333V5.83333H11.5C12.4167 5.83333 13.1667 6.58333 13.1667 7.5C13.1667 8.41667 12.4167 9.16667 11.5 9.16667Z'
                        fill='#2E2B2E'
                      />
                    </svg>
                  </IconWrapper>
                  {noOfParkingSlot} {isGrid ? 'Pa' : 'Parking slots'}
                </IconLabelWrapper>
              )}
              {noOfBathrooms && (
                <IconLabelWrapper>
                  <IconWrapper>
                    <svg
                      width='20'
                      height='20'
                      viewBox='0 0 20 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M5.83464 7.50033C6.75511 7.50033 7.5013 6.75413 7.5013 5.83366C7.5013 4.91318 6.75511 4.16699 5.83464 4.16699C4.91416 4.16699 4.16797 4.91318 4.16797 5.83366C4.16797 6.75413 4.91416 7.50033 5.83464 7.50033Z'
                        fill='#2E2B2E'
                      />
                      <path
                        d='M17.5013 10.8337H16.668V4.02533C16.668 2.72533 15.6096 1.66699 14.3096 1.66699C13.6846 1.66699 13.0846 1.91699 12.643 2.35866L11.6013 3.40033C11.468 3.35866 11.3263 3.33366 11.1763 3.33366C10.843 3.33366 10.5346 3.43366 10.2763 3.60033L12.5763 5.90033C12.743 5.64199 12.843 5.33366 12.843 5.00033C12.843 4.85033 12.818 4.71699 12.7846 4.57533L13.8263 3.53366C13.9513 3.40866 14.1263 3.33366 14.3096 3.33366C14.693 3.33366 15.0013 3.64199 15.0013 4.02533V10.8337H9.29297C9.04297 10.6587 8.81797 10.4587 8.60964 10.2337L7.44297 8.94199C7.28464 8.76699 7.08464 8.62532 6.86797 8.52532C6.60964 8.40032 6.3263 8.33366 6.03464 8.33366C5.0013 8.34199 4.16797 9.17533 4.16797 10.2087V10.8337H2.5013C2.04297 10.8337 1.66797 11.2087 1.66797 11.667V15.8337C1.66797 16.7503 2.41797 17.5003 3.33464 17.5003C3.33464 17.9587 3.70964 18.3337 4.16797 18.3337H15.8346C16.293 18.3337 16.668 17.9587 16.668 17.5003C17.5846 17.5003 18.3346 16.7503 18.3346 15.8337V11.667C18.3346 11.2087 17.9596 10.8337 17.5013 10.8337Z'
                        fill='#2E2B2E'
                      />
                    </svg>
                  </IconWrapper>
                  {noOfBathrooms} {isGrid ? 'Ba' : 'Bathrooms'}
                </IconLabelWrapper>
              )}
            </div>
          }
          {!isGrid && <Separator />}
          {address && !isGrid && (
            <>
              <p className='text-xs font-bold text-gray-500'>ADDRESS</p>
              <IconLabelWrapper>
                <IconWrapper>
                  <svg
                    width='20'
                    height='20'
                    viewBox='0 0 20 20'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M9.9987 10.0003C10.9154 10.0003 11.6654 9.25033 11.6654 8.33366C11.6654 7.41699 10.9154 6.66699 9.9987 6.66699C9.08203 6.66699 8.33203 7.41699 8.33203 8.33366C8.33203 9.25033 9.08203 10.0003 9.9987 10.0003ZM9.9987 1.66699C13.4987 1.66699 16.6654 4.35033 16.6654 8.50033C16.6654 11.1503 14.6237 14.267 10.5487 17.8587C10.232 18.1337 9.75703 18.1337 9.44036 17.8587C5.3737 14.267 3.33203 11.1503 3.33203 8.50033C3.33203 4.35033 6.4987 1.66699 9.9987 1.66699Z'
                      fill='#2E2B2E'
                    />
                  </svg>
                </IconWrapper>
                {address}
              </IconLabelWrapper>
            </>
          )}
          {building && !isGrid && (
            <>
              <p className='text-xs font-bold text-gray-500 mt-6'>BUILDING</p>
              <IconLabelWrapper>
                <IconWrapper>
                  <svg
                    width='20'
                    height='20'
                    viewBox='0 0 20 20'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M16.666 2.5H9.99935C9.54102 2.5 9.16602 2.875 9.16602 3.33333V4.675C9.16602 4.675 9.16602 4.675 9.17435 4.68333L13.341 8.43333C13.866 8.9 14.166 9.58333 14.166 10.2917V10.8333H15.8327V12.5H14.166V14.1667H15.8327V15.8333H14.166V17.5H16.666C17.1243 17.5 17.4993 17.125 17.4993 16.6667V3.33333C17.4993 2.875 17.1243 2.5 16.666 2.5ZM12.4993 5.83333H10.8327V4.16667H12.4993V5.83333ZM15.8327 9.16667H14.166V7.5H15.8327V9.16667ZM15.8327 5.83333H14.166V4.16667H15.8327V5.83333Z'
                      fill='#2E2B2E'
                    />
                    <path
                      d='M12.5 16.6663V10.2913C12.5 10.058 12.4 9.83301 12.225 9.67467L8.05833 5.92467C7.9 5.77467 7.7 5.70801 7.5 5.70801C7.3 5.70801 7.1 5.78301 6.94167 5.92467L2.775 9.67467C2.6 9.82467 2.5 10.0497 2.5 10.2913V16.6663C2.5 17.1247 2.875 17.4997 3.33333 17.4997H5C5.45833 17.4997 5.83333 17.1247 5.83333 16.6663V13.333H9.16667V16.6663C9.16667 17.1247 9.54167 17.4997 10 17.4997H11.6667C12.125 17.4997 12.5 17.1247 12.5 16.6663Z'
                      fill='#2E2B2E'
                    />
                  </svg>
                </IconWrapper>
                {building}
              </IconLabelWrapper>
              {!isGrid && <Separator />}
            </>
          )}
          <div className='mt-2'>
            {!isGrid && (
              <p className='text-xs font-bold text-gray-500'>TOTAL VALUE</p>
            )}
            {!isGrid && (
              <div className='flex flex-col'>
                <div className='flex justify-between items-baseline text-gray-800'>
                  {price && (
                    <span className='mt-1 font-bold' style={{ fontSize: 22 }}>
                      {formatMoney(price)}
                    </span>
                  )}
                  {pricePerSq && (
                    <span className='text-sm font-bold'>
                      {cadFormatter.format(pricePerSq)} per sq.
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {isGrid && showViewDetails && (
        <p className='mt-4 font-light font-openSans italic text-base text-softBlack hover:cursor-pointer'>
          View Details
        </p>
      )}
      {(tag?.toLowerCase() !== 'sold' && (rightButtonLabel.length > 0 || leftButtonLabel.length > 0)) && (
        <div className='flex justify-center w-full space-x-4 font-medium mt-8'>
          {leftButtonLabel.length > 0 ? (
            <button
              onClick={onLeftButtonClick}
              className='w-40 h-12 rounded border border-softBlack70'
            >
              {leftButtonLabel}
            </button>
          ) : null}
          {rightButtonLabel.length > 0 ? (
            <button
              onClick={onRightButtonClick}
              className='w-32 h-12 rounded bg-volt'
            >
              {rightButtonLabel}
            </button>
          ) : null}
        </div>
      )}
    </div>
  )
}

export default LargeUnitCard
