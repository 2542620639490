import React, {
  useState, useEffect,
  useCallback
} from 'react'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
import { InlineInput, Tabs } from 'ui'
import { FormTabsWrapper, FormWrapper } from 'components'
import { getProjects } from 'store/actions/projectActions'

import { validateRequiredInputs } from 'utils'
import { TSaveButton } from 'components/DefaultButtons'
import { createOrUpdateUnitGroup, getUnitGroups } from 'store/actions/unitGroupsActions'

const TabHeader = ({ title, subtitle }) => (
  <div className='space-y-1'>
    <h3 className='text-lg leading-6 font-medium text-gray-900'>
      {title && title}
    </h3>
    <p className='max-w-2xl text-sm text-gray-500'>
      {subtitle && subtitle}
    </p>
  </div>
)

const TabContent = ({ children }) => (
  <div className='mt-6'>
    <dl className='divide-y divide-gray-200'>
      {children}
    </dl>
  </div>
)

const UnitGroupData = (props) => {
  const {
    _id,
    userObject: {
      userType: loggedUserType
    }
  } = props

  const [name, setName] = useState('')
  const [description, setDescription] = useState('')

  const [project, setProject] = useState([])
  const [projectsData, setProjectsData] = useState([])
  const [gettingProjects, setGettingProjects] = useState(false)
  const [gettingData, setGettingData] = useState(false)

  const [selectedTab, setSelectedTab] = useState('general')

  const [saving, setSaving] = useState(false)

  // When mounted, get the list of projects to fill Project Select
  useEffect(() => {
    setGettingProjects(true)
    getProjects('')
      .then((projects) => {
        const tmpProjects = projects.map((project) => ({
          id: project._id, value: project.projectName
        }))

        setProjectsData(tmpProjects)
        setGettingProjects(false)
      })
      .catch(() => {
        setGettingProjects(false)
      })
  }, [])

  const getUnitGroupData = useCallback(() => {
    if (_id) {
      setGettingData(true)

      // Get project data from id
      getUnitGroups(_id)
        .then((unitGroup) => {
          const { name, description, project } = unitGroup

          if (project && Object.keys(project).length) {
            setProject(project._id)
          }

          setName(name)
          setDescription(description)
          setGettingData(false)
        })
    } else {
      setGettingData(false)
    }
  }, [_id])

  useEffect(() => {
    getUnitGroupData()
  }, [getUnitGroupData])

  const _onSaveClick = useCallback(() => new Promise((resolve, reject) => {
    validateRequiredInputs('createUnitGroup')
      .then(() => {
        setSaving(true)

        const params = {
          _id,
          name,
          description,
          project
        }

        createOrUpdateUnitGroup(params)
          .then(() => {
            setSaving(false)
            resolve()
          })
          .catch(() => {
            setSaving(false)
            getUnitGroupData()
            reject()
          })
      })
  }), [
    _id, description, getUnitGroupData, name, project
  ])

  const renderGeneralTab = useCallback(() => (
    <div className={`${selectedTab !== 'general' && 'sr-only'}`}>
      <TabHeader
        title='General'
        subtitle='Here are all infos related to the unit group'
      />
      <TabContent>
        <InlineInput
          id='name'
          key='name'
          type='text'
          label='Name'
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder={'the unit group\'s name'}
          required
          validateKey='createUnitGroup'
          loading={gettingData}
          onClickUpdate={_id ? _onSaveClick : null}
        />
        <InlineInput
          id='description'
          key='description'
          type='text'
          label='Description'
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder='and the description'
          required
          validateKey='createUnitGroup'
          loading={gettingData}
          onClickUpdate={_id ? _onSaveClick : null}
        />
        <InlineInput
          id='project'
          key='project'
          type='text'
          label='Project'
          value={project}
          typeInput='select'
          data={projectsData}
          onChange={(project) => setProject(project)}
          placeholder={'floor plan\'s project'}
          required
          validateKey='createFloorPlan'
          loading={gettingData || gettingProjects}
          onClickUpdate={_id ? _onSaveClick : null}
        />
      </TabContent>
    </div>
  ), [
    _id, _onSaveClick, description, gettingData, gettingProjects,
    name, project, projectsData, selectedTab
  ])

  const renderIdTab = useCallback(() => (
    <div className={`${selectedTab !== 'id' && 'sr-only'}`}>
      <TabHeader
        title='ID'
        subtitle='This tab is only available to Super Admins'
      />
      <TabContent>
        <InlineInput
          key='unitGroupId'
          label='Unit Group ID'
          value={_id}
          onChange={(e) => {}}
          placeholder='unit group ID'
          loading={gettingData}
          disabled
        />
      </TabContent>
    </div>
  ), [gettingData, _id, selectedTab])

  const getMenus = useCallback(() => {
    const menus = [
      { key: 'general', title: 'General' }
    ]

    if (loggedUserType === 'SuperAdmin' && Boolean(_id)) {
      menus.push(
        { key: 'id', title: 'ID' }
      )
    }

    return menus
  }, [loggedUserType, _id])

  return (
    <FormWrapper>
      <FormTabsWrapper>
        <Tabs
          menus={getMenus()}
          tabSelected={selectedTab}
          onClick={(tab) => setSelectedTab(tab)}
        />
        {
          !_id && (
            <TSaveButton
              loading={saving}
              className='ml-3'
              onClick={_onSaveClick}
            >
              Save unit group
            </TSaveButton>
          )
        }
      </FormTabsWrapper>

      <div className='mt-7'>
        {renderGeneralTab()}
        {renderIdTab()}
     </div>
    </FormWrapper>
  )
}

const mapStateToProps = (state) => ({
  userObject: state.authReducer.userObject
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(UnitGroupData)

UnitGroupData.propTypes = {
  _id: propTypes.string,
  userObject: propTypes.shape({
    userType: propTypes.string
  })
}

TabHeader.propTypes = {
  title: propTypes.string,
  subtitle: propTypes.string
}

TabContent.propTypes = {
  children: propTypes.node
}
