/* eslint-disable */
// @ts-nocheck
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PacificaLogo from '../../assets/img/pacifica-logo.png';
import { ProjectData } from 'utils/projectConfig';
import { AiFillCaretUp } from 'react-icons/ai';
import { AreaChart } from '@tremor/react';
import CustomTable from 'components/CustomTable';
import CustomButton from 'components/CustomButton';
import { SHOW_HIDE_SIDEBAR } from 'store/actions/actionTypes';
import LeadsSignUpChart from './components/LeadsSignUpChart';

const SectionWrapper = ({ children, className = '' }) => (
  <div className={`rounded-xl border bg-card text-card-foreground shadow bg-white overflow-hidden ${className}`}>
    {children}
  </div>
)

// const CardUI = ({
//   title,
//   number,
//   Icon
// }) => (
//   <SectionWrapper>
//     <div className='p-6 flex flex-row items-center justify-between space-y-0 pb-2'>
//       <h3 className='tracking-tight text-sm font-medium truncate'>
//         {title}
//       </h3>
//       {Icon}
//     </div>
//     <div className='p-6 pt-0'>
//       <div className='text-2xl font-bold'>
//         {number}
//       </div>
//     </div>
//   </SectionWrapper>
// )

// const HorizontalCardUI = ({
//   title,
//   number,
//   Icon
// }) => (
//   <div className='bg-white w-full gap-y-3 basis-1/2 z-99 flex flex-col top-20 justify-between'>
//     <div className='flex items-center justify-between text-[14px] font-medium uppercase'>
//       {title}
//       {Icon}
//     </div>
//     <div className='text-[24px] font-bold'>
//       {number}
//     </div>
//   </div>
// )

const TabWrapper = ({
  tabMenus,
  setSelectedTab,
  selectedTab
}) => (
  <div className='w-full md:w-fit flex items-center md:justify-center rounded-lg bg-white p-1 text-zinc-600'>
    {tabMenus.map((tab, index) => (
      <div
        key={index}
        onClick={() => {
          setSelectedTab(tab.id)
        }}
        className={`flex flex-1 w-full min-w-[110px] md:w-fit md:min-w-[160px] max-w-[180px] text-center justify-center items-center just px-3 py-[6px] cursor-pointer rounded-[4px] font-semibold text-[12px] ${selectedTab === tab.id
          ? 'bg-[#18181B] text-white shadow-sm'
          : 'text-zinc-600 hover:bg-zinc-100'
          } transition-all`}
      >
        {tab.name}
      </div>
    ))}
  </div>
)

// const HorizontalLine = ({ className }) => (<div className={`w-full h-[1px] bg-zinc-200 ${className}`} />)

const OfferDetailsHeader = () => {
  const typesOfContracts = [
    {
      id: 'pendingSignature',
      name: 'Pending signature',
      color: '#EDDF65',
      number: 105
    },
    {
      id: 'pendingApproval',
      name: 'Pending approval',
      color: '#6D6B6D',
      number: 47
    },
    {
      id: 'rescission',
      name: 'Rescission',
      color: '#C1C0C1',
      number: 23
    },
    {
      id: 'soldFirm',
      name: 'Sold firm',
      color: '#2E2B2E',
      number: 14
    }
  ]
  return (
    <div className='w-full flex items-center gap-x-10'>
      <div className='flex-1 flex flex-col gap-1'>
        <h5 className='text-base font-medium mb-0'>Total offers</h5>
        <h2 className='text-2xl font-semibold mb-0'>211</h2>
        <div className='w-fit rounded-md bg-[#D6FABB] flex gap-1 items-center py-1 px-2'>
          <AiFillCaretUp className='text-[#44AC2A]' />
          <p className='text-xs font-semibold mb-0'>1.6%</p>
        </div>
      </div>
      <div className='flex items-center gap-x-10'>
        <div>
          <svg width="420" height="28" viewBox="0 0 420 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_52_918)">
              <mask id="path-2-inside-1_52_918" fill="white">
                <path d="M0 0H185V28H0V0Z" />
              </mask>
              <path d="M0 0H185V28H0V0Z" fill="#EDDF65" />
              <path d="M182 0V28H188V0H182Z" fill="white" mask="url(#path-2-inside-1_52_918)" />
              <mask id="path-4-inside-2_52_918" fill="white">
                <path d="M185 0H294V28H185V0Z" />
              </mask>
              <path d="M185 0H294V28H185V0Z" fill="#6D6B6D" />
              <path d="M291 0V28H297V0H291Z" fill="white" mask="url(#path-4-inside-2_52_918)" />
              <mask id="path-6-inside-3_52_918" fill="white">
                <path d="M294 0H373V28H294V0Z" />
              </mask>
              <path d="M294 0H373V28H294V0Z" fill="#C1C0C1" />
              <path d="M370 0V28H376V0H370Z" fill="white" mask="url(#path-6-inside-3_52_918)" />
              <rect x="373" width="47" height="28" fill="#2E2B2E" />
            </g>
            <defs>
              <clipPath id="clip0_52_918">
                <rect width="420" height="28" rx="6" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div className='flex items-center gap-x-4'>
          {
            typesOfContracts.map((type, index) => (
              <div key={type.id} className={`flex flex-col gap-y-1 px-4 ${index !== 0 ? 'border-l border-[#2E2B2E] border-opacity-25' : ''}`}>
                <div className='flex items-center gap-1'>
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="6" cy="6" r="6" fill={type.color} />
                  </svg>
                  <h6 className='text-sm mb-0 font-medium'>{type.name}</h6>
                </div>
                <p className='text-xl mb-0 font-bold'>{type.number}</p>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

const dummyChartData = [
  {
    date: 'Jan 22',
    SolarPanels: 2890,
    'Inverters': 2338,
  },
  {
    date: 'Feb 22',
    SolarPanels: 2756,
    'Inverters': 2103,
  },
  {
    date: 'Mar 22',
    SolarPanels: 3322,
    'Inverters': 2194,
  },
  {
    date: 'Apr 22',
    SolarPanels: 3470,
    'Inverters': 2108,
  },
  {
    date: 'May 22',
    SolarPanels: 3475,
    'Inverters': 1812,
  },
  {
    date: 'Jun 22',
    SolarPanels: 3129,
    'Inverters': 1726,
  },
  {
    date: 'Jul 22',
    SolarPanels: 3490,
    'Inverters': 1982,
  },
  {
    date: 'Aug 22',
    SolarPanels: 2903,
    'Inverters': 2012,
  },
  {
    date: 'Sep 22',
    SolarPanels: 2643,
    'Inverters': 2342,
  },
  {
    date: 'Oct 22',
    SolarPanels: 2837,
    'Inverters': 2473,
  },
  {
    date: 'Nov 22',
    SolarPanels: 2954,
    'Inverters': 3848,
  },
  {
    date: 'Dec 22',
    SolarPanels: 3239,
    'Inverters': 3736,
  },
]

const CustomSection = ({ children, title = '', subTitle = '', className = '', isHavingLiveTag = false }) => (
  <SectionWrapper className={`p-6 flex flex-col gap-4 min-h-[600px] ${className}`}>
    {
      (title?.length > 0 || subTitle?.length > 0 || isHavingLiveTag) &&
      <div className='flex flex-col gap-1'>
        {
          isHavingLiveTag &&
          <div className='mt-1 w-fit rounded-md bg-[#D6FABB] flex gap-2 items-center py-1 px-2.5'>
            <div className='h-2.5 w-2.5 rounded-full bg-[#44AC2A]' />
            <p className='text-xs font-semibold mb-0'>Live</p>
          </div>
        }
        {
          title?.length > 0 &&
          <h3 className='mb-0 text-xl font-semibold'>{title}</h3>
        }
        {
          subTitle?.length > 0 &&
          <p className='mb-0 text-base font-normal text-zinc-400'>{subTitle}</p>
        }
      </div>
    }
    {children}
  </SectionWrapper>
)

const CustomTableSection = ({
  title = '',
  dataSource = [],
  columns,
  errorTitle,
  handleBtnClick,
  btnText
}) => (
  <SectionWrapper className='p-6 flex flex-col gap-4 min-h-[600px]'>
    <CustomTable
      dataSource={dataSource}
      columns={columns}
      tailwindTable
      loading={false}
      isFromContracts
      noMargin
      showTablePagination={false}
      isSmall
      isWithoutBorder
      title={title}
      isHideEmptyMessage
    />
    {
      !(dataSource?.length > 0) &&
      <div className='p-6 flex flex-col items-center gap-4 border-2 border-dashed rounded-lg shadow-md'>
        <h3 className='mb-0 text-xl font-semibold'>{errorTitle}</h3>
        <CustomButton handleClick={handleBtnClick}>
          <span className='p-2 px-3 text-base font-medium text-center'>
            {btnText}
          </span>
        </CustomButton>
      </div>
    }
  </SectionWrapper>
)

const NewWhiteCapDashboard = (props) => {
  const dispatch = useDispatch()
  const appReducer = useSelector((state) => state.appReducer);
  const [selectedTab, setSelectedTab] = useState('tab-1');
  const [projectData, setProjectData] = useState(null);
  const [projectName, setProjectName] = useState('');
  const [transactions, setTransactions] = useState([])
  const tabMenus = [
    {
      id: 'tab-1',
      name: 'Dashboard',
    },
    {
      id: 'tab-2',
      name: 'Activity',
    },
    {
      id: 'tab-3',
      name: 'Transactions',
    },
    {
      id: 'tab-4',
      name: 'Contracts',
    },
    {
      id: 'tab-5',
      name: 'Parking',
    },
  ];

  useEffect(() => {
    let projectId = process.env.REACT_APP_PROJECT_ID;
    if (projectId) {
      setProjectData(ProjectData.find((project) => project.id === projectId));
    }
  }, []);

  useEffect(() => {
    const list = appReducer?.listOfProjects;
    const selectedProjectId = appReducer?.appProject;

    if (!list || !selectedProjectId) return;

    const activeProject = list.find(
      (project) => project._id === selectedProjectId
    );
    setProjectName(activeProject?.projectName);
  }, [appReducer]);

  const dataFormatter = (number) => `$${Intl.NumberFormat('us').format(number).toString()}`;

  const transactionsTableColumns = useMemo(
    () => [
      {
        id: 'transaction',
        label: 'Transaction',
        Title: (
          <span className='leading-none text-sm font-normal text-[#2E2B2E] text-opacity-70'>
            Transaction #
          </span>
        ),
        accessor: 'transaction',
        dataIndex: 'transaction',
        isSort: true,
        sortingType: 'string',
        Cell: (props) => (
          <div className='w-full flex flex-col'>
            <p className='mb-0 truncate text-base font-normal'>
              {moment(props.cell.value).format('MM/DD/YYYY')}
            </p>
          </div>
        )
      },
      {
        id: 'primaryBuyer',
        label: 'Primary buyer',
        Title: (
          <span className='leading-none text-sm font-normal text-[#2E2B2E] text-opacity-70'>
            Primary buyer
          </span>
        ),
        accessor: 'primaryBuyer',
        dataIndex: 'primaryBuyer',
        isSort: true,
        sortingType: 'string',
        Cell: (props) => (
          <span className='flex items-center space-x-4'>
            <span className='text-base font-normal capitalize'>
              {props.cell.value?.length > 20
                ? props.cell.value.slice(0, 19) + '...'
                : props.cell.value}
            </span>
          </span>
        )
      },
      {
        id: 'purchasePrice',
        label: 'purchasePrice',
        Title: (
          <span className='leading-none text-sm font-normal text-[#2E2B2E] text-opacity-70'>
            Purchase price
          </span>
        ),
        accessor: 'purchasePrice',
        dataIndex: 'purchasePrice',
        isSort: true,
        sortingType: 'string',
        Cell: (props) => (
          <span className='flex items-center space-x-4'>
            <span className='text-base font-normal capitalize'>
              {props.cell.value}
            </span>
          </span>
        )
      }
    ],
    []
  );

  return (
    <div className='bg-white flex justify-center min-h-[calc(100vh-64px)]'>
      <div className='w-full container px-4 md:px-8 py-7 max-w-[1440px] flex flex-col gap-6 relative'>
        <div className='w-full items-center top-10 mb-2 flex justify-between gap-6'>
          <div className='w-full flex items-center'>
            <img
              src={projectData?.logo ?? PacificaLogo}
              className='w-auto h-[50px] object-contain cursor-pointer'
              onClick={() => {
                dispatch({ type: SHOW_HIDE_SIDEBAR, payload: true })
              }}
            />
          </div>
        </div>
        <div className='w-full gap-2 md:gap-6 flex flex-col lg:flex-row items-start lg:items-center lg:justify-between overflow-hidden'>
          <h2 className='flex items-center justify-center text-center text-black font-medium md:font-semibold text-xl md:text-3xl p-2 md:p-4 truncate mb-0'>
            {projectName || '1818 Pacifica'}
          </h2>
          <div className='max-w-full hide-scrollbar overflow-x-auto'>
            <TabWrapper
              tabMenus={tabMenus}
              setSelectedTab={setSelectedTab}
              selectedTab={selectedTab}
            />
          </div>
        </div>
        <div className='w-full gap-6 lg:gap-y-0 flex flex-col lg:flex-row justify-between'>
          <div className='w-full lg:flex-1 flex flex-col gap-y-6'>
            <SectionWrapper className='p-6 flex flex-col gap-6'>
              <OfferDetailsHeader />
              <AreaChart
                className="h-80"
                data={dummyChartData}
                index="date"
                categories={['SolarPanels', 'Inverters']}
                colors={['yellow', 'fuchsia']}
                valueFormatter={dataFormatter}
                yAxisWidth={60}
                onValueChange={(v) => console.log(v)}
                showLegend={false}
              />
            </SectionWrapper>
            <CustomSection title='Leads' subTitle='last updated 30 seconds ago'>
              <LeadsSignUpChart />
            </CustomSection>
            <div className='grid grid-cols-2 w-full gap-6'>
              <CustomSection title='Recent Activity' subTitle='last updated 30 seconds ago' isHavingLiveTag></CustomSection>
              <CustomTableSection
                title='Transactions'
                dataSource={transactions}
                columns={transactionsTableColumns}
                errorTitle='No Transactions'
                handleBtnClick={() => { }}
                btnText='Create Offer'
              />
              <CustomSection title='New Contacts' subTitle='last updated 30 seconds ago' className='col-span-2'></CustomSection>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default NewWhiteCapDashboard;
