/* eslint-disable react-hooks/exhaustive-deps */
import ProgressBar from 'components/ProgressBar'
import React from 'react'
import { GrayText, TextBold } from '../styles'
import ReportChat from './ReportChat'

export const ItemType = {
  NORMAL: 'Normal',
  GRAPH: 'Graph'
}

export const TableTitle = ({ isStatic, title = '', date = '' }) => {
  return (
    <div className='flex justify-between flex-col'>
      <TextBold className='tracking-[0.24px] font-bold text-xl'>{`${title} ${
        isStatic ? '(static)' : ''
      }`}</TextBold>
      <GrayText className='text-xs pt-2'>{date}</GrayText>
    </div>
  )
}

export const TableListItem = ({ title = '', value, type, vertical = true }) => {
  if (type === ItemType.GRAPH) {
    return (
      <>
        <div className='flex gap-6 mb-4 flex-wrap'>
          {value.data.map((item, index) => (
            <div className='flex items-center gap-2' key={index}>
              <div
                className={'w-3 h-3 rounded-sm'}
                style={{ backgroundColor: item.progressColor }}
              ></div>
              <span className='font-normal text-sm capitalize'>
                {item.title}
              </span>
            </div>
          ))}
        </div>
        <>
          {value.data.map((item, index) => (
            <ProgressBar
              key={index}
              currentPercent={item.value / value.total}
              progressColor={item.progressColor}
              backgroundColor={item.progressBackgroundColor}
              height={'30px'}
            />
          ))}
        </>
      </>
    )
  } else {
    if (vertical) {
      return (
        <div className='flex justify-between flex-col'>
          <TextBold className='tracking-[0.24px] text-xl font-bold'>
            {value}
          </TextBold>
          <GrayText className='text-xs pt-2'>{title}</GrayText>
        </div>
      )
    } else {
      return (
        <div className='flex justify-between flex-row border border-softBlack_70 p-1'>
          <GrayText className='text-xs'>{title}</GrayText>
          <TextBold className='tracking-[0.24px] text-xl font-bold'>
            {value}
          </TextBold>
        </div>
      )
    }
  }
}

const AllAgentsSalesReport = ({ Title, Data, isStatic = false }) => {
  // grid grid-cols-2 gap-4
  // flex flex-wrap gap-10 flex-col  h-screen max-h-[300px] class="
  return (
    <>
      <div className='px-12 flex pt-6'>
        <span className='font-bold text-xl font-openSans'>{Title}</span>
      </div>
      <div className='mx-8 mt-8 mb-8 grid grid-cols-1 lg:grid-cols-2 gap-4'>
        {Data.map((item, index) => {
          return (
            <div className='p-4 bg-[#fcfcfa] gap-4'>
              <TableTitle {...item} isStatic={isStatic} />
              <div className='border-b-2 border-black mt-4' />
              {item?.type !== ItemType.GRAPH && (
                <div>
                  {item.data?.map((cItem, cIndex) => {
                    return (
                      <div className='my-4'>
                        <TableListItem {...cItem} />
                        <div className='border-b-2 border-gray mt-4' />
                      </div>
                    )
                  })}
                </div>
              )}
              {item?.type === ItemType.GRAPH && <ReportChat {...item} />}
            </div>
          )
        })}
      </div>
    </>
  )
}

export default AllAgentsSalesReport
