import styled from 'styled-components'
import { Row } from 'antd'
import standardStyle from 'assets/css/standardStyle'
import { motion } from 'framer-motion'

export const OptionWrapper = styled(motion.div)`
  background: ${standardStyle.colors.primary};
  color: white;
  border-radius: 4px;
  /* border: 1px solid ${props => props.selected === true ? props.developerColor : standardStyle.colors.gray}; */
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 70px;
  text-align: center;
  box-shadow: 0 0 ${props => props.selected === true ? '2px 2px' : '0px 0px'} rgba(0,0,0,0.07);
  height: 100%;

  &:hover {
    cursor: pointer;
  }
`

export const TitleWrapper = styled(Row)`
  justify-content: center;
  font-size:18px;
  pointer-events: none;
`
