import React from 'react'

import Header from './components/Header'
import SelectBuyer from './components/SelectBuyer'

const ReserveUnit = () => {
  return (
    <div>
      <Header />
      <div className='px-8 pt-33.25 pb-8'>
        <div className='p-6 bg-white'>
          <SelectBuyer />
        </div>
      </div>
    </div>
  )
}

export default ReserveUnit
