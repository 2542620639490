import React, { useState } from 'react'

import { COLORS } from '../constants/colors'

import { ReactComponent as UserIcon } from 'assets/icons/user.svg'
import { ReactComponent as CorporationIcon } from 'assets/icons/corporation.svg'
import { ReactComponent as LinkIcon } from 'assets/icons/link.svg'

import UserInfoCard from 'components/UserInfoCard'
import BuyerCard from 'components/BuyerCard'
import { LoadingDetails } from 'pages/desktopMode/inventory/Allocation/AllocateInventory/Steps/Building'

import useGetProjectBuyers from 'hooks/useGetProjectBuyers'

const PWASelectBuyer = ({
  setButtonState,
  selectedBuyers,
  setSelectedBuyers
}) => {
  const [active, setActive] = useState('Buyers')
  const { loading, buyers } = useGetProjectBuyers()
  // const [showCreateModal, setShowCreateModal] = useState(false)

  const onChangeBuyer = (broker) => {
    const selectedBuyersClone = [...selectedBuyers]
    let isBuyerFound = false
    for (let i = 0; i < selectedBuyersClone.length; i++) {
      const brokerTemp = selectedBuyersClone[i]
      if (broker.id === brokerTemp.id) {
        isBuyerFound = true
        break
      }
    }
    if (!isBuyerFound) {
      setSelectedBuyers([...selectedBuyersClone, broker])
    } else {
      setSelectedBuyers(
        selectedBuyersClone.filter((brokerTemp) => broker.id !== brokerTemp.id)
      )
    }
  }

  const isBuyerActive = (broker) => {
    for (let i = 0; i < selectedBuyers.length; i++) {
      const brokerTemp = selectedBuyers[i]
      if (broker.id === brokerTemp.id) {
        return true
      }
    }
    return false
  }

  if (loading) {
    return (
      <div
        className='flex items-center justify-center w-full h-screen'
        style={{ gridColumn: '1 / -1' }}
      >
        <LoadingDetails title='Loading Buyers' />
      </div>
    )
  }

  return (
    <>
      {/* <NewBroker
        {...{
          showCreateModal,
          setShowCreateModal,
          onSuccess,
          isBroker: true
        }}
      /> */}
      <div className='flex' style={{ gap: 43, margin: '48px 0px 48px 80px' }}>
        <p
          className={`font-bold font-openSans text-4xl hover:cursor-pointer ${
            active === 'Buyers' ? 'text-softBlack' : 'text-softBlack70'
          }`}
          onClick={() => setActive('Buyers')}
        >
          Buyers
        </p>
        <p
          className={`font-bold font-openSans text-4xl hover:cursor-pointer ${
            active === 'Corporations' ? 'text-softBlack' : 'text-softBlack70'
          }`}
          onClick={() => setActive('Corporations')}
        >
          Corporations
        </p>
      </div>
      <div className='px-20'>
        <div className='grid grid-cols-3 gap-6 mt-10'>
          {buyers.map((broker, index) => (
            <UserInfoCard
              {...broker}
              key={index}
              onSelect={() => {
                onChangeBuyer(broker)
                setButtonState('Default')
              }}
              isActive={isBuyerActive(broker)}
              className=''
              backgroundColor={COLORS.white}
            />
          ))}
        </div>
        <div
          style={{ backgroundColor: COLORS.softBlack70 }}
          className='w-full h-px my-10'
        />
        <div className='flex gap-6 w-full justify-between'>
          <BuyerCard
            title={'Create New Buyer'}
            Icon={UserIcon}
            // onClick={() => setShowCreateModal(true)}
            onClick={() => {}}
            classes='w-1/3'
            backgroundColor={COLORS.white}
          />
          <BuyerCard
            title='Create New Corporation'
            Icon={CorporationIcon}
            onClick={() => {}}
            classes='w-1/3'
            backgroundColor={COLORS.white}
          />
          <BuyerCard
            title='Link with Existing Buyer'
            Icon={LinkIcon}
            onClick={() => {}}
            classes='w-1/3'
            backgroundColor={COLORS.white}
          />
        </div>
      </div>
    </>
  )
}

export default PWASelectBuyer
