import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import CancelModal from 'components/Modal/Cancel'
import CustomButton from 'components/CustomButton'

const NoHeader = ({ title, redirect }) => {
  const history = useHistory()
  const [isOpenCancelModal, setIsOpenCancelModal] = useState(false)

  const onCancel = () => {
    setIsOpenCancelModal(true)
  }

  const onConfirm = () => {
    setIsOpenCancelModal(false)
    history.push(redirect)
  }
  const cancelMessage = 'Are you sure you want to cancel?'
  return (
    <>
      <div className='bg-white fixed w-full flex justify-between items-center px-8 pt-6 pb-6 border-b-2 border-softBlack z-10'>
        <p className='my-0 text-4xl font-bold font-openSans'>{title}</p>
        <CustomButton variant='ghost' handleClick={onCancel}>
          <span className='font-medium py-3 px-4 text-base flex gap-2 items-center'>
            Cancel
          </span>
        </CustomButton>
      </div>
      <CancelModal {...{ isOpenCancelModal, setIsOpenCancelModal, onConfirm, cancelMessage }} />
    </>
  )
}

export default NoHeader
