/* eslint-disable no-unused-vars */
import React, { useCallback, useState } from 'react'
import { ImageUploaderPrimary } from 'components/ImageUploader'
import CustomInput from 'components/CustomInput'
import { uploadDocumentImage } from 'store/actions/usersActions'
import { toast } from 'react-hot-toast'
import { Modal } from '@material-ui/core'
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg'

const PaymentReceipt = ({
  title,
  onClose,
  onSucess,
  reservation: { _id: reservationId },
  projectId,
  show,
  type
}) => {
  const [documentName, setDocumentName] = useState('')
  const [paymentReceiptImage, setPaymentReceiptImage] = useState(null)
  const [isUploading, setIsUploading] = useState(false)

  const uploadImage = useCallback(() => {
    setIsUploading(true)
    const toastId = toast.loading('Uploading Document...')
    uploadDocumentImage(
      reservationId,
      documentName,
      paymentReceiptImage,
      projectId,
      type
    )
      .then((data) => {
        toast.dismiss(toastId)
        toast.success('Document uploaded successfully')
        onSucess({
          ...data,
          reservationId
        })
      })
      .catch((e) => {
        toast.dismiss(toastId)
      })
      .finally(() => {
        setIsUploading(true)
      })
  }, [documentName, paymentReceiptImage])

  return (
    <Modal
      open={show}
      onClose={onClose}
      aria-labelledby='Private Document Upload Modal'
      aria-describedby='Private Document Edit Modal for uploading document'
    >
      <div
        className='relative flex flex-col font-openSans bg-grey-8 p-4 rounded shadow-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'
        style={{ width: '512px' }}
      >
        <div className='flex flex-col gap-10 justify-center items-center rounded-3xl p-4'>
          <div className='flex justify-between items-center text-center w-4/5 pt-5'>
            <h1 className='text-2xl text-italic'>{title}</h1>
            <button onClick={onClose}>
              <CrossIcon />
            </button>
          </div>
          <div className='flex flex-col gap-3 w-full items-center'>
            <div className='flex flex-col w-4/5 items-start justify-start gap-2'>
              <div className='font-normal'>Document Name</div>
              <div className='w-full'>
                <CustomInput
                  label=''
                  placeholder='Enter name of Document (Check OR receipt)'
                  value={documentName}
                  onChange={(e) => {
                    setDocumentName(e.target.value)
                  }}
                  type='text'
                  containerClasses={undefined}
                  classes=''
                  required
                />
              </div>
            </div>
            <div className='flex flex-col w-4/5 items-start justify-start gap-2'>
              <div className='font-normal'>Payment Receipt or Check</div>
              <div className='w-full'>
                <ImageUploaderPrimary
                  onFileChange={(e) =>
                    setPaymentReceiptImage(e?.currentTarget?.files?.[0] ?? '')
                  }
                  value='Drag & Drop Files Here'
                  disabled={isUploading}
                />
              </div>
            </div>
            <button
              className='flex justify-center items-center font-bold py-4  px-12 bg-volt rounded cursor-pointer'
              onClick={uploadImage}
              disabled={isUploading}
            >
              Upload Image
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
export default PaymentReceipt
