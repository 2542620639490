import { api, ApiErrors } from 'services/api'
import { message } from 'antd'

export const getStoragePolygons = (id = '') =>
  new Promise((resolve, reject) => {
    api.get(`storage/polygon/${id.length ? id : 'all'}`).then((response) => {
      if (response.ok) {
        resolve(response.data.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const createOrUpdateStoragePolygon = (props) =>
  new Promise((resolve, reject) => {
    api.post(`storage/polygon/${props.storage._id}`, props).then((response) => {
      if (response.ok) {
        message.success(`${props.storage.unitNumber} was created successfully`)
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const deleteStoragePolygon = (id) =>
  new Promise((resolve, reject) => {
    api.delete(`storage/polygon/${id}`).then((response) => {
      if (response.ok) {
        message.success('Storage-polygon deleted successfully')
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })
