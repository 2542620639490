import { Col } from 'antd'
import standardStyle from 'assets/css/standardStyle'
import styled from 'styled-components'
// import { H2 } from 'ui'

export const PageWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
`

export const TitleWrapper = styled.div``

export const Option = styled.div`
  @media ${standardStyle.mediaqueries.xs}, ${standardStyle.mediaqueries.sm} {
    margin: 20px 0;
    min-height: 250px;
  } 
`

export const OptionTitleContainer = styled(Col)`
  padding: 0px 10px;

  span {
    text-align: center;
  }

  div {
    padding-bottom: 0px;
  }
`

// export const OptionTitle = styled(H2)`
//   color: white;
//   text-align: center;
// `

export const ProjectThumbnailWrapper = styled.div``
export const ProjectImageWrapper = styled.div``
export const ProjectImage = styled.img``
export const ProjectLogo = styled.img``
export const ProjectName = styled.span``
export const ListOfProjectsWrapper = styled.div``
