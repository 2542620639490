/*eslint-disable */
// @ts-nocheck
import TableGrid from "components/TableGrid/Table";
import useGetLists, { useGetListsAll } from "hooks/useGetLists";
import { useGetUsersCounts } from "hooks/useGetUsers";
import useProjectId from "hooks/useProjectId";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import CreateListDialog from "./createList";
import useContactContext from "pages/newContacts/hooks/useContactContext";

// eslint-disable-next-line space-before-function-paren
export default function ListsManagement() {
  const { projectId } = useProjectId();
  const { view } = useContactContext();
  const {
    lists,
    isLoading: isLoadingList,
    refetch: refetchLists,
  } = useGetLists(view === "corporate" ? "undefined" : projectId);
  const history = useHistory();
  const { count: usersCounts, isLoading: isLoadingCounts } =
    useGetUsersCounts(projectId);

  useEffect(() => {
    refetchLists();
  }, [refetchLists, projectId]);

  if (isLoadingList || isLoadingCounts) return <div>Loading...</div>;
  const totalLists = lists.length;
  const listCountByType = lists?.reduce(
    (acc, list) => {
      acc[list.listType]++;
      return acc;
    },
    {
      smart: 0,
      static: 0,
    }
  );

  const columns = () => {
    const result = [
      {
        headerName: "List Name",
        field: "title",
        flex: 1,
        cellRenderer: (params) => {
          return (
            <div className="flex flex-row gap-x-1">
              <div>
                <button
                  className="bg-gray-500 text-white border border-border px-2  py-1 rounded-full text-xs"
                  onClick={() => {
                    history.push(`${window.location.pathname}/${params.data._id}`);
                  }}
                >
                  View
                </button>
              </div>
              <div
                className="cursor-pointer"
                onClick={() => {
                  history.push(`${window.location.pathname}/${params.data._id}`);
                }}
              >
                {params.value}
              </div>
            </div>
          );
        },
      },
      {
        headerName: "List Type",
        field: "listType",
        cellRenderer: ({ value }) => {
          return (
            <div>
              {value === "static" && (
                <div className="bg-gray-500 text-white border border-border px-2  py-1 rounded-full text-xs">
                  Standard
                </div>
              )}
              {value === "smart" && (
                <div className="bg-blue-500 text-white border border-border px-2  py-1 rounded-full text-xs">
                  Smart
                </div>
              )}
            </div>
          );
        },
      },
      {
        headerName: 'Project',
        width: 200,
        field: 'project',
        resizable: true,
        cellRenderer: (params) => {
          const project = params?.data?.projectId
          return (
            <div className="flex gap-x-2">
              {project?.projectName}
            </div>
          )
        }
      },
      {
        headerName: "Total Contacts",
        field: "users",
        valueGetter: (params) => params?.data?.users?.length || 0,
      },
      {
        headerName: "Created On",
        field: "createdAt",
        sortable: true,
        cellRenderer: ({ value }) => {
          return new Date(value).toLocaleString();
        },
      },
      {
        headerName: "Updated On",
        field: "updatedAt",
        sortable: true,
        cellRenderer: ({ value }) => {
          return new Date(value).toLocaleString();
        },
      },
    ]
    console.log('view', view)
    if (view === 'project') {
      return result.filter((item) => item.headerName !== "Project");
    }
    return result;
  }

  return (
    <div className="px-5 sm:px-10 flex flex-col py-4">
      <div className="grid grid-cols-3 sm:grid-cols-4 gap-x-4">
        {/* design card view tailwind css */}
        <div className="flex flex-col gap-y-2 py-4 px-4 shadow-sm border-2 border-gray-100 rounded-md">
          <div className="font-semibold text-gray-400 text-xl">
            Total Contacts
          </div>
          <div className="flex items-start font-bold text-xl">
            {usersCounts}
          </div>
        </div>
        <div className="flex flex-col gap-y-2 py-4 px-4 shadow-sm border-2 border-gray-100 rounded-md">
          <div className="font-semibold text-gray-400 text-xl">Total Lists</div>
          <div className="flex items-start font-bold text-xl">{totalLists}</div>
        </div>
        <div className="flex flex-col gap-y-2 py-4 px-4 shadow-sm border-2 border-gray-100 rounded-md">
          <div className="font-semibold text-gray-400 text-xl">Smart Lists</div>
          <div className="flex items-start font-bold text-xl">
            {listCountByType.smart || 0}
          </div>
        </div>
      </div>
      <div className='flex flex-col'>
        <div className="flex w-full justify-end">
          <CreateListDialog
            isCorporate={view === 'corporate'}
            refreshData={() => {
              refetchLists();
            }}
          />
        </div>
        <TableGrid
          pagination={true}
          style={{
            height: "650px",
            minHeight: "650px",
          }}
          columnDefs={columns()}
          getData={(filter, pagination,) => {
            return new Promise((resolve) => {
              // Row Data: The data to be displayed.
              const rowData = lists.sort((a, b) => {
                if (new Date(a.updatedAt) < new Date(b.updatedAt)) return 1;
                if (new Date(a.updatedAt) > new Date(b.updatedAt)) return -1;
                return 0;
              })
              resolve(rowData);
            });
          }}
        />
      </div>
    </div>
  );
}
