import useDynamicDropdown from './useDynamicDropdown'

const useLeadSource = () => {
  const {
    data: leadSources,
    loading,
    update,
    deleteItem: deleteLeadSource,
    updateItem: updateSpecific,
    formattedData: formattedLeadsources
  } = useDynamicDropdown('leadSource')

  return {
    leadSources,
    loading,
    update,
    deleteLeadSource,
    updateSpecific,
    formattedLeadsources
  }
}

export default useLeadSource
