// @ts-nocheck
/* eslint-disable */
import CustomModal from 'components/Modal';
import React, { useState, useEffect } from 'react';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import { api } from 'services/api';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import useContactContext from 'pages/newContacts/hooks/useContactContext';

export default function ReportWaitingModal({ waitingModal, handleModal }) {
  const {
    waitingModal: contextWaitingModal,
    setWaitingModal: contextSetWaitingModal,
  } = useContactContext();

  useEffect(() => {
    if (contextWaitingModal === true) {
      handleModal(true);
    } else {
      handleModal(false);
    }
  }, [contextSetWaitingModal, contextWaitingModal]);

  return (
    <CustomModal
      isOpen={waitingModal}
      closeModal={() => {
        handleModal(false);
        contextSetWaitingModal(false);
      }}
      contentLabel='Waiting For Download'
    >
      <div className='font-openSans max-w-[800px] relative bg-grey-8 p-10 rounded shadow-lg w-full'>
        <button
          className='absolute right-6 top-4'
          onClick={() => {
            handleModal(false);
            contextSetWaitingModal(false);
          }}
        >
          <CrossIcon />
        </button>
        <div className='text-[20px] font-bold'>
          We are generating your report...
        </div>
        <div className='mt-2 text-[18px]'>
          This might take few minutes to download the report You will be
          notified once the report is ready to download. you can close this
          window.
        </div>
      </div>
    </CustomModal>
  );
}
