// @ts-nocheck
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { ReactComponent as DownIcon } from 'assets/icons/down.svg'
import { useFormikContext } from 'formik'
import { Select } from 'antd'
import { getDyanamicDropdownData } from 'store/actions/usersActions'

const FormSelect = ({
  title = '',
  options = [],
  fieldName = '',
  placeholder = '',
  multi = false,
  customOptionSchema = false,
  customeLabelAccessor = 'label',
  customeValueAccessor = 'value',
  dynamic = false
}) => {
  const [projectId] = useState('607605e70de444001a71cfc8')
  const { handleChange, setFieldValue, values } = useFormikContext()
  const [data, setData] = useState()
  // const { data } = useDynamicDropdown(fieldName)
  useEffect(() => {
    getDyanamicDropdownData(projectId, fieldName).then((data) => {
      setData(data)
    }).catch((error) => console.log(error))
  }, [])
  return (
    <div key={fieldName} className='flex flex-1 flex-col justify-left'>
      <div className='font-bold text-softBlack_70 italic'>{title}</div>
      <Select
        suffixIcon={<DownIcon />}
        showArrow
        onChange={multi ? (e) => setFieldValue(fieldName, e) : handleChange(fieldName)}
        defaultValue={values[fieldName]}
        placeholder={placeholder}
        mode={multi ? 'multiple' : undefined}
        className='rating-select text-sm bg-white border border-softBlack30 rounded text-softBlack placeholder-softBlack70 h-10'
      >
        {(data && dynamic) && data?.values.map(option => <Select.Option value={customOptionSchema ? !!option[customeValueAccessor] : option}>
          <div className='flex gap-2 items-center'>
            <span className='h-full'>{customOptionSchema ? option[customeLabelAccessor] : option}</span>
          </div>
        </Select.Option>)}
        {!dynamic && options.map(option => <Select.Option value={customOptionSchema ? !!option[customeValueAccessor] : option}>
          <div className='flex gap-2 items-center'>
            <span className='h-full'>{customOptionSchema ? option[customeLabelAccessor] : option}</span>
          </div>
        </Select.Option>)}
      </Select>
    </div>
  )
}

export default FormSelect
