/* eslint-disable space-before-function-paren */
import CustomButton from 'components/CustomButton'
import TableGrid from 'components/TableGrid/Table'
import { ArrowLeft } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { api } from 'services/api'

export default function SMSBroadcastDetails() {
  const [loading, setLoading] = useState(true)
  const [broadCastData, setBroadCastData] = useState(null)

  useEffect(() => {
    const url = window.location.pathname
    const id = url.split('/').pop()
    api.get(`/sms/campaigns/${id}`).then((response) => {
      setBroadCastData(response.data)
      setLoading(false)
    }).catch((error) => {
      console.log(error)
      alert(error)
    })
  }, [])

  if (loading) {
    return <div>Loading...</div>
  }

  const broadCastUsers = broadCastData?.users
  const broadCastReport = broadCastData?.report

  const users = []
  if (broadCastUsers && broadCastUsers.length > 0) {
    broadCastUsers.forEach((item) => {
      users.push({
        userId: item.userId,
        name: item.name,
        phoneNumber: item.phoneNumber
      })
    })
  } else {
    broadCastReport.forEach((item) => {
      const userId = typeof item.user === 'object' ? item.user?._id?.toString() : item?.user?.toString()
      users.push({
        userId,
        name: item.name,
        phoneNumber: item.ToNumber
      })
    })
  }
  const usersWithStatus = users.map((item) => {
    const reportItem = broadCastReport.find((reportItem) => {
      if (reportItem.user) {
        const reportUserId = typeof reportItem.user === 'object' ? reportItem.user?._id?.toString() : reportItem?.user?.toString()
        return reportUserId === item.userId
      } else {
        return false
      }
    })
    const reportUserName = typeof reportItem?.user === 'object' ? reportItem?.user?.fullName : ''
    let finalName = (item.name || reportUserName || '-').trim()
    if (finalName === '') {
      finalName = '-'
    }
    const status = reportItem?.status || '-'
    let notes = ''
    let success = false
    let notesColor = ''
    if (status === 'undelivered') {
      notes = 'Undelivered'
      success = false
      notesColor = 'text-red-500'
    } else if (status === 'delivered') {
      notes = 'Delivered'
      success = true
      notesColor = 'text-green-500'
    } else if (status === 'sent') {
      notes = 'Sent'
      success = true
      notesColor = 'text-blue-500'
    } else if (status === 'delivery_unknown') {
      notes = 'Delivery Unknown'
      notesColor = 'text-yellow-500'
      success = false
    } else if (status === 'failed') {
      notes = 'Failed'
      notesColor = 'text-red-500'
      success = false
    } else if (status === 'accepted') {
      notes = 'Accepted'
      notesColor = 'text-blue-500'
    } else if (status === 'scheduled') {
      notes = 'Scheduled'
      notesColor = 'text-blue-500'
      success = false
    } else if (status === 'queued') {
      notes = 'Queued'
      notesColor = 'text-blue-500'
      success = false
    } else if (status === 'sending') {
      notes = 'Sending'
      notesColor = 'text-blue-500'
      success = false
    } else {
      notes = status
      notesColor = 'text-blue-500'
      success = false
    }
    return {
      ...item,
      name: finalName,
      phoneNumber: reportItem?.ToNumber || item.phoneNumber,
      status,
      success,
      notes,
      notesColor
    }
  })

  const gridData = [{
    title: 'Selected Contacts',
    value: broadCastUsers.length || broadCastReport.length,
    className: ''
  }, {
    title: 'Delivered',
    value: usersWithStatus.filter((item) => item.success).length,
    className: 'text-green-500'
  }, {
    title: 'Failed',
    value: usersWithStatus.filter((item) => !item.success).length,
    className: 'text-red-500'
  }]

  const groupByStatus = usersWithStatus.reduce((acc, item) => {
    if (!acc[item.status]) {
      acc[item.status] = 0
    }
    acc[item.status] += 1
    return acc
  }, {
    delivered: 0,
    undelivered: 0,
    failed: 0,
    sent: 0,
    delivery_unknown: 0
  })

  return (
    <div className='px-8 py-4 sm:px-16 sm:py-8'>
      <div className='pb-3'>
        <div>
          <CustomButton
            className='mb-2 py-1 px-2'
            handleClick={() => {
              window.history.back()
            }}
          >
            <ArrowLeft size={16} /> Go Back
          </CustomButton>
        </div>
        <div className='font-bold text-2xl'>
          {broadCastData.campaignName}
        </div>
        <div>
          {new Date(broadCastData.createdAt).toLocaleString()}
        </div>
      </div>
      <div className='grid grid-cols-3 gap-2'>
        {gridData.map((item, index) => {
          return (
            <div key={index} className={`border border-border rounded-md shadow-sm px-4 py-5 ${item.className}`}>
              <div className='font-bold'>
                {item.title}
              </div>
              <div className='font-extrabold text-2xl'>
                {item.value}
              </div>
            </div>
          )
        })}
      </div>
      <div className='my-3'>
        <div className='text-lg'>
          SMS by Status
        </div>
        <div className='flex flex-wrap gap-x-2'>
          {Object.keys(groupByStatus)
            .map((key, index) => {
              return (
                <div key={index} className='rounded-sm border border-border flex items-center justify-between border-b border-border py-2 px-4 gap-y-4 flex-1'>
                  <div className='capitalize'>
                    {key}
                  </div>
                  <div className='font-bold'>
                    {groupByStatus[key]}
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
      <div>
        <TableGrid
          pagination={false}
          getData={() => {
            return new Promise((resolve) => {
              resolve(usersWithStatus)
            })
          }}
          columnDefs={[
            {
              headerName: 'Name',
              field: 'name',
              sortable: true,
              filter: true,
              flex: 1
            },
            {
              headerName: 'Phone Number',
              field: 'phoneNumber',
              sortable: true,
              filter: true,
              flex: 1
            },
            {
              headerName: 'Status',
              field: 'status',
              sortable: true,
              filter: true,
              flex: 1
            }, {
              headerName: 'Notes',
              field: 'notes',
              sortable: true,
              filter: true,
              flex: 1,
              cellRenderer: ({ data }) => {
                return <span className={data.notesColor}>{data.notes}</span>
              }
            }
          ]}
        />
      </div>
    </div >
  )
}
