// @ts-nocheck
import React from 'react'

import CustomRadio from 'components/CustomRadio'
import CustomSelect from 'components/CustomSelect'
import CustomInput from 'components/CustomInput'

import { RISK_TYPES, RISK_TYPES_OPTIONS } from './constants'

const ClientRisk = ({ riskType, setRiskType, selectedOption, setSelectedOption, riskExplanation, setRiskExplanation }) => {
  const selectedRisk = RISK_TYPES?.find((_risk) => _risk.value === riskType)

  const handleExplanation = (e) => {
    setRiskExplanation(e.target.value)
  }

  return (
    <div className='mt-10'>
      <p className='my-0 text-xl font-bold'>Client Risk</p>
      <p className='my-6 text-base'>
        Determine the level of risk of a money laundering or terrorist financing offence for this client by determining the appropriate cluster of client in your policies and procedures manual this client falls into and choose one of the options below.
      </p>
      <div className='flex gap-10'>
        {RISK_TYPES?.map((_riskType, index) => (
          <CustomRadio
            label={_riskType?.label}
            key={index}
            checked={riskType === _riskType.value}
            onClick={() => setRiskType(_riskType.value)}
          />
        ))}
      </div>
      <div className='mt-6'>
        <CustomSelect
          label={`${selectedRisk.label} OPTIONS`}
          options={RISK_TYPES_OPTIONS?.[riskType]}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />

        {selectedOption === 'explain' && (
          <CustomInput label='OTHER' placeholder='Explain here' containerClasses='mt-6' value={riskExplanation} onChange={handleExplanation} />
        )}
      </div>
    </div>
  )
}

export default ClientRisk
