/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { getProjectBrokers } from 'store/actions/usersActions'

const useGetProjectBrokers = () => {
  const appProject = useSelector((state) => state.appReducer.appProject)

  const [brokers, setBrokers] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getData()
  }, [appProject])

  const getData = async () => {
    setLoading(true)
    try {
      const data = await getProjectBrokers(appProject)
      const tempData = []
      data.forEach((element) => {
        const tempObject = { ...element, isActive: false }
        tempData.push(tempObject)
      })
      setBrokers(tempData)
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  return { loading, brokers, refetch: getData, setBrokers }
}

export default useGetProjectBrokers
