import React from 'react'
import propTypes from 'prop-types'

import { StyledImage } from './styles'

const TableImageThumbnail = ({ src, style, className }) => (
  <StyledImage src={src} style={style} className={className} />
)

export default TableImageThumbnail

TableImageThumbnail.defaultProps = {
  className: 'h-18',
  style: {}
}

TableImageThumbnail.propTypes = {
  src: propTypes.object,
  style: propTypes.object,
  className: propTypes.string
}
