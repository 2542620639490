import React, { useCallback, useEffect, useRef, useState } from 'react'
import propTypes from 'prop-types'
import {
  Title,
  TitleWrapper, ContinueButtonWrapper,
  ListWrapper,
  List,
  RoundedOptionWrapper,
  OptionImageWrapper,
  OptionImage,
  OptionDataWrapper,
  OptionDescriptionWrapper,
  SlideToMoreItemsWrapper
} from './styles'
import { TButton } from 'ui'
import { classNames, formatMoney } from 'utils'
import { initCardAnimator } from './cardAnimation'
import cardAnimationStyles from './index.module.scss'
import { LottieWrapper } from 'components'
import slideAnimation from 'assets/lottieJsons/slideArrows.json'
import { AnimatePresence, motion } from 'framer-motion'
import { CreateOfferStepPrimaryButton } from 'components/CreateOfferCommonComponents'

// const smallDevice = (window.matchMedia(standardStyle.mediaqueries.xs).matches ||
//   window.matchMedia(standardStyle.mediaqueries.sm).matches)

const smallDevice = false

const ColorPalleteItem = ({ className, color }) => (
  <div className={`h-4 w-8 xl:h-6 xl:w-12 ${className}`} style={{ background: color }} />
)

const ColorPalette = ({ colorPalette }) => {
  return (
    <div className='flex flex-row mb-2 px-3'>
      {colorPalette.map((color, index) => {
        let className = ''

        if (index === 0) {
          className = 'rounded-l-md xl:rounded-l-lg'
        }

        if (index === colorPalette.length - 1) {
          className = 'rounded-r-md xl:rounded-r-lg'
        }

        return (
          <ColorPalleteItem
            key={index}
            color={color}
            className={className}
          />)
      })}
    </div>
  )
}

const CreateOfferStepWithList = (props) => {
  const {
    title, items,
    continueButtonText, continueButtonOnClick, continueButtonDisabled,
    optionSelected, onSelectOption, totalDollarValueCurrencyType,
    isCollorStep, showPrice, multiSelectItems, offerId,
    titleWrapperClassName, optionsWrapperClassName, buttonWrapperClassName,
    titleClassName, continueButtonClassName, buttonSize, numberOfItemsToSlide
  } = props

  const [showSlideMoreOptions, setShowSlideMoreOptions] = useState(false)
  const [animationStopped, setAnimationStopped] = useState(false)

  const stackcards = useRef()

  initCardAnimator(stackcards)

  useEffect(() => {
    setTimeout(() => {
      setShowSlideMoreOptions(true)
    }, 300)

    setTimeout(() => {
      setShowSlideMoreOptions(false)
      setAnimationStopped(true)
    }, 5000)
  }, [])

  const RenderOption = useCallback(({ item, id, optionSelected }) => {
    const {
      _id, images, name, description, value,
      colorPalette, selected: itemSelected
    } = item

    let image = ''

    if (images.length) {
      image = images[0]
    }

    let selected = false

    if (multiSelectItems) {
      selected = itemSelected
    } else {
      const { _id: selectedOptionId } = optionSelected
      selected = selectedOptionId === _id
    }

    const offerSelected = ((!offerId) || (offerId && selected))

    return (
      <div
        className={`
          flex flex-col rounded-lg shadow-lg overflow-hidden w-64 md:w-88 lg:w-64 xl:w-88 ml-0 xl:ml-5 h-full
          my-0 ${!offerSelected && 'bg-gray-200'}
          ${smallDevice ? cardAnimationStyles.verticalStackCardsItem : cardAnimationStyles.horizontalStackCardsItem} js-stack-cards__item
        `}
      >
        <RoundedOptionWrapper
          className={`
            flex flex-col overflow-hidden h-full w-full
            ${offerSelected ? 'opacity-100' : 'opacity-60'} ${!offerId ? 'hover:cursor-pointer' : ''}
          `}
          onClick={!offerId ? () => onSelectOption({ item, index: id }) : null}
        >
          <OptionImageWrapper className='h-1/2'>
            <OptionImage src={image} className='h-full w-full object-cover' />
          </OptionImageWrapper>
          <OptionDataWrapper
            className={`
              flex flex-col text-center p-2 items-center
              h-1/2 justify-around
              ${selected ? 'bg-indigo-600' : 'bg-white'}
            `}
          >
            <span className={`${selected && 'text-white'} text-lg xl:text-xl font-semibold `}>{name}</span>
            <OptionDescriptionWrapper className='h-8 overflow-y-auto'>
              <span className={`${selected && 'text-white'} text-sm xl:text-base mx-5`}>{description}</span>
            </OptionDescriptionWrapper>
            {
              showPrice && (
                <span
                  className={`${selected && 'text-white'}
                  text-xl xl:text-2xl`}
                >
                  {formatMoney(value, totalDollarValueCurrencyType)}
                </span>
              )
            }

            {
              isCollorStep && (
                <ColorPalette colorPalette={colorPalette} />
              )
            }

            {
              offerSelected && (
                <TButton
                  // style={{ background: selected && 'white' }}
                  className={classNames('px-7 h-6 text-lg xl:h-8')}
                >
                  {selected ? 'Selected' : 'Select'}
                </TButton>
              )
            }

          </OptionDataWrapper>
        </RoundedOptionWrapper>
      </div>
    )
  }, [
    isCollorStep, multiSelectItems, offerId, onSelectOption,
    showPrice, totalDollarValueCurrencyType
  ])

  const RenderListOfOptions = useCallback(() => {
    return items.map((item, index) => (
      <RenderOption
        key={index}
        id={index}
        item={item}
        optionSelected={optionSelected}
      />
    ))
  }, [items, optionSelected])

  const renderOptions = () => (
    <ListWrapper className={`${optionsWrapperClassName} h-10/20 lg:h-9/20 xl:h-1/2 flex flex-col overflow-hidden justify-center`}>
      <List
        ref={stackcards}
        className={`${cardAnimationStyles.stackCards} h-full js-stack-cards
        -ml-2 pl-2 ${items.length > 3 ? 'mr-2' : ''} pb-5`}
        smallDevice={smallDevice.toString()}
        id='optionsWrapper'
      >
        <RenderListOfOptions />
      </List>
    </ListWrapper>
  )

  return (
    <>
      <TitleWrapper className={`h-1/5 flex items-end pb-5 xl:pb-10 ${titleWrapperClassName}`}>
        <Title className={`${titleClassName || 'text-4xl'}`}>{title}</Title>
      </TitleWrapper>

      {!items.length && <div className='text-lg flex justify-center'>There are no items to be shown</div>}

      { renderOptions() }

      <ContinueButtonWrapper
        className={`h-3/20 md:h-3/20 flex flex-col-reverse md:flex-row items-start md:items-center
        justify-between pb-3 ${buttonWrapperClassName}`}
      >
        <CreateOfferStepPrimaryButton
          className={continueButtonClassName}
          disabled={continueButtonDisabled}
          onClick={continueButtonOnClick}
          buttonSize={buttonSize}
        >
          {continueButtonText}
        </CreateOfferStepPrimaryButton>

        <SlideToMoreItemsWrapper
          className={`${items.length > 1 ? 'flex' : 'hidden'} md:${items.length > numberOfItemsToSlide ? 'flex' : 'hidden'}
          w-full md:w-60 md:h-full flex-row-reverse md:flex-col items-center justify-start md:justify-center`}
        >
          <LottieWrapper
            animation={slideAnimation}
            className='-rotate-90 transform h-7 w-18'
            isStopped={animationStopped}
            loop
          />
          <AnimatePresence>
            {
              showSlideMoreOptions && (
                <motion.div
                  initial={{ opacity: 0, x: -40 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: 40 }}
                  className='flex text-center'
                >
                  <span className='text-base px-0 md:px-16'>Slide to check more items</span>
                </motion.div>
              )
            }
          </AnimatePresence>
        </SlideToMoreItemsWrapper>
      </ContinueButtonWrapper>
    </>
  )
}

export default CreateOfferStepWithList

CreateOfferStepWithList.defaultProps = {
  isCollorStep: false,
  showPrice: true,
  multiSelectItems: false,
  titleWrapperClassName: '',
  optionsWrapperClassName: '',
  buttonWrapperClassName: '',
  continueButtonClassName: ''
}

CreateOfferStepWithList.propTypes = {
  title: propTypes.string,
  items: propTypes.func,
  continueButtonText: propTypes.string,
  continueButtonOnClick: propTypes.func,
  continueButtonDisabled: propTypes.bool,
  optionSelected: propTypes.object,
  onSelectOption: propTypes.func,
  totalDollarValueCurrencyType: propTypes.string,
  isCollorStep: propTypes.bool,
  showPrice: propTypes.bool,
  multiSelectItems: propTypes.bool,
  offerId: propTypes.string,
  titleWrapperClassName: propTypes.string,
  optionsWrapperClassName: propTypes.string,
  buttonWrapperClassName: propTypes.string,
  titleClassName: propTypes.string,
  continueButtonClassName: propTypes.string,
  buttonSize: propTypes.string,
  numberOfItemsToSlide: propTypes.number
}

ColorPalleteItem.propTypes = {
  className: propTypes.string,
  color: propTypes.string
}

ColorPalette.propTypes = {
  colorPalette: propTypes.array
}
