// @ts-nocheck

import React, { useContext } from 'react'
import LargeUnitCard from 'components/LargeUnitCard'
import SelectUnit from './SelectUnit'
import { PurchaseParkingContext } from '../context/PurchaseParkingContext'
import CustomButton from 'components/CustomButton'

const Step1 = (props) => {
  const { setStep } = props

  const { selectedUnit, setSelectedUnit } = useContext(PurchaseParkingContext)

  const onSelect = (unit) => {
    if (unit.status !== 'SoldCommitted') {
      setSelectedUnit(unit)
    }
  }

  const onBack = () => {
    setSelectedUnit({})
  }

  const isSelected = Object.values(selectedUnit).length > 0

  return (
    <div>
      <div className='pb-4 border-b-2 border-softBlack mb-10'>
        <p className='my-0 text-xl font-bold'>
          {isSelected ? 'Units Details' : 'Select Unit'}
        </p>
      </div>
      {!isSelected && <SelectUnit onSelect={onSelect} />}
      {isSelected && (
        <>
          <LargeUnitCard
            imgsrc={selectedUnit?.floorPlan?.image}
            unitName={`#${selectedUnit?.unitNumber}`}
            noOfBedrooms={selectedUnit?.numberOfBedrooms}
            noOfBathrooms={selectedUnit?.numberOfBathrooms}
            balcony={selectedUnit?.balcony}
            noOfParkingSlot={selectedUnit?.floorPlan?.parkingQty}
            building={selectedUnit?.building?.name}
            price={selectedUnit?.price}
          />
          <div className='flex justify-between mt-32'>
            <CustomButton variant='outline' handleClick={onBack}>
              <span className='font-medium py-3 px-4 text-base flex gap-2 items-center'>
                Back
              </span>
            </CustomButton>
            <CustomButton handleClick={() => setStep((prev) => prev + 1)}>
              <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
                Next
              </span>
            </CustomButton>
          </div>
        </>
      )}{' '}
    </div>
  )
}

export default Step1
