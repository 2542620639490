import React, { createContext, useState } from 'react'

export const UnitInventoryMapContext = createContext({
  openFilterDrawer: () => {},
  closeFilterDrawer: () => {},
  openUnitInfoDrawer: () => {},
  closeUnitInfoDrawer: () => {},
  setSearch: () => {},
  setFilters: () => {},
  resetFilters: () => {},
  state: {
    isFilterDrawerOpen: true,
    isUnitInfoDrawerOpen: true,
    search: '',
    filters: {}
  }
})

const UnitInventoryMapContextProvider = ({ children }) => {
  const [state, setState] = useState({
    isFilterDrawerOpen: false,
    isUnitInfoDrawerOpen: false,
    search: '',
    filters: {}
  })

  const openFilterDrawer = () => {
    setState({
      ...state,
      isFilterDrawerOpen: true
    })
  }

  const closeFilterDrawer = () => {
    setState({ ...state, isFilterDrawerOpen: false })
  }

  const setFilters = (filters) => setState({ ...state, filters })
  const resetFilters = () => {
    setState({
      ...state,
      filters: {}
    })
  }

  const openUnitInfoDrawer = () => {
    setState({
      ...state,
      isUnitInfoDrawerOpen: true
    })
  }
  const closeUnitInfoDrawer = () => {
    setState({ ...state, isUnitInfoDrawerOpen: false })
  }

  const setSearch = (search) => setState({ ...state, search })

  return (
    <UnitInventoryMapContext.Provider
      value={{
        state,
        setFilters,
        resetFilters,
        openFilterDrawer,
        closeFilterDrawer,
        openUnitInfoDrawer,
        closeUnitInfoDrawer,
        setSearch
      }}
    >
      {children}
    </UnitInventoryMapContext.Provider>
  )
}

export default UnitInventoryMapContextProvider
