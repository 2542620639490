import React from 'react'
import ReactDOM from 'react-dom'
import propTypes from 'prop-types'
import { Transition } from '@headlessui/react'
import { TCancelButton } from 'components'

let fullScreenPortal = document.getElementById('fullScreenPortal')

if (!fullScreenPortal) {
  fullScreenPortal = document.createElement('div')
  fullScreenPortal.setAttribute('id', 'fullScreenPortal')
  fullScreenPortal.className = 'fixed z-1900' // 10 less compared to the modal component, which has 2000
  document.body.appendChild(fullScreenPortal)
}

const FullScreenPortal = ({
  showPortal, children, onClose
}) => {
  return ReactDOM.createPortal(
    <Transition show={showPortal} className='h-full fixed z-50 translate-y inset-0 overflow-y-auto w-full flex items-center justify-center'>
      <div className='h-full flex items-center justify-center min-h-screen text-center w-full'>
        <span className='hidden sm:inline-block sm:align-middle sm:h-screen' aria-hidden='true'>&#8203;</span>
        <Transition.Child
          enter='ease-out duration-200'
          enterFrom='opacity-0 translate-y-96'
          enterTo='opacity-100 translate-y-0'
          leave='ease-in duration-200'
          leaveFrom='opacity-100 translate-y-0'
          leaveTo='opacity-0 -translate-y-96'
          className='h-full inline-block align-bottom text-left overflow-hidden transform transition-all sm:align-middle w-full'
          role='dialog'
          id='firstDiv'
        >
          <div
            id='secondDiv'
            className='h-full inline-block align-bottom bg-white rounded-lg text-left overflow-hidden
            shadow-xl transform transition-all sm:align-middle w-full'
            role='dialog'
            aria-modal='true'
            aria-labelledby='modal-headline'
          >
            <div className='h-full bg-white overflow-auto w-full'>
              { children }
            </div>
          </div>
        </Transition.Child>

        </div>
        <div className='fixed bottom-8 left-8'>
          <TCancelButton onClick={onClose}>
            Back
          </TCancelButton>
        </div>
    </Transition>,
    fullScreenPortal
  )
}

export default FullScreenPortal

FullScreenPortal.propTypes = {
  showPortal: propTypes.bool,
  children: propTypes.node,
  onClose: propTypes.func
}
