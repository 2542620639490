import React from 'react'
import propTypes from 'prop-types'
import { classNames } from 'utils'

const GridFormWrapper = ({ children, className }) => (
  <div
    id='gridFormWrapper'
    className={classNames('grid grid-cols-2 gap-2', className)}
  >
    {children}
  </div>
)

export default GridFormWrapper

GridFormWrapper.propTypes = {
  children: propTypes.node,
  className: propTypes.string
}
