import { message } from 'antd'
import { api, ApiErrors } from 'services/api'
import { getRandomInteger } from 'utils'

export const getEvents = (id = '') =>
  new Promise((resolve, reject) => {
    api.get(`events/${id}`).then((response) => {
      if (response.ok) {
        resolve(response.data.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const createOrUpdateEvent = (props) =>
  new Promise((resolve, reject) => {
    const { _id, ...params } = props

    // If id, so we have to update the record, or we're gonna create
    const verb = _id ? 'put' : 'post'
    const url = _id ? `events/${_id}` : 'events'

    api[verb](url, params).then((response) => {
      if (response.ok) {
        message.success(
          `${props.name} was ${_id ? 'successfully updated' : 'created successfully'
          }`
        )
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const deleteEvent = (eventId) =>
  new Promise((resolve, reject) => {
    api.delete(`events/${eventId}`).then((response) => {
      if (response.ok) {
        message.success('Event deleted successfully')
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getEventsAttendees = (eventId) =>
  new Promise((resolve, reject) => {
    api
      .get(
        `events/${eventId}/attendees`
      )
      .then((response) => {
        if (response.ok) {
          resolve(response.data.data)
        } else {
          ApiErrors(response)
          reject()
        }
      })
  })

export const createAttendees = (props) =>
  new Promise((resolve, reject) => {
    const { _id, ...params } = props

    api.post(`events/${_id}/attendees`, params).then((response) => {
      if (response.ok) {
        message.success('Attendee created successfully')
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const uploadImageAPI = (selectedImage, appProject) =>
  new Promise((resolve, reject) => {
    const formData = new FormData()
    const fileName = `Event image-${getRandomInteger()}`
    formData.append('documentName', fileName)
    formData.append('file', selectedImage)
    formData.append('project', appProject)
    api.post('privatedocuments/upload', formData).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getImage = (id) =>
  new Promise((resolve, reject) => {
    api.get(`privatedocuments/${id}`).then((response) => {
      if (response.ok) {
        resolve(response.data.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const deleteImage = (id) => new Promise((resolve, reject) => {
  api.delete(`privatedocuments/${id}`)
    .then((response) => {
      if (response.ok) {
        message.success('Image was deleted successfully')
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
})
