import React from 'react'

const TrophieIcon = ({ width = 11, height = 10 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_424_1038)">
            <path d="M8.41667 2.08333H7.58333V1.25H3.41667V2.08333H2.58333C2.125 2.08333 1.75 2.45833 1.75 2.91667V3.33333C1.75 4.39583 2.55 5.2625 3.57917 5.39167C3.84167 6.01667 4.40417 6.4875 5.08333 6.625V7.91667H3.41667V8.75H7.58333V7.91667H5.91667V6.625C6.59583 6.4875 7.15833 6.01667 7.42083 5.39167C8.45 5.2625 9.25 4.39583 9.25 3.33333V2.91667C9.25 2.45833 8.875 2.08333 8.41667 2.08333ZM2.58333 3.33333V2.91667H3.41667V4.50833C2.93333 4.33333 2.58333 3.875 2.58333 3.33333ZM8.41667 3.33333C8.41667 3.875 8.06667 4.33333 7.58333 4.50833V2.91667H8.41667V3.33333Z" fill="#2E2B2E"/>
        </g>
        <defs>
        <clipPath id="clip0_424_1038">
        <rect width="10" height="10" fill="white" transform="translate(0.5)"/>
            </clipPath>
        </defs>
    </svg>
  )
}

export default TrophieIcon
