import React from 'react'
import {
  AdminPageWrapper,
  PageHeader,
  ProjectUnitsInventoryGraph
} from 'components'
import { connect } from 'react-redux'

const UnitsInventoryGraph = () => {
  // @ts-ignore
  // const projectId = useSelector((state) => state.appReducer.appProject)
  const RightContent = () => <></>

  return (
    <>
      <PageHeader title='Units Inventory Graph' rightContent={<RightContent />} />
      <AdminPageWrapper fullscreen>
        <ProjectUnitsInventoryGraph />
      </AdminPageWrapper>
    </>
  )
}

const mapStateToProps = (state) => ({
  // appProject: state.appReducer.appProject
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(UnitsInventoryGraph)

UnitsInventoryGraph.propTypes = {
  // appProject: propTypes.string
}
