import styled from 'styled-components'

export const UnitImage = styled.div`
  position: absolute;
  height: 40%;
  width: 100%;
  background-position: center;
  background-size: cover;
`

export const LogoWrapper = styled.div`
  height: 20%;
  margin-top: 30px;
  margin-left: 30px;
`
