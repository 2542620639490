import styled from 'styled-components'
import { TButton } from 'ui'

export const TitleWrapper = styled.div``
export const Title = styled.span``
export const ContinueButtonWrapper = styled.div``
export const ContinueButton = styled(TButton)``
export const ListWrapper = styled.div``
export const List = styled.div`
  display: ${props => props.smallDevice === 'true' ? 'unset' : '-webkit-box'};
  flex-direction: ${props => props.smallDevice === 'true' ? 'column' : 'row'};
  overflow-x: ${props => props.smallDevice === 'true' ? 'unset' : 'auto'};
`
export const RoundedOptionWrapper = styled.div``
export const OptionImageWrapper = styled.div``
export const OptionImage = styled.img``
export const OptionDataWrapper = styled.div``
export const OptionDescriptionWrapper = styled.div`
`

export const SlideToMoreItemsWrapper = styled.div``
