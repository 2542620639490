import { Select } from 'antd'
import React from 'react'
import { isMobile } from 'react-device-detect'

const LeadStatusSelect = ({ userType = '', leadStatuses = [], leadStatus, handleLeadStatusChange, marginTop = '', forceMobile = false }) => (
  userType === 'Buyer' && (
    <div
        style={{
          position: 'relative',
          overflow: 'visible',
          marginLeft: isMobile || forceMobile ? '' : '120px',
          marginTop
        }}
    >
        <div
        className='text-softBlack70'
        style={{
          color: 'soft-black',
          position: 'absolute',
          width: '100%',
          fontSize: '18px',
          fontWeight: '300',
          top: isMobile || forceMobile ? '-25px' : '-20px',
          left: '10px'
        }}
        >
            Lead Status
        </div>
        <Select
        showSearch={true}
        filterOption={(input, option) =>
          (option?.label ?? '')
            .toString()
            .toLowerCase()
            .includes(input.toLowerCase())
        }
        value={leadStatus}
        onChange={handleLeadStatusChange}
        className='rating-select text-sm bg-white border border-softBlack30 h-8 w-48 rounded text-softBlack placeholder-softBlack70 py-1'
        >
            {leadStatuses?.map((_item, index) => (
                <Select.Option value={_item.value} key={index} label={_item.label}>
                <div className='flex gap-2 items-center'>
                    <span>{_item.label}</span>
                </div>
                </Select.Option>
            ))}
        </Select>
    </div>
  )
)

export default LeadStatusSelect
