import { useState } from 'react'
import { getCommunicationTemplateByType } from 'store/actions/communicationTemplateActions'

const useGetCommunicationTemplatesByType = (type) => {
  const [communicationTemplates, setCommunicationTemplates] = useState([])
  const [loading, setLoading] = useState(true)

  const getCommunicationTemplates = async () => {
    setLoading(true)
    try {
      const data = await getCommunicationTemplateByType(type)
      setCommunicationTemplates(data)
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  return { loading, communicationTemplates, getCommunicationTemplates }
}

export default useGetCommunicationTemplatesByType
