// @ts-nocheck
/* eslint-disable react/jsx-props-no-multi-spaces */
import React, { useState, useEffect, useCallback, useRef, memo } from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { WidthProvider, Responsive } from 'react-grid-layout'
import CountUp from 'react-countup'
import Flag from 'react-world-flags'
import { initCardAnimator } from './cardAnimation'
import cardAnimationStyles from './index.module.scss'
import searching from 'assets/lottieJsons/searching.json'

import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  Cell,
  PieChart,
  Pie,
  LineChart,
  Line
} from 'recharts'

import {
  AdminPageWrapper,
  FadedAnimatedDiv,
  LottieWrapper,
  UserRoundedImageWithCountryFlag,
  WelcomeHeader
} from 'components'
import { getUsers } from 'store/actions/usersActions'
import { getLeadsCounter, getLeadsByCountry, getUnitsDemand, getTopBrokers, getTopFloorPlans, getRealTimeVisitors, getVisitorsInAPeriod } from 'store/actions/dashboardActions'
import { addDays, isObject, leftZero } from 'utils'
import { AnimatePresence, motion } from 'framer-motion'
import { Table, Tabs } from 'ui'
import useUpdateUsers from 'hooks/useUpdateUsers'

const dataComingVariants = {
  hidden: {
    opacity: 0,
    y: -20
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.2,
      y: { type: 'spring', stiffness: 300, damping: 30 }
    }
  },
  exit: {
    opacity: 0,
    y: -20
  }
}

const periodsData = [
  { id: 'sevenDays', value: '7 days' },
  { id: 'twoWeeks', value: 'Two Weeks' },
  { id: 'oneMonth', value: 'One Month' }
]

const selectedPeriodColors = ['#3B82F6', '#F59E0B', '#0EA5E9', '#A855F7', '#64748B']
const periodBeforeColors = ['#93C5FD', '#FDE68A', '#7DD3FC', '#D8B4FE', '#CBD5E1']

const topLeadSourceData = [
  {
    id: 0,
    sourceName: 'Google',
    leads: 876,
    averageBuyerScore: 'B.1'
  },
  {
    id: 1,
    sourceName: 'Facebook',
    leads: 657,
    averageBuyerScore: 'C.2'
  },
  {
    id: 2,
    sourceName: 'Instagram',
    leads: 576,
    averageBuyerScore: 'B.2'
  },
  {
    id: 3,
    sourceName: 'Walk In',
    leads: 312,
    averageBuyerScore: 'A.3'
  },
  {
    id: 4,
    sourceName: 'Broker',
    leads: 45,
    averageBuyerScore: 'B.3'
  }
]

const topCountriesColumns = [
  {
    Title: '',
    accessor: 'country',
    Cell: ({ row: { original: { country } } }) => {
      return (
        <div className='h-9 w-9 overflow-hidden flex rounded-full'>
          <Flag code={country} className='object-cover' />
        </div>
      )
    }
  },
  {
    Title: 'Reach',
    accessor: 'reach'
  },
  {
    Title: 'Leads',
    accessor: 'leads'
  }
]

const topBrokersColumns = [
  {
    Title: '',
    accessor: 'country',
    Cell: ({ row: { original: { country, userAvatar, name } } }) => {
      return (
        <UserRoundedImageWithCountryFlag
          userAvatarSrc={userAvatar}
          userAvatarImageSize='h-12 w-12'
          countryCode={country}
          countryFlagSize='h-7 w-7'
          countryFlagPosition={userAvatar ? '-right-2 -top-2' : '-right-0 -top-1'}
          tooltip={name}
          tooltipPlacement='top'
        />
      )
    }
  },
  {
    Title: 'Leads',
    accessor: 'leads'
  },
  {
    Title: 'Av Buyer Score',
    accessor: 'averageBuyerScore'
  }
]

const topLeadSourceColumns = [
  {
    Title: '',
    accessor: 'sourceName'
  },
  {
    Title: 'Leads',
    accessor: 'leads'
  },
  {
    Title: 'Av Buyer Score',
    accessor: 'averageBuyerScore'
  }
]

const ResponsiveReactGridLayout = WidthProvider(Responsive)

const slideBottomItem = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1
  }
}

const baseUserStats = [
  { w: 2, h: 1, x: 2, y: 0, isResizable: false, isDraggable: true, i: 'allContacts', title: 'All contacts', value: '0' },
  { w: 2, h: 1, x: 4, y: 0, isResizable: false, isDraggable: true, i: 'todayLeads', title: "Today's Leads", value: '0' },
  // { w: 2, h: 1, x: 4, y: 0, isResizable: false, /* isDraggable: true, */ i: 'todayBuyerScore', title: "Today's Buyer Score", value: 'B.2' },
  { w: 2, h: 1, x: 6, y: 0, isResizable: false, isDraggable: true, i: 'leadsByPeriod', title: 'Leads in 7 days', value: '0' },
  // { w: 2, h: 1, x: 8, y: 0, isResizable: false, /* isDraggable: true, */ i: 'averageBuyerScore', title: 'Average Buyer Score', value: 'A.1' },
  { w: 2, h: 1, x: 8, y: 0, isResizable: false, isDraggable: true, i: 'realTimeVisitors', title: 'Real-Time Visitors', value: '46' },
  { w: 6, h: 2, x: 0, y: 3, i: 'unitInterest', isDraggable: true, title: 'Unit Interest' },
  { w: 6, h: 2, x: 6, y: 3, i: 'topCountries', isDraggable: true, title: 'Top Countries' },

  // { w: 4, h: 3, x: 0, y: 3, i: 'topBrokers', title: 'Top Brokers' },
  // { w: 4, h: 3, x: 4, y: 3, i: 'buyerScore', title: 'Buyer Score' },
  { w: 6, h: 2, x: 0, y: 5, i: 'topLeadSource', isDraggable: true, title: 'Top Lead Source' },

  // { w: 8, h: 3, x: 0, y: 6, i: 'topFloorPlansDemand', title: 'Top Floor Plans Demand' },
  { w: 6, h: 2, x: 6, y: 5, i: 'contactRatings', isDraggable: true, title: 'Contact Ratings' },
  { w: 12, h: 2, x: 0, y: 1, i: 'visitorsInAPeriod', isDraggable: true, title: 'Visitors In A Period' }
]

const userScoreStat = [
  { name: 'A.1', value: 37 },
  { name: 'A.2', value: 61 },
  { name: 'B.1', value: 121 },
  { name: 'B.2', value: 130 },
  { name: 'B.3', value: 142 },
  { name: 'C.1', value: 189 },
  { name: 'C.3', value: 190 }
]

const dashboardTabs = [
  { key: 'demandDashboard', title: 'Demand Dashboard' },
  { key: 'salesDashboard', title: 'Sales Dashboard' }
]

const COLORS = ['#65A30D', '#059669', '#0891B2', '#2563EB', '#7C3AED', '#C026D3', '#EA580C', '#65A30D', '#059669', '#0891B2', '#2563EB', '#7C3AED', '#C026D3', '#EA580C']

const RADIAN = Math.PI / 180

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, name }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <text className='text-lg font-bold' x={x < cx ? x + 20 : x} y={y} fill='white' textAnchor={x > cx ? 'start' : 'end'} dominantBaseline='central'>
      {`${name} - ${(percent * 100).toFixed(0)}%`}
    </text>
  )
}

const itemsWrapper = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.1
    }
  }
}

const StatWrapper = ({ title, children, index }) => (
  <motion.li
    className='shadow-md rounded-md w-full h-full overflow-hidden bg-white'
    key={index}
    variants={slideBottomItem}
  >
    {title && <div className='p-3 h-2/20 bg-transparent'><span className='text-lg'>{title}</span></div>}
    <div className={`px-3 overflow-auto ${title ? 'h-18/20' : 'flex items-center justify-center w-full h-full'}`}>
      {children}
    </div>
  </motion.li>
)

const StatsWrapper = ({ children }) => (
  <motion.ul
    className='w-full'
    variants={itemsWrapper}
    initial='hidden'
    animate='visible'
  >
    {children}
  </motion.ul>
)

const FloorPlanStat = ({ title, value }) => (
  <div className='flex flex-col items-center justify-center'>
    <span className='text-2xl'>{value}</span>
    <span className='text-base'>{title}</span>
  </div>
)

const FloorPlan = ({ floorPlanData: { name, image, type, averageBuyerScore, leads }, index }) => (
  <div
    className={`${cardAnimationStyles.stackCardsItem} rounded-lg w-64 shadow-lg flex flex-col flex-none h-full js-stack-cards__item`}
    style={{
      backgroundImage: `linear-gradient(to bottom, ${COLORS[index]} 50%, #17223b 50%)`
    }}
  >
    <div className='h-14/20 m-3 flex items-center justify-center'>
      <img src={image} className='w-full h-full object-contain rounded-md' alt='Floor Plan' />
    </div>
    <div className='h-6/20 flex flex-col items-center justify-center text-white'>
      <span className='text-2xl'>{name}</span>
      <div className='flex flex-row items-center justify-around w-full px-3'>
        <FloorPlanStat title='Type' value={type} />
        <FloorPlanStat title='Av Buyer Score' value={averageBuyerScore} />
        <FloorPlanStat title='Leads' value={leads} />
      </div>
    </div>
  </div>
)

const CustomCountdown = memo(({ onMouseEnter, onMouseLeave, loadingDivWidth, showDataComingCounter, counter }) => (
  <div
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    className='w-full h-1.5 hover:h-9 relative transition-all flex justify-end bg-gradient-to-r from-indigo-500 to-green-500'
  >
    <div
      className={`h-full transition-all ${loadingDivWidth === '100%' ? 'duration-200' : 'duration-60000'}
      ease-linear ${cardAnimationStyles.invertedBorderRadius}`}
      style={{ width: loadingDivWidth, background: '#F9FAFB' }}
    />
    <AnimatePresence>
      {
        showDataComingCounter && (
          <motion.div
            key='dataComingCounter'
            className='w-full items-center justify-center flex absolute h-full'
            variants={dataComingVariants}
            initial='hidden'
            animate='show'
            exit='exit'
          >
            <span
              style={{ background: 'rgba(0, 0, 0, 0.3)' }}
              className='text-base text-white px-2 rounded-md'
            >
              {counter <= 0 ? 'Getting your updated numbers...' : `Updated numbers arriving in ${counter} seconds`}
            </span>

          </motion.div>
        )
      }
    </AnimatePresence>
  </div>
))

const MemoWelcomeHeader = memo(({
  firstName, lastName, userType, developerCompany, email,
  selectedPeriod, userAvatar, onChangePeriod
}) => (
  <WelcomeHeader
    name={`${firstName} ${lastName}`}
    userType={userType}
    developerCompany={developerCompany}
    email={email}
    selectedPeriod={selectedPeriod}
    onChangePeriod={(selectedPeriod) => onChangePeriod(selectedPeriod)}
    periodsData={periodsData}
    image={userAvatar}
  />
))

// const useOnScreen = (ref) => {
//   const [isIntersecting, setIntersecting] = useState(false)

//   useEffect(() => {
//     const observer = new IntersectionObserver(([entry]) => {
//       setIntersecting(entry.isIntersecting)
//     })

//     observer.observe(ref.current)
//     return (): void => {
//       observer.disconnect()
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [])

//   return isIntersecting
// }

const VisitorsInAPeriod = ({ index, data }) => (
  <StatWrapper title='Visitors In A Period' index={index}>
    <ResponsiveContainer>
      <LineChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 0,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='date' />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type='monotone' dataKey='visitors' stroke='#8884d8' activeDot={{ r: 8 }} />
      </LineChart>
    </ResponsiveContainer>
  </StatWrapper>
)

const SimpleStat = memo(({ title, index, value }) => (
  <StatWrapper index={index}>
    <div className='flex flex-col items-center justify-center text-center'>
      {
        Number.parseInt(value)
          ? <CountUp
            start={0}
            end={value}
            duration={1.5}
            className='text-2xl'
            delay={index * 0.3}
            />
          : <span className='text-2xl'>{value}</span>
      }
      <span className='text-base'>{title}</span>
    </div>
  </StatWrapper>
))

const TopCountriesStat = memo(({ index, value }) => (
  <StatWrapper title='Top Countries' index={index}>
    <Table
      tailwindTable
      dataSource={value}
      columns={topCountriesColumns}
      showTablePagination={false}
    />
  </StatWrapper>
))

const UnitInterestStat = memo(({ index, value }) => (
  <StatWrapper title='Unit Interest' index={index}>
    {console.log(value)}
    <ResponsiveContainer width='100%' height='100%'>
      <BarChart
        // width={500}
        // height={300}
        data={value}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='name' />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar animationBegin={index * 100} legendType='none' name='Strong Color - Selected Period' dataKey='selectedPeriod' stackId='a' fill='#111827'>
          {
            value.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={selectedPeriodColors[index]} />
            ))
          }
        </Bar>
        <Bar animationBegin={index * 100} legendType='none' name='Light Color - Same Period Before' dataKey='periodBefore' stackId='a' fill='#9CA3AF'>
          {
            value.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={periodBeforeColors[index]} />
            ))
          }
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  </StatWrapper>
))

const BuyerScoreStat = memo(({ index }) => (
  <StatWrapper title='Buyer Score' index={index}>
    <ResponsiveContainer>
      <PieChart>
        <Pie
          data={userScoreStat}
          labelLine={false}
          label={renderCustomizedLabel}
          fill='#8884d8'
          dataKey='value'
          animationBegin={index * 150}
        >
          {userScoreStat.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  </StatWrapper>
))

const TopBrokersStat = memo(({ index, value }) => (
  <StatWrapper title='Top Brokers' index={index}>
    <Table
      tailwindTable
      dataSource={value}
      columns={topBrokersColumns}
      showTablePagination={false}
    />
  </StatWrapper>
))

const TopLeadSource = memo(({ index }) => (
  <StatWrapper title='Top Lead Source' index={index}>
    <Table
      tailwindTable
      dataSource={topLeadSourceData}
      columns={topLeadSourceColumns}
      showTablePagination={false}
    />
  </StatWrapper>
))

const FloorPlanDemandData = memo(({ index, value }) => (
  <StatWrapper title='Top Floor Plans Demand' index={index}>
    <div
      className={`${cardAnimationStyles.stackCards} flex flex-row h-full overflow-x-auto js-stack-cards`}
    >
      {
        value.map((floorPlan, index) => <FloorPlan floorPlanData={floorPlan} index={index} />)
      }
    </div>
  </StatWrapper>
))

// const renderVisitorsInAPeriod = (index, data) => (
//   <StatWrapper title='Visitors In A Period' index={index}>
//     <ResponsiveContainer width="100%" height="100%">
//       <LineChart
//         // width={500}
//         // height={300}
//         data={data}
//         margin={{
//           top: 20,
//           right: 30,
//           left: 0,
//           bottom: 5
//         }}
//       >
//         <CartesianGrid strokeDasharray="3 3" />
//         <XAxis dataKey="date" />
//         <YAxis />
//         <Tooltip />
//         <Legend />
//         <Line animationBegin={10000} type="monotone" dataKey="visitors" stroke="#8884d8" activeDot={{ r: 8 }} />
//       </LineChart>
//     </ResponsiveContainer>
//   </StatWrapper>
// )

const ContactRatingsStat = memo(({ index, value }) => (
  <StatWrapper title='Contact Ratings' index={index}>
    <ResponsiveContainer width='100%' height='100%'>
      <BarChart
        // width={500}
        // height={300}
        data={value}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='name' />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar
          animationBegin={index * 100}
          legendType='none'
          name='Count'
          dataKey='count'
          stackId='a'
          fill='#111827'
        />
      </BarChart>
    </ResponsiveContainer>
  </StatWrapper>
))

const Stat = memo(({ statType, title, index, value }) => {
  if (
    statType === 'allContacts' ||
    statType === 'todayLeads' ||
    statType === 'todayBuyerScore' ||
    statType === 'leadsByPeriod' ||
    statType === 'averageBuyerScore' ||
    statType === 'realTimeVisitors'
  ) {
    return <SimpleStat title={title} index={index} value={value} />
  }

  if (statType === 'topCountries') {
    return <TopCountriesStat index={index} value={value} />
  }

  if (statType === 'unitInterest') {
    return <UnitInterestStat index={index} value={value} />
  }

  if (statType === 'buyerScore') {
    return <BuyerScoreStat index={index} />
  }

  if (statType === 'topBrokers') {
    return <TopBrokersStat index={index} value={value} />
  }

  if (statType === 'topLeadSource') {
    return <TopLeadSource index={index} />
  }

  if (statType === 'topFloorPlansDemand') {
    return <FloorPlanDemandData index={index} value={value} />
  }

  if (statType === 'visitorsInAPeriod') {
    return <VisitorsInAPeriod index={index} data={value} />
  }

  if (statType === 'contactRatings') {
    return <ContactRatingsStat index={index} value={value} />
  }
})

const StatsAndCharts = memo(({ onLayoutChange, layouts, userStats }) => {
  const stackcards = useRef()

  useEffect(() => {
    if (userStats.length) {
      initCardAnimator(stackcards)
    }
  }, [userStats])

  const children = React.useMemo(() => {
    return userStats.map((chart, index) => {
      const { i, title, value } = chart

      return (
        <div key={i} data-grid={chart} name={i}>
          <Stat
            statType={i}
            title={title}
            index={index}
            value={value}
          />
        </div>
      )
    })
  }, [userStats])

  return (
    <StatsWrapper>
      <ResponsiveReactGridLayout
        // breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 10, sm: 4, xs: 2, xxs: 2 }}
        onLayoutChange={(layout, layouts) =>
          onLayoutChange(layout, layouts)}
        className='layout'

        layouts={layouts}
        // onLayoutChange={(layout, layouts) => {
        //   console.log('layout', layout)
        //   console.log('layouts', layout)
        //   console.log('JSON strigfy', JSON.stringify({
        //     layouts: layouts
        //   }))
        // }}

        // rowHeight={30}
      >
        {children}
        {/* <div key="1" data-grid={{ w: 2, h: 3, x: 0, y: 0, minW: 2, minH: 3 }}>
          <span className="text">1</span>
        </div>
        <div key="2" data-grid={{ w: 2, h: 3, x: 2, y: 0, minW: 2, minH: 3 }}>
          <span className="text">2</span>
        </div>
        <div key="3" data-grid={{ w: 2, h: 3, x: 4, y: 0, minW: 2, minH: 3 }}>
          <span className="text">3</span>
        </div>
        <div key="4" data-grid={{ w: 2, h: 3, x: 6, y: 0, minW: 2, minH: 3 }}>
          <span className="text">4</span>
        </div>
        <div key="5" data-grid={{ w: 2, h: 3, x: 8, y: 0, minW: 2, minH: 3 }}>
          <span className="text">5</span>
        </div> */}
      </ResponsiveReactGridLayout>
    </StatsWrapper>
  )
})

const Dashboard = (props) => {
  const { createOrUpdateUser } = useUpdateUsers()
  const [selectedPeriod, setSelectedPeriod] = useState('sevenDays')
  const [userStats, setUserStats] = useState([])
  const [selectedTab, setSelectedTab] = useState('demandDashboard')
  const [queryDates, setQueryDates] = useState([])
  const [loadingDivWidth, setLoadingDivWidth] = useState('100%')
  const [showDataComingCounter, setShowDataComingCounter] = useState(false)
  const [counter] = useState(60)
  const [sixtySecondsInTheFuture, setSixtySecondsInTheFuture] = useState('')
  const [statsSequence, setStatsSequence] = useState({})
  const [lastTimeDashboardUpdated, setLastTimeDashboardUpdated] = useState('')
  const [changedStatsSequence, setChangedStatsSequence] = useState(false)
  const [layouts, setLayouts] = useState({})

  const refStatsSequence = useRef(statsSequence)

  const updateStatsSequence = (newStats) => {
    refStatsSequence.current = newStats
    setStatsSequence(newStats)
  }

  const {
    userObject: {
      _id: loggedUserId,
      firstName,
      lastName,
      userType,
      developerCompany,
      email,
      userAvatar,
      statsSequence: loggedUserStatsSequence
    },
    appProject
  } = props

  useEffect(() => {
    // if (loggedUserStatsSequence !== refStatsSequence.current) {
    updateStatsSequence(loggedUserStatsSequence)
    // }
  }, [loggedUserStatsSequence])

  const getDashboardNumbers = useCallback(async () => {
    if (queryDates.length) {
      setUserStats([])

      setTimeout(() => {
        setLoadingDivWidth('100%')
      }, 530)

      const startDate = new Date(queryDates[0]).toLocaleDateString('fr-CA') // Just to convert to YYYY-MM-DD
      const endDate = new Date(queryDates[1]).toLocaleDateString('fr-CA') // Just to convert to YYYY-MM-DD

      const today = new Date()

      const arraySource = baseUserStats.map((stat) =>
        Object.assign(
          {},
          stat,
          refStatsSequence.current && isObject(refStatsSequence.current) && Object.keys(refStatsSequence.current).length
            ? refStatsSequence.current[Object.keys(refStatsSequence.current)[0]].filter((userStat) => userStat.i === stat.i)[0]
            : {}
        ))

      const allContacts = await getUsers('', '?userType=Buyer&userType=Lead&userType=LeadBroker')
      const todayLeads = await getLeadsCounter(today, today, appProject).catch(() => {})
      const leadsByPeriod = await getLeadsCounter(startDate, endDate, appProject).catch(() => {})
      const leadsByCountry = await getLeadsByCountry(startDate, endDate, appProject).catch(() => {})
      const unitsDemand = await getUnitsDemand(startDate, endDate, appProject).catch(() => {})
      const topBrokers = await getTopBrokers(startDate, endDate, appProject).catch(() => {})
      const topFloorPlans = await getTopFloorPlans(startDate, endDate, appProject).catch(() => {})
      const realTimeVisitors = await getRealTimeVisitors(appProject).catch(() => {})
      let visitorsInAPeriod = await getVisitorsInAPeriod(startDate, endDate, appProject).catch(() => {})
      const contactRatings = allContacts.map((contact) => contact.buyerData ? contact.buyerData.rating : 'Not a contact')
      const ratingsCounter = []

      contactRatings.forEach((rating) => {
        if (!ratingsCounter.filter(filterRating => filterRating.name === rating).length) {
          ratingsCounter.push({ name: rating, count: contactRatings.filter(filterRating => filterRating === rating).length })
        }
      })

      if (visitorsInAPeriod && visitorsInAPeriod.length) {
        visitorsInAPeriod = visitorsInAPeriod.map((record) => ({
          date: `${leftZero(new Date(record.date).getDate())}/${leftZero(new Date(record.date).getMonth() + 1)}`,
          visitors: record.visitors
        }))
      }

      const tmpStats = arraySource.map((stat) => {
        const tmpStat = { ...stat }
        const filteredStat = baseUserStats.filter((baseState) => stat.i === baseState.i)

        tmpStat.title = (filteredStat && filteredStat.length && filteredStat[0].title) || 'Stat name'

        if (stat.i === 'allContacts') {
        // tmpStat.isResizable = false
          tmpStat.isDraggable = true
          tmpStat.value = allContacts.length
        }

        if (stat.i === 'todayLeads') {
          tmpStat.value = todayLeads
          // tmpStat.isResizable = false
          tmpStat.isDraggable = true
        }

        if (stat.i === 'todayBuyerScore') {
        // tmpStat.isResizable = false
          tmpStat.isDraggable = true
        }

        if (stat.i === 'averageBuyerScore') {
        // tmpStat.isResizable = false
          tmpStat.isDraggable = true
        }

        if (stat.i === 'leadsByPeriod') {
          tmpStat.value = leadsByPeriod

          const tmpSelectedPeriod = periodsData.filter((period) => period.id === selectedPeriod)

          tmpStat.title = `Leads in ${tmpSelectedPeriod.length && tmpSelectedPeriod[0].value}`
          // tmpStat.isResizable = false
          tmpStat.isDraggable = true
        }

        if (stat.i === 'realTimeVisitors') {
          tmpStat.value = realTimeVisitors
          // tmpStat.isResizable = false
          tmpStat.isDraggable = true
        }

        if (stat.i === 'topCountries') {
          tmpStat.value = leadsByCountry
          tmpStat.isDraggable = true
        }

        if (stat.i === 'unitInterest') {
          tmpStat.value = unitsDemand
          tmpStat.isDraggable = true
        }

        if (stat.i === 'topBrokers') {
          tmpStat.value = topBrokers
          tmpStat.isDraggable = true
        }

        if (stat.i === 'topFloorPlansDemand') {
          tmpStat.value = topFloorPlans
          tmpStat.isDraggable = true
        }

        if (stat.i === 'visitorsInAPeriod') {
          tmpStat.value = visitorsInAPeriod
          tmpStat.isDraggable = true
          tmpStat.isDraggable = true
        }

        if (stat.i === 'contactRatings') {
          tmpStat.value = ratingsCounter
          tmpStat.isDraggable = true
        }

        if (stat.i === 'topLeadSource') {
          tmpStat.isDraggable = true
        }

        if (tmpStat.value === undefined) {
          tmpStat.value = (filteredStat && filteredStat.length && filteredStat[0].value) || 'not found'
        }

        return tmpStat
      })

      setUserStats(tmpStats)
      setLoadingDivWidth('0%')

      setTimeout(() => {
        setSixtySecondsInTheFuture(new Date(Date.now() + 60000))

        // Stop the update user timer if has started by the stats sequence update
        setChangedStatsSequence(false)
      }, 675)
    }
  }, [queryDates, selectedPeriod, appProject])

  useEffect(() => {
    // if (sixtySecondsInTheFuture) {
    //   const timer = setInterval(() => {
    //     const diffInSeconds = (sixtySecondsInTheFuture - Date.now()) / 1000
    //     const roundedSeconds = Math.floor(diffInSeconds)

    //     if (roundedSeconds === 0) {
    //       clearInterval(timer)
    //       getDashboardNumbers()
    //     }

    //     setCounter(roundedSeconds)
    //   }, 1000)

    //   return () => clearInterval(timer)
    // }
  }, [getDashboardNumbers, sixtySecondsInTheFuture])

  // useEffect(() => {
  //   return () => updateUserStatsSequenceObject()
  // }, [])

  useEffect(() => {
    let startDate = ''
    const today = new Date()

    if (selectedPeriod === 'sevenDays') {
      startDate = addDays(today, -7)
    }

    if (selectedPeriod === 'twoWeeks') {
      startDate = addDays(today, -14)
    }

    if (selectedPeriod === 'oneMonth') {
      startDate = addDays(today, -30)
    }

    setQueryDates([startDate, today])
  }, [selectedPeriod])

  useEffect(() => {
    if (appProject) {
      getDashboardNumbers(true)
    }
  }, [getDashboardNumbers, appProject])

  const updateUserStatsSequence = useCallback(() => {
    const params = {
      id: loggedUserId,
      statsSequence
    }

    if (loggedUserId) {
      createOrUpdateUser(params, false, true)
    }
  }, [loggedUserId, statsSequence])

  // useEffect(() => {
  //   // If is leaving the page but still has the updateUser stats sequence is pendent, then update the user
  //   return () => updateUserStatsSequence()
  // }, [updateUserStatsSequence])

  useEffect(() => {
    const timer = setInterval(() => {
      if (!changedStatsSequence) clearInterval(timer)

      const diffInSeconds = (Date.now() - lastTimeDashboardUpdated) / 1000
      const roundedSeconds = Math.floor(diffInSeconds)

      if (roundedSeconds === 5) {
        setChangedStatsSequence(false)

        updateUserStatsSequence()
      }
    }, 1000)

    return () => clearInterval(timer)
  }, [lastTimeDashboardUpdated, changedStatsSequence, updateUserStatsSequence])

  const onLayoutChange = useCallback((layout, layouts) => {
    setLastTimeDashboardUpdated(new Date())
    setChangedStatsSequence(true)

    updateStatsSequence(layouts)
    setLayouts(layouts)

    // startupdateUserStatsSequenceCounter()
    // const params = {
    //   id: loggedUserId,
    //   statsSequence
    // }

    // if (loggedUserId) {
    //   createOrUpdateUser(params, false, true)
    // }
  }, [])

  const LoadingStats = () => (
    <div className='flex flex-col items-center justify-center'>
      <LottieWrapper
        animation={searching}
        loop
        autoplay
        className='h-52 w-52'
      />
      <span className='mt-10 text-2xl'>Loading your customized dashboard...</span>
    </div>
  )

  const renderDashboard = useCallback(() => {
    const { length } = userStats

    return (
      <AnimatePresence>
        {
          !length
            ? <FadedAnimatedDiv key='loadingStats'><LoadingStats /></FadedAnimatedDiv>
            : <FadedAnimatedDiv key='statsAndCharts'><StatsAndCharts onLayoutChange={onLayoutChange} layouts={layouts} userStats={userStats} /></FadedAnimatedDiv>
        }
      </AnimatePresence>
    )
  }, [userStats, layouts, onLayoutChange])

  const showCounter = useCallback(() => {
    setShowDataComingCounter(true)
  }, [])

  const hideCounter = useCallback(() => {
    setShowDataComingCounter(false)
  }, [])

  const onChangePeriod = useCallback((selectedPeriod) => {
    setSelectedPeriod(selectedPeriod)
  }, [])

  return (
    <>
      <MemoWelcomeHeader
        firstName={firstName}
        lastName={lastName}
        userType={userType}
        developerCompany={developerCompany}
        email={email}
        selectedPeriod={selectedPeriod}
        userAvatar={userAvatar}
        onChangePeriod={onChangePeriod}
      />

      <CustomCountdown
        loadingDivWidth={loadingDivWidth}
        counter={counter}
        showDataComingCounter={showDataComingCounter}
        onMouseEnter={showCounter}
        onMouseLeave={hideCounter}
      />

      <AdminPageWrapper style={{ background: '#F9FAFB' }} className='overflow-y-auto'>
        <div className='flex flex-col items-center justify-center text-2xl mb-3 sticky top-0 z-5' style={{ background: '#F9FAFB' }}>
          <Tabs
            menus={dashboardTabs}
            tabSelected={selectedTab}
            onClick={(tab) => setSelectedTab(tab)}
            showBottomBorder
          />
        </div>

        {renderDashboard()}
      </AdminPageWrapper>
    </>
  )
}

const mapStateToProps = (state) => ({
  userObject: state.authReducer.userObject,
  appProject: state.appReducer.appProject
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)

Dashboard.propTypes = {
  userObject: propTypes.shape({
    _id: propTypes.string,
    firstName: propTypes.string,
    lastName: propTypes.string,
    userType: propTypes.string,
    developerCompany: propTypes.string,
    email: propTypes.string,
    userAvatar: propTypes.string,
    statsSequence: propTypes.object
  }),
  // className: propTypes.string,
  appProject: propTypes.string
}

StatWrapper.propTypes = {
  title: propTypes.string,
  children: propTypes.node,
  index: propTypes.number
}

StatsWrapper.propTypes = {
  children: propTypes.node
}

FloorPlan.propTypes = {
  name: propTypes.string,
  image: propTypes.string
}

FloorPlanStat.propTypes = {
  title: propTypes.string,
  value: propTypes.string
}

FloorPlan.propTypes = {
  floorPlanData: propTypes.shape({
    name: propTypes.string,
    image: propTypes.string,
    type: propTypes.string,
    averageBuyerScore: propTypes.string,
    leads: propTypes.string
  }),
  index: propTypes.string
}

MemoWelcomeHeader.propTypes = {
  firstName: propTypes.string,
  lastName: propTypes.string,
  userType: propTypes.string,
  developerCompany: propTypes.string,
  email: propTypes.string,
  selectedPeriod: propTypes.string,
  userAvatar: propTypes.string,
  onChangePeriod: propTypes.func
}

CustomCountdown.propTypes = {
  onMouseEnter: propTypes.func,
  onMouseLeave: propTypes.func,
  loadingDivWidth: propTypes.string,
  showDataComingCounter: propTypes.bool,
  counter: propTypes.nymber
}

VisitorsInAPeriod.propTypes = {
  index: propTypes.number,
  data: propTypes.array
}
