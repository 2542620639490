import React from 'react'
import { ReactComponent as ExternalLink } from 'assets/icons/external.svg'
import { formatMoney } from 'utils/formatters'

const Row = ({ id, data, showIndex }) => {
  return (
    <tr className="group" key={data.link}>
      {showIndex && (
        <td className='text-softBlack text-base font-medium font-openSans py-3'>
          {id + 1}
        </td>
      )}
      <td className='text-softBlack text-base font-medium font-openSans py-3'>{data.unit}</td>
      <td className='text-softBlack text-base font-medium font-openSans py-3'>{data.building}</td>
      <td className='text-softBlack text-base font-medium font-openSans py-3'>{formatMoney(data.amount)}</td>
      <td className='text-softBlack text-base font-medium font-openSans py-3'>
        <div className="flex gap-4 items-center">
          <img
            className="w-10 h-10 rounded-full object-cover"
            src={data.broker.image}
            alt="broker"
          />
          <h3 className='font-medium font-openSans'>{data.broker.name}</h3>
        </div>
      </td>
      <td className='text-softBlack text-base font-medium font-openSans py-3 opacity-0 group-hover:opacity-100'>
        <a
          target="_blank"
          href={data.link}
          className="flex justify-end p-0 items-center"
          rel="noreferrer"
        >
          <ExternalLink />
        </a>
      </td>
    </tr>
  )
}

export default Row
