import React from 'react'

const DeleteButton = ({ onClickDelete, marginTop = '' }) => (
    <div className='flex space-x-5 text-xs font-semibold' style={{ marginTop }}>
        <button
            onClick={onClickDelete}
            className='hover:bg-gray-100 leading-none h-9 px-5 border shadow border-black rounded flex space-x-2 items-center'
        >
            <svg
            width='12'
            height='16'
            viewBox='0 0 14 18'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            >
            <path
                d='M1.50016 15.4167C1.50016 16.425 2.32516 17.25 3.3335 17.25H10.6668C11.6752 17.25 12.5002 16.425 12.5002 15.4167V4.41667H1.50016V15.4167ZM13.4168 1.66667H10.2085L9.29183 0.75H4.7085L3.79183 1.66667H0.583496V3.5H13.4168V1.66667Z'
                fill='#2E2B2E'
            />
            </svg>
            <span>Delete</span>
        </button>
    </div>
)

export default DeleteButton
