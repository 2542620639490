/* eslint-disable */
import React, { useEffect, useState } from "react";
import { ReactComponent as ActivityIcon } from '../../assets/icons/icn_ballot.svg';
import { Download, Loader2 } from "lucide-react";
import useContactContext from "pages/newContacts/hooks/useContactContext";
import { getUserListsByContactId, removeContactFromList } from "store/actions/usersActions";
import Skeleton from "react-loading-skeleton";
import CustomButton from "components/CustomButton";

export const ListsTab = ({ contact }) => {
  const { state, toggleCheckContact } = useContactContext()
  const [lists, setLists] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [removingFromList, setRemovingFromList] = useState({})

  useEffect(() => {
    if (!state.isAddToListDrawerOpen) {
      setIsLoading(true)
      getUserListsByContactId(contact?._id).then((res) => {
        setLists(res.data)
        setIsLoading(false)
      })
    }
  }, [state])

  return (
    <>
      {isLoading ? (
        <div className="flex flex-col gap-6 px-4 pt-6">
          <Skeleton height={100} />
        </div>
      ) : (
        <>
          {lists.length > 0 ? (
            <>
              <div className="w-full flex justify-between items-center p-3 md:p-6 pb-0 md:pb-0">
                <h3 className="m-0 text-xl font-semibold">Lists</h3>
                <div className="text-center flex gap-x-4">
                  <CustomButton handleClick={() => toggleCheckContact(contact?._id, true)}>
                    <span className='font-medium placeholder py-2 px-4 text-base flex gap-2 items-center'>
                      <Download className='w-5 h-6' />
                      Add to Lists
                    </span>
                  </CustomButton>
                </div>
              </div>
              <div className='flex-1 p-3 md:p-6'>
                {
                  lists.map((data) => {
                    const { _id, title } = data
                    const systemList = data?.systemList
                    const canRemove = !systemList
                    return (
                      <div key={_id} className='flex items-center justify-between gap-2 hover:bg-gray-200 px-2'>
                        <div className='flex items-center gap-2'>
                          <ActivityIcon />
                          <h5 className='text-lg m-0 py-2'>{title}</h5>
                        </div>
                        {removingFromList[_id] && (
                          <div className=' text-red-300 flex items-center justify-center gap-x-1'>
                            <div className='animate-spin'>
                              <Loader2 size={20} />
                            </div>
                            Deleting
                          </div>
                        )}
                        {canRemove && !removingFromList[_id] && (
                          <button className='text-red-500' onClick={() => {
                            if (removingFromList[_id]) return
                            if (!confirm('Are you sure you want to remove this contact from this list?'))
                              return
                            setRemovingFromList({
                              ...removingFromList,
                              [_id]: true
                            })
                            removeContactFromList(
                              _id,
                              contact?._id,
                            ).then(() => {
                              getUserListsByContactId(contact?._id).then((res) => {
                                setLists(res.data)
                                setRemovingFromList({
                                  ...removingFromList,
                                  [_id]: false
                                })
                              })
                            }
                            )
                          }}>Delete</button>
                        )}
                      </div>
                    )
                  })
                }
              </div>
            </>
          ) : (
            <div className="font-openSans flex flex-col justify-center items-center h-full w-full bg-white rounded">
              <ActivityIcon className="h-10 w-10" />
              <div className="font-semibold mb-2 mt-4">
                This contact is not part of any list
              </div>
              <div className="text-center pt-4 flex gap-x-4">
                <CustomButton handleClick={() => toggleCheckContact(contact?._id, true)}>
                  <span className='font-medium placeholder py-2 px-4 text-base flex gap-2 items-center'>
                    <Download className='w-5 h-6' />
                    Add to Lists
                  </span>
                </CustomButton>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
