import React from 'react'
import { Switch as AntdSwitch } from 'antd'
import propTypes from 'prop-types'

import standardStyle from 'assets/css/standardStyle'
import { TCheckIcon, TCloseIcon } from 'components'

export const Switch = (props) => {
  const { checked } = props
  return (
    <AntdSwitch
      {...props}
      style={{ background: checked && standardStyle.colors.primary, ...props.style }}
    />)
}

const IconWrapper = ({ children, className }) => (
  <span
    className={`${className} absolute inset-0 h-full w-full flex items-center justify-center transition-opacity`}
    aria-hidden='true'
  >
    {children}
  </span>
)

export const TSwitch = (props) => {
  const { checked, unCheckedIcon, checkedIcon, onClick, title, titleColor } = props

  return (
    <div className='flex items-center'>
      <span className='mr-3' id='toggleLabel'>
        <span className={`text-sm font-medium ${titleColor}`}>{title}</span>
      </span>
      <button
        onClick={onClick}
        type='button'
        aria-pressed='false'
        className={`${checked ? 'bg-green-600' : 'bg-gray-200'} relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full 
      cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none
      `}
      >
        <span className='sr-only'>{title}</span>
        <span className={
          `${checked ? 'translate-x-5' : 'translate-x-0'} translate-x-0 relative inline-block h-5 w-5 rounded-full 
          bg-white shadow transform ring-0 transition ease-in-out duration-200`
        }
        >
          <IconWrapper
            className={checked ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200'}
            aria-hidden='true'
          >
            {unCheckedIcon()}
          </IconWrapper>
          <IconWrapper
            className={checked ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100'}
            aria-hidden='true'
          >
            {checkedIcon()}
          </IconWrapper>
        </span>
      </button>

    </div>
  )
}

Switch.propTypes = {
  checked: propTypes.bool,
  onChange: propTypes.func,
  style: propTypes.object
}

TSwitch.defaultProps = {
  unCheckedIcon: () => <TCloseIcon className='h-3 w-3 text-gray-400' />,
  checkedIcon: () => <TCheckIcon className='h-3 w-3 text-green-600' />
}

TSwitch.propTypes = {
  checked: propTypes.bool,
  unCheckedIcon: propTypes.node,
  checkedIcon: propTypes.node,
  onClick: propTypes.func,
  title: propTypes.string,
  titleColor: propTypes.string
}

IconWrapper.propTypes = {
  children: propTypes.node,
  className: propTypes.string
}
