import styled from 'styled-components'
import { FiLogOut } from 'react-icons/fi'

export const LogoutIcon = styled(FiLogOut)`
  font-size: 23px;
  color: white;
  
  :hover {
    cursor: pointer;
  }
`
