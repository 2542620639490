import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import {
  uploadFile as uploadFileAPI,
  getAllFiles as getAllFilesAPI,
  deleteFile as deleteFileAPI
} from 'store/actions/fileActions'
import { toast } from 'react-hot-toast'
import useFileTags from './useFileTags'

const useFileManager = () => {
  // @ts-ignore
  const projectId = useSelector((state) => state.appReducer.appProject)
  const client = useQueryClient()
  const { data, isLoading, refetch } = useQuery(
    ['project-files', projectId],
    ({ queryKey }) => getAllFilesAPI(queryKey[1]),
    {
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!projectId
    }
  )
  const { formatteedFileTags: fileTags } = useFileTags()
  const onSuccess = useCallback(
    (newCreated) => {
      client.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === 'project-files' &&
          query.queryKey[1] === projectId
      })
      toast.success(`Successfully ${newCreated ? 'created' : 'deleted'} file`)
    },
    [client, projectId, data]
  )

  const deleteFile = useCallback(
    (documentId) => {
      return new Promise((resolve, reject) => {
        deleteFileAPI(documentId)
          .then(() => {
            onSuccess?.(false)
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },
    [onSuccess]
  )

  const uploadFile = useCallback(
    ({ projectId, docName, file, tag, userId }) => {
      return new Promise((resolve, reject) => {
        uploadFileAPI({ projectId, docName, file, tag, userId })
          .then(() => {
            onSuccess?.(true)
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },
    [onSuccess]
  )

  return {
    files: data,
    loading: isLoading,
    deleteFile,
    uploadFile,
    refetch,
    fileTags
  }
}

export default useFileManager
