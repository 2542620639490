import { Row, Col } from 'antd'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import { H6 } from 'ui'

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  border-radius: 10px;
  background: white;
  padding: 20px;
  height: 100%;
`

export const ReviewWrapper = styled(Row)`
  display: flex;
  flex-direction: row;
  width: 100%;
`

export const ReviewSectionWrapper = styled(Row)`
  margin: 0px 0px 30px 0px;
  flex-direction: column;
`

export const ReviewSectionDescription = styled(H6)``

export const ReviewColumns = styled(Col)`
  padding-right: 30px;
`

export const CreateOfferButtonWrapper = styled.div``

export const TitlesWrapper = styled.div`
  height: 20%;
  text-align: center;
`

export const ReviewMainWrapper = styled.div`
  height: 82%;
  overflow-y: auto;
`

export const SuccessPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const SuccessPageButtonsWrapper = styled(motion.div)`
  display: flex;
  flex-direction: row;

  button {
    margin: 0px 15px;
  }
`
