// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { LottieWrapper } from 'components'
import successAnimation from 'assets/lottieJsons/avesdoLoading.json'

const CustomLoading = () => {
  const [messages, setMessages] = useState([
    'Connecting to map',
    'Map Fetching...'
  ])
  const [currentIndex, setCurrentIndex] = useState(0)
  const [fade, setFade] = useState(false)

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(true)
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % messages.length)
        setFade(false)
      }, 500)
    }, 2000)

    return () => clearInterval(interval)
  }, [messages])

  useEffect(() => {
    setMessages((prevMessages) => [...prevMessages])
  }, [])
  return (
    <div role='presentation'>
      <div
        role='presentation'
        style={{
          height: 150,
          width: 150,
          margin: '0 auto'
        }}
      >
        <LottieWrapper
          animation={successAnimation}
          autoplay
          loop={true}
          className='-mt-40 md:mt-0 max-w-sm'
        />
        <h1 className={`mt-4 text-[16px] truncate text-center ${fade && 'fade mt-4 text-[16px]'}`}>
          {messages[currentIndex]}
        </h1>
      </div>
    </div>
  )
}

export default CustomLoading
