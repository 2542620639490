import React from 'react'
import propTypes from 'prop-types'

import { ButtonsWrapper } from './styles'
import { CancelButton, SaveButton } from 'components/DefaultButtons'

const DrawerFooterButtons = (props) => {
  const {
    onSaveClick, onCancelClick,
    saving, parentIsLoading
  } = props
  return (
    <ButtonsWrapper>
      <CancelButton
        parentIsLoading={parentIsLoading}
        onClick={onCancelClick}
      />
      <SaveButton
        parentIsLoading={parentIsLoading}
        loading={saving}
        onClick={onSaveClick}
      />
    </ButtonsWrapper>
  )
}

export default DrawerFooterButtons

DrawerFooterButtons.propTypes = {
  onSaveClick: propTypes.func,
  onCancelClick: propTypes.func,
  saving: propTypes.bool,
  parentIsLoading: propTypes.bool
}
