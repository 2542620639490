/* eslint-disable */
// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import { Oval } from 'react-loader-spinner';
import { Card, Modal } from '@material-ui/core';
import CustomInput from 'components/CustomInput';
import { api } from 'services/api';
import { PlusSmIcon } from '@heroicons/react/outline';
import { ArrowLeft, EditIcon } from 'lucide-react';
import CustomButton from 'components/CustomButton';

const AddParkingInventoryModal = ({ show, setShow, building }) => {
  // @ts-ignore
  const [openModal, setOpenModal] = useState(false);
  const [optionName, setOptionName] = useState('');
  const [optionNameAllocate, setOptionNameAllocate] = useState('');
  const [editIndex, setEditIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editIndexAllocate, setEditIndexAllocate] = useState(null);
  const [editedName, setEditedName] = useState('');
  const [editedNameAllocate, setEditedNameAllocate] = useState('');
  const [totalAssignedParking, setTotalAssignedParking] = useState(null);
  const [totalAssignedParkingAllocate, setTotalAssignedParkingAllocate] =
    useState(null);
  const [totalParkings, setTotalParkings] = useState(null);
  const [parkingTypeData, setParkingTypeData] = useState([{}]);
  const [parkingError, setParkingError] = useState({});
  const [parkingErrorAllocate, setParkingErrorAllocate] = useState({});
  const [parkingInventoryState, setParkingInventoryState] = useState({
    step: 1,
  });
  const initialEmptyValues = {
    building,
    options: [],
    parkingLevels: ['P1', 'P2'],
    totalParkings: null,
  };

  const editTotalParking = async () => {
    setValues({ ...value, totalParkings: null });
  };
  const [value, setValues] = useState({
    ...initialEmptyValues,
  });

  const parkingAllocationInitialValues = {
    building,
    options: [],
    parkingLevels: [],
  };

  const parkingColor = ['#59b099', 'red', 'yellow', 'black'];

  const parkingTypeArray = () => {
    const parkingStatus = [
      { name: 'Available', value: value.totalParkings || 0 },
      { name: 'Sold', value: 0 },
      { name: 'Allocated', value: 0 },
      { name: 'Assigend', value: 0 },
    ];
    const parkingData = parkingStatus.map((parking, index) => {
      const color = parkingColor[index % parkingColor.length];
      return { name: parking.name, value: parking.value, color: color };
    });
    setParkingTypeData(parkingData);
  };

  const [parkingAllocationValues, setParkingAllocationValues] = useState({});

  const handleEditOption = (index) => {
    setEditIndex(index);
    setEditedName(value.options[index].name);
  };

  const handleEditOptionAllocate = (index) => {
    setEditIndexAllocate(index);
    setEditedNameAllocate(parkingAllocationValues.options[index].name);
  };

  const parkingAllocationStatus = [
    {
      name: 'Allocated',
      value: totalAssignedParkingAllocate,
    },
    {
      name: 'Unallocated',
      value:
        parkingAllocationValues.allocatedParkings -
        totalAssignedParkingAllocate,
    },
  ];

  const handleSaveOption = () => {
    const updatedOptions = value.options.map((op, i) =>
      i === editIndex ? { ...op, name: editedName } : op
    );
    setValues({ ...value, options: updatedOptions });
    setEditIndex(null);
    setEditedName('');
  };

  const handleSaveOptionAllocate = () => {
    const updatedOptions = parkingAllocationValues.options.map((op, i) =>
      i === editIndexAllocate ? { ...op, name: editedNameAllocate } : op
    );
    setParkingAllocationValues({ ...value, options: updatedOptions });
    setEditIndexAllocate(null);
    setEditedNameAllocate('');
  };
  //   const TEMP_AVAILABILITY_STATUS = {
  //     available: 'Available',
  //     assigned: 'Assigned',
  //     reserved: 'Reserved',
  //     allocated: 'Allocated',
  //     recision: 'Recision',
  //     sold: 'Sold'
  //   }

  //   const STATUS_OPTIONS = [
  //     ...Object.values(TEMP_AVAILABILITY_STATUS).map((value) => {
  //       return { value: value, id: value }
  //     })
  //   ]

  //   const isInsideBuildingOptions = [
  //     { id: 'true', value: 'Yes' },
  //     { id: 'false', value: 'No' }
  //   ]

  const AddOption = async () => {
    setValues((prevState) => ({
      ...prevState,
      options: [
        ...prevState.options,
        { name: optionName, value: [], isCustom: true },
      ],
    }));
    setOptionName('');
    setOpenModal(false);
  };
  const AddOptionAllocate = async () => {
    setParkingAllocationValues((prevState) => ({
      ...prevState,
      options: [
        ...prevState.options,
        {
          name: optionNameAllocate,
          parkingTypes: value.parkingLevels.map((level, index) => ({
            parkingLevel: level,
            options: value.options.map((option) => ({
              name: option.name,
              value: 0,
            })),
          })),
          isCustom: true,
        },
      ],
    }));
    setOptionNameAllocate('');
    setOpenModal(false);
  };

  const handleTotalParkingsAllocate = () => {
    const totalParking = parkingAllocationValues.options.reduce((sum, obj) => {
      const objSum = obj.parkingTypes.reduce((objSum, parkingType) => {
        const typeSum = parkingType.options.reduce(
          (typeSum, option) => typeSum + Number(option.value),
          0
        );
        return objSum + typeSum;
      }, 0);
      return sum + objSum;
    }, 0);
    setTotalAssignedParkingAllocate(totalParking);
  };

  const handleSubmit = async () => {
    try {
      if (Number(value.totalParkings) === totalAssignedParking) {
        setLoading(true);
        setValues({ ...value });
        setParkingError({});
        const temp = {
          // @ts-ignore
          ...value,
          buildingId: building,
        };
        const { data } = await api.get(
          `/parking/${building}/allocateInventory`
        );
        if (
          data.data &&
          Array.isArray(data.data) &&
          data.data.length > 0 &&
          data.data[0].options
        ) {
          const parkingLevelsAllocate = data.data[0].parkingLevels;
          const parkingLevels = value.parkingLevels;
          const fetchedOptions = data.data[0].options;
          const mergedOption = fetchedOptions.map((option, i) => {
            return {
              name: option.name,
              isCustom: option.isCustom || false,
              parkingTypes: parkingLevels.map((level, index) => ({
                parkingLevel: level,
                options: value.options.map((op, OIndex) =>
                  // console.log(
                  //   option.parkingTypes[lIndex].options.filter((op) => {
                  //     op.name === value.options.name;
                  //   })
                  // ),
                  ({
                    name: op.name,
                    value:
                      option.parkingTypes[index]?.options[OIndex]?.value || 0,
                  })
                ),
              })),
            };
          });
          setParkingAllocationValues({
            parkingLevels: parkingLevels,
            building: building,
            options: mergedOption,
            allocatedParkings: totalAssignedParking,
          });
          const allocatedObject = {
            building: building,
            parkingLevels: parkingLevels,
            options: mergedOption,
            allocatedParkings: totalAssignedParking,
          };
          await api.post(`/parking/allocateInventory`, {
            data: allocatedObject,
          });
        } else {
          console.log('not found');
          const parkingLevels = value.parkingLevels;
          const newOptions = [
            {
              name: 'UNITS',
              parkingTypes: value.parkingLevels.map((level, index) => ({
                parkingLevel: level,
                options: value.options.map((option) => ({
                  name: option.name,
                })),
              })),
            },
            {
              name: 'For Sale (extra parking)',
              parkingTypes: value.parkingLevels.map((level, index) => ({
                parkingLevel: level,
                options: value.options.map((option) => ({
                  name: option.name,
                })),
              })),
            },
          ];

          const allocatedObject = {
            building: building,
            parkingLevels: parkingLevels,
            options: newOptions,
            allocatedParkings: totalAssignedParking,
          };
          // allocatedParkings: totalAssignedParking;
          setParkingAllocationValues({
            parkingLevels: parkingLevels,
            building: building,
            options: newOptions,
            allocatedParkings: totalAssignedParking,
          });

          await api.post(`/parking/allocateInventory`, {
            data: allocatedObject,
          });
        }
        await api.post(`/parking/inventory`, {
          data: temp,
        });

        setParkingInventoryState({ step: 2 });
        setLoading(false);
      } else {
        setLoading(false);
        setParkingError({
          error: 'Total parkings are not accounted',
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitAllocate = async () => {
    try {
      setParkingErrorAllocate({});
      setLoading(true);
      setParkingError({});
      const temp = {
        // @ts-ignore
        ...parkingAllocationValues,
        building: building,
      };
      await api.post(`/parking/allocateInventory`, {
        data: temp,
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const handleClose = () => {
    setShow(false);
  };

  const handleRemoveOption = (index) => {
    setValues((prevState) => ({
      ...prevState,
      options: prevState.options.filter((_, i) => i !== index),
    }));
  };

  const handleRemoveAllocation = (index) => {
    setParkingAllocationValues((prevState) => ({
      ...prevState,
      options: prevState.options.filter((_, i) => i !== index),
    }));
  };
  const handleTotalParkings = () => {
    const totalParking = value.options.reduce((sum, obj) => {
      const objSum = obj.value.reduce((objSum, val) => objSum + Number(val), 0);
      return sum + objSum;
    }, 0);
    setTotalAssignedParking(totalParking);
  };

  const handleRemoveParkingLevel = (indexToRemove) => {
    setValues((prevState) => {
      const updatedParkingLevels = prevState.parkingLevels.filter(
        (_, index) => index !== indexToRemove
      );
      const updatedOptions = prevState.options.map((option) => ({
        ...option,
        value: option.value.filter((_, index) => index !== indexToRemove),
      }));
      return {
        ...prevState,
        options: updatedOptions,
        parkingLevels: updatedParkingLevels,
      };
    });
  };

  useEffect(() => {
    const handleBuildingInventory = async () => {
      try {
        const { data } = await api.get(`/parking/${building}/inventory`);
        if (data.data && data.data.length > 0) {
          setValues(data.data[0]);
        } else {
          console.log('not found');
          setValues({ ...initialEmptyValues });
        }
      } catch (error) {
        console.error('Error fetching building inventory:', error);
      }
    };
    handleBuildingInventory();
    parkingTypeArray();
  }, []);

  useEffect(() => {
    handleTotalParkings();
  }, [value.options]);

  useEffect(() => {
    parkingTypeArray();
  }, [value.totalParkings]);

  useEffect(() => {
    if (parkingInventoryState.step === 2) {
      handleTotalParkingsAllocate();
    }
  }, [parkingAllocationValues, parkingInventoryState.step]);
  return (
    <>
      {parkingInventoryState.step === 1 && (
        <Modal
          open={show}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
          className='h-full'
        >
          <div
            className='font-openSans flex flex-col overflow-scroll relative bg-grey-8 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-10 rounded shadow-lg'
            style={{ width: '100vw', height: '100%' }}
          >
            <button className='absolute right-10' onClick={handleClose}>
              <CrossIcon />
            </button>
            <h1 className='font-bold text-xl text-center p-0 m-0 mb-6'>
              Add Parking Inventory
            </h1>
            <Card className='!shadow-none !overflow-visible mb-24 py-8 px-12 w-fit border-[2px] border-[rgba(0,0,0,0.2)] rounded-[12px]'>
              <div className='font-bold text-[20px]'>Parking Inventory</div>
              <div className='flex gap-x-16 mt-6'>
                <div className='font-bold text-[24px]'>
                  {value.totalParkings} Parking
                </div>
                <div className='h-full'>
                  <div className='flex flex-wrap h-full gap-x-4'>
                    {parkingTypeData.map((parking, index) => (
                      <>
                        <div className='flex flex-col'>
                          <div className='flex items-center gap-x-2'>
                            <div
                              style={{
                                backgroundColor: parking.color,
                              }}
                              className={`rounded-full h-3 w-3`}
                            ></div>
                            <div className='text-sm col-span-2'>
                              {parking.name}
                            </div>
                          </div>
                          <div className='text-xl col-span-2 font-bold'>
                            {parking.value}
                          </div>
                        </div>
                        {parkingTypeData.length - 1 != index && (
                          <div className='w-[2px] bg-[rgba(0,0,0,0.4)]'></div>
                        )}
                      </>
                    ))}
                  </div>
                </div>
              </div>
              <div className='flex gap-x-20 items-center mt-6'>
                <div>
                  <div className='font-bold text-[16px] '>Parking Types</div>
                  <div className='flex gap-x-4 mt-6'>
                    {value.options.map((parking, index) => (
                      <>
                        <div className='flex flex-col'>
                          <div className='flex items-center gap-x-2'>
                            <div
                              style={{
                                backgroundColor:
                                  parkingColor[index % parkingColor.length],
                              }}
                              className={`rounded-full h-3 w-3`}
                            ></div>
                            <div className='text-sm col-span-2'>
                              {parking.name}
                            </div>
                          </div>
                          <div className='text-xl col-span-2 font-bold'>
                            {parking.value.reduce(
                              (sum, currentValue) =>
                                Number(sum) + Number(currentValue),
                              0
                            )}
                          </div>
                        </div>
                        {value.options.length - 1 != index && (
                          <div className='w-[2px] bg-[rgba(0,0,0,0.4)]'></div>
                        )}
                      </>
                    ))}
                  </div>
                </div>
                <div>
                  <div className='font-bold text-[16px]'>Parking Types</div>
                  <div className='flex gap-x-4 mt-6'>
                    {value.parkingLevels.map((parking, index) => (
                      <>
                        <div className='flex flex-col'>
                          <div className='flex items-center gap-x-2'>
                            <div
                              style={{
                                backgroundColor:
                                  parkingColor[index % parkingColor.length],
                              }}
                              className={`rounded-full h-3 w-3`}
                            ></div>
                            <div className='text-sm col-span-2'>{parking}</div>
                          </div>
                          <div className='text-xl col-span-2 font-bold'>
                            {value.options.reduce((sum, op) => {
                              const opValue = op.value[index];
                              return (
                                sum + (isNaN(opValue) ? 0 : Number(opValue))
                              );
                            }, 0)}
                          </div>
                        </div>
                        {value.parkingLevels.length - 1 != index && (
                          <div className='w-[2px] bg-[rgba(0,0,0,0.4)]'></div>
                        )}
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </Card>
            <div className=''>
              {value.totalParkings === null ? (
                <div className='items-center flex gap-x-6'>
                  <div className='text-lg font-semibold'>
                    Add Total Parking
                  </div>
                  <div className=''>
                    <CustomInput
                      label=''
                      placeholder='Total Parking slots'
                      type='text'
                      classes='font-openSans text-base col-span-2'
                      value={totalParkings}
                      onChange={(e) => setTotalParkings(e.target.value)}
                    />
                  </div>
                  <CustomButton handleClick={() => setValues({ ...value, totalParkings })} className='w-fit'>
                    <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
                      Save
                    </span>
                  </CustomButton>
                </div>
              ) : (
                <div className='flex gap-x-48 items-center'>
                  <div className='text-xl flex gap-x-4 items-center  pl-6 font-bold'>
                    Total parkings - {value.totalParkings}
                    <div className='cursor-pointer'>
                      <EditIcon height={'20px'} onClick={editTotalParking} />
                    </div>
                  </div>
                  {value.totalParkings == totalAssignedParking && (
                    <div className='font-bold text-lg'>
                      All Parking Accounted For
                    </div>
                  )}
                  {totalAssignedParking > value.totalParkings && (
                    <div className='text-[#8a2c2c] font-bold text-xl'>
                      You can not assign more parkings than total parkings
                    </div>
                  )}
                  {totalAssignedParking < value.totalParkings && (
                    <div className='text-[#8a2c2c] font-bold text-xl'>
                      {value.totalParkings - totalAssignedParking} parking
                      missing
                    </div>
                  )}
                </div>
              )}
              <div className='grid grid-cols-12 col-span-12 px-6 pt-8 gap-x-10 gap-y-6'>
                <ul
                  className={`grid col-span-12 items-center text-[#323232] font-bold grid-cols-12 ${value.parkingLevels.length >= 5 ? 'gap-x-4' : 'gap-x-10'
                    }`}
                >
                  <li className='col-span-2 text-[20px] text-black'>Options</li>
                  {value.parkingLevels.map((level, index) => {
                    return (
                      <li
                        key={index}
                        className={`${value.parkingLevels.length > 2 &&
                          value.parkingLevels.length < 5 &&
                          'col-span-2'
                          } ${value.parkingLevels.length >= 5 && 'col-span-1'}
                      ${value.parkingLevels.length <= 2 && 'col-span-3'}
                      text-[20px] flex items-center gap-x-4 justify-center  text-center text-black`}
                      >
                        {level}
                        {value.parkingLevels.length > 1 && (
                          <div
                            className='cursor-pointer'
                            onClick={() => {
                              handleRemoveParkingLevel(index);
                            }}
                          >
                            <svg
                              width='14'
                              height='22'
                              viewBox='0 0 14 18'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M1.50016 15.4167C1.50016 16.425 2.32516 17.25 3.3335 17.25H10.6668C11.6752 17.25 12.5002 16.425 12.5002 15.4167V4.41667H1.50016V15.4167ZM13.4168 1.66667H10.2085L9.29183 0.75H4.7085L3.79183 1.66667H0.583496V3.5H13.4168V1.66667Z'
                                fill='#2E2B2E'
                              />
                            </svg>
                          </div>
                        )}
                      </li>
                    );
                  })}
                  <li className='col-span-1 text-center'>
                    <button
                      onClick={() => {
                        setValues({
                          ...value,
                          parkingLevels: [
                            ...value.parkingLevels,
                            'P' + (value.parkingLevels.length + 1),
                          ],
                        });
                      }}
                      className='flex cursor-pointer gap-x-2'
                    >
                      <PlusSmIcon height={34} className='' />
                    </button>
                  </li>
                </ul>
                {value.options.map((option, index) => (
                  <ul
                    key={index}
                    className={`grid col-span-12 overflow-visible !mb-0 items-center text-[#323232] font-bold grid-cols-12 ${value.parkingLevels.length >= 5 ? 'gap-x-4' : 'gap-x-12'
                      }`}
                  >
                    <li
                      key={index}
                      className='col-span-2 flex gap-x-8 items-center text-[20px] text-black '
                    >
                      <div className='cursor-pointer'>
                        {editIndex === index ? (
                          <div className='flex gap-x-2 items-center'>
                            <CustomInput
                              label=''
                              placeholder=''
                              type='text'
                              value={editedName}
                              onChange={(e) => setEditedName(e.target.value)}
                              classes='!m-0'
                            />
                            <CustomButton handleClick={handleSaveOption} className='w-fit h-fit'>
                              <span className='font-medium py-2.5 px-3.5 text-base flex gap-2 items-center'>
                                Save
                              </span>
                            </CustomButton>
                          </div>
                        ) : (
                          <div className='flex gap-x-6 items-center'>
                            {option.name}
                            {option.isCustom && (
                              <EditIcon
                                height={'20px'}
                                onClick={() => handleEditOption(index)}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </li>
                    {value.parkingLevels.map((level, i) => {
                      return (
                        <li
                          key={i}
                          className={`${value.parkingLevels.length > 2 &&
                            value.parkingLevels.length < 5 &&
                            'col-span-2'
                            } ${value.parkingLevels.length >= 5 && 'col-span-1'}
                        ${value.parkingLevels.length <= 2 && 'col-span-3'}
                        `}
                        >
                          <CustomInput
                            type='number'
                            label=''
                            placeholder=''
                            classes='font-openSans text-base col-span-2'
                            style={{ maxHeight: '48px' }}
                            value={option.value[i] || ''}
                            onChange={(e) => {
                              const updatedOptions = value.options.map(
                                (op, j) => {
                                  if (j === index) {
                                    const newValue = [...op.value];
                                    newValue[i] = e.target.value;
                                    return {
                                      ...op,
                                      value: newValue,
                                    };
                                  }
                                  return op;
                                }
                              );
                              setValues({ ...value, options: updatedOptions });
                            }}
                          />
                        </li>
                      );
                    })}
                    <li className='col-span-1'>
                      {option.isCustom && (
                        <div
                          className='cursor-pointer'
                          onClick={() => {
                            handleRemoveOption(index);
                          }}
                        >
                          <svg
                            width='16'
                            height='24'
                            viewBox='0 0 14 18'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M1.50016 15.4167C1.50016 16.425 2.32516 17.25 3.3335 17.25H10.6668C11.6752 17.25 12.5002 16.425 12.5002 15.4167V4.41667H1.50016V15.4167ZM13.4168 1.66667H10.2085L9.29183 0.75H4.7085L3.79183 1.66667H0.583496V3.5H13.4168V1.66667Z'
                              fill='#2E2B2E'
                            />
                          </svg>
                        </div>
                      )}
                    </li>
                  </ul>
                ))}
              </div>
              <button
                onClick={() => {
                  setOpenModal(true);
                }}
                className='flex cursor-pointer items-center ml-4 mt-4 gap-x-2'
              >
                <PlusSmIcon height={34} className='' />
                <div className='text-[20px]'>Add Parking Type</div>
              </button>
              {openModal && (
                <div className='ml-4 mt-4'>
                  <CustomInput
                    type='text'
                    label=''
                    placeholder='Enter Option Name'
                    classes='font-openSans text-base max-w-xl col-span-2'
                    style={{ maxHeight: '48px' }}
                    onChange={(e) => {
                      setOptionName(e.target.value);
                    }}
                  />
                  <CustomButton handleClick={AddOption} className='w-fit h-fit my-4'>
                    <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
                      Add
                    </span>
                  </CustomButton>
                </div>
              )}
              <CustomButton handleClick={handleSubmit} className='w-fit h-fit mx-auto'>
                <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
                  {loading && <Oval height='18' width='18' color='white' />}
                  Update Parking
                </span>
              </CustomButton>
              <div className='text-lg font-[500] text-[#8a2c2c]'>
                {parkingError.error}
              </div>
            </div>
          </div>
        </Modal>
      )}
      {parkingInventoryState.step === 2 && (
        <Modal
          open={show}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
          className='h-full'
        >
          <div
            className='font-openSans relative overflow-scroll bg-grey-8 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-10 rounded shadow-lg'
            style={{ width: '100vw', height: '100%' }}
          >
            <button className='absolute right-10' onClick={handleClose}>
              <CrossIcon />
            </button>
            <div
              className='absolte left-10'
              onClick={() => {
                setParkingInventoryState({ step: 1 });
              }}
            >
              <ArrowLeft height={24} />
            </div>
            <h1 className='font-bold text-xl text-center p-0 m-0 mb-6'>
              Allocate parking
            </h1>
            <div className='grid grid-cols-6 lg:grid-cols-12 col-span-12 gap-y-10 gap-x-10 mb-6'>
              <Card className='!shadow-none col-span-6 p-3 md:py-8 md:px-8 w-full border-[2px] border-[rgba(0,0,0,0.2)] rounded-[12px]'>
                <div className='flex justify-between'>
                  <div className='font-bold text-[18px]'>
                    Parking Allocations
                  </div>
                  {parkingAllocationValues.allocatedParkings !=
                    totalAssignedParkingAllocate ? (
                    <div className='bg-[#F6E4CA] max-w-[200px]  rounded-[8px]  gap-x-4 flex items-center pl-4 pr-3 py-2'>
                      <div className='h-4 w-4 rounded-full bg-[#DA952C]'></div>
                      <div className='text-[16px] flex-1 font-[500] leading-[20px]'>
                        {parkingAllocationValues.allocatedParkings -
                          totalAssignedParkingAllocate}{' '}
                        Inventory Unaccounted For
                      </div>
                    </div>
                  ) : (
                    <div className='bg-[#D6FABB] max-w-[200px]  rounded-[8px]  gap-x-4 flex items-center pl-4 pr-3 py-2'>
                      <div className='h-4 w-4 rounded-full bg-[#60AD83]'></div>
                      <div className='text-[16px] flex-1 font-[500] leading-[20px]'>
                        All Inventory Accounted for
                      </div>
                    </div>
                  )}
                </div>
                <div className='flex'>
                  <div className='flex mt-4 md:mt-0 flex-col'>
                    <div className='flex gap-x-6'>
                      <div className='h-full'>
                        <div className='flex flex-wrap h-full gap-x-4'>
                          {parkingAllocationStatus.map((parking, index) => (
                            <>
                              <div className='flex flex-col'>
                                <div className='flex items-center gap-x-2'>
                                  <div
                                    style={{
                                      backgroundColor:
                                        parkingColor[
                                        index % parkingColor.length
                                        ],
                                    }}
                                    className={`rounded-full h-3 w-3`}
                                  ></div>
                                  <div className='text-sm col-span-2'>
                                    {parking.name}
                                  </div>
                                </div>
                                <div className='text-xl col-span-2 font-bold'>
                                  {parking.value}
                                </div>
                              </div>
                              {parkingAllocationStatus.length - 1 != index && (
                                <div className='w-[2px] bg-[rgba(0,0,0,0.4)]'></div>
                              )}
                            </>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className='flex gap-x-20 items-center mt-6'>
                      <div>
                        <div className='font-bold text-[16px] '>
                          Parking Types
                        </div>
                        <div className='flex flex-col md:flex-row gap-x-4 mt-6'>
                          {parkingAllocationValues.options.map(
                            (parking, index) => (
                              <>
                                <div className='flex flex-row gap-x-4 md:gap-x-0 md:flex-col'>
                                  <div className='flex items-center gap-x-2'>
                                    <div
                                      style={{
                                        backgroundColor:
                                          parkingColor[
                                          index % parkingColor.length
                                          ],
                                      }}
                                      className={`rounded-full h-3 w-3`}
                                    ></div>
                                    <div className='text-sm col-span-2'>
                                      {parking.name}
                                    </div>
                                  </div>
                                  <div className='text-xl col-span-2 font-bold'>
                                    {parking.parkingTypes.reduce(
                                      (sum, parkingType) =>
                                        sum +
                                        parkingType.options.reduce(
                                          (subSum, option) =>
                                            subSum + Number(option.value),
                                          0
                                        ),
                                      0
                                    )}
                                  </div>
                                </div>
                                {parkingAllocationValues.options.length - 1 !=
                                  index && (
                                    <div className='w-[2px] bg-[rgba(0,0,0,0.4)]'></div>
                                  )}
                              </>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
              <Card className='!shadow-none col-span-6 !overflow-visible p-3 md:py-8 md:px-8 w-full border-[2px] border-[rgba(0,0,0,0.2)] rounded-[12px]'>
                <div className='font-bold text-[20px]'>Parking Inventory</div>
                <div className='flex gap-x-16 mt-6'>
                  <div className='font-bold text-[24px]'>
                    {value.totalParkings} Parking
                  </div>
                  <div className='h-full'>
                    <div className='flex flex-wrap h-full gap-y-4 gap-x-4'>
                      {parkingTypeData.map((parking, index) => (
                        <>
                          <div className='flex flex-row gap-x-4 md:gap-x-0 md:flex-col'>
                            <div className='flex items-center gap-x-2'>
                              <div
                                style={{
                                  backgroundColor: parking.color,
                                }}
                                className={`rounded-full h-3 w-3`}
                              ></div>
                              <div className='text-sm col-span-2'>
                                {parking.name}
                              </div>
                            </div>
                            <div className='text-xl col-span-2 font-bold'>
                              {parking.value}
                            </div>
                          </div>
                          {parkingTypeData.length - 1 != index && (
                            <div className='w-0 md:w-[2px] bg-[rgba(0,0,0,0.4)]'></div>
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                </div>
                <div className='flex gap-x-10 flex-wrap gap-y-6 items-center mt-6'>
                  <div>
                    <div className='font-bold text-[16px] '>Parking Types</div>
                    <div className='flex flex-col md:flex-row gap-x-4 mt-6'>
                      {value.options.map((parking, index) => (
                        <>
                          <div className='flex flex-row gap-x-4 md:gap-x-0 md:flex-col'>
                            <div className='flex items-center gap-x-2'>
                              <div
                                style={{
                                  backgroundColor:
                                    parkingColor[index % parkingColor.length],
                                }}
                                className={`rounded-full h-3 w-3`}
                              ></div>
                              <div className='text-sm col-span-2'>
                                {parking.name}
                              </div>
                            </div>
                            <div className='text-xl col-span-2 font-bold'>
                              {parking.value.reduce(
                                (sum, currentValue) =>
                                  Number(sum) + Number(currentValue),
                                0
                              )}
                            </div>
                          </div>
                          {value.options.length - 1 != index && (
                            <div className='w-0 md:w-[2px] bg-[rgba(0,0,0,0.4)]'></div>
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                  <div>
                    <div className='font-bold text-[16px]'>Parking Types</div>
                    <div className='flex flex-col md:flex-row gap-x-4 mt-6'>
                      {value.parkingLevels.map((parking, index) => (
                        <>
                          <div className='flex flex-row gap-x-4 md:gap-x-0 md:flex-col'>
                            <div className='flex items-center gap-x-2'>
                              <div
                                style={{
                                  backgroundColor:
                                    parkingColor[index % parkingColor.length],
                                }}
                                className={`rounded-full h-3 w-3`}
                              ></div>
                              <div className='text-sm col-span-2'>
                                {parking}
                              </div>
                            </div>
                            <div className='text-xl col-span-2 font-bold'>
                              {value.options.reduce((sum, op) => {
                                const opValue = op.value[index];
                                console.log('opValue:', opValue);
                                return (
                                  sum + (isNaN(opValue) ? 0 : Number(opValue))
                                );
                              }, 0)}
                            </div>
                          </div>
                          {value.parkingLevels.length - 1 != index && (
                            <div className='w-0 md:w-[2px] bg-[rgba(0,0,0,0.4)]'></div>
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              </Card>
            </div>
            <div className='overflow-auto flex flex-col grid-cols-12 gap-x-10 mt-8 gap-y-6'>
              {/* <ul
                className={`grid col-span-12 items-center text-[#323232] font-bold grid-cols-12 ${
                  value.parkingLevels.length >= 5 ? 'gap-x-4' : 'gap-x-10'
                }`}
              >
                <li className='col-span-2 text-[20px] text-black'>Options</li>
                {value.parkingLevels.map((level, index) => {
                  return (
                    <li
                      key={index}
                      className={`${
                        value.parkingLevels.length > 2 &&
                        value.parkingLevels.length < 5 &&
                        'col-span-2'
                      } ${
                        value.parkingLevels.length >= 5 && 'col-span-1'
                      } text-[20px] col-span-3 text-center text-black`}
                    >
                      {level}
                    </li>
                  );
                })}
              </ul> */}
              {parkingAllocationValues.options.map((option, index) => (
                <div
                  className={`grid col-span-12 !mb-0 items-center text-[#323232] font-bold grid-cols-12`}
                >
                  <div
                    key={index}
                    className='gap-x-8 flex col-span-2 items-center text-[20px] text-black '
                  >
                    <div
                      style={{
                        marginTop: index == 0 ? '5rem' : '0',
                      }}
                      className='cursor-pointer'
                    >
                      {editIndexAllocate === index ? (
                        <div className='flex gap-x-2 items-center'>
                          <CustomInput
                            label=''
                            placeholder=''
                            type='text'
                            value={editedNameAllocate}
                            onChange={(e) =>
                              setEditedNameAllocate(e.target.value)
                            }
                          />
                          <button
                            className='font-light text-[18px]'
                            onClick={handleSaveOptionAllocate}
                          >
                            Save
                          </button>
                        </div>
                      ) : (
                        <div className='flex gap-x-5 items-center'>
                          {option.name}
                          {option.isCustom && (
                            <EditIcon
                              height={'20px'}
                              onClick={() => handleEditOptionAllocate(index)}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='flex col-span-10 gap-x-14'>
                    {parkingAllocationValues.options[index].parkingTypes.map(
                      (option, optionIndex) => (
                        <div key={optionIndex}>
                          {index == 0 && (
                            <div className='text-center mb-4 text-[24px] font-bold'>
                              {value.parkingLevels[optionIndex]}
                            </div>
                          )}
                          <div key={optionIndex}>
                            <div className='flex gap-x-4'>
                              {option.options.map((op, opIndex) => {
                                return (
                                  <div
                                    key={opIndex}
                                    className='flex w-[180px] flex-col text-center'
                                  >
                                    {index == 0 && (
                                      <div className='text-[18px] mb-2 truncate'>
                                        {op.name}
                                      </div>
                                    )}
                                    <CustomInput
                                      type='number'
                                      label=''
                                      placeholder=''
                                      classes='font-openSans text-base col-span-2'
                                      style={{ maxHeight: '48px' }}
                                      value={op.value || ''}
                                      onChange={(e) => {
                                        const newValue = e.target.value;
                                        const updatedOptions =
                                          parkingAllocationValues.options.map(
                                            (opt, optIndex) => {
                                              if (optIndex === index) {
                                                const updatedParkingTypes =
                                                  opt.parkingTypes.map((pt) => {
                                                    if (
                                                      pt.parkingLevel ===
                                                      parkingAllocationValues
                                                        .parkingLevels[
                                                      optionIndex
                                                      ]
                                                    ) {
                                                      const updatedOptions =
                                                        pt.options.map(
                                                          (option) => {
                                                            if (
                                                              option.name ===
                                                              parkingAllocationValues
                                                                .options[index]
                                                                .parkingTypes[
                                                                optionIndex
                                                              ].options[opIndex]
                                                                .name
                                                            ) {
                                                              return {
                                                                ...option,
                                                                value: newValue,
                                                              };
                                                            }
                                                            return option;
                                                          }
                                                        );
                                                      return {
                                                        ...pt,
                                                        options: updatedOptions,
                                                      };
                                                    }
                                                    return pt;
                                                  });
                                                return {
                                                  ...opt,
                                                  parkingTypes:
                                                    updatedParkingTypes,
                                                };
                                              }
                                              return opt;
                                            }
                                          );
                                        setParkingAllocationValues({
                                          ...parkingAllocationValues,
                                          options: updatedOptions,
                                        });
                                      }}
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>

                  <div className='col-span-1'>
                    {option.isCustom && (
                      <div
                        className='cursor-pointer'
                        onClick={() => {
                          handleRemoveAllocation(index);
                        }}
                      >
                        <svg
                          width='16'
                          height='24'
                          viewBox='0 0 14 18'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M1.50016 15.4167C1.50016 16.425 2.32516 17.25 3.3335 17.25H10.6668C11.6752 17.25 12.5002 16.425 12.5002 15.4167V4.41667H1.50016V15.4167ZM13.4168 1.66667H10.2085L9.29183 0.75H4.7085L3.79183 1.66667H0.583496V3.5H13.4168V1.66667Z'
                            fill='#2E2B2E'
                          />
                        </svg>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
            <button
              onClick={() => {
                setOpenModal(true);
              }}
              className='flex cursor-pointer items-center ml-4 mt-4 gap-x-2'
            >
              <PlusSmIcon height={34} className='' />
              <div className='text-[20px]'>Add Parking Type</div>
            </button>
            {openModal && (
              <div className='ml-4 mt-4'>
                <CustomInput
                  type='text'
                  label=''
                  placeholder='Enter Option Name'
                  classes='font-openSans text-base max-w-xl col-span-2'
                  style={{ maxHeight: '48px' }}
                  onChange={(e) => {
                    setOptionNameAllocate(e.target.value);
                  }}
                />
                <CustomButton handleClick={AddOptionAllocate} className='my-4'>
                  <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
                    Add
                  </span>
                </CustomButton>
              </div>
            )}
            <CustomButton handleClick={handleSubmitAllocate} className='m-auto'>
              <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
                {loading && <Oval height='18' width='18' color='white' />}
                Update Parking
              </span>
            </CustomButton>
            <div className='text-lg font-[500] text-[#8a2c2c]'>
              {parkingErrorAllocate.error}
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default AddParkingInventoryModal;
