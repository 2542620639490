import React from 'react'

import { ReactComponent as CheckedCheckbox } from 'assets/icons/CheckedCheckbox.svg'

const Checkbox = ({ checked, label, handleChange, containerClasses, labelClasses, iconClasses }) => {
  return (
    <div className={`flex items-center ${containerClasses}`} onClick={handleChange}>
      {checked
        ? <CheckedCheckbox className={`cursor-pointer mr-2.5 transition duration-200 w-4.5 h-4.5 ${iconClasses}`} />
        : <div className={`cursor-pointer w-4.5 h-4.5 border-1.5 border-softBlack30 rounded-sm mr-2.5 transition duration-200 ${iconClasses}`} />}
      {label && (
        <label className={`m-0 text-base text-softBlack font-medium ${labelClasses}`}>
          {label}
        </label>
      )}
    </div>
  )
}

export default Checkbox
