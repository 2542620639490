/* eslint-disable */
// @ts-nocheck
import React, { createContext, useCallback, useEffect, useState } from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { getUnits } from 'store/actions/unitActions'
import FilterDrawer from './components/FilterDrawer'
import UnitInfoDrawer from './components/UnitInfoDrawer'
import { AVAILABILITY_BG_COLOR, AVAILABILITY_STATUS, classNames, formatMoney } from 'utils'
import useUnitInventoryMapContext from './hooks/useUnitInventoryMapContext'
import { getUnitPolygons } from 'store/actions/unitPolygonActions'
import AssignPolygonToUnitModal from './components/AssignPolygonToUnitModal'
// import CustomSelect from 'components/VerticalCardProfile/components/CustomSelect'

// const LOCALSTORAGE_KEY = 'polygonsData_unit'

// const filterOptions = [
//   {
//     value: AVAILABILITY_STATUS.open,
//     bgColor: AVAILABILITY_BG_COLOR.available
//   },
//   {
//     value: AVAILABILITY_STATUS.reservedBroker,
//     bgColor: AVAILABILITY_BG_COLOR.reserved
//   },
//   {
//     value: AVAILABILITY_STATUS.soldCommitted,
//     bgColor: AVAILABILITY_BG_COLOR.sold
//   },
//   {
//     value: AVAILABILITY_STATUS.underContract,
//     bgColor: AVAILABILITY_BG_COLOR.undercontract
//   },
//   {
//     value: AVAILABILITY_STATUS.developerHeld,
//     bgColor: AVAILABILITY_BG_COLOR.developerheld
//   }
// ]

// const getValuesFromLocalStorage = () => {
//   const localData = localStorage.getItem(LOCALSTORAGE_KEY)
//   if (!localData) {
//     localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify([]))
//     return []
//   }
//   return JSON.parse(localData)
// }

const typesOfContracts = [
  {
    id: 'available',
    name: 'Available',
    color: '#60AD83',
    number: 150
  },
  {
    id: 'sold',
    name: 'Sold',
    color: '#B91C1C',
    number: 27
  },
  {
    id: 'reserved',
    name: 'Reserved',
    color: '#EDDF65',
    number: 8
  },
  {
    id: 'assigned',
    name: 'Assigned',
    color: '#2E2B2E',
    number: 70
  }
]

const UnitInventoryMap = () => {
  const { state, openUnitInfoDrawer, closeUnitInfoDrawer } = useUnitInventoryMapContext()
  const [gettingData, setGettingData] = useState(true)
  // Units Data
  const [unitsData, setUnitsData] = useState([])

  const MAP_API_KEY = 'AIzaSyDCj_iKzflRXqK8NGXj_WUMbcqmiwTSVIU'
  const YOUR_METERS_PER_PIXEL = 0.02
  const POLYGON_YOUR_METERS_PER_PIXEL = 0.0001
  const YOUR_METERS_PER_DEGREE_LAT = 5000 // 10000
  const YOUR_METERS_PER_DEGREE_LNG = 5000

  const [map, setMap] = useState(null)
  const [drawingManager, setDrawingManager] = useState(null)
  // const [polygonsData, setPolygonsData] = useState(getValuesFromLocalStorage)
  const [unitPolygonsData, setUnitPolygonsData] = useState([])
  // console.log('unitPolygonsData: ', unitPolygonsData)
  const [showAssignPolygonToUnitModal, setShowAssignPolygonToUnitModal] = useState(false)
  const [newPolygonsData, setNewPolygonsData] = useState([])
  const [editDetails, setEditDetails] = useState({})

  const updatePolygons = () => {
    if (!unitPolygonsData?.length || !map) {
      return
    }
    const colorCodes = ['#FFB7FF', '#3BF4FB', '#CAFF8A', '#826AED']
    const polygons = unitPolygonsData.map((data, index) => {
      const val = {
        coordinates: data.polygon,
        fillColor: colorCodes[index % colorCodes.length],
      }
      return val
    })

    // Add a click event listener to show a custom info-window
    const infoWindowContent =
      '<div><h3>Custom Info-Window</h3><p>Additional information goes here.</p></div>'
    const infoWindow = new window.google.maps.InfoWindow({
      content: infoWindowContent,
    })

    polygons.forEach((polygonData, index) => {
      const selectedUnitId = unitPolygonsData?.[index].unit ?? ''
      const selectedUnitsData = unitsData.filter((unit) => unit._id === selectedUnitId)[0]
      // const {
      //   sector,
      //   salesLotNumber,
      //   lotType,
      //   collection,
      //   buildableDimensions,
      // } = selectedUnitsData?.lotData ?? {}

      const customPolygon = new window.google.maps.Polygon({
        paths: polygonData.coordinates,
        strokeColor: '#FF0000',
        strokeOpacity: 1, //0.8,
        strokeWeight: 2,
        fillColor: polygonData.fillColor,
        fillOpacity: 1, // 0.35,
        map: map,
      })


      window.google.maps.event.addListener(
        customPolygon,
        'mouseover',
        // 'click',
        function (event) {
          infoWindow.setPosition(event.latLng)
          infoWindow.setContent(`<div style='inset: 0px auto auto 0px;'>
            <div style='display: flex; flex-direction: column; color: #2E2B2E;'>
                <div style='font-weight: bold; font-size: 12px; padding: 4.5px 8px; display: flex; justify-content: center; align-items: center; width: 171px; height: 27px; background-color: ${AVAILABILITY_BG_COLOR[selectedUnitsData.status.toLowerCase()]}; text-transform: uppercase;'>${selectedUnitsData.status}</div>
                <div style='font-weight: bold; font-size: 24px; margin-top: 8px;'>Unit #${selectedUnitsData.unitNumber}</div>
                <div style='font-weight: medium; font-size: 16px; margin-top: 9px;'>$${selectedUnitsData.price}</div>
            </div>
          </div>`)
          // infoWindow.setContent(ReactDOM.render(<Content />, document.getElementById('popover')))
          // infoWindow.setContent(ReactDOM.render(<Content />, document.getElementsByClassName('gm-style-iw gm-style-iw-c')[0]))
          infoWindow.open(map)
        },
      )
      window.google.maps.event.addListener(
        customPolygon,
        'click',
        function () {
          setEditDetails(selectedUnitsData)
          openUnitInfoDrawer();
        },
      )
    })
  }

  const overlayClickListener = (event) => {
    setShowAssignPolygonToUnitModal(true)
    const cPolygon = event.overlay
      .getPath()
      .getArray()
      .map((a) => ({ lat: a.lat(), lng: a.lng() }))
    setUnitPolygonsData([...unitPolygonsData, { polygon: cPolygon }])
    setNewPolygonsData([...cPolygon])
  }

  useEffect(() => {
    if (drawingManager) {
      google.maps.event.addListener(
        drawingManager,
        'overlaycomplete',
        overlayClickListener,
      )
    }

    if (unitPolygonsData?.length > 0) {
      // localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(unitPolygonsData))
      updatePolygons()
    }
    return () => {
      if (drawingManager) {
        google.maps.event.clearListeners(drawingManager, 'overlaycomplete')
      }
    }
  }, [drawingManager, unitPolygonsData])

  const initMap = () => {
    // Geocode the address to get coordinates
    const geocoder = new window.google.maps.Geocoder()
    geocoder.geocode(
      {
        address:
          '14945 S Padre Island Dr, Corpus Christi, TX 78418, United States',
      },
      (results, status) => {
        if (status === 'OK' && results[0].geometry) {
          // const location = results[0].geometry.location
          const centerPoint = {
            // lat: location.lat(),
            // lng: location.lng(),
            lat: 49.30802539363068,
            lng: -123.06184709072114,
          }

          // Image size in pixels
          // const imageSize = { width: 1385, height: 786 }
          const imageSize = { width: 380, height: 110 }

          // Calculate half of the width and height in meters
          const halfWidth = imageSize.width * YOUR_METERS_PER_PIXEL
          const halfHeight = imageSize.height * YOUR_METERS_PER_PIXEL

          // Calculate the southwest and northeast corners of the image
          const imageSW = {
            lat: centerPoint.lat - halfHeight / YOUR_METERS_PER_DEGREE_LAT,
            lng: centerPoint.lng - halfWidth / YOUR_METERS_PER_DEGREE_LNG,
          }

          const imageNE = {
            lat: centerPoint.lat + halfHeight / YOUR_METERS_PER_DEGREE_LAT,
            lng: centerPoint.lng + halfWidth / YOUR_METERS_PER_DEGREE_LNG,
          }

          // Output the values
          const newMap = new google.maps.Map(document.getElementById('map'), {
            zoom: 18,
            center: centerPoint,
            rotateControl: true, // Enable rotate control
            rotateControlOptions: {
              position: window.google.maps.ControlPosition.TOP_CENTER, // Position of the rotate control
            },
            heading: 90, // In
            // mapTypeId: 'satellite',
          });
          // Add a marker at the geocoded location
          // const marker = new window.google.maps.Marker({
          //   position: location,
          //   map: newMap,
          //   title: 'Marker at Geocoded Location',
          // })

          // Add a custom image overlay
          const bounds = new window.google.maps.LatLngBounds(
            new window.google.maps.LatLng(imageSW.lat, imageSW.lng),
            new window.google.maps.LatLng(imageNE.lat, imageNE.lng),
          )
          // The photograph is courtesy of the U.S. Geological Survey.
          const srcImage =
            // `${window.location.origin}/abc.jpg`
            `${window.location.origin}/sample-unit-inventory-graph-first-floor.png`
          // 'https://whitecaplivingnpi.com/wp-content/uploads/2023/07/Phase1-Homesite-Map.jpg'

          // const newMap = new window.google.maps.Map(
          //   document.getElementById('map'),
          //   {
          //     center: centerPoint,
          //     zoom: 14,
          //   },
          // )

          // const customImageOverlay = new window.google.maps.GroundOverlay(
          //   // 'project-map.jpg',
          //   'https://whitecaplivingnpi.com/wp-content/uploads/2023/07/Phase1-Homesite-Map.jpg',
          //   imageBounds,
          // )
          class USGSOverlay extends google.maps.OverlayView {
            bounds_
            image_
            div_
            constructor(bounds, image) {
              super()
              // Initialize all properties.
              this.bounds_ = bounds
              this.image_ = image
              // Define a property to hold the image's div. We'll
              // actually create this div upon receipt of the onAdd()
              // method so we'll leave it null for now.
              this.div_ = null
            }
            /**
             * onAdd is called when the map's panes are ready and the overlay has been
             * added to the map.
             */
            onAdd() {
              this.div_ = document.createElement('div')
              this.div_.style.borderStyle = 'none'
              this.div_.style.borderWidth = '0px'
              this.div_.style.position = 'absolute'

              // Create the img element and attach it to the div.
              const img = document.createElement('img')

              img.src = this.image_
              img.style.width = '100%'
              img.style.height = '100%'
              img.style.position = 'absolute'
              this.div_.appendChild(img)

              // Add the element to the 'overlayLayer' pane.
              const panes = this.getPanes()

              panes.overlayLayer.appendChild(this.div_)
            }
            draw() {
              // We use the south-west and north-east
              // coordinates of the overlay to peg it to the correct position and size.
              // To do this, we need to retrieve the projection from the overlay.
              const overlayProjection = this.getProjection()
              // Retrieve the south-west and north-east coordinates of this overlay
              // in LatLngs and convert them to pixel coordinates.
              // We'll use these coordinates to resize the div.
              const sw = overlayProjection.fromLatLngToDivPixel(
                this.bounds_.getSouthWest(),
              )
              const ne = overlayProjection.fromLatLngToDivPixel(
                this.bounds_.getNorthEast(),
              )

              // Resize the image's div to fit the indicated dimensions.
              if (this.div_) {
                this.div_.style.left = sw.x + 'px'
                this.div_.style.top = ne.y + 'px'
                this.div_.style.width = ne.x - sw.x + 'px'
                this.div_.style.height = sw.y - ne.y + 'px'
              }
            }
            /**
             * The onRemove() method will be called automatically from the API if
             * we ever set the overlay's map property to 'null'.
             */
            onRemove() {
              if (this.div_) {
                this.div_.parentNode.removeChild(this.div_)
                this.div_ = null
              }
            }
          }

          const overlay = new USGSOverlay(bounds, srcImage)
          overlay.setMap(newMap)

          // Add a custom polygon with info-window
          // const polygonCoordinates = [
          //   { lat: imageSW.lat, lng: imageSW.lng },
          //   // { lat: imageSW.lat, lng: imageSW.lng },
          //   // { lat: imageSW.lat, lng: imageSW.lng },
          //   // Add more polygon vertices as needed
          // ]

          function getRandomColor(value) {
            var letters = '0123456789ABCDEF'
            var color = '#'
            for (var i = 0; i < 6; i++) {
              color += letters[Math.floor((value / 10) * 16)]
            }
            return color
          }

          // let currentCenterPoint = centerPoint

          const _drawingManager = new google.maps.drawing.DrawingManager({
            drawingMode: google.maps.drawing.OverlayType.POLYGON,
            drawingControl: true,
            drawingControlOptions: {
              position: google.maps.ControlPosition.TOP_CENTER,
              drawingModes: [google.maps.drawing.OverlayType.POLYGON],
            },
            polygonOptions: {
              editable: true,
            },
          })
          _drawingManager.setMap(newMap)

          setMap(newMap)
          updatePolygons()
          setDrawingManager(_drawingManager)
        } else {
          console.error('Geocoding failed:', status)
        }
      },
    )
  }

  useEffect(() => {
    // Load the Google Maps API script dynamically
    const script = document.createElement('script')
    document.head.appendChild(script)
    if (!gettingData) {
      script.src = `https://maps.googleapis.com/maps/api/js?key=${MAP_API_KEY}&libraries=drawing`
      script.async = true
      script.onload = () => {
        initMap()
      }
    }

    // Clean up the script on component unmount
    return () => {
      document.head.removeChild(script)
    }
  }, [unitsData, gettingData, unitPolygonsData])

  const _getUnits = useCallback(() => {
    setGettingData(true)
    getUnits()
      .then((units) => {
        setUnitsData(units)
        setGettingData(false)
      })
      .catch(() => {
        setGettingData(false)
      })
  }, [])

  useEffect(() => {
    _getUnits()
  }, [_getUnits])

  const _getUnitPolygons = useCallback(() => {
    setGettingData(true)
    getUnitPolygons()
      .then((unitPolygons) => {
        setUnitPolygonsData(unitPolygons)
        setGettingData(false)
      })
      .catch(() => {
        setGettingData(false)
      })
  }, [])

  useEffect(() => {
    _getUnitPolygons()
  }, [_getUnitPolygons])

  return (
    <div className='w-full h-full'>
      <div className='flex w-full h-full'>
        <div
          className={classNames(
            `flex flex-col w-full max-h-full overflow-auto transition-all`,
            state.isUnitInfoDrawerOpen ? 'mr-[280px]' : 'mr-0',
          )}
        >
          {/* <div className={`flex flex-row p-4 items-center gap-4 justify-start`}>
            <FilterToggle />
            <SearchBox />
            <CustomSelect
              value={contact?.buyerData?.leadStatus}
              handleChange={handleLeadStatusChange}
              options={leadStatuses}
              isLeadStatus
              />
            {filterOptions.map((item, index) => {
              return (
                <button
                  value={item.value}
                  key={`${item.value}-${index}`}
                  className={`flex w-[113px] rounded-sm items-center h-full content-center capitalize`}
                  onClick={() => {
                  }}
                  style={{
                    backgroundColor: item.bgColor,
                    opacity: 1.0
                  }}
                >
                  <span
                    className={
                      'w-full text-center font-medium text-sm text-softBlack'
                    }
                  >
                    {item.value}
                  </span>
                </button>
              );
            })}
            <button
                className='w-[129px] h-[45px] px-3 py-2 bg-volt rounded justify-center items-center inline-flex font-medium'
                key={index}
                onClick={onClick}
              >
                <div className='ml-2 italic'>{title}</div>
              </button>
            ))}
          </div> */}
          <div id='map' style={{ width: '100%', height: '100%' }}></div>
          <FilterDrawer />
        </div>
        <UnitInfoDrawer
          editDetails={editDetails}
          setEditDetails={setEditDetails}
          closeUnitInfoDrawer={closeUnitInfoDrawer}
          isUnitInfoDrawerOpen={state.isUnitInfoDrawerOpen}
        />
        {showAssignPolygonToUnitModal && (
          <AssignPolygonToUnitModal
            show={showAssignPolygonToUnitModal}
            setShow={setShowAssignPolygonToUnitModal}
            refetch={_getUnitPolygons}
            polygonsData={newPolygonsData}
            handleCancel={() => {
              setUnitPolygonsData(unitPolygonsData.filter(({ polygon }) => (polygon[0].lat !== newPolygonsData[0].lat && polygon[0].lng !== newPolygonsData[0].lng)))
            }}
          />
        )}
      </div>
    </div>
  );

}

const mapStateToProps = (state) => ({
  appProject: state.appReducer.appProject,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(UnitInventoryMap)

UnitInventoryMap.propTypes = {
  appProject: propTypes.string,
}
