import React, { useEffect, useState } from 'react'
import propTypes from 'prop-types'
import {
  PaymentSuccessWrapper,
  PaymentSuccessTextWrapper,
  PaymentSuccessPlantImageWrapper
} from './styles'
import { Col, Row } from 'antd'
import { TButton } from 'ui'
import { FadedAnimatedDiv, UnitSummarizedData } from 'components'
import { withRouter } from 'react-router-dom'
import standardStyle from 'assets/css/standardStyle'

const Text = ({ children, className }) => (
  <span className={`text-xl ${className}`}>{children}</span>
)

const SuccessPaymentTextWrapper = ({ style, delay, children }) => (
  <FadedAnimatedDiv
    transition={{ delay: delay }}
    style={style || {}}
  >
    {children}
  </FadedAnimatedDiv>
)

const OfferPaymentSuccess = (props) => {
  const {
    offer,
    executeOnFinish,
    doneButtonClassName
  } = props

  const [unitNumber, setUnitNumber] = useState('')
  const [unit, setUnit] = useState('')
  const [projectName, setProjectName] = useState('')

  useEffect(() => {
    const { unit } = offer
    if (unit && Object.keys(unit).length) {
      const {
        unitNumber,
        building: {
          project: {
            projectName
          }
        }
      } = unit

      setUnitNumber(unitNumber)
      setProjectName(projectName)
      setUnit(unit)
    }
  }, [offer])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const smallDevice = (window.matchMedia(standardStyle.mediaqueries.xs).matches ||
  window.matchMedia(standardStyle.mediaqueries.sm).matches)

  return (
    <PaymentSuccessWrapper>
      <PaymentSuccessTextWrapper xs={24} sm={24} lg={12} md={12}>
        <SuccessPaymentTextWrapper delay={0}>
          <Col span={24}>
            <span className='text-2xl'>Congratulations!</span>
          </Col>
        </SuccessPaymentTextWrapper>

        <Row style={{ marginTop: 50 }}>
          <Col>
            <SuccessPaymentTextWrapper delay={1}>
              <Text>Now,</Text>
            </SuccessPaymentTextWrapper>
          </Col>
          <Col>
            <SuccessPaymentTextWrapper
              style={{ marginLeft: 7 }}
              delay={2}
            >
              <Text>you're</Text>
            </SuccessPaymentTextWrapper>
          </Col>
          <Col>
            <SuccessPaymentTextWrapper
              style={{ marginLeft: 7 }}
              delay={2.2}
            >
              <Text className='font-bold'>officially</Text>
            </SuccessPaymentTextWrapper>
          </Col>
          <Col>
            <SuccessPaymentTextWrapper
              style={{ marginLeft: 7 }}
              delay={3}
            >
              <Text>the owner of</Text>
            </SuccessPaymentTextWrapper>
          </Col>

        </Row>
        <Row>
          <Col>
            <SuccessPaymentTextWrapper delay={5}>
              <Text>{unitNumber}</Text>
            </SuccessPaymentTextWrapper>
          </Col>
          <Col>
            <SuccessPaymentTextWrapper
              style={{ marginLeft: 7 }}
              delay={7}
            >
              {
                projectName && (
                  <Text className='font-bold'>{`at ${projectName}`}</Text>
                )
              }
            </SuccessPaymentTextWrapper>
          </Col>
        </Row>
        <Row style={{ marginTop: 30 }}>
          <SuccessPaymentTextWrapper
            delay={9}
          >
            <TButton
              onClick={() => executeOnFinish()}
              className={doneButtonClassName}
            >
              Done!
            </TButton>
          </SuccessPaymentTextWrapper>
        </Row>
      </PaymentSuccessTextWrapper>
      <PaymentSuccessPlantImageWrapper xs={24} sm={24} lg={12} md={12}>
        <SuccessPaymentTextWrapper
          delay={5}
          style={{ width: smallDevice ? '100%' : '80%' }}
        >
          <UnitSummarizedData
            unit={unit}
          />
        </SuccessPaymentTextWrapper>
      </PaymentSuccessPlantImageWrapper>
    </PaymentSuccessWrapper>
  )
}

export default withRouter(OfferPaymentSuccess)

OfferPaymentSuccess.propTypes = {
  offer: propTypes.shape({
    unit: propTypes.shape({
      unitNumber: propTypes.string,
      building: propTypes.shape({
        project: propTypes.shape({
          projectName: propTypes.string,
          totalDollarValueCurrencyType: propTypes.string
        })
      })
    }),
    totalCost: propTypes.string,
    options: propTypes.string
  }),
  executeOnFinish: propTypes.func,
  doneButtonClassName: propTypes.string,
  unit: propTypes.shape({
    unitNumber: propTypes.string,
    building: propTypes.shape({
      project: propTypes.shape({
        projectName: propTypes.string,
        totalDollarValueCurrencyType: propTypes.string
      })
    })
  })
}

Text.propTypes = {
  children: propTypes.node,
  className: propTypes.string
}

SuccessPaymentTextWrapper.propTypes = {
  style: propTypes.object,
  delay: propTypes.number,
  children: propTypes.node
}
