import React, { useCallback } from 'react'
import propTypes from 'prop-types'
import { AdminPageWrapper, PageHeader, ProjectOpportunities } from 'components'
import { TFilledPlusIcon } from 'components/Icons'
import { useHistory } from 'react-router-dom'
import { ADMIN_OPPORTUNITY } from 'routes'
import { connect } from 'react-redux'

import { isMobile } from 'react-device-detect'
import CustomButton from 'components/CustomButton'

const Button = (props) => {
  return (
    <button className='text-softBlack text-center text-base font-medium py-1.5 flex items-center'
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  )
}

const Opportunities = (props) => {
  const { appProject } = props
  const history = useHistory()

  const createOpportunity = useCallback(() => {
    history.push({
      pathname: ADMIN_OPPORTUNITY,
      state: {
        projectId: appProject
      }
    })
  }, [appProject, history])

  const RightContent = () => (
    <div className='flex flex-row items-center'>
      <CustomButton
        variant='ghost'
        className='w-fit'
        handleClick={createOpportunity}
        disabled={!appProject}
      >
        <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
          <TFilledPlusIcon className='h-6 w-6' />
          {isMobile ? 'Create' : 'Create Opportunity'}
        </span>
      </CustomButton>
    </div>
  )

  return (
    <>
      <PageHeader
        title='Opportunities'
        rightContent={<RightContent />}
      />
      <AdminPageWrapper style={{ height: 'calc(100vh - 100px)' }} fullscreen>
        <ProjectOpportunities />
      </AdminPageWrapper>
    </>
  )
}

const mapStateToProps = (state) => ({
  appProject: state.appReducer.appProject
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Opportunities)

Opportunities.propTypes = {
  appProject: propTypes.string
}

Button.propTypes = {
  disabled: propTypes.bool,
  onClick: propTypes.func,
  children: propTypes.node
}
