// @ts-nocheck
import AllocateInventoryHeader from './Header'
import React, { useState } from 'react'
import Building from './Steps/Building'
import Receiver from './Steps/Receiver'
import Method from './Steps/Method'
import Brokers from './Steps/Brokers'
import Inventory from './Steps/Inventory'
import { AllocationMethods, InventoryReceiverOptions, steps } from './utils'
import Review from './Steps/Review'
import { ALLOCATIONS } from 'routes'
import { useHistory } from 'react-router-dom'
import { reserveUnitsToBroker } from 'store/actions/unitActions'
import AllocationProgressSteps from './Steps/AllocationProgressSteps'

const iconStyle = {
  width: '20px',
  height: '20px'
}
const ActiveIconStyle = {
  width: '20px',
  height: '20px',
  opacity: '0.3'
}

const CreateInventoryAllocation = () => {
  const history = useHistory()
  const [step, setStep] = useState(0)
  const [stepsState, setSteps] = useState(steps)
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState({
    building: '',
    receiver: '',
    method: '',
    broker: '',
    inventory: '',
    duration: 1
  })

  const handleChange = (newValue, field) => {
    setValue({ ...value, [field]: newValue })
  }

  const onCreateOffer = () => {
    history.push(ALLOCATIONS)
  }

  const handleSubmit = () => {
    const selectedBrokers = value.broker
      .filter((broker) => broker.isActive)
      .map(({ _id }) => _id)
    const selectedUnits = value.inventory.map(({ _id }) => _id)
    const brokerMaxReservationDays = value.duration
    reserveUnitsToBroker(
      selectedBrokers,
      selectedUnits,
      brokerMaxReservationDays
    )
      .then(() => {
        setLoading(true)
      })
      .catch((error) => console.log(error))
  }

  return (
    <div>
      <AllocateInventoryHeader />
      <div className='px-8 pt-33.25 pb-8 flex gap-8'>
        <AllocationProgressSteps
          steps={stepsState}
          currentStepIndex={step}
          className='p-7'
          iconStyle={iconStyle}
          value={value}
          ActiveIconStyle={ActiveIconStyle}
        />
        <div className='w-full' style={{ marginLeft: '20rem' }}>
          <div
            className='bg-white rounded p-6 grow w-full overflow-auto'
            style={{ maxHeight: 'calc(100vh - 166px)', minHeight: 'calc(100vh - 166px)' }}
          >
            {step === 0 && (
              <Building
                // @ts-ignore
                selected={value.building?._id}
                onSelect={(building) => handleChange(building, 'building')}
                setStep={setStep}
              />
            )}
            {step === 1 && (
              <Receiver
                options={InventoryReceiverOptions}
                selected={value.receiver}
                onSelect={(receiver) => handleChange(receiver, 'receiver')}
                setStep={setStep}
                stepsState={stepsState}
                setSteps={setSteps}
              />
            )}
            {step === 2 && value.receiver !== 'Buyers' && (
              <Method
                options={AllocationMethods}
                selected={value.method}
                onSelect={(method) => handleChange(method, 'method')}
                setStep={setStep}
              />
            )}
            {((value.receiver === 'Buyers' && step === 2) || (value.receiver === 'Brokers' && step === 3)) && (
              <Brokers
                receiver={value.receiver}
                setStep={setStep}
                method={
                  value.receiver === 'Brokers'
                    ? value.method?.split(' ')[0]
                    : 'Individual'
                }
                onNextStep={(brokers) => handleChange(brokers, 'broker')}
                setSteps={setSteps}
                stepsInitialState={steps}
              />
            )}
            {((value.receiver === 'Buyers' && step === 3) || (value.receiver === 'Brokers' && step === 4)) &&
              (
              <Inventory
                value={value.inventory}
                setStep={setStep}
                onNextPage={(units) => handleChange(units, 'inventory')}
              />
              )}
              {((value.receiver === 'Buyers' && step === 4) || (value.receiver === 'Brokers' && step === 5)) &&
              (<Review
                value={value}
                setStep={setStep}
                setValue={setValue}
                onCreateOffer={onCreateOffer}
                handleSubmit={handleSubmit}
                loading={loading}
                receiver={value.receiver === 'Buyers' ? 'Buyer' : 'Broker'}
              />
              )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateInventoryAllocation
