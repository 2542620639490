import React, { useCallback, useEffect, useState } from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { InputLabel } from 'ui'
import {
  PageWrapper,
  BuyersWrapper,
  BuyerWrapper,
  LoadingWrapper,
  ConfirmSelectionWrapper,
  CancelSelectionWrapper,
  OptionsContainer,
  YesNoOptionWrapper,
  BrokerCommissionsWrapper
} from './styles'

import {
  LoadingIcon,
  UserIcon,
  AddUserIcon,
  CloseIcon,
  CheckIcon,
  FadedAnimatedDiv,
  OfferFooterButtons
} from 'components'
import { AnimatePresence } from 'framer-motion'
import {
  createAssignment,
  getProjectBrokers,
  removeBrokerFromAssignment,
  updateAssignment
} from 'store/actions/usersActions'
import TableFilter from 'components/TableFilter'
import { createOfferChangeBroker, createOfferChangeBrokerCommissions } from 'store/actions/offerActions'
import {
  classNames,
  isObject,
  pluralWords,
  validateRequiredInputs
} from 'utils'
import useUpdateUsers from 'hooks/useUpdateUsers'

const BUYER_HAS_BROKER = 'buyer_has_broker'
const LOADING_BROKERS = 'loading_brokers'
const SHOWING_BROKERS = 'showing_brokers'
const CREATING_BROKER = 'creating_broker'

const confirmationButtonsVariants = {
  initial: {
    x: 1000
  },
  confirmButtonEnter: {
    x: '20%'
  },
  cancelButtonEnter: (custom) => ({
    x: '-120%',
    transition: {
      delay: custom,
      x: { type: 'spring', stiffness: 265, damping: 30 }
    }
  }),
  exit: (custom) => ({
    x: 1000,
    transition: { delay: custom }
  })
}

const FormStatusWrapper = ({ key, children, className }) => (
  <FadedAnimatedDiv
    key={key}
    className={classNames('w-full h-full py-11 flex flex-col', className)}
  >
    {children}
  </FadedAnimatedDiv>
)

const BrokerComponent = ({
  brokerData: { _id, firstName, lastName, email, brokerData },
  selectedBuyers,
  appProject,
  selectBroker,
  onClick,
  creatingUpdatingAssignments,
  editingId
}) => {
  const [showConfirmationButton, setShowConfirmationButton] = useState(false)

  let licenseNumber = ''
  let licenseBrokerage = ''
  let licenseExpiration = ''

  if (isObject(brokerData) && Object.keys(brokerData).length) {
    licenseNumber = brokerData.licenseNumber
    licenseBrokerage = brokerData.licenseBrokerage
    licenseExpiration = brokerData.licenseExpiration
  }

  const openConfirmationButtons = (e) => {
    setShowConfirmationButton(true)
  }

  const closeConfirmationButtons = (e) => {
    e.stopPropagation()

    setShowConfirmationButton(false)
  }

  const _selectBroker = (e) => {
    e.stopPropagation()

    // Will change the broker on reducer, but it will keep on the page to inform broker comission
    selectBroker({ _id, firstName, lastName, email, licenseNumber, licenseBrokerage, licenseExpiration })
  }

  return (
    <div
      className={classNames(
        `w-full ${_id.length <= 2 && 'sticky top-0 z-50 bg-white'}`
      )}
    >
      <BuyerWrapper
        id={_id}
        onClick={onClick ? () => onClick() : () => openConfirmationButtons()}
        className={classNames('h-full', _id.length <= 2 ? 'bg-indigo-600 text-white' : 'bg-white')}
      >
        {_id === '0'
          ? (
          <AddUserIcon style={{ fontSize: 35, marginBottom: 10 }} />
            )
          : (
          <UserIcon style={{ fontSize: 35, marginBottom: 10 }} />
            )}

        <span>{`${firstName} ${lastName}`}</span>
        <span>{email}</span>

        {(showConfirmationButton ||
          (creatingUpdatingAssignments && editingId === _id)) && (
          <>
            <ConfirmSelectionWrapper
              key="confirmButton"
              initial="initial"
              animate="confirmButtonEnter"
              exit="exit"
              variants={confirmationButtonsVariants}
              transition={{ duration: 0.2 }}
              custom={0}
              onClick={_selectBroker}
            >
              <AnimatePresence exitBeforeEnter initial={false}>
                {creatingUpdatingAssignments
                  ? (
                  <FadedAnimatedDiv key="updating">
                    <LoadingIcon className="h-9 w-9 text-white" />
                  </FadedAnimatedDiv>
                    )
                  : (
                  <FadedAnimatedDiv key="icon">
                    <CheckIcon style={{ fontSize: 30 }} />
                  </FadedAnimatedDiv>
                    )}
              </AnimatePresence>

              {`Confirm ${firstName} as a Broker?`}
              <br />
              {`${
                selectedBuyers.length > 1
                  ? 'All the selected buyers of the offer'
                  : selectedBuyers[0].firstName
              } will be reassigned to ${firstName} as it's personal broker.`}
            </ConfirmSelectionWrapper>

            <CancelSelectionWrapper
              key="cancelButton"
              initial="initial"
              animate="cancelButtonEnter"
              exit="exit"
              variants={confirmationButtonsVariants}
              transition={{ duration: 0.2 }}
              onClick={closeConfirmationButtons}
              custom={0.01}
            >
              <CloseIcon style={{ fontSize: 30 }} />
              Cancel
            </CancelSelectionWrapper>
          </>
        )}
      </BuyerWrapper>
    </div>
  )
}

const HasBrokerOption = ({ title, subtitle, onClick, loading }) => (
  <YesNoOptionWrapper
    className={`flex flex-col items-center justify-center shadow py-2.5 px-5 
    rounded mx-2.5 text-center w-1/4 border hover:cursor-pointer`}
    onClick={onClick}
  >
    <AnimatePresence exitBeforeEnter initial={false}>
      {loading
        ? (
        <FadedAnimatedDiv key="brokerOptionUpdating">
          <LoadingIcon className="h-9 w-9" />
        </FadedAnimatedDiv>
          )
        : (
        <FadedAnimatedDiv key="optionDescription" className="contents">
          <span className="text-lg">{title}</span>
          <span className="text-base">{subtitle}</span>
        </FadedAnimatedDiv>
          )}
    </AnimatePresence>
  </YesNoOptionWrapper>
)

const Broker = (props) => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [licenseNumber, setLicenseNumber] = useState('')
  const [licenseBrokerage, setLicenseBrokerage] = useState('')
  const [licenseExpiration, setLicenseExpiration] = useState('')

  const [formStatus, setFormStatus] = useState(BUYER_HAS_BROKER)
  const [originalBrokers, setOriginalBrokers] = useState([])
  const [brokers, setBrokers] = useState(originalBrokers)
  const [removingBrokerFromAssignment, setRemovingBrokerFromAssignment] =
    useState(false)
  const [creatingNewBroker, setCreatingNewBroker] = useState(false)
  const [creatingUpdatingAssignments, setCreatingUpdatingAssignments] =
    useState(false)
  const [editingId, setEditingId] = useState('')
  const [brokerCommissionFirstRange, setBrokerCommissionFirstRange] = useState(0)
  const [brokerCommissionSecondRange, setBrokerCommissionSecondRange] = useState(0)
  const { createOrUpdateUser } = useUpdateUsers()
  const {
    executeOnSelectBroker,
    selectedBuyers,
    selectedBroker,
    createOfferChangeBroker,
    createOfferChangeBrokerCommissions,
    appProject,
    appDeveloper,
    brokerCommissionFirstRange: reducerCommissionFirstRange,
    brokerCommissionSecondRange: reducerCommissionSecondRange
  } = props

  const createOrUpdateBuyerAssignments = useCallback(
    (brokerId) =>
      new Promise((resolve, reject) => {
        const allUpdates = selectedBuyers.map(async (buyer) => {
          let projectAssignment = []
          setCreatingUpdatingAssignments(true)

          if (
            buyer.buyerData &&
            Object.keys(buyer.buyerData).length &&
            buyer.buyerData.assignments &&
            buyer.buyerData.assignments.length
          ) {
            projectAssignment = buyer.buyerData.assignments.filter(
              (assignment) => assignment.project === appProject
            )
          }

          if (projectAssignment.length) {
            const params = { ...projectAssignment[0] }
            params.broker = brokerId
            params.buyer = buyer.email

            if (params.developerAdmin && isObject(params.developerAdmin)) {
              params.developerAdmin = params.developerAdmin._id
            }

            await updateAssignment(params).catch(() => {
              setCreatingUpdatingAssignments(false)
              reject()
            })
          } else {
            const newAssignment = {
              project: appProject,
              buyer: buyer.email,
              broker: brokerId
            }

            await createAssignment(newAssignment).catch(() => {
              setCreatingUpdatingAssignments(false)
              reject()
            })
          }
        })

        Promise.all(allUpdates).then(() => {
          setCreatingUpdatingAssignments(false)
          resolve()
        })
      }),
    [selectedBuyers, appProject]
  )

  const updateBuyerAssignments = useCallback((props) => new Promise((resolve, reject) => {
    setEditingId(props._id)

    createOrUpdateBuyerAssignments(props._id)
      .then(() => resolve())
      .catch(() => reject())
  }), [createOrUpdateBuyerAssignments])

  const selectBroker = useCallback(
    (props) => {
      executeOnSelectBroker({
        broker: { ...props },
        brokerCommissionFirstRange,
        brokerCommissionSecondRange
      })
    },
    [executeOnSelectBroker, brokerCommissionFirstRange, brokerCommissionSecondRange]
  )

  const updateAssignmentsAndGoToNextStep = useCallback(props => {
    updateBuyerAssignments(props)
      .then(() => {
        selectBroker(props)
      })
  }, [selectBroker, updateBuyerAssignments])

  const _getBrokers = useCallback(() => {
    setFormStatus(LOADING_BROKERS)

    getProjectBrokers(appProject)
      .then((brokers) => {
        setOriginalBrokers(brokers)
        setBrokers(brokers)
        setFormStatus(SHOWING_BROKERS)
      })
      .catch(() => {
        setFormStatus(SHOWING_BROKERS)
      })
  }, [appProject])

  // useEffect(() => {
  //   _getBrokers()
  // }, [_getBrokers])

  // When mounted, check if has a broker filled. If so, show the data
  useEffect(() => {
    if (Object.keys(selectedBroker).length && selectedBroker.id !== '0') {
      const { firstName, lastName, email, licenseNumber, licenseBrokerage, licenseExpiration } = selectedBroker

      setFirstName(firstName)
      setLastName(lastName)
      setEmail(email)
      setLicenseNumber(licenseNumber)
      setLicenseBrokerage(licenseBrokerage)
      setLicenseExpiration(licenseExpiration)

      setFormStatus(CREATING_BROKER)
    } else {
      setFormStatus(BUYER_HAS_BROKER)
    }
  }, [selectedBroker])

  useEffect(() => {
    setBrokerCommissionFirstRange(reducerCommissionFirstRange)
    setBrokerCommissionSecondRange(reducerCommissionSecondRange)
  }, [reducerCommissionFirstRange, reducerCommissionSecondRange])

  const Brokers = useCallback(
    () => (
      <div className="flex flex-col w-full h-full">
        <BuyersWrapper className="grid grid-cols-2 w-full overflow-y-auto gap-3 pb-3 px-3">
          <BrokerComponent
            brokerData={{
              _id: '0',
              firstName: 'Create New',
              lastName: 'Broker'
            }}
            selectedBuyers={selectedBuyers}
            appProject={appProject}
            onClick={() => setFormStatus(CREATING_BROKER)}
            creatingUpdatingAssignments={creatingUpdatingAssignments}
            editingId={editingId}
          />
          {brokers.map((broker, index) => (
            <BrokerComponent
              brokerData={broker}
              key={index}
              selectedBuyers={selectedBuyers}
              appProject={appProject}
              selectBroker={(props) => updateBuyerAssignments(props).then(() => {
                createOfferChangeBroker(props)
              })}
              creatingUpdatingAssignments={creatingUpdatingAssignments}
              editingId={editingId}
            />
          ))}
        </BuyersWrapper>
      </div>
    ),
    [
      appProject,
      brokers,
      selectedBuyers,
      creatingUpdatingAssignments,
      editingId,
      createOfferChangeBroker,
      updateBuyerAssignments
    ]
  )

  const createNewBrokerAndSelect = useCallback(async () => {
    let brokerId = selectedBroker.id || selectedBroker._id

    if (!brokerId) {
      await validateRequiredInputs('new_broker')

      setCreatingNewBroker(true)

      const params = {
        email,
        firstName,
        lastName,
        userType: 'CoopBroker',
        brokerData: {
          developerCompany: [appDeveloper],
          licenseNumber,
          licenseBrokerage,
          licenseExpiration
        }
      }

      try {
        const createdUser = await createOrUpdateUser(params, false, false)

        brokerId = createdUser._id

        await createOrUpdateBuyerAssignments(brokerId)
      } catch (e) {
        setCreatingNewBroker(false)
      }

      setCreatingNewBroker(false)
    }

    updateAssignmentsAndGoToNextStep({
      id: brokerId,
      firstName,
      lastName,
      email,
      licenseNumber,
      licenseBrokerage,
      licenseExpiration
    })

    setTimeout(() => {
      setFirstName('')
      setLastName('')
      setEmail('')
      setLicenseNumber('')
      setLicenseBrokerage('')
      setLicenseExpiration('')
    }, 1000)
  }, [
    email,
    firstName,
    lastName,
    appDeveloper,
    createOrUpdateBuyerAssignments,
    selectedBroker,
    licenseNumber,
    licenseBrokerage,
    licenseExpiration,
    updateAssignmentsAndGoToNextStep
  ])

  const setBuyerSelfRepresented = useCallback(() => {
    setRemovingBrokerFromAssignment(true)

    const allBuyers = selectedBuyers.map(async (buyer) => {
      await removeBrokerFromAssignment(buyer.email, appProject)
    })

    // For all selected buyers, remove the broker assignment for this project
    Promise.all(allBuyers)
      .then(() => {
        setRemovingBrokerFromAssignment(false)

        updateAssignmentsAndGoToNextStep({
          id: '0',
          firstName: pluralWords(selectedBuyers.length, 'Buyer'),
          lastName: 'Represented by the Sales Rep',
          licenseNumber: '',
          licenseBrokerage: '',
          licenseExpiration: ''
        })
      })
      .catch(() => {
        setRemovingBrokerFromAssignment(false)
      })
  }, [selectedBuyers, updateAssignmentsAndGoToNextStep, appProject])

  const clearBroker = () => {
    createOfferChangeBroker({})
    createOfferChangeBrokerCommissions({
      brokerCommissionFirstRange: '',
      brokerCommissionSecondRange: ''
    })
  }

  return (
    <PageWrapper className="h-full w-full">
      <AnimatePresence exitBeforeEnter initial={false}>
        {formStatus === BUYER_HAS_BROKER && (
          <FormStatusWrapper key="buyerHasBroker">
            {!selectedBuyers.length ||
            (selectedBuyers.length === 1 && selectedBuyers[0]._id === -1)
              ? (
              <>
                <span className="text-2xl text-center w-full mb-5">
                  Select Broker
                </span>
                <span className="text-lg text-center">
                  Please, select the Buyer
                </span>
                <span className="text-lg text-center">
                  Then you will be able to select the Broker
                </span>
              </>
                )
              : (
              <>
                <span className="text-2xl text-center w-full">{`${pluralWords(
                  selectedBuyers.length,
                  'Buyer'
                )} ${
                  selectedBuyers.length > 1 ? 'have' : 'has'
                } a Broker?`}</span>
                <OptionsContainer justify="center">
                  <HasBrokerOption
                    title="Yes"
                    subtitle={`${pluralWords(selectedBuyers.length, 'Buyer')} ${
                      selectedBuyers.length > 1 ? 'have' : 'has'
                    } a Broker`}
                    onClick={() => _getBrokers()}
                  />
                  <HasBrokerOption
                    title="No"
                    subtitle={`${pluralWords(
                      selectedBuyers.length,
                      'Buyer'
                    )} will be represented by Sales Rep`}
                    onClick={() => setBuyerSelfRepresented()}
                    loading={
                      creatingUpdatingAssignments ||
                      removingBrokerFromAssignment
                    }
                  />
                </OptionsContainer>
              </>
                )}
          </FormStatusWrapper>
        )}

        {formStatus === LOADING_BROKERS && (
          <FormStatusWrapper key="loadingBrokers">
            <LoadingWrapper>
              <span className="text-2xl text-center w-full mb-5">
                Getting Brokers...
              </span>
              <LoadingIcon className="h-9 w-9" />
            </LoadingWrapper>
          </FormStatusWrapper>
        )}

        {formStatus === SHOWING_BROKERS && (
          <FormStatusWrapper key="tableFilterWrapper" className="items-center">
            <span className="text-2xl text-center w-full">Select Broker</span>

            <div className="w-4/6 my-5">
              <TableFilter
                dataToFilter={originalBrokers}
                setData={setBrokers}
                placeHolder="You can filter the brokers below by spacing words"
                loading={formStatus === LOADING_BROKERS}
              />
            </div>

            <Brokers />

            <OfferFooterButtons
              backButtonClick={() => setFormStatus(BUYER_HAS_BROKER)}
            />
          </FormStatusWrapper>
        )}

        {formStatus === CREATING_BROKER && (
          <FormStatusWrapper key="newBroker">
            <span className="text-2xl text-center w-full mb-5">
              {`${
                Object.keys(selectedBroker).length && selectedBroker.id !== '0'
                  ? 'Selected'
                  : 'New'
              } Broker`}
            </span>
            <div className="flex flex-col w-full h-3/4 overflow-y-auto items-center">
              <div className="space-y-2 w-2/5">
                <InputLabel
                  id="firstName"
                  name="firstName"
                  key="firstName"
                  label="Legal First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder={"broker's first name"}
                  required
                  validateKey="new_broker"
                  disabled={
                    Object.keys(selectedBroker).length &&
                    selectedBroker.id !== '0'
                  }
                />
                <InputLabel
                  id="lastName"
                  name="lastName"
                  key="lastName"
                  label="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder={"broker's last name"}
                  required
                  validateKey="new_broker"
                  disabled={
                    Object.keys(selectedBroker).length &&
                    selectedBroker.id !== '0'
                  }
                />
                 <InputLabel
                  id="email"
                  name="email"
                  key="email"
                  label="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder={"broker's email"}
                  required
                  validateKey="new_broker"
                  disabled={
                    Object.keys(selectedBroker).length &&
                    selectedBroker.id !== '0'
                  }
                />

                <InputLabel
                  id="licenseNumber"
                  name="licenseNumber"
                  key="licenseNumber"
                  label="License number"
                  value={licenseNumber}
                  onChange={(e) => setLicenseNumber(e.target.value)}
                  placeholder="license number"
                  required
                  validateKey="new_broker"
                  disabled={
                    Object.keys(selectedBroker).length &&
                    selectedBroker.id !== '0'
                  }
                />
                <InputLabel
                  id="licenseBrokerage"
                  name="licenseBrokerage"
                  key="licenseBrokerage"
                  label="License brokerage"
                  value={licenseBrokerage}
                  onChange={(e) => setLicenseBrokerage(e.target.value)}
                  placeholder="license brokerage"
                  required
                  validateKey="new_broker"
                  disabled={
                    Object.keys(selectedBroker).length &&
                    selectedBroker.id !== '0'
                  }
                />
                <InputLabel
                  id="licenseExpiration"
                  name="licenseExpiration"
                  key="licenseExpiration"
                  label="License expiration"
                  value={licenseExpiration}
                  onChange={(e) => setLicenseExpiration(e)}
                  placeholder="license expiration"
                  typeInput="datepicker"
                  required
                  validateKey="new_broker"
                  disabled={
                    Object.keys(selectedBroker).length &&
                    selectedBroker.id !== '0'
                  }
                />
              </div>
              <BrokerCommissionsWrapper className="mt-10 flex flex-col">
                <span className="text-xl">Commission</span>

                <span className="text-base">
                  Broker will receive
                  <InputLabel
                    id="brokerCommissionFirstRange"
                    key="brokerCommissionFirstRange"
                    typeInput="numberinput"
                    value={brokerCommissionFirstRange}
                    onChange={(e) => setBrokerCommissionFirstRange(e.value)}
                    inputWrapperClassName="ml-3 mr-1 max-w-12 inline-flex h-6"
                  />
                  % on the first $100,000 and
                  <InputLabel
                    id="brokerCommissionSecondRange"
                    key="brokerCommissionSecondRange"
                    value={brokerCommissionSecondRange}
                    typeInput="numberinput"
                    onChange={(e) => setBrokerCommissionSecondRange(e.value)}
                    inputWrapperClassName="ml-3 mx-1 max-w-12 inline-flex h-6"
                  />
                  % on balance.
                </span>
              </BrokerCommissionsWrapper>
            </div>
            <OfferFooterButtons
              backButtonText={
                Object.keys(selectedBroker).length && selectedBroker.id !== '0'
                  ? 'Clear Broker'
                  : 'Back'
              }
              backButtonClick={() => {
                setFirstName('')
                setLastName('')
                setEmail('')
                setLicenseNumber('')
                setLicenseBrokerage('')
                setLicenseExpiration('')

                if (
                  Object.keys(selectedBroker).length &&
                  selectedBroker.id !== '0'
                ) {
                  clearBroker()
                } else {
                  setFormStatus(SHOWING_BROKERS)
                }
              }}
              continueButtonClick={() => createNewBrokerAndSelect()}
              continueButtonLoading={creatingNewBroker}
            />
          </FormStatusWrapper>
        )}
      </AnimatePresence>
    </PageWrapper>
  )
}

const mapStateToProps = (state) => ({
  selectedBuyers: state.createOfferReducer.selectedBuyers,
  selectedBroker: state.createOfferReducer.selectedBroker,
  appProject: state.appReducer.appProject,
  appDeveloper: state.appReducer.appDeveloper,
  brokerCommissionFirstRange: state.createOfferReducer.brokerCommissionFirstRange,
  brokerCommissionSecondRange: state.createOfferReducer.brokerCommissionSecondRange
})

const mapDispatchToProps = {
  createOfferChangeBroker,
  createOfferChangeBrokerCommissions
}

export default connect(mapStateToProps, mapDispatchToProps)(Broker)

Broker.propTypes = {
  _id: propTypes.string,
  executeOnSelectBroker: propTypes.func,
  selectedBuyers: propTypes.array,
  selectedBroker: propTypes.object,
  createOfferChangeBroker: propTypes.func,
  createOfferChangeBrokerCommissions: propTypes.func,
  appProject: propTypes.string,
  appDeveloper: propTypes.string,
  brokerCommissionFirstRange: propTypes.number,
  brokerCommissionSecondRange: propTypes.number
}

FormStatusWrapper.propTypes = {
  key: propTypes.string,
  children: propTypes.string,
  className: propTypes.string
}

BrokerComponent.propTypes = {
  brokerData: propTypes.shape({
    _id: propTypes.string,
    firstName: propTypes.string,
    lastName: propTypes.string,
    email: propTypes.string,
    brokerData: propTypes.shape({
      licenseNumber: propTypes.string,
      licenseBrokerage: propTypes.string,
      licenseExpiration: propTypes.string
    })
  }),
  selectedBuyers: propTypes.array,
  appProject: propTypes.string,
  selectBroker: propTypes.object,
  onClick: propTypes.func,
  creatingUpdatingAssignments: propTypes.bool,
  editingId: propTypes.string
}

HasBrokerOption.propTypes = {
  title: propTypes.string,
  subtitle: propTypes.string,
  onClick: propTypes.func,
  loading: propTypes.bool
}
