import React from 'react'
import NumberFormat from 'react-number-format'
import getSymbolFromCurrency from 'currency-symbol-map'

const CURRENCY_LOCALSTORAGE_KEY = 'pxp.project.currency'
const DEFAULT_CURRENCY_TYPE = 'USD$'

export const changeCurrencyType = (currencyType) => {
  const symbol = getSymbolFromCurrency(currencyType)
  localStorage.setItem(
    CURRENCY_LOCALSTORAGE_KEY,
    `${currencyType}${symbol ?? ''}` ?? DEFAULT_CURRENCY_TYPE
  )
}
export const getCurrenyType = () =>
  localStorage.getItem(CURRENCY_LOCALSTORAGE_KEY) ?? DEFAULT_CURRENCY_TYPE

// Format dollar
export const formatMoney = (
  value,
  currencyType = getCurrenyType(),
  showNoCostString = false
) => {
  let result = (
    <NumberFormat
      value={value}
      displayType='text'
      prefix={currencyType}
      thousandSeparator
    />
  )

  if (!value && showNoCostString) {
    result = <span>No additional cost</span>
  }
  return result
}

// Capitalize words
export const capitalizeWords = (value) =>
  value.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })

// Plural Words
export const pluralWords = (validator, word) =>
  `${word}${validator > 1 ? 's' : ''}`

export const cadFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0
})
export const formatValue = (number) => {
  return new Intl.NumberFormat('en-US').format(number)
}
