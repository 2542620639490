import styled from 'styled-components'
import standardStyle from 'assets/css/standardStyle'

const backgroundColor = 'white'

const sizes = {
  large: '18px',
  medium: '16px',
  small: '12px'
}

export const LineWrapper = styled.div`
  display: flex;
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 2px dotted ${standardStyle.colors.primary};
  font-size: ${props => sizes[props.size]};
  /* font-weight: ${props => props.size === 'large' ? '800' : '400'}; */
  line-height: 1.4;
  color: ${props => props.color || 'inherit'};

  & ~ div {
    /* margin-top:5px; */
  }

  span {
    background: ${props => props.bgColor || backgroundColor};
    display: inline-block;
    transform: translateY(7px);
  }
`

export const RightText = styled.div`
  margin-right: 0;
  margin-left:auto;
`

export const SkeletonLineWrapper = styled.div`
  margin: 7.5px 0px 5px 0px;
`
