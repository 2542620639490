import React from 'react'
// @ts-ignore
import { getCustomForCustomField, SUPPORTED_CUSTOM_FIELDS } from './setting'

const Component = React.forwardRef((props, ref) => {
  // @ts-ignore
  const { data, defaultValue, disabled } = props
  const { name, key } = data
  return <div>
    <label htmlFor={name}>{name}</label>
    <input
      ref={ref}
      type='text'
      name={key}
      defaultValue={defaultValue}
      disabled={disabled}
    />
  </div>
})
const field = SUPPORTED_CUSTOM_FIELDS.FIRST_NAME
const FirstNameConfig = getCustomForCustomField(field, Component, {
  icon: 'fa fa-user',
  props: {}
})
export default FirstNameConfig
