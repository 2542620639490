import { useQuery } from '@tanstack/react-query'
import { getCustomForm, submitCustomForm, getCustomFormSubmissions, getCustomForms, updateCustomForm, createCustomForm } from 'store/actions/customFormActions'

export const useGetCustomForms = ({
  projectId,
  isCorporate
}) => {
  const { data, isLoading, refetch } = useQuery(
    ['get-custom-forms'],
    () => getCustomForms(!isCorporate ? projectId : ''),
    { refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false, retry: false }
  )
  return { forms: data ?? null, isLoading, refetch }
}

export const useCustomForms = () => {
  return {
    useGetCustomForms,
    createCustomForm,
    getCustomForm,
    getCustomForms,
    updateCustomForm,
    submitCustomForm,
    getCustomFormSubmissions
  }
}

export default useCustomForms
