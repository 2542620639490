import React from 'react'
import { useFormikContext } from 'formik'

const FormButton = () => {
  const { handleSubmit } = useFormikContext()
  return (
    <div className='flex flex-row justify-end items-center flex-wrap p-4 pb-0 gap-4'>
        <button onClick={() => handleSubmit()} className='flex justify-center items-center p-4 bg-volt rounded gap-2 italic text-black'>Save</button>
    </div>
  )
}

export default FormButton
