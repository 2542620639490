// @ts-nocheck
import React, { useState } from 'react'
import Logo from './Logo'
// import background from '../../assets/img/bg.png'
import backgroundVideo from '../../assets/videos/login-screen-bg-video.mp4'
import { forgotPassword, setResetPassword } from 'store/actions/usersActions'

import { message } from 'antd'
import { useHistory } from 'react-router-dom'
import { LOGIN } from 'routes'
import CustomButton from 'components/CustomButton'

const Input = ({ placeholder, label, value, onChange, defaultValue = '', type = 'text' }) => {
  return (
    <label className='text-xs w-full'>
      <div className='text-xs'>{label}</div>
      <input
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        type={type}
        className='border p-2 px-3 leading-none mt-2 w-full placeholder:text-gray-400'
      />
    </label>
  )
}

const AuthScreenBgVideo = () => (
  <div className='absolute z-[-1] top-0 left-0 w-screen h-screen'>
    <video
      src={backgroundVideo}
      className='w-full h-full object-cover'
      autoPlay
      muted
      loop
    />
  </div>
)

const AuthScreenWrapper = ({ children }) => (
  <div className='relative h-screen w-100 flex'>
    <div className='p-4 md:p-20 h-screen w-full md:w-[700px] flex items-center'>
      <div className='py-20 px-4 md:py-32 md:px-18 bg-stone-100 w-full h-fit flex flex-col justify-center items-center rounded-lg shadow-lg gap-12'>
        <Logo />
        <div className='w-full flex-1'>
          {children}
        </div>
      </div>
      <AuthScreenBgVideo />
    </div>
  </div>
)

export const SignUpPage = ({
  firstName = { onChange: () => {}, value: '' },
  lastName = { onChange: () => {}, value: '' },
  developmentCompany = { onChange: () => {}, value: '' },
  email = { onChange: () => {}, value: '' },
  password = { onChange: () => {}, value: '' },
  onCreateAccount = () => {},
  onSignInInstead = () => {}
}) => {
  return (
    <AuthScreenWrapper>
      <div className='max-w-md mt-auto'>
        <h1 className='text-2xl font-bold'>Welcome to Aires</h1>
        <p className='text-sm mt-3'>Please enter your details</p>
        <div className='flex flex-col space-y-4 mt-6 text-gray-500'>
          <Input placeholder='Enter first name' label='FIRST NAME *' {...firstName} />
          <Input placeholder='Enter last name' label='LAST NAME *' {...lastName} />
          <Input
            placeholder='Enter development company'
            label='DEVELOPMENT COMPANY *'
            {...developmentCompany}
          />
          <Input type='email' placeholder='Enter email' label='EMAIL *' {...email} />
          <Input
            type='password'
            placeholder='Enter password'
            label='ENTER PASSWORD *'
            {...password}
          />
          <p className='text-[0.7rem] relative bottom-2'>
            * Password must contain at least 6 characters A-Z, including lower and upper case
            and digits.
          </p>
          <button
            className='w-full bg-yellow-200 mt-4 p-3 focus:border-[3px] hover:bg-white hover:text-yellow-400 hover:border-yellow-100 transition-all focus:border-blue-300 text-gray-600 font-sm'
            onClick={onCreateAccount}>
            Create Account
          </button>
        </div>
      </div>
      <div className='mt-auto pt-2'>
        Already have an account? <strong onClick={onSignInInstead}>Sign In</strong>
      </div>
    </AuthScreenWrapper>
  )
}

export const LoginPage = ({
  email = { onChange: () => {}, value: '' },
  password = { onChange: () => {}, value: '' },
  onLoggingIn = () => {},
  loginProgress = false,
  onSignUpInstead = () => {},
  onForgotPassword = () => {},
  isResetPasswordPage = false,
  resetToken = '',
  ...props
}) => {
  const [resetPassword, setResetPasswords] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const history = useHistory()

  const updatePassword = () => {
    // eslint-disable-next-line no-useless-return
    if (resetPassword !== confirmPassword) {
      message.error('Passwords do not match')
      return
    } else if (resetPassword.length === 0) {
      message.error('Please fill all required fields')
      return
    }

    setResetPassword(resetToken, resetPassword).then((response) => {
      if (response?.success) {
        message.success('Password reset successfull')
        setTimeout(() => {
          history.push(LOGIN)
        }, 1000)
      }
    })
  }

  return (
    <AuthScreenWrapper>
      {!isResetPasswordPage
        ? <>
          <h1 className='text-2xl font-bold'>Welcome Back</h1>
          <p className='text-sm mt-3'>Please enter your details</p>
          <div className='flex flex-col space-y-4 mt-6 text-gray-500'>
            <Input type='email' placeholder='Enter email' label='EMAIL *' {...email} />
            <Input
              type='password'
              placeholder='Enter password'
              label='ENTER PASSWORD *'
              {...password}
            />
            <button
              className='text-sm font-bold relative bottom-2 self-end'
              onClick={onForgotPassword}>
              Forgot Password?
            </button>
            <CustomButton handleClick={onLoggingIn} className='w-full !mt-4'>
              <span className='font-medium p-3 text-base flex gap-2 items-center'>
                {loginProgress ? 'Loading...' : 'Sign In'}
              </span>
            </CustomButton>
          </div>
        </>
        : <>
          <h1 className='text-2xl font-bold'>Reset Password</h1>
          <p className='text-sm mt-3'>Please enter your new password</p>
          <div className='flex flex-col space-y-4 mt-6 text-gray-500'>
            <Input
              type='password'
              placeholder='Enter password'
              label='PASSWORD *'
              value={resetPassword}
              onChange={(e) => setResetPasswords(e?.target?.value)}
            />
            <Input
              type='password'
              placeholder='Confirm password'
              label='CONFIRM PASSWORD *'
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e?.target?.value)}
            />
            <CustomButton handleClick={updatePassword} className='w-full !mt-8'>
              <span className='font-medium p-3 text-base flex gap-2 items-center'>
                Update Password
              </span>
            </CustomButton>
          </div>
        </>}
    </AuthScreenWrapper >
  )
}

export const ResetPasswordPage = ({
  onLoggingIn: onSendLink = () => {
    console.log('sending link')
  },
  userEmail = '',
  setRequestingRecoveryPassword,
  onSignInInstead = () => { },
  ...props
}) => {
  const [email, setEmail] = useState(userEmail)
  const [linkSent, setLinkSent] = useState(false)

  const onForgotPassword = () => {
    forgotPassword(email)
      .then(() => {
        setLinkSent((prev) => !prev)
      })
  }
  return (
    <AuthScreenWrapper>
      {!linkSent
        ? (<>
          <button className='flex items-center space-x-2 text-xs font-bold'
            onClick={() => { setRequestingRecoveryPassword(false) }}
          >
            <svg
              width='15'
              height='10.5'
              viewBox='0 0 20 14'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M7.25065 13.4163L8.54315 12.1238L4.34482 7.91634H19.1673V6.08301H4.34482L8.55232 1.87551L7.25065 0.583008L0.833984 6.99967L7.25065 13.4163Z'
                fill='#2E2B2E'
              />
            </svg>
            <span>Sign In</span>
          </button>
          <h1 className='text-2xl font-bold pt-4'>Reset Password</h1>
          <p className='text-sm mt-3'>
            Please enter your email and we'll send you a link for password resetting
          </p>
          <div className='flex flex-col space-y-4 mt-6 text-gray-500'>
            <Input type='email' placeholder='Enter email' label='EMAIL *' defaultValue={userEmail} onChange={(e) => setEmail(e.target.value)} />
            <div>
              <CustomButton handleClick={onForgotPassword} className='w-full !mt-4'>
                <span className='font-medium p-3 text-base flex gap-2 items-center'>
                  Send Link
                </span>
              </CustomButton>
            </div>
          </div>
        </>)
        : (
          <>
            <h1 className='text-2xl font-bold pt-4'>Link Sent</h1>
            <p className='text-sm mt-3'>
              Link was sent to your email <strong>{email}</strong>
            </p>
            <div className='flex flex-col space-y-4 mt-6 text-gray-500'>
              <CustomButton handleClick={() => setRequestingRecoveryPassword(false)} className='w-full !mt-3'>
                <span className='font-medium p-3 text-base flex gap-2 items-center'>
                  Go to Signin
                </span>
              </CustomButton>
            </div>
          </>)}
    </AuthScreenWrapper>
  )
}
