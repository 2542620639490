import React, { useMemo } from 'react'

const TableHead = () => {
  const columns = useMemo(() => ['Name', '', ''], [])
  return (
    <thead className='bg-gray-50'>
      <tr>
        <th scope='col' className='relative w-25 px-6 sm:w-16 sm:px-8'>
          <input
            type='checkbox'
            className='absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6 hover:cursor-pointer'
          />
        </th>
        {columns.map((col, index) => (
          <th
            scope='col'
            className='relative w-25 px-6 sm:w-16 sm:px-8 whitespace-nowrap py-3 pr-6'
            style={{ textAlign: 'left', paddingLeft: 0 }}
            key={index}
          >
            {col}
          </th>
        ))}
      </tr>
    </thead>
  )
}

export default TableHead
