import React, { useCallback } from 'react'
import propTypes from 'prop-types'
import { AdminPageWrapper, PageHeader, ProjectDeposits } from 'components'
import { TFilledPlusIcon } from 'components/Icons'
import { useHistory } from 'react-router-dom'
// import { ADMIN_DEPOSIT_PAYMENT } from 'routes'
import { connect } from 'react-redux'

const Button = (props) => {
  return (
    <button
      className='text-softBlack text-center text-base font-medium py-1.5 px-4 space-x-1.5 flex items-center'
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  )
}

const Deposits = (props) => {
  const { appProject } = props
  const history = useHistory()

  const createOffer = useCallback(() => {
    // history.push({
    //   pathname: ADMIN_OFFER,
    //   state: {
    //     projectId: appProject
    //   }
    // })
  }, [appProject, history])

  const RightContent = () => (
    <div className='flex-row items-center hidden'>
      <Button disabled={!appProject} onClick={() => createOffer()}>
        <TFilledPlusIcon className='h-6 w-6' />
        Create Deposit
      </Button>
    </div>
  )

  return (
    <>
      <PageHeader title='Deposits' rightContent={<RightContent />} />
      <AdminPageWrapper style={{ height: 'calc(100vh - 100px)' }} fullscreen>
        <ProjectDeposits />
      </AdminPageWrapper>
    </>
  )
}

const mapStateToProps = (state) => ({
  appProject: state.appReducer.appProject
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Deposits)

Deposits.propTypes = {
  appProject: propTypes.string
}

Button.propTypes = {
  disabled: propTypes.bool,
  onClick: propTypes.func,
  children: propTypes.node
}
