import { ContactContext } from 'pages/contacts/context/Contacts'
import React, { useMemo, useContext } from 'react'

const TableHead = ({ page }) => {
  const {
    selected,
    setSelected,
    saveList,
    setSaveList,
    filteredContacts: contacts,
    limit,
    listsChecked
  } = useContext(ContactContext)

  const listsFilterApplied = listsChecked && Object.values(listsChecked).some(status => !!status)

  const columns = useMemo(() => listsFilterApplied
    ? ([
        { name: 'Name', property: 'firstName' },
        { name: 'User Type', property: 'userType' },
        { name: 'Email', property: 'email' },
        {
          name: 'Phone Number',
          property: { name: 'buyerData', property: 'phoneNumber' }
        },
        {
          name: 'Sales Rep Owner ',
          property: { name: 'buyerData', property: 'salesRep' }
        },
        {
          name: 'Buyer Score',
          property: { name: 'buyerData', property: 'rating' }
        },
        {
          name: 'Lead Status',
          property: { name: 'buyerData', property: 'leadStatus' }
        },
        {
          name: 'Remove From List'
        }
      ])
    : ([
        { name: 'Name', property: 'firstName' },
        { name: 'User Type', property: 'userType' },
        { name: 'Email', property: 'email' },
        {
          name: 'Phone Number',
          property: { name: 'buyerData', property: 'phoneNumber' }
        },
        {
          name: 'Sales Rep Owner',
          property: { name: 'buyerData', property: 'salesRep' }
        },
        {
          name: 'Buyer Score',
          property: { name: 'buyerData', property: 'rating' }
        },
        {
          name: 'Lead Status',
          property: { name: 'buyerData', property: 'leadStatus' }
        }
      ]), [listsFilterApplied])

  const handleSelectAll = (e) => {
    if (e?.currentTarget?.checked) {
      const obj = { ...selected }
      obj[page] = true
      const tempList = [...saveList, ...contacts.slice((page - 1) * limit, limit * page)]
      const uniqueList = []
      const indexes = {}
      tempList.forEach(item => {
        if (!indexes[item._id]) {
          uniqueList.push(item)
          indexes[item._id] = item._id
        }
      })
      contacts
        .slice((page - 1) * limit, limit * page)
        .forEach(contact => {
          obj[contact._id] = true
        })
      setSaveList(uniqueList)
      // @ts-ignore
      setSelected(obj)
    } else {
      const obj = { ...selected }
      const map = {}
      obj[page] = false
      const contactsOfPage = contacts.slice((page - 1) * limit, limit * page)
      contactsOfPage.forEach(contact => {
        delete obj[contact._id]
        map[contact._id] = contact._id
      })
      // @ts-ignore
      setSelected(obj)
      setSaveList(prev => prev.filter(item => !map[item._id] && item))
    }
  }

  return <thead className='bg-gray-50'>
      <tr>
        <th
          scope='col'
          className='relative w-25 px-6 sm:w-16 sm:px-8'
        >
          <input
            type='checkbox'
            checked={selected[page]}
            onChange={handleSelectAll}
            className='absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6 hover:cursor-pointer'
          />
        </th>
        {columns.map((col, index) => (
          <th
            scope='col'
            className='relative w-25 px-6 sm:w-16 sm:px-8 whitespace-nowrap py-3 pr-6'
            style={{ textAlign: 'left', paddingLeft: 0 }}
            key={index}
          >
            {col.name}
          </th>
        ))}
      </tr>
    </thead>
}

export default TableHead
