import React from 'react'

const BuyerCard = ({ title, Icon, onClick, classes, backgroundColor = '' }) => {
  return (
    <div
      onClick={onClick}
      className={`px-8 py-4 bg-gray-2 flex flex-col items-center justify-evenly rounded-md h-32 cursor-pointer hover:bg-slate-200 transition-all duration-200 ${classes}`}
      style={{ backgroundColor: backgroundColor.length > 0 && backgroundColor }}
    >
      {Icon && <Icon />}
      <span>{title}</span>
    </div>
  )
}

export default BuyerCard
