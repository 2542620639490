import React, { useState } from 'react'
import devWorking from 'assets/lottieJsons/devWorking.json'
import { AnimatedList, LottieWrapper } from 'components'

import { AnimatePresence, motion } from 'framer-motion'

const workingPrhase = 'We are working hard to bring another fantastic tool for you!'

const PageUnderConstruction = () => {
  const [showPhrase, setShowPhrase] = useState(false)

  setTimeout(() => {
    setShowPhrase(true)
  }, 500)

  return (
    <div className='flex flex-col h-full w-full items-center justify-center'>
      <motion.div
        id='animationContainer'
        className='w-full flex items-center justify-center'
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
      >
        <LottieWrapper animation={devWorking} loop className='w-2/5' />
      </motion.div>

      <AnimatePresence initial={false}>
        {showPhrase && (
          <AnimatedList
            entranceSpeed={0.07}
            wrapperClassName='text-2xl m-5 flex flex-row space-x-1.5'
          >
            {workingPrhase?.split(' ')}
          </AnimatedList>
        )}
      </AnimatePresence>
    </div>
  )
}

export default PageUnderConstruction
