import React from 'react'
import { Popconfirm } from 'antd'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import standardStyle from 'assets/css/standardStyle'
import { handleLogout } from 'store/actions/authActions'
import { QuestionIcon } from 'components'
import { IconContainer } from './styles'

const HeaderButton = (props) => {
  const { popConfirmTitle, onConfirm, children } = props

  const _onConfirm = () => {
    onConfirm()
  }

  return (
    <Popconfirm
      title={popConfirmTitle}
      okText='Yes'
      cancelText='No'
      placement='bottomRight'
      onConfirm={_onConfirm}
      icon={
        <QuestionIcon style={{ color: standardStyle.colors.primary }} />
      }
    >
      <IconContainer>
        {children}
      </IconContainer>
    </Popconfirm>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {
  handleLogout
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderButton))

HeaderButton.propTypes = {
  popConfirmTitle: propTypes.string,
  onConfirm: propTypes.func,
  children: propTypes.node
}
