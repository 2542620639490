import { ContactContext } from 'pages/contacts/context/Contacts'
import { useState, useEffect, useRef, useContext } from 'react'
import { getBrokerClients } from 'store/actions/usersActions'

const useGetBrokerClients = () => {
  const { selectedContact } = useContext(ContactContext)
  const [brokerClients, setBrokerClients] = useState([])
  const [loading, setLoading] = useState(true)
  const fetchClients = useRef(async (id) => {})

  fetchClients.current = async (id) => {
    if (!id) return
    const clients = await getBrokerClients(id).catch(() => setLoading(false))
    if (!clients) return setLoading(false)
    setBrokerClients(clients)
    setLoading(false)
  }

  useEffect(() => {
    fetchClients.current(selectedContact)
  }, [selectedContact])

  return { brokerClients, loading }
}

export default useGetBrokerClients
