import { ContactProvider } from 'pages/contacts/context/Contacts'
import React from 'react'
import { CreateBrokerOpportunityProvider } from './context/CreateBrokerOpportunityContext'
import CreateOpportunityWrapper from './Wrapper'

const CreateOpportunity = () => {
  return (
    <CreateBrokerOpportunityProvider>
      <ContactProvider>
        <CreateOpportunityWrapper />
      </ContactProvider>
    </CreateBrokerOpportunityProvider>
  )
}

export default CreateOpportunity
