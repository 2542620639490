/* eslint-disable */

import React, { useState } from "react";
import { classNames } from "utils";
import InventoryListViewV2 from "./InventoryListViewV2";
import InventoryEditDrawerV2 from "../InventoryEditDrawerV2";

const InventoryListViewWrapperV2 = ({
  inventoryItems,
  loading,
  type = "",
  refetch,
  isFullHeight = false,
  selectedBuilding,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [editDetails, setEditDetails] = useState({});

  return (
    <div className="w-full h-full">
      <div className="relative flex w-full h-full">
        <div
          className={classNames(
            "w-full relative transition-all",
            isDrawerOpen ? "mr-[350px]" : "mr-0"
          )}
        >
          <InventoryListViewV2
            inventoryItems={inventoryItems}
            loading={loading}
            type={type}
            onSelect={(val) => {
              setEditDetails(val);
              setIsDrawerOpen(true);
            }}
          />
        </div>
        <InventoryEditDrawerV2
          editDetails={editDetails}
          setEditDetails={setEditDetails}
          closeInfoDrawer={() => setIsDrawerOpen(false)}
          isInfoDrawerOpen={isDrawerOpen}
          isAbleToPurchase
          refetch={refetch}
          type={type}
          selectedBuilding={selectedBuilding}
          isFullHeight={isFullHeight}
        />
      </div>
    </div>
  );
};

export default InventoryListViewWrapperV2;
