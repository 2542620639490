import { useFormikContext } from 'formik'
import React from 'react'

const FormInput = ({ title = '', placeholder = '', fieldName = '', wrapperClass = '', titleClass = '', inputClass = '' }) => {
  const { handleChange, values } = useFormikContext()
  return (
    <div className={`flex flex-col justify-left ${wrapperClass}`}>
        <div className={`font-bold text-softBlack_70 italic ${titleClass}`}>{title}</div>
        <input value={values[fieldName]} onChange={handleChange(fieldName)} className={inputClass} placeholder={placeholder} />
    </div>
  )
}

export default FormInput
