import React from 'react'
import EmailAttachmentItem from './EmailAttachmentItem'

const EmailAttachments = ({ files, threadId, message }) => {
  if (!files?.length) {
    return null
  }
  return <ul className='flex flex-wrap p-4 gap-2'>
    {files.map((file) => (
      <EmailAttachmentItem file={file} threadId={threadId} key={file.id} message={message} />
    ))}
  </ul>
}

export default EmailAttachments
