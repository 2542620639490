import { ProjectBrokerLeads } from 'components'
import React from 'react'

const LandingPageScreen = () => {
  return (
    <div className=''>
      <ProjectBrokerLeads />
    </div>
  )
}

export default LandingPageScreen
