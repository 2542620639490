import React, {
  useState, useEffect,
  useCallback
} from 'react'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
import { InlineInput, Tabs } from 'ui'
import { FormTabsWrapper, FormWrapper } from 'components'
import { getProjects } from 'store/actions/projectActions'

import { validateRequiredInputs } from 'utils'
import { TSaveButton } from 'components/DefaultButtons'
import { createOrUpdateBuilding, getBuildings } from 'store/actions/buildingActions'

const TabHeader = ({ title, subtitle }) => (
  <div className='space-y-1'>
    <h3 className='text-lg leading-6 font-medium text-gray-900'>
      {title && title}
    </h3>
    <p className='max-w-2xl text-sm text-gray-500'>
      {subtitle && subtitle}
    </p>
  </div>
)

const TabContent = ({ children }) => (
  <div className='mt-6'>
    <dl className='divide-y divide-gray-200'>
      {children}
    </dl>
  </div>
)

const BuildingData = (props) => {
  const {
    _id,
    userObject: {
      userType: loggedUserType
    }
  } = props

  const [name, setName] = useState('')
  const [numberOfUnits, setNumberOfUnits] = useState('')
  const [totalDollarValue, setTotalDollarValue] = useState('')
  const [totalDollarValueCurrencyType, setTotalDollarValueCurrencyType] = useState('')
  const [gettingData, setGettingData] = useState(false)

  const [project, setProject] = useState([])
  const [projectsData, setProjectsData] = useState([])
  const [gettingProjects, setGettingProjects] = useState(false)

  const [selectedTab, setSelectedTab] = useState('general')

  const [saving, setSaving] = useState(false)

  // When mounted, get the list of projects to fill Project Select
  useEffect(() => {
    setGettingProjects(true)
    getProjects('')
      .then((projects) => {
        const tmpProjects = projects.map((project) => ({
          id: project._id, value: project.projectName
        }))

        setProjectsData(tmpProjects)
        setGettingProjects(false)
      })
      .catch(() => {
        setGettingProjects(false)
      })
  }, [])

  const getBuildingData = useCallback(() => {
    if (_id) {
      setGettingData(true)

      // Get project data from id
      getBuildings(_id)
        .then((building) => {
          const {
            name, numberOfUnits, totalDollarValue,
            totalDollarValueCurrencyType, project
          } = building

          setName(name)
          setNumberOfUnits(numberOfUnits)
          setTotalDollarValue(totalDollarValue)
          setTotalDollarValueCurrencyType(totalDollarValueCurrencyType)
          setProject(project)
          setGettingData(false)
        })
    } else {
      setGettingData(false)
    }
  }, [_id])

  useEffect(() => {
    getBuildingData()
  }, [getBuildingData])

  const _onSaveClick = useCallback(() => new Promise((resolve, reject) => {
    validateRequiredInputs('createBuilding')
      .then(() => {
        setSaving(true)

        const params = {
          _id,
          name,
          numberOfUnits,
          totalDollarValue,
          totalDollarValueCurrencyType,
          project
        }

        createOrUpdateBuilding(params)
          .then(() => {
            setSaving(false)
            resolve()
          })
          .catch(() => {
            setSaving(false)
            getBuildingData()
            reject()
          })
      })
  }), [
    getBuildingData, _id, name, numberOfUnits, project,
    totalDollarValue, totalDollarValueCurrencyType
  ])

  const renderGeneralTab = useCallback(() => (
    <div className={`${selectedTab !== 'general' && 'sr-only'}`}>
      <TabHeader
        title='General'
        subtitle='Here are all infos related to the building'
      />
      <TabContent>
        <InlineInput
          id='name'
          key='name'
          type='text'
          label='Name'
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder={'the building\'s name'}
          required
          validateKey='createBuilding'
          loading={gettingData}
          onClickUpdate={_id ? _onSaveClick : null}
        />
        <InlineInput
          id='numberOfUnits'
          key='numberOfUnits'
          type='number'
          label='Number of units'
          value={numberOfUnits}
          onChange={(e) => setNumberOfUnits(e.target.value)}
          placeholder={'how many units?'}
          required
          validateKey='createBuilding'
          loading={gettingData}
          onClickUpdate={_id ? _onSaveClick : null}
        />
        <InlineInput
          id='totalDollarValue'
          key='totalDollarValue'
          type='text'
          label='Total dollar value'
          value={totalDollarValue}
          onChange={(e) => setTotalDollarValue(e.value)}
          placeholder={'building\'s total value'}
          required
          typeInput='numberinput'
          validateKey='createBuilding'
          loading={gettingData}
          prefix={totalDollarValueCurrencyType}
          thousandSeparator
          onClickUpdate={_id ? _onSaveClick : null}
        />
        <InlineInput
          id='totalDollarValueCurrencyType'
          key='totalDollarValueCurrencyType'
          type='text'
          label='Currency type'
          value={totalDollarValueCurrencyType}
          onChange={(e) => setTotalDollarValueCurrencyType(e.target.value)}
          placeholder='currency type'
          required
          validateKey='createBuilding'
          loading={gettingData}
          onClickUpdate={_id ? _onSaveClick : null}
        />
        <InlineInput
          id='project'
          key='project'
          type='text'
          label='Project'
          value={project}
          typeInput='select'
          data={projectsData}
          onChange={(project) => setProject(project)}
          placeholder={'building\'s project'}
          required
          validateKey='createBuilding'
          loading={gettingData || gettingProjects}
          onClickUpdate={_id ? _onSaveClick : null}
        />
      </TabContent>
    </div>
  ), [
    _onSaveClick, gettingData, _id, selectedTab, gettingProjects,
    projectsData, project, name, numberOfUnits, totalDollarValue,
    totalDollarValueCurrencyType
  ])

  const renderIdsTab = useCallback(() => (
    <div className={`${selectedTab !== 'ids' && 'sr-only'}`}>
      <TabHeader
        title='IDs'
        subtitle='This tab is only available to Super Admins'
      />
      <TabContent>
        <InlineInput
          key='buildingId'
          label='Building ID'
          value={_id}
          onChange={(e) => {}}
          placeholder='building ID'
          loading={gettingData}
          disabled
        />
      </TabContent>
    </div>
  ), [gettingData, _id, selectedTab])

  const getMenus = useCallback(() => {
    const menus = [
      { key: 'general', title: 'General' }
    ]

    if (loggedUserType === 'SuperAdmin' && Boolean(_id)) {
      menus.push(
        { key: 'ids', title: 'IDs' }
      )
    }

    return menus
  }, [loggedUserType, _id])

  return (
    <FormWrapper>
      <FormTabsWrapper>
        <Tabs
          menus={getMenus()}
          tabSelected={selectedTab}
          onClick={(tab) => setSelectedTab(tab)}
        />
        {
          !_id && (
            <TSaveButton
              loading={saving}
              className='ml-3'
              onClick={_onSaveClick}
            >
              Save building
            </TSaveButton>
          )
        }
      </FormTabsWrapper>

      <div className='mt-7'>
        {renderGeneralTab()}
        {renderIdsTab()}
     </div>
    </FormWrapper>
  )
}

const mapStateToProps = (state) => ({
  userObject: state.authReducer.userObject
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(BuildingData)

BuildingData.propTypes = {
  _id: propTypes.string,
  userObject: propTypes.shape({
    userType: propTypes.string
  })
}

TabHeader.propTypes = {
  title: propTypes.string,
  subtitle: propTypes.string
}

TabContent.propTypes = {
  children: propTypes.node
}
