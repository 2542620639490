import React, { useState, useEffect } from 'react'
import propTypes from 'prop-types'

import { getImageColors } from 'store/actions/projectActions'
import { AnimatePresence } from 'framer-motion'
import { CreateOfferStepWithList, FadedAnimatedDiv, LottieWrapper, CreateOfferStepFullScreenWrapper } from 'components'
import loadingColorScheme from 'assets/lottieJsons/loadingBuyerColorSchemes.json'

const BuyerColorSchemes = (props) => {
  const {
    projectData,
    onSelectColorScheme,
    selectedColorScheme: externalSelectedColorScheme,
    offerId, titleWrapperClassName,
    titleClassName, buttonsClassName, buttonSize,
    numberOfItemsToSlide
  } = props
  const { options, totalDollarValueCurrencyType } = projectData
  const [colorSchemes, setColorSchemes] = useState([])
  const [gettingColorSchemes, setGettingColorSchemes] = useState(options.length || false)
  const [selectedColorScheme, setSelectedColorScheme] = useState('')

  useEffect(() => {
    // Check if already has a selected color scheme
    if (Object.keys(externalSelectedColorScheme).length) {
      setSelectedColorScheme(externalSelectedColorScheme)
    }
  }, [externalSelectedColorScheme])

  useEffect(() => {
    async function getColorSchemes () {
      setGettingColorSchemes(true)
      const colorSchemes = options
        .filter((option) => option.type === 'color')

      if (colorSchemes.length) {
        const tmpColorSchemes = await Promise.all(colorSchemes.map(async (colorScheme) => {
          const tmpElement = { ...colorScheme }

          const colorSchemePaletteColors =
            await getImageColors(colorScheme.images.length ? colorScheme.images[0] : '')
          tmpElement.colorPalette = colorSchemePaletteColors
          return tmpElement
        }))

        setColorSchemes(tmpColorSchemes)
      }

      setGettingColorSchemes(false)
    }

    if (options.length) {
      getColorSchemes()
    }
  }, [options])

  return (
    <CreateOfferStepFullScreenWrapper className='px-5 md:px-18'>
      <AnimatePresence exitBeforeEnter initial={false}>
        {
          gettingColorSchemes
            ? (
                <FadedAnimatedDiv
                  key='loadingColorSchemes'
                  className='flex flex-col items-center justify-center h-full'
                >
                  <LottieWrapper
                    animation={loadingColorScheme}
                    className='h-24 w-52'
                    loop
                  />
                  <span className='text-xl text-center'>Loading Color Schemes...</span>
                </FadedAnimatedDiv>
              )
            : (
                <FadedAnimatedDiv key='colorSchemes' className='h-full flex flex-col md:justify-around'>
                  <CreateOfferStepWithList
                    title='Select your Color Palette'
                    continueButtonText='Continue'
                    continueButtonOnClick={() => onSelectColorScheme(selectedColorScheme)}
                    continueButtonDisabled={false}
                    items={colorSchemes}
                    optionSelected={selectedColorScheme}
                    onSelectOption={({ item: colorScheme }) =>
                      setSelectedColorScheme(colorScheme)}
                    totalDollarValueCurrencyType={totalDollarValueCurrencyType}
                    isCollorStep
                    offerId={offerId}
                    titleWrapperClassName={titleWrapperClassName}
                    titleClassName={titleClassName}
                    continueButtonClassName={buttonsClassName}
                    buttonSize={buttonSize}
                    numberOfItemsToSlide={numberOfItemsToSlide}
                  />
                </FadedAnimatedDiv>
              )
        }
      </AnimatePresence>
    </CreateOfferStepFullScreenWrapper>
  )
}

export default BuyerColorSchemes

BuyerColorSchemes.defaultProps = {
  titleWrapperClassName: '',
  titleClassName: '',
  buttonsClassName: '',
  buttonSize: 'large',
  numberOfItemsToSlide: 3
}

BuyerColorSchemes.propTypes = {
  projectData: propTypes.object,
  onSelectColorScheme: propTypes.func,
  selectedColorScheme: propTypes.string,
  offerId: propTypes.object,
  titleWrapperClassName: propTypes.string,
  titleClassName: propTypes.string,
  buttonsClassName: propTypes.string,
  buttonSize: propTypes.string,
  numberOfItemsToSlide: propTypes.number
}
