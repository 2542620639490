import React from 'react'
import { BsBuilding } from 'react-icons/bs'

const ActionButton = ({ text, Icon = BsBuilding, onClick }) => {
  return (
        <div onClick={onClick} className='italic flex flex-col justify-center items-center p-3 cursor-pointer rounded-md bg-white' style={{ flex: 1, height: '115px' }}>
            <div className='mb-4'>
              <Icon className='w-8 h-8' />
            </div>
            {text}
        </div>
  )
}

export default ActionButton
