import React from 'react'
import { PwaWrapper, CreateOffer as CreateOfferComponent } from 'components'
import { useAnimation } from 'framer-motion'
import { fadeVariants } from 'utils'

// const project = {
//   images: [
//     'https://pxpdemo-images.s3.us-east-2.amazonaws.com/1603308879074-1601095260340-1601091579843-hero-homes-1600x900.jpg',
//     'https://pxpdemo-images.s3.us-east-2.amazonaws.com/1603308879785-1601095261939-1601091581505-18045_UD2_2BR_Kitchen_C041.50_5K0008-1600x1070.jpg',
//     'https://pxpdemo-images.s3.us-east-2.amazonaws.com/1603308881187-1601095263877-1601091583383-18045_UD2_Bike-Lounge-View-A02_RD_6_01-1600x1070.jpg',
//     'https://pxpdemo-images.s3.us-east-2.amazonaws.com/1603308881192-1601095266007-1601091585524-18045_UD2_Ensuite_C011.50_LightScheme_5K0006-1600x1070.jpg',
//     'https://pxpdemo-images.s3.us-east-2.amazonaws.com/1603308881197-1601095267262-1601091586771-18045_UD2_Pool_G01b1.50_5K0004_Web-1600x900.jpg',
//     'https://pxpdemo-images.s3.us-east-2.amazonaws.com/1603308881979-1601095260340-1601091579843-hero-homes-1600x900.jpg',
//     'https://pxpdemo-images.s3.us-east-2.amazonaws.com/1603308882291-1601095261939-1601091581505-18045_UD2_2BR_Kitchen_C041.50_5K0008-1600x1070.jpg'
//   ],
//   _id: '5f4531c0dd19390012f6d7bb',
//   unitInventory: [],
//   projectName: 'University District',
//   websiteUrl: 'https://ud.blueskyproperties.ca/',
//   city: 'Surrey',
//   province: 'British Columbia',
//   country: 'Canada',
//   totalDollarValue: 1000000,
//   totalDollarValueCurrencyType: 'CAD',
//   numberOfUnits: 100,
//   developer: '5f6e7b10be490c0019a9cb8c',
//   createdBy: '5f4592f4129afa00121c1a81',
//   createdAt: '2020-08-25T15:44:00.502Z',
//   updatedAt: '2020-10-21T19:34:43.240Z',
//   __v: 0,
//   backgroundImage: 'https://pxpdemo-images.s3.us-east-2.amazonaws.com/1603308882653-1601095257930-1601078323344-home-hero-1600x900.jpg',
//   logoImage: 'https://pxpdemo-images.s3.us-east-2.amazonaws.com/1603308882647-1601095260306-ud_logo.svg',
//   paymentRounds: [],
//   colors: [],
//   options: [],
//   parkings: []
// }

const CreateOffer = () => {
  const animatedWrapper = useAnimation()

  return (
    <PwaWrapper
      animate={animatedWrapper}
      initial={false}
      variants={fadeVariants}
    >
      <CreateOfferComponent />
    </PwaWrapper>
  )
}

export default CreateOffer
