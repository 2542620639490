import React from 'react'

const UploadIcon = ({ width = 11, height = 12 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_424_1045)">
            <path d="M4.1252 7.83333H6.8752V5.08333H8.70854L5.5002 1.875L2.29187 5.08333H4.1252V7.83333ZM2.29187 8.75H8.70854V9.66667H2.29187V8.75Z" fill="#2E2B2E"/>
        </g>
        <defs>
        <clipPath id="clip0_424_1045">
            <rect width="11" height="11" fill="white" transform="translate(0 0.5)"/>
        </clipPath>
        </defs>
    </svg>
  )
}

export default UploadIcon
