import { useState, useEffect, useRef } from 'react'
import { getCalendarEvents } from 'store/actions/usersActions'

const useGetParticipantsMeetings = (_id) => {
  const mountedRef = useRef(true)
  const [meetings, setMeetings] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  const getData = async () => {
    if (!mountedRef.current) return null
    setLoading(true)
    try {
      const data = await getCalendarEvents('', `?participants=${_id}`)
      if (!mountedRef.current) return null
      setMeetings(data)
      setLoading(false)
    } catch (err) {
      if (!mountedRef.current) return null
      setLoading(false)
    }
  }

  return { loading, meetings, refetch: getData }
}

export default useGetParticipantsMeetings
