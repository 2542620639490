import React from 'react'
import { Spin as AntdSpin } from 'antd'
import propTypes from 'prop-types'
import { LoadingIcon } from 'components/Icons'
import standardStyle from 'assets/css/standardStyle'
import { SpinWrapper } from './styles'

const Spin = (props) => {
  const loadingIcon = <LoadingIcon style={{ color: props.spinColor }} />

  return (
    <SpinWrapper style={props.style || {}}>
      <AntdSpin indicator={loadingIcon} />
    </SpinWrapper>
  )
}

export default Spin

Spin.defaultProps = {
  spinColor: standardStyle.colors.danger
}

Spin.propTypes = {
  style: propTypes.object,
  spinColor: propTypes.string
}
