/*eslint-disable */
// @ts-nocheck
import useListsMutation from 'hooks/useListsMutation';
import useProjectId from 'hooks/useProjectId';
import useContactContext from 'pages/newContacts/hooks/useContactContext';
import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import { MdClose } from 'react-icons/md';
import { getFilterContacts } from 'store/actions/usersActions';

const CreateNewListV2 = ({ closeModal }) => {
  const { state } = useContactContext();
  const { projectId } = useProjectId();
  const { createList } = useListsMutation();
  const [title, setTitle] = useState('');

  const handleCreateList = () => {
    if (!title) return toast.error('Please enter list title');

    let filters = state.filters;
    delete filters['page'];
    delete filters['limit'];
    delete filters['search'];
    getFilterContacts({
      ...filters,
      userType: state.contactUserType,
    }).then((data) => {
      let contacts = data.data;
      if (contacts.length === 0) return toast.error('No contacts found');
      createList({
        projectId,
        title,
        users: contacts.map((contact) => contact._id),
      });
      closeModal();
    });

    setTitle('');
  };

  return (
    <div className='bg-white relative rounded p-6 h-max-content flex flex-col gap-5 w-72 border-2 border-gray-2'>
      <MdClose
        onClick={() => {
          closeModal();
        }}
        className='absolute cursor-pointer right-3 top-3 h-6 w-6'
      />
      <div className='text-3xl font-bold mt-7'>CREATE NEW LIST</div>
      <div className='flex flex-col gap-1'>
        <div className='text-softBlack30 italic'>NEW LIST</div>
        <input
          className='w-full'
          placeholder='Enter list name'
          value={title}
          onChange={(e) => setTitle(e.currentTarget.value)}
        />
      </div>
      <button
        onClick={handleCreateList}
        className='py-2 px-4 text-lg bg-[#18181B] text-white rounded-lg font-medium flex gap-2 items-center justify-center'
      >
        Create List
      </button>
    </div>
  );
};

export default CreateNewListV2;
