/* eslint-disable */
// @ts-nocheck
import React, { useEffect, useMemo, useState } from 'react'
import CopyIt from 'components/Utils/CopyToClipboard'
import { useSelector } from 'react-redux'
import CustomButton from 'components/CustomButton'
import { getProjects } from 'store/actions/projectActions'
import { getMergeFields } from 'store/actions/contractTemplateActions'

const FieldSelector = ({ contractType, className = '', onSuccess = () => { } }) => {
  const appProject = useSelector((state) => state.appReducer.appProject)
  const customSections = useSelector((state) => state.appReducer.customSections)
  const [keyFilterText, setKeyFilterText] = useState('')
  const [fields, setFields] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(true)
    if (appProject && contractType) {
      getMergeFields(appProject, contractType)
        .then((res) => {
          setFields(res)
          setIsLoading(false)
        })
        .catch((error) => {
          console.error('error occur at getMergeFields: ', error)
          setIsLoading(false)
        })
    } else {
      setIsLoading(false)
    }
  }, [appProject, contractType]);

  return (
    <div className={`w-[300px] h-full flex flex-col bg-white ${className}`}>
      <div className='flex p-2 gap-x-2 items-stretch border-y border-softBlack30'>
        <input value={keyFilterText} type='text' placeholder='Search merge fields' className='border border-softBlack30 rounded mb-0' onChange={(e) => setKeyFilterText(e.target.value)} />
        <CustomButton handleClick={() => setKeyFilterText('')}>
          <span className='font-medium px-3 py-1 text-base flex gap-2 items-center'>
            Clear
          </span>
        </CustomButton>
      </div>
      {
        isLoading
          ? <div className='flex-1 p-2 flex justify-center items-center'>
            Loading...
          </div>
          : <div className='flex-1 p-2 flex flex-col justify-start overflow-y-auto thin-scrollbar'>
            {fields?.filter(mergeTag => mergeTag.label.toLowerCase().includes(keyFilterText.toLowerCase()))?.map((mergeTag, index) => (
              <div key={index} className='flex justify-between bg-white hover:bg-zinc-100 rounded-md transition-all p-2 items-center'>
                <p className='text-base font-bold pb-0 mb-0'>{mergeTag.label}</p>
                <CopyIt size="sm" content={mergeTag.key} onSuccess={onSuccess}></CopyIt>
              </div>
            ))}
          </div>
      }
    </div>
  )
}

export default FieldSelector
