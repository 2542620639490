import React from 'react'
import propTypes from 'prop-types'

const FormWrapper = ({ children }) => (
  <div>
    {children}
  </div>
)

export default FormWrapper

FormWrapper.propTypes = {
  children: propTypes.node
}
