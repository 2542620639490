// @ts-nocheck
import Logo from 'components/LoginPage/Logo'
import React from 'react'
import CustomButton from 'components/CustomButton'
import backgroundVideo from '../../assets/videos/aires-public-screen-bg-video.mp4'

const ThankYouScreen = ({ projectLogo = '', handleGoBack = () => { }, projectName = '', buyer = null, description }) => {
  return (
    <div className='h-screen w-full flex'>
      <div className='p-8 bg-stone-100 w-full md:w-[548px]'>
        <div className='relative flex flex-col justify-center items-center h-full'>
          <Logo />
          <div className='flex-1 max-w-md w-full mx-auto flex flex-col justify-center gap-6'>
            {projectLogo?.length > 0 &&
              <div className='w-fit flex flex-col gap-4 justify-start items-start mb-10'>
                <img
                  src={projectLogo}
                  className='h-[120px] object-contain'
                />
                <h2 className='text-2xl font-medium mb-0'>{projectName}</h2>
              </div>
            }
            <div className='w-full flex flex-col gap-3 pb-4'>
              <h1 className='text-2xl font-normal mb-0'>
                Thank you
                {
                  buyer && (buyer?.firstName || buyer?.lastName) &&
                  <span className='ml-2 font-bold'>
                    {`${buyer.firstName + ' ' + buyer.lastName}`}
                  </span>
                }
                ,
              </h1>
              {description}
            </div>
            {/* <a href={'https://app.aires.ai/'}>Go To ONE ZEN's Website</a> */}
            <CustomButton
              handleClick={handleGoBack}
              className='w-full'
            >
              <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
                Go back
              </span>
            </CustomButton>
          </div>
        </div>
      </div>
      <div className='w-0 md:flex-1 md:flex h-full'>
        <video
          src={backgroundVideo}
          className='w-full h-full object-cover'
          autoPlay
          muted
          loop
        />
      </div>
    </div>
  )
}

export default ThankYouScreen
