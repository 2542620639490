import Pusher from 'pusher-js'

const PUSHER_KEY = process.env.REACT_APP_PUSHER_KEY
const PUSHER_CLUSTER = process.env.REACT_APP_PUSHER_CLUSTER

let pusher = null
export const getPusherInstance = () => {
  if (!pusher) {
    pusher = new Pusher(PUSHER_KEY, {
      cluster: PUSHER_CLUSTER
    })
  }
  return pusher
}

export const subscribeToChannel = (channelId) => {
  return getPusherInstance()?.subscribe(`public-${channelId}`)
}

export const unsubscribeToChannel = (channelId) => {
  getPusherInstance()?.unsubscribe(`public-${channelId}`)
}

export const bindChannel = (channel, callback) => {
  channel?.bind('sms', callback)
}

export const unbindChannel = (channel, callback) => {
  channel?.unbind('sms', callback)
}
