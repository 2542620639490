import { useSelector } from 'react-redux'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  createDropDown,
  getBrokerageCompanies,
  updateDropDown
} from 'store/actions/usersActions'
import { toast } from 'react-hot-toast'
import { useCallback } from 'react'

const useBrokerageCompanies = () => {
  // @ts-ignore
  const projectId = useSelector((state) => state.appReducer.appProject)
  const client = useQueryClient()
  const { data, isLoading } = useQuery(
    ['brokerageCompanies', projectId],
    ({ queryKey }) => getBrokerageCompanies(),
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!projectId
    }
  )

  const onSuccess = useCallback(() => {
    client.invalidateQueries({
      predicate: (query) =>
        query.queryKey[0] === 'brokerageCompanies' &&
        query.queryKey[1] === projectId
    })
    toast.success(
      `Successfully ${
        data?.values?.length ? 'updated' : 'created'
      } brokerage companies`
    )
  }, [client, projectId, data])

  const { mutateAsync: makeDropDown } = useMutation(createDropDown, {
    onSuccess
  })
  const { mutateAsync: changeDropDown } = useMutation(updateDropDown, {
    onSuccess
  })

  const update = useCallback(
    ({ name }) => {
      if (data?._id) {
        return changeDropDown({ ...data, values: [...data.values, name] })
      }
      makeDropDown({
        project: projectId,
        name: 'brokerageCompany',
        values: [name]
      })
    },
    [data, changeDropDown, makeDropDown, projectId]
  )

  const updateSpecific = useCallback(
    ({ name, oldName }) => {
      const values = [...data.values]
      const index = values.findIndex((item) => item === oldName)
      values[index] = name
      changeDropDown({ ...data, values })
    },
    [data, changeDropDown]
  )

  const deleteCompany = useCallback(
    ({ name }) => {
      changeDropDown({
        ...data,
        values: data.values.filter((val) => val !== name)
      })
    },
    [data, changeDropDown]
  )

  return {
    companies: data,
    loading: isLoading,
    update,
    deleteCompany,
    updateSpecific
  }
}

export default useBrokerageCompanies
