import React, {
  useState, useEffect,
  useCallback
} from 'react'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
import { InlineInput, Tabs } from 'ui'
import { FormTabsWrapper, FormWrapper, TableImageThumbnail } from 'components'

import { validateRequiredInputs } from 'utils'
import { TSaveButton } from 'components/DefaultButtons'
import { getUnitGroups } from 'store/actions/unitGroupsActions'
import { getBuildings } from 'store/actions/buildingActions'
import { getFloorPlans } from 'store/actions/floorPlanActions'
import { createOrUpdateUnit, getUnits } from 'store/actions/unitActions'

const listOfStatus = [
  { id: 'ReservedBroker', value: 'Reserved for Broker' },
  { id: 'ReservedBuyer', value: 'Reserved for Buyer' },
  { id: 'Open', value: 'Open' },
  { id: 'DeveloperHeld', value: 'Developer Held' },
  { id: 'SoldCommitted', value: 'Sold - Committed' },
  { id: 'SoldFirm', value: 'Sold - Firm' },
  { id: 'Closed', value: 'Closed' }
]

const TabHeader = ({ title, subtitle }) => (
  <div className='space-y-1'>
    <h3 className='text-lg leading-6 font-medium text-gray-900'>
      {title && title}
    </h3>
    <p className='max-w-2xl text-sm text-gray-500'>
      {subtitle && subtitle}
    </p>
  </div>
)

const TabContent = ({ children }) => (
  <div className='mt-6'>
    <dl className='divide-y divide-gray-200'>
      {children}
    </dl>
  </div>
)

const balconyData = [
  { id: false, value: 'No' },
  { id: true, value: 'Yes' }
]

const UnitData = (props) => {
  const {
    _id,
    userObject: {
      userType: loggedUserType
    }
  } = props

  const [unitNumber, setUnitNumber] = useState('')
  const [fullAddress, setFullAddress] = useState('')
  const [strataLotNumber, setStrataLotNumber] = useState('')
  const [building, setBuilding] = useState('')
  const [price, setPrice] = useState('')
  const [priceCurrencyType, setPriceCurrencyType] = useState('')
  const [floorPlan, setFloorPlan] = useState('')
  const [floorNumber, setFloorNumber] = useState('')
  const [numberOfBedrooms, setNumberOfBedrooms] = useState('')
  const [numberOfBathrooms, setNumberOfBathrooms] = useState('')
  const [unitGroup, setUnitGroup] = useState('')
  const [status, setStatus] = useState('')
  const [balcony, setBalcony] = useState('')
  const [gettingData, setGettingData] = useState(false)
  const [gettingBuildings, setGettingBuildings] = useState(false)
  const [gettingFloorPlans, setGettingFloorPlans] = useState(false)
  const [gettingUnitGroups, setGettingUnitGroups] = useState(false)
  const [buildingsDataToSelect, setBuildingsDataToSelect] = useState([])
  const [floorPlansDataToSelect, setFloorPlansDataToSelect] = useState([])
  const [unitGroupsDataToSelect, setUnitGroupDataToSelect] = useState([])
  const [showFloorPlanThumbnail, setShowFloorPlanThumbnail] = useState(false)

  const [selectedTab, setSelectedTab] = useState('general')
  const [saving, setSaving] = useState(false)

  // When mounted
  useEffect(() => {
    async function fetchData () {
      const buildingsData = await getBuildings('')
      const floorPlansData = await getFloorPlans('')
      const unitGroupsData = await getUnitGroups('')

      // Get buildings and map it to fill the select components for the user
      const tmpBuildingsData = buildingsData.map((building) => ({ id: building._id, value: building.name }))
      setBuildingsDataToSelect(tmpBuildingsData)
      setGettingBuildings(false)

      // Get floor plans and map it to fill the select components for the user
      const tmpFloorPlansData = floorPlansData.map((floorPlan) => ({ id: floorPlan._id, value: floorPlan.name, image: floorPlan.image }))
      setFloorPlansDataToSelect(tmpFloorPlansData)
      setGettingFloorPlans(false)

      // Get units groups and map it to fill the select components for the user
      const tmpUnitsGroupsData = unitGroupsData.map((unitGroup) => ({ id: unitGroup._id, value: unitGroup.name }))
      setUnitGroupDataToSelect(tmpUnitsGroupsData)
      setGettingUnitGroups(false)
    }

    fetchData()
  }, [])

  const getUnitData = useCallback(() => {
    if (_id) {
      setGettingData(true)

      // Get unit data from id
      getUnits(_id)
        .then((unit) => {
          const {
            unitNumber, strataLotNumber, building, price,
            priceCurrencyType, floorPlan, floorNumber,
            numberOfBedrooms, numberOfBathrooms, unitGroup,
            status, balcony, fullAddress
          } = unit

          if (building) {
            setBuilding(building._id)
          }

          setUnitNumber(unitNumber)
          setFullAddress(fullAddress)
          setStrataLotNumber(strataLotNumber)
          setPrice(price)
          setPriceCurrencyType(priceCurrencyType)
          setFloorPlan(floorPlan ? floorPlan._id : '')
          setFloorNumber(floorNumber)
          setNumberOfBedrooms(numberOfBedrooms)
          setNumberOfBathrooms(numberOfBathrooms)
          setUnitGroup(unitGroup ? unitGroup._id : '')
          setStatus(status)
          setBalcony(balcony)
          setGettingData(false)
        })
    } else {
      setGettingData(false)
    }
  }, [_id])

  useEffect(() => {
    getUnitData()
  }, [getUnitData])

  const _onSaveClick = useCallback(() => new Promise((resolve, reject) => {
    validateRequiredInputs('createUnit')
      .then(() => {
        setSaving(true)

        const params = {
          _id,
          unitNumber,
          fullAddress,
          strataLotNumber,
          building,
          price,
          priceCurrencyType,
          floorPlan,
          floorNumber,
          numberOfBedrooms,
          numberOfBathrooms,
          unitGroup,
          status,
          balcony: Boolean(balcony)
        }

        createOrUpdateUnit(params)
          .then(() => {
            setSaving(false)
            resolve()
          })
          .catch(() => {
            setSaving(false)
            getUnitData()
            reject()
          })
      })
  }), [
    _id, balcony, building, floorNumber, floorPlan, getUnitData, numberOfBathrooms,
    numberOfBedrooms, price, priceCurrencyType, status, strataLotNumber, unitGroup,
    unitNumber, fullAddress
  ])

  const renderGeneralTab = useCallback(() => (
    <div className={`${selectedTab !== 'general' && 'sr-only'}`}>
      <TabHeader
        title='General'
        subtitle='Here are all infos related to the unit'
      />
      <TabContent>
        <InlineInput
          id='unitNumber'
          key='unitNumber'
          type='text'
          label='Unit number'
          value={unitNumber}
          onChange={(e) => setUnitNumber(e.target.value)}
          placeholder={'first, the number of the unit'}
          required
          validateKey='createUnit'
          loading={gettingData}
          onClickUpdate={_id ? _onSaveClick : null}
        />
        <InlineInput
          id='fullAdddress'
          key='fullAdddress'
          type='text'
          label='Full Address'
          value={fullAddress}
          onChange={(e) => setFullAddress(e.target.value)}
          placeholder={'unit\'s full address like'}
          required
          validateKey='createUnit'
          loading={gettingData}
          onClickUpdate={_id ? _onSaveClick : null}
        />
        <InlineInput
          id='strataLotNumner'
          key='strataLotNumner'
          type='text'
          label='Strata Lot Number'
          value={strataLotNumber}
          onChange={(e) => setStrataLotNumber(e.target.value)}
          placeholder={'unit\'s strata lot number'}
          required
          validateKey='createUnit'
          loading={gettingData}
          onClickUpdate={_id ? _onSaveClick : null}
        />
        <InlineInput
          id='building'
          key='building'
          type='text'
          label='Building'
          value={building}
          typeInput='select'
          data={buildingsDataToSelect}
          onChange={(building) => setBuilding(building)}
          placeholder={'belongs to any building?'}
          required
          validateKey='createUnit'
          loading={gettingData || gettingBuildings}
          onClickUpdate={_id ? _onSaveClick : null}
        />
        <InlineInput
          id='price'
          key='price'
          type='text'
          label='Price'
          value={price}
          onChange={(e) => setPrice(e.value)}
          placeholder={'the unit\'s price'}
          required
          typeInput='numberinput'
          validateKey='createUnit'
          prefix={priceCurrencyType}
          loading={gettingData}
          onClickUpdate={_id ? _onSaveClick : null}
        />
        <InlineInput
          id='priceCurrencyType'
          key='priceCurrencyType'
          type='text'
          label='Currency type'
          value={priceCurrencyType}
          onChange={(e) => setPriceCurrencyType(e.target.value)}
          placeholder='currency type'
          required
          validateKey='createUnit'
          loading={gettingData}
          onClickUpdate={_id ? _onSaveClick : null}
        />
        <InlineInput
          id='floorPlan'
          name='floorPlan'
          type='text'
          label='Floor plan'
          required
          validateKey='createUnit'
          value={floorPlan}
          onChange={(floorPlan) => setFloorPlan(floorPlan)}
          placeholder='the floor plan'
          loading={gettingData || gettingFloorPlans}
          typeInput='select'
          data={floorPlansDataToSelect}
          onDropdownVisibleChange={(e) => setShowFloorPlanThumbnail(e)}
          showExtraContent={showFloorPlanThumbnail}
          extraContent={(e) =>
            <TableImageThumbnail
              src={e.image}
              style={{ marginRight: 15, minWidth: '40%', objectFit: 'contain' }}
            />
          }
          onClickUpdate={_id ? _onSaveClick : null}
        />
        <InlineInput
          id='floorNumber'
          name='floorNumber'
          type='text'
          label='Floor number'
          value={floorNumber}
          onChange={(e) => setFloorNumber(e.target.value)}
          placeholder={'what\'s the floor number?'}
          required
          validateKey='createUnit'
          loading={gettingData}
          onClickUpdate={_id ? _onSaveClick : null}
        />
        <InlineInput
          id='numberOfBedrooms'
          name='numberOfBedrooms'
          type='text'
          label='Number of bedrooms'
          value={numberOfBedrooms}
          onChange={(e) => setNumberOfBedrooms(e.target.value)}
          placeholder='how many bedrooms?'
          required
          validateKey='createUnit'
          loading={gettingData}
          onClickUpdate={_id ? _onSaveClick : null}
        />
        <InlineInput
          id='numberOfBathrooms'
          name='numberOfBathrooms'
          type='text'
          label='Number of bathrooms'
          value={numberOfBathrooms}
          onChange={(e) => setNumberOfBathrooms(e.target.value)}
          placeholder='how many bathrooms?'
          required
          validateKey='createUnit'
          loading={gettingData}
          onClickUpdate={_id ? _onSaveClick : null}
        />
        <InlineInput
          id='unitGroup'
          name='unitGroup'
          type='text'
          label='Unit group'
          value={unitGroup}
          onChange={(unitGroup) => setUnitGroup(unitGroup)}
          placeholder='belongs to any unit group?'
          loading={gettingData || gettingUnitGroups}
          data={unitGroupsDataToSelect}
          typeInput='select'
          required
          validateKey='createUnit'
          onClickUpdate={_id ? _onSaveClick : null}
        />
        <InlineInput
          id='status'
          name='status'
          type='text'
          label='Status'
          value={status}
          onChange={(status) => setStatus(status)}
          placeholder='status'
          loading={gettingData}
          data={listOfStatus}
          typeInput='select'
          required
          validateKey='createUnit'
          onClickUpdate={_id ? _onSaveClick : null}
        />
        <InlineInput
          id='balcony'
          name='balcony'
          type='text'
          label='Balcony'
          value={balcony}
          onChange={(option) => setBalcony(option)}
          placeholder='balcony?'
          required
          validateKey='createUnit'
          loading={gettingData}
          typeInput='select'
          data={balconyData}
          onClickUpdate={_id ? _onSaveClick : null}
        />
      </TabContent>
    </div>
  ), [
    _id, _onSaveClick, balcony, building, floorNumber, floorPlan,
    floorPlansDataToSelect, gettingBuildings, gettingData,
    numberOfBathrooms, numberOfBedrooms, price, priceCurrencyType,
    selectedTab, showFloorPlanThumbnail, status, strataLotNumber,
    unitGroup, unitGroupsDataToSelect, unitNumber, buildingsDataToSelect,
    gettingFloorPlans, gettingUnitGroups, fullAddress
  ])

  const renderIdTab = useCallback(() => (
    <div className={`${selectedTab !== 'id' && 'sr-only'}`}>
      <TabHeader
        title='ID'
        subtitle='This tab is only available to Super Admins'
      />
      <TabContent>
        <InlineInput
          key='unitId'
          label='Unit ID'
          value={_id}
          onChange={(e) => {}}
          placeholder='unit ID'
          loading={gettingData}
          disabled
        />
      </TabContent>
    </div>
  ), [gettingData, _id, selectedTab])

  const getMenus = useCallback(() => {
    const menus = [
      { key: 'general', title: 'General' }
    ]

    if (loggedUserType === 'SuperAdmin' && Boolean(_id)) {
      menus.push(
        { key: 'id', title: 'ID' }
      )
    }

    return menus
  }, [loggedUserType, _id])

  return (
    <FormWrapper>
      <FormTabsWrapper>
        <Tabs
          menus={getMenus()}
          tabSelected={selectedTab}
          onClick={(tab) => setSelectedTab(tab)}
        />
        {
          !_id && (
            <TSaveButton
              loading={saving}
              className='ml-3'
              onClick={_onSaveClick}
            >
              Save unit
            </TSaveButton>
          )
        }
      </FormTabsWrapper>

      <div className='mt-7'>
        {renderGeneralTab()}
        {renderIdTab()}
     </div>
    </FormWrapper>
  )
}

const mapStateToProps = (state) => ({
  userObject: state.authReducer.userObject
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(UnitData)

UnitData.propTypes = {
  _id: propTypes.string,
  userObject: propTypes.shape({
    userType: propTypes.string
  })
}

TabHeader.propTypes = {
  title: propTypes.string,
  subtitle: propTypes.string
}

TabContent.propTypes = {
  children: propTypes.node
}
