import ProgressBar from 'components/ProgressBar'
import React from 'react'

const ImageSequence = ({ userList }) =>
  userList.map((user, idx) => (
    <div className={'left-' + idx * 2 + ' absolute '}>
      <div
        className={
          'text-gray-400 h-10 w-10 border border-white rounded-full flex items-center justify-center bg-gray-200 text-text-gray-500 font-bold'
        }>
        {user.split('')[0]}
      </div>
    </div>
  ))

export const AllocationTableRow = ({
  name,
  allocationMethod,
  receivers,
  percentageSold,
  expiration,
  units
}) => {
  const [showUnits, setShowUnits] = React.useState(false)
  return (
    <div onClick={() => setShowUnits((prev) => !prev)} className='border-b border-black'>
      <div className='grid grid-cols-12 border-b py-4 font-semibold'>
        <span className='col-span-2'>{name}</span>
        <span className='col-span-3'>{allocationMethod}</span>
        <span className='h-10 col-span-2 relative'>
          <ImageSequence userList={units.map((unit) => unit.brokerName)} />
        </span>
        <span className='col-span-3 py-1'>
          <ProgressBar currentPercent={percentageSold} />
        </span>
        <span className=''>{expiration}</span>
      </div>
      {showUnits && (
        <div>
          <AllocationTableUnits units={units} />
        </div>
      )}
    </div>
  )
}

export const AllocationTableUnits = ({ units }) => {
  return (
    <div>
      <div className='grid grid-cols-12 text-xs py-4 px-6'>
        <span>UNIT</span>
        <span className='col-span-3'>DETAILS</span>
        <span className='col-span-3'>BROKER</span>
        <span className='col-span-3'>PRICE</span>
        <span className='col-span-2'>STATUS</span>
      </div>
      {units.map(
        ({ unitName, noOfBedrooms, noOfBathrooms, noOfParking, brokerName, price, status }, index) => (
          <div key={index} className='grid grid-cols-12 transition-all px-6 py-2 items-center'>
            <span>{unitName}</span>
            <div className='flex col-span-3 text-sm gap-3 whitespace-nowrap'>
              <div className='flex items-baseline'>
                <svg
                  width='18'
                  height='12'
                  viewBox='0 0 18 12'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M15.668 4.33366V1.83366C15.668 0.916992 14.918 0.166992 14.0013 0.166992H4.0013C3.08464 0.166992 2.33464 0.916992 2.33464 1.83366V4.33366C1.41797 4.33366 0.667969 5.08366 0.667969 6.00033V10.167H1.7763L2.33464 11.8337H3.16797L3.7263 10.167H14.2846L14.8346 11.8337H15.668L16.2263 10.167H17.3346V6.00033C17.3346 5.08366 16.5846 4.33366 15.668 4.33366ZM8.16797 4.33366H4.0013V1.83366H8.16797V4.33366ZM14.0013 4.33366H9.83463V1.83366H14.0013V4.33366Z'
                    fill='#2E2B2E'
                  />
                </svg>
                <span className='ml-2'>{noOfBedrooms} Br</span>
              </div>
              <div className='flex items-baseline'>
                <svg
                  width='12'
                  height='12'
                  viewBox='0 0 18 18'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M16.5013 9.83366H15.668V3.02533C15.668 1.72533 14.6096 0.666992 13.3096 0.666992C12.6846 0.666992 12.0846 0.916992 11.643 1.35866L10.6013 2.40033C10.468 2.35866 10.3263 2.33366 10.1763 2.33366C9.84297 2.33366 9.53464 2.43366 9.2763 2.60033L11.5763 4.90033C11.743 4.64199 11.843 4.33366 11.843 4.00033C11.843 3.85033 11.818 3.71699 11.7846 3.57533L12.8263 2.53366C12.9513 2.40866 13.1263 2.33366 13.3096 2.33366C13.693 2.33366 14.0013 2.64199 14.0013 3.02533V9.83366H8.29297C8.04297 9.65866 7.81797 9.45866 7.60964 9.23366L6.44297 7.94199C6.28464 7.76699 6.08464 7.62533 5.86797 7.52532C5.60963 7.40032 5.3263 7.33366 5.03464 7.33366C4.0013 7.34199 3.16797 8.17533 3.16797 9.20866V9.83366H1.5013C1.04297 9.83366 0.667969 10.2087 0.667969 10.667V14.8337C0.667969 15.7503 1.41797 16.5003 2.33464 16.5003C2.33464 16.9587 2.70964 17.3337 3.16797 17.3337H14.8346C15.293 17.3337 15.668 16.9587 15.668 16.5003C16.5846 16.5003 17.3346 15.7503 17.3346 14.8337V10.667C17.3346 10.2087 16.9596 9.83366 16.5013 9.83366Z'
                    fill='#2E2B2E'
                  />
                </svg>
                <div className='ml-2'>{noOfBathrooms} Ba</div>
              </div>
              <div className='flex items-baseline'>
                <svg
                  width='8.25'
                  height='12'
                  viewBox='0 0 11 16'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M5.65833 0.5H1.66667C0.75 0.5 0 1.25 0 2.16667V13.8333C0 14.75 0.75 15.5 1.66667 15.5C2.58333 15.5 3.33333 14.75 3.33333 13.8333V10.5H5.83333C8.80833 10.5 11.1833 7.89167 10.7917 4.84167C10.4667 2.325 8.2 0.5 5.65833 0.5ZM6 7.16667H3.33333V3.83333H6C6.91667 3.83333 7.66667 4.58333 7.66667 5.5C7.66667 6.41667 6.91667 7.16667 6 7.16667Z'
                    fill='#2E2B2E'
                  />
                </svg>
                <div className='ml-2'>{noOfParking} Pa</div>
              </div>
            </div>
            <div className='col-span-3 flex items-center'>
              <div className='text-gray-400 h-10 w-10 border rounded-full flex items-center justify-center bg-gray-200 text-text-gray-500 font-bold'>
                {brokerName.split('')[0]}
              </div>
              <span className='ml-3'>{brokerName}</span>
            </div>
            <div className='col-span-3 '>{price}</div>
            <div className='text-sm font-semibold'>
              {status === 'reserved' && (
                <button className='py-2 w-28 bg-yellow-200 rounded'>RESERVED</button>
              )}
              {status === 'available' && (
                <button className='py-2 bg-lime-300 w-28 rounded '>AVAILABLE</button>
              )}
              {status === 'sold' && (
                <button className='py-2 bg-orange-700 w-28 rounded text-white'>SOLD</button>
              )}
            </div>
          </div>
        )
      )}
    </div>
  )
}

export const AllocationTable = ({ allocations = [] }) => {
  return (
    <div>
      <div className='grid grid-cols-12 text-xs'>
        <span className='col-span-2'>ALLOCATION NAME</span>
        <span className='col-span-3'>ALLOCATION METHOD</span>
        <span className='col-span-2'>RECEIVER</span>
        <span className='col-span-3'>PERCENTAGE SOLD</span>
        <span>EXPIRATION</span>
      </div>
      {allocations.map((row) => (
        <AllocationTableRow {...row} />
      ))}
    </div>
  )
}
