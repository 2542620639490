import useDynamicDropdown from './useDynamicDropdown'

const useLeadApproachForBuyer = () => {
  const {
    data: leadApproachesForBuyer,
    loading,
    update,
    deleteItem: deleteLeadApproachForBuyer,
    updateItem: updateSpecific,
    formattedData: formattedLeadApproachesForBuyer
  } = useDynamicDropdown('leadApproach')

  return {
    leadApproachesForBuyer,
    loading,
    update,
    deleteLeadApproachForBuyer,
    updateSpecific,
    formattedLeadApproachesForBuyer
  }
}

export default useLeadApproachForBuyer
