import { StatsHorizontal } from 'components/StatsHorizontal'
import React from 'react'
import {
  Container,
  TextBold
} from './styles'

const AllOpportunities = ({ title }) => {
  return (
    <Container style={{ maxHeight: 297, minHeight: 297, paddingLeft: 0, paddingRight: 0 }}>
      <TextBold style={{ fontSize: 20, lineHeight: '150%', fontWeight: 700, paddingLeft: 24 }}>
        {title}
      </TextBold>
      <div>
        <StatsHorizontal
          stats={[
            { units: 18, color: '#EDDF65', title: 'CREATED' },
            { units: 21, color: '#54B5D1', title: 'RESERVATION' },
            { units: 11, color: '#DF6D50', title: 'CONTRACT WRITING' },
            { units: 28, color: '#59B099', title: 'SOLD FIRM' },
            { units: 28, color: '#2E2B2E', title: 'LOST' }
          ]}
          title={''} classes={'justify-start g-10'} tabClasses={'w-1/4'}
          showPercentage
        />
      </div>
    </Container>
  )
}

export default AllOpportunities
