/* eslint-disable space-before-function-paren */
// {
//   "key": "Phone",
//   "element": "CustomElement",
//   "type": "custom",
//   "forwardRef": true,
//   "bare": true,
//   "field_name": "Phone___516E6678-CE76-4AB1-9DEE-6453BFC84612",
//   "name": "Phone",
//   "icon": "fa fa-phone",
//   "label": "Phone",
//   "id": "F17DC65E-D4EB-4DD8-8FC0-3F3B093980B3",
//   "text": "Phone",
//   "required": false,
//   "custom": true,
//   "custom_options": [],
//   "canHavePageBreakBefore": true,
//   "canHaveAlternateForm": true,
//   "canHaveDisplayHorizontal": true,
//   "canHaveOptionCorrect": true,
//   "canHaveOptionValue": true,
//   "canPopulateFromApi": true
// }

export const SUPPORTED_CUSTOM_FIELDS = {
  FIRST_NAME: {
    id: 'firstName',
    key: 'firstName',
    label: 'First Name',
    getHtml: (props) => {
      return "<input type='text' name='firstName' placeholder='First Name' />"
    }
  },
  LAST_NAME: {
    id: 'lastName',
    key: 'lastName',
    label: 'Last Name',
    getHtml: (props) => {
      return "<input type='text' name='lastName' placeholder='Last Name' />"
    }
  },
  EMAIL: {
    id: 'emailAddress',
    key: 'emailAddress',
    label: 'Email',
    getHtml: (props) => {
      return "<input type='email' name='emailAddress' placeholder='example@gmail.com' />"
    }
  },
  PHONE: {
    id: 'phoneNumber',
    key: 'phoneNumber',
    label: 'Phone',
    getHtml: (props) => {
      return "<input type='text' name='phone' placeholder='(123) 456-7890' />"
    }
  },
  POSTAL_CODE: {
    id: 'postalCode',
    key: 'postalCode',
    label: 'Postal Code',
    getHtml: (props) => {
      return "<input type='text' name='zipCode' placeholder='Postal Code' />"
    }
  }
}

export function getDemandNoteFieldSetting(item) {
  const id = item.id ?? item._id
  const options = item.options
  const label = item.label ?? item.name
  const type = item.type ?? 'project'
  const isMulti = item.isMulti ?? false
  const demandNotesId = `${type}-demand-${id}`
  const demandNotesKey = `${type}-demand-${id}`
  const inputType = isMulti ? 'checkbox' : 'radio'
  return {
    id: demandNotesId,
    key: demandNotesKey,
    label,
    // @ts-ignore
    getHtml: (props) => {
      const optionHtml = options.map((option) => {
        const optionId = option.id ?? option._id
        return `<div>
          <input type='${inputType}' name='${demandNotesId}' value='${optionId}'/>
          <label className='m-0 p-0' >${option.label}</label>
        </div>`
      })
      return `<div>
      <div>${props.label}</div>
      <div>${optionHtml.join('')}</div>
    </div>`
    }
  }
}

export const ALL_CUSTOM_FIELDS = [
  SUPPORTED_CUSTOM_FIELDS.FIRST_NAME,
  SUPPORTED_CUSTOM_FIELDS.LAST_NAME,
  SUPPORTED_CUSTOM_FIELDS.EMAIL,
  SUPPORTED_CUSTOM_FIELDS.PHONE,
  SUPPORTED_CUSTOM_FIELDS.POSTAL_CODE
]

export function getKeyForCustomComponent(name) {
  const key = `${name}____`
  return key
}

export function getCustomForCustomField(field, reactComponent, config) {
  const item = {
    id: field.id,
    component: reactComponent,
    setting: {
      key: field.key,
      element: 'CustomElement',
      component: reactComponent,
      type: 'custom',
      forwardRef: true,
      bare: true,
      field_name: getKeyForCustomComponent(field.key),
      name: field.label,
      icon: config.icon,
      props: config.props,
      label: field.label
    }
  }
  return item
}
