import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ADMIN_DASHBOARD_COLUMBIA_REPORT } from 'routes'

const ColumbiaContainer = (props) => {
  const history = useHistory()

  return (
          <div className=" flex flex-col items-center justify-center gap-y-[40xpx] absolute top-20 w-full">
            <div className="text-[56px]">
              <div>British Columbia</div>
            </div>
            <div className="flex gap-x-[30px]">
              <div
                onClick={() => {
                  history.push(ADMIN_DASHBOARD_COLUMBIA_REPORT)
                }}
                className="w-[300px] cursor-pointer bg-[white]  xl:w-[345px] "
              >
                <div>
                  <img src="/center.png" />
                </div>
                <div className="items-center justify-center  py-[23px] flex flex-col gap-y-[10px]">
                  <div className="text-[14px] font-bold">Center</div>
                  <div className="text-[30px] font-bol">18 18 Pacifica</div>
                  <div className="font-bold text-[20px]">Coquitlam</div>
                </div>
              </div>
              <div className="w-[300px] bg-[white]  xl:w-[345px] ">
                <div>
                  <img src="/metro-town.png" />
                </div>
                <div className="items-center justify-center  py-[23px] flex flex-col gap-y-[10px]">
                  <div className="text-[14px] font-bold">Metrotown</div>
                  <div className="text-[30px] font-bol">Pike Square</div>
                  <div className="font-bold text-[20px]">Burnaby</div>
                </div>
              </div>
              <div className="w-[300px] bg-[white]  xl:w-[345px] ">
                <div>
                  <img src="/down-town.png" />
                </div>
                <div className="items-center justify-center  py-[23px] flex flex-col gap-y-[10px]">
                  <div className="text-[14px] font-bold">Downtown</div>
                  <div className="text-[30px] font-bol">Ayu</div>
                  <div className="font-bold text-[20px]">Vancouver</div>
                </div>
              </div>
            </div>
          </div>
  )
}

const mapStateToProps = (state) => ({
  userObject: state.authReducer.userObject
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ColumbiaContainer)
