/* eslint-disable */
import React from 'react'
import UnitListView from 'components/ProjectUnitsInventory/components/UnitListView'

function UnitsList({ units, loading, refetchUnits }) {
  return (
    <div className='flex w-full mt-12'>
      <UnitListView
        units={units}
        gettingUnits={loading}
        type='offerFlow'
        classNames='flex-1 w-full'
        showActionButtonOnHover={false}
        refetchUnits={refetchUnits}
      />
    </div>
  )
}

export default UnitsList
