import React, { useContext } from 'react'
import { Button } from '@material-ui/core'
import CustomModal from 'components/Modal'
import { DynamicDropdownContext } from '../context'

const ConfirmDelete = () => {
  const {
    deleteModal,
    setDeleteModal,
    dataToDelete,
    setDataToDelete,
    deleteItem
  } = useContext(DynamicDropdownContext)
  return (
    <CustomModal isOpen={deleteModal} closeModal={() => setDeleteModal(false)}>
      <div className='flex flex-col justify-center items-center p-5 gap-5'>
        <div className='text-lg font-bold'>
          Are you sure you want to delete?
        </div>
        <div className='flex flex-row gap-4 justify-center items-center'>
          <Button
            onClick={() => {
              setDataToDelete('')
              setDeleteModal(false)
            }}
          >
            No
          </Button>
          <Button
            onClick={() => {
              deleteItem({ name: dataToDelete })
              setDataToDelete('')
              setDeleteModal(false)
            }}
            style={{
              backgroundColor: '#EDDF65',
              width: '100%'
            }}
          >
            Delete
          </Button>
        </div>
      </div>
    </CustomModal>
  )
}

export default ConfirmDelete
