import React, { useState, useEffect, useCallback } from 'react'
import propTypes from 'prop-types'

import { AnimatePresence } from 'framer-motion'
import {
  BuyerPageWithImage, CreateOfferStepWithList, FadedAnimatedDiv,
  CreateOfferStepFullScreenWrapper, CreateOfferStepPrimaryButton, CrateOfferStepTitle, CreateOfferStepSecondaryButton
} from 'components'

const ParkingButtonsWrapper = ({ children }) => <div>{children}</div>

const CreateOfferParkingStep = (props) => {
  const {
    projectData, onSelectParkingOption, selectedUnit,
    selectedParkingOption: externalSelectedParkingOption,
    offerId, pageWithSideImage, titleWrapperClassName,
    titleClassName, buttonsClassName, buttonSize, numberOfItemsToSlide
  } = props
  const { options, totalDollarValueCurrencyType } = projectData

  const [parkingOptions, setParkingOptions] = useState([])
  const [selectedParkingOption, setSelectedParkingOption] = useState('')
  const [showAdditionalParking, setShowAdditionalParking] =
    useState(Boolean(Object.keys(externalSelectedParkingOption).length) || false)
  const [unitParkingQty, setUnitParkingQty] = useState(0)
  const [unitParkingType, setUnitParkingType] = useState('')

  useEffect(() => {
    // Check if has an already selected parking option
    if (Object.keys(externalSelectedParkingOption).length) {
      setShowAdditionalParking(true)
      setSelectedParkingOption(externalSelectedParkingOption)
    }
  }, [externalSelectedParkingOption])

  useEffect(() => {
    async function getParkingOptions () {
      const parkingOptions = options
        .filter((option) => option.type === 'parking')

      setParkingOptions(parkingOptions)
    }

    if (options.length) {
      getParkingOptions()
    }
  }, [options])

  useEffect(() => {
    if (selectedUnit.floorPlan) {
      const { parkingQty, parkingType } = selectedUnit.floorPlan

      setUnitParkingQty(parkingQty)
      setUnitParkingType(parkingType)
    }
  }, [selectedUnit])

  const renderUnitParkingQty = useCallback(() => {
    if (Number(unitParkingQty) > 0) {
      return (
        <>
          <span className='text-3xl text-center'>
            Your purchase of {selectedUnit.unitNumber} includes {unitParkingQty} {unitParkingType}.
          </span>
          <br />
          <span className='text-xl text-center'>Would you like to purchase an additional parking spot?</span>
        </>
      )
    }

    return (
      <>
        <span className='text-3xl text-center'>Your unit has no Parking Spot Included</span>
        <span className='text-xl text-center'>Would you like to purchase a parking spot?</span>
      </>
    )
  }, [unitParkingQty, unitParkingType, selectedUnit.unitNumber])

  const renderDecisor = useCallback(() => {
    let content =
      <div className='flex flex-col items-center justify-start mt-20 md:mt-0 md:justify-center h-full px-10'>
        {
          <>
            <CrateOfferStepTitle className='pb-16'>{selectedUnit.unitNumber}</CrateOfferStepTitle>
            {renderUnitParkingQty()}

            <ParkingButtonsWrapper
              className='flex flex-row items-center justify-center w-full'
            >
              <CreateOfferStepSecondaryButton
                className={`mx-4 md:mx-10 ${buttonsClassName}`}
                onClick={() => onSelectParkingOption(selectedParkingOption)}
                buttonSize={buttonSize}
              >
                No
              </CreateOfferStepSecondaryButton>
              <CreateOfferStepPrimaryButton
                className={`mx-4 md:mx-10 mt-32 ${buttonsClassName}`}
                onClick={() => setShowAdditionalParking(true)}
                buttonSize={buttonSize}
              >
                Yes
              </CreateOfferStepPrimaryButton>
            </ParkingButtonsWrapper>
          </>
        }

      </div>

    if (!Object.keys(selectedUnit).length) {
      content =
        <div className='flex flex-col items justify-center text-center mt-20'>
          <span className='text-2xl'>Parking</span>
          <span className='text-xl mt-3'>Please, select the Unit</span>
          <span className='text-xl'>Then you will be able to select the Parking</span>
        </div>
    }

    if (pageWithSideImage) {
      return (
        <BuyerPageWithImage
          image={projectData && projectData.images[1]}
        >
          {content}
        </BuyerPageWithImage>
      )
    }

    return content
  }, [
    pageWithSideImage, projectData, onSelectParkingOption, renderUnitParkingQty,
    selectedParkingOption, buttonsClassName, buttonSize, selectedUnit
  ])

  return (
    <CreateOfferStepFullScreenWrapper>
      <AnimatePresence exitBeforeEnter initial={false}>
        {
          !showAdditionalParking
            ? (
                <FadedAnimatedDiv
                  key='loadingColorSchemes'
                  className='h-full'
                >
                  {renderDecisor()}
                </FadedAnimatedDiv>
              )
            : (
                <FadedAnimatedDiv key='parkingOptions' className='h-full flex flex-col md:justify-around px-5 md:px-18'>
                  <CreateOfferStepWithList
                    title='Additional Parking Options'
                    continueButtonText={selectedParkingOption ? 'Continue' : 'Skip This Step'}
                    continueButtonOnClick={() => onSelectParkingOption(selectedParkingOption)}
                    continueButtonDisabled={false}
                    items={parkingOptions}
                    optionSelected={selectedParkingOption}
                    onSelectOption={({ item: colorScheme }) => setSelectedParkingOption(colorScheme)}
                    totalDollarValueCurrencyType={totalDollarValueCurrencyType}
                    offerId={offerId}
                    titleWrapperClassName={titleWrapperClassName}
                    titleClassName={titleClassName}
                    continueButtonClassName={buttonsClassName}
                    buttonSize={buttonSize}
                    numberOfItemsToSlide={numberOfItemsToSlide}
                  />
                </FadedAnimatedDiv>
              )
        }
      </AnimatePresence>
    </CreateOfferStepFullScreenWrapper>
  )
}

export default CreateOfferParkingStep

CreateOfferParkingStep.defaultProps = {
  pageWithSideImage: true,
  titleWrapperClassName: '',
  titleClassName: '',
  buttonsClassName: '',
  buttonSize: 'large',
  numberOfItemsToSlide: 3
}

CreateOfferParkingStep.propTypes = {
  projectData: propTypes.object,
  onSelectParkingOption: propTypes.func,
  totalDollarValueCurrencyType: propTypes.string,
  selectedUnit: propTypes.object,
  selectedParkingOption: propTypes.object,
  offerId: propTypes.string,
  pageWithSideImage: propTypes.bool,
  titleWrapperClassName: propTypes.string,
  titleClassName: propTypes.string,
  buttonsClassName: propTypes.string,
  buttonSize: propTypes.string,
  numberOfItemsToSlide: propTypes.number
}

ParkingButtonsWrapper.propTypes = {
  children: propTypes.node
}
