import { Select } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'

const TableHeader = ({
  headers = ['Header1', 'Header2', 'Header3'],
  handleSelectHeader,
  selectedHeader = 'Selected Header'
}) => {
  // @ts-ingore
  const [selectedIndex, setSelectedIndex] = useState(0)

  const handleChange = e => {
    handleSelectHeader(e.target.value)
    setSelectedIndex(headers.indexOf(e.target.value))
  }

  useEffect(() => {
    // @ts-ignore
    if (window.openTasksTab) {
      setSelectedIndex(headers.indexOf(selectedHeader))
      // @ts-ignore
      window.openTasksTab = false
    }
  }, [setSelectedIndex, selectedHeader, headers])

  // eslint-disable-next-line curly
  if (isMobile) return (
    <div className='w-full p-4'>
      <Select variant='outlined' fullWidth value={headers[selectedIndex]} onChange={handleChange}>
        {headers.map(header => <option className='p-2' key={header} value={header}>{header}</option>)}
      </Select>
    </div>
  )
  return (
    <div className='w-full'>
      <div className='flex'>
        <div className='border-r border-black'/>
        {headers.map((header, index) => {
          const selected = index === selectedIndex
          return (
            <div
              key={index}
              className={`cursor-pointer px-4 py-2 text-center border-r border-t border-b border-l border-black text-xs ${
                selected ? 'text-white' : 'text-gray-700'
              } font-semibold grow ${selected && 'bg-softBlack'}`}
              onClick={() => {
                handleSelectHeader(header)
                setSelectedIndex(index)
              }}>
              {header}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default TableHeader
