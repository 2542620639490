import { Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/solid'
// import { DesktopMenuWrapper } from './styles'
import { TCancelButton, TSaveButton } from 'components/DefaultButtons'
import { AnimatePresence, motion } from 'framer-motion'
import propTypes from 'prop-types'
import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { sendEmail } from 'store/actions/usersActions'
import { Modal } from 'ui'
import { classNames, sanitizeCode } from 'utils'
import { TitleText } from './styles'

const EmailParamsWrapper = memo(({ title, children, className }) => (
  <div className='flex flex-row w-full px-3 py-1 text-gray-400 text-base'>
    <span className='mr-1'>{title}</span>
    <div className={classNames('w-full', className)}>{children}</div>
  </div>
))

const EmailParams = memo(({ title, value, onChange }) => (
  <EmailParamsWrapper title={title}>
    <input disabled={!onChange} value={value} onChange={(e) => onChange(e.target.value)} className='border-none text-gray-500 outline-none' />
  </EmailParamsWrapper>
))

const Title = memo(({ emailSubject }) => (
  <div className='flex flex-col w-full bg-indigo-600 py-2 px-4 text-white'>
      <TitleText className='text-lg'>{`New email ${emailSubject && ` - ${emailSubject}`}`}</TitleText>
    </div>
))

const Footer = memo(({ onCancelClick, onSendClick, sending }) => {
  if (onCancelClick || onSendClick) {
    return (
      <div className="flex-shrink-0 px-4 py-4 flex justify-end border-t">
        <TCancelButton onClick={onCancelClick}>Cancel</TCancelButton>
        <TSaveButton disabled={!onSendClick} loading={sending} onClick={onSendClick} className='ml-5'>Send</TSaveButton>
      </div>
    )
  }

  return null
})

const EmailRecipientItem = memo(({
  recipientData: { name, email, allowToChange, type },
  onAddOrEditRecipient, onRemoveRecipient
}) => {
  const newRecipientInput = type === 'newRecipientInput'

  const [showRemoveEmailIcon, setShowRemoveEmailIcon] = useState(false)
  const [recipientEmail, setRecipientEmail] = useState('')
  const [originalRecipientEmail, setOriginalRecipientEmail] = useState('')

  useEffect(() => {
    const recipientEmail = newRecipientInput ? '' : email

    setRecipientEmail(recipientEmail)
    setOriginalRecipientEmail(recipientEmail)
  }, [email, newRecipientInput])

  const createNewRecipient = useCallback((email, action) => {
    if ((action === 'add' && email) || (email !== originalRecipientEmail)) {
      onAddOrEditRecipient(email, action, originalRecipientEmail)

      setRecipientEmail('')

      setTimeout(() => {
        const input = document.getElementById('newRecipientInput')
        if (input) input.focus()
      }, 500)
    }
  }, [onAddOrEditRecipient, originalRecipientEmail])

  return (
    <div
      className={
        classNames(
          'rounded-md relative',
          allowToChange ? 'hover:cursor-pointer' : 'hover:cursor-default',
          newRecipientInput && !recipientEmail ? 'text-gray-400' : 'bg-indigo-600 text-white'
        )}
      onMouseEnter={() => setShowRemoveEmailIcon(true)}
      onMouseLeave={() => setShowRemoveEmailIcon(false)}
    >
      <AnimatePresence exitBeforeEnter>
        {
          showRemoveEmailIcon && allowToChange &&
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0 }}
            onClick={() => onRemoveRecipient(email)}
            className='absolute h-5 w-5 rounded-full -top-1 -right-2 flex items-center justify-center bg-red-500'
          >
            <div>
              <XIcon className='h-3 w-3 text-white bg-red-500' />
            </div>
          </motion.div>
        }
      </AnimatePresence>
      <input
        id={newRecipientInput ? 'newRecipientInput' : recipientEmail}
        disabled={!allowToChange && !newRecipientInput}
        className='bg-transparent text-center outline-none border-none px-1.5'
        value={recipientEmail}
        onChange={(e) => setRecipientEmail(e.target.value)}
        placeholder='add new recipient'
        onKeyUp={(e) => e.key === 'Enter' && e.target.blur() }
        onBlur={() => createNewRecipient(recipientEmail, newRecipientInput ? 'add' : 'edit')}
      />
    </div>
  )
})

const EmailRecipient = memo(({ title, emailRecipient, onAddOrEditRecipient, onRemoveRecipient }) => {
  return (
  <EmailParamsWrapper title={title} className='grid grid-cols-3 gap-3'>
      {emailRecipient.map(recipient =>
        <EmailRecipientItem
        recipientData={recipient}
        onAddOrEditRecipient={onAddOrEditRecipient}
        onRemoveRecipient={onRemoveRecipient}
        />
      )}
    </EmailParamsWrapper>
  )
})

const EmailBox = ({
  emailSender, emailRecipient, emailSubject, onChangeEmailSubject, emailBody,
  onChangeEmailBody, onCancelClick, onSendClick, sending, onAddOrEditRecipient,
  onRemoveRecipient
}) => (
  <div className='h-full flex flex-col relative'>
    <Title emailSubject={emailSubject} />
    <div className='pt-5 pb-4 flex flex-1 h-full flex-col overflow-y-auto divide-y divide-gray-200'>
      <EmailParams title='From:' value={emailSender} />
      <EmailRecipient
        title='To:'
        emailRecipient={emailRecipient}
        onAddOrEditRecipient={onAddOrEditRecipient}
        onRemoveRecipient={onRemoveRecipient}
      />
      <EmailParams title='Subject:' value={emailSubject} onChange={onChangeEmailSubject} />
      <div />
      <div
        id='email_body'
        contentEditable={true}
        dangerouslySetInnerHTML={{ __html: sanitizeCode(emailBody) }}
        className='h-full w-full focus:outline-none focus:ring-0 px-2 overflow-auto'
      />
    </div>
    <Footer onCancelClick={onCancelClick} onSendClick={onSendClick} sending={sending} />
  </div>
)

const FloatingEmailBox = (props) => {
  const {
    showEmailBox, emailSender, emailRecipient,
    emailBody, emailSubject, onCancelClick,
    executeAfetSendEmail
  } = props

  const [localEmailRecipient, setLocalEmailRecipient] = useState(emailRecipient)
  const [localEmailBody, setLocalEmailBody] = useState(emailBody)
  const [localEmailSubject, setLocalEmailSubject] = useState(emailSubject)
  const [sendingEmail, setSendingEmail] = useState(false)
  const [showConfirmSendingEmailModal, setShowConfirmSendingEmailModal] = useState(false)

  const refLocalEmailRecipient = useRef(localEmailRecipient)

  const updateLocalEmailRecipient = (newValue) => {
    refLocalEmailRecipient.current = newValue
    setLocalEmailRecipient(newValue)
  }

  useEffect(() => {
    if (emailBody) setLocalEmailBody(emailBody)
  }, [emailBody])

  useEffect(() => {
    if (emailSubject) setLocalEmailSubject(emailSubject)
  }, [emailSubject])

  useEffect(() => {
    updateLocalEmailRecipient(emailRecipient)
  }, [emailRecipient])

  const onChangeEmailSubject = useCallback((value) => {
    setLocalEmailSubject(value)
  }, [])

  const onChangeEmailBody = useCallback((value) => {
    setLocalEmailBody(value)
  }, [])

  const _sendEmail = useCallback(() => {
    setSendingEmail(true)
    setShowConfirmSendingEmailModal(false)

    const emailBody = sanitizeCode(document.getElementById('email_body').innerHTML)

    const recipients =
      refLocalEmailRecipient.current
        .filter(recipient => recipient.type !== 'newRecipientInput')
        .map(({ name, email }) => ({ name, email }))

    sendEmail(emailBody, localEmailSubject, recipients)
      .then(() => {
        setSendingEmail(false)
        if (executeAfetSendEmail) executeAfetSendEmail()
      })
      .catch(() => setSendingEmail(false))
  }, [localEmailSubject, executeAfetSendEmail])

  const checkBeforeSend = useCallback(() => {
    if (!localEmailSubject) {
      setShowConfirmSendingEmailModal(true)
    } else {
      _sendEmail()
    }
  }, [localEmailSubject, _sendEmail])

  const onCancelModal = useCallback(() => {
    setShowConfirmSendingEmailModal(false)
  }, [])

  const cleanValues = useCallback(() => {
    setLocalEmailBody('')
    updateLocalEmailRecipient([])
    setLocalEmailSubject('')
    setSendingEmail(false)
  }, [])

  const addNewRecipientOption = useCallback(() => {
    const tmpRecipients = [...refLocalEmailRecipient.current]
    tmpRecipients.push({ name: 'New Recipient', email: 'new recipient', type: 'newRecipientInput' })

    updateLocalEmailRecipient(tmpRecipients)
  }, [])

  const onAddOrEditRecipient = useCallback((email, action, originalRecipientEmail) => {
    if (action === 'add' || (email !== originalRecipientEmail)) {
      let tmpRecipients = [...refLocalEmailRecipient.current]

      // If is editing the items
      if (action === 'edit') {
        tmpRecipients = tmpRecipients.map(recipient =>
          Object.assign({}, recipient, {
            email: recipient.email === originalRecipientEmail ? email : recipient.email,
            name: recipient.email === originalRecipientEmail ? email : recipient.name
          }))
      } else {
        // If is adding an new email recipient
        tmpRecipients.splice(tmpRecipients.length - 1, 1, { name: email, email, allowToChange: true })
      }

      tmpRecipients = tmpRecipients.filter(recipient => recipient.type !== 'newRecipientInput')

      updateLocalEmailRecipient(tmpRecipients)
      addNewRecipientOption()
    }
  }, [addNewRecipientOption])

  const onRemoveRecipient = useCallback((email) => {
    let tmpRecipients = [...refLocalEmailRecipient.current]

    tmpRecipients = tmpRecipients.filter(recipient => recipient.email !== email)

    updateLocalEmailRecipient(tmpRecipients)
  }, [])

  return (
    <Transition
      show={showEmailBox}
      afterEnter={addNewRecipientOption}
      afterLeave={() => {
        setTimeout(() => {
          cleanValues()
        }, 500)
      }}
    >
      <Modal
        showModal={showConfirmSendingEmailModal}
        title='Confirm sending the email?'
        subtitle="your email doesn't seem to have any subject, would you like to send it anyway?"
        modalType='alert'
        onOk={_sendEmail}
        onCancel={onCancelModal}
      />

      <motion.div
        id='animatedWrapper'
        drag
        dragMomentum={false}
        dragPropagation={false}
        className='fixed max-w-xl left-0 flex z-500 max-h-lg h-full'
        // initial={{ x: -1000 }}
      >
        <span className='hidden sm:inline-block sm:align-middle sm:h-screen' aria-hidden='true'>&#8203;</span>
        <Transition.Child
          enter='ease-out duration-300'
          enterFrom='opacity-0 scale-95'
          enterTo='opacity-100 scale-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100 scale-100'
          leaveTo='opacity-0 scale-95'
          className='h-full align-bottom rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden
          transform transition-all sm:align-middle w-full sm:p-6 flex items-center'
          role='dialog'
        >
          <div
            className='h-full inline-block align-bottom bg-white rounded-lg text-left overflow-hidden
            shadow-2xl transform transition-all sm:align-middle w-full'
            role='dialog'
            aria-modal='true'
            aria-labelledby='modal-headline'
          >
            <div className={'h-full bg-white overflow-auto'}>
              <EmailBox
                emailSender={emailSender}
                emailRecipient={refLocalEmailRecipient.current || ''}
                emailSubject={localEmailSubject}
                onChangeEmailSubject={onChangeEmailSubject}
                emailBody={localEmailBody}
                onChangeEmailBody={onChangeEmailBody}
                onCancelClick={onCancelClick}
                onSendClick={checkBeforeSend}
                sending={sendingEmail}
                onAddOrEditRecipient={onAddOrEditRecipient}
                onRemoveRecipient={onRemoveRecipient}
              />
            </div>
          </div>
        </Transition.Child>
      </motion.div>
    </Transition>
  )
}

export default FloatingEmailBox

FloatingEmailBox.defaultProps = {
  closeButtonColor: 'text-white',
  fixed: true,
  position: 'left',
  title: '',
  subtitle: '',
  allowToPopup: false,
  onCancelClick: false,
  onSendClick: false,
  initPoppedOut: false
}

FloatingEmailBox.propTypes = {
  showEmailBox: propTypes.bool,
  emailSender: propTypes.string,
  emailRecipient: propTypes.oneOfType([propTypes.array, propTypes.string]),
  emailBody: propTypes.string,
  emailSubject: propTypes.string,
  onCancelClick: propTypes.func,
  executeAfetSendEmail: propTypes.func

}

EmailParamsWrapper.propTypes = {
  title: propTypes.string,
  children: propTypes.node,
  className: propTypes.string
}

EmailParams.propTypes = {
  title: propTypes.string,
  value: propTypes.string,
  onChange: propTypes.func
}

Title.propTypes = {
  emailSubject: propTypes.string
}

Footer.propTypes = {
  onCancelClick: propTypes.func,
  onSendClick: propTypes.func,
  sending: propTypes.bool
}

EmailBox.propTypes = {
  emailSender: propTypes.string,
  emailRecipient: propTypes.oneOfType([propTypes.array, propTypes.string]),
  emailSubject: propTypes.string,
  onChangeEmailSubject: propTypes.func,
  emailBody: propTypes.string,
  onChangeEmailBody: propTypes.func,
  onCancelClick: propTypes.func,
  onSendClick: propTypes.func,
  sending: propTypes.bool,
  onAddOrEditRecipient: propTypes.func,
  onRemoveRecipient: propTypes.func
}

EmailRecipient.propTypes = {
  title: propTypes.string,
  emailRecipient: propTypes.oneOfType([propTypes.array, propTypes.string]),
  onAddOrEditRecipient: propTypes.func,
  onRemoveRecipient: propTypes.func
}

EmailRecipientItem.propTypes = {
  recipientData: propTypes.shape({
    name: propTypes.string,
    email: propTypes.string,
    allowToChange: propTypes.bool,
    type: propTypes.string
  }),
  onAddOrEditRecipient: propTypes.func,
  onRemoveRecipient: propTypes.func
}
