import React from 'react'
import { Box, CircularProgress } from '@material-ui/core'

const Loader = ({ height = '100%', width = '100%' }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      height={height}
      width={width}
    >
      <CircularProgress />
    </Box>
  )
}

export default Loader
