import { PageHeader } from 'components'
import React from 'react'

const SalesTransaction = () => {
  return (
    <div>
      <PageHeader title='Sales Transaction' />
    </div>
  )
}

export default SalesTransaction
