/*eslint-disable */
// @ts-nocheck
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import DeleteIcon from 'components/Icons/DeleteIcon';
import useContactContext from 'pages/newContacts/hooks/useContactContext';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { parsePhoneNumber } from 'libphonenumber-js'
const TableBody = ({ contacts }) => {
  const { setSelectedContact, state, toggleCheckContact } = useContactContext();
  const history = useHistory();
  // @ts-ignore
  const userObject = useSelector((state) => state.authReducer.userObject);
  const columns = useCallback(
    (userType) => {
      const salesRepKey =
        userType?.toLowerCase?.() === 'buyer' ? 'buyerData' : 'leadBrokerData';
      const result =
        state.isContactDetailDrawerOpen || isMobile
          ? [{ name: 'Name', property: 'firstName', salesRepKey }]
          : !state.isContactDetailDrawerOpen
          ? [
              {
                name: 'Date Registered',
                property: 'dateRegistered',
                isSort: true,
              },
              { name: 'Name', property: 'firstName' },
              { name: 'User Type', property: 'userType' },
              { name: 'Email', property: 'email' },
              {
                name: 'Phone Number',
                property: { name: salesRepKey, property: 'phoneNumber' },
              },
              {
                name: 'Sales Rep Owner',
                property: { name: salesRepKey, property: 'salesRep' },
              },
              {
                name: 'Buyer Score',
                property: { name: salesRepKey, property: 'rating' },
              },
              {
                name: 'Lead Status',
                property: { name: salesRepKey, property: 'leadStatus' },
              },
            ]
          : [
              {
                name: 'Date Registered',
                property: 'dateRegistered',
                isSort: true,
              },
              { name: 'Name', property: 'firstName' },
              {
                name: 'Lead Status',
                property: { name: salesRepKey, property: 'leadStatus' },
              },
              { name: 'User Type', property: 'userType' },
              { name: 'Email', property: 'email' },
            ];
      return result;
    },
    [state.selectedContact, state.isAddToListDrawerOpen]
  );

  const getDeleteButton = () => (
    <button>
      <DeleteIcon height={20} width={20} />
    </button>
  );
  const formatPhoneNumber = (phoneNumberString) => {
    if (!phoneNumberString) return '';
    try {
      const phoneNumber = parsePhoneNumber(phoneNumberString, 'US', true);
      if (phoneNumber && phoneNumber.isValid()) {
        return phoneNumber.formatInternational();
      }
    } catch (error) {
      console.error(`Error formatting phone number: ${error.message}`);
    }
    return phoneNumberString;
  };
  const getStringFromProperty = (contact, col) => {
    if (col.name === 'Phone Number') return '-';
    if (col?.property?.name === 'Delete Button') return getDeleteButton();
    if (contact?.userType === 'LeadBroker') {
      return contact?.leadBrokerData?.assignments?.[0]?.salesRep?.firstName;
    }
    if (userObject?.userType !== 'SalesRep') {
      return (
        contact[col?.property?.name]?.assignments[0]?.salesRep?.firstName || '-'
      );
    }
    const assignemnts = contact[col?.property?.name]?.assignments;
    if (!assignemnts?.length) return '';
    const found = assignemnts?.some?.(
      (assignment) => assignment?.salesRep?._id === userObject?._id
    );
    if (!found) {
      return contact[col?.property?.name]?.assignments[0]?.salesRep?.firstName;
    }
    return userObject?.firstName;
  };

  const leadStatusBadgeColorFinder = useCallback((title) => {
    if (title?.includes('New')) {
      return {
        text: '#03543F',
        bg: '#DEF7EC',
      };
    }
    if (title?.includes('Active')) {
      return {
        text: '#5521B5',
        bg: '#EDEBFE',
      };
    }
    if (title?.includes('Opportunity')) {
      return {
        text: '#1E429F',
        bg: '#E1EFFE',
      };
    }
    if (title?.includes('Reservation')) {
      return {
        text: '#42389D',
        bg: '#E5EDFF',
      };
    }
    if (title?.includes('Owner')) {
      return {
        text: '#723B13',
        bg: '#FDF6B2',
      };
    }
    if (title?.includes('Lost')) {
      return {
        text: '#9B1C1C',
        bg: '#FDE8E8',
      };
    }
    return {
      text: '#111928',
      bg: '#F3F4F6',
    };
  }, []);

  const LeadStatusBadge = ({ title, haveDotIcon = false }) => {
    const badgeColor = leadStatusBadgeColorFinder(title);
    const badgeStyle = {
      backgroundColor: badgeColor?.bg,
      color: badgeColor?.text,
    };
    if (badgeColor) {
      return (
        <div
          className='px-3 py-0.5 rounded-md justify-center items-center gap-1 inline-flex'
          style={badgeStyle}
        >
          {haveDotIcon && (
            <div
              className='w-2 h-2 rounded-full'
              style={{
                backgroundColor: badgeColor?.text,
              }}
            />
          )}
          <div className='text-center text-xs font-normal'>{title}</div>
        </div>
      );
    }
    return null;
  };

  useEffect(() => {
    const id = history.location.pathname.split('/').pop();
    if (id !== ':id' && id && id !== 'newcontacts') {
      const matched = contacts.find((contact) => {
        return contact._id === id;
      });
      setSelectedContact(matched);
    }
  }, [history.location]);

  return (
    <tbody className='bg-white'>
      {/* <DeleteFromListModal isOpen={openDelFromListModal} contact={targetContact} onClose={() => setOpenDeleteFromListModal(false)} /> */}
      {contacts.map((contact, contactIndex) => (
        <tr
          key={contactIndex}
          className={`${
            state.isContactDetailDrawerOpen &&
            state.selectedContact?.id === contact.id &&
            !isMobile
              ? 'bg-[#EDDF65]/[35%] ring-1 ring-[#EDDF65]'
              : 'ring-[0.5px] ring-softBlack_70/10'
          }`}
        >
          {columns(contact.userType).map((col, columnIndex) => (
            <React.Fragment key={`${contactIndex}-${columnIndex}`}>
              {columnIndex === 0 ? (
                <td
                  key={`${contactIndex}-${columnIndex - 1}`}
                  className={`relative ${
                    !state.isContactDetailDrawerOpen || isMobile
                      ? 'px-3 md:px-6'
                      : 'p-4'
                  } w-12`}
                >
                  {(!state.isContactDetailDrawerOpen || isMobile) && (
                    <div className='absolute inset-y-0 left-0 w-0.5 bg-indigo-600'></div>
                  )}
                  <input
                    data-id={`${contact._id}`}
                    onChange={
                      (e) => toggleCheckContact(contact?._id)
                      // handleSaveList(e, contact, columnIndex, contactIndex)
                    }
                    checked={state.checkedContacts?.[contact?._id] ?? false}
                    type='checkbox'
                    className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6 hover:cursor-pointer'
                  />
                </td>
              ) : null}
              {
                // @ts-ignore
                col.property.name === 'Delete Button' ? (
                  <td
                    style={{ textAlign: 'left', cursor: 'pointer' }}
                    className='whitespace-nowrap w-25 pr-3 text-sm font-medium text-gray-900'
                    // @ts-ignore
                    // onClick={() => openDeleteFromListModal(contact)}
                  >
                    {typeof col.property === 'string' || isMobile ? (
                      col.property === 'firstName' ? (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '16px',
                          }}
                        >
                          <div className='rounded-full bg-gray-200 text-softBlack p-3 my-2 w-14 h-14 flex justify-center items-center font-sans hover:shadow hover:cursor-pointer'>
                            {`${contact.firstName?.[0] ?? ''}${
                              contact?.lastName?.[0] ?? ''
                            }`}
                          </div>
                          {(
                            contact[col.property] + ' ' + contact?.lastName ??
                            ''
                          ).slice(0, 25)}
                        </div>
                      ) : (
                        contact[col.property]?.trim()
                      )
                    ) : typeof contact[col.property.name]?.[
                        // @ts-ignore
                        col.property.property
                      ] === 'string' ? (
                      // @ts-ignore
                      contact[col.property.name]?.[col.property.property]
                    ) : (
                      getStringFromProperty(contact, col)
                    )}
                  </td>
                ) : (
                  <td
                    style={{ textAlign: 'left', cursor: 'pointer' }}
                    className={`whitespace-nowrap w-25 text-sm font-medium text-gray-900 ${
                      !state.isContactDetailDrawerOpen || isMobile
                        ? 'pr-3'
                        : 'w-[150px] p-4 px-0.5'
                    }`}
                    onClick={() => {
                      setSelectedContact(contact);
                      history.push(
                        `/admin/newcontacts/${contact._id}?tab=activity`
                      );
                    }}
                  >
                    {typeof col.property === 'string' ? (
                      col.property === 'firstName' ? (
                        <div className='flex items-center gap-3 md:gap-4'>
                          <div className='rounded-full bg-gray-200 text-softBlack p-3 my-2 w-14 h-14 flex justify-center items-center font-sans hover:shadow hover:cursor-pointer'>
                            {`${contact.firstName?.[0] ?? '-'}${
                              contact?.lastName?.[0] ?? '-'
                            }`}
                          </div>
                          <div className='flex flex-col gap-2'>
                            <div>
                              {(
                                contact[col.property] +
                                  ' ' +
                                  contact?.lastName ?? ''
                              ).slice(0, 18)}
                              {(contact[col.property] + ' ' + contact?.lastName)
                                .length > 18 && '...'}
                            </div>
                            {(state.isContactDetailDrawerOpen || isMobile) && (
                              <>
                                <div className='flex gap-1 items-center'>
                                  <span className='text-xs'>User Type:</span>
                                  <div className='px-3 py-0.5 rounded-md justify-center items-center gap-1 inline-flex bg-[#F3F4F6]'>
                                    <div className='text-center text-xs font-medium'>
                                      {contact.userType}
                                    </div>
                                  </div>
                                </div>
                                <div className='flex gap-1 items-center'>
                                  <span className='text-xs'>Lead Status:</span>
                                  <LeadStatusBadge
                                    title={contact[col.salesRepKey]?.leadStatus}
                                    haveDotIcon
                                  />
                                </div>
                                <div className='text-xs text-zinc-400'>
                                  {contact.dateRegistered}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className='w-full text-center md:text-left'>
                          {contact[col.property]?.trim()}
                        </div>
                      )
                    ) : typeof contact[col.property.name]?.[
                        // @ts-ignore
                        col.property.property
                      ] === 'string' ? (
                      <>
                        {col.property.property === 'leadStatus' &&
                        state.isContactDetailDrawerOpen ? (
                          <LeadStatusBadge
                            title={
                              contact[col.property.name]?.[
                                col.property.property
                              ]
                            }
                          />
                        ) : col.property.property === 'phoneNumber' &&
                          !state.isContactDetailDrawerOpen ? (
                            formatPhoneNumber(contact[col.property.name]?.[col.property.property])
                        ) : (
                          contact[col.property.name]?.[col.property.property]
                        )}
                      </>
                    ) : (
                      getStringFromProperty(contact, col)
                    )}
                  </td>
                )
              }
            </React.Fragment>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

export default TableBody;
