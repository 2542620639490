import React, { useState } from 'react'
import propTypes from 'prop-types'
import CustomButton from 'components/CustomButton'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import CustomSelect from 'components/Inventory/components/CustomSelect'
import { isMobile } from 'react-device-detect'

const calendarViewOptions = [
  {
    value: 'month',
    label: 'Month view'
  },
  {
    value: 'week',
    label: 'Week view'
  },
  {
    value: 'day',
    label: 'Day view'
  },
  {
    value: 'agenda',
    label: 'Agenda view'
  }
]

const NavigateButtons = (props) => {
  return (
    <div className="order-2 md:order-1 w-full md:w-fit flex items-center gap-2 md:gap-3">
      <CustomButton handleClick={() => props.onNavigate('PREV')}>
        <span className='font-medium p-2 text-xs md:text-sm flex gap-1 items-center'>
          <ChevronLeft className='w-4 h-4 md:w-5 md:h-5' />
          {/* Back */}
        </span>
      </CustomButton>
      <CustomButton className='flex-1' handleClick={() => props.onNavigate('TODAY')}>
        <span className='font-medium p-2 px-4 text-xs md:text-sm flex gap-1 items-center'>
          Today
        </span>
      </CustomButton>
      <CustomButton handleClick={() => props.onNavigate('NEXT')}>
        <span className='font-medium p-2 text-xs md:text-sm flex gap-1 items-center'>
          <ChevronRight className='w-4 h-4 md:w-5 md:h-5' />
          {/* Next */}
        </span>
      </CustomButton>
    </div>
  )
}

const SelectedLabel = (props) => {
  const [selectedCalendarView, setSelectedCalendarView] = useState('month')

  return (
    <div className='order-1 md:order-2 w-full md:flex-1 flex justify-between items-center'>
      <span className="w-full md:w-fit flex-grow md:px-2.5 md:text-center text-[22px] md:text-2xl">{props.label}</span>
      <div className='block md:hidden w-40'>
        <CustomSelect
          value={selectedCalendarView}
          handleChange={(newValue) => {
            props.onView(newValue)
            setSelectedCalendarView(newValue)
          }}
          options={calendarViewOptions}
          placeholder='Select View'
        />
      </div>
    </div>
  )
}

const PeriodFormat = (props) => (
  <div className="order-3 flex items-center gap-2 md:gap-3">
    <CustomButton handleClick={() => props.onView('month')}>
      <span className='font-medium p-2 px-4 text-xs md:text-sm flex gap-1 items-center'>
        Month
      </span>
    </CustomButton>
    <CustomButton handleClick={() => props.onView('week')}>
      <span className='font-medium p-2 px-4 text-xs md:text-sm flex gap-1 items-center'>
        Week
      </span>
    </CustomButton>
    <CustomButton handleClick={() => props.onView('day')}>
      <span className='font-medium p-2 px-4 text-xs md:text-sm flex gap-1 items-center'>
        Day
      </span>
    </CustomButton>
    <CustomButton handleClick={() => props.onView('agenda')}>
      <span className='font-medium p-2 px-4 text-xs md:text-sm flex gap-1 items-center'>
        Agenda
      </span>
    </CustomButton>
  </div>
)

const CalendarToolbar = (props) => (
  <div className="flex flex-col md:flex-row gap-2 flex-wrap justify-center items-center m-2">
    <NavigateButtons {...props} />
    <SelectedLabel {...props} />
    {
      !isMobile &&
      <PeriodFormat {...props} />
    }
  </div>
)

export default CalendarToolbar

CalendarToolbar.propTypes = {}

SelectedLabel.propTypes = {
  label: propTypes.string
}

NavigateButtons.propTypes = {
  onNavigate: propTypes.func
}

PeriodFormat.propTypes = {
  onView: propTypes.func
}
