import useDynamicDropdown from './useDynamicDropdown'

const useBuyerScore = () => {
  const {
    data: scores,
    loading,
    update,
    deleteItem: deleteLeadStatus,
    updateItem: updateSpecific,
    formattedData: formattedScores
  } = useDynamicDropdown('buyerScore')

  return {
    scores,
    loading,
    update,
    deleteLeadStatus,
    updateSpecific,
    formattedScores
  }
}

export default useBuyerScore
