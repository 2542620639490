import React, { memo, useCallback, useEffect, useState } from 'react'
import propTypes from 'prop-types'
import {
  OptionsWrapper,
  OptionWrapper,
  PageWrapper,
  TitleWrapper,
  OptionSwitchWrapper,
  OptionDescriptionWrapper
} from './styles'
import { TSwitch } from 'ui'
import { FadedAnimatedDiv, LoadingIcon, OfferFooterButtons } from 'components'
import { getUsers } from 'store/actions/usersActions'
import { AnimatePresence } from 'framer-motion'
import { isObject } from 'utils'
import useUpdateUsers from 'hooks/useUpdateUsers'

const Option = memo(({ description, onChange, checked }) => (
  <OptionWrapper className='flex flex-row px-20'>
    <OptionSwitchWrapper className='w-3/20 flex justify-center'>
      <TSwitch checked={checked} onClick={onChange} />
    </OptionSwitchWrapper>
    <OptionDescriptionWrapper>
      <span className='text-lg'>{description}</span>
    </OptionDescriptionWrapper>
  </OptionWrapper>
))

const LOADING_BUYER = 'loading_buyer'
const SHOWING_OPTIONS = 'showing_options'
const NO_BUYER_SELECTED = 'no_buyer_selected'

const PrivacyConsentStep = ({ selectedBuyers, onClickContinue }) => {
  const [allowAdditionalServices, setAllowAdditionalServices] = useState(false)
  const [allowSurveys, setAllowSurveys] = useState(false)
  const [savingBuyer, setSavingBuyer] = useState(false)
  const [formStatus, setFormStatus] = useState('')
  const { createOrUpdateUser } = useUpdateUsers()

  const onChangeAdditionalServicesOptions = useCallback(() => {
    setAllowAdditionalServices(!allowAdditionalServices)
  }, [allowAdditionalServices])

  const onChangeParticipageInSurveys = useCallback(() => {
    setAllowSurveys(!allowSurveys)
  }, [allowSurveys])

  const getBuyerInfos = useCallback(() => {
    if (!selectedBuyers.length || (selectedBuyers.length === 1 && selectedBuyers[0]._id === -1)) {
      setFormStatus(NO_BUYER_SELECTED)
    } else {
      const buyerId = selectedBuyers[0]._id

      setFormStatus(LOADING_BUYER)

      getUsers(buyerId)
        .then((buyer) => {
          if (buyer && buyer.buyerData && Object.keys(buyer.buyerData).length) {
            const { allowSurveys, allowAdditionalServices } = buyer.buyerData

            setAllowSurveys(allowSurveys)
            setAllowAdditionalServices(allowAdditionalServices)
          }

          setFormStatus(SHOWING_OPTIONS)
        })
    }
  }, [selectedBuyers])

  useEffect(() => {
    getBuyerInfos()
  }, [getBuyerInfos])

  const saveBuyer = useCallback(() => {
    setSavingBuyer(true)

    const allUpdates = selectedBuyers.map(async (buyer) => {
      // must get all the buyer infos first, to avoid erasing user data
      const allBuyerInfos = await getUsers(buyer._id)

      if (allBuyerInfos && isObject(allBuyerInfos) && Object.keys(allBuyerInfos).length) {
        const newUserParams = { ...allBuyerInfos }

        newUserParams.id = newUserParams._id
        newUserParams.buyerData = Object.assign({}, { ...allBuyerInfos.buyerData }, { allowSurveys, allowAdditionalServices })

        await createOrUpdateUser(newUserParams, false)
          .catch(() => {
            setSavingBuyer(false)
          })
      }
    })

    Promise.all(allUpdates)
      .then(() => {
        setSavingBuyer(false)
        if (onClickContinue) onClickContinue({ allowSurveys, allowAdditionalServices })
      })
  }, [allowAdditionalServices, allowSurveys, onClickContinue, selectedBuyers])

  return (
    <PageWrapper className='h-full w-full flex flex-col'>
      <TitleWrapper className='w-full text-center py-11'>
        <span className='text-2xl'>Privacy and Consents</span>
      </TitleWrapper>

      <AnimatePresence exitBeforeEnter>
        { formStatus === LOADING_BUYER && (
          <FadedAnimatedDiv key='loading' className='w-full flex justify-center'>
            <LoadingIcon className='h-10 w-10'/>
          </FadedAnimatedDiv>
        )}

        { formStatus === NO_BUYER_SELECTED && (
          <FadedAnimatedDiv key='noBuyersSelected' className='w-full flex flex-col justify-center'>
            <span className='text-lg text-center'>Please, select the Buyer</span>
            <span className='text-lg text-center'>Then you will be able to select the options</span>
          </FadedAnimatedDiv>
        )}

        { formStatus === SHOWING_OPTIONS && (
          <FadedAnimatedDiv key='privacyConsent' className='h-full flex flex-col'>
            <OptionsWrapper className='space-y-28 h-full'>
              <Option
                description='Other BROKERS® may communicate with you to determine if you require additional real estate services.'
                onChange={onChangeAdditionalServicesOptions}
                checked={allowAdditionalServices}
                />
              <Option
                description='The boards, and other BROKERS® or their brokerage participate in surveys.'
                onChange={onChangeParticipageInSurveys}
                checked={allowSurveys}
              />
            </OptionsWrapper>

            <OfferFooterButtons
              continueButtonClick={saveBuyer}
              continueButtonLoading={savingBuyer}
            />
        </FadedAnimatedDiv>
        )}
      </AnimatePresence>
    </PageWrapper>
  )
}

export default PrivacyConsentStep

PrivacyConsentStep.propTypes = {
  selectedBuyers: propTypes.array,
  onClickContinue: propTypes.func
}

Option.propTypes = {
  description: propTypes.string,
  onChange: propTypes.func,
  checked: propTypes.bool
}
