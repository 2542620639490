/* eslint-disable */
import { Col, Row, Steps } from 'antd'
import standardStyle from 'assets/css/standardStyle'
import FadedAnimatedDiv from 'components/FadedAnimatedDiv'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import { H4, H6, H7 } from 'ui'

export const CreateOfferWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`

export const UnitImage = styled.div`
  position: absolute;
  height: 40%;
  width: 100%;
  background-position: center;
  background-size: cover;
`

export const UnitDeveloperLogo = styled.img`
  height: 20%;
  margin-top: 30px;
  margin-left: 30px;
`

export const CreateOfferStepsWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: row;
  z-index: 1;
  overflow: hidden;
`

export const StepsWrapper = styled.div`
  display: flex;
  align-items: center;
  min-width: 141px;
  margin-left: 30px;
`

export const CustomStep = styled(Steps)`
  background: white;
  border-radius: 10px;
  box-shadow: 0 0 2px 2px rgba(0,0,0,0.07);
  height: 90%;
  width: 180px;
  overflow: auto;
  position: relative;
  padding: 60px 0px;

  .ant-steps-item {
    padding: 0px 10px;
  }
`

export const ContentsWrapper = styled.div``

export const CarouselWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
  width: -webkit-fill-available;
`

export const CarouselContentWrapper = styled.div`
  /* width: 90%;
  height: 90%; */
  overflow: scroll;
  ::-webkit-scrollbar {display:none};
  /* margin-top: 200px; */
  /* margin-bottom: 30px; */
  border-radius: 10px;
  box-shadow: 0 0 2px 2px rgba(0,0,0,0.07);
  background: white;
`

export const ButtonContainer = styled.div`
  height: 5%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`

export const PageWrapper = styled.div``

export const BuyerMainWrapper = styled(Row)`
  height: 75%;
  overflow-y: auto;
`

export const BuyerPhotosWrapper = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`
export const BuyerInfosWrapper = styled(Col)`
  padding: 0px 15px;
`

export const BuyersListWrapper = styled.div``

export const BuyersWrapper = styled.div``

export const BuyerWrapper = styled.div`
  
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 0 2px 2px rgba(0,0,0,0.07);
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
  overflow: hidden;

  span {
    font-weight: ${props => props.id.length <= 2 ? 'normal' : 'bold'};
    padding: 0px 30px;
  }

  :hover {
    cursor: pointer;
  }
`

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const ConfirmSelectionWrapper = styled(motion.div)`
  background: ${standardStyle.colors.green};
  color: white;
  position: absolute;
  height: 105%;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 75%;
  flex-direction: column;
  z-index: 10;
  padding: 0px 10px;
`

export const CancelSelectionWrapper = styled(motion.div)`
  background: ${standardStyle.colors.danger};
  color: white;
  position: absolute;
  height: 105%;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 30%;
  flex-direction: column;
  z-index: 5;
`

export const AnimatedProjectBanner = styled(motion.img)`
  position: absolute;
  height: 40%;
  width: 100%;
  object-fit: cover;
  z-index: -1;
`

export const MethodsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 70%;
`

export const MethodWrapper = styled(motion.div)`
`

export const BuyerPhotoIdWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 70%;
`

export const UploadButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const PhotoId = styled.img`
  width: 100%;
`

export const CameraButtonWrapper = styled(motion.div)``

export const CameraContainer = styled(motion.div)`
  display: flex;
  width: -webkit-fill-available;
`

export const BuyerInfosAnimatedWrapper = styled(motion.div)`
`

export const OptionsContainer = styled(Row)`
  margin-top: 30px;
`

export const YesNoOptionWrapper = styled.div``

export const SubtitleWrapper = styled.div`
  text-align: center;
`

export const OptionWrapper = styled(motion.div)`
  box-shadow: 0 0 2px 2px rgba(0,0,0,0.07);
  border-radius: 4px;
  display: flex;
  margin: 15px;
  padding-bottom: 10px;
  position: relative;
  flex-direction: column;
`

export const ParkingOptionControlWrapper = styled.div`
  margin-top: -50px;
  margin: 0px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const ControlWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const ColorSchemesWrapper = styled(Row)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
`

export const ColorSchemeImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`

export const OptionFooter = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
`

export const OptionsWrapper = styled.div`
  width: 100%;
  height: 65%;
  overflow-y: auto;
`

export const ExtraWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

export const ReviewWrapper = styled(Row)`
  display: flex;
  flex-direction: row;
  width: 100%;
`

export const ReviewSectionWrapper = styled.div`
  margin: 30px 0px;
  display: flex;
  flex-direction: column;
`

export const ReviewSectionTitle = styled(H4)``
export const ReviewSectionDescription = styled(H6)``

export const ReviewColumns = styled(Col)`
  padding-right: 30px;
`

export const StepDescription = styled(H7)`
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  /* overflow: auto; */
  text-overflow: ellipsis;
`

export const ParkingOptionsWrapper = styled(Row)`
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  justify-content: center;
  height: 65%;
  margin-top: 15px;
  ::-webkit-scrollbar {display:none};
`

export const ParkingLottieTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const ParkingImageDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  margin: 0px 10px;
  height: 100%;
`

export const ColorSchemeImageWrapper = styled(motion.div)`
  height: 200px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const AdditionalOptionImage = styled.img`
  border-radius: 4px;
  width: 100%;
`

export const AdditionalOptionPanelWrapper = styled(Row)`
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: center;
`

export const QtdeSelectedWrapper = styled(motion.div)`
  background: ${standardStyle.colors.primary};
  color: white;
  font-size: 25px;
  border-radius: 50%;

  right: -8px;
  position: absolute;
  top: -8px;
  align-items: center;
  justify-content: center;
  display: flex;
`

export const CreateOfferButtonWrapper = styled(Row)`
  width: 100%;
  padding: 20px 0px;
  height: 15%;
  background: white;
`

export const SuccessPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const SuccessPageButtonsWrapper = styled(motion.div)`
  display: flex;
  flex-direction: row;

  button {
    margin: 0px 15px;
  }
`

export const CustomFadedWrapper = styled(FadedAnimatedDiv)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 2.75rem/* 44px */;
  padding-bottom: 2.75rem/* 44px */;
`

export const TitlesWrapper = styled.div`
  height: 20%;
  text-align: center;
`

export const ReviewMainWrapper = styled.div`
  height: 70%;
  overflow-y: auto;
  padding: 0px 20px;
`

export const MultiBuyerSwitchWrapper = styled.div``

export const CreateBuyerButtons = styled.div``

export const CorporateBuyerWrapper = styled.div``
export const CorporationWrapper = styled.div``
export const CorporationDocumentWrapper = styled.div``
export const CorporationInfosWrapper = styled.div``
export const RepresentativeWrapper = styled.div``
export const RepresentativeDocumentsWrapper = styled.div``
export const RepresentativeInfosWrapper = styled.div``
export const BrokerCommissionsWrapper = styled.div``
