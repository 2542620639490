import React from 'react'
import propTypes from 'prop-types'
import { formatMoney } from 'utils'
import { Price } from './styles'

const OfferPrice = ({ priceCurrencyType, children }) => (
  <Price className='text-2xl lg:text-xl xl:text-2xl font-bold'>{formatMoney(children, priceCurrencyType)}</Price>
)

export default OfferPrice

OfferPrice.propTypes = {
  priceCurrencyType: propTypes.string,
  children: propTypes.node
}
