import React, { useState, useEffect, useCallback } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { InputLabel, Modal, TButton } from 'ui'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  getPipelineBoard,
  createPipelineItem,
  movePipelineItem
} from 'store/actions/boardActions'
import { reserveUnitToBuyer, unreserveUnit } from 'store/actions/unitActions'
import {
  PageHeader,
  TCancelButton,
  TDeleteButton,
  TFilledCellphoneIcon,
  TFilledEmailIcon,
  TFilledHomeIcon,
  TFilledPhoneIcon,
  TFilledPlusIcon,
  TFilledUserCircle,
  TSaveButton
} from 'components'
import { validateRequiredInputs } from 'utils'

const renderItemDetailIcon = (Icon) => (
  <Icon className='h-5 w-5 text-gray-400 mr-1.5' />
)

const PipelineItemDetail = ({ icon, children, className }) => (
  <p className={`mt-2 flex items-start text-sm text-gray-500 ${className}`}>
    {renderItemDetailIcon(icon)}
    {children}
  </p>
)

const SalesPipeline = (props) => {
  const [data, setData] = useState({})
  const [selectedBuyer, setSelectedBuyer] = useState('')
  const [selectedUnit, setSelectedUnit] = useState('')
  const [gettingBoard, setGettingBoard] = useState(false)
  const [isFormVisible, setIsFormVisible] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [newStatus, setNewStatus] = useState('')
  const [reserve, setReserve] = useState({})
  const [savingReservation, setSavingReservation] = useState(false)
  const [buyersData, setBuyersData] = useState([])
  const [unitsData, setUnitsData] = useState([])
  const [savingPipelineItem, setSavingPipelineItem] = useState(false)
  const [reservingUnreservingUnit, setReservingUnreservingUnit] = useState(false)
  const [
    showConfirmUnreserveUnitModal,
    setShowConfirmUnreserveUnitModal
  ] = useState(false)
  const [selectedUnitToUnreserve, setSelectedUnitToUnreserve] = useState('')

  const { appProject } = props

  // const receiveMessage = (message) => {
  //   setRefresh(true)
  // }

  // useEffect(() => {
  //   const pusher = new Pusher('64a03031a93c8c9d1f54', {
  //     cluster: 'us3'
  //   })
  //   const channel1 = pusher.subscribe('6022a38a8df61a00192c9fc8')
  //   const channel2 = pusher.subscribe('6022a3a88df61a00192c9fc9')
  //   channel1.bind('update-unit', receiveMessage)
  //   channel1.bind('new-unit', receiveMessage)
  //   channel2.bind('update-unit', receiveMessage)
  //   channel2.bind('new-unit', receiveMessage)
  // }, [])

  useEffect(() => {
    if (data && Object.keys(data).length && data.buyers) {
      const tmpBuyers = data.buyers.map((buyer) => ({
        id: buyer._id,
        value: buyer.name
      }))
      const tmpUnits = data.units.map((unit) => ({
        id: unit._id,
        value: unit.unitNumber
      }))

      setBuyersData(tmpBuyers)
      setUnitsData(tmpUnits)
    }
  }, [data])

  const handleOnDragEnd = async (result) => {
    if (!result.destination) return
    if (result.source.droppableId === result.destination.droppableId) return
    const items1 = Array.from(data.activities[result.source.droppableId])
    const items2 = Array.from(data.activities[result.destination.droppableId])
    const [movedItem] = items1.splice(result.source.index, 1)
    items2.splice(result.destination.index, 0, movedItem)
    movedItem.status = result.destination.droppableId
    movedItem.substatus = null

    data.activities = {
      ...data.activities,
      [result.source.droppableId]: items1,
      [result.destination.droppableId]: items2
    }

    movePipelineItem({
      item: { status: result.destination.droppableId, substatus: null },
      _id: movedItem._id
    })
  }

  const showForm = (status) => {
    setNewStatus(status)
    setIsFormVisible(true)
  }

  const handleCancel = () => {
    setIsFormVisible(false)
  }

  const handleSave = () => {
    validateRequiredInputs('createPipelineItem').then(async () => {
      setSavingPipelineItem(true)
      await createPipelineItem({
        buyer: selectedBuyer,
        project: appProject,
        status: newStatus
      })
      setIsFormVisible(false)
      setRefresh(true)
      setSavingPipelineItem(false)
    })
  }

  const saveReservation = (buyer) => {
    validateRequiredInputs('changePipelineUnit').then(async () => {
      setReservingUnreservingUnit(true)
      const unit = selectedUnit
      setSavingReservation(true)
      await reserveUnitToBuyer(unit, buyer)
      setSavingReservation(false)
      setRefresh(true)
      setReservingUnreservingUnit(false)
    })
  }

  const cancelReserve = async (unit) => {
    setSelectedUnit('')
    setShowConfirmUnreserveUnitModal(false)
    setReservingUnreservingUnit(true)
    await unreserveUnit(unit)
    setRefresh(true)
    setReservingUnreservingUnit(false)
  }

  const startReserve = (state, index) => {
    const showReserve = true
    setReserve({ state, index, showReserve })
  }

  const hideReserve = (state, index) => {
    const showReserve = false
    setSelectedUnit('')
    setReserve({ state, index, showReserve })
  }

  const _getPipelineBoard = useCallback((project) => {
    setGettingBoard(true)
    getPipelineBoard(project)
      .then((board) => {
        setGettingBoard(false)
        setData(board)
      })
      .catch(() => setGettingBoard(false))
  }, [])

  useEffect(() => {
    if (appProject) {
      _getPipelineBoard(appProject)
      setRefresh(false)
    }
  }, [appProject, refresh, _getPipelineBoard])

  useEffect(() => {
    const { state, index, showReserve } = reserve
    if (state) {
      const newData = {
        ...data
      }
      newData.activities[state][index].showReserve = showReserve
      setData(newData)
    }
  }, [reserve, data])

  const confirmUnreserveUnit = (unitId) => {
    setSelectedUnitToUnreserve(unitId)
    setShowConfirmUnreserveUnitModal(true)
  }

  return (
    <>
      <PageHeader title='Sales Pipeline' />
      <Modal
        modalType="alert"
        title="Unreserve unit"
        subtitle="do you really want to cancel the unit reservation?"
        onOk={() => cancelReserve(selectedUnitToUnreserve)}
        onCancel={() => {
          setSelectedUnitToUnreserve('')
          setShowConfirmUnreserveUnitModal(false)
        }}
        showModal={showConfirmUnreserveUnitModal}
      />
      {data && data.statuses && (
        <>
          <div className="bg-blue-500 w-full  p-4 flex justify-center">
            <DragDropContext onDragEnd={handleOnDragEnd}>
              {Object.keys(data.statuses).map((e) => (
                <Droppable droppableId={e} key={e}>
                  {(provided) => (
                    <div className="rounded bg-gray-200 w-64 p-2 m-2">
                      <div className="flex justify-between py-1">
                        <h3 className="text-sm">{e}</h3>
                        {buyersData.length > 0 && (
                          <button
                            type="button"
                            onClick={() => showForm(e)}
                            className="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            <TFilledPlusIcon className="h-5 w-5" />
                          </button>
                        )}
                      </div>
                      <div className="text-sm mt-2">
                        <ul
                          className="pipeline"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {data.activities[e].map(
                            (
                              {
                                _id,
                                buyerId,
                                name,
                                substatus,
                                email,
                                mobile,
                                phone,
                                avatar,
                                reserves,
                                showReserve
                              },
                              index
                            ) => (
                              <Draggable
                                key={_id}
                                draggableId={_id}
                                index={index}
                              >
                                {(provided) => (
                                  <li
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <div className="bg-white p-2 rounded mt-1 border-b border-gray-400 cursor-pointer hover:bg-gray-100">
                                      <div className="min-w-0 flex-1 flex items-center">
                                        <div className="flex-shrink-0">
                                          {avatar && (
                                            <img
                                              className="h-16 w-16 rounded-full"
                                              src={avatar}
                                              alt=""
                                            />
                                          )}
                                          {!avatar && (
                                            <TFilledUserCircle className="h-16 w-16 text-gray-300" />
                                          )}
                                        </div>
                                        <div className="min-w-0 flex-1 px-4">
                                          <div>
                                            <p className="text-sm font-medium text-indigo-600 truncate">
                                              {name}
                                            </p>
                                          </div>
                                        </div>
                                      </div>

                                      <PipelineItemDetail
                                        icon={TFilledEmailIcon}
                                      >
                                        {email}
                                      </PipelineItemDetail>
                                      <PipelineItemDetail
                                        icon={TFilledCellphoneIcon}
                                      >
                                        {mobile}
                                      </PipelineItemDetail>
                                      <PipelineItemDetail
                                        icon={TFilledPhoneIcon}
                                      >
                                        {phone}
                                      </PipelineItemDetail>

                                      {reserves.length > 0 &&
                                        reserves.map((r) => (
                                          <>
                                            <PipelineItemDetail
                                              icon={TFilledHomeIcon}
                                              key={r._id}
                                            >
                                              <span className="break-all">
                                                {r.unitNumber} -{' '}
                                                {r.building.name}
                                                <p className="text-green-600">
                                                  {r.offer}
                                                </p>
                                              </span>
                                            </PipelineItemDetail>
                                            {(r.offer === '' ||
                                              r.offer === 'No offer') && (
                                              <TDeleteButton
                                                onClick={() =>
                                                  confirmUnreserveUnit(r._id)
                                                }
                                                loading={
                                                  reservingUnreservingUnit
                                                }
                                                className="bg-red-500 hover:bg-red-400 w-full"
                                              >
                                                Cancel reserve
                                              </TDeleteButton>
                                            )}
                                          </>
                                        ))}
                                      {(e === 'Reservation' || e === 'Offer') &&
                                        !showReserve &&
                                        unitsData.length > 0 && (
                                          <p className="mt-2 flex items-center text-sm text-gray-500">
                                            {renderItemDetailIcon(
                                              TFilledHomeIcon
                                            )}
                                            <span className="break-all" />
                                            <TButton
                                              onClick={() =>
                                                startReserve(e, index)
                                              }
                                              className="w-full"
                                            >
                                              Reserve unit
                                            </TButton>
                                          </p>
                                      )}
                                      {(e === 'Reservation' || e === 'Offer') &&
                                        showReserve &&
                                        unitsData.length > 0 && (
                                          <>
                                            <InputLabel
                                              id="unit"
                                              label="Unit"
                                              data={unitsData}
                                              value={selectedUnit}
                                              onChange={(unit) =>
                                                setSelectedUnit(unit)
                                              }
                                              typeInput="select"
                                              validateKey="changePipelineUnit"
                                              required
                                              inputWrapperClassName="w-full"
                                            />
                                            <div className="flex flex-row mt-2">
                                              <TCancelButton
                                                onClick={() =>
                                                  hideReserve(e, index)
                                                }
                                                className="w-full mr-2"
                                              >
                                                Cancel
                                              </TCancelButton>
                                              <TSaveButton
                                                onClick={() =>
                                                  saveReservation(buyerId)
                                                }
                                                disabled={savingReservation}
                                                className="w-full ml-2"
                                                loading={
                                                  reservingUnreservingUnit
                                                }
                                              >
                                                Reserve
                                              </TSaveButton>
                                            </div>
                                          </>
                                      )}
                                    </div>
                                  </li>
                                )}
                              </Draggable>
                            )
                          )}
                          {provided.placeholder}
                        </ul>
                      </div>
                    </div>
                  )}
                </Droppable>
              ))}
            </DragDropContext>
          </div>
          <Modal
            showModal={isFormVisible}
            onCancel={handleCancel}
            modalType="custom"
          >
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="max-w-3xl mx-auto">
                <div className="bg-white shadow sm:rounded-lg mt-8">
                  <div className="px-4 py-5 sm:p-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Add new pipeline item
                    </h3>
                    <div className="mt-2 max-w-xl text-sm text-gray-500">
                      <p> Status: {newStatus} </p>
                    </div>
                    <form className="space-y-8 divide-y divide-gray-200">
                      <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        <div className="sm:col-span-3">
                          <div className="mt-1">
                            <InputLabel
                              id="buyerSelector"
                              label="Buyer"
                              data={buyersData}
                              onChange={(buyer) => setSelectedBuyer(buyer)}
                              typeInput="select"
                              value={selectedBuyer}
                              required={isFormVisible}
                              validateKey="pipelineItemCreation"
                              inputWrapperClassName="w-60"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="pt-5">
                        <div className="flex justify-end">
                          <TSaveButton
                            onClick={handleSave}
                            loading={savingPipelineItem}
                          >
                            Save
                          </TSaveButton>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </>
      )}
      {gettingBoard && <h1>loading...</h1>}
    </>
  )
}

const mapStateToProps = (state) => ({
  userObject: state.authReducer.userObject,
  appProject: state.appReducer.appProject
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(SalesPipeline)

SalesPipeline.propTypes = {
  userObject: propTypes.shape({
    firstName: propTypes.string,
    lastName: propTypes.string,
    userType: propTypes.string,
    developerCompany: propTypes.string,
    email: propTypes.string,
    userAvatar: propTypes.string
  }),
  appProject: propTypes.string
}

PipelineItemDetail.defaultProps = {
  className: ''
}

PipelineItemDetail.propTypes = {
  icon: propTypes.node,
  children: propTypes.node,
  className: propTypes.string
}
