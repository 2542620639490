/* eslint multiline-ternary: ["error", "never"] */
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Table } from 'ui'
import ContactCard from '../Sidebar/ContactCard/ContactCard'
import { ContactContext } from 'pages/contacts/context/Contacts'
import FilterBySalesType from 'components/FilterBySalesType'
import {
  filterNullAssignments,
  filterAssignmentsBySaleRepId
} from 'pages/newContacts/context/MainContactContext'
import useSidebar from 'hooks/useSidebar'
import LeadsTopFilterPanel from './LeadsTopFilterPanel'
import { isMobile } from 'react-device-detect'
import Card from './HorizontalStats/components/Card'
import { classNames } from 'utils'
import useGetLeadsReport from 'hooks/useGetLeadsReport'
import { isNumber } from 'utils/isNumber'
import useGetAllUserEmailContacts from 'hooks/useGetAllUserEmailContacts'
import { DashboardContext } from '../context/DashboardContext'

const ActionButton = ({ onClick }) => (
  <div
    onClick={onClick}
    className='font-medium py-1 text-base text-black uppercase text-center border border-black w-32 cursor-pointer'
  >
    View
  </div>
)

export const HorizontalStats = ({ data }) => {
  return (
    <div
      className={classNames(
        'w-full pb-3',
        isMobile && 'grid grid-cols-2 gap-2 mt-2',
        !isMobile && 'flex flex-row gap-3'
      )}
    >
      {data?.map((item, index) => (
        <Card
          key={index}
          item={item}
          index={index}
          active={false}
          setActive={null}
        />
      ))}
    </div>
  )
}

const LeadsData = () => {
  const { loading, data, refetchAllLeads } = useGetLeadsReport()
  const { Sidebar } = useSidebar()

  // @ts-ignore
  const userObject = useSelector((state) => state.authReducer.userObject)
  const _DashboardContext = useContext(DashboardContext)
  const { openSidePanel, setOpenSidePanel } = _DashboardContext
  const [contactId, setContactId] = useState(null)
  const { loading: isContactLoading } = useGetAllUserEmailContacts()
  const {
    setSelectedContact,
    selectedContactObject: contact
  } = useContext(ContactContext)
  const [selectedPage, setSelectedPage] = useState(1)
  const [selectedSalesType, setSelectedSalesType] = useState('buyer')
  const [selectedDateFilter, setSelectedDateFilter] = useState('today')
  const [filteredLeads, setfilteredLeads] = useState({})

  useEffect(() => {
    if (selectedDateFilter?.length) {
      refetchAllLeads(selectedDateFilter)
    }
  }, [selectedDateFilter, refetchAllLeads])

  useEffect(() => {
    const leads = data?.contacts
    if (userObject && leads?.length) {
      const filtered = leads?.reduce(
        function (out, contact) {
          const salesType = contact?.userType?.toLowerCase?.()
          if (
            !filterNullAssignments(contact?.buyerData?.assignments) &&
            !filterNullAssignments(contact?.leadBrokerData?.assignments)
          ) {
            out.unassigned.push(contact)
            out.all.push(contact)
          }

          if (
            salesType?.indexOf('broker') !== -1 &&
            filterAssignmentsBySaleRepId(
              contact?.leadBrokerData?.assignments,
              userObject
            )
          ) {
            out.broker.push(contact)
            out.all.push(contact)
          }

          if (
            salesType?.indexOf('buyer') !== -1 &&
            filterAssignmentsBySaleRepId(
              contact?.buyerData?.assignments,
              userObject
            )
          ) {
            out.buyer.push(contact)
            out.all.push(contact)
          }
          return out
        },
        {
          unassigned: [],
          buyer: [],
          broker: [],
          all: []
        }
      )
      return setfilteredLeads(filtered ?? {})
    }
  }, [data?.contacts, userObject])

  const unitColumnsForLeads = useMemo(() => {
    if (selectedSalesType === 'buyer') {
      return [
        {
          title: 'Timestamp',
          Title: 'Timestamp',
          key: 'timestamp',
          dataIndex: 'timestamp',
          accessor: 'timestamp'
        },
        {
          title: 'User Type',
          Title: 'User Type',
          key: 'userType',
          dataIndex: 'userType',
          accessor: 'userType'
        },
        {
          title: 'Email',
          Title: 'Email',
          key: 'email',
          dataIndex: 'email',
          accessor: 'email'
        },
        {
          title: 'Name',
          Title: 'Name',
          key: 'name',
          dataIndex: 'name',
          accessor: 'name'
        },
        {
          title: 'Sales Rep',
          Title: 'Sales Rep',
          key: 'salesRep',
          dataIndex: 'salesRep',
          accessor: 'salesRep',
          width: 150
        },
        {
          title: 'Lead Source',
          Title: 'Lead Source',
          key: 'leadSource',
          dataIndex: 'leadSource',
          accessor: 'leadSource',
          width: 150
        },
        {
          title: '',
          Title: '',
          key: 'id',
          dataIndex: 'id',
          accessor: 'id',
          Cell: (props) => (
            <ActionButton
              onClick={() => {
                setContactId(props.cell.value)
                setOpenSidePanel(true)
              }}
            />
          ),
          render: (props) => (
            <ActionButton
              onClick={() => {
                setContactId(props)
                setOpenSidePanel(true)
              }}
            />
          )
        }
      ]
    } else {
      return [
        {
          title: 'Timestamp',
          Title: 'Timestamp',
          key: 'timestamp',
          dataIndex: 'timestamp',
          accessor: 'timestamp'
        },
        {
          title: 'User Type',
          Title: 'User Type',
          key: 'userType',
          dataIndex: 'userType',
          accessor: 'userType'
        },
        {
          title: 'Email',
          Title: 'Email',
          key: 'email',
          dataIndex: 'email',
          accessor: 'email'
        },
        {
          title: 'Name',
          Title: 'Name',
          key: 'name',
          dataIndex: 'name',
          accessor: 'name'
        },
        {
          title: 'Sales Rep',
          Title: 'Sales Rep',
          key: 'salesRep',
          dataIndex: 'salesRep',
          accessor: 'salesRep',
          width: 150
        },
        {
          title: '',
          Title: '',
          key: 'id',
          dataIndex: 'id',
          accessor: 'id',
          Cell: (props) => (
            <ActionButton
              onClick={() => {
                setContactId(props.cell.value)
                setOpenSidePanel(true)
              }}
            />
          ),
          render: (props) => (
            <ActionButton
              onClick={() => {
                setContactId(props)
                setOpenSidePanel(true)
              }}
            />
          )
        }
      ]
    }
  }, [selectedSalesType])

  const unitColumnsForLeadTypes = useMemo(() => {
    return [
      {
        title: 'UserType',
        Title: 'UserType',
        key: 'key',
        dataIndex: 'key',
        accessor: 'key'
      },
      {
        title: 'Leads Count',
        Title: 'Leads Count',
        key: 'value',
        dataIndex: 'value',
        accessor: 'value'
      }
    ]
  }, [])

  const leadByTypes = useMemo(() => {
    // @ts-ignore
    const reducedLeads = filteredLeads?.all?.reduce(function (out, current) {
      if (!out[current.userType]) {
        out[current.userType] = 0
      }
      out[current.userType] += 1
      return out
    }, {})

    if (!reducedLeads || !Object.keys(reducedLeads).length) {
      return []
    }
    const result = []
    for (const [key, value] of Object.entries(reducedLeads)) {
      result.push({ key, value })
    }
    return result
  }, [filteredLeads])

  const tableDataForLeads = useMemo(() => {
    if (!filteredLeads?.[selectedSalesType]?.length) return []
    const result = filteredLeads?.[selectedSalesType]?.map((lead) => {
      if (selectedSalesType === 'buyer') {
        return {
          timestamp: `${new Date(lead.createdAt).toLocaleDateString()}`,
          userType: lead.userType,
          email: lead.email,
          name: `${lead.firstName} ${lead.lastName}`,
          salesRep: lead.saleRepDoc?.email,
          leadSource: lead?.buyerData?.leadSource ?? '-',
          id: lead._id
        }
      } else {
        return {
          timestamp: `${new Date(lead.createdAt).toLocaleDateString()}`,
          userType: lead.userType,
          email: lead.email,
          name: `${lead.firstName} ${lead.lastName}`,
          salesRep: lead.saleRepDoc?.email,
          id: lead._id
        }
      }
    })
    return result
  }, [filteredLeads, selectedSalesType])

  const tableDataForLeadTypes = useMemo(() => {
    if (!leadByTypes?.length) return []
    const result = leadByTypes.map(({ key, value }) => {
      return {
        key,
        value
      }
    })
    return result
  }, [leadByTypes])

  const leadByTypesForCount = useMemo(() => {
    // @ts-ignore
    const reducedLeads = filteredLeads?.all?.reduce(function (out, current) {
      if (!out[current.userType]) {
        out[current.userType] = 0
      }
      out[current.userType] += 1
      return out
    }, {})

    if (!reducedLeads || !Object.keys(reducedLeads).length) {
      return []
    }
    const result = []
    for (const [key, value] of Object.entries(reducedLeads)) {
      result.push({ key, value })
    }
    return result
  }, [filteredLeads])

  const LeadTypesForCount = useMemo(() => {
    if (!leadByTypesForCount?.length) return []
    const resultObject = {
      Buyer: 0,
      LeadBroker: 0,
      Total: 0
    }
    leadByTypesForCount.forEach(({ key, value }) => {
      resultObject[key] = value
      resultObject.Total += value
    })
    return resultObject
  }, [leadByTypesForCount])

  const isContentLoading = useMemo(
    () => loading || isContactLoading,
    [loading, isContactLoading]
  )

  const tempStatsData = useMemo(() => {
    // @ts-ignore
    const { Total, Buyer, LeadBroker } = LeadTypesForCount
    return [
      { title: 'Total Leads', number: isNumber(Total) },
      { title: 'Buyers', number: isNumber(Buyer) },
      { title: 'Brokers ', number: isNumber(LeadBroker) }
    ]
  }, [LeadTypesForCount])

  const getSortedNewestFirst = (leadsData = []) => {
    // If last 7 days filter is selected, we sort leads by newest first then return them
    if (selectedDateFilter === '7days') {
      const arr = leadsData.map(obj => ({ ...obj })).sort((a, b) => {
        const dateA = new Date(a.timestamp.split('/').reverse().join('/'))
        const dateB = new Date(b.timestamp.split('/').reverse().join('/'))
        const timeA = dateA.getTime()
        const timeB = dateB.getTime()
        if (Number.isNaN(timeA) || Number.isNaN(timeB)) {
          return 0
        }
        return timeB - timeA
      })
      return arr
    }

    // If last 7 days filter is not selected, we return passed leads data as it is
    return leadsData
  }

  return (
    <>
      <div className='bg-menuGray font-openSans overscroll-none mb-6'>
        <h3 className='font-bold text-2xl py-2 border-b-2 border-softBlack pl-10'>
          New Leads
        </h3>
        <div>
          <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
            <div className='max-w-136 w-136 mb-3'>
              <LeadsTopFilterPanel
                loading={isContentLoading}
                selected={selectedDateFilter}
                onSelectChange={setSelectedDateFilter}
              />
            </div>
            <HorizontalStats data={tempStatsData} />
            <div className='max-w-136 w-136 mb-3'>
              <FilterBySalesType
                loading={isContentLoading}
                selected={selectedSalesType}
                onSelectChange={setSelectedSalesType}
                contacts={filteredLeads}
              />
            </div>
            <Table
              loading={isContentLoading}
              className='rounded-lg'
              dataSource={getSortedNewestFirst(tableDataForLeads)}
              columns={unitColumnsForLeads}
              scrollOptions={{ y: 640 }}
              selectedPage={selectedPage}
              defaultPageSize={7}
              changeSelectedPage={setSelectedPage}
            />
          </div>
          <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
            <Table
              loading={isContentLoading}
              className='rounded-lg'
              dataSource={tableDataForLeadTypes}
              columns={unitColumnsForLeadTypes}
            />
          </div>
        </div>
      </div>
      {!isContentLoading && openSidePanel && (
        <Sidebar title={'User Profile'} isFullScreen>
          <div className='flex flex-row gap-2 w-full'>
            <div className='flex flex-row w-full overflow-y-auto rounded-lg border-2 border-gray-1 p-4'>
              <ContactCard
                fullScreen={true}
                setSelectedContact={setSelectedContact}
                contact={contact}
                tasksList={null}
                contactId={contactId}
              />
            </div>
          </div>
        </Sidebar>
      )}
    </>
  )
}

export default React.memo(LeadsData)
