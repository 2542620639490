import React, { useCallback } from 'react'
import { CgChevronLeft } from 'react-icons/cg'
import { useHistory } from 'react-router-dom'
import { PWA_PROJECTS } from 'routes'

const SomethingWentWrong = () => {
  const history = useHistory()
  const goToBack = useCallback(() => {
    history.replace(PWA_PROJECTS)
  }, [history])

  return (
    <div className='relative'>
      <button
        className='absolute top-10 left-10 flex space-x-2 items-center font-semibold text-base text-softBlack'
        onClick={() => {
          console.log('on GoBack Clicked')
          goToBack?.()
        }}
      >
        <CgChevronLeft className='w-8 h-8' />
        <span className='uppercase'>Something went wrong, Please go Back</span>
      </button>
    </div>
  )
}

export default SomethingWentWrong
