/* eslint-disable */
// @ts-nocheck
import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'ui';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import { getNylasSchedulerPages, sendEmail } from 'store/actions/usersActions';
import CustomInput from 'components/CustomInput';
import RichTextField from 'components/RichTextField';
import { isMobile } from 'react-device-detect';
import { EMAIL_ATTACHMENT_SVG } from 'utils/emailUtils';
import { AddUserEmailInput } from './AddUserEmailInput';
import CustomModal from 'components/Modal';
import useGetAllUserEmailContacts from 'hooks/useGetAllUserEmailContacts';
import './styles/style.css';
import CustomButton from './CustomButton';
import { Oval } from 'react-loader-spinner';
import { getEmailTemplates } from 'store/actions/emailTemplateActions';
import { FadedAnimatedDiv, LottieWrapper } from 'components';
import tableLoading from 'assets/lottieJsons/tableLoading.json';
import { EyeIcon } from 'lucide-react';
import EmailTemplatePreview from 'pages/emailCampaignsBuilder/TemplatePreview';
import toast from 'react-hot-toast';
import useGetCommunicationTemplatesByType from 'hooks/useGetCommunicationTemplatesByType';

const validRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export const LANGUAGE_TYPE = [
  {
    title: 'English',
    key: 'english',
    value: 'english',
  },
  {
    title: 'Spanish',
    key: 'spanish',
    value: 'spanish',
  },
];

const tabMenus = [
  {
    id: 'template',
    name: 'Template',
  },
  {
    id: 'booking-links',
    name: 'Booking Links',
  },
];

const initialEmptyValues = {
  from: '',
  to: '',
  cc: '',
  bcc: '',
  subject: '',
  body: '',
};

export const EmailModal = ({
  show,
  handleClose,
  contact,
  onEmailSent = () => {},
}) => {
  const { contacts, loading: isContactLoading } = useGetAllUserEmailContacts();
  // @ts-ignore
  const userObject = useSelector((state) => state.authReducer.userObject);
  const projectId = useSelector((state) => state.appReducer.appProject);
  const userType = userObject?.userType;
  const [emailData, setEmailData] = useState({
    ...initialEmptyValues,
    to: contact?.email ? [contact.email] : [],
  });
  const [selectedTab, setSelectedTab] = useState('template');
  const [showConfirmSendingEmailModal, setShowConfirmSendingEmailModal] =
    useState(false);
  const editorRef = useRef(null)
  const [sendingEmail, setSendingEmail] = useState(false);
  const [fileAttachments, setFileAttachments] = useState([]);
  const [cursorPosition, setCursorPosition] = useState(0);

  // const [activeLanguageTab, setActiveLanguageTab] = useState('english')
  const [meetings, setMeetings] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [showTemplates, setShowTemplates] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isTemplateLoading, setIsTemplateLoading] = useState(true);
  const [selectedTemplateForPreview, setSelectedTemplateForPreview] =
    useState(null);
  const { loading: isCommunicationTemplatesLoading, communicationTemplates, getCommunicationTemplates } = useGetCommunicationTemplatesByType('email')

  useEffect(() => {
    getCommunicationTemplates()
  }, [])

  const onChange = (fieldName, value) => {
    setEmailData({ ...emailData, [fieldName]: value });
  };

  const onCancelModal = useCallback(() => {
    setShowConfirmSendingEmailModal(false);
  }, []);

  useEffect(() => {
    if (contact?.email) {
      setEmailData((_emailData) => {
        return { ..._emailData, to: [contact?.email] };
      });
    }
  }, [contact?.email]);

  useEffect(() => {
    if (!show) setEmailData({ ...initialEmptyValues });
  }, [show]);

  const getAllTemplates = () => {
    setIsTemplateLoading(true);
    const tempCommunicationTemplates = communicationTemplates.map((communicationTemplate, index) => ({
      _id: index,
      name: communicationTemplate.title,
      unlayerTemplateName: communicationTemplate.title,
      unlayerConfig: {
        html: communicationTemplate.content
      },
      id: index,
      subject: communicationTemplate?.subject ?? ''
    }))
    getEmailTemplates()
      .then((response) => {
        setTemplates([...response, ...tempCommunicationTemplates]);
        setIsTemplateLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsTemplateLoading(false);
      });
  };

  useEffect(() => {
    if (!isCommunicationTemplatesLoading) {
      getAllTemplates();
    }
  }, [isCommunicationTemplatesLoading, communicationTemplates]);

  useEffect(() => {
    setIsLoading(true);
    getNylasSchedulerPages()
      .then((data) => {
        setMeetings([...data.data]);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, []);

  const defaultStyles = useMemo(() => {
    return {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      zIndex: '4999',
      border: 'none',
      padding: '0',
      height: '95vh',
      width: isMobile ? '95%' : '90%',
      background: 'white',
      overflow: 'hidden',
    };
  }, [userType, isMobile]);

  const mapEmail = (emailList) => {
    if (!emailList?.length) return [];
    const filteredEmails = emailList?.filter((email) =>
      email.match(validRegex)
    );
    if (!filteredEmails?.length) return [];
    const result =
      filteredEmails?.map((email) => {
        return {
          name: '',
          email,
        };
      }) ?? [];
    return result;
  };

  function cleanHTML(html) {
    return html
      .replace(/<p>/g, '')
      .replace(/<\/p>/g, '<br>')
      .replace(/<p><br><\/p>/g, '<br>')
      .replace(/<p><br \/><\/p>/g, '<br>')
      .replace(/<br><br>/g, '<br>') // Remove double line breaks
      .replace(/^<br>|<br>$/g, ''); // Remove leading/trailing <br>
  }

  const _sendEmail = () => {
    setSendingEmail(true);
    setShowConfirmSendingEmailModal(false);
    const emailHtml = editorRef.current?.getEditor?.().root?.innerHTML ?? ''
    const emailBody = cleanHTML(emailHtml)

    sendEmail(
      emailBody,
      emailData.subject,
      mapEmail(emailData.to),
      fileAttachments,
      mapEmail(emailData.cc),
      mapEmail(emailData.bcc),
      projectId
    )
      .then(() => {
        setSendingEmail(false);
        handleCloseModal();
        onEmailSent?.();
      })
      .catch(() => setSendingEmail(false));
  };

  const handleBodyChange = (value) => {
    setEmailData((data) => ({ ...data, body: value }));
  };

  const handleSendEmail = () => {
    if (!emailData.subject) {
      setShowConfirmSendingEmailModal(true);
    } else {
      _sendEmail();
    }
  };

  const handleUseTemplate = (template) => {
    setEmailData((data) => ({
      ...data,
      body: template?.unlayerConfig?.html || '',
      subject: template.subject ?? '',
    }));
  };

  const handleUseLink = (link) => {
    const quill = editorRef.current.getEditor();

    const cursorPosition = quill.getSelection().index;

    quill.insertText(cursorPosition, link);

    quill.setSelection(cursorPosition + link.length);
  };

  const handleCloseModal = () => {
    handleClose();
    setEmailData((data) => ({ ...data, body: '', subject: '' }));
  };

  // const handleLanguageTemplateChange = (language) => {
  //   setActiveLanguageTab(language)
  //   setTemplates(emailTemplates[language])
  // }

  const Templates = () => {
    if (!isMobile || showTemplates) {
      return (
        <div className='w-full h-full lg:w-1/2 flex flex-col gap-4 overflow-auto rounded'>
          {isMobile && (
            <CustomButton
              className='mt-5'
              handleClick={() => setShowTemplates(false)}
            >
              <span className='font-semibold px-4 py-2 rounded text-base flex items-center gap-2'>
                Back
              </span>
            </CustomButton>
          )}
          <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
            {isCommunicationTemplatesLoading || isTemplateLoading ? (
              <>
                <FadedAnimatedDiv
                  key='loadingTable'
                  className='w-full h-full flex items-center justify-center my-10 flex-col'
                >
                  <LottieWrapper
                    animation={tableLoading}
                    loop
                    className='h-16 w-16'
                  />
                  <span className='text-base'>loading...</span>
                </FadedAnimatedDiv>
              </>
            ) : templates.length ? (
              <div className='min-w-full'>
                <h3 className='pb-4 w-full font-bold text-xl text-center md:text-start m-0 border-b border-gray-300'>
                  Templates
                </h3>
                <div className='divide-y divide-gray-200'>
                  {templates?.map((template) => (
                    <div
                      key={template.id}
                      className='w-full p-2 flex flex-col gap-3 md:flex-row md:items-center md:justify-between'
                    >
                      <p className='text-base text-gray-900 mb-0'>
                        {template.unlayerTemplateName}
                      </p>
                      <div className='whitespace-nowrap text-sm flex md:justify-end gap-3 text-gray-900'>
                        <CustomButton
                          className='flex-1 md:w-fit'
                          handleClick={() => {
                            handleUseTemplate(template);
                            isMobile && setShowTemplates(false);
                          }}
                        >
                          <span className='font-semibold px-4 py-2 rounded text-base flex items-center gap-2'>
                            Use Template
                          </span>
                        </CustomButton>
                        <CustomButton
                          className='flex-1 md:w-fit'
                          variant='outline'
                          handleClick={() =>
                            setSelectedTemplateForPreview(template)
                          }
                        >
                          <span className='font-semibold px-4 py-2 rounded text-base flex items-center gap-2'>
                            <EyeIcon size={16} />
                            Preview
                          </span>
                        </CustomButton>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <h1 className='text-center text-xl py-6 mb-0'>
                Templates not found.
              </h1>
            )}
          </div>
        </div>
      );
    }
    return null;
  };

  const BookingLinks = () => {
    return (
      <div className='w-full h-full lg:w-1/2 flex flex-col gap-4 overflow-auto rounded'>
        <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
          {isLoading ? (
            <>
              <FadedAnimatedDiv
                key='loadingTable'
                className='w-full h-full flex items-center justify-center my-10 flex-col'
              >
                <LottieWrapper
                  animation={tableLoading}
                  loop
                  className='h-16 w-16'
                />
                <span className='text-base'>loading...</span>
              </FadedAnimatedDiv>
            </>
          ) : meetings.length ? (
            <div className='min-w-full'>
              <h3 className='pb-4 w-full font-bold text-xl text-center md:text-start m-0 border-b border-gray-300'>
                Booking Links
              </h3>
              <div className='divide-y divide-gray-200'>
                {meetings?.map((data, index) => {
                  return (
                    <div
                      key={index}
                      className='p-2 items-center shadow-none flex justify-between'
                    >
                      <div className='font-semibold'>
                        {data.event_booking.title}{' '}
                        <span className='font-normal'>
                          ( {data?.participants?.[0].name ?? ''} )
                        </span>
                      </div>
                      <div className='flex gap-x-2 items-center'>
                        <CopyLinkButton
                          meetingUrl={`https://book.nylas.com/us/${process.env.REACT_APP_NYLAS_CLIENT_ID}/${data.slug}`}
                        />
                        <CustomButton
                          className='px-3 py-1.5'
                          handleClick={() =>
                            handleUseLink(
                              `https://book.nylas.com/us/${process.env.REACT_APP_NYLAS_CLIENT_ID}/${data.slug}`
                            )
                          }
                        >
                          Add Link
                        </CustomButton>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <h1 className='text-center text-xl py-6 mb-0'>
              Booking Links not found.
            </h1>
          )}
        </div>
      </div>
    );
  };

  const handleAppendBookingLink = () => {
    let emailBody = emailData.body;
    emailBody =
      emailBody +
      `<a hred={${userObject?.salesRepData?.bookingLink}}>${userObject?.salesRepData?.bookingLink}</a>`;
    onChange('body', emailBody);
  };

  const handleAppendZoomLink = () => {
    let emailBody = emailData.body;
    emailBody =
      emailBody +
      `<a hred={${userObject?.salesRepData?.zoomLink}}>${userObject?.salesRepData?.zoomLink}</a>`;
    onChange('body', emailBody);
  };

  return (
    <>
      <CustomModal
        isOpen={show}
        closeModal={handleCloseModal}
        contentLabel='New Email Modal'
        customStyles={{ ...defaultStyles }}
      >
        <div className='h-full flex flex-col overflow-hidden'>
          <div className='w-full relative'>
            <h1
              className={`font-bold mt-4 md:mt-6 ${
                isMobile ? 'text-lg' : 'text-xl '
              } text-center p-0 m-0 font-openSans tracking-wider`}
            >
              New Email |{' '}
              {(contact?.firstName || '') + ' ' + (contact?.lastName || '')}
            </h1>
            <button
              className='absolute top-3 md:top-4 right-3 md:right-4'
              onClick={handleCloseModal}
            >
              <CrossIcon />
            </button>
          </div>
          <div className='flex lg:w-[50%] mt-2 rounded-lg bg-white px-6 md:px-14  text-zinc-600'>
            {tabMenus.map((tab, index) => (
              <div
                key={index}
                onClick={() => {
                  setSelectedTab(tab.id);
                }}
                className={`flex w-1/4 md:w-fit text-center justify-center items-center px-2 md:px-14 py-1 cursor-pointer rounded-[4px] font-semibold text-xs ${
                  selectedTab === tab.id
                    ? 'bg-softBlack text-white shadow-sm'
                    : 'text-zinc-500'
                } transition-all`}
              >
                {tab.name}
              </div>
            ))}
          </div>
          <div className='flex-1 w-full flex flex-row gap-2 md:gap-6 font-openSans rounded shadow-lg p-2 md:p-6 overflow-hidden'>
            {selectedTab === 'template' && <Templates />}
            {selectedTab === 'booking-links' && <BookingLinks />}
            <div
              style={{
                display: showTemplates && isMobile ? 'none' : 'flex',
              }}
              className='w-full lg:w-1/2 h-full flex relative overflow-hidden flex-col gap-4 rounded'
            >
              {isMobile && (
                <CustomButton
                  className='mt-5'
                  handleClick={() => setShowTemplates(true)}
                  disabled={sendingEmail}
                >
                  <span className='font-semibold px-4 py-2 rounded text-base flex items-center gap-2'>
                    {sendingEmail && (
                      <Oval height='18' width='18' color='white' />
                    )}
                    Show Templates
                  </span>
                </CustomButton>
              )}
              <div className='flex-1 p-2 overflow-y-auto flex flex-col gap-3'>
                <AddUserEmailInput
                  contacts={contacts}
                  loading={isContactLoading}
                  label='To'
                  selectedEmails={emailData.to?.length ? emailData.to : null}
                  singleSelection={false}
                  setSelectedEmails={(_val) => {
                    onChange('to', _val);
                  }}
                  placeholder='Enter Receivers Email (use comma for multiple values)'
                />
                <AddUserEmailInput
                  contacts={contacts}
                  loading={isContactLoading}
                  label='CC'
                  selectedEmails={emailData.cc}
                  setSelectedEmails={(_val) => {
                    onChange('cc', _val);
                  }}
                  placeholder='Enter CC Email (use comma for multiple values)'
                />
                <AddUserEmailInput
                  contacts={contacts}
                  loading={isContactLoading}
                  label='BCC'
                  selectedEmails={emailData.bcc}
                  setSelectedEmails={(_val) => {
                    onChange('bcc', _val);
                  }}
                  placeholder='Enter BCC Email (use comma for multiple values)'
                />
                <CustomInput
                  label='Subject'
                  placeholder='Enter Subject'
                  value={emailData.subject}
                  onChange={(e) => {
                    onChange('subject', e.target.value);
                  }}
                  required
                  containerClasses={undefined}
                  classes={undefined}
                />
                <div
                  className='relative'
                  style={{
                    height: '560px',
                    marginBottom: isMobile ? 20 : undefined,
                    overflow: '',
                  }}
                >
                  {userType === 'SalesRep' && (
                    <>
                      {userObject?.bookingLink && (
                        <div
                          onClick={handleAppendBookingLink}
                          style={{ top: '30px', backgroundColor: '#85878F' }}
                          className='bg-grey-9 text-white p-1 rounded absolute right-0 cursor-pointer'
                        >
                          BOOKING LINK
                        </div>
                      )}
                      {userObject?.zoomLink && (
                        <div
                          onClick={handleAppendZoomLink}
                          style={{
                            top: '30px',
                            right: '110px',
                            backgroundColor: '#85878F',
                          }}
                          className=' text-white p-1 rounded absolute cursor-pointer'
                        >
                          ZOOM LINK
                        </div>
                      )}
                    </>
                  )}
                  <p
                    className={
                      'mt-0 mb-2 text-softBlack70 text-xs font-medium uppercase'
                    }
                    style={{ letterSpacing: '0.16px' }}
                  >
                    EMAIL TEXT
                  </p>

                  <RichTextField
                    editorRef={editorRef}
                    onChangeText={handleBodyChange}
                    text={emailData?.body}
                    placeholder={'Type text here...'}
                    onChangeFileAttachments={(attachments) =>
                      setFileAttachments(attachments)
                    }
                  />
                </div>
              </div>
              <CustomButton
                handleClick={handleSendEmail}
                disabled={sendingEmail}
              >
                <span className='font-semibold px-4 py-2 rounded text-base flex items-center gap-2'>
                  {sendingEmail && (
                    <Oval height='18' width='18' color='white' />
                  )}
                  Send Email
                </span>
              </CustomButton>
            </div>
          </div>
        </div>
      </CustomModal>
      <Modal
        showModal={showConfirmSendingEmailModal}
        title='Confirm sending the email?'
        subtitle="your email doesn't seem to have any subject, would you like to send it anyway?"
        modalType='alert'
        onOk={_sendEmail}
        onCancel={onCancelModal}
      />
      {selectedTemplateForPreview && (
        <EmailTemplatePreview
          selectedTemplate={selectedTemplateForPreview}
          onClose={() => setSelectedTemplateForPreview(null)}
          open={selectedTemplateForPreview}
        />
      )}
    </>
  );
};

export const EmailAttachmentSVGIcon = (contentType) => {
  const Component =
    typeof contentType === 'string'
      ? EMAIL_ATTACHMENT_SVG[contentType] ?? EMAIL_ATTACHMENT_SVG.default
      : EMAIL_ATTACHMENT_SVG.default;
  return <Component className='w-10 h-10' />;
};

export default EmailModal;

const CopyLinkButton = ({ meetingUrl }) => {
  const [copyLinkState, setCopyLinkState] = useState('Copy Link');

  useEffect(() => {
    if (copyLinkState === 'Copied') {
      const timeoutId = setTimeout(() => {
        setCopyLinkState('Copy Link');
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [copyLinkState]);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(meetingUrl);
    setCopyLinkState('Copied');
    toast.success('Link Copied');
  };

  return (
    <CustomButton handleClick={handleCopyLink} className='px-3 py-1.5'>
      {copyLinkState === 'Copy Link' ? (
        <span>Copy Link</span>
      ) : (
        <span>Copied</span>
      )}
    </CustomButton>
  );
};
