import useGetBrokerClients from 'hooks/useGetBrokerClients'
import React from 'react'
import BrokerClientsTable from './BrokerClientsTable'

const BrokerClientsTab = (props) => {
  const { brokerClients, loading } = useGetBrokerClients()

  if (loading) return <div>loading...</div>
  return (
        <div>
          <BrokerClientsTable clients={brokerClients} {...props} />
        </div>
  )
}

export default BrokerClientsTab
