import React from 'react'
import clsx from 'clsx'

const ToggleSwitch = ({ checked, setChecked }) => {
  const toggleOption = () => setChecked((prev) => !prev)

  return (
    <label className="flex items-center cursor-pointer relative">
      <input
        type="checkbox"
        checked={checked}
        onChange={toggleOption}
        className="sr-only"
      />
      <div
        className={clsx('after:absolute after:top-0.5 after:bg-white after:border after:rounded-full after:h-6.5 after:w-6.5 transition shadow-sm h-7.5 w-13 rounded-full', {
          'after:translate-x-full after:-left-0.5 after:border-white bg-softBlack border-2 border-softBlack': checked,
          'after:left-0.5 after:border-gray-300 bg-gray-200 border-2 border-gray-200': !checked
        })}
      ></div>
    </label>
  )
}

export default ToggleSwitch
