import React, { useMemo, useState } from 'react'
import AddToListButton from './MobileCardComponents/AddToListButton'
import DeleteButton from './MobileCardComponents/DeleteButton'
import LeadStatusSelect from './MobileCardComponents/LeadStatusSelect'
import RatingSelect from './MobileCardComponents/RatingSelect'
import SalesRepSelect from './MobileCardComponents/SalesRepSelect'
import UserName from './MobileCardComponents/UserName'
import UserType from './MobileCardComponents/UserType'
import { TFilledEmailIcon, ScheduleIcon } from 'components/Icons'
import EmailModal from 'pages/contacts/EmailModal'
import NewMeeting from 'components/NewMeeting'
import TaskRow from 'components/TaskCard'
import { NotesTab } from 'pages/contacts/NotesTab'
import { useSelector } from 'react-redux'

const ActionButtonTop = ({ label, icon, onClick }) => {
  return (
    <button
      className='flex items-center border w-22 h-10 justify-center rounded border-black font-semibold'
      onClick={onClick}
    >
      <div className='p-1 rounded-md h-8 w-8 flex justify-center items-center cursor-pointer'>
        {icon}
      </div>
      <span className='font-semiBold text-xs text-center'>{label}</span>
    </button>
  )
}

const MobileCardSidebar = ({
  firstName,
  lastName,
  email,
  userType,
  leadStatuses,
  scores,
  handleLeadStatusChange,
  leadStatus,
  rating,
  handleRatingChange,
  onClickDelete,
  buyerData,
  buyerEmail,
  developerAdminId,
  handleSalesRepChange,
  projectId,
  salesRep,
  salesrep,
  setSalesRep,
  setOpenAddToList,
  forceMobile = false,
  showDeleteButton = true,
  contact,
  buyers,
  tasksList
}) => {
  // @ts-ignore
  const userObject = useSelector((state) => state.authReducer.userObject)
  const [showEmailBox, setShowEmailBox] = useState(false)
  const [showMeetingBox, setShowMeetingBox] = useState(false)

  const ACTION_BUTTONS = [
    {
      key: 'email',
      label: 'Send Email',
      icon: <TFilledEmailIcon />,
      onClick: () => {
        setShowEmailBox(true)
      }
    },
    {
      key: 'meeting',
      label: 'Book Meeting',
      icon: <ScheduleIcon />,
      onClick: () => {
        setShowMeetingBox(true)
      }
    }
  ]

  const buyersUpcomingTasks = useMemo(() => {
    const result = []
    if (tasksList?.upcommingTasks?.length && contact?._id) {
      tasksList?.upcommingTasks?.forEach((task) => {
        if (task?.buyer?.id === contact?._id) {
          result.push(task)
        }
      })
    }
    return result
  }, [tasksList?.upcommingTasks, contact])

  return (
    <>
      {showEmailBox && (
        <EmailModal
          show={showEmailBox}
          handleClose={() => setShowEmailBox(!showEmailBox)}
          contact={contact}
        />
      )}
      {showMeetingBox && (
        <NewMeeting
          isOpen={showMeetingBox}
          setOpen={() => setShowMeetingBox(!showMeetingBox)}
          contact={contact}
          meeting={null}
          onSuccess={null}
          buyers={buyers}
        />
      )}
      <div className='flex flex-col justify-items-start items-center w-full h-full'>
        <UserName firstName={firstName} lastName={lastName} />
        <p className='text-xs text-gray-600 mt-[6px] mb-0'>{email}</p>
        <SalesRepSelect
          buyerData={buyerData}
          buyerEmail={buyerEmail}
          developerAdminId={developerAdminId}
          handleSalesRepChange={handleSalesRepChange}
          projectId={projectId}
          salesRep={salesRep}
          salesrep={salesrep}
          setSalesRep={setSalesRep}
          userType={userType}
          forceMobile
        />
        <UserType userType={userType} />
        <div className='flex space-x-2 mt-5 mb-3 overflow-x-scroll overflow-y-auto'>
          {ACTION_BUTTONS.map((props) => {
            return <ActionButtonTop {...props} key={props.key} />
          })}
        </div>
        <LeadStatusSelect
          userType={userType}
          leadStatuses={leadStatuses}
          leadStatus={leadStatus}
          handleLeadStatusChange={handleLeadStatusChange}
          marginTop='30px'
          forceMobile
        />
        <RatingSelect
          rating={rating}
          ratings={scores}
          handleRatingChange={handleRatingChange}
          marginTop='30px'
          forceMobile
        />
        {!forceMobile && (
          <AddToListButton setOpenAddToList={setOpenAddToList} />
        )}
        {showDeleteButton && (
          <DeleteButton onClickDelete={onClickDelete} marginTop='30px' />
        )}
        {/* Upcoming Tasks */}
        <p className='font-bold text-lg mt-4'>Upcoming Tasks</p>
        {buyersUpcomingTasks?.length > 0 && (
          <div className='flex flex-col overflow-y-scroll gap-2 h-64 w-full'>
            {buyersUpcomingTasks?.map((task, index) => {
              return (
                <TaskRow
                  key={`${task?._id}${index}`}
                  task={task}
                  refetch={null}
                  showPadding={false}
                />
              )
            })}
          </div>
        )}
        {!buyersUpcomingTasks?.length && (
          <p className='font-bold text-sm text-softBlack70'>All Cleared</p>
        )}
        {/* Notes */}
        <div className='flex flex-col overflow-y-scroll gap-2 h-64 mt-4 w-full border'>
          <NotesTab contact={contact} user={userObject?._id} />
        </div>
      </div>
    </>
  )
}

export default MobileCardSidebar
