/* eslint-disable no-unused-vars */
import { Select, Checkbox } from 'antd'
import React, { useCallback, useState } from 'react'
import { ReactComponent as DownIcon } from 'assets/icons/down.svg'
import CustomInput from 'components/CustomInput'
import moment from 'moment'
import CustomModal from 'components/Modal'
import { paymentStatusArr } from '../new'

const RecordPaymentModal = ({ offerData, onSubmit, show, setShow }) => {
  const [data, setData] = useState({
    paymentStatus: offerData?.paymentStatus ?? '',
    paymentReceivedBy: offerData?.paymentReceivedBy ?? '',
    paymentReceivedDate:
      moment(offerData?.paymentReceivedDate).format('YYYY-MM-DDTHH:mm') ?? '',
    consent: false
  })

  const handleClose = () => {
    setShow(false)
  }

  const onChange = useCallback(
    (fieldName, value) => {
      setData((prevState) => ({ ...prevState, [fieldName]: value }))
    },
    [data]
  )

  const validate = () => {
    for (const key in data) {
      if (!data[key]) {
        return false
      }
    }
    return true
  }

  return (
    <CustomModal
      isOpen={show}
      closeModal={handleClose}
      contentLabel='Add Or Edit Contact Modal'
    >
      <div className='p-3 text-lg font-bold grid gap-x-12 gap-y-4 h-full grid-cols-1'>
        <div className='flex flex-col gap-10 justify-center items-center rounded-3xl p-4'>
          <div className='flex justify-between items-center w-4/5 pt-5'>
            <h1 className='text-4xl text-italic'>Record Payment</h1>
            <div
              className='flex justify-center items-center font-bold py-3  px-12 border border-black rounded cursor-pointer'
              onClick={() => {
                setShow(false)
              }}
            >
              Exit
            </div>
          </div>
          <div className='flex flex-col gap-3 w-full items-center'>
            <div className='flex flex-col w-4/5 items-start justify-start gap-2'>
              <div className='font-normal'>Payment Status</div>
              <div className='w-full'>
                <Select
                  suffixIcon={<DownIcon />}
                  showArrow
                  placeholder='Select'
                  value={data.paymentStatus}
                  onChange={(value) => {
                    onChange('paymentStatus', value)
                  }}
                  getPopupContainer={(node) => node.parentNode}
                  className='ant-picker-input1 w-full'
                >
                  {paymentStatusArr.map?.((_item, index) => (
                    <Select.Option value={_item} key={index}>
                      <div
                        className='flex gap-2 items-center'
                        style={{ zIndex: '1500 !important' }}
                      >
                        <span>{_item}</span>
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
            <div className='flex flex-col w-4/5 items-start justify-start gap-2'>
              <div className='font-normal'>RECIEVED BY</div>
              <div className='w-full'>
                <CustomInput
                  label=''
                  placeholder='Enter name of reciever'
                  value={data.paymentReceivedBy}
                  onChange={(e) => {
                    onChange('paymentReceivedBy', e.target.value)
                  }}
                  type='text'
                  containerClasses={undefined}
                  classes=''
                  required
                />
              </div>
            </div>
            <div className='flex flex-col w-4/5 items-start justify-start gap-2'>
              <div className='font-normal'>DATE RECIEVED</div>
              <div className='w-full'>
                <CustomInput
                  label=''
                  value={data.paymentReceivedDate}
                  onChange={(e) => {
                    onChange(
                      'paymentReceivedDate',
                      moment(e.target.value).format('YYYY-MM-DDTHH:mm')
                    )
                  }}
                  type='datetime-local'
                  classes='uppercase'
                  min={moment().format('YYYY-MM-DD')}
                />
              </div>
            </div>
            <div className='flex items-start justify-start w-4/5'>
              <Checkbox
                className='font-normal'
                checked={data.consent}
                onChange={(data) => {
                  onChange('consent', data.target.checked)
                }}
              >
                I confirm that I have recieved payment
              </Checkbox>
            </div>
            <button
              className='flex justify-center items-center font-bold py-4  px-12 bg-volt rounded cursor-pointer'
              onClick={() => {
                setShow(false)
                onSubmit(data)
              }}
              disabled={!validate()}
            >
              Save Payment
            </button>
          </div>
        </div>
      </div>
    </CustomModal>
  )
}
export default RecordPaymentModal
