import React from 'react'

const Header = () => {
  return (
    <>
      <div
        className='w-full bg-white fixed flex justify-between items-center px-8 py-6 border-b-2 border-softBlack'
      >
        <p className='my-0 text-4xl font-bold'>Purchase Additional parking</p>
      </div>
    </>
  )
}

export default Header
