import React from 'react'
import CustomModal from 'components/Modal'
import { Oval } from 'react-loader-spinner'
import CustomButton from 'pages/contacts/CustomButton'

const DeleteModal = ({ isOpen, setIsOpen, onConfirm, title, description, confirmButtonTitle, cancelButtonTitle, loading = false, isBtnLoading = false }) => {
  const closeModal = () => {
    setIsOpen(false)
  }
  return (
    <CustomModal isOpen={isOpen} closeModal={closeModal} contentLabel="Cancel Modal">
      {loading && (
        <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
          <Oval height="50" width="50" color="black" />
        </div>
      )}
      <div className='w-[95vw] md:w-136 max-w-full p-5 md:p-10 text-center rounded bg-grey-8 mx-auto'>
        <h1 className='text-softBlack font-bold text-xl mb-0'>{title}</h1>
        <p className='mb-0 mt-4 md:mt-6 text-softBlack text-base'>{description}</p>
        <div className='flex justify-center items-center gap-4 md:gap-6 mt-8'>
          <CustomButton className='w-37.5 h-12' variant='outline' handleClick={closeModal} disabled={isBtnLoading}>
            <span className='text-base font-medium'>
              {cancelButtonTitle}
            </span>
          </CustomButton>
          <CustomButton className='w-37.5 h-12' handleClick={onConfirm} disabled={isBtnLoading}>
            <span className='text-base font-medium flex items-center justify-center gap-2'>
              {
                isBtnLoading &&
                <Oval height="20" width="20" color="black" />
              }
              {confirmButtonTitle}
            </span>
          </CustomButton>
        </div>
      </div>
    </CustomModal>
  )
}

export default DeleteModal
