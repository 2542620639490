import React from 'react'
import CustomModal from 'components/Modal'
import DoneBlackBorder from 'assets/icons/doneBlackBorder.svg'
import moment from 'moment'

const AllocationNameModal = ({ duration, open, setOpen }) => {
  const closeModal = () => {
    setOpen(false)
  }
  const lastdate = moment(new Date()).add(duration, 'days')
  return (
    <CustomModal
      isOpen={open}
      closeModal={closeModal}
      contentLabel='Cancel Modal'
    >
      <div className='w-136 max-w-full p-10 text-center rounded bg-grey-8'>
        <img
          src={DoneBlackBorder}
          alt='Confirm Tick Icon'
          className='mx-auto'
        />
        <h1
          className='text-softBlack font-bold text-xl mb-0 font-openSans'
          style={{ marginTop: '14.67px' }}
        >
          Allocation Confirmed{' '}
        </h1>
        <p className='mb-8 mt-6 text-softBlack font-openSans font-normal text-base'>
          Units will be kept reserved by {lastdate.format('DD MMM YYYY')}
        </p>
        <button
          className='border rounded border-softBlack70 text-base font-light italic font-openSans py-3 px-6 text-softBlack'
          onClick={closeModal}
        >
          Close
        </button>
      </div>
    </CustomModal>
  )
}

export default AllocationNameModal
