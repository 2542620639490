/*eslint-disable */
// @ts-nocheck
import { Drawer, makeStyles } from '@material-ui/core';
import CustomButton from 'components/CustomButton';
import CustomRadio from 'components/CustomRadio';
import CustomSelect from 'components/Inventory/components/CustomSelect';
import useBuyers from 'hooks/useGetBuyers';
import useGetLists from 'hooks/useGetLists';
import useGetProjectBuyers from 'hooks/useGetProjectBuyers';
import React, { useEffect, useMemo, useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import { Oval } from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import { getDocuSealContractTemplateByProjectId } from 'store/actions/contractTemplateActions';
import { createMassSingularContract } from 'store/actions/singularContractsAction';
import CustomModal from "ui/customModal";

const CONTRACT_TYPE = "singularContract"

const CreateSinglePageCampaign = ({ isOpen, onClose, onSuccess = () => { } }) => {
  const projectId = useSelector((state) => state.appReducer.appProject)
  const [isChecked, setIsChecked] = useState(true)
  const [templates, setTemplates] = useState([])
  const [selectedTemplate, setSelectedTemplate] = useState('')
  const { loading: loadingBuyers, buyers } = useGetProjectBuyers()
  const [selectedBuyers, setSelectedBuyers] = useState([])
  const [selectedRecipients, setSelectedRecipients] = useState([])
  const { lists } = useGetLists(projectId)
  const [selectedList, setSelectedList] = useState('')
  const [isSendingCampaign, setIsSendingCampaign] = useState(false)
  const [isRecipientsDataLoading, setIsRecipientsDataLoading] = useState(false)
  const [isOpenConfirmRemoveModal, setIsOpenConfirmRemoveModal] = useState(false)
  const [removeToBeUser, setRemoveToBeUser] = useState(null)

  const useStyles = makeStyles((theme) => ({
    hide: {
      display: 'none',
    },
    drawer: {
      // width: isMobile ? '100%' : 'calc(100% - 280px)',
      width: '100%',
      flexShrink: 0,
    },
    drawerPaper: {
      // width: isMobile ? '100%' : 'calc(100% - 280px)',
      width: '100%',
      height: '100vh',
      overflow: 'hidden',
      boxShadow: '-10px 0px 15px 0px rgba(46, 43, 46, 0.05)',
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
  }));

  const classes = useStyles();

  const handleClose = () => {
    setSelectedTemplate('')
    setSelectedList('')
    setSelectedBuyers([])
    setSelectedRecipients([])
    setIsOpenConfirmRemoveModal(false)
    setRemoveToBeUser(null)
    onClose()
  }

  const getContracts = () => {
    getDocuSealContractTemplateByProjectId(projectId, CONTRACT_TYPE)
      .then((res) => {
        setTemplates(res)
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  }

  useEffect(() => {
    getContracts()
  }, [projectId])

  const isValid = useMemo(() => {
    if (selectedRecipients?.length > 0 && selectedTemplate?.length > 0) {
      return true
    }
    return false
  }, [selectedRecipients, selectedTemplate])

  const sendCampaign = () => {
    if (isValid) {
      setIsSendingCampaign(true)
      createMassSingularContract({
        buyers: [...selectedRecipients?.map((recipient) => (recipient?._id))],
        contractTemplate: selectedTemplate
      }).then(() => {
        onSuccess()
        handleClose()
        setIsSendingCampaign(false)
      }).catch((error) => {
        console.error('error: ', error)
        handleClose()
        setIsSendingCampaign(false)
      })
    }
  }

  const selectedContractTemplatePreviewImageUrl = useMemo(() => {
    if (templates?.length > 0 && selectedTemplate?.length > 0) {
      return templates.filter((template) => (template._id === selectedTemplate))?.[0]?.documents?.[0]?.preview_image_url
    }
    return null
  }, [templates, selectedTemplate])

  useEffect(() => {
    let users = []

    if (isChecked && selectedList?.length > 0) {
      setIsRecipientsDataLoading(true)
      users = [...lists.filter((list) => (list._id === selectedList))[0]?.users]
    }

    if (!isChecked && selectedBuyers?.length > 0) {
      setIsRecipientsDataLoading(true)
      users = [...selectedBuyers]
    }

    const tempUsers = users?.map((userId) => ({
      ...buyers.filter((buyer) => (buyer._id === userId))?.[0]
    }))

    setSelectedRecipients([...tempUsers])
  }, [selectedList, selectedBuyers, lists, buyers, isChecked])

  useEffect(() => {
    if (selectedRecipients && selectedRecipients?.length > 0 && isRecipientsDataLoading) {
      const temp = selectedRecipients?.filter((user) => (user?.firstName?.length > 0))
      if (temp?.length > 0) {
        setIsRecipientsDataLoading(false)
      }
    } else if (selectedRecipients?.length === 0) {
      setIsRecipientsDataLoading(false)
    }
  }, [selectedRecipients])

  const closeConfirmRemoveModal = () => {
    setIsOpenConfirmRemoveModal(false)
    setRemoveToBeUser(null)
  }

  const handleRemoveRecipient = () => {
    if (removeToBeUser !== null && removeToBeUser?._id?.length > 0) {
      setSelectedRecipients([
        ...selectedRecipients.filter((recipient) => (recipient?._id !== removeToBeUser?._id))
      ])
      if (selectedBuyers?.length > 0) {
        setSelectedBuyers([
          ...selectedBuyers.filter((buyer) => (buyer !== removeToBeUser?._id))
        ])
      }
    }
    closeConfirmRemoveModal()
  }

  const handleRadioButtonChange = (val) => {
    if (!isSendingCampaign) {
      setSelectedRecipients([])
      setSelectedBuyers([])
      setSelectedList('')
      setIsChecked(val)
    }
  }

  return (
    <>
      <Drawer
        open={isOpen}
        variant='persistent'
        anchor='right'
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className='p-6 pt-22 relative h-full flex flex-col gap-4'>
          <div className='col-span-2 h-fit flex justify-between items-center'>
            <h2 className='capitalize text-xl font-bold mb-0'>
              Single page campaign
            </h2>
            <CustomButton
              handleClick={handleClose}
              variant='outline'
              className='overflow-hidden'
            >
              <span className='text-sm flex items-center gap-2 px-3 py-1.5 hover:bg-zinc-200 transition-all'>
                <IoMdClose fontSize='16px' />
                Close
              </span>
            </CustomButton>
          </div>
          <div className='w-full flex-1 grid grid-cols-2 gap-4 overflow-hidden'>
            <div className='col-span-1 flex flex-col gap-4 h-full overflow-hidden'>
              <div className='w-full border rounded-md p-4'>
                <h3 className='capitalize text-xl font-bold mb-0'>
                  campaign settings
                </h3>
                <p className='text-xs font-medium text-zinc-400 mb-0 mt-1'>
                  Configure your contract campaign
                </p>
                <div className='pt-10 flex flex-col gap-4'>
                  <CustomSelect
                    label='Choose Contract Template'
                    value={selectedTemplate}
                    handleChange={(value) => setSelectedTemplate(value)}
                    options={templates?.length > 0 ? templates.map((template) => ({ value: template._id, label: template.name })) : []}
                    placeholder='Select a template'
                    labelClass='text-xs !font-medium capitalize !text-softBlack'
                    disabled={isSendingCampaign}
                  />
                  <div className='w-fit flex flex-col gap-1'>
                    <p className='capitalize text-xs font-medium text-softBlack mb-0 mt-1'>
                      Choose Recipients
                    </p>
                    <CustomRadio
                      label="Choose Lists"
                      checked={isChecked}
                      labelClass="text-base !font-medium capitalize !text-softBlack"
                      onClick={() => handleRadioButtonChange(true)}
                    />
                    <CustomRadio
                      label="Choose Recipients One by One"
                      checked={!isChecked}
                      labelClass="text-base !font-medium capitalize !text-softBlack"
                      onClick={() => handleRadioButtonChange(false)}
                    />
                  </div>
                  {
                    isChecked
                      ? <CustomSelect
                        label='Select Recipients List'
                        value={selectedList}
                        handleChange={(value) => setSelectedList(value)}
                        options={lists?.length > 0 ? lists?.filter?.((list) => (list?.projectId?._id === projectId))?.map((list) => ({ value: list._id, label: list.title })) : []}
                        placeholder='Select a list'
                        labelClass='text-xs !font-medium capitalize !text-softBlack'
                        disabled={isSendingCampaign}
                      />
                      : <CustomSelect
                        mode="multiple"
                        label='Select Recipients'
                        value={selectedBuyers}
                        handleChange={(value) => setSelectedBuyers(value)}
                        options={buyers?.length > 0 ? buyers.map((buyer) => ({ value: buyer._id, label: buyer.fullName })) : []}
                        placeholder='Select Recipients'
                        labelClass='text-xs !font-medium capitalize !text-softBlack'
                        showSearch
                        disabled={isSendingCampaign}
                      />
                  }
                </div>
              </div>
              <div className='flex-1 flex flex-col gap-8 w-full border rounded-md p-4 overflow-hidden'>
                <div className='w-full flex flex-col'>
                  <h3 className='capitalize text-xl font-bold mb-0'>
                    Selected Recipients ({selectedRecipients?.length})
                  </h3>
                  <p className='text-xs font-medium text-zinc-400 mb-0 mt-1'>
                    Recipients of this campaign
                  </p>
                </div>
                <div className='flex-1 flex flex-col overflow-y-auto thin-scrollbar'>
                  {
                    !isRecipientsDataLoading && selectedRecipients?.length > 0 && selectedRecipients.map((_item, index) => (
                      <div key={index} className='w-full p-1 py-2 flex justify-between items-center hover:bg-zinc-100 transition-all'>
                        <div className='flex items-center gap-2'>
                          <div className="h-10 w-10 rounded-full flex items-center justify-center bg-zinc-200 text-text-softBlack_70/50 font-bold">
                            {_item?.firstName?.[0]}
                            {_item?.lastName?.[0]}
                          </div>
                          <div className='flex flex-col'>
                            <p className='font-medium mb-0'>{_item?.fullName}</p>
                            <p className='mb-0 leading-none'>{_item?.email}</p>
                          </div>
                        </div>
                        <div className='flex items-center gap-2'>
                          <CustomButton
                            handleClick={() => {
                              setIsOpenConfirmRemoveModal(true)
                              setRemoveToBeUser(_item)
                            }}
                            variant='ghost'
                            className='overflow-hidden'
                            disabled={isSendingCampaign}
                          >
                            <span className='text-xs font-medium flex items-center gap-2 px-3 py-2 hover:bg-red-100 text-red-500 transition-all'>
                              Remove
                            </span>
                          </CustomButton>
                        </div>
                      </div>
                    ))
                  }
                  {
                    isRecipientsDataLoading &&
                    <div className='h-full w-full flex justify-center items-center'>
                      <p className='text-sm font-medium text-zinc-500 mb-0 text-center'>
                        Loading...
                      </p>
                    </div>
                  }
                </div>
              </div>
              <CustomButton
                handleClick={sendCampaign}
                disabled={!isValid || isSendingCampaign}
              >
                <span className='text-base font-medium flex items-center gap-2 px-3 py-2'>
                  {isSendingCampaign && <Oval height='18' width='18' color='white' />}
                  Send Campaign
                </span>
              </CustomButton>
            </div>
            <div className='col-span-1 h-full overflow-hidden'>
              <div className='w-full h-full flex flex-col gap-3 border rounded-md p-4 overflow-hidden'>
                <div className='w-full flex flex-col'>
                  <h3 className='capitalize text-xl font-bold mb-0'>
                    Contract Preview
                  </h3>
                  <p className='text-xs font-medium text-zinc-400 mb-0 mt-1'>
                    Review the contract before sending
                  </p>
                </div>
                {
                  selectedContractTemplatePreviewImageUrl && selectedContractTemplatePreviewImageUrl?.length > 0
                    ? <div className='flex-1 w-fit mx-auto flex justify-center items-center overflow-hidden rounded-md border shadow'>
                      <img src={selectedContractTemplatePreviewImageUrl} className='h-full object-contain' />
                    </div>
                    : <div className='flex-1 flex justify-center items-center'>
                      <p className='text-sm font-medium text-zinc-400 mb-0 mt-1 text-center'>
                        Select a contract template and a recipient to preview
                      </p>
                    </div>
                }
              </div>
            </div>
          </div>
        </div>
      </Drawer>
      <CustomModal
        showModal={isOpenConfirmRemoveModal}
        onCancel={closeConfirmRemoveModal}
        onOk={handleRemoveRecipient}
        minHeight={false}
      >
        <div className="min-w-[95%] md:min-w-[500px] md:min-h-[200px] flex flex-col gap-3">
          <h2 className="text-2xl mb-0">Are you sure?</h2>
          <h4 className="text-xl mb-2">
            Do you want to remove
            <span className='ml-1.5 font-bold'>
              {removeToBeUser?.fullName}
            </span>
            ?
          </h4>
          <div className="flex-1 w-full mt-2 flex justify-center md:justify-end items-end gap-3">
            <CustomButton
              variant="ghost"
              handleClick={closeConfirmRemoveModal}
              className="min-w-[150px]"
            >
              <span className="font-medium py-2 px-4 text-base flex gap-2 items-center">
                Cancel
              </span>
            </CustomButton>
            <CustomButton variant="danger" handleClick={handleRemoveRecipient} className="min-w-[150px]">
              <span className="font-medium py-2 px-4 text-base flex gap-2 items-center">
                Yes
              </span>
            </CustomButton>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default CreateSinglePageCampaign;
