import React, { useState, useEffect, useCallback } from 'react'
import propTypes from 'prop-types'
import { AdminPageWrapper, FadedAnimatedDiv, ImageIcon, ImageUpload, PageHeader, TCancelButton, UserData } from 'components'
import { useHistory, useLocation } from 'react-router-dom'
import { createUploadFileList, getFileUrl, getUserType, updateLoggedUserObject } from 'utils'
import { Modal, TButton } from 'ui'
import { changeUserAvatarImage, getUsers } from 'store/actions/usersActions'
import { AnimatePresence } from 'framer-motion'
import { connect } from 'react-redux'
import useUpdateUsers from 'hooks/useUpdateUsers'

const CreateEditUser = (props) => {
  const location = useLocation()
  const history = useHistory()
  const { createOrUpdateUser } = useUpdateUsers()
  const {
    userObject: {
      userType: loggedUserType,
      _id: loggedUserId
    }
  } = props

  const [showChangeImageModal, setShowChangeImageModal] = useState(false)
  const [profileImages, setProfileImages] = useState([])
  const [uploadingProfileImage, setUploadingProfileImage] = useState(false)
  const [removingProfileImage, setRemovingProfileImage] = useState(false)

  const {
    state: {
      _id,
      userType
    }
  } = location

  useEffect(() => {
    if (_id) {
      getUsers(_id)
        .then((result) => {
          const { userAvatar } = result
          let tmpUserAvatar = []

          if (userAvatar) {
            tmpUserAvatar = createUploadFileList(userAvatar)
          }

          setProfileImages(tmpUserAvatar)
        })
    }
  }, [_id])

  const RightContent = () => (
    <TCancelButton
      onClick={() => history.goBack()}
    >
      Back
    </TCancelButton>
  )

  const allowImageUpdate = useCallback(() => {
    // If is changing it's own image, allow to update
    if (_id === loggedUserId) {
      return true
    }

    if (
      (loggedUserType === 'SuperAdmin') ||
      (loggedUserType === 'SalesRep' && !profileImages.length)
    ) {
      return true
    }

    return false
  }, [_id, loggedUserType, loggedUserId, profileImages])

  const openUserImageModal = () => {
    setShowChangeImageModal(true)
  }

  const UserImageUploader = useCallback(() => (
    <ImageUpload
      uploadProps={{
        name: 'imageUpload',
        fileList: profileImages,
        listType: 'picture-card',
        className: 'avatar-uploader',
        showUploadList: true,
        multiple: false,
        onChange: ({ fileList }) => {
          setProfileImages(fileList)
        },
        beforeUpload: () => {
          if (!_id) {
            return false
          }
        },
        customRequest: async (componentData) => {
          if (_id) {
            const { file } = componentData
            const tmpUrl = await getFileUrl(file)
            const tmpArray = Array.from({ length: 1 }, () => ({ url: tmpUrl }))

            setProfileImages(tmpArray)
            setUploadingProfileImage(true)

            changeUserAvatarImage({ file, _id })
              .then(() => {
                setUploadingProfileImage(false)
                updateLoggedUserObject()
                setShowChangeImageModal(false)
              })
              .catch(() => {
                setUploadingProfileImage(false)
              })
          }
        },
        // onRemove: (file) => {
        //   deleteProjectOptionImage({
        //     projectId,
        //     optionId: optionId,
        //     url: file.url
        //   })
        //     .then(() => {
        //       _getProjectOption(optionId)
        //     })
        //     .catch(() => {})
        // },
        style: { height: '100%', width: '100%' }
      }}
      showUploadButton={profileImages.length === 0}
      uploadButtonText='Click or drag here the User Profile Image'
      uploadButtonIcon={<ImageIcon style={{ fontSize: 30 }} />}
      uploadWrapperStyle={{
        width: '100%',
        marginRight: 0
      }}
      // loading={gettingData}
      skeletonHeight={100}
      title=''
    />
  ), [_id, profileImages])

  const removeProfileImage = useCallback(() => {
    setProfileImages([])
    setRemovingProfileImage(true)

    const params = {
      id: _id,
      userAvatar: ''
    }

    createOrUpdateUser(params, false)
      .then(() => {
        updateLoggedUserObject()
        setRemovingProfileImage(false)
      })
      .catch(() => {
        setRemovingProfileImage(false)
      })
  }, [_id])

  return (
    <>
      <Modal
        showModal={showChangeImageModal}
        onCancel={() => setShowChangeImageModal(false)}
        modalType='custom'
      >
        <AnimatePresence initial={false} exitBeforeEnter>
          {
            profileImages.length
              ? (
              <FadedAnimatedDiv
                key='profileImage'
                className='w-full flex justify-center flex-col items-center'
              >
                <img src={profileImages[0].url} className='rounded-full h-40 w-40' alt='Profile' />
                <TButton
                  className='mt-2'
                  onClick={() => removeProfileImage()}
                  loading={uploadingProfileImage || removingProfileImage}
                >
                  {uploadingProfileImage ? 'Uploading image' : 'Remove image'}
                </TButton>
              </FadedAnimatedDiv>
                )
              : (
              <FadedAnimatedDiv
                key='profileAvatarUpload'
              >
                <UserImageUploader />
              </FadedAnimatedDiv>
                )
          }
        </AnimatePresence>
      </Modal>
      <PageHeader
        title={`${_id ? 'Editing' : 'Creating new'} ${getUserType(userType)}`}
        rightContent={<RightContent />}
        onImageClick={allowImageUpdate() ? () => openUserImageModal() : null}
        image={profileImages.length ? profileImages[0].url : ''}
        showEmptyImage
      />
      <AdminPageWrapper>
        <UserData
          id={_id}
          userType={userType}
        />
      </AdminPageWrapper>
    </>
  )
}

const mapStateToProps = state => ({
  userObject: state.authReducer.userObject
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(CreateEditUser)

CreateEditUser.propTypes = {
  userObject: propTypes.shape({
    userType: propTypes.string,
    _id: propTypes.string
  })
}
