// @ts-nocheck
/* eslint-disable */
import { Modal } from "@material-ui/core";
import CustomNumberInput from "components/CustomNumberInput";
import { CloseIcon } from "components/Icons";
import { Button } from "components/ShadcnComponents/button";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
} from "components/ShadcnComponents/card";
import { Input } from "components/ShadcnComponents/input";
import { Select } from "components/ShadcnComponents/select";
import { Textarea } from "components/ShadcnComponents/textarea";
import useGetLeadsReport from "hooks/useGetLeadsReport";
import Checkbox from "pages/offer/components/Checkbox";
import React, { useCallback, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import moment from "moment-timezone";
import { Loader2 } from "lucide-react";
import loadingAnimation from "assets/lottieJsons/tableLoading.json";

import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  getDailyReport,
  getDailyReportById,
  getTodayReport,
  saveDailyReport,
} from "store/actions/reportAction"; // Assume this action exists
import {
  getProjectUnits,
  getUnits,
  getUnitsByProjectSummary,
} from "store/actions/unitActions";
import {
  getLeadsReport,
  getTodayContactsReport,
} from "store/actions/usersActions";
import { Badge } from "@tremor/react";
import CustomSelect from "pages/tasks/components/CustomSelect";
import { ArrowLeftIcon, CalendarIcon } from "lucide-react";
import { ADMIN_MASTER_REPORT } from "routes";
import CustomInput from "components/CustomInput";
import { IoIosArrowBack } from "react-icons/io";
import { DatePicker } from "rsuite";
import "rsuite/dist/rsuite-no-reset.min.css";
import LottieWrapper from "components/LottieWrapper";
import { Loader } from "ui/loader/styles";

const EditableInput = React.memo(({ initialValue, onSave }) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleChange = useCallback(
    (e) => {
      const newValue = e.target.value;
      setValue(newValue);
      onSave(newValue);
    },
    [onSave]
  );

  return <Input type="number" value={value} onChange={handleChange} />;
});

const EditableTable = React.memo(({ data, onChange, title }) => (
  <Card>
    <CardHeader>
      <CardTitle>{title}</CardTitle>
    </CardHeader>
    <CardContent>
      <table className="w-full">
        <tbody>
          {Object.entries(data).map(([key, value]) => (
            <tr key={key}>
              <td className="font-medium">
                {key
                  .replace(/([A-Z])/g, " $1")
                  .replace(/^./, (str) => str.toUpperCase())}
              </td>
              <td>
                <EditableInput
                  initialValue={Number(value)}
                  onSave={(newValue) => onChange(key, newValue)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </CardContent>
  </Card>
));

export default function DailyReportPage(props) {
  const { id: reportId } = useParams();
  const [dailyReport, setDailyReport] = useState(null);
  const router = useHistory();
  const projectId = useSelector((state) => state.appReducer.appProject);
  const dispatch = useDispatch();
  const [leads, setLeads] = useState([]);
  const [tempFetch, setTempFetch] = useState(false);
  const [dailyVisitors, setDailyVisitors] = useState({
    totalTraffic: 0,
    newVisits: 0,
    realtorVisits: 0,
    realtorWithClient: 0,
    industry: 0,
    pastPurchasers: 0,
    newRegistrants: 0,
  });
  const [isDownloading, setIsDownloading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [inventorySummary, setInventorySummary] = useState({
    available: 0,
    reserved: 0,
    offers: 0,
    rescission: 0,
    soldFirm: 0,
    holdbacks: 0,
    totalInventory: 0,
  });

  const [salesUpdate, setSalesUpdate] = useState({
    rescissions: dailyReport?.rescissions || "",
    summary_of_sales_transactions:
      dailyReport?.summary_of_sales_transactions || "",
    deposits_dropped_off: dailyReport?.deposits_dropped_off || "",
  });
  const [reportDates, setReportDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    moment().startOf("day").format("YYYY-MM-DD")
  );

  useEffect(() => {
    const fetchReportDates = async () => {
      try {
        const data = await getDailyReport(projectId);
        let datesMapped = data.map((d) => ({
          label: moment(d.createdAt).format("YYYY-MM-DD"),
          value: d.createdAt,
          _id: d.createdAt,
        }));
        datesMapped.push({
          label: moment().format("YYYY-MM-DD"),
          value: null,
          _id: null,
        });
        setReportDates(datesMapped);
      } catch (error) {
        console.error("Error fetching report dates:", error);
      }
    };

    fetchReportDates();
  }, [projectId]);

  const getTodayLeads = async () => {
    try {
      const leads = await getTodayContactsReport(
        projectId,
        moment(selectedDate).toISOString()
      );
      setDailyVisitors({
        ...dailyVisitors,
        newRegistrants: leads?.length || 0,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getInventorySummary = async () => {
    try {
      const inventory = await getUnitsByProjectSummary(projectId);
      let summary = {
        available: 0,
        reserved: 0,
        offers: 0,
        rescission: 0,
        soldFirm: 0,
        holdbacks: 0,
        totalInventory: inventory?.total?.count || 0,
      };

      inventory?.summary.forEach((item) => {
        const status = item.status.toLowerCase();
        switch (status) {
          case "available":
            summary.available += item.count;
            break;
          case "reserved":
            summary.reserved += item.count;
            break;
          case "soldfirm":
            summary.soldFirm += item.count;
            break;
          case "underconstruct":
            summary.holdbacks += item.count;
            break;
          case "offers":
            summary.offers += item.count;
            break;
          case "rescission":
            summary.rescission += item.count;
            break;
          default:
            break;
        }
      });
      setInventorySummary(summary);
    } catch (error) {
      console.log(error);
    }
  };

  const interactionTypes = [
    {
      label: "Walk In",
      value: "walk-in",
    },
    {
      label: "Appointment",
      value: "appointment",
    },
    {
      label: "SMS",
      value: "sms",
    },
    {
      label: "Call",
      value: "call",
    },
    {
      label: "Email",
      value: "email",
    },
  ];

  const handleDailyVisitorsChange = useCallback((key, value) => {
    setDailyVisitors((prev) => ({
      ...prev,
      [key]: value,
    }));
  }, []);

  const handleInventorySummaryChange = useCallback((key, value) => {
    setInventorySummary((prev) => ({
      ...prev,
      [key]: value,
    }));
  }, []);

  const handleContactChange = (index, field, value) => {
    const newContacts = [...leads];
    newContacts[index][field] = value;
    setLeads(newContacts);
  };

  const handleDownloadDailyReport = async (reportId) => {
    try {
      setIsDownloading(true);
      let data = await getDailyReportById(reportId, projectId);
      let filtered = data[0].interactions.filter((d) => d.isChecked);
      data[0].interactions = filtered;
      let reportData = data[0];
      const formatNotes = (notes) => {
        return notes
          .split("\n")
          .map((line) => {
            if (
              line.startsWith("Task:") ||
              line.startsWith("Notes:") ||
              line.startsWith("Called at")
            ) {
              return `<p style="margin-bottom: 5px;">${line}</p>`;
            } else if (
              line.startsWith("- Notes:") ||
              line.startsWith("- Status:") ||
              line.startsWith("- Call Notes:")
            ) {
              return `<p style="margin-left: 15px; margin-bottom: 5px;">${line}</p>`;
            } else {
              return `<p style="margin-bottom: 24px;">${line}</p>`;
            }
          })
          .join("");
      };
      const reportHtml = `
      <div id="report-container" style="padding: 40px; font-family: Arial, sans-serif; background-color: white;">
        <h1 style="text-align: left; font-size: 28px; margin-bottom: 30px; color: black;">Presentation Center Report | ${moment(
          reportData.date
        ).format("YYYY-MM-DD")}</h1>

        <div style="display: flex; justify-content: space-between; margin-bottom: 40px;">
          <div style="width: 48%; border: 1px solid #e0e0e0; padding: 20px; border-radius: 4px;">
            <h2 style="font-size: 20px; margin-bottom: 20px; color: black;">Daily Visitors</h2>
            <table style="width: 100%; border-collapse: separate; border-spacing: 0 12px;">
              ${Object.entries(reportData.dailyVisitors)
                .map(
                  ([key, value]) => `
                <tr>
                  <td style="padding: 5px 0; color: black;">${key
                    .replace(/([A-Z])/g, " $1")
                    .replace(/^./, (str) => str.toUpperCase())}</td>
                  <td style="padding: 5px 0; text-align: right; font-weight: bold; color: black;">${value}</td>
                </tr>
              `
                )
                .join("")}
            </table>
          </div>
          <div style="width: 48%; border: 1px solid #e0e0e0; padding: 20px; border-radius: 4px;">
            <h2 style="font-size: 20px; margin-bottom: 20px; color: black;">Daily Inventory Summary</h2>
            <table style="width: 100%; border-collapse: separate; border-spacing: 0 12px;">
              ${Object.entries(reportData.dailyInventorySummary)
                .map(
                  ([key, value]) => `
                <tr>
                  <td style="padding: 5px 0; color: black;">${key
                    .replace(/([A-Z])/g, " $1")
                    .replace(/^./, (str) => str.toUpperCase())}</td>
                  <td style="padding: 5px 0; text-align: right; font-weight: bold; color: black;">${value}</td>
                </tr>
              `
                )
                .join("")}
            </table>
          </div>
        </div>

        <div style="margin-bottom: 40px; border-top: 1px solid #e0e0e0; padding-top: 40px;">
          <h2 style="font-size: 20px; margin-bottom: 20px; color: black;">Sales Update</h2>
          <h3 style="font-size: 18px; margin-bottom: 15px; color: black;">Offers</h3>
          <div style="border: 1px solid #e0e0e0; padding: 15px; margin-bottom: 20px;">
            <p style="color: black;">${reportData.rescissions || "-"}</p>
          </div>
          <div style="border: 1px solid #e0e0e0; padding: 15px; margin-bottom: 20px;">
            <p style="color: black;">${
              reportData.summary_of_sales_transactions || "-"
            }</p>
          </div>
          <div style="border: 1px solid #e0e0e0; padding: 15px;">
            <p style="color: black;">${
              reportData.deposits_dropped_off || "-"
            }</p>
          </div>
        </div>

        <div style="border-top: 1px solid #e0e0e0; padding-top: 40px;">
          <h2 style="font-size: 20px; margin-bottom: 20px; color: black;">Contact Interactions</h2>
          ${reportData.interactions
            .map(
              (interaction) => `
           <div style="border: 1px solid #e0e0e0; padding: 20px; margin-bottom: 20px;">
              <div style="display: flex; flex-wrap: wrap; align-items: center; gap: 10px; margin-bottom: 16px;">
                <div style="font-size: 18px; line-height: 20px; color: black; margin: 0; display: flex; align-items: center;">
                <div>
                  ${interaction.user.firstName} ${interaction.user.lastName}
                  </div>
                <div style="font-size: 14px; height: 32px; line-height: 18px; background-color: ${
                  interaction.user.userType === "leadBroker"
                    ? "#e6e4ea"
                    : "#e6f4ea"
                }; padding-left: 12px; padding-right: 12px; margin-top: 16px; border-radius: 6px; font-weight: 500; color: ${
                interaction.user.userType === "leadBroker"
                  ? "#8a28a7"
                  : "#28a745"
              }; margin-left: 16px;">
  ${interaction.user.userType || "N/A"}
</div>
                  ${
                    interaction.user.userType === "leadBroker" &&
                    interaction?.user?.leadBrokerData?.brokerageCompany
                      ? `<div style="font-size: 14px; line-height: 18px; margin-top: 12px; font-weight: 500; color: #000000; margin-left: 16px;">

                      ${interaction?.user?.leadBrokerData?.brokerageCompany}
                    </div>`
                      : ""
                  }
                </div>
              </div>
              <p style="color: black; border: 1px solid #e0e0e0; padding: 12px;">${
                interaction.type || "-"
              }</p>
              <div style="border: 1px solid #e0e0e0; padding: 15px; margin-top: 15px;">
                ${formatNotes(interaction.notes)}
              </div>
            </div>
          `
            )
            .join("")}
        </div>
      </div>
    `;

      const opt = {
        margin: 10,
        filename: `Daily_Report_${moment(reportData.date).format(
          "YYYY-MM-DD"
        )}.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        // html2canvas: { scale: 3 },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      };

      html2pdf().from(reportHtml).set(opt).save();
      toast.success("Daily report downloaded successfully");
    } catch (error) {
      console.error("Error downloading daily report:", error);
    } finally {
      setIsDownloading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (reportId) {
          await fetchReportById(reportId);
        } else {
          await fetchReportByDate(selectedDate);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("An error occurred while fetching data");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [projectId, reportId, selectedDate]);

  const fetchReportById = async (id) => {
    const data = await getDailyReportById(id, projectId);
    updateStateWithReportData(data[0]);
  };

  const updateStateWithReportData = (data) => {
    setDailyReport(data);
    setDailyVisitors(mapDailyVisitors(data.dailyVisitors));
    setInventorySummary(mapInventorySummary(data.dailyInventorySummary));
    setSalesUpdate(mapSalesUpdate(data));
    setLeads(mapLeads(data.interactions));
  };

  const fetchReportByDate = async (date) => {
    const isToday = date === moment().startOf("day").format("YYYY-MM-DD");
    const startDate = moment(date).startOf("day").toISOString();
    const endDate = moment(date).endOf("day").toISOString();

    const existingReport = await getDailyReportById(
      "",
      projectId,
      startDate,
      endDate
    );

    if (existingReport.length > 0) {
      toast.success("Report found for this date");
      updateStateWithReportData(existingReport[0]);
    } else {
      const todayReport = await getTodayReport(projectId, startDate, endDate);
      updateStateWithTodayReport(todayReport);
      setDailyReport(null);
      setSalesUpdate({
        rescissions: "",
        summary_of_sales_transactions: "",
        deposits_dropped_off: "",
      });
      await getTodayLeads();
      await getInventorySummary();
    }
  };

  const updateStateWithTodayReport = (data) => {
    const mappedLeads = mapTodayReportLeads(data.userActivities);
    setLeads(mappedLeads);
  };

  const handleCheckboxChange = (index) => {
    setLeads((prevLeads) => {
      const newLeads = prevLeads.map((lead, i) =>
        i === index ? { ...lead, isChecked: !lead.isChecked } : lead
      );
      return sortLeads(newLeads);
    });
  };
  const sortLeads = (leadsToSort) => {
    return [...leadsToSort].sort((a, b) => {
      if (a.isChecked === b.isChecked) {
        return a.originalIndex - b.originalIndex;
      }
      return a.isChecked ? -1 : 1;
    });
  };

  const sortedLeads = sortLeads(leads);

  const handleNoteChange = (index, value) => {
    const newContacts = [...leads];
    newContacts[index].activity = value;
    setLeads(newContacts);
  };

  const handleSalesUpdateChange = (field, value) => {
    setSalesUpdate((prev) => ({ ...prev, [field]: value }));
  };

  const handleSave = async () => {
    const reportData = {
      date: dailyReport?.date || selectedDate,
      project: projectId,
      interactions: leads.map(
        ({ user, userId, type, activity, isChecked }) => ({
          user: userId,
          type,
          notes: activity,
          isChecked,
        })
      ),
      dailyVisitors,
      dailyInventorySummary: inventorySummary,
      ...salesUpdate,
      id: dailyReport?._id || null,
      createdAt: moment(selectedDate).toISOString(),
      updatedAt: new Date(),
    };
    try {
      await saveDailyReport(reportData).then((data) => {
        toast.success("Daily report saved successfully");
        setDailyReport(data);
      });
    } catch (error) {
      console.error("Error saving daily report:", error);
    }
  };

  return (
    <div className="max-w-4xl mx-auto py-12 flex flex-col">
      <h1 className="text-2xl text-center mb-4 font-bold">
        Presentation Center Report
      </h1>
      <div className="flex flex-col">
        <div className="flex justify-end items-center mb-4">
          <div
            onClick={() => {
              router.push(ADMIN_MASTER_REPORT);
              setSalesUpdate({});
              setDailyVisitors({});
              setInventorySummary({});
            }}
            className="cursor-pointer absolute top-6 left-4"
          >
            <IoIosArrowBack className="w-6 h-6 mr-2" />
          </div>

          <div className="flex gap-4 items-center">
            {!reportId && (
              <DatePicker
                placeholder="select date"
                value={moment(selectedDate).startOf("day").toDate()}
                oneTap
                onChange={(e) => {
                  if (moment(e).startOf("day") > moment().startOf("day")) {
                    toast.error("You cannot select a future date");
                  } else {
                    setIsLoading(true);
                    setSelectedDate(moment(e).format("YYYY-MM-DD"));
                  }
                }}
                cleanable={false}
              />
            )}
            <Button disabled className="">
              Email
            </Button>
            <Button
              onClick={() => {
                const reportData = {
                  date: dailyReport?.date || new Date(),
                  project: projectId,
                  interactions: leads.map(
                    ({ user, userId, type, activity, isChecked }) => ({
                      user: userId,
                      type,
                      notes: activity,
                      isChecked,
                    })
                  ),
                  dailyVisitors,
                  dailyInventorySummary: inventorySummary,
                  ...salesUpdate,
                  id: dailyReport?._id || null,
                };
                saveDailyReport(reportData).then((data) => {
                  handleDownloadDailyReport(data._id);
                });
              }}
            >
              {isDownloading ? "Downloading..." : "Export"}
            </Button>
          </div>
        </div>
        {isLoading && (
          <div className="flex flex-col absolute inset-0 bg-[rgb(255,255,255,0.5)] items-center justify-center w-full">
            <LottieWrapper
              animation={loadingAnimation}
              loop
              className="h-20 w-38"
            />
            <div className="font-bold text-center">Loading</div>
          </div>
        )}
        <div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            {/* <EditableTable
              data={dailyVisitors}
              onChange={handleDailyVisitorsChange}
              title="Daily Visitors"
            />
            <EditableTable
              data={inventorySummary}
              onChange={handleInventorySummaryChange}
              title="Daily Inventory Summary"
            /> */}
          </div>
          <Card className="mb-4">
            <CardHeader>
              <CardTitle>Sales Update</CardTitle>
            </CardHeader>
            <CardContent>
              <h3 className="font-semibold mb-2">Offers</h3>
              <Textarea
                className="mb-4"
                placeholder="Rescissions"
                value={salesUpdate.rescissions}
                onChange={(e) =>
                  handleSalesUpdateChange("rescissions", e.target.value)
                }
              />
              <Textarea
                className="mb-4"
                placeholder="Summary of Sales Transactions"
                value={salesUpdate.summary_of_sales_transactions}
                onChange={(e) =>
                  handleSalesUpdateChange(
                    "summary_of_sales_transactions",
                    e.target.value
                  )
                }
              />
              <Textarea
                placeholder="Deposits Dropped off"
                value={salesUpdate.deposits_dropped_off}
                onChange={(e) =>
                  handleSalesUpdateChange(
                    "deposits_dropped_off",
                    e.target.value
                  )
                }
              />
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>Contact Interactions</CardTitle>
            </CardHeader>
            <CardContent>
              {sortedLeads?.map((contact, index) => {
                let user = contact.user;
                return (
                  <Card
                    key={index}
                    className={`mb-4 py-4 relative ${
                      !contact.isChecked ? "opacity-40" : ""
                    }`}
                  >
                    <div className="absolute top-4 right-2">
                      <Checkbox
                        checked={contact.isChecked}
                        handleChange={() => handleCheckboxChange(index)}
                      />
                    </div>
                    <CardHeader>
                      <CardTitle>
                        <div className="flex gap-4 items-center">
                          <div className="font-semibold">
                            {user?.firstName + " " + user?.lastName}
                          </div>
                          <Badge color="green">{user?.userType}</Badge>
                          {user?.userType === "LeadBroker" &&
                            user?.leadBrokerData?.brokerageCompany && (
                              <Badge color="blue">
                                {user?.leadBrokerData?.brokerageCompany || ""}
                              </Badge>
                            )}
                        </div>
                      </CardTitle>
                      <Select
                        disabled={!contact.isChecked}
                        value={contact.type}
                        onChange={(value) =>
                          handleContactChange(index, "type", value)
                        }
                        options={interactionTypes}
                      />
                    </CardHeader>
                    <CardContent className="flex flex-col gap-6">
                      {contact.activity && (
                        <Textarea
                          disabled={!contact.isChecked}
                          value={contact.activity}
                          onChange={(e) =>
                            handleNoteChange(index, e.target.value)
                          }
                          rows={10}
                        />
                      )}
                    </CardContent>
                  </Card>
                );
              })}
            </CardContent>
          </Card>
          <div className="mt-4 flex justify-end">
            <Button disabled={isLoading} onClick={handleSave}>
              Save Report
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

const AutoResizeTextarea = React.memo(
  ({ value, onChange, disabled, placeholder, className }) => {
    const textareaRef = useRef(null);

    useEffect(() => {
      if (textareaRef.current) {
        textareaRef.current.style.height = "auto";
        textareaRef.current.style.height =
          textareaRef.current.scrollHeight + "px";
      }
    }, [value]);

    return (
      <textarea
        ref={textareaRef}
        value={value}
        onChange={onChange}
        disabled={disabled}
        placeholder={placeholder}
        className={`w-full resize-none overflow-hidden ${className}`}
        rows={1}
      />
    );
  }
);

const mapDailyVisitors = (visitors) => ({
  totalTraffic: Number(visitors?.totalTraffic) || 0,
  newVisits: Number(visitors?.newVisits) || 0,
  realtorVisits: Number(visitors?.realtorVisits) || 0,
  realtorWithClient: Number(visitors?.realtorWithClient) || 0,
  industry: Number(visitors?.industry) || 0,
  pastPurchasers: Number(visitors?.pastPurchasers) || 0,
  newRegistrants: Number(visitors?.newRegistrants) || 0,
});

const mapInventorySummary = (summary) => ({
  available: Number(summary?.available) || 0,
  reserved: Number(summary?.reserved) || 0,
  offers: Number(summary?.offers) || 0,
  rescission: Number(summary?.rescission) || 0,
  soldFirm: Number(summary?.soldFirm) || 0,
  holdbacks: Number(summary?.holdbacks) || 0,
  totalInventory: Number(summary?.totalInventory) || 0,
});

const mapSalesUpdate = (data) => ({
  rescissions: data?.rescissions || "",
  summary_of_sales_transactions: data?.summary_of_sales_transactions || "",
  deposits_dropped_off: data?.deposits_dropped_off || "",
});

const mapLeads = (interactions) =>
  (interactions || []).map((interaction, index) => ({
    ...interaction,
    isChecked: interaction?.isChecked ?? true,
    originalIndex: index,
    userId: interaction?.user?._id,
    activity: interaction?.notes,
  }));

const mapTodayReportLeads = (userActivities) =>
  userActivities.map((contact, index) => {
    let activities = contact.activities;
    let allActivities = [
      ...activities?.notes,
      ...activities?.calls,
      ...activities?.tasks,
    ];
    allActivities.sort(
      (a, b) => new Date(a?.createdAt) - new Date(b?.createdAt)
    );
    let activityContent = allActivities
      .map((activity) => {
        let content = [];
        if (activity?.notes && activity?.noteType) {
          let parsed = JSON.parse(activity?.notes)?.notes;
          content.push(
            `Notes: ${parsed} | ${moment(activity?.createdAt).format(
              "hh:mm A"
            )}`
          );
        } else if (activity.host) {
          content.push(
            `Called at ${moment(activity?.createdAt).format("hh:mm A")}`
          );
          if (activity?.callStatus) {
            content.push(`- Status: ${activity?.callStatus}`);
          }
          if (typeof activity?.notes === "string") {
            content.push(`- Call Notes: ${activity?.notes}`);
          }
        } else if (activity?.taskType) {
          content.push(
            `Task: ${activity?.taskType} | ${moment(activity?.createdAt).format(
              "hh:mm A"
            )}`
          );
          if (activity?.description) {
            content.push(`- Notes: ${activity?.description}`);
          }
        }
        return content.join("\n");
      })
      .join("\n\n");
    return {
      user: contact?.user,
      userId: contact?.user?._id,
      type: contact?.type,
      activity: activityContent,
      isChecked: contact?.isChecked ?? true,
      originalIndex: index,
    };
  });

const sortLeads = (leadsToSort) => {
  return [...leadsToSort].sort((a, b) => {
    if (a.isChecked === b.isChecked) {
      return a.originalIndex - b.originalIndex;
    }
    return a.isChecked ? -1 : 1;
  });
};

const formatNotes = (notes) => {
  return notes
    .split("\n")
    .map((line) => {
      if (
        line.startsWith("Task:") ||
        line.startsWith("Notes:") ||
        line.startsWith("Called at")
      ) {
        return `<p style="margin-bottom: 5px;">${line}</p>`;
      } else if (
        line.startsWith("- Notes:") ||
        line.startsWith("- Status:") ||
        line.startsWith("- Call Notes:")
      ) {
        return `<p style="margin-left: 15px; margin-bottom: 5px;">${line}</p>`;
      } else {
        return `<p style="margin-bottom: 24px;">${line}</p>`;
      }
    })
    .join("");
};
