import {
  CHANGE_USER_LOGGED_STATUS,
  CHANGE_USER_OBJECT
} from 'store/actions/actionTypes'

export const initialState = {
  isUserLoggedIn: false,
  userObject: {}
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_USER_LOGGED_STATUS:
      return {
        ...state,
        isUserLoggedIn: action.payload
      }

    case CHANGE_USER_OBJECT:
      return {
        ...state,
        userObject: action.payload
      }

    default: return state
  }
}

export default authReducer
