/* eslint-disable */
// @ts-nocheck
import React, { useCallback, useEffect, useState } from 'react'
import { getStorages } from 'store/actions/storageActions'
import { getStoragePolygons } from 'store/actions/storagePolygonActions'
import CustomMap from './CustomMap'
import { getAllMapInventoryByType } from 'store/actions/mapInventoryActions'
import useInventory from '../hooks/useInventory'

const StorageInventoryMapView = ({ projectId, building }) => {
  const { selectedStorageLevel } = useInventory()
  const [gettingData, setGettingData] = useState(true)
  const [storagesData, setStoragesData] = useState([])
  const [storagePolygonsData, setStoragePolygonsData] = useState([])
  const [polygons, setPolygons] = useState([])
  const [storagesStatusCount, setStoragesStatusCount] = useState([])
  const [mapInfos, setMapInfos] = useState([])
  const [selectedMapInfo, setSelectedMapInfo] = useState({})

  const _getProjectStorages = useCallback(() => {
    setGettingData(true)
    getStorages()
      .then((storages) => {
        //sorting an array based on status
        let tempArr = {
          available: 0,
          assigned: 0,
          reserved: 0,
          allocated: 0,
          rescission: 0,
          sold: 0
        }
        storages.forEach((elm) => {
          tempArr[elm.status?.toLowerCase()] += 1
        })
        setStoragesStatusCount(Object.entries(tempArr).map(([status, count]) => ({ status, count, percentage: `${(count / storages.length) * 100}%` })).sort((a, b) => (a.status > b.status) ? 1 : -1))

        setStoragesData(storages)
        setGettingData(false)
      })
      .catch(() => {
        setGettingData(false)
      })
  }, [])

  useEffect(() => {
    _getProjectStorages()
  }, [_getProjectStorages])

  const _getStoragePolygons = useCallback(() => {
    setGettingData(true)
    getStoragePolygons()
      .then((storagePolygons) => {
        setStoragePolygonsData(storagePolygons)
        setGettingData(false)
      })
      .catch(() => {
        setGettingData(false)
      })
  }, [])

  useEffect(() => {
    _getStoragePolygons()
  }, [_getStoragePolygons])

  useEffect(() => {
    if (storagePolygonsData?.length > 0 && storagesData?.length > 0) {
      setPolygons([
        ...storagePolygonsData.map((data) => {
          return {
            coordinates: data.polygon,
            storageLevel: storagesData.filter((storage) => storage._id === data.storage)[0]?.storageLevel ?? selectedStorageLevel?.storageLevel,
            inventoryId: data.storage,
            polygonId: data._id
          }
        }).filter((elm) => elm?.storageLevel === selectedStorageLevel?.storageLevel)
      ])
    }
  }, [storagePolygonsData, storagesData, selectedStorageLevel])

  const _getAllMapInventoryByType = useCallback(() => {
    setGettingData(true)
    getAllMapInventoryByType(projectId, building, 'Storage')
      .then((res) => {
        setMapInfos(res)
        setGettingData(false)
      })
      .catch((error) => {
        console.log('error: ', error)
        setGettingData(false)
      })
  }, [])

  useEffect(() => {
    _getAllMapInventoryByType()
  }, [projectId, building])

  useEffect(() => {
    setGettingData(true)
    if (selectedStorageLevel?.storageLevel && mapInfos?.length > 0) {
      setSelectedMapInfo({
        ...mapInfos.filter((elm) => elm.level === selectedStorageLevel?.storageLevel)[0]
      })
    }
    setGettingData(false)
  }, [mapInfos, selectedStorageLevel])

  return (
    <CustomMap
      mapInfo={selectedMapInfo}
      selectedLevel={selectedStorageLevel?.storageLevel}
      inventoryData={storagesData}
      polygonsData={storagePolygonsData}
      setPolygonsData={setStoragePolygonsData}
      polygons={polygons}
      isLoading={gettingData}
      setIsLoading={setGettingData}
      statusCount={storagesStatusCount}
      refetchInventoryData={_getProjectStorages}
      refetchPolygonsData={_getStoragePolygons}
    />
  );
}

export default StorageInventoryMapView
