import React from 'react'
import propTypes from 'prop-types'
import {
  HeaderLogo, SurveyStepWrapper, SurveyTitle,
  SurveySubTitle, SurveyFooter
} from './surveyComponents'
import Slider from '@material-ui/core/Slider'
import { withStyles } from '@material-ui/core/styles'
import { formatMoney } from 'utils'

const StyledSlider = withStyles({
  root: {
    color: '#3a8589',
    height: 3,
    padding: '13px 0'
  },
  thumb: {
    height: 40,
    width: 40,
    backgroundColor: '#fff',
    border: '1px solid currentColor',
    marginTop: -16,
    marginLeft: -13,
    boxShadow: '#ebebeb 0 2px 2px',
    '&:focus, &:hover, &$active': {
      boxShadow: '#ccc 0 2px 3px 1px'
    },
    '& .bar': {
      // display: inline-block !important;
      height: 9,
      width: 1,
      backgroundColor: 'currentColor',
      marginLeft: 1,
      marginRight: 1
    }
  },
  active: {},
  track: {
    height: 8
  },
  rail: {
    color: '#d8d8d8',
    opacity: 1,
    height: 6
  }
})(Slider)

function AirbnbThumbComponent (props) {
  return (
    <span {...props}>
      <span className="bar" />
      <span className="bar" />
      <span className="bar" />
    </span>
  )
}

const PriceRange = ({ onClickContinue, priceRange, onChangePriceRange }) => {
  const handleChange = (event, newValue) => {
    onChangePriceRange(newValue)
  }

  return (
    <SurveyStepWrapper>
      <HeaderLogo className='rounded-full w-20 h-20'/>
      <SurveyTitle className='my-7'>Let´s talk about budget!</SurveyTitle>
      <SurveySubTitle>Help us find the perfect residence in your price range?</SurveySubTitle>

      <div className='text-4xl text-gray-600 font-bold my-12 border-b-4 border-gray-600'>
        {formatMoney(priceRange[0])}
        <span className='mx-4'>-</span>
        {formatMoney(priceRange[1])}
      </div>

      <StyledSlider
        ThumbComponent={AirbnbThumbComponent}
        getAriaLabel={(index) => (index === 0 ? 'Minimum price' : 'Maximum price')}
        defaultValue={[20, 40]}
        value={priceRange}
        onChange={handleChange}
        min={0}
        max={6000000}
        step={5000}
      />

      <SurveyFooter onClickContinue={onClickContinue} />
    </SurveyStepWrapper>
  )
}

export default PriceRange

PriceRange.propTypes = {
  onClickContinue: propTypes.func,
  priceRange: propTypes.array,
  onChangePriceRange: propTypes.func
}
