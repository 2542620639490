import React from 'react'
import propTypes from 'prop-types'
import styled from 'styled-components'
import { ReactComponent as CardView } from '../../assets/icons/card_view.svg'
import { ReactComponent as ListView } from '../../assets/icons/list_view.svg'

const ViewSwitcherBorder = styled.div`
  width: fit-content;
  height: fit-content;
  border: 0.063rem solid #2e2b2e;
  border-radius: 0.125rem;
`

const ViewSwitcherWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f1;
  width: 4.25rem;
  height: 2.125rem;
  text-align: center;
  overflow: hidden;
  border-radius: 0.063rem;
`

const ListTab = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  background-color: ${(props) => props.listColor};
  margin: 0rem;
  padding: 0rem;
`

const CardTab = styled(ListTab)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  background-color: ${(props) => props.cardColor};
  margin: 0rem;
  padding: 0rem;
`

function ViewSwitcher ({ view = 'cardView', onChange = null }) {
  let cardColor = '#2E2B2E'
  let listColor = '#F2F2F1'

  if (view === 'listView') {
    cardColor = '#F2F2F1'
    listColor = '#2E2B2E'
  }

  return (
    <ViewSwitcherBorder>
      <ViewSwitcherWrapper>
        <CardTab
          className='hover:cursor-pointer'
          cardColor={cardColor}
          onClick={() => {
            onChange?.('cardView')
          }}
        >
          <CardView style={{ color: listColor }} />
        </CardTab>
        <ListTab
          className='hover:cursor-pointer'
          listColor={listColor}
          onClick={() => {
            onChange?.('listView')
          }}
        >
          <ListView style={{ color: cardColor }} />
        </ListTab>
      </ViewSwitcherWrapper>
    </ViewSwitcherBorder>
  )
}

ViewSwitcher.propTypes = {
  view: propTypes.string
}

export default ViewSwitcher
