import React, {
  useState, useEffect,
  useCallback
} from 'react'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
import { Checkbox, InlineInput, Tabs } from 'ui'
import { FadedAnimatedDiv, FormTabsWrapper, FormWrapper } from 'components'
import { createOrUpdateProjectOptionContractDetail, getProjectOptionContractDetail } from 'store/actions/projectActions'

import { validateRequiredInputs } from 'utils'
import { TCancelButton, TSaveButton } from 'components/DefaultButtons'
import { AnimatePresence } from 'framer-motion'
import { getContractTemplates } from 'store/actions/contractTemplateActions'

const TabHeader = ({ title, subtitle, extraContent }) => (
  <div className='space-y-1 flex flex-row justify-between items-center'>
    <div className='flex flex-col'>
      <h3 className='text-lg leading-6 font-medium text-gray-900'>
        {title && title}
      </h3>
      <p className='max-w-2xl text-sm text-gray-500'>
        {subtitle && subtitle}
      </p>
    </div>
    {extraContent}
  </div>
)

const TabContent = ({ children }) => (
  <div className='mt-6'>
    <dl className='divide-y divide-gray-200'>
      {children}
    </dl>
  </div>
)

const ProjectOptionContractData = (props) => {
  const {
    _id,
    userObject: {
      userType: loggedUserType
    },
    projectId,
    optionId,
    executeOnBack,
    executeAfterSave
  } = props

  const [contractTemplatesData, setContractTemplatesData] = useState([])
  const [gettingContractTemplates, setGettingContractTemplates] = useState(false)
  const [contractTemplate, setContractTemplate] = useState('')
  const [templateFieldsData, setTemplateFieldsData] = useState([])
  const [originalTemplateFields, setOriginalTemplateFields] = useState([])
  const [templateField, setTemplateField] = useState('')
  const [gettingTemplateFields, setGettingTemplateFields] = useState(false)
  const [allFields, setAllFields] = useState(true)
  const [name, setName] = useState('')
  const [type, setType] = useState('')
  const [value, setValue] = useState('')
  const [gettingData, setGettingData] = useState(false)

  const [selectedTab, setSelectedTab] = useState('general')

  const [saving, setSaving] = useState(false)

  useEffect(() => {
    // Get contractTemplates from API to fill selects components for the user
    setGettingContractTemplates(true)
    getContractTemplates(_id)
      .then((contractTemplates) => {
        const tmpContractTemplates =
        contractTemplates.map((contractTemplate) =>
          ({ id: contractTemplate._id, value: contractTemplate.name })
        )

        setContractTemplatesData(tmpContractTemplates)
        setGettingContractTemplates(false)
      })
      .catch(() => {
        setGettingContractTemplates(false)
      })
  }, [])

  const getProjectOptionContractData = useCallback(() => {
    if (_id && projectId && optionId) {
      setGettingData(true)

      // Get option contract data from the _id
      getProjectOptionContractDetail({ projectId, optionId, contractId: _id })
        .then((optionContract) => {
          const { contractTemplate, field } = optionContract
          setContractTemplate(contractTemplate)

          if (field && Object.keys(field).length) {
            const { name, type, value, contractField } = field

            setName(name)
            setType(type)
            setValue(value)
            setTemplateField(contractField)
            setAllFields(false)
          }

          setGettingData(false)
        })
        .catch(() => {
          setGettingData(false)
        })
    }
  }, [_id, optionId, projectId])

  useEffect(() => {
    getProjectOptionContractData()
  }, [getProjectOptionContractData])

  useEffect(() => {
    // When changed the Selected Template, load the template's fields
    if (contractTemplate) {
      setGettingTemplateFields(true)
      getContractTemplates(contractTemplate)
        .then((template) => {
          const { contractFields } = template

          setOriginalTemplateFields(contractFields)
          setGettingTemplateFields(false)

          const tmpTemplateFields =
          contractFields.map((field) => ({ id: field._id, value: field.name }))

          setTemplateFieldsData(tmpTemplateFields)
        })
        .catch(() => setGettingTemplateFields(false))
    }
  }, [contractTemplate])

  // When changed the field, set the name, type and value
  const updateFieldValues = useCallback((fieldId) => {
    const selectedFieldData = originalTemplateFields.find((field) => field._id === fieldId)
    if (selectedFieldData) {
      const { name, type, value } = selectedFieldData

      setTemplateField(fieldId)
      setName(name)
      setType(type)
      setValue(value)
    }
  }, [originalTemplateFields])

  const _onSaveClick = useCallback(() => new Promise((resolve, reject) => {
    validateRequiredInputs('createOptionTemplate')
      .then(() => {
        setSaving(true)
        let params = {
          _id,
          projectId,
          optionId,
          contractTemplate: contractTemplate
        }

        if (!allFields) {
          params = Object.assign({}, params, { field: { name, type, value } })
        }

        createOrUpdateProjectOptionContractDetail(params)
          .then(() => {
            setSaving(false)

            if (executeAfterSave) {
              executeAfterSave()
            }
            resolve()
          })
          .catch(() => {
            setSaving(false)
            reject()
          })
      })
      .catch(() => reject())
  }), [
    allFields, name, optionId, projectId,
    contractTemplate, type, value, _id, executeAfterSave
  ])

  const filterFields = useCallback((textToFilter) => {
    const filteredFields = originalTemplateFields
      .filter(field =>
        field.name.toLowerCase().includes(textToFilter.toLowerCase()))
      .map((field) => ({ id: field._id, value: field.name }))

    setTemplateFieldsData(filteredFields)
  }, [originalTemplateFields])

  const renderGeneralTab = useCallback(() => (
    <div className={`${selectedTab !== 'general' && 'sr-only'}`}>
      <TabHeader
        title='General'
        subtitle='Here are all infos related to the contract template'
      />
      <TabContent>
        <InlineInput
          id='template'
          name='template'
          type='text'
          label='Template'
          value={contractTemplate}
          onChange={(e) => {
            setName('')
            setType('')
            setValue('')

            setContractTemplate(e)
          }}
          placeholder={'the option\'s template'}
          required
          typeInput='select'
          validateKey='createOptionTemplate'
          data={contractTemplatesData}
          loading={gettingContractTemplates || gettingData}
          onClickUpdate={_id ? _onSaveClick : null}
        />
        <Checkbox
          checked={allFields}
          onClick={(e) => setAllFields(e.target.checked)}
          style={{ marginTop: 7 }}
        >
          Use all template fields
        </Checkbox>
        <AnimatePresence initial={false}>
          {
            !allFields && (
              <FadedAnimatedDiv
                key='optionTemplate'
                transition={{ duration: 0.4 }}
                className='mt-6'
              >
                <InlineInput
                  id='templateFields'
                  name='templateFields'
                  type='text'
                  label='Template Fields'
                  value={templateField}
                  onChange={(e) => updateFieldValues(e)}
                  placeholder={'the template\'s field'}
                  required
                  typeInput='select'
                  validateKey='createOptionTemplate'
                  data={templateFieldsData}
                  loading={gettingTemplateFields}
                  showSearch
                  onSearch={filterFields}
                  filterOption={false}
                  onClickUpdate={_id ? _onSaveClick : null}
                />
                <InlineInput
                  id='type'
                  name='type'
                  type='text'
                  label='Type'
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                  placeholder={'the field\'s type'}
                  required
                  validateKey='createOptionTemplate'
                  loading={gettingTemplateFields}
                  onClickUpdate={_id ? _onSaveClick : null}
                  disabled
                />
                <InlineInput
                  id='value'
                  name='value'
                  type='text'
                  label='Value'
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  placeholder={'the field\'s value'}
                  required
                  validateKey='createOptionTemplate'
                  loading={gettingTemplateFields}
                  onClickUpdate={_id ? _onSaveClick : null}
                />
              </FadedAnimatedDiv>
            )
          }
        </AnimatePresence>
      </TabContent>
    </div>
  ), [
    _id, _onSaveClick, allFields, contractTemplatesData, filterFields,
    gettingContractTemplates, gettingData, gettingTemplateFields,
    selectedTab, contractTemplate, templateField, templateFieldsData,
    type, value, updateFieldValues
  ])

  const renderIdTab = useCallback(() => (
    <div className={`${selectedTab !== 'id' && 'sr-only'}`}>
      <TabHeader
        title='ID'
        subtitle='This tab is only available to Super Admins'
      />
      <TabContent>
        <InlineInput
          key='optionContractId'
          label='Option Contract ID'
          value={_id}
          onChange={(e) => {}}
          placeholder='Option Contract ID'
          loading={gettingData}
          disabled
        />
      </TabContent>
    </div>
  ), [gettingData, _id, selectedTab])

  const getMenus = useCallback(() => {
    const menus = [
      { key: 'general', title: 'General' }
      // { key: 'images', title: 'Images' },
      // { key: 'contracts', title: 'Contracts' }
    ]

    if (loggedUserType === 'SuperAdmin' && Boolean(_id)) {
      menus.push(
        { key: 'id', title: 'ID' }
      )
    }

    return menus
  }, [loggedUserType, _id])

  return (
    <FormWrapper>
      <FormTabsWrapper>
        <Tabs
          menus={getMenus()}
          tabSelected={selectedTab}
          onClick={(tab) => setSelectedTab(tab)}
        />
        <div>
          {
            !_id && (
              <TSaveButton
                loading={saving}
                className='ml-3'
                onClick={_onSaveClick}
              >
                Save contract
              </TSaveButton>
            )
          }
          <TCancelButton
            loading={saving}
            className='ml-3'
            onClick={executeOnBack}
          >
            Back
          </TCancelButton>
        </div>
      </FormTabsWrapper>

      <div className='mt-7'>
        {renderGeneralTab()}
        {renderIdTab()}
     </div>
    </FormWrapper>
  )
}

const mapStateToProps = (state) => ({
  userObject: state.authReducer.userObject
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectOptionContractData)

ProjectOptionContractData.propTypes = {
  _id: propTypes.string,
  userObject: propTypes.shape({
    userType: propTypes.string
  }),
  projectId: propTypes.string,
  optionId: propTypes.string,
  executeOnBack: propTypes.func,
  executeAfterSave: propTypes.func
}

TabHeader.propTypes = {
  title: propTypes.string,
  subtitle: propTypes.string,
  extraContent: propTypes.node
}

TabContent.propTypes = {
  children: propTypes.node
}
