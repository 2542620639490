/* eslint multiline-ternary: ["error", "never"] */
import React from 'react'
import AdditionalStats from './components/AdditionalStats/AdditionalStats'
import Header from './components/Header'
import HorizontalStats from './components/HorizontalStats/HorizontalStats'
import { isMobile } from 'react-device-detect'
import DashboardProvider from './context/DashboardContext'
import {
  ContactProvider
} from 'pages/contacts/context/Contacts'
import LeadsData from './components/LeadsData'
import ActionButtons from './components/ActionButtons'

const Dashboard = () => {
  return (
    <div className='relative p-4 space-y-2'>
      <Header />
      {isMobile ? (
        <>
          <AdditionalStats />
          <ActionButtons />
          <LeadsData />
          <HorizontalStats />
        </>
      ) : (
        <>
          <HorizontalStats />
          <ActionButtons />
          <LeadsData />
          <AdditionalStats />
        </>
      )}
    </div>
  )
}

const DashboardContainer = () => {
  return (
    <DashboardProvider>
      <ContactProvider>
        <Dashboard />
      </ContactProvider>
    </DashboardProvider>
  )
}
export default DashboardContainer
