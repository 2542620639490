import React, { useEffect, useState, useCallback } from 'react'
// import propTypes from 'prop-types'
import { TFilledChevronUpDownIcon, TCheckIcon } from 'components'
import { Transition } from '@headlessui/react'
import Styles from './inputLabel.module.scss'
import { isString } from 'utils'

const Select = (props) => {
  const [showSelect, setShowSelect] = useState(false)
  const [selectData, setSelectData] = useState([])
  const [title, setTitle] = useState('')

  const clickOutsideComponent = (e) => {
    // If clicked outside of input, close the select
    if (
      e.target.id !== 'inputLabelSelectButton' &&
      e.target.id !== 'inputLabelSelectText' &&
      e.target.id !== 'placeHolder'
    ) {
      setShowSelect(false)
    }
  }

  document.onclick = (e) => clickOutsideComponent(e)

  const {
    value, onChange, data, className,
    disabled, mode,
    placeholder
  } = props

  useEffect(() => {
    if (data.length) {
      const tmpData = data.map(element => {
        const tmpElement = { ...element }

        if (mode === 'multiple') {
          const selected = value.filter(value => value === element.id)
          tmpElement.selected = Boolean(selected.length)
        } else {
          const selected = element.id === value
          tmpElement.selected = selected
        }

        return tmpElement
      })

      if (tmpData.length) {
        const selectedItems = tmpData.filter(element => element.selected)
        const tmpTitle = selectedItems
          .reduce((ac, element, index) =>
          `${ac} ${element.value}${index < selectedItems.length - 1 ? ',' : ''}`
          , '')

        setTitle(tmpTitle)
      }

      setSelectData(tmpData)
    }
  }, [data, value, mode])

  const _onChange = useCallback((element) => {
    if (mode === 'multiple') {
      // Get the already selected items
      let result = selectData.filter(element => element.selected).map(element => element.id)

      // Check if the selected element is already selected
      const alreadySelected = result.filter(selectedElement => selectedElement === element)

      if (alreadySelected.length) {
        // Remove the selected item
        result = result.filter(selectedElement => selectedElement !== element)
      } else {
        // Add the selected item
        result.push(element)
      }

      onChange(result)
    } else {
      onChange(element)
    }
  }, [mode, onChange, selectData])

  const renderItems = useCallback(() => {
    return (
      selectData.map((element, index) => {
        return (
          <li
            onClick={(e) => {
              if (mode === 'multiple') {
                // Do not close the list automatically if is a multiple selector
                e.stopPropagation()
              }

              _onChange(element.id)
            }}
            key={index}
            id='listbox-option-0'
            role='option'
            className='hover:cursor-pointer group flex items-center flex-row px-4 py-2 text-sm
              text-gray-700 hover:bg-gray-100
              hover:text-gray-900
             cursor-default overflow-hidden select-none relative pl-3 pr-9'
          >
            {element.extraData && element.extraData}
            <span className={`${element.selected ? 'font-semibold' : 'font-normal'} block truncate`}>
              {element.value}
            </span>

            {element.selected && (
              <span className='absolute inset-y-0 right-0 flex items-center pr-4'>
                <TCheckIcon className='h-5 w-5 text-green-600' />
              </span>
            )}
          </li>
        )
      })
    )
  }, [_onChange, mode, selectData])

  const renderSelectItems = useCallback(() => {
    return (
      <Transition
        show={showSelect}
        enter=''
        enterFrom=''
        enterTo=''
        leave='transition ease-in duration-100'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'
      >
        <div className='absolute z-10 mt-1 w-full rounded-md'>
          <ul
            tabIndex='-1'
            role='listbox'
            aria-labelledby='listbox-label'
            aria-activedescendant='listbox-item-3'
            className='max-h-60 rounded-md bg-white shadow-lg py-1 text-base overflow-auto focus:outline-none sm:text-sm'
          >
            {renderItems()}
          </ul>
        </div>
      </Transition>
    )
  }, [renderItems, showSelect])

  return (
    <div className={className}>
      <div className='relative' onClick={() => !disabled && setShowSelect(!showSelect)}>
        <button
          id='inputLabelSelectButton'
          disabled={disabled}
          type='button'
          aria-haspopup='listbox'
          aria-expanded='true'
          // validateExtraMessage={validateExtraMessage}
          aria-labelledby='listbox-label'
          className={`${Styles.inputStyle} focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500`}
        >
          {((mode === 'multiple' || isString(value)) && !value.length) && placeholder && <span id='placeHolder' className='text-gray-500 text-base'>{placeholder}</span>}
          <span className='block truncate' id='inputLabelSelectText'>
            {title}
          </span>
          <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
            <TFilledChevronUpDownIcon className='h-5 w-5 text-gray-400' />
          </span>

        </button>

        {renderSelectItems()}
      </div>
    </div>
  )
}

export default Select

// Select.propTypes = {
//   value: propTypes.string,
//   onChange: propTypes.func,
//   data: propTypes.array,
//   className: propTypes.string,
//   disabled: propTypes.bool,
//   mode: propTypes.string,
//   validateExtraMessage: propTypes.string,
//   placeholder: propTypes.string
// }
