import React from 'react'

import { COLORS } from '../constants/colors'

import Button from 'components/Buttons'
import ProgressBar from 'components/ProgressBar'

const Header = ({ setShowCancelModal, progressPercentage }) => {
  const handleClick = () => {
    setShowCancelModal(true)
  }

  return (
    <>
      <div
        style={{ minHeight: 223 }}
        className='w-full bg-softBlack flex items-center text-white px-20 justify-between'
      >
        <div className='flex flex-col'>
          <p className='text-white text-4xl font-bold font-openSans mb-4'>
            Hudson 8
          </p>
          <p className='text-white font-normal text-2xl font-openSans'>
            Create Offer
          </p>
        </div>
        <Button
          buttonClasses='justify-self-end border border-solid border-yellow110 font-openSans'
          label='Cancel'
          type='Secondary'
          textColor={COLORS.volt}
          buttonBorder={`1px solid ${COLORS.volt}`}
          fontWeight='300'
          handleClick={handleClick}
        />
      </div>
      <ProgressBar
        currentPercent={progressPercentage}
        progressColor={COLORS.volt}
        backgroundColor={COLORS.darkGray}
        height={'8px'}
      />
    </>
  )
}

export default Header
