import React, { useState } from 'react'
import ActionButton from './AdditionalStats/components/ActionButton'
import { BsPersonPlusFill } from 'react-icons/bs'
import { BuildingIcon } from 'components'
import { isMobile } from 'react-device-detect'
import AddOrEditContact from 'components/AddOrEditContact'
import { useHistory } from 'react-router-dom'
import EmailModal from 'pages/contacts/EmailModal'

const ActionButtons = () => {
  const history = useHistory()
  const [showEmailBox, setShowEmailBox] = useState(false)
  const [modalUserType, setModalUserType] = useState(null)

  const actionButtonsData = [
    {
      text: 'Create New Buyer',
      icon: BsPersonPlusFill,
      onClick: () => setModalUserType('Buyer')
    },
    {
      text: 'Send Email',
      icon: BuildingIcon,
      onClick: () => {
        setShowEmailBox(true)
      }
    },
    {
      text: 'Create New Broker',
      icon: BsPersonPlusFill,
      onClick: () => setModalUserType('LeadBroker')
    },
    {
      text: 'Create New Opportunity',
      icon: BuildingIcon,
      onClick: () => history.push('/new/admin/opportunity')
    }
  ]

  return (
    <div>
      <div
        className={`${
          isMobile
            ? 'grid grid-cols-2 gap-2 mt-2'
            : 'flex flex-row mt-3 gap-3 items-stretch'
        }`}
      >
        {actionButtonsData.map(({ text, icon, onClick }, index) => (
          <ActionButton text={text} Icon={icon} onClick={onClick} />
        ))}
      </div>
      {showEmailBox && (
        <EmailModal
          show={showEmailBox}
          handleClose={() => setShowEmailBox(false)}
          contact={null}
        />
      )}
      {modalUserType?.length && (
        <AddOrEditContact
          show={modalUserType?.length}
          setShow={setModalUserType}
          contact={null}
          onSuccess={(_id) => {
            if (history) {
              history.push({
                pathname: '/admin/newcontacts',
                search: `?id=${_id}&userType=${modalUserType}`
              })
            }
          }}
          userType={modalUserType}
          invalideCacheOnUpdate
        />
      )}
    </div>
  )
}

export default ActionButtons
