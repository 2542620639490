import { Col } from 'antd'
import standardStyle from 'assets/css/standardStyle'
import styled from 'styled-components'

export const MethodsWrapper = styled(Col)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${standardStyle.mediaqueries.sm}, ${standardStyle.mediaqueries.xs} {
    display: inherit;
  }
`

export const SelectedMethodWrapper = styled.div`
  position: sticky;
  position: -webkit-sticky;
  background: white;
  top: -2px;
  z-index: 10;
  padding: 15px 5px;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100%;
`
