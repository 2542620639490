import React from 'react'
import {
  AdminPageWrapper,
  PageHeader
} from 'components'
import { connect, useSelector } from 'react-redux'
import ProjectParkingsInventory from 'components/ProjectParkingsInventory'
import { ADMIN_PURCHASE_PARKING } from 'routes'
import { useHistory } from 'react-router-dom'
import CustomButton from 'components/CustomButton'

const ParkingSalesAndWaitlist = () => {
  // @ts-ignore
  const projectId = useSelector((state) => state.appReducer.appProject)
  const history = useHistory()

  const RightContent = () => (
    <div className='flex flex-row items-center'>
      <CustomButton
        handleClick={() => history.push(ADMIN_PURCHASE_PARKING)}
        disabled={!projectId}
        className='m-auto'
      >
        <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
          Purchase / Join Waitlist
        </span>
      </CustomButton>
    </div>
  )

  return (
    <>
      <PageHeader title='Parking Sales & Waitlist' rightContent={<RightContent />} />
      <AdminPageWrapper fullscreen>
        <ProjectParkingsInventory />
      </AdminPageWrapper>
    </>
  )
}

const mapStateToProps = (state) => ({
  // appProject: state.appReducer.appProject
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ParkingSalesAndWaitlist)

ParkingSalesAndWaitlist.propTypes = {
  // appProject: propTypes.string
}
