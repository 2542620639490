import BuyerCard from 'components/BuyerCard'
import UserInfoCard from 'components/UserInfoCard'
import useGetProjectBrokers from 'hooks/useGetProjectBrokers'
import { ReactComponent as UserIcon } from 'assets/icons/user.svg'
import React, { useState } from 'react'
import NewBroker from 'pages/offer/components/NewBrokerOrSalesRep'
import CustomButton from 'components/CustomButton'

const Brokers = ({ setStep, method, onNextStep, receiver, setSteps, stepsInitialState }) => {
  const [showCreateModal, setShowCreateModal] = useState(false)
  const { brokers, refetch: getBrokers, setBrokers } = useGetProjectBrokers()
  const onBack = () => {
    if (receiver === 'Buyers') {
      setSteps(stepsInitialState)
    }
    setStep((prev) => prev - 1)
  }
  const onNext = () => {
    onNextStep(brokers)
    setStep((prev) => prev + 1)
  }

  const isDisabled = () => {
    let activeBrokerFound = true
    for (let i = 0; i < brokers?.length; i++) {
      const element = brokers[i]
      if (element.isActive === true) {
        activeBrokerFound = false
        break
      }
    }
    return activeBrokerFound
  }

  const createNewBroker = () => {
    setShowCreateModal(true)
  }

  const onSuccess = () => {
    getBrokers()
  }

  const getNewBrokerState = (prevState, broker) => {
    const newState = []
    for (let i = 0; i < prevState?.length; i++) {
      const tempBroker = prevState[i]
      if (tempBroker.id === broker.id) {
        tempBroker.isActive = true
      } else if (method === 'Individual') {
        tempBroker.isActive = false
      }
      newState.push(tempBroker)
    }
    return newState
  }

  return (
    <div>
      <NewBroker
        {...{
          showCreateModal,
          setShowCreateModal,
          onSuccess,
          isBroker: true
        }}
      />
      <div className='pb-4 border-b-2 border-softBlack'>
        <p className='my-0 text-xl font-bold'>
          {receiver === 'Brokers' ? 'Brokers' : 'Buyer'}
        </p>
      </div>
      <p className='my-0 mt-4 mb-5 text-softBlack text-base'>
        Select a {receiver === 'Brokers' ? 'broker' : 'buyer'}
      </p>
      <div className='grid grid-cols-3 gap-6 mt-10'>
        {brokers.map((broker, index) => (
          <UserInfoCard
            {...broker}
            key={index}
            onSelect={() => {
              setBrokers((prevState) => getNewBrokerState(prevState, broker))
            }}
            isActive={broker.isActive}
            className=''
          />
        ))}
      </div>
      <div className='grid grid-cols-3 gap-6 w-full justify-between mt-6'>
        <BuyerCard
          title={'Create New Broker'}
          Icon={UserIcon}
          onClick={createNewBroker}
          classes=''
        />
      </div>
      <div className='flex justify-between mt-32'>
        <button
          className='py-3 px-6 text-base border border-softBlack text-softBlack rounded font-medium'
          onClick={onBack}
        >
          Back
        </button>
        <CustomButton
          handleClick={onNext}
          disabled={isDisabled()}
        >
          <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
            Next
          </span>
        </CustomButton>
      </div>
    </div>
  )
}

export default Brokers
