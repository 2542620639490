import React, { useCallback, useEffect, useState } from 'react'
// import propTypes from 'prop-types'
import { Transition } from '@headlessui/react'

import { TThinCloseIcon } from 'components/Icons'

import { DesktopMenuWrapper } from './styles'
import { TCancelButton, TSaveButton, TDeleteButton } from 'components/DefaultButtons'
import { motion } from 'framer-motion'
import { classNames } from 'utils'

import { RiDragDropFill, RiDragDropLine } from 'react-icons/ri'

const Sider = (props) => {
  const {
    showMenu, setShowMenu, children,
    closeButtonClassName, closeButtonColor,
    closeIconClassName, fixed, position,
    title, subtitle, onCancelClick, onSaveClick,
    allowToPopup, saving, initPoppedOut, onDeleteClick,
    deleting, width
  } = props

  const [poppedOut, setPoppedOut] = useState(false)

  useEffect(() => {
    if (initPoppedOut && showMenu) {
      setPoppedOut(true)
    }
  }, [initPoppedOut, showMenu])

  useEffect(() => {
    if (!showMenu) {
      setTimeout(() => {
        setPoppedOut(false)
      }, 500)
    }
  }, [showMenu])

  const closeSider = useCallback(() => {
    if (onCancelClick) {
      onCancelClick()
    }

    if (setShowMenu) {
      setShowMenu()
    }

    setTimeout(() => {
      setPoppedOut(false)
    }, 600)
  }, [showMenu, setShowMenu, onCancelClick])

  const CloseMenuButton = useCallback(() => {
    // Only render the close Icon if there isn't a onCancelClick function
    if (onCancelClick) {
      return (
        <div className={`absolute top-0 right-0 -mr-12 pt-2 ${closeButtonColor} ${closeButtonClassName}`}>
          <button onClick={() => closeSider()} className='ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'>
            <span className='sr-only'>Close sidebar</span>
            <TThinCloseIcon className={`h-6 w-6 ${closeIconClassName}`} />
          </button>
        </div>
      )
    }

    return null
  }, [
    closeButtonClassName, closeSider,
    closeButtonColor, closeIconClassName, onCancelClick
  ])

  const resetSiderPosition = useCallback(() => {
    const animatedWrapper = document.getElementById('animatedWrapper')

    animatedWrapper.removeAttribute('style')
  }, [])

  const renderPopupIcon = useCallback(() => {
    const Icon = poppedOut ? RiDragDropLine : RiDragDropFill

    const iconClassName = 'h-6 w-6 text-white'

    if (allowToPopup) {
      return (
        <div onClick={() => {
          setPoppedOut(!poppedOut)

          setTimeout(() => {
            if (poppedOut) resetSiderPosition()
          }, 10)
        }} className='absolute top-3 right-3 hover:cursor-pointer'>
          <Icon className={iconClassName} />
        </div>
      )
    }

    return null
  }, [allowToPopup, poppedOut, resetSiderPosition])

  const Title = useCallback(() => {
    if (title) {
      return (
        <div className='flex flex-col w-full bg-indigo-600 py-6 px-4 text-white'>
          <span className='text-lg'>{title}</span>
          {subtitle && <span className='text-sm'>{subtitle}</span>}
        </div>
      )
    }

    return null
  }, [title, subtitle])

  const Footer = useCallback(() => {
    if (onCancelClick || onSaveClick) {
      return (
        <div className="flex-shrink-0 px-4 py-4 flex justify-end border-t">
          {onDeleteClick && <div className='flex flex-grow'><TDeleteButton loading={deleting} onClick={onDeleteClick}>Delete</TDeleteButton></div>}

          <TCancelButton onClick={() => closeSider()}>Cancel</TCancelButton>
          {onSaveClick && <TSaveButton loading={saving} onClick={onSaveClick} className='ml-5'>Save</TSaveButton>}
        </div>
      )
    }

    return null
  }, [onCancelClick, closeSider, onSaveClick, saving, deleting, onDeleteClick])

  const renderCustomChildren = useCallback(() => (
    <div className={classNames('h-full flex flex-col relative', width)}>
      {renderPopupIcon()}
      <Title />
      <div className='flex flex-1 h-max flex-col overflow-y-auto'>
        {children}
      </div>
      <Footer />
    </div>
  ), [children, renderPopupIcon, width])

  return (
    <>
      <Transition show={showMenu ?? false}>
        <div className='border-r border-gray-200'>
          <motion.div
            id='animatedWrapper'
            drag={poppedOut}
            dragMomentum={false}
            dragPropagation={false}
            className={`fixed inset-y-0 
            ${position === 'left' ? 'left-0' : 'right-0'} flex z-500 
            ${fixed && 'xl:hidden'}
            `}
            initial={initPoppedOut && showMenu ? { x: -1000 } : { x: 0 }}
          >
            <Transition.Child
              enter='transition-opacity ease-linear duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='transition-opacity ease-linear duration-300'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              {
                !poppedOut && (
                  <div
                    onClick={() => closeSider()}
                    className='fixed inset-0'
                    aria-hidden='true'
                  >
                    <div className='absolute inset-0 bg-gray-600 opacity-75' />
                  </div>
                )
              }

              <Transition.Child
                enter='transition ease-in-out duration-300 transform'
                enterFrom={position === 'left' ? '-translate-x-full' : 'translate-x-full'}
                enterTo={position === 'left' ? 'translate-x-0' : '-translate-x-0'}
                leave='transition ease-in-out duration-300 transform'
                leaveFrom={classNames(poppedOut ? 'scale-100' : position === 'left' ? 'translate-x-0' : '-translate-x-0')}
                leaveTo={classNames(poppedOut ? 'scale-0' : position === 'left' ? '-translate-x-full' : 'translate-x-full')}
                className={poppedOut ? 'h-17/20' : 'h-full'}
              >
                <div
                  className={classNames('relative h-full',
                    poppedOut && 'rounded-md overflow-hidden border top-10 right-10 shadow-2xl',
                    'flex-1 flex flex-col max-w-sm w-full bg-gray-1',
                    width
                  )}
                >
                  <CloseMenuButton />
                  {renderCustomChildren()}
                </div>
                <div className='flex-shrink-0 w-14' aria-hidden='true' />
              </Transition.Child>
            </Transition.Child>
          </motion.div>
        </div>
      </Transition>

      {
        fixed && (
          <DesktopMenuWrapper className='hidden xl:flex xl:flex-shrink-0'>
            <div className='flex flex-col'>
              <div className='flex flex-col h-0 flex-1 border-r border-gray-200 bg-gray-100'>
                <div className='flex-1 flex flex-col overflow-y-auto'>
                  {renderCustomChildren()}
                </div>
              </div>
            </div>
          </DesktopMenuWrapper>
        )
      }
    </>
  )
}

export default Sider

Sider.defaultProps = {
  closeButtonColor: 'text-white',
  fixed: true,
  position: 'left',
  title: '',
  subtitle: '',
  allowToPopup: false,
  onCancelClick: false,
  onSaveClick: false,
  initPoppedOut: false,
  width: ''
}

// Sider.propTypes = {
//   showMenu: propTypes.bool,
//   setShowMenu: propTypes.func,
//   children: propTypes.node,
//   closeButtonClassName: propTypes.string,
//   closeButtonColor: propTypes.string,
//   closeIconClassName: propTypes.string,
//   fixed: propTypes.bool,
//   position: propTypes.bool,
//   title: propTypes.bool,
//   subtitle: propTypes.bool,
//   onCancelClick: propTypes.func,
//   onSaveClick: propTypes.func,
//   allowToPopup: propTypes.bool,
//   saving: propTypes.bool,
//   initPoppedOut: propTypes.bool,
//   onDeleteClick: propTypes.func,
//   deleting: propTypes.bool,
//   width: propTypes.string
// }
