// @ts-nocheck
import React, { useCallback, useState, useMemo } from 'react'
import standardStyle from 'assets/css/standardStyle'
import {
  ContractThumbnailWrapper,
  PageControlWrapper,
  ContractThumbnailDocumentWrapper,
  ContractSignatureStatusWrapper
} from './../styles'
import PdfDocument from './PdfDocument'
import propTypes from 'prop-types'
import AnimatedList from 'components/AnimatedList'
import PdfPagination from './PdfPagination'
import { ReactComponent as TickIcon } from 'assets/icons/tickk.svg'
import { isSelectedContractSignedByUser } from '..'
import { getUserType } from 'utils'

const contractVariants = {
  hidden: {
    x: -100,
    opacity: 0
  },
  enter: (custom) => ({
    x: 0,
    opacity: 1,
    background: 'transparent',
    transition: {
      delay: custom * 0.08
    }
  }),
  selected: (custom) => ({
    x: 0,
    opacity: 1,
    background: standardStyle.colors.mainBackground,
    transition: {
      delay: custom * 0.08
    }
  })
}

const signatureStatus = {
  awaiting: 'Awaiting Signature',
  awaiting_signature: 'Awaiting Signature',
  signed: 'Signed',
  canceled: 'Canceled',
  Viewed: 'Viewed',
  Started: 'Started'
}

const ContractThumbnail = ({
  contractData,
  index,
  selected,
  onSelectDoc,
  onChangePdfPage,
  onLoadSuccess,
  isPublicPath = false
}) => {
  const isContractSigned = useMemo(() => {
    return (
      (isPublicPath ||
        isSelectedContractSignedByUser(contractData, 'DeveloperAdmin')) &&
      isSelectedContractSignedByUser(contractData, 'Buyer')
    )
  }, [contractData])

  const { url, signatures, contractTemplate } = contractData
  const [pdfPageNumber, setPdfPageNumber] = useState(1)
  const [pdfNumPages, setPdfNumPages] = useState(0)

  const getDocumentStatus = useCallback(
    (signature) => {
      if (!signatures?.length) {
        return null
      }
      const status = signature.status
      const signatureUserType = signature.userType
      return `${signatureUserType}: ${signatureStatus[status]}`
    },
    [signatures]
  )

  const decreaseSelectedContractPage = useCallback(
    (e) => {
      e.stopPropagation()
      if (pdfPageNumber > 1) {
        const newNumber = Number(pdfPageNumber) - 1
        setPdfPageNumber(newNumber)
        onChangePdfPage(newNumber)
      }
    },
    [pdfPageNumber]
  )

  const increaseSelectedContractPage = useCallback(
    (e) => {
      e.stopPropagation()
      if (pdfPageNumber < pdfNumPages) {
        const newNumber = Number(pdfPageNumber) + 1
        setPdfPageNumber(newNumber)
        onChangePdfPage(newNumber)
      }
    },
    [pdfPageNumber, pdfNumPages]
  )

  const showSignatureStatus = !isPublicPath && signatures?.length
  return (
    <ContractThumbnailWrapper
      key={index}
      variants={contractVariants}
      initial='hidden'
      animate={selected ? 'selected' : 'enter'}
      custom={index}
      onClick={() => {
        setPdfPageNumber(1)
        onSelectDoc(contractData)
      }}
    >
      <span className='text-base ml-4 mr-2 font-bold'>{`${index + 1}. ${contractTemplate?.name}`}</span>
      <ContractThumbnailDocumentWrapper>
        <PageControlWrapper
          pdfPageNumber={pdfPageNumber}
          pdfNumPages={pdfNumPages}
        >
          <PdfDocument
            documentProps={{ file: url }}
            pageNumber={selected ? pdfPageNumber : 1}
            pageProps={{ height: 150 }}
            onLoadSuccess={(pdf) => {
              setPdfNumPages(pdf?._pdfInfo?.numPages)
              onLoadSuccess(pdf)
            }}
          />
          {isContractSigned && (
            <div className='absolute bg-volt h-16 w-16 rounded-lg flex justify-center items-center p-2'>
              <TickIcon />
            </div>
          )}
        </PageControlWrapper>
      </ContractThumbnailDocumentWrapper>
      {selected && !isContractSigned && (
        <PdfPagination
          pdfPageNumber={pdfPageNumber}
          setPdfPageNumber={setPdfPageNumber}
          onChangePdfPage={onChangePdfPage}
          pdfNumPages={pdfNumPages}
          decreaseSelectedContractPage={decreaseSelectedContractPage}
          increaseSelectedContractPage={increaseSelectedContractPage}
        />
      )}
      <AnimatedList wrapperClassName='mt-5 space-y-1.5'>
        {signatures
          ?.filter(
            (signature) =>
              showSignatureStatus || signature.userType === getUserType('Buyer')
          )
          ?.map((signature) => (
            <ContractSignatureStatusWrapper
              className={`px-2 text-white font-semibold rounded-md ${signature.status === 'signed' ? 'bg-green-400' : 'bg-yellow-400'}`}
            >
              {getDocumentStatus(signature)}
            </ContractSignatureStatusWrapper>
          ))}
      </AnimatedList>
    </ContractThumbnailWrapper>
  )
}
export default React.memo(ContractThumbnail)

ContractThumbnail.propTypes = {
  contractData: propTypes.shape({
    description: propTypes.string,
    url: propTypes.any,
    pdfNumPages: propTypes.number,
    contractTemplateId: propTypes.string,
    signatures: propTypes.array
  }),
  index: propTypes.number,
  selected: propTypes.bool,
  onSelectDoc: propTypes.func,
  onChangePdfPage: propTypes.func,
  onLoadSuccess: propTypes.func,
  isPublicPath: propTypes.bool
}
