import React from 'react'
import propTypes from 'prop-types'
import { Popover } from '@headlessui/react'
import { MoreIcon } from 'components/Icons'

const Option = ({ title, icon, handleOnClick }) => {
  return (
    <div
      onClick={handleOnClick}
      className='group flex items-center gap-2 px-4 py-2 text-base text-softBlack font-medium hover:bg-gray-100 hover:cursor-pointer hover:text-gray-900'
      role='menuitem'
    >
      {icon &&
        <span className='text-softBlack'>{icon}</span>
      }
      {title}
    </div>
  )
}

const Dropdown = ({ options }) => {
  const renderOptions = (close) => {
    return options.map((option, index) =>
      <Option
        key={index}
        title={option.title}
        icon={option.icon}
        handleOnClick={() => {
          option.onClick({ id: option.id, status: option.status })
          close()
        }}
      />
    )
  }

  return (
    <Popover className="relative">
      <Popover.Button>
        <MoreIcon className='text-4xl 2xl:text-5xl' />
      </Popover.Button>
      <Popover.Panel className="absolute right-0 z-10 h-auto overflow-auto mt-2 bg-menuGray border border-black ring-1 ring-black ring-opacity-5 divide-y divide-gray-100"
        role='menu'
        aria-orientation='vertical'
        aria-labelledby='options-menu'>
        {({ close }) => (
          <div id='optionsWrapper'>
            {renderOptions(close)}
          </div>
        )}
      </Popover.Panel>
    </Popover>
  )
}

export default Dropdown

Dropdown.propTypes = {
  options: propTypes.array
}

Option.propTypes = {
  title: propTypes.string,
  icon: propTypes.node,
  handleOnClick: propTypes.func
}
