// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react'
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg'
import { Oval } from 'react-loader-spinner'
import { Modal } from '@material-ui/core'
import CustomInput from 'components/CustomInput'
import CustomSelect from 'components/CustomSelect'
import { useSelector } from 'react-redux'
import ProjectFileSelector from 'components/ProjectFileSelector'
import { createOrUpdateStorage, getStorageAllocationInventory, getStorageInventory } from 'store/actions/storageActions'
import { getProjectUnits } from 'store/actions/unitActions'
import CustomButton from 'components/CustomButton'

const AddStorageModal = ({
  show,
  setShow,
  editDetails,
  setEditDetails,
  building,
  refetch
}) => {
  // @ts-ignore
  const appProject = useSelector((state) => state.appReducer.appProject)
  const mountedRef = useRef(true)
  const [units, setUnits] = useState([])
  const [storageType, setStorageType] = useState([])
  const [storageLevel, setStorageLevel] = useState([])
  const [allocationType, setAllocationType] = useState([])

  const _getStorageAllocationType = async () => {
    if (appProject) {
      setAllocationType([])
      let storageAllocationInventory = await getStorageAllocationInventory(building)
      storageAllocationInventory = storageAllocationInventory.map((storage) => ({
        allocationType: storage.options.map((op) => op.name)
      }))
      Promise.all([storageAllocationInventory]).then(() => {
        if (storageAllocationInventory.length) {
          setAllocationType(storageAllocationInventory[0].allocationType.map((at) => (
            {
              value: at,
              id: at
            }
          )))
        }
      })
    }
  }

  const _getStorageType = async () => {
    if (appProject) {
      setStorageType([])
      let storageInventory = await getStorageInventory(building)
      storageInventory = storageInventory.map((storage) => ({
        storageType: storage.options.map((op) => op.name),
        storageLevels: storage.storageLevels
      }))
      Promise.all([storageInventory]).then(() => {
        if (storageInventory.length) {
          setStorageType(storageInventory[0].storageType.map((pt) => (
            {
              value: pt,
              id: pt
            }
          )))
        }
      })
      setStorageLevel(storageInventory[0].storageLevels.map((pl) => {
        return {
          value: pl,
          id: pl
        }
      }))
    }
  }

  useEffect(() => {
    _getStorageType()
    _getStorageAllocationType()
  }, [appProject])

  const initialEmptyValues = {
    status: '',
    unit: null,
    unitNumber: '',
    floorNumber: '',
    type: 'Created',
    description: '',
    building,
    price: '',
    images: [],
    storageLevel: null,
    storageType: null,
    allocationType: null,
    storageNumber: ''
  }
  const [value, setValues] = useState({
    ...initialEmptyValues
  })

  const TEMP_AVAILABILITY_STATUS = {
    available: 'Available',
    assigned: 'Assigned',
    reserved: 'Reserved',
    allocated: 'Allocated',
    rescission: 'Rescission',
    sold: 'Sold'
  }

  // const storageLevelOptions = [
  //   { id: 1, value: 1 },
  //   { id: 2, value: 2 }
  // ]

  const STATUS_OPTIONS = [
    ...Object.values(TEMP_AVAILABILITY_STATUS).map((value) => {
      return { value: value, id: value }
    })
  ]

  const handleSubmit = () => {
    if (validate()) {
      setLoading(true)
      const temp = {
        // @ts-ignore
        _id: value?._id ?? '',
        status: value.status,
        unit: value.unit,
        unitNumber: value.unitNumber,
        floorNumber: value.floorNumber,
        type: 'Created',
        building,
        description: value.description,
        price: value.price,
        images: [],
        storageLevel: value.storageLevel,
        storageNumber: value.storageNumber,
        storageType: value.storageType,
        allocationType: value.allocationType
      }
      createOrUpdateStorage(temp)
        .then((data) => {
          refetch()
          setLoading(false)
          setShow(false)
          setEditDetails(data)
        })
        .catch((err) => {
          setLoading(false)
          console.log(err)
        })
    }
  }

  const onChange = (fieldName, newValue) => {
    setValues({ ...value, [fieldName]: newValue })
  }
  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    setShow(false)
    setEditDetails({})
  }

  const validate = () => {
    for (const key in initialEmptyValues) {
      if (key !== 'unit' && key !== 'images' && key !== 'unitNumber' && !value[key]) {
        return false
      }
    }
    return true
  }

  useEffect(() => {
    if (!mountedRef.current) return null
    if (editDetails?.editType === 'Storage') {
      setValues({
        ...value,
        ...editDetails,
        status: STATUS_OPTIONS.filter(
          ({ id }) => id === editDetails.status
        )?.[0]?.id
      })
    } else {
      setValues(initialEmptyValues)
    }
  }, [editDetails, units])

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  useEffect(() => {
    getProjectUnits(appProject).then((data) => setUnits(data.map((elm) => ({
      value: elm.unitNumber,
      id: elm._id
    }))))
  }, [appProject])

  const handleStatusChange = (id) => {
    const temp = STATUS_OPTIONS.filter((option) => option.id === id)[0]
    onChange('status', temp.id)
  }

  const handleUnitChange = (id) => {
    const temp = units.filter((option) => option.id === id)[0]
    setValues({
      ...value,
      unit: temp.id,
      unitNumber: temp.value
    })
  }

  const handleImagesChange = (contractObject) => {
    onChange('images', contractObject._id ?? contractObject.id)
  }

  const handleStorageTypeChange = (id) => {
    const temp = storageType.filter((option) => option.id === id)[0]
    onChange('storageType', temp.id)
  }

  const handleAllocationTypeChange = (id) => {
    const temp = allocationType.filter((option) => option.id === id)[0]
    onChange('allocationType', temp.id)
  }

  const handleStorageLevelChange = (id) => {
    const temp = storageLevel.filter((option) => option.id === id)[0]
    onChange('storageLevel', temp.id)
  }

  return (
    <Modal
      open={show}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div
        className='font-openSans relative bg-grey-8 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-10 rounded shadow-lg'
        style={{ width: 1015 }}
      >
        <button className='absolute right-10' onClick={handleClose}>
          <CrossIcon />
        </button>
        <h1 className='font-bold text-xl text-center p-0 m-0 mb-6'>
          {!value?._id ? 'Add' : 'Update'} Storage
        </h1>
        <div className='grid grid-cols-2 gap-6 mb-6'>
          <CustomSelect
            label='Select Unit *'
            inputClasses='h-11'
            labelClasses={undefined}
            options={units}
            selectedOption={value.unit}
            setSelectedOption={(option) => {
              handleUnitChange(option)
            }}
          />
          <CustomInput
            label='Enter Storage number *'
            placeholder='Enter storage number'
            labelClassName='italic'
            value={value.storageNumber}
            onChange={(e) => {
              onChange('storageNumber', e.target.value)
            }}
            required='required'
            errorMessage='Enter storage number...'
            containerClasses={undefined}
            classes={undefined}
          />
          <div className='grid grid-cols-2 gap-6'>
            <CustomInput
              label='price *'
              placeholder='Enter amount'
              labelClassName='italic'
              value={value.price}
              onChange={(e) => {
                onChange('price', e.target.value)
              }}
              type='number'
              required='required'
              errorMessage='Please enter price'
              containerClasses={undefined}
              classes={undefined}
            />
            <CustomSelect
              label='status *'
              inputClasses='h-11'
              labelClasses={undefined}
              options={STATUS_OPTIONS}
              selectedOption={value.status}
              setSelectedOption={(option) => {
                handleStatusChange(option)
              }}
            />
          </div>
          <CustomSelect
            label='Storage Type'
            inputClasses='h-11'
            labelClasses={undefined}
            options={storageType}
            selectedOption={value.storageType}
            setSelectedOption={(option) => {
              handleStorageTypeChange(option)
            }}
          />
          <CustomSelect
            label='Allocation Type'
            inputClasses='h-11'
            labelClasses={undefined}
            options={allocationType}
            selectedOption={value.allocationType}
            setSelectedOption={(option) => {
              handleAllocationTypeChange(option)
            }}
          />
          <div className='grid grid-cols-2 gap-6'>
            <CustomInput
              label='Storage description *'
              placeholder='Enter description'
              labelClassName='italic'
              value={value.description}
              onChange={(e) => {
                onChange('description', e.target.value)
              }}
              required='required'
              errorMessage='Enter description...'
              containerClasses={undefined}
              classes={undefined}
            />
            <CustomInput
              label='floor number*'
              placeholder='Enter number'
              labelClassName='italic'
              value={value.floorNumber}
              onChange={(e) => {
                onChange('floorNumber', e.target.value)
              }}
              type='number'
              required='required'
              errorMessage='Please enter floor number'
              containerClasses={undefined}
              classes={undefined}
            />
          </div>
          <div className='grid grid-cols-2 gap-6'>
            <CustomSelect
              label='Storage Level*'
              labelClasses='italic'
              inputClasses='h-11'
              options={storageLevel}
              setSelectedOption={(value) => {
                handleStorageLevelChange(value)
              }}
              selectedOption={value.storageLevel}
            />
            <ProjectFileSelector
              title={'Images'}
              onSuccess={(selectedFileData) => {
                handleImagesChange(JSON.parse(selectedFileData))
              }}
            />
          </div>
        </div>
        <CustomButton handleClick={handleSubmit} disabled={!validate() || loading} className='m-auto'>
          <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
            {loading && <Oval height='18' width='18' color='white' />}
            {value?._id ? 'Update' : 'Add'} Storage
          </span>
        </CustomButton>
      </div>
    </Modal>
  )
}

export default AddStorageModal
