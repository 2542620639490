// @ts-nocheck
/* eslint-disable no-unused-vars */
import { api, ApiErrors } from 'services/api'
import { message } from 'antd'
import { getRandomInteger } from 'utils'

export const uploadFile = ({ projectId, docName, file, tag, userId }) =>
  new Promise((resolve, reject) => {
    const formData = new FormData()
    console.log('uploadFile type', tag)
    const fileName = `${projectId}-${tag}-${docName}-${
      file.name || getRandomInteger()
    }`
    formData.append('documentName', docName)
    formData.append('fileName', fileName)
    formData.append('user', userId)
    formData.append('project', projectId)
    formData.append('tags', `${tag}`)
    formData.append('file', file)
    api.post('files', formData).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getAllFiles = (projectId) =>
  new Promise((resolve, reject) => {
    api.get(`files/project/${projectId}`).then((response) => {
      if (response.ok) {
        resolve(response.data.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const deleteFile = (documentId) =>
  new Promise((resolve, reject) => {
    api.delete(`files/${documentId}`).then((response) => {
      if (response.ok) {
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })
