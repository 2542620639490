import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg'
import { Oval } from 'react-loader-spinner'
import { Modal } from '@material-ui/core'
import CustomInput from 'components/CustomInput'
import CustomSelect from 'components/CustomSelect'
import { getProjectUnits } from 'store/actions/unitActions'
import { useSelector } from 'react-redux'
import { createOrUpdateParking, getParkingAllocationInventory, getParkingInventory } from 'store/actions/parkingActions'
import CustomButton from 'components/CustomButton'

const AddParkingModal = ({
  show,
  setShow,
  editDetails,
  setEditDetails,
  refetch,
  building
}) => {
  // @ts-ignore
  const projectSelector = state => state.appReducer.appProject
  const appProject = useSelector(projectSelector)
  const userSelector = state => state.authReducer.userObject
  const userObject = useSelector(userSelector)
  const mountedRef = useRef(true)
  const [unitOptions, setUnitOptions] = useState([])
  const [parkingType, setParkingType] = useState([])
  const [parkingLevel, setParkingLevel] = useState([])
  const [allocationType, setAllocationType] = useState([])

  useEffect(() => {
    _getProjectUnits()
    _getParkingType()
    _getParkingAllocationType()
  }, [appProject])

  const _getParkingType = async () => {
    if (appProject) {
      setParkingType([])
      let parkingInventory = await getParkingInventory(building)
      parkingInventory = parkingInventory.map((parking) => ({
        parkingType: parking.options.map((op) => op.name),
        parkingLevels: parking.parkingLevels
      }))
      Promise.all([parkingInventory]).then(() => {
        if (parkingInventory.length) {
          setParkingType(parkingInventory[0].parkingType.map((pt) => (
            {
              value: pt,
              id: pt
            }
          )))
        }
      })
      setParkingLevel(parkingInventory[0].parkingLevels.map((pl) => {
        return {
          value: pl,
          id: pl
        }
      }))
    }
  }

  const _getParkingAllocationType = async () => {
    if (appProject) {
      setAllocationType([])
      let parkingAllocationInventory = await getParkingAllocationInventory(building)
      parkingAllocationInventory = parkingAllocationInventory.map((parking) => ({
        allocationType: parking.options.map((op) => op.name)
      }))
      Promise.all([parkingAllocationInventory]).then(() => {
        if (parkingAllocationInventory.length) {
          setAllocationType(parkingAllocationInventory[0].allocationType.map((at) => (
            {
              value: at,
              id: at
            }
          )))
        }
      })
    }
  }
  const _getProjectUnits = async () => {
    if (appProject) {
      setUnitOptions([])

      let projectUnits = await getProjectUnits(appProject)

      projectUnits = projectUnits.map((unit) => ({
        ...unit,
        hasPermission: checkUnitPermission(unit)
      }))

      Promise.all([projectUnits]).then(() => {
        if (projectUnits.length) {
          setUnitOptions(projectUnits.map((unit) => (
            {
              value: unit.unitNumber,
              id: unit._id
            }
          )))
        }
      })
    }
  }

  const checkUnitPermission = useCallback(
    (unit, selectedTabFilter) => {
      const { _id } = userObject
      const { brokerAssigned, reservedTo } = unit

      const tmpBrokerAssigned = brokerAssigned || []

      return Boolean(
        (selectedTabFilter !== 'myUnits' &&
          unit.status !== 'ReservedBroker' &&
          unit.status !== 'ReservedBuyer') ||
        ((unit.status === 'ReservedBroker' ||
          unit.status === 'ReservedBuyer') &&
          ((tmpBrokerAssigned &&
            tmpBrokerAssigned.length &&
            tmpBrokerAssigned[0] !== 'HIDDEN') ||
            (reservedTo && reservedTo.length && reservedTo[0] !== 'HIDDEN') ||
            tmpBrokerAssigned.filter((broker) => broker === _id).length))
      )
    },
    [userObject]
  )

  const initialEmptyValues = {
    stall: null,
    price: null,
    floorNumber: null,
    unit: null,
    status: '',
    inBuilding: null,
    building,
    parkingLevel: null,
    parkingType: null,
    allocationType: null
  }
  const [value, setValues] = useState({
    ...initialEmptyValues
  })

  const TEMP_AVAILABILITY_STATUS = {
    available: 'Available',
    assigned: 'Assigned',
    reserved: 'Reserved',
    allocated: 'Allocated',
    rescission: 'Rescission',
    sold: 'Sold'
  }

  const STATUS_OPTIONS = [
    ...Object.values(TEMP_AVAILABILITY_STATUS).map((value) => {
      return { value: value, id: value }
    })
  ]

  const isInsideBuildingOptions = [
    { id: 'true', value: 'Yes' },
    { id: 'false', value: 'No' }
  ]

  // const parkingLevelOptions = [
  //   { id: 1, value: 1 },
  //   { id: 2, value: 2 }
  // ]

  const handleSubmit = () => {
    if (validate()) {
      setLoading(true)
      const temp = {
        // @ts-ignore
        ...value
      }
      createOrUpdateParking(temp)
        .then((data) => {
          refetch()
          setLoading(false)
          setShow(false)
          setEditDetails(data)
        })
        .catch((err) => {
          setLoading(false)
          console.log(err)
        })
    }
  }

  const onChange = (fieldName, newValue) => {
    setValues({ ...value, [fieldName]: newValue })
  }
  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    setShow(false)
    setEditDetails({})
  }

  const validate = () => {
    for (const key in initialEmptyValues) {
      if (key !== 'unit' && !value[key]) {
        return false
      }
    }
    return true
  }

  useEffect(() => {
    if (!mountedRef.current) return null
    if (editDetails?.type === 'Parking') {
      setValues({
        ...value,
        ...editDetails,
        status: STATUS_OPTIONS.filter(
          ({ id }) => id === editDetails.status
        )?.[0]?.id,
        inBuilding: editDetails.inBuilding ? 'true' : 'false'
      })
    } else {
      setValues(initialEmptyValues)
    }
  }, [editDetails])

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  const handleStatusChange = (id) => {
    const temp = STATUS_OPTIONS.filter((option) => option.id === id)[0]
    onChange('status', temp.id)
  }

  const handleUnitChange = (id) => {
    const temp = unitOptions.filter((option) => option.id === id)[0]
    onChange('unit', temp.id)
  }

  const handleIsInsideBuildingChange = (id) => {
    const temp = isInsideBuildingOptions.filter((option) => option.id === id)[0]
    onChange('inBuilding', temp.id)
  }

  const handleParkingTypeChange = (id) => {
    const temp = parkingType.filter((option) => option.id === id)[0]
    onChange('parkingType', temp.id)
  }

  const handleAllocationTypeChange = (id) => {
    const temp = allocationType.filter((option) => option.id === id)[0]
    onChange('allocationType', temp.id)
  }

  const handleParkingLevelChange = (id) => {
    const temp = parkingLevel.filter((option) => option.id === id)[0]
    onChange('parkingLevel', temp.id)
  }

  return (
    <Modal
      open={show}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div
        className='font-openSans relative bg-grey-8 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-10 rounded shadow-lg'
        style={{ width: 1015 }}
      >
        <button className='absolute right-10' onClick={handleClose}>
          <CrossIcon />
        </button>
        <h1 className='font-bold text-xl text-center p-0 m-0 mb-6'>
          {!value?._id ? 'Add' : 'Update'} Parking
        </h1>
        <div className='grid grid-cols-1 gap-6 mb-6'>
          <div className='grid grid-cols-2 gap-6'>
            <CustomInput
              label='Stall*'
              placeholder='Enter number'
              labelClassName='italic'
              value={value.stall}
              onChange={(e) => {
                onChange('stall', e.target.value)
              }}
              type='number'
              required='required'
              errorMessage='Please enter stall number'
              containerClasses={undefined}
              classes={undefined}
            />
            <CustomSelect
              label='status*'
              inputClasses='h-11'
              labelClasses={undefined}
              options={STATUS_OPTIONS}
              selectedOption={value.status}
              setSelectedOption={(option) => {
                handleStatusChange(option)
              }}
            />
          </div>
          <div className='grid grid-cols-2 gap-6'>
            <CustomInput
              label='price*'
              placeholder='Enter amount'
              labelClassName='italic'
              value={value.price}
              onChange={(e) => {
                onChange('price', e.target.value)
              }}
              type='number'
              required='required'
              errorMessage='Please enter price'
              containerClasses={undefined}
              classes={undefined}
            />
            <CustomSelect
              label='Unit*'
              inputClasses='h-11'
              labelClasses={undefined}
              options={unitOptions}
              selectedOption={value.unit}
              setSelectedOption={(option) => {
                handleUnitChange(option)
              }}
            />
          </div>
          <div className='grid grid-cols-2 gap-6'>
            <CustomSelect
              label='Parking Type'
              inputClasses='h-11'
              labelClasses={undefined}
              options={parkingType}
              selectedOption={value.parkingType}
              setSelectedOption={(option) => {
                handleParkingTypeChange(option)
              }}
            />
            <CustomSelect
              label='Allocation Type'
              inputClasses='h-11'
              labelClasses={undefined}
              options={allocationType}
              selectedOption={value.allocationType}
              setSelectedOption={(option) => {
                handleAllocationTypeChange(option)
              }}
            />
          </div>
          <div className='grid grid-cols-3 gap-6'>
            <CustomInput
              label='floor number*'
              placeholder='Enter number'
              labelClassName='italic'
              value={value.floorNumber}
              onChange={(e) => {
                onChange('floorNumber', e.target.value)
              }}
              type='number'
              required='required'
              errorMessage='Please enter floor number'
              containerClasses={undefined}
              classes={undefined}
            />
            <CustomSelect
              label='Is inside building*'
              labelClasses='italic'
              inputClasses='h-11'
              options={isInsideBuildingOptions}
              setSelectedOption={(value) => {
                handleIsInsideBuildingChange(value)
              }}
              selectedOption={value.inBuilding}
            />
            <CustomSelect
              label='Parking Level*'
              labelClasses='italic'
              inputClasses='h-11'
              options={parkingLevel}
              setSelectedOption={(value) => {
                handleParkingLevelChange(value)
              }}
              selectedOption={value.parkingLevel}
            />
          </div>
        </div>
        <CustomButton handleClick={handleSubmit} disabled={!validate() || loading} className='m-auto'>
          <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
            {loading && <Oval height='18' width='18' color='white' />}
            {value?._id ? 'Update' : 'Add'} Parking
          </span>
        </CustomButton>
      </div>
    </Modal>
  )
}

export default AddParkingModal
