import React from 'react'
import ProjectColors from '../components/ProjectColors'

const AddColors = ({ setStep }) => {
  return (
    <ProjectColors setStep={setStep} />
  )
}

export default AddColors
