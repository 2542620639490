// @ts-nocheck
/* eslint-disable */
// @ts-ignore
import { classNames } from "utils"

import React from "react"
import { createContext, forwardRef } from "react"
import * as RechartsPrimitive from "recharts"
// Format: { THEME_NAME: CSS_SELECTOR }
const THEMES = { light: "", dark: ".dark" } 


const ChartContext = createContext(null)

// @ts-ignore
function useChart() {
  const context = React.useContext(ChartContext)

  if (!context) {
    throw new Error("useChart must be used within a <ChartContainer />")
  }

  return context
}

// @ts-ignore
const ChartContainer = forwardRef(({ id, className, children, config, ...props }, ref) => {
  const uniqueId = Math.random().toString(36).substring(2, 15)
  const chartId = `chart-${id || uniqueId.replace(/:/g, "")}`

  return (
    <ChartContext.Provider value={{ config }}>
      <div
        data-chart={chartId}
        ref={ref}
        className={classNames(
          "flex aspect-video justify-center text-xs [&_.recharts-cartesian-axis-tick_text]:fill-muted-foreground [&_.recharts-cartesian-grid_line[stroke='#ccc']]:stroke-border/50 [&_.recharts-curve.recharts-tooltip-cursor]:stroke-border [&_.recharts-dot[stroke='#fff']]:stroke-transparent [&_.recharts-layer]:outline-none [&_.recharts-polar-grid_[stroke='#ccc']]:stroke-border [&_.recharts-radial-bar-background-sector]:fill-muted [&_.recharts-rectangle.recharts-tooltip-cursor]:fill-muted [&_.recharts-reference-line_[stroke='#ccc']]:stroke-border [&_.recharts-sector[stroke='#fff']]:stroke-transparent [&_.recharts-sector]:outline-none [&_.recharts-surface]:outline-none",
          className
        )}
        {...props}
      >
        <ChartStyle id={chartId} config={config} />
        <RechartsPrimitive.ResponsiveContainer>
          {children}
        </RechartsPrimitive.ResponsiveContainer>
      </div>
    </ChartContext.Provider>
  )
})
ChartContainer.displayName = "Chart"

// @ts-ignore
const ChartStyle = ({ id, config }) => {
  const colorConfig = Object.entries(config).filter(
    ([_, config]) => config.theme || config.color
  )

  if (!colorConfig.length) {
    return null
  }

  return (
    <style
      dangerouslySetInnerHTML={{
        __html: Object.entries(THEMES)
          .map(
            ([theme, prefix]) => `
${prefix} [data-chart=${id}] {
${colorConfig
  .map(([key, itemConfig]) => {
    const color =
      // @ts-ignore
      itemConfig.theme?.[theme] ||
      itemConfig.color
    return color ? `  --color-${key}: ${color};` : null
  })
  .join("\n")}
}
`
          )
          .join("\n"),
      }}
    />
  )
}

const ChartTooltip = RechartsPrimitive.Tooltip

// @ts-ignore
const ChartTooltipContent = React.forwardRef(
  (
    {
      // @ts-ignore
      active,
      // @ts-ignore
      payload,
      // @ts-ignore
      className,
      // @ts-ignore
      indicator = "dot",
      // @ts-ignore
      hideLabel = false,
      // @ts-ignore
      hideIndicator = false,
      // @ts-ignore
      label,
      // @ts-ignore
      labelFormatter,
      // @ts-ignore
      labelClassName,
      // @ts-ignore
      formatter,
      // @ts-ignore
      color,
      // @ts-ignore
      nameKey,
      // @ts-ignore
      labelKey,
    // @ts-ignore
    },
    ref
  ) => {
    const { 
// @ts-ignore
    config } = useChart()

    const tooltipLabel = React.useMemo(() => {
      if (hideLabel || !payload?.length) {
        return null
      }

      const [item] = payload
      const key = `${
// @ts-ignore
      labelKey || item.dataKey || item.name || "value"}`
      const itemConfig = getPayloadConfigFromPayload(config, item, key)
      const value =
        !labelKey && typeof label === "string"
          ? config[label]?.label || label
          : itemConfig?.label

      if (labelFormatter) {
        return (
          <div className={classNames("font-medium", 
// @ts-ignore
          labelClassName)}>
            {
// @ts-ignore
            labelFormatter(value, payload)}
          </div>
        )
      }

      if (!value) {
        return null
      }

      return <div className={classNames("font-medium", 
// @ts-ignore
      labelClassName)}>{value}</div>
    }, [
      label,
      labelFormatter,
      payload,
      hideLabel,
      labelClassName,
      config,
      labelKey,
    ])

    if (!active || !payload?.length) {
      return null
    }

    const nestLabel = payload.length === 1 && indicator !== "dot"

    return (
      <div
        // @ts-ignore
        ref={ref}
        className={classNames(
          "grid min-w-[8rem] items-start bg-white gap-1.5 rounded-lg border border-border/50 px-2.5 py-1.5 text-xs shadow-xl",
          // @ts-ignore
          className
        )}
      >
        {!
// @ts-ignore
        nestLabel ? tooltipLabel : null}
        <div className="grid gap-1.5">
          {
// @ts-ignore
          payload.map((item, index) => {
            // @ts-ignore
            const key = `${nameKey || item.name || item.dataKey || "value"}`
            // @ts-ignore
            const itemConfig = getPayloadConfigFromPayload(config, item, key)
            // @ts-ignore
            const indicatorColor = color || item.payload.fill || item.color

            return (
              <div
                key={item.dataKey}
                className={classNames(
                  "flex w-full flex-wrap items-stretch gap-2 [&>svg]:h-2.5 [&>svg]:w-2.5 [&>svg]:text-muted-foreground",
                  // @ts-ignore
                  indicator === "dot" && "items-center"
                )}
              >
                {
// @ts-ignore
                formatter && item?.value !== undefined && item.name ? (
                  // @ts-ignore
                  formatter(item.value, item.name, item, index, item.payload)
                ) : (
                  <>
                    {itemConfig?.icon ? (
                      <itemConfig.icon />
                    ) : (
                      // @ts-ignore
                      !hideIndicator && (
                        <div
                          className={classNames(
                            "shrink-0 rounded-[2px] border-[--color-border] bg-[--color-bg]",
                            {
                              // @ts-ignore
                              "h-2.5 w-2.5": indicator === "dot",
                              // @ts-ignore
                              "w-1": indicator === "line",
                              "w-0 border-[1.5px] border-dashed bg-transparent":
                                // @ts-ignore
                                indicator === "dashed",
                              // @ts-ignore
                              "my-0.5": nestLabel && indicator === "dashed",
                            }
                          )}
                          style={
                            {
                              // @ts-ignore
                              "--color-bg": indicatorColor,
                              "--color-border": indicatorColor,
                            // @ts-ignore
                            }
                          }
                        />
                      )
                    )}
                    <div
                      className={classNames(
                        "flex flex-1 justify-between leading-none",
                        // @ts-ignore
                        nestLabel ? "items-end" : "items-center"
                      )}
                    >
                      <div className="grid gap-1.5">
                        {
// @ts-ignore
                        nestLabel ? tooltipLabel : null}
                        <span className="text-muted-foreground">
                          {itemConfig?.label || item.name}
                        </span>
                      </div>
                      {item.value && (
                        <span className="font-mono font-medium tabular-nums text-foreground">
                          {item.value.toLocaleString()}
                        </span>
                      )}
                    </div>
                  </>
                )}
              </div>
            )
          })}
        </div>
      </div>
    )
  }
)
ChartTooltipContent.displayName = "ChartTooltip"

const ChartLegend = RechartsPrimitive.Legend

const ChartLegendContent = forwardRef(
  (
    { 
// @ts-ignore
    className, hideIcon = false, payload, verticalAlign = "bottom", nameKey },
    ref
  ) => {
    const { 
// @ts-ignore
    config } = useChart()

    if (!payload?.length) {
      return null
    }

    return (
      <div
        // @ts-ignore
        ref={ref}
        className={classNames(
          "flex items-center justify-center gap-4",
          // @ts-ignore
          verticalAlign === "top" ? "pb-3" : "pt-3",
          // @ts-ignore
          className
        )}
      >
        {
// @ts-ignore
        payload.map((item) => {
          // @ts-ignore
          const key = `${nameKey || item.dataKey || "value"}`
          // @ts-ignore
          const itemConfig = getPayloadConfigFromPayload(config, item, key)

          return (
            <div
              key={item.value}
              className={classNames(
                "flex items-center gap-1.5 [&>svg]:h-3 [&>svg]:w-3 [&>svg]:text-muted-foreground"
              )}
            >
              {itemConfig?.icon && !
// @ts-ignore
              hideIcon ? (
                <itemConfig.icon />
              ) : (
                <div
                  className="h-2 w-2 shrink-0 rounded-[2px]"
                  style={{
                    backgroundColor: item.color,
                  }}
                />
              )}
              {itemConfig?.label}
            </div>
          )
        })}
      </div>
    )
  }
)
ChartLegendContent.displayName = "ChartLegend"

// Helper to extract item config from a payload.
function getPayloadConfigFromPayload(
  config,
  payload,
  key
) {
  if (typeof payload !== "object" || payload === null) {
    return undefined
  }

  const payloadPayload =
    "payload" in payload &&
    typeof payload.payload === "object" &&
    payload.payload !== null
      ? payload.payload
      : undefined

  let configLabelKey = key

  if (
    key in payload &&
    typeof payload[key ] === "string"
  ) {
    // @ts-ignore
    configLabelKey = payload[key]
  } else if (
    payloadPayload &&
    key in payloadPayload &&
    typeof payloadPayload[key] === "string"
  ) {
    // @ts-ignore
    configLabelKey = payloadPayload[
      // @ts-ignore
      key
    // @ts-ignore
    ]
  }

  return configLabelKey in config
    ? config[configLabelKey]
    : config[key]
}

export {
  // @ts-ignore
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
  // @ts-ignore
  ChartLegend,
  // @ts-ignore
  ChartLegendContent,
  ChartStyle,
// @ts-ignore
}// @ts-ignore

