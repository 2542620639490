import React, { useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import propTypes from 'prop-types'

import {
  AdminPageWrapper,
  ControlledTableColumn,
  PageHeader,
  TCancelButton,
  TSaveButton
} from 'components'
import { Modal, Table, Tabs, TSwitch } from 'ui'

import { changeUserSelectedTab } from 'store/actions/appActions'
import { deleteDeveloper, getDevelopers } from 'store/actions/developersActions'
import { deleteUser, getUsers } from 'store/actions/usersActions'
import { useHistory } from 'react-router-dom'
import { ADMIN_CREATE_EDIT_USER, ADMIN_CREATE_EDIT_DEVELOPER } from 'routes'
import { getUserType } from 'utils'
import { CardsContainer } from 'components/ContactCard'

const salesRepColumns = [
  {
    Title: 'Name',
    accessor: 'name',
    Cell: ({
      row: {
        original: { firstName, lastName }
      }
    }) => `${firstName} ${lastName}`
  },
  {
    Title: 'Email',
    accessor: 'email'
  },
  {
    Title: 'Developer Company',
    accessor: 'developerCompany',
    Cell: (props) => {
      const {
        row: {
          original: { salesRepData }
        }
      } = props

      let developerCompanyName = ''

      if (Object.keys(salesRepData).length) {
        if (salesRepData.developerCompany) {
          developerCompanyName = salesRepData.developerCompany.companyName
        }
      }

      return developerCompanyName
    }
  }
]

const coopBrokerColumns = [
  {
    Title: 'Name',
    accessor: 'name',
    Cell: ({
      row: {
        original: { firstName, lastName }
      }
    }) => `${firstName} ${lastName}`
  },
  {
    Title: 'Email',
    accessor: 'email'
  },
  {
    Title: 'Licensed with Company',
    accessor: 'licensedWithCompany',
    Cell: (props) => {
      const {
        row: {
          original: { brokerData }
        }
      } = props

      let developerCompanyName = ''

      if (Object.keys(brokerData).length) {
        if (brokerData.developerCompany.length) {
          const developerCompanyArray = [...brokerData.developerCompany]

          developerCompanyName = developerCompanyArray.reduce(
            (acumulator, developerCompany, index) => {
              return (
                acumulator +
                `${developerCompany.companyName}${
                  index < developerCompanyArray.length - 1 ? ', ' : ''
                }`
              )
            },
            ''
          )
        }
      }

      return <ControlledTableColumn>{developerCompanyName}</ControlledTableColumn>
    }
  }
]

const buyerColumns = [
  {
    Title: 'Name',
    accessor: 'name',
    Cell: ({
      row: {
        original: { firstName, lastName }
      }
    }) => `${firstName} ${lastName}`
  },
  {
    Title: 'Email',
    accessor: 'email'
  },
  {
    Title: 'Location',
    accessor: 'location',
    Cell: (props) => {
      const {
        row: {
          original: { buyerData }
        }
      } = props

      let location = ''

      if (buyerData && Object.keys(buyerData).length) {
        let { city, province, country } = buyerData

        city = city ? `${city} - ` : ''
        province = province ? `${province} - ` : ''
        country = country ? `${country}` : ''

        location = `${city}${province}${country}`
      }

      return <ControlledTableColumn className='max-w-xxs'>{location}</ControlledTableColumn>
    }
  },
  {
    Title: 'Mobile number',
    accessor: 'mobileNumber',
    Cell: (props) => {
      const {
        row: {
          original: { buyerData }
        }
      } = props

      let number = ''

      if (buyerData && Object.keys(buyerData).length) {
        const { mobileNumber } = buyerData
        number = mobileNumber || ''
      }

      return number
    }
  }
]

const superAdminColumns = [
  {
    Title: 'Name',
    accessor: 'name',
    Cell: ({
      row: {
        original: { firstName, lastName }
      }
    }) => `${firstName} ${lastName}`
  },
  {
    Title: 'Email',
    accessor: 'email'
  },
  {
    Title: 'Phone number',
    accessor: 'phoneNumber'
  }
]

const developersColumns = [
  {
    Title: 'Company Name',
    accessor: 'companyName'
  },
  {
    Title: 'City',
    accessor: 'city'
  },
  {
    Title: 'Province',
    accessor: 'province'
  },
  {
    Title: 'Country',
    accessor: 'country'
  }
]

const developerAdminColumns = [
  {
    Title: 'Name',
    accessor: 'name',
    Cell: ({
      row: {
        original: { firstName, lastName }
      }
    }) => `${firstName} ${lastName}`
  },
  {
    Title: 'Email',
    accessor: 'email'
  },
  {
    Title: 'Developer Company',
    accessor: 'developerCompany',
    Cell: (props) => {
      const {
        row: {
          original: { developerAdminData }
        }
      } = props

      let developerCompanyName = ''

      if (Object.keys(developerAdminData).length) {
        if (developerAdminData.developerCompany) {
          developerCompanyName = developerAdminData.developerCompany.companyName
        }
      }

      return developerCompanyName
    }
  }
]

const Users = (props) => {
  const { changeUserSelectedTab, userSelectedTab, userObject } = props
  const [seeAsCards, setSeeAsCards] = useState(false)
  const [gettingData, setGettingData] = useState(true)
  const [originalDevelopersData, setOriginalDevelopersData] = useState([])
  const [developersData, setDevelopersData] = useState(originalDevelopersData)
  const [showSuperAdminTabs, setShowSuperAdminTabs] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [userIdBeingDeleted, setUserIdBeingDeleted] = useState('')
  const [userNameBeingDeleted, setUserNameBeingDeleted] = useState('')
  const [deletingUser, setDeletingUser] = useState(false)

  // Users Data
  const [originalUsersData, setOriginalUsersData] = useState([])
  const [usersData, setUsersData] = useState(originalUsersData)

  // Developer Admin Data
  const [originalSuperAdminData, setOriginalSuperAdminData] = useState([])
  const [superAdminData, setSuperAdminData] = useState(originalSuperAdminData)

  // Developer Admin Data
  const [originalDeveloperAdminData, setOriginalDeveloperAdminData] = useState([])
  const [developerAdminData, setDeveloperAdminData] = useState(originalDeveloperAdminData)

  // Sales Rep Data
  const [originalSalesRepData, setOriginalSalesRepData] = useState([])
  const [salesRepData, setSalesRepData] = useState(originalSalesRepData)

  // CoopBroker Data
  const [originalCoopBrokerData, setOriginalCoopBrokerData] = useState([])
  const [coopBrokerData, setCoopBrokerData] = useState(originalCoopBrokerData)

  // Buyer Data
  const [originalBuyerData, setOriginalBuyerData] = useState([])
  const [buyerData, setBuyerData] = useState(originalBuyerData)

  const history = useHistory()

  useEffect(() => {
    if (userObject) {
      const { userType } = userObject
      if (userType === 'SuperAdmin') {
        setShowSuperAdminTabs(true)
      }
    }
  }, [userObject])

  useEffect(() => {
    if (showSuperAdminTabs) {
      // Get Developers
      _getDevelopers()
    }
  }, [showSuperAdminTabs])

  useEffect(() => {
    // Get Users
    _getUsers()
  }, [])

  // Get developers from API
  const _getDevelopers = () => {
    setGettingData(true)
    getDevelopers()
      .then((developers) => {
        setOriginalDevelopersData(developers)
        setDevelopersData(developers)
        setGettingData(false)
      })
      .catch(() => {
        setGettingData(false)
      })
  }

  // Get users from API
  const _getUsers = () => {
    setGettingData(true)
    getUsers('')
      .then((users) => {
        setOriginalUsersData(users)
        setUsersData(users)
        setGettingData(false)
      })
      .catch(() => {
        setGettingData(false)
      })
  }

  // When users have been updated, also update each array of users types
  useEffect(() => {
    // Get the SuperAdmin users
    const newSuperAdminUsers = usersData.filter((user) => user.userType === 'SuperAdmin')
    setOriginalSuperAdminData(newSuperAdminUsers)
    setSuperAdminData(newSuperAdminUsers)

    // Get the DeveloperAdmin users
    const newDeveloperAdminUsers = usersData.filter((user) => user.userType === 'DeveloperAdmin')
    setOriginalDeveloperAdminData(newDeveloperAdminUsers)
    setDeveloperAdminData(newDeveloperAdminUsers)

    // Get the Sales Rep users
    const newSalesRepUsers = usersData.filter((user) => user.userType === 'SalesRep')
    setOriginalSalesRepData(newSalesRepUsers)
    setSalesRepData(newSalesRepUsers)

    // Get the Coop Broker users
    const newCoopBrokerUsers = usersData.filter((user) => user.userType === 'CoopBroker')
    setOriginalCoopBrokerData(newCoopBrokerUsers)
    setCoopBrokerData(newCoopBrokerUsers)

    // Get the Buyer users
    const newBuyerUsers = usersData.filter(
      (user) =>
        user.userType === 'Buyer' || user.userType === 'Lead' || user.userType === 'CorporateBuyer'
    )
    setOriginalBuyerData(newBuyerUsers)
    setBuyerData(newBuyerUsers)
  }, [usersData, originalUsersData])

  // Open the create/edition user page
  const createEditUserPage = useCallback(
    (id, userType) => {
      history.push({
        pathname: ADMIN_CREATE_EDIT_USER,
        state: {
          _id: id,
          userType
        }
      })
    },
    [history]
  )

  // Open the create/edition developer page
  const createEditDeveloperPage = useCallback(
    (_id) => {
      history.push({
        pathname: ADMIN_CREATE_EDIT_DEVELOPER,
        state: {
          _id: _id
        }
      })
    },
    [history]
  )

  const getMenus = useCallback(() => {
    let menus = [{ key: 'Buyer', title: 'Buyers' }]

    if (userObject.userType === 'DeveloperAdmin') {
      menus = menus.concat(
        { key: 'SalesRep', title: 'Sales Reps' },
        { key: 'CoopBroker', title: 'Coop Brokers' }
      )

      menus.shift()
    }

    if (showSuperAdminTabs) {
      menus = menus.concat(
        { key: 'SuperAdmin', title: 'Super Admins' },
        { key: 'Developer', title: 'Developers' },
        { key: 'DeveloperAdmin', title: 'Developer Admins' },
        { key: 'SalesRep', title: 'Sales Reps' },
        { key: 'CoopBroker', title: 'Coop Brokers' }
      )
    }

    return menus
  }, [showSuperAdminTabs, userObject])

  const openDeleteModal = (id, name) => {
    setUserIdBeingDeleted(id)
    setUserNameBeingDeleted(name)
    setShowDeleteModal(true)
  }

  const renderSalesReps = useCallback(
    () => (
      seeAsCards
        ? <CardsContainer data={salesRepData}/>
        : <Table
          loading={gettingData}
          className='rounded-lg'
          dataSource={salesRepData}
          columns={salesRepColumns}
          tailwindTable
          onViewEditClick={({ original: { id, userType } }) => createEditUserPage(id, userType)}
          onDeleteClick={({ original: { id, firstName, lastName } }) =>
            openDeleteModal(id, `${firstName} ${lastName}`)
          }
          deleting={deletingUser}
          idBeingDeleted={userIdBeingDeleted}
          />
    ),
    [createEditUserPage, salesRepData, deletingUser, userIdBeingDeleted, gettingData, seeAsCards]
  )

  const renderCoopBrokers = useCallback(
    () => (
      seeAsCards
        ? <CardsContainer data={coopBrokerData}/>
        : <Table
          loading={gettingData}
          className='rounded-lg'
          dataSource={coopBrokerData}
          columns={coopBrokerColumns}
          tailwindTable
          onViewEditClick={({ original: { id, userType } }) => createEditUserPage(id, userType)}
          onDeleteClick={({ original: { id, firstName, lastName } }) =>
            openDeleteModal(id, `${firstName} ${lastName}`)
          }
          deleting={deletingUser}
          idBeingDeleted={userIdBeingDeleted}
        />
    ),
    [coopBrokerData, createEditUserPage, deletingUser, userIdBeingDeleted, gettingData, seeAsCards]
  )

  const renderBuyers = useCallback(
    () => (
      seeAsCards
        ? <CardsContainer data={buyerData}/>
        : <Table
          loading={gettingData}
          className='rounded-lg'
          dataSource={buyerData}
          columns={buyerColumns}
          tailwindTable
          onViewEditClick={({ original: { id, userType } }) => createEditUserPage(id, userType)}
          onDeleteClick={({ original: { id, firstName, lastName } }) =>
            openDeleteModal(id, `${firstName} ${lastName}`)
          }
          deleting={deletingUser}
          idBeingDeleted={userIdBeingDeleted}
          />
    ),
    [buyerData, createEditUserPage, deletingUser, userIdBeingDeleted, gettingData, seeAsCards]
  )

  const renderSuperAdmins = useCallback(
    () =>
      seeAsCards
        ? (<CardsContainer data={superAdminData}/>)
        : (<Table
              loading={gettingData}
              className='rounded-lg'
              dataSource={superAdminData}
              columns={superAdminColumns}
              tailwindTable
              onViewEditClick={({ original: { id, userType } }) => createEditUserPage(id, userType)}
              onDeleteClick={({ original: { id, firstName, lastName } }) =>
                openDeleteModal(id, `${firstName} ${lastName}`)
              }
              deleting={deletingUser}
              idBeingDeleted={userIdBeingDeleted}
            />),
    [createEditUserPage, superAdminData, deletingUser, userIdBeingDeleted, gettingData, seeAsCards]
  )

  const renderDevelopers = useCallback(
    () => (
      seeAsCards
        ? <CardsContainer data={developersData}/>
        : <Table
            loading={gettingData}
            className='rounded-lg'
            dataSource={developersData}
            columns={developersColumns}
            tailwindTable
            onViewEditClick={({ original: { _id } }) => createEditDeveloperPage(_id)}
            onDeleteClick={({ original: { _id, companyName } }) => openDeleteModal(_id, companyName)}
            deleting={deletingUser}
            idBeingDeleted={userIdBeingDeleted}
          />
    ),
    [developersData, createEditDeveloperPage, deletingUser, userIdBeingDeleted, gettingData, seeAsCards]
  )

  const renderDeveloperAdmins = useCallback(
    () => (
      seeAsCards
        ? <CardsContainer data={developerAdminData}/>
        : <Table
          loading={gettingData}
          className='rounded-lg'
          dataSource={developerAdminData}
          columns={developerAdminColumns}
          tailwindTable
          onViewEditClick={({ original: { id, userType } }) => createEditUserPage(id, userType)}
          onDeleteClick={({ original: { id, firstName, lastName } }) =>
            openDeleteModal(id, `${firstName} ${lastName}`)
          }
          deleting={deletingUser}
          idBeingDeleted={userIdBeingDeleted}
          />
    ),
    [createEditUserPage, developerAdminData, deletingUser, userIdBeingDeleted, gettingData, seeAsCards]
  )

  const renderRightContent = useCallback(
    () => (
      <div className='flex flex-col items-end'>
        <div className='flex flex-row'>
          <TCancelButton onClick={() => history.goBack()}>Back</TCancelButton>
          <TSaveButton
            onClick={() => {
              if (userSelectedTab === 'Developer') {
                createEditDeveloperPage(0)
              } else {
                createEditUserPage(0, userSelectedTab)
              }
            }}
            className='ml-3'
          >
            {`Create new ${getUserType(userSelectedTab)}`}
          </TSaveButton>
        </div>
        <div className='mt-4'>
          <TSwitch
            title='Cards View'
            checked={seeAsCards}
            onClick={() => setSeeAsCards(!seeAsCards)}
          />
        </div>
      </div>
    ),
    [history, seeAsCards, createEditUserPage, userSelectedTab, createEditDeveloperPage]
  )

  const renderTabs = useCallback(() => {
    return (
      <Tabs
        menus={getMenus()}
        tabSelected={userSelectedTab}
        onClick={(tab) => changeUserSelectedTab(tab)}
      />
    )
  }, [getMenus, userSelectedTab, changeUserSelectedTab])

  const renderTabsContent = useCallback(() => {
    return (
      <div className='mt-7'>
        {userSelectedTab === 'SalesRep' && renderSalesReps()}
        {userSelectedTab === 'CoopBroker' && renderCoopBrokers()}
        {userSelectedTab === 'Buyer' && renderBuyers()}
        {userSelectedTab === 'SuperAdmin' && renderSuperAdmins()}
        {userSelectedTab === 'Developer' && renderDevelopers()}
        {userSelectedTab === 'DeveloperAdmin' && renderDeveloperAdmins()}
      </div>
    )
  }, [
    renderBuyers,
    renderCoopBrokers,
    renderDeveloperAdmins,
    renderDevelopers,
    renderSalesReps,
    renderSuperAdmins,
    userSelectedTab
  ])

  const _deleteUser = useCallback(() => {
    setShowDeleteModal(false)
    setDeletingUser(true)

    let deleteFunction = deleteUser
    let executeAfterDelete = _getUsers

    if (userSelectedTab === 'Developer') {
      deleteFunction = deleteDeveloper
      executeAfterDelete = _getDevelopers
    }

    deleteFunction(userIdBeingDeleted)
      .then(() => {
        setDeletingUser(false)
        executeAfterDelete()
      })
      .catch(() => setDeletingUser(false))
  }, [userIdBeingDeleted, userSelectedTab])

  const renderDeleteModal = useCallback(
    () => (
      <Modal
        showModal={showDeleteModal}
        onCancel={() => {
          setShowDeleteModal(false)
          setTimeout(() => {
            setUserNameBeingDeleted('')
            setUserIdBeingDeleted('')
          }, 150)
        }}
        modalType='danger'
        title='Confirm deletion?'
        subtitle={`${userNameBeingDeleted} will be deleted!`}
        onOk={_deleteUser}
      />
    ),
    [showDeleteModal, userNameBeingDeleted, _deleteUser]
  )

  return (
    <>
      {renderDeleteModal()}
      <PageHeader title='User Management' rightContent={renderRightContent()} />
      <AdminPageWrapper>
        <div className='py-4 pl-2 pr-2 md:pl-10 md:pr-10 bg-white rounded-md'>
          {renderTabs()}
          {renderTabsContent()}
        </div>
      </AdminPageWrapper>
    </>
  )
}

const mapStateToProps = (state) => ({
  userSelectedTab: state.appReducer.userSelectedTab,
  userObject: state.authReducer.userObject
})

const mapDispatchToProps = {
  changeUserSelectedTab
}

export default connect(mapStateToProps, mapDispatchToProps)(Users)

Users.propTypes = {
  userSelectedTab: propTypes.string,
  changeUserSelectedTab: propTypes.func,
  userObject: propTypes.object
}
