/* eslint-disable */
import React, {
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { Popover } from '@headlessui/react'
import { usePopper } from 'react-popper'
import { cadFormatter, formatMoney } from 'utils/formatters'
import { AVAILABILITY_BG_COLOR, AVAILABILITY_COLOR } from 'utils'
import InventoryContext from 'pages/desktopMode/inventory/Allocation/AllocateInventory/Context/InventoryContext'
import { Tooltip } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { ADMIN_OFFER } from 'routes'
import { getUnitEventEmitter } from 'hooks/useRealtimeUnitUpdate'
import AddParkingModal from 'pages/desktopMode/buildings/components/AddParkingModal'

const getPopperAttributes = (popperAttributes, isOnRightSide) => {
  return {
    ...popperAttributes,
    'data-popper-placement': `${popperAttributes?.['data-popper-placement']}-${isOnRightSide ? 'end' : 'start'
      }`
  }
}

const statusColor = {
  available: AVAILABILITY_COLOR.available,
  assigned: AVAILABILITY_COLOR.reserved,
  reserved: AVAILABILITY_COLOR.undercontract,
  sold: AVAILABILITY_COLOR.sold,
  allocated: AVAILABILITY_COLOR.sold,
  rescission: AVAILABILITY_COLOR.developerHeld
}


const getPopperStyles = (popperStyles, isOnRightSide) => {
  if (isOnRightSide) {
    popperStyles.right = '0'
  } else {
    popperStyles.left = '0'
  }
  return { ...popperStyles }
}

const HorizontalCardUnit = ({
  parkingName = '',
  price = 0,
  priceSquareFoot = null,
  tag = '',
  leftButtonLabel = '',
  onLeftButtonClick = () => { },
  rightButtonLabel = '',
  onRightButtonClick = () => { },
  showActionButtonOnHover = true,
  isPublicPage = false
}) => {
  return (
    <div>
      <div className='flex p-4'>
        <div className='ml-4 flex flex-col text-softBlack'>
          <div
            className={
              'font-medium text-xs px-1 py-[4.5px] w-[171px] h-[27px] uppercase'
            }
            style={{
              backgroundColor: AVAILABILITY_BG_COLOR[tag.toLowerCase()]
            }}
          >
            {tag}
          </div>
          <div className='font-bold text-2xl mt-2'>{parkingName}</div>
          <div className='font-medium text-sm mt-[9px]'>
            {formatMoney(price)}
            {priceSquareFoot
              ? ` / ${cadFormatter.format(priceSquareFoot)} SQ. FT`
              : ''}
          </div>
        </div>
      </div>
      {!isPublicPage && (
        <div className='flex justify-end w-full space-x-4 font-medium mb-4 px-4'>
          {leftButtonLabel.length ? (
            <button
              onClick={onLeftButtonClick}
              className='w-40 h-12 rounded border border-softBlack70'
            >
              {leftButtonLabel}
            </button>
          ) : null}
          {showActionButtonOnHover &&
            !tag?.toLowerCase()?.includes('sold') &&
            rightButtonLabel.length ? (
            <button
              onClick={onRightButtonClick}
              className='w-32 h-12 rounded bg-volt'
            >
              {rightButtonLabel}
            </button>
          ) : null}
        </div>
      )}
    </div>
  )
}

const ParkingItem = ({
  parking,
  price,
  isDisabled,
  isOnRightSide,
  type,
  onSelect,
  showActionButtonOnHover = true,
  isAllocateInventory = false,
  isPublicPage = false,
  parkingsRefetch
}) => {
  const [parkingData, setParkingData] = useState(parking)
  const [referenceElement, setReferenceElement] = useState()
  const [popperElement, setPopperElement] = useState()
  const { styles, attributes } = usePopper(referenceElement, popperElement)
  const isStatic = { static: isAllocateInventory ? true : false }
  const [isSelected, setIsSelected] = useState(false)
  const selectedParkings = useContext(InventoryContext)
  const [showParkingEditModal, setShowParkingEditModal] = useState(false)
  const history = useHistory()

  useEffect(() => handleSelect(parkingData, selectedParkings), [selectedParkings])

  useEffect(() => {
    setParkingData({ ...parking })
  }, [parking])

  const handleSelect = (parking, selectedParkingsTemp) => {
    if (isAllocateInventory) {
      let parkingFound = false
      if (selectedParkingsTemp.length > 0) {
        for (let i = 0; i < selectedParkingsTemp.length; i++) {
          const parking2 = selectedParkingsTemp[i]
          if (parking.id === parking2.id) {
            setIsSelected(true)
            parkingFound = true
            break
          }
        }
      }
      if (!parkingFound) setIsSelected(false)
    }
  }

  const onEditParkingClicked = useCallback((parking) => {
    setShowParkingEditModal(true)
  }, [])

  const onParkingRealtimeUpdate = useCallback(
    (newData) => {
      if (parkingData._id === newData.id || parkingData._id === newData._id) {
        setParkingData({ ...newData })
      }
    },
    [parkingData]
  )

  useEffect(() => {
    getUnitEventEmitter()?.addListener('parking-update', onParkingRealtimeUpdate)
    return () => {
      getUnitEventEmitter()?.removeListener('parking-update', onParkingRealtimeUpdate)
    }
  }, [])

  const buttonContent = (parking) => (
    <span
      className={`text-sm ${isDisabled ? 'text-softBlack30' : 'text-softBlack'
        }`}
    >
      <span className='font-medium'>{`#${parking?.stall ?? ''}`}</span>
      <br />
      <span className='font-normal'>{formatMoney(price)}</span>
    </span>
  )

  return (
    <>
      {showParkingEditModal && (
        <AddParkingModal
          show={showParkingEditModal}
          setShow={setShowParkingEditModal}
          editDetails={{ ...parkingData, type: 'Parking' }}
          setEditDetails={() => { }}
          refetch={() => {
            parkingsRefetch?.()
          }}
          building={parkingData?.building?._id ?? null}
        />
      )}
      <Popover
        className={'group h-[59px] flex-1'}
        style={{
          backgroundColor:
            isDisabled || (isAllocateInventory && parkingData.status !== 'Open')
              ? '#F2F2F1'
              : statusColor[parkingData?.status?.toLowerCase()]
        }}
      >
        <Popover.Button
          // @ts-ignore
          ref={setReferenceElement}
          className={`flex w-full h-full items-center justify-center text-center ${isSelected && 'border-2 rounded border-solid border-softBlack'
            }`}
          disabled={
            !isAllocateInventory
              ? isDisabled
              : parkingData.status === 'Open'
                ? false
                : true
          }
          onClick={() => {
            return type === 'Parking' && onSelect(parkingData)
          }}
        >
          {isAllocateInventory && parkingData.status !== 'Open' ? (
            <Tooltip
              title={
                <h1
                  style={{
                    color: 'rgba(255, 255, 255, 1)',
                    fontSize: 14,
                    paddingTop: 4
                  }}
                >
                  This unit is already reserved by another buyer
                </h1>
              }
              arrow
              placement='bottom'
            >
              {buttonContent(parkingData)}
            </Tooltip>
          ) : (
            buttonContent(parkingData)
          )}
        </Popover.Button>
        {/* {!isAllocateInventory && (
          <Popover.Panel
            {...isStatic}
            className='absolute z-10 mt-1 top-full max-w-[490.3px] rounded bg-[#FCFCFA] shadow'
            // @ts-ignore
            ref={setPopperElement}
            style={getPopperStyles(styles.popper, isOnRightSide)}
            {...getPopperAttributes(attributes.popper, isOnRightSide)}
          >
            <HorizontalCardUnit
              parkingName={`Parking #${parkingData?.stall}`}
              price={parkingData?.price}
              tag={parkingData?.status}
              leftButtonLabel={'Edit'}
              onLeftButtonClick={() => {
                onEditParkingClicked(parkingData)
              }}
              rightButtonLabel='Purchase'
              onRightButtonClick={() => {
                console.log('On right button Clicked')
                history.push(ADMIN_OFFER)
              }}
              showActionButtonOnHover={showActionButtonOnHover}
              isPublicPage={isPublicPage}
            />
          </Popover.Panel>
        )} */}
      </Popover>
    </>
  )
}

export default ParkingItem
