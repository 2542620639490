import React, {
  useState, useEffect,
  useCallback
} from 'react'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
import { InlineInput, Tabs } from 'ui'
import { FormTabsWrapper, FormWrapper, ImageUpload } from 'components'
import { getProjects } from 'store/actions/projectActions'

import { validateRequiredInputs } from 'utils'
import { TSaveButton } from 'components/DefaultButtons'
import { createOrUpdateFloorPlan, getFloorPlans } from 'store/actions/floorPlanActions'
import { ImageIcon } from 'components/Icons'

const TabHeader = ({ title, subtitle }) => (
  <div className='space-y-1'>
    <h3 className='text-lg leading-6 font-medium text-gray-900'>
      {title && title}
    </h3>
    <p className='max-w-2xl text-sm text-gray-500'>
      {subtitle && subtitle}
    </p>
  </div>
)

const TabContent = ({ children }) => (
  <div className='mt-6'>
    <dl className='divide-y divide-gray-200'>
      {children}
    </dl>
  </div>
)

const FloorPlanData = (props) => {
  const {
    _id,
    userObject: {
      userType: loggedUserType
    }
  } = props

  const [name, setName] = useState('')
  const [floorPlanImage, setFloorPlanImage] = useState('')
  const [fileList, setFileList] = useState([])
  const [parkingQty, setParkingQty] = useState('')
  const [parkingType, setParkingType] = useState('')

  const [project, setProject] = useState([])
  const [projectsData, setProjectsData] = useState([])
  const [gettingProjects, setGettingProjects] = useState(false)
  const [gettingData, setGettingData] = useState(false)

  const [selectedTab, setSelectedTab] = useState('general')

  const [saving, setSaving] = useState(false)

  // When mounted, get the list of projects to fill Project Select
  useEffect(() => {
    setGettingProjects(true)
    getProjects('')
      .then((projects) => {
        const tmpProjects = projects.map((project) => ({
          id: project._id, value: project.projectName
        }))

        setProjectsData(tmpProjects)
        setGettingProjects(false)
      })
      .catch(() => {
        setGettingProjects(false)
      })
  }, [])

  const getFloorPlanData = useCallback(() => {
    if (_id) {
      setGettingData(true)

      // Get project data from id
      getFloorPlans(_id)
        .then((floorPlan) => {
          const {
            _id, name, image,
            parkingQty, parkingType,
            project
          } = floorPlan

          const tmpFileList = [{
            uid: _id,
            name: `image${_id}`,
            status: 'done',
            url: image
          }]

          setFileList(tmpFileList)

          setName(name)
          setFloorPlanImage(image)
          setParkingQty(parkingQty)
          setParkingType(parkingType)
          setProject(project)
          setGettingData(false)
        })
    } else {
      setGettingData(false)
    }
  }, [_id])

  useEffect(() => {
    getFloorPlanData()
  }, [getFloorPlanData])

  const _onSaveClick = useCallback(() => new Promise((resolve, reject) => {
    validateRequiredInputs('createFloorPlan')
      .then(() => {
        setSaving(true)

        const params = {
          _id,
          name,
          floorPlanImage,
          parkingQty,
          parkingType,
          project
        }

        createOrUpdateFloorPlan(params)
          .then(() => {
            setSaving(false)
            resolve()
          })
          .catch(() => {
            setSaving(false)
            getFloorPlanData()
            reject()
          })
      })
  }), [
    _id, floorPlanImage, getFloorPlanData, name,
    parkingQty, parkingType, project
  ])

  // If changed the image, call the update
  useEffect(() => {
    if (_id && typeof floorPlanImage === 'object') {
      _onSaveClick()
    }
  }, [_id, floorPlanImage, _onSaveClick])

  const renderGeneralTab = useCallback(() => (
    <div className={`${selectedTab !== 'general' && 'sr-only'}`}>
      <TabHeader
        title='General'
        subtitle='Here are all infos related to the floor plan'
      />
      <TabContent>
        <InlineInput
          id='name'
          key='name'
          type='text'
          label='Name'
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder={'the floor plan\'s name'}
          required
          validateKey='createFloorPlan'
          loading={gettingData}
          onClickUpdate={_id ? _onSaveClick : null}
        />
        <InlineInput
          id='parkingQty'
          key='parkingQty'
          type='number'
          label='Parking slots quantity'
          value={parkingQty}
          onChange={(e) => setParkingQty(e.target.value)}
          placeholder='how many parking slots are included?'
          required
          validateKey='createFloorPlan'
          loading={gettingData}
          onClickUpdate={_id ? _onSaveClick : null}
        />
        <InlineInput
          id='parkingType'
          key='parkingType'
          type='text'
          label='Parking slot type'
          value={parkingType}
          onChange={(e) => setParkingType(e.target.value)}
          placeholder='type of parking slot'
          required
          validateKey='createFloorPlan'
          loading={gettingData}
          onClickUpdate={_id ? _onSaveClick : null}
        />
        <InlineInput
          id='project'
          key='project'
          type='text'
          label='Project'
          value={project}
          typeInput='select'
          data={projectsData}
          onChange={(project) => setProject(project)}
          placeholder={'floor plan\'s project'}
          required
          validateKey='createFloorPlan'
          loading={gettingData || gettingProjects}
          onClickUpdate={_id ? _onSaveClick : null}
        />
      </TabContent>
    </div>
  ), [
    _id, _onSaveClick, gettingData, gettingProjects,
    name, parkingQty, parkingType, project, projectsData,
    selectedTab
  ])

  const renderFloorPlanImageTab = useCallback(() => (
    <div className={`${selectedTab !== 'floorPlanImage' && 'sr-only'}`}>
      <TabHeader
        title='Image'
        subtitle='Click at the area below or drag an image here to update the floor plan image'
      />
      <TabContent>
        <ImageUpload
          uploadProps={{
            name: 'imageUpload',
            listType: 'picture-card',
            className: 'avatar-uploader',
            showUploadList: fileList.length > 0,
            fileList: fileList,
            onChange: ({ file, fileList }) => {
              setFileList(fileList)
            },
            beforeUpload: (file) => {
              setFloorPlanImage(file)

              return false
            },
            style: { height: '100%', width: '100%' }
          }}
          title='Floor Plan Image'
          uploadButtonText='Click or drag here the Floor Plan Image'
          uploadButtonIcon={<ImageIcon style={{ fontSize: 30 }} />}
          uploadWrapperStyle={{ width: '100%', marginRight: 0 }}
          // images={}
          showUploadButton={fileList.length === 0}
        />
      </TabContent>
    </div>
  ), [selectedTab, fileList])

  const renderIdTab = useCallback(() => (
    <div className={`${selectedTab !== 'id' && 'sr-only'}`}>
      <TabHeader
        title='ID'
        subtitle='This tab is only available to Super Admins'
      />
      <TabContent>
        <InlineInput
          key='floorPlanId'
          label='Floor Plan ID'
          value={_id}
          onChange={(e) => {}}
          placeholder='floor plan ID'
          loading={gettingData}
          disabled
        />
      </TabContent>
    </div>
  ), [gettingData, _id, selectedTab])

  const getMenus = useCallback(() => {
    const menus = [
      { key: 'general', title: 'General' },
      { key: 'floorPlanImage', title: 'Floor Plan Image' }
    ]

    if (loggedUserType === 'SuperAdmin' && Boolean(_id)) {
      menus.push(
        { key: 'id', title: 'ID' }
      )
    }

    return menus
  }, [loggedUserType, _id])

  return (
    <FormWrapper>
      <FormTabsWrapper>
        <Tabs
          menus={getMenus()}
          tabSelected={selectedTab}
          onClick={(tab) => setSelectedTab(tab)}
        />
        {
          !_id && (
            <TSaveButton
              loading={saving}
              className='ml-3'
              onClick={_onSaveClick}
            >
              Save floor plan
            </TSaveButton>
          )
        }
      </FormTabsWrapper>

      <div className='mt-7'>
        {renderGeneralTab()}
        {renderFloorPlanImageTab()}
        {renderIdTab()}
     </div>
    </FormWrapper>
  )
}

const mapStateToProps = (state) => ({
  userObject: state.authReducer.userObject
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(FloorPlanData)

FloorPlanData.propTypes = {
  _id: propTypes.string,
  userObject: propTypes.shape({
    userType: propTypes.string
  })
}

TabHeader.propTypes = {
  title: propTypes.string,
  subtitle: propTypes.string
}

TabContent.propTypes = {
  children: propTypes.node
}
