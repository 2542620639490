import React from 'react'
import { ReactComponent as DownIcon } from 'assets/icons/down.svg'
import { Select } from 'antd'
import { isMobile } from 'react-device-detect'

const RatingSelect = ({
  rating,
  handleRatingChange,
  ratings,
  marginTop = '',
  forceMobile = false
}) => (
  <div
    style={{
      position: 'relative',
      overflow: 'visible',
      marginLeft: isMobile || forceMobile ? '' : '40px',
      marginTop
    }}
  >
    <div
      className='text-softBlack70'
      style={{
        position: 'absolute',
        width: '100%',
        fontSize: '18px',
        fontWeight: '300',
        top: isMobile || forceMobile ? '-25px' : '-20px',
        left: '10px'
      }}
    >
      Rating
    </div>
    <Select
      suffixIcon={<DownIcon />}
      showArrow
      value={rating}
      onChange={handleRatingChange}
      className='rating-select text-sm bg-white border border-softBlack30 h-8 w-48 rounded text-softBlack placeholder-softBlack70 py-1'
    >
      {ratings?.map((_item, index) => (
        <Select.Option value={_item.value} key={index}>
          <div className='flex items-center'>
            {/* <div
            className='min-w-4 min-h-4 w-4 h-4 rounded-full'
            // style={{ backgroundColor: _item?.color }}
            /> */}
            <span>{_item.label}</span>
          </div>
        </Select.Option>
      ))}
    </Select>
  </div>
)

export default RatingSelect
