import React from 'react'

const DeleteIcon = ({ height = 11, width = 12 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_424_1050)">
            <path d="M2.7502 9.20833C2.7502 9.7125 3.1627 10.125 3.66687 10.125H7.33354C7.8377 10.125 8.2502 9.7125 8.2502 9.20833V3.70833H2.7502V9.20833ZM8.70854 2.33333H7.10437L6.64604 1.875H4.35437L3.89604 2.33333H2.29187V3.25H8.70854V2.33333Z" fill="#2E2B2E"/>
        </g>
        <defs>
        <clipPath id="clip0_424_1050">
            <rect width="11" height="11" fill="white" transform="translate(0 0.5)"/>
        </clipPath>
        </defs>
    </svg>
  )
}

export default DeleteIcon
