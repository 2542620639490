import {
  CHANGE_USER_SELECTED_TAB,
  CHANGE_PROJECT_MANAGEMENT_SELECTED_TAB,
  CHANGE_PINNED_HEADER,
  CHANGE_APP_PROJECT,
  UPDATE_LIST_OF_PROJECTS,
  SHOW_HIDE_SIDEBAR
} from './actionTypes'

export const changeUserSelectedTab = (tab) => dispatch => {
  dispatch({
    type: CHANGE_USER_SELECTED_TAB,
    payload: tab
  })
}

export const changeProjectManagementSelectedTab = (tab) => dispatch => {
  dispatch({
    type: CHANGE_PROJECT_MANAGEMENT_SELECTED_TAB,
    payload: tab
  })
}

export const changePinnedHeader = (pinned) => dispatch => {
  dispatch({
    type: CHANGE_PINNED_HEADER,
    payload: pinned
  })
}

export const changeAppProject = ({ projectId, developerId, newProject }) => dispatch => {
  dispatch({
    type: CHANGE_APP_PROJECT,
    payload: { projectId, developerId, newProject }
  })
}

export const updateListOfProjects = (listOfProjects) => dispatch => {
  dispatch({
    type: UPDATE_LIST_OF_PROJECTS,
    payload: listOfProjects
  })
}

export const displaySidebar = (show) => dispatch => {
  dispatch({
    type: SHOW_HIDE_SIDEBAR,
    payload: show
  })
}
