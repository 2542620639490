import React from 'react'
import propTypes from 'prop-types'
import { ProjectMap } from 'components'
// import { TFilledPlusIcon } from 'components/Icons'
// import { useHistory } from 'react-router-dom'
// import { ADMIN_OPPORTUNITY } from 'routes'
import { connect } from 'react-redux'
import ProjectMapContextProvider from 'components/ProjectMap/context/ProjectMapContext'
// import { isMobile } from 'react-device-detect'

const Button = (props) => {
  return (
    <button
      className='text-softBlack text-center text-base font-medium py-1.5 flex items-center'
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  )
}

const ProjectFilesWrapper = ({ appProject }) => {
  return (
    <ProjectMapContextProvider>
     {/* <div className='flex'> */}
      {/* <AdminPageWrapper style={{ height: 'calc(100vh - 100px)' }} fullscreen> */}
        <ProjectMap />
      {/* </AdminPageWrapper> */}
      {/* </div> */}
    </ProjectMapContextProvider>
  )
}

const mapStateToProps = (state) => ({
  appProject: state.appReducer.appProject
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectFilesWrapper)

ProjectFilesWrapper.propTypes = {
  appProject: propTypes.string
}

Button.propTypes = {
  disabled: propTypes.bool,
  onClick: propTypes.func,
  children: propTypes.node
}
