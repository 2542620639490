/* eslint multiline-ternary: ["error", "never"] */
// @ts-nocheck
/* eslint-disable */
import { api, ApiErrors } from 'services/api';

export const getUserActivityByProject = (_id = '', skip = 0, limit = 0) =>
  new Promise((resolve, reject) => {
    api.get(`useractivity/project/${_id}/${skip}/${limit}`).then((response) => {
      if (response.ok) {
        resolve(response.data.data);
      } else {
        ApiErrors(response);
        reject();
      }
    });
  });

  export const getUserActivityAll = (skip = 0, limit = 0) =>
  new Promise((resolve, reject) => {
    api.get(`useractivity`).then((response) => {
      if (response.ok) {
        resolve(response.data.data);
      } else {
        ApiErrors(response);
        reject();
      }
    });
  });

export const getUserActivityAllByGroup = (type = 'today') =>
  new Promise((resolve, reject) => {
    api.get(`useractivity/group?type=${type}`).then((response) => {
      if (response.ok) {
        resolve(response.data);
      } else {
        ApiErrors(response);
        reject();
      }
    });
  });

export const getUserActivityBySalesRepByGroup = (type = 'today', id) =>
  new Promise((resolve, reject) => {
    api.get(`useractivity/group/salesrep/${id}?type=${type}`).then((response) => {
      if (response.ok) {
        resolve(response.data);
      } else {
        ApiErrors(response);
        reject();
      }
    });
  });

