// @ts-nocheck
/* eslint-disable */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { getConversationMessages, sendConversationMessage } from 'store/actions/usersActions'
import { subscribeToChannel, unsubscribeToChannel, bindChannel, unbindChannel } from '../../services/pusher'
import { ReactComponent as LinkIcon } from '../../assets/icons/icn_link.svg'
import moment from 'moment'
import { Send } from 'lucide-react'
import { isMobile } from 'react-device-detect'
import CustomButton from './CustomButton'
import { Drawer } from 'antd'
import useGetCommunicationTemplatesByType from 'hooks/useGetCommunicationTemplatesByType'
import { sanitizeCode } from 'utils'
import RichTextField from 'components/RichTextField'
import { getTextToolbarOptions } from 'components/RichTextField/config/config'
import { Oval } from 'react-loader-spinner'

const SMSTemplatesSelectionModal = ({ onClose, open, applySMSTemplate, sendMessage, isSendingSMS }) => {
  const editorRef = useRef(null)
  const { loading: isCommunicationTemplatesLoading, communicationTemplates, getCommunicationTemplates } = useGetCommunicationTemplatesByType('sms')
  const [isTemplateEditor, setIsTemplateEditor] = useState(false)
  const [message, setMessage] = useState('')

  useEffect(() => {
    getCommunicationTemplates()
  }, [])

  const handleOnClose = () => {
    onClose()
    setIsTemplateEditor(false)
    setMessage('')
  }

  const messageText = useMemo(() => {
    return editorRef.current?.getEditor?.().getText?.().trim?.()
  }, [message, isTemplateEditor])

  const handleMessageSubmit = () => {
    const tempMsg = editorRef.current?.getEditor?.().getText?.().trim?.()
    sendMessage(tempMsg)
    handleOnClose()
  }

  return (
    <Drawer size={isMobile ? 'default' : 'large'} closable={true} placement="right" onClose={handleOnClose} open={open}>
      <div className='h-full flex flex-col gap-2'>
        {
          isTemplateEditor &&
          <div>
            <div className='flex w-full justify-between'>
              <h1 className='text-2xl mb-6'>
                Template editor
              </h1>
            </div>
            <div>
              <RichTextField
                isHideToolbar={true}
                hasFileAttachments={false}
                editorRef={editorRef}
                onChangeText={(newValue) => setMessage(newValue)}
                text={message ?? ''}
                placeholder={'Type text here...'}
                height='250px'
                style={{
                  height: '250px',
                }}
                customModules={getTextToolbarOptions()}
              />
            </div>
            <div className='mt-16 flex gap-3 items-center justify-end'>
              <CustomButton
                handleClick={handleMessageSubmit}
                disabled={messageText?.length === 0 || isSendingSMS}
              >
                <span className='p-2 px-3 md:px-4 text-sm font-medium whitespace-nowrap flex items-center gap-2'>
                  {
                    isSendingSMS &&
                    <Oval height='18' width='18' color='white' />
                  }
                  Send message
                </span>
              </CustomButton>
            </div>
          </div>
        }
        <div className='flex w-full justify-between'>
          <h1 className='text-2xl mb-0'>
            SMS Templates
          </h1>
        </div>
        <div className='flex-1 flex flex-col gap-3 my-3 justify-start overflow-y-auto thin-scrollbar'>
          {
            isCommunicationTemplatesLoading
              ? <div className='flex-1 flex items-center justify-center flex-col'>
                <h1 className='text-center text-xl py-6 mb-0'>
                  SMS templates loading...
                </h1>
              </div>
              : <>
                {
                  communicationTemplates?.length > 0
                    ? <>
                      {communicationTemplates.map(template => (
                        <div key={template.id} className='flex gap-y-2 justify-start flex-col bg-zinc-50 p-3 border rounded-lg'>
                          <p className='text-lg font-bold mb-0'>{template.title}</p>
                          <div className='h-[120px] *:mb-0 mb-3 overflow-y-auto thin-scrollbar' dangerouslySetInnerHTML={{ __html: sanitizeCode(template.content) ?? '' }} />
                          <div className='w-full flex justify-end'>
                            <CustomButton
                              handleClick={() => {
                                setMessage(template.content)
                                setIsTemplateEditor(true)
                              }}
                            >
                              <span className='font-semibold px-4 py-2 text-base'>
                                Select
                              </span>
                            </CustomButton>
                          </div>
                        </div>
                      ))}
                    </>
                    : <div className='flex-1 flex items-center justify-center flex-col'>
                      <h1 className='text-center text-xl py-6 mb-0'>
                        SMS templates not found
                      </h1>
                    </div>
                }
              </>
          }
        </div>
      </div>
    </Drawer>
  )
}

const MessageConversation = ({ contact }) => {
  const [loading, setLoading] = useState(true)
  const [messages, setMessages] = useState([])
  const [msg, setMsg] = useState('')
  const [isSMSTemplatesSelectionModalOpen, setIsSMSTemplatesSelectionModalOpen] = useState(false)
  const [isSendingSMS, setIsSendingSMS] = useState(false)

  const onNewMessagesCallback = useCallback((newMessage) => {
    setMessages([...messages, { ...newMessage, msgID: newMessage.MessageSid }])
  }, [messages, setMessages])

  useEffect(() => {
    if (contact?.id) {
      getConversationMessages({ userId: contact?.id })
        .then((data) => {
          const updateData = data?.map((msg) => {
            return { ...msg, msgID: msg.sid }
          })
          setMessages([...updateData])
        })
        .catch(e => {
          console.error(e)
        }).finally(() => {
          setLoading(false)
        })
    }
  }, [contact?.id])

  useEffect(() => {
    let smsChannel = null
    if (contact?.id) {
      smsChannel = subscribeToChannel(contact?.id)
      if (smsChannel) {
        bindChannel(smsChannel, onNewMessagesCallback)
      }
    }
    return () => {
      if (contact?.id) {
        if (smsChannel) {
          unbindChannel(smsChannel, onNewMessagesCallback)
        }
        unsubscribeToChannel(contact?.id)
      }
    }
  }, [contact?.id, onNewMessagesCallback])

  const _sendMessage = (msg) => {
    setIsSendingSMS(true)
    sendConversationMessage({
      userId: contact?.id,
      message: msg
    }).then((data) => {
      setIsSendingSMS(false)
      setMessages([...messages, { ...data, msgID: data.sid }])
    }).catch((e) => {
      setIsSendingSMS(false)
      console.error('sendConversationMessage e', e)
    })
  }

  if (loading) return null

  const submitMsg = () => {
    _sendMessage(msg)
    setMsg('')
  }

  const MessageItem = ({ msgID, author, Author, body, Body, dateCreated, DateCreated, participantSid, ParticipantSid }) => {
    if (!body?.length && !Body?.length) return null

    return (
      <div key={msgID} id={msgID} className='p-4 md:p-6 flex gap-x-2.5 w-full'>
        {
          (participantSid?.length || ParticipantSid?.length)
            ? !contact?.backgroundImage?.length > 0
              ? (
                <div className='h-8 w-8 border rounded-full flex items-center justify-center bg-gray-200 text-text-softBlack_70/50 font-bold'>
                  {contact.firstName[0]}
                  {contact.lastName[0]}
                </div>
              )
              : (
                <img
                  src={contact.backgroundImage}
                  alt='Avatar'
                  className='h-8 w-8 rounded-full'
                />
              )
            : <div className='w-8' />
        }
        <div className='flex flex-col gap-y-1 flex-1'>
          <div className={`w-full flex gap-1.5 just ${!(participantSid?.length || ParticipantSid?.length) ? 'justify-end' : ''}`}>
            <h6 className='m-0 text-sm font-medium'>
              {
                (participantSid?.length || ParticipantSid?.length)
                  ? contact.firstName + ' ' + contact.lastName
                  : author || Author
              }
            </h6>
            <p className='m-0 text-sm font-normal text-softBlack_70'>{moment(dateCreated || DateCreated).format("hh:mm")}</p>
          </div>
          <div className={`p-3 md:p-4 bg-gray-2 rounded-2xl ${!(participantSid?.length || ParticipantSid?.length) ? 'rounded-tr-none' : 'rounded-tl-none'}`}>
            <p className='m-0 text-base font-normal text-softBlack_70/100 whitespace-pre-wrap'>
              {body || Body}
            </p>
          </div>
          {/* {
            seen &&
            <p className='m-0 text-sm font-normal text-softBlack_70'>Seen</p>
          } */}
        </div>
        {
          !(participantSid?.length || ParticipantSid?.length)
            ? <img src='/stefan-dupuis.png' className='h-8 w-8 rounded-full object-cover' />
            : <div className='w-8' />
        }
      </div>
    )
  }

  const MessagesContainer = ({ messages }) => {
    return (
      <div className='h-full w-full flex flex-col overflow-y-auto'>
        <div className='w-full h-full flex flex-col'>
          {
            messages.map((message) => <MessageItem {...message} key={message.msgID} />)
          }
        </div>
      </div>
    )
  }

  return (
    <>
      <div className='flex w-full h-full'>
        <div className='flex flex-col flex-1'>
          {!messages?.length
            ? <div className='font-openSans flex flex-col justify-center items-center h-full w-full bg-white rounded'>
              <svg
                width='22'
                height='24'
                viewBox='0 0 18 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M16 2H15V0H13V2H5V0H3V2H2C0.89 2 0.00999999 2.9 0.00999999 4L0 18C0 19.1 0.89 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM16 18H2V7H16V18ZM4 9H9V14H4V9Z'
                  fill='#2E2B2E'
                />
              </svg>
              <div className='font-semibold mb-2 mt-4'>You don't have any messages yet</div>
              <div className='text-sm text-softBlack70'>
                {`Start with your first message with ${contact?.firstName ?? '...'}`}
              </div>
            </div>
            : <MessagesContainer messages={messages} />
          }
          <div className='w-full bg-white sticky bottom-0 p-4 md:p-6 border-t flex gap-x-3 md:gap-x-4'>
            <input
              id='message'
              name='message'
              className='px-4 py-2 text-sm bg-gray-2 font-normal border border-softBlack_70/30 h-full'
              placeholder='Send text message...'
              type='text'
              value={msg}
              onChange={(e) => setMsg(e.target.value)}
            />
            {
              !isMobile &&
              <CustomButton
                variant='outline'
                handleClick={() => setIsSMSTemplatesSelectionModalOpen(true)}
              >
                <span className='p-2 md:px-4 text-sm font-medium whitespace-nowrap'>
                  <LinkIcon className='h-5 w-5' />
                </span>
              </CustomButton>
            }
            <CustomButton handleClick={submitMsg}>
              <span className='p-2 px-3 md:px-4 text-sm font-medium whitespace-nowrap'>
                {
                  isMobile
                    ? <Send className='w-5 h-5' />
                    : 'Send message'
                }
              </span>
            </CustomButton>
          </div>
        </div>
      </div>
      <SMSTemplatesSelectionModal
        onClose={() => setIsSMSTemplatesSelectionModalOpen(false)}
        open={isSMSTemplatesSelectionModalOpen}
        applySMSTemplate={(smsTemplate) => setMsg(msg + smsTemplate)}
        sendMessage={_sendMessage}
        isSendingSMS={isSendingSMS}
      />
    </>
  )
}

export default MessageConversation
