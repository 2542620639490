import React from 'react'
import propTypes from 'prop-types'
import { PwaWrapper } from 'components'

const ProjectFloorPlans = (props) => {
  const {
    location: { state }
  } = props

  if (!state?.floorPlanUrl) {
    return <div>Something went wrong, Please try again</div>
  }

  return (
    <PwaWrapper style={{ background: 'red' }}>
      <div
        dangerouslySetInnerHTML={{
          __html: `<iframe title='FloorPlans' src='${state?.floorPlanUrl}&output=embed' width='100%' height='100%'/>`
        }}
      />
    </PwaWrapper>
  )
}

export default ProjectFloorPlans

ProjectFloorPlans.propTypes = {
  floorPlanUrl: propTypes.string,
  location: propTypes.object
}
