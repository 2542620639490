import React from 'react'
import { Box } from '@material-ui/core'
import AddForm from './AddForm'
import Table from './Table'
import ContextProvider from './context'
import ConfirmDelete from './confirmDelete'
import PageHeader from './PageHeader'

const DynamicDropDown = ({ itemKey }) => {
  return (
    <ContextProvider itemKey={itemKey}>
      <div className='h-full w-full flex flex-col'>
        <ConfirmDelete />
        <PageHeader />
        <div className='h-full w-full flex flex-row'>
          <Box display='flex' padding='20px' flex={0.6}>
            <Table />
          </Box>
          <Box
            display='flex'
            justifyContent='center'
            height='max-content'
            padding='20px'
            flex={0.4}
          >
            <AddForm />
          </Box>
        </div>
      </div>
    </ContextProvider>
  )
}

export default DynamicDropDown
