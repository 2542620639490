import { message } from 'antd'
import { api, ApiErrors } from 'services/api'

export const getProjectDeposits = (projectId) =>
  new Promise((resolve, reject) => {
    api.get(`deposits/project/${projectId}`).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getProjectDepositStatistics = (projectId) =>
  new Promise((resolve, reject) => {
    api.get(`deposits/project/${projectId}/statistics`).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getDeposits = (depositId = '') =>
  new Promise((resolve, reject) => {
    api.get(`deposits/${depositId}`).then((response) => {
      if (response.ok) {
        resolve(response.data.contracts)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const createOrUpdateDeposit = (params, showUpdateMessage = false) =>
  new Promise((resolve, reject) => {
    const { _id } = params

    // If id, so we have to update the record, or we're gonna create
    const verb = _id ? 'put' : 'post'
    const url = _id ? `deposits/${_id}` : 'deposits'

    api[verb](url, params).then((response) => {
      if (response.ok) {
        if (showUpdateMessage) {
          message.success(
            `The deposits was ${_id ? 'successfully updated' : 'created successfully'
            }`
          )
        }
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const deleteDeposit = (depositId) =>
  new Promise((resolve, reject) => {
    api.delete(`/deposits/${depositId}`).then((response) => {
      if (response.ok) {
        message.success('Deposits deleted successfully')
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })
