import React from 'react'
import ProjectUpgrades from '../components/ProjectUpgrades'

const AddUpgrades = ({ setStep }) => {
  return (
    <ProjectUpgrades setStep={setStep} />
  )
}

export default AddUpgrades
