import { api, ApiErrors } from 'services/api'
import { message } from 'antd'

export const getCalls = (id = '', filter = '') => new Promise((resolve, reject) => {
  api.get(`calls/${id}${filter}`)
    .then((response) => {
      if (response.ok) {
        resolve(response.data.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const createOrUpdateCall = (props) => new Promise((resolve, reject) => {
  const { _id, ...params } = props

  // If id, so we have to update the record, or we're gonna create
  const verb = _id ? 'put' : 'post'
  const url = _id ? `calls/${_id}` : 'calls'

  api[verb](url, params)
    .then((response) => {
      if (response.ok) {
        message.success(`Call ${_id ? 'successfully updated' : 'created successfully'}`)
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const deleteCall = (id) => new Promise((resolve, reject) => {
  api.delete(`/calls/${id}`)
    .then((response) => {
      if (response.ok) {
        message.success('Call deleted successfully')
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const getAllCallsBySalesRep = (project, salesRepId) => new Promise((resolve, reject) => {
  api.get(`/calls/all?project=${project}&salesRep=${salesRepId}`)
    .then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})
