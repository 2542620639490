import React, { useEffect, useState } from 'react'
import propTypes from 'prop-types'
import { Popover } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'

const SelectMultiple = ({
  buttonLabel,
  options,
  onChange,
  multiple,
  buttonClass,
  iconClass,
  itemClass,
  autoSelectFirst
}) => {
  const [checkedState, setCheckedState] = useState([])

  const [buttonTitle, setButtonTitle] = useState('')

  const handleOnChange = (option) => {
    const index = checkedState.findIndex((_option) => _option === option.value)
    if (multiple) {
      if (index !== -1) {
        setCheckedState([
          ...checkedState.filter((_option) => _option !== option.value)
        ])
      } else {
        setCheckedState([...checkedState, option.value])
      }
    } else {
      if (index !== -1) {
        if (!autoSelectFirst) {
          setCheckedState([])
        }
      } else {
        setCheckedState([option.value])
      }
    }
  }

  useEffect(() => {
    if (autoSelectFirst) {
      setCheckedState([options[0].value])
    }
  }, [])

  useEffect(() => {
    const items = options.filter((_option) =>
      checkedState.includes(_option.value)
    )
    const labels = items?.map((_item) => _item?.label)
    const itemCount = items.length
    if (itemCount > 0) {
      setButtonTitle(labels?.join(', '))
    } else {
      setButtonTitle(buttonLabel)
    }
    onChange([...checkedState])
  }, [checkedState, buttonLabel])

  return (
    <Popover className='relative w-full'>
      {({ open }) => (
        <>
          <Popover.Button className='flex flex-row w-full items-center justify-between'>
            <span
              className={`${
                buttonClass ?? 'ml-1 text-softBlack_70 text-start'
              }`}
            >
              {buttonTitle}
            </span>
            <ChevronDownIcon
              aria-hidden='true'
              className={`${
                iconClass ?? 'h-5 w-5 text-gray-400 group-hover:text-gray-500'
              } ${open ? 'rotate-180 transform' : ''}`}
            />
          </Popover.Button>
          <Popover.Panel className='absolute z-10 flex flex-col w-full space-y-3 bg-menuGray justify-start py-[21px] px-[18px] border-softBlack border mt-3 '>
            {options.map((option, index) => (
              <div
                key={option.value}
                className={`${
                  itemClass ?? 'flex-1 space-x-2.5 text-softBlack_70'
                }`}
              >
                <input
                  type='checkbox'
                  id={`custom-checkbox-${index}`}
                  name={option.value}
                  value={option.value}
                  checked={checkedState.includes(option.value)}
                  onChange={() => handleOnChange(option)}
                />
                <label htmlFor='checkbox'>{option?.label}</label>
              </div>
            ))}
          </Popover.Panel>
        </>
      )}
    </Popover>
  )
}

SelectMultiple.defaultProps = {
  multiple: false,
  buttonClass: null,
  iconClass: null,
  itemClass: null,
  autoSelectFirst: false
}

SelectMultiple.propTypes = {
  buttonLabel: propTypes.string,
  options: propTypes.array,
  onChange: propTypes.func,
  multiple: propTypes.bool,
  buttonClass: propTypes.string,
  iconClass: propTypes.string,
  itemClass: propTypes.string,
  autoSelectFirst: propTypes.bool
}

export default SelectMultiple
