import { message } from 'antd'
import { api, ApiErrors } from 'services/api'
import {
  CREATE_OFFER_CHANGE_UNIT,
  CREATE_OFFER_CHANGE_BUYER,
  CREATE_OFFER_CHANGE_BROKER,
  CREATE_OFFER_CHANGE_PARKING,
  CREATE_OFFER_CHANGE_COLOR_SCHEME,
  CREATE_OFFER_CHANGE_OPTIONS,
  CREATE_OFFER_CLEAR_REDUCER,
  CREATE_OFFER_CHANGE_FINTRAC,
  CREATE_OFFER_CHANGE_BROKER_COMMISSION
} from './actionTypes'
import { getRandomInteger } from 'utils'

export const createOfferChangeUnit = (unit) => (dispatch) => {
  dispatch({
    type: CREATE_OFFER_CHANGE_UNIT,
    payload: unit
  })
}

export const createOfferChangeBuyer = (buyer) => (dispatch) => {
  dispatch({
    type: CREATE_OFFER_CHANGE_BUYER,
    payload: buyer
  })
}

export const createOfferChangeBroker = (broker) => (dispatch) => {
  dispatch({
    type: CREATE_OFFER_CHANGE_BROKER,
    payload: broker
  })
}

export const createOfferChangeParking = (option) => (dispatch) => {
  dispatch({
    type: CREATE_OFFER_CHANGE_PARKING,
    payload: option
  })
}

export const createOfferChangeColorScheme = (colorScheme) => (dispatch) => {
  dispatch({
    type: CREATE_OFFER_CHANGE_COLOR_SCHEME,
    payload: colorScheme
  })
}

export const createOfferChangeOptions = (options) => (dispatch) => {
  dispatch({
    type: CREATE_OFFER_CHANGE_OPTIONS,
    payload: options
  })
}

export const createOfferChangeFintrac = (fintracOptions) => (dispatch) => {
  dispatch({
    type: CREATE_OFFER_CHANGE_FINTRAC,
    payload: fintracOptions
  })
}

export const createOfferChangeBrokerCommissions =
  (commissionValues) => (dispatch) => {
    dispatch({
      type: CREATE_OFFER_CHANGE_BROKER_COMMISSION,
      payload: commissionValues
    })
  }

export const createOrUpdateOffer = (params, showUpdateMessage = false) =>
  new Promise((resolve, reject) => {
    const { id } = params

    // If id, so we have to update the record, or we're gonna create
    const verb = id ? 'put' : 'post'
    const url = id ? `offers/${id}` : 'offers'

    api[verb](url, params).then((response) => {
      if (response.ok) {
        if (showUpdateMessage) {
          message.success(`The Offer was ${id ? 'successfully updated' : 'created successfully'}`)
        }

        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const createOfferClearReducer = () => (dispatch) => {
  dispatch({ type: CREATE_OFFER_CLEAR_REDUCER })
}

export const getOffers = (id = '', filter = '') =>
  new Promise((resolve, reject) => {
    api.get(`offers/${id}${filter}`).then((response) => {
      if (response.ok) {
        resolve(response.data.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const updateOffers = (data) => {
  const { _id, ...params } = data
  return new Promise((resolve, reject) => {
    api.patch(`offers/${_id}`, params).then((response) => {
      if (response.ok) {
        resolve(response.data.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })
}

export const sendOfferToBuyer = (projectId) =>
  new Promise((resolve, reject) => {
    api.post(`offers/${projectId}/send`).then((response) => {
      if (response.ok) {
        message.success('The offer and contracts were sent to the buyer')
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const sendContractsToBuyer = (offerId) =>
  new Promise((resolve, reject) => {
    const data = { id: offerId }
    api
      .post(`offers/${offerId}/sendcontractstobuyer`, data)
      .then((response) => {
        if (response.ok) {
          message.success('The contracts were sent to the buyer')
          resolve()
        } else {
          ApiErrors(response)
          reject()
        }
      })
  })

export const sendContractsToDeveloper = (offerId) =>
  new Promise((resolve, reject) => {
    const data = { id: offerId }
    api
      .post(`offers/${offerId}/sendContractsToDeveloper`, data)
      .then((response) => {
        if (response.ok) {
          message.success('The contracts were sent to the developer')
          resolve()
        } else {
          ApiErrors(response)
          reject()
        }
      })
  })

export const generateContractBuyerLink = (offerId) =>
  new Promise((resolve, reject) => {
    const data = { id: offerId }
    api
      .post(`offers/${offerId}/generatecontractbuyerlink`, data)
      .then((response) => {
        if (response.ok) {
          resolve(response?.data?.url)
        } else {
          ApiErrors(response)
          reject()
        }
      })
  })

export const getDepositOptions = (projectId) =>
  new Promise((resolve, reject) => {
    api.get(`depositoption/project/${projectId}`).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const createOrUpdateDepositOptions = (params, showUpdateMessage = false) =>
  new Promise((resolve, reject) => {
    console.log('params: ', params)
    const { _id } = params

    const verb = _id ? 'put' : 'post'
    const url = _id ? `depositoption/${_id}` : 'depositoption'

    api[verb](url, params).then((response) => {
      if (response.ok) {
        if (showUpdateMessage) {
          message.success(`Deposit option was ${_id ? 'successfully updated' : 'created successfully'}`)
        }

        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getOfferContracts = (offerId) =>
  new Promise((resolve, reject) => {
    api.get(`offers/${offerId}/contracts`).then((response) => {
      if (response.ok) {
        resolve(response.data.contracts)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getOfferContractsPreview = (offerId) =>
  new Promise((resolve, reject) => {
    const data = { id: offerId }
    api.post(`offers/${offerId}/contracts/preview`, data).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getRefreshedHellosignLink = (signatureId) =>
  new Promise((resolve, reject) => {
    api.get(`offers/contracts/refresh/${signatureId}`).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const createOfferPayment = (props) =>
  new Promise((resolve, reject) => {
    const { offerId, payment } = props

    const params = {
      payment
    }

    api.post(`offers/${offerId}/payment/create`, params).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getProjectOffers = (projectId) =>
  new Promise((resolve, reject) => {
    api.get(`offers/project/${projectId}`).then((response) => {
      if (response.ok) {
        resolve(response.data.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getProjectStatistics = (projectId) =>
  new Promise((resolve, reject) => {
    api.get(`offers/project/${projectId}/statistics`).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const rejectOffer = (offerId) =>
  new Promise((resolve, reject) => {
    api.post(`offers/${offerId}/reject`, {}).then((response) => {
      if (response.ok) {
        resolve(response.data.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const cancelOffer = (offerId) =>
  new Promise((resolve, reject) => {
    api.post(`offers/${offerId}/cancel`, {}).then((response) => {
      if (response.ok) {
        resolve(response.data.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const completeOffer = (offerId) =>
  new Promise((resolve, reject) => {
    api.post(`offers/${offerId}/complete`, {}).then((response) => {
      if (response.ok) {
        resolve(response.data.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getEmbedUrlForOffer = (offerId, contractId, role) =>
  new Promise((resolve, reject) => {
    api
      .post(
        `/offers/${offerId}/contracts/${contractId}/getEmbedUrl?role=${role}`,
        {}
      )
      .then((response) => {
        if (response.ok) {
          resolve(response.data)
        } else {
          ApiErrors(response)
          reject()
        }
      })
  })

export const getPublicOfferData = (token) =>
  new Promise((resolve, reject) => {
    api.setHeaders({
      'Content-Type': 'application/json'
    })
    api.get('/offers/public', { token }).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getContractDownloadURL = (contractId) =>
  new Promise((resolve, reject) => {
    api.get(`contracts/${contractId}/url `).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getContractDownloadContent = (contractId) =>
  new Promise((resolve, reject) => {
    api.get(`contracts/${contractId}/dataUrl`).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const uploadOfferDocument = ({ projectId, docName, file, type }) =>
  new Promise((resolve, reject) => {
    const formData = new FormData()
    console.log('uploadOfferDocument type', type)
    const fileName = `${type}-${docName}-${file.name || getRandomInteger()}`
    formData.append('project', projectId)
    formData.append('documentName', fileName)
    formData.append('file', file)
    formData.append('tags', `${type}`)
    api.post('privatedocuments/upload', formData).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getOfferActivity = (offerId, skip = 0, limit = 100) => {
  return new Promise((resolve, reject) => {
    api
      .get(`/useractivity/offer/${offerId}/${skip}/${limit}`)
      .then((response) => {
        if (response.ok) {
          resolve(response.data)
        } else {
          ApiErrors(response)
          reject()
        }
      })
  })
}
