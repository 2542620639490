import React from 'react'
import propTypes from 'prop-types'
import { LottieWrapper } from 'components'
import FadedAnimatedDiv from 'components/FadedAnimatedDiv'

import successAnimation from 'assets/lottieJsons/success.json'
import { TButton } from 'ui'

const AllDocsSigned = ({ onNextButtonClick, nextButtonClassName }) => (
  <FadedAnimatedDiv
    key='allDocsSigned'
    style={{
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center'
    }}
  >
    <LottieWrapper
      animation={successAnimation}
      autoplay
      loop={false}
      className='-mt-40 md:mt-0 max-w-sm'
    />
    <FadedAnimatedDiv
      transition={{ delay: 1.5 }}
      className='text-center'
    >
      <span className='text-xl'>
        All of your docs have been successfully signed!
      </span>
    </FadedAnimatedDiv>

    <FadedAnimatedDiv
      transition={{ delay: 3 }}
      style={{ marginTop: 45 }}
    >
      <TButton
        onClick={onNextButtonClick}
        className={nextButtonClassName}
      >
        Next
      </TButton>
    </FadedAnimatedDiv>
  </FadedAnimatedDiv>
)

export default AllDocsSigned

AllDocsSigned.propTypes = {
  onNextButtonClick: propTypes.string,
  nextButtonClassName: propTypes.func
}
