import useProjectMapContext from 'components/ProjectMap/hooks/useProjectMapContext'
import React, { useEffect, useState } from 'react'

const SearchBox = () => {
  const { setSearch } = useProjectMapContext()
  const [text, setText] = useState('')

  useEffect(() => {
    const id = setTimeout(() => setSearch(text), 400)
    return () => clearTimeout(id)
  }, [text/*, setSearch (do not uncomment this, it will break rendering) */])

  return (
    <input value={text} onChange={(e) => setText(e.currentTarget.value)} className='bg-white h-full p-2 rounded w-72 text-softBlack' placeholder='Search' />
  )
}

export default SearchBox
