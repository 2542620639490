/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'

import { TableFilter } from 'components'
import Skeleton from 'react-loading-skeleton'
import { getProjectUnits } from 'store/actions/unitActions'
import { getProjects } from 'store/actions/projectActions'

import UnitCard from './UnitCard'
import UnitListView from 'components/ProjectUnitsInventory/components/UnitListView'

const SelectUnit = (props) => {
  const [gettingData, setGettingData] = useState(false)
  const [originalUnits, setOriginalUnits] = useState([])
  const [units, setUnits] = useState(originalUnits)
  const [projectData, setProjectData] = useState({})

  const { appProject, userObject, onSelect } = props

  useEffect(() => {
    _getProjectUnits()
  }, [appProject])

  const _getProjectUnits = async () => {
    if (appProject) {
      setUnits([])
      setOriginalUnits([])
      setProjectData({})

      setGettingData(true)

      let projectUnits = await getProjectUnits(appProject)
      const projectData = await getProjects(appProject)

      projectUnits = projectUnits.map((unit) => ({
        ...unit,
        hasPermission: checkUnitPermission(unit)
      }))

      Promise.all([projectUnits, projectData]).then(() => {
        if (projectUnits.length) {
          setUnits(projectUnits)
          setOriginalUnits(projectUnits)
        }

        if (Object.keys(projectData).length) {
          setProjectData(projectData)
        }
        setGettingData(false)
      })
    }
  }
  const TableIndex = ({ title }) => {
    return (
      <div className='flex h-[59px] min-w-20 w-20 bg-[#E3E3E1] h-full items-center justify-center text-softBlack70 font-medium text-xs uppercase'>
        <span> {title}</span>
      </div>
    )
  }
  const TableTitle = ({ title, span = 2 }) => {
    return (
      <div
        className={
          'flex flex-1 bg-[#E3E3E1] border-softBlack30 border-b items-center justify-center border-l'
        }
      >
        <span className='text-softBlack70 font-medium text-xs uppercase'>
          {title}
        </span>
      </div>
    )
  }
  const checkUnitPermission = useCallback(
    (unit, selectedTabFilter) => {
      const { _id } = userObject
      const { brokerAssigned, reservedTo } = unit

      const tmpBrokerAssigned = brokerAssigned || []

      return Boolean(
        (selectedTabFilter !== 'myUnits' &&
          unit.status !== 'ReservedBroker' &&
          unit.status !== 'ReservedBuyer') ||
          ((unit.status === 'ReservedBroker' ||
            unit.status === 'ReservedBuyer') &&
            ((tmpBrokerAssigned &&
              tmpBrokerAssigned.length &&
              tmpBrokerAssigned[0] !== 'HIDDEN') ||
              (reservedTo && reservedTo.length && reservedTo[0] !== 'HIDDEN') ||
              tmpBrokerAssigned.filter((broker) => broker === _id).length))
      )
    },
    [userObject]
  )

  return (
    <div className='flex flex-col items-center justify-center'>
      {props?.isAllocateInventory === false ||
        (props?.isAllocateInventory === undefined && (
          <div className='w-4/6 mb-10'>
            <TableFilter
              dataToFilter={originalUnits}
              setData={setUnits}
              placeHolder='You can filter the units below by spacing words'
              label=''
              loading={gettingData}
            />
          </div>
        ))}
      <div className='w-full'>
        <UnitListView
          units={units}
          gettingUnits={gettingData}
          type='offerFlow'
          onSelect={onSelect}
          isAllocateInventory={props.isAllocateInventory ?? false}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  appProject: state.appReducer.appProject,
  userObject: state.authReducer.userObject
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(SelectUnit)
