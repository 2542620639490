import { ContactProvider } from 'pages/contacts/context/Contacts'
import React from 'react'
import { CreateReservationProvider } from './context/CreateReservationContext'
import CreateReservationWrapper from './Wrapper'

const CreateReservation = () => {
  return (
    <CreateReservationProvider>
        <ContactProvider>
          <CreateReservationWrapper />
        </ContactProvider>
    </CreateReservationProvider>
  )
}

export default CreateReservation
