import { Checkbox as CheckboxAntd } from 'antd'
import styled from 'styled-components'

import standardStyle from 'assets/css/standardStyle'

const Checkbox = styled(CheckboxAntd)`
  color: ${standardStyle.colors.primary};
  white-space: nowrap;
`

export default Checkbox
