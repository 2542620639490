import { Drawer } from 'antd'
import CustomButton from 'components/CustomButton'
import React, { useState } from 'react'

const CreateEmailTemplateSlider = ({ onSubmit, onClose, open }) => {
  const [templateName, setTemplateName] = useState('')
  const handleSubmit = () => {
    onSubmit(templateName)
    setTemplateName('')
  }

  return (
    <Drawer closable={false} placement="right" onClose={onClose} open={open}>
      <div className='px-8 py-12'>
        <h1 className='text-2xl font-semibold'>Create a New Email Template</h1>
        <div className='flex flex-1 flex-col justify-left my-10'>
          <div className='font-bold text-softBlack_70 italic'>Name of your new Email Template</div>
          <input className='' value={templateName} onChange={(e) => setTemplateName(e.target.value)} placeholder='Email Template Name' />
        </div>
        <div className='flex justify-end gap-2'>
          <CustomButton
            variant='outline'
            handleClick={() => {
              setTemplateName('')
              onClose()
            }}>
            <span className='font-medium py-2.5 px-6 text-base flex gap-2 items-center'>
              Cancel
            </span>
          </CustomButton>
          <CustomButton handleClick={handleSubmit}>
            <span className='font-medium py-2.5 px-6 text-base flex gap-2 items-center'>
              Create
            </span>
          </CustomButton>
        </div>
      </div>
    </Drawer>
  )
}

export default CreateEmailTemplateSlider
