import React from 'react'
import { useParams } from 'react-router-dom'
import PublicForm from 'pages/forms/publicForm'

const Index = () => {
  const { slug } = useParams()
  return <div className='h-screen w-screen'>
    <PublicForm formId={slug} />
  </div>
}

export default Index
