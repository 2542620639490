/*eslint-disable */
// @ts-nocheck
import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import moment from "moment";
import "./style/style.css";
import {
  createOrUpdateTask,
  deleteTask,
  getTasks,
  getTasksBySalesRepId,
  getTasksTodayCount,
} from "store/actions/taskActions";
import useUser from "hooks/useUser";
import TaskModal from "./components/TaskModal";
import { getAllSalesRepByProject, getUsers } from "store/actions/usersActions";
import Loader from "pages/desktopMode/dynamicDropDown/Loader";
import CustomModal from "components/Modal";
import { Oval } from "react-loader-spinner";
import { isMobile } from "react-device-detect";
import CustomButton from "components/CustomButton";
import TaskDetailsDrawer from "./components/TaskDetailsDrawer";
import { ContactContext } from "pages/contacts/context/Contacts";
import CustomSelect from "./components/CustomSelect";
import { useSelector } from "react-redux";
import { IoIosArrowUp } from "react-icons/io";
import { Dropdown, Tooltip } from "antd";
import { CheckCircle, Edit, TrashIcon } from "lucide-react";
import DeleteModal from "components/DeleteModal";
import useContactContext from "pages/newContacts/hooks/useContactContext";

export const pendingTasksStatus = ["pending", "NOT_STARTED"];
export const completedTasksStatus = ["closed", "COMPLETED"];

const PendingTaskIcon = () => {
  return (
    <div className="h-6 w-6 rounded-full border-[1.7px] border-dashed border-softBlack/40" />
  );
};

const CompletedTaskIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6666 0C6.04263 0 0.666626 5.376 0.666626 12C0.666626 18.624 6.04263 24 12.6666 24C19.2906 24 24.6666 18.624 24.6666 12C24.6666 5.376 19.2906 0 12.6666 0ZM10.2666 18L4.26663 12L5.95863 10.308L10.2666 14.604L19.3746 5.496L21.0666 7.2L10.2666 18Z"
        fill="#B8E04A"
      />
    </svg>
  );
};

const ContactIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1133 8.75333C12.0266 9.37333 12.6666 10.2133 12.6666 11.3333V13.3333H15.3333V11.3333C15.3333 9.87999 12.9533 9.01999 11.1133 8.75333Z"
        fill="#2E2B2E"
        fillOpacity="0.5"
      />
      <path
        d="M5.99943 7.99999C7.47219 7.99999 8.6661 6.80608 8.6661 5.33332C8.6661 3.86056 7.47219 2.66666 5.99943 2.66666C4.52667 2.66666 3.33276 3.86056 3.33276 5.33332C3.33276 6.80608 4.52667 7.99999 5.99943 7.99999Z"
        fill="#2E2B2E"
        fillOpacity="0.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99995 7.99999C11.4733 7.99999 12.6666 6.80666 12.6666 5.33332C12.6666 3.85999 11.4733 2.66666 9.99995 2.66666C9.68661 2.66666 9.39328 2.73332 9.11328 2.82666C9.66661 3.51332 9.99995 4.38666 9.99995 5.33332C9.99995 6.27999 9.66661 7.15332 9.11328 7.83999C9.39328 7.93332 9.68661 7.99999 9.99995 7.99999Z"
        fill="#2E2B2E"
        fillOpacity="0.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.99971 8.66666C4.21971 8.66666 0.666382 9.55999 0.666382 11.3333V13.3333H11.333V11.3333C11.333 9.55999 7.77971 8.66666 5.99971 8.66666Z"
        fill="#2E2B2E"
        fillOpacity="0.5"
      />
    </svg>
  );
};

const CalenderIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3336 1.99999H12.667V0.666656H11.3336V1.99999H4.66695V0.666656H3.33362V1.99999H2.66695C1.93362 1.99999 1.33362 2.59999 1.33362 3.33332V14C1.33362 14.7333 1.93362 15.3333 2.66695 15.3333H13.3336C14.067 15.3333 14.667 14.7333 14.667 14V3.33332C14.667 2.59999 14.067 1.99999 13.3336 1.99999ZM13.3336 14H2.66695V5.33332H13.3336V14Z"
        fill="#2E2B2E"
        fillOpacity="0.5"
      />
    </svg>
  );
};

const Header = ({
  title,
  count,
  buttonText,
  buttonAction,
  handleScrollTop,
  showScrollButton,
}) => {
  return (
    <div className="flex justify-between items-center gap-x-4">
      <div className="flex items-center w-full justify-between gap-x-[13px]">
        <h3 className="text-zinc-800 text-[28px] font-medium m-0">{title}</h3>
        {showScrollButton && (
          <div className="h-8 w-8 border-[1px] flex items-center justify-center border-[rgb(0,0,0)] rounded-full p-1">
            <IoIosArrowUp
              className="cursor-pointer"
              onClick={handleScrollTop}
              size={24}
            />
          </div>
        )}
      </div>
      {buttonText && (
        <CustomButton variant="outline" handleClick={buttonAction}>
          <span className="font-medium py-2 px-4 text-base flex gap-2 items-center">
            {buttonText}
          </span>
        </CustomButton>
      )}
    </div>
  );
};

const tabMenus = [
  {
    id: "upcomingTasks",
    name: "Upcoming",
  },
  {
    id: "pastDueTasks",
    name: "Past due",
  },
  {
    id: "completedTasks",
    name: "Complete",
  },
];

const Tasks = () => {
  const { user } = useUser();
  const projectId = useSelector((state) => state.appReducer.appProject);
  const [tasks, setTasks] = useState([]);
  const [upcomingTasks, setUpcomingTasks] = useState([]);
  const [pastDueTasks, setPastDueTasks] = useState([]);
  const [completedTasks, setCompletedTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingNewDataUpComing, setIsLoadingNewDataUpComing] =
    useState(false);
  const [isLoadingNewDataPastDue, setIsLoadingNewDataPastDue] = useState(false);
  const [isLoadingNewDataCompleted, setIsLoadingNewDataCompleted] =
    useState(false);
  const [isMarkCompleteLoading, setIsMarkCompleteLoading] = useState(false);
  const [isTaskDrawerOpen, setIsTaskDrawerOpen] = useState(false);
  const [isInfoDrawerOpen, setIsInfoDrawerOpen] = useState(false);
  const [salesRepData, setSalesRepData] = useState([]);
  const [selectedSalesRep, setSelectedSalesRep] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const [selectedTaskType, setSelectedTaskType] = useState("");
  const [selectedDurationDueDate, setSelectedDurationDueDate] = useState("");
  const [todayTasks, setTodayTasks] = useState({});
  const [isCreateOrUpdateTaskModalOpen, setIsCreateOrUpdateTaskModalOpen] =
    useState(false);
  const [selectedTask, setSelectedTask] = useState({});
  const [contact, setContact] = useState({});
  const [
    isMarkCompleteConfirmationModalOpen,
    setIsMarkCompleteConfirmationModalOpen,
  ] = useState(false);
  const [selectedTab, setSelectedTab] = useState({ ...tabMenus[0] });
  const [taskFetchCompleted, setTaskFetchCompleted] = useState({
    upcomingTasks: false,
    pastDueTasks: false,
    completedTasks: false,
  });
  const { setSelectedContact, setSelectedContactObject } =
    useContext(ContactContext);
  const [salesRepTab, setSalesRepTab] = useState("tab-1");
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const { view } = useContactContext();
  // const [upcomingTaskPage, setUpcomingTaskPage] = useState(0);
  // const [pastDueTaskPage, setPastDueTaskPage] = useState(0);
  // const [completedTaskPage, setCompletedTaskPage] = useState(0);
  const [text, setText] = useState("");
  const [search, setSearch] = useState("");
  const salesRepMenu = [
    {
      id: "tab-1",
      name: "All Sales Reps",
    },
    {
      id: "tab-2",
      name: "Per Sales Rep",
    },
  ];

  const taskTypes = [
    { label: "All", value: "all", _id: "all" },
    { label: "Email", value: "email", _id: "email" },
    { label: "Call", value: "call", _id: "call" },
    { label: "Other", value: "other", _id: "other" },
  ];

  const durationType = [
    { label: "Today", value: "today", _id: "today" },
    { label: "This Week", value: "this_week", _id: "this_week" },
    { label: "All", value: "all", _id: "all" },
  ];

  useEffect(() => {
    if (!isInitialized && user._id) {
      setSelectedSalesRep(user.userType === "SalesRep" ? user._id : "all");
      setIsInitialized(true);
    }
  }, [user, isInitialized]);
  const deleteTheTask = () => {
    setDeleteLoading(true);

    deleteTask(selectedTask?._id)
      .then(() => {
        fetchCompletedTasks();
        fetchPastDueTasks();
        fetchUpComingTasks();
        getTodaysTasks();
        setSelectedTask({});
        setIsTaskDrawerOpen(false);
        setTimeout(() => {
          setIsDeleteModal(false);
          setDeleteLoading(false);
        }, 1000);
      })
      .catch(() => {
        setDeleteLoading(false);
      });
  };

  const color = [
    "#dc2626",
    "#16a34a",
    "#f97316",
    "#171717",
    "#facc15",
    "#2563eb",
    "#7c3aed",
    "#e11d48",
  ];

  const fetchUpComingTasks = (
    page = 0,
    limit = 30,
    dataStatus = "filterFetch",
    searchedTask = ""
  ) => {
    let filter = {
      page: page,
      limit: limit,
      taskStatus: "upcoming",
      searched: searchedTask,
    };
    if (selectedSalesRep !== null) {
      if (selectedSalesRep === "all") {
        if (selectedTaskType !== "") {
          filter.taskType = selectedTaskType;
        }

        if (selectedDurationDueDate !== "") {
          filter.dueDate = selectedDurationDueDate;
        }
        setIsLoadingNewDataUpComing(true);
        getTasks("", filter).then((res) => {
          setIsLoading(true);
          if (dataStatus === "filterFetch") {
            if (res.length === 0) {
              setUpcomingTasks(res);
            } else {
              setUpcomingTasks(res);
            }
          } else {
            if (res.length === 0) {
              setTaskFetchCompleted({
                ...taskFetchCompleted,
                upcomingTasks: true,
              });
              setIsLoading(false);
              setIsLoadingNewDataUpComing(false);
              return;
            } else {
              setUpcomingTasks((prev) => [...prev, ...res]);
            }
          }
          setIsLoading(false);
          setIsLoadingNewDataUpComing(false);
        });
      } else {
        if (selectedTaskType !== "") {
          filter.taskType = selectedTaskType;
        }
        if (selectedDurationDueDate !== "") {
          filter.dueDate = selectedDurationDueDate;
        }
        setIsLoadingNewDataUpComing(true);
        getTasks(selectedSalesRep, filter).then((res) => {
          setIsLoading(true);

          const currentDate = new Date();
          if (dataStatus === "filterFetch") {
            if (res.length === 0) {
              setUpcomingTasks(res);
            } else {
              setUpcomingTasks(res);
            }
          } else {
            if (res.length === 0) {
              setTaskFetchCompleted({
                ...taskFetchCompleted,
                upcomingTasks: true,
              });
              setIsLoading(false);
              setIsLoadingNewDataUpComing(false);
              return;
            } else {
              setUpcomingTasks((prev) => [...prev, ...res]);
            }
          }
          setIsLoading(false);
          setIsLoadingNewDataUpComing(false);
        });
      }
    }
  };

  const fetchPastDueTasks = (
    page = 0,
    limit = 30,
    dataStatus = "filterFetch",
    searchedTask = ""
  ) => {
    let filter = {
      page: page,
      limit: limit,
      taskStatus: "pastDue",
      searched: searchedTask,
    };
    if (selectedSalesRep !== null) {
      if (selectedSalesRep === "all") {
        if (selectedTaskType !== "") {
          filter.taskType = selectedTaskType;
        }

        if (selectedDurationDueDate !== "") {
          filter.dueDate = selectedDurationDueDate;
        }
        setIsLoadingNewDataPastDue(true);

        getTasks("", filter).then((res) => {
          setIsLoading(true);
          if (dataStatus === "filterFetch") {
            if (res.length === 0) {
              setPastDueTasks(res);
            } else {
              setPastDueTasks(res);
            }
          } else {
            if (res.length === 0) {
              setTaskFetchCompleted({
                ...taskFetchCompleted,
                pastDueTasks: true,
              });
            } else {
              setPastDueTasks((prev) => [...prev, ...res]);
            }
          }
          setIsLoading(false);
          setIsLoadingNewDataPastDue(false);
        });
      } else {
        if (selectedTaskType !== "") {
          filter.taskType = selectedTaskType;
        }
        if (selectedDurationDueDate !== "") {
          filter.dueDate = selectedDurationDueDate;
        }
        setIsLoadingNewDataPastDue(true);
        getTasks(selectedSalesRep, filter).then((res) => {
          setIsLoading(true);
          if (dataStatus === "filterFetch") {
            if (res.length === 0) {
              setPastDueTasks(res);
            } else {
              setPastDueTasks(res);
            }
          } else {
            if (res.length === 0) {
              setTaskFetchCompleted({
                ...taskFetchCompleted,
                pastDueTasks: true,
              });
            } else {
              setPastDueTasks((prev) => [...prev, ...res]);
            }
          }
          setIsLoading(false);
          setIsLoadingNewDataPastDue(false);
        });
      }
    }
  };

  const fetchCompletedTasks = (
    page = 0,
    limit = 30,
    dataStatus = "filterFetch",
    searchedTask = ""
  ) => {
    let filter = {
      page: page,
      limit: limit,
      taskStatus: "completed",
      searched: searchedTask,
    };
    if (selectedSalesRep !== null) {
      if (selectedSalesRep === "all") {
        if (selectedTaskType !== "") {
          filter.taskType = selectedTaskType;
        }

        if (selectedDurationDueDate !== "") {
          filter.dueDate = selectedDurationDueDate;
        }
        setIsLoadingNewDataCompleted(true);
        getTasks("", filter).then((res) => {
          setIsLoading(true);

          if (dataStatus === "filterFetch") {
            if (res.length === 0) {
              setCompletedTasks(res);
            } else {
              setCompletedTasks(res);
            }
          } else {
            if (res.length === 0) {
              setTaskFetchCompleted({
                ...taskFetchCompleted,
                completedTasks: true,
              });
            } else {
              setCompletedTasks((prev) => [...prev, ...res]);
            }
          }
          setIsLoading(false);
          setIsLoadingNewDataCompleted(false);
        });
      } else {
        if (selectedTaskType !== "") {
          filter.taskType = selectedTaskType;
        }
        if (selectedDurationDueDate !== "") {
          filter.dueDate = selectedDurationDueDate;
        }
        setIsLoadingNewDataCompleted(true);
        getTasks(selectedSalesRep, filter).then((res) => {
          setIsLoading(true);
          if (dataStatus === "filterFetch") {
            if (res.length === 0) {
              setCompletedTasks(res);
            } else {
              setCompletedTasks(res);
            }
          } else {
            if (res.length === 0) {
              setTaskFetchCompleted({
                ...taskFetchCompleted,
                completedTasks: true,
              });
            } else {
              setCompletedTasks((prev) => [...prev, ...res]);
            }
          }
          setIsLoading(false);
          setIsLoadingNewDataCompleted(false);
        });
      }
    }
  };

  const getTodaysTasks = async () => {
    const data = await getTasksTodayCount();
    const currentDate = new Date();
    const sortedByDateTasks = data
      .filter((elm) => elm.status)
      .sort(function (a, b) {
        return new Date(a.dueDate) - new Date(b.dueDate);
      });

    let upcomingTaskCount = 0;
    let pastDueTaskCount = 0;
    let completedTaskCount = 0;

    sortedByDateTasks.forEach((elm) => {
      if (!elm.status) return;
      const startDate = moment().startOf("day");
      const endDate = moment().endOf("day");
      const dueDate = moment(elm.dueDate);
      if (!completedTasksStatus.includes(elm.status)) {
        if (startDate <= dueDate && endDate >= dueDate) {
          upcomingTaskCount++;
        } else {
          pastDueTaskCount++;
        }
      } else {
        completedTaskCount++;
      }
    });
    const totalTodaysTasks = data.filter((elm) => elm.status).length;
    setTodayTasks({
      todaysUpcomingTask: upcomingTaskCount,
      todaysPastDueTask: pastDueTaskCount,
      todaysCompletedTask: completedTaskCount,
      totalTodaysTasks,
    });
  };

  useEffect(() => {
    if (upcomingTasks && pastDueTasks && completedTasks) {
      getTodaysTasks();
    }
  }, []);

  useEffect(() => {
    const fetchAllTasks = async () => {
      if (selectedSalesRep === null) return;
      setIsLoading(true);

      setTaskFetchCompleted({
        upcomingTasks: false,
        pastDueTasks: false,
        completedTasks: false,
      });

      try {
        fetchCompletedTasks();
        fetchUpComingTasks();
        fetchPastDueTasks();
      } catch (error) {
        console.error("Error fetching tasks:", error);
      } finally {
        setTimeout(() => {
          setIsLoading(false);
        }, 1500);
      }
    };

    if (isInitialized) {
      fetchAllTasks();
    }
  }, [selectedSalesRep, selectedTaskType, selectedDurationDueDate]);

  useEffect(() => {
    getAllSalesRepByProject(projectId).then((res) => {
      setSalesRepData([
        ...res,
        { _id: "all", firstName: "All", lastName: "Sales Reps" },
      ]);
    });
  }, []);

  useEffect(() => {
    if (selectedTask?.buyer?.id) {
      getUsers(selectedTask.buyer.id).then((res) => {
        setContact({ ...res, id: res?._id });
        setSelectedContact(res?._id);
        setSelectedContactObject({ ...res, id: res?._id });
      });
    }
  }, [selectedTask]);

  useEffect(() => {
    const id = setTimeout(() => {
      fetchUpComingTasks(0, 30, "filterFetch", text),
        fetchPastDueTasks(0, 30, "filterFetch", text),
        fetchCompletedTasks(0, 30, "filterFetch", text);
    }, 800);
    return () => clearTimeout(id);
  }, [text]);

  // const TaskManageDropDown = ({ options }) => {
  //   const items = useMemo(() => {
  //     return options.map((option, index) => {
  //       return {
  //         key: index,
  //         label: (
  //           <span
  //             className='flex items-center gap-2'
  //             onClick={() => handleClickEvent(option.value)}
  //           >
  //             {option.label}
  //           </span>
  //         ),
  //       };
  //     });
  //   }, [options]);
  //   return (
  //     <Dropdown
  //       menu={{ items }}
  //       placement='bottomCenter'
  //       className='px-4 py-2 flex items-center gap-x-2 rounded bg-white border border-[#2E2B2E] border-opacity-[15%] cursor-pointer'
  //     ></Dropdown>
  //   );
  // };

  const TaskCard = forwardRef(({ task, tasksKey }, ref) => {
    const { _id, title, dueDate, status, buyer, user, assignedTo } = task;
    const [isHovered, setIsHovered] = useState(false);

    return (
      <div
        ref={ref}
        key={_id}
        className="w-full relative task p-3 md:p-4 bg-white rounded cursor-pointer border border-zinc-800 border-opacity-5 hover:bg-[#F9F4C9] hover:border-[#EDDF65] hover:border-opacity-100 justify-start items-start gap-4 inline-flex transition-all"
        onClick={() => {
          setSelectedTask({ ...task });
          setIsTaskDrawerOpen(true);
        }}
        onMouseOver={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
      >
        <div className="cursor-pointer">
          {completedTasksStatus.includes(status) ? (
            <CompletedTaskIcon />
          ) : (
            <PendingTaskIcon />
          )}
        </div>

        <div className="grow shrink basis-0 flex-col justify-start items-start gap-2 inline-flex relative">
          <div className="self-stretch text-zinc-800 text-lg font-semibold leading-normal">
            {title}
          </div>
          {buyer?.firstName?.length > 0 && (
            <div className="self-stretch justify-start items-center gap-2 inline-flex">
              <ContactIcon />
              <div className="px-2 py-1 bg-[#F0EEE9] rounded-md justify-center items-center gap-1 flex">
                <div className="w-[22px] h-[22px] relative bg-zinc-800 bg-opacity-20 rounded-full flex items-center justify-center text-xs">
                  {buyer.firstName[0]}
                  {buyer.lastName[0]}
                </div>
                <div className="text-zinc-800 text-sm font-normal leading-[21px]">
                  {buyer.firstName + " " + buyer.lastName}
                </div>
              </div>
            </div>
          )}
          <div className="self-stretch justify-start items-center gap-2 inline-flex">
            <CalenderIcon />
            <div
              className={`px-2 py-1 ${
                tasksKey === "pastDueTasks"
                  ? "bg-[#FF3D0040]/25"
                  : "bg-[#F0EEE9]"
              } rounded-md justify-center items-center gap-1 flex`}
            >
              <div className="text-zinc-800 text-sm font-normal leading-[21px]">
                {moment(dueDate).format("MMM DD")}
              </div>
            </div>
          </div>
          {assignedTo?.firstName?.length > 0 && (
            <div className="self-stretch justify-start items-center gap-2 inline-flex">
              <ContactIcon />
              <div className="px-2 py-1 bg-[#F0EEE9] rounded-md justify-center items-center gap-1 flex">
                <div className="w-[22px] h-[22px] relative bg-zinc-800 bg-opacity-20 rounded-full flex items-center justify-center text-xs">
                  {assignedTo.firstName[0]}
                  {assignedTo.lastName[0]}
                </div>
                <div className="text-zinc-800 text-sm font-normal leading-[21px]">
                  {assignedTo.firstName + " " + assignedTo.lastName}
                </div>
              </div>
            </div>
          )}
          {view === 'corporate' && task.project && (
            <div className="self-stretch justify-start items-center gap-2 inline-flex">
              <div
              className="w-[22px] h-[22px] font-bold relative bg-zinc-800 bg-opacity-20 rounded-md flex items-center justify-center text-xs"
              >
                P
              </div>
              <div className="px-2 py-1 bg-[#F0EEE9] rounded-md justify-center items-center gap-1 flex">
                <div className="text-zinc-800 text-sm font-normal leading-[21px]">
                  {task?.project?.projectName}
                </div>
              </div>
            </div>
          )}
          {isHovered && (
            <div className="flex self-end mt-1 flex-row z-[9999] gap-y-2 bottom-0 right-0">
              {!completedTasksStatus.includes(status) && (
                <CustomButton
                  variant="ghost_2"
                  className=""
                  handleClick={(e) => {
                    setSelectedTask({ ...task });
                    setIsMarkCompleteConfirmationModalOpen(true);
                    e.stopPropagation();
                  }}
                >
                  <Tooltip title="Mark as complete">
                    <span className="font-medium py-2 px-4 text-base flex gap-2 items-center">
                      <CheckCircle className="text-gray-600" />
                    </span>
                  </Tooltip>
                </CustomButton>
              )}
              <CustomButton
                variant="ghost_2"
                handleClick={(e) => {
                  setSelectedTask({ ...task });
                  setIsCreateOrUpdateTaskModalOpen(true);
                  e.stopPropagation();
                }}
              >
                <Tooltip title="Edit">
                  <span className="font-medium p-2 px-3 text-base flex gap-2 items-center">
                    <Edit className="text-gray-600" />
                  </span>
                </Tooltip>
              </CustomButton>
              <CustomButton
                variant="ghost_2"
                onClick={(e) => {
                  setSelectedTask({ ...task });
                  setIsDeleteModal(true);
                  e.stopPropagation();
                }}
              >
                <Tooltip title="Delete">
                  <span className="font-medium p-2 px-3 text-base flex gap-2 items-center">
                    <TrashIcon className="text-gray-600" />
                  </span>
                </Tooltip>
              </CustomButton>
            </div>
          )}
        </div>
      </div>
    );
  });

  const TaskTab = useCallback(
    ({
      title,
      buttonText,
      buttonAction,
      tasksKey,
      taskData,
      isLoading,
      isLoadingNewData,
      isShowHeader = false,
      fetchUpComingTasks,
      fetchPastDueTasks,
      fetchCompletedTasks,
      taskFetchCompleted,
    }) => {
      const observer = useRef();
      const firstTaskRef = useRef(null);
      const [scrollToTop, setScrollToTop] = useState(false);
      const [showScrollButton, setShowScrollButton] = useState(false);
      const [upcomingTaskPage, setUpcomingTaskPage] = useState(0);
      const [pastDueTaskPage, setPastDueTaskPage] = useState(0);
      const [completedTaskPage, setCompletedTaskPage] = useState(0);
      useEffect(() => {
        const handleScroll = () => {
          if (firstTaskRef.current) {
            const { top } = firstTaskRef.current.getBoundingClientRect();
            setShowScrollButton(top < 0);
          }
        };

        const scrollableArea = document.getElementById(tasksKey);
        if (scrollableArea) {
          scrollableArea.addEventListener("scroll", handleScroll);
        }
        return () => {
          if (scrollableArea) {
            scrollableArea.removeEventListener("scroll", handleScroll);
          }
        };
      }, [tasksKey]);

      useLayoutEffect(() => {
        if (scrollToTop && firstTaskRef.current) {
          firstTaskRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
          setScrollToTop(false);
        }
      }, [scrollToTop]);

      const handleScrollToTop = () => {
        setScrollToTop(true);
      };
      const fetchMoreTasks = useCallback(() => {
        const itemsPerPage = isMobile ? 10 : 30;
        if (tasksKey === "upcomingTasks" && !taskFetchCompleted.upcomingTasks) {
          fetchUpComingTasks(upcomingTaskPage + 1, itemsPerPage, "newData");
          setUpcomingTaskPage((prev) => prev + 1);
        } else if (
          tasksKey === "pastDueTasks" &&
          !taskFetchCompleted.pastDueTasks
        ) {
          fetchPastDueTasks(pastDueTaskPage + 1, itemsPerPage, "newData");
          setPastDueTaskPage((prev) => prev + 1);
        } else if (
          tasksKey === "completedTasks" &&
          !taskFetchCompleted.completedTasks
        ) {
          fetchCompletedTasks(completedTaskPage + 1, itemsPerPage, "newData");
          setCompletedTaskPage((prev) => prev + 1);
        }
      }, [
        tasksKey,
        upcomingTaskPage,
        pastDueTaskPage,
        completedTaskPage,
        taskFetchCompleted,
        fetchUpComingTasks,
        fetchPastDueTasks,
        fetchCompletedTasks,
      ]);
      const lastTaskRef = useCallback(
        (node) => {
          if (observer.current) observer.current.disconnect();
          observer.current = new IntersectionObserver(
            (entries) => {
              if (entries[0].isIntersecting && !isLoadingNewData) {
                fetchMoreTasks();
              }
            },
            {
              root: null,
              threshold: 0.1,
            }
          );
          if (node) observer.current.observe(node);
          return () => {
            if (observer.current) {
              observer.current.disconnect();
            }
          };
        },
        [isLoadingNewData, fetchMoreTasks]
      );

      useLayoutEffect(() => {
        if (!isMobile) {
          const lastTaskElement = document.querySelector(".task:last-child");
          if (lastTaskElement) {
            lastTaskElement.scrollIntoView({
              behavior: "smooth",
              block: "end",
            });
          }
        }
      }, [taskData]);

      return (
        <div
          key={`${tasksKey}-tab`}
          className="w-full lg:w-1/3 h-full flex flex-col gap-y-4"
        >
          {isShowHeader && (
            <Header
              title={title}
              handleScrollTop={handleScrollToTop}
              showScrollButton={showScrollButton}
              count={taskData?.length ?? 0}
              buttonText={buttonText}
              buttonAction={buttonAction}
            />
          )}
          <div
            id={tasksKey}
            className="flex-1 w-full flex flex-col gap-y-4  overflow-y-auto"
          >
            {isLoading ? (
              <div className="h-full py-12 text-base font-normal text-center flex justify-center items-center">
                <Loader />
              </div>
            ) : (
              <>
                {taskData?.length ? (
                  <>
                    {taskData.map((task, index) => {
                      if (
                        taskData.length === index + 4 &&
                        taskData.length > 4
                      ) {
                        return (
                          <>
                            <TaskCard
                              key={task._id}
                              task={task}
                              tasksKey={tasksKey}
                              ref={lastTaskRef}
                            />
                          </>
                        );
                      } else {
                        return (
                          <TaskCard
                            ref={index === 0 ? firstTaskRef : null}
                            key={task._id}
                            task={task}
                            tasksKey={tasksKey}
                          />
                        );
                      }
                    })}
                    {isLoadingNewData && (
                      <div className="h-12 text-base font-normal text-center flex justify-center items-center">
                        <Loader />
                      </div>
                    )}
                  </>
                ) : (
                  <div className="py-12 text-base font-normal text-center">
                    No
                    <span className="lowercase px-1">{title}</span>
                    tasks
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      );
    },
    []
  );

  const LeadStatusStats = () => {
    const {
      todaysUpcomingTask,
      todaysPastDueTask,
      todaysCompletedTask,
      totalTodaysTasks,
    } = todayTasks;

    const tasksData = [
      { name: "Pending", count: todaysUpcomingTask || 0 },
      { name: "Completed", count: todaysCompletedTask || 0 },
    ];
    return (
      <div className="bg-white border border-[#E4E4E7] shadow-sm rounded-2xl p-4 md:p-6 flex flex-col gap-y-4 md:gap-y-6">
        <div className="flex items-center gap-x-8">
          <div>
            <h5 className="text-sm sm:text-lg font-semibold mb-0">Today</h5>
          </div>
          <div className="flex-1 h-[10px] sm:h-[24px] flex gap-1 items-center rounded-lg overflow-hidden">
            {tasksData.length > 0 &&
              tasksData.map((item, index) => {
                return (
                  <div
                    className="h-full"
                    style={{
                      width: `${(item.count / totalTodaysTasks) * 100}%`,
                      backgroundColor: color[index] || "#000000",
                    }}
                  />
                );
              })}
          </div>
        </div>
        <div className="flex-1 flex items-center gap-x-4 gap-y-6 flex-wrap overflow-hidden">
          <div
            className={`flex flex-col md:w-[200px] gap-y-1 pl-4 ${"border-l border-[#2E2B2E] border-opacity-25"} overflow-hidden`}
          >
            <div className="flex gap-2">
              <svg
                className="mt-1"
                width="15"
                height="15"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="6" cy="6" r="6" fill={color[3] ?? "#000000"} />
              </svg>
              <h6 className="flex-1 truncate text-[12px] md:text-sm mb-0 font-medium">
                Total Tasks
              </h6>
            </div>
            <p className="text-xl mb-0 font-bold">{totalTodaysTasks}</p>
          </div>
          {tasksData.map((type, index) => (
            <div
              key={index}
              className={`flex flex-col md:w-[200px] gap-y-1 pl-4 ${"border-l border-[#2E2B2E] border-opacity-25"} overflow-hidden`}
            >
              <div className="flex gap-2">
                <svg
                  className="mt-1"
                  width="15"
                  height="15"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="6"
                    cy="6"
                    r="6"
                    fill={color[index] ?? "#000000"}
                  />
                </svg>
                <h6 className="flex-1 truncate text-[12px] md:text-sm mb-0 font-medium">
                  {type.name}
                </h6>
              </div>
              <p className="text-xl mb-0 font-bold">{type.count}</p>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const CustomTaskModal = useCallback(() => {
    return (
      <TaskModal
        show={isCreateOrUpdateTaskModalOpen}
        setClose={() => setIsCreateOrUpdateTaskModalOpen(false)}
        task={selectedTask}
        refetch={(task) => {
          fetchCompletedTasks();
          fetchPastDueTasks();
          fetchUpComingTasks();
          getTodaysTasks();
          if (task?._id) {
            setSelectedTask(task);
          }
        }}
      />
    );
  }, [isCreateOrUpdateTaskModalOpen, user, selectedTask]);

  const onMarkComplete = async () => {
    setIsMarkCompleteLoading(true);
    try {
      await createOrUpdateTask({ ...selectedTask, status: "closed" }).then(
        (res) => {
          fetchCompletedTasks();
          fetchPastDueTasks();
          fetchUpComingTasks();
          setSelectedTask({
            ...selectedTask,
            status: res.status,
          });
          setIsMarkCompleteConfirmationModalOpen(false);
          setIsMarkCompleteLoading(false);
          setIsTaskDrawerOpen(false);
        }
      );
    } catch (err) {
      setIsMarkCompleteConfirmationModalOpen(false);
      setIsMarkCompleteLoading(false);
      setIsTaskDrawerOpen(false);
    }
  };

  const MarkCompleteConfirmationModal = useCallback(
    () => (
      <CustomModal
        isOpen={isMarkCompleteConfirmationModalOpen}
        closeModal={() => setIsMarkCompleteConfirmationModalOpen(false)}
        contentLabel="Mark Complete Confirmation Modal"
      >
        <div className="p-5 md:p-8 max-w-[95vw] md:max-w-[500px]">
          <div className="pb-3.5">
            <h3 className="text-xl font-semibold">Are you sure?</h3>
            <p className="text-lg font-normal">
              Do you want to mark complete "{selectedTask?.title}"?
            </p>
          </div>
          <div className="w-full flex justify-end items-center gap-2">
            <CustomButton
              className="w-1/2 md:w-fit"
              variant="outline"
              handleClick={() => setIsMarkCompleteConfirmationModalOpen(false)}
            >
              <span className="font-semibold px-4 py-2 text-base">No</span>
            </CustomButton>
            <CustomButton
              className="w-1/2 md:w-fit"
              handleClick={onMarkComplete}
            >
              <span className="font-semibold px-4 py-2 text-base flex items-center gap-2">
                {isMarkCompleteLoading && (
                  <Oval height='18' width='18' color='white' />
                )}
                Yes, I do
              </span>
            </CustomButton>
          </div>
        </div>
      </CustomModal>
    ),
    [isMarkCompleteConfirmationModalOpen, selectedTask, isMarkCompleteLoading]
  );

  return (
    <>
      <div className="py-4 lg:py-6 px-4 lg:px-10 flex flex-col lg:flex-row gap-4 lg:gap-12 bg-zinc-100">
        {isMobile ? (
          <>
            <div className="w-full flex items-center justify-between">
              <h3 className="text-xl mb-0">Tasks</h3>
              <CustomButton
                variant="outline"
                handleClick={() => {
                  setSelectedTask({});
                  setIsTaskDrawerOpen(false);
                  setIsCreateOrUpdateTaskModalOpen(true);
                }}
              >
                <span className="font-semibold px-4 py-2 text-base">Add</span>
              </CustomButton>
            </div>
            <LeadStatusStats />
            <div className="flex flex-wrap items-end gap-x-12 w-full">
              <div className="flex w-full flex-col gap-y-2">
                <label className="text-softBlack70 text-xs font-medium uppercase">
                  Search Task
                </label>
                <input
                  value={text}
                  onChange={(e) => setText(e.currentTarget.value)}
                  className="bg-white !mb-0 border border-[rgb(0,0,0,0.4)] h-[50px] p-2 rounded w-full md:w-[400px] text-softBlack"
                  placeholder="Search"
                />
              </div>

              <div className="w-full md:w-[200px] mt-6 flex flex-col gap-y-1">
                <CustomSelect
                  label="Select Sales Rep"
                  value={
                    selectedSalesRep === null
                      ? "All Sales Rep"
                      : selectedSalesRep
                  }
                  handleChange={(value) => {
                    setSelectedSalesRep(value);
                  }}
                  options={salesRepData}
                  isName
                />
              </div>
              <div className="w-full md:w-[200px] mt-6 flex ">
                <CustomSelect
                  label="Task Type *"
                  value={
                    selectedTaskType === ""
                      ? "Select Task Type"
                      : selectedTaskType
                  }
                  handleChange={(value) => {
                    setSelectedTaskType(value);
                  }}
                  options={taskTypes}
                />
              </div>
              <div className="w-full md:w-[200px] mt-6 flex ">
                <CustomSelect
                  label="Filter By Due Date *"
                  value={
                    selectedDurationDueDate === ""
                      ? "Select Duration"
                      : selectedDurationDueDate
                  }
                  handleChange={(value) => {
                    setSelectedDurationDueDate(value);
                  }}
                  options={durationType}
                />
              </div>
            </div>
            <div className="md:w-full items-center text-center flex flex-wrap gap-y-1 md:gap-x-2 p-1 rounded-md bg-white">
              {tabMenus.map((tab, index) => (
                <div
                  key={index}
                  onClick={() => {
                    setSelectedTab(tab);
                  }}
                  className={`flex flex-1 w-full md:w-fit text-center justify-center gap-1 items-center just py-[6px] cursor-pointer rounded-[4px] font-semibold text-[14px] ${
                    selectedTab.id === tab.id
                      ? "bg-softBlack text-white shadow-sm"
                      : "text-zinc-500"
                  } transition-all truncate`}
                >
                  {tab.name}{" "}
                  <span className="text-xs">
                    (
                    {tab.id === "upcomingTasks"
                      ? upcomingTasks?.length
                      : tab.id === "pastDueTasks"
                      ? pastDueTasks?.length
                      : completedTasks?.length}
                    )
                  </span>
                </div>
              ))}
            </div>
            <div className="flex overflow-hidden">
              <TaskTab
                taskData={
                  selectedTab?.id === "upcomingTasks" && upcomingTasks
                    ? upcomingTasks
                    : selectedTab?.id === "pastDueTasks" && pastDueTasks
                    ? pastDueTasks
                    : selectedTab?.id === "completedTasks" && completedTasks
                    ? completedTasks
                    : []
                }
                title={selectedTab?.name}
                tasksKey={selectedTab?.id}
                isLoading={isLoading}
                taskFetchCompleted={taskFetchCompleted}
                fetchPastDueTasks={fetchPastDueTasks}
                fetchCompletedTasks={fetchCompletedTasks}
                fetchUpComingTasks={fetchUpComingTasks}
                isLoadingNewData={
                  selectedTab?.id === "pastDueTasks" && isLoadingNewDataPastDue
                    ? isLoadingNewDataPastDue
                    : selectedTab?.id === "completedTasks" &&
                      isLoadingNewDataCompleted
                    ? isLoadingNewDataCompleted
                    : isLoadingNewDataUpComing
                }
              />
            </div>
          </>
        ) : (
          <div className="flex flex-col w-full">
            <LeadStatusStats />
            <div className="flex flex-wrap items-end gap-x-12 gap-y-4 w-full">
              <div className="flex flex-col gap-y-2">
                <label className="text-softBlack70 text-xs font-medium uppercase">
                  Search Task
                </label>
                <input
                  value={text}
                  onChange={(e) => setText(e.currentTarget.value)}
                  className="bg-white !mb-0 border border-[rgb(0,0,0,0.4)] h-[50px] p-2 rounded w-full md:w-[400px] text-softBlack"
                  placeholder="Search"
                />
              </div>

              <div className="w-[200px] mt-6 flex flex-col gap-y-1">
                <CustomSelect
                  label="Select Sales Rep"
                  value={
                    selectedSalesRep === "" ? "All Sales Rep" : selectedSalesRep
                  }
                  handleChange={(value) => {
                    setSelectedSalesRep(value);
                  }}
                  options={salesRepData}
                  isName
                />
              </div>
              <div className="w-[200px] mt-6 flex ">
                <CustomSelect
                  label="Task Type *"
                  value={
                    selectedTaskType === ""
                      ? "Select Task Type"
                      : selectedTaskType
                  }
                  handleChange={(value) => {
                    setSelectedTaskType(value);
                  }}
                  options={taskTypes}
                />
              </div>
              <div className="w-[200px] mt-6 flex ">
                <CustomSelect
                  label="Filter By Due Date *"
                  value={
                    selectedDurationDueDate === ""
                      ? "Select Duration"
                      : selectedDurationDueDate
                  }
                  handleChange={(value) => {
                    setSelectedDurationDueDate(value);
                  }}
                  options={durationType}
                />
              </div>
              {view !== "corporate" && (
                <div className="flex flex-1 justify-end mr-8 lg:mr-16">
                  <CustomButton
                    handleClick={() => {
                      setSelectedTask({});
                      setIsTaskDrawerOpen(false);
                      setIsCreateOrUpdateTaskModalOpen(true);
                    }}
                    className=""
                  >
                    <span className="font-semibold px-4 py-2 rounded text-base">
                      Add Task
                    </span>
                  </CustomButton>
                </div>
              )}
            </div>
            <div className="pt-5 lg:pt-8 flex flex-col md:flex-row gap-4 lg:gap-12 h-[calc(100vh-64px)]">
              <TaskTab
                title="Upcoming"
                taskData={upcomingTasks}
                taskFetchCompleted={taskFetchCompleted}
                tasksKey="upcomingTasks"
                isLoading={isLoading}
                isLoadingNewData={isLoadingNewDataUpComing}
                fetchUpComingTasks={fetchUpComingTasks}
                isShowHeader
              />
              <TaskTab
                title="Past due"
                taskData={pastDueTasks}
                tasksKey="pastDueTasks"
                isLoading={isLoading}
                taskFetchCompleted={taskFetchCompleted}
                isShowHeader
                fetchPastDueTasks={fetchPastDueTasks}
                isLoadingNewData={isLoadingNewDataPastDue}
              />
              <TaskTab
                title="Complete"
                taskData={completedTasks}
                tasksKey="completedTasks"
                isLoading={isLoading}
                taskFetchCompleted={taskFetchCompleted}
                isShowHeader
                fetchCompletedTasks={fetchCompletedTasks}
                isLoadingNewData={isLoadingNewDataCompleted}
              />
            </div>
          </div>
        )}
      </div>
      <TaskDetailsDrawer
        isTaskDrawerOpen={isTaskDrawerOpen}
        handleTaskDrawerClose={() => {
          setIsTaskDrawerOpen(false);
          setSelectedTask({});
          setContact({});
          setSelectedContact("");
          setSelectedContact(null);
        }}
        setIsDeleteModal={setIsDeleteModal}
        selectedTask={selectedTask}
        isInfoDrawerOpen={isInfoDrawerOpen}
        setIsInfoDrawerOpen={setIsInfoDrawerOpen}
        handleInfoDrawerOpen={() => setIsInfoDrawerOpen(false)}
        contact={contact}
        setIsMarkCompleteConfirmationModalOpen={
          setIsMarkCompleteConfirmationModalOpen
        }
        setIsCreateOrUpdateTaskModalOpen={setIsCreateOrUpdateTaskModalOpen}
      />
      <CustomTaskModal />
      <MarkCompleteConfirmationModal />
      <DeleteModal
        title="Confirm deletion?"
        description="This task will be deleted permanently!"
        confirmButtonTitle="Delete Task"
        cancelButtonTitle="Cancel"
        isOpen={isDeleteModal}
        setIsOpen={setIsDeleteModal}
        onConfirm={deleteTheTask}
        loading={deleteLoading}
      />
    </>
  );
};

export default Tasks;
