/* eslint-disable */
// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { api } from 'services/api';
import { getBuildingsByProjectId } from 'store/actions/buildingActions';
import CustomSelectBuilding from 'components/Inventory/components/CustomSelect';
import useInventory from 'components/Inventory/hooks/useInventory';
import CustomParkingDetail from 'pages/desktopMode/buildings/components/CustomParkingDetail';
const ShadCNWrapper = ({ children, className = '' }) => (
  <div
    className={`rounded-xl border bg-card text-card-foreground shadow bg-white overflow-hidden ${className}`}
  >
    {children}
  </div>
);

const CardUI = ({ title, number, Icon }) => (
  <ShadCNWrapper>
    <div className='p-6 flex flex-row items-center justify-between space-y-0 pb-2'>
      <h3 className='tracking-tight text-sm font-medium truncate'>{title}</h3>
      {Icon}
    </div>
    <div className='p-6 pt-0'>
      <div className='text-2xl font-bold'>{number}</div>
    </div>
  </ShadCNWrapper>
);

const HorizontalCardUI = ({ title, number, Icon }) => (
  <div className='bg-white w-full gap-y-3 basis-1/2 z-99 flex flex-col top-20 justify-between'>
    <div className='flex items-center justify-between text-[14px] font-medium uppercase'>
      {title}
      {Icon}
    </div>
    <div className='text-[24px] font-bold'>{number}</div>
  </div>
);

const NotFoundView = ({ text }) => (
  <div className='p-4 py-6 w-full flex justify-center items-center text-xl font-semibold'>
    {text}
  </div>
);

const TabWrapper = ({ tabMenus, setSelectedTab, selectedTab }) => (
  <div className='w-full flex items-center md:justify-center rounded-lg bg-white p-1 text-zinc-600'>
    {tabMenus.map((tab, index) => (
      <div
        key={index}
        onClick={() => {
          setSelectedTab(tab.id);
        }}
        className={`flex flex-1 w-full min-w-[110px] md:w-fit md:min-w-[160px] max-w-[180px] text-center justify-center items-center just px-3 py-[6px] cursor-pointer rounded-[4px] font-semibold text-[12px] ${
          selectedTab === tab.id
            ? 'bg-[#18181B] text-white shadow-sm'
            : 'text-zinc-600 hover:bg-zinc-100'
        } transition-all`}
      >
        {tab.name}
      </div>
    ))}
  </div>
);

const HorizontalLine = ({ className }) => (
  <div className={`w-full h-[1px] bg-zinc-200 ${className}`} />
);
const VerticleLine = ({ className }) => (
  <div className={`w-[1px] bg-zinc-200 ${className}`} />
);
const DashboardTab = ({ totalSales }) => {
  const projectId = useSelector((state) => state.appReducer.appProject);
  const [allParkings, setAllParkings] = useState([]);
  const [assignedParkingData, setAssignedParkingData] = useState([]);
  const [assignedParkingDataIsMore, setAssignedParkingDataIsMore] =
    useState(false);
  const {
    state: { statusColors },
  } = useInventory();

  const [stallSoldCount, setStallSoldCount] = useState(0);
  const [parkingDataByUnit, setParkingDataByUnit] = useState([]);
  const [parkingByUnitIsMore, setParkingByUnitIsMore] = useState(false);
  const [assignedParkings, setAssignedParkings] = useState(0);
  //   const [parkingRevenue, setParkingRevenue] = useState(0);
  const [parkingInventoryType, setParkingInventoryType] = useState([]);
  const [parkingAllocateInventoryType, setParkingAllocateInventoryType] =
    useState(null);
  const [totalAllocatedParking, setTotalAllocatedParking] = useState(0);
  const [totalParking, setTotalParking] = useState(0);
  const [selectedBuilding, setSelectedBuilding] = useState('');
  const [buildingOptions, setBuildingOptions] = useState([]);
  useEffect(() => {
    if (projectId.length > 0) {
      getBuildingsByProjectId(projectId)
        .then((buildings) => {
          const temp = buildings?.map((building) => ({
            value: building?._id,
            label: building?.name,
          }));
          setBuildingOptions(temp);
          setSelectedBuilding(temp?.length > 0 ? temp[0]?.value : '');
        })
        .catch((error) => console.error(error));
    }
  }, [projectId]);
  const parkingInventoryColors = ['black', 'grey'];
  const currencyFormate = (value) => {
    if (value == null) return undefined;
    else {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });

      const formattedValue = formatter.format(value);
      return formattedValue;
    }
  };
  const getAssignedParkingsFromUnit = async () => {
    if (selectedBuilding === '') return;
    const { data } = await api.get(`/parking/building/${selectedBuilding}`);
    if (data.length > 0) {
      let assignedParkings = data.filter((parking) => {
        return parking.unit;
      });
      setAllParkings(data);
      setAssignedParkingData(assignedParkings);
      setAssignedParkings(assignedParkings.length);
      const counts = assignedParkings.reduce((acc, obj) => {
        const { unit } = obj;
        const { _id, unitNumber } = unit;

        if (!acc[_id]) {
          acc[_id] = { count: 1, unitNumber };
        } else {
          acc[_id].count++;
        }
        return acc;
      }, {});
      const unitCountArray = Object.entries(counts).map(
        ([_id, { count, unitNumber }]) => ({
          _id,
          count,
          unitNumber,
        })
      );
      const unitByBuilding = await api.get(
        `/units/building/${selectedBuilding}`,
        {},
        { responseType: 'json' }
      );
      const parkingsDataByUnit = unitByBuilding.data.data.map((item, index) => {
        return {
          unitNumber: item.unitNumber,
          key: index,
          parkings:
            (item?.numberParkingIncluded || 0) +
            (item?.numberParkingOptional || 0),
        };
      });
      unitCountArray.forEach(({ unitNumber, count }) => {
        const unit = parkingsDataByUnit.find(
          (item) => item.unitNumber === unitNumber
        );
        if (unit) {
          unit.parkings -= count;
          if (Math.sign(unit.parkings === -1)) unit.parkings = 0;
        }
      });
      setParkingDataByUnit(parkingsDataByUnit);
    }
  };
  const getParkingInventoryDataByBuilding = async () => {
    if (selectedBuilding === '') return;
    const { data } = await api.get(
      `/parking/${selectedBuilding}/inventory`,
      {},
      { responseType: 'json' }
    );
    if (data.data.length > 0) {
      const totalParkings = data.data[0].totalParkings;
      const parkingInventoryTypes = data.data[0].options.map((op, index) => {
        return {
          type: op.name,
          key: op._id,
          count: op.value.map((v) => v).reduce((a, b) => a + b, 0),
        };
      });
      setParkingInventoryType(parkingInventoryTypes);
      setTotalParking(totalParkings);
    }
  };
  const getParkingAllocateInventoryDataByBuilding = async () => {
    if (selectedBuilding === '') return;
    const { data } = await api.get(
      `/parking/${selectedBuilding}/allocateInventory`,
      {},
      { responseType: 'json' }
    );
    if (data.data.length > 0) {
      setParkingAllocateInventoryType(data.data[0]);
      const totalAllocatedParking = data.data[0].options.reduce((sum, obj) => {
        const objSum = obj.parkingTypes.reduce((objSum, parkingType) => {
          const typeSum = parkingType.options.reduce(
            (typeSum, option) => typeSum + Number(option.value),
            0
          );
          return objSum + typeSum;
        }, 0);
        return sum + objSum;
      }, 0);
      setTotalAllocatedParking(totalAllocatedParking);
    }
  };
  const parkingTypes = [
    {
      type: 'Allocated',
      count: totalAllocatedParking,
    },
    {
      type: 'Assigned',
      count: assignedParkings,
    },
    {
      type: 'Available',
      count:
        Math.sign(totalParking - totalAllocatedParking - assignedParkings) === 1
          ? totalParking - totalAllocatedParking - assignedParkings
          : 0,
    },
    {
      type: 'Rescission',
      count: 0,
    },
    {
      type: 'Reserved',
      count: 0,
    },
    {
      type: 'Sold',
      count: stallSoldCount,
    },
  ];

  useEffect(() => {
    getParkingInventoryDataByBuilding();
    getParkingAllocateInventoryDataByBuilding();
    getAssignedParkingsFromUnit();
  }, [selectedBuilding]);
  return (
    <div className='p-8 w-full bg-white'>
      <div className='flex w-full justify-between'>
        <div className='w-[200px] flex flex-col gap-y-3'>
          <div className='text-[18px] font-bold'>Select Building</div>
          <CustomSelectBuilding
            value={selectedBuilding}
            handleChange={(newValue) => setSelectedBuilding(newValue)}
            options={buildingOptions}
            placeholder='Select Building'
          />
        </div>
        <div className='w-full justify-end p-4 flex gap-x-14 h-full'>
          <VerticleLine className='!w-[2px]' />
          <div className='flex flex-col gap-y-3'>
            <h5 className='text-xl uppercase font-semibold mb-0 truncate'>
              Total Sales
            </h5>
            <div className='text-2xl font-bold'>
              {currencyFormate(totalSales)}
            </div>
          </div>
          <VerticleLine className='!w-[2px]' />
          <div className='justify-between flex-col items-start flex'>
            <h5 className='text-xl uppercase font-semibold mb-0 truncate'>
              Inventory
            </h5>
            <div className='px-4 pl-0 border-opacity-25 flex gap-x-4 flex-wrap justify-start items-start gap-[7.51px]'>
              {parkingInventoryType.length === 0 && 'Loading...'}
              {parkingInventoryType.map((parkingType, index) => {
                return (
                  <div key={index} className='flex'>
                    <div className='flex flex-col'>
                      <div className='justify-start items-center gap-[3.76px] inline-flex'>
                        <div className='justify-start items-start flex'>
                          <div
                            style={{
                              backgroundColor:
                                index % 2 === 0
                                  ? parkingInventoryColors[0]
                                  : parkingInventoryColors[1],
                            }}
                            className='w-[11.27px] h-[11.27px] rounded-full'
                          ></div>
                        </div>
                        <div className='text-zinc-950 text-[13.04px] font-medium leading-[18.78px]'>
                          {parkingType.type}
                        </div>
                      </div>
                      <div className='justify-start items-end gap-[5.63px] inline-flex'>
                        <div className='text-zinc-950 text-xl font-bold leading-[30.05px]'>
                          {parkingType.count}
                        </div>
                      </div>
                    </div>
                    {index !== parkingInventoryType.length - 1 && (
                      <div className='w-[1px] h-[44px] ml-4 bg-neutral-500'></div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <VerticleLine className='!w-[2px]' />
          <div className=' bg-white rounded-lg  flex flex-col gap-y-4'>
            <h5 className='text-xl uppercase font-semibold mb-0 truncate'>
              Allocation
            </h5>
            <div className='flex flex-wrap gap-x-6 gap-y-3'>
              {parkingAllocateInventoryType === null
                ? 'No Data Available'
                : parkingAllocateInventoryType.options.map((parking, index) => (
                    <div className='flex' key={index}>
                      <div className='flex flex-row gap-x-4 md:gap-x-0 md:flex-col'>
                        <div className='flex items-center gap-x-2'>
                          <div
                            style={{
                              backgroundColor:
                                index % 2 === 0
                                  ? parkingInventoryColors[0]
                                  : parkingInventoryColors[1],
                            }}
                            className={`rounded-full h-3 w-3`}
                          ></div>
                          <div className='text-sm col-span-2'>
                            {parking?.name}
                          </div>
                        </div>
                        <div className='text-xl col-span-2 font-bold'>
                          {parking.parkingTypes.reduce(
                            (sum, parkingType) =>
                              sum +
                              parkingType.options.reduce(
                                (subSum, option) =>
                                  subSum + Number(option.value),
                                0
                              ),
                            0
                          )}
                        </div>
                      </div>
                      {parkingAllocateInventoryType.options.length - 1 !=
                        index && (
                        <div className='w-[1px] h-[44px] ml-4 bg-[rgba(0,0,0,0.4)]'></div>
                      )}
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </div>
      <HorizontalLine className='my-5' />
      <div className='flex flex-col gap-y-6'>
        <div className='text-xl font-bold'>Report</div>
        {/* <HorizontalLine /> */}
        <ShadCNWrapper>
          <div className='p-6 grid grid-cols-10 col-span-10 gap-x-4'>
            <div className='col-span-10 flex flex-col gap-x-4'>
              <div className='h-full flex  flex-col gap-2 justify-start'>
                <h5 className='text-base uppercase font-semibold mb-0 truncate'>
                  Parking Inventory
                </h5>
                <h2 className='text-3xl font-bold mb-0'>
                  {totalParking || 0} Parking stalls
                </h2>
              </div>
              <div className='flex flex-1 w-[100%] mt-6 flex-col gap-6'>
                <div className='h-[27px] flex w-full gap-2 items-center rounded-md overflow-hidden'>
                  {parkingTypes.map((pt, index) => (
                    <div
                      key={index}
                      className='h-full'
                      style={{
                        width: `${(pt.count / totalParking) * 100}%`,
                        backgroundColor:
                          statusColors[pt.type.toLowerCase()]?.bgColor,
                      }}
                    />
                  ))}
                </div>
                <div className='flex flex-wrap items-center gap-y-2'>
                  {parkingTypes.map((elm, index) => (
                    <div
                      key={index}
                      className={`flex flex-col gap-y-1 px-4 ${
                        index !== 0
                          ? 'border-l border-[#2E2B2E] border-opacity-25'
                          : ''
                      } overflow-hidden`}
                    >
                      <div className='flex gap-1'>
                        <svg
                          className='mt-1'
                          width='11'
                          height='11'
                          viewBox='0 0 12 12'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <circle
                            cx='6'
                            cy='6'
                            r='6'
                            fill={statusColors[elm.type.toLowerCase()]?.bgColor}
                          />
                        </svg>
                        <h6 className='truncate text-sm mb-0 font-medium transition-all'>
                          {statusColors[elm.type.toLowerCase()]?.name}
                        </h6>
                      </div>
                      <p className='text-xl mb-0 font-bold'>{elm?.count}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </ShadCNWrapper>
        <div className='flex flex-col md:flex-row col-span-9 gap-6'>
          <ShadCNWrapper className='basis-1/2'>
            <div className='p-6 flex flex-col gap-y-2'>
              <h5 className='text-lg uppercase font-semibold mb-0 truncate'>
                Assignment PARKING - UNIT
              </h5>
              <div className='my-2 border-b border-zinc-200'>
                <ul className='flex'>
                  <li className='basis-1/3 leading-none text-sm text-left font-normal text-[#2E2B2E] text-opacity-70 capitalize'>
                    Parking Stall
                  </li>
                  <li className='basis-1/3 leading-none text-sm text-center font-normal text-[#2E2B2E] text-opacity-70 capitalize'>
                    Status
                  </li>
                  <li className='basis-1/3 leading-none text-sm text-center font-normal text-[#2E2B2E] text-opacity-70 capitalize'>
                    Unit
                  </li>
                </ul>
              </div>
              <div>
                {assignedParkingData === []
                  ? 'No Data Available'
                  : !assignedParkingDataIsMore
                  ? assignedParkingData.slice(0, 5).map((item, index) => {
                      return (
                        <ul key={index} className='flex'>
                          <li className='uppercase basis-1/3 text-[14px] font-semibold'>
                            Parking #{item.stall}
                          </li>
                          <li className='uppercase basis-1/3 text-center text-[14px] font-semibold'>
                            Assigned
                          </li>
                          <li className='uppercase basis-1/3 text-center text-[14px] font-semibold'>
                            {item?.unit?.unitNumber}
                          </li>
                        </ul>
                      );
                    })
                  : assignedParkingData.map((item, index) => {
                      return (
                        <ul key={index} className='flex'>
                          <li className='uppercase basis-1/3 text-[14px] font-semibold'>
                            Parking #{item.stall}
                          </li>
                          <li className='uppercase basis-1/3 text-center text-[14px] font-semibold'>
                            Assigned
                          </li>
                          <li className='uppercase basis-1/3 text-center text-[14px] font-semibold'>
                            {item?.unit?.unitNumber}
                          </li>
                        </ul>
                      );
                    })}
              </div>
              {assignedParkingData.length > 5 && (
                <div className='flex mx-auto mt-[10px]'>
                  <div
                    onClick={() => {
                      setAssignedParkingDataIsMore(!assignedParkingDataIsMore);
                    }}
                    className='cursor-pointer flex gap-x-2 items-center mx-auto'
                  >
                    <div className='text-[#2E2B2E] font-[300]'>
                      {!assignedParkingDataIsMore ? 'Show more' : 'Show less'}
                    </div>
                    <div>
                      {!assignedParkingDataIsMore ? (
                        <img src='/down-arrow.png' />
                      ) : (
                        <img src='/up-arrow.svg' />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </ShadCNWrapper>
          <ShadCNWrapper className='basis-1/2'>
            <div className='p-6 flex flex-col gap-y-2'>
              <h5 className='text-lg uppercase font-semibold mb-0 truncate'>
                Allocated
              </h5>
              <div className='my-2 border-b border-zinc-200'>
                <ul className='flex'>
                  <li className='basis-1/3 leading-none text-sm text-left font-normal text-[#2E2B2E] text-opacity-70 capitalize'>
                    unit
                  </li>
                  <li className='basis-1/3 leading-none text-sm text-center font-normal text-[#2E2B2E] text-opacity-70 capitalize'>
                    Status
                  </li>
                  <li className='basis-1/3 leading-none text-sm text-center font-normal text-[#2E2B2E] text-opacity-70 capitalize'>
                    Assignments
                  </li>
                </ul>
              </div>
              <div>
                {parkingDataByUnit === []
                  ? 'No Data Available'
                  : !parkingByUnitIsMore
                  ? parkingDataByUnit.slice(0, 5).map((item, index) => {
                      return (
                        <ul key={index} className='flex'>
                          <li className='uppercase basis-1/3 text-[14px] font-semibold'>
                            {item.unitNumber}
                          </li>
                          <li className='uppercase basis-1/3 text-center text-[14px] font-semibold'>
                            pending
                          </li>
                          <li className='uppercase basis-1/3 text-center text-[14px] font-semibold'>
                            {item?.parkings}
                          </li>
                        </ul>
                      );
                    })
                  : parkingDataByUnit.map((item, index) => {
                      return (
                        <ul key={index} className='flex'>
                          <li className='uppercase basis-1/3 text-[14px] font-semibold'>
                            {item.unitNumber}
                          </li>
                          <li className='uppercase basis-1/3 text-center text-[14px] font-semibold'>
                            pending
                          </li>
                          <li className='uppercase basis-1/3 text-center text-[14px] font-semibold'>
                            {item?.parkings}
                          </li>
                        </ul>
                      );
                    })}
              </div>
              {parkingDataByUnit.length > 5 && (
                <div className='flex mx-auto mt-[10px]'>
                  <div
                    onClick={() => {
                      setParkingByUnitIsMore(!parkingByUnitIsMore);
                    }}
                    className='cursor-pointer flex gap-x-2 items-center mx-auto'
                  >
                    <div className='text-[#2E2B2E] font-[300]'>
                      {!parkingByUnitIsMore ? 'Show more' : 'Show less'}
                    </div>
                    <div>
                      {!parkingByUnitIsMore ? (
                        <img src='/down-arrow.png' />
                      ) : (
                        <img src='/up-arrow.svg' />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </ShadCNWrapper>
        </div>
        {allParkings?.length > 0 ? (
          <ShadCNWrapper className='p-6 flex flex-col gap-6'>
            {allParkings?.map((item) => (
              <CustomParkingDetail key={item._id} parking={item} />
            ))}
          </ShadCNWrapper>
        ) : (
          <NotFoundView text='Parking not Found' />
        )}
      </div>
    </div>
  );
};

export default DashboardTab;
