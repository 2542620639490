import React from 'react'
import {
  EditIcon,
  TFilledEmailIcon,
  UnorderedListIcon,
  ScheduleIcon,
  TFilledPhoneIcon
} from 'components/Icons'
import '../../assets/css/main.css'

const IconContainer = ({ children }) => {
  return (
    <div className='p-1 rounded-md bg-gray-100 h-8 w-8 flex justify-center items-center cursor-pointer hover:shadow'>
      {children}
    </div>
  )
}

const ContactCard = (props) => {
  return (
    <div
      className='h-auto flex flex-col items-center text-xl p-6 gap-2 hover:shadow bg-grey-8 rounded'
      onClick={props.onClick}>
      <div className='rounded-full bg-gray-200 text-gray-300 p-3 my-2 w-14 h-14 flex justify-center items-center font-sans hover:shadow'>
        {props.firstName ? props?.firstName?.[0] : props.companyName?.[0]}
        {props.lastName ? props?.lastName?.[0] : ''}
      </div>
      <div className='text-gray-600 font-bold text-center'>
        {props.firstName ?? props?.companyName} {props?.lastName}
      </div>
      <div className='text-gray-400 text-sm'>{props.email}</div>
      <div className='text-gray-500 mt-2 mb-3 text-xs font-bold'>
        {props.userType ?? props.city}
      </div>
      <div className='flex justify-items-stretch justify-between w-full items-stretch h-8 px-4'>
        <IconContainer>
          <EditIcon />
        </IconContainer>
        <IconContainer>
          <TFilledEmailIcon className='p-1' />
        </IconContainer>
        <IconContainer>
          <TFilledPhoneIcon className='p-1' />
        </IconContainer>
        <IconContainer>
          <UnorderedListIcon />
        </IconContainer>
        <IconContainer>
          <ScheduleIcon />
        </IconContainer>
      </div>
    </div>
  )
}

export const CardsContainer = ({ data }) => {
  return (
    <div className='flex flex-wrap gap-6 justify-center transition-all bg-gray-100 p-8'>
      {data.map((d) => {
        return <ContactCard {...d} />
      })}
    </div>
  )
}

export default ContactCard
