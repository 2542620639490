import useListsMutation from 'hooks/useListsMutation'
import useProjectId from 'hooks/useProjectId'
import useContactContext from 'pages/newContacts/hooks/useContactContext'
import React, { useState } from 'react'
import { toast } from 'react-hot-toast'

const CreateNewList = () => {
  const { state } = useContactContext()
  const { projectId } = useProjectId()
  const { createList } = useListsMutation()
  const [title, setTitle] = useState('')

  const handleCreateList = () => {
    if (!title) return toast.error('Please enter list title')
    if (!state.checkedContacts || !Object.values(state.checkedContacts).filter(val => val === true).length) return toast.error('Please select some contacts')
    createList({ projectId, title, users: Object.keys(state.checkedContacts).filter(key => state.checkedContacts[key] === true) })
    setTitle('')
  }

  return (
    <div className='bg-white rounded p-4 h-max-content flex flex-col gap-5 w-72 border-2 border-gray-2'>
      <div className='text-3xl font-bold'>CREATE NEW LIST</div>
      <div className='flex flex-col gap-1'>
        <div className='text-softBlack30 italic'>NEW LIST</div>
        <input className='w-full' placeholder='Enter list name' value={title} onChange={e => setTitle(e.currentTarget.value)} />
      </div>
      <button onClick={handleCreateList}className='py-2 px-4 text-lg bg-[#18181B] text-white rounded-lg font-medium flex gap-2 items-center justify-center' >Create List</button>
    </div>
  )
}

export default CreateNewList
