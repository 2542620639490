import CustomButton from 'components/CustomButton'
import React from 'react'

const BottomActions = ({ hasYPadding = false, onBack, onNext, isBackBtnDisabled = false, isNextBtnDisabled = false }) => {
  return (
    <div className={`flex-1 flex items-end ${hasYPadding ? 'py-6' : 'py-0'}`}>
      <div className='w-full h-fit flex justify-between'>
        <CustomButton variant='outline' handleClick={onBack} disabled={isBackBtnDisabled}>
          <span className='font-medium py-3 px-4 text-base flex gap-2 items-center'>
            Back
          </span>
        </CustomButton>
        <CustomButton handleClick={onNext} disabled={isNextBtnDisabled}>
          <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
            Next
          </span>
        </CustomButton>
      </div>
    </div>
  )
}

export default BottomActions
