/* eslint-disable no-unused-vars */
import React from 'react'
import NewReservation from './NewReservation/NewReservation'
import NewVIPLotReservation from './NewReservation/NewVIPLotReservation'

const CreateReservationWrapper = () => {
  return (
    <div className='h-full'>
      {/* <NewReservation /> */}
      <NewVIPLotReservation />
    </div>
  )
}

export default CreateReservationWrapper
