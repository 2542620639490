import React, { useState } from 'react'
import propTypes from 'prop-types'
import { InputLabel } from 'ui'
import { LoadingIcon } from 'components'

const InlineInput = (props) => {
  const {
    label, onClickUpdate, onClickRemove,
    required, disabled, ...inputProps
  } = props

  const [updating, setUpdating] = useState(false)

  const update = () => {
    document.activeElement.blur()
    if (!disabled && onClickUpdate) {
      setUpdating(true)
      onClickUpdate()
        .then(() => setUpdating(false))
        .catch(() => setUpdating(false))
    }
  }

  return (
    <div className='py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4'>
      <dt className='text-sm font-medium text-gray-500 flex flex-col'>
        {label}
        {required && <span className='text-red-600 text-xs'>required</span>}
      </dt>
      <dd className='mt-1 flex items-center text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
        <InputLabel
          label={label}
          inputStyle='inlineInput'
          inputWrapperClassName='flex-grow w-5/6'
          onKeyPress={(pressEvent) => pressEvent.key === 'Enter' && update()}
          required={required}
          disabled={disabled}
          {...inputProps}
        />
        <span className='ml-4 flex-shrink-0'>
          {
            !disabled && onClickUpdate && (
              updating
                ? (
                    <div className='text-purple-600 mr-5'>
                      <LoadingIcon className='h-4 w-4' />
                    </div>
                  )
                : (
                  <button
                    onClick={update}
                    type='button'
                    className='bg-white p-1 rounded-md font-medium
                text-purple-600 hover:text-purple-500 focus:outline-none'
                  >
                    Update
                  </button>
                  )
            )
          }

          {
            onClickRemove && (
              <>
                <span className='text-gray-300' aria-hidden='true'>|</span>
                <button
                  type='button'
                  className='bg-white rounded-md font-medium text-purple-600 hover:text-purple-500
                          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500'
                >
                  Remove
                </button>
              </>
            )
          }
        </span>
      </dd>
    </div>
  )
}

export default InlineInput

InlineInput.defaultProps = {
  allowUpdate: true
}

InlineInput.propTypes = {
  label: propTypes.string,
  value: propTypes.string,
  onChange: propTypes.func,
  onClickUpdate: propTypes.func,
  onClickRemove: propTypes.func,
  key: propTypes.string,
  required: propTypes.bool,
  disabled: propTypes.bool
}
