/* eslint-disable */
// @ts-nocheck
import React, { useCallback, useEffect, useState } from 'react'
import { getParkings } from 'store/actions/parkingActions'
import { getParkingPolygons } from 'store/actions/parkingPolygonActions'
import CustomMap from './CustomMap'
import { getAllMapInventoryByType } from 'store/actions/mapInventoryActions'
import useInventory from '../hooks/useInventory'

const ParkingInventoryMapView = ({ projectId, building }) => {
  const { selectedParkingLevel } = useInventory()
  const [gettingData, setGettingData] = useState(true)
  const [parkingsData, setParkingsData] = useState([])
  const [parkingPolygonsData, setParkingPolygonsData] = useState([])
  const [polygons, setPolygons] = useState([])
  const [parkingStatusCount, setParkingStatusCount] = useState([])
  const [mapInfos, setMapInfos] = useState([])
  const [selectedMapInfo, setSelectedMapInfo] = useState({})

  const _getProjectParkings = useCallback(() => {
    setGettingData(true)
    getParkings()
      .then((parkings) => {
        //sorting an array based on status
        let tempArr = {
          available: 0,
          assigned: 0,
          reserved: 0,
          allocated: 0,
          rescission: 0,
          sold: 0,
          occupied: 0
        }
        parkings.forEach((elm) => {
          tempArr[elm.status?.toLowerCase()] += 1
        })
        setParkingStatusCount(Object.entries(tempArr).map(([status, count]) => ({ status, count, percentage: `${(count / parkings.length) * 100}%` })).sort((a, b) => (a.status > b.status) ? 1 : -1))
        setParkingsData(parkings)
        setGettingData(false)
      })
      .catch(() => {
        setGettingData(false)
      })
  }, [])

  useEffect(() => {
    _getProjectParkings()
  }, [_getProjectParkings])

  const _getParkingPolygons = useCallback(() => {
    setGettingData(true)
    getParkingPolygons()
      .then((parkingPolygons) => {
        setParkingPolygonsData(parkingPolygons)
        setGettingData(false)
      })
      .catch(() => {
        setGettingData(false)
      })
  }, [])

  useEffect(() => {
    _getParkingPolygons()
  }, [_getParkingPolygons])

  useEffect(() => {
    if (parkingPolygonsData?.length > 0 && parkingsData?.length > 0) {
      setPolygons([
        ...parkingPolygonsData.map((data) => {
          return {
            coordinates: data.polygon,
            parkingLevel: parkingsData.filter((parking) => parking._id === data.parking)[0]?.parkingLevel ?? selectedParkingLevel?.parkingLevel,
            inventoryId: data.parking,
            polygonId: data._id
          }
        }).filter((elm) => elm?.parkingLevel === selectedParkingLevel?.parkingLevel)
      ])
    }
  }, [parkingPolygonsData, parkingsData, selectedParkingLevel])

  const _getAllMapInventoryByType = useCallback(() => {
    setGettingData(true)
    getAllMapInventoryByType(projectId, building, 'Parking')
      .then((res) => {
        setMapInfos(res)
        setGettingData(false)
      })
      .catch((error) => {
        console.log('error: ', error)
        setGettingData(false)
      })
  }, [])

  useEffect(() => {
    _getAllMapInventoryByType()
  }, [projectId, building])

  useEffect(() => {
    setGettingData(true)
    if (selectedParkingLevel?.parkingLevel && mapInfos?.length > 0) {
      setSelectedMapInfo({
        ...mapInfos.filter((elm) => elm.level === selectedParkingLevel?.parkingLevel)[0]
      })
    }
    setGettingData(false)
  }, [mapInfos, selectedParkingLevel])

  return (
    <CustomMap
      mapInfo={selectedMapInfo}
      selectedLevel={selectedParkingLevel?.parkingLevel}
      inventoryData={parkingsData}
      polygonsData={parkingPolygonsData}
      setPolygonsData={setParkingPolygonsData}
      polygons={polygons}
      isLoading={gettingData}
      setIsLoading={setGettingData}
      statusCount={parkingStatusCount}
      refetchInventoryData={_getProjectParkings}
      refetchPolygonsData={_getParkingPolygons}
    />
  );
}

export default ParkingInventoryMapView
