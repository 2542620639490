export const fadeVariants = {
  fadeOut: {
    opacity: 0,
    transition: { duration: 0.3 }
  },
  fadeIn: {
    opacity: 1,
    transition: { duration: 0.3 }
  }
}
