import React from 'react'
const EnhancedTableHeader = ({ columnNames, showIndex }) => {
  return (
    <thead>
      <tr>
        {showIndex && (
          <th
            className='font-openSans text-softBlack70 text-xs uppercase py-6 border-b'
            style={{
              borderColor: 'rgba(46, 43, 46, 0.3)',
              width: 26,
              letterSpacing: '0.16px'
            }}
          ></th>
        )}
        {columnNames.map((rowName, index) => (
          <th
            key={index}
            className='font-openSans text-softBlack70 text-xs uppercase py-6 border-b text-left'
            style={{
              borderColor: 'rgba(46, 43, 46, 0.3)',
              letterSpacing: '0.16px'
            }}
          >
            {rowName}
          </th>
        ))}
      </tr>
    </thead>
  )
}
export default EnhancedTableHeader
