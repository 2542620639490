import React, { useState, useEffect, useCallback } from 'react'
import propTypes from 'prop-types'

import { CreateOfferStepWithList, CreateOfferStepFullScreenWrapper } from 'components'

const BuyerOptionsUpgrade = (props) => {
  const [optionsUpgrade, setOptionsUpgrade] = useState([])

  const {
    projectData, onSelectOptionUpgrade, selectedOptionsUpgrade,
    offerId, titleWrapperClassName, titleClassName, buttonsClassName,
    buttonSize, numberOfItemsToSlide
  } = props
  const { options, totalDollarValueCurrencyType } = projectData

  useEffect(() => {
    if (options.length) {
      const tmpSelectedAdditionalOptions = options
        .filter((option) => option.type === 'additionalOptions')
        .map((option, index) => {
          let tmpOption = Object.assign({}, option, { selected: false })
          selectedOptionsUpgrade.forEach((selectedOption) => {
            if (option.name === selectedOption.name) {
              tmpOption = Object.assign({}, option, selectedOption)
            }
          })
          return tmpOption
        })

      setOptionsUpgrade(tmpSelectedAdditionalOptions)
    }
  }, [options, selectedOptionsUpgrade])

  const setSelectedOptionsUpgrade = useCallback(() => {
    const selectedAdditionalOptions = optionsUpgrade.filter((option) => option.selected)

    onSelectOptionUpgrade(selectedAdditionalOptions)
  }, [onSelectOptionUpgrade, optionsUpgrade])

  const selectOrUnselectOption = (indexToChange) => {
    const newOptions = optionsUpgrade
      .map((option, index) => {
        const tmpOption = Object.assign({}, option)

        if (index === indexToChange) {
          tmpOption.selected = !tmpOption.selected
        }
        return tmpOption
      })

    setOptionsUpgrade(newOptions)
  }

  return (
    <CreateOfferStepFullScreenWrapper className='md:justify-around'>
      <CreateOfferStepWithList
        title='Upgrade and Options'
        continueButtonText='Continue'
        continueButtonOnClick={() => setSelectedOptionsUpgrade()}
        // continueButtonDisabled={!optionsUpgrade.length}
        items={optionsUpgrade}
        onSelectOption={({ index }) => selectOrUnselectOption(index)}
        totalDollarValueCurrencyType={totalDollarValueCurrencyType}
        multiSelectItems
        offerId={offerId}
        titleWrapperClassName={`px-5 md:px-18 ${titleWrapperClassName}`}
        titleClassName={titleClassName}
        continueButtonClassName={buttonsClassName}
        optionsWrapperClassName='pl-5 md:pl-18'
        buttonWrapperClassName='px-5 md:px-18'
        buttonSize={buttonSize}
        numberOfItemsToSlide={numberOfItemsToSlide}
      />
    </CreateOfferStepFullScreenWrapper>
  )
}

export default BuyerOptionsUpgrade

BuyerOptionsUpgrade.defaultProps = {
  selectedOptionsUpgrade: [],
  titleWrapperClassName: '',
  titleClassName: '',
  buttonsClassName: '',
  buttonSize: 'large',
  numberOfItemsToSlide: 3
}

BuyerOptionsUpgrade.propTypes = {
  projectData: propTypes.object,
  onSelectOptionUpgrade: propTypes.func,
  totalDollarValueCurrencyType: propTypes.string,
  selectedOptionsUpgrade: propTypes.array,
  offerId: propTypes.string,
  titleWrapperClassName: propTypes.string,
  titleClassName: propTypes.string,
  buttonsClassName: propTypes.string,
  buttonSize: propTypes.string,
  numberOfItemsToSlide: propTypes.number
}
