/* eslint-disable */
// @ts-nocheck
import React, { useState, useCallback, useEffect, useRef } from 'react'
import propTypes from 'prop-types'
import { TButton } from 'ui'
import { useTable, usePagination } from 'react-table'
import { FadedAnimatedDiv, LottieWrapper, TDeleteButton } from 'components'
import tableLoading from 'assets/lottieJsons/tableLoading.json'
// import { AnimatePresence } from 'framer-motion'
import { ReactComponent as SorterIcon } from 'assets/icons/sorter.svg'

const PaginationDetail = ({ children }) => (
  <span className='font-medium text-softBlack text-base w-5 mx-1 h-full text-center mb-0'>
    {children}
  </span>
)
const getRowItemPaddingCSS = (index = -1, total = 0) => {
  if (index === 0) {
    return 'pr-2 md:pr-6'
  }

  if (index === total - 1) {
    return 'px-2 md:pl-6 md:pr-2.5'
  }

  return 'px-2 md:px-6'
}

const TailwindTableNew = (props) => {
  const {
    className, extraHeaders, extraColumns,
    loading, onViewEditClick, onRow,
    onDeleteClick,
    deleting, idBeingDeleted, data, columns,
    showTablePagination, onDeleteButtonText,
    noMargin, onSortClicked, isSmall, isWithoutBorder, isHideEmptyMessage
  } = props

  const [customPageIndex, setCustomPageIndex] = useState(1)

  const refCustomPageIndex = useRef(customPageIndex)

  const updateCustomPageIndex = (newState) => {
    refCustomPageIndex.current = newState
    setCustomPageIndex(newState)
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    rows,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 30 }
    },
    usePagination
  )

  useEffect(() => {
    if (customPageIndex) {
      gotoPage(customPageIndex - 1)
    }
  }, [customPageIndex, gotoPage])

  const renderActionColumnHeader = () => {
    return (
      <th key='actionHeader' scope='col' className={`relative py-3 ${getRowItemPaddingCSS()}`}>
        <span className='sr-only'>Actions</span>
      </th>
    )
  }

  const renderDeleteColumnHeader = () => {
    return (
      <th key='deleteHeader' scope='col' className={`relative py-3 ${getRowItemPaddingCSS()}`}>
        <span className='sr-only'>Delete</span>
      </th>
    )
  }

  const renderActionsButtons = (row) => {
    return (
      <td key='actionContent' className='pr-2 py-4 whitespace-nowrap text-center text-sm font-medium'>
        <>
          <TButton
            className='ml-1'
            onClick={() => onViewEditClick(row)}
          >
            View/Edit
          </TButton>
        </>
      </td>
    )
  }

  const renderDeleteButton = useCallback((row) => (
    <td key='deleteContent' className='pr-2 py-4 whitespace-nowrap text-center text-sm font-medium'>
      <TDeleteButton
        className='ml-1'
        onClick={() => onDeleteClick(row)}
        loading={deleting && idBeingDeleted === row.original._id}
      >
        {onDeleteButtonText}
      </TDeleteButton>
    </td>
  ), [deleting, idBeingDeleted, onDeleteClick, onDeleteButtonText])

  const renderHeaders = () => (
    <thead>
      {headerGroups.map((headerGroup, index) => (
        <tr key={index} {...headerGroup.getHeaderGroupProps()} className='border-b border-[#2E2B2E] dark:border-zinc-500 border-opacity-[15%] dark:border-opacity-100'>
          {
            headerGroup.headers.map((column, index, headers) => (
              <th
                key={index}
                scope='col'
                className={`p-2 ${isSmall ? 'md:py-4' : 'md:p-5'} text-left text-xs font-medium text-softBlack tracking-wider`}
                // className={`py-6 text-left text-xs font-medium text-softBlack uppercase tracking-wider ${getRowItemPaddingCSS(index, headers.length)}`}
                {...column.getHeaderProps()}
              >
                <span className='flex items-center gap-1.5'>
                  {column.render('Title')}
                  {
                    column?.isSort &&
                    <button onClick={() => onSortClicked(column.dataIndex, column.sortingType)}>
                      <SorterIcon />
                    </button>
                  }
                </span>
              </th>
            ))
          }
          {onViewEditClick && renderActionColumnHeader()}
          {onDeleteClick && renderDeleteColumnHeader()}
          {extraHeaders.map((extraHeader) => extraHeader)}
        </tr>
      ))}
    </thead>
  )

  const renderBody = () => (
    <tbody
      {...getTableBodyProps()}
    >
      {
        page.map((row, i) => {
          prepareRow(row)
          return (
            <tr
              key={i} {...row.getRowProps()}
              className={`${onRow && 'hover:cursor-pointer'} ${!isWithoutBorder && 'border-b border-[#2E2B2E] dark:border-zinc-500 border-opacity-[15%] dark:border-opacity-100'}`}
            >
              {row.cells.map((cell, index, cells) => {
                return (
                  <td
                    onClick={(e) => onRow && onRow(cell)}
                    key={index}
                    {...cell.getCellProps()}
                    className={`p-2 ${isSmall ? 'md:py-4' : 'md:p-5'} whitespace-nowrap`}
                  // className={`py-4 whitespace-nowrap ${getRowItemPaddingCSS(index, cells.length)}`}
                  >
                    {cell.render('Cell')}
                  </td>
                )
              })}
              {onViewEditClick && renderActionsButtons(row)}
              {onDeleteClick && renderDeleteButton(row)}
              {extraColumns && extraColumns(row)}
            </tr>
          )
        })
      }
    </tbody>
  )

  const Paginator = useCallback(() => {
    const { length } = rows
    return (
      <>
        <nav className='px-2.5 py-3 flex items-center justify-between border-t border-gray-200 sm:px-4' aria-label='Pagination'>
          <div className='sm:block flex-1'>
            <p className='text-sm text-gray-700 mb-0'>
              Showing
              <PaginationDetail>{parseFloat(page[0].id) + 1}</PaginationDetail>
              to
              <PaginationDetail>{parseFloat(page[page.length - 1].id) + 1}</PaginationDetail>
              of
              <PaginationDetail>{length}</PaginationDetail>
            </p>
          </div>
          <div className='flex-1 flex justify-end content-center items-center align-middle'>
            <button
              onClick={() => updateCustomPageIndex(--refCustomPageIndex.current)}
              disabled={!canPreviousPage}
            >
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.5013 15.8337L8.6763 14.6587L4.85964 10.8337H18.3346V9.16699H4.85964L8.68464 5.34199L7.5013 4.16699L1.66797 10.0003L7.5013 15.8337Z" fill="#2E2B2E" />
              </svg>
            </button>
            <div className='mx-2'>
              <PaginationDetail>{customPageIndex}</PaginationDetail>
            </div>
            <button
              onClick={() => updateCustomPageIndex(++refCustomPageIndex.current)}
              disabled={!canNextPage}
            >
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.4993 4.16699L11.3243 5.34199L15.141 9.16699H1.66602V10.8337H15.141L11.316 14.6587L12.4993 15.8337L18.3327 10.0003L12.4993 4.16699Z" fill="#2E2B2E" />
              </svg>
            </button>
          </div>
        </nav>
      </>
    )
  }, [
    canNextPage, canPreviousPage,
    page, rows, customPageIndex, pageCount
  ])

  return (
    <div className={`w-full flex flex-col bg-white m-0 ${!noMargin && 'md:m-14'} rounded-lg overflow-hidden ${!isWithoutBorder && 'border border-[#2E2B2E] dark:border-zinc-500 border-opacity-[15%] dark:border-opacity-100'} dark:bg-zinc-800 ${className}`}>
      {/* <AnimatePresence exitBeforeEnter> */}
      {loading
        ? (
          <div
            key='loadingTable'
            className='w-full flex items-center justify-center my-10 flex-col'
          >
            <LottieWrapper
              animation={tableLoading}
              loop
              className='h-16 w-16'
            />
            <span className='text-base dark:text-white'>loading...</span>
          </div>
        )
        : (
          <div className='max-w-full overflow-x-auto'>
            <table className='w-full dark:bg-zinc-900' {...getTableProps()}>
              {renderHeaders()}
              {data?.length > 0 && renderBody()}
            </table>
            {!data?.length && !isHideEmptyMessage && (
              <span className='my-10 text-base text-center flex items-center justify-center dark:text-white'>
                There are no items to be shown
              </span>
            )}
            {page.length > 0 && showTablePagination && <Paginator />}
          </div>
        )
      }
      {/* </AnimatePresence> */}
    </div>
  )
}

export default TailwindTableNew

TailwindTableNew.defaultProps = {
  extraHeaders: [],
  onViewEditClick: null,
  onDeleteClick: null,
  showTablePagination: true,
  onDeleteButtonText: 'Delete'
}

TailwindTableNew.propTypes = {
  className: propTypes.string,
  extraHeaders: propTypes.array,
  extraColumns: propTypes.node,
  loading: propTypes.bool,
  showViewEditButton: propTypes.bool,
  onViewEditClick: propTypes.func,
  onDeleteClick: propTypes.func,
  deleting: propTypes.bool,
  idBeingDeleted: propTypes.string,
  data: propTypes.array,
  columns: propTypes.array,
  showTablePagination: propTypes.bool,
  onDeleteButtonText: propTypes.string,
  onRow: propTypes.func,
  noMargin: propTypes.bool
}

PaginationDetail.propTypes = {
  children: propTypes.node
}
