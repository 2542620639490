/* eslint-disable */
// @ts-nocheck
import React, {
  useEffect,
  useState,
  memo,
  useMemo,
  useCallback,
  useRef
} from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import {
  getProjectDeposits,
} from 'store/actions/depositActions'
import { formatMoney } from 'utils'
import FadedAnimatedDiv from 'components/FadedAnimatedDiv'
import standardStyle from 'assets/css/standardStyle'
import { AgGridReact } from 'ag-grid-react'
import LottieWrapper from 'components/LottieWrapper'
import successAnimation from 'assets/lottieJsons/avesdoLoading.json'
import CustomButton from 'components/CustomButton'
import RecordPaymentModal from './RecordPaymentModal'

const CardMoneyTextComponent = memo(({ children }) => (
  <div className='text-lg font-medium text-gray-900'>{children}</div>
))

const StatisticValue = memo(({ title, value, loadingObject }) => {
  return (
    <AnimatePresence exitBeforeEnter initial>
      <FadedAnimatedDiv key={`statisticValue${title}`}>
        {value}
      </FadedAnimatedDiv>
    </AnimatePresence>
  )
})

const Card = ({ iconSrc, title, amount, noOfUnits }) => (
  <div
    className='bg-white rounded-xl border shadow-md'
    style={{ fontFamily: standardStyle.fonts.sfpro }}
  >
    {
      iconSrc &&
      <div className='p-3'>
        <div className='w-8 h-4'>{iconSrc}</div>
      </div>
    }
    <div className='flex flex-col space-y-2 p-3'>
      <div className='text-xs text-softBlack_70 font-medium uppercase'>
        {title}
      </div>
      <div className='text-xl text-softBlack font-bold'>
        <StatisticValue
          title={title}
          value={formatMoney(amount || 0)}
          loadingObject={
            <CardMoneyTextComponent>loading...</CardMoneyTextComponent>
          }
        />
      </div>
      <div className='text-sm font-bold text-softBlack pt-4'>
        <StatisticValue title={title} value={`${noOfUnits ?? '0'} Counts`} />
      </div>
    </div>
  </div>
)

const ProjectDeposits = ({ appProject, userObject }) => {
  const history = useHistory()
  const [projectDeposits, setProjectDeposits] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [crrDeposit, setCrrDeposit] = useState(null)
  const gridRef = useRef(null)
  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      filter: true,
      resizable: true,
    }
  }, [])

  const cards = useMemo(
    () => [
      {
        IconSrc: <></>,
        title: 'Total deposits collected',
        amount: 0,
        totalCount: 0
      },
      {
        IconSrc: <></>,
        title: 'Total deposit 1',
        amount: 0,
        totalCount: 0
      },
      {
        IconSrc: <></>,
        title: 'Total deposit 1 collected',
        amount: 0,
        totalCount: 0
      },
      {
        IconSrc: <></>,
        title: 'Total 1st deposit  pending',
        amount: 0,
        totalCount: 0
      },
      {
        IconSrc: <></>,
        title: 'Total deposit pending ',
        amount: 0,
        totalCount: 0
      }
    ],
    []
  )

  const hideLoading = useCallback(() => {
    gridRef?.current?.api?.hideOverlay();
  }, []);

  const showLoading = useCallback(() => {
    gridRef?.current?.api?.showLoadingOverlay();
  }, []);

  const getData = useCallback(() => {
    if (appProject) {
      setProjectDeposits([])
      showLoading()

      getProjectDeposits(appProject)
        .then((Deposits) => {
          const tmpProjectDeposits = Deposits?.map((deposit, index) => {
            const {
              value,
              _id,
              offer,
              createdAt,
              dueDate,
              isReceived,
              name,
              paymentMethodType,
              paymentReceivedBy = '',
            } = deposit

            const tmpDeposit = {
              _id,
              unitNumber: 101,
              createdAt,
              dueDate,
              buyer: offer?.buyer?.[0]?.firstName?.length > 0 ? `${offer?.buyer?.[0]?.firstName} ${offer?.buyer?.[0]?.lastName}` : '-',
              createdAt,
              dueDate,
              depositOption: offer?.deposit?.optionName ?? '-',
              depositOrder: 1,
              isReceived,
              amount: value,
              status: isReceived,
              paymentMethodType,
              paymentReceivedBy,
              depositDetails: { ...deposit }
            }
            return tmpDeposit
          })
          setProjectDeposits(tmpProjectDeposits)
          hideLoading()
        })
        .catch((err) => {
          hideLoading()
          console.log(err)
        })
    }
  }, [appProject])

  useEffect(() => {
    getData()
  }, [getData])

  const dateFormate = (date) => {
    if (date == null) {
      return undefined;
    } else {
      const d = new Date(date);
      const day = d.getDate() < 10 ? '0' + d.getDate() : d.getDate();
      const month =
        d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1;
      const year = d.getFullYear();
      const hours = d.getHours();
      const minutes = d.getMinutes();
      const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}`;
      return formattedDate;
    }
  };

  const currencyFormate = (value) => {
    if (value == null) return undefined;
    else {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });

      const formattedValue = formatter.format(value);
      return formattedValue;
    }
  };

  const columnDefs = [
    {
      headerName: 'Unit',
      field: 'unitNumber',
      width: 120,
      resizable: true,
    },
    {
      headerName: 'Buyer',
      field: 'buyer',
      width: 200,
      resizable: true,
    },
    {
      headerName: 'Created Date',
      field: 'createdAt',
      width: 200,
      resizable: true,
      valueFormatter: (p) => dateFormate(p.value),
    },
    {
      headerName: 'Due Date',
      field: 'dueDate',
      width: 200,
      resizable: true,
      valueFormatter: (p) => dateFormate(p.value),
    },
    {
      headerName: 'Deposit Option',
      field: 'depositOption',
      width: 180,
      resizable: true,
    },
    {
      headerName: 'Deposit Order',
      field: 'depositOrder',
      width: 180,
      resizable: true,
    },
    {
      headerName: 'Amount',
      field: 'amount',
      width: 150,
      resizable: true,
      valueFormatter: (p) => currencyFormate(p.value),
    },
    {
      headerName: 'Status',
      field: 'status',
      width: 150,
      resizable: true,
      cellRenderer: (params) => (<span className={`h-fit px-3 my-1 rounded-full leading-6 ${params.data.isReceived ? 'bg-green-500' : 'bg-red-500'} text-white`}>{params.data.isReceived ? 'Pending' : 'Received'}</span>)
    },
    {
      headerName: 'Received By',
      field: 'paymentReceivedBy',
      width: 150,
      resizable: true,
      cellRenderer: (params) => (<>{params?.data?.paymentReceivedBy?.length > 0 ? params?.data?.paymentReceivedBy : '-'}</>)
    },
    {
      headerName: 'Payment Method',
      field: 'paymentMethodType',
      width: 200,
      resizable: true,
      cellRenderer: (params) => (<>{params?.data?.paymentMethodType?.length > 0 ? params?.data?.paymentMethodType : '-'}</>)
    },
    {
      headerName: 'Action',
      field: '_id',
      width: 200,
      cellRenderer: (params) => (
        <div className='w-full justify-center items-center flex gap-2'>
          <CustomButton
            handleClick={() => {
              setCrrDeposit(params.data)
              setIsModalOpen(true)
            }}
          >
            <span className='font-normal px-4 py-1 text-base flex items-center gap-2'>
              Record Payment
            </span>
          </CustomButton>
        </div>
      )
    }
  ]

  const loadingOverlayComponent = useMemo(() => {
    return CustomLoadingCellRenderer
  }, [])

  return (
    <div className='h-full w-full flex flex-col mt-6 items-center'>
      <div className='grid grid-cols1 md:grid-cols-5 gap-4 w-full'>
        {cards?.map((card, index) => {
          return (
            <Card
              IconSrc={card.iconSrc}
              title={card.title}
              amount={card.amount}
              noOfUnits={card.total}
            />
          )
        })}
      </div>
      <div className='w-full h-[680px] ag-theme-alpine  pt-8'>
        <AgGridReact
          ref={gridRef}
          pagination={true}
          paginationPageSize={25}
          columnDefs={columnDefs}
          loadingOverlayComponent={loadingOverlayComponent}
          rowData={projectDeposits}
          rowHeight={50}
          paginateChildRows
          defaultColDef={defaultColDef}
        />
      </div>
      <RecordPaymentModal
        isModalOpen={isModalOpen}
        handleCancel={() => {
          setIsModalOpen(false)
          setCrrDeposit(null)
        }}
        crrDeposit={crrDeposit}
        refetch={getData}
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  appProject: state.appReducer.appProject,
  userObject: state.authReducer.userObject
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDeposits)

ProjectDeposits.propTypes = {
  appProject: propTypes.string,
  userObject: propTypes.shape({
    userType: propTypes.string
  }),
  cell: propTypes.shape({
    value: propTypes.string
  }),
  row: propTypes.shape({
    original: propTypes.shape({
      _id: propTypes.string
    })
  })
}

StatisticValue.propTypes = {
  title: propTypes.string,
  value: propTypes.oneOfType([propTypes.object, propTypes.string]),
  loadingObject: propTypes.object
}

Card.propTypes = {
  IconSrc: propTypes.node,
  title: propTypes.string,
  amount: propTypes.number,
  noOfUnits: propTypes.number
}

const CustomLoadingCellRenderer = (props) => {
  const [messages, setMessages] = useState([
    'Loading',
    'Data Fetched',
  ])
  const [currentIndex, setCurrentIndex] = useState(0)
  const [fade, setFade] = useState(false)

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(true)
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % messages.length)
        setFade(false)
      }, 500)
    }, 2000)

    return () => clearInterval(interval)
  }, [messages])

  useEffect(() => {
    setMessages((prevMessages) => [...prevMessages])
  }, [])
  return (
    <div role='presentation'>
      <div
        role='presentation'
        style={{
          height: 100,
          width: 100,
          margin: '0 auto',
        }}
      >
        <LottieWrapper
          animation={successAnimation}
          autoplay
          loop={true}
          className='-mt-40 md:mt-0 max-w-sm'
        />
        <h1 className={fade ? 'fade mt-4 text-[16px]' : 'mt-4 text-[16px]'}>
          {messages[currentIndex]}
        </h1>
      </div>
    </div>
  )
}