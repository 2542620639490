/* eslint-disable no-unused-vars */
import React from 'react'
import { Select } from 'antd'
import { ReactComponent as DownIcon } from '../../assets/icons/down.svg'

export const AddUserEmailInput = ({
  contacts,
  loading,
  selectedEmails,
  setSelectedEmails,
  label,
  placeholder,
  singleSelection = false,
  disabled = false
}) => {
  const handleEmailChange = (value) => {
    setSelectedEmails(value)
  }

  // const getNameFromContact = (contact) => {
  //   return contact.given_name && contact.surname ? `${contact.given_name} ${contact.surname}` : getEmailFromContact(contact)
  // }

  const getEmailFromContact = (contact) => {
    return contact?.emails?.[0]?.email
  }

  return (
    <div>
      {label?.length > 0 && (
        <p
          className='mt-0 mb-2 text-softBlack70 text-xs font-medium uppercase'
          style={{ letterSpacing: '0.16px' }}
        >
          {label}
        </p>
      )}
      <Select
        mode={singleSelection ? null : 'tags'}
        placeholder={placeholder}
        value={selectedEmails || []}
        style={{
          width: '100%'
        }}
        showArrow
        getPopupContainer={(node) => node.parentNode}
        onChange={handleEmailChange}
        loading={loading}
        className='ant-picker-input1'
        suffixIcon={loading ? undefined : <DownIcon />}
        showSearch={true}
        tokenSeparators={singleSelection ? null : [',']}
        disabled={disabled}
      >
        {!loading &&
          contacts.map((contact) => (
            <Select.Option
              key={getEmailFromContact(contact)}
              label={getEmailFromContact(contact)}
              value={getEmailFromContact(contact)}
            >
              {getEmailFromContact(contact)}
            </Select.Option>
          ))}
      </Select>
    </div>
  )
}
