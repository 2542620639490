/* eslint-disable */
// @ts-nocheck
import React, { useEffect, useState } from 'react';
import './styles/style.css';
import CustomSelect from './components/CustomSelect';
import useInventory from './hooks/useInventory';
import UnitsInventoryListView from './components/UnitsInventoryListView';
import ParkingsInventoryListView from './components/ParkingsInventoryListView';
import StorageInventoryListView from './components/StorageInventoryListView';
import UnitInventoryMapView from './components/UnitInventoryMapView';
import ParkingInventoryMapView from './components/ParkingInventoryMapView';
import StorageInventoryMapView from './components/StorageInventoryMapView';
import { NotebookTabs } from 'lucide-react';
import { useSelector } from 'react-redux';
import ParkingReportView from './components/ParkingReportView';
import { ParkingHeatmap } from './components/ParkingTypeHeatMap';
import { ParkingAllocaitonHeatMap } from './components/ParkingAllocationHeatMap';
import TableView from './components/TableView';
import TableViewParking from './components/TableViewParking';
import { getProjects } from 'store/actions/projectActions';
import TableViewUnit from './components/TableViewUnit';
import { getInventorySettings } from 'store/actions/inventoryActions';
import InventoryListViewMain from './components/InventoryListViewMain';
import TableViewInventory from './components/TableViewInventory';

const selectViewOption = [
  {
    _id: 'availabilityStatus',
    label: 'Availability Status',
    value: 'availabilityStatus',
  },
  {
    _id: 'demandHeatmap',
    label: 'Demand Heatmap',
    value: 'demandHeatmap',
  },
  {
    _id: 'brokerAllocations',
    label: 'Broker Allocations',
    value: 'brokerAllocations',
  },
  {
    _id: 'map',
    label: 'Map View',
    value: 'map',
  },
  {
    _id: 'parkingTypeHeatmap',
    label: 'Parking Type Heatmap',
    value: 'parkingTypeHeatmap',
  },
  {
    _id: 'parkingAllocationHeatmap',
    label: 'Parking Allocation Heatmap',
    value: 'parkingAllocationHeatmap',
  },
  {
    _id: 'tableView',
    label: 'Parking View',
    value: 'tableView',
  },
  {
    _id: 'tableViewUnit',
    label: 'Table View',
    value: 'tableViewUnit',
  },
];

const selectViewOptionInventory = [
  {
    _id: 'availabilityStatus',
    label: 'Availability Status',
    value: 'availabilityStatus',
  },
  {
    _id: 'demandHeatmap',
    label: 'Demand Heatmap',
    value: 'demandHeatmap',
  },
  {
    _id: 'tableViewUnit',
    label: 'Table View',
    value: 'tableViewUnit',
  },
];

const typesOfStatus = [
  {
    id: 'available',
    name: 'Available',
  },
  {
    id: 'assigned',
    name: 'Assigned',
  },
  {
    id: 'reservedBroker',
    name: 'Reserved Broker',
  },
  {
    id: 'sold',
    name: 'Sold Committed',
  },
  {
    id: 'undercontract',
    name: 'Under Contract',
  },
  {
    id: 'developerHeld',
    name: 'Developer Held',
  },
];

const TabView = ({ tabs, onChange, selectedTabId }) => (
  <div className='flex items-center rounded-lg bg-white h-full'>
    {tabs.map((level) => (
      <div
        key={level.id}
        onClick={() => {
          onChange(level);
        }}
        className={`flex self-stretch items-center justify-center min-w-[160px] px-4 py-1.5 cursor-pointer rounded-md font-medium text-base ${
          selectedTabId === level.id
            ? 'bg-[#18181B] text-white shadow-sm'
            : 'bg-white text-[#18181B]'
        } transition-all`}
      >
        {level.label}
      </div>
    ))}
  </div>
);

export const groupBy = (list, keyGetter) => {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
};

const ProjectInventory = () => {
  const projectId = useSelector((state) => state.appReducer.appProject);
  let unitVar = localStorage.getItem(`projectId_${projectId}`);
  const [unitVariable, setUnitVariable] = useState(unitVar || 'Unit');
  const [projectInventory, setProjectInventory] = useState({});
  const [inventoryName, setInventoryName] = useState('Unit');

  useEffect(()=>{
  if(process.env.REACT_APP_INENVETORY_SETTINGS_UI) {
    getInventorySettings(projectId).then((res) => {
      if(res[0].type !== null){
        setInventoryName(res[0]?.type);
        setProjectInventory({
          _id: res[0]?.type,
          label: res[0]?.type + ' Inventory',
          value: res[0].type
        });
      }
    })
  }
  },[projectId])

  useEffect(() => {
    getProjects(projectId).then((project) => {
      const { unitVariable } = project;
      if (unitVariable) {
        setUnitVariable(unitVariable || 'Unit');
      }
    });
  }, [projectId]);

  const {
    state,
    handleInventoryChange,
    handleInventoryViewChange,
    setSelectedParkingLevel,
    setSelectedStorageLevel,
    setIsReportScreenOpen,
    selectedBuilding,
    setSelectedBuilding,
    buildingOptions,
    allParkingLevels,
    allStorageLevels,
    selectedParkingLevel,
    selectedStorageLevel,
  } = useInventory();

  const Header = ({
    state,
    handleInventoryChange,
    handleInventoryViewChange,
    setSelectedParkingLevel,
    setSelectedStorageLevel,
    buildingOptions,
    selectedBuilding,
    setSelectedBuilding,
    setIsReportScreenOpen,
    selectedParkingLevel,
    selectedStorageLevel,
    allParkingLevels,
    allStorageLevels,
  }) => {
    return (
      <div className='w-full py-2.5 px-6 flex items-center gap-6'>
        <div className='flex items-center gap-4'>
          <h6 className='mb-0 font-medium text-sm'>Building</h6>
          <div className='w-40'>
            <CustomSelect
              value={selectedBuilding}
              handleChange={(newValue) => setSelectedBuilding(newValue)}
              options={buildingOptions}
              placeholder='Select Building'
            />
          </div>
        </div>
        <div className='w-40'>
          <CustomSelect
            value={state.selectedInventory}
            handleChange={(val) => handleInventoryChange(val)}
            options={selectInventoryOption}
            placeholder='Select Inventory'
          />
        </div>
        <div className='flex items-center gap-4'>
          <h6 className='mb-0 font-medium text-sm'>View</h6>
          <div className='w-56'>
            <CustomSelect
              value={state.selectedInventoryView}
              handleChange={(val) => handleInventoryViewChange(val)}
              options={(process.env.REACT_APP_INENVETORY_SETTINGS_UI && state.selectedInventory === 'unit')  ? selectViewOptionInventory : selectViewOption}
              placeholder='Select View'
            />
          </div>
        </div>
        {(state.selectedInventory === 'parking' ||
          state.selectedInventory === 'storage') && (
          <button
            className='w-40 py-2.5 px-5 bg-[#18181B] text-white rounded-md flex gap-2 items-center justify-center'
            onClick={() => setIsReportScreenOpen(true)}
          >
            <NotebookTabs height='16' width='16' color='white' />
            <p className='mb-0 font-medium text-base'>Report</p>
          </button>
        )}
        {!state.isReportScreenOpen && (
          <>
            {state.selectedInventory === 'parking' &&
              state.selectedInventoryView === 'map' && (
                <TabView
                  tabs={allParkingLevels}
                  selectedTabId={selectedParkingLevel.id}
                  onChange={setSelectedParkingLevel}
                />
              )}
            {state.selectedInventory === 'storage' &&
              state.selectedInventoryView === 'map' && (
                <TabView
                  tabs={allStorageLevels}
                  selectedTabId={selectedStorageLevel.id}
                  onChange={setSelectedStorageLevel}
                />
              )}
            {state.selectedInventoryView === 'availabilityStatus' && (
              <div className='flex-1 h-full overflow-y-auto thin-horizontal-scrollbar flex items-center'>
                <div className='w-full flex items-center gap-5'>
                  {typesOfStatus.map((status) => (
                    <div
                      key={status.id}
                      class='rounded-md items-center gap-2 flex'
                    >
                      <div
                        class='w-3 h-3 rounded-full'
                        style={{
                          backgroundColor:
                            state.statusColors[status.id.toLowerCase()].bgColor,
                          border: `1px solid ${
                            state.statusColors[status.id.toLowerCase()]
                              .borderColor
                          }`,
                        }}
                      />
                      <p class='text-zinc-900 text-sm font-semibold leading-tight mb-0 truncate'>
                        {status.name}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {state.selectedInventoryView === 'demandHeatmap' && (
              <div className='flex-1 flex justify-end items-center pr-10'>
                <div className='h-5 justify-start items-center gap-3 inline-flex'>
                  <div className='text-zinc-900 text-sm font-semibold leading-tight'>
                    0
                  </div>
                  <div className='w-[324px] h-3.5 bg-gradient-to-r from-white to-green-800 rounded-3xl' />
                  <div className='text-zinc-900 text-sm font-semibold leading-tight'>
                    12
                  </div>
                </div>
              </div>
            )}
            {(state.selectedInventoryView === 'parkingTypeHeatmap' ||
              state.selectedInventoryView === 'parkingAllocationHeatmap') && (
              <div className='flex-1 flex justify-end items-center pr-10'>
                <div className='h-5 justify-start items-center gap-3 inline-flex'>
                  <div className='text-zinc-900 text-sm font-semibold leading-tight'>
                    low
                  </div>
                  <div className='w-[324px] h-3.5 bg-gradient-to-r from-white to-green-800 rounded-3xl' />
                  <div className='text-zinc-900 text-sm font-semibold leading-tight'>
                    high
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    );
  };
  let selectInventoryOption = [
    {
      _id: 'unit',
      label: process.env.REACT_APP_INENVETORY_SETTINGS_UI ? inventoryName[0]?.toUpperCase() + inventoryName?.slice(1) + ' Inventory' : unitVariable + ' Inventory',
      value: 'unit',
    },
    {
      _id: 'parking',
      label: 'Parking Inventory',
      value: 'parking',
    },
    {
      _id: 'storage',
      label: 'Storage Inventory',
      value: 'storage',
    },
  ]
  
  return (
    <div className="h-full w-full flex flex-col overflow-hidden">
      <Header
        state={state}
        buildingOptions={buildingOptions}
        handleInventoryChange={handleInventoryChange}
        handleInventoryViewChange={handleInventoryViewChange}
        setSelectedParkingLevel={setSelectedParkingLevel}
        setSelectedStorageLevel={setSelectedStorageLevel}
        selectedBuilding={selectedBuilding}
        setSelectedBuilding={setSelectedBuilding}
        setIsReportScreenOpen={setIsReportScreenOpen}
        selectedParkingLevel={selectedParkingLevel}
        selectedStorageLevel={selectedStorageLevel}
        allParkingLevels={allParkingLevels}
        allStorageLevels={allStorageLevels}
      />
      <div className="flex-1 overflow-y-auto thin-scrollbar">
        {state.selectedInventory === "unit" && (
          <>
            {(state.selectedInventoryView === "availabilityStatus" ||
              state.selectedInventoryView === "demandHeatmap") &&
              (process.env.REACT_APP_INENVETORY_SETTINGS_UI ? (
                <InventoryListViewMain
                  projectId={projectId}
                  buildingId={selectedBuilding}
                />
              ) : (
                <UnitsInventoryListView
                  projectId={projectId}
                  buildingId={selectedBuilding}
                />
              ))
              // <UnitsInventoryListView
              //   projectId={projectId}
              //   buildingId={selectedBuilding}
              // />
            }
            {state.selectedInventoryView === "map" && (
              <UnitInventoryMapView
                projectId={projectId}
                building={selectedBuilding}
              />
            )}
            {state.selectedInventoryView === "parkingTypeHeatmap" && (
              <div className="p-4 h-full bg-white">
                <ParkingHeatmap selectedBuilding={selectedBuilding} />
              </div>
            )}
            {state.selectedInventoryView === "parkingAllocationHeatmap" && (
              <div className="p-4 h-full bg-white">
                <ParkingAllocaitonHeatMap selectedBuilding={selectedBuilding} />
              </div>
            )}
            {state.selectedInventoryView === "tableView" && (
              <div className="p-4 h-full bg-white">
                <TableView
                  selectedBuilding={selectedBuilding}
                  projectId={projectId}
                />
              </div>
            )}
            {state.selectedInventoryView === "tableViewUnit" &&
              (process.env.REACT_APP_INENVETORY_SETTINGS_UI ? (
                <div className="p-4 h-full bg-white">
                  <TableViewInventory
                    selectedBuilding={selectedBuilding}
                    projectId={projectId}
                  />
                </div>
              ) : (
                <TableViewUnit
                  selectedBuilding={selectedBuilding}
                  projectId={projectId}
                />
              ))}
          </>
        )}
        {/* {state.selectedInventory === inventoryName && (
          <>
            {(state.selectedInventoryView === "availabilityStatus" ||
              state.selectedInventoryView === "demandHeatmap") && (
              process.env.REACT_APP_INENVETORY_SETTINGS_UI ? (
              <InventoryListViewMain
                projectId={projectId}
                buildingId={selectedBuilding}
                />
              ) : (
              <UnitsInventoryListView
                projectId={projectId}
                buildingId={selectedBuilding}
              />
              )
            )}

            {state.selectedInventoryView === "tableViewUnit" && (
              <div className="p-4 h-full bg-white">
                <TableViewInventory
                  selectedBuilding={selectedBuilding}
                  projectId={projectId}
                />
              </div>
            )}
          </>
        )} */}
        {state.selectedInventory === "parking" && (
          <>
            {!state.isReportScreenOpen ? (
              <>
                {(state.selectedInventoryView === "availabilityStatus" ||
                  state.selectedInventoryView === "demandHeatmap") && (
                  <ParkingsInventoryListView
                    projectId={projectId}
                    buildingId={selectedBuilding}
                  />
                )}
                {state.selectedInventoryView === "map" && (
                  <ParkingInventoryMapView
                    projectId={projectId}
                    building={selectedBuilding}
                  />
                )}
                {state.selectedInventoryView === "parkingTypeHeatmap" && (
                  <div className="p-4 h-full bg-white">
                    <ParkingHeatmap selectedBuilding={selectedBuilding} />
                  </div>
                )}
                {state.selectedInventoryView === "parkingAllocationHeatmap" && (
                  <div className="p-4 h-full bg-white">
                    <ParkingAllocaitonHeatMap
                      selectedBuilding={selectedBuilding}
                    />
                  </div>
                )}
                {state.selectedInventoryView === "tableView" && (
                  <div className="p-4 h-full bg-white">
                    <TableViewParking
                      selectedBuilding={selectedBuilding}
                      projectId={projectId}
                    />
                  </div>
                )}
              </>
            ) : (
              <ParkingReportView buildingId={selectedBuilding} />
            )}
          </>
        )}
        {state.selectedInventory === "storage" && (
          <>
            {!state.isReportScreenOpen ? (
              <>
                {(state.selectedInventoryView === "availabilityStatus" ||
                  state.selectedInventoryView === "demandHeatmap") && (
                  <StorageInventoryListView
                    projectId={projectId}
                    buildingId={selectedBuilding}
                  />
                )}
                {state.selectedInventoryView === "map" && (
                  <StorageInventoryMapView
                    projectId={projectId}
                    building={selectedBuilding}
                  />
                )}
              </>
            ) : (
              <div className="h-full w-full bg-[#FFF] flex items-center justify-center border-t border-[#E4E4E7]">
                <h3 className="text-lg font-semibold mb-0 text-center">
                  We are working on it.
                </h3>
              </div>
            )}
          </>
        )}
        {state.selectedInventoryView === "brokerAllocations" && (
          <div className="h-full w-full bg-[#FFF] flex items-center justify-center border-t border-[#E4E4E7]">
            <h3 className="text-lg font-semibold mb-0 text-center">
              We are working on it.
            </h3>
          </div>
        )}
        {/* {state.selectedInventory === projectInventory.value && (
          <div className="h-full w-full bg-[#FFF] flex items-center justify-center border-t border-[#E4E4E7]">
            <h3 className="text-lg font-semibold mb-0 text-center">
              We are working on it.
            </h3>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default ProjectInventory;
