// @ts-nocheck
import React, { useEffect, useState } from 'react'
import BuyerInfo from '../CreatePage/BuyerInfo/BuyerInfo'
import { Formik } from 'formik'
import Form from '../CreatePage/Form/Form'
import DemandNotesForm from '../CreatePage/DemandNotesForm/DemandNotesForm'
import { createOrUpdateOpportunity, getBuyers, getOpportunity } from 'store/actions/usersActions'
import { useParams, useHistory } from 'react-router-dom'
import { FadedAnimatedDiv, LottieWrapper } from 'components'
import tableLoading from 'assets/lottieJsons/tableLoading.json'
import { ADMIN_OPPORTUNITIES } from 'routes'

const EditPage = () => {
  const { id } = useParams()
  const history = useHistory()
  const [initialValues, setInitialValues] = useState({})
  const [selectedBuyers, setSelectedBuyers] = useState(null)
  const onSubmit = (values) => {
    createOrUpdateOpportunity(values).then(() => history.push(ADMIN_OPPORTUNITIES))
  }

  useEffect(() => {
    if (id) {
      getOpportunity(id).then((data) => setInitialValues(data))
    }
  }, [])

  useEffect(() => {
    if (initialValues.user) {
      getBuyers().then((res) => {
        setSelectedBuyers(res.filter((elm) => elm._id === '6459e6233afb67f65838838f')[0])
      })
    }
  }, [initialValues])

  return (
    <>
      {initialValues._id
        ? <Formik
          initialValues={{
            _id: initialValues._id,
            name: initialValues.name,
            // user: initialValues.user,
            user: '6459e6233afb67f65838838f',
            rating: initialValues.rating,
            stage: initialValues.stage,
            floorPlansInterest: initialValues.floorPlansInterest,
            floorNumbersInterest: initialValues.floorNumbersInterest,
            numberOfBedrooms: initialValues.numberOfBedrooms,
            firstChoiceUnit: initialValues.firstChoiceUnit,
            secondChoiceUnit: initialValues.secondChoiceUnit,
            thirdChoiceUnit: initialValues.thirdChoiceUnit,
            minimumSquareFeet: initialValues.minimumSquareFeet,
            maximumSquareFeet: initialValues.maximumSquareFeet,
            budget: initialValues.budget,
            orientation: initialValues.orientation,
            primaryUse: initialValues.primaryUse,
            needParking: initialValues.needParking,
            numberOfParkingSpots: initialValues.numberOfParkingSpots,
            upgrades: initialValues.upgrades,
            needsFinancing: initialValues.needsFinancing,
            notes: initialValues.notes,
            project: initialValues.project,
            salesRep: initialValues.salesRep
          }}
          onSubmit={onSubmit}
        >
          {() =>
            <div className="flex flex-row gap-2 w-full h-full">
              <div style={{ width: '20%' }} className="h-full flex flex-col gap-4 p-2 overflow-auto">
                {selectedBuyers
                  ? <>
                    <div className='flex justify-between pb-5 items-start relative'>
                      <BuyerInfo contact={selectedBuyers} />
                    </div>
                    <DemandNotesForm selectedBuyers={selectedBuyers} />
                    <div className='flex flex-col gap-1'>
                      <div>Past Buyer Notes</div>
                      <div style={{ height: '400px' }} className='bg-white rounded'>
                      </div>
                    </div>
                  </>
                  : <>
                    <FadedAnimatedDiv
                      key='loadingTable'
                      className='w-full h-full flex items-center justify-center my-10 flex-col'
                    >
                      <LottieWrapper
                        animation={tableLoading}
                        loop
                        className='h-16 w-16'
                      />
                      <span className='text-base'>loading...</span>
                    </FadedAnimatedDiv>
                  </>
                }
              </div>
              <Form isEdit />
            </div>
          }
        </Formik>
        : <FadedAnimatedDiv
          key='loadingTable'
          className='w-full h-full flex items-center justify-center my-10 flex-col'
        >
          <LottieWrapper
            animation={tableLoading}
            loop
            className='h-16 w-16'
          />
          <span className='text-base'>loading...</span>
        </FadedAnimatedDiv>
      }
    </>
  )
}

export default EditPage
