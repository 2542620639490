import React, { useContext } from 'react'
import { CreateBrokerOpportunityContext } from '../context/CreateBrokerOpportunityContext'
import BuyerInfo from './BuyerInfo/BuyerInfo'
import { Formik } from 'formik'
import Form from './Form/Form'
import { createOrUpdateOpportunity } from 'store/actions/usersActions'
import { BROKER_OPPORTUNITIES_PAGE } from 'routes'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

const CreatePage = () => {
  const history = useHistory()
  // @ts-ignore
  const userObject = useSelector(state => state.authReducer.userObject)
  const { selectedBuyers } = useContext(CreateBrokerOpportunityContext)
  const onSubmit = (values) => {
    createOrUpdateOpportunity(values).then(() => history.push(BROKER_OPPORTUNITIES_PAGE))
  }
  return (
    <Formik
      initialValues={{
        name: null,
        user: selectedBuyers._id,
        // user: '607602b50de444001a71cf35',
        rating: null,
        stage: null,
        floorPlansInterest: [],
        floorNumbersInterest: [],
        numberOfBedrooms: [],
        firstChoiceUnit: null,
        secondChoiceUnit: null,
        thirdChoiceUnit: null,
        minimumSquareFeet: null,
        maximumSquareFeet: null,
        budget: null,
        orientation: null,
        primaryUse: [],
        needParking: false,
        numberOfParkingSpots: null,
        upgrades: [],
        needsFinancing: false,
        notes: null,
        project: selectedBuyers.buyerData.additionalFields[0].project,
        salesRep: selectedBuyers.buyerData.assignments[0].salesRep._id,
        leadBroker: userObject._id
      }}
      onSubmit={onSubmit}
    >
      {() => <div style={{ height: '95vh' }} className='flex flex-col'>
        <div className='w-full flex justify-between items-center'>
          <h3 className='text-4xl font-bold ml-2 mb-0'>Create Opportunity</h3>
        </div>
        <div className='flex flex-row w-full h-full pt-4 rounded overflow-hidden'>
          <div className='h-full flex flex-col'>
            <div className='h-full flex justify-between items-start relative'>
              {selectedBuyers && <BuyerInfo contact={selectedBuyers} />}
            </div>
          </div>
          <Form isEdit={false} selectedBuyers={selectedBuyers} />
        </div>
      </div>}
    </Formik>
  )
}

export default CreatePage
