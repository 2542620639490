/*eslint-disable */
// @ts-nocheck
import React, { useCallback, useEffect, useMemo } from 'react';
import TableBody from './TableBody/TableBody';
import TableHead from './TableHead/TableHead';
import { FadedAnimatedDiv } from 'components';
import Searching from 'pages/contacts/Searching';
import TablePagination from './TablePagination/TablePagination';
import useContactContext from 'pages/newContacts/hooks/useContactContext';
// import {
//   filterAssignmentsBySaleRepId,
//   sortContacts
// } from 'pages/newContacts/context/MainContactContext'
import { useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import { isMobile } from 'react-device-detect';
import moment from 'moment';

const UserTable = () => {
  const { state, isUserLoading: isLoading } = useContactContext();
  // @ts-ignore
  const userObject = useSelector((state) => state.authReducer.userObject);
  const LoadingContacts = useCallback(
    () => (
      <FadedAnimatedDiv className='h-full w-full flex flex-col items-center mt-20'>
        <Searching title='Loading contacts...' />
      </FadedAnimatedDiv>
    ),
    []
  );

  useEffect(() => {
    // setPage(1)
  }, [state.contacts, state?.contactUserType]);

  const filteredContacts = useMemo(() => {
    const _contacts = state.contacts.map((contact) => ({
      ...contact,
      dateRegistered: moment(contact?.createdAt).isValid() ? (moment(contact?.createdAt).format('MM/DD/YYYY')) : '',
    }));
    const _filterContacts = _contacts.filter((contact) => {
      const salesType = contact?.userType?.toLowerCase?.();
      if (state?.contactUserType?.toLowerCase?.() === 'leadbroker') {
        return (
          salesType?.indexOf(state?.contactUserType?.toLowerCase?.()) !== -1 && (contact?.deleted === false || contact?.deleted === undefined)
        );
        // return (
        //   salesType?.indexOf(state?.contactUserType?.toLowerCase?.()) !== -1 &&
        //   filterAssignmentsBySaleRepId(
        //     contact?.leadBrokerData?.assignments,
        //     userObject,
        //     true,
        //     state?.filters?.salesRepOwner
        //   )
        // )
      } else if (state?.contactUserType?.toLowerCase?.() === 'buyer') {
        return (
          salesType?.indexOf(state?.contactUserType?.toLowerCase?.()) !== -1 && (contact?.deleted === false || contact?.deleted === undefined)
        );
        // return (
        //   salesType?.indexOf(state?.contactUserType?.toLowerCase?.()) !== -1 &&
        //   filterAssignmentsBySaleRepId(
        //     contact?.buyerData?.assignments,
        //     userObject,
        //     true,
        //     state?.filters?.salesRepOwner
        //   )
        // )
      } else if(state?.contactUserType?.toLowerCase?.() === 'default') {
        return true;
      } else {
        return false;
      }
    });
    _filterContacts.sort((a, b) => {
      // const condition = moment(a.createdAt).isBefore(b.createdAt)
      const aData = new Date(a.createdAt);
      const bData = new Date(b.createdAt);
      // @ts-ignore
      return state.sortType !== 'asc' ? aData - bData : bData - aData;
    });
    return _filterContacts ?? [];
  }, [
    userObject,
    state?.sortType,
    state?.contacts,
    state?.contactUserType,
    state?.filters?.salesRepOwner,
    state.filters
  ]);

  useEffect(() => {
    if (filteredContacts?.length) {
      toast.success(`Found ${filteredContacts?.length} contacts`);
    }
  }, [filteredContacts?.length]);
  

  if (isLoading) return <LoadingContacts />;
  return (
    <div className='flex flex-col justify-center'>
      <div
        // style={{ paddingLeft: '2.7rem', paddingRight: '1.9rem' }}
        className={`inline-block align-middle ${
          !state.isContactDetailDrawerOpen || isMobile
            ? 'w-full py-2 '
            : 'w-[350px]'
        }`}
      >
        <div
          className={`relative transition-all ${
            !state.isContactDetailDrawerOpen || isMobile
              ? 'md:shadow md:ring-1 md:ring-black md:ring-opacity-5 overflow-x-auto md:rounded-lg'
              : 'overflow-x-hidden -ml-4'
          }`}
        >
          <table
            className={`min-w-full table-fixed ${
              !state.isContactDetailDrawerOpen || isMobile
                ? 'divide-y divide-gray-300'
                : ''
            }`}
          >
            <TableHead />
            <TableBody contacts={filteredContacts} />
          </table>
          <TablePagination customContacts={null} contacts={filteredContacts} />
        </div>
      </div>
    </div>
  );
};

export default UserTable;
