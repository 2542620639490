import React from 'react'

import CustomModal from 'components/Modal'
import LargeUnitCard from 'components/LargeUnitCard'
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg'

import { isMobile } from 'react-device-detect'

const LargeUnitCardModal = ({
  openModal,
  setOpenModal,
  tempCardToOpen,
  onReserveClicked = null,
  onPurchaseClicked = null
}) => {
  const closeModal = () => {
    setOpenModal(false)
  }

  if (!tempCardToOpen) return null

  return (
    <CustomModal
      isOpen={openModal}
      closeModal={closeModal}
      contentLabel='Unit Details'
      customStyles={{
        width: '80%',
        height: '90%'
      }}
    >
      <div
        className={`${isMobile ? 'overflow-hidden' : ''} w-full p-10 rounded`}
      >
        <div className='flex w-full justify-between'>
          <p className='flex-1 mb-8 font-bold text-xl text-softBlack text-center'>
            Unit Details
          </p>
          <button
            className='absolute right-10'
            onClick={() => {
              setOpenModal(false)
            }}
          >
            <CrossIcon />
          </button>
        </div>
        <LargeUnitCard
          imgsrc={tempCardToOpen.floorPlan?.image ?? null}
          unitName={`#${tempCardToOpen.unitNumber}`}
          description=''
          noOfBedrooms={tempCardToOpen?.numberOfBedrooms}
          noOfBathrooms={tempCardToOpen?.numberOfBathrooms}
          balcony={tempCardToOpen?.balcony}
          noOfParkingSlot={tempCardToOpen?.floorPlan?.parkingQty}
          building={tempCardToOpen?.building?.name}
          price={tempCardToOpen?.price}
          address={tempCardToOpen?.fullAddress}
          tagText={
            tempCardToOpen?.status?.toLowerCase().includes('reserved')
              ? `reserved until ${tempCardToOpen.reservedUntil}`
              : tempCardToOpen.status
          }
          tag={tempCardToOpen.status}
          leftButtonLabel={'Edit'}
          onLeftButtonClick={() => {
            onReserveClicked?.()
          }}
          rightButtonLabel='Purchase'
          onRightButtonClick={() => {
            onPurchaseClicked?.()
          }}
        />
      </div>
    </CustomModal>
  )
}

export default LargeUnitCardModal
