import React from 'react'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
import { H1 } from 'ui'
import { getDayPeriod } from 'utils'

const WelcomeTitle = (props) => {
  const { userObject: { firstName }, className } = props

  return (
    <H1 className={`font-bold ${className}`}>{`Good ${getDayPeriod()}, ${firstName}`}</H1>
  )
}

const mapStateToProps = (state) => ({
  userObject: state.authReducer.userObject
})

export default connect(mapStateToProps, {})(WelcomeTitle)

WelcomeTitle.propTypes = {
  userObject: propTypes.shape({
    firstName: propTypes.string
  }),
  className: propTypes.string
}
