import React from 'react'
import InventoryContextProvider from 'components/Inventory/context/InventoryContext'
import WhiteCapDashboard from '.'

const DashboardWrapper = ({ appProject }) => {
  return (
    <InventoryContextProvider>
      <WhiteCapDashboard/>
    </InventoryContextProvider>
  )
}

export default DashboardWrapper
