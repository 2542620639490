/* eslint-disable */
import React, { useState } from 'react'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
import { handleLogout } from 'store/actions/authActions'
import BrokerMenus from 'components/BrokerMenus'
import Sider from 'components/Sider'

const BrokerSideMenu = (props) => {
  const {
    showMenu,
    setShowMenu,
    // userObject: { firstName, userAvatar },
    handleLogout
  } = props
  const [selectedMenuId, setSelectedMenuId] = useState('dashboard')

  // const profileOptions = [
  //   {
  //     title: 'Your Profile',
  //     onClick: () => {
  //       history.push(BROKER_PROFILE_PAGE)
  //       setShowMenu(false)
  //     }
  //   },
  //   {
  //     title: 'Logout',
  //     onClick: () => setShowLogoutModal(true)
  //   }
  // ]

  // const UserProfile = () => (
  //   <Dropdown
  //     title={firstName}
  //     imgSrc={userAvatar}
  //     options={profileOptions}
  //     backgroundColor='bg-white'
  //     backgroundHoverColor='bg-none'
  //     border='border border-gray-200'
  //     shadow='shadow-lg'
  //     focus='focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500'
  //     className='lg:hover:bg-gray-200 border'
  //     arrowDownIconColor='text-gray-400'
  //   />
  // )

  return (
    <>
      <Sider
        showMenu={showMenu}
        setShowMenu={setShowMenu}
        closeButtonColor='text-black'
        closeButtonClassName='left-2'
        fixed={false}
      >
        <BrokerMenus
          showMenu={showMenu}
          selectedMenuId={selectedMenuId}
          setSelectedMenuId={setSelectedMenuId}
          setShowMenu={setShowMenu}
          handleLogout={handleLogout}
        />
      </Sider>
    </>
  )
}

const mapStateToProps = (state) => ({
  userObject: state.authReducer.userObject
})

const mapDispatchToProps = {
  handleLogout
}

export default connect(mapStateToProps, mapDispatchToProps)(BrokerSideMenu)

BrokerSideMenu.propTypes = {
  showMenu: propTypes.bool,
  setShowMenu: propTypes.func,
  userObject: propTypes.shape({
    firstName: propTypes.string,
    userAvatar: propTypes.string
  }),
  handleLogout: propTypes.func
}
