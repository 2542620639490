import { useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { cancelReservation } from 'store/actions/reservationActions'

const useCancelReservation = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  // @ts-ignore
  const userObject = useSelector((state) => state.authReducer.userObject)

  const callApi = useCallback(
    async (reservationId) => {
      try {
        setLoading(true)
        const response = await cancelReservation(reservationId)
        setData(response)
        setLoading(false)
      } catch (e) {
        setLoading(false)
        console.log('Failed to cancel reservation', e)
      }
    },
    [userObject]
  )

  return [callApi, loading, data]
}

export default useCancelReservation
