import useDynamicDropdown from './useDynamicDropdown'

const useLeadStatus = () => {
  const {
    data: leadStatuses,
    loading,
    update,
    deleteItem: deleteLeadStatus,
    updateItem: updateSpecific,
    formattedData: formattedLeadStatuses
  } = useDynamicDropdown('leadStatus')

  return {
    leadStatuses,
    loading,
    update,
    deleteLeadStatus,
    updateSpecific,
    formattedLeadStatuses: formattedLeadStatuses?.map(({ value, label, id }) => ({
      label: label.trim(),
      value: value.trim(),
      id: id.trim()
    }))
  }
}

export default useLeadStatus
