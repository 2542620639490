import React from 'react'
import propTypes from 'prop-types'

import { UserCircleIcon } from '@heroicons/react/solid'

const UserRoundedImage = ({ src, className, applyMarginEmptyImage = true }) => (
  <>
    {
      src
        ? (
            <img
              id='userAvatar'
              className={`rounded-full ${className}`}
              src={src}
              alt='User Avatar'
            />
          )
        : (
            <UserCircleIcon
              style={{ marginLeft: applyMarginEmptyImage && -7, marginTop: applyMarginEmptyImage && -7 }}
              id='userEmptyAvatar'
              className={`text-gray-400 ${className}`}
            />
          )
    }
  </>
)

export default UserRoundedImage

UserRoundedImage.defaultProps = {
  className: 'h-8 w-8'
}

UserRoundedImage.propTypes = {
  src: propTypes.string,
  className: propTypes.string,
  applyMarginEmptyImage: propTypes.bool
}
