import React, { memo, useCallback, useState } from 'react'
import { Modal, TButton } from 'ui'
import { AnimatedList, FadedAnimatedDiv, TCancelButton, TDeleteButton } from 'components'
import { AnimatePresence, motion } from 'framer-motion'

const FilePreview = memo(({ file, onDeleteItem, key }) => {
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)

  const showDeleteConfirm = useCallback(() => {
    setShowConfirmDelete(true)
  }, [])

  const cancelDeleteConfirm = useCallback(() => {
    setShowConfirmDelete(false)
  }, [])

  const confirmDelete = useCallback(() => {
    onDeleteItem(file)
  }, [onDeleteItem, file])

  return (
    <motion.div key={key} whileHover={{ scale: 1.10 }} className='relative flex flex-col items-center'>
      {file.url && <img src={file.url} alt='file preview' className='h-16 w-32 rounded-md object-fill' />}
      <span className='text-base'>{file.name || ''}</span>
      <AnimatePresence exitBeforeEnter>
        {
          showConfirmDelete
            ? <AnimatedList wrapperClassName='flex flex-row space-x-3'>
            <TCancelButton onClick={cancelDeleteConfirm}>Cancel</TCancelButton>
            <TButton onClick={confirmDelete}>Yes</TButton>
          </AnimatedList>
            : <FadedAnimatedDiv><TDeleteButton className='mt-2' onClick={showDeleteConfirm}>Remove</TDeleteButton></FadedAnimatedDiv>
        }
      </AnimatePresence>
    </motion.div>
  )
})

const FileListModal = ({ showModal, onOk, fileList, onDeleteItem }) => {
  return (
    <Modal
      modalType='custom'
      showModal={showModal}
      waitAnimationToShow
      onOk={onOk}
      okText='Ok'
    >
      <div className='w-full text-center'>
       <span className='text-2xl'>Manage your files below</span>
      </div>

      <AnimatedList wrapperClassName='grid grid-cols-3 gap-5 mt-5'>
        {
          fileList?.map(file => <FilePreview file={file} key={file.name} onDeleteItem={onDeleteItem} />)
        }
      </AnimatedList>
    </Modal>
  )
}

export default FileListModal
