import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useAnimation } from 'framer-motion'
import { PwaWrapper, SomethingWentWrong } from 'components'

import { fadeVariants } from 'utils'
import {
  PWA_CREATE_OFFER_NEW,
  PWA_PROJECT_FLOOR_PLANS,
  PWA_PROJECT_OFFERS,
  PWA_PROJECT_AVAILABILITY_BOARD
} from 'routes'
import {
  CgArrowRight,
  CgArrowLeft,
  CgMathPlus,
  CgChevronLeft
} from 'react-icons/cg'

const ProjectScreen = ({ location: { state } }) => {
  const [projects, setProjects] = useState([])
  const [isProjectsLoading, setIsProjectsLoading] = useState(true)

  const [currentProjectIndex, setCurrentProjectIndex] = useState(-1)
  const [isCurrentProjectIndexLoading, setIsCurrentProjectIndexLoading] =
    useState(true)

  const animatedWrapper = useAnimation()
  const history = useHistory()
  const [projectIndex, setProjectIndex] = useState(-1)

  const goToBack = useCallback(() => {
    history.goBack()
  }, [history])

  const goToStatesAndOffers = useCallback(() => {
    history.push(PWA_PROJECT_OFFERS)
  }, [history])

  const goToFloorPlans = useCallback(() => {
    history.push({
      pathname: PWA_PROJECT_FLOOR_PLANS,
      state: { floorPlanUrl: 'https://hollybridgeliving.com/towers/tower-f' }
    })
  }, [history])

  const goToAvailabilityBoard = useCallback(() => {
    history.push(PWA_PROJECT_AVAILABILITY_BOARD)
  }, [history])

  const goToCreateOffer = useCallback(() => {
    history.push(PWA_CREATE_OFFER_NEW)
  }, [history])

  useEffect(() => {
    let _projects = []
    if (state?.projects.length > 0) {
      localStorage.setItem('projects', JSON.stringify(state?.projects))
      _projects = state?.projects
    } else {
      _projects = JSON.parse(localStorage.getItem('projects')) ?? []
    }
    setProjects(structuredClone(_projects))
    setIsProjectsLoading(false)
  }, [])

  useEffect(() => {
    let _currentProjectIndex
    if (
      state?.currentProjectIndex !== undefined &&
      state?.currentProjectIndex !== -1
    ) {
      localStorage.setItem('currentProjectIndex', state?.currentProjectIndex)
      _currentProjectIndex = state?.currentProjectIndex
    } else {
      _currentProjectIndex =
        parseInt(localStorage.getItem('currentProjectIndex')) ?? -1
    }
    setCurrentProjectIndex(_currentProjectIndex)
    setProjectIndex(_currentProjectIndex)
    setIsCurrentProjectIndexLoading(false)
  }, [])

  useEffect(() => {
    if (projectIndex !== undefined) {
      // @ts-ignore
      localStorage.setItem('currentProjectIndex', projectIndex)
    }
  }, [projectIndex])

  const ActionButton = ({ label, onClick }) => {
    return (
      <button
        className='flex space-x-4 items-center font-semibold text-2xl text-white'
        onClick={() => {
          onClick?.()
        }}
      >
        <span>{label}</span>
        <CgArrowRight className='w-8 h-8' />
      </button>
    )
  }

  const ArrowWrapper = ({ onClick, children, disabled = false }) => {
    return (
      <button
        onClick={() => {
          onClick()
        }}
        className='flex items-center justify-center h-14 w-14 bg-volt text-softBlack space-x-[14.33px] rounded'
        disabled={disabled}
      >
        {children}
      </button>
    )
  }

  if (isProjectsLoading || isCurrentProjectIndexLoading) {
    return null
  }

  if (currentProjectIndex === -1 || !projects?.length) {
    return <SomethingWentWrong />
  }

  return (
    <PwaWrapper
      animate={animatedWrapper}
      initial={false}
      variants={fadeVariants}
    >
      <div className='flex flex-row bg-menuGray w-full h-full text-white'>
        <div className='relative bg-softBlack w-2/3 h-full'>
          <button
            className='absolute top-20 left-20 flex space-x-2 items-center font-semibold text-base'
            onClick={() => {
              goToBack?.()
            }}
          >
            <CgChevronLeft className='w-8 h-8' />
            <span>Projects</span>
          </button>
          <span className='absolute left-20 top-[20%] font-bold text-5xl'>
            {projects?.[projectIndex]?.projectName}
          </span>
          <div className='absolute left-20 top-1/2 flex flex-col space-y-8 -translate-y-1/2'>
            <ActionButton
              label='States and Offers'
              onClick={() => {
                goToStatesAndOffers?.()
              }}
            />
            <ActionButton
              label='Floor Plans'
              onClick={() => {
                goToFloorPlans?.()
              }}
            />
            <ActionButton
              label='Availability Board'
              onClick={() => {
                goToAvailabilityBoard?.()
              }}
            />
          </div>
          <button
            onClick={() => {
              goToCreateOffer?.()
            }}
            className='absolute flex justify-center items-center left-20 bottom-20 w-60 h-16 bg-volt py-4 pl-8 pr-8 text-softBlack space-x-[17.83px] rounded'
          >
            <CgMathPlus className='h-6 w-6' />
            <span className='font-medium text-xl text-softBlack'>
              Create Offer
            </span>
          </button>
        </div>
        <div className='absolute right-20 bottom-20 flex space-x-8'>
          <ArrowWrapper
            onClick={() => {
              setProjectIndex(projectIndex - 1)
            }}
            disabled={projectIndex === 0}
          >
            <CgArrowLeft className='h-6 w-6' />
          </ArrowWrapper>
          <ArrowWrapper
            onClick={() => {
              setProjectIndex(projectIndex + 1)
            }}
            disabled={projectIndex === projects?.length - 1}
          >
            <CgArrowRight className='h-6 w-6' />
          </ArrowWrapper>
        </div>
        <div className='absolute right-0 top-1/2 -translate-y-1/2 w-3/5 h-[63.86%]'>
          <img
            alt='Project Main image'
            src={projects?.[projectIndex]?.backgroundImage}
            className='object-cover h-full w-full'
          />
        </div>
      </div>
    </PwaWrapper>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectScreen)

ProjectScreen.propTypes = {}
