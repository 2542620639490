import React from 'react'
import { connect } from 'react-redux'

const AdminLeadReport = (props) => {
  const activityItems = [
    {
      user: {
        name: 'Stefan Dupuis',
        imageUrl: '/stefan-dupuis.png'
      },
      email: 'stefan@aires.ai',
      phone_number: '+1778589654',
      broker: 'Shannon Mckenzie',
      project: '1818 Pacifica - Vancouver'
    },
    {
      user: {
        name: 'Alena Donin',
        imageUrl: '/alena-donin.png'
      },
      email: 'alenadonin@RBC.ca',
      phone_number: '+1788365942',
      broker: 'Connor Lee',
      project: '1818 Pacifica - Vancouver'
    },
    {
      user: {
        name: 'Lindsey Calzoni',
        imageUrl: '/lindesy-calzoni.png'
      },
      email: 'lindseycalzoni@gmail.com',
      phone_number: '+16049282982',
      broker: 'Karen Cho',
      project: '1818 Pacifica - Vancouver'
    },
    {
      user: {
        name: 'Martin Levin',
        imageUrl: '/martin-levin.png'
      },
      email: 'martinlevin@gmail.com',
      phone_number: '+1261112982',
      broker: 'Rachel Almasi',
      project: '1818 Pacifica - Vancouver'
    },
    {
      user: {
        name: 'Jocelyn Passaquindici',
        imageUrl: '/jocelyn-passaquindici.png'
      },
      email: 'jocelynarcand@canadapost.com',
      phone_number: '+1614566783',
      broker: 'Connor Lee',
      project: '1818 Pacifica - Vancouver'
    },
    {
      user: {
        name: 'Zaire Schleifer',
        imageUrl: '/zaire-schleifer.png'
      },
      email: 'zaireschleifer@gmail.com',
      phone_number: '+1778928922',
      broker: 'Ian Purchase',
      project: '1818 Pacifica - Vancouver'
    }
  ]
  return (
    <div className='w-full relative '>
      <img
        src='/background-img.png'
        className='object-cover   w-full h-[360px]'
      />
      <div className='absolute w-full  top-[48px] px-[20px] xl:px-[40px]'>
        <div className='bg-white py-3 max-w-[350px] pl-4 pr-10 text-[#2E2B2EB3] text-[19px] font-bold'>
          <div> LEAD GENERATION REPORT </div>
          <div> 24/04/2023 - 30/04/2023 </div>
        </div>
        <div className=' grid grid-cols-12 col-span-12 gap-6 xl:gap-10  mt-12 justify-between'>
          <div className='bg-white z-99 col-span-2 w-full px-5 py-4 rounded-[4px]  flex flex-col  items-center justify-center'>
            <div className='flex flex-col text-start  justify-center'>
              <div className='text-[20px] font-bold'>268</div>
              <div className='text-[#2E2B2EB3] text-[12px] italic font-bold uppercase'>
                Lead Registrations
              </div>
            </div>
          </div>
          <div className='bg-white z-99  col-span-2 w-full px-5 py-4 rounded-[4px]  flex flex-col  items-center justify-center'>
            <div className='flex flex-col text-start  justify-center'>
              <div className='text-[20px] font-bold'>198</div>
              <div className='text-[#2E2B2EB3] text-[12px] italic font-bold uppercase'>
                Buyer Leads
              </div>
            </div>
          </div>
          <div className='bg-white z-99  col-span-2 w-full px-5 py-4 rounded-[4px]  flex flex-col  items-center justify-center'>
            <div className='flex flex-col text-start  justify-center'>
              <div className='text-[20px] font-bold'>74</div>
              <div className='text-[#2E2B2EB3] text-[12px] italic font-bold uppercase'>
                Broker Landing Pages
              </div>
            </div>
          </div>
          <div className='bg-white z-99  col-span-2 w-full px-5 py-4 rounded-[4px]  flex flex-col  items-center justify-center'>
            <div className='flex flex-col text-start  justify-center'>
              <div className='text-[20px] font-bold'>31</div>
              <div className='text-[#2E2B2EB3] text-[12px] italic font-bold uppercase'>
                Appointments Booked
              </div>
            </div>
          </div>
          <div className='flex  col-span-4  flex-col gap-y-[16px] p-6 bg-[#FCFCFA] '>
            <div className='text-[17px] font-bold'>Lead Score</div>
            <div className='py-[12px] flex justify-between'>
              <div className='flex items-center gap-x-[8px]'>
                <div className='h-full w-[11px] bg-[#EDDF65]'></div>
                <div className='flex gap-x-2'>
                  <div className='font-bold'>63</div>
                  <div className='italic uppercase font-bold text-[#2E2B2EB2]'>
                    A
                  </div>
                </div>
              </div>
              <div className='flex items-center gap-x-[8px]'>
                <div className=' h-full w-[11px] bg-[#2E2B2EB3]'></div>
                <div className='flex gap-x-2'>
                  <div className='font-bold'>81</div>
                  <div className='italic uppercase  font-bold text-[#2E2B2EB2]'>
                    B
                  </div>
                </div>
              </div>
              <div className='flex items-center gap-x-[8px]'>
                <div className=' h-full w-[11px] bg-[#BEBDBD]'></div>
                <div className='flex gap-x-2'>
                  <div className='font-bold'>126</div>
                  <div className='italic uppercase  font-bold text-[#2E2B2EB2]'>
                    C
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className=' relative rounded-[3px] h-[35px]'>
                <div className='absolute h-full left-0 bg-[#EDDF65] w-[20%]'></div>
                <div className='absolute h-full left-[20%] bg-[#2E2B2EB2] w-[30%]'></div>
                <div className='absolute  h-full left-[50%] bg-[#BEBDBD] w-[50%]'></div>
              </div>
            </div>
          </div>
        </div>
        <div className=' py-10 text-black'>
          <table className='mt-6 w-full whitespace-nowrap text-left'>
            <thead className='border-b italic text-[#2E2B2EB3] border-white/10 text-[20px] leading-6'>
              <tr>
                <th scope='col' className='py-2  pr-8  uppercase font-semibold'>
                  NAME
                </th>
                <th scope='col' className='py-2  pr-8  uppercase font-semibold'>
                  EMAIL
                </th>
                <th scope='col' className='py-2  pr-8  uppercase font-semibold'>
                  Phone Number
                </th>
                <th scope='col' className='py-2  pr-8  uppercase font-semibold'>
                  Broker
                </th>
                <th scope='col' className='py-2  pr-8  uppercase font-semibold'>
                  Project
                </th>
              </tr>
            </thead>
            <tbody className='divide-y text-[#2E2B2E] divide-[#2E2B2E]'>
              {activityItems.map((item) => (
                <tr key={item.commit}>
                  <td className='py-4 pr-8'>
                    <div className='flex items-center gap-x-4'>
                      <img
                        src={item.user.imageUrl}
                        alt=''
                        className='h-16 w-16 rounded-full bg-gray-800'
                      />
                      <div className=' text-sm font-medium leading-6'>
                        {item.user.name}
                      </div>
                    </div>
                  </td>
                  <td className=' py-4 pl-0 text-[#2E2B2E] pr-4 sm:table-cell sm:pr-8'>
                    <div className='flex gap-x-3'>
                      <div className='text-sm leading-6'>{item.email}</div>
                    </div>
                  </td>
                  <td className='py-4 pl-0 pr-4 text-sm leading-6 sm:pr-8 lg:pr-20'>
                    <div className='flex items-center  gap-x-2 sm:justify-start'>
                      <div>{item.phone_number}</div>
                    </div>
                  </td>
                  <td className=' py-4 pl-0 pr-8 text-sm leading-6md:table-cell lg:pr-20'>
                    {item.broker}
                  </td>
                  <td className=' py-4 pl-0 pr-4  text-[16px] leading-6sm:table-cell sm:pr-6 lg:pr-8'>
                    <div>{item.project}</div>
                  </td>
                </tr>
              ))}
              <tr className='py-6'>
                <td>
                  <div>
                    Show <span className='italic font-light  '> 6 of 268</span>
                  </div>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td className='text-right flex gap-4 justify-end'>
                <div>
                <button className="flex items-center justify-center">
                  <img className="" src="/left-arrow-table.svg" />
                </button>
              </div>
              <button className="active">1</button>
              <button>2</button>
              <button>3</button>
              <div>...</div>
              <button>8</button>
              <button></button>
              <div>
                <button className="flex items-center justify-center">
                  <img className="" src="/right-arrow-table.svg" />
                </button>
              </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  userObject: state.authReducer.userObject
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(AdminLeadReport)
