import React from 'react'
import {
  Tooltip,
  ResponsiveContainer,
  Cell,
  PieChart,
  Pie,
  Legend
} from 'recharts'

const ReportChat = ({
  data,
  label = true,
  labelLine = true,
  legend = true,
  height = 400,
  width = '100%',
  innerRadius = 50,
  outerRadius = 90,
  fill = '#8884d8',
  paddingAngle = 2,
  colors = ['#EDDF65', '#59B099', '#54B5D1', '#DF6D50', '#646264']
}) => {
  return <>
    <div style={{ width, height }}>
      <ResponsiveContainer height={height}>
        <PieChart>
          <Pie
            dataKey='value'
            data={data}
            labelLine={labelLine}
            fill={fill}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            paddingAngle={paddingAngle}
            label={label}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
            ))}
          </Pie>
          {legend ? <Legend iconSize={15} width={120} height={140} layout='vertical' verticalAlign='middle' align="right" /> : null}
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    </div>
  </>
}

export default ReportChat
