import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import reducers from 'store/reducers'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['appReducer']
}

const persistedReducer = persistReducer(persistConfig, reducers)

export default ({ initialState } = {}) => {
  const enhacer = compose(
    applyMiddleware(thunk),
    logger()
  )

  const store = createStore(
    persistedReducer,
    initialState,
    enhacer
  )

  const persistor = persistStore(store)

  return { store, persistor }
}

const logger = () => window.__REDUX_DEVTOOLS_EXTENSION__
  ? window.__REDUX_DEVTOOLS_EXTENSION__()
  : (x) => x
