// @ts-nocheck
import React, { useCallback, useEffect, useState } from 'react'
import propTypes from 'prop-types'
import useSearchParams from 'hooks/useSearchParams'
import { FadedAnimatedDiv } from 'components'
import styled from 'styled-components'
import useGetBuildings from 'hooks/useGetBuildigs'
import useRealtimeUnitUpdate, {
  getUnitEventEmitter
} from 'hooks/useRealtimeUnitUpdate'
import { formatMoney } from 'utils/formatters'
import { AVAILABILITY_BG_COLOR } from 'utils'
import useGetStorages from 'hooks/useGetStorages'
import StorageListView from 'components/ProjectStoragesInventory/components/StorageListView'

export const ScreenWrapper = styled.div``

const StorageCard = ({
  imgsrc = '',
  unitNumber = '',
  price = 0,
  tag = ''
}) => {
  return (
    <div className='flex flex-col bg-menuGray gap-2 rounded'>
      <img
        src={imgsrc}
        alt='Storage'
        className='object-fill w-full h-[196px] border-2 border-black rounded p-2'
      />
      <div className='my-2 h-1 divide-y-2 divide-softBlack_70 w-full bg-black ' />
      <div className='flex flex-col text-softBlack px-4 pb-4'>
        <div
          className={
            'font-medium text-xs px-1 py-[4.5px] w-[171px] h-[27px] uppercase'
          }
          style={{
            backgroundColor: AVAILABILITY_BG_COLOR[tag.toLowerCase()]
          }}
        >
          {tag}
        </div>
        <div className='font-bold text-2xl mt-2'>{unitNumber}</div>
        <div className='font-medium text-sm mt-[9px]'>{formatMoney(price)}</div>
      </div>
    </div>
  )
}

const AnimatedDiv = ({ children, key }) => (
  <FadedAnimatedDiv
    key={key}
    className='h-full w-full flex flex-col items-center justify-center'
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
  >
    {children}
  </FadedAnimatedDiv>
)
const StorageInventoryPublicPage = () => {
  const searchParams = useSearchParams()
  const projectId = atob(searchParams.get('projectId'))
  useRealtimeUnitUpdate(projectId)
  // @ts-ignore
  const { loadingStorages, storages, refetchStorages } = useGetStorages()
  const { buildings, loading: getBuildings } = useGetBuildings('', true)
  const [realTimeUpdateArray, setRealTimeUpdateArray] = useState([])

  const onUnitRealtimeUpdate = useCallback((newData) => {
    // temp logic to show only SoldFirm Units
    if (newData?.status?.toLowerCase()?.includes('soldfirm')) {
      setRealTimeUpdateArray((prev) => {
        return [newData, ...prev.filter((unit) => unit?.id !== newData?.id)]
      })
    }
  }, [])

  useEffect(() => {
    getUnitEventEmitter()?.addListener('unit-update', onUnitRealtimeUpdate)
    return () => {
      getUnitEventEmitter()?.removeListener('unit-update', onUnitRealtimeUpdate)
    }
  }, [])

  return (
    <ScreenWrapper
      style={{ background: '#f8f8f9' }}
      className='h-screen w-screen'
    >
      <div className='flex flex-row gap-2 w-screen h-screen'>
        {
          // Left side
          <div className='flex-1 overflow-y-scroll gap-3 h-screen'>
            <StorageListView
              isPublicPage={true}
              {...{
                storages,
                loading: loadingStorages,
                buildings,
                getBuildings,
                refetchStorages
              }}
            />
          </div>
        }
        {
          // Right Side
          <div className='min-w-[448px] max-w-[448px] h-screen'>
            <div className='flex flex-col w-full overflow-y-scroll p-4 gap-3 h-screen'>
              <h1 className='font-bold text-xl justify-center'>Recent Sales</h1>
              {realTimeUpdateArray?.length > 0 &&
                realTimeUpdateArray.map((selectedUnit) => {
                  if (!selectedUnit) return null
                  return (
                    <StorageCard
                      imgsrc={selectedUnit?.floorPlan?.image}
                      unitNumber={`#${selectedUnit?.unitNumber}`}
                      price={selectedUnit?.price}
                      tag={selectedUnit?.status}
                    />
                  )
                })}
            </div>
          </div>
        }
      </div>
    </ScreenWrapper>
  )
}

export default StorageInventoryPublicPage

AnimatedDiv.propTypes = {
  children: propTypes.node,
  key: propTypes.string
}
