import { Drawer } from 'antd'
import { CloseIcon } from 'components'
import React from 'react'
import { isMobile } from 'react-device-detect'

const EmailTemplatePreview = ({ selectedTemplate, onClose, open }) => {
  const html = selectedTemplate?.unlayerConfig?.html || ''
  if (!selectedTemplate) return null
  return (
    <Drawer size={isMobile ? 'default' : 'large'} closable={true} placement="right" onClose={onClose} open={open}>
      <div className='flex justify-end flex-col gap-2 h-full'>
        <div className='flex w-full justify-between'>
          <h1 className='text-2xl'>
            {selectedTemplate.name}
          </h1>
          <button
            className='gap-x-1 text-softBlack text-center text-base font-medium py-1 px-3 rounded flex items-center border border-black'
            onClick={() => {
              onClose()
            }}
          >
            <CloseIcon />
            <span>Exit
            </span>
          </button>
        </div>
        {!html &&
          <div className='flex-1 flex items-center justify-center flex-col'>
            <h1 className='text-center text-xl py-6 mb-0 text-red-400'>
              Empty template
            </h1>
            <p>
              Start by adding some content to your template.
            </p>
          </div>
        }
        {html && (
          <div
            className='flex-1 border-border border shadow-sm overflow-auto'
            dangerouslySetInnerHTML={{ __html: html }} >
          </div>
        )}
      </div>
    </Drawer >
  )
}

export default EmailTemplatePreview
