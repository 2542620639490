// @ts-nocheck
import React, { useEffect, useState } from 'react'
import CustomInput from '../../CustomInput'
import CustomSelect from '../../CustomSelect'
import { getUsers } from 'store/actions/usersActions'
import { H5 } from 'ui'

const prefixData = [
  { id: 'mr', value: 'Mr.' },
  { id: 'miss', value: 'Miss.' },
  { id: 'ms', value: 'Ms.' },
  { id: 'mrs', value: 'Mrs.' }
]

const BuyersTab = ({ buyerId }) => {
  const tabMenus = [
    {
      id: 'tab-1',
      name: 'Individual'
    },
    {
      id: 'tab-2',
      name: 'Corporation'
    }
  ]
  const [selectedTad, setSelectedTab] = useState('tab-1')
  const [values, setValues] = useState({
    id: '',
    prefix: '',
    firstName: '',
    lastName: '',
    legalName: '',
    phoneNumber: '',
    birthday: '',
    placeOfBirth: '',
    idNumber: '',
    idType: '',
    idExpireDate: ''
  })

  useEffect(() => {
    getUsers(buyerId)
      .then((res) => setValues({
        id: res._id,
        prefix: res.buyerData.prefix,
        firstName: res.firstName,
        lastName: res.lastName,
        legalName: res.buyerData.legalName,
        phoneNumber: res.buyerData.phoneNumber,
        birthday: res.buyerData.birthday,
        placeOfBirth: res.buyerData.placeOfBirth,
        idNumber: res.buyerData.idNumber,
        idType: res.buyerData.idType
      }))
      .catch((err) => console.error(err))
  }, [])

  const onChange = (field, value) => {
    setValues({ ...values, [field]: value })
  }

  return (
    <div className='flex flex-col gap-y-12'>
      <div className='flex flex-col gap-y-4'>
        <H5 className='text-lg font-semibold mb-0'>
          Buyer details
        </H5>
        <div className='flex items-center gap-2'>
          <H5 className='mb-0 text-sm font-medium'>
            Buyer type
          </H5>
          <div className='flex items-center gap-x-2 p-1 rounded-lg bg-[#C1C0C1] dark:bg-zinc-800 bg-opacity-20'>
            {
              tabMenus.map((tab) => (
                <div
                  key={tab.id}
                  onClick={() => setSelectedTab(tab.id)}
                  className={`flex items-center px-2 py-1 cursor-pointer rounded-md font-medium dark:text-white ${selectedTad === tab.id ? 'bg-white dark:bg-zinc-600 shadow-sm' : 'text-sm'} transition-all`}
                >
                  {tab.name}
                </div>
              ))
            }
          </div>
        </div>
      </div>
      <div>
        <H5 className='text-lg font-semibold mb-4'>
          Personal Info
        </H5>
        <div>
          <div className='grid grid-cols-7 gap-6 pb-6'>
            <div className='col-span-1'>
              <CustomSelect
                label='Prefix'
                labelClasses='text-left text-sm font-normal capitalize'
                inputClasses='h-10 bg-white text-sm font-normal'
                fonts='text-sm font-normal'
                selectedOption={values.prefix}
                options={prefixData}
                setSelectedOption={(e) => onChange('prefix', e)}
                defaultValue={undefined}
                isDarkFont
              />
            </div>
            <CustomInput
              classes='max-h-14'
              label='First Name'
              placeholder='Enter first name'
              onChange={(e) => onChange('firstName', e.target.value)}
              value={values.firstName || ''}
              containerClasses='col-span-2'
              labelClassName='text-sm font-normal capitalize'
              isDarkFont
            />
            <CustomInput
              classes='max-h-14'
              label='Last Name'
              placeholder='Enter last name'
              onChange={(e) => onChange('lastName', e.target.value)}
              value={values.lastName || ''}
              containerClasses='col-span-2'
              labelClassName='text-sm font-normal capitalize'
              isDarkFont
            />
            <CustomInput
              classes='max-h-14'
              label='Legal Name'
              placeholder='Enter legal name'
              onChange={(e) => onChange('legalName', e.target.value)}
              value={values.legalName || ''}
              containerClasses='col-span-2'
              labelClassName='text-sm font-normal capitalize'
              isDarkFont
            />
          </div>
          <div>
            <div className='grid grid-cols-2 gap-6 pb-6'>
              <CustomInput
                type='email'
                classes='max-h-14'
                label='Email'
                placeholder='Enter email'
                onChange={(e) => onChange('email', e.target.value)}
                value={values.email || ''}
                containerClasses={undefined}
                labelClassName='text-sm font-normal capitalize'
                isDarkFont
              />
              <CustomInput
                type='phone'
                classes='max-h-14'
                label='Phone'
                placeholder='Enter phone number'
                onChange={(e) => onChange('phoneNumber', e.target.value)}
                value={values.phoneNumber || ''}
                containerClasses={undefined}
                labelClassName='text-sm font-normal capitalize'
                isDarkFont
              />
            </div>
          </div>
          <div>
            <div className='grid grid-cols-2 gap-6 pb-6'>
              <CustomInput
                classes='max-h-14'
                label='Birthday'
                placeholder='DD/MM/YYYY'
                type='date'
                onChange={(e) => onChange('birthday', e.target.value)}
                value={values.birthday || ''}
                containerClasses={undefined}
                labelClassName='text-sm font-normal capitalize'
                isDarkFont
              />
              <CustomInput
                classes='max-h-14'
                label='Place Of Birth'
                value={values.placeOfBirth}
                onChange={(e) => onChange('placeOfBirth', e.target.value)}
                placeholder='Place Of Birth'
                containerClasses={undefined}
                labelClassName='text-sm font-normal capitalize'
                isDarkFont
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <H5 className='text-lg font-semibold mb-4'>
          ID Documents
        </H5>
        <div>
          <div className='w-full py-5 flex items-center flex-col gap-1 border-2 border-dashed border-[#D1D5DB] rounded-[5px]'>
            <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M28.5 8H12.5C11.4391 8 10.4217 8.42143 9.67157 9.17157C8.92143 9.92172 8.5 10.9391 8.5 12V32M8.5 32V36C8.5 37.0609 8.92143 38.0783 9.67157 38.8284C10.4217 39.5786 11.4391 40 12.5 40H36.5C37.5609 40 38.5783 39.5786 39.3284 38.8284C40.0786 38.0783 40.5 37.0609 40.5 36V28M8.5 32L17.672 22.828C18.4221 22.0781 19.4393 21.6569 20.5 21.6569C21.5607 21.6569 22.5779 22.0781 23.328 22.828L28.5 28M40.5 20V28M40.5 28L37.328 24.828C36.5779 24.0781 35.5607 23.6569 34.5 23.6569C33.4393 23.6569 32.4221 24.0781 31.672 24.828L28.5 28M28.5 28L32.5 32M36.5 8H44.5M40.5 4V12M28.5 16H28.52" stroke="#2E2B2E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <p className='m-0 text-sm font-normal dark:text-darkModeText'>
              <span className='text-[#1C64F2] mr-1 cursor-pointer'>Upload a file</span>
              or drag and drop
            </p>
            <p className='m-0 text-xs font-normal text-[#2E2B2E] dark:text-darkModeText text-opacity-50'>
              PNG, JPG, GIF up to 10MB
            </p>
          </div>
          <div className='grid grid-cols-3 gap-6 py-5'>
            <CustomInput
              classes='max-h-14'
              label='Id Number'
              placeholder='Enter id'
              onChange={(e) => onChange('idNumber', e.target.value)}
              value={values.idNumber || ''}
              containerClasses={undefined}
              labelClassName='text-sm font-normal capitalize'
              isDarkFont
            />
            <CustomInput
              classes='max-h-14'
              label='Id Type'
              placeholder='Enter type'
              onChange={(e) => onChange('idType', e.target.value)}
              value={values.idType || ''}
              containerClasses={undefined}
              labelClassName='text-sm font-normal capitalize'
              isDarkFont
            />
            <CustomInput
              classes='max-h-14'
              label='Id Expiry Date'
              placeholder='DD/MM/YYYY'
              type='date'
              onChange={(e) => onChange('idExpireDate', e.target.value)}
              value={values.idExpireDate || ''}
              containerClasses={undefined}
              labelClassName='text-sm font-normal capitalize'
              isDarkFont
            />
            <CustomSelect
              label='Place of Issue'
              labelClasses='text-left text-sm font-normal capitalize'
              inputClasses='h-10 bg-white'
              // selectedOption={values.resident}
              // options={residentData}
              // setSelectedOption={(e) => onChange('resident', e)}
              defaultValue='Select city'
              isDarkFont
            />
            <CustomInput
              classes='max-h-14'
              label='Birthday'
              placeholder='DD/MM/YYYY'
              type='date'
              onChange={(e) => onChange('birthday', e.target.value)}
              value={values.birthday || ''}
              containerClasses={undefined}
              labelClassName='text-sm font-normal capitalize'
              isDarkFont
            />
            <CustomSelect
              label='Issuing Country'
              labelClasses='text-left text-sm font-normal capitalize'
              inputClasses='h-10 bg-white'
              // selectedOption={values.resident}
              // options={residentData}
              // setSelectedOption={(e) => onChange('resident', e)}
              defaultValue='Select options'
              isDarkFont
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default BuyersTab
