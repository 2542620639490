import React, { useCallback } from 'react'
import propTypes from 'prop-types'
import AnimatedList from 'components/AnimatedList'
import UserCard from 'components/UserCard'

const UserList = ({ users, onClick }) => (
  <AnimatedList wrapperClassName={'grid gap-5 grid-cols-3'}>
    {users?.map((user) => (
      <UserCard data={user} onClick={() => onClick(user)} />
    ))}
  </AnimatedList>
)

const PickUserList = ({ listOfUsers, onSelectUsers, title, subtitle }) => {
  const selectUnselectUsers = useCallback((selectedUser) => {
    let tmpUsers = [...listOfUsers]

    tmpUsers = tmpUsers.map((user) => {
      const tmpUser = { ...user }
      if (user._id === selectedUser) {
        tmpUser.selected = !tmpUser.selected
      }

      return tmpUser
    })

    onSelectUsers(tmpUsers)
  }, [listOfUsers, onSelectUsers])

  return (
    <div className='flex flex-col text-center'>
      <span className='text-2xl mt-5'>{title}</span>
      <span className='text-xl mb-10'>{subtitle}</span>

      <UserList
        users={listOfUsers}
        onClick={(user) => selectUnselectUsers(user._id)}
      />
  </div>
  )
}

export default PickUserList

PickUserList.defaultProp = {
  title: 'Choose users',
  subtitle: 'The selected users will be added to the list'

}

PickUserList.propTypes = {
  listOfUsers: propTypes.array,
  onSelectUsers: propTypes.func,
  title: propTypes.string,
  subtitle: propTypes.string

}

UserList.propTypes = {
  users: propTypes.array,
  onClick: propTypes.func

}
