import { api, ApiErrors } from 'services/api'
import { message } from 'antd'

export const getDevelopers = (id = '') => new Promise((resolve, reject) => {
  api.get(`developers/${id}`)
    .then((response) => {
      if (response.ok) {
        resolve(response.data.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const createOrUpdateDeveloper = (props) => new Promise((resolve, reject) => {
  const {
    _id,
    companyName,
    websiteUrl,
    city,
    province,
    country,
    logoImage
  } = props

  // If id, so we have to update the record, or we're gonna create
  const verb = _id ? 'put' : 'post'
  const url = _id ? `developers/${_id}` : 'developers'

  const params = new FormData()
  params.append('companyName', companyName)
  params.append('websiteUrl', websiteUrl)
  params.append('city', city)
  params.append('province', province)
  params.append('country', country)
  if (logoImage.length) {
    const imageFile = logoImage[0].originFileObj
    if (imageFile) {
      params.append('logoImage', imageFile)
    }
  }

  api[verb](url, params)
    .then((response) => {
      if (response.ok) {
        message.success(`${props.companyName} was ${_id ? 'successfully updated' : 'created successfully'}`)
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const deleteDeveloper = (id) => new Promise((resolve, reject) => {
  api.delete(`/developers/${id}`)
    .then((response) => {
      if (response.ok) {
        message.success('Developer deleted successfully')
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
})
