import React from 'react'
import LeadsRegistrationForm from 'components/LeadsRegistrationForm'
import { useParams } from 'react-router-dom'

const OliveGroveWebsiteForm = () => {
  const { leadSource } = useParams()

  return (
    <LeadsRegistrationForm
      leadSource={leadSource ?? 'Website'}
      projectName='Olive Grove'
      project='66c4450e83492174ec6efa68'
    />
  )
}

export default OliveGroveWebsiteForm
