import React, { useCallback } from 'react'
import { UnitsRealTimeDashboard } from 'components'
import { useHistory, useLocation } from 'react-router-dom'
import { ADMIN_OFFER } from 'routes'

const AvailabilityBoard = () => {
  const location = useLocation()
  const history = useHistory()

  const {
    state: {
      showTabsFilter
    }
  } = location

  const createOffer = useCallback((unit) => {
    history.push({
      pathname: ADMIN_OFFER,
      state: {
        unit
      }
    })
  }, [history])

  return (
    <UnitsRealTimeDashboard
      showTabsFilter={showTabsFilter}
      executeOnSelectUnit={(unit) => createOffer(unit)}
    />
  )
}

export default AvailabilityBoard
