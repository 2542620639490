/* eslint-disable */
// @ts-nocheck
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import propTypes from 'prop-types'
import Skeleton from 'react-loading-skeleton'
import { Table as AntdTable } from 'antd'
import { getRandomInteger } from 'utils'
import { TableToCard } from './transformGridToCard'
import TailwindTable from './TailwindTableNew'
import CustomDropdown from 'components/CustomDropdown'
import CustomButton from 'components/CustomButton'
import { RefreshCcw } from 'lucide-react'

const CustomTable = (props) => {
  const {
    dataSource, columns, loading,
    card, seeAsCards, emptyText,
    selectedPage, changeSelectedPage,
    scrollOptions, tableLayout, tailwindTable,
    extraHeaders, extraColumns, className = '',
    onViewEditClick, onDeleteClick,
    deleting, idBeingDeleted, showTablePagination,
    onDeleteButtonText, onRow, isFromContracts,
    showSizeChanger, defaultPageSize, pageSizeOptions,
    noMargin, title = '', isSmall = false, isWithoutBorder = false, isHideEmptyMessage = false,
    refetchData,
  } = props

  const [localColumns, setLocalColumns] = useState(columns)
  const [filterProjectOffers, setFilterProjectOffers] = useState([])
  const [selectedColumns, setSelectedColumns] = useState([])
  const [sortType, setSortType] = useState('asc')
  const [sortByField, setSortByField] = useState('createdAt')

  const onSortClicked = useCallback(
    (field, sortingType) => {
      let newSortType = 'desc'
      if (field === sortByField) {
        newSortType = sortType === 'desc' ? 'asc' : 'desc'
      }
      let sortedData = []
      if (sortingType === 'number') {
        sortedData = dataSource.sort((a, b) => {
          if (newSortType === 'desc') {
            return Number(a[field]) - Number(b[field])
          } else if (newSortType === 'asc') {
            return Number(b[field]) - Number(a[field])
          }
        })
      } else {
        sortedData = dataSource.sort((a, b) => {
          if (newSortType === 'desc') {
            return a[field]?.localeCompare(b[field])
          } else if (newSortType === 'asc') {
            return b[field]?.localeCompare(a[field])
          } else return 0
        })
      }
      setFilterProjectOffers([...sortedData])
      setSortByField(field)
      setSortType(newSortType)
    },
    [dataSource, sortByField, sortType]
  )

  const rawOptions = useMemo(() => {
    return columns.map((elm) => {
      return {
        value: elm.id,
        label: elm.label
      }
    })
  }, [])

  const getSkeleton = (data) => {
    const newArray = data.map((element) => ({
      ...element,
      render: () => {
        const width = getRandomInteger(50, 100)
        return (
          <Skeleton width={`${width}%`} height={25} />
        )
      }
    }))

    return newArray
  }

  useEffect(() => {
    if (loading) {
      // Transform the columns into Skeleton columns
      const skeletonColumns = getSkeleton(columns)
      setLocalColumns(skeletonColumns)
    } else {
      // When finished to load, show the data
      setLocalColumns(columns)
      setSelectedColumns([
        ...columns.map((elm) => {
          return {
            value: elm.id,
            label: elm.label
          }
        })
      ])
    }
  }, [columns, loading])

  const TableComponent = () => (
    tailwindTable
      ? (
        <>
          <div className={`w-full flex ${title?.length > 0 ? 'justify-between' : 'justify-end'} items-center gap-2 mb-2`}>
            {
              title?.length > 0 &&
              <div className='text-xl font-semibold'>{title}</div>
            }
            <div className='w-fit flex items-center gap-2'>
              {
                refetchData !== undefined && typeof refetchData === 'function' &&
                <CustomButton
                  handleClick={refetchData}
                  variant='outline'
                  disabled={loading}
                  className='!rounded border !border-[#2E2B2E] !border-opacity-[15%]'
                >
                  <span className='text-sm font-medium flex items-center gap-2 px-3 py-2.5'>
                    <RefreshCcw className='h-4 w-4' />
                    Refresh
                  </span>
                </CustomButton>
              }
              <CustomDropdown
                title='Column'
                values={selectedColumns}
                setValues={setSelectedColumns}
                options={rawOptions}
                handleChange={(newValue) => {
                  setLocalColumns([
                    ...columns.filter((column) => newValue.find((elm) => elm.value === column.id))
                  ])
                }}
              />
            </div>
          </div>
          <TailwindTable
            columns={localColumns}
            data={filterProjectOffers?.length > 0 ? filterProjectOffers : dataSource}
            extraHeaders={extraHeaders}
            extraColumns={extraColumns}
            className={className}
            loading={loading}
            onViewEditClick={onViewEditClick}
            onDeleteClick={onDeleteClick}
            deleting={deleting}
            idBeingDeleted={idBeingDeleted}
            showTablePagination={showTablePagination}
            onDeleteButtonText={onDeleteButtonText}
            onRow={onRow}
            noMargin={noMargin}
            onSortClicked={onSortClicked}
            isSmall={isSmall}
            isWithoutBorder={isWithoutBorder}
            isHideEmptyMessage={isHideEmptyMessage}
          />
        </>
      )
      : (
        <AntdTable
          columns={localColumns}
          tableLayout={tableLayout}
          scroll={{ y: 240, ...scrollOptions }}
          dataSource={dataSource}
          loading={loading}
          pagination={{
            onChange: (page) => changeSelectedPage(page),
            current: selectedPage,
            defaultPageSize,
            showSizeChanger,
            pageSizeOptions
          }}
          locale={{
            emptyText
          }}
        />
      )
  )

  if (isFromContracts) {
    return <TableComponent />
  }
  return (
    <TableToCard
      cardMaxSize='sm'
      card={card}
      seeAsCards={seeAsCards}
    >
      <TableComponent />
    </TableToCard>
  )
}

export default CustomTable

CustomTable.defaultProps = {
  selectedPage: 1,
  changeSelectedPage: () => { },
  tableLayout: '-',
  card: <></>,
  tailwindTable: false,
  propTypes: [],
  onViewEditClick: null,
  onDeleteClick: null,
  showTablePagination: true,
  defaultPageSize: 10,
  pageSizeOptions: ['10', '20', '30']
}

CustomTable.propTypes = {
  dataSource: propTypes.array,
  columns: propTypes.array,
  loading: propTypes.bool,
  card: propTypes.node,
  seeAsCards: propTypes.bool,
  emptyText: propTypes.string,
  selectedPage: propTypes.number,
  changeSelectedPage: propTypes.func,
  scrollOptions: propTypes.object,
  tableLayout: propTypes.string,
  tailwindTable: propTypes.bool,
  extraHeaders: propTypes.array,
  extraColumns: propTypes.array,
  className: propTypes.string,
  onViewEditClick: propTypes.func,
  onDeleteClick: propTypes.func,
  deleting: propTypes.bool,
  idBeingDeleted: propTypes.string,
  showTablePagination: propTypes.bool,
  onDeleteButtonText: propTypes.string,
  isFromContracts: propTypes.bool,
  defaultPageSize: propTypes.number,
  showSizeChanger: propTypes.bool,
  pageSizeOptions: propTypes.any,
  noMargin: propTypes.bool
}
