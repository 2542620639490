/* eslint-disable */
// @ts-nocheck
import React, { useEffect, useState, useCallback, useMemo } from 'react'
import propTypes from 'prop-types'
import { AnimatePresence, motion } from 'framer-motion'
import { pdfjs } from 'react-pdf'
import { Divider, Row } from 'antd'
import { PencilIcon } from '@heroicons/react/solid'
import standardStyle from 'assets/css/standardStyle'
import { FadedAnimatedDiv, HelloSignDoc, LottieWrapper } from 'components'
import loadingContractsAnimation from 'assets/lottieJsons/loadingDoc.json'
import PdfDocument from './components/PdfDocument'
import ContractThumbnail from './components/ContractThumbnail'
import ContractsCounterAndFilter from './components/ContractsCounterAndFilter'
import { classNames, getUserType } from 'utils'
import {
  ContractsWrapper,
  ListOfContractsWrapper,
  ContractsCarouselWrapper,
  ContractWrapper,
  ZoomControlWrapper,
  ZoomIconWrapper,
  LeftWrapper,
  RightWrapper,
  DocumentBtnsWrapper,
  PreviewHeader
} from './styles'

import {
  sendContractsToBuyer,
  sendContractsToDeveloper,
  generateContractBuyerLink,
  getOfferContractsPreview,
  getEmbedUrlForOffer
} from 'store/actions/offerActions'
import { connect, useSelector } from 'react-redux'
import ContractSignedPanel from './components/ContractSignedPanel'
import { DocusealForm } from '@docuseal/react'
import { ChevronDown, CircleCheck, CircleX, ZoomIn, ZoomOut } from 'lucide-react'
import CustomButton from 'components/CustomButton'

const formatNumber = (value) => {
  if (value % 1 !== 0) {
    return value.toFixed(2);
  }
  return value.toString();
}

const DEFAULT_CONTRACT_TYPES = [
  'reservation',
  'offer',
  'unit',
  'parking',
  'storage',
  'upgrades',
  'colors'
]

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export const isSelectedContractSignedByUser = (selectedContract, userRole) => {
  if (!selectedContract) {
    return false
  }
  const isSinged =
    selectedContract?.signatures?.filter(
      (signature) =>
        signature.userType === getUserType(userRole) &&
        signature.status === 'signed'
    )?.length > 0
  return isSinged
}

const OfferDocuments = (props) => {
  const {
    contracts,
    onGettingContracts,
    executeWhenAllDocsSigned,
    userObject,
    btnsShow = true,
    setCloseDocument = () => { },
    setPrimaryBtnAction = null,
    isPublicPath = false,
    offerId,
    isGroupedContracts = false,
    handleGoBack = () => { }
  } = props
  const projectId = useSelector((state) => state.appReducer.appProject)
  const [gettingContracts, setGettingContracts] = useState(false)
  const [originalListOfContracts, setOriginalListOfContracts] = useState(contracts)
  const [listOfContracts, setListOfContracts] = useState(contracts)
  const [selectedContract, setSelectedContract] = useState(null)
  const [docuSignUrl, setDocuSignUrl] = useState('')
  const [pdfPageNumber, setPdfPageNumber] = useState(1)
  const [pdfScale, setPdfScale] = useState(1.2)
  const [isSigning, setIsSigning] = useState(null)
  const [needsToSignDocument, setNeedsToSignDocument] = useState(false)
  const [selectedContractSignatureId, setSelectedContractSignatureId] = useState('')
  const [docsAnalyzedCounter, setDocsAnalyzedCounter] = useState(0)
  const [allDocsSigned, setAllDocsSigned] = useState(false)
  const [loading, setLoading] = useState(false)
  const [contractStream, setContractStream] = useState()
  const [selectedContractListId, setSelectedContractListId] = useState(null)
  const customSections = useSelector((state) => state.appReducer.customSections)
  const [customSectionContractTemplateTypes, setCustomSectionContractTemplateTypes] = useState([])
  const [totalSignedContracts, setTotalSignedContracts] = useState(null)
  const [totalUnsignedContracts, setTotalUnsignedContracts] = useState(null)

  const getLoggedInUserType = useMemo(() => {
    return getUserType(userObject.userType)
  }, [userObject])

  useEffect(() => {
    let _needsToSign = false
    let _helloSignUrl = ''
    let _signatureId = ''

    if (!selectedContract?.mustSign) {
      _needsToSign = false
      _helloSignUrl = ''
      _signatureId = ''
    }

    if (selectedContract?.signatures?.length) {
      const userDocuments = selectedContract?.signatures.filter(
        (signature) =>
          signature.userType === getLoggedInUserType &&
          signature.status === 'awaiting_signature'
      )

      if (userDocuments && userDocuments.length) {
        const { url, signatureId } = userDocuments[0]

        _needsToSign = true
        _helloSignUrl = url
        _signatureId = signatureId
      }
    }

    setNeedsToSignDocument(_needsToSign)
    setDocuSignUrl(_helloSignUrl)
    setSelectedContractSignatureId(_signatureId)
  }, [selectedContract, userObject])

  useEffect(() => {
    const userDocs = listOfContracts
      ?.filter?.((contract) => {
        const signatureRequires =
          contract?.signatures?.filter(
            (signature) => signature.userType === getLoggedInUserType
          ).length > 0
        return contract.mustSign && signatureRequires
      })
      ?.map((contract) => {
        const tmpContract = { ...contract }
        tmpContract.signed = false
        const contractSigned = tmpContract.signatures?.filter(
          (signature) =>
            signature.userType === getLoggedInUserType &&
            signature.status === 'signed'
        )
        tmpContract.signed = Boolean(contractSigned.length)
        return tmpContract
      })
    const pendingDocs = Boolean(userDocs?.filter((doc) => !doc.signed).length)
    const allDocsAnalyzed = listOfContracts?.length === docsAnalyzedCounter

    if (listOfContracts?.length && !pendingDocs && allDocsAnalyzed) {
      setAllDocsSigned(true)
      if (executeWhenAllDocsSigned) executeWhenAllDocsSigned()
    }
  }, [
    listOfContracts,
    userObject,
    docsAnalyzedCounter,
    executeWhenAllDocsSigned
  ])

  const _getOfferContracts = useCallback(() => {
    if (offerId) {
      if (isPublicPath && contracts) {
        if (contracts?.length) {
          setOriginalListOfContracts(contracts)
          setListOfContracts(contracts)
          // setSelectedContract(contracts[0])
        }
      } else {
        setGettingContracts(true)
        getOfferContractsPreview(offerId)
          .then((response) => {
            const { contracts } = response
            if (contracts?.length) {
              setOriginalListOfContracts(contracts)
              setListOfContracts(contracts)
              // setSelectedContract(contracts[0])
            }
          })
          .catch((err) => {
            console.error(err)
          })
          .finally(() => {
            setGettingContracts(false)
          })
      }
    }
  }, [offerId, userObject, isPublicPath, contracts])

  useEffect(() => {
    _getOfferContracts()
  }, [_getOfferContracts])

  useEffect(() => {
    onGettingContracts && onGettingContracts(gettingContracts)
  }, [gettingContracts, onGettingContracts])

  const manageNumberOfPagesList = useCallback(
    ({ contractTemplateId, numPages }) => {
      const tmpListOfContracts = listOfContracts?.map?.((contract) => {
        const tmpContract = { ...contract }

        if (tmpContract.contractTemplateId === contractTemplateId) {
          tmpContract.pdfNumPages = numPages
        }

        return tmpContract
      })

      // This counter will say if the all of the documents were analyzed to collect the number of pages
      setDocsAnalyzedCounter(docsAnalyzedCounter + 1)
      setListOfContracts(tmpListOfContracts)

      // Must not update the originalLIst
      // if was already updated at the first time
      if (originalListOfContracts[0].pdfNumPages === undefined) {
        setOriginalListOfContracts(tmpListOfContracts)
      }
    },
    [listOfContracts, originalListOfContracts, docsAnalyzedCounter]
  )

  const isBuyerSingedSelectedContract = useMemo(() => {
    return isSelectedContractSignedByUser(selectedContract, 'Buyer')
  }, [selectedContract])

  const isDeveloperSingedSelectedContract = useMemo(() => {
    return (
      isPublicPath ||
      isSelectedContractSignedByUser(selectedContract, 'DeveloperAdmin')
    )
  }, [selectedContract, isPublicPath])

  const setDocSigned = useCallback(() => {
    let contractToSelect = {}
    const tmpContracts = listOfContracts?.map?.((contract) => {
      const tmpContract = { ...contract }
      if (contract.contractTemplateId === selectedContract?.contractTemplateId) {
        tmpContract.signatures = tmpContract?.signatures?.map((signature) => {
          const tmpSignature = { ...signature }

          if (tmpSignature.status === 'awaiting_signature' && tmpSignature.userType === getUserType(isSigning)) {
            tmpSignature.status = 'signed'
          }

          return tmpSignature
        })
        tmpContract.signed = true

        contractToSelect = { ...tmpContract }
      }
      return tmpContract
    })

    setSelectedContract(contractToSelect)
    setListOfContracts(tmpContracts)
    setOriginalListOfContracts(tmpContracts)
    setIsSigning(null)
  }, [listOfContracts, selectedContract, isSigning])

  const onSignDocumentClicked = useCallback(
    async (role) => {
      if (selectedContract) {
        setIsSigning(role)
        try {
          const userDocuments = selectedContract?.signatures.filter(
            (signature) => signature.userType === getUserType(role)
          )
          if (userDocuments && userDocuments.length) {
            const { url } = userDocuments[0]
            setDocuSignUrl(url)
          }
        } catch (err) {
          console.error(err)
        }
      }
    },
    [selectedContract]
  )
  const isAllSigned = useMemo(() => {
    return listOfContracts?.every((contract) => {
      return contract.signatures?.every((signature) =>
        signature.userType === getUserType('Buyer')
          ? signature.status === 'signed'
          : isPublicPath || signature.status === 'signed'
      )
    })
  }, [listOfContracts, isPublicPath])

  const isAllSingedForSelectedContact =
    isBuyerSingedSelectedContract && isDeveloperSingedSelectedContract

  const onSignNextContractClicked = useCallback(() => {
    if (!listOfContracts?.length) {
      return
    }
    const nextContractToSign =
      listOfContracts?.filter((contract) => {
        return (
          contract.signatures?.some((signature) =>
            isPublicPath
              ? signature.status !== 'signed' &&
              signature.userType === getUserType('Buyer')
              : signature.status !== 'signed'
          ) ?? null
        )
      })?.[0] ?? null
    if (nextContractToSign) {
      onSelectDoc(nextContractToSign)
    }
  }, [listOfContracts, isPublicPath])

  useEffect(() => {
    const tempCustomSectionMenus = customSections?.map((section) => {
      return {
        type: section._id,
        label: section.title
      }
    })
    setCustomSectionContractTemplateTypes(tempCustomSectionMenus)
  }, [customSections])

  const contractTypesOption = useMemo(() => {
    if (!(listOfContracts?.length > 0)) {
      return []
    }
    const types = [
      {
        type: 'reservation',
        label: 'Reservation'
      },
      {
        type: 'offer',
        label: 'Offer'
      },
      {
        type: 'unit',
        label: 'Unit/Lot'
      },
      {
        type: 'parking',
        label: 'Parking'
      },
      {
        type: 'storage',
        label: 'Storage'
      },
      {
        type: 'upgrades',
        label: 'Upgrades'
      },
      ...customSectionContractTemplateTypes
    ]
    const temp = listOfContracts?.map((contract) => ({
      ...contract,
      type: types.filter((elm) => (elm.type === contract?.contractTemplate?.contractType))?.[0]?.label ?? ''
    }))?.filter(e => e?.type?.length > 0)

    if (!isGroupedContracts && (temp?.length > 0)) {
      let tempTotalSignedContracts = 0;
      let tempTotalUnsignedContracts = 0;

      const nonGroupContractsTemp = temp?.map((contract, index) => {
        let signedCount = 0;
        let unsignedCount = 0;

        const isSigned = !(contract.signatures.filter(signature => signature.status !== 'signed')?.length > 0);
        if (isSigned) {
          tempTotalSignedContracts++;
          signedCount++;
        } else {
          tempTotalUnsignedContracts++;
          unsignedCount++;
        }

        return ({
          id: `non-grouped-contract-${index}`,
          title: contract.contractTemplate.name,
          items: [
            { ...contract }
          ],
          signedCount: contract.signatures.filter(signature => signature.status === 'signed')?.length,
          unsignedCount: contract.signatures.filter(signature => signature.status !== 'signed')?.length
        })
      })

      setTotalUnsignedContracts(tempTotalUnsignedContracts)
      setTotalSignedContracts(tempTotalSignedContracts)

      if (!selectedContract) {
        setSelectedContract(nonGroupContractsTemp?.[0]?.items?.[0])
      }
      if (!selectedContractListId) {
        setSelectedContractListId(nonGroupContractsTemp?.[0] ?? null)
      }
      return nonGroupContractsTemp
    } else {
      const groupedData = temp?.reduce((acc, curr, index) => {
        const type = curr.type;
        if (!acc[type]) {
          acc[type] = { id: `grouped-contract-${index}`, title: type, items: [] };
        }
        acc[type].items.push(curr);
        return acc;
      }, {});
      if (groupedData) {
        let tempTotalSignedContracts = 0;
        let tempTotalUnsignedContracts = 0;

        Object.values(groupedData).forEach(group => {
          let signedCount = 0;
          let unsignedCount = 0;

          group.items.forEach(contract => {
            const isSigned = !(contract.signatures.filter(signature => signature.status !== 'signed')?.length > 0);
            if (isSigned) {
              tempTotalSignedContracts++;
              signedCount++;
            } else {
              tempTotalUnsignedContracts++;
              unsignedCount++;
            }
          });

          group.signedCount = signedCount;
          group.unsignedCount = unsignedCount;
        });

        setTotalUnsignedContracts(tempTotalUnsignedContracts)
        setTotalSignedContracts(tempTotalSignedContracts)

        const result = Object?.values(groupedData);
        if (!selectedContract) {
          setSelectedContract(result?.[0]?.items?.[0])
        }
        if (!selectedContractListId) {
          setSelectedContractListId(result?.[0] ?? null)
        }
        return result
      }
    }
    return []
  }, [customSectionContractTemplateTypes, listOfContracts])

  const onSelectDoc = useCallback((docProps) => {
    const newSelectedListId = contractTypesOption?.filter((contractType) => (contractType.items.filter((contract) => contract.contractId == docProps.contractId)?.length > 0))?.[0] ?? null
    setPdfPageNumber(1)
    setIsSigning(null)
    setSelectedContractListId(newSelectedListId)
    setSelectedContract(docProps)
  }, [contractTypesOption])

  const AccordionItem = useCallback(({ item }) => {
    const { id, title, signedCount, unsignedCount, items } = item
    if (selectedContractListId === null) return null
    return (
      <div
        key={`accordion-${id}`}
        className={`self-stretch rounded-lg border-2 flex-col justify-start items-start flex transition-all cursor-pointer
          ${selectedContractListId.id === item.id ? 'bg-[#EDDF65]/[35%] border-[#EDDF65]' : 'hover:bg-[#EDDF65]/[35%] border-zinc-200 dark:border-zinc-400 hover:border-[#EDDF65]'}
        `}
      >
        <div
          className='w-full justify-between items-center inline-flex gap-2 p-2 xl:px-4 xl:py-3.5'
          onClick={() => {
            setSelectedContract(items?.[0])
            setSelectedContractListId(item)
          }}
        >
          <div className='justify-start items-center gap-2 flex'>
            <div className='flex-col justify-start items-start gap-0.5 inline-flex'>
              <p className='text-zinc-950 text-xs xl:text-sm font-semibold leading-tight mb-0'>{title}</p>
            </div>
          </div>
          <div className='flex-1 justify-end items-center gap-1 xl:gap-2 flex'>
            <div className='justify-start items-center gap-1 flex'>
              {
                (signedCount + unsignedCount) === signedCount
                  ? <CircleCheck className='w-5 h-5 text-green-500' />
                  : <CircleX className='w-5 h-5 text-red-500' />
              }
              {
                isGroupedContracts &&
                <p className='text-[10px] xl:text-xs font-normal leading-none truncate mb-0'>{signedCount + unsignedCount} contracts</p>
              }
            </div>
            <ChevronDown className={`w-4 h-4 text-[#71717A] dark:text-zinc-100 transition-all ${selectedContractListId.id === item.id ? 'rotate-0' : 'rotate-180'}`} />
          </div>
        </div>
        {
          (selectedContractListId.id === item.id) && (items?.length > 0) &&
          <div className='w-full px-2 pb-2 pt-0 xl:px-4 xl:py-3.5 xl:pt-0 flex-col justify-start items-start gap-2 xl:gap-4 flex'>
            {
              items?.map?.((contract, index) => {
                return (
                  <ContractThumbnail
                    key={index}
                    contractData={contract}
                    index={index}
                    selected={contract?.contractId === selectedContract?.contractId}
                    onSelectDoc={onSelectDoc}
                    onChangePdfPage={setPdfPageNumber}
                    onLoadSuccess={() => { }}
                    // onLoadSuccess={manageNumberOfPagesList}
                    isPublicPath={isPublicPath}
                    isGroupedContracts={isGroupedContracts}
                  />
                )
              })
            }
          </div>
        }
      </div>
    )
  }, [selectedContractListId, selectedContract])

  return (
    <AnimatePresence exitBeforeEnter initial={false}>
      {gettingContracts && (
        <FadedAnimatedDiv
          key='loadingContracts'
          className='flex flex-col justify-center items-center h-full'
        >
          <LottieWrapper
            animation={loadingContractsAnimation}
            loop
            autoplay
            className='max-w-lg'
          />
          <span className='text-xl text-center'>
            We are now working to show you the contracts generated for this
            Offer
          </span>
        </FadedAnimatedDiv>
      )}
      {!gettingContracts && (contractTypesOption?.length > 0) && (
        <FadedAnimatedDiv
          key='contracts'
          className='h-full'
          style={{ overflowY: 'hidden' }}
        >
          <ContractsWrapper>
            <LeftWrapper xs={24} sm={24} md={6} lg={6} className='border-r'>
              <div className='flex flex-col justify-center mb-0 border-b p-6 z-10'>
                <span className='text-xl font-medium text-center'>
                  {`Sign ${listOfContracts?.length} ${listOfContracts?.length > 1 ? 'contracts' : 'contract'}`}
                </span>
              </div>
              {/* <ListOfContractsWrapper>
                {listOfContracts?.length &&
                  listOfContracts?.map?.((contract, index) => {
                    return (
                      <ContractThumbnail
                        key={index}
                        contractData={contract}
                        index={index}
                        selected={
                          (contract?._id &&
                            contract._id === selectedContract._id) ||
                          (contract?.contractId &&
                            contract.contractId === selectedContract.contractId)
                        }
                        onSelectDoc={onSelectDoc}
                        onChangePdfPage={setPdfPageNumber}
                        onLoadSuccess={manageNumberOfPagesList}
                        isPublicPath={isPublicPath}
                      />
                    )
                  })}
              </ListOfContractsWrapper> */}
              <div className='w-full p-4 xl:p-6 flex-col justify-start items-start gap-2 xl:gap-4 inline-flex overflow-y-auto thin-scrollbar'>
                <div className='self-stretch h-9 flex-col justify-start items-start gap-2 flex'>
                  <p className='text-zinc-950 text-xs xl:text-sm font-semibold leading-tight mb-0'>{formatNumber(totalSignedContracts / (totalSignedContracts + totalUnsignedContracts) * 100)}% complete</p>
                  <div className='self-stretch justify-start items-center gap-3 inline-flex'>
                    <div className='w-full h-2 relative rounded-lg'>
                      <div className='w-full h-2 left-0 top-0 absolute bg-zinc-200 dark:bg-zinc-800 rounded' />
                      <div style={{ width: `${(totalSignedContracts / (totalSignedContracts + totalUnsignedContracts) * 100)}%` }} className='w-[94.88px] h-2 left-0 top-0 absolute bg-zinc-900 dark:bg-zinc-300 rounded transition-all' />
                    </div>
                  </div>
                </div>
                {
                  contractTypesOption?.map((item) => (
                    <AccordionItem key={item.id} item={item} />
                  ))
                }
              </div>
            </LeftWrapper>
            <RightWrapper>
              <div className='w-full p-6 border-b text-xl font-medium text-center'>{selectedContract?.contractTemplate?.name ?? 'Loading...'}</div>
              {!isAllSingedForSelectedContact && (
                <Row
                  justify='start'
                  style={{
                    width: '100%',
                    position: 'sticky',
                    top: 0,
                    right: 0,
                    zIndex: 10
                  }}
                >
                  <div className='flex items-center gap-1 p-2'>
                    {!isSigning?.length && (
                      <>
                        <CustomButton
                          variant='ghost'
                          className='p-2'
                          handleClick={() => setPdfScale(pdfScale + 0.1)}
                        >
                          <ZoomIn className='h-6 w-6' />
                        </CustomButton>
                        <Divider
                          style={{ height: '60%', background: 'black' }}
                          type='vertical'
                        />
                        <CustomButton
                          variant='ghost'
                          className='p-2'
                          handleClick={() => setPdfScale(pdfScale - 0.1)}
                        >
                          <ZoomOut className='h-6 w-6' />
                        </CustomButton>
                      </>
                    )}
                  </div>
                </Row>
              )}
              <ContractsCarouselWrapper>
                {/* {allDocsSigned && !executeWhenAllDocsSigned && (
                  <motion.div
                    initial={{ y: -15, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    className='absolute px-3 py-1 rounded text-white top-1.5 font-semibold bg-green-500'
                    transition={{
                      y: { type: 'spring', stiffness: 300, damping: 30 }
                    }}
                  >
                    All of your pending docs were signed!
                  </motion.div>
                )} */}
                <AnimatePresence
                  exitBeforeEnter
                  initial={false}
                  className='absolute'
                >
                  {isSigning?.length && docuSignUrl && (
                    <FadedAnimatedDiv
                      style={{ height: '100%', width: '100%' }}
                      key='hellosignAnimatedDiv'
                    >
                      <DocusealForm
                        src={docuSignUrl}
                        withTitle={false}
                        withDownloadButton={false}
                        withSendCopyButton={false}
                        allowToResubmit={false}
                        onComplete={() => {
                          setDocSigned()
                          setIsSigning(null)
                        }}
                      />
                    </FadedAnimatedDiv>
                  )}
                  {!isSigning?.length && (
                    <FadedAnimatedDiv
                      key='pdfPreview'
                      style={{ height: '100%', width: '100%' }}
                    >
                      <ContractWrapper>
                        {isAllSingedForSelectedContact && (
                          <ContractSignedPanel
                            contract={selectedContract}
                            onCloseScreenClicked={() => {
                              if (isAllSigned) {
                                setCloseDocument(false)
                              }
                            }}
                            isAllSigned={isAllSigned}
                            onSignNextContractClicked={() => {
                              if (!isAllSigned) {
                                onSignNextContractClicked()
                              }
                            }}
                          />
                        )}
                        {!isAllSingedForSelectedContact && (
                          <div className='w-full flex justify-center items-center'>
                            <div className='w-fit border shadow-md'>
                              <PdfDocument
                                pageProps={{ scale: pdfScale }}
                                documentProps={{
                                  file: selectedContract?.url
                                }}
                                pageNumber={pdfPageNumber}
                              />
                            </div>
                          </div>
                        )}
                      </ContractWrapper>
                    </FadedAnimatedDiv>
                  )}
                </AnimatePresence>
              </ContractsCarouselWrapper>
              <DocumentBtnsWrapper>
                {btnsShow && (
                  <>
                    {!isPublicPath && !isBuyerSingedSelectedContract && setPrimaryBtnAction && (
                      <CustomButton
                        handleClick={() => {
                          setCloseDocument(false)
                          setPrimaryBtnAction?.(true)
                        }}
                        disabled={loading}
                      >
                        <span className='font-semibold px-4 py-2 rounded text-base flex items-center gap-2'>
                          Take Payment
                        </span>
                      </CustomButton>
                    )}
                    {!isPublicPath && !isDeveloperSingedSelectedContract && (
                      <>
                        <CustomButton
                          handleClick={() => sendContractsToDeveloper(offerId)}
                          disabled={loading}
                        >
                          <span className='font-semibold px-4 py-2 rounded text-base flex items-center gap-2'>
                            Send to Developer
                          </span>
                        </CustomButton>
                        {!isSigning?.length && (
                          <CustomButton
                            handleClick={() => {
                              if (isSigning?.length) {
                                return setIsSigning(null)
                              }
                              onSignDocumentClicked('DeveloperAdmin')
                            }}
                            disabled={loading}
                          >
                            <span className='font-semibold px-4 py-2 rounded text-base flex items-center gap-2'>
                              Developer Sign
                            </span>
                          </CustomButton>
                        )}
                      </>
                    )}
                    {!isPublicPath && !isBuyerSingedSelectedContract && (
                      <CustomButton
                        handleClick={() => sendContractsToBuyer(offerId)}
                        disabled={loading}
                      >
                        <span className='font-semibold px-4 py-2 rounded text-base flex items-center gap-2'>
                          Send to Buyer
                        </span>
                      </CustomButton>
                    )}
                    {!isSigning?.length && !isBuyerSingedSelectedContract && (
                      <CustomButton
                        handleClick={() => onSignDocumentClicked('Buyer')}
                        disabled={loading}
                      >
                        <span className='font-semibold px-4 py-2 rounded text-base flex items-center gap-2'>
                          Sign Now
                        </span>
                      </CustomButton>
                    )}
                    {isSigning?.length && !isBuyerSingedSelectedContract && (
                      <CustomButton
                        handleClick={() => setIsSigning(null)}
                        disabled={loading}
                      >
                        <span className='font-semibold px-4 py-2 rounded text-base flex items-center gap-2'>
                          Back to Document Viewer
                        </span>
                      </CustomButton>
                    )}
                    {isPublicPath && !isAllSigned && (
                      <CustomButton
                        handleClick={handleGoBack}
                        disabled={loading}
                      >
                        <span className='font-semibold px-4 py-2 rounded text-base flex items-center gap-2'>
                          Go Back
                        </span>
                      </CustomButton>
                    )}
                  </>
                )}
              </DocumentBtnsWrapper>
            </RightWrapper>
          </ContractsWrapper>
        </FadedAnimatedDiv>
      )}
    </AnimatePresence>
  )
}

const mapStateToProps = (state) => ({
  userObject: state.authReducer.userObject
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(OfferDocuments)

OfferDocuments.propTypes = {
  contracts: propTypes.array,
  onGettingContracts: propTypes.func,
  executeWhenAllDocsSigned: propTypes.func,
  btnsShow: propTypes.bool,
  setCloseDocument: propTypes.func,
  setPrimaryBtnAction: propTypes.func,
  userObject: propTypes.shape({
    userType: propTypes.string
  }),
  offerId: propTypes.string,
  isPublicPath: propTypes.bool,
  handleGoBack: propTypes.func,
}
