import React from 'react'
import styled from 'styled-components'
import InputMask from 'react-input-mask'
import NumberFormat from 'react-number-format'
import { Select as SelectAntd, DatePicker as DatePickerAntd } from 'antd'
import CustomTextArea from './textarea'

import standardStyle from 'assets/css/standardStyle'

export const { Option } = SelectAntd

const applyStyle = (input) => styled(input)`
  background-color: ${standardStyle.colors.input.background};
  border: 1px solid ${standardStyle.colors.input.border};
  border-radius: .25rem;
  font-family: ${standardStyle.fonts.text};
  padding: .375rem .75rem;
  font-size: 14px;
  line-height: 1.5;
  width: 100%;

  &[readonly]{
    opacity:0.5;
  }

  &:focus {
    border-color: ${standardStyle.colors.primary} !important;
    box-shadow: 0 0 0 0.5px ${standardStyle.colors.primary} !important;
  }
`

export const DatePicker = applyStyle(DatePickerAntd)
export const InputText = () => <InputMask />
export const InputNumber = (props) => <NumberFormat {...props} />
export const TextArea = applyStyle(CustomTextArea)
export const Select = (props) => <SelectAntd {...props} />
