import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { ArrowRight, CalendarDays, Check, DollarSign, KeyRound, Mail, MessagesSquare, Phone, Settings } from 'lucide-react'
import { getProjects } from 'store/actions/projectActions'
import { BUYER_DOCUMENTS } from 'routes'

const quickLinks = [
  { Icon: CalendarDays, title: 'Calendar', redirectTo: '/buyer/dashboard' },
  { Icon: DollarSign, title: 'Payments', redirectTo: '/buyer/dashboard' },
  { Icon: MessagesSquare, title: 'Inbox', redirectTo: '/buyer/dashboard' },
  { Icon: Settings, title: 'Management', redirectTo: '/buyer/dashboard' }
]

const tabs = [
  {
    id: 'quickLinks',
    title: 'Quick links'
  },
  {
    id: 'purchaseStatus',
    title: 'Purchase status'
  }
]

const QuickLinkCard = ({
  Icon,
  title,
  redirectTo
}) => {
  const history = useHistory()

  return (
    <div
      className='p-3 bg-white rounded-lg border border-[#e6e6e6] flex-col justify-center items-center gap-2 inline-flex cursor-pointer'
      onClick={() => history.push(redirectTo)}
    >
      <div className='flex-col justify-center items-start gap-4 flex'>
        <Icon strokeWidth={1.25} className='w-12 h-12' />
      </div>
      <div className='self-stretch text-center text-zinc-950 text-sm font-medium leading-tight'>{title}</div>
    </div>
  )
}

const ContactCard = ({ className }) => {
  return (
    <div className={`p-4 bg-white rounded-lg border border-[#e6e6e6] justify-start items-center gap-4 inline-flex ${className}`}>
      <div className='grow shrink basis-0 h-[88px] justify-start items-center gap-4 flex'>
        <div className='w-14 h-14 rounded-full flex p-2 justify-center items-center text-xl font-bold text-white bg-zinc-950'>
          AS
        </div>
        <div className='flex-col justify-start items-start gap-2 inline-flex'>
          <div className='flex-col justify-start items-start flex'>
            <div className='w-[100px] text-zinc-950 text-base font-medium leading-normal'>Austin Jones</div>
            <div className='text-zinc-500 text-sm font-normal leading-tight'>Your Sales Representative</div>
          </div>
          <div className='justify-start items-center gap-2 inline-flex'>
            <div className='w-9 h-9 bg-zinc-900 rounded-md shadow justify-center items-center flex cursor-pointer'>
              <Mail className='w-4 h-4 text-white' />
            </div>
            <div className='w-9 h-9 bg-zinc-900 rounded-md shadow justify-center items-center flex cursor-pointe'>
              <Phone className='w-4 h-4 text-white' />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const PurchaseStatus = ({ className }) => {
  const statusItems = [
    { title: 'Initial inquiry', date: 'Jan 2, 2024 10:00 AM', isComplete: true, Icon: Check },
    { title: 'Document signing', date: 'Jan 2, 2024 10:00 AM', isComplete: true, Icon: Check },
    { title: 'Payments', date: 'Jan 2, 2024 10:00 AM', isComplete: true, Icon: Check },
    { title: 'Approvals', date: 'Jan 2, 2024 10:00 AM', isComplete: false, Icon: Check },
    { title: 'Ownership transfer', date: 'Jan 2, 2024 10:00 AM', isComplete: false, Icon: KeyRound }
  ]

  const purchaseDetails = [
    { label: 'Primary buyer', value: 'Jonathan Banes' },
    { label: 'Purchase price', value: '$1,288,000' },
    { label: 'Upgrades & options', value: '$25,000' },
    { label: 'Total deposit paid', value: '$128,800' },
    { label: 'Sold firm date', value: '—' }
  ]

  return (
    <div className={`bg-white rounded-lg border border-[#e6e6e6] flex-col justify-start items-start inline-flex gap-6 lg:gap-0 p-6 lg:p-0 ${className}`}>
      <div className='self-stretch lg:p-6 lg:border-b border-[#e6e6e6] flex flex-col lg:flex-row lg:items-center gap-6'>
        <img src='/unit-1201.png' className='w-full lg:w-[243px] h-44 object-contain' />
        <div className='flex flex-col gap-2'>
          <div className='w-fit bg-[#f8e6c3] rounded-md px-3 py-1 flex items-center gap-2'>
            <div className='w-2 h-2 bg-[#e9ab2f] rounded-full' />
            <div className='text-zinc-950 text-xs font-bold'>Reserved pending signature</div>
          </div>
          <div className='text-zinc-950 text-[28px] font-semibold'>Unit #1201</div>
        </div>
      </div>

      <div className='self-stretch lg:p-8 flex flex-col gap-12'>
        <div className='flex flex-col lg:flex-row lg:justify-between gap-6 lg:gap-0'>
          <div className='order-2 lg:order-1 w-full lg:w-fit grid grid-cols-2 gap-6'>
            {purchaseDetails.map((detail, index) => (
              <div key={index} className='flex flex-col w-[151px]'>
                <div className='text-zinc-500 text-sm'>{detail.label}</div>
                <div className='text-zinc-950 text-base'>{detail.value}</div>
              </div>
            ))}
          </div>

          <div className='order-1 lg:order-2 w-full lg:w-56 flex flex-col gap-4'>
            {statusItems.map((item, index) => (
              <div key={index} className='flex items-center gap-2'>
                <div className={`z-[5] relative w-9 h-9 flex items-center justify-center rounded-full ${item.isComplete ? 'bg-[#1f1f1f] text-[#E6E6E6]' : 'bg-zinc-200 text-[#71717A]'}`}>
                  <item.Icon className='w-6 h-6' />
                  {
                    index < (statusItems?.length - 1) &&
                    <div className='z-[4] w-[16px] h-[0px] absolute left-[0.65rem] top-11 rotate-90 border border-dashed border-zinc-400' />
                  }
                </div>
                <div className='flex flex-col gap-0.5'>
                  <div className='text-zinc-950 text-sm font-semibold'>{item.title}</div>
                  <div className='text-zinc-500 text-xs'>{item.date}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

const QuickLinks = () => {
  return (
    <div className='w-full grid grid-cols-2 gap-4'>
      {quickLinks.map((link, index) => (
        <QuickLinkCard
          key={index}
          Icon={link.Icon}
          title={link.title}
          redirectTo={link.redirectTo}
        />
      ))}
      <ContactCard className='col-span-2' />
      <ContactCard className='col-span-2' />
    </div>
  )
}

const BuyerDashBoard = (props) => {
  const { userObject } = props
  const [selectedTab, setSelectedTab] = useState(tabs[0].id)
  const [isFetchingProject, setIsFetchingProject] = useState(true)
  const [project, setProject] = useState(null)
  const [projectAddress, setProjectAddress] = useState('')

  useEffect(() => {
    const projectId = userObject?.buyerData?.assignments?.[0]?.project ?? ''
    if (projectId) {
      setIsFetchingProject(true)
      getProjects(projectId).then((res) => {
        setProject(res)
        const tempAddress = [res?.city, res?.country]?.filter((e) => (e))?.join(', ')
        setProjectAddress(tempAddress)
        setIsFetchingProject(false)
      }).catch(() => {
        setIsFetchingProject(false)
      })
    }
  }, [userObject])

  return (
    <section className='w-full flex flex-col overflow-y-auto bg-[#F6F5F1]'>
      <div className='w-full h-fit relative bg-white flex flex-col lg:flex-row px-6 lg:pr-0 lg:pl-[71px] gap-12 lg:gap-[152px] transition-all'>
        <div className='z-[1] w-full h-full left-0 top-0 absolute bg-gradient-to-tl from-black via-black to-black' />
        <div className='z-[2] w-full lg:w-[347px] flex flex-col gap-[89px] pt-[143px] lg:pb-[110px]'>
          {
            !isMobile &&
            <div className='min-h-[100px] flex justify-start items-center gap-[17px] transition-all'>
              {
                isFetchingProject
                  ? <div className='opacity-50 text-neutral-50 text-sm font-normal leading-tight'>
                    Loading...
                  </div>
                  : <>
                    <div className='w-[100px] h-[100px] rounded-[100px] border border-white flex-col justify-center items-center gap-2 inline-flex overflow-hidden'>
                      <img src={project?.logoImage ?? ''} className='w-full h-full object-cover' alt={project?.projectName} />
                    </div>
                    <div className='flex-col justify-start items-start inline-flex'>
                      <div className='text-white text-base font-bold leading-normal'>{project?.projectName ?? ''}</div>
                      {
                        projectAddress?.length > 0 &&
                        <div className='opacity-50 text-neutral-50 text-sm font-normal leading-tight'>
                          {projectAddress}
                        </div>
                      }
                    </div>
                  </>
              }
            </div>
          }
          <div className='flex flex-col gap-4'>
            <div className='text-white text-[42px] font-normal leading-[52.50px]'>Hello, {userObject?.firstName}!</div>
            <div className='w-full lg:w-[347px] text-white text-lg font-normal leading-[28.80px]'>Welcome back to your buyer's portal. You can find and stay up-to-date about your new property!</div>
          </div>
        </div>
        <div className='z-[2] w-full lg:flex-1 flex flex-col pb-8 lg:pb-0 lg:pt-[190px] gap-[18px] overflow-hidden'>
          <div className='w-full lg:w-[356px] p-4 bg-white/25 rounded-lg justify-start items-start gap-2 inline-flex'>
            <div className='grow shrink basis-0 text-white text-[15px] font-semibold leading-normal'>Stage: What to do next</div>
          </div>
          <div className='w-full flex gap-6 lg:gap-10'>
            <div className='w-full lg:w-[353px] h-60 p-6 bg-[#ffdf9d] rounded-lg flex-col justify-between items-start inline-flex'>
              <div className='self-stretch flex-col justify-start items-start flex'>
                <div className='text-zinc-950 text-[21px] font-semibold leading-[35.11px]'>Sign your documents</div>
                <div className='self-stretch text-zinc-500 text-sm font-normal leading-tight'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</div>
              </div>
              <Link
                to={BUYER_DOCUMENTS}
                className="text-white text-sm font-medium leading-tight"
              >
                <button className='h-10 px-4 py-2 bg-zinc-900 rounded-md shadow justify-center items-center gap-2 inline-flex cursor-pointer'>
                  <div className='text-white text-sm font-medium leading-tight'>See documents</div>
                  <ArrowRight className='w-5 h-5 text-white' />
                </button>
              </Link>
            </div>
            {/* <div className='w-full lg:w-[353px] h-60 p-6 opacity-75 bg-white rounded-lg flex-col justify-between items-start inline-flex'>
              <div className='self-stretch h-[116px] flex-col justify-start items-start flex'>
                <div className='text-zinc-950 text-[21px] font-semibold leading-[35.11px]'>Pay your deposit</div>
                <div className='self-stretch text-zinc-500 text-sm font-normal leading-tight'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</div>
              </div>
              <div className='h-10 px-4 py-2 bg-zinc-900 rounded-md shadow justify-center items-center gap-2 inline-flex'>
                <div className='text-white text-sm font-medium leading-tight'>Payment options</div>
                <ArrowRight className='w-5 h-5 text-white' />
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {
        isMobile &&
        <div className='w-full bg-white grid grid-cols-2'>
          {
            tabs.map((tab) => (
              <div
                key={tab.id}
                className={`px-6 py-4 justify-center items-center gap-2 inline-flex border-b-2 ${tab.id === selectedTab ? 'border-[#141431] text-[#141431]' : 'border-[#FFF] text-[#5a5d5f]'} transition-all`}
                onClick={() => setSelectedTab(tab.id)}
              >
                <div className='text-center text-base font-semibold leading-normal'>{tab.title}</div>
              </div>
            ))
          }
        </div>
      }
      <div className='w-full z-[3] relative lg:top-[-20px] bg-[#F6F5F1] lg:rounded-t-3xl flex flex-col lg:flex-row gap-[49px] px-6 py-12 lg:p-16 lg:pb-11'>
        {
          isMobile
            ? <>
              {
                selectedTab === 'quickLinks' && <QuickLinks />
              }
              {
                selectedTab === 'purchaseStatus' && <PurchaseStatus className='w-full' />
              }
            </>
            : <>
              <div className='w-full lg:w-[447.22px] flex flex-col gap-4'>
                <h4 className='text-zinc-950 text-lg font-normal leading-[28.80px] mb-0'>Quick links</h4>
                <QuickLinks />
              </div>
              <div className='flex-1 flex flex-col gap-4'>
                <h4 className='text-zinc-950 text-lg font-normal leading-[28.80px] mb-0'>Purchase status</h4>
                <PurchaseStatus className='w-full' />
              </div>
            </>
        }
      </div>
    </section>
  )
}

const mapStateToProps = (state) => ({
  userObject: state.authReducer.userObject
})
const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(BuyerDashBoard)

// <div className='w-full'>
//   <div className='bg-[#566A75] pl-6 fixed z-[999] h-full py-6 pr-[26px] flex flex-col gap-y-4 w-[252px]'>
//     <div className='flex mx-auto '>
//       <img src='/logo.png' alt='logo' className='w-28 h-28' />
//     </div>
//     <div className=''>
//       <ul className='flex flex-col gap-y-6'>
//         {
//           buyerNavItems.map((elm) => (
//             <li
//               key={elm.title}
//               onClick={() => history.push(elm.path)}
//               className='flex items-center gap-x-2 cursor-pointer text-white hover:bg-white hover:text-black px-2 py-[6px] rounded-md italic text-lg font-light truncate transition-all'
//             >
//               <img src={elm.icon} alt={elm.title} className='text-white' />
//               {elm.title}
//             </li>
//           ))
//         }
//       </ul>
//     </div>
//   </div>
//   <div className='relative h-full ml-[252px] '>
//     <img
//       src='/buyer-dashboard-no-shadow.png'
//       className='w-full fixed h-full object-cover '
//     />
//     <div className='w-full gap-5 p-6 flex flex-col items-center justify-center top-0 absolute'>
//       <div className=' rounded-lg w-full pt-[22px] pb-6 flex items-center  justify-center flex-col gap-y-2 bg-[rgba(0,0,0,0.6)] backdrop-blur-md'>
//         <div>
//           <img src='/logo.png' className='h-[84.64px]' />
//         </div>
//         <div className='text-[40px] text-[#EFA581] font-bold'>
//           The Pacifica
//         </div>
//         <div className='text-center font-bold text-[40px] text-white'>
//           Welcome Back, Stefan
//         </div>
//       </div>
//       <div className='flex flex-1   w-full gap-8 justify-between'>
//         <div className='bg-[#FCFCFA] max-h-2xl max-w-sm w-full px-7 py-6 text-[24px] rounded-lg'>
//           <div className='mb-4 text-center'>Purchase Timeline</div>
//           <div className='flex flex-col gap-y-7'>
//             <div className='flex gap-x-7 relative'>
//               <div className='w-14 h-14 bg-[#E8CB78] flex items-center justify-center rounded-full'><img src='/right-arrow.png' /></div>
//               <div>
//                 <div className='italic font-light text-[14px]'>July 16th  2023</div>
//                 <div className='text-[20px] font-bold text-[#7a7a7a]'>Reservation</div>
//               </div>
//               <div className='w-[2px] h-7 -bottom-7 ml-7 bg-[#2E2B2E4D] absolute'></div>
//             </div>
//             <div className='flex gap-x-7 relative'>
//               <div className='w-14 h-14 bg-[#E8CB78] flex items-center justify-center rounded-full'><img src='/right-arrow.png' /></div>
//               <div>
//                 <div className='italic font-light text-[14px]'>July 16th  2023</div>
//                 <div className='text-[20px] font-bold text-[#7a7a7a]'>Reservation</div>
//               </div>
//               <div className='w-[2px] h-7 -bottom-7 ml-7 bg-[#2E2B2E4D] absolute'></div>
//             </div>
//             <div className='flex gap-x-7 relative'>
//               <div className='w-14 h-14 bg-[#E8CB78] flex items-center justify-center rounded-full'><img src='/right-arrow.png' /></div>
//               <div>
//                 <div className='italic font-light text-[14px]'>July 16th  2023</div>
//                 <div className='text-[20px] font-bold text-[#7a7a7a]'>Reservation</div>
//               </div>
//               <div className='w-[2px] h-7 -bottom-7 ml-7 bg-[#2E2B2E4D] absolute'></div>
//             </div>
//             <div className='flex gap-x-7 relative'>
//               <div className='w-14 h-14 bg-[#E3E3E1] flex items-center justify-center rounded-full'></div>
//               <div>
//                 <div className='italic font-light text-[14px]'>July 16th  2023</div>
//                 <div className='text-[20px] font-bold text-[#2E2B2E]'>Reservation</div>
//               </div>
//               <div className='w-[2px] h-7 -bottom-7 ml-7 bg-[#2E2B2E4D] absolute'></div>
//             </div>
//             <div className='flex gap-x-7 relative'>
//               <div className='w-14 h-14 bg-[#E3E3E1] flex items-center justify-center rounded-full'></div>
//               <div>
//                 <div className='italic text-[14px]'>July 16th  2023</div>
//                 <div className='text-[20px] font-bold text-[#2E2B2E]'>Reservation</div>
//               </div>
//               <div className='w-[2px] h-7 -bottom-7 ml-7 bg-[#2E2B2E4D] absolute'></div>
//             </div>
//             <div className='flex gap-x-7 relative'>
//               <div className='w-14 h-14 bg-[#E3E3E1] flex items-center justify-center rounded-full'></div>
//               <div>
//                 <div className='italic text-[14px]'>July 16th  2023</div>
//                 <div className='text-[20px] font-bold text-[#2E2B2E]'>Reservation</div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className='bg-[#FCFCFA] max-h-2xl max-w-sm w-full px-7 pt-8 text-[24px] rounded-lg'>
//           <div className='flex flex-col gap-y-6'>
//             <div className=''>
//               <img src='/residence-diagram.png' />
//             </div>
//             <div className='w-full h-[1px] bg-black'></div>
//             <div className='text-[#2E2B2E] flex flex-col gap-y-1'>
//               <div>
//                 <button className='bg-[#B8E04A] italic font-light text-[16px] p-1'>SOLD FIRM</button>
//               </div>
//               <div className='text-[32px] font-bold'>
//                 Residence #1201
//               </div>
//               <div className='text-[21px] flex items-center gap-x-1'>
//                 <div>
//                   <img src='/residence-icon.png' />
//                 </div>
//                 <div>
//                   1818 Pacifica
//                 </div>
//               </div>
//               <div className='italic text-[27px]'>
//                 CA$1,320,040
//               </div>

//             </div>
//           </div>
//         </div>
//         <div className='bg-[#FCFCFA] max-h-2xl max-w-sm w-full px-2 py-4 text-[24px] rounded-lg'>
//           <div className='flex flex-col gap-y-4'>
//             <div className='flex gap-x-5 relative rounded-[4px] text-[#2E2B2E] bg-[#7D63451F] py-4 pl-8 pr-8  border-[#E8CB78] border-2'>
//               <div className=''><img src='/austin-jones.png' /></div>
//               <div className='flex flex-1 flex-col gap-y-1'>
//                 <div className=' text-[17px]'>Your Sales Rep</div>
//                 <div className='flex items-center  justify-between'>
//                   <div className=' text-[20px] font-bold '>Austin Jones</div>
//                   <div className='w-8 h-8  bg-[#F2F2F1] flex items-center justify-center'>
//                     <img src='/call-icon.svg' />
//                   </div>
//                 </div>
//                 <div className='flex items-center  justify-between'>
//                   <div className=' text-[13px] font-light '>AJ@milborne.com</div>
//                   <div className='w-8 h-8  bg-[#F2F2F1] flex items-center justify-center'>
//                     <img src='/message-icon.svg' />
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className='flex gap-x-5 relative rounded-[4px] text-[#2E2B2E] bg-[#7D63451F] py-4 pl-8 pr-8  border-[#E8CB78] border-2'>
//               <div className=''><img src='/shannon-mckenzie.png' /></div>
//               <div className='flex flex-1 flex-col gap-y-1'>
//                 <div className=' text-[17px]'>Your Broker</div>
//                 <div className='flex items-center  justify-between'>
//                   <div className=' text-[15px] font-bold '>Shannon Mckenzie</div>
//                   <div className='w-8 h-8  bg-[#F2F2F1] flex items-center justify-center'>
//                     <img src='/call-icon.svg' />
//                   </div>
//                 </div>
//                 <div className='flex items-center  justify-between'>
//                   <div className=' text-[13px] font-light '>s.mc@sotherbys.ca</div>
//                   <div className='w-8 h-8  bg-[#F2F2F1] flex items-center justify-center'>
//                     <img src='/message-icon.svg' />
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className='flex flex-col gap-2'>
//               <div className=' flex justify-between w-full gap-x-4'>
//                 <div className='flex items-center w-full justify-center flex-col pt-4 pb-2 px-8 bg-[#7D63451F] rounded-[4px] gap-y-2'>
//                   <img src='/calender-card-icon.png' />
//                   <div className='italic text-[16px] font-bold'>Calendar</div>
//                 </div>
//                 <div className='flex flex-col  items-center w-full justify-center pt-4 pb-2 px-8 bg-[#7D63451F] rounded-[4px] gap-y-2'>
//                   <img src='/calender-card-icon.png' className='' />
//                   <div className='italic text-[16px] font-bold'>Calendar</div>
//                 </div>
//               </div>
//               <div className=' flex justify-between w-full gap-x-4'>
//                 <div className='flex flex-col  items-center w-full justify-center pt-4 pb-2 px-8 bg-[#7D63451F] rounded-[4px] gap-y-2'>
//                   <img src='/calender-card-icon.png' />
//                   <div className='italic text-[16px] font-bold'>Calendar</div>
//                 </div>
//                 <div className='flex flex-col  items-center w-full justify-center pt-4 pb-2 px-8 bg-[#7D63451F] rounded-[4px] gap-y-2'>
//                   <div><img src='/calender-card-icon.png' /></div>
//                   <div className='italic text-[16px] font-bold'>Calendar</div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
// </div>
