/* eslint-disable */
// @ts-nocheck
import React, { useEffect, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '../../../assets/css/ag-theme-balham.css';
import { classNames } from 'utils';
import useGetProjectUnits from 'hooks/useGetProjectUnits';
import { groupBy } from '..';
import { getFilteredDeals } from 'store/actions/dealAction';
import {
  getUnitsByBuildingId,
  getUnitsByBuildingIdTableView,
} from 'store/actions/unitActions';
import { useState } from 'react';
import moment from 'moment';
import { IoMdClose } from 'react-icons/io';
import CustomSelect from 'components/CustomSelect';
import CustomInput from 'components/CustomInput';
import {
  getParkingInventory,
  getParkingsByBuildingId,
} from 'store/actions/parkingActions';
import InventoryEditDrawer from './InventoryEditDrawer';
import Skeleton from 'react-loading-skeleton';

export default function TableViewParking({ selectedBuilding, projectId }) {
  const [filteredParkings, setFilteredParkings] = useState([]);
  const [tempTypesOfAllocatedParking, setTempTypesOfAllocatedParking] =
    useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [typesOfAllocatedParking, setTypesOfAllocatedParking] = useState([]);
  const [parkingType, setParkingType] = useState([]);
  const [allocationModal, setAllocationModal] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [editDetails, setEditDetails] = useState({});
  const [tempRefetch, setTempRefetch] = useState(false);
  //   const handleFilteredDeals = async () => {
  //     let data = await getFilteredDeals({});
  //     console.log(data);
  //   };
  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      filter: true,
      resizable: true,
    };
  }, []);

  const currencyFormate = (value) => {
    if (value == null) return undefined;
    else {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });

      const formattedValue = formatter.format(value);
      return formattedValue;
    }
  };
  const _getParkingType = async (parking) => {
    let parkingInventory = await getParkingInventory(selectedBuilding);
    parkingInventory = parkingInventory.map((parking) => ({
      parkingType: parking.options.map((op) => op.name),
    }));
    if (parkingInventory.length) {
      setParkingType(
        parkingInventory[0].parkingType.map((pt) => ({
          value: pt,
          id: pt,
        }))
      );
    }
  };

  useEffect(() => {
    _getParkingType();
  }, [selectedBuilding]);

  const handleAddParking = () => {
    const newId = tempTypesOfAllocatedParking.length + 1;
    const newBgColor = colors[newId % colors.length]; // Rotate through colors
    const newAllocation = {
      id: newId,
      count: 1,
      title: '',
      bgColor: newBgColor,
    };

    setTempTypesOfAllocatedParking((prev) => [...prev, newAllocation]);
  };

  const openAllocationModal = () => {
    setAllocationModal(true);
  };
  const colors = {
    Visitor: '#FFD700',
    Regular: '#FFBE9A',
    Tandem: '#16A34A40',
  };

  const colorsAllocation = {
    UNITS: '#FFBE9A',
    'For Sale (extra parking)': '#16A34A40',
    Tandem: '#FFD700',
  };
  //   const colors = ['#FFBE9A', '#16A34A40', '#FFD700', '#FF0000'];
  useEffect(() => {
    setIsLoading(true);
    const parkings = getParkingsByBuildingId(selectedBuilding).then((data) => {
      let mapped = data.map((parking) => {
        return {
          ...parking,
          unit: parking.unit ? parking.unit?.unitNumber : 'N/A',
        };
      });
      setFilteredParkings(mapped);
      setIsLoading(false);
    });
  }, [selectedBuilding, tempRefetch]);

  const columnDefs = [
    {
      headerName: 'Parking Name',
      field: 'stall',
      sortable: true,
      filter: true,
      width: 120,
      pinned: 'left',
      //   checkboxSelection: true,
    },
    {
      headerName: 'Price',
      field: 'price',
      sortable: true,
      filter: true,
      width: 150,
      valueFormatter: (params) => {
        return currencyFormate(params.value);
      },
    },
    {
      headerName: 'Status',
      field: 'status',
      sortable: true,
      filter: true,
      width: 150,
      cellRenderer: (params) => {
        return (
          <div className='px-3 py-0.5 border rounded-md justify-center items-center gap-1 inline-flex'>
            <div className='text-center text-sm font-normal'>
              {params?.data?.status}
            </div>
          </div>
        );
      },
    },
    {
      headerName: 'Parking Type',
      sortable: true,
      width: 250,
      field: 'parkingType',
      filter: true,
      cellRenderer: (params) => {
        return (
          <div
            style={{
              backgroundColor: colors[params?.data?.parkingType] || '#FFBE9A',
            }}
            className='px-3 py-0.5 border rounded-md justify-center items-center gap-1 inline-flex'
          >
            <div className='text-center text-sm font-normal'>
              {params?.data?.parkingType}
            </div>
          </div>
        );
      },
    },
    {
      headerName: 'Allocation Type',
      sortable: true,
      width: 250,
      field: 'parkingType',
      filter: true,
      cellRenderer: (params) => {
        return (
          <div
            style={{
              backgroundColor:
                colorsAllocation[params?.data?.allocationType] || '#FFBE9A',
            }}
            className='px-3 py-0.5 border rounded-md justify-center items-center gap-1 inline-flex'
          >
            <div className='text-center text-sm font-normal'>
              {params?.data?.allocationType}
            </div>
          </div>
        );
      },
    },
    {
      headerName: 'Assigned Unit',
      sortable: true,
      width: 200,
      field: 'unit',
      cellRenderer: (params) => {
        return (
          <div className='flex gap-x-2'>
            <div className='px-3 py-0.5 border rounded-md justify-center items-center gap-1 inline-flex'>
              <div className='text-center text-sm font-normal'>
                {params?.data?.unit}
              </div>
            </div>
          </div>
        );
      },
    },

    {
      headerName: 'Is Avesdo Parking',
      field: 'unitType',
      sortable: true,
      filter: true,
      width: 150,
      cellRenderer: (params) => {
        return (
          <div className='flex gap-x-2'>
            <div
              style={{
                backgroundColor: params.data.isAvesdo ? 'green' : '#fbbf24 ',
              }}
              className='px-3 py-0.5 rounded-md justify-center items-center gap-1 inline-flex'
            >
              <div className='text-center text-sm text-black font-normal'>
                {params.data.isAvesdo ? 'Yes' : 'No'}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      headerName: 'Edit Parking',
      field: 'allocatedParkingBtn',
      sortable: true,
      filter: true,
      cellRenderer: (params) => {
        return (
          <button
            onClick={() => {
              setEditDetails(params.data);
              setIsDrawerOpen(true);
            }}
            className='py-1 px-3 bg-[#FFBE9A] rounded-md items-center justify-center'
          >
            <div className='text-center text-sm text-black font-[500]'>
              Edit Parking
            </div>
          </button>
        );
      },
    },
  ];

  return (
    <div className='flex w-full h-full'>
      {isLoading ? (
        <div className='h-full w-full flex flex-col gap-y-4'>
          <Skeleton height={130} />
          <Skeleton height={130} />
          <Skeleton height={130} />
          <Skeleton height={130} />
          <Skeleton height={130} />
        </div>
      ) : (
        <div
          className={classNames(
            'h-full ag-theme-alpine w-full transition-all relative',
            isDrawerOpen ? 'mr-[350px]' : 'mr-0'
          )}
        >
          <AgGridReact
            domLayout='autoHeight'
            defaultColDef={defaultColDef}
            rowHeight={40}
            columnDefs={columnDefs}
            rowData={filteredParkings}
          />
        </div>
      )}
      <div className='absolute right-0 top-0 bottom-0 z-[999]'>
        <InventoryEditDrawer
          editDetails={editDetails}
          setEditDetails={setEditDetails}
          closeInfoDrawer={() => setIsDrawerOpen(false)}
          isInfoDrawerOpen={isDrawerOpen}
          isAbleToPurchase
          refetch={() => {
            setTempRefetch(!tempRefetch);
          }}
          type={'Parking'}
          selectedBuilding={selectedBuilding}
          isFullHeight={false}
        />
      </div>
    </div>
  );
}
