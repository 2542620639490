import styled from 'styled-components'
import standardStyle from 'assets/css/standardStyle'

export const AddBlockContainer = styled.div`
  width: 100%;
  height: 170px;
  background: ${standardStyle.colors.white};
  border: 1px dashed ${standardStyle.colors.gray_300};
  border-radius: 4px;
`

export const AddBlockContent = styled.div`
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;
`
export const PlusIconContainer = styled.div`
    font-size: 32px;
    display: flex;
    margin-bottom: 16px;
`
export const TextLabel = styled.p`
    text-align: center;
    color: ${standardStyle.colors.softBlack_70};
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 16px;
    font-family: 'Open Sans', system-ui, sans-serif;
`
export const AddButton = styled.button`
    border: unset;
    text-align: center;
    background: ${standardStyle.colors.yellow};
    padding: 8px 16px;
    border-radius: 4px;
    display: flex;
    font-style: italic;
    align-items: center;
    justify-content: center;
    font-weight: 300;
    font-family: 'Open Sans', system-ui, sans-serif;
    text-transform: capitalize;
    letter-spacing: 0.6px;
    text-
    color: ${standardStyle.colors.softBlack};
    box-sizing: border-box;
    height: 38px;
    margin-bottom: 8px;
    svg {
        margin-right: 8px;
    }
    &:hover {
        border: 1px solid ${standardStyle.colors.softBlack};
    }
`
