/* eslint-disable no-unused-vars */
import React, { useContext, useState } from 'react'
import { ReactComponent as DownIcon } from 'assets/icons/down.svg'
import { Select } from 'antd'
import { ContactContext } from 'pages/contacts/context/Contacts'
import CustomSelect from 'components/CustomSelect'
import { getUserDataByType } from 'utils/getUserDataByType'

const DemandNotesForm = ({ selectedBuyers }) => {
  const { dropdownData } = useContext(ContactContext)
  const { userType } = selectedBuyers ?? {}
  const userDataObject = getUserDataByType(userType, selectedBuyers)
  const DROPDOWN_TITLES = [
    'Unit Interests',
    'Budget',
    'Timeline to Purchase',
    'Project Interests',
    'Floor Interests',
    'Needs Financing'
  ]

  const getSelectedUnitInterests = () => {
    const unitInterestsIndex = []
    const unitInterestsApi = userDataObject.unitInterests
    const dropdownValues = []
    for (let i = 0; i < dropdownData.unitInterests.length; i++) {
      dropdownValues.push(dropdownData.unitInterests[i].value)
    }
    for (let i = 0; i < unitInterestsApi.length; i++) {
      const unitInterest = unitInterestsApi[i]
      const index = dropdownValues.indexOf(unitInterest)
      if (index > -1) {
        unitInterestsIndex.push(index)
      }
    }
    return unitInterestsIndex
  }

  const getSelectedProjectInterests = () => {
    const projectInterests = []
    const projectInterestsApi = userDataObject.projectInterests
    for (let i = 0; i < projectInterestsApi.length; i++) {
      const id = projectInterestsApi[i]._id
      projectInterests.push(id)
    }
    return projectInterests
  }

  const getSelectedFloorInterests = () => {
    const floorInterestsIndex = []
    const floorInterestsApi = userDataObject.floorInterests
    const dropdownValues = []
    for (let i = 0; i < dropdownData.floorInterests.length; i++) {
      dropdownValues.push(dropdownData.floorInterests[i].value)
    }
    for (let i = 0; i < floorInterestsApi.length; i++) {
      const floorInterest = floorInterestsApi[i]
      const index = dropdownValues.indexOf(floorInterest)
      if (index > -1) {
        floorInterestsIndex.push(index)
      }
    }
    return floorInterestsIndex
  }

  const [selectedUnitInterests, setSelectedUnitInterests] = useState(
    userDataObject?.unitInterests?.length > 0 ? getSelectedUnitInterests() : []
  )
  const [selectedBudgetOption, setSelectedBudgetOption] = useState(
    userDataObject?.budget?.length > 0 ? userDataObject.budget : 'Select Option'
  )
  const [selectedtimelineToPurchase, setSelectedtimelineToPurchase] = useState(
    userDataObject?.timelineToPurchase?.length > 0
      ? userDataObject.timelineToPurchase
      : 'Select Option'
  )
  const [selectedProjectInterests, setSelectedProjectInterests] = useState(
    userDataObject?.projectInterests?.length > 0
      ? getSelectedProjectInterests()
      : []
  )
  const [selectedFloorInterests, setSelectedFloorInterests] = useState(
    userDataObject?.floorInterests?.length > 0
      ? getSelectedFloorInterests()
      : []
  )
  const [selectedNeedsFinancing, setSelectedNeedsFinancing] = useState(
    userDataObject?.needsFinancing?.length > 0
      ? userDataObject.needsFinancing
      : 'Select Option'
  )

  const renderSingleSelect = (
    title,
    dropdownValues,
    handleOnChange,
    selectedOption
  ) => {
    return (
      <div className='relative'>
        <div className='mt-0 mb-1.5 text-softBlack70 w-full text-xs uppercase italic font-openSans font-bold'>
          {title}
        </div>
        <Select
          suffixIcon={<DownIcon />}
          showArrow
          onChange={handleOnChange}
          value={selectedOption}
          className='rating-select text-base bg-white border border-softBlack30 w-full rounded text-softBlack placeholder-softBlack70 py-1'
          disabled
          getPopupContainer={(node) => node.parentNode}
        >
          {dropdownValues?.map((_item, index) => (
            <Select.Option value={_item.value} key={index}>
              <div className='flex gap-2 items-center'>
                <span className='italic'>{_item.label}</span>
              </div>
            </Select.Option>
          ))}
        </Select>
      </div>
    )
  }

  const renderMultiSelectDropdown = (
    title,
    dropdownValues,
    handleOnChange,
    selectedOptions
  ) => {
    return (
      <div className='relative'>
        <div className='mt-0 text-softBlack70 w-full text-xs uppercase italic font-openSans font-bold'>
          {title}
        </div>
        <div>
          <CustomSelect
            label={undefined}
            options={dropdownValues}
            selectedOption={selectedOptions}
            setSelectedOption={(e) => handleOnChange(e)}
            labelClasses={'text-left'}
            inputClasses={'bg-white'}
            defaultValue={undefined}
            multiple={true}
            fieldName={'Options'}
            disabled
          />
        </div>
      </div>
    )
  }

  return (
    <>
        <div>Demand Notes</div>
        <div className='flex flex-col gap-y-3 px-4'>
          {renderMultiSelectDropdown(
            DROPDOWN_TITLES[0],
            dropdownData.unitInterests,
            (e) => setSelectedUnitInterests(e),
            selectedUnitInterests
          )}
          {renderSingleSelect(
            DROPDOWN_TITLES[1],
            dropdownData.budgetOptions,
            (e) => setSelectedBudgetOption(e),
            selectedBudgetOption
          )}
          {renderSingleSelect(
            DROPDOWN_TITLES[2],
            dropdownData.timelineToPurchase,
            (e) => setSelectedtimelineToPurchase(e),
            selectedtimelineToPurchase
          )}
          {renderMultiSelectDropdown(
            DROPDOWN_TITLES[3],
            dropdownData.projectInterests,
            (e) => setSelectedProjectInterests(e),
            selectedProjectInterests
          )}
          {renderMultiSelectDropdown(
            DROPDOWN_TITLES[4],
            dropdownData.floorInterests,
            (e) => setSelectedFloorInterests(e),
            selectedFloorInterests
          )}
          {renderSingleSelect(
            DROPDOWN_TITLES[5],
            dropdownData.needFinancing,
            (e) => setSelectedNeedsFinancing(e),
            selectedNeedsFinancing
          )}
        </div>
    </>
  )
}

export default DemandNotesForm
