import React from 'react'

const Attribute = ({ label, Icon }) => {
  return (
    <div className='flex items-center gap-2'>
      <Icon />
      <p className='my-0 text-softBlack text-base'>{label}</p>
    </div>
  )
}

export default Attribute
