import { useCallback, useEffect } from 'react'
import {
  bindChannel,
  subscribeToChannel,
  unbindChannel,
  unsubscribeToChannel
} from 'services/pusher'
import { EventEmitter } from 'events'

let unitEventEmitter = null
export const getUnitEventEmitter = () => {
  if (!unitEventEmitter) {
    unitEventEmitter = new EventEmitter()
  }
  return unitEventEmitter
}

const useRealtimeUnitUpdate = (projectId) => {
  const onNewMessagesCallback = useCallback(
    (newMessage) => {
      if (unitEventEmitter) {
        if (Object.keys(newMessage?.data ?? {})?.length > 0) {
          unitEventEmitter.emit('unit-update', newMessage.data)
        }
      }
    },
    [unitEventEmitter]
  )

  useEffect(() => {
    let smsChannel = null
    const channelName = `units-${projectId}`
    smsChannel = subscribeToChannel(channelName)
    if (smsChannel) {
      bindChannel(smsChannel, onNewMessagesCallback)
    }
    return () => {
      if (smsChannel) {
        unbindChannel(smsChannel, onNewMessagesCallback)
      }
      unsubscribeToChannel(channelName)
    }
  }, [onNewMessagesCallback])

  return {}
}

export default useRealtimeUnitUpdate
