import VerifyBuyerButton from 'components/VerifyBuyerButton'
import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { BUYER_HOME_DASHBOARD } from 'routes'

const BuyerHomeComponent = () => {
  const history = useHistory()
  return (
    <div className='w-full'>
      <img
        src='/buyer-dashboard.png'
        className='h-screen w-full object-cover'
      />
      <div className='inset-0 flex flex-col items-center justify-center absolute'>
        <div className='mb-[81px]'>
          <img src='/logo.png' />
        </div>
        <div className='text-[64px] mb-[30px] text-[#EFA581] font-bold'>
          The Pacifica
        </div>
        <div className='text-center font-bold text-[45px] text-white'>
        889 Residences. <br />
        One Amazing Community.
        </div>
        <div className='mt-[40px] xl:mt-[80px]'>
          <button onClick={ () => {
            history.push(BUYER_HOME_DASHBOARD)
          }} className='py-[21px] px-[61px] bg-[#E8CB78] rounded-[7px] text-[#2E2B2E] text-[29px] font-bold'>Enter Buyer Portal</button>
        </div>
        <VerifyBuyerButton />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  userObject: state.authReducer.userObject
})
const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(BuyerHomeComponent)
