/* eslint-disable */
// @ts-nocheck
import React from 'react';
import InventoryItem from './InventoryItem';

const TableIndex = ({ title }) => {
  return (
    <div className='flex bg-gray-2 min-w-20 w-20 items-center justify-center uppercase text-[#A1A1AA] font-medium text-sm'>
      {title}
    </div>
  );
};

const FloorItem = ({
  heatMapData,
  data,
  floorNUmber,
  availabilityFilter,
  onSelect,
  isAllocateInventory = false,
  type = '',
}) => {
  const isDisabled = (availabilityStatus) => {
    return (
      availabilityFilter.length > 0 &&
      availabilityFilter.indexOf(availabilityStatus) === -1
    );
  };

  return (
    <div
      style={{
        paddingLeft: type === 'Parking' ? '32px' : '0px',
      }}
      className='flex gap-8 w-full pr-8'
    >
      {type !== 'Parking' && <TableIndex title={floorNUmber} />}
      <div
        className={'relative flex flex-wrap gap-x-8'}
        style={{ width: 'calc(100% - 80px)' }}
      >
        {data.length > 0 &&
          data.map((itemValue, index) => {
            return (
              <InventoryItem
                heatMapData={heatMapData}
                key={index}
                data={itemValue}
                isDisabled={isDisabled(itemValue.status)}
                onSelect={onSelect}
                isAllocateInventory={isAllocateInventory}
                type={type}
              />
            );
          })}
      </div>
    </div>
  );
};

export default FloorItem;
