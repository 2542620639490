import { useState } from 'react'
import { getLeadsCountByTime } from 'store/actions/usersActions'

const useGetLeadsChartData = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  const fetchCountData = async (time) => {
    try {
      setLoading(true)
      const response = await getLeadsCountByTime(time)
      setData(response)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  return [fetchCountData, data, loading]
}

export default useGetLeadsChartData
