import Tag from 'components/Tags'
import React from 'react'
import { formatMoney } from 'utils/formatters'

const Row = ({ id, data }) => {
  return (
    <tr className="group">
      <td className='text-softBlack text-base font-medium font-openSans py-3'>
        {id + 1}
      </td>
      <td className='text-softBlack text-base font-medium font-openSans py-3'>{data.name}</td>
      <td className='text-softBlack text-base font-medium font-openSans py-3'>{data.company}</td>
      <td className='text-softBlack text-base font-medium font-openSans py-3'>{formatMoney(data.amount)}</td>
      <td className='py-3'><Tag label={data.score} classes='text-softBlack text-xs font-medium font-openSans' type={data.score === 'hot' ? 'Reserved' : 'Grey'}/></td>
    </tr>
  )
}

export default Row
