import styled from 'styled-components'
import { Card } from 'antd'
import standardStyle from 'assets/css/standardStyle'

export const CustomCard = styled(Card)`
  background: white;
  border-radius:4px;
  transition: background .2s;
  cursor: ${props => props.onClick ? 'pointer' : 'inherit'};

  /* &:hover {
    background: ${props => props.onClick ? '#d5ecf3' : '#efefef'};

    .ant-card-head {
      border-bottom-color: #c1e1eb;
    }
  } */

  & + .ant-card {
    margin-top:10px;
  }

  .ant-card-body {

    @media ${standardStyle.mediaqueries.xs}{
      padding:13px;
    }

    @media ${standardStyle.mediaqueries.sm}{
      padding:16px;
    }

    & > *:last-child {
      margin-bottom:0;
      padding-bottom:0;
    }

  }

  .ant-card-head {

    @media ${standardStyle.mediaqueries.xs}{
      padding:0 13px;
    }

    @media ${standardStyle.mediaqueries.sm}{
      padding:0 16px;
    }

  }
`
