// @ts-nocheck
/* eslint-disable */
import React, { useCallback, useEffect, useContext, useState } from 'react';
import './styles/style.css';
import AddToListModal from 'components/TailwindTable/components/AddToListModal';
import ContactListCreateModal from 'components/TailwindTable/components/ContactListCreateModal';
import useGetLeadBrokers from 'hooks/useGetLeadBrokers';
import useCreateContactList from 'hooks/useCreateContactList';
import useGetSalesReps from 'hooks/useGetSalesReps';
import useBrokerageCompanies from 'hooks/useBrokerageCompanies';
import useGetReferralAgent from 'hooks/useGetReferralAgent';
import useLeadSource from 'hooks/useLeadSource';
import useLeadStatus from 'hooks/useLeadStatus';
import useBuyerScore from 'hooks/useBuyerScore';
import useBrokerScore from 'hooks/useBrokerScore';
import useReasonForBuyerLost from 'hooks/useReasonForBuyerLost';
import useLeadApproachForBuyer from 'hooks/useLeadApproachForBuyer';
import ContactTabHeader from 'components/ContactTabHeader';
import { ReactComponent as ActivityIcon } from '../../assets/icons/icn_ballot.svg';
import NameCard from './components/NameCard';
import ContactDetailsGridView from './components/ContactDetailsGridView';
import useUpdateUsers from 'hooks/useUpdateUsers';
import { ContactContext } from 'pages/contacts/context/Contacts';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import {
  updateConnections,
  updateLeadSource,
  updateLeadStatus,
  updateSalesRep,
} from 'store/actions/usersActions';
import SelectionsView from './components/SelectionsView';
import DemandSelectionsView from './components/DemandSelectionsView';
import ListsView from './components/ListsView';
import useContactTypes from 'hooks/useContactTypes';

const demoData = [
  '1818 Pacifica',
  '$923,000',
  'Master Suite Upgrade',
  'Parking Spot Included',
  'Rent-to-own Program',
];

export const VerticalCardContact = ({
  contact,
  isNegotiation = false,
  setSelectedTabHeader = () => {},
  isShowQuickActionButton = false,
}) => {
  if (!contact) return null;
  const {
    backgroundImage,
    firstName,
    lastName,
    userType,
    buyerData,
    buyerEmail,
    email,
    _id,
    leadBrokerData,
  } = contact;
  const { createOrUpdateUser: createOrUpdateUserAPI } = useUpdateUsers();
  const { updateContact } = useContext(ContactContext);
  const userObject = useSelector((state) => state.authReducer.userObject);
  const projectId = useSelector((state) => state.appReducer.appProject);
  const [broker, setBroker] = useState(null);
  const [rating, setRating] = useState(null);
  const [referralAgent, setReferralAgent] = useState(null);
  const { createSaveList: createContactList, loading } = useCreateContactList();
  const { salesReps, refetch } = useGetSalesReps();
  const { companies } = useBrokerageCompanies();
  const isLeadSourceAvailable = userType === 'Buyer';
  const isReasonForBuyerLostAvailable = userType === 'Buyer';
  const isBuyerLeadApproachAvailable = userType === 'Buyer';
  const { data: brokers, refetchBrokers } =
    userType === 'Lead' || userType === 'Buyer'
      ? useGetLeadBrokers()
      : { data: null, refetchBrokers: (value) => {} };
  const { data: referralAgents, refetch: refetchReferralAgents } =
    useGetReferralAgent();
  const { formattedLeadsources: leadSources } = useLeadSource();
  const { formattedReasonForBuyerLost: reasonsForBuyerLost } =
    useReasonForBuyerLost();
  const { formattedLeadApproachesForBuyer: leadApproachesForBuyer } =
    useLeadApproachForBuyer();
  const { formattedLeadStatuses: leadStatuses } = useLeadStatus();
  const { formattedScores: scores } =
    userType === 'Buyer' ? useBuyerScore() : useBrokerScore();
  const [selectedLeadSource, setSelectedLeadSource] = useState(
    isLeadSourceAvailable ? buyerData?.leadSource : null
  );
  const [selectedReasonForBuyerLost, setSelectedReasonForBuyerLost] = useState(
    isReasonForBuyerLostAvailable ? buyerData?.reasonForLost : null
  );
  const [selectedLeadApproachForBuyer, setSelectedLeadApproachForBuyer] =
    useState(isBuyerLeadApproachAvailable ? buyerData?.leadApproach : null);
  const [salesRep, setSalesRep] = useState();
  const [openAddToList, setOpenAddToList] = useState(false);
  const [openCreateList, setOpenCreateList] = useState(false);
  const [brokerageCompany, setBrokerageCompany] = useState(null);
  const [selectedHeader, setSelectedHeader] = useState('Lead');
  const [selectedConnections, setSelectedConnections] = useState([]);
  const {
    contactTypeFormattedData
  } = useContactTypes()

  const salesRepChange = (type, params) => {
    const temp = salesReps?.find?.((rep) => rep?._id === params?.salesRep);
    setSalesRep(temp);
    handleSalesRepChange(type, params);
  };

  useEffect(() => {
    if (!brokers?.length) refetchBrokers();
    if (!referralAgents?.length) refetchReferralAgents();
  }, []);

  useEffect(() => {
    if (!salesReps?.length) refetch();
  }, [salesReps]);

  useEffect(() => {
    if (leadBrokerData?.brokerageCompany) {
      setBrokerageCompany(leadBrokerData.brokerageCompany);
    }
  }, [leadBrokerData]);

  useEffect(() => {
    const rep = salesReps?.find?.(
      (rep) => rep?._id === buyerData?.assignments?.[0]?.salesRep
    );
    setSalesRep(rep);
    if (!rep) {
      const assign = leadBrokerData?.assignments?.[0]?.salesRep?._id;
      const rep = salesReps.find((obj) => obj?._id === assign);
      setSalesRep(rep);
    }
  }, [buyerData, salesReps]);

  useEffect(() => {
    if (brokers?.length > 0) {
      let brokerFoundTemp = false;
      for (let i = 0; i < brokers.length; i++) {
        const brokerTemp = brokers[i];
        if (brokerTemp._id === broker) {
          brokerFoundTemp = true;
          break;
        }
      }
      if (!brokerFoundTemp) {
        setBroker('');
      }
    }
  }, [brokers]);

  const getTabHeaders = useCallback(() => {
    const headers = [
      {
        key: 'lead',
        title: 'Lead',
        Icon: ActivityIcon,
      },
      {
        key: 'demand',
        title: 'Demand',
        Icon: ActivityIcon,
      },
      {
        key: 'lists',
        title: 'Lists',
        Icon: ActivityIcon,
      },
    ];
    return headers;
  }, [buyerData]);

  const createOrUpdateUser = useCallback(
    (
      params,
      showUpdateMessage = true,
      updateLoggedUserObjectAfterSave = false
    ) => {
      if (
        !params.buyerData.referralAgent ||
        params.buyerData.referralAgent?.length === 0
      ) {
        delete params.buyerData.referralAgent;
      }
      createOrUpdateUserAPI(
        params,
        showUpdateMessage,
        updateLoggedUserObjectAfterSave
      );
    },
    [createOrUpdateUserAPI]
  );

  const handleBrokerChange = async (value) => {
    if (!value) return;
    setBroker(value);
    const prevValue = { ...contact };
    const newUserData = { ...contact };
    if (newUserData?.buyerData?.assignments?.[0]) {
      newUserData.buyerData.assignments[0].leadBroker = value;
    }
    try {
      updateContact(newUserData);
      await createOrUpdateUser(newUserData, false, false);
    } catch (err) {
      updateContact(prevValue);
      console.log(err);
    }
  };

  const handleReferralAgentChange = async (value) => {
    if (!value) return;
    setReferralAgent(value);
    const prevValue = { ...contact };
    const newUserData = { ...contact };
    newUserData.buyerData.referralAgent = value;
    try {
      updateContact(newUserData);
      await createOrUpdateUser(newUserData, false, false);
    } catch (err) {
      updateContact(prevValue);
      console.log(err);
    }
  };

  useEffect(() => {
    if (contact?.userType === 'Buyer') {
      setBroker(contact?.buyerData?.assignments?.[0]?.leadBroker);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact?.buyerData?.assignments?.[0]?.leadBroker]);

  useEffect(() => {
    if (contact?.userType === 'Buyer') {
      setReferralAgent(contact?.buyerData?.referralAgent);
    }
  }, [contact?.buyerData?.referralAgent]);

  const handleRatingChange = async (value) => {
    const toastId = toast.loading('loading...');
    const prevValue = { ...contact };
    const newUserData = { ...contact };
    if (contact?.userType === 'LeadBroker') {
      if (Object.keys(newUserData?.leadBrokerData)?.length) {
        newUserData.leadBrokerData.rating = value;
      }
    } else {
      if (Object.keys(newUserData?.buyerData)?.length) {
        newUserData.buyerData.rating = value;
      }
    }

    try {
      updateContact(newUserData);
      await createOrUpdateUser(newUserData, false, false);
      setRating(value);
      toast.dismiss(toastId);
      toast.success('Rating updated successfully');
    } catch (err) {
      toast.dismiss(toastId);
      updateContact(prevValue);
      console.error("Rating couldn't be updated", err);
    }
  };

  const handleBrokerageCompanyChange = async (company) => {
    const toastId = toast.loading('loading...');
    const prevValue = { ...contact };
    const newUserData = { ...contact };
    if (newUserData.leadBrokerData) {
      newUserData.leadBrokerData.brokerageCompany = company;
    } else {
      newUserData.leadBrokerData = { brokerageCompany: company };
    }

    try {
      updateContact(newUserData);
      await createOrUpdateUser(newUserData, false, false);
      toast.dismiss(toastId);
      toast.success('Brokerage company updated successfully');
    } catch (err) {
      toast.dismiss(toastId);
      updateContact(prevValue);
      console.error("Brokerage company couldn't be updated", err);
    }
  };

  const handleLeadStatusChange = async (type) => {
    const prevValue = { ...contact };
    const newUserData = { ...contact };
    if (contact?.userType === 'Buyer') {
      if (Object.keys(newUserData?.buyerData).length) {
        newUserData.buyerData.leadStatus = type;
      }
    } else {
      if (Object.keys(newUserData?.leadBrokerData).length) {
        newUserData.leadBrokerData.leadStatus = type;
      }
    }
    try {
      updateContact(newUserData);
      await updateLeadStatus(type, contact._id);
    } catch (err) {
      updateContact(prevValue);
      console.log(err);
    }
  };

  const handleConnectionChange = async (value) => {
    const prevValue = { ...contact };
    const newUserData = { ...contact };
    if (Object.keys(newUserData?.buyerData).length) {
      newUserData.buyerData.connections = value;
    }
    try {
      updateContact(newUserData);
      await updateConnections(value, contact._id);
    } catch (err) {
      updateContact(prevValue);
      console.log(err);
    }
  };

  const handleLeadSourceChange = async (leadSource) => {
    const prevValue = { ...contact };
    const newUserData = { ...contact };
    if (Object.keys(newUserData?.buyerData).length) {
      newUserData.buyerData.leadSource = leadSource;
    }

    try {
      updateContact(newUserData);
      await updateLeadSource(leadSource, contact._id);
    } catch (err) {
      updateContact(prevValue);
      console.log(err);
    }
  };

  const handleReasonForBuyerLostChange = async (reasonForLost) => {
    const toastId = toast.loading('updating Reason for lost...');
    const prevValue = { ...contact };
    const newUserData = { ...contact };
    if (newUserData.buyerData) {
      newUserData.buyerData.reasonForLost = reasonForLost;
    } else {
      newUserData.buyerData = { reasonForLost: reasonForLost };
    }
    try {
      updateContact(newUserData);
      await createOrUpdateUser(newUserData, false, false);
      toast.dismiss(toastId);
      toast.success('Reason for lost updated successfully');
    } catch (err) {
      toast.dismiss(toastId);
      updateContact(prevValue);
      console.error("Reason for lost couldn't be updated", err);
    }
  };

  const handleBuyerLeadApproachChange = async (leadApproach) => {
    const toastId = toast.loading('updating lead approach...');
    const prevValue = { ...contact };
    const newUserData = { ...contact };
    if (newUserData.buyerData) {
      newUserData.buyerData.leadApproach = leadApproach;
    } else {
      newUserData.buyerData = { leadApproach: leadApproach };
    }
    try {
      updateContact(newUserData);
      await createOrUpdateUser(newUserData, false, false);
      toast.dismiss(toastId);
      toast.success('Lead approach updated successfully');
    } catch (err) {
      toast.dismiss(toastId);
      updateContact(prevValue);
      console.error("Lead approach couldn't be updated", err);
    }
  };

  const handleContactTypeChange = async (contactType) => {
    const toastId = toast.loading('updating contact type...');
    const prevValue = { ...contact };
    const newUserData = { ...contact };
    if (newUserData.buyerData) {
      newUserData.buyerData.contactType = contactType;
    } else {
      newUserData.buyerData = { contactType: contactType };
    }
    try {
      updateContact(newUserData);
      await createOrUpdateUser(newUserData, false, false);
      toast.dismiss(toastId);
      toast.success('Contact type updated successfully');
    } catch (err) {
      toast.dismiss(toastId);
      updateContact(prevValue);
      console.log(err);
    }
  }

  const handleSalesRepChange = async (type, params) => {
    const toastId = toast.loading('loading...');
    try {
      await updateSalesRep({
        salesRep: params.salesRep,
        contactId: contact?._id,
      });
      toast.dismiss(toastId);
      toast.success('SalesRep updated successfully');
    } catch (error) {
      toast.dismiss(toastId);
      console.error("SalesRep couldn't be updated", error);
    }
  };

  useEffect(() => {
    if (contact?.userType === 'Buyer') {
      setBroker(contact?.buyerData?.assignments?.[0]?.leadBroker);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact?.buyerData?.assignments?.[0]?.leadBroker]);

  useEffect(() => {
    setRating(
      contact?.userType === 'LeadBroker'
        ? contact?.leadBrokerData?.rating
        : contact?.buyerData?.rating
    );
  }, [contact?.userType]);

  return (
    <div className='w-full md:w-[350px] relative border-r h-full bg-white overflow-y-auto thin-scrollbar'>
      <ContactListCreateModal
        open={openCreateList}
        setOpen={setOpenCreateList}
        createContactList={createContactList}
        savingContacts={loading}
        showBackButtonToAddList
        setAddToListOpen={setOpenAddToList}
      />
      <AddToListModal
        open={openAddToList}
        setOpen={setOpenAddToList}
        selectedContactId={_id}
        showCreateListButton
        setOpenCreateListModal={setOpenCreateList}
      />
      <div className='h-full flex flex-col'>
        <NameCard
          isShowQuickActionButton={isShowQuickActionButton}
          setSelectedTabHeader={setSelectedTabHeader}
          contact={contact}
          backgroundImage={backgroundImage ?? ''}
          firstName={firstName}
          lastName={lastName}
          buyerEmail={email}
          userType={userType}
          rating={rating ?? 'Unrated'}
        />
        <ContactDetailsGridView
          rating={rating ?? 'Unrated'}
          userType={userType}
          salesRep={salesRep}
          brokerId={buyerData?.assignments?.[0]?.leadBroker ?? ''}
        />
        {!isNegotiation && (
          <>
            <div className='border-b border-softBlack_70/30'>
              <ContactTabHeader
                headers={getTabHeaders()}
                handleSelectHeader={(header) => setSelectedHeader(header)}
                selectedHeader={selectedHeader}
              />
            </div>
            {selectedHeader === 'Lead' && (
              <SelectionsView
                contact={contact}
                userType={userType}
                handleLeadStatusChange={handleLeadStatusChange}
                leadStatuses={leadStatuses}
                rating={rating}
                handleRatingChange={handleRatingChange}
                scores={scores}
                salesRep={salesRep}
                handleConnectionChange={handleConnectionChange}
                salesRepChange={salesRepChange}
                salesReps={salesReps}
                userObject={userObject}
                projectId={projectId}
                buyerEmail={buyerEmail}
                isLeadSourceAvailable={isLeadSourceAvailable}
                selectedLeadSource={selectedLeadSource}
                setSelectedLeadSource={setSelectedLeadSource}
                handleLeadSourceChange={handleLeadSourceChange}
                leadSources={leadSources}
                brokerageCompany={brokerageCompany}
                setBrokerageCompany={setBrokerageCompany}
                handleBrokerageCompanyChange={handleBrokerageCompanyChange}
                companies={companies}
                referralAgent={referralAgent}
                handleReferralAgentChange={handleReferralAgentChange}
                referralAgents={referralAgents}
                broker={broker}
                handleBrokerChange={handleBrokerChange}
                brokers={brokers}
                isReasonForBuyerLostAvailable={isReasonForBuyerLostAvailable}
                selectedReasonForBuyerLost={selectedReasonForBuyerLost}
                setSelectedReasonForBuyerLost={setSelectedReasonForBuyerLost}
                handleReasonForBuyerLostChange={handleReasonForBuyerLostChange}
                reasonsForBuyerLost={reasonsForBuyerLost}
                isBuyerLeadApproachAvailable={isBuyerLeadApproachAvailable}
                selectedLeadApproachForBuyer={selectedLeadApproachForBuyer}
                setSelectedLeadApproachForBuyer={
                  setSelectedLeadApproachForBuyer
                }
                handleBuyerLeadApproachChange={handleBuyerLeadApproachChange}
                leadApproachesForBuyer={leadApproachesForBuyer}
                selectedConnections={selectedConnections}
                contactTypes={contactTypeFormattedData}
                handleContactTypeChange={handleContactTypeChange}
              />
            )}
            {selectedHeader === 'Demand' && (
              <DemandSelectionsView contact={contact} />
            )}
            {selectedHeader === 'Lists' && <ListsView contact={contact} />}
          </>
        )}
        {isNegotiation && (
          <div className='border-t-2 border-softBlack_70/100'>
            <div className='w-full p-4 py-1  border-b-2 border-softBlack_70/100'>
              <img className='w-full' src='/unit-1201.png' />
            </div>
            <div className='py-6 px-4'>
              <p className='w-fit uppercase italic bg-volt p-1 rounded font-light text-xs'>
                sold-firm
              </p>
              <h4 className='text-2xl font-semibold'>Unit #1201</h4>
              <p className='text-3xl font-normal underline mb-4'>$923,000</p>
              <div className='flex flex-col gap-y-2'>
                {demoData.map((elm) => (
                  <div key={elm} className='flex gap-2'>
                    <img className='h-6 w-6' src='/residence-icon.png' />
                    <p className='text-base font-normal m-0'>{elm}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
