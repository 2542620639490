/* eslint-disable */
// @ts-nocheck
import React, { memo, useState } from 'react'
import TemplateBuilder from '../components/TemplateBuilder'
import { createOrUpdateContractTemplate, createOrUpdateDocuSealContractTemplate } from 'store/actions/contractTemplateActions'
import { useParams, useHistory } from 'react-router-dom'
import { CONTRACT_TEMPLATES } from 'routes'
import { PageHeader } from 'components'
import CustomButton from 'components/CustomButton'
import { v4 as uuidv4 } from 'uuid'
import { PageLoader } from '..'

const RightContent = memo(({ action = () => { } }) => (
  <div className='flex flex-row items-center gap-2'>
    <CustomButton handleClick={action} disabled={false}>
      <span className='font-medium py-2 px-4 text-base flex gap-2 items-center'>
        View all contract template
      </span>
    </CustomButton>
  </div>
))

const NewContractTemplate = () => {
  const { token, contractTemplateId, contractType } = useParams()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)

  const handleTemplateSave = (data) => {
    setIsLoading(true)
    createOrUpdateDocuSealContractTemplate(data)
      .then(() => {
        history.push(`/admin/contract_templates/${data.id}`)
        setIsLoading(false)
      })
      .catch((error) => {
        console.error('Error:', error)
        setIsLoading(false)
      })
  }

  const handleOnLoad = (data) => {
    setIsLoading(true)
    const params = {
      _id: contractTemplateId,
      templateNumber: data.id
    }
    createOrUpdateDocuSealContractTemplate({
      id: data.id,
      submitters: contractType === 'reservation'
        ? [
          {
            name: 'primaryBuyer',
            uuid: uuidv4()
          },
          {
            name: 'secondaryBuyer',
            uuid: uuidv4()
          },
          {
            name: 'Developer Admin',
            uuid: uuidv4()
          }
        ]
        : [
          {
            name: 'Buyer',
            uuid: uuidv4()
          },
          {
            name: 'Developer Admin',
            uuid: uuidv4()
          }
        ]
    })
      .then(() => {
        createOrUpdateContractTemplate(params)
          .then((res) => {
            if (res?.templateNumber?.length > 0) {
              history.push(`/admin/contract_templates/${res.templateNumber}?type=edit`)
            }
            setIsLoading(false)
          })
          .catch((error) => {
            console.error(error)
            setIsLoading(false)
          })
      })
      .catch((error) => {
        console.error(error)
        setIsLoading(false)
      })
  }

  return (
    <div>
      <PageHeader
        className='md:h-[74px]'
        title='Contract Template'
        showImage={false}
        rightContent={
          <RightContent
            action={() => history.push(CONTRACT_TEMPLATES)}
          />
        }
      />
      {
        isLoading
          ? <PageLoader />
          : <>
            {
              token?.length > 0 &&
              <div
                key={Math.random()}
                className='h-full bg-slate-50 rounded-box border border-slate-300 border-1 overflow-y-hidden'
              >
                <TemplateBuilder
                  templateType='new'
                  autosave={false}
                  withSendButton={false}
                  withSignYourselfButton={false}
                  token={token}
                  onSave={handleTemplateSave}
                  onLoad={handleOnLoad}
                  style={{ height: 'calc(-138px + 100vh)', display: 'block', backgroundColor: 'white' }}
                />
              </div>
            }
          </>
      }
    </div>
  )
}

export default NewContractTemplate
