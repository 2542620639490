/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import React, { useCallback } from 'react'
import propTypes from 'prop-types'
import { useHistory, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { MenuItem } from './MenuItem'

import {
  MarketIcon, BuildingIcon, QuestionIcon
} from 'components'
import { ADMIN_USERS, ADMIN_DASHBOARD, ADMIN_PROJECT_MANAGEMENT, SALES_PIPELINE, ADMIN_CONTACTS, AVAILABILITY_BOARD, ADMIN_OFFERS, PAGE_UNDER_CONSTRUCTION, EMAIL_INBOX, CALENDAR, CONTRACT_TEMPLATES, UNITS_INVENTORY, ADMIN_CREATE_INVENTORY_ALLOCATION, ADMIN_OPPORTUNITIES, ADMIN_DEPOSIT_PAYMENT } from 'routes'
import { changeUserSelectedTab, changeProjectManagementSelectedTab } from 'store/actions/appActions'

import { H5 } from 'ui'

import {
  ChartSquareBarIcon, ColorSwatchIcon, DocumentTextIcon, InboxInIcon,
  UserGroupIcon, UserIcon, BellIcon, ChartPieIcon,
  CashIcon, CurrencyDollarIcon, CogIcon, ViewBoardsIcon,
  CollectionIcon, ViewGridIcon, DocumentReportIcon, CalendarIcon,
  DocumentDuplicateIcon
} from '@heroicons/react/outline'

const SubMenuItem = ({ title, onClick, selected }) => (
    <button
      onClick={onClick}
      className={`group w-full flex items-center text-left pl-11 pr-2 py-2 text-base font-medium rounded-lg
      ${selected
        ? 'bg-menuGray text-softBlack hover:bg-menuGray'
        : 'bg-transparent text-softBlack_70 hover:bg-menuGray hover:text-softBlack'
      }
      focus:outline-none focus:ring-2 focus:ring-indigo-500 filter-none-important spacing-[-0.24px]`}
    >
      {title}
    </button>
)

const AdminMenus = (props) => {
  const {
    userObject: { userType },
    changeUserSelectedTab,
    changeProjectManagementSelectedTab,
    executeAfterSelect,
    selectedMenuId, setSelectedMenuId,
    selectedSubMenuId, setSelectedSubMenuId,
    expandedMenus, setExpandedMenus
  } = props

  const history = useHistory()

  const goToUserPageAndSetTab = useCallback((selectedTab) => {
    changeUserSelectedTab(selectedTab)
    history.push(ADMIN_USERS)
  }, [changeUserSelectedTab, history])

  const goToProjectManagementPageAndSetTab = useCallback((selectedTab) => {
    changeProjectManagementSelectedTab(selectedTab)
    history.push(ADMIN_PROJECT_MANAGEMENT)
  }, [changeProjectManagementSelectedTab, history])

  const gotoSalesPipelinePage = useCallback(() => {
    history.push(SALES_PIPELINE)
  }, [history])

  const goToAvailabilityBoard = useCallback((showTabsFilter) => {
    history.push({
      pathname: AVAILABILITY_BOARD,
      state: { showTabsFilter }
    })
  }, [history])

  // const menuIcon = ({ Icon, selected }) =>
  //   <Icon className={`
  //   ${selected
  //     ? 'text-softBlack'
  //     : 'text-softBlack_70 group-hover:text-gray-500 hover:text-gray-900'
  //   } mr-3.5 h-6 w-6`}
  //   />

  const createMenus = useCallback(() => {
    switch (userType) {
      case 'SuperAdmin':
        return [
          {
            key: 'dashboard',
            title: 'Dashboard',
            icon: ChartPieIcon,
            onClick: () => history.push(ADMIN_DASHBOARD),
            hasSubMenus: false
          },
          {
            key: 'users',
            title: 'Users',
            icon: UserIcon,
            hasSubMenus: true,
            subMenus: [
              {
                key: 'buyers',
                title: 'Buyers',
                onClick: () => goToUserPageAndSetTab('Buyer'),
                hasSubMenus: false
              },
              {
                key: 'superAdmins',
                title: 'Super Admins',
                onClick: () => goToUserPageAndSetTab('SuperAdmin'),
                hasSubMenus: false
              },
              {
                key: 'developers',
                title: 'Developers',
                onClick: () => goToUserPageAndSetTab('Developer'),
                hasSubMenus: false
              },
              {
                key: 'developerAdmins',
                title: 'Developer Admins',
                onClick: () => goToUserPageAndSetTab('DeveloperAdmin'),
                hasSubMenus: false
              },
              {
                key: 'salesReps',
                title: 'Sales Reps',
                onClick: () => goToUserPageAndSetTab('SalesRep'),
                hasSubMenus: false
              },
              {
                key: 'coopBrokers',
                title: 'Coop Brookers',
                onClick: () => goToUserPageAndSetTab('CoopBroker'),
                hasSubMenus: false
              }
            ]
          },
          {
            key: 'projectManagement',
            title: 'Project Management',
            icon: DocumentReportIcon,
            hasSubMenus: true,
            subMenus: [
              {
                key: 'projects',
                title: 'Projects',
                onClick: () => goToProjectManagementPageAndSetTab('Projects'),
                hasSubMenus: false
              },
              {
                key: 'buildings',
                title: 'Buildings',
                onClick: () => goToProjectManagementPageAndSetTab('Buildings'),
                hasSubMenus: false
              },
              {
                key: 'floorPlans',
                title: 'Floor Plans',
                onClick: () => goToProjectManagementPageAndSetTab('FloorPlans'),
                hasSubMenus: false
              },
              {
                key: 'unitGroups',
                title: 'Unit Groups',
                onClick: () => goToProjectManagementPageAndSetTab('UnitGroups'),
                hasSubMenus: false
              },
              {
                key: 'units',
                title: 'Units',
                onClick: () => goToProjectManagementPageAndSetTab('Units'),
                hasSubMenus: false
              }
            ]
          }
        ]

      case 'DeveloperAdmin':
        return [
          {
            key: 'dashboard',
            title: 'Dashboard',
            icon: ChartPieIcon,
            onClick: () => history.push(ADMIN_DASHBOARD),
            hasSubMenus: false
          },
          {
            key: 'offers',
            title: 'Offers',
            icon: DocumentTextIcon,
            onClick: () => history.push(ADMIN_OFFERS),
            hasSubMenus: false
          },
          {
            key: ADMIN_DEPOSIT_PAYMENT,
            title: 'Deposits',
            icon: DocumentTextIcon,
            onClick: () => history.push(ADMIN_DEPOSIT_PAYMENT),
            hasSubMenus: false
          },
          {
            key: 'opportunities',
            title: 'Opportunities',
            icon: DocumentTextIcon,
            onClick: () => history.push(ADMIN_OPPORTUNITIES),
            hasSubMenus: false
          },
          {
            key: 'inbox',
            title: 'Email Inbox',
            icon: InboxInIcon,
            onClick: () => history.push(EMAIL_INBOX),
            hasSubMenus: false
          },
          {
            key: 'calendar',
            title: 'Calendar',
            icon: CalendarIcon,
            onClick: () => history.push(CALENDAR),
            hasSubMenus: false
          },
          {
            key: 'contracTemplates',
            title: 'Contract Templates',
            icon: DocumentDuplicateIcon,
            onClick: () => history.push(CONTRACT_TEMPLATES),
            hasSubMenus: false
          },
          {
            key: 'team',
            title: 'Team',
            icon: UserGroupIcon,
            hasSubMenus: true,
            subMenus: [
              {
                key: 'salesReps',
                title: 'Sales Reps',
                onClick: () => goToUserPageAndSetTab('SalesRep'),
                hasSubMenus: false
              },
              {
                key: 'coopBrokers',
                title: 'Coop Brookers',
                onClick: () => goToUserPageAndSetTab('CoopBroker'),
                hasSubMenus: false
              }
            ]
          },
          {
            key: 'projectManagement',
            title: 'Project Management',
            icon: ColorSwatchIcon,
            hasSubMenus: true,
            subMenus: [
              {
                key: 'projects',
                title: 'Projects',
                onClick: () => goToProjectManagementPageAndSetTab('Projects'),
                hasSubMenus: false
              },
              {
                key: 'buildings',
                title: 'Buildings',
                onClick: () => goToProjectManagementPageAndSetTab('Buildings'),
                hasSubMenus: false
              },
              {
                key: 'floorPlans',
                title: 'Floor Plans',
                onClick: () => goToProjectManagementPageAndSetTab('FloorPlans'),
                hasSubMenus: false
              },
              {
                key: 'unitGroups',
                title: 'Unit Groups',
                onClick: () => goToProjectManagementPageAndSetTab('UnitGroups'),
                hasSubMenus: false
              },
              {
                key: 'units',
                title: 'Units',
                onClick: () => goToProjectManagementPageAndSetTab('Units'),
                hasSubMenus: false
              }
            ]
          },
          {
            key: 'inventory',
            title: 'Inventory',
            icon: CollectionIcon,
            onClick: () => {},
            hasSubMenus: true,
            subMenus: [
              {
                key: 'units_inventory',
                title: 'Units Inventory',
                onClick: () => history.push(UNITS_INVENTORY),
                hasSubMenus: false
              },
              {
                key: 'allocate_inventory',
                title: 'Allocate Inventory',
                onClick: () => history.push(ADMIN_CREATE_INVENTORY_ALLOCATION),
                hasSubMenus: false
              }
            ]
          },
          {
            key: 'salesPipeline',
            title: 'Sales Pipeline',
            icon: ViewBoardsIcon,
            onClick: () => {},
            hasSubMenus: true,
            subMenus: [
              {
                key: 'individualTransaction',
                title: 'Individual Transaction',
                onClick: () => history.push(PAGE_UNDER_CONSTRUCTION),
                hasSubMenus: false
              }
            ]
          },
          {
            key: 'contacts',
            title: 'Contacts',
            icon: UserIcon,
            onClick: () => history.push(ADMIN_CONTACTS),
            hasSubMenus: false
          }
        ]

      case 'CoopBroker':
        return [
          {
            key: 'availability_board',
            title: 'Availability Board',
            icon: ViewGridIcon,
            onClick: () => goToAvailabilityBoard(true)
          },
          {
            key: 'dashboard',
            title: 'Dashboard',
            icon: ChartPieIcon,
            onClick: () => history.push(PAGE_UNDER_CONSTRUCTION),
            hasSubMenus: false
          },
          {
            key: 'inbox',
            title: 'Email Inbox',
            icon: InboxInIcon,
            onClick: () => history.push(EMAIL_INBOX),
            hasSubMenus: false
          },
          {
            key: 'contacts',
            title: 'Contacts',
            icon: UserIcon,
            onClick: () => history.push(ADMIN_CONTACTS),
            hasSubMenus: false
          }
        ]

      case 'SalesRep':
        return [
          {
            key: 'notifications',
            title: 'Notifications',
            icon: BellIcon,
            onClick: () => history.push(PAGE_UNDER_CONSTRUCTION),
            hasSubMenus: false
          },
          {
            key: 'dashboard',
            title: 'Dashboard',
            icon: ChartPieIcon,
            onClick: () => history.push(ADMIN_DASHBOARD),
            hasSubMenus: false
          },
          {
            key: 'inbox',
            title: 'Email Inbox',
            icon: InboxInIcon,
            onClick: () => history.push(EMAIL_INBOX),
            hasSubMenus: false
          },
          {
            key: 'calendar',
            title: 'Calendar',
            icon: CalendarIcon,
            onClick: () => history.push(CALENDAR),
            hasSubMenus: false
          },
          {
            key: 'availability_board',
            title: 'Availability Board',
            icon: ViewGridIcon,
            onClick: () => goToAvailabilityBoard(false)
          },
          {
            key: 'salesPipeline',
            title: 'Sales Pipeline',
            icon: ViewBoardsIcon,
            onClick: () => gotoSalesPipelinePage(),
            hasSubMenus: false
          },
          {
            key: 'offers',
            title: 'Offers',
            icon: DocumentTextIcon,
            onClick: () => history.push(ADMIN_OFFERS),
            hasSubMenus: false
          },
          {
            key: 'opportunities',
            title: 'Opportunities',
            icon: DocumentTextIcon,
            onClick: () => history.push(ADMIN_OPPORTUNITIES),
            hasSubMenus: false
          },
          {
            key: 'contacts',
            title: 'Contacts',
            icon: UserIcon,
            onClick: () => history.push(ADMIN_CONTACTS),
            hasSubMenus: false
          },
          {
            key: 'deposits',
            title: 'Deposits',
            icon: CashIcon,
            onClick: () => history.push(PAGE_UNDER_CONSTRUCTION),
            hasSubMenus: false
          },
          {
            key: 'commissions',
            title: 'Commissions',
            icon: CurrencyDollarIcon,
            onClick: () => history.push(PAGE_UNDER_CONSTRUCTION),
            hasSubMenus: false
          },
          {
            key: 'myProjects',
            title: 'My Projects',
            icon: ChartSquareBarIcon,
            onClick: () => history.push(PAGE_UNDER_CONSTRUCTION),
            hasSubMenus: false
          },
          {
            key: 'settings',
            title: 'Settings',
            icon: CogIcon,
            onClick: () => history.push(PAGE_UNDER_CONSTRUCTION),
            hasSubMenus: false
          }
        ]

      case 'Buyer':
        return [
          {
            key: 'dashboard',
            title: 'Dashboard',
            icon: ChartPieIcon,
            onClick: () => history.push(ADMIN_DASHBOARD),
            hasSubMenus: false
          },
          {
            key: 'marketPlace',
            title: 'Marketplace',
            icon: MarketIcon,
            onClick: () => {},
            hasSubMenus: false
          },
          {
            key: 'myUnits',
            title: 'My Units',
            icon: BuildingIcon,
            onClick: () => {},
            hasSubMenus: false
          },
          {
            key: 'settings',
            title: 'Settings',
            icon: CogIcon,
            onClick: () => {},
            hasSubMenus: false
          },
          {
            key: 'customerSupport',
            title: 'Customer Support',
            icon: QuestionIcon,
            onClick: () => {},
            hasSubMenus: false
          },
          {
            key: 'offers',
            title: 'Offers',
            icon: DocumentTextIcon,
            onClick: () => {},
            hasSubMenus: false
          }
        ]

      default:
        return (
          <H5 style={{ color: 'white' }}>
            No available menus
          </H5>
        )
    }
  }, [
    goToProjectManagementPageAndSetTab, goToUserPageAndSetTab,
    history, userType, gotoSalesPipelinePage, goToAvailabilityBoard
  ])

  const manageExpandedMenus = (selectedMenuId, expandedMenus) => {
    const tmpExpandedMenus = expandedMenus.filter((menu) => menu === selectedMenuId)

    let newExpandedMenus = [...expandedMenus]

    if (!tmpExpandedMenus.length) {
      newExpandedMenus.push(selectedMenuId)
    } else {
      newExpandedMenus = newExpandedMenus.filter(menu => menu !== selectedMenuId)
    }

    setExpandedMenus(newExpandedMenus)
  }

  const renderMenus = useCallback(() => {
    const menus = createMenus()

    if (menus.length) {
      return menus.map((menu) => {
        const { key, title, icon, onClick, hasSubMenus, subMenus } = menu
        const expanded = expandedMenus.filter(menu => menu === key)

        return (
          <MenuItem
            key={key}
            id={key}
            title={title}
            icon={icon}
            onClick={onClick}
            hasSubMenus={hasSubMenus}
            subMenus={subMenus || []}
            selectedMenuId={selectedMenuId}
            expandedMenus={expandedMenus}
            expanded={Boolean(expanded.length)}
            selectedSubMenuId={selectedSubMenuId}
            setSelectedSubMenuId={setSelectedSubMenuId}
            setSelectedMenuId={setSelectedMenuId}
            executeAfterSelect={executeAfterSelect}
            manageExpandedMenus={manageExpandedMenus}
          />
        )
      })
    } else {
      return menus
    }
  }, [createMenus, selectedMenuId, expandedMenus])

  return (
    <div className='flex-1 flex flex-col overflow-y-auto'>
      <nav className='flex-1 px-4 py-4 bg-white md:bg-gray-100 space-y-1' aria-label='Sidebar'>
        {renderMenus()}
      </nav>
    </div>
  )
}

const mapStateToProps = state => ({
  userObject: state.authReducer.userObject
})

const mapDispatchToProps = {
  changeUserSelectedTab,
  changeProjectManagementSelectedTab
}

// @ts-ignore
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminMenus))

AdminMenus.propTypes = {
  changeUserSelectedTab: propTypes.func,
  changeProjectManagementSelectedTab: propTypes.func,
  userObject: propTypes.object,
  executeAfterSelect: propTypes.func,
  selectedMenuId: propTypes.string,
  setSelectedMenuId: propTypes.func,
  selectedSubMenuId: propTypes.string,
  setSelectedSubMenuId: propTypes.func,
  expandedMenus: propTypes.string,
  setExpandedMenus: propTypes.func
}

SubMenuItem.propTypes = {
  title: propTypes.string,
  onClick: propTypes.func,
  selected: propTypes.bool
}
