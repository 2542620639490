import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'
import { createOrUpdateUser as createOrUpdateUserRequest } from 'store/actions/usersActions'

const useUpdateUsers = (isInvalidateUsers) => {
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: async (params) => {
      return await createOrUpdateUserRequest(params)
    },
    onSuccess: () => {
      if (isInvalidateUsers) {
        queryClient.invalidateQueries({
          queryKey: ['getUsers'],
          refetchType: 'all'
        })
      }
    }
  })
  const createOrUpdateUser = useCallback(
    (
      params,
      showUpdateMessage = true,
      updateLoggedUserObjectAfterSave = false
    ) => {
      const args = {
        params,
        showUpdateMessage,
        updateLoggedUserObjectAfterSave
      }
      return new Promise((resolve, reject) => {
        mutation.mutate(args, {
          onSuccess: (data) => {
            resolve(data)
          },
          onError: (err) => {
            reject(err)
          }
        })
      })
    },
    [mutation]
  )
  return { createOrUpdateUser }
}

export default useUpdateUsers
