import React from 'react'
import { connect } from 'react-redux'

const AdminLeadReport = (props) => {
  return (
    <div className='w-full  gap-10 flex pb-6   relative  px-7'>
      <div className=' pt-6 w-[407px]'>
        <div className='mb-4'>
          <div className=' text-4xl font-bold'>LEAD PROFILE</div>
        </div>
        <div className='bg-white flex-col   flex gap-[11px]  px-6 py-6 rounded border border-black h-fit '>
          <div className='flex gap-6 justify-start'>
            <img
              src='/stefan-dupuis-border.png'
              alt='stefan-dupuis'
              className=' '
            />
          </div>
          <div className='text-2xl leading-[33px] font-bold text-[#2e2b2e]  '>
            Stefan Dupuis
          </div>
          <div className=' flex items-center gap-1 text-base text-[#2e2b2e] '>
            <div>stefan@aires.ai</div>
          </div>
          <div className=' flex items-center gap-1 text-base text-[#2e2b2e] '>
            <div>778-818-1191</div>
          </div>
          <div className=' flex items-center gap-1 text-base text-[#2e2b2e] '>
            <div>Vancouver, BC, Canada</div>
          </div>
          <div className='flex gap-4'>
            <a href='/'>
              <img src='/facebook.png' />
            </a>
            <a href='/'>
              <img src='/instagram.png' />
            </a>
            <a href='/'>
              <img src='/twitter.png' />
            </a>
            <a href='/'>
              <img src='/linked-in.png' />
            </a>
          </div>

          <div className='w-full flex items-center gap-1 text-sm text-[#2e2b2e] '>
            <div className='leading-[21px]'>Vancouver, BC, Canada</div>
          </div>
          <div className='w-full flex justify-between items-center pl-1 text-base font-bold text-[#2e2b2eb3] '>
            <div >PAST PROJECTS</div>
            <button>
              <img src='/up-arrow.svg' alt='expand_less' />
            </button>
          </div>
          <div className='bg-[#f2f2f1] flex-col w-full flex gap-4 px-4 py-4 border border-[#2e2b2e] text-base text-[#2e2b2e] '>
            <div className='w-full flex items-center gap-4'>
              <input type='checkbox' />
              <div>1112 Hute - North Vancouver</div>
            </div>
            <div className='w-full flex items-center gap-4'>
              <input type='checkbox' />
              <div>East 110 North Vancouver</div>
            </div>
          </div>
          <div className='text-[#2e2b2e] flex flex-col gap-2'>
            <div className='italic tracking-[0.16px]  uppercase text-[15px] font-bold'>
              Occupation
            </div>
            <div className='bg-white border-[1px] text-[#2e2b2eb3] rounded-[4px] py-4 px-4 text-[16px]'>
              Vice President - Aires Inc
            </div>
          </div>
          <div className='text-[#2e2b2e] flex flex-col gap-2'>
            <div className='italic uppercase text-[15px] tracking-[0.16px] font-bold'>Salary</div>
            <div className='bg-white border-[1px] text-[#2e2b2eb3] rounded-[4px] py-4 px-4 text-[16px]'>
              $150,000 - $170,000
            </div>
          </div>
          <div className='text-[#2e2b2e] flex flex-col gap-2'>
            <div className='italic tracking-[0.16px] uppercase text-[15px] font-bold'>
              Sales REp
            </div>
            <div className='bg-white border-[1px] text-[#2e2b2eb3] rounded-[4px] py-4 px-4 text-[16px]'>
              Wendy Lei
            </div>
          </div>
          <div className='text-[#2e2b2e] flx flex-col gap-2'>
            <div className='italic uppercase text-[15px] tracking-[0.16px] font-bold'>Broker</div>
            <div className='bg-white border-[1px] text-[#2e2b2eb3] rounded-[4px] py-4 px-4 text-[16px]'>
              Shannon Mckenzie
            </div>
          </div>
        </div>
      </div>
      <div className=' mt-20 flex flex-col gap-4 w-[407px]  rounded border border-black  p-6 '>
        <div className='text-[26px] font-bold'>Registration Form</div>
        <div className='flex flex-col gap-4'>
          <div className='text-[#2e2b2eb3] flex flex-col gap-2'>
            <div className='italic uppercase tracking-[0.16px]  text-[15px] font-bold'>
              Registration date
            </div>
            <input
              type='text'
              placeholder='02/04/2024'
              className='bg-white border-[1px] rounded-[4px] py-4 px-4 text-[16px]'
            />
          </div>
          <div className='text-[#2e2b2eb3] flex flex-col gap-2'>
            <div className='italic uppercase tracking-[0.16px] text-[15px] font-bold'>
              project
            </div>
            <input
              type='text'
              placeholder='1818 pacifica'
              className='bg-white border-[1px] rounded-[4px] py-4 px-4 text-[16px]'
            />
          </div>
          <div className='bg-[#EDDF65] p-6 max-w-[207px]'>
            <div className='font-bold italic text-[#2e2b2eb3] text-[12px]'>
              LEAD SCORE
            </div>
            <div className=' font-bold text-[20px] '>A - 86.8</div>
          </div>
          <div className='text-[#2e2b2eb3] flex flex-col gap-2'>
            <div className='italic uppercase tracking-[0.16px] text-[15px] font-bold'>
              Residence Interest
            </div>
            <input
              type='text'
              placeholder='2 Bedroom - Plan C1 - Plan C2'
              className='bg-white border-[1px] rounded-[4px] py-4 px-4 text-[16px]'
            />
          </div>
          <div className='text-[#2e2b2eb3] flex flex-col gap-2'>
            <div className='italic uppercase text-[15px] tracking-[0.16px] font-bold'>Budget</div>
            <input
              type='text'
              placeholder='$1,300,000 - 1,900,000'
              className='bg-white border-[1px] rounded-[4px] py-4 px-4 text-[16px]'
            />
          </div>
          <div className='font-bold text-[26px]'>Next Action</div>
          <div className='bg-white flex text-[] flex-col gap-2 p-6'>
            <div>
              <img src='/unit-sold.svg' />
            </div>
            <div className='italic text-[#2e2b2eb3] text-[16px]'>
              Presentation Centre Visit
            </div>
            <div className='text-[#2e2b2e] text-[20px] leading-8'>
              08/07/2023 <br />
              1:30PM
            </div>
            <button onClick={() => {}} className='bg-[#FCEE76] cursor-pointer max-w-[156px] flex items-center gap-2 py-3 px-6'>
              <img src='/correct-arrow.svg' />
              <div className='text-[16px]  font-bold'>
                RSVP - YES
              </div>
            </button>
          </div>
        </div>
      </div>
      <div className='bg-white px-8 rounded-[12px] pt-8  flex flex-col max-w-lg'>
        <div className='text-[30px] items-center justify-center mb-8 mx-auto flex font-bold'>
          Website Activity
        </div>
        <div className='flex justify-center items-center'>
          <img src='/website-activity.png' />
        </div>
        <div className='mt-6 flex flex-col gap-6'>
          <div className='flex gap-4'>
            <div className='mt-2'>
              <img src='/phone.png' />
            </div>
            <div className='flex flex-1 flex-col'>
              <div className='text-[20px] text-[#1F2D3D]'>
                Viewed - 1818 Pacifica Project Site
              </div>
              <div className='text-[18px] text-[#7A7A9D]'>
                Vancouver, Canada · March 18 at 10:18 AM
              </div>
            </div>
            <div className='flex items-center  justify-end'>
              <img src='/three-dot-dark.png' />
            </div>
          </div>
          <div className='flex gap-4'>
            <div className='mt-2'>
              <img src='/phone.png' />
            </div>
            <div className='flex flex-1 flex-col'>
              <div className='text-[20px] text-[#1F2D3D]'>
                Viewed - 1818 Pacifica Project Site
              </div>
              <div className='text-[18px] text-[#7A7A9D]'>
                Vancouver, Canada · April 2 at 3:48 PM
              </div>
            </div>
            <div className='flex items-center  justify-end'>
              <img src='/three-dot-dark.png' />
            </div>
          </div>
          <div className='flex gap-4'>
            <div className='mt-2'>
              <img src='/phone.png' />
            </div>
            <div className='flex flex-1 flex-col'>
              <div className='text-[20px] text-[#1F2D3D]'>
                Clicked - Floor Plan - 2bed- Plan C1
              </div>
              <div className='text-[18px] text-[#7A7A9D]'>
                Vancouver, Canada · April 2 at 4:01 PM
              </div>
            </div>
            <div className='flex items-center justify-end'>
              <img src='/three-dot-dark.png' />
            </div>
          </div>
          <div className='flex gap-4'>
            <div className='mt-2'>
              <img src='/phone.png' />
            </div>
            <div className='flex flex-1 flex-col'>
              <div className='text-[20px] text-[#1F2D3D]'>
                Clicked - Floor Plan - 2bed- Plan C3
              </div>
              <div className='text-[18px] text-[#7A7A9D]'>
                Vancouver, Canada · April 2 at 4:03 PM
              </div>
            </div>
            <div className='flex items-center  justify-end'>
              <img src='/three-dot-dark.png' />
            </div>
          </div>
          <div className='flex gap-4'>
            <div className='mt-2'>
              <img src='/phone.png' />
            </div>
            <div className='flex flex-1 flex-col'>
              <div className='text-[20px] text-[#1F2D3D]'>
                Clicked - Schools Info Tab
              </div>
              <div className='text-[18px] text-[#7A7A9D]'>
                Vancouver, Canada · April 2 at 4:05 PM
              </div>
            </div>
            <div className='flex items-center justify-end'>
              <img src='/three-dot-dark.png' />
            </div>
          </div>
          <div className='flex gap-4'>
            <div className='mt-2'>
              <img src='/phone.png' />
            </div>
            <div className='flex flex-1 flex-col'>
              <div className='text-[20px] text-[#1F2D3D]'>
                Form Filled - Registration Page
              </div>
              <div className='text-[18px] text-[#7A7A9D]'>
                Vancouver, Canada · April 2 at 4:08 PM
              </div>
            </div>
            <div className='flex items-center  justify-end'>
              <img src='/three-dot-dark.png' />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  userObject: state.authReducer.userObject
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(AdminLeadReport)
