import React from 'react'
import standardStyle from 'assets/css/standardStyle'
import '../../assets/css/main.css'

import { formatMoney } from 'utils/formatters'

const StatsCard = ({ title = 'Total Units', price = null, count = null, Icon }) => {
  return (
    <div
      className='bg-grey-8 p-2 sm:p-7 rounded-md'
      style={{ fontFamily: standardStyle.fonts.sfpro, width: '100%' }}
    >
      <Icon />
      <div className='flex flex-col space-y-2 pt-10'>
        <div className='text-xs text-zinc-500 capitalize'>
          {title}
        </div>
        <div className='break-words text-lg font-extrabold text-zinc-700'>
          {price && formatMoney(price)}
          {count}
        </div>
      </div>
    </div>
  )
}

export default StatsCard
