import React from 'react'
import { ContactProvider } from 'pages/contacts/context/Contacts'
import { CreateBrokerLeadContextProvider } from './context/CreateBrokerLeadContext'
import CreateBrokerLeadWrapper from './Wrapper'

const CreateBrokerLeads = () => {
  return (
    <CreateBrokerLeadContextProvider>
      <ContactProvider>
        <CreateBrokerLeadWrapper />
      </ContactProvider>
    </CreateBrokerLeadContextProvider>
  )
}

export default CreateBrokerLeads
