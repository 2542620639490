import React from 'react'
import PropsTypes from 'prop-types'

const CategoryButton = (props) => {
  const {
    children,
    selected = false,
    setSelectedCategory,
    category,
    disabled = false,
    totalCategories
  } = props

  const onClickHandler = () => {
    setSelectedCategory(category.value)
  }
  const buttonWidth = totalCategories > 1 ? `w-1/${totalCategories}` : 'w-full'

  return (
    <button
      type='button'
      disabled={disabled}
      onClick={onClickHandler}
      className={`h-full text-base font-medium overflow-hidden rounded-md transition-all ${
        selected ? 'bg-softBlack text-white' : 'bg-white hover:bg-zinc-200 text-softBlack'
      } ${buttonWidth}`}
    >
      {children}
    </button>
  )
}

CategoryButton.propTypes = {
  selected: PropsTypes.bool,
  setSelectedCategory: PropsTypes.func,
  category: PropsTypes.object,
  children: PropsTypes.any,
  totalCategories: PropsTypes.number.isRequired
}

export default CategoryButton
