// @ts-nocheck
import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  createContext
} from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import standardStyle from 'assets/css/standardStyle'
import { Table, Modal } from 'ui'
import useGetUsers from 'hooks/useGetUsers'
import { formatDate } from 'utils/dateUtils'
import { ReactComponent as SorterIcon } from 'assets/icons/sorter.svg'
import { useGetAPI } from 'hooks/useGenericAPIs'
import useDeleteReservationsById from 'hooks/useDeleteReservationsById'
import { toast } from 'react-hot-toast'
import { FadedAnimatedDiv, LottieWrapper } from 'components'
import tableLoading from 'assets/lottieJsons/tableLoading.json'
import { getReservations } from 'store/actions/reservationActions'

// @ts-ignore
const LoadingContext = createContext()

export const offerStatus = {
  complete: { text: 'Complete', backgroundColor: 'bg-green-700' },
  pending: { text: 'Pending', backgroundColor: 'bg-gray-600' },
  cancelled: { text: 'Cancelled', backgroundColor: 'bg-red-600' }
}

const ColoredStatus = ({ status }) => (
  <div
    className={`font-medium py-1 text-base text-white uppercase
      ${
        offerStatus[status.toLowerCase()].backgroundColor || 'bg-gray-600'
      } text-center
    `}
  >
    {offerStatus[status.toLowerCase()].text}
  </div>
)

const ActionButton = ({ text, onClick, value }) => {
  return (
    <div
      className='font-medium py-1 text-base text-black uppercase text-center border border-black w-32 cursor-pointer'
      onClick={onClick}
    >
      View/Edit
    </div>
  )
}

const DeleteButton = ({ onClick }) => {
  return null
  // return (
  //   <div
  //     className='font-medium py-1 text-base text-black uppercase text-center border border-black w-32 cursor-pointer bg-red-600'
  //     onClick={onClick}
  //   >
  //     Delete
  //   </div>
  // )
}

const TextField = ({ value }) => (
  <span className='font-medium text-base text-softBlack capitalize'>
    {value}
  </span>
)

const MemoTable = ({
  dataSource,
  columns,
  loading,
  idBeingDeleted,
  deleting
}) => {
  return (
    <Table
      className='rounded-lg'
      dataSource={dataSource}
      columns={columns}
      tailwindTable
      loading={loading}
      idBeingDeleted={idBeingDeleted}
      deleting={deleting}
      noMargin
    />
  )
}

const CardComponent = ({ title, count }) => (
  <div
    className='bg-white rounded col-span-1'
    style={{ fontFamily: standardStyle.fonts.sfpro }}
  >
    <div className='flex flex-col space-y-2 p-4'>
      <div className='text-xs text-softBlack_70 font-medium uppercase'>
        {title}
      </div>
      <div className='text-sm font-bold text-softBlack'>{count}</div>
    </div>
  </div>
)

const ProjectReservations = () => {
  const userObject = useSelector(state => state.authReducer.userObject)
  const [projectId] = useState('607605e70de444001a71cfc8')
  const [tableDataSource, setTableDataSource] = useState([])
  const [filteredTableDataSource, setFilteredTableDataSource] = useState([])
  const [idBeingDeleted] = useState('')
  const [deletingReservation] = useState(false)
  const [reservations, setReservations] = useState([])
  const [loading, setLoading] = useState(true)
  const { users, isLoading } = useGetUsers()
  const [isDeleteDialogOpened, setIsDeleteDialogOpened] = useState(-1)

  const history = useHistory()

  const [sortType, setSortType] = useState('asc')
  const [sortByField, setSortByField] = useState('createdAt')
  const { deleteAPI: deleteReservationById, loading: isDeleteInProgress } =
    useDeleteReservationsById()
  const { data: fetchedDataByReservationType, fetch: fetchByReservationType } =
    useGetAPI()
  const { data: fetchedDataByPaymentStatus, fetch: fetchByPaymentStatus } =
    useGetAPI()
  const { data: fetchedDataBySignatureStatus, fetch: fetchBySignatureStatus } =
    useGetAPI()

  useEffect(() => {
    fetchReservations()
  }, [])

  const fetchReservations = () => {
    setLoading(true)
    getReservations(projectId).then((res) => {
      console.log('res: ', res)
      setReservations(res)
      setLoading(false)
    })
  }

  useEffect(() => {
    if (userObject?._id?.length > 0) {
      fetchByReservationType(
        `/analytics/reservation/leadBroker/metrics/groupBy/reservationStatus/${userObject._id}`
      )
      fetchByPaymentStatus(`/analytics/reservation/leadBroker/metrics/groupBy/paymentStatus/${userObject._id}`)
      fetchBySignatureStatus(
        `/analytics/reservation/leadBroker/metrics/groupBy/primaryBuyerSignatureStatus/${userObject._id}`
      )
    }
  }, [userObject])

  useEffect(() => {
    if (reservations && users) {
      const updatedReservations = reservations.map((reservation) => {
        const user = users?.find?.(
          (usr) => usr?._id === reservation?.primaryBuyer
        )
        const firstName = user?.firstName
        const lastName = user?.lastName
        return {
          ...reservation,
          reservation_holder:
            firstName && lastName ? firstName + ' ' + lastName : null
        }
      })
      setTableDataSource(updatedReservations)
    }
  }, [reservations, loading, users])

  const onSortClicked = useCallback(
    (field) => {
      let newSortType = 'asc'
      if (field === sortByField) {
        newSortType = sortType === 'asc' ? 'desc' : 'asc'
      }
      const sortedData = tableDataSource.sort((a, b) => {
        if (newSortType === 'asc') {
          return a[field]?.localeCompare(b[field])
        } else if (newSortType === 'desc') {
          return b[field]?.localeCompare(a[field])
        } else return 0
      })
      setFilteredTableDataSource([...sortedData])
      setSortByField(field)
      setSortType(newSortType)
    },
    [tableDataSource, sortByField, sortType]
  )

  const tableColumns = useMemo(
    () => [
      {
        Title: (
          <span className='flex items-center space-x-2'>
            <span className='leading-none'>Date Created</span>
            <button onClick={() => onSortClicked('createdAt')}>
              <SorterIcon />
            </button>
          </span>
        ),
        accessor: 'createdAt',
        dataIndex: 'createdAt',
        Cell: (props) => <TextField value={formatDate(props.cell.value)} />
      },
      {
        Title: (
          <span className='flex items-center space-x-2'>
            <span className='leading-none'>Reservation Holder(s)</span>
            <button onClick={() => onSortClicked('reservation_holder')}>
              <SorterIcon />
            </button>
          </span>
        ),
        accessor: 'reservation_holder',
        Cell: (props) => <TextField value={props.cell.value} />
      },
      {
        Title: (
          <span className='flex items-center space-x-2'>
            <span className='leading-none'>Signature Status</span>
            <button
              onClick={() => onSortClicked('primaryBuyerSignatureStatus')}
            >
              <SorterIcon />
            </button>
          </span>
        ),
        accessor: 'primaryBuyerSignatureStatus',
        Cell: (props) => <TextField value={props.cell.value} />
      },
      {
        Title: (
          <span className='flex items-center space-x-2'>
            <span className='leading-none'>Payment Status</span>
            <button onClick={() => onSortClicked('paymentStatus')}>
              <SorterIcon />
            </button>
          </span>
        ),
        accessor: 'paymentStatus',
        Cell: (props) => <TextField value={props.cell.value} />
      },
      {
        Title: (
          <span className='flex items-center space-x-2'>
            <span className='leading-none'>Reservation Status</span>
            <button onClick={() => onSortClicked('reservationStatus')}>
              <SorterIcon />
            </button>
          </span>
        ),
        accessor: 'reservationStatus',
        Cell: (props) => <ColoredStatus status={props.cell.value} />
      },
      {
        Title: '',
        accessor: '_id',
        Cell: (props) => (
          <div className='flex flex-row gap-2'>
            <ActionButton
              text={props.cell.value}
              value={props.cell}
              onClick={() =>
                history.push(`/lead-broker/reservations/${props.cell.value}`)
              }
            />
            <DeleteButton
              onClick={() => {
                console.log('DeleteButton clicked', props.cell.value)
                setIsDeleteDialogOpened(props.cell.value)
              }}
            />
          </div>
        )
      }
    ],
    [onSortClicked]
  )

  const isDataLoading = useMemo(
    () => loading || isLoading || isDeleteInProgress,
    [loading, isLoading, isDeleteInProgress]
  )

  const values = useMemo(() => ({}), [])
  const tabeleDataSource = useMemo(() => {
    const values =
      filteredTableDataSource?.length > 0
        ? filteredTableDataSource
        : tableDataSource
    return values.filter((x) => !x.deleted)
  }, [filteredTableDataSource, tableDataSource])

  const deleteSelectedReservation = useCallback(async () => {
    const toastId = toast.loading('Deleting reservation...')
    try {
      const reservationId = isDeleteDialogOpened
      setIsDeleteDialogOpened(-1)
      await deleteReservationById(reservationId)
      fetchReservations()
      toast.dismiss(toastId)
      toast.success('Deleted reservation successfully')
    } catch (err) {
      toast.dismiss(toastId)
      console.error('Failed to delete reservation', err)
    }
  }, [isDeleteDialogOpened, fetchReservations])

  return (
    <LoadingContext.Provider value={values}>
      <div className='h-full w-full flex flex-col items-center'>
        <div className='w-full lg:overflow-x-hidden'>
          <div className='mt-5 grid grid-flow-col grid-cols-3 sm:grid-cols-7 gap-2'>
            {fetchedDataByReservationType
              ?.filter((item) => item._id?.length)
              ?.map((item) => {
                return (
                  <CardComponent
                    title={`Reservation ${item._id}`}
                    count={item.count}
                  />
                )
              })}
            {fetchedDataByPaymentStatus
              ?.filter((item) => item._id?.length)
              ?.map((item) => {
                return (
                  <CardComponent
                    title={`Payment Status - ${item._id}`}
                    count={item.count}
                  />
                )
              })}
            {fetchedDataBySignatureStatus
              ?.filter((item) => item._id?.length)
              ?.map((item) => {
                return (
                  <CardComponent
                    title={`Signature Status - ${item._id}`}
                    count={item.count}
                  />
                )
              })}
          </div>
          <div className='mt-5'>
            {isDataLoading && (
              <FadedAnimatedDiv
                key='loadingTable'
                className='w-full h-[300px] flex items-center justify-center my-10 flex-col bg-white rounded'
              >
                <LottieWrapper
                  animation={tableLoading}
                  loop
                  className='h-16 w-16'
                />
                <span className='text-base'>loading reservations...</span>
              </FadedAnimatedDiv>
            )}
            {!isDataLoading && (
              <MemoTable
                dataSource={tabeleDataSource}
                columns={tableColumns}
                loading={loading}
                idBeingDeleted={idBeingDeleted}
                deleting={deletingReservation}
              />
            )}
            <Modal
              showModal={isDeleteDialogOpened !== -1}
              onCancel={() => setIsDeleteDialogOpened(-1)}
              modalType='alert'
              title='Are you sure?'
              subtitle='Do you want to delete reservation?'
              onOk={deleteSelectedReservation}
            />
          </div>
        </div>
      </div>
    </LoadingContext.Provider>
  )
}

export default ProjectReservations
