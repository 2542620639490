import { leftZero } from './converters'

export const addDays = (date, days) => {
  const result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}

const _MS_PER_DAY = 1000 * 60 * 60 * 24

// a and b are javascript Date objects
export const dateDiffInDays = (startDate, endDate) => {
  if (!startDate || !endDate) return ''

  const tmpStartDate = new Date(startDate)
  const tmpEndDate = new Date(endDate)

  // Discard the time and time-zone information.
  const utc1 = Date.UTC(tmpStartDate.getFullYear(), tmpStartDate.getMonth(), tmpStartDate.getDate())
  const utc2 = Date.UTC(tmpEndDate.getFullYear(), tmpEndDate.getMonth(), tmpEndDate.getDate())

  return Math.floor((utc2 - utc1) / _MS_PER_DAY)
}

export const formatDate = (date, showSeconds = true) => {
  const tmpDate = new Date(date)

  const year = tmpDate.getFullYear()
  const month = tmpDate.getMonth() + 1
  const day = tmpDate.getDate()
  const hours = leftZero(tmpDate.getHours())
  const minutes = leftZero(tmpDate.getMinutes())
  const seconds = leftZero(tmpDate.getSeconds())

  return `${day}/${month}/${year} ${hours}:${minutes}${showSeconds ? `:${seconds}` : ''}`

  // const supports = testDateTimeStyleSupport()

  // const opts = supports ? { dateStyle: 'full', timeStyle: 'short' } : {
  //   weekday: 'long',
  //   year: 'numeric',
  //   month: 'long',
  //   day: 'numeric',
  //   hour: 'numeric',
  //   minute: 'numeric',
  //   second: 'numeric'
  // }

  // console.log('supports', supports)

  // function testDateTimeStyleSupport () {
  //   let support = false
  //   const tester = {
  //     get dateStyle () { support = true }
  //   }

  //   new Intl.DateTimeFormat(undefined, tester)
  //   return support
  // }

  // const newDate = new Date(date)
  // return newDate.toLocaleDateString('en-us', opts)
}

export const getCalenderDateMomentUsingObjectType = (object, date) => {
  let result
  if (object === 'timespan') {
    result = new Date(date * 1000)
    // } else if (object === 'datespan') {
    //   result = new Date(date)
  } else {
    result = date
  }
  return result
}
