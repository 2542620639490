import { Drawer, makeStyles } from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import '../../style/style.css'
// import { IoMdClose } from 'react-icons/io'
import { FadedAnimatedDiv } from 'components'
import Searching from 'pages/contacts/Searching'
import { ReactComponent as ActivityIcon } from 'assets/icons/icn_ballot.svg'
import { ReactComponent as BuyerIcon } from 'assets/icons/icn_id.svg'
import { ReactComponent as ComplianceIcon } from 'assets/icons/icn_beenhere.svg'
import { ReactComponent as ContractIcon } from 'assets/icons/icn_text_snippet.svg'
import { ReactComponent as DepositIcon } from 'assets/icons/icn_local_atm.svg'
// import ContractTabHeader from '../ContractTabHeader'
// import UnitImage from 'assets/img/unit-1201.png'
// import { ColoredStatus } from 'components/ProjectOffers'
import ActivityTab from './ContractsTabs/ActivityTab'
import BuyersTab from './ContractsTabs/BuyersTab'
import ComplianceTab from './ContractsTabs/ComplianceTab'
import ContractsTab from './ContractsTabs/ContractsTab'
import DepositsTab from './ContractsTabs/DepositsTab'
import ParkingTab from './ContractsTabs/ParkingTab'
import { getOffers } from 'store/actions/offerActions'
import { formatMoney } from 'utils'
import Checkbox from '../Checkbox'
import Button from '../Button'
import { H3, H5 } from 'ui'
import SubText from '../SubText'
import StorageTab from './ContractsTabs/StorageTab'
import TransactionOverviewTab from './ContractsTabs/TransactionOverviewTab'

const ContractDetailDrawer = ({ openDocumentsModal, isContractDetailDrawerOpen, closeContractDetailDrawer, transactionId }) => {
  const [transactionData, setTransactionData] = useState(null)
  const [selectedHeader, setSelectedHeader] = useState('Parking')
  const [selectedAuditChecklistHeader, setSelectedAuditChecklistHeader] = useState('All')
  const [loading, setLoading] = useState(false)
  const [isAuditChecklistOpen, setIsAuditChecklistOpen] = useState(false)

  const useStyles = makeStyles((theme) => ({
    hide: {
      display: 'none'
    },
    drawer: {
      // width: 'calc(100% - 250px)',
      width: 'calc(100% - 78px)',
      flexShrink: 0
    },
    drawerPaper: {
      // width: 'calc(100% - 250px)',
      width: 'calc(100% - 78px)',
      height: '100vh',
      overflow: 'hidden',
      boxShadow: '-10px 0px 15px 0px rgba(46, 43, 46, 0.05)'
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end'
    }
  }))

  const classes = useStyles()

  useEffect(() => {
    setLoading(true)
    getOffers(transactionId)
      .then((data) => {
        setTransactionData(data)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [transactionId])

  const LoadingTransaction = useCallback(() => (
    <FadedAnimatedDiv className='h-full w-full flex flex-col justify-center items-center'>
      <Searching title='Loading information of transaction...' />
    </FadedAnimatedDiv>
  ), [])

  const calculateTotalCostOfParkingAndUpgrade = () => {
    let cost = 0
    transactionData?.options?.forEach((option) => {
      cost += option.value
    })
    return cost
  }

  const getTableHeaders = useCallback(() => {
    const headers = [
      {
        key: 'transactionOverview',
        title: 'Transaction Overview',
        Icon: ActivityIcon
      },
      {
        key: 'activity',
        title: 'Activity',
        Icon: ActivityIcon
      },
      {
        key: 'buyers',
        title: 'Buyers',
        Icon: BuyerIcon
      },
      {
        key: 'compliance',
        title: 'Compliance',
        Icon: ComplianceIcon
      },
      {
        key: 'contracts',
        title: 'Contracts',
        Icon: ContractIcon
      },
      {
        key: 'deposits',
        title: 'Deposits',
        Icon: DepositIcon
      },
      {
        key: 'parking',
        title: 'Parking',
        Icon: ContractIcon
      },
      {
        key: 'storage',
        title: 'Storage',
        Icon: ContractIcon
      }
    ]
    return headers
  }, [])

  const listData = useCallback(() => {
    return [
      {
        key: 'primaryBuyer',
        title: 'Primary buyer',
        value: !transactionData?.buyer?.[0]
          ? 'N?A'
          : transactionData?.buyer?.[0]?.firstName +
          ' ' +
          transactionData?.buyer?.[0]?.lastName
      },
      {
        key: 'purchasePrice',
        title: 'Purchase price',
        value: formatMoney(transactionData?.totalCost ?? 0)
      },
      {
        key: 'upgradesAndOptions',
        title: 'Upgrades & options',
        value: formatMoney(calculateTotalCostOfParkingAndUpgrade())
      },
      {
        key: 'totalDepositPaid',
        title: 'Total deposit paid',
        value: '—'
        // value: '$128,800'
      },
      {
        key: 'soldFirmDate',
        title: 'Sold firm date',
        value: '—'
      }
    ]
  }, [transactionData])

  const ListItem = ({ list }) => (
    <div key={list.id} className='col-span-1 self-stretch flex-col justify-start items-start gap-1 flex'>
      <SubText variant='light' label={list.title} className='text-sm leading-tight' />
      <SubText label={list.value} className='text-sm leading-tight' />
    </div>
  )

  const TabMenus = () => {
    const MenuItem = ({ menu: { key, title, Icon } }) => (
      <div
        id={key}
        className={`w-full p-2 xl:px-1.5 xl:py-2.5 rounded justify-start items-center inline-flex transition-all cursor-pointer ${selectedHeader === title ? 'bg-zinc-100 dark:bg-zinc-800' : 'hover:bg-zinc-100 dark:hover:bg-zinc-800'}`}
        onClick={() => {
          if (key !== 'audit-checklist') {
            setSelectedHeader(title)
          } else {
            setIsAuditChecklistOpen(!isAuditChecklistOpen)
          }
        }}
      >
        <div className='justify-start items-center gap-1.5 flex'>
          <Icon className='w-4 h-4 relative' />
          <div className='justify-center items-center gap-2.5 flex'>
            <div className='text-zinc-900 dark:text-white text-sm font-medium leading-tight'>{title}</div>
          </div>
        </div>
      </div>
    )

    return (
      <div className='px-3 py-3 xl:px-6 xl:py-4 flex flex-col gap-1 border-b border-[#2E2B2E] dark:border-zinc-500 border-opacity-[15%]'>
        {
          getTableHeaders().map((menu, index) => (
            <MenuItem key={menu.key} menu={menu} />
          ))
        }
        <div
          className={`w-full px-1.5 py-2.5 rounded justify-start items-center inline-flex transition-all cursor-pointer ${isAuditChecklistOpen ? 'bg-zinc-100 dark:bg-zinc-800' : 'hover:bg-zinc-100 dark:hover:bg-zinc-800'}`}
          onClick={() => {
            setIsAuditChecklistOpen(!isAuditChecklistOpen)
          }}
        >
          <div className='justify-start items-center gap-1.5 flex'>
            <DepositIcon className='w-4 h-4 relative' />
            <div className='justify-center items-center gap-2.5 flex'>
              <div className='text-zinc-900 dark:text-white text-sm font-medium leading-tight'>Audit checklist</div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const VerticalCard = ({ unitNumber }) => (
    <div key={`verticalCard-${selectedHeader}`} className='w-[250px] xl:w-[350px] h-full flex flex-col overflow-hidden'>
      <div className='p-3 xl:p-6 flex flex-col gap-y-2 xl:gap-y-4 border-b border-[#2E2B2E] dark:border-zinc-500 border-opacity-[15%]'>
        <Button
          variant='outline'
          onClick={() => closeContractDetailDrawer()}
          label='Back to all transactions'
        />
        <div className='w-full flex flex-col'>
          <H3 className='text-zinc-950 text-xl xl:text-2xl font-bold xl:leading-[38px] mb-0'>Unit #{unitNumber}</H3>
          <SubText variant='light' label='Strata Lot Number LMS 7362' />
        </div>
        <div className='w-full px-4 py-3 bg-[#EDDF65] bg-opacity-30 dark:bg-opacity-50 rounded-lg flex-col justify-start items-start inline-flex'>
          <div className='rounded-md justify-start items-center gap-2 inline-flex'>
            <div className='w-[7px] h-[7px] bg-yellow-400 rounded-full'></div>
            <SubText label='Pending approval' />
          </div>
          <div className='self-stretch py-2 justify-start items-start gap-1 inline-flex'>
            <div className='grow shrink basis-0 h-2 bg-zinc-950 dark:bg-zinc-200 rounded-sm'></div>
            <div className='grow shrink basis-0 h-2 bg-zinc-950 dark:bg-zinc-200 rounded-sm'></div>
            <div className='grow shrink basis-0 h-2 bg-zinc-950 dark:bg-zinc-200 rounded-sm'></div>
            <div className='grow shrink basis-0 h-2 bg-zinc-950 dark:bg-zinc-200 rounded-sm'></div>
            <div className='grow shrink basis-0 h-2 bg-zinc-950 dark:bg-zinc-200 rounded-sm'></div>
            <div className='grow shrink basis-0 h-2 bg-zinc-950 dark:bg-zinc-200 bg-opacity-20 dark:bg-opacity-40 rounded-sm'></div>
            <div className='grow shrink basis-0 h-2 bg-zinc-950 dark:bg-zinc-200 bg-opacity-20 dark:bg-opacity-40 rounded-sm'></div>
          </div>
          <SubText variant='light' label='5 of 8 stages completed' />
        </div>
      </div>
      <TabMenus />
      <div className='p-3 xl:p-6 grid grid-cols-2 gap-y-3 xl:gap-y-6 gap-x-2 overflow-y-auto thin-scrollbar'>
        {
          listData().map((list) => (<ListItem list={list} />))
        }
      </div>
    </div>
  )

  const AuditCheckList = () => {
    const auditCheckListHeaderList = [
      {
        id: 'all',
        title: 'All'
      },
      {
        id: 'outstanding',
        title: 'Outstanding'
      },
      {
        id: 'complete',
        title: 'Complete'
      },
      {
        id: 'blocked',
        title: 'Blocked'
      }
    ]

    const getCheckList = useCallback(() => {
      return [
        {
          id: 'list_1',
          label: 'Interest registration'
        },
        {
          id: 'list_2',
          label: 'Reservation agreement signed could be multiple lines long with links or more instructions in the task'
        },
        {
          id: 'list_3',
          label: 'Reservation agreement signed'
        },
        {
          id: 'list_4',
          label: 'Reservation fee paid'
        },
        {
          id: 'list_5',
          label: 'Signed documents'
        },
        {
          id: 'list_6',
          label: 'KYC verification'
        },
        {
          id: 'list_7',
          label: 'ID verification'
        },
        {
          id: 'list_8',
          label: 'Deposit payment'
        },
        {
          id: 'list_9',
          label: 'Legal approval'
        }
      ]
    }, [])

    const TabView = () => (
      <div className='w-full justify-start items-center gap-1 xl:gap-2 inline-flex overflow-x-auto hide-scrollbar'>
        {
          auditCheckListHeaderList.map((item) => (
            <div
              key={item.id}
              className={`px-2 py-1 ${selectedAuditChecklistHeader === item.title ? 'bg-zinc-200' : ''} rounded-[100px] justify-center items-center flex cursor-pointer`}
              onClick={() => setSelectedAuditChecklistHeader(item.title)}
            >
              <SubText
                className={`${selectedAuditChecklistHeader === item.title ? 'text-zinc-900 dark:text-zinc-900' : 'text-zinc-500'} text-xs xl:text-sm font-normal leading-[1rem] mb-0 truncate`}
                label={item.title}
              />
            </div>
          ))
        }
      </div>
    )

    const CustomCheckBox = ({ label }) => {
      const [isChecked, setIsChecked] = useState(false)

      return (
        <Checkbox
          label={label}
          labelClasses='flex-1 self-center text-zinc-950 dark:text-zinc-50 text-xs xl:text-sm font-normal'
          checked={isChecked}
          handleChange={() => setIsChecked(!isChecked)}
          containerClasses='py-1 px-2 xl:py-2 xl:px-4 hover:bg-zinc-100 dark:hover:bg-zinc-900 transaction-all w-full'
          iconClasses='self-start my-1'
        />
      )
    }

    return (
      <div key='audit-checklist' className='w-[250px] xl:w-[350px] border-r border-zinc-200'>
        <div className='w-full p-2 pt-3 xl:p-4 xl:pt-6 border-b border-zinc-200 flex-col justify-start items-start gap-2 xl:gap-4 inline-flex overflow-hidden'>
          <div className='justify-start items-center gap-4 inline-flex'>
            <H5 className='text-zinc-950 text-base font-semibold leading-none mb-0'>Audit checklist</H5>
          </div>
          <TabView />
        </div>
        <div className='self-stretch py-2 xl:py-4 bg-white dark:bg-black flex-col justify-start items-start flex'>
          {
            getCheckList().map((item) => (
              <CustomCheckBox
                key={item.id}
                label={item.label}
              />
            ))
          }
        </div>
      </div>
    )
  }

  return (
    <Drawer
      open={isContractDetailDrawerOpen}
      variant='persistent'
      anchor='right'
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <div className='pt-16 relative h-full dark:bg-darkModeBackground'>
        {loading && <LoadingTransaction />}
        {transactionData && !loading && transactionData?._id && (
          <div
            className='w-full flex h-full'
          >
            <div className='h-full border-r border-[#2E2B2E] dark:border-zinc-500 border-opacity-[15%]'>
              <VerticalCard key={`sidenav-${selectedHeader}`} unitNumber={transactionData.unit.unitNumber} />
            </div>
            {
              isAuditChecklistOpen &&
              <AuditCheckList key='audit-checklists' />
            }
            <div className='flex-1 h-full flex flex-col overflow-hidden'>
              {/* <div className='thin-scrollbar overflow-x-auto'>
                <ContractTabHeader
                  headers={getTableHeaders()}
                  handleSelectHeader={(header) => setSelectedHeader(header)}
                  selectedHeader={selectedHeader}
                />
              </div> */}
              <div className='w-full min-w-[400px] h-[calc(100%-53px)] overflow-auto p-10'>
                <h3 className='text-2xl font-semibold mb-4.5'>
                  {
                    (selectedHeader === 'Parking' || selectedHeader === 'Storage')
                      ? `Assigned ${selectedHeader}`
                      : selectedHeader
                  }
                </h3>
                {
                  selectedHeader === 'Activity' &&
                  <ActivityTab buyerId={transactionData.buyer[0]._id} />
                }
                {
                  selectedHeader === 'Buyers' &&
                  <BuyersTab buyerId={transactionData.buyer[0]._id} />
                }
                {
                  selectedHeader === 'Compliance' &&
                  <ComplianceTab />
                }
                {
                  selectedHeader === 'Contracts' &&
                  <ContractsTab
                    openDocumentsModal={openDocumentsModal}
                    transactionId={transactionId}
                  />
                }
                {
                  selectedHeader === 'Deposits' &&
                  <DepositsTab
                    installments={transactionData.deposit.installments}
                  />
                }
                {
                  selectedHeader === 'Parking' &&
                  <ParkingTab />
                }
                {
                  selectedHeader === 'Storage' &&
                  <StorageTab />
                }
                {
                  selectedHeader === 'Transaction Overview' &&
                  <TransactionOverviewTab />
                }
              </div>
            </div>
          </div>
        )}
      </div>
    </Drawer>
  )
}

export default ContractDetailDrawer
