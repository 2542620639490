import { TCancelButton } from 'components/DefaultButtons'
import propTypes from 'prop-types'
import React from 'react'
import { TButton } from 'ui'
import { BuyerButtonsWrapper } from './styles'

const OfferFooterButtons = ({
  backButtonDisabled,
  backButtonText = 'Back',
  backButtonClick,
  continueButtonClick,
  continueButtonDisabled,
  continueButtonText = 'Continue',
  continueButtonLoading
}) => (
  <BuyerButtonsWrapper justify='center'>
    {
      backButtonClick
        ? (
            <TCancelButton
              onClick={backButtonClick}
              disabled={backButtonDisabled ? 'disabled' : ''}
              className='text-base'
            >
              {backButtonText}
            </TCancelButton>
          )
        : null
    }
    {
      continueButtonClick && (
        <TButton
          onClick={continueButtonClick}
          disabled={continueButtonDisabled}
          loading={continueButtonLoading}
          className='text-base'
        >
          {continueButtonText}
        </TButton>
      )
    }

  </BuyerButtonsWrapper>
)

export default OfferFooterButtons

OfferFooterButtons.propTypes = {
  backButtonDisabled: propTypes.bool,
  backButtonText: propTypes.string,
  backButtonClick: propTypes.func,
  continueButtonClick: propTypes.func,
  continueButtonDisabled: propTypes.bool,
  continueButtonText: propTypes.string,
  continueButtonLoading: propTypes.bool
}
