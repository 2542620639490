import React from 'react'
import propTypes from 'prop-types'
import Skeleton from 'react-loading-skeleton'

const Title = ({
  loading, color, children, className
}) => (
  <span
    color={color || 'primary'}
    className={`text-4xl md:text-5xl text-left ${className}`}
  >
  {loading ? <Skeleton width={350} /> : children}
  </span>
)

Title.defaultProps = {
  className: 'initial'
}

Title.propTypes = {
  children: propTypes.string.isRequired,
  loading: propTypes.bool,
  color: propTypes.string,
  className: propTypes.string
}

export default Title
