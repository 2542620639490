// @ts-nocheck
/* eslint-disable */
import React, { useRef, useState } from 'react'
import { message } from 'antd'
import useHiddenHillFormSubmission from 'hooks/useHiddenHillFormSubmission'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import Logo from 'components/LoginPage/Logo'
import CustomButton from 'components/CustomButton'
import { Oval } from 'react-loader-spinner'
import bgImage from '../../assets/img/mountain-bg.jpg'
import PhoneInput from 'react-phone-number-input'
import { Check } from 'lucide-react'
import HCaptcha from '@hcaptcha/react-hcaptcha';

const Input = ({ placeholder, label, value, onChange, onBlur, name, type = 'text', error, touched, containerClassName = '' }) => {
  return (
    <div className={`relative w-full pb-3.5 ${containerClassName}`}>
      <label className='block text-sm font-medium text-gray-700 mb-1'>
        {label} *
      </label>
      <input
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        type={type}
        className='border border-softBlack focus:border-softBlack p-2 w-full rounded focus:outline-none focus:ring-0'
      />
      {error && touched && <p className='absolute bottom-0 text-red-500 text-xs mt-1 mb-0'>{error}</p>}
    </div>
  )
}

const RADIO_OPTION = [
  {
    label: 'Yes',
    value: 'true'
  },
  {
    label: 'No',
    value: 'false'
  }
]

const HCAPTCHA_KEY = process.env.REACT_APP_HCAPTCHA_KEY || 'c6ae0532-c246-487e-a4b4-e7b23b8abcf6'
const LeadsRegistrationForm = ({ leadSource, projectName, project }) => {
  const { postData, loading } = useHiddenHillFormSubmission()
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [token, setToken] = useState(null)
  const captchaRef = useRef(null);

  const initialValues = {
    firstName: '',
    lastName: '',
    emailAddress: '',
    phoneNumber: '',
    discoverProject: '',
    isRealtor: ''
  }

  const validationSchema = Yup.object({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    emailAddress: Yup.string()
      .email('Invalid email address')
      .required('Email Address is required'),
    phoneNumber: Yup.string()
      .required('Phone Number is required'),
    discoverProject: Yup.string().required('Please select an option'),
    isRealtor: Yup.string().required('Please select an option')
  })

  const handleSubmit = async (values, { resetForm }) => {
    if (!token) {
      return alert('Please complete the captcha')
    }
    const payload = {
      ...values,
      leadSource,
      project,
      'h-captcha-response': token
    }
    try {
      await postData(payload)
      message.success('Lead data submitted successfully')
      setIsFormSubmitted(true)
      resetForm()
    } catch (err) {
      setIsFormSubmitted(false)
      message.error(`${err}`)
    }
  }

  return (
    <div className='w-screen h-screen flex justify-center items-center bg-cover' style={{ backgroundImage: `url(${bgImage})` }}>
      <div className='w-full md:w-[650px] min-h-[660px] p-10 bg-white shadow-lg rounded-lg flex flex-col'>
        <div className='mb-16'>
          <Logo />
        </div>
        {
          isFormSubmitted
            ? <>
              <div className='flex-1 flex flex-col gap-10 justify-center items-center'>
                <div className='p-10 flex justify-center items-center bg-zinc-200 rounded-full'>
                  <Check className='w-20 h-20' />
                </div>
                <h1 className='text-3xl font-bold text-center'>Registration Successful</h1>
              </div>
              <CustomButton className='col-span-2 mt-5' onClick={() => setIsFormSubmitted(false)}>
                <span className='font-medium py-2 px-6 text-base flex gap-2 items-center'>
                  Return to Registration Page
                </span>
              </CustomButton>
            </>
            : <>
              <h1 className='text-3xl font-bold mb-1 capitalize'>{projectName} - {leadSource}</h1>
              <p className='text-base mt-3 mb-6'>Please enter your details</p>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ values, handleChange, handleBlur, touched, errors, setFieldValue }) => (
                  <Form className='p-0 border-none shadow-none w-full grid grid-cols-2 gap-4'>
                    <Input
                      label='First Name'
                      name='firstName'
                      placeholder='Enter your first name'
                      value={values.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.firstName}
                      touched={touched.firstName}
                      containerClassName=''
                    />
                    <Input
                      label='Last Name'
                      name='lastName'
                      placeholder='Enter your last name'
                      value={values.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.lastName}
                      touched={touched.lastName}
                    />
                    <Input
                      label='Email Address'
                      name='emailAddress'
                      type='email'
                      placeholder='Enter your email address'
                      value={values.emailAddress}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.emailAddress}
                      touched={touched.emailAddress}
                    />
                    <div className='relative w-full pb-4.5'>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Phone Number
                      </label>
                      <PhoneInput
                        placeholder="Enter your phone number"
                        defaultCountry="US"
                        value={values.phoneNumber}
                        onChange={(value) => setFieldValue('phoneNumber', value)}
                        className='border border-softBlack h-[42px] rounded text-softBlack font-normal placeholder-softBlack60 pl-2 overflow-hidden'
                        onBlur={handleBlur}
                      />
                      {errors.phoneNumber && touched.phoneNumber && (
                        <p className='absolute bottom-0 text-red-500 text-xs mt-1 mb-0'>{errors.phoneNumber}</p>
                      )}
                    </div>
                    <div className='relative w-full pb-4.5'>
                      <label className='block text-sm font-medium text-gray-700 mb-1'>
                        How did you hear about us?
                      </label>
                      <select
                        name='discoverProject'
                        value={values.discoverProject}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className='border p-2 w-full rounded border-softBlack focus:border-softBlack focus:outline-none focus:ring-0'
                      >
                        <option value='' label='Select an option' />
                        <option value='Ads' label='Ads' />
                        <option value='Referral' label='Referral' />
                      </select>
                      {errors.discoverProject && touched.discoverProject && (
                        <p className='absolute bottom-0 text-red-500 text-xs mt-1 mb-0'>
                          {errors.discoverProject}
                        </p>
                      )}
                    </div>
                    <div className='relative w-full pb-4.5 flex flex-col'>
                      <label className='block text-sm font-medium text-gray-700 mb-1'>
                        Are you a REALTORS®/Broker?
                      </label>
                      <div className='flex-1 flex items-center'>
                        {
                          RADIO_OPTION.map((radio, index) => (
                            <label key={index} className='h-full flex items-center mr-4'>
                              <input
                                type='radio'
                                name='isRealtor'
                                value={radio.value}
                                checked={values.isRealtor === radio.value}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className='form-radio h-4 w-4 text-softBlack mb-0 focus:border-none focus:outline-none focus:ring-0 cursor-pointer'
                              />
                              <span className='ml-2 text-gray-700 cursor-pointer'>{radio.label}</span>
                            </label>
                          ))
                        }
                      </div>
                      {errors.isRealtor && touched.isRealtor && (
                        <p className='absolute bottom-0 text-red-500 text-xs mt-1 mb-0'>{errors.isRealtor}</p>
                      )}
                    </div>
                    <HCaptcha
                      sitekey={HCAPTCHA_KEY}
                      onVerify={setToken}
                      ref={captchaRef}
                    />
                    <CustomButton type='submit' className='col-span-2 w-full mt-5' disabled={loading}>
                      <span className='font-medium py-2 px-6 text-base flex gap-2 items-center'>
                        {loading && <Oval height='18' width='18' color='white' />}
                        {loading ? 'Submitting...' : 'Register'}
                      </span>
                    </CustomButton>
                  </Form>
                )}
              </Formik>
            </>
        }
      </div>
    </div>
  )
}

export default LeadsRegistrationForm
