import React, { useState, useCallback, useEffect } from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { Dropdown, Modal } from 'ui'
import { handleLogout } from 'store/actions/authActions'
import { useHistory } from 'react-router-dom'
import { ADMIN_PROFILE_PAGE, LAYOUT_MODE_SELECTOR, LOGOUT, SETTINGS } from 'routes'
import { TNotificationIcon, TSearchIcon, TMenuIcon } from 'components/Icons'

const HeaderContentWrapper = ({ children }) => (
  <div className='flex-1 px-4 flex justify-between'>
    {children}
  </div>
)

const NotificationsAndProfileWrapper = ({ children }) => (
  <div className='ml-4 flex items-center md:ml-6'>
    {children}
  </div>
)

const ProfileWrapper = ({ children }) => (
  <div className='ml-3 relative'>
    <div>
      {children}
    </div>
  </div>
)

const AppHeader = ({ setShowMenu, handleLogout, userObject: { firstName, userAvatar, userType } }) => {
  const [showLogoutModal, setShowLogoutModal] = useState(false)
  const [showChangeLayoutModal, setShowChangeLayoutModal] = useState(false)
  const [menuOptions, setMenuOptions] = useState([])

  const history = useHistory()

  const HeaderWrapper = useCallback(({ children }) =>
    <div
      className='relative flex-shrink-0 flex h-16
      bg-white border-b border-gray-200
      '
    >
      {children}
    </div>
  , [])

  const OpenSideBarButton = () => (
    <button
      onClick={() => {
        setShowMenu()
      }}
      className='xl:hidden px-4 border-r border-gray-200 text-gray-400 focus:outline-none
      focus:ring-2 focus:ring-inset focus:ring-cyan-500'
    >
      <span className='sr-only'>Open sidebar</span>
      <TMenuIcon className='h-6 w-6' />
    </button>
  )

  const SearchBar = () => (
    <div className='flex-1 flex'>
      <form className='w-full flex md:ml-0 p-0 shadow-none' action='#' method='GET'>
        <label htmlFor='search_field' className='sr-only'>Search</label>
        <div className='relative w-full text-gray-400 focus-within:text-gray-600'>
          <div className='absolute inset-y-0 left-0 flex items-center pointer-events-none' aria-hidden='true'>
            <TSearchIcon className='h-5 w-5' />
          </div>
          <input
            id='search_field'
            name='search_field'
            className='bg-white block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 focus:border-transparent sm:text-sm'
            placeholder='Search'
            type='search'
          />
        </div>
      </form>
    </div>
  )

  const NotificationsIcon = () => (
    <button className='bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500'>
      <span className='sr-only'>View notifications</span>
      <TNotificationIcon className='h-6 w-6' />
    </button>
  )

  const changeLayoutMode = useCallback(() => {
    history.push(LAYOUT_MODE_SELECTOR)
  }, [history])

  useEffect(() => {
    const options = [
      {
        title: 'Your Profile',
        onClick: () => history.push(ADMIN_PROFILE_PAGE)
      },
      {
        title: 'Change layout mode',
        onClick: () => setShowChangeLayoutModal(true)
      },
      {
        title: 'Logout',
        onClick: () => setShowLogoutModal(true)
      }
    ]

    // If is a developerAdmin allow to access the settings page
    if (userType === 'DeveloperAdmin') {
      options.splice(2, 0, {
        title: 'Settings',
        onClick: () => history.push(SETTINGS)
      })
    }

    setMenuOptions(options)
  }, [history, userType])

  const logout = useCallback(() => {
    handleLogout()
    history.push(LOGOUT)
  }, [handleLogout, history])

  const confirmLogoutModal = useCallback(() => {
    return (
      <Modal
        showModal={showLogoutModal}
        onCancel={() => setShowLogoutModal(false)}
        modalType='alert'
        title='Are you sure?'
        subtitle='Do you want to quit?'
        onOk={() => logout()}
      />
    )
  }, [logout, showLogoutModal, setShowLogoutModal])

  const confirmChangeLayoutModeModal = useCallback(() => {
    return (
      <Modal
        showModal={showChangeLayoutModal}
        onCancel={() => setShowChangeLayoutModal(false)}
        modalType='alert'
        title='Are you sure?'
        subtitle='Do you want to change the Layout Mode?'
        onOk={() => changeLayoutMode()}
      />
    )
  }, [changeLayoutMode, showChangeLayoutModal, setShowChangeLayoutModal])

  return (
    <HeaderWrapper>
      <OpenSideBarButton />
      <HeaderContentWrapper>
        <SearchBar />
        <NotificationsAndProfileWrapper>
          <NotificationsIcon />
          <ProfileWrapper>
            <Dropdown
              title={firstName}
              options={menuOptions}
              backgroundColor='bg-white'
              backgroundHoverColor='bg-none'
              border='border-none'
              shadow='shadow-none'
              focus='focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500'
              imgSrc={userAvatar}
              className='lg:hover:bg-gray-200'
              arrowDownIconColor='text-gray-400'
            />
          </ProfileWrapper>
        </NotificationsAndProfileWrapper>
      </HeaderContentWrapper>
      {confirmLogoutModal()}
      {confirmChangeLayoutModeModal()}
    </HeaderWrapper>
  )
}

const mapStateToProps = (state) => ({
  userObject: state.authReducer.userObject
})

const mapDispatchToProps = {
  handleLogout
}

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader)

AppHeader.propTypes = {
  setShowMenu: propTypes.bool,
  handleLogout: propTypes.func,
  userObject: propTypes.shape({
    firstName: propTypes.string,
    userAvatar: propTypes.string,
    userType: propTypes.string
  })
}

HeaderContentWrapper.propTypes = {
  children: propTypes.node
}

NotificationsAndProfileWrapper.propTypes = {
  children: propTypes.node
}

ProfileWrapper.propTypes = {
  children: propTypes.node
}
