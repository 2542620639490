/* eslint-disable no-unused-vars */
import { Select, Checkbox } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { ReactComponent as DownIcon } from 'assets/icons/down.svg'
import { toast } from 'react-hot-toast'
import { useSelector } from 'react-redux'
import { classNames } from 'utils'
import CustomInput from 'components/CustomInput'
import moment from 'moment'

const RecordPayment = ({
  setStep,
  reservation,
  createOrUpdateReservation,
  selectedBuyer,
  refetchReservationDetails
}) => {
  const appProjectSelector = (state) => state.appReducer.appProject
  const projectId = useSelector(appProjectSelector)
  const userIdSelector = (state) => state.authReducer.userObject?._id
  const userId = useSelector(userIdSelector)
  const [paymentStatus, setPaymentStatus] = useState(reservation?.paymentStatus)
  const [paymentReceivedBy, setPaymentReceivedBy] = useState(
    reservation?.paymentReceivedBy
  )
  const [paymentReceivedDate, setPaymentReceivedDate] = useState(
    moment(reservation?.paymentReceivedDate).format('YYYY-MM-DDTHH:mm')
  )

  const [paymentConsent, setPaymentConsent] = useState(
    reservation?.paymentConsent ?? true
  )
  const [isUpdateInProgress, setIsUpdateInProgress] = useState(false)
  const PAYMENT_STATUSES = ['Pending', 'Complete']

  useEffect(() => {
    if (reservation) {
      setPaymentStatus(reservation?.paymentStatus)
      setPaymentReceivedBy(reservation?.paymentReceivedBy)
      setPaymentReceivedDate(
        moment(reservation?.paymentReceivedDate).format('YYYY-MM-DDTHH:mm')
      )
      setPaymentConsent(reservation?.paymentConsent ?? true)
    }
  }, [reservation])

  const updateDetails = useCallback(async () => {
    if (
      paymentStatus?.length &&
      paymentReceivedDate &&
      paymentReceivedBy?.length &&
      paymentConsent
    ) {
      const toastId = toast.loading('Updating Details...')

      setIsUpdateInProgress(true)
      try {
        const dataObject = {
          _id: reservation?._id,
          paymentStatus,
          paymentReceivedDate,
          paymentReceivedBy,
          paymentConsent
        }
        await createOrUpdateReservation(projectId, dataObject)
        await refetchReservationDetails?.()
        toast.dismiss(toastId)
        toast.success('Details updated successfully')
      } catch {
        toast.dismiss(toastId)
      }
      setIsUpdateInProgress(false)
      setStep(2)
    } else {
      toast.error('Please fill fields...')
    }
  }, [
    projectId,
    reservation,
    createOrUpdateReservation,
    paymentConsent,
    paymentReceivedBy,
    paymentStatus,
    paymentReceivedDate
  ])

  return (
    <>
      <div
        className={classNames(
          'p-3 text-lg font-bold grid gap-x-12 gap-y-4 h-full w-1/2 grid-cols-1',
          isUpdateInProgress && 'opacity-30'
        )}
      >
        <div className='bg-gray-100 flex flex-col gap-10 justify-center items-center rounded-3xl p-4'>
          <div className='flex justify-between items-center w-4/5 pt-5'>
            <h1 className='text-4xl text-italic'>Record Payment</h1>
            <div
              className='flex justify-center items-center font-bold py-3  px-12 border border-black rounded cursor-pointer'
              onClick={() => setStep(2)}
            >
              Exit
            </div>
          </div>
          <div className='flex flex-col gap-3 w-full items-center'>
            <div className='flex flex-col w-4/5 items-start justify-start gap-2'>
              <div className='font-normal'>Payment Status</div>
              <div className='w-full'>
                <Select
                  suffixIcon={<DownIcon />}
                  showArrow
                  placeholder='Select'
                  value={paymentStatus}
                  onChange={(value) => {
                    setPaymentStatus(value)
                  }}
                  style={{ height: '40px', width: '100%' }}
                  className='rating-select flex align-center w-full text-sm p-2 bg-white border border-softBlack30 h-12 rounded text-softBlack placeholder-softBlack70'
                >
                  {PAYMENT_STATUSES.map?.((_item, index) => (
                    <Select.Option value={_item} key={index}>
                      <div
                        className='flex gap-2 items-center'
                        style={{ zIndex: '1500 !important' }}
                      >
                        <span>{_item}</span>
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
            <div className='flex flex-col w-4/5 items-start justify-start gap-2'>
              <div className='font-normal'>RECIEVED BY</div>
              <div className='w-full'>
                <CustomInput
                  label=''
                  placeholder='Enter name of reciever'
                  value={paymentReceivedBy}
                  onChange={(e) => {
                    setPaymentReceivedBy(e.target.value)
                  }}
                  type='text'
                  containerClasses={undefined}
                  classes=''
                  required
                />
              </div>
            </div>
            <div className='flex flex-col w-4/5 items-start justify-start gap-2'>
              <div className='font-normal'>DATE RECIEVED</div>
              <div className='w-full'>
                <CustomInput
                  label=''
                  value={paymentReceivedDate}
                  onChange={(e) => {
                    setPaymentReceivedDate(
                      moment(e.target.value).format('YYYY-MM-DDTHH:mm')
                    )
                  }}
                  type='datetime-local'
                  classes='uppercase'
                  min={moment().format('YYYY-MM-DD')}
                />
              </div>
            </div>
            <div className='flex items-start justify-start w-4/5'>
              <Checkbox
                className='font-normal'
                checked={true}
                onChange={(e) => {
                  setPaymentConsent(true)
                }}
              >
                {`I confirm that I have recieved payment from ${selectedBuyer?.firstName} ${selectedBuyer?.lastName}`}
              </Checkbox>
            </div>
            <button
              className='flex justify-center items-center font-bold py-4  px-12 bg-volt rounded cursor-pointer'
              onClick={updateDetails}
            >
              Save Payment
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
export default RecordPayment
