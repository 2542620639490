import styled from 'styled-components'
import standardStyle from 'assets/css/standardStyle'

export const LargeTabsContainer = styled.div`
  padding: 20px;
`
export const BottomLine = styled.div`
    width: 100%;
    height: 1px;
    border-bottom: 1px solid rgba(46, 43, 46, 0.3);
`
export const Tab = styled.button`
  font-family: 'Test Untitled Sans', system-ui, sans-serif;
  font-size: 20px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 60px;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${standardStyle.colors.softBlack};
  background: ${standardStyle.colors.white};
  border: 1.5px solid ${standardStyle.colors.softBlack} !important;
  padding: 7px 27px;
  border: 0;
  outline: 0;
  flex: 1;
  border-right: unset !important;
  ${({ active }) =>
    active &&
    `
    color: ${standardStyle.colors.white};
    background: ${standardStyle.colors.softBlack};
    opacity: 1;
  `}
  &:last-child {
    border-right: 1.5px solid ${standardStyle.colors.softBlack} !important;
  }
`
export const ButtonGroup = styled.div`
  display: flex;
  width: 100%;
`
