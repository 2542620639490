import React from 'react'
import NewBrokerLeadPage from './NewBrokerLeadPage/NewBrokerLeadPage'

const CreateBrokerLeadWrapper = () => {
  return (
    <div>
      <NewBrokerLeadPage />
    </div>
  )
}

export default CreateBrokerLeadWrapper
