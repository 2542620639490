export const lowRiskOptions = [
  { id: 'resident_present', value: 'Canadian Citizen or Resident Physically Present' },
  { id: 'resident_not_present', value: 'Canadian Citizen or Resident Not Physically Present' },
  { id: 'resident_high_crime_area', value: 'Canadian Citizen or Resident – High Crime Area – No Other Higher Risk Factors Evident' },
  { id: 'foreign_citizen', value: 'Foreign Citizen or Resident that does not Operate in a High Risk Country (physically present or not)' },
  { id: 'explain', value: 'Other, explain:' }
]

export const mediumRiskOptions = [
  { id: 'explain', value: 'Explain' }
]

export const highRiskOptions = [
  { id: 'foreign_citizen', value: 'Foreign Citizen or Resident that operates in a High Risk Country (physically present or not) ' },
  { id: 'explain', value: 'Other Explain' }
]

export const RISK_TYPES = [
  { label: 'Low Risk', value: 'Low' },
  { label: 'Medium Risk', value: 'Medium' },
  { label: 'High Risk', value: 'High' }
]

export const RISK_TYPES_OPTIONS = {
  Low: lowRiskOptions,
  Medium: mediumRiskOptions,
  High: highRiskOptions
}

export const BUYING_AS = [
  { id: 1, label: 'Yes', value: true },
  { id: 1, label: 'No', value: false }
]

export const AGENT_CHECKBOXES = [
  { id: 1, label: 'Residential property' },
  { id: 2, label: 'Commercial property' },
  { id: 3, label: 'Residential property for income purposes' },
  { id: 4, label: 'Land for commercial use' },
  { id: 5, label: 'Other' }
]
