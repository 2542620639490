import React from 'react'
import ProjectPayments from '../components/ProjectPayments'

const AddPayments = ({ setStep }) => {
  return (
    <ProjectPayments setStep={setStep} />
  )
}

export default AddPayments
