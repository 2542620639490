/* eslint-disable */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ContactContext } from 'pages/contacts/context/Contacts';
import { getUserDataByType } from 'utils/getUserDataByType';
import CustomSelect from './CustomSelect';
import toast from 'react-hot-toast';
import useUpdateUsers from 'hooks/useUpdateUsers';
import { GetDemandNotes } from 'store/actions/demandNotes';
// import CustomInput from 'components/CustomInput'
// import moment from 'moment'

const DemandSelectionsView = ({ contact }) => {
  const { createOrUpdateUser } = useUpdateUsers();
  const { dropdownData, updateContact } = useContext(ContactContext);
  const { userType } = contact ?? {};
  const userDataObject = getUserDataByType(userType, contact);
  // const [selectedUnitInterests, setSelectedUnitInterests] = useState(userDataObject.unitInterests)
  const [selectedBudgetOption, setSelectedBudgetOption] = useState(
    userDataObject?.budget
  );
  const [selectedTimeLineToPurchase, setSelectedTimeLineToPurchase] = useState(
    userDataObject?.timelineToPurchase
  );
  const [selectedProjectInterests, setSelectedProjectInterests] = useState(
    userDataObject?.projectInterests ?? []
  );
  const [selectedFloorInterests, setSelectedFloorInterests] = useState(
    userDataObject?.floorInterests
  );
  const [selectedNeedsFinancing, setSelectedNeedsFinancing] = useState(
    userDataObject?.needsFinancing
  );

  const [customDemands, setCustomDemands] = useState(
    userDataObject?.customDemands ?? []
  );
  // const [selectedPropertyUse, setSelectedPropertyUse] = useState(userDataObject.propertyUse ?? '')
  // const [selectedDiscoverProject, setSelectedDiscoverProject] = useState(userDataObject.discoverProject ?? '')
  // const [selectedInterestedInBoating, setSelectedInterestedInBoating] = useState(userDataObject.interestedInBoating ?? '')
  // const [selectedMarketingQualifiedLeadDate, setSelectedMarketingQualifiedLeadDate] = useState(userDataObject.marketingQualifiedLeadDate ?? '')
  // const [selectedPreferredMethodOfContact, setSelectedPreferredMethodOfContact] = useState(userDataObject.preferredMethodOfContact ?? '')
  const [extraDemands, setExtraDemands] = useState([]);
  const handleSelectionChange = async (value, name, setValue, key) => {
    if (!value) return;
    if(name === 'Project Interests') {
      const newProjectInterests = value.map((item) => ({
        project: item,
      }));
      setValue([...newProjectInterests]);
    } else {
      setValue(value);
    }
    const prevValue = { ...contact };
    const newUserData = { ...contact };
    if (newUserData?.userType === 'Buyer') {
      if(name === 'Project Interests') {
        const newProjectInterests = value.map((item) => ({
          project: item,
        }));
        newUserData.buyerData[key] = newProjectInterests;
      }
      else {
        newUserData.buyerData[key] = value;
      }
    } else if (newUserData?.userType === 'LeadBroker') {
      if(name === 'Project Interests') {
        const newProjectInterests = value.map((item) => ({
          project: item,
        }));
        newUserData.leadBrokerData[key] = newProjectInterests;
      } else {
        newUserData.leadBrokerData[key] = value;
      }
    }
    try {
      updateContact(newUserData);
      await createOrUpdateUser(newUserData, false, false);
      toast.success(`${name} updated successfully`);
    } catch (err) {
      setValue(prevValue);
      console.error(`${name} couldn't be updated`, err);
    }
  };

  const getCustomDemandFields = async () => {
    const data  = await GetDemandNotes();
    if (data) {
      const formatedData = await data.map((item) => {
        return {
          label: item.label,
          options: item.options.map((option) => {
            return {
              label: option.label,
              value: option.value,
              _id: option.value,
            };
          }),
        };
      });
      setExtraDemands(formatedData);
    }
  };

  useEffect(() => {
    getCustomDemandFields();
  }, []);

  return (
    <div className='p-4 flex-1 flex flex-col gap-y-3 text-xs items-center'>
      {/* <CustomSelect
        label='Unit Interests'
        value={selectedUnitInterests}
        handleChange={(value) => handleSelectionChange(
          value,
          'Unit Interests',
          setSelectedUnitInterests,
          'unitInterests'
        )}
        options={dropdownData.unitInterests}
        mode='tags'
        isDemandNotes
        isName
      /> */}
      <CustomSelect
        label='Budget'
        value={selectedBudgetOption}
        handleChange={(value) =>
          handleSelectionChange(
            value,
            'Budget',
            setSelectedBudgetOption,
            'budget'
          )
        }
        options={dropdownData.budgetOptions}
        isDemandNotes
        isName
      />
      <CustomSelect
        label='Timeline to Purchase'
        value={selectedTimeLineToPurchase}
        handleChange={(value) =>
          handleSelectionChange(
            value,
            'Timeline to Purchase',
            setSelectedTimeLineToPurchase,
            'timelineToPurchase'
          )
        }
        options={dropdownData.timelineToPurchase}
        isDemandNotes
        isName
      />
      <CustomSelect
        label='Project Interests'
        value={selectedProjectInterests?.map((item) => item.project)}
        handleChange={(value) => {
          handleSelectionChange(
            value,
            'Project Interests',
            setSelectedProjectInterests,
            'projectInterests'
          )
        }
        }
        options={dropdownData.projectInterests}
        mode='tags'
        isDemandNotes
        isName
      />
      <CustomSelect
        label='Floor Interests'
        value={selectedFloorInterests}
        handleChange={(value) =>
          handleSelectionChange(
            value,
            'Floor Interests',
            setSelectedFloorInterests,
            'floorInterests'
          )
        }
        options={dropdownData.floorInterests}
        mode='tags'
        isDemandNotes
        isName
      />
      <CustomSelect
        label='Needs Financing'
        value={selectedNeedsFinancing}
        handleChange={(value) =>
          handleSelectionChange(
            value,
            'Needs Financing',
            setSelectedNeedsFinancing,
            'needsFinancing'
          )
        }
        options={dropdownData.needFinancing}
        isDemandNotes
        isName
      />
      {extraDemands &&
        extraDemands.map((demand, index) => {
          return (
            <CustomSelect
              key={index}
              label={demand.label}
              value={
                customDemands.find(
                  (customDemand) => customDemand.label === demand.label
                )?.value ?? ''
              }
              handleChange={(value) => {
                const newCustomDemands = [...customDemands];
                const demandIndex = newCustomDemands.findIndex(
                  (customDemand) => customDemand.label === demand.label
                );
                if (demandIndex === -1) {
                  newCustomDemands.push({ label: demand.label, value: value });
                } else {
                  newCustomDemands[demandIndex] = {
                    label: demand.label,
                    value: value,
                  };
                }
                setCustomDemands(newCustomDemands);
                const newUserData = { ...contact };
                if (newUserData?.userType === 'Buyer') {
                  newUserData.buyerData.customDemands = newCustomDemands;
                } else if (newUserData?.userType === 'LeadBroker') {
                  newUserData.leadBrokerData.customDemands = newCustomDemands;
                }
                try {
                  updateContact(newUserData);
                  createOrUpdateUser(newUserData, false, false);
                  toast.success(`${demand.label} updated successfully`);
                } catch (err) {
                  setCustomDemands(customDemands);
                  console.error(`${demand.label} couldn't be updated`, err);
                }
              }}
              options={demand.options}
              isDemandNotes
              isName
            />
          );
        })}
      {/* <CustomSelect
        label='How would you use your Whitecap property?'
        value={selectedPropertyUse}
        handleChange={(value) => handleSelectionChange(
          value,
          'The way of using Whitecap property',
          setSelectedPropertyUse,
          'propertyUse'
        )}
        options={dropdownData.demoOptions}
        isDemandNotes
      />
      <CustomSelect
        label='How did you discover Whitecap?'
        value={selectedDiscoverProject}
        handleChange={(value) => handleSelectionChange(
          value,
          'The way of you find Whitecap property',
          setSelectedDiscoverProject,
          'discoverProject'
        )}
        options={dropdownData.demoOptions}
        isDemandNotes
      />
      <CustomSelect
        label='Are You Interested in Boating?'
        value={selectedInterestedInBoating}
        handleChange={(value) => handleSelectionChange(
          value,
          'Boating Interests',
          setSelectedInterestedInBoating,
          'interestedInBoating'
        )}
        options={dropdownData.interestedInBoatingOptions}
        isDemandNotes
      />
      <CustomInput
        label="Became a marketing qualified lead date"
        placeholder="MM/DD/YYYY"
        value={selectedMarketingQualifiedLeadDate ?? ''}
        onChange={(e) => handleSelectionChange(
          e.target.value,
          'Marketing qualified lead date',
          setSelectedMarketingQualifiedLeadDate,
          'marketingQualifiedLeadDate'
        )}
        type="date"
        classes="uppercase"
        min={moment().format('YYYY-MM-DD')}
        containerClasses='w-full'
      />
      <CustomSelect
        label='What is your preferred method of contact?'
        value={selectedPreferredMethodOfContact}
        handleChange={(value) => handleSelectionChange(
          value,
          'Preferred method of contact',
          setSelectedPreferredMethodOfContact,
          'preferredMethodOfContact'
        )}
        options={dropdownData.demoOptions}
        isDemandNotes
      /> */}
    </div>
  );
};

export default DemandSelectionsView;
