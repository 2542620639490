/* eslint-disable */
// @ts-nocheck
import { Dropdown, Space } from 'antd'
import { Check, ChevronDown } from 'lucide-react'
import React, { useMemo } from 'react'

const CustomDropdown = ({
  title,
  values,
  setValues,
  options,
  handleChange,
  placement = 'bottomRight'
}) => {
  const handleClickEvent = (crrValue) => {
    let temp = []
    const existedColumn = values.filter(column => column?.value === crrValue)
    if (existedColumn?.length > 0) {
      temp = values.filter(column => column.value !== crrValue)
    } else {
      temp = [
        ...values,
        options.filter((option) => option.value === crrValue)[0]
      ]
    }
    handleChange(temp)
    setValues(temp)
  }

  const items = useMemo(() => {
    return options.map((option, index) => {
      return {
        key: index,
        label: (
          <span
            className='flex items-center gap-2'
            onClick={() => handleClickEvent(option.value)}
          >
            <span className='w-4'>
              {
                values?.filter((elm) => elm?.value === option?.value)?.length > 0 &&
                <Check className='w-4 h-4' />
              }
            </span>
            {option.label}
          </span>
        )
      }
    })
  }, [options])

  return (
    <Dropdown
      menu={{ items }}
      placement={placement}
      className='px-4 py-2 flex items-center gap-x-2 rounded bg-white border border-[#2E2B2E] border-opacity-[15%] cursor-pointer'
    >
      <Space className='text-base text-softBlack flex items-center'>
        {title}
        <ChevronDown className='w-5 h-5' />
      </Space>
    </Dropdown>
  )
}

export default CustomDropdown
