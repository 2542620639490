import React from 'react'
import CustomModal from 'components/Modal'
import CustomButton from 'components/CustomButton'

const CancelModal = ({ isOpenCancelModal, setIsOpenCancelModal, onConfirm, cancelMessage }) => {
  const closeModal = () => {
    setIsOpenCancelModal(false)
  }
  return (
    <CustomModal isOpen={isOpenCancelModal} closeModal={closeModal} contentLabel="Cancel Modal">
      <div className='w-136 max-w-full p-10 text-center rounded bg-grey-8'>
        <h1 className='text-softBlack font-bold text-xl mb-0'>Cancel</h1>
        <p className='mb-0 mt-6 text-softBlack text-base'>{cancelMessage}</p>
        <div className='flex justify-center items-center gap-6 mt-8'>
          <CustomButton variant='outline' handleClick={closeModal}>
            <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
              No
            </span>
          </CustomButton>
          <CustomButton handleClick={onConfirm}>
            <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
              Yes, cancel it
            </span>
          </CustomButton>
        </div>
      </div>
    </CustomModal>
  )
}

export default CancelModal
