/* eslint-disable */
import React, { useEffect, useState } from 'react';

import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import { Oval } from 'react-loader-spinner';

import { Modal } from '@material-ui/core';
import CustomInput from 'components/CustomInput';
import CustomTextArea from 'components/CustomTextArea';
import { useSelector } from 'react-redux';
import { createOrUpdateUnitGroup } from 'store/actions/unitGroupsActions';
import CustomButton from 'components/CustomButton';

const AddUnitGroupModal = ({
  show,
  setShow,
  editDetails,
  setEditDetails,
  refetch,
}) => {
  const { appProject: projectId } = useSelector((state) => state.appReducer);
  const initialEmptyValues = {
    name: '',
    description: '',
    _id: '',
    project: projectId,
  };
  const [value, setValues] = useState({
    ...initialEmptyValues,
  });

  const onChange = (fieldName, newValue) => {
    setValues({ ...value, [fieldName]: newValue });
  };

  const handleClose = () => {
    setShow(false);
    setEditDetails({});
  };
  const validate = () => {
    if (!value.name) {
      return false;
    }
    if (!value.description) {
      return false;
    }
    return true;
  };

  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    if (validate()) {
      setLoading(true);
      createOrUpdateUnitGroup(value).then((data) => {
        refetch();
        setLoading(false);
        setShow(false);
        setEditDetails({});
      });
    }
  };

  useEffect(() => {
    if (
      editDetails?.name ||
      (editDetails?.description && editDetails.type === 'Unit Groups')
    ) {
      setValues({ ...value, ...editDetails });
    } else {
      setValues(initialEmptyValues);
    }
  }, [editDetails]);

  return (
    <Modal
      open={show}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div
        className='font-openSans relative bg-grey-8 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-10 rounded shadow-lg'
        style={{ width: 544 }}
      >
        <button className='absolute right-10' onClick={handleClose}>
          <CrossIcon />
        </button>
        <div className='flex flex-col gap-6'>
          <h1 className='font-bold text-xl text-center p-0 m-0'>
            Add Unit Group
          </h1>
          <CustomInput
            label='Name *'
            placeholder='Enter name'
            labelClassName='italic'
            value={value.name}
            onChange={(e) => {
              onChange('name', e.target.value);
            }}
            required='required'
            errorMessage='Please enter name'
            containerClasses={undefined}
            classes={undefined}
          />
          <CustomTextArea
            labelClasses='italic'
            placeholder='Enter description'
            label='Description *'
            value={value.description}
            onChange={(e) => {
              onChange('description', e.target.value);
            }}
            required='required'
            errorMessage='Please enter description'
            textareaClasses={undefined}
            containerClasses={undefined}
            style={undefined}
          />
          <CustomButton handleClick={handleSubmit} disabled={!validate() || loading} className='m-auto'>
            <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
              {loading && <Oval height='18' width='18' color='white' />}
              {value?._id ? 'Update' : 'Add'} Unit Group
            </span>
          </CustomButton>
        </div>
      </div>
    </Modal>
  );
};

export default AddUnitGroupModal;
