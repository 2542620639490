import React from 'react'

const CustomBadge = ({
  title = '',
  bgColor = ''
}) => {
  return (
    <div
      style={{ backgroundColor: bgColor + '40' }}
      className='w-fit px-[18px] py-1 bg-opacity-25 rounded-lg items-center gap-3 flex'
    >
      <div
        className='w-[13.88px] h-[13.88px] rounded-full'
        style={{ backgroundColor: bgColor }}
      />
      <div className='text-zinc-950 text-lg font-semibold'>{title}</div>
    </div>
  )
}

export default CustomBadge
