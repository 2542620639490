import React, { createContext, useState } from 'react'

export const StorageInventoryMapContext = createContext({
  openFilterDrawer: () => {},
  closeFilterDrawer: () => {},
  openStorageInfoDrawer: () => {},
  closeStorageInfoDrawer: () => {},
  setSearch: () => {},
  setFilters: () => {},
  resetFilters: () => {},
  state: {
    isFilterDrawerOpen: true,
    isStorageInfoDrawerOpen: true,
    search: '',
    filters: {}
  }
})

const StorageInventoryMapContextProvider = ({ children }) => {
  const [state, setState] = useState({
    isFilterDrawerOpen: false,
    isStorageInfoDrawerOpen: false,
    search: '',
    filters: {}
  })

  const openFilterDrawer = () => {
    setState({
      ...state,
      isFilterDrawerOpen: true
    })
  }

  const closeFilterDrawer = () => {
    setState({ ...state, isFilterDrawerOpen: false })
  }

  const setFilters = (filters) => setState({ ...state, filters })
  const resetFilters = () => {
    setState({
      ...state,
      filters: {}
    })
  }

  const openStorageInfoDrawer = () => {
    setState({
      ...state,
      isStorageInfoDrawerOpen: true
    })
  }
  const closeStorageInfoDrawer = () => {
    setState({ ...state, isStorageInfoDrawerOpen: false })
  }

  const setSearch = (search) => setState({ ...state, search })

  return (
    <StorageInventoryMapContext.Provider
      value={{
        state,
        setFilters,
        resetFilters,
        openFilterDrawer,
        closeFilterDrawer,
        openStorageInfoDrawer,
        closeStorageInfoDrawer,
        setSearch
      }}
    >
      {children}
    </StorageInventoryMapContext.Provider>
  )
}

export default StorageInventoryMapContextProvider
