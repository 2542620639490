import { useCallback, useState } from 'react'
import { getUsers } from 'store/actions/usersActions'

const useGetSalesReps = () => {
  const [salesReps, setSalesReps] = useState([])
  const [loading, setLoading] = useState(false)

  const getData = useCallback(async () => {
    setLoading(true)
    try {
      const data = await getUsers('', '?userType=SalesRep')
      setSalesReps(data)
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }, [])

  return { loading, salesReps, refetch: getData }
}

export default useGetSalesReps
