import React, { memo, useEffect, useState } from 'react'
import propTypes from 'prop-types'
import { CameraIcon } from '@heroicons/react/outline'

import { Wrapper } from './styles'
import { AnimatePresence } from 'framer-motion'
import { TDeleteButton } from 'components'
import { TButton } from 'ui'
import { isArray } from 'utils'

const Title = memo(({ title }) => <span className='text-lg'>{title}</span>)

const Icon = memo(({ photo }) => (
  <AnimatePresence exitBeforeEnter>
    {
      photo
        ? <img src={photo} alt='preview' className='h-14 w-28 rounded-md object-fill' />
        : <CameraIcon className='h-16 w-16 text-indigo-600' />
    }
  </AnimatePresence>
))

const Button = memo(({ photo, onClick, onRemovePhoto, loading, disabled }) => (
  <AnimatePresence exitBeforeEnter>
    {
      photo
        ? <TDeleteButton disabled={disabled} loading={loading} onClick={onRemovePhoto}>Remove</TDeleteButton>
        : <TButton disabled={disabled} loading={loading} onClick={onClick}>Take</TButton>
    }
  </AnimatePresence>
))

const CameraButton = ({ photo, onClick, onRemovePhoto, title, loading, disabled }) => {
  const [localPhoto, setLocalPhoto] = useState('')

  useEffect(() => {
    setLocalPhoto('')

    if (photo && isArray(photo) && photo.length) {
      setLocalPhoto(photo[0].url)
    }
  }, [photo])

  return (
    <Wrapper className='flex items-center justify-center rounded-md border border-gray-200 py-8 px-12 flex-col space-y-3'>
      <Title title={title} />

      <Icon photo={localPhoto} />

      <Button disabled={disabled} loading={loading} photo={localPhoto} onClick={onClick} onRemovePhoto={onRemovePhoto} />
    </Wrapper>
  )
}

export default CameraButton

CameraButton.propTypes = {
  photo: propTypes.string,
  onClick: propTypes.func,
  onRemovePhoto: propTypes.func,
  title: propTypes.string,
  disabled: propTypes.string
}

Button.propTypes = {
  photo: propTypes.string,
  onClick: propTypes.func,
  onRemovePhoto: propTypes.func
}

Icon.propTypes = {
  photo: propTypes.string
}

Title.propTypes = {
  title: propTypes.string
}
