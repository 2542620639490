/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import moment from 'moment'

import DeleteModal from 'components/DeleteModal'

import { ReactComponent as EditIcon } from 'assets/icons/edit.svg'
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg'

import NewMeeting from 'components/NewMeeting'
import { deleteCalendarEvent } from 'store/actions/usersActions'
import { getCalenderDateMomentUsingObjectType } from 'utils'
import { ReactComponent as PeopleIcon } from '../../assets/icons/icn_people.svg'
import { ReactComponent as PlaceIcon } from '../../assets/icons/icn_place.svg'

const MeetingCard = ({ meeting, contact, onSuccess, buyers }) => {
  const [loading, setLoading] = useState(false)
  const [isDeleteModal, setIsDeleteModal] = useState(false)
  const [isEditModal, setIsEditModal] = useState(false)

  const onConfirmDelete = async () => {
    setLoading(true)
    try {
      await deleteCalendarEvent(meeting.id)
      setIsDeleteModal(false)
      onSuccess()
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  // if (meeting?.when?.object !== 'timespan') {
  //   return null
  // }

  const startDate = moment(getCalenderDateMomentUsingObjectType(meeting.when.object, meeting.when.start_time))

  return (
    <>
      <div className='relative group border border-softBlack_10 bg-[#F4F4F4] p-4 flex flex-col gap-y-2 rounded'>
        <div className='text-base font-normal text-softBlack_70'>{startDate.format('hh:mm A')}</div>
        <div className='text-lg font-medium'>
          {meeting?.title}
        </div>
        <div className='text-base font-normal text-softBlack_70 capitalize flex items-center gap-1'>
          <PeopleIcon className='h-4 w-4' />
          {meeting.participants.map((elm) => elm.name).join(', ').toLowerCase()}
          {/* {moment(getCalenderDateMomentUsingObjectType(meeting.when.object, meeting.when.start_time)).format('YYYY/MM/DD hh:mm A')} - {moment(getCalenderDateMomentUsingObjectType(meeting.when.object, meeting.when.end_time)).format('YYYY/MM/DD hh:mm A')} */}
        </div>
        <div className='text-base font-normal text-softBlack_70 capitalize flex items-center gap-1'>
          <PlaceIcon className='h-4 w-4' />
          {meeting.location}
        </div>
        <div className='absolute bottom-4 right-4 flex gap-4 invisible group-hover:visible'>
          <button onClick={() => setIsEditModal(true)}><EditIcon /></button>
          <button onClick={() => setIsDeleteModal(true)}><TrashIcon /></button>
        </div>
      </div>
      <DeleteModal
        title='Confirm deletion?'
        description='This meeting will be deleted permanently!'
        confirmButtonTitle='Delete Meeting'
        cancelButtonTitle='Cancel'
        isOpen={isDeleteModal}
        setIsOpen={setIsDeleteModal}
        onConfirm={onConfirmDelete}
        loading={loading}
      />
      {isEditModal ? <NewMeeting isOpen={isEditModal} setOpen={setIsEditModal} contact={contact} meeting={meeting} onSuccess={onSuccess} buyers={buyers}/> : null}
    </>
  )
}

export default MeetingCard
