import { api, ApiErrors } from 'services/api'
import { message } from 'antd'

export const getFloorPlans = (id = '') => new Promise((resolve, reject) => {
  api.get(`floorplans/${id}`)
    .then((response) => {
      if (response.ok) {
        resolve(response.data.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const createOrUpdateFloorPlan = (props) => new Promise((resolve, reject) => {
  const {
    _id, name, floorPlanImage,
    parkingQty, parkingType, project
  } = props

  // If id, so we have to update the record, or we're gonna create
  const verb = _id ? 'put' : 'post'
  const url = _id ? `floorplans/${_id}` : 'floorplans'

  const params = new FormData()
  params.append('name', name)
  params.append('parkingQty', parkingQty)
  params.append('parkingType', parkingType)
  params.append('project', project)
  if (typeof floorPlanImage === 'object') {
    params.append('image', floorPlanImage)
  }

  api[verb](url, params)
    .then((response) => {
      if (response.ok) {
        message.success(`${props.name} was ${_id ? 'successfully updated' : 'created successfully'}`)
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const deleteFloorPlan = (id) => new Promise((resolve, reject) => {
  api.delete(`/floorplans/${id}`)
    .then((response) => {
      if (response.ok) {
        message.success('Floor Plan deleted successfully')
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
})
