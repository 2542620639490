/*eslint-disable */
// @ts-nocheck
import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { getProjects } from 'store/actions/projectActions'


const useGetCurrentProject = () => {
  const projectId = useSelector((state) => state.appReducer.appProject)
  const { data, isLoading, refetch } = useQuery(
    ['getProject', projectId],
    () => getProjects(projectId),
    { refetchOnMount: true, refetchOnReconnect: true, refetchOnWindowFocus: true, retry: false, enabled: !!projectId }
  )
  return { data, isLoading, refetch }
}

export default useGetCurrentProject