import { api, ApiErrors } from 'services/api'

export const requestVopayUrl = () => new Promise((resolve, reject) => {
  api.get('/payments/eft/iq11')
    .then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const updateVopayUserToken = (params) => new Promise((resolve, reject) => {
  const { token, buyer } = params

  api.post('/payments/eft/iq11', { token, buyer })
    .then((response) => {
      if (response.ok) {
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
})
