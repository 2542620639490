import React from 'react'
import { ReactComponent as Done } from '../../assets/icons/done.svg'

const ProgressSteps = ({ steps = [], currentStepIndex = 0, className = '', iconStyle = {}, ActiveIconStyle = {} }) => {
  return (
    <div className={`bg-white flex flex-col space-y-3 rounded-lg ${className}`}>
      {steps.map((step, i) => {
        const Icon = step.icon
        const iconstyle = i === currentStepIndex ? iconStyle : ActiveIconStyle
        return (
          <div key={i}>
            {i !== 0 && <div className='border-l-2 h-8 ml-5 rounded-md w-0' />}
            <div
              className={'flex space-x-5 items-center text-gray-700'}>
              <div className='rounded-full bg-zinc-100 h-11 w-11 min-w-11 min-h-11 flex justify-center items-center p-1'>
                { Number(i) < Number(currentStepIndex)
                  ? (
                  <Done className="opacity-30" />

                    )
                  : (
                  <Icon iconStyle={iconstyle} />
                    ) }
              </div>
              <div>
                <span className="font-openSans italic font-light text-xs">STEP {i + 1}</span>
                <h3 className='flex flex-col min-w-[6rem] flex-grow text-lg font-bold font-openSans'>{step?.text}</h3>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default ProgressSteps
