import { Buffer } from 'buffer'
import { ReactComponent as ICON_AI } from 'assets/icons/icn_file_ai.svg'
// import { ReactComponent as ICON_CRD } from 'assets/icons/icn_file_crd.svg'
import { ReactComponent as ICON_CSV } from 'assets/icons/icn_file_csv.svg'
// import { ReactComponent as ICON_DOCX } from 'assets/icons/icn_file_docx.svg'
// import { ReactComponent as ICON_EXE } from 'assets/icons/icn_file_exe.svg'
import { ReactComponent as ICON_GIF } from 'assets/icons/icn_file_gif.svg'
import { ReactComponent as ICON_HTML } from 'assets/icons/icn_file_html.svg'
// import { ReactComponent as ICON_ISO } from 'assets/icons/icn_file_iso.svg'
// import { ReactComponent as ICON_JAVA } from 'assets/icons/icn_file_java.svg'
import { ReactComponent as ICON_JPG } from 'assets/icons/icn_file_jpg.svg'
import { ReactComponent as ICON_PDF } from 'assets/icons/icn_file_pdf.svg'
import { ReactComponent as ICON_PNG } from 'assets/icons/icn_file_png.svg'
import { ReactComponent as ICON_PSD } from 'assets/icons/icn_file_psd.svg'
// import { ReactComponent as ICON_RAR } from 'assets/icons/icn_file_rar.svg'
import { ReactComponent as ICON_XML } from 'assets/icons/icn_file_xml.svg'
import { getEmailAttachment, getEmailAttachmentV3 } from 'store/actions/usersActions'
import { AiOutlineFile } from 'react-icons/ai'

global.Buffer = Buffer

export const EMAIL_ATTACHMENT_SVG = {
  'application/postscript': ICON_AI,
  // 'image/svg+xml': ICON_CRD,
  'text/csv': ICON_CSV,
  // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ICON_DOCX,
  // 'application/octet-stream': ICON_EXE,
  'image/gif': ICON_GIF,
  'text/html': ICON_HTML,
  // 'iso': ICON_ISO,
  // 'text/x-java-source': ICON_JAVA,
  'image/jpg': ICON_JPG,
  'image/jpeg': ICON_JPG,
  'application/pdf': ICON_PDF,
  'image/png': ICON_PNG,
  'image/vnd.adobe.photoshop': ICON_PSD,
  // 'rar': ICON_RAR,
  'text/xml': ICON_XML,
  csv: ICON_CSV,
  gif: ICON_GIF,
  jpg: ICON_JPG,
  jpeg: ICON_JPG,
  pdf: ICON_PDF,
  png: ICON_PNG,
  default: AiOutlineFile
}

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const downloadAttachement = async (file) => {
  try {
    const result = await getEmailAttachment({ attachmentId: file.id })
    const buffer = Buffer.from(result)
    const blob = new Blob([buffer])
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    document.body.appendChild(a)
    // a.style = "display: none"
    a.href = url
    a.download = file.filename
    a.click()
    window.URL.revokeObjectURL(url)
  } catch (e) {
    console.error('downloadAttachement Error', file.filename, e)
  }
}

export const downloadAttachementNewV3 = async (file, threadId = '', message) => {
  try {
    const { data } = await getEmailAttachmentV3({ attachmentId: file.id, threadId: threadId, message: message })
    const buffer = Buffer.from(data)
    const blob = new Blob([buffer])
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    document.body.appendChild(a)
    // a.style = "display: none"
    a.href = url
    a.download = file.filename
    a.click()
    window.URL.revokeObjectURL(url)
  } catch (e) {
    console.error('downloadAttachement Error', file.filename, e)
  }
}
