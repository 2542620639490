import React from 'react'
import propTypes from 'prop-types'
import UserRoundedImage from 'components/UserRoundedImage'
import Flag from 'react-world-flags'
import { Tooltip } from 'antd'

const UserRoundedImageWithCountryFlag = ({
  userAvatarSrc, userAvatarImageSize, countryCode, countryFlagSize, countryFlagPosition,
  tooltip, tooltipPlacement
}) => (
  <Tooltip
    placement={tooltipPlacement}
    title={tooltip}
    trigger={['hover', 'click']}
  >
    <div className='relative'>
      <UserRoundedImage
        src={userAvatarSrc}
        className={userAvatarImageSize}
      />
      <div className={`${countryFlagSize} overflow-hidden flex rounded-full absolute ${countryFlagPosition}`}>
        <Flag code={countryCode} className='object-cover' />
      </div>
    </div>
  </Tooltip>
)

export default UserRoundedImageWithCountryFlag

UserRoundedImageWithCountryFlag.defaultProps = {
  userAvatarImageSize: 'h-12 w-12',
  countryFlagSize: 'h-7 w-7',
  countryFlagPosition: '-right-2 -top-2',
  tooltip: '',
  tooltipPlacement: 'top'
}

UserRoundedImageWithCountryFlag.propTypes = {
  userAvatarSrc: propTypes.string,
  userAvatarImageSize: propTypes.string,
  countryCode: propTypes.string,
  countryFlagSize: propTypes.string,
  countryFlagPosition: propTypes.string,
  tooltip: propTypes.string,
  tooltipPlacement: propTypes.string
}
