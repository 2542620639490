// @ts-nocheck
/* eslint-disable */
import React, { useEffect, useState } from "react";
import CustomSelect from "./CustomSelect";
import { Select } from "antd";
import { ReactComponent as DownIcon } from "../../../assets/icons/down.svg";
import { getSearchContacts, getUsers } from "store/actions/usersActions";

const SelectionsView = ({
  contact,
  userType,
  handleLeadStatusChange,
  leadStatuses,
  handleConnectionChange,
  rating,
  handleRatingChange,
  scores,
  salesRep,
  salesRepChange,
  salesReps,
  userObject,
  projectId,
  buyerEmail,
  isLeadSourceAvailable,
  selectedLeadSource,
  setSelectedLeadSource,
  handleLeadSourceChange,
  leadSources,
  brokerageCompany,
  setBrokerageCompany,
  handleBrokerageCompanyChange,
  companies,
  referralAgent,
  handleReferralAgentChange,
  referralAgents,
  broker,
  handleBrokerChange,
  brokers,
  isReasonForBuyerLostAvailable,
  selectedReasonForBuyerLost,
  setSelectedReasonForBuyerLost,
  handleReasonForBuyerLostChange,
  reasonsForBuyerLost,
  isBuyerLeadApproachAvailable,
  selectedLeadApproachForBuyer,
  setSelectedLeadApproachForBuyer,
  handleBuyerLeadApproachChange,
  leadApproachesForBuyer,
  selectedConnections,
  contactTypes,
  handleContactTypeChange,
}) => {
  const handleSearchUser = async (value, setSearchedUsers) => {
    let { data } = await getSearchContacts({
      search: value,
      userType: userType,
    });

    const formattedData = data.map((user) => {
      return {
        value: user._id,
        label: `${user.firstName} ${user.lastName}`,
        _id: user._id,
        id: user._id,
      };
    });
    setSearchedUsers(formattedData);
  };

  const [selectedUsers, setSelectedUsers] = useState([]);

  useEffect(() => {
    const fetchConnections = async () => {
      if (contact?.buyerData?.connections) {
        const connections = await Promise.all(
          contact.buyerData.connections.map(async (connection) => {
            const data = await getUsers(connection);
            return {
              value: data._id,
              label: `${data.firstName} ${data.lastName}`,
              _id: data._id,
              id: data._id,
            };
          })
        );
        setSelectedUsers(connections);
      }
    };
    fetchConnections();
  }, [contact]);

  const ConnectionSearch = () => {
    const [searchedUsers, setSearchedUsers] = useState([]);

    return (
      <div className="w-full flex flex-col gap-y-1.5">
        <div className="text-softBlack_70/50 w-full text-xs top-6 font-normal uppercase">
          Connections
        </div>
        <Select
          mode="multiple"
          style={{
            width: "100%",
          }}
          showArrow
          placeholder="Search connections"
          getPopupContainer={(node) => node.parentNode}
          onChange={(value) => {
            handleConnectionChange(value);
          }}
          value={selectedUsers}
          className="ant-picker-input1"
          suffixIcon={<DownIcon />}
          showSearch={true}
          tokenSeparators={[","]}
          onSearch={(value) => {
            handleSearchUser(value, setSearchedUsers);
          }}
          filterOption={false}
          optionFilterProp="_id"
        >
          {searchedUsers?.map((contact) => (
            <Select.Option label={contact.label} value={contact.value}>
              {contact.label}
            </Select.Option>
          ))}
        </Select>
      </div>
    );
  };
  return (
    <div className="p-4 max-h-full flex flex-col gap-y-3 text-xs items-center">
      <CustomSelect
        label="Lead Status"
        value={
          contact?.buyerData?.leadStatus || contact?.leadBrokerData?.leadStatus
        }
        handleChange={handleLeadStatusChange}
        options={leadStatuses}
        isLeadStatus
      />
      <CustomSelect
        label="Rating"
        value={rating}
        handleChange={handleRatingChange}
        options={scores}
      />
      {userType === "Buyer" && <ConnectionSearch />}
      {userType === "Buyer" && (
        <CustomSelect
          label="Contact Type"
          value={contact?.buyerData.contactType || 'Select Contact Type'}
          handleChange={handleContactTypeChange}
          options={contactTypes}
        />
      )}
      {(userType === "Buyer" || userType === "LeadBroker") && (
        <CustomSelect
          label="Sales Rep"
          value={salesRep?._id}
          handleChange={(salesRepId) => {
            salesRepChange(
              { create: !salesRep?.firstName },
              {
                salesRep: salesRepId,
                developerAdmin: userObject?._id,
                project: projectId,
                buyer: buyerEmail,
              }
            );
          }}
          options={salesReps}
          showSearch
          isName
        />
      )}

      {isLeadSourceAvailable ? (
        <CustomSelect
          label="Lead Source"
          value={selectedLeadSource}
          handleChange={(value) => {
            setSelectedLeadSource(value);
            handleLeadSourceChange(value);
          }}
          options={leadSources}
          showSearch
        />
      ) : null}
      {userType === "LeadBroker" ? (
        <CustomSelect
          label="Brokerage Company"
          value={brokerageCompany}
          handleChange={(value) => {
            setBrokerageCompany(value);
            handleBrokerageCompanyChange(value);
          }}
          options={companies?.values}
          showSearch
        />
      ) : null}
      <CustomSelect
        label="Referral Agent"
        value={referralAgent}
        handleChange={(value) => handleReferralAgentChange(value)}
        options={referralAgents}
        showSearch
        isName
      />
      {userType === "Buyer" || userType === "Lead" ? (
        <CustomSelect
          label="Broker"
          value={broker}
          handleChange={(value) => handleBrokerChange(value)}
          options={brokers}
          showSearch
          isName
        />
      ) : null}
      {isReasonForBuyerLostAvailable ? (
        <CustomSelect
          label="Reason For Lost"
          value={selectedReasonForBuyerLost}
          handleChange={(value) => {
            setSelectedReasonForBuyerLost(value);
            handleReasonForBuyerLostChange(value);
          }}
          options={reasonsForBuyerLost}
          showSearch
        />
      ) : null}
      {isBuyerLeadApproachAvailable ? (
        <CustomSelect
          label="Lead Approach"
          value={selectedLeadApproachForBuyer}
          handleChange={(value) => {
            setSelectedLeadApproachForBuyer(value);
            handleBuyerLeadApproachChange(value);
          }}
          options={leadApproachesForBuyer}
          showSearch
        />
      ) : null}
    </div>
  );
};

export default SelectionsView;
