import { Col } from 'antd'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import { convertHexToRgbA } from 'utils'
import standardStyle from 'assets/css/standardStyle'

export const Wrapper = styled(Col)`
  background: white;
  border-radius: 10px;
  padding: 20px;
`

export const HeaderWrapper = styled.div``

export const LeftContent = styled.div`
  position: absolute;
  height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const PanelWrapper = styled.div`
  /* margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 30px;
  overflow: scroll;
  ::-webkit-scrollbar {display:none}; */
`

export const UnitCard = styled(motion.div)`
  box-shadow: 0 ${props => props.externalSelectedUnit._id === props._id ? '2px 4px 4px' : '0 0 0'} rgba(0,0,0,0.09),
              0 ${props => props.externalSelectedUnit._id === props._id ? '5px 8px 8px' : '0 0 0'} rgba(0,0,0,0.09),
              0 ${props => props.externalSelectedUnit._id === props._id ? '8px 12px 12px' : '0 0 0'} rgba(0,0,0,0.09),
              0 ${props => props.externalSelectedUnit._id === props._id ? '10px 16px 16px' : '0 0 0'} rgba(0,0,0,0.09);
`

export const LoadingUnitsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-top: -70px;
`

export const OptionButtonWrapper = styled.div`
  border-radius: 4px;
  background: ${props => props.selected && convertHexToRgbA(standardStyle.colors.primary, 1)};
  box-shadow: 0 0 2px 2px rgba(0,0,0,0.07);
  padding: 5px 10px;
  margin: 5px 0px;
  display: flex;
  justify-content: center;
`
