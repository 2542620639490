import React, { useState } from 'react'

import { COLORS } from './constants/colors'
import { RISK_TYPES } from './constants/Fintrac'

import { useAnimation } from 'framer-motion'
import { fadeVariants } from 'utils'
import Button from 'components/Buttons'
import DeleteModal from 'components/DeleteModal'

import { PwaWrapper } from 'components'
import Header from './components/Header'

import PWABuildings from './components/PWABuildings'
import PWAUnitsInventory from './components/PWAUnitsInventory'
import PWASelectBuyer from './components/PWASelectBuyer'
import PWASelectBroker from './components/PWASelectBroker'
import PWAParking from './components/PWAParking'
import PWAPrivacyConsent from './components/PWAPrivacyConsent'
import PWAFintrac from './components/PWAFintrac'
import PWAReview from './components/PWAReview'

import { PWA_PROJECT_SCREEN } from 'routes'
import { useHistory } from 'react-router-dom'

const CreateOfferNew = () => {
  const history = useHistory()
  const animatedWrapper = useAnimation()

  // Current Page in Create Offer and Cancel Modal
  const [currentPage, setCurrentPage] = useState(1)
  const [showCancelModal, setShowCancelModal] = useState(false)

  // Next button state
  const [buttonState, setButtonState] = useState('Disabled')

  // Progress Bar
  const [progressPercentage, setProgressPercentage] = useState(0.08)

  // Buyers
  const [selectedBuyers, setSelectedBuyers] = useState([])

  // Broker
  const [selectedBroker, setSelectedBroker] = useState({})

  // Selected Unit
  const [selectedUnit, setSelectedUnit] = useState('')

  // Selected Parking
  const [selectedParking, setSelectedParking] = useState([])

  // Fintrac
  const [isThirdParty, setIsThirdParty] = useState(true)
  const [riskType, setRiskType] = useState(RISK_TYPES[0].value)
  const [selectedOption, setSelectedOption] = useState('')
  const [checkedProperties, setCheckedProperties] = useState([])
  const [businessRelationshipExplanation, setBusinessRelationshipExplanation] =
    useState('')
  const [thirdPartDescription, setThirdPartDescription] = useState('')
  const [thirdPartRecord, setThirdPartRecord] = useState({
    address: '',
    birthday: '',
    incorporationCountry: '',
    incorporationJurisdiction: '',
    incorporationNumber: '',
    name: '',
    occupation: '',
    phoneNumber: '',
    relationship: ''
  })
  const [riskExplanation, setRiskExplanation] = useState('')

  const goToNextPage = () => {
    if (currentPage < 8 && buttonState !== 'Disabled') {
      setCurrentPage((prevPage) => prevPage + 1)
      setButtonState('Disabled')
      setProgressPercentage((prevPercentage) => prevPercentage + 0.14)
    }
  }

  const goToPreviousPage = () => {
    setCurrentPage((currentPage) => currentPage - 1)
    setButtonState('Disabled')
    setProgressPercentage((prevPercentage) => prevPercentage - 0.14)
  }

  const handleCancel = () => {
    history.push(PWA_PROJECT_SCREEN)
  }

  const renderButtons = () => (
    <div style={{ backgroundColor: COLORS.lightGray }}>
      <div
        style={{
          margin: '48px 80px 0px 80px',
          paddingBottom: 64
        }}
        className={`flex ${
          currentPage > 1 ? 'justify-between' : 'justify-end'
        }`}
      >
        {currentPage > 1 && currentPage !== 8 && (
          <Button
            buttonClasses='justify-self-end font-openSans px-8 py-4'
            label='Back'
            type='Secondary'
            textColor={COLORS.softBlack}
            buttonBorder={`1px solid ${COLORS.softBlack70}`}
            fontWeight='300'
            labelSize='20px'
            handleClick={goToPreviousPage}
            labelFontStyle='italic'
          />
        )}
        {currentPage !== 8 && (
          <Button
            buttonClasses='justify-self-end font-openSans px-8 py-4'
            label='Next'
            type='Primary'
            textColor={COLORS.softBlack}
            buttonBorder={`1px solid ${COLORS.volt}`}
            fontWeight='300'
            labelSize='20px'
            handleClick={goToNextPage}
            labelFontStyle='italic'
            state={buttonState}
          />
        )}
      </div>
    </div>
  )

  const renderPage = () => (
    <>
      <div style={{ backgroundColor: COLORS.lightGray }}>
        {currentPage === 1 && <PWABuildings setButtonState={setButtonState} />}
        {currentPage === 2 && (
          <PWAUnitsInventory
            setButtonState={setButtonState}
            selectedUnit={selectedUnit}
            setSelectedUnit={setSelectedUnit}
          />
        )}
        {currentPage === 3 && (
          <PWASelectBuyer
            setButtonState={setButtonState}
            selectedBuyers={selectedBuyers}
            setSelectedBuyers={setSelectedBuyers}
          />
        )}
        {currentPage === 4 && (
          <PWASelectBroker
            setButtonState={setButtonState}
            selectedBroker={selectedBroker}
            setSelectedBroker={setSelectedBroker}
          />
        )}
        {currentPage === 5 && (
          <PWAParking
            setButtonState={setButtonState}
            selectedParking={selectedParking}
            setSelectedParking={setSelectedParking}
          />
        )}
        {currentPage === 6 && (
          <PWAPrivacyConsent setButtonState={setButtonState} />
        )}
        {currentPage === 7 && (
          <PWAFintrac
            isThirdParty={isThirdParty}
            setIsThirdParty={setIsThirdParty}
            riskType={riskType}
            setRiskType={setRiskType}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            checkedProperties={checkedProperties}
            setCheckedProperties={setCheckedProperties}
            businessRelationshipExplanation={businessRelationshipExplanation}
            setBusinessRelationshipExplanation={
              setBusinessRelationshipExplanation
            }
            thirdPartDescription={thirdPartDescription}
            setThirdPartDescription={setThirdPartDescription}
            thirdPartRecord={thirdPartRecord}
            setThirdPartRecord={setThirdPartRecord}
            riskExplanation={riskExplanation}
            setRiskExplanation={setRiskExplanation}
            setButtonState={setButtonState}
          />
        )}
        {currentPage === 8 && (
          <PWAReview
            setButtonState={setButtonState}
            selectedUnit={selectedUnit}
            selectedBuyers={selectedBuyers}
            selectedBroker={selectedBroker}
            selectedParking={selectedParking}
            isThirdParty={isThirdParty}
            riskType={riskType}
            selectedOption={selectedOption}
            checkedProperties={checkedProperties}
            businessRelationshipExplanation={businessRelationshipExplanation}
            thirdPartDescription={thirdPartDescription}
            thirdPartRecord={thirdPartRecord}
            riskExplanation={riskExplanation}
          />
        )}
      </div>
    </>
  )

  return (
    <>
      <DeleteModal
        title='Cancel'
        description='Are you sure you want to cancel Creating Offer'
        isOpen={showCancelModal}
        cancelButtonTitle='No'
        setIsOpen={() => {
          setShowCancelModal(false)
        }}
        confirmButtonTitle='Yes, cancel it'
        onConfirm={handleCancel}
        loading={undefined}
      />
      <PwaWrapper
        // @ts-ignore
        animate={animatedWrapper}
        initial={false}
        variants={fadeVariants}
      >
        <Header
          setShowCancelModal={setShowCancelModal}
          progressPercentage={progressPercentage}
        />
        {renderPage()}
        {renderButtons()}
      </PwaWrapper>
    </>
  )
}

export default CreateOfferNew
