import SidePanel from 'components/SidePanel/SidePanel'
import { DashboardContext } from 'pages/dashboard/context/DashboardContext'
import React, { useCallback, useContext, useEffect, useState } from 'react'

const useSidebar = () => {
  const _DashboardContext = useContext(DashboardContext)
  const { openSidePanel, setOpenSidePanel } = _DashboardContext
  const [fullScreen, setFullScreen] = useState(false)

  useEffect(() => {
    if (!openSidePanel) {
      setFullScreen(false)
    }
  }, [openSidePanel])

  const showPanel = useCallback(() => setOpenSidePanel(true), [])
  const hidePanel = useCallback(() => setOpenSidePanel(false), [])

  const Sidebar = useCallback(
    ({ children, title, isFullScreen = false, onClickFullProfile = null }) => (
      <SidePanel
        key={title ?? 'SIDE-PANEL'}
        title={title}
        open={openSidePanel}
        setOpen={setOpenSidePanel}
        fullScreen={isFullScreen}
        onClickFullProfile={onClickFullProfile}
      >
        {children}
      </SidePanel>
    ),
    [openSidePanel, setOpenSidePanel, SidePanel]
  )

  return {
    Sidebar,
    fullScreen,
    setFullScreen,
    showPanel,
    hidePanel,
    ..._DashboardContext
  }
}

export default useSidebar
