import CustomButton from 'components/CustomButton'
import CopyIt from 'components/Utils/CopyToClipboard'
import useGetCommunicationTemplatesByType from 'hooks/useGetCommunicationTemplatesByType'
import React, { useEffect, useState } from 'react'
import { sanitizeCode } from 'utils'
const TAB = {
  mergeFields: 'mergeFields',
  templates: 'templates'
}

const tabs = [
  {
    key: TAB.mergeFields,
    label: 'Merge Fields'
  },
  {
    key: TAB.templates,
    label: 'Templates'
  }
]

// const templates = [
//   {
//     title: 'Welcome Email',
//     content:
//       'Welcome to PXP\n\nHi {{firstName}},\n\nWe are excited to have you on board. We are here to help you with your real estate needs.\n\nBest,\nThe PXP Team'
//   },
//   {
//     title: 'Follow-Up Email',
//     content:
//       "Hi {{firstName}},\n\nJust checking in to see if you had any questions or needed further assistance. We're here to help!\n\nBest regards,\n{{senderName}}"
//   },
//   {
//     title: 'Thank You Email',
//     content:
//       'Hi {{firstName}},\n\nThank you for choosing PXP for your real estate needs. We appreciate your trust in us and look forward to serving you.\n\nSincerely,\nThe PXP Team'
//   },
//   {
//     title: 'Appointment Reminder',
//     content:
//       'Hi {{firstName}},\n\nThis is a friendly reminder about your upcoming appointment with PXP on {{appointmentDate}} at {{appointmentTime}}. We look forward to meeting with you.\n\nBest,\nThe PXP Team'
//   },
//   {
//     title: 'Newsletter',
//     content:
//       'Hello {{firstName}},\n\nWelcome to the latest edition of the PXP Newsletter! Stay tuned for updates, tips, and news about the real estate market.\n\nBest,\nThe PXP Team'
//   },
//   {
//     title: 'Feedback Request',
//     content:
//       'Hi {{firstName}},\n\nWe hope you had a great experience with PXP. We would love to hear your feedback to improve our services. Please take a moment to share your thoughts.\n\nThank you,\nThe PXP Team'
//   },
//   {
//     title: 'Service Update',
//     content:
//       "Hi {{firstName}},\n\nWe wanted to inform you about some exciting updates to our services. Check out what's new and how it can benefit you.\n\nBest,\nThe PXP Team"
//   },
//   {
//     title: 'Special Offer',
//     content:
//       "Hi {{firstName}},\n\nWe have a special offer just for you! For a limited time, enjoy exclusive benefits with PXP. Don't miss out!\n\nBest regards,\nThe PXP Team"
//   },
//   {
//     title: 'Seasonal Greetings',
//     content:
//       'Hi {{firstName}},\n\nWishing you a wonderful holiday season! May this time be filled with joy and prosperity for you and your loved ones.\n\nHappy holidays,\nThe PXP Team'
//   },
//   {
//     title: 'Event Invitation',
//     content:
//       'Hi {{firstName}},\n\nYou are invited to our upcoming event! Join us on {{eventDate}} for an exciting time with the PXP community. We hope to see you there.\n\nBest,\nThe PXP Team'
//   }
// ]

const MergeFieldsAndTemplates = (
  {
    mergeTags
  }
) => {
  const [selectedTab, setSelectedTab] = useState(TAB.mergeFields)
  const [keyFilterText, setKeyFilterText] = useState('')
  const { loading: isCommunicationTemplatesLoading, communicationTemplates, getCommunicationTemplates } = useGetCommunicationTemplatesByType('email')

  useEffect(() => {
    getCommunicationTemplates()
  }, [])

  return (
    <div className='px-2 my-2 max-w-sm min-w-sm'>
      <div className='flex justify-between border border-bottom bg-white rounded-md overflow-hidden'>
        {tabs.map(tab => (
          <button
            key={tab.key}
            className={`flex-1 text-softBlack text-base font-medium py-2 px-6 flex justify-center items-center ${selectedTab === tab.key ? 'bg-softBlack text-white' : 'hover:bg-white'} transition-all`}
            onClick={() => setSelectedTab(tab.key)}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div className='flex flex-col gap-y-2 py-2'>
        {selectedTab === TAB.mergeFields && (
          <div>
            <div className='flex gap-x-1'>
              <input type='text' placeholder='Search merge fields' className='border border-border mb-0 rounded-md' onChange={(e) => setKeyFilterText(e.target.value)} />
              <CustomButton handleClick={() => setKeyFilterText('')}>
                <span className='font-medium px-3 py-1 text-base flex gap-2 items-center'>
                  Clear
                </span>
              </CustomButton>
            </div>
            <div className='flex flex-col gap-2 my-3 justify-start overflow-y-scroll max-h-[70vh]'>
              {mergeTags
                .filter(mergeTag => mergeTag.key.toLowerCase().includes(keyFilterText.toLowerCase()))
                .map((mergeTag, index) => {
                  const key = `{{${mergeTag.key}}}`
                  return <div key={index} className='flex justify-between bg-white px-2 items-center py-1'>
                    <p className='text-base font-bold pb-0 mb-0'>{mergeTag.label}</p>
                    <CopyIt size="sm" content={key}></CopyIt>
                  </div>
                })}
            </div>
          </div>
        )}
        {selectedTab === TAB.templates && (
          <div>
            <div className='flex flex-col gap-2 my-3 justify-start overflow-y-scroll max-h-[75vh]'>
              {
                isCommunicationTemplatesLoading
                  ? <div className='flex-1 flex items-center justify-center flex-col'>
                    <h1 className='text-center text-xl py-6 mb-0'>
                      Email templates loading...
                    </h1>
                  </div>
                  : <>
                    {
                      communicationTemplates?.length > 0
                        ? <>
                          {communicationTemplates.map(template => (
                            <div key={template.id} className='flex gap-y-0.5 justify-start flex-col bg-white px-2 py-2'>
                              <p className='h-full text-base font-bold'>{template.title}</p>
                              <div className='flex-1 *:mb-0 overflow-y-auto thin-scrollbar mb-3' dangerouslySetInnerHTML={{ __html: sanitizeCode(template.content) ?? '' }} />
                              <CopyIt content={template.content}></CopyIt>
                            </div>
                          ))}
                        </>
                        : <div className='flex-1 flex items-center justify-center flex-col'>
                          <h1 className='text-center text-xl py-6 mb-0'>
                            Email templates not found
                          </h1>
                        </div>
                    }
                  </>
              }
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default MergeFieldsAndTemplates
