/* eslint-disable no-unused-vars */
// @ts-nocheck
import React, { useContext, useEffect, useState, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import CustomInput from 'components/CustomInput'
import CreateOfferSuccessModal from './CreateOfferSuccessModal'
import standardStyle from 'assets/css/standardStyle'
import { ReactComponent as DownIcon } from 'assets/icons/down.svg'
import { CreateOfferContext } from '../context/CreateOfferContext'
import {
  cadFormatter,
  getCurrenyType,
  formatMoney,
  formatValue
} from 'utils/formatters'

import { getOfferContracts } from 'store/actions/offerActions'
import { MessageModal } from 'components/MessageModal'
import { Modal } from '@material-ui/core'
import { message, Select } from 'antd'
import stripeLogo from 'assets/img/logo-stripe.svg'

import PaymentTypeModal from './PaymentTypeModal'
import useGetProjectDepositOptions from 'hooks/useGetProjectDepositOptions'
import PaymentMethodModal from './PaymentMethodModal'
import CustomButton from 'components/CustomButton'

const SelectDeposit = ({ setStep }) => {
  const { selectedUnit, setDepositOptions } = useContext(CreateOfferContext)
  const [isSuccessModal, setIsSuccessModal] = useState(false)
  const [offerRes, serOfferRes] = useState({})
  const [showOfferCreateMessageModal, setShowOfferCreateMessageModal] =
    useState(false)
  const [showOfferCompleteMessageModal, setShowOfferCompleteMessageModal] =
    useState(false)
  const [showCreditCardPaymentModal, setShowCreditCardPaymentModal] =
    useState(false)
  const [showPaymentModal, setShowPaymentModal] = useState(false)
  const [dashboardRedirect, setDashboardRedirect] = useState(false)
  const { loading, depositOptions } = useGetProjectDepositOptions()

  const [showPaymentTypeModal, setShowPaymentTypeModal] = useState(false)
  const [selectedDepositOption, setSelectedDepositOption] = useState(null)
  const [showPaymentMethodModal, setShowPaymentMethodModal] = useState(false)
  const [paymentMethodId, setPaymentMethodId] = useState(null)
  const [successfulPayment, setSuccessfulPayment] = useState(false)

  const history = useHistory()

  const onGoToContract = () => {
    getOfferContracts(offerRes._id)
    setIsSuccessModal(false)
  }

  const onBack = () => setStep((prev) => prev - 1)
  const onNext = () => {
    setStep((prev) => prev + 1)
  }
  const showCreditCardForm = () => {
    setShowCreditCardPaymentModal(true)
    setShowPaymentTypeModal(false)
    setShowPaymentModal(false)
  }
  const showOfferCompleteModal = () => {
    setShowOfferCompleteMessageModal(true)
    setShowCreditCardPaymentModal(false)
  }
  useEffect(() => {
    if (showPaymentModal) {
      showCreditCardForm()
    }
  }, [showPaymentModal])

  useEffect(() => {
    if (depositOptions?.length > 0) {
      setSelectedDepositOption(depositOptions[0]._id)
    }
  }, [depositOptions])

  useEffect(() => {
    if (dashboardRedirect) {
      history.push('/admin')
    }
  }, [dashboardRedirect])

  const selectedDepositOptionData = useMemo(() => {
    const result = depositOptions?.filter(
      (item) => item._id === selectedDepositOption
    )?.[0]
    return result
  }, [depositOptions, selectedDepositOption])

  const discountPrice = useMemo(() => {
    return (
      selectedUnit?.price *
      (selectedDepositOptionData?.discountPercentage / 100)
    )
  }, [selectedUnit, selectedDepositOptionData])

  const totalPrice = () => {
    return selectedUnit?.price - discountPrice || 0
  }

  const discountedPrice = useMemo(() => {
    if (!selectedDepositOptionData) {
      return null
    }
    return {
      discountPercentage: discountPrice,
      optionPercentages: selectedDepositOptionData?.optionPercentages.map(
        (item) => {
          return totalPrice() * (item / 100)
        }
      )
    }
  }, [discountPrice, selectedDepositOptionData])

  useEffect(() => {
    if (discountedPrice) {
      const newDepositOption = {
        optionName: selectedDepositOptionData?.depositOptionName,
        discount: {
          percentage: selectedDepositOptionData?.discountPercentage,
          value: discountPrice
        },
        installments: [
          ...selectedDepositOptionData?.optionPercentages.map((item, index) => {
            return {
              name: `Deposit ${index + 1}`,
              percentage: item,
              value: discountedPrice?.optionPercentages?.[index]
            }
          })
        ]
      }
      setDepositOptions({
        ...newDepositOption,
        id: selectedDepositOptionData._id
      })
    }
  }, [discountedPrice])
  return (
    <div>
      <CreateOfferSuccessModal
        {...{
          open: isSuccessModal,
          setOpen: setIsSuccessModal,
          onConfirm: onGoToContract
        }}
      />
      <div className='pb-4 border-b-2 border-softBlack'>
        <p className='my-0 text-xl font-bold'>Deposit Options</p>
      </div>
      <div className='my-10' style={{ height: '100hv' }}>
        <div className='flex flex-col mt-6'>
          <span
            className='text-softBlack_70 font-bold italic'
            style={{ fontSize: 12 }}
          >
            RESIDENCE
          </span>
          <span
            className='text-softBlack font-bold mt-3'
            style={{ fontSize: 24 }}
          >
            {'#' + selectedUnit?.unitNumber}
          </span>
        </div>
        <div className='flex flex-col mt-6'>
          <span
            className='text-softBlack_70 font-bold italic'
            style={{ fontSize: 12 }}
          >
            LIST PRICE
          </span>
          <span
            className='text-softBlack font-bold mt-3'
            style={{ fontSize: 24 }}
          >
            {formatMoney(selectedUnit?.price)}
          </span>
        </div>

        <div className='flex flex-col my-6'>
          <span
            className='text-softBlack_70 font-bold italic mb-3'
            style={{ fontSize: 17 }}
          >
            Deposit Options
          </span>
          <Select
            placeholder='Select Deposit Options'
            showArrow
            getPopupContainer={(node) => node.parentNode}
            suffixIcon={loading ? undefined : <DownIcon />}
            value={selectedDepositOption}
            filterOption={(input, option) =>
              (option?.label ?? '')
                .toString()
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            onChange={(value) => {
              setSelectedDepositOption(value)
            }}
            className='ant-picker-input max-w-60 w-60'
          >
            {!loading &&
              depositOptions?.filter((depositOption) => (depositOption?.isActivated))?.map((item, index) => (
                <Select.Option
                  key={item?._id}
                  label={item?.depositOptionName ?? `Option ${index + 1}`}
                  value={item?._id}
                >
                  {item?.depositOptionName ?? `Option ${index + 1}`}
                </Select.Option>
              ))}
          </Select>
        </div>
        <div className='grid grid-cols-5 gap-4 mt-3'>
          {selectedDepositOptionData?.optionPercentages?.map(
            (option, index) => (
              <CustomInput
                label={`DEPOSIT ${index + 1}`}
                value={`${option}%`}
                disabled
                loading={!selectedDepositOptionData}
              />
            )
          )}
          <CustomInput
            label={'DISCOUNT'}
            value={
              !selectedDepositOptionData
                ? ''
                : `${selectedDepositOptionData?.discountPercentage}%`
            }
            disabled
            loading={!selectedDepositOptionData}
          />
        </div>
        <div className='grid grid-cols-5 gap-4 mt-3'>
          {discountedPrice?.optionPercentages?.map((option, index) => (
            <CustomInput
              label={`DEPOSIT ${index + 1}`}
              value={
                !discountedPrice ? '' : `${getCurrenyType()}${formatValue(option)}`
              }
              disabled
              loading={!discountedPrice}
            />
          ))}
          <CustomInput
            label={'DISCOUNT'}
            value={
              !discountedPrice
                ? ''
                : `${getCurrenyType()}${formatValue(
                  discountedPrice?.discountPercentage
                )}`
            }
            disabled
            loading={!discountedPrice}
          />
        </div>
      </div>
      <div className='flex justify-between items-stretch gap-4 m-0 p-0'>
        <div className='flex flex-col mt-6'>
          <span
            className='text-softBlack_70 font-bold italic uppercase'
            style={{ fontSize: 17 }}
          >
            Final List Price
          </span>
          <span
            className='text-softBlack font-bold mt-3'
            style={{ fontSize: 32 }}
          >
            {formatMoney(totalPrice())}
          </span>
        </div>
        <div className='flex items-end gap-6'>
          <CustomButton variant='outline' handleClick={onBack} disabled={loading}>
            <span className='font-medium py-3 px-4 text-base flex gap-2 items-center'>
              Go Back
            </span>
          </CustomButton>
          <CustomButton handleClick={onNext} disabled={loading}>
            <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
              Continue
            </span>
          </CustomButton>
        </div>
      </div>
      <MessageModal
        show={showOfferCreateMessageModal}
        setShow={setShowOfferCreateMessageModal}
        title='Success'
        message={'Your offer is created. Now you can send it to the Buyer'}
        primaryBtn={true}
        primaryBtnText={'Go to Contract'}
        setPrimaryBtnAction={(value) => setShowPaymentTypeModal(value)}
      />
      <PaymentTypeModal
        {...{
          showPaymentTypeModal,
          setShowPaymentTypeModal,
          setShowPaymentMethodModal,
          setPaymentMethodId,
          setSuccessfulPayment
        }}
      />
      <PaymentMethodModal
        {...{
          showPaymentMethodModal,
          setShowPaymentMethodModal,
          paymentMethodId,
          successfulPayment,
          setSuccessfulPayment
          // setShowPaymentModal
        }}
      />
      <Modal
        open={showCreditCardPaymentModal}
        onClose={() => setShowCreditCardPaymentModal(false)}
        aria-labelledby='Credit Card Payments'
        aria-describedby='Credit Card Payments'
      >
        <div
          className='font-openSans relative bg-grey-8 absolute p-10 rounded shadow-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'
          style={{
            width: 1376,
            height: '760px',
            overflowY: 'auto',
            fontFamily: standardStyle.fonts.openSans
          }}
        >
          <div
            className='flex justify-center items-center flex-col'
            style={{ height: '100%' }}
          >
            <h2
              style={{
                fontSize: '20px',
                fontWeight: '700',
                marginTop: '30px',
                fontFamily: standardStyle.fonts.openSans
              }}
            >
              Pay Deposit With Credit Card
            </h2>
            <div
              className='flex gap-6 flex-wrap flex-col justify-center'
              style={{ margin: '30px', marginTop: '14px', width: '887px' }}
            >
              <CustomInput
                label={'Name on Card'}
                placeholder='Enter first name'
                type='text'
              />
              <CustomInput
                label={'Credit card number'}
                placeholder='xxxx-xxxx-xxxx-xxxx'
                type='text'
              />
              <div className='grid grid-cols-2 gap-4'>
                <CustomInput
                  label={'Exipry date'}
                  placeholder='MM/YY'
                  type='text'
                />
                <CustomInput
                  label={'CVV Number'}
                  placeholder='***'
                  type='text'
                />
              </div>
              <p
                className='font-bold mt-2 text-softBlack text-left'
                style={{ fontSize: 22, width: '100%' }}
              >
                Due within 7 days
              </p>
              <div className='flex justify-between items-center gap-4 m-0 p-0'>
                <p className='text-2xl p-0 m-0 font-bold text-softBlack'>
                  {cadFormatter.format(totalPrice())}
                </p>
                <div className='flex gap-6'>
                  <CustomButton variant='outline' handleClick={() => setShowPaymentTypeModal(false)} disabled={loading}>
                    <span className='font-medium placeholder py-3 px-6 text-base flex gap-2 items-center'>
                      Close
                    </span>
                  </CustomButton>
                  <CustomButton handleClick={showOfferCompleteModal} disabled={loading}>
                    <span className='font-medium placeholder py-3 px-6 text-base flex gap-2 items-center'>
                      Pay Now
                    </span>
                  </CustomButton>
                </div>
              </div>
              <div className='flex justify-center'>
                <img src={stripeLogo} alt={'stripe logo'} />
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <MessageModal
        show={showOfferCompleteMessageModal}
        setShow={setShowOfferCompleteMessageModal}
        title='Success'
        message={'Offer flow completed'}
        primaryBtn={true}
        primaryBtnText={'Return to Dashboard'}
        setPrimaryBtnAction={setDashboardRedirect}
        modalWidth={'1376px'}
        modalHeight={'760px'}
      />
    </div>
  )
}

export default SelectDeposit
