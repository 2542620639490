import { Row } from 'antd'

import styled from 'styled-components'

export const CarouselWrapper = styled.div`
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const DirectionalIconWrapper = styled.div`
  top: calc(50% - 20px);
  position: absolute;
  background: white;
  border-radius: 30px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 30px;
  z-index: 2;
`

export const PaginationWrapper = styled(Row)``
