import Logo from 'components/LoginPage/Logo'
import React from 'react'
import background from 'assets/img/bg.png'
import { Oval } from 'react-loader-spinner'

const Welcome = ({ onClick, isLoadingData }) => {
  return (
    <div className='h-screen w-full flex'>
      <div className='p-8 bg-stone-100 w-full md:w-1/3'>
        <div className='relative flex flex-col justify-center items-center h-full'>
          <Logo />
          <div className='max-w-md w-full' style={{ margin: 'auto' }}>
            {isLoadingData && (
              <div className='flex flex-col w-full items-center justify-center content-center center'>
                <h1 className='text-xl font-bold'>Loading contract...</h1>
                <Oval height='48' width='48' color='black' />
              </div>
            )}
            {!isLoadingData && (
              <>
                <h1 className='text-2xl font-bold'>Welcome</h1>
                <p className='text-sm mt-3'>Your contracts are ready to sign</p>
                <button
                  className='bg-volt py-2 px-3 rounded-sm'
                  onClick={onClick}
                  disabled={isLoadingData}
                >
                  {!isLoadingData ? 'Sign Now' : 'Loading data...'}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      <div className='w-0 md:w-2/3 md:flex h-full'>
        <img
          className='h-full w-full object-left-top'
          alt='background'
          src={background}
        />
      </div>
    </div>
  )
}

export default Welcome
