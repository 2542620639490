export const PRIVACIES = [
  {
    id: 1,
    text: 'Other BROKERS may communicate with you to determine if you require additional real estate services.',
    isActive: true
  },
  {
    id: 2,
    text: 'The boards, and other BROKERS or their brokerage participate in surveys.',
    isActive: true
  }
]
