import React, { useContext } from 'react'
import { useFormikContext } from 'formik'
import { DynamicDropdownContext } from '../context'
import CustomButton from 'components/CustomButton'

const CreateButton = () => {
  const { isEdit, label } = useContext(DynamicDropdownContext)
  const { handleSubmit } = useFormikContext()
  return (
    <CustomButton handleClick={() => handleSubmit()} className='w-full'>
      <span className='font-medium py-2 px-6 text-base flex gap-2 items-center'>
        {isEdit ? 'Update' : 'Create New'} {label}
      </span>
    </CustomButton>
  )
}

export default CreateButton
