import React, { useEffect, useState } from 'react'
import propTypes from 'prop-types'
import Skeleton from 'react-loading-skeleton'
import { Table as AntdTable } from 'antd'
import { getRandomInteger } from 'utils'
import { TableToCard } from './transformGridToCard'
import TailwindTable from './TailwindTableNew'

const Table = (props) => {
  const {
    dataSource, columns, loading,
    card, seeAsCards, emptyText,
    selectedPage, changeSelectedPage,
    scrollOptions, tableLayout, tailwindTable,
    extraHeaders, extraColumns, className,
    onViewEditClick, onDeleteClick,
    deleting, idBeingDeleted, showTablePagination,
    onDeleteButtonText, onRow, isFromContracts,
    showSizeChanger, defaultPageSize, pageSizeOptions,
    noMargin
  } = props

  const [localColumns, setLocalColumns] = useState(columns)

  const getSkeleton = (data) => {
    const newArray = data.map((element) => ({
      ...element,
      render: () => {
        const width = getRandomInteger(50, 100)
        return (
          <Skeleton width={`${width}%`} height={25} />
        )
      }
    }))

    return newArray
  }

  useEffect(() => {
    if (loading) {
      // Transform the columns into Skeleton columns
      const skeletonColumns = getSkeleton(columns)
      setLocalColumns(skeletonColumns)
    } else {
      // When finished to load, show the data
      setLocalColumns(columns)
    }
  }, [columns, loading])

  const TableComponent = () => (
    tailwindTable
      ? (
        <TailwindTable
          columns={columns}
          data={dataSource}
          extraHeaders={extraHeaders}
          extraColumns={extraColumns}
          className={className}
          loading={loading}
          onViewEditClick={onViewEditClick}
          onDeleteClick={onDeleteClick}
          deleting={deleting}
          idBeingDeleted={idBeingDeleted}
          showTablePagination={showTablePagination}
          onDeleteButtonText={onDeleteButtonText}
          onRow={onRow}
          noMargin={noMargin}
        />
        )
      : (
        <AntdTable
          columns={localColumns}
          tableLayout={tableLayout}
          scroll={{ y: 240, ...scrollOptions }}
          dataSource={dataSource}
          loading={loading}
          pagination={{
            onChange: (page) => changeSelectedPage(page),
            current: selectedPage,
            defaultPageSize,
            showSizeChanger,
            pageSizeOptions
          }}
          locale={{
            emptyText
          }}
        />
        )
  )

  if (isFromContracts) {
    return <TableComponent />
  }
  return (
    <TableToCard
      cardMaxSize='sm'
      card={card}
      seeAsCards={seeAsCards}
    >
      <TableComponent />
    </TableToCard>
  )
}

export default Table

Table.defaultProps = {
  selectedPage: 1,
  changeSelectedPage: () => {},
  tableLayout: '-',
  card: <></>,
  tailwindTable: false,
  propTypes: [],
  onViewEditClick: null,
  onDeleteClick: null,
  showTablePagination: true,
  defaultPageSize: 10,
  pageSizeOptions: ['10', '20', '30']
}

Table.propTypes = {
  dataSource: propTypes.array,
  columns: propTypes.array,
  loading: propTypes.bool,
  card: propTypes.node,
  seeAsCards: propTypes.bool,
  emptyText: propTypes.string,
  selectedPage: propTypes.number,
  changeSelectedPage: propTypes.func,
  scrollOptions: propTypes.object,
  tableLayout: propTypes.string,
  tailwindTable: propTypes.bool,
  extraHeaders: propTypes.array,
  extraColumns: propTypes.array,
  className: propTypes.string,
  onViewEditClick: propTypes.func,
  onDeleteClick: propTypes.func,
  deleting: propTypes.bool,
  idBeingDeleted: propTypes.string,
  showTablePagination: propTypes.bool,
  onDeleteButtonText: propTypes.string,
  isFromContracts: propTypes.bool,
  defaultPageSize: propTypes.number,
  showSizeChanger: propTypes.bool,
  pageSizeOptions: propTypes.any,
  noMargin: propTypes.bool
}
