import React, { useCallback, useState } from 'react'
import Checkbox from '../../Checkbox'
import ReviewModeDrawer from '../../ReviewModeDrawer'
import { Check, CircleHelp, DollarSign, FilePen, KeyRound } from 'lucide-react'
import { H5 } from 'ui'
import Button from '../../Button'
import SubText from '../../SubText'

const ActivityTab = ({ buyerId }) => {
  const [isReviewModeDrawerOpen, setIsReviewModeDrawerOpen] = useState(false)
  const getActivities = useCallback(() => {
    return [
      {
        id: 'activity_1',
        title: 'Initial inquiry',
        details: 'Initial inquiry made by the buyer {{name}}.',
        Icon: CircleHelp,
        date: 'Jan 2, 2024',
        time: '10:00 AM'
      },
      {
        id: 'activity_2',
        title: 'Document signing',
        details: 'Buyer signed the initial documents',
        Icon: FilePen,
        date: 'Jan 2, 2024',
        time: '10:00 AM'
      },
      {
        id: 'activity_3',
        title: 'Payments',
        details: 'Initial inquiry made by the buyer {{name}}.',
        Icon: DollarSign,
        date: 'Jan 2, 2024',
        time: '10:00 AM'
      },
      {
        id: 'activity_4',
        title: 'Approvals',
        details: 'Initial inquiry made by the buyer {{name}}.',
        Icon: Check,
        date: 'Jan 2, 2024',
        time: '10:00 AM'
      },
      {
        id: 'activity_5',
        title: 'Ownership transfer',
        details: 'Initial inquiry made by the buyer {{name}}.',
        Icon: KeyRound,
        date: 'Jan 2, 2024',
        time: '10:00 AM'
      }
    ]
  }, [])

  const getCheckList = useCallback(() => {
    return [
      {
        id: 'list_1',
        label: 'Review terms of sale'
      },
      {
        id: 'list_2',
        label: 'Review all draft contracts'
      },
      {
        id: 'list_3',
        label: 'Approve KYC documents'
      },
      {
        id: 'list_4',
        label: 'Confirm consideration deposit payment'
      }
    ]
  }, [])

  const ActivityItem = ({ activity: { id, title, details, Icon, date, time }, index }) => (
    <div key={id} className='self-stretch py-1 justify-between items-center inline-flex'>
      <div className='justify-start items-center gap-2 flex'>
        <div className='relative w-9 h-9 px-1.5 py-1.5 bg-zinc-200 dark:bg-zinc-800 rounded-full'>
          <Icon className='w-6 h-6 relative flex-col justify-start items-start flex text-zinc-500 dark:text-zinc-200' />
          {
            !(getActivities()?.length - 1 === index) &&
            <div className='absolute w-[0px] h-[22.5px] left-4 top-9 border border-zinc-400 dark:border-zinc-200 border-dashed' />
          }
        </div>
        <div className='flex-col justify-start items-start gap-0.5 inline-flex'>
          <SubText label={title} className='text-sm leading-tight' />
          <SubText variant='light' label={details} />
        </div>
      </div>
      <div className='text-right text-zinc-500 text-xs font-normal leading-none flex flex-col gap-1'>
        <SubText variant='light' label={date} />
        <SubText variant='light' label={time} />
      </div>
    </div>
  )

  const CustomCheckBox = ({ label }) => {
    const [isChecked, setIsChecked] = useState(false)

    return (
      <Checkbox
        label={label}
        labelClasses='self-stretch text-zinc-950 text-xs xl:text-sm font-normal'
        checked={isChecked}
        handleChange={() => setIsChecked(!isChecked)}
        containerClasses='py-1 xl:py-2'
        iconClasses=''
      />
    )
  }

  return (
    <div className='w-full min-h-full flex flex-col gap-y-3 xl:gap-y-4'>
      <H5 className='text-zinc-950 text-base font-semibold leading-none mb-0'>Next step</H5>
      <div className='w-full rounded-lg border border-zinc-200 dark:border-zinc-500 justify-start items-start inline-flex overflow-auto'>
        <div className='grow shrink basis-0 bg-zinc-50 dark:bg-zinc-800 flex-col justify-center items-start inline-flex'>
          <div className='self-stretch h-14 p-3 xl:p-4 border-b border-zinc-200 dark:border-zinc-500 justify-between items-center inline-flex'>
            <div className='justify-start items-center gap-2 flex'>
              <div className='w-9 h-9 p-1.5 bg-yellow-200 rounded-full justify-center items-center flex'>
                <KeyRound
                  className='w-6 h-6 relative flex-col justify-start items-start flex'
                />
              </div>
              <div className='flex-col justify-start items-start gap-0.5 inline-flex'>
                <H5 className='text-zinc-950 text-sm font-semibold leading-tight mb-0'>Approve Draft Offer</H5>
              </div>
            </div>
            <Button
              variant='default'
              onClick={() => setIsReviewModeDrawerOpen(true)}
              label='Start review for approval'
            />
          </div>
          <div className='self-stretch p-3 xl:px-6 xl:py-4 bg-white dark:bg-black flex-col justify-start items-start gap-1 flex'>
            <SubText
              variant='light'
              className='text-xs xl:text-sm font-normal leading-tight'
              label="Here’s that to do next. Thank you for the project update. It looks great! I' ve gone through the report, and the progress is impressive. The team has done a fantastic job, and I appreciate the hard…"
            />
            {
              getCheckList().map((item) => (
                <CustomCheckBox
                  key={item.id}
                  label={item.label}
                />
              ))
            }
          </div>
        </div>
      </div>
      <Button
        variant='disabled'
        label='Next stage'
      />
      <H5 className='h-[32px] xl:h-[48px] flex items-end text-zinc-950 text-base font-semibold leading-none mb-0'>Activity</H5>
      <div className='w-full flex-col justify-start items-start gap-4 inline-flex'>
        {
          getActivities().map((activity, index) => (<ActivityItem key={activity.id} activity={activity} index={index} />))
        }
      </div>
      <ReviewModeDrawer
        key='review-mode-drawer'
        buyerId={buyerId}
        isReviewModeDrawerOpen={isReviewModeDrawerOpen}
        closeReviewModeDrawerOpen={() => setIsReviewModeDrawerOpen(false)}
      />
    </div >
  )
}

export default ActivityTab
