import { api, ApiErrors } from 'services/api'
import { message } from 'antd'

export const getMapInventory = (id = '') =>
  new Promise((resolve, reject) => {
    api.get(`/map-inventory/${id}`).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const createOrUpdateMapInventory = (props) =>
  new Promise((resolve, reject) => {
    const { _id, project, building, ...params } = props
    // If id, so we have to update the record, or we're gonna create
    const verb = _id ? 'put' : 'post'
    const url = _id ? `/map-inventory/${_id}` : `/map-inventory/project/${project}/${building}`

    api[verb](url, params).then((response) => {
      if (response.ok) {
        message.success(`Map-inventory was ${_id ? 'successfully updated' : 'created successfully'}`)
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const deleteMapInventory = (id) =>
  new Promise((resolve, reject) => {
    api.delete(`/map-inventory/${id}`).then((response) => {
      if (response.ok) {
        message.success('Map-inventory deleted successfully')
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getAllMapInventoryByType = (projectId, buildingId, type) =>
  new Promise((resolve, reject) => {
    api
      .get(`/map-inventory/project/${projectId}/${buildingId}/${type}`)
      .then((response) => {
        if (response.ok) {
          resolve(response.data.data)
        } else {
          ApiErrors(response)
          reject()
        }
      })
  })
