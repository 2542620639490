import { TFilledPlusIcon } from 'components'
import ProjectOpportunities from 'pages/leadBrokerPages/components/ProjectOpportunities'
import React, { useCallback, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useHistory } from 'react-router-dom'
import { BROKER_CREATE_OPPORTUNITY_PAGE } from 'routes'

const Button = (props) => {
  return (
    <button className='text-softBlack text-center text-lg font-medium py-1.5 px-2 flex items-center bg-white rounded'
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  )
}

const BrokerOpportunitiesScreen = () => {
  const history = useHistory()
  const [appProject] = useState('607605e70de444001a71cfc8')
  const createOpportunity = useCallback(() => {
    history.push({
      pathname: BROKER_CREATE_OPPORTUNITY_PAGE,
      state: {
        projectId: appProject
      }
    })
  }, [appProject, history])

  return (
    <div className='px-14 py-4'>
      <div className='w-full flex justify-between items-center'>
        <h3 className='text-4xl font-bold ml-2 mb-0'>BROKER OPPORTUNITIES</h3>
        <Button disabled={!appProject}
          onClick={() => createOpportunity()}
        >
          <TFilledPlusIcon className='h-6 w-3' />
          {isMobile ? 'Create' : 'Create Opportunity'}
        </Button>
      </div>
      <ProjectOpportunities />
    </div>
  )
}

export default BrokerOpportunitiesScreen
