import { combineReducers } from 'redux'

import authReducer from './authReducer'
import appReducer from './appReducer'
import createOfferReducer from './createOfferReducer'
import createProjectReducer from './createProjectReducer'

const rootReducer = combineReducers({
  authReducer,
  appReducer,
  createOfferReducer,
  createProjectReducer
})

export default rootReducer
