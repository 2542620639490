/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { ReactComponent as DownIcon } from 'assets/icons/down.svg'
import { useFormikContext } from 'formik'
import { Select } from 'antd'
import useDynamicDropdown from 'hooks/useDynamicDropdown'

const SelectFloorPlans = ({
  title = '',
  fieldName = '',
  placeholder = ''
}) => {
  const [floorPlansIds] = useState([
    '6453aea4301626b5801fbc9c',
    '6464daa78f4eaf1338dc7d08',
    '6464dac58f4eaf1338dc7ded'
  ])
  const { setFieldValue, values } = useFormikContext()
  const [dropDownOption, setDropDownOption] = useState([])
  const { data } = useDynamicDropdown(fieldName)
  useEffect(() => {
    if (data?.values) {
      setDropDownOption(data?.values.map((elm, index) => (
        {
          title: elm,
          value: floorPlansIds[index]
        }
      )))
    }
  }, [data])

  return (
    <div key={fieldName} className='flex flex-1 flex-col justify-left'>
        <div className='font-bold text-softBlack_70 italic'>{title}</div>
        <Select
            suffixIcon={<DownIcon />}
            showArrow
            onChange={(e) => setFieldValue(fieldName, e)}
            defaultValue={values[fieldName]}
            placeholder={placeholder}
            mode='multiple'
            className='rating-select text-sm bg-white border border-softBlack30 rounded text-softBlack placeholder-softBlack70 h-10'
        >
            {dropDownOption?.map(option => <Select.Option value={option.value}>
                <div className='flex gap-2 items-center'>
                    <span className='h-full'>{option.title}</span>
                </div>
            </Select.Option>)}
        </Select>
    </div>
  )
}

export default SelectFloorPlans
