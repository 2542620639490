/*eslint-disable */
// @ts-nocheck
import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { getCorporatDemandNotes } from 'store/actions/demandNotes'

const useGetCorporateDemandNotes = () => {
  const userId = useSelector((state) => state.authReducer.userObject)
  const { data, isLoading, refetch } = useQuery(
    ['getCorporatDemandNotes'],
    () => getCorporatDemandNotes(),
    { refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false, retry: false, enabled: !!userId }
  )
  return { data, isLoading, refetch }
}

export { useGetCorporateDemandNotes }
