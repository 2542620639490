import { useState, useEffect, useRef } from 'react'
import { getStorages } from 'store/actions/storageActions'

const useGetStorages = (id = '') => {
  const mountedRef = useRef(true)
  const [storages, setStorages] = useState([])
  const [loadingStorages, setLoadingStorages] = useState(false)

  useEffect(() => {
    getData(id)
    return () => {
      mountedRef.current = false
    }
  }, [])

  const getData = async (id = '') => {
    setLoadingStorages(true)
    try {
      const data = await getStorages(id || '')
      if (!mountedRef.current) return null
      setStorages(data)
      setLoadingStorages(false)
    } catch (err) {
      setLoadingStorages(false)
    }
  }

  return { loadingStorages, storages, refetchStorages: () => getData(id) }
}

export default useGetStorages
