/* eslint-disable */
// @ts-nocheck
import { Bell, Check, ChevronsUpDown, KeyRound, Pen } from 'lucide-react';
import React, { useEffect, useMemo, useState } from 'react';
import { AllocationBadges } from './InventoryEditDrawer';
import CustomTable from './CustomTable';
import Checkbox from './Checkbox';
import { Modal } from '@material-ui/core';
import { IoMdClose } from 'react-icons/io';
import CustomSelect from 'components/CustomSelect';
import CustomInput from 'components/CustomInput';
import toast from 'react-hot-toast';
import { createOrUpdateUnit } from 'store/actions/unitActions';
import {
  createOrUpdateStorage,
  getAllStorageForBuildingByStorageType,
  getAvailableStorageForBuildingByStorageType,
  getStorageInventory,
} from 'store/actions/storageActions';
import { getFilteredDeals } from 'store/actions/dealAction';
const currencyFormate = (value) => {
  if (value == null) return undefined;
  else {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    const formattedValue = formatter.format(value);
    return formattedValue;
  }
};
export const StorageAllocatePage = ({
  type,
  editDetails,
  setEditDetails,
  refetch,
  selectedBuilding,
}) => {
  const [pendingStorageAssignment, setPendingStorageAssignment] = useState(0);
  const colors = ['#FFBE9A', '#16A34A40', '#FFD700', '#FF0000'];
  const [storageAssignedOption, setStorageAssignedOption] = useState('');
  const [storages, setStorages] = useState([]);
  const [selectedStorage, setSelectedStorage] = useState('');
  const [allocationModal, setAllocationModal] = useState(false);
  const [storageType, setStorageType] = useState([]);

  const [assignStorageModal, setAssignStorageModal] = useState(false);

  const [reassignedSelectedStorage, setReassignedSelectedStorage] =
    useState('');
  const [assignedStorageTableDataSource, setAssignedStorageTableDataSource] =
    useState([]);
  const [editStorageModal, setEditStorageModal] = useState(false);
  const [unassignStorageModal, setUnassignStorageModal] = useState(false);
  const [reassignStorageModal, setReassignStorageModal] = useState(false);
  const [avesdoStorages, setAvesdoStorages] = useState([]);
  const [avesdoWaitlistedStorage, setAvesdoWaitlistedStorage] = useState([]);
  const [
    avesdoUnAssignedWaitlistStorages,
    setAvesdoUnAssignedWaitlistStorages,
  ] = useState([]);
  const [avesdoSelectedStorage, setAvesdoSelectedStorage] = useState(null);
  const [avesdoUnit, setAvesdoUnit] = useState([]);
  const [tempAvesdoRefetct, setTempAvesdoRefetct] = useState(false);
  const [datesData, setDatesData] = useState([]);
  const [typesOfAllocatedStorage, setTypesOfAllocatedStorage] = useState([]);
  const [tempTypesOfAllocatedStorage, setTempTypesOfAllocatedStorage] =
    useState([]);
  const openAllocationModal = () => {
    setAllocationModal(true);
  };

  const openAssignStorageModal = () => {
    setAssignStorageModal(true);
  };
  const CustomCheckBox = () => {
    const [isChecked, setIsChecked] = useState(false);

    return (
      <Checkbox
        checked={isChecked}
        handleChange={() => setIsChecked(!isChecked)}
        iconClasses='self-start my-1'
      />
    );
  };
  const handleAddStorage = () => {
    const newId = tempTypesOfAllocatedStorage.length + 1;
    const newBgColor = colors[newId % colors.length]; // Rotate through colors
    const newAllocation = {
      id: newId,
      count: 1,
      title: '',
      bgColor: newBgColor,
    };

    setTempTypesOfAllocatedStorage((prev) => [...prev, newAllocation]);
  };
  const getAvailableStorages = async () => {
    if (editStorageModal) {
      let data = await getAllStorageForBuildingByStorageType(
        selectedBuilding,
        'storageType=' + storageAssignedOption
      );
      console.log(data);
      let filtered = data?.map((storage, index) => {
        return {
          value: storage?.storageNumber || index + 1,
          id: storage?._id,
        };
      });
      setStorages(filtered);
    } else {
      let data = await getAvailableStorageForBuildingByStorageType(
        selectedBuilding
      );
      let filtered = data?.map((storage, index) => {
        return {
          value: storage?.storageNumber || index + 1,
          id: storage?._id,
        };
      });
      if (filtered.length === 0)
        toast.error(
          'No storage spots available for' + ' ' + storageAssignedOption
        );
      setStorages(filtered);
    }
  };

  useEffect(() => {
    if (assignStorageModal || editStorageModal) {
      getAvailableStorages();
    }
  }, [assignStorageModal, editStorageModal]);

  useEffect(() => {
    let assignedStorage = editDetails?.storages?.assigned || [];
    let allocatedStorage = editDetails?.storages?.allocated || [];
    let mappedAllocated = allocatedStorage.map((storage, index) => {
      return {
        _id: storage._id,
        id: index,
        count: storage.quantity,
        title: storage.name,
        bgColor: colors[index % colors.length],
      };
    });
    setTypesOfAllocatedStorage(mappedAllocated);
    setTempTypesOfAllocatedStorage(mappedAllocated);
    let mappedAssigned = assignedStorage.map((storage, index) => {
      if (storage.isAvesdo) {
        let concatWaitlistAndAvesdo = [
          ...avesdoWaitlistedStorage,
          ...avesdoStorages,
        ];
        let avesdoStoarge = concatWaitlistAndAvesdo.filter((item) => {
          return item.id === Number(storage.avesdoId);
        })[0];
        return {
          avesdoId: storage.avesdoId,
          id: storage._id,
          stall: (storage?.storageNumber || '').toString(),
          type: 'Avesdo',
          option: avesdoStoarge?.label || '',
          price:
            avesdoStoarge?.price === '$0.00'
              ? 'Included'
              : avesdoStoarge?.price || 0,
          isAssigned: true,
        };
      } else {
        console.log(storage.price);
        return {
          id: storage._id,
          stall: (storage?.storageNumber || '').toString(),
          type: (storage?.storageType || '').toString(),
          price:
            storage.price === 0
              ? 'Included'
              : currencyFormate(storage?.price).toString() || '',
          isAssigned: true,
        };
      }
    });
    let unassignedStorage = [];
    let totalCount = mappedAllocated.reduce((acc, curr) => acc + curr.count, 0);
    let assignedCount = mappedAssigned.length;
    let unassignedCount = totalCount - assignedCount;
    for (let i = 0; i < unassignedCount; i++) {
      unassignedStorage.push({
        id: `pending${i}_${type.title}`,
        stall: 'N/a',
        type: '-',
        floor: 'N/a',
        price: 'N/a',
        isAssigned: false,
      });
    }

    let avesdoUnassigned = avesdoStorages.filter((item) => {
      return !mappedAssigned.some((t) => Number(t.avesdoId) === item.id);
    });

    setAssignedStorageTableDataSource([
      ...mappedAssigned,
      ...unassignedStorage,
      ...avesdoUnassigned,
    ]);
  }, [editDetails, selectedBuilding, avesdoStorages]);

  useEffect(() => {
    if (typesOfAllocatedStorage.length === 0)
      return setPendingStorageAssignment(0);
    let total = typesOfAllocatedStorage.reduce(
      (acc, curr) => acc + Number(curr.count),
      0
    );
    setPendingStorageAssignment(total);
  }, [typesOfAllocatedStorage, editDetails]);

  const handleFilteredDeals = async () => {
    let data = await getFilteredDeals({
      unitNumber: editDetails?.unitNumber || null,
    });
    setAvesdoUnit(data);
    let allStorages = [];
    data.forEach((deal) => {
      const { options } = deal;
      const modifiedStorages = options
        .filter((option) => {
          return (
            option.label.toLowerCase().includes('locker') &&
            option.header !== 'Lockers Waitlist'
          );
        })
        .map((option, index) => ({
          ...option,
          option: option.label,
          price:
            option.price === 0 ? 'Included' : currencyFormate(option.price),
          type: '-',
          id: option.id,
          flag: 'avesdo',
        }));
      allStorages = allStorages.concat(modifiedStorages);
    });
    const filteredDeals = data.filter(
      (deal) =>
        deal.options.some((option) => option.header === 'Lockers Waitlist') &&
        deal.formatedAcceptedDate !== ''
    );
    let waitlistedStorages = [];
    filteredDeals.forEach((deal) => {
      const { options } = deal;
      const modifiedStorages = options
        .filter((option) => option.header === 'Lockers Waitlist')
        .map((option, index) => ({
          ...option,
          option: option.label,
          price:
            option.price === 0 ? 'Included' : currencyFormate(option.price),
          type: '-',
          id: option.id,
          flag: 'avesdo',
        }));
      waitlistedStorages = waitlistedStorages.concat(modifiedStorages);
    });
    let unassignedWaitlistedStorage = [];
    let assignedStorages = editDetails?.storages?.assigned || [];
    waitlistedStorages.forEach((waitlistStorage) => {
      const isAssigned = assignedStorages.some((assigned) => {
        return (
          assigned.isAvesdo && Number(assigned.avesdoId) === waitlistStorage.id
        );
      });

      if (!isAssigned) {
        unassignedWaitlistedStorage.push({
          ...waitlistStorage,
        });
      }
    });
    setAvesdoWaitlistedStorage(waitlistedStorages);
    setAvesdoUnAssignedWaitlistStorages(unassignedWaitlistedStorage);
    setAvesdoStorages(allStorages);
  };
  useEffect(() => {
    handleFilteredDeals();
  }, [editDetails, tempAvesdoRefetct]);

  const tableColumns = useMemo(
    () => [
      {
        Title: (
          <div className='items-center inline-flex'>
            <CustomCheckBox />
            <p
              className='text-center text-zinc-500 text-[15px] font-medium leading-none capitalize mb-0'
              label='Document'
            >
              Storage stall
            </p>
            <ChevronsUpDown className='w-4 h-4 text-zinc-500 dark:text-zinc-300' />
          </div>
        ),
        accessor: 'stall',
        dataIndex: 'stall',
        Cell: (props) => (
          <div className='items-center flex'>
            <CustomCheckBox />
            <p className='mb-0 text-black text-base font-bold truncate'>
              {props?.cell?.value || 'N/a'}
            </p>
          </div>
        ),
      },
      {
        Title: (
          <div className='items-center max-w- inline-flex'>
            <p
              className='text-center text-zinc-500 text-[15px] font-medium leading-none capitalize mb-0'
              label='Document'
            >
              Avesdo Name
            </p>
            <ChevronsUpDown className='w-4 h-4 text-zinc-500 dark:text-zinc-300' />
          </div>
        ),
        accessor: 'option',
        dataIndex: 'otion',
        Cell: (props) => (
          <div className='items-center max-w-[200px] flex-wrap flex'>
            <p className='mb-0 text-black whitespace-normal text-base font-[500]'>
              {props?.cell?.value || 'N/a'}
            </p>
          </div>
        ),
      },
      {
        Title: (
          <div className='items-center inline-flex'>
            <p
              className='text-center text-zinc-500 text-[15px] font-medium leading-none capitalize mb-0'
              label='Document'
            >
              Type
            </p>
            <ChevronsUpDown className='w-4 h-4 text-zinc-500 dark:text-zinc-300' />
          </div>
        ),
        accessor: 'type',
        dataIndex: 'type',
        Cell: (props) => (
          <div className='px-3 py-1 rounded-md border border-zinc-200 justify-center items-center gap-2 inline-flex'>
            <div className='text-zinc-950 text-sm font-medium leading-none capitalize'>
              {props?.cell?.value || '-'}
            </div>
          </div>
        ),
      },
      {
        Title: (
          <div className='items-center inline-flex'>
            <p
              className='text-center text-zinc-500 text-[15px] font-medium leading-none capitalize mb-0'
              label='Document'
            >
              Price
            </p>
            <ChevronsUpDown className='w-4 h-4 text-zinc-500 dark:text-zinc-300' />
          </div>
        ),
        accessor: 'price',
        dataIndex: 'price',
        Cell: (props) => (
          <p className='mb-0 text-zinc-950 text-sm font-normal leading-tight truncate capitalize'>
            {props.cell?.value?.length ? props.cell.value : '—'}
          </p>
        ),
      },
      {
        Title: '',
        accessor: 'isAssigned',
        Cell: (props) => (
          <div className='w-full flex flex-row justify-end items-center gap-2 text-center'>
            {props.cell.value ? (
              <>
                <button
                  onClick={() => {
                    if (props.row.original.flag === 'avesdo') {
                      setAvesdoSelectedStorage(props.row.original.id);
                    }
                    setEditStorageModal(true);
                    setSelectedStorage(props.row.original.id);
                    setReassignedSelectedStorage(props.row.original.id);
                    setStorageAssignedOption(props.row.original.type);
                  }}
                  className='self-stretch p-2.5 text-sm font-medium bg-white border border-zinc-200 text-black rounded-lg flex gap-2 items-center justify-center'
                >
                  <Pen className='w-4 h-4' />
                </button>
                <button className='py-2.5 px-4 text-sm font-medium bg-[#18181B] border border-[#18181B] text-white rounded-lg flex gap-2 items-center justify-center'>
                  <Check className='w-4 h-4' />
                  <p className='mb-0'>Assigned</p>
                </button>
              </>
            ) : (
              <>
                <button
                  onClick={() => {
                    if (props.row.original.flag === 'avesdo') {
                      setAvesdoSelectedStorage(props.row.original.id);
                    }
                    openAssignStorageModal();
                  }}
                  className='py-2.5 px-4 text-sm font-medium bg-[#FFBE9A] border border-[#E4E4E7] text-black rounded-lg flex gap-2 items-center justify-center'
                >
                  <Bell height='16' width='16' color='black' />
                  <p className='mb-0'>Assign Storage spot</p>
                </button>
              </>
            )}
          </div>
        ),
      },
    ],
    []
  );
  const tableColumnsAvesdoStorage = useMemo(
    () => [
      {
        Title: (
          <div className='items-center inline-flex'>
            <CustomCheckBox />
            <p
              className='text-center text-zinc-500 text-[15px] font-medium leading-none capitalize mb-0'
              label='Document'
            >
              Options
            </p>
            <ChevronsUpDown className='w-4 h-4 text-zinc-500 dark:text-zinc-300' />
          </div>
        ),
        accessor: 'option',
        dataIndex: 'otion',
        Cell: (props) => (
          <div className='items-center flex'>
            <CustomCheckBox />
            <p className='mb-0 text-black text-base font-[500]'>
              {props.cell.value}
            </p>
          </div>
        ),
      },
      {
        Title: (
          <div className='items-center inline-flex'>
            <p
              className='text-center text-zinc-500 text-[15px] font-medium leading-none capitalize mb-0'
              label='Document'
            >
              Price
            </p>
            <ChevronsUpDown className='w-4 h-4 text-zinc-500 dark:text-zinc-300' />
          </div>
        ),
        accessor: 'price',
        dataIndex: 'price',
        Cell: (props) => (
          <p className='mb-0 text-zinc-950 text-sm font-normal leading-tight truncate capitalize'>
            {props.cell.value.length ? props.cell.value : '—'}
          </p>
        ),
      },
      {
        Title: '',
        accessor: 'isAssigned',
        Cell: (props) => (
          <div className='w-full flex flex-row justify-end items-center gap-2 text-center'>
            {props.cell.value ? (
              <>
                <button
                  onClick={() => {
                    setEditStorageModal(true);
                    setSelectedStorage(props.row.original.id);
                    setReassignedSelectedStorage(props.row.original.id);
                    setStorageAssignedOption(props.row.original.type);
                  }}
                  className='self-stretch p-2.5 text-sm font-medium bg-white border border-zinc-200 text-black rounded-lg flex gap-2 items-center justify-center'
                >
                  <Pen className='w-4 h-4' />
                </button>
                <button className='py-2.5 px-4 text-sm font-medium bg-[#18181B] border border-[#18181B] text-white rounded-lg flex gap-2 items-center justify-center'>
                  <Check className='w-4 h-4' />
                  <p className='mb-0'>Assigned</p>
                </button>
              </>
            ) : (
              <>
                <button
                  onClick={() => {
                    if (props.row.original.flag === 'avesdo') {
                      console.log(props.row.original.id);
                      setAvesdoSelectedStorage(props.row.original.id);
                    }
                    openAssignStorageModal();
                  }}
                  className='py-2.5 px-4 text-sm font-medium bg-[#FFBE9A] border border-[#E4E4E7] text-black rounded-lg flex gap-2 items-center justify-center'
                >
                  <Bell height='16' width='16' color='black' />
                  <p className='mb-0'>Assign Storage spot</p>
                </button>
              </>
            )}
          </div>
        ),
      },
    ],
    []
  );
  const AllocationModal = () => {
    return (
      <Modal open={allocationModal} onClose={() => setAllocationModal(false)}>
        <div className='relative w-fit flex flex-col gap-y-4 min-w-[500px] font-openSans bg-grey-8 p-10 rounded shadow-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
          <button
            onClick={() => {
              setTempTypesOfAllocatedStorage(typesOfAllocatedStorage);
              setAllocationModal(false);
            }}
            className='absolute right-4 top-4'
          >
            <IoMdClose fontSize='22px' />
          </button>
          <h3 className='text-[20px] text-center'>Allocate Storage</h3>
          {tempTypesOfAllocatedStorage.map((type) => (
            <div key={type.id} className='flex items-center gap-3'>
              <div className='w-1/2'>
                <CustomSelect
                  inputClasses='h-11'
                  labelClasses={undefined}
                  options={storageType}
                  selectedOption={type.title}
                  setSelectedOption={(option) => {
                    setTempTypesOfAllocatedStorage((prev) => {
                      prev.find((t) => t.id === type.id).title = option;
                      return [...prev];
                    });
                  }}
                />
              </div>
              <CustomInput
                type='number'
                value={type.count}
                onChange={(e) => {
                  if (
                    e.target.value <
                    assignedStorageTableDataSource.filter((item) => {
                      return (
                        item.type === type.title && item.isAssigned === true
                      );
                    }).length
                  ) {
                    toast.error(
                      'Can not reduce the count below the number of assigned storage spots for'
                    );
                    return;
                  }
                  let newTypes = tempTypesOfAllocatedStorage.map((t) => {
                    if (t.id === type.id) {
                      return {
                        ...t,
                        count: e.target.value,
                      };
                    }
                    return t;
                  });
                  setTempTypesOfAllocatedStorage(newTypes);
                }}
              />
              <button
                onClick={() => {
                  if (
                    assignedStorageTableDataSource.filter(
                      (item) =>
                        item.type === type.title && item.isAssigned === true
                    ).length > 0
                  ) {
                    toast.error(
                      'Storage spots are already assigned for' +
                        ' ' +
                        type.title +
                        '. Please unassign them first'
                    );
                    return;
                  }
                  setTempTypesOfAllocatedStorage((prev) => {
                    return prev.filter((t) => t.id !== type.id);
                  });
                }}
                className='h-full'
              >
                <svg
                  width='12'
                  height='16'
                  viewBox='0 0 14 18'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M1.50016 15.4167C1.50016 16.425 2.32516 17.25 3.3335 17.25H10.6668C11.6752 17.25 12.5002 16.425 12.5002 15.4167V4.41667H1.50016V15.4167ZM13.4168 1.66667H10.2085L9.29183 0.75H4.7085L3.79183 1.66667H0.583496V3.5H13.4168V1.66667Z'
                    fill='#2E2B2E'
                  />
                </svg>
              </button>
            </div>
          ))}
          <div
            className='text-[16px] cursor-pointer'
            onClick={() => {
              handleAddStorage();
            }}
          >
            + Allocate storage
          </div>
          <button
            onClick={async () => {
              if (
                tempTypesOfAllocatedStorage.some((type) => type.count === 0)
              ) {
                toast.error('Please allocate storage');
                return;
              }
              const titles = new Set();
              for (const type of tempTypesOfAllocatedStorage) {
                if (titles.has(type.title)) {
                  toast.error('Duplicate storage title found');
                  return;
                }
                titles.add(type.title);
              }
              await createOrUpdateUnit({
                ...editDetails,
                storages: {
                  ...editDetails.storages,
                  allocated: tempTypesOfAllocatedStorage.map((type) => ({
                    quantity: type.count,
                    name: type.title,
                  })),
                  totalAllocatedStorage: tempTypesOfAllocatedStorage.reduce(
                    (acc, curr) => acc + Number(curr.count),
                    0
                  ),
                },
              }).then((data) => {
                refetch();
                setAllocationModal(false);
                let total = tempTypesOfAllocatedStorage.reduce(
                  (acc, curr) => acc + Number(curr.count),
                  0
                );
                setPendingStorageAssignment(total);
                setEditDetails(data);
              });
            }}
            className='py-2 px-2 text-sm font-medium bg-[#18181B] border border-[#18181B] text-white rounded-lg flex gap-2 items-center justify-center'
          >
            <Check />
            Save
          </button>
        </div>
      </Modal>
    );
  };

  const AssignStorageModal = () => {
    return (
      <Modal
        open={assignStorageModal}
        onClose={() => setAssignStorageModal(false)}
      >
        <div className='relative w-fit flex flex-col gap-y-4 min-w-[500px] font-openSans bg-grey-8 p-10 rounded shadow-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
          <button
            onClick={() => {
              setAssignStorageModal(false);
              setStorageAssignedOption('');
              setSelectedStorage('');
            }}
            className='absolute right-4 top-4'
          >
            <IoMdClose fontSize='22px' />
          </button>
          <div className='text-[20px] text-center'>Assigned Storage</div>
          <CustomSelect
            disabled={storages.length === 0}
            label={'Select Storage Stall'}
            options={storages}
            selectedOption={selectedStorage}
            setSelectedOption={(value) => {
              setSelectedStorage(value);
            }}
          />
          <button
            onClick={async () => {
              if (selectedStorage === '') {
                toast.error('Please select a storage stall');
                return;
              }
              let concatWaitlistAndAvesdo = [
                ...avesdoWaitlistedStorage,
                ...avesdoStorages,
              ];
              let avesdoStorage = concatWaitlistAndAvesdo.find((item) => {
                return item.id === avesdoSelectedStorage;
              });
              await createOrUpdateStorage({
                _id: selectedStorage,
                status: 'Assigned',
                unit: editDetails._id,
                isAvesdo: avesdoStorage ? true : false,
                avesdoId: avesdoStorage ? avesdoStorage.id : null,
              });
              await createOrUpdateUnit({
                ...editDetails,
                storages: {
                  ...editDetails.storages,
                  assigned: [...editDetails.storages.assigned, selectedStorage],
                },
              }).then(async (data) => {
                setEditDetails(data);
                refetch();
                setSelectedStorage('');
                setStorageAssignedOption('');
                setAssignStorageModal(false);
                setTempAvesdoRefetct(!tempAvesdoRefetct);
              });
            }}
            className='py-2 px-2 text-sm font-medium bg-[#18181B] border border-[#18181B] text-white rounded-lg flex gap-2 items-center justify-center'
          >
            <Check />
            Save
          </button>
        </div>
      </Modal>
    );
  };

  const EditStorageModal = () => {
    return (
      <Modal open={editStorageModal} onClose={() => setEditStorageModal(false)}>
        <div className='relative w-fit flex flex-col gap-y-4 min-w-[500px] font-openSans bg-grey-8 p-10 rounded shadow-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
          <button
            onClick={() => {
              setEditStorageModal(false);
              setReassignStorageModal(false);
              setUnassignStorageModal(false);
              setStorageAssignedOption('');
              setSelectedStorage('');
            }}
            className='absolute right-4 top-4'
          >
            <IoMdClose fontSize='22px' />
          </button>
          {!unassignStorageModal && !reassignStorageModal && (
            <div className='flex flex-col gap-y-3'>
              <div className='text-[20px] text-center'>
                Select a option to reassign the storage spot
              </div>
              <div className='flex gap-4 items-center justify-center'>
                <button
                  onClick={() => {
                    setUnassignStorageModal(true);
                  }}
                  className='py-2.5 px-4  text-sm font-medium bg-[#18181B] border border-[#18181B] text-white rounded-lg flex gap-2 items-center justify-center'
                >
                  Unassign Storage
                </button>
                <button
                  onClick={() => {
                    setReassignStorageModal(true);
                    setStorageAssignedOption('');
                  }}
                  className='py-2.5 px-4  text-sm font-medium bg-[#18181B] border border-[#18181B] text-white rounded-lg flex gap-2 items-center justify-center'
                >
                  Reassign Storage
                </button>
              </div>
            </div>
          )}
          {unassignStorageModal && (
            <div className='flex flex-col items-center gap-y-4'>
              <div className='text-[20px] text-center'>
                Are you sure you want to unassign this storage spot?
              </div>
              <div className='flex gap-x-4 items-center'>
                <button
                  onClick={async () => {
                    await createOrUpdateStorage({
                      _id: selectedStorage,
                      status: 'Available',
                      unit: null,
                      isAvesdo: false,
                      avesdoId: null,
                    });
                    await createOrUpdateUnit({
                      ...editDetails,
                      storages: {
                        ...editDetails.storages,
                        assigned: editDetails.storages.assigned.filter(
                          (item) => item._id !== selectedStorage
                        ),
                      },
                    }).then(async (data) => {
                      refetch();
                      setEditDetails(data);
                      setUnassignStorageModal(false);
                      setEditStorageModal(false);
                    });
                  }}
                  className='py-2.5 px-4  text-sm font-medium bg-[#18181B] border border-[#18181B] text-white rounded-lg flex gap-2 items-center justify-center'
                >
                  Yes
                </button>
                <button
                  onClick={() => {
                    setUnassignStorageModal(false);
                  }}
                  className='py-2.5 px-4  text-sm font-medium bg-[#18181B] border border-[#18181B] text-white rounded-lg flex gap-2 items-center justify-center'
                >
                  No
                </button>
              </div>
            </div>
          )}
          {reassignStorageModal && (
            <div className='flex flex-col gap-y-4'>
              <div className='text-[20px] text-center'>Reassign Storage</div>
              <CustomSelect
                label={'Select Storage For'}
                options={storages}
                selectedOption={reassignedSelectedStorage}
                setSelectedOption={(value) => {
                  setReassignedSelectedStorage(value);
                }}
              />
              <button
                onClick={async () => {
                  if (
                    assignedStorageTableDataSource.filter(
                      (t) =>
                        t.id === reassignedSelectedStorage &&
                        t.isAssigned === true
                    ).length > 0
                  ) {
                    toast.error(
                      'Storage spot is already assigned to a unit please select another or unassign it first'
                    );
                    return;
                  }
                  let newAssignedStorageArr = editDetails.storages.assigned.map(
                    (item) => {
                      if (item._id === selectedStorage) {
                        return reassignedSelectedStorage;
                      }
                      return item._id;
                    }
                  );
                  let concatWaitlistAndAvesdo = [
                    ...avesdoWaitlistedStorage,
                    ...avesdoStorages,
                  ];
                  let avesdoStorage = concatWaitlistAndAvesdo.find((item) => {
                    return item.id === avesdoSelectedStorage;
                  });
                  await createOrUpdateStorage({
                    _id: selectedStorage,
                    status: 'Available',
                    unit: null,
                    isAvesdo: false,
                    avesdoId: null,
                  });
                  await createOrUpdateStorage({
                    _id: reassignedSelectedStorage,
                    status: 'Assigned',
                    unit: editDetails._id,
                    isAvesdo: avesdoStorage ? true : false,
                    avesdoId: avesdoStorage ? avesdoStorage.id : null,
                  });

                  await createOrUpdateUnit({
                    ...editDetails,
                    storages: {
                      ...editDetails.storages,
                      assigned: newAssignedStorageArr,
                    },
                  }).then(async (data) => {
                    refetch();

                    setEditDetails(data);
                    setSelectedStorage('');
                    setReassignedSelectedStorage('');
                    setReassignStorageModal(false);
                    setEditStorageModal(false);
                  });
                }}
                className='py-2.5 px-4  text-sm font-medium bg-[#18181B] border border-[#18181B] text-white rounded-lg flex gap-2 items-center justify-center'
              >
                Save
              </button>
            </div>
          )}
        </div>
      </Modal>
    );
  };
  const _getStorageType = async () => {
    let storageInventory = await getStorageInventory(selectedBuilding);
    storageInventory = storageInventory.map((storage) => ({
      storageType: storage.options.map((op) => op.name),
    }));
    if (storageInventory.length) {
      setStorageType(
        storageInventory[0].storageType.map((pt) => ({
          value: pt,
          id: pt,
        }))
      );
    }
  };

  useEffect(() => {
    _getStorageType();
  }, [selectedBuilding]);
  return (
    <div className='flex flex-col gap-10'>
      <div className='flex flex-col border border-[#E4E4E7] rounded-lg overflow'>
        <div className='border-b border-[#E4E4E7] bg-[#F3F4F6] py-2 px-4 flex justify-between items-center'>
          <div className='flex gap-2 items-center'>
            <div className='bg-volt p-[6px] rounded-full'>
              <KeyRound />
            </div>
            <h6 className='mb-0 text-sm font-semibold'>
              {pendingStorageAssignment +
                avesdoStorages.length +
                avesdoWaitlistedStorage.length -
                (assignedStorageTableDataSource.filter((item) => {
                  return item.isAssigned === true;
                }).length || 0)}{' '}
              Storage Spot Pending Assignment
            </h6>
          </div>
        </div>
        {avesdoWaitlistedStorage.length > 0 && (
          <div className='p-4 flex flex-col gap-y-4 h-full'>
            <p className='text-black text-lg font-semibold underline mb-0'>
              Additional Storage Waitlist
            </p>
            <div>
              {datesData?.map((unit) => {
                return (
                  <p className='text-stone-950 text-lg font-bold mb-0'>
                    Joined Waitlist on {unit.formatedAcceptedDate}
                  </p>
                );
              })}
            </div>
          </div>
        )}
      </div>
      <div className='flex flex-col gap-4'>
        <div className='flex gap-x-4 items-center'>
          <h3 className='mb-0 text-3xl font-semibold'>Allocated Storage</h3>
          <button
            onClick={openAllocationModal}
            className='py-2.5 px-4 text-sm font-medium bg-[#18181B] border border-[#18181B] text-white rounded-lg flex gap-2 items-center justify-center'
          >
            <Pen className='w-4 h-4' />
            <p className='mb-0'>Edit</p>
          </button>
        </div>
        <div className='flex items-center gap-7.5'>
          {typesOfAllocatedStorage.map((type) => (
            <AllocationBadges key={type.id} type={type} />
          ))}
        </div>
      </div>
      <div className='flex flex-col mb-4 gap-4'>
        <h3 className='mb-0 text-3xl font-semibold'>Assigned Storage</h3>
        {assignedStorageTableDataSource.length === 0 ? (
          <div className='flex flex-col items-start gap-4'>
            <p className='mb-0 text-lg font-normal'>
              No storage spots have been assigned yet.
            </p>
          </div>
        ) : (
          <div className='w-full'>
            <CustomTable
              className='rounded-lg'
              dataSource={assignedStorageTableDataSource}
              columns={tableColumns}
              tailwindTable
              loading={false}
              idBeingDeleted={''}
              deleting={false}
              isFromContracts
              showTablePagination={false}
              noMargin
            />
          </div>
        )}
      </div>
      {process.env.REACT_APP_AVESDO_FLAG === 'true' && (
        <div className='flex flex-col gap-y-3'>
          {avesdoUnAssignedWaitlistStorages.length > 0 && (
            <div className='flex flex-col mb-4 gap-4'>
              <h3 className='mb-0 text-3xl font-semibold'>Storage waitlists</h3>
              <CustomTable
                className='rounded-lg'
                dataSource={avesdoUnAssignedWaitlistStorages}
                columns={tableColumnsAvesdoStorage}
                tailwindTable
                loading={false}
                idBeingDeleted={''}
                deleting={false}
                isFromContracts
                showTablePagination={false}
                noMargin
              />
            </div>
          )}
        </div>
      )}
      {allocationModal && <AllocationModal />}
      {assignStorageModal && <AssignStorageModal />}
      {editStorageModal && <EditStorageModal />}
    </div>
  );
};
