import { useGetPendingSendGridJobs } from 'hooks/useGetLists'
import { Loader } from 'lucide-react'
import React, { useEffect } from 'react'

import {
  subscribeToChannel,
  unsubscribeToChannel
} from 'services/pusher'

// eslint-disable-next-line space-before-function-paren
export default function JobsRunning(
  props // eslint-disable-line
) {
  const { type, id, count: refetchData } = props
  const { isLoading, data: jobs, refetch } = useGetPendingSendGridJobs(type, id)
  const [count, setCount] = React.useState(0)

  useEffect(() => {
    refetch()
  }, [count, refetchData, refetch])

  useEffect(() => {
    let channel = null
    const channelId = `${type}-${id}`
    channel = subscribeToChannel(channelId)
    channel?.bind('update', (message) => {
      console.log('message', message)
      setCount((prev) => prev + 1)
    })
    return () => {
      if (channel) {
        channel.unbind('update')
      }
      unsubscribeToChannel(channelId)
    }
  }, [id, type])
  return (
    <div className='flex flex-col'>
      {!isLoading && jobs && jobs.map((job) => {
        return (
          <div className='flex justify-between items-center border border-border px-2 py-1 rounded-sm my-0.5 bg-gray-200 '>
            <div className='text-sm'>{job.message}</div>
            <div>
              <Loader size='18' className='animate-spin' />
            </div>
          </div>
        )
      })}
    </div>
  )
}
