// @ts-nocheck
/* eslint-disable space-before-function-paren */
import React, { useEffect, useState } from 'react'
import { AgGridReact } from 'ag-grid-react' // React Data Grid Component
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'

const _paginationPageSizeSelector = [5, 10, 20, 50, 100, 200, 500, 1000]
// eslint-disable-next-line space-before-function-paren
export default function TableGrid(
  {
    multiple = false,
    rowSelectKey = '_id',
    allowSelect = false,
    allowSelectAll = false,
    actions = [],
    columnDefs, getData, pagination = true, paginationPageSize = 50, style = {
      height: '500px',
      minHeight: '500px'
    },
    children = null
  }
) {
  const ref = React.useRef()
  const [_pagination] = useState(pagination)
  const [_paginationCurrentPage, setPaginationCurrentPage] = useState(1)
  const [_paginationPageSize, setPaginationPageSize] = useState(paginationPageSize)
  const [rowData, setRowData] = useState([])
  const [_sorting, setSorting] = useState([])
  const [loading, setLoading] = useState(false)
  const [processingAction, setProcessingAction] = React.useState({})
  // Column Definitions: Defines the columns to be displayed.
  const [selectedRows, setSelectedRows] = useState([])
  useEffect(() => {
    setLoading(true)
    getData(
      {},
      {
        page: _paginationCurrentPage,
        limit: _paginationPageSize,
        sort: _sorting
      }
    ).then((data) => {
      setRowData(data)
    }).finally(() => {
      setLoading(false)
    })
  }, [_paginationPageSize, _paginationCurrentPage, _sorting, getData])

  function reloadTableData() {
    clearSelectedRows()
  }

  function clearSelectedRows() {
    if (ref?.current) {
      ref?.current?.api?.deselectAll()
    }
  }

  return (
    <div
      className="ag-theme-alpine h-fit "
      style={{
        height: style?.height || '500px',
        minHeight: style?.minHeight || '500px'
      }}
    >
      <div className='flex justify-between gap-x-2 my-1.5 items-center'>
        <div>
          {selectedRows.length > 0 && <div><span>{selectedRows.length}</span> rows selected</div>}
        </div>
        <div>
          {actions.filter(action => {
            const visible = action.onlyWhenSelected ? selectedRows.length > 0 : true
            return visible
          })
            .map((action, index) => {
              return (
                <div>
                  <button
                    disabled={processingAction[action.label]}
                    key={index}
                    className='px-4 py-2 bg-black text-white border border-gray-300 rounded-md shadow-sm'
                    onClick={async () => {
                      if (action?.apply && typeof action?.apply === 'function') {
                        setProcessingAction({
                          ...processingAction,
                          [action.label]: true
                        })
                        await action?.apply(selectedRows)
                        setProcessingAction({
                          ...processingAction,
                          [action.label]: false
                        })
                      }
                    }}
                  >
                    {processingAction[action.label] ? (action.progressLabel || '...') : action.label}
                  </button>
                </div>
              )
            })
          }
        </div>
      </div>
      <AgGridReact
        loading={loading}
        ref={ref}
        rowSelection={multiple ? 'multiple' : 'single'}
        pagination={_pagination}
        suppressRowClickSelection={true}
        enableRangeSelection={true}
        enableCellTextSelection={true}
        suppressClearOnFillReduction={true}
        paginationPageSize={_paginationPageSize}
        paginationPageSizeSelector={_paginationPageSizeSelector}
        rowData={rowData}
        onRowSelected={(event) => {
          const selectedRows = event.api.getSelectedRows()
          const selectedIds = selectedRows.map((row) => row[rowSelectKey])
          if (Array.isArray(selectedIds)) {
            const validIds = selectedIds.filter((e) => e)
            return setSelectedRows(validIds)
          }
        }}
        onSortChanged={(event) => {
          const columns = event.columns
          if (!columns) {
            return setSorting([])
          }
          const sort = columns.map((column) => {
            return {
              id: column.getColId(),
              sort: column.getSort()
            }
          }).filter((column) => column.sort)
          setSorting(sort)
        }}
        onPaginationChanged={(event) => {
          const isPageSizeChanged = event.newPageSize
          if (isPageSizeChanged) {
            const newPageSize = event.api.paginationGetPageSize()
            setPaginationPageSize(newPageSize)
          }
          const isPageChanged = event.newPage
          if (isPageChanged) {
            const newPage = event.api.paginationGetCurrentPage()
            setPaginationCurrentPage(newPage)
          }
        }
        }
        columnDefs={columnDefs.map((column, index) => {
          if (index === 0) {
            column.checkboxSelection = allowSelect
            column.headerCheckboxSelection = allowSelectAll
          }
          return column
        })}
      >
        {children && children?.({
          reloadTableData
        })}
      </AgGridReact>
    </div>
  )
}
