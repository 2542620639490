import React from 'react'
import { Col } from 'antd'
import styled from 'styled-components'
import propTypes from 'prop-types'
import { LabelOnly } from 'ui'
import globals from 'assets/css/standardStyle'
import Skeleton from 'react-loading-skeleton'
import { getRandomInteger } from 'utils'

const CombinedWrapper = styled.div`
  display:inline-flex;
  width:100%;
`

const { colors } = globals
const { borderRadius } = globals.inputs

const ChildrenWrapper = styled.div`
  flex-grow: 1;
  
  & > input {
    border-right-color: ${props => props.rightSide ? 'transparent' : colors.input.border};
    border-top-right-radius: ${props => props.rightSide ? 0 : borderRadius.medium};
    border-bottom-right-radius: ${props => props.rightSide ? 0 : borderRadius.medium};
    border-top-left-radius: ${props => props.leftSide ? 0 : borderRadius.medium};
    border-bottom-left-radius: ${props => props.leftSide ? 0 : borderRadius.medium};
    border-left-color: ${props => props.leftSide ? 'transparent' : colors.input.border};
    line-height:1.6;
  }
`

const RightSideWrapper = styled.div`
  min-width:75px;
  flex-basis: 75px;
  flex-shrink: 0; 

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-left:0;
    border-top-left-radius:0 !important;
    border-bottom-left-radius:0 !important;
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    transform: translateY(3px);
  }
`

const LeftSideWrapper = styled.div`
  flex-basis: 69px;
  flex-shrink: 0; 
  
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-right:0;
    border-top-right-radius:0 !important;
    border-bottom-right-radius:0 !important;
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    transform: translateY(3px);
  }
`

const SkeletonContainer = styled.div`
  margin-top: -5px;
`

const CombinedFields = props => {
  const { children, xs, sm, md, lg, leftSide, rightSide, label, loading } = props
  const mediaQueries = { xs, sm, md, lg }

  return (
    <Col {...mediaQueries}>
      <LabelOnly style={{ marginBottom: 0 }}>{loading ? <Skeleton width={getRandomInteger(60, 120)} /> : label ? `${label}:` : ''}</LabelOnly>
      <CombinedWrapper>
        {leftSide && (
          <LeftSideWrapper>
            {loading
              ? (
                <SkeletonContainer>
                  <Skeleton height={36} />
                </SkeletonContainer>)
              : leftSide}
          </LeftSideWrapper>
        )}
        <ChildrenWrapper leftSide={Boolean(leftSide)} rightSide={Boolean(rightSide)}>
          {loading
            ? (
              <SkeletonContainer>
                <Skeleton height={36} />
              </SkeletonContainer>)
            : children}
        </ChildrenWrapper>
        {rightSide && (
          <RightSideWrapper>
            {loading
              ? (
                <SkeletonContainer>
                  <Skeleton height={36} />
                </SkeletonContainer>)
              : rightSide}
          </RightSideWrapper>
        )}
      </CombinedWrapper>
    </Col>
  )
}

CombinedFields.propTypes = {
  rightSide: propTypes.node,
  leftSide: propTypes.node,
  children: propTypes.node.isRequired,
  xs: propTypes.number,
  sm: propTypes.number,
  md: propTypes.number,
  lg: propTypes.number,
  label: propTypes.string,
  loading: propTypes.bool
}

export default CombinedFields
