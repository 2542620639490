import React from 'react'

const Selection = ({ value, onChangeValue, Options }) => {
  return (
    <select
      onChange={onChangeValue}
      value={value}
      className="bg-inherit border-none outline-0 focus:ring-0 outline-transparent shadow-none focus:shadow-none text-softBlack py-0 font-semibold text-sm pr-7 pl-0 bg-inherit"
    >
      {Options?.map((_option, index) => (
        <option key={index} value={_option.value}>
          {_option.label}
        </option>
      ))}
    </select>
  )
}

export default Selection
