import React from 'react'
import {
  TermFooterButtonContainer, TermButton,
  TermButtonLoader, TermButtonText
} from './buttonLoaderStyles'
import { Modal } from 'antd'
import propTypes from 'prop-types'

const { confirm } = Modal

const ButtonLoader = ({
  termRead, loading,
  handlePostTerm, qtyTermRead
}) => {
  const showConfirmation = () => {
    confirm({
      title: 'Confirma o aceite do termo?',
      // icon: 'none',
      okText: 'Sim',
      cancelText: 'Não',
      body: '',
      // content: 'Some descriptions',
      onOk () {
        handlePostTerm()
      },
      onCancel () {

      }
    })
  }

  return (
    <TermFooterButtonContainer>
      <TermButton
        variants={{
          termRead: {
            scale: [1, 1.1, 1, 1.1, 1],
            transition: {
              duration: 0.5
            }
          }
        }}
        animate={termRead && 'termRead'}
        style={{ cursor: termRead && 'pointer' }}
        disabled={!termRead}
        onClick={!loading && termRead && showConfirmation}
        termRead={termRead}
        size='default'
      >
        <TermButtonLoader
          style={{ width: `${loading ? 0 : qtyTermRead}%` }}
          termRead={termRead}
        />
        <TermButtonText
          termRead={termRead}
        >
          {
            termRead
              ? 'Concordo que li o termo e assino com '
              : `Role o termo para comprovar a leitura e a aceitação (${Math.round(qtyTermRead)}%)`
          }
        </TermButtonText>
      </TermButton>

    </TermFooterButtonContainer>)
}

export default ButtonLoader

ButtonLoader.propTypes = {
  termRead: propTypes.bool,
  loading: propTypes.bool,
  handlePostTerm: propTypes.func,
  qtyTermRead: propTypes.number
}
