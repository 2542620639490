import useContactContext from 'pages/newContacts/hooks/useContactContext'
import React from 'react'
import { FaFilter } from 'react-icons/fa'

const FilterToggle = () => {
  const { state, openFilterDrawer } = useContactContext()

  if (state.isFilterDrawerOpen) return null
  return (
    <div onClick={() => openFilterDrawer()} className='cursor-pointer flex justify-center items-center'><FaFilter fontSize='18px' /></div>
  )
}

export default FilterToggle
