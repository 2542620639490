import React, { useEffect, useCallback, useState } from 'react'
import propTypes from 'prop-types'

import {
  UploadButtonWrapper,
  StyledImage
} from './styles'
import { LoadingIcon } from 'components'

const ImageUploadButtonWithThumbnail = ({
  // Button props
  uploadingImage,
  uploadButtonIcon,
  uploadButtonText,
  uploadButtonStyle = {},
  onClick = () => {},
  image
}) => {
  const [localImage, setLocalImage] = useState('')

  const transformImage = useCallback(() => {
    // Check if the image passed is an object
    // If so, transform do a URL file to be rendered
    if (typeof image === 'object') {
      const reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onload = () => setLocalImage(reader.result)
    } else {
      setLocalImage(image)
    }
  }, [image])

  useEffect(() => {
    transformImage()
  }, [transformImage])

  return (
    <UploadButtonWrapper
      style={uploadButtonStyle}
      onClick={onClick}
    >
      {localImage
        ? (
            <StyledImage src={localImage} alt='avatar' />
          )
        : (
            <>
              {uploadingImage ? <LoadingIcon /> : uploadButtonIcon}
              <div className='text-center' style={{ marginTop: 8 }}>{uploadButtonText}</div>
            </>
          )}
    </UploadButtonWrapper>
  )
}

export default ImageUploadButtonWithThumbnail

ImageUploadButtonWithThumbnail.propTypes = {
  uploadingImage: propTypes.bool,
  uploadButtonIcon: propTypes.node,
  uploadButtonText: propTypes.string,
  uploadButtonStyle: propTypes.object,
  onClick: propTypes.func,
  image: propTypes.object
}
