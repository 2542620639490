/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable */
// @ts-nocheck
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import CustomInput from 'components/CustomInput';
import { Oval } from 'react-loader-spinner';
import { useSelector, connect } from 'react-redux';
import { getDevelopers } from 'store/actions/developersActions';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
// import { getContractTemplates } from 'store/actions/contractTemplateActions'
import CustomTextArea from 'components/CustomTextArea';
import { FadedAnimatedDiv, TFilledPenIcon } from 'components';
import Searching from 'pages/contacts/Searching';
import { ReactComponent as Done } from '../../../../assets/icons/done.svg';
import {
  createOrUpdateProject,
  getProjects,
} from 'store/actions/projectActions';
import { CREATE_PROJECT_IMAGES_STEP } from '../constant';
import { changeAppProject } from 'store/actions/appActions';
import CreateProjectContext from '../context/CreateProjectProvider';
import useProjectChange from 'hooks/useProjectChange';
import useCurrencyList from 'hooks/useCurrencyList';
import Checkbox from 'components/Checkbox';
import { Modal } from '@material-ui/core';
import { Check, CircleX } from 'lucide-react';
import Button from 'pages/reservation/NewReservation/components/Button';
import { isMobile } from 'react-device-detect';
import CustomNumberInput from 'components/CustomNumberInput';
import CustomButton from 'pages/contacts/CustomButton';
import CustomSelect from 'pages/tasks/components/CustomSelect';

const ProjectManagement = ({
  type = 'create',
  setStep = () => { },
  changeAppProject,
}) => {
  const { onCurrencyChange } = useProjectChange();
  const { formattedCurrencyList: currencyData } = useCurrencyList();
  const mountedRef = useRef(true);
  const countryData = [
    { id: 'Canada', value: 'Canada' },
    { id: 'United States', value: 'United States' },
    { id: 'Mexico', value: 'Mexico' },
  ];

  const initialReducerState = useSelector((state) => state.appReducer);

  const localProjectId = initialReducerState?.newProject
    ? ''
    : initialReducerState.appProject;
  const [developersData, setDevelopersData] = useState([]);
  // const [contractsData, setContractsData] = useState([])
  const [editMode, setEditMode] = useState(true);
  const [editModeTask, setEditModeTask] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const projectcontext = useContext(CreateProjectContext);
  const setNewProjectId = projectcontext.setNewProjectId;
  const newProjectId = projectcontext.newProjectId;

  const initialStateOfValues = {
    localProjectId: '',
    projectName: '',
    description: '',
    websiteUrl: '',
    city: '',
    province: '',
    country: '',
    totalDollarValue: 0,
    totalDollarValueCurrencyType: '',
    numberOfUnits: '',
    developer: '',
    reservationMaxDays: '',
    brokerMaxReservationDays: '',
    projectBackgroundColor: '',
    originalProjectBackgroundColor: '',
    paymentRounds: [],
    contracts: [],
    signer: {
      name: '',
      email: '',
    },
    images: [],
    backgroundImage: '',
    logoImage: '',
    signerName: '',
    signerEmail: '',
    isAutoTaskCreate: false,
    unitVariable: '',
    autoCreateTasks: [],
    showProjectDemandNote: true,
    showCorporateDemandNote: true,
  };
  const [values, setValues] = useState(initialStateOfValues);
  const [taskManageModal, setTaskManageModal] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState({
    title: '',
    description: '',
    dueDays: 0,
    taskType: '',
  });
  const [errors, setErrors] = useState({
    projectName: false,
    description: false,
    websiteUrl: false,
    city: false,
    country: false,
    totalDollarValue: false,
    totalDollarValueCurrencyType: false,
    numberOfUnits: false,
    developer: false,
    reservationMaxDays: false,
    brokerMaxReservationDays: false,
  });

  useEffect(() => {
    if (!mountedRef.current) return null;
    if (initialReducerState.newProject === true) {
      setValues(initialStateOfValues);
    }
  }, [initialReducerState]);

  const handleAddNewTask = () => {
    setTaskManageModal(true);
  };
  const deleteTask = (i) => {
    setTasks([
      ...tasks.filter((task, index) => {
        return index !== i;
      }),
    ]);
  };
  const onChangeAppProject = (selectedproject) => {
    const projectId = selectedproject;
    const developerId = values.developer;
    changeAppProject({ projectId, developerId });
  };

  const onChange = (field, value) => {
    setValues({ ...values, [field]: value });
  };

  const onTaskChange = (field, value) => {
    setSelectedTask({
      ...selectedTask,
      [field]: value,
    });
  };
  const taskTypes = [
    { label: 'Email', value: 'email' },
    { label: 'Call', value: 'call' },
    { label: 'Other', value: 'other' },
  ];
  const fetchData = useCallback(async () => {
    if (type !== 'create') {
      setEditMode(false);
      setEditModeTask(false);
    } else {
      setEditMode(true);
      setEditModeTask(true);
      setIsLoading(false);
    }

    const tmpDevelopers = await getDevelopers('');
    const tmpDevelopersData = tmpDevelopers.map((developer) => ({
      id: developer._id,
      value: developer.companyName,
    }));

    if (!mountedRef.current) return;
    setDevelopersData(tmpDevelopersData);
  }, [type]);

  useEffect(() => {
    fetchData();
    return () => {
      mountedRef.current = false;
    };
  }, [fetchData]);

  const onValidate = () => {
    const requiredFields = [
      'projectName',
      'description',
      'website',
      'city',
      'country',
      'totalDollarValue',
      'totalDollarValueCurrencyType',
      'numberOfUnits',
      'developer',
      'reservationMaxDays',
      'brokerMaxReservationDays',
    ];
    for (let i = 0; i < requiredFields.length; i++) {
      if (values[requiredFields[i]] === '') {
        return false;
      }
      if (
        (requiredFields[i] === 'reservationMaxDays' ||
          requiredFields[i] === 'brokerMaxReservationDays') &&
        (parseInt(values[requiredFields[i]]) <= 0 ||
          parseInt(values[requiredFields[i]]) > 14)
      ) {
        return false;
      }
    }
    return true;
  };

  const getProjectData = useCallback(() => {
    setIsLoading(true);
    if (!values.localProjectId) {
      setIsLoading(false);
      return;
    }
    getProjects(values.localProjectId).then((project) => {
      if (!mountedRef.current) return null;
      const {
        _id,
        projectName,
        description,
        websiteUrl,
        city,
        province,
        country,
        totalDollarValue,
        totalDollarValueCurrencyType,
        numberOfUnits,
        paymentRounds,
        options,
        logoImage,
        backgroundImage,
        images,
        developer,
        contracts,
        reservationMaxDays,
        projectBackgroundColor,
        brokerMaxReservationDays,
        signer,
        isAutoTaskCreate,
        unitVariable,
        autoCreateTasks,
        showProjectDemandNote,
        showCorporateDemandNote,
      } = project;
      setValues({
        localProjectId: _id,
        projectName,
        websiteUrl,
        description: description ?? '',
        city,
        province,
        country,
        totalDollarValue,
        totalDollarValueCurrencyType,
        numberOfUnits,
        paymentRounds,
        options,
        logoImage,
        backgroundImage,
        images,
        developer: developer._id,
        contracts,
        reservationMaxDays,
        projectBackgroundColor,
        brokerMaxReservationDays,
        signer,
        signerEmail: signer?.email,
        signerName: signer?.name,
        isAutoTaskCreate,
        unitVariable,
        autoCreateTasks: autoCreateTasks || [],
        showProjectDemandNote: showProjectDemandNote || true,
        showCorporateDemandNote: showCorporateDemandNote || true,
      });
      if (autoCreateTasks && autoCreateTasks.length > 0) {
        const updatedTasks = autoCreateTasks.map((task) => ({
          ...task,
          id: task._id,
        }));
        setTasks([...updatedTasks]);
      }
      setIsLoading(false);
    });
  }, [values.localProjectId]);

  useEffect(() => {
    if (type === 'edit') {
      setValues({ localProjectId });
    }
    if (newProjectId) {
      setValues({ localProjectId: newProjectId });
    }
    if (localProjectId) {
      setValues({ localProjectId });
    }
  }, [type, newProjectId, localProjectId]);

  useEffect(() => {
    if (localProjectId) {
      getProjectData();
    }
  }, [values.localProjectId]);
  const validate = () => {
    let isValid = true;
    const _errors = { ...errors };
    Object.entries(values).forEach(function ([key, val]) {
      if (key in _errors) {
        if (val === '' || val === undefined) {
          _errors[key] = true;
        } else {
          _errors[key] = false;
          if (
            (key === 'reservationMaxDays' ||
              key === 'brokerMaxReservationDays') &&
            (parseInt(val) <= 0 || parseInt(val) > 14)
          ) {
            _errors[key] = true;
          }
        }
        setErrors(_errors);
      }
    });
    Object.entries(_errors).forEach(function ([key, val]) {
      if (val === true) {
        isValid = false;
      }
    });
    return isValid;
  };

  const _onSaveClick = useCallback(
    () =>
      new Promise((resolve, reject) => {
        const isValid = validate();

        if (isValid) {
          setLoading(true);
          const params = {
            _id: values.localProjectId,
            projectName: values.projectName,
            websiteUrl: values.websiteUrl,
            city: values.city,
            description: values.description,
            province: values.province,
            country: values.country,
            totalDollarValue: values.totalDollarValue,
            totalDollarValueCurrencyType: values.totalDollarValueCurrencyType,
            numberOfUnits: values.numberOfUnits,
            developer: values.developer,
            paymentRounds: values.paymentRounds,
            contracts: values.contracts,
            reservationMaxDays: values.reservationMaxDays,
            brokerMaxReservationDays: values.brokerMaxReservationDays,
            projectBackgroundColor: values.projectBackgroundColor,
            signer: {
              name: values.signerName,
              email: values.signerEmail,
            },
            logoImage: values.logoImage,
            backgroundImage: values.backgroundImage,
            images: values.images,
            isAutoTaskCreate: values.isAutoTaskCreate,
            unitVariable: values.unitVariable,
            autoCreateTasks: tasks,
            showProjectDemandNote: values.showProjectDemandNote,
            showCorporateDemandNote: values.showCorporateDemandNote,
          };
          onCurrencyChange(values.totalDollarValueCurrencyType);
          createOrUpdateProject({ params })
            .then((projectData) => {
              setLoading(false);
              onChangeAppProject(projectData._id);
              if (type === 'new') {
                setNewProjectId(projectData._id);
              }
              if (type === 'edit') {
                setEditMode(false);
                setEditModeTask(false);
              }
              localStorage.setItem(
                `projectId_${projectData._id}`,
                projectData?.unitVariable || 'Unit'
              );
              setStep(CREATE_PROJECT_IMAGES_STEP);
            })
            .catch(() => {
              setLoading(false);
              getProjectData();
              reject();
            });
        }
      }),
    [values, getProjectData, tasks]
  );

  const LoadingProjectDetails = () => (
    <FadedAnimatedDiv className='h-full w-full flex flex-col items-center mt-20'>
      <Searching title='Loading Project Information...' />
    </FadedAnimatedDiv>
  );

  // useEffect(() => console.log(errors), [errors]);
  return (
    <>
      {isLoading && <LoadingProjectDetails />}
      {!isLoading && (
        <div className='flex flex-col gap-y-6 mb-6'>
          <div className='p-6 rounded-xl border bg-card text-card-foreground shadow bg-white'>
            <h1 className='font-black text-lg font-openSans'>
              General Information
            </h1>
            <div className='border-b-2 border-black my-2' />
            <h2 className='font-openSans'>
              Please enter the details about the projectS
            </h2>

            <div className='grid grid-cols-2  gap-6 border-0 p-0 mt-10 rounded-none shadow-none'>
              <div>
                <CustomInput
                  label='Project Name *'
                  labelClassName='text-xs italic font-openSans font-light'
                  placeholder='Enter project name'
                  value={values.projectName}
                  onChange={(e) => onChange('projectName', e.target.value)}
                  classes='font-openSans text-base'
                  style={{ maxHeight: '48px' }}
                  disabled={!editMode}
                  required='required'
                  error={errors.projectName}
                />
                <div className='mt-6'>
                  <CustomInput
                    label='Website *'
                    labelClassName='text-xs italic font-openSans font-light'
                    placeholder='http://'
                    value={values.websiteUrl}
                    onChange={(e) => onChange('websiteUrl', e.target.value)}
                    classes='font-openSans text-base'
                    style={{ maxHeight: '48px' }}
                    disabled={!editMode}
                    required='required'
                    error={errors.websiteUrl}
                  />
                </div>
              </div>
              <div>
                <CustomTextArea
                  label='Project Description *'
                  placeholder='Enter description'
                  labelClasses='text-xs italic font-openSans font-light'
                  value={values.description}
                  onChange={(e) => onChange('description', e.target.value)}
                  style={{ height: '144px' }}
                  textareaClasses='font-openSans'
                  disabled={!editMode}
                  required='required'
                  error={errors.description}
                />
              </div>
              <CustomInput
                label='City *'
                labelClassName='italic font-openSans font-light'
                placeholder='Enter city'
                value={values.city}
                onChange={(e) => onChange('city', e.target.value)}
                classes='font-openSans text-base'
                style={{ maxHeight: '48px' }}
                disabled={!editMode}
                required='required'
                error={errors.city}
              />
              <CustomSelect
                label='Country *'
                labelClasses='text-left italic font-openSans font-light'
                inputClasses='h-10 bg-white font-openSans'
                selectedOption={values?.country}
                options={countryData}
                setSelectedOption={(e) => onChange('country', e)}
                fonts={'font-openSans'}
                fieldName={'country'}
                disabled={!editMode}
                required='required'
                error={errors.country}
              />
              <CustomInput
                label='Total Value *'
                labelClassName='text-xs italic font-openSans font-light'
                placeholder='Enter value'
                value={values?.totalDollarValue}
                onChange={(e) => onChange('totalDollarValue', e.target.value)}
                classes='font-openSans text-base'
                style={{ maxHeight: '48px' }}
                disabled={!editMode}
                required='required'
                error={errors.totalDollarValue}
              />
              <div className='grid grid-cols-2 gap-6'>
                <CustomSelect
                  label='Currency *'
                  labelClasses='text-xs italic font-openSans font-light'
                  inputClasses='h-10 bg-white font-openSans'
                  selectedOption={values?.totalDollarValueCurrencyType}
                  options={currencyData}
                  setSelectedOption={(e) =>
                    onChange('totalDollarValueCurrencyType', e)
                  }
                  fonts={'font-openSans'}
                  fieldName={'currency'}
                  disabled={!editMode}
                  required='required'
                  error={errors.totalDollarValueCurrencyType}
                />
                <CustomInput
                  label='Number of Units *'
                  labelClassName='text-xs italic font-openSans font-light'
                  placeholder='Enter units'
                  value={values.numberOfUnits}
                  onChange={(e) => onChange('numberOfUnits', e.target.value)}
                  classes='font-openSans text-base'
                  style={{ maxHeight: '48px' }}
                  disabled={!editMode}
                  required='required'
                  type='number'
                  min={1}
                  error={errors.numberOfUnits}
                />
              </div>
              <CustomSelect
                label='Developer *'
                labelClasses='text-xs italic font-openSans font-light'
                inputClasses='h-10 bg-white font-openSans'
                selectedOption={values?.developer}
                options={developersData}
                setSelectedOption={(e) => onChange('developer', e)}
                fonts={'font-openSans'}
                fieldName={'developer'}
                disabled={!editMode}
                required='required'
                error={errors.developer}
              />
              <div className='grid grid-cols-2 gap-6'>
                <CustomInput
                  label='Days to keep units reserved *'
                  labelClassName='text-xs italic font-openSans font-light'
                  placeholder='Enter days'
                  min={1}
                  max={14}
                  type='number'
                  value={values.reservationMaxDays}
                  onChange={(e) =>
                    onChange('reservationMaxDays', e.target.value)
                  }
                  classes='font-openSans text-base'
                  style={{ maxHeight: '48px' }}
                  disabled={!editMode}
                  required='required'
                  error={errors.reservationMaxDays}
                />
                <CustomInput
                  label='Inventory allocation days *'
                  labelClassName='text-xs italic font-openSans font-light'
                  placeholder='Enter days'
                  value={values.brokerMaxReservationDays}
                  onChange={(e) =>
                    onChange('brokerMaxReservationDays', e.target.value)
                  }
                  classes='font-openSans text-base'
                  min={1}
                  max={14}
                  type='number'
                  style={{ maxHeight: '48px' }}
                  disabled={!editMode}
                  required='required'
                  error={errors.brokerMaxReservationDays}
                />
              </div>
              <CustomInput
                label='Project background color'
                labelClassName='text-xs italic font-openSans font-light'
                placeholder='HEX #'
                value={values.projectBackgroundColor}
                onChange={(e) =>
                  onChange('projectBackgroundColor', e.target.value)
                }
                classes='font-openSans text-base'
                style={{ maxHeight: '48px' }}
                disabled={!editMode}
              />
              {/* <CustomSelect
              label='Contracts'
              labelClasses='text-left text-xs italic font-openSans font-light'
              inputClasses='h-10 bg-white font-openSans'
              selectedOption={values.contracts}
              options={contractsData}
              setSelectedOption={(e) => onChange('contracts', e)}
              fonts={'font-openSans'}
              fieldName={'contracts'}
              multiple={true}
              disabled={!editMode}
            /> */}
              <CustomInput
                label='Signer Name'
                labelClassName='text-xs italic font-openSans font-light'
                placeholder='Enter full name'
                value={values.signerName}
                onChange={(e) => onChange('signerName', e.target.value)}
                classes='font-openSans text-base'
                style={{ maxHeight: '48px' }}
                disabled={!editMode}
              />
              <CustomInput
                label='Signer Email'
                labelClassName='text-xs italic font-openSans font-light'
                placeholder='Enter email'
                value={values.signerEmail}
                onChange={(e) => onChange('signerEmail', e.target.value)}
                classes='font-openSans text-base'
                style={{ maxHeight: '48px' }}
                disabled={!editMode}
              />
              <CustomInput
                label='Unit Variable Name'
                labelClassName='text-xs italic font-openSans font-light'
                placeholder='Enter name to use for unit'
                value={values.unitVariable}
                onChange={(e) => onChange('unitVariable', e.target.value)}
                classes='font-openSans text-base'
                style={{ maxHeight: '48px' }}
                disabled={!editMode}
              />
            </div>
            <div className='col-span-2 flex mt-2 flex-col gap-y-4'>
              <Checkbox
                iconClasses={'!h-6 !w-6'}
                labelClasses={'text-sm text-softBlack70 italic font-openSans font-light'}
                checked={values.showProjectDemandNote}
                label={'Show Project Demand Note'}
                handleChange={(e) => {
                  editMode && onChange('showProjectDemandNote', !values.showProjectDemandNote)
                }}
                disabled={!editMode}
              />
              <Checkbox
                iconClasses={'!h-6 !w-6'}
                labelClasses={'text-sm text-softBlack70 italic font-openSans font-light'}
                checked={values.showCorporateDemandNote}
                label={'Show Corporate Demand Note'}
                handleChange={(e) => {
                  editMode && onChange('showCorporateDemandNote', !values.showCorporateDemandNote)
                }}
                disabled={!editMode}
                />
            </div>
            {type !== 'create' &&
              (!editMode ? (
                <div className='w-full flex justify-end mt-6'>
                  <CustomButton
                    handleClick={() => setEditMode(true)}
                  >
                    <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
                      <TFilledPenIcon className='h-4 w-4' />
                      Edit
                    </span>
                  </CustomButton>
                </div>
              ) : (
                <div className='w-full flex justify-between items-center mt-6'>
                  <CustomButton
                    handleClick={() => {
                      getProjectData();
                      setEditMode(false);
                    }}
                    className='w-fit'
                  >
                    <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
                      Cancel
                    </span>
                  </CustomButton>
                  <CustomButton
                    handleClick={_onSaveClick}
                    className='w-fit'
                  >
                    <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
                      {loading && <Oval height='18' width='18' color='white' />}
                      {!loading && <Check className='h-5 w-5' />}
                      Save
                    </span>
                  </CustomButton>
                </div>
              ))}
            {type === 'create' && (
              <div className='w-full flex justify-end mt-6'>
                <CustomButton
                  handleClick={_onSaveClick}
                  disabled={!onValidate()}
                >
                  <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
                    {loading && <Oval height='18' width='18' color='white' />}
                    Next
                  </span>
                </CustomButton>
              </div>
            )}
          </div>
          <div className='p-6 rounded-xl border bg-card text-card-foreground shadow bg-white'>
            <h1 className='font-black text-lg font-openSans'>Auto Tasks</h1>
            <div className='border-b-2 border-black my-2' />
            <h2 className='font-openSans'>
              Automatically Create Tasks When a New Contact is Added
            </h2>
            <div className='flex items-center my-3 gap-x-3'>
              <Checkbox
                iconClasses={'!h-6 !w-6'}
                labelClasses={
                  'text-sm text-softBlack70 italic font-openSans font-light'
                }
                checked={values.isAutoTaskCreate}
                label={'Create task on contact creation'}
                handleChange={(e) => {
                  if (!editModeTask) return;
                  onChange('isAutoTaskCreate', !values.isAutoTaskCreate);
                }}
              />
            </div>
            {values.isAutoTaskCreate && (
              <div className='flex flex-col mt-4 gap-y-6'>
                <div className='flex flex-col gap-y-4'>
                  {tasks?.map((task, index) => (
                    <div
                      key={index}
                      className='self-stretch justify-start items-start gap-6 inline-flex'
                    >
                      <div className='grow shrink basis-0 flex-col justify-start border border-md py-5 px-4 items-start gap-2.5 inline-flex'>
                        <div className='group self-stretch  flex-col justify-start items-start gap-1.5 flex'>
                          <div className='w-full flex justify-between items-center'>
                            <div className='text-zinc-800 text-lg'>
                              {task?.title}
                            </div>
                            <div className='flex gap-x-2'>
                              <Button
                                disabled={!editModeTask}
                                className='py-3 px-4'
                                btnText='Edit Task'
                                handleClick={() => {
                                  console.log(task);
                                  setSelectedTask({
                                    ...task,
                                    _id: task._id,
                                  });
                                  setTaskManageModal(true);
                                }}
                              />
                              <Button
                                disabled={!editModeTask}
                                className='py-3 px-4'
                                btnText='Delete Task'
                                handleClick={() => {
                                  deleteTask(index);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <CustomButton
                  disabled={!editModeTask}
                  className='w-fit mt-2'
                  handleClick={handleAddNewTask}
                >
                  <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
                    Add Task
                  </span>
                </CustomButton>
              </div>
            )}
            {type !== 'create' &&
              (!editModeTask ? (
                <div className='w-full flex justify-end mt-6'>
                  <CustomButton
                    handleClick={() => setEditModeTask(true)}
                  >
                    <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
                      <TFilledPenIcon className='h-4 w-4' />
                      Edit
                    </span>
                  </CustomButton>
                </div>
              ) : (
                <div className='w-full flex justify-between items-center mt-6'>
                  <CustomButton
                    handleClick={() => {
                      getProjectData();
                      setEditMode(false);
                    }}
                    className='w-fit'
                  >
                    <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
                      Cancel
                    </span>
                  </CustomButton>
                  <CustomButton
                    handleClick={_onSaveClick}
                    className='w-fit'
                  >
                    <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
                      {loading && <Oval height='18' width='18' color='white' />}
                      {!loading && <Check className='h-5 w-5' />}
                      Save
                    </span>
                  </CustomButton>
                </div>
              ))}
          </div>
          {taskManageModal && (
            <Modal
              open={taskManageModal}
              onClose={() => {
                setTaskManageModal(false);
                setSelectedTask({
                  title: '',
                  description: '',
                  dueDays: 0,
                  taskType: '',
                });
              }}
            >
              <div
                className='bg-grey-8 p-5 md:p-10 relative h-fit overflow-auto rounded shadow-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'
                style={{ width: isMobile ? '95vw' : 544 }}
              >
                <button
                  className='absolute top-4 right-5 md:right-6'
                  onClick={() => {
                    setTaskManageModal(false);
                    setSelectedTask({
                      title: '',
                      description: '',
                      dueDays: 0,
                      taskType: '',
                    });
                  }}
                >
                  <CrossIcon />
                </button>
                <h3 className='text-center my-2 text-xl font-bold'>
                  {selectedTask?.id ? 'Update Task' : 'New Task'}
                </h3>
                <div className='flex flex-col gap-y-3'>
                  <CustomInput
                    label='Title *'
                    placeholder='Enter title'
                    labelClassName='mt-0 mb-2 text-softBlack70 text-xs font-medium uppercase'
                    value={selectedTask?.title}
                    classes='text-sm'
                    onChange={(e) => onTaskChange('title', e.target.value)}
                  />
                  <CustomTextArea
                    containerClasses='flex flex-col'
                    label='description'
                    labelClassName='text-xs'
                    placeholder='Enter description'
                    value={selectedTask?.description}
                    onChange={(e) => {
                      onTaskChange('description', e.target.value);
                    }}
                    classes='w-full text-sm'
                    style={{ color: '#2E2B2E' }}
                  />
                  <CustomNumberInput
                    incrementHandler={() => {
                      onTaskChange(
                        'dueDays',
                        parseInt(selectedTask?.dueDays) + 1
                      );
                    }}
                    decrementHandler={() => {
                      if (parseInt(selectedTask?.dueDays) === 0) {
                        return;
                      }
                      onTaskChange(
                        'dueDays',
                        parseInt(selectedTask?.dueDays) - 1
                      );
                    }}
                    label='Due in X Days *'
                    placeholder='Enter days'
                    labelClassName='mt-0 mb-2 text-softBlack70 text-xs font-medium uppercase'
                    value={selectedTask?.dueDays || 0}
                    classes='text-sm'
                    onChange={(e) => onTaskChange('dueDays', e.target.value)}
                  />
                  <CustomSelect
                    label='Task Type *'
                    fieldName='taskType'
                    value={
                      selectedTask.taskType === ''
                        ? 'Select Task Type'
                        : selectedTask.taskType
                    }
                    handleChange={(value) => {
                      onTaskChange('taskType', value);
                    }}
                    options={taskTypes}
                  />
                  <CustomButton
                    className='my-4'
                    handleClick={() => {
                      if (selectedTask?.id) {
                        setTasks([
                          ...tasks?.map((task) => {
                            if (task?._id === selectedTask?._id) {
                              return selectedTask;
                            }
                            return task;
                          }),
                        ]);
                        setSelectedTask({
                          title: '',
                          description: '',
                          dueDays: 0,
                          taskType: '',
                        });
                      } else {
                        setTasks([
                          ...tasks,
                          {
                            ...selectedTask,
                            id: tasks.length + 1,
                          },
                        ]);
                        setSelectedTask({
                          title: '',
                          description: '',
                          dueDays: 0,
                          taskType: '',
                        });
                      }
                      setTaskManageModal(false);
                    }}
                  >
                    <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
                      {loading && <Oval height='18' width='18' color='white' />}
                      {selectedTask?.id ? 'Update' : 'Save'}
                    </span>
                  </CustomButton>
                </div>
              </div>
            </Modal>
          )}
        </div>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  userObject: state.authReducer.userObject,
  appProject: state.appReducer.appProject,
  listOfProjects: state.appReducer.listOfProjects,
});

const mapDispatchToProps = {
  changeAppProject,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectManagement);
