// @ts-nocheck
import React, { useRef } from 'react'
import * as Yup from 'yup'
import { message } from 'antd'
import { ImageUploaderBrowse } from 'components/ImageUploader'
import { getDevelopers } from 'store/actions/developersActions'
import useUpdateUsers from 'hooks/useUpdateUsers'
import CustomButton from 'components/CustomButton'
import { Oval } from 'react-loader-spinner'

const FormInput = ({ label, labelClassName, ...props }) => {
  return (
    <label className='relative'>
      <div className={`mb-1 font-semibold ${labelClassName}`}>{label}</div>
      <input className={`placeholder:text-gray-300 rounded-none ${props.className}`} {...props} />
    </label>
  )
}

const NewCoopBroker = ({ cancel, update, broker = {} }) => {
  const mountedRef = useRef(true)
  const { createOrUpdateUser } = useUpdateUsers()
  const [formValues, setFormValues] = React.useState({
    firstName: '',
    lastName: '',
    email: '',
    licenseNumber: '',
    licenseBrokerage: '',
    licenseExpiration: '',
    city: '',
    province: '',
    country: '',
    imgsrc: ''
  })
  const [developers, setDevelopers] = React.useState([])
  const [selectedDeveloper, setSelectedDeveloper] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(false)

  const schema = {
    email: Yup.string().required(),
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    imgsrc: Yup.string().notRequired(),
    licenseNumber: Yup.string().required(),
    licenseBrokerage: Yup.string().required(),
    licenseExpiration: Yup.string().required()
  }

  React.useEffect(() => {
    getDevelopers().then((result) => {
      if (!mountedRef.current) return null
      setDevelopers([{ _id: '0', companyName: 'Select Developer Company' }, ...result])
    })

    if (broker && broker?.id) {
      setFormValues({
        ...formValues,
        firstName: broker?.firstName ?? '',
        lastName: broker?.lastName ?? '',
        email: broker?.email ?? '',
        licenseNumber: broker?.brokerData?.licenseNumber ?? '',
        licenseBrokerage: broker?.brokerData?.licenseBrokerage ?? '',
        licenseExpiration: broker?.brokerData?.licenseExpiration ?? '',
        city: broker?.brokerData?.city ?? '',
        province: broker?.brokerData?.province ?? '',
        country: broker?.brokerData?.country ?? ''
      })
      setSelectedDeveloper(broker?.brokerData?.developerCompany[0]?._id)
    }
  }, [])

  React.useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  const onSubmit = async () => {
    setIsLoading(true)
    const isValid = await Yup.object()
      .shape(schema)
      .isValid({ ...formValues })

    if (!isValid) {
      message.error('please fill all required fields')
      setIsLoading(false)
      return
    }

    const isEmailValid = await Yup.string().email().isValid(formValues.email)
    if (!isEmailValid) {
      message.error('please enter valid email.')
      setIsLoading(false)
      return
    }

    if (selectedDeveloper === '0') {
      message.error('please select developer company')
      setIsLoading(false)
      return
    }

    const params = {
      ...broker,
      email: formValues.email,
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      userType: 'CoopBroker',
      brokerData: {
        ...broker?.brokerData,
        developerCompany: [
          {
            _id: selectedDeveloper,
            companyName: developers.find((dev) => dev._id === selectedDeveloper)
          }
        ],
        city: formValues.city,
        country: formValues.country,
        province: formValues.province,
        licenseNumber: formValues.licenseNumber,
        licenseBrokerage: formValues.licenseBrokerage,
        licenseExpiration: formValues.licenseExpiration
      }
    }

    await createOrUpdateUser(params, false, false)
    update()
    cancel()
    setIsLoading(false)
  }

  return (
    <div className='bg-gray-100'>
      <div className='p-6 flex justify-between items-center'>
        <span className='font-bold text-3xl'>New Coop Broker</span>
        <CustomButton variant='ghost' className='hover:bg-zinc-300' handleClick={cancel}>
          <span className='p-2 px-4 text-base font-semibold flex items-center gap-2'>
            Cancel
          </span>
        </CustomButton>
      </div>
      <div className='border-b-2 border-black' />
      <div className='flex-1 m-6 p-6 bg-gray-50 rounded'>
        <h1 className='font-black text-md pt-3'>Personal Information</h1>
        <div className='border-b-2 border-black my-2' />
        <h2>Please enter the details about the new coop broker</h2>
        <div
          className='grid grid-cols-2  gap-6 border-0 p-0 mt-10 rounded-none shadow-none'
          onSubmit={onSubmit}>
          <FormInput
            label='FIRST NAME *'
            labelClassName='text-xs'
            placeholder='Enter first name'
            value={formValues.firstName}
            onChange={(e) =>
              setFormValues((prev) => {
                return { ...prev, firstName: e?.target?.value }
              })
            }
          />
          <FormInput
            label='LAST NAME *'
            labelClassName='text-xs'
            placeholder='Enter last name'
            value={formValues.lastName}
            onChange={(e) =>
              setFormValues((prev) => {
                return { ...prev, lastName: e?.target?.value }
              })
            }
          />
          <FormInput
            label='EMAIL *'
            labelClassName='text-xs'
            placeholder='Enter email'
            value={formValues.email}
            onChange={(e) =>
              setFormValues((prev) => {
                return { ...prev, email: e?.target?.value }
              })
            }
            disabled={!!broker?.id}
          />
          <label>
            <div className='text-xs mb-[5px] font-semibold'>DEVELOPER COMPANY *</div>
            <select
              value={selectedDeveloper}
              onChange={(e) => setSelectedDeveloper(e.target.value)}
              className='border-gray-400 border w-full h-11 '
              disabled={!!broker?.id}
            >
              {developers.map((developer, index) => (
                <option key={index} value={developer._id}>{developer.companyName}</option>
              ))}
            </select>
          </label>
          <FormInput
            label='LICENSE NUMBER'
            labelClassName='text-xs'
            placeholder='Enter number'
            value={formValues.licenseNumber}
            onChange={(e) =>
              setFormValues((prev) => {
                return { ...prev, licenseNumber: e?.target?.value }
              })
            }
          />
          <div className='grid grid-cols-2 gap-4'>
            <FormInput
              label='LICENSE BROKERAGE'
              labelClassName='text-xs'
              placeholder='Enter brokerage'
              value={formValues.licenseBrokerage}
              onChange={(e) =>
                setFormValues((prev) => {
                  return { ...prev, licenseBrokerage: e?.target?.value }
                })
              }
            />
            <FormInput
              label='LICENSE EXPIRATION'
              labelClassName='text-xs'
              placeholder='Enter expiration'
              type='date'
              value={formValues.licenseExpiration}
              onChange={(e) =>
                setFormValues((prev) => {
                  return { ...prev, licenseExpiration: e?.target?.value }
                })
              }
            />
          </div>
          <FormInput
            label='CITY'
            labelClassName='text-xs'
            placeholder='Enter city'
            value={formValues.city}
            onChange={(e) =>
              setFormValues((prev) => {
                return { ...prev, city: e?.target?.value }
              })
            }
          />
          <div className='grid grid-cols-2 gap-4'>
            <FormInput
              label='PROVINCE'
              labelClassName='text-xs'
              placeholder='Enter province'
              value={formValues.province}
              onChange={(e) =>
                setFormValues((prev) => {
                  return { ...prev, province: e?.target?.value }
                })
              }
            />
            <FormInput
              label='COUNTRY'
              labelClassName='text-xs'
              placeholder='Enter country'
              value={formValues.country}
              onChange={(e) =>
                setFormValues((prev) => {
                  return { ...prev, country: e?.target?.value }
                })
              }
            />
          </div>
          <div className='mt-4 col-span-2'>
            <div className='font-bold pb-2'>Upload Image </div>
            <div className='border'>
              <ImageUploaderBrowse
                value={formValues.imgsrc}
                onFileChange={(e) =>
                  setFormValues((prev) => {
                    return { ...prev, imgsrc: e?.target?.value || '' }
                  })
                }
              />
            </div>
          </div>
          <div className='col-span-2 flex justify-end'>
            <CustomButton handleClick={onSubmit} disabled={isLoading}>
              <span className='p-2 px-4 text-base font-semibold flex items-center gap-2'>
                {
                  isLoading &&
                  <Oval height='20' width='20' color='white' />
                }
                {broker?._id ? 'Save' : 'Add'} Coop Broker
              </span>
            </CustomButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewCoopBroker
