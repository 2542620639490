import React from 'react'
import propTypes from 'prop-types'
import UserRoundedImage from 'components/UserRoundedImage'
import { classNames } from 'utils'

const UserCard = ({
  data: { userAvatar, firstName, lastName, email, selected },
  key, onClick, showSelectedRing
}) => (
  <li
    className={
      classNames('shadow-md w-full flex rounded-md flex-col items-center justify-center px-3 py-3',
        selected && showSelectedRing && 'ring-2 ring-indigo-500',
        onClick && 'hover:cursor-pointer'
      )}
    onClick={onClick && onClick}
    key={key}
  >
    <div className='flex flex-row w-full items-center'>
      <UserRoundedImage src={userAvatar} className={userAvatar ? 'h-12 w-12' : 'h-14 w-14'} />
      <div className='flex flex-col ml-5'>
        <span className='text-lg'>{`${firstName} ${lastName}`}</span>
        <span className='text-base'>{email}</span>
      </div>
    </div>
  </li>
)

export default UserCard

UserCard.defaultProps = {
  showSelectedRing: true
}

UserCard.propTypes = {
  data: propTypes.shape({
    userAvatar: propTypes.string,
    firstName: propTypes.string,
    lastName: propTypes.string,
    email: propTypes.string,
    selected: propTypes.bool
  }),
  key: propTypes.string,
  onClick: propTypes.func,
  showSelectedRing: propTypes.bool
}
