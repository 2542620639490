import React from 'react'

import DashboardTable from 'components/DashboardTable'
import { recentSales } from '../TopUnitsTable/dummyData'
import Row from './Row'

const RecentSalesTable = () => {
  return (
    <DashboardTable
      title="Recent Sales"
      columnNames={['Unit', 'Building', 'Amount', 'Broker', '']}
      data={recentSales}
      RowToRender={Row}
      showMore
    />
  )
}

export default RecentSalesTable
