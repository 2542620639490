/* eslint-disable */
// @ts-nocheck
import React, { useEffect, useState } from 'react';
import {
  AreaChart,
  Card,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
} from '@tremor/react';
import useGetLeadsChartData from 'hooks/useGetLeadsChartData';

const RenderChart = ({
  chartData,
  category,
  valueFormatter
}) => (
  <AreaChart
    className="h-80"
    data={chartData}
    index="date"
    categories={[category]}
    colors={['emerald']}
    valueFormatter={valueFormatter}
    yAxisWidth={60}
    onValueChange={(v) => console.log(v)}
    showLegend={false}
  />
)

const valueFormatter = (number) => `${Intl.NumberFormat('us').format(number).toString()}`;

const LeadsSignUpChart = () => {
  const [fetchCountData, data, loading] = useGetLeadsChartData()
  const [fetchAllCountData, allChartData, isAllChartDataLoading] = useGetLeadsChartData()
  const [allTabs, setAllTabs] = useState([
    {
      name: 'Last 90 Days',
      value: 0,
      dataKey: 'signups90Count',
      apiEndPoint: '90'
    },
    {
      name: 'Last 30 Days',
      value: 0,
      dataKey: 'signupsMonthlyCount',
      apiEndPoint: 'month'
    },
    {
      name: 'Last 7 Days',
      value: 0,
      dataKey: 'signupsWeekCount',
      apiEndPoint: 'week'
    }
  ])
  const [selectedTab, setSelectedTab] = useState(allTabs[0])
  const [chartData, setChartData] = useState([])

  useEffect(() => {
    fetchAllCountData('all')
  }, [])

  useEffect(() => {
    if (allChartData) {
      setAllTabs([
        ...allTabs.map((tab) => ({
          ...tab,
          value: `${allChartData[tab.dataKey]}`
        }))
      ])
    }
  }, [allChartData])

  useEffect(() => {
    fetchCountData(selectedTab.apiEndPoint)
  }, [selectedTab.apiEndPoint])

  useEffect(() => {
    if (data) {
      setChartData([
        ...data.map((elm) => ({
          ...elm,
          [selectedTab.name]: elm.count
        }))
      ])
    }
  }, [data])

  return (
    <Card className="overflow-hidden p-0 rounded-xl">
      <TabGroup defaultIndex={0}>
        {
          !isAllChartDataLoading &&
          <TabList className="space-x-0 bg-tremor-background-muted dark:bg-dark-tremor-background-muted">
            {allTabs.map((tab) => (
              <>
                <Tab
                  key={tab.name}
                  className="py-4 pl-5 pr-12 text-left ui-selected:bg-tremor-brand-inverted ui-focus-visible:ring-2 ui-focus-visible:ring-blue-400 ui-focus-visible:ring-offset-2 dark:ui-selected:bg-dark-tremor-background-muted"
                  onClick={() => setSelectedTab(tab)}
                >
                  <span className="block text-tremor-content dark:text-dark-tremor-content">
                    {tab.name}
                  </span>
                  <span className="mt-1 block text-tremor-metric font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
                    {tab.value}
                  </span>
                </Tab>
                <div
                  className="border-r border-tremor-border dark:border-dark-tremor-border"
                  aria-hidden={true}
                />
              </>
            ))}
          </TabList>
        }
      </TabGroup>
      <div className='p-6 min-h-80'>
        {
          !loading && chartData?.length > 0 &&
          <RenderChart
            chartData={chartData}
            category={selectedTab.name}
            valueFormatter={valueFormatter}
          />
        }
      </div>
    </Card >
  );
}

export default LeadsSignUpChart
