import React, { useEffect, useRef, useState } from 'react'
import { Oval } from 'react-loader-spinner'
import CustomInput from 'components/CustomInput'
import CustomSelect from 'components/CustomSelect'
import { getProjectUnits } from 'store/actions/unitActions'
import { useSelector } from 'react-redux'
import { ADMIN_OFFER } from 'routes'
import { useHistory } from 'react-router-dom'
import { createOrUpdateStorage } from 'store/actions/storageActions'
import ProjectFileSelector from 'components/ProjectFileSelector'
import CustomButton from 'components/CustomButton'

const EditStorageView = ({
  editDetails,
  setEditDetails,
  refetch,
  building,
  closeStorageInfoDrawer,
  isAbleToPurchase
}) => {
  const history = useHistory()
  // @ts-ignore
  const appProject = useSelector((state) => state.appReducer.appProject)
  const mountedRef = useRef(true)
  const [units, setUnits] = useState([])
  const initialEmptyValues = {
    status: '',
    unit: '',
    unitNumber: '',
    floorNumber: '',
    type: 'Created',
    description: 'test description',
    price: '',
    images: []
  }
  const [value, setValues] = useState({
    ...initialEmptyValues
  })

  const TEMP_AVAILABILITY_STATUS = {
    available: 'Available',
    assigned: 'Assigned',
    reserved: 'Reserved',
    allocated: 'Allocated',
    rescission: 'Rescission',
    sold: 'Sold'
  }

  const STATUS_OPTIONS = [
    ...Object.values(TEMP_AVAILABILITY_STATUS).map((value) => {
      return { value: value, id: value }
    })
  ]

  const handleSubmit = () => {
    if (validate()) {
      setLoading(true)
      const temp = {
        // @ts-ignore
        _id: value?._id ?? '',
        status: value.status,
        unit: value.unit,
        unitNumber: value.unitNumber,
        floorNumber: value.floorNumber,
        type: 'Created',
        description: value.description,
        price: value.price,
        images: []
      }
      createOrUpdateStorage(temp)
        .then((data) => {
          refetch()
          setLoading(false)
          closeStorageInfoDrawer()
          setEditDetails(data)
        })
        .catch((err) => {
          setLoading(false)
          console.log(err)
        })
    }
  }

  const onChange = (fieldName, newValue) => {
    setValues({ ...value, [fieldName]: newValue })
  }
  const [loading, setLoading] = useState(false)

  const validate = () => {
    for (const key in initialEmptyValues) {
      if (!value[key]) {
        return false
      }
    }
    return true
  }

  useEffect(() => {
    if (!mountedRef.current) return null
    if (editDetails?.type === 'Storage') {
      setValues({
        ...value,
        ...editDetails,
        status: STATUS_OPTIONS.filter(
          ({ id }) => id === editDetails.status
        )?.[0]?.id,
        unit: units.filter(
          ({ id }) => id === editDetails.unit
        )?.[0]?.id
      })
    } else {
      setValues(initialEmptyValues)
    }
  }, [editDetails, units])

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  useEffect(() => {
    getProjectUnits(appProject).then((data) => setUnits(data.map((elm) => ({
      value: elm.unitNumber,
      id: elm._id
    }))))
  }, [appProject])

  const handleStatusChange = (id) => {
    const temp = STATUS_OPTIONS.filter((option) => option.id === id)[0]
    onChange('status', temp.id)
  }

  const handleUnitChange = (id) => {
    const temp = units.filter((option) => option.id === id)[0]
    setValues({
      ...value,
      unit: temp.id,
      unitNumber: temp.value
    })
  }

  const handleImagesChange = (contractObject) => {
    onChange('images', contractObject._id ?? contractObject.id)
  }

  return (
    <div
      className='font-openSans relative bg-grey-8 flex flex-col h-[calc(100%-64px)] overflow-hidden'
    >
      <div className='flex-1 flex flex-col gap-4 overflow-y-auto thin-scrollbar'>
        <CustomSelect
          label='Select Unit *'
          inputClasses='h-11'
          labelClasses={undefined}
          options={units}
          selectedOption={value.unit}
          setSelectedOption={(option) => {
            handleUnitChange(option)
          }}
        />
        <CustomInput
          label='price *'
          placeholder='Enter amount'
          labelClassName='italic'
          value={value.price}
          onChange={(e) => {
            onChange('price', e.target.value)
          }}
          type='number'
          required='required'
          errorMessage='Please enter price'
          containerClasses={undefined}
          classes={undefined}
        />
        <CustomSelect
          label='status *'
          inputClasses='h-11'
          labelClasses={undefined}
          options={STATUS_OPTIONS}
          selectedOption={value.status}
          setSelectedOption={(option) => {
            handleStatusChange(option)
          }}
        />
        <CustomInput
          label='Storage description *'
          placeholder='Enter description'
          labelClassName='italic'
          value={value.description}
          onChange={(e) => {
            onChange('description', e.target.value)
          }}
          required='required'
          errorMessage='Enter description...'
          containerClasses={undefined}
          classes={undefined}
        />
        <CustomInput
          label='floor number*'
          placeholder='Enter number'
          labelClassName='italic'
          value={value.floorNumber}
          onChange={(e) => {
            onChange('floorNumber', e.target.value)
          }}
          type='number'
          required='required'
          errorMessage='Please enter floor number'
          containerClasses={undefined}
          classes={undefined}
        />
        <ProjectFileSelector
          title={'Images'}
          onSuccess={(selectedFileData) => {
            handleImagesChange(JSON.parse(selectedFileData))
          }}
        />
      </div>
      <div className='mt-2 border-t border-softBlack/[30%] flex flex-col gap-4'>
        <CustomButton
          handleClick={handleSubmit}
          disabled={!validate() || loading}
        >
          <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
            {loading && <Oval height='18' width='18' color='white' />}
            {value?._id ? 'Update' : 'Add'} Storage
          </span>
        </CustomButton>
        {
          isAbleToPurchase &&
          <CustomButton
            handleClick={() => history.push(ADMIN_OFFER)}
          >
            <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
              Purchase
            </span>
          </CustomButton>
        }
      </div>
    </div>
  )
}

export default EditStorageView
