// @ts-nocheck
import React, { useCallback, useEffect, useState } from 'react'
import propTypes from 'prop-types'
import { AnimatePresence } from 'framer-motion'
import useSearchParams from 'hooks/useSearchParams'
import { FadedAnimatedDiv } from 'components'
import styled from 'styled-components'
import Welcome from './welcome'
import ThankYou from './thankyou'
import Contract from './contract'
import { getPublicReservationData } from 'store/actions/reservationActions'

export const ScreenWrapper = styled.div``

const AnimatedDiv = ({ children, key }) => (
  <FadedAnimatedDiv
    key={key}
    className='h-full w-full flex flex-col items-center justify-center'
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
  >
    {children}
  </FadedAnimatedDiv>
)

const BuyerSignReservationContract = () => {
  // @ts-ignore
  const searchParams = useSearchParams()
  const token = searchParams.get('token')
  const [currentStep, setCurrentStep] = useState(0)
  const [isLoadingData, setIsLoadingData] = useState(false)
  const [reservationData, setReservationData] = useState(null)

  useEffect(() => {
    const func = async () => {
      if (token?.length > 0) {
        try {
          setIsLoadingData(true)
          const result = await getPublicReservationData(token)
          setReservationData(result)
        } catch (e) {
          setReservationData(null)
        } finally {
          setIsLoadingData(false)
        }
      }
    }
    func()
  }, [token])

  const handleSignNowClick = useCallback(() => {
    if (isLoadingData) {
      return
    }
    setCurrentStep(1)
  }, [isLoadingData])

  const handleCloseContractScreen = useCallback(() => {
    if (isLoadingData) {
      return
    }
    setCurrentStep(2)
  }, [isLoadingData])

  return (
    <ScreenWrapper
      style={{ background: '#f8f8f9' }}
      className='h-screen w-screen'
    >
      <AnimatePresence exitBeforeEnter initial={false}>
        {currentStep === 0 && (
          <AnimatedDiv key='welcome'>
            <Welcome
              isLoadingData={isLoadingData}
              onClick={handleSignNowClick}
            />
          </AnimatedDiv>
        )}
        {currentStep === 1 && (
          <FadedAnimatedDiv key='contractsReview' style={{ height: '100%' }}>
            <Contract
              contractData={reservationData}
              onClose={handleCloseContractScreen}
            />
          </FadedAnimatedDiv>
        )}
        {currentStep === 2 && (
          <AnimatedDiv key='thankyou'>
            <ThankYou />
          </AnimatedDiv>
        )}
      </AnimatePresence>
    </ScreenWrapper>
  )
}

export default BuyerSignReservationContract

AnimatedDiv.propTypes = {
  children: propTypes.node,
  key: propTypes.string
}
