import styled from 'styled-components'

export const Wrapper = styled.div``
export const Title = styled.h1``
export const SelectedTabWrapper = styled.div``
export const RatingsWrapper = styled.div``
export const RatingWrapper = styled.div``
export const RatingCircle = styled.div``
export const RatingTitle = styled.span``
export const NewRatingButtonWrapper = styled.div``
export const DeleteIconWrapper = styled.div``
