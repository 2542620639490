import React, { useEffect, useState } from 'react'
import { Modal } from '@material-ui/core'
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg'
import * as Yup from 'yup'
import { useSelector } from 'react-redux'
import { createAttendees } from 'store/actions/eventsActions'
import CustomButton from 'components/CustomButton'

const attendeeSchema = Yup.object().shape({
  status: Yup.string().required('Status is required'),
  user: Yup.string().required('User is required'),
  checkIn: Yup.string().required('Check-in Image is required'),
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  email: Yup.string().required('Email is required')
})

const AddAttendeeModal = ({
  onClose,
  show,
  eventId
}) => {
  const projectId = useSelector(state => state.appReducer.appProject)
  const [attendee, setAttendee] = useState({
    _id: '',
    event: eventId,
    status: '',
    user: '',
    checkIn: '',
    firstName: '',
    lastName: '',
    email: ''
  })
  const [errors, setErrors] = useState({})
  const [touched, setTouched] = useState({})

  useEffect(() => {
    if (projectId) {
      setAttendee({
        ...attendee,
        _id: projectId
      })
    }
  }, [projectId])

  const handleBlur = (e) => {
    setTouched({
      ...touched,
      [e.target.name]: true
    })
    attendeeSchema
      .validate(attendee, { abortEarly: false })
      .catch((validationErrors) => {
        const errors = {}
        validationErrors.inner.forEach((err) => {
          errors[err.path] = err.message
        })
        setErrors({ ...errors })
      })
  }

  const handleChange = (e) => {
    setAttendee({
      ...attendee,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = () => {
    setTouched({
      status: true,
      user: true,
      checkIn: true,
      firstName: true,
      lastName: true,
      email: true
    })
    attendeeSchema
      .validate(attendee, { abortEarly: false })
      .then(() => {
        createAttendees(attendee).then((data) => {
          console.log(data)
          setTouched({})
          // setAttendee({
          //   ...attendee,
          //   // _id: '',
          //   status: '',
          //   user: '',
          //   checkIn: '',
          //   firstName: '',
          //   lastName: '',
          //   email: ''
          // })
        })
      })
      .catch((validationErrors) => {
        const errors = {}
        validationErrors.inner.forEach((err) => {
          errors[err.path] = err.message
        })
        setErrors(errors)
      })
  }

  return (
    <Modal
      open={show}
      onClose={onClose}
      aria-labelledby='Private Document Upload Modal'
      aria-describedby='Private Document Edit Modal for uploading document'
    >
      <div
        className='relative flex flex-col font-openSans bg-white p-8 rounded-lg shadow-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'
        style={{ width: '512px' }}
      >
        <div className='flex flex-col gap-5 relative justify-center items-center rounded-3xl'>
          <div className='flex flex-col items-center text-center w-4/5'>
            <h1 className='text-2xl text-italic'>Add Attendee</h1>
            <button className='absolute top-0 right-0' onClick={onClose}>
              <CrossIcon />
            </button>
          </div>
          <div>
            <div className='col-span-7 gap-y-6 grid grid-cols-6 gap-x-4 lg:gap-x-6'>
              <div className='col-span-3 flex flex-col gap-1 relative'>
                <label className='text-[2E2B2E] font-normal text-base italic'>
                  First Name *
                </label>
                <div>
                  <input
                    type='text'
                    name='firstName'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder='first name'
                    className='border border-[rgba(46,43,46,0.3)] rounded-[4px] w-full'
                  />
                  {touched.firstName && errors.firstName && <div className='relative h-0'><p className='absolute text-red-500'>{errors.firstName}</p></div>}
                </div>
              </div>
              <div className='col-span-3 flex flex-col gap-1 relative'>
                <label className='text-[2E2B2E] font-normal text-base italic'>
                  Last Name *
                </label>
                <div>
                  <input
                    type='text'
                    name='lastName'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder='last name'
                    className='border border-[rgba(46,43,46,0.3)] rounded-[4px] w-full'
                  />
                  {touched.lastName && errors.lastName && <div className='relative h-0'><p className='absolute text-red-500'>{errors.lastName}</p></div>}
                </div>
              </div>
              <div className='col-span-6 flex flex-col gap-1 relative'>
                <label className='text-[2E2B2E] font-normal text-base italic'>
                  Email *
                </label>
                <div>
                  <input
                    type='email'
                    name='email'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder='email'
                    className='border border-[rgba(46,43,46,0.3)] rounded-[4px] w-full'
                  />
                  {touched.email && errors.email && <div className='relative h-0'><p className='absolute text-red-500'>{errors.email}</p></div>}
                </div>
              </div>
              <div className='col-span-3 flex flex-col gap-1'>
                <label className='text-[2E2B2E] font-normal text-base italic'>
                  Status *
                </label>
                <div>
                  <input
                    type='text'
                    name='status'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder='status'
                    className='border border-[#2E2B2E4d] rounded-[4px] w-full'
                  />
                  {touched.status && errors.status && <div className='relative h-0'><p className='absolute text-red-500'>{errors.status}</p></div>}
                </div>
              </div>
              <div className='col-span-3 flex flex-col gap-1'>
                <label className='text-[2E2B2E] font-normal text-base italic'>
                  User *
                </label>
                <div>
                  <input
                    type='text'
                    name='user'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder='user'
                    className='border border-[#2E2B2E4d] rounded-[4px] w-full'
                  />
                  {touched.user && errors.user && <div className='relative h-0'><p className='absolute text-red-500'>{errors.user}</p></div>}
                </div>
              </div>
              <div className='col-span-6 flex flex-col gap-1'>
                <label className='text-[2E2B2E] font-normal text-base italic'>
                  Check-In *
                </label>
                <div>
                  <input
                    type='text'
                    name='checkIn'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder='08/05/2023 03:05 PM'
                    className='border border-[#2E2B2E4d] rounded-[4px] w-full'
                  />
                  {touched.checkIn && errors.checkIn && <div className='relative h-0'><p className='absolute text-red-500'>{errors.checkIn}</p></div>}
                </div>
              </div>
            </div>
          </div>
          <div className='flex justify-center gap-6 w-full mt-4 items-center'>
            <CustomButton variant='outline' handleClick={onClose}>
              <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
                Close
              </span>
            </CustomButton>
            <CustomButton handleClick={handleSubmit}>
              <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
                Add Attendee
              </span>
            </CustomButton>
          </div>
        </div>
      </div>
    </Modal>
  )
}
export default AddAttendeeModal
