import React, { useState } from 'react'
import propTypes from 'prop-types'
import { Transition } from '@headlessui/react'
import { TButton } from '.'
import { TFilledChevronDownIcon, UserRoundedImage } from 'components'

const Option = ({ title, icon, handleOnClick }) => {
  return (
    <div
      onClick={handleOnClick}
      className='hover:cursor-pointer group flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900'
      role='menuitem'
    >
      {icon && icon}
      {title}
    </div>
  )
}

const Dropdown = ({
  title, options, backgroundColor, backgroundHoverColor,
  border, shadow, imgSrc, focus, className, arrowDownIconColor,
  srText, showImageComponent
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const clickOutsideComponent = (e) => {
    // If clicked outside of input, close the select
    if (
      e.target.id !== 'options-menu' &&
      e.target.id !== 'optionsWrapper' &&
      e.target.id !== 'titleWrapper' &&
      e.target.id !== 'userAvatar' &&
      e.target.id !== 'userEmptyAvatar' &&
      e.target.id !== 'dropdownTitle' &&
      e.target.id !== 'dropdownIcon'
    ) {
      setIsOpen(false)
    }
  }

  document.onclick = (e) => clickOutsideComponent(e)

  const renderOptions = () => {
    return options.map((option, index) =>
      <Option
        key={index}
        title={option.title}
        icon={option.icon}
        handleOnClick={() => {
          option.onClick()
          setIsOpen(false)
        }}
      />
    )
  }

  return (
    <div className='relative inline-block text-left z-2000' id='dropdownWrapper'>
      <div>
        <TButton
          onClick={() => setIsOpen(!isOpen)}
          type='button'
          id='options-menu'
          aria-haspopup='true'
          aria-expanded='true'
          backgroundColor={backgroundColor}
          backgroundHoverColor={backgroundHoverColor}
          border={border}
          shadow={shadow}
          focus={focus}
          className={className}
        >
          {
            showImageComponent && (
              <UserRoundedImage src={imgSrc} className='h-8 w-8 mr-3' applyMarginEmptyImage={false}/>
            )
          }

          <span
            id='dropdownTitle'
            className='text-gray-700 text-lg font-medium'
          >
            <span className='sr-only'>
              {srText}
            </span>
            {title}
          </span>
          <TFilledChevronDownIcon
            id='dropdownIcon'
            className={`-mr-1 ml-2 h-6 w-6 ${arrowDownIconColor}`}
          />
        </TButton>
      </div>

      <Transition
        show={isOpen}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <div
          className='origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg
        bg-white border border-transparent
        ring-1 ring-black ring-opacity-5 divide-y divide-gray-100'
          role='menu'
          aria-orientation='vertical'
          aria-labelledby='options-menu'
        >
          <div className='py-1' id='optionsWrapper'>
            {renderOptions()}
          </div>
        </div>
      </Transition>
    </div>
  )
}

export default Dropdown

Dropdown.defaultProps = {
  imgSrc: '',
  arrowDownIconColor: 'text-white',
  srText: 'Open user menu for',
  showImageComponent: true
}

Dropdown.propTypes = {
  title: propTypes.string,
  options: propTypes.array,
  backgroundColor: propTypes.string,
  backgroundHoverColor: propTypes.string,
  border: propTypes.string,
  shadow: propTypes.string,
  imgSrc: propTypes.string,
  focus: propTypes.string,
  className: propTypes.string,
  arrowDownIconColor: propTypes.string,
  srText: propTypes.string,
  showImageComponent: propTypes.bool
}

Option.propTypes = {
  title: propTypes.string,
  icon: propTypes.node,
  handleOnClick: propTypes.func
}
