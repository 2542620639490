import { api, ApiErrors } from 'services/api'
import { message } from 'antd'

export const getStorages = (id = '') =>
  new Promise((resolve, reject) => {
    api.get(`storage/${id}`).then((response) => {
      if (response.ok) {
        resolve(response.data.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const createOrUpdateStorage = (props) =>
  new Promise((resolve, reject) => {
    const { _id, ...params } = props

    // If id, so we have to update the record, or we're gonna create
    const verb = _id ? 'put' : 'post'
    const url = _id ? `storage/${_id}` : 'storage'

    api[verb](url, params).then((response) => {
      if (response.ok) {
        message.success(
          `Storage-${props.unitNumber} was ${_id ? 'successfully updated' : 'created successfully'
          }`
        )
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const deleteStorage = (id) =>
  new Promise((resolve, reject) => {
    api.delete(`/storage/${id}`).then((response) => {
      if (response.ok) {
        message.success('Storage deleted successfully')
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getStorageInventory = (buildingId = '') =>
  new Promise((resolve, reject) => {
    api.get(`storage/${buildingId}/inventory`).then((response) => {
      if (response.ok) {
        resolve(response.data.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getStorageAllocationInventory = (buildingId = '') =>
  new Promise((resolve, reject) => {
    api.get(`storage/${buildingId}/allocateInventory`).then((response) => {
      if (response.ok) {
        resolve(response.data.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getAllStorageForBuildingByStorageType = (buildingId, filters) =>
  new Promise((resolve, reject) => {
    const params = new URLSearchParams(filters)
    api.get(`storage/building/all/${buildingId}?${params}`).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getAvailableStorageForBuildingByStorageType = (buildingId, filters) =>
  new Promise((resolve, reject) => {
    const params = new URLSearchParams(filters)
    api.get(`storage/building/availalble/${buildingId}?${params}`).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })
