import { api, ApiErrors } from 'services/api'
import { message } from 'antd'

export const getCorporations = (id = '', filter = '') => new Promise((resolve, reject) => {
  api.get(`corporations/${id}${filter}`)
    .then((response) => {
      if (response.ok) {
        resolve(response.data.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const createOrUpdateCorporation = (props) => new Promise((resolve, reject) => {
  const { _id, ...params } = props

  // If id, so we have to update the record, or we're gonna create
  const verb = _id ? 'put' : 'post'
  const url = _id ? `corporations/${_id}` : 'corporations'

  api[verb](url, params)
    .then((response) => {
      if (response.ok) {
        message.success(`Corporation ${_id ? 'successfully updated' : 'created successfully'}`)
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const deleteCorporation = (id) => new Promise((resolve, reject) => {
  api.delete(`/corporations/${id}`)
    .then((response) => {
      if (response.ok) {
        message.success('Corporation deleted successfully')
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
})
