/*eslint-disable */
// @ts-nocheck
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Calendar as CalendarComponent, Views } from 'react-big-calendar'
import propTypes from 'prop-types'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import localizer from 'react-big-calendar/lib/localizers/globalize'
import globalize from 'globalize'
import 'react-big-calendar/lib/sass/styles.scss'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss'
import CalendarToolbar from './CalendarToolbar'
import { CalendarWrapper } from './styles'
import { connect } from 'react-redux'
import { AnimatePresence } from 'framer-motion'
import { FadedAnimatedDiv, LoadingIcon, StartExternalProviderConfiguration } from 'components'
import useBuyers from 'hooks/useGetBuyers'
import { createOrUpdateTask, getTasks } from 'store/actions/taskActions'
import TaskModal from 'pages/tasks/components/TaskModal'
import TaskDetailsDrawer from 'pages/tasks/components/TaskDetailsDrawer'
import CustomModal from 'components/Modal'
import CustomButton from 'components/CustomButton'
import { Oval } from 'react-loader-spinner'
import { getUsers } from 'store/actions/usersActions'
import { ContactContext } from 'pages/contacts/context/Contacts'

const DragAndDropCalendar = withDragAndDrop(CalendarComponent)

const globalizeLocalizer = localizer(globalize)

const TasksCalendar = (props) => {
  const { userObject } = props
  const { buyers, getBuyersData } = useBuyers()
  const [events, setEvents] = useState([])
  const [draggedEvent, setDraggedEvent] = useState({})
  const [gettingEvents, setGettingEvents] = useState(false)
  const [gettingSelectedEvent, setGettingSelectedEvent] = useState(false)
  // const [editingEvent, setEditingEvent] = useState(null)
  const [isTaskDrawerOpen, setIsTaskDrawerOpen] = useState(false)
  const [isInfoDrawerOpen, setIsInfoDrawerOpen] = useState(false)
  const [isCreateOrUpdateTaskModalOpen, setIsCreateOrUpdateTaskModalOpen] = useState(false)
  const [selectedTask, setSelectedTask] = useState({})
  const [contact, setContact] = useState({})
  const [isMarkCompleteConfirmationModalOpen, setIsMarkCompleteConfirmationModalOpen] = useState(false)
  const [isMarkCompleteLoading, setIsMarkCompleteLoading] = useState(false)
  const { setSelectedContact, setSelectedContactObject } =
  useContext(ContactContext);
  const refEvents = useRef(events)
  const refDraggedEvent = useRef(draggedEvent)

  const updateEvents = (newState) => {
    refEvents.current = newState
    setEvents(newState)
  }

  const getEvents = useCallback(() => {
    setGettingEvents(true)

    getTasks()
      .then((res) => {
        const sortedByDateTasks = res.filter((elm) => elm.status).sort(function (a, b) {
          return new Date(a.dueDate) - new Date(b.dueDate)
        })
        // const currentDate = new Date()
        // setTasks({
        //   upcomingTasks: sortedByDateTasks.filter((elm) => !(elm.status === 'closed' || elm.status === 'COMPLETED')).filter((elm) => currentDate <= new Date(elm.dueDate)),
        //   pastDueTasks: sortedByDateTasks.filter((elm) => !(elm.status === 'closed' || elm.status === 'COMPLETED')).filter((elm) => currentDate > new Date(elm.dueDate)),
        //   completedTasks: sortedByDateTasks.filter((elm) => elm.status === 'closed' || elm.status === 'COMPLETED')
        // })
        const tmpEvents = sortedByDateTasks.map(task => ({
          id: task._id,
          title: task.title,
          start: new Date(task.dueDate),
          end: new Date(task.dueDate),
          desc: task.description,
          detail: task
        }))

        updateEvents(tmpEvents)
        setGettingEvents(false)
      })
      .catch(() => setGettingEvents(false))
  }, [])

  useEffect(() => {
    if (userObject.providerToken) {
      getEvents()
      getBuyersData()
    }
  }, [getEvents, userObject])

  useEffect(() => {
    if (selectedTask?.buyer?.id) {
      getUsers(selectedTask.buyer.id).then((res) => {
        setContact({ ...res, id: res?._id })
        setSelectedContact(res?._id)
        setSelectedContactObject({ ...res, id: res?._id })
      })
    }
  }, [selectedTask])

  const updateDraggedEvent = (newState) => {
    refDraggedEvent.current = newState
    setDraggedEvent(newState)
  }

  const moveEvent = useCallback(({ event, start, end, isAllDay: droppedOnAllDaySlot }) => {
    const updatedTask = {
      ...event.detail,
      dueDate: new Date(start)
    }

    createOrUpdateTask(updatedTask).then(() => {
      getEvents()
    })
  }, [])

  const newEvent = useCallback((event) => {
    const { slots } = event
    if (!isCreateOrUpdateTaskModalOpen) {
      setSelectedTask({
        dueDate: new Date(slots[0])
      })
      setIsCreateOrUpdateTaskModalOpen(true)
    }
  }, [isCreateOrUpdateTaskModalOpen])

  const handleDragStart = useCallback((event) => {
    updateDraggedEvent(event)
  }, [])

  // const executeAfterSaveDeleteCalendarEvent = useCallback(() => {
  //   setShowMeetingModal(false)
  //   setEditingEvent(null)

  //   getEvents()
  // }, [getEvents])

  const openEvent = useCallback((_event) => {
    setSelectedTask(_event.detail)
    setIsTaskDrawerOpen(true)
  }, [])

  const onMarkComplete = async () => {
    setIsMarkCompleteLoading(true)
    try {
      await createOrUpdateTask({ ...selectedTask, status: 'closed' }).then((res) => {
        getEvents()
        setSelectedTask({
          ...selectedTask,
          status: res.status
        })
        setIsMarkCompleteConfirmationModalOpen(false)
        setIsMarkCompleteLoading(false)
        setIsTaskDrawerOpen(false)
      })
    } catch (err) {
      setIsMarkCompleteConfirmationModalOpen(false)
      setIsMarkCompleteLoading(false)
      setIsTaskDrawerOpen(false)
    }
  }

  const CustomTaskModal = useCallback(() => {
    return (
      <TaskModal
        show={isCreateOrUpdateTaskModalOpen}
        setClose={() => {
          setIsCreateOrUpdateTaskModalOpen(false)
          setIsTaskDrawerOpen(false)
          setSelectedTask({})
          setContact({})
        }}
        contact={buyers}
        task={selectedTask}
        refetch={(task) => {
          getEvents()
          setSelectedTask({})
        }}
      />
    )
  }, [isCreateOrUpdateTaskModalOpen, buyers, selectedTask])

  const MarkCompleteConfirmationModal = useCallback(() => (
    <CustomModal
      isOpen={isMarkCompleteConfirmationModalOpen}
      closeModal={() => setIsMarkCompleteConfirmationModalOpen(false)}
      contentLabel="Mark Complete Confirmation Modal"
    >
      <div className='p-5 md:p-8 max-w-[95vw] md:max-w-[500px]'>
        <div className='pb-3.5'>
          <h3 className='text-xl font-semibold'>Are you sure?</h3>
          <p className='text-lg font-normal'>Do you want to mark complete "{selectedTask?.title}"?</p>
        </div>
        <div className='w-full flex justify-end items-center gap-2'>
          <CustomButton
            className='w-1/2 md:w-fit'
            variant='outline'
            handleClick={() => setIsMarkCompleteConfirmationModalOpen(false)}
          >
            <span className='font-semibold px-4 py-2 text-base'>
              No
            </span>
          </CustomButton>
          <CustomButton className='w-1/2 md:w-fit' handleClick={onMarkComplete} >
            <span className='font-semibold px-4 py-2 text-base flex items-center gap-2'>
              {isMarkCompleteLoading && <Oval height="18" width="18" color="black" />}
              Yes, I do
            </span>
          </CustomButton>
        </div>
      </div>
    </CustomModal>
  ), [isMarkCompleteConfirmationModalOpen, selectedTask, isMarkCompleteLoading])

  return (
    <CalendarWrapper className='h-full w-full relative'>
      {/* {
        Object.keys(userObject).length && !userObject.providerToken
          ? <StartExternalProviderConfiguration pageName='Calendar' />
          : (
            <> */}
      <AnimatePresence>
        {
          (gettingEvents || gettingSelectedEvent) &&
          <FadedAnimatedDiv
            className='absolute top-1/3 left-2/4 z-10 shadow-xl rounded-full bg-white'
          >
            <LoadingIcon className='h-22 w-22 text-blue-400 p-1' />
          </FadedAnimatedDiv>
        }
      </AnimatePresence>
      <DragAndDropCalendar
        selectable
        localizer={globalizeLocalizer}
        events={events}
        onEventDrop={moveEvent}
        resizable={false}
        onSelectSlot={newEvent}
        defaultView={Views.MONTH}
        defaultDate={new Date()}
        popup={true}
        handleDragStart={handleDragStart}
        components={{ toolbar: CalendarToolbar }}
        onSelectEvent={openEvent}
      />
      <TaskDetailsDrawer
        isTaskDrawerOpen={isTaskDrawerOpen}
        handleTaskDrawerClose={() => {
          setIsTaskDrawerOpen(false)
          setSelectedTask({})
          setContact({})
        }}
        selectedTask={selectedTask}
        isInfoDrawerOpen={isInfoDrawerOpen}
        setIsInfoDrawerOpen={setIsInfoDrawerOpen}
        handleInfoDrawerOpen={() => setIsInfoDrawerOpen(false)}
        contact={contact}
        setIsMarkCompleteConfirmationModalOpen={setIsMarkCompleteConfirmationModalOpen}
        setIsCreateOrUpdateTaskModalOpen={setIsCreateOrUpdateTaskModalOpen}
      />
      <CustomTaskModal />
      <MarkCompleteConfirmationModal />
      {/* </>
          )
        } */}
    </CalendarWrapper>
  )
}

const mapStateToProps = (state) => ({
  userObject: state.authReducer.userObject
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(TasksCalendar)

TasksCalendar.propTypes = {
  userObject: propTypes.object
}
