/* eslint-disable no-undef */
// @ts-nocheck
import React, { useState } from 'react'
import geo from './data.json'
import userData from './points.json'
import { useD3 } from './useD3'
const SalesChart = () => {
  const [showTooltip, setShowTooltip] = useState({
    show: false,
    top: 0,
    left: 0,
    sales: 0,
    leads: 0
  })

  const ref = useD3(
    (svg) => {
      let temp = []
      const margin = { top: 0, right: 0, bottom: 0, left: 0 }
      const width = 1015 - margin.left - margin.right
      const height = 688 - margin.top - margin.bottom

      svg
        .attr('width', width + margin.left + margin.top)
        .attr('height', height + margin.top + margin.bottom)
        .append('g')
        .attr('transform', `translate(${margin.left} ${margin.top})`)

      // Projection and path.
      const projection = d3.geoMercator().fitSize([width, height], geo)
      const geoPath = d3.geoPath().projection(projection)

      // Prep user data.
      userData.forEach((site) => {
        const coords = projection([+site.lng, +site.lat])
        site.x = coords[0]
        site.y = coords[1]
        temp = [...temp, site]
      })

      // Create a hexgrid generator.
      const hexgrid = d3
        .hexgrid?.()
        .extent?.([width, height])
        .geography?.(geo)
        .pathGenerator?.(geoPath)
        .projection?.(projection)
        .hexRadius?.(6)

      // Instantiate the generator.
      const hex = hexgrid(userData)

      // Create exponential colorScale.
      // const colourScale = d3
      //   .scaleSequential(function (t) {
      //     const tNew = Math.pow(t, 10)
      //     return d3.interpolateViridis(tNew)
      //   })
      //   .domain([...hex.grid.extentPointDensity].reverse())

      // Draw the hexes.
      svg
        .append('g')
        .selectAll('path')
        .data(hex.grid.layout)
        .enter()
        .append('path')
        .attr('d', hex.hexagon())
        .attr('transform', (d) => `translate(${d.x} ${d.y})`)
        .style('fill', (d) =>
          !d.pointDensity ? 'rgba(46, 43, 46, 0.3)' : '#EDDF65'
        )
        .style('stroke', 'white')
        .on('mouseover', (event, coordinates) => {
          const demo = temp.find(
            (data) =>
              data.x === coordinates[0]?.x && data.y === coordinates[0]?.y
          )
          if (demo) {
            setShowTooltip({
              show: true,
              top: event.pageY,
              left: event.pageX,
              sales: demo.sales,
              leads: demo.leads
            })
          }
        })
        .on('mouseout', () => {
          setShowTooltip({ ...showTooltip, show: false })
        })
    },
    [geo.length]
  )

  return (
    <>
      <svg ref={ref}></svg>
      <div
        style={{
          width: 181,
          zIndex: 100,
          top: showTooltip.top - 74,
          left: showTooltip.left - 86,
          visibility: showTooltip.show ? 'visible' : 'hidden'
        }}
        className="fixed  bg-white shadow-md"
      >
        <div className="relative flex py-2 px-4 justify-center items-center">
          <div className="flex justify-between w-full">
            <div className="flex gap-2">
              <div className="mt-1 w-3 h-3 bg-volt"></div>
              <div className="flex flex-col">
                <p className="m-0">Leads</p>
                <p className="m-0">{showTooltip.leads}</p>
              </div>
            </div>
            <div className="flex gap-2">
              <div
                className="mt-1 w-3 h-3"
                style={{ backgroundColor: '#CCBA1F' }}
              ></div>
              <div className="flex flex-col">
                <p className="m-0">Sales</p>
                <p className="m-0">{showTooltip.sales}</p>
              </div>
            </div>
          </div>
          <p
            style={{
              backgroundColor: 'white',
              position: 'absolute',
              bottom: -20,
              transform: 'rotate(45deg)',
              height: 12,
              width: 12
            }}
          ></p>
        </div>
      </div>
    </>
  )
}

export default SalesChart
