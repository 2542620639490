// @ts-nocheck
/* eslint-disable */
import TableHeader from 'components/TableHeader'
import { ContactContext } from 'pages/contacts/context/Contacts'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { CreateBrokerOpportunityContext } from '../context/CreateBrokerOpportunityContext'
import { STEP } from '../steps'
import BuyerCard from './BuyerCard/BuyerCard'
import { BROKER_OPPORTUNITIES_PAGE } from 'routes'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { FadedAnimatedDiv, LottieWrapper } from 'components'
import tableLoading from 'assets/lottieJsons/tableLoading.json'

const SelectBuyer = () => {
  const history = useHistory()
  const userObject = useSelector(state => state.authReducer.userObject)
  const { selectedBuyers, setSelectedBuyers, setStep } = useContext(CreateBrokerOpportunityContext)
  const { contacts } = useContext(ContactContext)
  const [selectedHeader, setSelectedHeader] = useState('')
  const [limit] = useState(10)
  const [skip, setSkip] = useState(0)
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState([])
  const [search, setSearch] = useState('')

  const getTableHeaders = useCallback(() => {
    const headers = ['Buyers', 'Corporations']
    return headers
  }, [])

  const onNextClick = () => setStep(STEP.TWO)

  useEffect(() => {
    if (!contacts) return
    setPages([...Array.from({ length: Math.ceil(contacts?.filter(elm => elm.buyerData?.assignments[0]?.leadBroker === userObject._id)?.filter?.(ct => search ? ct?.userType === 'Buyer' && nameMatches(ct) : ct?.userType === 'Buyer').length / limit) }, (value, index) => index + 1)])
  }, [setPages, contacts, search])

  const nameMatches = (contact) => (
    contact?.firstName?.toLowerCase?.()?.indexOf?.(search.toLowerCase()) !== -1 ||
    contact?.lastName?.toLowerCase?.()?.indexOf?.(search.toLowerCase()) !== -1 ||
    (`${contact?.firstName?.toLowerCase?.()}${contact?.lastName?.toLowerCase?.()}`)?.indexOf?.(search.toLowerCase()) !== -1 ||
    (`${contact?.firstName?.toLowerCase?.()} ${contact?.lastName?.toLowerCase?.()}`)?.indexOf?.(search.toLowerCase()) !== -1
  )

  return (
    <div>
      <div className='w-full flex justify-between items-center'>
        <h3 className='text-4xl font-bold ml-2 mb-0'>Create Opportunity</h3>
        <button className='text-softBlack text-center text-lg font-medium py-1.5 px-2 flex items-center bg-white rounded'
          onClick={() => history.replace(BROKER_OPPORTUNITIES_PAGE)}
        >
          Cancel
        </button>
      </div>
      <div style={{ height: '85vh' }} className='w-full pt-4'>
        <div style={{ height: 'max' }} className='w-full h-full p-6 bg-white flex flex-col gap-10 rounded'>
          <div className='w-full border-b-2 border-black p-3 text-lg pl-0 font-bold flex flex-row items-center justify-between'>
            Select Buyer
            <div className='flex justify-center items-center'>
              <div onClick={onNextClick} className={`flex justify-center items-center p-3 bg-volt rounded cursor-pointer w-36 ${selectedBuyers._id ? '' : 'opacity-50 cursor-not-allowed'}`}>Next Step</div>
            </div>
          </div>
          <div className='w-full relative'>
            <input value={search} onChange={e => setSearch(e.currentTarget.value)} style={{ width: '50%' }} placeholder="Search Buyers" className='border-black rounded active:border-black outline-none p-2' />
          </div>
          <TableHeader
            headers={getTableHeaders()}
            handleSelectHeader={(header) => setSelectedHeader(header)}
            selectedHeader={selectedHeader}
          />
          <div className='flex flex-row gap-4 flex-wrap items-stretch overflow-y-auto'>
            {
              contacts.length > 0
                ? <>
                  {contacts?.filter(elm => elm.buyerData?.assignments[0]?.leadBroker === userObject._id)?.filter?.(ct => search ? ct?.userType === 'Buyer' && nameMatches(ct) : ct?.userType === 'Buyer').slice(skip, limit * page).map((buyer, index) => <BuyerCard selectedBuyerId={selectedBuyers?._id} onClick={(contact) => setSelectedBuyers(contact)} buyer={buyer} />)}
                </>
                : <FadedAnimatedDiv
                  key='loadingTable'
                  className='w-full h-[300px] flex items-center justify-center flex-col bg-white'
                >
                  <LottieWrapper
                    animation={tableLoading}
                    loop
                    className='h-16 w-16'
                  />
                  <span className='text-base'>loading...</span>
                </FadedAnimatedDiv>
            }
          </div>
          <div className='w-full flex flex-row justify-center items-center'>
            <div className='w-max-content flex flex-row gap-1 cursor-pointer flex-wrap justify-center items-center'>
              {[pages.map((pg, index) => pg !== 0 && <div onClick={() => { setSkip(limit * index); setPage(pg) }} style={pg === page ? { color: 'black', border: '1px solid black' } : { color: 'gray' }} className='border-2 border-gray-1 rounded p-2'>{pg}</div>)]}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SelectBuyer
