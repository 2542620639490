import React from 'react'
import { Modal } from '@material-ui/core'
import { TCheckCircleIcon } from 'components/Icons'
import standardStyle from 'assets/css/standardStyle'
import { ADMIN_BUILDINGS } from 'routes'
import { useHistory } from 'react-router-dom'

export const MessageModal = ({
  show,
  setShow,
  primaryBtn = false,
  primaryBtnText = '',
  setPrimaryBtnAction = () => {},
  secondaryBtn,
  title,
  message,
  messageHtml = '',
  modalWidth = 544,
  modalHeight = 'auto'
}) => {
  const history = useHistory()
  const handleClose = () => {
    history.push(ADMIN_BUILDINGS)
    setShow(false)
  }
  const handlePrimaryAction = () => {
    setPrimaryBtnAction(true)
    setShow(false)
  }
  const btnStyle = {
    width: '119px',
    height: '62px',
    borderRadius: '4px'
  }
  const primaryBtnStyle = {
    height: '48px',
    borderRadius: '4px',
    fontSize: '16px',
    fontFamily: standardStyle.fonts.openSans
  }
  return (
    <Modal
        open={show}
        onClose={handleClose}
        aria-labelledby="Add Or Edit Contact Modal"
        aria-describedby="Add or edit contact modal description"
    >
    <div
        className="font-openSans relative bg-grey-8 absolute p-10 rounded shadow-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex justify-center items-center flex-col"
        style={{ width: modalWidth, height: modalHeight }}
      >
          <div className="flex justify-center mb-4">
            <TCheckCircleIcon background={'black'} className="w-8 h-8" />
          </div>
          <h2 className="text-softBlack font-bold text-xl text-center p-0 m-0" style={{ fontFamily: standardStyle.fonts.openSans }}>{ title }</h2>
          <div className="pt-8 flex flex-col gap-6 text-center">
            {messageHtml !== ''
              ? (
              <p className="text-softBlack text-base font-openSans font-normal">{ messageHtml }</p>
                )
              : (
              <p className="text-softBlack text-base font-openSans">{ message }</p>
                )}
              {primaryBtn && (
                <button
                className='italic font-light px-6 border text-lg text-softBlack bg-volt m-auto flex items-center justify-center'
                style={primaryBtnStyle}
                onClick={() => handlePrimaryAction()}
                >{primaryBtnText}</button>
              )}
              {secondaryBtn && (
                <button
                className='italic font-light border text-lg text-softBlack border-softBlack m-auto flex items-center justify-center font-openSans'
                style={btnStyle}
                onClick={() => handleClose()}
                >Close</button>
              )}
          </div>
      </div>
    </Modal>
  )
}
