import { useState } from 'react'
import { getBrokerLeadDetails } from 'store/actions/brokerLeadActions'

const useGetBrokerLeadsDetail = () => {
  const [leads, setLeads] = useState({})
  const [loading, setLoading] = useState(false)

  const getData = async (id) => {
    setLoading(true)
    try {
      const data = await getBrokerLeadDetails(id)
      setLeads(data)
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  return { loading, leads, refetch: getData }
}

export default useGetBrokerLeadsDetail
