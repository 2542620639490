/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable */
// @ts-nocheck
import React, { useEffect, useState } from 'react';

// import SelectContract from 'components/SelectContract'
import standardStyle from 'assets/css/standardStyle';

import CustomInput from 'components/CustomInput';
import CustomNumberInput from 'components/CustomNumberInput';
import CustomTextArea from 'components/CustomTextArea';
import { ImageUploaderPrimary } from 'components/ImageUploader';

import { TFilledTrashIcon } from 'components/Icons';

import {
  changeProjectOptionImages,
  deleteProjectOptionImage,
  // createOrUpdateProjectOptionContractDetail,
  deleteProjectOption,
  // deleteProjectOptionContractDetail
} from 'store/actions/projectActions';
import CustomSelect from 'components/CustomSelect';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';

const ParkingImage = ({ index, imgsrc, onDeleteParkingImage }) => {
  return (
    <div className='mt-4'>
      <ImageUploaderPrimary
        imgsrc={imgsrc}
        imgTitle={imgsrc.substring(imgsrc.lastIndexOf('/') + 1)}
        imgFileType={imgsrc
          ?.substring(imgsrc?.lastIndexOf('.') + 1)
          ?.toUpperCase()}
        onDelete={() => onDeleteParkingImage(index)}
      />
    </div>
  );
};

const Option = ({
  values,
  pOptions,
  setPOptions,
  editMode,
  index,
  setProjectData,
  setDeleteHandler,
  projectData,
  type,
  viewMode,
  // allContractTemplates,
  errors,
  errorValue,
  setErrors,
  // params,
  // setParams,
  lengthOfOptions,
  // latestContractTemplateId,
  // setLatestContractTemplateId,
}) => {
  // const [newContractDetail, setNewContractDetail] = useState(null);

  const onChange = (field, value) => {
    setPOptions([
      ...pOptions?.map((option, i) =>
        i === index ? { ...option, [field]: value } : option
      ),
    ]);
  };

  const AddParkingImages = async ({ e }) => {
    const file = e.target.files[0];
    const url = URL.createObjectURL(e.target.files[0]);
    if (values._id) {
      const imageParams = {
        images: [file],
        optionId: values._id,
        projectId: projectData._id,
      };
      changeProjectOptionImages(imageParams)
        .then((projectData) => {
          setProjectData(projectData);
        })
        .catch(() => { });
    }
    const tmpErrors = [...errors];
    tmpErrors[index].images = false;
    setErrors([...tmpErrors]);
    setPOptions([
      ...pOptions.map((option, i) =>
        i === index
          ? {
            ...option,
            images: [...option.images, file],
            displayImages: [...option.displayImages, url],
          }
          : option
      ),
    ]);
  };

  const onDeleteParkingImage = (imageIndex) => {
    const filter = [...pOptions].filter((value, i) => index === i)[0];
    if (values._id) {
      const imageParams = {
        url: filter.images[imageIndex],
        projectId: projectData._id,
        optionId: values._id,
      };
      deleteProjectOptionImage(imageParams)
        .then((projectData) => {
          setProjectData(projectData);
        })
        .catch(() => { });
    }
    const temp = [
      ...filter.images.slice(0, imageIndex),
      ...filter.images.slice(imageIndex + 1),
    ];
    onChange('images', temp);
  };

  const onDeleteParkingHandler = (index) => {
    const params = { optionId: values._id, projectId: projectData._id };
    if (values._id) {
      deleteProjectOption(params)
        .then((projectData) => {
          setProjectData(projectData);
        })
        .catch(() => { });
    }
    setPOptions(pOptions.filter((_, i) => i !== index));
  };

  // const [contractsData, setContractsData] = useState([]);
  // const [checkAllTemplateFields, setCheckAllTemplateFields] = useState(true);
  // const [allTemplateFields, setAllTemplateFields] = useState();
  // const [selectedAllTemplateFields, setSelectedAllTemplateFields] = useState({
  //   name: '',
  //   type: '',
  //   value: '',
  // });

  // useEffect(() => {
  //   if (allContractTemplates.length > 0 && contractsData.length === 0) {
  //     const contractsData = allContractTemplates.map((contract) => ({
  //       id: contract._id,
  //       value: contract.name,
  //     }));
  //     setContractsData(contractsData);
  //   }
  // }, [allContractTemplates]);

  // When selected a specific template contract
  // useEffect(() => {
  //   if (typeof newContractDetail === 'string') {
  //     const selectedContractId = newContractDetail;
  //     const selectedContract = allContractTemplates
  //       ?.filter((contract) => contract._id === selectedContractId)
  //       .pop();
  //     if (selectedContract?.contractFields.length > 0) {
  //       setAllTemplateFields(selectedContract.contractFields);
  //     } else {
  //       setAllTemplateFields([]);
  //     }
  //   } else {
  //     setAllTemplateFields([]);
  //   }
  // }, [newContractDetail, pOptions, allContractTemplates]);

  // useEffect(() => {
  //   if (typeof newContractDetail === 'string') {
  //     setPOptions(pOptions.map((option) => {
  //       if (option._id === values._id) {
  //         return {
  //           ...option,
  //           contractDetail: [
  //             {
  //               contractTemplate: newContractDetail
  //             }
  //           ]
  //         }
  //       }
  //       return option
  //     }))
  //   }
  // }, [newContractDetail])

  // const [templateField, setTemplateField] = useState('');

  // useEffect(() => {
  //   if (typeof newContractDetail === 'string') {
  //     let contractParams = {};
  //     let name = '';
  //     try {
  //       const { name: optionName } = allTemplateFields
  //         .filter((field) => field._id === selectedAllTemplateFields.name)
  //         .pop();
  //       name = optionName;
  //     } catch (error) {
  //       name = templateField;
  //     }
  //     if (name !== '') {
  //       const field = { ...selectedAllTemplateFields, name };
  //       if (!checkAllTemplateFields) {
  //         contractParams = {
  //           _id: latestContractTemplateId[index]
  //             ? latestContractTemplateId[index]
  //             : '',
  //           optionId: values._id,
  //           projectId: projectData._id,
  //           contractTemplate: newContractDetail,
  //           field,
  //         };
  //       } else {
  //         contractParams = {
  //           _id: latestContractTemplateId[index]
  //             ? latestContractTemplateId[index]
  //             : '',
  //           optionId: values._id,
  //           projectId: projectData._id,
  //           contractTemplate: newContractDetail,
  //         };
  //       }
  //     } else {
  //       contractParams = {
  //         _id: latestContractTemplateId[index]
  //           ? latestContractTemplateId[index]
  //           : '',
  //         optionId: values._id,
  //         projectId: projectData._id,
  //         contractTemplate: newContractDetail,
  //       };
  //     }
  //     const temp = [];
  //     for (let i = 0; i < lengthOfOptions; i++) {
  //       if (i === index) {
  //         temp.push(contractParams);
  //       } else {
  //         temp.push(params[i] ? params[i] : {});
  //       }
  //     }
  //     setParams(temp);
  //   }
  // }, [
  //   newContractDetail,
  //   selectedAllTemplateFields,
  //   latestContractTemplateId,
  //   templateField,
  //   checkAllTemplateFields,
  // ]);

  // useEffect(() => {
  //   if (values.contractDetail.length > 0) {
  //     setNewContractDetail(
  //       values.contractDetail[0].contractTemplate?._id
  //     );
  //     if (values.contractDetail[values.contractDetail.length - 1]?.field) {
  //       const tmpFields =
  //         values.contractDetail[values.contractDetail.length - 1]?.field;
  //       setTemplateField(tmpFields.name);
  //       setCheckAllTemplateFields(false);
  //       setSelectedAllTemplateFields({
  //         name: '',
  //         type: tmpFields.type,
  //         value: tmpFields.value,
  //       });
  //     }
  //   }
  // }, []);

  return (
    <div className='mt-10 p-3 rounded-xl border bg-card text-card-foreground shadow'>
      <div className='grid grid-cols-2 gap-6 border-0 p-0 rounded-none shadow-none'>
        <div>
          <CustomInput
            label={type + ' Name *'}
            labelClassName='text-xs italic font-openSans font-light'
            placeholder={'Enter ' + type + ' name'}
            value={values.name}
            onChange={(e) => onChange('name', e.target.value)}
            classes='font-openSans text-base'
            style={{ maxHeight: '48px' }}
            disabled={!editMode}
            required='required'
            error={errorValue?.name}
          />
          <div className='mt-6 grid grid-cols-2 gap-6'>
            <CustomInput
              label='Total Value *'
              labelClassName='text-xs italic font-openSans font-light'
              placeholder='Enter value'
              value={values.value}
              onChange={(e) => onChange('value', Number(e.target.value))}
              classes='font-openSans text-base'
              style={{ maxHeight: '48px' }}
              disabled={!editMode}
              type='number'
              required='required'
              min={0}
              errorMessage={'This field is required'}
              error={errorValue?.value}
            />
            <CustomNumberInput
              label='Max Amount Per Unit *'
              labelClassName='text-xs italic font-openSans font-light'
              value={values.maxAmountPerUnit}
              onChange={(e) => {
                onChange('maxAmountPerUnit', Number(e.target.value));
              }}
              required='required'
              classes='font-openSans text-base'
              style={{ maxHeight: '48px' }}
              disabled={!editMode}
              decrementHandler={() => {
                if (values.maxAmountPerUnit > 0) {
                  onChange(
                    'maxAmountPerUnit',
                    values.maxAmountPerUnit !== '' &&
                      values.maxAmountPerUnit !== 0
                      ? Number(values.maxAmountPerUnit - 1)
                      : 0
                  )
                }
              }}
              type='number'
              min={0}
              incrementHandler={() =>
                onChange(
                  'maxAmountPerUnit',
                  values.maxAmountPerUnit !== '' || values.maxAmountPerUnit !== NaN
                    ? Number(values.maxAmountPerUnit + 1)
                    : 1
                )
              }
              error={errorValue?.maxAmountPerUnit}
              containerClasses='col-span-1'
            />
          </div>
        </div>
        <div>
          <CustomTextArea
            label={type + ' Description *'}
            placeholder='Placeholder'
            labelClasses='text-xs italic font-openSans font-light'
            value={values.description}
            onChange={(e) => onChange('description', e.target.value)}
            style={{ height: '144px', color: standardStyle.colors.softBlack }}
            textareaClasses='font-openSans text-softBlack'
            disabled={!editMode}
            required='required'
            error={errorValue?.description}
          />
        </div>
      </div>
      <div className='mt-4'>
        <div className='font-light pb-4 italic font-openSans'>Image *</div>

        <div>
          {values?.images?.length > 0 &&
            values?.images?.map(
              (image, index) =>
                typeof image === 'string' && (
                  <ParkingImage
                    key={index}
                    index={index}
                    imgsrc={image}
                    key={index}
                    onDeleteParkingImage={() => onDeleteParkingImage(index)}
                  />
                )
            )}
          {values?.displayImages?.map((image, index) => {
            return (
              <ParkingImage
                key={index}
                index={index}
                imgsrc={image}
                onDeleteParkingImage={() => onDeleteParkingImage(index)}
              />
            );
          })}
        </div>
        <div className='my-4'>
          <ImageUploaderPrimary
            onFileChange={(e) => AddParkingImages({ e })}
            uploader={true}
            required='required'
          />
        </div>
        {errorValue?.images && (
          <p className='text-red-500'>Please upload at least 1 image</p>
        )}
      </div>
      {/* New contracts component */}
      {/* <CustomSelect
        label='Connect contract template'
        labelClasses='text-left text-xs italic font-openSans font-light'
        inputClasses='h-10 bg-white font-openSans'
        selectedOption={newContractDetail}
        options={contractsData}
        setSelectedOption={(e) => {
          setTemplateField('');
          setSelectedAllTemplateFields({
            name: '',
            type: '',
            value: '',
          });
          setNewContractDetail(e);
        }}
        fonts={'font-openSans'}
        fieldName={'contracts'}
        disabled={!editMode || viewMode === 'create'}
      />
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={checkAllTemplateFields}
              onChange={() => {
                setCheckAllTemplateFields((prev) => !prev);
                // setSelectedAllTemplateFields({ name: '', type: '', value: '' })
              }}
              color='primary'
              disabled={viewMode === 'create'}
            />
          }
          label='Use all template fields'
        />
      </FormGroup>

      {!checkAllTemplateFields && (
        <div className='grid grid-cols-3 gap-3 items-center'>
          <CustomSelect
            label='Template Fields*'
            labelClasses='text-left text-xs italic font-openSans font-light'
            inputClasses='h-10 bg-white font-openSans'
            selectedOption={selectedAllTemplateFields.name}
            options={allTemplateFields}
            defaultValue={templateField}
            fieldName='Contract Template'
            setSelectedOption={(value) => {
              setTemplateField('');
              const temp = allTemplateFields.filter(
                (contract) => contract._id === value
              )[0];
              setSelectedAllTemplateFields({
                name: value,
                type: temp.type,
                value: temp.value,
              });
            }}
            toIterateField='name'
            fonts={'font-openSans'}
            disabled={!editMode}
          />
          <CustomInput
            label='Type*'
            labelClassName='text-left text-xs italic font-openSans font-light'
            value={selectedAllTemplateFields.type}
            fonts={'font-openSans'}
            disabled={!editMode}
            readOnly
            placeholder={"The field's type"}
          />
          <CustomInput
            label='Value*'
            labelClassName='text-left text-xs italic font-openSans font-light'
            value={selectedAllTemplateFields.value}
            onChange={(e) =>
              setSelectedAllTemplateFields({
                ...selectedAllTemplateFields,
                value: e.target.value,
              })
            }
            fonts={'font-openSans'}
            disabled={!editMode}
            placeholder={"The field's value"}
          />
        </div>
      )} */}
      <div className='my-8 flex items-center justify-center'>
        <button
          className='flex items-center font-openSans italic font-light capitalize'
          onClick={() => onDeleteParkingHandler(index)}
        >
          <TFilledTrashIcon className='w-4 h-4 mr-1' />
          Delete {type}
        </button>
      </div>
    </div>
  );
};

export default Option;
