import { api, ApiErrors } from 'services/api'

// https://wilden-b2644364e2d7.herokuapp.com/api

export const postLeadData = (data) =>
  new Promise((resolve, reject) => {
    api.post('/leads/presale/v2', data).then((response) => {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject(new Error(response?.data?.errors?.[0]?.msg ?? 'Failed to post lead data'))
      }
    })
      .catch((error) => {
        ApiErrors(error.response)
        reject(error)
      })
  })
