import { useState, useEffect, useRef } from 'react'

import { getBuildings } from 'store/actions/buildingActions'

const useGetBuildings = (id = '', isPublicPage = false) => {
  const mountedRef = useRef(true)
  const [buildings, setBuildings] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getData(id)
    return () => {
      mountedRef.current = false
    }
  }, [])

  const getData = async (id = '') => {
    setLoading(true)
    try {
      const data = await getBuildings(id || '', isPublicPage)
      if (!mountedRef.current) return null
      setBuildings(data)
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  return { loading, buildings }
}

export default useGetBuildings
