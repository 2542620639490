import React, { createContext, useState } from 'react'
import { STEP } from '../steps'

export const CreateBrokerLeadContext = createContext(null)

export const CreateBrokerLeadContextProvider = ({ children }) => {
  const [step, setStep] = useState(STEP.ONE)
  const [selectedBroker, setSelectedBroker] = useState(null)
  const [selectedSalesRep, setSelectedSalesRep] = useState(null)

  return (
    <CreateBrokerLeadContext.Provider
      value={{
        step,
        setStep,
        selectedBroker,
        setSelectedBroker,
        selectedSalesRep,
        setSelectedSalesRep
      }}
    >
      {children}
    </CreateBrokerLeadContext.Provider>
  )
}
