// import { Select } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
// import { isMobile } from 'react-device-detect'

const ContactTabHeader = ({
  headers = [],
  handleSelectHeader,
  selectedHeader = 'Selected Header'
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0)

  // const handleChange = e => {
  //   handleSelectHeader(e.target.value)
  //   setSelectedIndex(headers.indexOf(headers.filter((elm) => e.target.value === elm.title)[0]))
  // }

  useEffect(() => {
    setSelectedIndex(headers.indexOf(headers.filter((elm) => selectedHeader === elm.title)[0]))
    // if (window.openTasksTab) {
    //   console.log('selectedHeader: ', selectedHeader)
    //   // @ts-ignore
    //   window.openTasksTab = false
    // }
  }, [setSelectedIndex, selectedHeader, headers])

  // eslint-disable-next-line curly
  // if (isMobile) return (
  //   <div className='w-full p-4'>
  //     <Select variant='outlined' fullWidth value={headers[selectedIndex].title} onChange={handleChange}>
  //       {headers.map(header => <option className='p-2' key={`tab-${header.key}`} value={header.title}>{header.title}</option>)}
  //     </Select>
  //   </div>
  // )
  return (
    <div className='relative w-full'>
      <div className='w-full z-1 absolute bottom-0' />
      <div className='px-3 flex gap-x-3'>
        {headers.map(({ key, title, Icon }, index) => {
          const selected = index === selectedIndex
          return (
            <div
              key={`tab-${key}`}
              className={`min-w-[100px] flex items-center gap-x-1.5 justify-center cursor-pointer p-2 py-4 text-center text-sm border-b z-2 ${selected ? 'text-[#1C64F2] border-[#1C64F2]' : 'text-gray-700 border-transparent'} font-medium grow`}
              onClick={() => {
                handleSelectHeader(title)
                setSelectedIndex(index)
              }}>
              <Icon className='h-4 w-4' />
              <span className='w-fit mb-0 truncate'>{title}</span>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ContactTabHeader
