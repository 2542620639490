/* eslint-disable */
// @ts-nocheck
import React from 'react'
import { IoMdClose } from 'react-icons/io'
import EditUnitView from './EditUnitView';

const UnitInfoDrawer = ({
  editDetails,
  setEditDetails,
  closeUnitInfoDrawer,
  isUnitInfoDrawerOpen,
  isAbleToPurchase = false,
  refetchUnits
}) => {
  return (
    <div
      className={`right-0 fixed flex flex-col h-[calc(100vh-100px)] overflow-hidden w-[300px] bg-white border-t border-l border-[#E4E4E7] ${isUnitInfoDrawerOpen ? 'translate-x-0' : 'translate-x-full'
        } transition-all duration-300 ease-in-out`}
    >
      <div className='p-3'>
        <div className='relative flex flex-col gap-4 items-center w-full justify-between p-4 bg-[#b8e04a] bg-opacity-30 rounded-md'>
          <div className='text-xl font-bold uppercase'>Unit Info.</div>
          <div className='font-bold text-2xl mt-2'>#{editDetails.unitNumber}</div>
          <button className='absolute top-2 right-2' onClick={() => closeUnitInfoDrawer()}>
            <IoMdClose fontSize='22px' />
          </button>
        </div>
      </div>
      {/* <img
        src='https://pxpdemo-images.s3.us-east-2.amazonaws.com/1690233502943-Screenshot%202023-07-24%20at%202.18.09%20PM.png'
        alt='Plan'
        className='object-contain w-full'
      /> */}
      <div className='flex-1 p-3 border-t border-softBlack/[30%] overflow-hidden'>
        <EditUnitView
          editDetails={{ ...editDetails, type: 'Units' }}
          setEditDetails={setEditDetails}
          refetch={() => {
            closeUnitInfoDrawer()
            refetchUnits()
          }}
          building={null}
          closeUnitInfoDrawer={closeUnitInfoDrawer}
          isAbleToPurchase={isAbleToPurchase}
        />
      </div>
    </div>
  );
}

export default UnitInfoDrawer
