import React, { useMemo } from 'react'
import { BrokerSidePanel, CompanyLogo } from 'pages/leadBrokerPages/components'
import { useHistory } from 'react-router-dom'
import { BROKER_DASHBOARD_PAGE, BROKER_DOCUMENTS_PAGE, BROKER_LANDING_PAGE } from 'routes'

const MenuItem = ({ title, Icon, onClick }) => {
  return (
    <div
      className='flex flex-row justify-center items-center content-center bg-white w-[445px] h-[210px] rounded-[17.51px] cursor-pointer'
      onClick={onClick}
    >
      <div className='flex mx-14 h-14 w-14 items-center'>
        <Icon />
      </div>
      <h2 className='w-full text-start justify-start content-center items-center text-3xl font-bold -tracking-wider mb-0'>
        {title}
      </h2>
    </div>
  )
}

const MenuScreen = () => {
  const history = useHistory()
  const menuItems = useMemo(() => {
    return [
      {
        title: 'Dashboard',
        Icon: () => {
          return (
            <svg
              width='44'
              height='44'
              viewBox='0 0 44 44'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <g clipPath='url(#clip0_1275_60165)'>
                <path
                  d='M37.9784 4.09668H5.60057C3.62193 4.09668 2.00304 5.71557 2.00304 7.69421V29.2794C2.00304 31.258 3.62193 32.8769 5.60057 32.8769H18.1919V36.4745H14.5944V40.072H28.9845V36.4745H25.387V32.8769H37.9784C39.957 32.8769 41.5759 31.258 41.5759 29.2794V7.69421C41.5759 5.71557 39.957 4.09668 37.9784 4.09668ZM37.9784 29.2794H5.60057V7.69421H37.9784V29.2794Z'
                  fill='#323232'
                />
              </g>
              <defs>
                <clipPath id='clip0_1275_60165'>
                  <rect
                    width='43.1704'
                    height='43.1704'
                    fill='white'
                    transform='translate(0.204193 0.499023)'
                  />
                </clipPath>
              </defs>
            </svg>
          )
        },
        onClick: () => history.push(BROKER_DASHBOARD_PAGE)
      },
      {
        title: 'Projects',
        Icon: () => {
          return (
            <svg
              width='59'
              height='60'
              viewBox='0 0 59 60'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M41.8605 27.6181V13.0288C41.8605 10.3542 39.6722 8.16577 36.9975 8.16577H22.4082C19.7336 8.16577 17.5452 10.3542 17.5452 13.0288V17.8919H12.6821C10.0074 17.8919 7.81902 20.0803 7.81902 22.755V47.0704C7.81902 49.7451 10.0074 51.9335 12.6821 51.9335H24.8398C26.1771 51.9335 27.2713 50.8393 27.2713 49.5019V42.2073H32.1344V49.5019C32.1344 50.8393 33.2286 51.9335 34.5659 51.9335H46.7236C49.3983 51.9335 51.5867 49.7451 51.5867 47.0704V32.4812C51.5867 29.8065 49.3983 27.6181 46.7236 27.6181H41.8605ZM17.5452 47.0704H12.6821V42.2073H17.5452V47.0704ZM17.5452 37.3442H12.6821V32.4812H17.5452V37.3442ZM17.5452 27.6181H12.6821V22.755H17.5452V27.6181ZM27.2713 37.3442H22.4082V32.4812H27.2713V37.3442ZM27.2713 27.6181H22.4082V22.755H27.2713V27.6181ZM27.2713 17.8919H22.4082V13.0288H27.2713V17.8919ZM36.9975 37.3442H32.1344V32.4812H36.9975V37.3442ZM36.9975 27.6181H32.1344V22.755H36.9975V27.6181ZM36.9975 17.8919H32.1344V13.0288H36.9975V17.8919ZM46.7236 47.0704H41.8605V42.2073H46.7236V47.0704ZM46.7236 37.3442H41.8605V32.4812H46.7236V37.3442Z'
                fill='#2E2B2E'
              />
            </svg>
          )
        }
        // ,
        // onClick: () => history.push(BROKER_MENU_PAGE)
      },
      {
        title: 'Marketing Docs',
        Icon: () => {
          return (
            <svg
              width='50'
              height='47'
              viewBox='0 0 50 47'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M43.7463 0H5.44095C2.43339 0 0 2.33428 0 5.18728L0.0273414 41.4982C0.0273414 44.3512 2.46073 46.6855 5.46829 46.6855H32.8097L49.2146 31.1237V5.18728C49.2146 2.33428 46.7539 0 43.7463 0ZM10.9366 12.9682H38.278V18.1555H10.9366V12.9682ZM24.6073 28.53H10.9366V23.3428H24.6073V28.53ZM30.0756 42.7951V28.53H45.1134L30.0756 42.7951Z'
                fill='#2E2B2E'
              />
            </svg>
          )
        },
        onClick: () => history.push(BROKER_DOCUMENTS_PAGE)
      },
      {
        title: 'Landing Page',
        Icon: () => {
          return (
            <svg
              width='50'
              height='47'
              viewBox='0 0 50 47'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M43.7463 0H5.44095C2.43339 0 0 2.33428 0 5.18728L0.0273414 41.4982C0.0273414 44.3512 2.46073 46.6855 5.46829 46.6855H32.8097L49.2146 31.1237V5.18728C49.2146 2.33428 46.7539 0 43.7463 0ZM10.9366 12.9682H38.278V18.1555H10.9366V12.9682ZM24.6073 28.53H10.9366V23.3428H24.6073V28.53ZM30.0756 42.7951V28.53H45.1134L30.0756 42.7951Z'
                fill='#2E2B2E'
              />
            </svg>
          )
        },
        onClick: () => history.push(BROKER_LANDING_PAGE)
      }
    ]
  }, [history])

  return (
    <div className='flex flex-row text-softBlack justify-center items-center content-center h-full w-full'>
      <div className='flex-1 flex-col space-y-4'>
        <CompanyLogo />
        <div className='flex-1 w-full h-full justify-center items-center content-center'>
          <div className='flex justify-center content-center'>
            <BrokerSidePanel
              buttonLabel='Open Portal'
              onButtonClick={() => {
                // history.push(BROKER_MENU_PAGE)
              }}
            />
          </div>
        </div>
      </div>
      <div className='flex-1 h-full p-0 bg-[#E9E9E9]'>
        <div className='flex-col flex h-full items-center content-center justify-center gap-7'>
          {menuItems.map((data) => {
            return <MenuItem {...data} />
          })}
        </div>
      </div>
    </div>
  )
}

export default MenuScreen
