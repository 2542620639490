/* eslint-disable */
// @ts-nocheck
import React, {
  useEffect,
  useState,
  createContext,
  useMemo,
  useCallback,
  useRef
} from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'

import { Table } from 'ui'
import { ReactComponent as SorterIcon } from 'assets/icons/sorter.svg'
import AllOpportunities from './AllOpportunities'
import { deleteOpportunity, getOpportunity } from 'store/actions/usersActions'
import { useHistory } from 'react-router-dom'

const LoadingContext = createContext()

export const offerStatus = {
  CREATED: { text: 'CREATED', backgroundColor: 'bg-volt' },
  RESERVATION: { text: 'RESERVATION', backgroundColor: 'bg-[#54B5D1]' },
  OFFER: { text: 'OFFER', backgroundColor: 'bg-[#DF6D50]' },
  'WON-SOLD FIRM': { text: 'WON-SOLD FIRM', backgroundColor: 'bg-[#59B099]' },
  LOST: { text: 'LOST', backgroundColor: 'bg-[#2E2B2E]', textColor: 'text-white' }
}

const ColoredStatus = ({ status }) => (
  <div
    className={`w-32 font-medium py-1 text-base uppercase text-center
      ${offerStatus[status].textColor || 'text-black'} 
      ${offerStatus[status].backgroundColor || 'bg-white'} 
    `}
  >
    {offerStatus[status].text}
  </div>
)

const ActionButton = ({ text, onClick }) => (
  <div
    className="font-medium py-1 text-base text-black uppercase text-center border border-black w-32 cursor-pointer"
    onClick={onClick}
  >
    {text}
  </div>
)

const TextField = ({ value }) => (
  <span className='font-medium text-base text-softBlack capitalize'>
    {value}
  </span>
)

const MemoTable = ({
  dataSource,
  columns,
  loading,
  idBeingDeleted,
  deleting
}) => {
  return (
    <Table
      className='rounded-lg'
      dataSource={dataSource}
      columns={columns}
      tailwindTable
      loading={loading}
      idBeingDeleted={idBeingDeleted}
      deleting={deleting}
      noMargin
    />
  )
}

const ProjectOpportunities = ({ appProject, userObject }) => {
  const mountedRef = useRef(true)
  const history = useHistory()
  const [projectOpportunities, setProjectOpportunities] = useState([])
  const [filterProjectOffers, setFilterProjectOffers] = useState([])
  const [idBeingDeleted] = useState('')
  const [cancelingOffer] = useState(false)
  const [sortByBuyer, setSortByBuyer] = useState('asc')
  const [opportunities, setOpportunities] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  useEffect(() => {
    if (sortByBuyer && projectOpportunities?.length > 0) {
      if (!mountedRef.current) return null
      setFilterProjectOffers([...sortedByBuyerName])
    }
  }, [sortByBuyer, projectOpportunities])

  const sortedByBuyerName = projectOpportunities.sort((a, b) => {
    if (sortByBuyer === 'asc') return a.name.localeCompare(b.name)
    if (sortByBuyer === 'desc') return b.name.localeCompare(a.name)
    return 0
  })

  const projectOpportunitiesColumns = useMemo(
    () => [
      {
        Title: (
          <span className='flex items-center space-x-2'>
            <span className='leading-none'>Opportunity Name</span>
            <button
              onClick={() =>
                setSortByBuyer((prev) => (prev === 'asc' ? 'desc' : 'asc'))
              }
            >
              <SorterIcon />
            </button>
          </span>
        ),
        accessor: 'name',
        dataIndex: 'name',
        Cell: (props) => <TextField value={props.cell.value} />
      },
      {
        Title: 'Buyer',
        accessor: 'user',
        Cell: (props) => <TextField value={props.cell.value} />
      },
      {
        Title: 'Rating',
        accessor: 'rating',
        Cell: (props) => <TextField value={props.cell.value} />
      },
      {
        Title: 'Stage',
        accessor: 'stage',
        Cell: (props) => <ColoredStatus status={props.cell.value} />
      },
      {
        Title: 'Edit',
        accessor: 'createdAt',
        Cell: () => (
          <ActionButton text='View / Edit' onClick={() => history.push(`/new/admin/opportunity/edit/${userObject._id}`)} />
        )
      },
      {
        Title: 'Delete',
        accessor: '_id',
        Cell: (props) => (
          <ActionButton text='Delete' onClick={() => deleteOpportunity(props.cell.value)} />
        )
      }
    ],
    []
  )

  const getData = useCallback(() => {
    if (opportunities) {
      setProjectOpportunities([])
      const tmpProjectOpportunities = opportunities.map(({ _id, name, user, rating, stage }, index) => {
        const tmpOpportunities = { _id, name, user, rating, stage }
        return tmpOpportunities
      })
      setProjectOpportunities(tmpProjectOpportunities)
      setFilterProjectOffers(tmpProjectOpportunities)
    }
  }, [opportunities])

  useEffect(() => {
    getData()
  }, [opportunities])

  useEffect(() => {
    setIsLoading(true)
    if (userObject._id){
      getOpportunity(userObject._id).then((data) => setOpportunities([data])).catch((error) => console.error(error))
      setIsLoading(false)
    }
  }, [userObject])

  const values = useMemo(
    () => ({}), []
  )

  return (
    <LoadingContext.Provider value={values}>
      <div className='h-full w-full flex flex-col items-center'>
        <AllOpportunities title="Opportunities by Stages" />
        <div className='mt-5 w-full lg:overflow-x-hidden'>
          <MemoTable
            dataSource={filterProjectOffers}
            columns={projectOpportunitiesColumns}
            loading={isLoading}
            idBeingDeleted={idBeingDeleted}
            deleting={cancelingOffer}
          />
        </div>
      </div>
    </LoadingContext.Provider>
  )
}

const mapStateToProps = (state) => ({
  appProject: state.appReducer.appProject,
  userObject: state.authReducer.userObject
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectOpportunities)

ProjectOpportunities.propTypes = {
  appProject: propTypes.string,
  userObject: propTypes.shape({
    userType: propTypes.string
  }),
  cell: propTypes.shape({
    value: propTypes.string
  }),
  row: propTypes.shape({
    original: propTypes.shape({
      _id: propTypes.string
    })
  })
}