// @ts-nocheck
/* eslint-disable */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import useGetUserNotes from 'hooks/useGetUserNotes';
import NoteCard from 'components/NotesCard';
import CustomButton from './CustomButton';
import NotesLists from 'components/NoteListBar';
import { createOrUpdateNote, deleteNote } from 'store/actions/notesActions';
import { ref } from 'yup';
import { CheckIcon, DeleteIcon } from 'components';
import { TrashIcon } from '@heroicons/react/outline';
import DeleteModal from 'components/DeleteModal';
import { CheckCheckIcon } from 'lucide-react';
import { set } from 'lodash';

export const NotesTab = ({ contact, user }) => {
  const tmpNote = {
    buyer: {
      firstName: contact?.firstName,
      lastName: contact?.lastName,
      _id: contact?._id,
      id: contact?._id,
    },
    noteType: 'General',
    contact: contact?._id,
    user,
    edit: true,
    notes: {
      notes: '',
      title: '',
    },
  };

  const { notes, refetch } = useGetUserNotes(contact?._id);
  const [isNewNote, setNewNote] = useState(false);
  const [newCard, setNewCard] = useState({ ...tmpNote });
  const [selectedNote, setSelectedNote] = useState(null);
  const [notesList, setNotesList] = useState([]);
  const [saveStatus, setSaveStatus] = useState('');
  const [deleteNoteId, setDeleteNoteId] = useState(0);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const handleDeleteNote = () => {
    setDeleteNoteId(selectedNote?._id);
    setIsDeleteModal(true);
  };

  const deleteTheNote = (note) => {
    setDeleteLoading(true);
    if (deleteNoteId !== 0) {
      deleteNote(deleteNoteId)
        .then(() => {
          setIsDeleteModal(false);
          setDeleteLoading(false);
          refetch();
          const list = notesList.filter(deleteNoteId !== note._id);
          const newNotesList = [...list];
          setNotesList(newNotesList);
        })
        .catch(() => {
          setDeleteLoading(false);
        });
    }
    setDeleteNoteId(0);
  };

  const createNewNote = async () => {
    const newNote = { ...newCard, notes: JSON.stringify(newCard.notes) };
    setNotesList((prevNotesList) => [
      { ...newCard, notes: JSON.stringify(newCard.notes) },
      ...prevNotesList,
    ]);
    setSaveStatus('Saving...');
    const resp = await createOrUpdateNote(newNote);
    setSaveStatus('Saved');
    refetch();
    setSelectedNote(newNote);
  };

  useEffect(() => {
    const notesData = [];
    if (notes) {
      let sortedData = notes.reverse();
      sortedData.forEach(function (note, index) {
        notesData.push({ ...note, edit: false });
      });
      setNotesList(notesData);
      setLoading(false);
    }
  }, [notes]);

  const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  const [isTyping, setIsTyping] = useState(false);
  const typingTimeoutRef = useRef(null);

  const handleInputChange = (e, inputType) => {
    setIsTyping(true);
    setSelectedNote({
      ...selectedNote,
      notes: {
        ...selectedNote.notes,
        [inputType]: e.target.value,
      },
    });

    let notes = {};
    if (selectedNote?.notes || tmpNote.notes) {
      if (inputType === 'title') {
        notes = {
          title: e.target.value,
          notes: selectedNote?.notes?.notes ?? tmpNote.notes.notes,
        };
      } else if (inputType === 'notes') {
        notes = {
          title: selectedNote?.notes?.title ?? tmpNote.notes.title,
          notes: e.target.value,
        };
      }
      clearTimeout(typingTimeoutRef.current);
      setSaveStatus('Saving...');
      typingTimeoutRef.current = setTimeout(() => {
        setIsTyping(false);
        onSaveNoteDebounced(notes);
      }, 1000);
    }
  };
  const onSaveNote = async (notes) => {
    const notesString = JSON.stringify({
      title: notes.title ?? '',
      notes: notes.notes ?? '',
    });
    let params = {};
    console.log(selectedNote);
    if (
      selectedNote &&
      selectedNote._id
    ) {
      params = {
        ...selectedNote,
        notes: notesString,
        id: selectedNote?._id,
        _id: selectedNote?._id,
        buyer: contact?._id,
      };
    } else {
      params = { ...selectedNote, notes: notesString };
    }
    try {
      const resp = await createOrUpdateNote(params);
      setSaveStatus('Saved');
      setNotesList((prevNotesList) => {
        const index = prevNotesList.findIndex(
          (note) => note._id === selectedNote._id
        );
        if (index !== -1) {
          const newNotesList = [...prevNotesList];
          newNotesList[index] = { ...newNotesList[index], ...resp };
          return newNotesList;
        }
        if (!selectedNote._id) {
          return [...prevNotesList, { ...resp }];
        }
        return prevNotesList;
      });
      refetch();
    } catch (error) {
      console.error('Error saving note:', error);
      setSaveStatus('Error saving note'); // Update status to "Error saving note" on failure
    }

    // .then((resp) => {
    //   setIsDeleteModal(false);
    //   setDeleteLoading(false);
    //   setNewCard?.({ ...newNote, _id: resp._id, id: resp?._id });
    //   onFocusOutNewNoteBox?.({
    //     ...newNote,
    //     _id: resp._id,
    //     id: resp?._id,
    //     edit: false,
    //   });
    //   const index = notesList.findIndex((_note) => _note._id === note._id);
    //   let newNotesList = [...notesList];
    //   if (index === -1) {
    //     newNotesList = [
    //       ...notesList,
    //       { ...newNote, _id: resp._id, id: resp?._id, edit: false },
    //     ];
    //   } else {
    //     newNotesList[index].edit = false;
    //   }
    //   setNotesList(newNotesList);
    // })
    // .catch(() => {
    //   setDeleteLoading(false);
    // });
  };

  useEffect(() => {
    if (saveStatus === 'Saved') {
      const timeoutId = setTimeout(() => {
        setSaveStatus('');
      }, 10000);
      return () => clearTimeout(timeoutId);
    }
  }, [saveStatus]);
  const onSaveNoteDebounced = debounce(onSaveNote, 500);

  return (
    <div className=''>
      {loading ? (
        <div className='flex flex-col gap-6 mt-8 p-4 md:p-6'>
          <Skeleton height={100} />
        </div>
      ) : (
        <>
          {notesList.length === 0 && (
            <div
              className={`flex flex-col justify-center items-center gap-2 p-6 ${
                isNewNote ? 'h-fit py-0' : 'h-full'
              } w-full`}
            >
              {!isNewNote && (
                <>
                  <svg
                    width='30'
                    height='27'
                    viewBox='0 0 16 14'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M13.3417 1.83324L12.3333 0.824902C11.6833 0.174902 10.625 0.174902 9.975 0.824902L7.74167 3.05824L0.5 10.2999V13.6666H3.86667L11.15 6.38324L13.3417 4.19157C14 3.54157 14 2.48324 13.3417 1.83324ZM3.175 11.9999H2.16667V10.9916L9.38333 3.7749L10.3917 4.78324L3.175 11.9999ZM7.16667 13.6666L10.5 10.3332H15.5V13.6666H7.16667Z'
                      fill='#323232'
                    />
                  </svg>
                  <div className='font-semibold mt-4'>
                    You don't have any notes yet
                  </div>
                  <div className='text-sm text-softBlack70 mb-4'>
                    Create your first note
                  </div>
                  <CustomButton handleClick={createNewNote}>
                    <span className='font-semibold px-4 py-2 text-base'>
                      Create Note
                    </span>
                  </CustomButton>
                </>
              )}
            </div>
          )}
          {(isNewNote || notesList.length > 0) && (
            <div className='p-4 md:p-6'>
              <div className='flex flex-col gap-4 md:gap-6'>
                <div className='w-full flex items-center justify-between'>
                  <h3 className='text-lg mb-0'></h3>
                  <div className='flex items-center gap-x-4'>
                    <CustomButton handleClick={handleDeleteNote}>
                      <span className='font-semibold px-4 py-2 text-base'>
                        Delete Note
                      </span>
                    </CustomButton>
                    <CustomButton
                      handleClick={createNewNote}
                      disabled={isNewNote}
                    >
                      <span className='font-semibold px-4 py-2 text-base'>
                        Create New Note
                      </span>
                    </CustomButton>
                  </div>
                </div>
                {/* <div className='flex h-6 w-6'>
                  <img src={NoteIcon} />
                </div> */}

                <div className='grid grid-cols-12 flex-1 relative gap-x-4 col-span-12'>
                  <div className='overflow-auto rounded-[8px] sticky border py-3 px-2 top-0 col-span-3'>
                    <NotesLists
                      selectedNote={selectedNote}
                      notesList={notesList}
                      setNotesList={setNotesList}
                      setSelectedNote={setSelectedNote}
                      contact={contact}
                      user={user}
                      refetch={refetch}
                    />
                  </div>
                  <div className='col-span-9 sticky top-4 md:top-6 max-h-[80vh] border rounded-[8px]'>
                    <div className='flex-1  relative w-full p-2'>
                      <div
                        className='absolute font-semibold text-[16px] right-4 '
                        style={{
                          color: saveStatus === 'Saved' ? 'green' : '',
                        }}
                      >
                        <div className='flex items-center'>
                          {saveStatus === 'Saved' && (
                            <span>
                              <CheckCheckIcon className='mr-2' color='green' />
                            </span>
                          )}
                          {saveStatus}
                        </div>
                      </div>
                      <div className='font-openSans text-softBlack'>
                        <input
                          type='text'
                          name='title'
                          value={
                            selectedNote?.notes?.title ?? tmpNote.notes.title
                          }
                          className='text-[20px] font-bold bg-transparent border-none focus:ring-0 focus:ring-offset-0 appearance-none'
                          onChange={(e) => handleInputChange(e, 'title')}
                          placeholder={'Add Title'}
                        />
                      </div>
                      <div className='font-openSans text-softBlack'>
                        <textarea
                          name='note'
                          className='bg-transparent border-none focus:ring-0 focus:ring-offset-0 appearance-none w-full resize-none overflow-auto'
                          cols='70'
                          value={
                            selectedNote?.notes?.notes ?? tmpNote.notes.notes
                          }
                          rows={29}
                          placeholder={'Type your note here ...'}
                          onChange={(e) => handleInputChange(e, 'notes')}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* {notesList.length > 0 &&
                  notesList.map((note, index) => (

                    // <NoteCard
                    //   note={note}
                    //   notesList={notesList}
                    //   setNotesList={setNotesList}
                    //   contact={contact}
                    //   user={user}
                    //   refetch={refetch}
                    //   key={note._id}
                    // />
                  ))} */}
                {/* {isNewNote && (
                  <NoteCard
                    note={newCard}
                    onFocusOutNewNoteBox={onFocusOutNewNoteBox}
                    setNewCard={setNewCard}
                    notesList={notesList}
                    setNotesList={setNotesList}
                    refetch={refetch}
                    contact={contact}
                    user={user}
                    onDeleteNewNote={onDeleteNewNote}
                  />
                )} */}
                <DeleteModal
                  title='Confirm deletion?'
                  description='Are you sure to delete this note?'
                  confirmButtonTitle='Yes'
                  cancelButtonTitle='No'
                  isOpen={isDeleteModal}
                  setIsOpen={setIsDeleteModal}
                  onConfirm={deleteTheNote}
                  loading={deleteLoading}
                />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
