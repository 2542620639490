// @ts-nocheck
/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { Modal } from '@material-ui/core'
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg'
import UseCSV from '@usecsv/react'
import useContactContext from 'pages/newContacts/hooks/useContactContext'
import CustomButton from 'components/CustomButton'
import useGetLists from 'hooks/useGetLists'
import { Select } from 'antd'
import Checkbox from 'components/Checkbox'

const dynamicColumnsForBuyer = [
  {
    name: 'firstName',
    displayName: 'First Name',
    example: 'John',
    // mustBeMatched: true,
    // valueCannotBeBlank: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'lastName',
    displayName: 'Last Name',
    example: 'Doe',
    // mustBeMatched: true,
    // valueCannotBeBlank: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'email',
    displayName: 'Email',
    example: 'john@example.com',
    // mustBeMatched: true,
    // valueCannotBeBlank: true,
    validationFormat: { type: 'email' },
  },
  {
    name: 'project',
    displayName: 'Project',
    example: 'Project X',
    // mustBeMatched: true,
    // valueCannotBeBlank: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'userType',
    displayName: 'User Type',
    example: 'Buyer',
    // mustBeMatched: true,
    // valueCannotBeBlank: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'phoneNumber',
    displayName: 'Phone Number',
    example: '123-456-7890',
    // mustBeMatched: true,
    // valueCannotBeBlank: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'leadSource',
    displayName: 'Lead Source',
    example: 'Source',
    // mustBeMatched: true,
    // valueCannotBeBlank: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'leadBroker',
    displayName: 'Lead Broker',
    example: 'Broker',
    // mustBeMatched: true,
    // valueCannotBeBlank: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'salesRep',
    displayName: 'Sales Rep',
    example: 'Rep',
    // mustBeMatched: true,
    // valueCannotBeBlank: true,
    validationFormat: { type: 'string' }
  },
  {
    name: 'hubspotId',
    displayName: 'Hubspot ID',
    example: '1234567890',
    validationFormat: { type: 'string' }
  },
  {
    name: "city",
    displayName: "City",
    example: "New York",
    validationFormat: { type: 'string' }
  },
  {
    name: "province",
    displayName: "State",
    example: "NY",
    validationFormat: { type: 'string' }
  },
  {
    name: "postalCode",
    displayName: "Postal Code",
    example: "10001",
    validationFormat: { type: 'string' }
  },
  {
    name: "country",
    displayName: "Country",
    example: "USA",
    validationFormat: { type: 'string' }
  },
  {
    name: "address",
    displayName: "Address",
    example: "123 Main St",
    validationFormat: { type: 'string' }
  },
  {
    name: 'createdAt',
    displayName: 'Created At',
    example: '2021-01-01',
    validationFormat: { type: 'string' }
  },
  {
    name: 'are_you_realtor',
    displayName: 'Are you a realtor?',
    example: 'Yes',
    validationFormat: { type: 'string' }
  }
]

const AddBulkContact = ({ show, onSuccess, setShow, project }) => {
  const [isCSVModalDisplay, setIsCSVModalDisplay] = useState(false)
  const [saveToList, setSaveToList] = useState(false)
  const [selectedListId, setSelectedListId] = useState(null)
  const { lists, isLoading, refetch } = useGetLists()
  // const [list, setList] = useState([])
  // useEffect(() => {
  //   const arr = []
  //   lists.forEach((el) => {
  //     if (!arr.find((item) => el.title === item.name)) {
  //       arr.push({ id: el._id, name: el.title })
  //     }
  //   })
  //   setList(arr)
  // }, [lists])
  const { view } = useContactContext()
  const renderButton = (openModal) => {
    return (
      <>
        {
          !isCSVModalDisplay
          && <CustomButton
            handleClick={() => {
              openModal()
              setIsCSVModalDisplay(true)
            }}
            className=''
          >
            <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
              Start importing bulk contacts
            </span>
          </CustomButton>
        }
      </>
    )
  }

  const handleClose = () => {
    setShow(false)
    setIsCSVModalDisplay(false)
    onSuccess()
  }

  return (
    <Modal
      open={show}
      onClose={handleClose}
      aria-labelledby='Add Or Edit Bulk Contact Modal'
      aria-describedby='Add Or Edit Bulk Contact description'
      className='!z-[100]'
    >
      <div
        className='relative flex flex-col font-openSans bg-grey-8 p-10 z-[10] rounded shadow-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'
        style={{ width: 'calc(100% - 50px)', height: 'calc(100% - 50px)' }}
      >
        <button className='absolute right-10' onClick={handleClose}>
          <CrossIcon />
        </button>
        <h1 className='font-bold text-xl text-center'>Bulk Upload Contacts</h1>
        <div className='relative w-full h-full flex flex-col gap-4 justify-center items-center'>
        {
          !isCSVModalDisplay && (
        <div className='flex z-[999] flex-col gap-4 mb-3 mt-4'>
          <div className='flex gap-2'>
            <Checkbox
              checked={saveToList}
              handleChange={() => setSaveToList(!saveToList)}
              label='Save contacts to the list'
            />
          </div> 
          {saveToList && (
            <Select
              className='z-[9999] !w-[260px]'
              placeholder='Lists'
              showSearch={true}
              value={selectedListId || ''}
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toString()
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onChange={(value) =>
                setSelectedListId(value)
              }
            >
              {lists?.map((el) => (
                <Select.Option key={el._id} label={el.title} value={el._id}>
                  {el.title}
                </Select.Option>
              ))}
            </Select>
            // <select
            //   value={selectedListId || ''}
            //   onChange={(e) => setSelectedListId(e.target.value)}
            //   className='w-full p-2 border rounded text-sm'
            //   disabled={isLoading}
            // >
            //   <option value=''>Select List</option>
            //   {lists?.map((list) => (
            //     <option key={list.id} value={list.id}>
            //       {list.name}
            //     </option>
            //   ))}
            // </select>
          )}
        </div>
          )}
          <UseCSV
            importerKey={process.env.REACT_APP_BULK_CONTACT_IMPORTER_KEY}
            dynamicColumns={dynamicColumnsForBuyer}
            render={(openModal) => renderButton(openModal)}
            importerDisplay='inline'
            onClose={handleClose}
            metadata={{
              project: view === 'project' ? project : null,
              listId: saveToList ? selectedListId : null,
            }}
          />
          {
            isCSVModalDisplay &&
            <div
              style={{
                position: 'relative',
                width: '100%',
                height: '100%',
                marginTop: '20px',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              id='usecsv-importer-inline-wrapper'
            />
          }
        </div>
      </div>
    </Modal>
  )
}

export default AddBulkContact