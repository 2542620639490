/* eslint-disable */
// @ts-nocheck
import React from 'react'
import { IoMdClose } from 'react-icons/io'
import useProjectMapContext from '../hooks/useProjectMapContext'

const UnitInfoDrawer = () => {
  const { state, closeUnitInfoDrawer } =
    useProjectMapContext()

  return (
    <div
      className={`right-0 fixed flex flex-col h-screen overflow-y-auto overflow-x-hidden gap-4 w-[280px] bg-white border-r-2 border-softBlack ${
        state.isUnitInfoDrawerOpen ? 'translate-x-0' : 'translate-x-full'
      } transition-all duration-300 ease-in-out`}
    >
      <div className='flex flex-col w-full max-h-full overflow-y-auto overflow-x-hidden'>
        <div className='flex flex-row w-full justify-between items-center p-6'>
          <div className='text-xl font-bold'>Info</div>
          <button onClick={() => closeUnitInfoDrawer()}>
            <IoMdClose fontSize='22px' />
          </button>
        </div>
        <img className='w-full h-full' src={'/unit-lot-temp.png'} />
        <div className='flex justify-center w-full space-x-4 font-medium mt-8 px-2'>
          <button
            onClick={() => console.log('reserve clicked')}
            className='w-40 h-12 rounded border border-softBlack70'
          >
            Reserve
          </button>
          <button
             onClick={() => console.log('purchase clicked')}
            className='w-32 h-12 rounded bg-volt'
          >
            Purchase
          </button>
        </div>
      </div>
    </div>
  );
}

export default UnitInfoDrawer
