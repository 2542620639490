import { Drawer, makeStyles } from '@material-ui/core'
import React, { useMemo, useState } from 'react'
import '../style/style.css'
import Checkbox from './Checkbox'
import sampleContract from 'assets/img/sampleContract.png'
import ReviewBuyerDetailsTab from './ContractDetailDrawer/ContractsTabs/ReviewBuyerDetailsTab'
import { KeyRound, Check, ChevronDown, Delete, Download, Image, MessageCircle, Pen, PencilLine, Printer, TextCursor } from 'lucide-react'
import Button from './Button'
import SubText from './SubText'
import { H5 } from 'ui'

const ReviewModeDrawer = ({ buyerId, isReviewModeDrawerOpen, closeReviewModeDrawerOpen }) => {
  const [selectedTabList, setSelectedTabList] = useState({
    id: 'review-terms-of-sale',
    title: 'Review terms of sale',
    numberOfTasks: 2,
    numberOfCompletedTasks: 2,
    checkLists: [
      {
        id: 'list_1.1',
        label: 'Offer terms'
      },
      {
        id: 'list_1.2',
        label: 'Buyer details'
      }
    ]
  })

  const useStyles = makeStyles((theme) => ({
    hide: {
      display: 'none'
    },
    drawer: {
      width: 'calc(100% - 78px)',
      flexShrink: 0
    },
    drawerPaper: {
      width: 'calc(100% - 78px)',
      height: '100vh',
      overflow: 'hidden',
      boxShadow: '-10px 0px 15px 0px rgba(46, 43, 46, 0.05)'
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end'
    }
  }))

  const classes = useStyles()

  const getItems = useMemo(() => {
    return [
      {
        id: 'review-terms-of-sale',
        title: 'Review terms of sale',
        numberOfTasks: 2,
        numberOfCompletedTasks: 2,
        checkLists: [
          {
            id: 'list_1.1',
            label: 'Offer terms'
          },
          {
            id: 'list_1.2',
            label: 'Buyer details'
          }
        ]
      },
      {
        id: 'review-all-draft-contracts',
        title: 'Review all draft contracts',
        numberOfTasks: 12,
        numberOfCompletedTasks: 1,
        checkLists: [
          {
            id: 'list_2.1',
            label: 'Disclosure of representation in trading services [DRTS]'
          },
          {
            id: 'list_2.2',
            label: 'Disclosure of risk to unrepresented parties [DRUP]'
          },
          {
            id: 'list_2.3',
            label: 'Disclosure to sellers of unexpected remueneration [DSER]'
          },
          {
            id: 'list_2.4',
            label: 'Disclosure of Interest in Trade [DIT]'
          },
          {
            id: 'list_2.5',
            label: 'FINTRAC | Individual, Corporation, Beneficial, Politically Exposed'
          },
          {
            id: 'list_2.6',
            label: 'FINTRAC | Receipt of Funds'
          },
          {
            id: 'list_2.7',
            label: 'Acknowledgement of receipt'
          },
          {
            id: 'list_2.8',
            label: 'Offer or purchase and Sale'
          },
          {
            id: 'list_2.9',
            label: 'Disclosure of Interest in Trade [DIT]'
          },
          {
            id: 'list_2.10',
            label: 'Disclosure of Interest in Trade [DIT]'
          },
          {
            id: 'list_2.11',
            label: 'Disclosure of Interest in Trade [DIT]'
          }
        ]
      },
      {
        id: 'approve-kyc-documents',
        title: 'Approve KYC documents',
        numberOfTasks: 4,
        numberOfCompletedTasks: 1,
        checkLists: []
      },
      {
        id: 'confirm-consideration-deposit-payment',
        title: 'Confirm consideration deposit payment',
        numberOfTasks: 1,
        numberOfCompletedTasks: 1,
        checkLists: []
      }
    ]
  }, [])

  const CustomCheckBox = ({ label }) => {
    const [isChecked, setIsChecked] = useState(false)

    return (
      <Checkbox
        label={label}
        labelClasses='self-center flex-1 text-zinc-950 dark:text-white text-xs xl:text-sm font-normal'
        checked={isChecked}
        handleChange={() => setIsChecked(!isChecked)}
        containerClasses=''
        iconClasses='self-start my-1'
      />
    )
  }

  const AccordionWrapper = () => {
    return (
      <>
        {
          getItems.map((item) => (
            <AccordionItem key={item.id} item={item} />
          ))
        }
      </>
    )
  }

  const AccordionItem = ({ item: { id, title, numberOfTasks, numberOfCompletedTasks, checkLists } }) => {
    return (
      <div
        key={`accordion-${id}`}
        className={`self-stretch rounded-lg border-2 flex-col justify-start items-start flex transition-all cursor-pointer
          ${selectedTabList.id === id ? 'bg-[#EDDF65]/[35%] border-[#EDDF65]' : 'hover:bg-[#EDDF65]/[35%] border-zinc-200 dark:border-zinc-400 hover:border-[#EDDF65]'}
        `}
      >
        <div
          className='w-full justify-between items-center inline-flex p-2 xl:px-4 xl:py-3.5'
          onClick={() => setSelectedTabList({
            id,
            title,
            numberOfTasks,
            numberOfCompletedTasks,
            checkLists
          })}
        >
          <div className='justify-start items-center gap-2 flex'>
            <div className='flex-col justify-start items-start gap-0.5 inline-flex'>
              <SubText className='text-zinc-950 text-xs xl:text-sm font-semibold leading-tight' label={title} />
            </div>
          </div>
          <div className='flex-1 justify-end items-center gap-1 xl:gap-2 flex'>
            <div className='justify-start items-center gap-1 flex'>
              <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <g clipPath='url(#clip0_16_17080)'>
                  <path d='M8.00016 14.6667C11.6822 14.6667 14.6668 11.682 14.6668 8.00004C14.6668 4.31804 11.6822 1.33337 8.00016 1.33337C6.00888 1.33337 4.22156 2.20633 3 3.59044C1.96282 4.76563 1.3335 6.30932 1.3335 8.00004C1.3335 11.682 4.31816 14.6667 8.00016 14.6667Z' stroke='#D4D4D8' strokeWidth='1.33' strokeLinecap='round' strokeLinejoin='round' />
                  <path d='M8.00001 1.33337C11.682 1.33337 14.6667 4.31804 14.6667 8.00004C14.6667 11.682 11.682 14.6667 8.00001 14.6667C4.31801 14.6667 1.33334 11.682 1.33334 8.00004' stroke='#047857' strokeWidth='1.33' strokeLinecap='round' strokeLinejoin='round' />
                </g>
                <defs>
                  <clipPath id='clip0_16_17080'>
                    <rect width='16' height='16' fill='white' />
                  </clipPath>
                </defs>
              </svg>
              <SubText variant='light' className='text-[10px] xl:text-xs font-normal leading-none truncate' label={`${numberOfTasks} tasks`} />
            </div>
            <ChevronDown className={`w-4 h-4 text-[#71717A] dark:text-zinc-100 transition-all ${selectedTabList.id === id ? 'rotate-0' : 'rotate-180'}`} />
          </div>
        </div>
        {
          (checkLists.length > 0 && selectedTabList.id === id) &&
          <div className='w-full px-2 pb-2 pt-0 xl:px-4 xl:py-3.5 xl:pt-0 flex-col justify-start items-start gap-2 xl:gap-4 flex'>
            {
              checkLists.map((elm) => (
                <CustomCheckBox
                  key={elm.id}
                  label={elm.label}
                />
              ))
            }
          </div>
        }
      </div>
    )
  }

  const VerticalCard = () => (
    <div className='w-[250px] xl:w-[350px] h-full flex flex-col overflow-hidden'>
      <div className='p-4 xl:p-6 flex flex-col gap-y-4 border-b border-[#2E2B2E] dark:border-zinc-500 border-opacity-[15%]'>
        <Button
          variant='outline'
          onClick={() => closeReviewModeDrawerOpen()}
          label='Exit review mode'
        />
      </div>
      <div className='w-full p-4 xl:p-6 flex-col justify-start items-start gap-2 xl:gap-4 inline-flex overflow-y-auto thin-scrollbar'>
        <div className='self-stretch h-9 flex-col justify-start items-start gap-2 flex'>
          <SubText className='text-zinc-950 text-xs xl:text-sm font-semibold leading-tight' label='25% complete' />
          <div className='self-stretch justify-start items-center gap-3 inline-flex'>
            <div className='w-full h-2 relative rounded-lg'>
              <div className='w-full h-2 left-0 top-0 absolute bg-zinc-200 dark:bg-zinc-800 rounded'></div>
              <div className='w-[94.88px] h-2 left-0 top-0 absolute bg-zinc-900 dark:bg-zinc-300 rounded'></div>
            </div>
          </div>
        </div>
        <AccordionWrapper />
      </div>
    </div>
  )

  return (
    <Drawer
      open={isReviewModeDrawerOpen}
      variant='persistent'
      anchor='right'
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <div className='pt-16 relative h-full dark:bg-darkModeBackground'>
        <div
          className='w-full flex h-full'
        >
          <div className='h-full border-r border-[#2E2B2E] dark:border-zinc-500 border-opacity-[15%]'>
            <VerticalCard />
          </div>
          <div className='flex-1 h-full flex flex-col bg-zinc-100 dark:bg-zinc-900'>
            <div className='w-full min-w-[400px] h-full flex flex-col overflow-hidden'>
              <div className='w-full p-4 border-b border-zinc-200 dark:border-zinc-800 justify-between items-center inline-flex bg-zinc-50 dark:bg-zinc-800'>
                <div className='justify-start items-center gap-2 flex'>
                  <div className='w-9 h-9 p-1.5 bg-[#EDDF65] dark:bg-[#EDDF65]/[45%] rounded-full justify-center items-center flex'>
                    <KeyRound className='w-6 h-6 dark:text-white' />
                  </div>
                  <div className='flex-col justify-start items-start gap-0.5 inline-flex'>
                    <div>
                      <H5 className='text-zinc-950 text-sm font-semibold leading-tight mb-0'>
                        {selectedTabList.title}
                      </H5>
                      <SubText
                        className='text-zinc-950 text-xs font-normal leading-none'
                        label={`${selectedTabList.numberOfCompletedTasks} of ${selectedTabList.numberOfTasks}: buyer details`}
                      />
                    </div>
                  </div>
                </div>
                <div className='flex justify-center gap-2'>
                  {
                    selectedTabList.id === 'review-all-draft-contracts' &&
                    <>
                      <Button
                        variant='outline'
                        label='Edit'
                        isIcon
                        Icon={Pen}
                      />
                      <Button
                        variant='outline'
                        label='Download'
                        isIcon
                        Icon={Download}
                      />
                    </>
                  }
                  <Button
                    label='Approve'
                    isIcon
                    Icon={Check}
                  />
                  {
                    selectedTabList.id === 'review-all-draft-contracts' &&
                    <Button
                      variant='ghost'
                      label='Skip'
                    />
                  }
                </div>
              </div>
              <div className='flex-1 overflow-y-auto thin-scrollbar'>
                {
                  selectedTabList.id === 'review-terms-of-sale' &&
                  <ReviewBuyerDetailsTab buyerId={buyerId} />
                }
                {
                  selectedTabList.id === 'review-all-draft-contracts' &&
                  <div className='w-full h-full px-8 pt-4 py-6 flex-col justify-start items-start gap-6 inline-flex overflow-hidden'>
                    <div className='self-stretch justify-between items-center inline-flex'>
                      <div className='self-stretch justify-start items-center gap-4 flex'>
                        <H5 className='text-zinc-950 text-base font-semibold leading-normal mb-0'>Disclosure of representation in trading services [DRTS]</H5>
                      </div>
                      <div className='self-stretch p-2 bg-white dark:bg-zinc-800 rounded border border-zinc-200 dark:border-zinc-500 justify-start items-start gap-2 flex'>
                        <div className='justify-start items-start gap-1 flex'>
                          <div className='w-9 h-9 hover:bg-zinc-100 dark:hover:bg-zinc-600 transition-all cursor-pointer rounded-md justify-center items-center flex'>
                            <TextCursor className='w-4 h-4 dark:text-white' />
                          </div>
                          <div className='w-9 h-9 hover:bg-zinc-100 dark:hover:bg-zinc-600 transition-all cursor-pointer rounded-md justify-center items-center flex'>
                            <PencilLine className='w-4 h-4 dark:text-white' />
                          </div>
                          <div className='w-9 h-9 hover:bg-zinc-100 dark:hover:bg-zinc-600 transition-all cursor-pointer rounded-md justify-center items-center flex'>
                            <MessageCircle className='w-4 h-4 dark:text-white' />
                          </div>
                          <div className='w-9 h-9 hover:bg-zinc-100 dark:hover:bg-zinc-600 transition-all cursor-pointer rounded-md justify-center items-center flex'>
                            <Image className='w-4 h-4 dark:text-white' />
                          </div>
                          <div className='w-9 h-9 hover:bg-zinc-100 dark:hover:bg-zinc-600 transition-all cursor-pointer rounded-md justify-center items-center flex'>
                            <Printer className='w-4 h-4 dark:text-white' />
                          </div>
                          <div className='w-9 h-9 hover:bg-zinc-100 dark:hover:bg-zinc-600 transition-all cursor-pointer rounded-md justify-center items-center flex'>
                            <Delete className='w-4 h-4 dark:text-white' />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='w-full flex-1 relative bg-white dark:bg-zinc-800 rounded-lg border border-zinc-200 dark:border-zinc-500 py-[60px] px-[43px] overflow-y-auto thin-scrollbar'>
                      <img className='w-full object-cover' src={sampleContract} />
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  )
}

export default ReviewModeDrawer
