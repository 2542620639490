import React, { useCallback } from 'react'
import propTypes from 'prop-types'
import { AdminPageWrapper, PageHeader } from 'components'
import { TFilledPlusIcon } from 'components/Icons'
import { useHistory } from 'react-router-dom'
import { ADMIN_CREATE_INVENTORY_ALLOCATION, ADMIN_PROJECT_ALLOCATIONS_NEW, ALLOCATIONS } from 'routes'
import { connect } from 'react-redux'
import AllocationsContent from './Allocations'

const Button = (props) => {
  return (
    <button
      className='text-softBlack text-center text-base font-medium py-1.5 px-4 space-x-1.5 flex items-center'
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  )
}

const Allocations = (props) => {
  const { appProject } = props
  const history = useHistory()

  const goToAllocationInventory = useCallback(() => {
    let nextRoute = ''
    if (history.location.pathname === ALLOCATIONS) {
      nextRoute = ADMIN_CREATE_INVENTORY_ALLOCATION
    } else {
      nextRoute = ADMIN_PROJECT_ALLOCATIONS_NEW
    }
    history.push({
      pathname: nextRoute,
      state: {
        projectId: appProject
      }
    })
  }, [appProject, history])

  const RightContent = () => (
    <div className='flex flex-row items-center'>
      <Button disabled={!appProject} onClick={() => goToAllocationInventory()}>
        <TFilledPlusIcon className='h-6 w-6' />
        Allocate Inventory
      </Button>
    </div>
  )

  return (
    <>
      <PageHeader title='Allocations & Suite Selection' rightContent={<RightContent />} />
      <AdminPageWrapper style={{ height: 'calc(100vh - 100px)' }} fullscreen>
        <AllocationsContent />
      </AdminPageWrapper>
    </>
  )
}

const mapStateToProps = (state) => ({
  appProject: state.appReducer.appProject
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Allocations)

Allocations.propTypes = {
  appProject: propTypes.string
}

Button.propTypes = {
  disabled: propTypes.bool,
  onClick: propTypes.func,
  children: propTypes.node
}
