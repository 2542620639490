/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'

import { classNames } from 'utils'
import StorageListView from './StorageListView'
import StorageInfoDrawer from 'components/StorageInventoryMap/components/StorageInfoDrawer'

const groupBy = (list, keyGetter) => {
  const map = new Map()
  list.forEach((item) => {
    const key = keyGetter(item)
    const collection = map.get(key)
    if (!collection) {
      map.set(key, [item])
    } else {
      collection.push(item)
    }
  })
  return map
}

const ListView = ({
  storages,
  loading,
  buildings,
  getBuildings,
  isPublicPage = false,
  refetchStorages
}) => {
  const [data, setData] = useState([])
  const [building, setBuilding] = useState('')
  // const [availabilityFilter, setAvailabilityFilter] = useState([])
  const [floorSortByAsc, setFloorSortByAsc] = useState(true)
  const [isStorageInfoDrawerOpen, setIsStorageInfoDrawerOpen] = useState(false)
  const [editDetails, setEditDetails] = useState({})

  useEffect(() => {
    if (storages.length > 0) {
      handleData()
    }
  }, [storages, building, floorSortByAsc])

  const handleData = () => {
    let uCards = [...storages]
    if (building.length > 0) {
      uCards = uCards.filter((_uCard) =>
        building.includes(_uCard?.building._id)
      )
    }
    const grouped = groupBy(uCards, (unit) => unit.floorNumber)
    // @ts-ignore
    const groupedValues = [...grouped.values()]
    if (floorSortByAsc) {
      setData([...groupedValues])
    } else {
      setData([...groupedValues.reverse()])
    }
  }

  return (
    <div className='w-full h-full'>
      <div className='flex w-full h-full'>
        <div
          className={classNames(
            isPublicPage ? 'flex w-full h-full p-2' : 'w-full pb-4',
            isStorageInfoDrawerOpen ? 'mr-[300px]' : 'mr-0',
            'relative transition-all'
          )}
        >
          <StorageListView
            storages={storages}
            loading={loading}
            buildings={buildings}
            getBuildings={getBuildings}
            type={'inventoryStorage'}
            isPublicPage={isPublicPage}
            refetchStorages={refetchStorages}
            onSelect={(val) => {
              setEditDetails(val)
              setIsStorageInfoDrawerOpen(true)
            }}
          />
        </div>
        <StorageInfoDrawer
          editDetails={editDetails}
          closeStorageInfoDrawer={() => setIsStorageInfoDrawerOpen(false)}
          isStorageInfoDrawerOpen={isStorageInfoDrawerOpen}
          isAbleToPurchase
        />
      </div>
    </div>
  )
}

export default ListView
