import { useStripe } from '@stripe/react-stripe-js'
import { message } from 'antd'
import React from 'react'
import { api } from 'services/api'

const VerifyBuyerButton = () => {
  const stripe = useStripe()
  const handleClick = async () => {
    const response = await api.post('/identity/stripe/createVerificationSession', {})
    const clientSecret = await response.data.client_secret
    const id = await response.data.id

    // Show the verification modal
    const { error } = await stripe.verifyIdentity(clientSecret)

    if (error) {
      console.log('error: ', error)
      message.error(`Verification failed! - ${error.message}`)
    } else {
      message.success('Verification submitted!')
      const session = await api.get('/identity/stripe/accessVerificationSessionData', {
        id: id
      })
      const verificationReportId = session.data.last_verification_report
      const report = await api.get('/identity/stripe/accessVerificationReport', {
        id: verificationReportId
      })
      console.log('res: ', report.data)
    }
  }

  return (
    <button
      role="link"
      onClick={handleClick}
      className='py-[21px] px-[61px] mt-4 bg-[#E8CB78] rounded-[7px] text-[#2E2B2E] text-[29px] font-bold'
    >
      Verify Me
    </button>
  )
}

export default VerifyBuyerButton
