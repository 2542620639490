// @ts-nocheck
/* eslint-disable */
import useDynamicDropdown from './useDynamicDropdown'

const useContactTypes = () => {
  const {
    data: contactTypeData,
    formattedData: contactTypeFormattedData,
  } = useDynamicDropdown('contactType')

  return {
    contactTypeData,
    contactTypeFormattedData
  }
}

export default useContactTypes
