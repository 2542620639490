import React, { useEffect, useCallback } from 'react'
import propTypes from 'prop-types'
import { updateVopayUserToken } from 'store/actions/paymentActions'
import { formatMoney } from 'utils'

const Vopay = (props) => {
  const {
    vopayUrl,
    offer: {
      unit: {
        unitNumber
      },
      buyer: {
        _id: buyerId
      }
    },
    chargePrice,
    executeWhenSuccess
  } = props

  const readVopayMessage = useCallback((message) => {
    if (message) {
      // Check if the event returned the token
      if (message.Token) {
        updateVopayUserToken({ token: message.Token, buyer: buyerId })
          .then(() => {
            executeWhenSuccess()
          })
          .catch(() => {
          })
      }
    }
  }, [buyerId, executeWhenSuccess])

  const receiveMessage = useCallback((event) => {
    try {
      const response = JSON.parse(event.data)
      if (response) {
        // Check if the message origin is from Vopay
        if (event.origin.includes('vopay')) {
          readVopayMessage(response)
        }
      }
    } catch (e) {}
  }, [readVopayMessage])

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false)
  }, [receiveMessage])

  return (
    <>
      <span className='text-xl'><strong>1st Payment - {unitNumber}</strong></span>
      <span className='text-xl'>You will be charged {formatMoney(chargePrice)}</span>
      <iframe title='Vopay' src={vopayUrl} width='100%' height='100%' />
    </>
  )
}

export default Vopay

Vopay.propTypes = {
  vopayUrl: propTypes.string,
  executeWhenSuccess: propTypes.func,
  unitNumber: propTypes.string,
  chargePrice: propTypes.string,
  offer: propTypes.shape({
    unit: propTypes.shape({
      unitNumber: propTypes.string
    }),
    buyer: {
      _id: propTypes.string
    }
  })
}
