import React from 'react'
import propTypes from 'prop-types'

const FormTabsWrapper = ({ children }) => (
  <div className='flex flex-row items-center justify-between sticky top-0 bg-white z-10'>
    {children}
  </div>
)

export default FormTabsWrapper

FormTabsWrapper.propTypes = {
  children: propTypes.node
}
