import { Check } from 'lucide-react'
import React from 'react'

const Button = ({
  variant = 'default',
  label = '',
  onClick = () => { },
  isIcon = false,
  Icon = Check,
  iconClass = '',
  containerClass = ''
}) => {
  const btnVariants = {
    default: 'bg-zinc-900 dark:bg-white text-white dark:text-zinc-900 hover:bg-zinc-900/90 dark:hover:bg-white/90 shadow',
    outline: 'bg-white dark:bg-zinc-900 dark:hover:bg-zinc-900/90 border border-zinc-200 dark:text-white shadow',
    destructive:
      'bg-destructive text-destructive-foreground hover:bg-destructive/90',
    secondary:
      'bg-secondary text-secondary-foreground hover:bg-secondary/80',
    ghost: 'hover:bg-accent hover:text-accent-foreground dark:hover:bg-zinc-600 dark:text-white',
    link: 'underline-offset-4 hover:underline text-primary',
    disabled: 'bg-zinc-100 dark:bg-zinc-900 hover:bg-zinc-200 dark:hover:bg-zinc-800 text-zinc-400'
  }

  return (
    <button
      onClick={onClick}
      className={`p-2 xl:px-4 xl:py-2 w-fit flex items-center justify-center rounded-md text-sm font-medium leading-tight transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background cursor-pointer 
        ${btnVariants[variant]} ${containerClass}
      `}
    >
      {
        isIcon &&
        <Icon className={`w-4 h-4 mr-2 ${iconClass}`} />
      }
      {label}
    </button>
  )
}

export default Button
