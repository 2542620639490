import styled from 'styled-components'
import { motion } from 'framer-motion'
import { Col, Row } from 'antd'

export const Wrapper = styled(Col)`
  margin: 0px 10px;
`
export const HeaderWrapper = styled(Row)``

export const IconWrapper = styled(motion.div)``
export const TitleWrapper = styled(motion.div)``

export const ChildrenWrapper = styled(motion.div)``
