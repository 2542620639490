/* eslint-disable */
// @ts-nocheck
import { DocusealBuilder } from '@docuseal/react'
import CustomButton from 'components/CustomButton'
import useSearchParams from 'hooks/useSearchParams'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { CONTRACT_TEMPLATES } from 'routes'
import { createOrUpdateContractTemplate, createOrUpdateDocuSealContractTemplate, getDocuSealContractTemplateById, getDocuSealContractTemplateByProjectId } from 'store/actions/contractTemplateActions'
import FieldSelector from '../components/FieldSelector'
import CustomSelect from 'pages/reservation/NewReservation/components/CustomSelect'
import { userTypesData } from 'utils'
import { useSelector } from 'react-redux'
import { getProjects } from 'store/actions/projectActions'

const DEFAULT_CONTRACT_TYPES = [
  'reservation',
  'offer',
  'unit',
  'parking',
  'storage',
  'upgrades',
  'colors',
  'singularContract'
]

const RightContent = memo(({ action = () => { } }) => (
  <div className='flex flex-row items-center gap-2'>
    <CustomButton handleClick={action} disabled={false}>
      <span className='font-medium py-2 px-4 text-base flex gap-2 items-center'>
        View all contract template
      </span>
    </CustomButton>
  </div>
))

const EditContractTemplate = () => {
  const { id } = useParams()
  const searchParams = useSearchParams()
  const projectId = useSelector((state) => state.appReducer.appProject)
  const customSections = useSelector((state) => state.appReducer.customSections)
  const type = searchParams.get('type')
  const history = useHistory()
  const [token, setToken] = useState('')
  const [newContract, setNewContract] = useState({
    allowedToSee: [],
    allowedToSign: []
  })
  const [isLoading, setIsLoading] = useState(false)
  const [templates, setTemplates] = useState([])
  const [customSectionOptions, setCustomSectionsOptions] = useState([])
  const [individualOptions, setIndividualOptions] = useState([])

  useEffect(() => {
    setIsLoading(true)
    getDocuSealContractTemplateById(id)
      .then((data) => {
        setToken(data.token)
        setNewContract({
          _id: data.contractTemplate._id,
          name: data.contractTemplate.name,
          contractType: data.contractTemplate.contractType,
          allowedToSee: data.contractTemplate.allowedToSee,
          allowedToSign: data.contractTemplate.allowedToSign,
          attachmentId: data?.contractTemplate?.attachmentId ?? '',
          contractCategory: data?.contractTemplate?.contractCategory ?? '',
          isMandatory: data?.contractTemplate?.isMandatory ?? false
        })
        setIsLoading(false)
      })
      .catch((error) => {
        console.error('Error:', error)
        setIsLoading(false)
      })
  }, [])

  useEffect(() => {
    const tempCustomSectionMenus = customSections?.map((section) => {
      return {
        value: section._id,
        label: section.title
      }
    })
    setCustomSectionsOptions(tempCustomSectionMenus)
  }, [customSections])

  const getContracts = () => {
    setIsLoading(true)
    getDocuSealContractTemplateByProjectId(projectId)
      .then((templates) => {
        setTemplates(templates)
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        console.error('Error:', error)
      })
  }

  const contractTypesOption = useMemo(() => {
    const isReservationContractExists = templates.filter((template) => template?.contractType === 'reservation')?.[0]
    return isReservationContractExists
      ? [
        {
          value: 'offer',
          label: 'Offer'
        },
        {
          value: 'singularContract',
          label: 'Singular Contract'
        },
        {
          value: 'unit',
          label: 'Unit/Lot'
        },
        {
          value: 'parking',
          label: 'Parking'
        },
        {
          value: 'storage',
          label: 'Storage'
        },
        {
          value: 'upgrades',
          label: 'Upgrades'
        },
        ...customSectionOptions,
        ...individualOptions
      ]
      : [
        {
          value: 'reservation',
          label: 'Reservation'
        },
        {
          value: 'offer',
          label: 'Offer'
        },
        {
          value: 'singularContract',
          label: 'Singular Contract'
        },
        {
          value: 'unit',
          label: 'Unit/Lot'
        },
        {
          value: 'parking',
          label: 'Parking'
        },
        {
          value: 'storage',
          label: 'Storage'
        },
        {
          value: 'upgrades',
          label: 'Upgrades'
        },
        ...customSectionOptions,
        ...individualOptions
      ]
  }, [templates, customSectionOptions, individualOptions])

  const getProject = () => {
    getProjects(projectId).then((project) => {
      const { options = [] } = project
      const temp = options.map((elm) => {
        if (DEFAULT_CONTRACT_TYPES.filter((e) => e === elm.type)?.length > 0) {
          return {
            label: `${elm.name} | ${elm.type}`,
            value: elm._id,
            type: elm.type
          }
        }
        const tempType = customSections.filter((section) => (section._id === elm.type))?.[0]?._id
        return {
          label: `${elm.name} | ${customSections.filter((section) => (section._id === elm.type))?.[0]?.title}`,
          value: elm._id,
          type: tempType
        }
      })
      setIndividualOptions([...temp])
    })
  }

  useEffect(() => {
    if (projectId) {
      getContracts()
      getProject()
    }
  }, [projectId])

  const handleTemplateSave = (data) => {
    setNewContract({
      ...newContract,
      name: data.name
    })
    createOrUpdateContractTemplate({
      _id: newContract._id,
      name: data.name
    }).catch((error) => {
      console.error(error)
    })
    createOrUpdateDocuSealContractTemplate(data)
      .then(() => {
        history.push(`/admin/contract_templates/${data.id}?type=${type}`)
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  }

  const handleSelectionChange = (key, value) => {
    setNewContract({
      ...newContract,
      [key]: value
    })
    createOrUpdateContractTemplate({
      _id: newContract._id,
      [key]: value
    })
      .catch((error) => {
        console.error(error)
      })
  }

  const handleContractTypeChange = (newValue) => {
    const temp = {
      ...newContract,
      contractType: individualOptions?.filter((elm) => ((elm?.value === newValue) || (elm?.value === newValue)))?.[0]?.type ?? newValue,
      attachmentId: individualOptions?.filter((elm) => ((elm?.value === newValue) || (elm?.value === newValue)))?.[0]?.value ?? ''
    }
    setNewContract(temp)
    createOrUpdateContractTemplate(temp)
      .catch((error) => {
        console.error(error)
      })
  }

  const getContractType = useCallback((type) => {
    if (contractTypesOption.filter((elm) => elm.value === type)?.length > 0) {
      return contractTypesOption.filter((elm) => elm.value === type)?.[0]?.label ?? '-'
    }
    if (individualOptions.filter((elm) => (elm.value === type))?.length > 0) {
      return individualOptions.filter((elm) => (elm.value === type))[0].label
    }
    const tempType = customSections.filter((section) => (section._id === type))?.[0]?.title
    return tempType ?? 'Untitled'
  }, [customSections, individualOptions])

  return (
    <div>
      <div className='sticky h-[64px] md:h-[74px] flex items-center justify-center border-b-2 border-black bg-white top-0 right-0 left-0 z-50 px-4 sm:px-6 lg:px-8'>
        <div className='flex-1 h-full flex flex-col items-start justify-center'>
          <h2 className='mb-0 font-semibold md:font-bold text-2xl md:text-3xl'>
            {isLoading ? 'Loading...' : newContract?.name ?? ''}
          </h2>
          <p className='mb-0 text-sm font-medium'>
            Type: {newContract?.attachmentId?.length > 0 && 'Individual '}{isLoading ? 'Loading...' : getContractType(newContract?.contractType) ?? ''}
          </p>
        </div>
        <div className='flex space-x-3 mt-0 ml-4 justify-end'>
          <RightContent
            action={() => history.push(CONTRACT_TEMPLATES)}
          />
        </div>
      </div>
      {
        token?.length > 0 &&
        <div className='h-[calc(100vh-138px)] flex'>
          {
            !(type === 'preview') &&
            <div className='h-full flex flex-col bg-white border-r border-softBlack30'>
              <div className='flex flex-col p-2 gap-2'>
                <CustomSelect
                  label='Who must sign the document? *'
                  labelClassName='text-[#000000]'
                  value={newContract?.allowedToSign ?? ''}
                  handleChange={(newValue) => handleSelectionChange('allowedToSign', newValue)}
                  options={userTypesData.map((userType) => ({
                    label: userType.value,
                    value: userType.id
                  }))}
                  mode='multiple'
                  placeholder='Who will need to sign?'
                  className='border-none'
                />
                <CustomSelect
                  label='Who can view the document? *'
                  labelClassName='text-[#000000]'
                  value={newContract?.allowedToSee ?? ''}
                  handleChange={(newValue) => handleSelectionChange('allowedToSee', newValue)}
                  options={userTypesData.map((userType) => ({
                    label: userType.value,
                    value: userType.id
                  }))}
                  mode='multiple'
                  placeholder='Who is gonna view the document?'
                  className='border-none'
                />
                <CustomSelect
                  label='Select Contract Type *'
                  labelClassName='text-[#000000]'
                  value={individualOptions?.filter((elm) => ((elm?.value === newContract?.attachmentId) || (elm?.value === newContract?.contractType)))?.[0]?.value ?? newContract?.contractType}
                  handleChange={handleContractTypeChange}
                  options={contractTypesOption}
                  showSearch
                  placeholder='Select Contract Type'
                  className='border-none'
                />
              </div>
              <div className='flex-1 overflow-hidden'>
                {
                  newContract?.contractType?.length > 0 &&
                  <FieldSelector contractType={newContract?.contractType} />
                }
              </div>
            </div>
          }
          <div className='flex-1'>
            <DocusealBuilder
              token={token}
              autosave={false}
              onSave={handleTemplateSave}
              style={{ height: '100%', display: 'block', backgroundColor: 'white' }}
              preview={!!(type === 'preview')}
            />
          </div>
        </div>
      }
    </div>
  )
}

export default EditContractTemplate
