export const leftZero = (num) => {
  return num >= 10 ? num : `0${num}`
}

export const convertHexToRgbA = (hex, a) => {
  // Convert the first 2 characters to hexadecimal
  const r = parseInt(hex.substring(1, 3), 16)

  // Convert the middle 2 characters to hexadecimal
  const g = parseInt(hex.substring(3, 5), 16)

  // Convert the last 2 characters to hexadecimal
  const b = parseInt(hex.substring(5, 7), 16)

  // append them all
  return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + a + ')'
}

export const toSnakeCase = (str) => {
  return str
    ?.match(
      /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
    )
    .map((x) => x.toLowerCase())
    .join('_')
}

export const convertToLowerCaseString = (str) => {
  return str
    ?.match(
      /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
    )
    .map((x) => x.toLowerCase())
    .join(' ')
}

export const convertToUpperCaseString = (str) => {
  return str
    ?.match(
      /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
    )
    .map((x) => x.toUpperCase())
    .join(' ')
}

export const convertToUpperCaseStringForDropdown = (str, type) => {
  const result = str
    ?.match(
      /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
    )
    .map((x) => x.toUpperCase())
    .join(' ')
  if (type?.length) {
    return `${type.toUpperCase().split('').slice(0, 3).join('')} - ${result}`
  }
  return result
}
