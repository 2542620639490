import React from 'react'

import DashboardTable from 'components/DashboardTable'
import { topAllocations } from '../TopUnitsTable/dummyData'
import Row from './Row'

const TopAllocationsTable = () => {
  return (
    <DashboardTable
      title="Top 10 Allocations"
      columnNames={['Allocation Name', 'Brokers', 'Percentage Sold', 'Expiration date', '']}
      data={topAllocations}
      RowToRender={Row}
      showIndex
      showMore
    />
  )
}

export default TopAllocationsTable
