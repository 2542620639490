import { ArrowDownUp } from 'lucide-react'
import useContactContext from 'pages/newContacts/hooks/useContactContext'
import React, { useMemo } from 'react'
import { isMobile } from 'react-device-detect'

const TableHead = () => {
  const { state, toggleCheckAllContacts, setFilters, toggleContactsSortingType } = useContactContext()
  const [localState, setLocalState] = React.useState('asc')
  const columns = useMemo(() => state.isContactDetailDrawerOpen || isMobile
    ? [
        { name: 'Contact', property: 'firstName', isSort: true }
      ]
    : !state.isContactDetailDrawerOpen
        ? ([
            { name: 'Date Registered', property: 'dateRegistered', isSort: true },
            { name: 'Name', property: 'firstName' },
            { name: 'User Type', property: 'userType' },
            { name: 'Email', property: 'email' },
            {
              name: 'Phone Number',
              property: { name: 'buyerData', property: 'phoneNumber' }
            },
            {
              name: 'Sales Rep Owner',
              property: { name: 'buyerData', property: 'salesRep' }
            },
            {
              name: 'Buyer Score',
              property: { name: 'buyerData', property: 'rating' }
            },
            {
              name: 'Lead Status',
              property: { name: 'buyerData', property: 'leadStatus' }
            }
          ])
        : ([
            { name: 'Date Registered', property: 'dateRegistered', isSort: true },
            { name: 'Name', property: 'firstName' },
            {
              name: 'Lead Status',
              property: { name: 'salesRepKey', property: 'leadStatus' }
            },
            { name: 'User Type', property: 'userType' },
            { name: 'Email', property: 'email' }
          ]), [state.selectedContact, state.isAddToListDrawerOpen])

  return <thead className={`transition-all ${(!state.isContactDetailDrawerOpen || isMobile) ? 'bg-gray-50' : 'bg-transparent'}`}>
    <tr>
      <th
        scope='col'
        className={`relative flex ${(!state.isContactDetailDrawerOpen || isMobile) ? 'w-fit md:w-16 px-3 md:px-6' : 'w-4 p-4'}`}
      >
        <input
          type='checkbox'
        //   checked={selected[page]}
          onChange={() => toggleCheckAllContacts()}
          className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6 hover:cursor-pointer'
        />
      </th>
      {columns.map((col, index) => (
        <th
          scope='col'
          className={`relative w-25 sm:w-16 whitespace-nowrap ${(!state.isContactDetailDrawerOpen || isMobile) ? 'px-6 py-3 pl-0' : 'p-4 px-0.5'}`}
          style={{ textAlign: 'left' }}
          key={index}
        >
          <span className='flex items-center gap-2'>
            {col.name}
            {
              col?.isSort &&
              <span onClick={() => {
                toggleContactsSortingType()
                setFilters({
                  ...state.filters,
                  sortType: localState === 'desc' ? 'asc' : 'desc'
                })
                setLocalState(localState === 'desc' ? 'asc' : 'desc')
              }} className='flex items-center cursor-pointer'>
                <ArrowDownUp className='w-4 h-4' />
              </span>
            }
          </span>
        </th>
      ))}
    </tr>
  </thead>
}

export default TableHead
