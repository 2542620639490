import React, { useEffect, useState } from 'react'
import { ReactComponent as DownIcon } from 'assets/icons/down.svg'
import { useFormikContext } from 'formik'
import { Select } from 'antd'
import { unitIds } from '../fieldData'
import { getDyanamicDropdownData } from 'store/actions/usersActions'
// import { useSelector } from 'react-redux'

const SelectUnitInterest = ({
  title = '',
  fieldName = '',
  placeholder = ''
}) => {
  // const projectId = useSelector(state => state.appReducer.appProject)
  const [projectId] = useState('607605e70de444001a71cfc8')
  const [dropDownOption, setDropDownOption] = useState([])
  const [options, setOptions] = useState([])
  const { setFieldValue, values } = useFormikContext()

  useEffect(() => {
    getDyanamicDropdownData(projectId, 'unitInterests').then((res) => {
      setDropDownOption(res.values.map((elm, index) => (
        {
          title: elm,
          value: unitIds[index],
          condition: true
        }
      )))
    }).catch((error) => console.log(error))
  }, [])

  useEffect(() => {
    if (values) {
      setDropDownOption(dropDownOption.map((elm) => {
        if (elm.value !== values.firstChoiceUnit && elm.value !== values.secondChoiceUnit && elm.value !== values.thirdChoiceUnit) {
          return {
            ...elm,
            condition: true
          }
        } else {
          return {
            ...elm,
            condition: false
          }
        }
      }))
    }
  }, [values])

  useEffect(() => {
    if (dropDownOption) {
      setOptions(dropDownOption.filter((elm) => elm.condition))
    }
  }, [dropDownOption])

  return (
    <div key={fieldName} className='flex flex-1 flex-col justify-left'>
      <div className='font-bold text-softBlack_70 italic'>{title}</div>
      <Select
        suffixIcon={<DownIcon />}
        showArrow
        onChange={(e) => setFieldValue(fieldName, e)}
        defaultValue={values[fieldName]}
        placeholder={placeholder}
        className='rating-select text-sm bg-white border border-softBlack30 rounded text-softBlack placeholder-softBlack70 h-10'
      >
        {options?.map(option => <Select.Option value={option.value}>
          <div className='flex gap-2 items-center'>
            <span className='h-full'>{option.title}</span>
          </div>
        </Select.Option>)}
      </Select>
    </div>
  )
}

export default SelectUnitInterest
