import React from 'react'

const CompanyLogo = () => {
  return <></>
  // return (
  //   <div className='flex-col flex m-auto items-center content-center justify-center w-[294px] h-[117px] bg-white'>
  //     <h2 className='px-6 py-3 text-4xl m-0'>MOSAIC</h2>
  //   </div>
  // )
}

export default CompanyLogo
