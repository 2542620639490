/* eslint-disable no-unused-vars */
import React from 'react'
import NewReservation from './NewReservation/NewReservation'

const CreateReservationWrapper = () => {
  return (
    <div>
      <NewReservation />
    </div>
  )
}

export default CreateReservationWrapper
