import { PageHeader } from 'components'
import React from 'react'

const ProspectQualification = () => {
  return (
    <div>
      <PageHeader title='Prospect Qualification' />
    </div>
  )
}

export default ProspectQualification
