import React from 'react'
import propTypes from 'prop-types'
import { classNames } from 'utils'

const ControlledTableColumn = ({ children, className }) =>
  <div className={classNames(' overflow-ellipsis overflow-hidden', className)}>{children}</div>

export default ControlledTableColumn

ControlledTableColumn.defaultProps = {
  className: 'max-w-xs'
}

ControlledTableColumn.propTypes = {
  children: propTypes.node,
  className: propTypes.string
}
