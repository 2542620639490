/* eslint-disable */
// @ts-nocheck
import React, { createContext, useEffect, useState } from 'react';
import { GetDemandNotes } from 'store/actions/demandNotes';
import { convertToLowerCaseString } from 'utils/converters';

export const DemandNotesContext = createContext({
  label: 'null',
  initialValues: null,
  setInitalValues: (val) => {},
  limit: 6,
  page: 1,
  handleEdit: () => {},
  setPage: (num) => {},
  filteredData: [],
  setFilteredData: (data) => {},
  search: '',
  setSearch: (str) => {},
  isEdit: false,
  setIsEdit: (bool) => {},
  oldName: '',
  setOldName: (name) => {},
  deleteModal: false,
  setDeleteModal: (bool) => {},
  dataToDelete: '',
  setDataToDelete: (name) => {},
  loading: null,
  update: null,
  deleteItem: null,
  updateItem: null,
  formattedData: null,
  data: [],
  setData: (data) => {},
  refresh: false,
  setRefresh: (bool) => {},
});

export const DemandNotesContextProvider = ({ children }) => {
  const [initialValues, setInitalValues] = useState({
    label: '',
    options: [],
    id: '',
  });
  const [filteredData, setFilteredData] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [limit] = useState(6);
  const [oldName, setOldName] = useState('');
  const [deleteModal, setDeleteModal] = useState(false);
  const [dataToDelete, setDataToDelete] = useState('');
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    GetDemandNotes().then((data) => {
      setData(data);
    });
  }, [refresh]);

  const handleEdit = async () => {
    setIsEdit(true);
  };

  return (
    <DemandNotesContext.Provider
      value={{
        data,
        refresh,
        setRefresh,
        initialValues,
        setInitalValues,
        limit,
        page,
        setPage,
        filteredData,
        setFilteredData,
        search,
        setSearch,
        isEdit,
        setIsEdit,
        oldName,
        setOldName,
        deleteModal,
        setDeleteModal,
        dataToDelete,
        setDataToDelete,
        handleEdit,
        label: convertToLowerCaseString('Test'),
      }}
    >
      {children}
    </DemandNotesContext.Provider>
  );
};

export default DemandNotesContextProvider;
