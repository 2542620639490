// @ts-nocheck
/* eslint-disable */
import ReportDownloadModal from 'components/ReportDownloadModal';
import ReportWaitingModal from 'components/ReportDownloadWaitingModal';
import useContactContext from 'pages/newContacts/hooks/useContactContext';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  bindChannel,
  subscribeToChannel,
  unbindChannel,
  unsubscribeToChannel,
} from 'services/pusher';
export default function ReportDownload() {
  const { waitingModal, setWaitingModal } = useContactContext();

  const [reportDownloadModal, setReportDownloadModal] = React.useState(false);
  const [reportUrl, setReportUrl] = React.useState(false);
  const userSelector = (state) => state.authReducer.userObject;
  const userId = useSelector(userSelector);
  const [reportName, setReportName] = React.useState('');
  let device_id = localStorage.getItem('device_id');
  const onNewCallCallBackReport = useCallback(
    (newCall) => {
      setWaitingModal(false);
      setReportUrl(newCall?.url);
      setReportName(newCall?.name);
      setReportDownloadModal(true);
    },
    [setReportDownloadModal, setReportUrl]
  );

  useEffect(() => {
    let reportChannel = null;
    reportChannel = subscribeToChannel(`report-${device_id}-${userId?._id}`);
    if (reportChannel) {
      bindChannel(reportChannel, onNewCallCallBackReport);
    }
    return () => {
      if (reportChannel) {
        unbindChannel(reportChannel, onNewCallCallBackReport);
      }
      unsubscribeToChannel(`report-${device_id}-${userId?._id}`);
    };
  }, [onNewCallCallBackReport, userId?._id]);

  return (
    <div>
      {reportDownloadModal && (
        <ReportDownloadModal
          reportName={reportName}
          show={reportDownloadModal}
          reportUrl={reportUrl}
          handleClose={() => setReportDownloadModal(false)}
        />
      )}
      {waitingModal && (
        <ReportWaitingModal
          waitingModal={waitingModal}
          handleModal={setWaitingModal}
        />
      )}
    </div>
  );
}
