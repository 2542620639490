import React, { useCallback, useEffect, useState } from 'react'
import clsx from 'clsx'
import { cadFormatter, formatMoney } from 'utils/formatters'
import Attribute from './Attribute'
import { AVAILABILITY_BG_COLOR } from 'utils'
import { getUnitEventEmitter } from 'hooks/useRealtimeUnitUpdate'

const InventoryUnit = ({
  imgSrc,
  _id: id,
  name: unitName,
  address,
  attributes,
  onViewDetails,
  totalValue,
  perSqValue,
  isActive,
  tag: tagData,
  onUpdate
}) => {
  const [tag, setTag] = useState(tagData)

  useEffect(() => {
    setTag(tagData)
  }, [tagData])

  const onUnitRealtimeUpdate = useCallback(
    (newData) => {
      if (id === newData.id || id === newData._id) {
        onUpdate?.(newData)
        setTag(newData.status)
      }
    },
    [tag, id]
  )

  useEffect(() => {
    getUnitEventEmitter()?.addListener('unit-update', onUnitRealtimeUpdate)
    return () => {
      getUnitEventEmitter()?.removeListener('unit-update', onUnitRealtimeUpdate)
    }
  }, [])

  return (
    <div
      className={clsx(' ', {
        'border-2 border-softBlack': isActive
      })}
    >
      <div className='h-60 bg-white p-2'>
        <img className='w-full h-full object-fill' src={imgSrc} alt='inventory' />
      </div>
      <div className='border-2 border-softBlack my-4 w-full' />
      {tag && (
        <div
          className={
            'rounded uppercase text-xs font-medium text-softBlack h-6.75 p-1 flex justify-center items-center min-w-20 w-fit px-2 mb-1'
          }
          style={{
            backgroundColor: AVAILABILITY_BG_COLOR[tag.toLowerCase()]
          }}
        >
          {tag}
        </div>
      )}
      <p className='font-bold text-2xl my-0'>Unit #{unitName}</p>
      <p className='text-sm font-medium mt-1'>
        {formatMoney(totalValue)}
        {perSqValue ? ` / ${cadFormatter.format(perSqValue)} PER SQ. FT` : ''}
      </p>
      <div className='flex items-start gap-2.5 mt-2'>
        <div className='h-5 w-5'>
          <svg
            width='20'
            height='21'
            viewBox='0 0 20 21'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M16.666 2.88086H9.99935C9.54102 2.88086 9.16602 3.25586 9.16602 3.71419V5.05586C9.16602 5.05586 9.16602 5.05586 9.17435 5.06419L13.341 8.81419C13.866 9.28086 14.166 9.96419 14.166 10.6725V11.2142H15.8327V12.8809H14.166V14.5475H15.8327V16.2142H14.166V17.8809H16.666C17.1243 17.8809 17.4993 17.5059 17.4993 17.0475V3.71419C17.4993 3.25586 17.1243 2.88086 16.666 2.88086ZM12.4993 6.21419H10.8327V4.54753H12.4993V6.21419ZM15.8327 9.54753H14.166V7.88086H15.8327V9.54753ZM15.8327 6.21419H14.166V4.54753H15.8327V6.21419Z'
              fill='#2E2B2E'
            />
            <path
              d='M12.5 17.0472V10.6722C12.5 10.4389 12.4 10.2139 12.225 10.0555L8.05833 6.30553C7.9 6.15553 7.7 6.08887 7.5 6.08887C7.3 6.08887 7.1 6.16387 6.94167 6.30553L2.775 10.0555C2.6 10.2055 2.5 10.4305 2.5 10.6722V17.0472C2.5 17.5055 2.875 17.8805 3.33333 17.8805H5C5.45833 17.8805 5.83333 17.5055 5.83333 17.0472V13.7139H9.16667V17.0472C9.16667 17.5055 9.54167 17.8805 10 17.8805H11.6667C12.125 17.8805 12.5 17.5055 12.5 17.0472Z'
              fill='#2E2B2E'
            />
          </svg>
        </div>
        <p className='my-0 font-normal text-softBlack text-base'>{address}</p>
      </div>
      {attributes?.length > 0 && (
        <div className='mt-4 flex items-center gap-4'>
          {attributes?.map((props, index) => (
            <Attribute {...props} key={index} />
          ))}
        </div>
      )}
      {onViewDetails && (
        <button onClick={onViewDetails} className='text-base font-medium mt-4'>
          View Details
        </button>
      )}
    </div>
  )
}

export default InventoryUnit
