import { useState, useCallback } from 'react'
import { api, ApiErrors, getTokenRequestHeader } from 'services/api'

const useCreateContactList = () => {
  const [loading, setLoading] = useState(false)

  const createSaveList = useCallback(async (saveList, titleText) => {
    setLoading(true)
    const token = getTokenRequestHeader()
    // @ts-ignore
    api.setHeader('Authorization', token)
    const response = await api.post('lists', {
      title: titleText,
      users: saveList
    })
    setLoading(false)
    if (!response.ok) return ApiErrors(response)
  }, [])

  return { createSaveList, loading }
}

export default useCreateContactList
