import React from 'react'
import CustomButton from 'components/CustomButton'
import { formatDate } from 'utils/dateUtils'

export const offerStatus = {
  complete: { text: 'Complete', backgroundColor: 'bg-green-200', fontColor: 'text-green-700' },
  signed: { text: 'Signed', backgroundColor: 'bg-green-200', fontColor: 'text-green-700' },
  pending: { text: 'Pending', backgroundColor: 'bg-gray-300', fontColor: 'text-gray-700' },
  cancelled: { text: 'Cancelled', backgroundColor: 'bg-red-200', fontColor: 'text-red-600' }
}

export const ColoredStatus = ({ status }) => (
  <div
    className={`w-fit font-medium py-1 px-3 md:px-4 text-xs md:text-base capitalize rounded-full
      ${offerStatus?.[status.toLowerCase()]?.backgroundColor || 'bg-gray-200'
      } ${offerStatus?.[status.toLowerCase()]?.fontColor || 'text-gray-700'
      } text-center
    `}
  >
    {offerStatus[status.toLowerCase()]?.text}
  </div>
)

const ReservationCard = ({
  reservationHolders = '',
  date,
  signatureStatus = '',
  paymentStatus = '',
  reservationStatus = '',
  btnAction = () => { },
  btnText,
  isTitled = false
}) => {
  return (
    <div className='w-full flex flex-col gap-2'>
      <div className='w-full flex flex-col gap-1 truncate' >
        <h4 className='font-medium text-lg text-softBlack capitalize truncate mb-0 flex flex-col justify-center'>
          {
            isTitled &&
            <span className='font-medium text-xs text-softBlack/[0.6] capitalize truncate'>
              Reservation holders:
            </span>
          }
          {reservationHolders}
        </h4>
        <span className='font-medium text-xs text-softBlack/[0.6] capitalize truncate'>
          {formatDate(date)}
        </span>
        <div className='flex flex-wrap gap-2'>
          {
            signatureStatus?.length > 0 &&
            <div className='flex items-center gap-1'>
              Signature:
              <ColoredStatus status={signatureStatus} />
            </div>
          }
          {
            paymentStatus?.length > 0 &&
            <div className='flex items-center gap-1'>
              Payment:
              <ColoredStatus status={paymentStatus} />
            </div>
          }
          {
            reservationStatus?.length > 0 &&
            <div className='flex items-center gap-1'>
              Reservation:
              <ColoredStatus status={reservationStatus} />
            </div>
          }
        </div>
      </div>
      <div className='w-full flex justify-end'>
        <CustomButton handleClick={btnAction}>
          <span className='font-medium py-1.5 px-3 text-sm md:text-base flex gap-2 items-center'>
            {btnText}
          </span>
        </CustomButton>
      </div>
    </div>
  )
}

export default ReservationCard
