import React, { memo, useEffect, useState, useCallback } from 'react'
import propTypes from 'prop-types'
import { Skeleton } from 'antd'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'
import { AnimatePresence, motion } from 'framer-motion'

import { PlusIcon, TrashIcon } from '@heroicons/react/solid'
import CreateEditRatingSideBar from './CreateEditRatingSideBar'
import { createOrUpdateLookupList, deleteLookuplist, getLookuplist } from 'store/actions/lookuplistActions'
import { AnimatedList, FadedAnimatedDiv, LoadingIcon } from 'components'
import {
  RatingWrapper, RatingsWrapper, RatingCircle, RatingTitle,
  NewRatingButtonWrapper, DeleteIconWrapper
} from './styles'
import { classNames } from 'utils'
import { Modal } from 'ui'

const RatingCircleWrapper = ({ children, onClick, className, onMouseEnter, onMouseLeave }) => (
  <RatingWrapper
    onClick={onClick}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    className={
      classNames(
        'flex flex-col items-center ease-in duration-75 hover:scale-115 transform rounded-md hover:cursor-pointer ml-5',
        className
      )}
  >
    {children}
  </RatingWrapper>
)

const defaultRatingOptions = [
  { name: 'Hot', type: 'contact_rating_status', value: '#10B981' },
  { name: 'Warm', type: 'contact_rating_status', value: '#F59E0B' },
  { name: 'Not Interested', type: 'contact_rating_status', value: '#EF4444' }
]

const Rating = ({ onClick, rating, executeAfterDelete }) => {
  const [showDeleteIcon, setShowDeleteIcon] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleting, setDeleting] = useState(false)

  const onDeleteClick = (e) => {
    e.stopPropagation()
    setShowDeleteModal(true)
  }

  const onDeleteRating = (e) => {
    e.stopPropagation()
    setShowDeleteIcon(false)
    setShowDeleteModal(false)

    setDeleting(true)

    deleteLookuplist(rating._id)
      .then(() => {
        setDeleting(false)
        executeAfterDelete()
      })
      .catch(() => setDeleting(false))
  }

  const onCancelDeleteRating = (e) => {
    e.stopPropagation()
    setShowDeleteIcon(false)
    setShowDeleteModal(false)
  }

  return (
    <RatingCircleWrapper
      onClick={onClick}
      className='relative'
      onMouseEnter={() => setShowDeleteIcon(true)}
      onMouseLeave={() => setShowDeleteIcon(false)}
    >
      <Modal
        showModal={showDeleteModal}
        modalType='danger'
        title='Confirm deletion?'
        subtitle={`${rating.name} will be deleted permanently!`}
        onOk={onDeleteRating}
        onCancel={onCancelDeleteRating}
      />
      <AnimatePresence exitBeforeEnter>
        {
          showDeleteIcon && !deleting &&
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0 }}
            className='absolute h-7 w-7 rounded-full -top-1 right-0 flex items-center justify-center bg-red-500'
          >
            <DeleteIconWrapper onClick={onDeleteClick}>
              <TrashIcon className='h-5 w-5 text-white' />
            </DeleteIconWrapper>
          </motion.div>
        }

        {
          deleting && (
            <LoadingIcon className='h-7 w-7 absolute -top-1 right-0 bg-white rounded-full p-1' />
          )
        }

      </AnimatePresence>

      <RatingCircle className='h-14 w-14 rounded-full' style={{ background: rating.value }} />
      <RatingTitle className='text-base mt-2 w-20 text-center'>{rating.name}</RatingTitle>
    </RatingCircleWrapper>
  )
}

const Ratings = memo(({ userObject: { userType, developerCompanyId } }) => {
  const [showCreateEditColorRatingModal, setShowCreateEditColorRatingModal] = useState(false)
  const [gettingRatings, setGettingRatings] = useState(true)
  const [listOfRatins, setListOfRatings] = useState(defaultRatingOptions)
  const [ratingId, setRatingId] = useState('')

  const history = useHistory()

  const insertDefaultOptions = (developerCompanyId) => new Promise((resolve) => {
    const itemsToInsert = defaultRatingOptions.map(async (option) => {
      const params = {
        name: option.name,
        type: option.type,
        key: developerCompanyId,
        value: option.value
      }

      await createOrUpdateLookupList(params, false)
    })

    Promise.all(itemsToInsert).then(() => {
      resolve()
    })
  })

  const getRatings = useCallback(() => new Promise((resolve, reject) => {
    getLookuplist({ type: 'contact_rating_status', key: developerCompanyId })
      .then(async (ratings) => {
        resolve(ratings)
      })
      .catch(() => reject())
  }), [developerCompanyId])

  const checkRatings = useCallback(async () => {
    setGettingRatings(true)

    let ratings = await getRatings()

    // If ratings is empty, means it's the first time is being getted.
    // Then, insert the default options first
    if (!ratings.length) {
      await insertDefaultOptions(developerCompanyId)
      ratings = await getRatings()
    }

    setListOfRatings(ratings)
    setGettingRatings(false)
  }, [developerCompanyId, getRatings])

  useEffect(() => {
    if (userType === 'DeveloperAdmin') {
      checkRatings()
    } else {
      history.goBack()
    }
  }, [userType, history, checkRatings])

  return (
    <RatingsWrapper className='flex flex-row w-full'>
      <CreateEditRatingSideBar
        _id={ratingId}
        showSideBar={showCreateEditColorRatingModal}
        setShowSideBar={() => setShowCreateEditColorRatingModal(false)}
        executeAfterSave={checkRatings}
        executeOnCancel={() => setRatingId('')}
        developerCompanyId={developerCompanyId}
      />
      <span className='text-sm font-medium text-gray-500'>Rating status</span>
      <AnimatePresence exitBeforeEnter initial={false}>
        {
          gettingRatings
            ? <FadedAnimatedDiv key='gettingRatings'>
                <AnimatedList wrapperClassName='flex flex-row w-full'>
                {
                  Array.from({ length: 4 })?.map(() =>
                    <Skeleton.Button
                      shape='circle'
                      active
                      style={{ width: 65, height: 65, marginLeft: 40 }}
                      />
                  )
                }
                </AnimatedList>
              </FadedAnimatedDiv>
            : (
                <FadedAnimatedDiv key='ratingsList' className='flex flex-row'>
                  <RatingCircleWrapper onClick={() => setShowCreateEditColorRatingModal(true)}>
                    <NewRatingButtonWrapper>
                      <RatingCircle className='rounded-full border-2 border-gray-400'>
                        <div className='p-3 flex items-center flex-col justify-center'>
                          <PlusIcon className='h-6 w-6 text-gray-500' />
                          <span className='text-base text-center'>Create</span>
                        </div>
                      </RatingCircle>
                    </NewRatingButtonWrapper>
                  </RatingCircleWrapper>
                  <AnimatedList wrapperClassName='grid grid-cols-6 w-full ml-5'>
                    {listOfRatins?.map(rating =>
                      <Rating
                        onClick={() => {
                          setRatingId(rating._id)
                          setShowCreateEditColorRatingModal(true)
                        }}
                        rating={rating}
                        executeAfterDelete={checkRatings}
                      />
                    )}
                  </AnimatedList>
                </FadedAnimatedDiv>
              )
        }
      </AnimatePresence>
    </RatingsWrapper>
  )
})

const mapStateToProps = (state) => ({
  userObject: state.authReducer.userObject
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Ratings)

Ratings.propTypes = {
  userObject: propTypes.shape({
    userType: propTypes.string,
    developerCompanyId: propTypes.string
  })
}

RatingCircleWrapper.defaultProps = {
  className: '',
  onMouseEnter: () => {},
  onMouseLeave: () => {}
}

RatingCircleWrapper.propTypes = {
  children: propTypes.node,
  onClick: propTypes.func,
  className: propTypes.string,
  onMouseEnter: propTypes.func,
  onMouseLeave: propTypes.func
}

Rating.propTypes = {
  onClick: propTypes.func,
  rating: propTypes.shape({
    value: propTypes.string,
    name: propTypes.string,
    _id: propTypes.string
  }),
  executeAfterDelete: propTypes.func
}
