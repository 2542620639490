/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react'

import { ContactContext } from 'pages/contacts/context/Contacts'

import CustomModal from 'components/Modal'
import CustomInput from 'components/CustomInput'
import Button from 'components/Buttons'
import { COLORS } from 'pages/pwaMode/createOfferNew/constants/colors'

import { CloseIcon } from 'components/Icons'

import { isMobile } from 'react-device-detect'

const ContactListCreateModal = ({
  open,
  setOpen,
  createContactList,
  savingContacts,
  showBackButtonToAddList = false,
  setAddToListOpen = (val) => { },
  refetchLists = () => { }
}) => {
  const { saveList, getContacts, setSelected, selectedContact } = useContext(ContactContext)
  const [listName, setListName] = useState('')

  const handleClose = () => {
    setListName('')
    setOpen(false)
  }

  useEffect(() => {
    if (!savingContacts) {
      handleClose()
      // @ts-ignore
      setSelected({})
    }
  }, [savingContacts])

  return (
    <>
      <CustomModal isOpen={open} closeModal={handleClose}>
        {' '}
        <div
          style={{ backgroundColor: 'rgb(242, 242, 241)' }}
          className={`${isMobile ? 'w-full' : 'w-136'} max-w-full p-10 text-center rounded bg-grey-8`}
        >
          <div
            className='flex justify-between items-center mb-2 hover:cursor-pointer'
            onClick={handleClose}
          >
            {showBackButtonToAddList &&
              <span onClick={() => {
                setOpen(false)
                setAddToListOpen(true)
              }} className='hover:cursor-pointer bg-volt rounded p-1'
              >
                Back
              </span>}
            <CloseIcon />
          </div>
          <h2
            style={{
              fontSize: 16,
              textAlign: 'left',
              marginLeft: 8,
              fontWeight: 400
            }}
            className='text-softBlack font-openSans italic'
          >
            List Name
          </h2>
          <CustomInput
            label={undefined}
            value={listName}
            onChange={(e) => setListName(e.target.value)}
            placeholder={'Enter List Name'}
            containerClasses={undefined}
            classes={undefined}
          />

          <div className='flex justify-center'>
            <Button
              buttonClasses='justify-self-center font-openSans px-4 py-4 mt-4'
              label='Save List'
              type='Primary'
              textColor={COLORS.softBlack}
              buttonBorder={`1px solid ${COLORS.volt}`}
              fontWeight='400'
              labelSize='16px'
              handleClick={async () => {
                if (savingContacts || listName.length === 0) {
                  return
                }

                selectedContact ? await createContactList([selectedContact], listName) : await createContactList(saveList, listName)
                setListName('')
                getContacts()
                refetchLists?.()
              }}
              labelFontStyle='italic'
              state={
                savingContacts || listName.length === 0 ? 'Disabled' : 'Default'
              }
            />
          </div>
        </div>
      </CustomModal>
    </>
  )
}

export default ContactListCreateModal
