import React from 'react'

import DashboardTable from 'components/DashboardTable'
import { mockBrokers } from '../TopUnitsTable/dummyData'
import Row from './Row'

const TopBrokersTable = () => {
  return (
    <DashboardTable
      title="Top-5 Brokers"
      columnNames={['Name', 'company', 'Sale Amount', 'Score']}
      data={mockBrokers}
      RowToRender={Row}
      showIndex
      learnMore
    />
  )
}

export default TopBrokersTable
