import React from 'react'
import PropTypes from 'prop-types'
import standardStyle from 'assets/css/standardStyle'
import { ReactComponent as Done } from '../../assets/icons/done.svg'

function Button ({
  label,
  size = 'Regular',
  type = 'Primary',
  icon = false,
  state = 'Default',
  handleClick,
  handleHover,
  buttonIcon = '',
  buttonClasses = '',
  fontWeight = '',
  textColor = '',
  buttonBorder = '',
  labelSize = '',
  labelFontStyle = '',
  buttonWidth = '',
  ...props
}) {
  let opacity = 1
  let height = '3rem'
  let border = 'none'
  let width = '6.25rem'
  let iconSize = '22px'
  let fontSize = '1rem'
  let iconDisplay = 'none'
  let lineHeight = '1.5rem'
  const borderRadius = '0.125rem'
  const color =
    textColor.length > 0 ? textColor : standardStyle.colors.softBlack
  let backgroundColor = standardStyle.colors.volt

  if (type === 'Primary' && state === 'Hover') {
    backgroundColor = standardStyle.colors.volt_hover
  }

  if (type === 'Primary' && state === 'Pressed') {
    backgroundColor = standardStyle.colors.volt_pressed
  }

  if (type === 'Secondary') {
    backgroundColor = 'none'
    border = `1px solid ${standardStyle.colors.softBlack_70}`
  }

  if (type === 'Secondary' && state === 'Hover') {
    border = `1px solid ${standardStyle.colors.volt}`
  }

  if (type === 'Secondary' && state === 'Pressed') {
    border = `1px solid ${standardStyle.colors.softBlack}`
  }

  if (size === 'Small') {
    width = '4.938rem'
    height = '2.313rem'
    fontSize = '0.875rem'
    lineHeight = '1.313rem'
    iconSize = '20px'
  }

  if (size === 'Small' && icon === true) {
    width = '6.688rem'
  }

  if (size === 'Long') {
    height = '3rem'
    width = '11.5rem'
    iconSize = '22px'
    fontSize = '1rem'
    lineHeight = '1.5rem'
  }

  if (icon === true) {
    iconDisplay = 'flex'
    width = '8.118rem'
  }

  if (state === 'Disabled') {
    opacity = 0.5
  }

  const iconStyle = {
    display: iconDisplay,
    width: iconSize,
    height: iconSize
  }

  const style = {
    color,
    width: buttonWidth.length > 0 ? buttonWidth : width,
    border: buttonBorder.length > 0 ? buttonBorder : border,
    height,
    opacity,
    fontSize: labelSize.length > 0 ? labelSize : fontSize,
    lineHeight,
    borderRadius,
    gap: '0.5rem',
    backgroundColor,
    display: 'flex',
    fontWeight: fontWeight.length > 0 ? fontWeight : 500,
    fontStyle: labelFontStyle.length > 0 ? labelFontStyle : 'normal',
    alignItems: 'center',
    justifyContent: 'center',
    font: standardStyle.fonts.title
  }

  return (
    <button
      className={buttonClasses}
      onClick={handleClick}
      onMouseOver={handleHover}
      style={style}
      {...props}
    >
      <Done style={iconStyle} />
      {buttonIcon && <img src={buttonIcon} alt='button icon' />}
      {label}
    </button>
  )
}

Button.propTypes = {
  label: PropTypes.string,
  size: PropTypes.oneOf(['Small', 'Regular', 'Long']),
  type: PropTypes.oneOf(['Primary', 'Secondary']),
  icon: PropTypes.oneOf([false, true]),
  state: PropTypes.oneOf(['Default', 'Hover', 'Pressed', 'Disabled']),
  handleClick: PropTypes.func,
  handleHover: PropTypes.func,
  buttonClasses: PropTypes.string,
  fontWeight: PropTypes.string,
  buttonIcon: PropTypes.string
}

export default Button
