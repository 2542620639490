// @ts-nocheck
import styled from 'styled-components'

export const TextBold = styled.p`
  color: #2e2b2e;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Open Sans';
  margin: 0px;
`
export const GrayText = styled.p`
  color: #646264;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  font-family: 'Open Sans';
  letter-spacing: -0.08px;
  margin: 0px;
`

export const AllAgentsRatingByContactsRatingContainer = styled.div`
  min-width: 421px;
  min-height: 573px;
  background-color: #fcfcfa;
  border-radius: 4px;
  flex-grow: 1;
`
export const AgentsByRatingContainer = styled.div`
  width: 100%;
  min-height: 573px;
  margin-top: 40px;
  padding: 24px;
  background-color: #fcfcfa;
  border-radius: 4px;
  flex-grow: 1;
`

export const PageContainer = styled.div`
  background-color: white;
  padding-bottom: 64px;
`
