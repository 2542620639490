import React from 'react'
import { ReactComponent as ExternalLink } from 'assets/icons/external.svg'
import { formatMoney } from 'utils/formatters'

const Row = ({ id, data }) => {
  return (
    <tr className="group">
      <td className='text-softBlack text-base font-medium font-openSans py-3'>
        {id + 1}
      </td>
      <td className='text-softBlack text-base font-medium font-openSans py-3'>{data.unit}</td>
      <td className='text-softBlack text-base font-medium font-openSans py-3'>{data.building}</td>
      <td className='text-softBlack text-base font-medium font-openSans py-3'>{formatMoney(data.amount)}</td>
      <td className='text-softBlack text-base font-medium font-openSans opacity-0 group-hover:opacity-100 py-3'>
        <a
          target="_blank"
          href={data.link}
          className="flex justify-end p-0 items-center"
          rel="noreferrer"
        >
          <ExternalLink />
        </a>
      </td>
    </tr>
  )
}

export default Row
