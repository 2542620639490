import { Formik } from 'formik'
import React from 'react'

const Form = ({ initialValues, onSubmit, children, className = '', wrapperStyle = undefined }) => {
  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit}>
        {() => <div style={wrapperStyle} className={className}>{children}</div>}
    </Formik>
  )
}

export default Form
