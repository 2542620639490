import React, { useState } from 'react'

import picture from 'assets/img/buyerBackgroundImage.jpg'

import { LoadingDetails } from 'pages/desktopMode/inventory/Allocation/AllocateInventory/Steps/Building'
import BuildingCard from 'components/BuildingCard'

import useGetBuildings from 'hooks/useGetBuildigs'

const PWABuildings = ({ setButtonState }) => {
  const { loading, buildings } = useGetBuildings('')
  const [activeBuilding, setActiveBuilding] = useState('')

  if (loading) {
    return (
      <div
        className='flex items-center justify-center w-full h-screen'
        style={{ gridColumn: '1 / -1' }}
      >
        <LoadingDetails title='Loading buildings' />
      </div>
    )
  }

  return (
    <>
      <div>
        <p className='font-bold font-2xl font-openSans font-softBlack text-4xl mt-11 mb-8 ml-8'>
          Buildings
        </p>
      </div>
      <div className='grid grid-cols-none lg:grid-cols-2 2xl:grid-cols-3 gap-0 w-full'>
        {buildings.length > 0 &&
          buildings.map((item, index) => (
            <div
              key={item._id}
              onClick={() => {
                setActiveBuilding(item._id)
                setButtonState('Default')
              }}
              className={`rounded-md border-2 ${
                activeBuilding === item?._id
                  ? 'border-softBlack'
                  : 'border-transparent'
              }`}
            >
              <BuildingCard
                key={item._id}
                id={item._id}
                index={index}
                propertyName={item.name}
                address={item.fullAddress}
                imgsrc={picture}
                price={`${item.totalDollarValue} ${item.totalDollarValueCurrencyType}`}
                noOfUnits={item.numberOfUnits}
                redirectToDetailPage={false}
              />
            </div>
          ))}
      </div>
    </>
  )
}

export default PWABuildings
