/* eslint-disable */
// @ts-nocheck
import { api, ApiErrors } from 'services/api'

export const getFilteredDeals = (filters) => new Promise((resolve, reject) => {
    let params = new URLSearchParams(filters)
    api.get(`deals/filtered?${params}`)
      .then((response) => {
        if (response.ok) {
          resolve(response.data)
        } else {
          ApiErrors(response)
          reject()
        }
      })
  })