/* eslint-disable no-unused-vars */
// @ts-nocheck
import React, { useEffect, useState, useCallback, useMemo } from 'react'
import propTypes from 'prop-types'
import { AnimatePresence } from 'framer-motion'
import { pdfjs } from 'react-pdf'
import { Divider, Row, message } from 'antd'
import { ZoomInIcon, ZoomOutIcon } from '@heroicons/react/solid'
import { FadedAnimatedDiv, LottieWrapper } from 'components'
import loadingContractsAnimation from 'assets/lottieJsons/loadingDoc.json'
import PdfDocument from './components/PdfDocument'
import ContractThumbnail from './components/ContractThumbnail'
import ContractsCounterAndFilter from './components/ContractsCounterAndFilter'
import { classNames, getUserType } from 'utils'
import {
  ContractsWrapper,
  ListOfContractsWrapper,
  ContractsCarouselWrapper,
  ContractWrapper,
  ZoomControlWrapper,
  ZoomIconWrapper,
  LeftWrapper,
  RightWrapper,
  DocumentBtnsWrapper,
  PreviewHeader
} from './styles'

import { connect } from 'react-redux'
import ContractSignedPanel from './components/ContractSignedPanel'
import { DocusealForm } from '@docuseal/react'
// import Button from 'components/Buttons'

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export const isSelectedContractSignedByUser = (selectedContract, userRole) => {
  if (!selectedContract) {
    return false
  }
  const isSinged =
    selectedContract?.signatures?.filter(
      (signature) =>
        signature.userType === getUserType(userRole) &&
        signature.status === 'signed'
    )?.length > 0
  return isSinged
}

const CustomButton = ({ children, buttonBGColor = null, ...rest }) => (
  <button
    // style={primaryBtnStyle}
    className={classNames(
      'italic font-light px-6 py-4 border text-sm text-softBlack m-auto flex items-center justify-center rounded',
      buttonBGColor ?? 'bg-volt'
    )}
    {...rest}
  >
    {children}
  </button>
)

const ParkingDocuments = (props) => {
  const {
    contracts,
    onGettingContracts,
    executeWhenAllDocsSigned,
    userObject,
    btnsShow = true,
    setCloseDocument = () => { },
    setPrimaryBtnAction = null,
    isPublicPath = false,
    parkingId
  } = props
  console.log('contracts: ', contracts)
  const [gettingContracts, setGettingContracts] = useState(false)
  const [originalListOfContracts, setOriginalListOfContracts] =
    useState(contracts)
  const [listOfContracts, setListOfContracts] = useState(contracts)
  const [selectedContract, setSelectedContract] = useState(contracts?.[0])
  const [docuSignUrl, setDocuSignUrl] = useState('')
  const [pdfPageNumber, setPdfPageNumber] = useState(1)
  const [pdfScale, setPdfScale] = useState(1.2)
  const [isSigning, setIsSigning] = useState(null)
  const [needsToSignDocument, setNeedsToSignDocument] = useState(false)
  const [selectedContractSignatureId, setSelectedContractSignatureId] =
    useState('')
  const [docsAnalyzedCounter, setDocsAnalyzedCounter] = useState(0)
  const [allDocsSigned, setAllDocsSigned] = useState(false)
  const [loading, setLoading] = useState(false)

  const getLoggedInUserType = useMemo(() => {
    return getUserType(userObject.userType)
  }, [userObject])

  useEffect(() => {
    let _needsToSign = false
    let _helloSignUrl = ''
    let _signatureId = ''

    if (!selectedContract?.mustSign) {
      _needsToSign = false
      _helloSignUrl = ''
      _signatureId = ''
    }

    if (selectedContract?.signatures?.length) {
      const userDocuments = selectedContract?.signatures.filter(
        (signature) =>
          signature.userType === getLoggedInUserType &&
          signature.status === 'awaiting_signature'
      )

      if (userDocuments && userDocuments.length) {
        const { url, signatureId } = userDocuments[0]

        _needsToSign = true
        _helloSignUrl = url
        _signatureId = signatureId
      }
    }

    setNeedsToSignDocument(_needsToSign)
    setDocuSignUrl(_helloSignUrl)
    setSelectedContractSignatureId(_signatureId)
  }, [selectedContract, userObject])

  useEffect(() => {
    const userDocs = listOfContracts
      ?.filter?.((contract) => {
        const signatureRequires =
          contract?.signatures?.filter(
            (signature) => signature.userType === getLoggedInUserType
          ).length > 0
        return contract.mustSign && signatureRequires
      })
      ?.map((contract) => {
        const tmpContract = { ...contract }
        tmpContract.signed = false
        const contractSigned = tmpContract.signatures?.filter(
          (signature) =>
            signature.userType === getLoggedInUserType &&
            signature.status === 'signed'
        )
        tmpContract.signed = Boolean(contractSigned.length)
        return tmpContract
      })
    const pendingDocs = Boolean(userDocs?.filter((doc) => !doc.signed).length)
    const allDocsAnalyzed = listOfContracts?.length === docsAnalyzedCounter

    if (listOfContracts?.length && !pendingDocs && allDocsAnalyzed) {
      setAllDocsSigned(true)
      if (executeWhenAllDocsSigned) executeWhenAllDocsSigned()
    }
  }, [
    listOfContracts,
    userObject,
    docsAnalyzedCounter,
    executeWhenAllDocsSigned
  ])

  const _getOfferContracts = useCallback(() => {
    // if (parkingId) {
    //   if (isPublicPath && contracts) {
    //     if (contracts?.length) {
    //       setOriginalListOfContracts(contracts)
    //       setListOfContracts(contracts)
    //       setSelectedContract(contracts[0])
    //     }
    //   } else {
    //     setGettingContracts(true)
    //     getOfferContractsPreview(parkingId)
    //       .then((response) => {
    //         const { contracts } = response
    //         if (contracts?.length) {
    //           setOriginalListOfContracts(contracts)
    //           setListOfContracts(contracts)
    //           setSelectedContract(contracts[0])
    //         }
    //       })
    //       .catch((err) => {
    //         console.error(err)
    //       })
    //       .finally(() => {
    //         setGettingContracts(false)
    //       })
    //   }
    // }

    const { contracts } = {
      contracts: [
        {
          contractId: '65eee52a9bb89b5b76798f33',
          templateId: '34810',
          contractTemplateId: '65d49df01071c555674ebfe8',
          contractTemplate: {
            id: '65d49df01071c555674ebfe8',
            name: 'Parking Addendum',
            templateNumber: '34810',
            description: 'Parking Addendum'
          },
          signatures: [
            {
              signatureId: '89518',
              userType: 'Buyer',
              status: 'awaiting_signature',
              url: 'https://docuseal.co/s/WP8mocegjNCs7R',
              email: 'mmollbuyer@gmail.com',
              _id: '65eee52a9bb89b5b76798f34'
            },
            {
              signatureId: '89520',
              userType: 'Developer Admin',
              status: 'awaiting_signature',
              url: 'https://docuseal.co/s/2GViYgjS95fDV3',
              email: 'mmoll+devsign@saperatech.com',
              _id: '65eee52a9bb89b5b76798f35'
            }
          ],
          submission_id: '72663',
          url: 'https://docuseal.co/blobs_proxy/WyIyYzcwYmRjYS0zMWJmLTQ0ZTgtOTZhMC1lNmNhYzU1ZDZlY2EiLCJibG9iIl0=--e35b9d0afd5fcf4a6b718c630e150922d11e2ce062b36ab04e441ac8d4fa4015/MW%20-%20Parking%20&%20Storage%20Addendum.pdf',
          updatedAt: '2024-03-11T11:04:10.225Z',
          mustSign: true
        }
      ]
    }
    if (contracts?.length) {
      setOriginalListOfContracts(contracts)
      setListOfContracts(contracts)
      setSelectedContract(contracts[0])
    }
  }, [parkingId, userObject, isPublicPath, contracts])

  useEffect(() => {
    _getOfferContracts()
  }, [_getOfferContracts])

  useEffect(() => {
    onGettingContracts && onGettingContracts(gettingContracts)
  }, [gettingContracts, onGettingContracts])

  const manageNumberOfPagesList = useCallback(
    ({ contractTemplateId, numPages }) => {
      const tmpListOfContracts = listOfContracts?.map?.((contract) => {
        const tmpContract = { ...contract }

        if (tmpContract.contractTemplateId === contractTemplateId) {
          tmpContract.pdfNumPages = numPages
        }

        return tmpContract
      })

      // This counter will say if the all of the documents were analyzed to collect the number of pages
      setDocsAnalyzedCounter(docsAnalyzedCounter + 1)
      setListOfContracts(tmpListOfContracts)

      // Must not update the originalLIst
      // if was already updated at the first time
      if (originalListOfContracts[0].pdfNumPages === undefined) {
        setOriginalListOfContracts(tmpListOfContracts)
      }
    },
    [listOfContracts, originalListOfContracts, docsAnalyzedCounter]
  )

  const isBuyerSingedSelectedContract = useMemo(() => {
    return isSelectedContractSignedByUser(selectedContract, 'Buyer')
  }, [selectedContract])

  const isDeveloperSingedSelectedContract = useMemo(() => {
    return (
      isPublicPath ||
      isSelectedContractSignedByUser(selectedContract, 'DeveloperAdmin')
    )
  }, [selectedContract, isPublicPath])

  const setDocSigned = useCallback(() => {
    let contractToSelect = {}
    const tmpContracts = listOfContracts?.map?.((contract) => {
      const tmpContract = { ...contract }
      if (
        contract.contractTemplateId === selectedContract?.contractTemplateId
      ) {
        tmpContract.signatures = tmpContract?.signatures?.map((signature) => {
          const tmpSignature = { ...signature }

          if (
            tmpSignature.status === 'awaiting_signature' &&
            tmpSignature.userType === getUserType(isSigning)
          ) {
            tmpSignature.status = 'signed'
          }

          return tmpSignature
        })
        tmpContract.signed = true

        contractToSelect = { ...tmpContract }
      }
      return tmpContract
    })

    setSelectedContract(contractToSelect)
    setListOfContracts(tmpContracts)
    setOriginalListOfContracts(tmpContracts)
    setIsSigning(null)
  }, [listOfContracts, selectedContract, isSigning])

  const onSelectDoc = useCallback((docProps) => {
    setPdfPageNumber(1)
    setIsSigning(null)
    setSelectedContract(docProps)
  }, [])

  const onSignDocumentClicked = useCallback(
    async (role) => {
      if (selectedContract) {
        setIsSigning(role)
        try {
          const userDocuments = selectedContract?.signatures.filter(
            (signature) => signature.userType === getUserType(role)
          )
          if (userDocuments && userDocuments.length) {
            const { url } = userDocuments[0]
            setDocuSignUrl(url)
          }
        } catch (err) {
          console.error(err)
        }
      }
    },
    [selectedContract]
  )
  const isAllSigned = useMemo(() => {
    return listOfContracts?.every((contract) => {
      return contract.signatures?.every((signature) =>
        signature.userType === getUserType('Buyer')
          ? signature.status === 'signed'
          : isPublicPath || signature.status === 'signed'
      )
    })
  }, [listOfContracts, isPublicPath])

  const isAllSingedForSelectedContact =
    isBuyerSingedSelectedContract && isDeveloperSingedSelectedContract

  const onSignNextContractClicked = useCallback(() => {
    if (!listOfContracts?.length) {
      return
    }
    const nextContractToSign =
      listOfContracts?.filter((contract) => {
        return (
          contract.signatures?.some((signature) =>
            isPublicPath
              ? signature.status !== 'signed' &&
              signature.userType === getUserType('Buyer')
              : signature.status !== 'signed'
          ) ?? null
        )
      })?.[0] ?? null
    if (nextContractToSign) {
      onSelectDoc(nextContractToSign)
    }
  }, [listOfContracts, isPublicPath])
  return (
    <AnimatePresence exitBeforeEnter initial={false}>
      {gettingContracts && (
        <FadedAnimatedDiv
          key='loadingContracts'
          className='flex flex-col justify-center items-center h-full'
        >
          <LottieWrapper
            animation={loadingContractsAnimation}
            loop
            autoplay
            className='max-w-lg'
          />
          <span className='text-xl text-center'>
            We are now working to show you the contracts generated for this
            Offer
          </span>
        </FadedAnimatedDiv>
      )}
      {!gettingContracts && (
        <FadedAnimatedDiv
          key='contracts'
          className='h-full'
          style={{ overflowY: 'hidden' }}
        >
          <ContractsWrapper>
            <LeftWrapper xs={24} sm={24} md={6} lg={6}>
              <ContractsCounterAndFilter
                listOfContracts={listOfContracts}
                originalListOfContracts={originalListOfContracts}
                onFilter={setListOfContracts}
              />
              <ListOfContractsWrapper>
                {listOfContracts?.length &&
                  listOfContracts?.map?.((contract, index) => {
                    return (
                      <ContractThumbnail
                        key={index}
                        contractData={contract}
                        index={index}
                        selected={
                          (contract?._id &&
                            contract._id === selectedContract._id) ||
                          (contract?.contractId &&
                            contract.contractId === selectedContract.contractId)
                        }
                        onSelectDoc={onSelectDoc}
                        onChangePdfPage={setPdfPageNumber}
                        onLoadSuccess={manageNumberOfPagesList}
                        isPublicPath={isPublicPath}
                      />
                    )
                  })}
              </ListOfContractsWrapper>
            </LeftWrapper>
            <RightWrapper>
              <PreviewHeader>Contract Preview</PreviewHeader>
              {!isAllSingedForSelectedContact && (
                <Row
                  justify='start'
                  style={{
                    width: '100%',
                    position: 'sticky',
                    top: 0,
                    right: 0,
                    zIndex: 10
                  }}
                >
                  <ZoomControlWrapper>
                    {!isSigning?.length && (
                      <>
                        <ZoomIconWrapper
                          onClick={() => setPdfScale(pdfScale + 0.1)}
                        >
                          <ZoomInIcon className='h-8 w-8' />
                        </ZoomIconWrapper>
                        <Divider
                          style={{ height: '60%', background: 'white' }}
                          type='vertical'
                        />
                        <ZoomIconWrapper
                          onClick={() => setPdfScale(pdfScale - 0.1)}
                        >
                          <ZoomOutIcon className='h-8 w-8' />
                        </ZoomIconWrapper>
                      </>
                    )}
                  </ZoomControlWrapper>
                </Row>
              )}
              <ContractsCarouselWrapper>
                <AnimatePresence
                  exitBeforeEnter
                  initial={false}
                  className='absolute'
                >
                  {isSigning?.length && docuSignUrl && (
                    <FadedAnimatedDiv
                      style={{ height: '100%', width: '100%' }}
                      key='hellosignAnimatedDiv'
                    >
                      <DocusealForm
                        src={docuSignUrl}
                        withTitle={false}
                        withDownloadButton={false}
                        withSendCopyButton={false}
                        allowToResubmit={false}
                        onComplete={() => {
                          setDocSigned()
                          setIsSigning(null)
                        }}
                      />
                    </FadedAnimatedDiv>
                  )}
                  {!isSigning?.length && (
                    <FadedAnimatedDiv
                      key='pdfPreview'
                      style={{ height: '100%', width: '100%' }}
                    >
                      <ContractWrapper>
                        {isAllSingedForSelectedContact && (
                          <ContractSignedPanel
                            contract={selectedContract}
                            onCloseScreenClicked={() => {
                              if (isAllSigned) {
                                setCloseDocument(false)
                              }
                            }}
                            isAllSigned={isAllSigned}
                            onSignNextContractClicked={() => {
                              if (!isAllSigned) {
                                onSignNextContractClicked()
                              }
                            }}
                          />
                        )}
                        {!isAllSingedForSelectedContact && (
                          <PdfDocument
                            pageProps={{ scale: pdfScale }}
                            documentProps={{
                              file: selectedContract?.url
                            }}
                            pageNumber={pdfPageNumber}
                          />
                        )}
                      </ContractWrapper>
                    </FadedAnimatedDiv>
                  )}
                </AnimatePresence>
              </ContractsCarouselWrapper>
              <DocumentBtnsWrapper>
                {btnsShow && (
                  <>
                    {!isPublicPath && !isBuyerSingedSelectedContract && setPrimaryBtnAction && (
                      <CustomButton
                        onClick={() => {
                          setCloseDocument(false)
                          setPrimaryBtnAction?.(true)
                        }}
                        disabled={loading}
                      >
                        Take Payment
                      </CustomButton>
                    )}
                    {!isPublicPath && !isDeveloperSingedSelectedContract && (
                      <>
                        <CustomButton
                          // onClick={() => sendContractsToDeveloper(parkingId)}
                          onClick={() => message.success('The contracts were sent to the developer')}
                          disabled={loading}
                          buttonBGColor={'bg-blue-500'}
                        >
                          Send to Developer
                        </CustomButton>
                        {!isSigning?.length && (
                          <CustomButton
                            onClick={() => {
                              if (isSigning?.length) {
                                return setIsSigning(null)
                              }
                              onSignDocumentClicked('DeveloperAdmin')
                            }}
                            disabled={loading}
                            buttonBGColor={'bg-blue-500'}
                          >
                            Developer Sign
                          </CustomButton>
                        )}
                      </>
                    )}
                    {!isPublicPath && !isBuyerSingedSelectedContract && (
                      <CustomButton
                        // onClick={() => sendContractsToBuyer(parkingId)}
                        onClick={() => message.success('The contracts were sent to the buyer')}
                        disabled={loading}
                      >
                        Send to Buyer
                      </CustomButton>
                    )}
                    {!isSigning?.length && !isBuyerSingedSelectedContract && (
                      <CustomButton
                        onClick={() => onSignDocumentClicked('Buyer')}
                        disabled={loading}
                      >
                        Sign Now
                      </CustomButton>
                    )}
                    {isSigning?.length && !isBuyerSingedSelectedContract && (
                      <CustomButton
                        onClick={() => setIsSigning(null)}
                        disabled={loading}
                      >
                        Back to Document Viewer
                      </CustomButton>
                    )}
                    {isPublicPath && !isAllSigned && (
                      <CustomButton
                        onClick={() => setCloseDocument(false)}
                        disabled={loading}
                      >
                        Go Back
                      </CustomButton>
                    )}
                  </>
                )}
              </DocumentBtnsWrapper>
            </RightWrapper>
          </ContractsWrapper>
        </FadedAnimatedDiv>
      )}
    </AnimatePresence>
  )
}

const mapStateToProps = (state) => ({
  userObject: state.authReducer.userObject
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ParkingDocuments)

ParkingDocuments.propTypes = {
  contracts: propTypes.array,
  onGettingContracts: propTypes.func,
  executeWhenAllDocsSigned: propTypes.func,
  btnsShow: propTypes.bool,
  setCloseDocument: propTypes.func,
  setPrimaryBtnAction: propTypes.func,
  userObject: propTypes.shape({
    userType: propTypes.string
  }),
  parkingId: propTypes.string,
  isPublicPath: propTypes.bool
}
