import styled from 'styled-components'
import { Row } from 'antd'
import { MotionButton } from '.'
import standardStyle from 'assets/css/standardStyle'

export const TermFooterButtonContainer = styled(Row)`
  justify-content: center;
  display: flex;
  margin: 25px 0px;
`

export const TermButton = styled(MotionButton)`
  background-color: transparent;
  border: 0.5px solid silver;
  width: 450px;
  overflow: hidden;
  padding: 0;
  justify-content: flex-start;
  z-index: 10;

  &:hover {
    border: ${props => !props.termRead ? '1px' : '0px'} solid ${standardStyle.colors.primaryHover} !important;
  }
`

export const TermButtonLoader = styled.div`
  background-color: ${props => props.termRead ? standardStyle.colors.primary : '#b5b5b5'};
  height: 32px;
`

export const TermButtonText = styled.p`
  position: absolute;
  align-self: center;
  text-align: center;
  color: ${props => props.termRead ? 'white' : 'default'};
  cursor: ${props => props.termRead ? 'pointer' : 'default'};

  @media ${standardStyle.mediaqueries.md}, ${standardStyle.mediaqueries.lg} {
    margin-left: ${props => props.termRead ? '60px' : '40px'};
  }

  @media ${standardStyle.mediaqueries.xs}, ${standardStyle.mediaqueries.sm} {
    margin-left: ${props => props.termRead ? '10px' : '5px'};
  }
`
