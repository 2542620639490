import React, { useState, useEffect, useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import propTypes from 'prop-types'

import { CreateOffer, PageHeader, TFilledLocationMarkerIcon } from 'components'
import { getProjects } from 'store/actions/projectActions'
import { FullScreenPortal, TButton } from 'ui'
import { TFilledChevronLeftIcon } from 'components/Icons'
import { connect } from 'react-redux'

const DesktopOffer = ({ appProject }) => {
  const location = useLocation()
  const history = useHistory()

  const [project, setProject] = useState('')
  const [localUnit, setLocalUnit] = useState({})
  const [gettingProject, setGettingProject] = useState(true)
  const [showingPortal, setShowingPortal] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setShowingPortal(true)
    }, 1) // 1 milisecond just to render the translation

    return setShowingPortal(false)
  }, [])

  const {
    state: {
      offerId,
      unit
    }
  } = location

  useEffect(() => {
    if (appProject) {
      setGettingProject(true)
      getProjects(appProject)
        .then((project) => {
          setProject(project)
          setGettingProject(false)
        })
        .catch(() => {
          setGettingProject(false)
        })
    } else {
      setGettingProject(false)
    }
  }, [appProject])

  useEffect(() => {
    if (unit && Object.keys(unit).length) {
      setLocalUnit(unit)
    }
  }, [unit])

  const renderPageHeader = useCallback(() => {
    const { projectName, backgroundImage, city, province, country } = project

    const parameters = [
      {
        icon: TFilledLocationMarkerIcon,
        name: 'Project location',
        label: `${city} - ${province} - ${country}`
      }
    ]

    const RightContent = () => (
      <>
        <TButton onClick={() => history.goBack()}>
          <TFilledChevronLeftIcon className='h-5 w-5 text-white' />
          Back
        </TButton>
      </>
    )

    return (
      <PageHeader
        title={`${offerId ? 'Editing' : 'Creating'} offer for ${projectName}`}
        image={backgroundImage}
        parameters={parameters}
        showParameters
        rightContent={<RightContent />}
        loading={gettingProject}
      />
    )
  }, [project, history, offerId, gettingProject])

  return (
    <>
      {renderPageHeader()}

      <FullScreenPortal
        showPortal={showingPortal}
        onClose={() => {
          setShowingPortal(false)
          setTimeout(() => {
            history.goBack()
          }, 100)
        }}
      >
        <CreateOffer
          offerId={offerId}
          externalSelectedUnit={localUnit}
        />
      </FullScreenPortal>
    </>
  )
}

const mapStateToProps = (state) => ({
  appProject: state.appReducer.appProject
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(DesktopOffer)

DesktopOffer.propTypes = {
  appProject: propTypes.string
}
