/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useState } from 'react'
import { getAllBrokerUsers } from 'store/actions/usersActions'

const useGetLeadBrokers = (userType) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  const getUsers = useCallback(async () => {
    setLoading(true)
    getAllBrokerUsers()
      .then((response) => {
        setData(
          response.sort((a, b) => {
            if (
              `${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}`
            ) {
              return 1
            }
            if (
              `${a.firstName} ${a.lastName}` < `${b.firstName} ${b.lastName}`
            ) {
              return -1
            }
            return 0
          })
        )
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  if (
    userType === 'Lead' ||
    userType === 'Buyer' ||
    userType === 'LeadBroker'
  ) {
    return { data: null, loading: false, refetchBrokers: (value) => {} }
  } else {
    return { data, loading, refetchBrokers: getUsers }
  }
}

export default useGetLeadBrokers
