import CustomButton from 'components/CustomButton'
import React from 'react'

const Method = ({ selected, onSelect, setStep, options }) => {
  const onBack = () => setStep((prev) => prev - 1)
  const onNext = () => {
    setStep((prev) => prev + 1)
  }

  return (
    <div>
      <div className='pb-4 border-b-2 border-softBlack'>
        <p className='my-0 text-xl font-bold'>Allocation Method</p>
      </div>
      <p className='my-0 mt-4 mb-5 text-softBlack text-base'>
        Select allocation method
      </p>
      <div className='grid grid-cols-3 gap-6'>
        {options.map((option, index) => (
          <div
            onClick={() => onSelect(option?.title)}
            key={index}
            className={`flex items-center justify-center bg-[#F2F2F1] rounded-sm border-2 ${selected === option?.title
                ? 'border-softBlack'
                : 'border-transparent'
              }`}
            style={{ height: 120 }}
          >
            <div className='flex items-center justify-center flex-col gap-4'>
              {option?.icon}
              <h1 className='font-bold text-lg m-0 text-center'>
                {option.title}
              </h1>
            </div>
          </div>
        ))}
      </div>
      <div className='flex justify-between mt-32'>
        <button
          className='py-3 px-6 text-base border border-softBlack text-softBlack rounded font-medium'
          onClick={onBack}
        >
          Back
        </button>
        <CustomButton
          handleClick={onNext}
          disabled={selected === undefined || selected?.length === 0}
        >
          <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
            Next
          </span>
        </CustomButton>
      </div>
    </div>
  )
}

export default Method
