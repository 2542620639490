import React from 'react'
import { connect } from 'react-redux'
import propTypes from 'prop-types'

import { handleLogout } from 'store/actions/authActions'
import { LogoutIcon } from './styles'
import { LOGOUT } from 'routes'
import { withRouter } from 'react-router-dom'
import HeaderButton from 'components/HeaderButton'

const LogoutButton = (props) => {
  const { handleLogout, history } = props

  const logout = () => {
    handleLogout()
    history.push(LOGOUT)
  }

  return (
    <HeaderButton
      popConfirmTitle='Are you sure?'
      onConfirm={() => logout()}
    >
      <LogoutIcon />

    </HeaderButton>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {
  handleLogout
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LogoutButton))

LogoutButton.propTypes = {
  handleLogout: propTypes.func,
  history: propTypes.shape()
}
