/* eslint-disable */
// @ts-nocheck
import React, { useCallback, useEffect, useState } from 'react'
import { getProjectUnits } from 'store/actions/unitActions'
import { getUnitPolygons } from 'store/actions/unitPolygonActions'
import CustomMap from './CustomMap'
import { getAllMapInventoryByType } from 'store/actions/mapInventoryActions'

const UnitInventoryMapView = ({ projectId, building }) => {
  const [gettingData, setGettingData] = useState(true)
  const [unitsData, setUnitsData] = useState([])
  const [unitPolygonsData, setUnitPolygonsData] = useState([])
  const [polygons, setPolygons] = useState([])
  const [unitStatusCount, setUnitStatusCount] = useState([])
  const [selectedMapInfo, setSelectedMapInfo] = useState({})

  const _getProjectUnits = useCallback(() => {
    setGettingData(true)
    getProjectUnits(projectId)
      .then((units) => {
        let tempObj = {
          underContract: 0,
          reserved: 0,
          sold: 0,
          available: 0,
          developerHeld: 0,
        }
        units.forEach((elm) => {
          if (elm.status === 'UnderContract') {
            tempObj.underContract += 1
          } else if (elm.status === 'ReservedBroker' || elm.status === 'ReservedBuyer' || elm.status === 'reserved') {
            tempObj.reserved += 1
          } else if (elm.status === 'SoldFirm' || elm.status === 'SoldCommitted' || elm.status === 'sold') {
            tempObj.sold += 1
          } else if (elm.status === 'Open' || elm.status === 'available' || elm.status === 'sold') {
            tempObj.available += 1
          } else if (elm.status === 'DeveloperHeld') {
            tempObj.developerHeld += 1
          }
        })
        setUnitStatusCount(Object.entries(tempObj).map(([status, count]) => ({ status, count, percentage: `${(count / units.length) * 100}%` })).sort((a, b) => (a.status > b.status) ? 1 : -1))
        setUnitsData(units)
        setGettingData(false)
      })
      .catch(() => {
        setGettingData(false)
      })
  }, [projectId])

  useEffect(() => {
    _getProjectUnits()
  }, [_getProjectUnits])

  const _getUnitPolygons = useCallback(() => {
    setGettingData(true)
    getUnitPolygons()
      .then((unitPolygons) => {
        setUnitPolygonsData(unitPolygons)
        setGettingData(false)
      })
      .catch(() => {
        setGettingData(false)
      })
  }, [])

  useEffect(() => {
    _getUnitPolygons()
  }, [_getUnitPolygons])

  useEffect(() => {
    if (unitPolygonsData?.length > 0) {
      setPolygons([
        ...unitPolygonsData.map((data) => {
          return {
            coordinates: data.polygon,
            inventoryId: data.unit,
            polygonId: data._id
          }
        })
      ])
    }
  }, [unitPolygonsData])

  const _getAllMapInventoryByType = useCallback(() => {
    setGettingData(true)
    getAllMapInventoryByType(projectId, building, 'Unit')
      .then((res) => {
        setSelectedMapInfo({ ...res[0] })
        setGettingData(false)
      })
      .catch((error) => {
        console.log('error: ', error)
        setGettingData(false)
      })
  }, [])

  useEffect(() => {
    _getAllMapInventoryByType()
  }, [projectId, building])

  return (
    <CustomMap
      mapInfo={selectedMapInfo}
      selectedLevel='U1'
      inventoryData={unitsData}
      polygonsData={unitPolygonsData}
      setPolygonsData={setUnitPolygonsData}
      polygons={polygons}
      isLoading={gettingData}
      setIsLoading={setGettingData}
      statusCount={unitStatusCount}
      refetchInventoryData={_getProjectUnits}
      refetchPolygonsData={_getUnitPolygons}
    />
  );
}

export default UnitInventoryMapView
