import { api, ApiErrors } from 'services/api'
import { message } from 'antd'

export const getPipelineBoard = (project = '') => new Promise((resolve, reject) => {
  api.get(`users/salesrep/pipeline/${project}/board`)
    .then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const createPipelineItem = async (props) => {
  const verb = 'post'
  const url = 'users/salesrep/pipeline/create'

  try {
    const response = await api[verb](url, props)
    if (response.ok) {
      message.success('New pipeline item created successfully\'}')
      return response.data
    } else {
      ApiErrors(response)
    }
  } catch (e) {
    ApiErrors(e)
  }
}

export const movePipelineItem = async (props) => {
  const { item, _id } = props
  const verb = 'put'
  const url = `users/salesrep/pipeline/move/${_id}`

  try {
    const response = await api[verb](url, item)
    if (response.ok) {
      return response.data
    } else {
      ApiErrors(response)
    }
  } catch (e) {
    ApiErrors(e)
  }
}
