// @ts-nocheck
import React from 'react'
import propTypes from 'prop-types'
import styled from 'styled-components'
import { ReactComponent as CardView } from '../../assets/icons/card_view.svg'
import { ReactComponent as ListView } from '../../assets/icons/list_view.svg'
import { MapIcon } from 'lucide-react'

const ViewSwitcherBorder = styled.div`
  width: fit-content;
  height: 100%;
  border: 0.063rem solid #2e2b2e;
  border-radius: 0.125rem;
  overflow: hidden;
`

const ViewSwitcherWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f1;
  width: 6.6rem;
  height: 100%;
  text-align: center;
  overflow: hidden;
`

const ListTab = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.2rem;
  height: 100%;
  background-color: ${(props) => props.listColor};
  margin: 0rem;
  padding: 0rem;
`

const CardTab = styled(ListTab)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.2rem;
  height: 100%;
  background-color: ${(props) => props.cardColor};
  margin: 0rem;
  padding: 0rem;
  border-right: 1px solid #2E2B2E;
`

const MapTab = styled(ListTab)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.2rem;
  height: 100%;
  background-color: ${(props) => props.mapColor};
  margin: 0rem;
  padding: 0rem;
  border: none;
  border-right: 1px solid #2E2B2E;
`

const NewViewSwitcher = ({ view = 'mapView', onChange = null }) => {
  const activatedColor = '#2E2B2E'
  const deactivatedColor = '#F2F2F1'

  return (
    <ViewSwitcherBorder>
      <ViewSwitcherWrapper>
        <MapTab
          className='hover:cursor-pointer'
          mapColor={view !== 'mapView' ? deactivatedColor : activatedColor}
          onClick={() => {
            onChange?.('mapView')
          }}
        >
          <MapIcon className='w-5 h-5' style={{ color: view === 'mapView' ? deactivatedColor : activatedColor }} />
        </MapTab>
        <CardTab
          className='hover:cursor-pointer'
          cardColor={view !== 'cardView' ? deactivatedColor : activatedColor}
          onClick={() => {
            onChange?.('cardView')
          }}
        >
          <CardView style={{ color: view === 'cardView' ? deactivatedColor : activatedColor }} />
        </CardTab>
        <ListTab
          className='hover:cursor-pointer'
          listColor={view !== 'listView' ? deactivatedColor : activatedColor}
          onClick={() => {
            onChange?.('listView')
          }}
        >
          <ListView style={{ color: view === 'listView' ? deactivatedColor : activatedColor }} />
        </ListTab>
      </ViewSwitcherWrapper>
    </ViewSwitcherBorder>
  )
}

NewViewSwitcher.propTypes = {
  view: propTypes.string
}

export default NewViewSwitcher
