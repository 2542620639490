import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { UNITS_INVENTORY } from 'routes'

import CancelModal from './CancelModal'

const Header = () => {
  const history = useHistory()
  const [isOpenCancelModal, setIsOpenCancelModal] = useState(false)

  const onCancel = () => {
    setIsOpenCancelModal(true)
  }

  const onConfirm = () => {
    setIsOpenCancelModal(false)
    history.push(UNITS_INVENTORY)
  }

  return (
    <>
      <div
        className='w-full bg-white fixed flex justify-between items-center px-8 py-6 border-b-2 border-softBlack'
      >
        <p className='my-0 text-4xl font-bold'>Reserve Unit</p>
        <button className='text-base font-medium' onClick={onCancel}>
          Cancel
        </button>
      </div>
      <CancelModal
        {...{ isOpenCancelModal, setIsOpenCancelModal, onConfirm }}
      />
    </>
  )
}

export default Header
