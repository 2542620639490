/* eslint-disable */
import { BedIcon, EditIcon } from 'components';
import { ReactComponent as TFilledBasketIcon } from 'assets/icons/deleteIcon.svg';
import { ReactComponent as TFilledPencilIcon } from 'assets/icons/editIcon.svg';
import Tag from 'components/Tags';
import React from 'react';
import { formatMoney } from 'utils';
const Button = ({ onClick, children, className = '', disabled = false }) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={
        'px-4 py-2 border border-gray-700 text-sm rounded flex items-center justify-center hover:bg-gray-100 ' +
        className
      }
    >
      {children}
    </button>
  );
};

const CustomParkingDetail = ({ parking, handleEdit, handleDelete }) => {
  return (
    <div className='p-4 flex space-x-4 text-softBlack bg-white rounded  border border-dashed border-softBlack_30 relative'>
      <div>
        {parking.stall && (
          <h1 className='font-bold' style={{ fontSize: 22 }}>
            Parking #{parking.stall}
          </h1>
        )}
        {parking.status && (
          <Tag
            label={parking.status}
            classes='italic'
            containerClasses='absolute top-4 right-4'
            type='Reserved'
          />
        )}
        {parking.price && (
          <h1
            className='font-light text-softBlack italic'
            style={{ fontSize: 14, marginTop: '-10px' }}
          >
            {formatMoney(parking.price)}
          </h1>
        )}
        <div className='flex items-center gap-4'>
          {parking.floorNumber && (
            <div className='flex items-center gap-4'>
              <BedIcon />
              <h1 className='m-0'>{parking.floorNumber}</h1>
            </div>
          )}
          {parking.inBuilding && (
            <div className='flex items-center gap-4'>
              <BedIcon />
              <h1 className='m-0'>Inside Building</h1>
            </div>
          )}
        </div>
        {handleDelete && handleEdit && (
          <div className='mt-6 space-x-4 flex'>
            <Button
              className='font-openSans italic flex items-center'
              onClick={handleEdit}
            >
              <TFilledPencilIcon className='h-[20px] mr-2' />
              Edit
            </Button>
            <Button
              className='font-openSans italic flex items-center'
              onClick={handleDelete}
            >
              <TFilledBasketIcon />
              Delete
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomParkingDetail;
