/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'

import { classNames } from 'utils'
import ParkingListView from './ParkingListView'
import ParkingInfoDrawer from 'components/ParkingInventoryMap/components/ParkingInfoDrawer'

const groupBy = (list, keyGetter) => {
  const map = new Map()
  list.forEach((item) => {
    const key = keyGetter(item)
    const collection = map.get(key)
    if (!collection) {
      map.set(key, [item])
    } else {
      collection.push(item)
    }
  })
  return map
}

const ListView = ({
  parkings,
  loading,
  buildings,
  getBuildings,
  isPublicPage = false,
  parkingsRefetch
}) => {
  const [data, setData] = useState([])
  const [building, setBuilding] = useState('')
  // const [availabilityFilter, setAvailabilityFilter] = useState([])
  const [floorSortByAsc, setFloorSortByAsc] = useState(true)
  const [isParkingInfoDrawerOpen, setIsParkingInfoDrawerOpen] = useState(false)
  const [editDetails, setEditDetails] = useState({})

  useEffect(() => {
    if (parkings.length > 0) {
      handleData()
    }
  }, [parkings, building, floorSortByAsc])

  const handleData = () => {
    let uCards = [...parkings]
    if (building.length > 0) {
      uCards = uCards.filter((_uCard) =>
        building.includes(_uCard?.building._id)
      )
    }
    const grouped = groupBy(uCards, (unit) => unit.floorNumber)
    // @ts-ignore
    const groupedValues = [...grouped.values()]
    if (floorSortByAsc) {
      setData([...groupedValues])
    } else {
      setData([...groupedValues.reverse()])
    }
  }

  return (
    <div className='w-full h-full'>
      <div className='flex w-full h-full'>
        <div
          className={classNames(
            isPublicPage ? 'flex w-full h-full p-2' : 'w-full pb-4',
            isParkingInfoDrawerOpen ? 'mr-[300px]' : 'mr-0',
            'relative transition-all'
          )}
        >
          <ParkingListView
            parkings={parkings}
            loading={loading}
            buildings={buildings}
            getBuildings={getBuildings}
            type={'Parking'}
            isPublicPage={isPublicPage}
            parkingsRefetch={parkingsRefetch}
            onSelect={(val) => {
              setEditDetails(val)
              setIsParkingInfoDrawerOpen(true)
            }}
          />
        </div>
        <ParkingInfoDrawer
          editDetails={editDetails}
          closeParkingInfoDrawer={() => setIsParkingInfoDrawerOpen(false)}
          isParkingInfoDrawerOpen={isParkingInfoDrawerOpen}
          isAbleToPurchase
        />
      </div>
    </div>
  )
}

export default ListView
