import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Oval } from 'react-loader-spinner'
import { message, Select } from 'antd'
// import { Button } from '@material-ui/core'
import CustomInput from 'components/CustomInput'
import CustomModal from 'components/Modal'
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg'
import useBrokerageCompanies from 'hooks/useBrokerageCompanies'
import * as Yup from 'yup'
import useUpdateUsers from 'hooks/useUpdateUsers'
import useLeadSource from 'hooks/useLeadSource'
import CustomButton from 'components/CustomButton'

const initialEmptyValues = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: ''
}

// const buttonStyle = {
//   backgroundColor: '#EDDF65',
//   height: '45px'
// }

const AddReferralAgent = ({ show, setShow, contact, onSuccess }) => {
  const { createOrUpdateUser } = useUpdateUsers(true)
  // @ts-ignore
  const appProject = useSelector((state) => state.appReducer.appProject)
  const initialValues = {
    ...initialEmptyValues
  }
  const { formattedLeadsources: leadSources } = useLeadSource()
  const [selectedLeadSource, setSelectedLeadSource] = useState('None')
  const [brokerageCompany, setBrokerageCompany] = useState('')
  const [state, setState] = useState({ ...initialValues })
  const [loading, setLoading] = useState(false)
  const [newBrokerageCompany, setNewBrokerageCompany] = useState('')
  const { companies, update } = useBrokerageCompanies()

  const onChange = useCallback(
    (fieldName, value) => {
      setState((prevState) => ({ ...prevState, [fieldName]: value }))
    },
    [setState]
  )

  const handleClose = () => {
    if (loading) {
      return
    }
    setState({ ...initialValues })
    setShow(false)
    setLoading(false)
  }

  const onValidate = () => {
    const requiredFields = ['firstName', 'lastName', 'email']
    for (let i = 0; i < requiredFields.length; i++) {
      if (state[requiredFields[i]] === '') {
        return false
      }
    }
    return true
  }

  useEffect(() => {
    if (newBrokerageCompany) {
      setBrokerageCompany(newBrokerageCompany)
    }
  }, [companies, newBrokerageCompany])

  const checkValidation = async () => {
    const isEmailValid = await Yup.string().email().isValid(state.email)
    if (!isEmailValid) {
      message.error('Please fill all the required field')
      return false
    }
    return true
  }

  const onSave = async () => {
    if (loading || !(await checkValidation())) {
      return
    }
    let success = false
    // If string has no valid number than return true
    if (!isNaN(parseInt(selectedLeadSource))) {
      success = true
    }
    let leadSource
    if (!success) {
      leadSource = leadSources[0].label
    } else {
      leadSource = leadSources[selectedLeadSource].label
    }

    const agentData = {
      firstName: state.firstName,
      lastName: state.lastName,
      email: state.email,
      project: appProject,
      userType: 'ReferralAgent',
      referralAgentData: {
        phoneNumber: state.phoneNumber,
        project: appProject,
        leadSource,
        brokerageCompany: brokerageCompany || undefined
        // "commissionPercentage": "string",
      }
    }

    try {
      setLoading(true)
      const res = await createOrUpdateUser(agentData)
      if (!contact?._id) {
        setState({ ...initialEmptyValues })
      }
      try {
        setShow?.(false)
        setLoading(false)
        onSuccess?.(res._id)
      } catch (error) {
        setLoading(false)
        console.error(error)
      }
    } catch (err) {
      setLoading(false)
      console.error(err)
    }
  }

  return (
    <CustomModal
      isOpen={show}
      closeModal={handleClose}
      contentLabel='Add Or Edit Contact Modal'
    >
      <div className='font-openSans relative bg-grey-8 p-10 rounded shadow-lg w-full'>
        <button id='create-referral-agent-form-close-button' className='absolute right-10' onClick={handleClose}>
          <CrossIcon />
        </button>
        <h1 className='font-bold text-xl text-center p-0 m-0'>
          Referral Agent
        </h1>
        <div className='flex flex-row gap-5 mt-5'>
          <div className='flex flex-1 flex-col  border-2 rounded-md border-gray-1 py-4 px-8'>
            <div className='text-xl font-bold'>Personal Info</div>
            <div className='py-8 flex flex-col gap-6'>
              <CustomInput
                id='create-referral-agent-firstName-input'
                label='First Name'
                placeholder='Enter first name'
                value={state.firstName}
                onChange={(e) => {
                  onChange('firstName', e.target.value)
                }}
              />
              <CustomInput
                id='create-referral-agent-lastName-input'
                label='Last Name'
                placeholder='Enter last name'
                value={state.lastName}
                onChange={(e) => {
                  onChange('lastName', e.target.value)
                }}
              />
              <CustomInput
                id='create-referral-agent-email-input'
                label='Email'
                placeholder='Enter email'
                value={state.email}
                onChange={(e) => {
                  onChange('email', e.target.value)
                }}
                type='email'
              />
              <CustomInput
                id='create-referral-agent-phoneNumber-input'
                type='phone'
                label='Phone Number'
                placeholder='Enter phone number'
                value={state.phoneNumber}
                onChange={(e) => {
                  onChange('phoneNumber', e.target.value)
                }}
              />
            </div>
            <CustomButton id='create-referral-agent-save-update-button' className='w-fit mx-auto mt-5' handleClick={onSave} disabled={!onValidate()}>
              <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
                {loading && <Oval height='18' width='18' color='white' />}
                {contact?._id ? 'Update' : 'Save'}
              </span>
            </CustomButton>
          </div>
          <div className='flex flex-1 flex-col gap-5 border-2 rounded-md border-gray-1 py-4 px-8'>
            <div className='text-xl font-bold pb-3'>Lead Data</div>
            <div className='w-full'>
              <p
                className={
                  'mt-0 mb-2 text-softBlack70 text-sm font-medium uppercase'
                }
                style={{ letterSpacing: '0.16px', marginBottom: '0.5rem' }}
              >
                Brokerage Companies
              </p>
              <Select
                id='create-referral-agent-brokerage-company-select'
                value={brokerageCompany}
                showSearch={true}
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toString()
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                onChange={(value) => {
                  setBrokerageCompany(value)
                }}
                className='ant-picker-brokerage-company w-full'
                getPopupContainer={(node) => node.parentNode}
              >
                {companies?.values?.map?.((_item, index) => (
                  <Select.Option key={index} label={_item} value={_item} className={`brokerage-company-${index}`}>
                    <div id={`brokerage-company-label-${index}`} className='flex gap-2 items-center'>
                      <span>{_item}</span>
                    </div>
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div className='w-full flex flex-row gap-2 items-end'>
              <div className='flex flex-1 flex-col'>
                <CustomInput
                  id='create-referral-agent-new-brokerage-company-name-input'
                  label='Add New Brokerage Company'
                  placeholder='Enter Brokerage Company'
                  classes='!m-0 text-xs font-medium '
                  value={newBrokerageCompany}
                  onChange={(e) => {
                    setNewBrokerageCompany(e.target.value)
                  }}
                  type='text'
                />
              </div>
              <CustomButton handleClick={() => update({ name: newBrokerageCompany })}>
                <span className='font-medium py-2 px-3 text-base flex gap-2 items-center'>
                  Submit
                </span>
              </CustomButton>
            </div>
            <div className='flex ' style={{ marginTop: '35%' }}>
              <div className='w-full'>
                <p
                  className={
                    'mt-0 mb-2 text-softBlack70 text-sm font-medium uppercase'
                  }
                  style={{ letterSpacing: '0.16px', marginBottom: '0.5rem' }}
                >
                  Lead Source
                </p>
                <Select
                  id='create-referral-agent-lead-source-select'
                  value={selectedLeadSource}
                  showSearch={true}
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toString()
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  onChange={(value) => {
                    setSelectedLeadSource(value)
                  }}
                  className='ant-picker-lead-source-select w-full'
                  getPopupContainer={(node) => node.parentNode}
                >
                  {leadSources?.map((_item, index) => (
                    <Select.Option
                      value={_item.value}
                      key={index}
                      label={_item.label}
                      className={_item.value}
                    >
                      <div id={`lead-source-label-${index}`} className='flex gap-2 items-center'>
                        <span>{_item.label}</span>
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CustomModal>
  )
}

export default AddReferralAgent
