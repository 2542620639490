import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import {
  getCampaignReport,
  getCampaignActivity
} from 'store/actions/emailCampaignActions'
import standardStyle from 'assets/css/standardStyle'
import PageHeader from 'components/PageHeader'
import { ADMIN_EMAIL_CUSTOM_CAMPAIGNS_BUILDER } from 'routes'
import { FadedAnimatedDiv, LottieWrapper } from 'components'
// @ts-ignore
import tableLoading from '../../assets/lottieJsons/tableLoading.json'

const Card = ({ title, value }) => (
  <div
    className='bg-white rounded'
    style={{ fontFamily: standardStyle.fonts.sfpro }}
  >
    <div className='flex flex-col space-y-2 p-6'>
      <div className='text-xs text-softBlack_70 font-medium uppercase'>
        {title}
      </div>
      <div className='text-xl text-softBlack font-bold'>{value}</div>
    </div>
  </div>
)

// eslint-disable-next-line space-before-function-paren
export default function EmailCampaignDetail() {
  // @ts-ignore
  const history = useHistory()
  const { id: campaignId } = useParams()
  const [campaign, setCampaign] = useState(null)
  const [subscriberActivity, setSubscriberActivity] = useState([])

  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [page, setPage] = useState(1)
  const [limit] = useState(25)
  useEffect(() => {
    getCampaignReport(campaignId)
      .then((report) => {
        delete report._links
        delete report.timeseries
        setCampaign(report)
      })
      .catch(() => {
        // redirect to campaigns screen
      })
  }, [campaignId])

  useEffect(() => {
    setIsLoadingMore(true)
    getCampaignActivity(campaignId, {
      page,
      limit
    })
      .then((activity) => {
        setSubscriberActivity((old) => {
          return [...old, ...activity]
        })
        setIsLoadingMore(false)
      })
      .catch()
  }, [campaignId, page, limit])

  if (!campaign) {
    return (
      <div className='w-full h-full'>
        <FadedAnimatedDiv
          key='loadingTable'
          className='w-full h-full flex items-center justify-center flex-col'
        >
          <LottieWrapper
            // @ts-ignore
            animation={tableLoading}
            loop
            className='h-16 w-16'
          />
          <span className='text-base'>loading...</span>
        </FadedAnimatedDiv>
      </div>
    )
  }

  const RightContent = () => (
    <>
      <button
        className='text-softBlack text-center text-base font-medium py-2 px-6 rounded flex items-center focus:ring-0 hover:bg-softBlack30 transition-all'
        onClick={() => history.push(ADMIN_EMAIL_CUSTOM_CAMPAIGNS_BUILDER)}
      >
        Go back
      </button>
      {/* <a
        target={'_blank'}
        href={campaign.long_archive_url}
        rel='noreferrer'
      >
        <Button size='Long' label='Live Preview'></Button>
      </a> */}
    </>
  )

  const sendGridReport = campaign?.sendGridReport ?? {
    bounce_drops: 0,
    bounces: 0,
    clicks: 0,
    delivered: 0,
    invalid_emails: 0,
    opens: 0,
    requests: 0,
    spam_report_drops: 0,
    spam_reports: 0,
    unique_clicks: 0,
    unique_opens: 0,
    unsubscribes: 0
  }

  return (
    <div>
      <PageHeader
        title={campaign.name}
        rightContent={<RightContent />}
      />
      <div className='grid grid-cols-6 gap-4 pt-8 mx-8'>
        <Card
          title={'Date'}
          value={new Date(campaign.createdAt).toLocaleDateString()}
        />
        <Card title={'Total Email Sent'} value={sendGridReport.requests} />
        <Card
          title={'Total opened'}
          value={sendGridReport.opens}
        />
        <Card
          title={'Total clicked'}
          value={sendGridReport.clicks}
        />
        <Card
          title={'Total Bounces'}
          value={
            sendGridReport.bounces
          }
        />
        <Card
          title={'Total Unsubscribed'}
          value={sendGridReport.unsubscribes}
        />
      </div>
      <div className='px-8'>
        <div className='grid grid-cols-2 gap-4 mt-8'>
          <div className='bg-white pt-6 px-6 rounded'>
            <div className='flex flex-col'>
              {[
                ['Successful Deliveries', sendGridReport.requests - sendGridReport.bounces],
                ['Total Opens', sendGridReport.opens ?? 0]
              ].map(([title, value]) => {
                return (
                  <div key={title} className='flex justify-between'>
                    <p>{title}</p>
                    <p>{value}</p>
                  </div>
                )
              })}
            </div>
          </div>
          <div className='bg-white pt-6 px-6 rounded'>
            <div className='flex flex-col'>
              {[
                ['Click per unique opens', ((sendGridReport.clicks ?? 0) / (sendGridReport.opens ? sendGridReport.opens : 1)) * 100 + ' %'],
                ['Total Clicks', sendGridReport.clicks ?? 0]
                // @ts-ignore
              ].map(([title, value]) => {
                return (
                  <div key={title} className='flex justify-between'>
                    <p>{title}</p>
                    <p>{value}</p>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <div className=''>
        <div className='mt-8 mx-8 px-4 bg-white rounded'>
          {!isLoadingMore && subscriberActivity.length === 0 && (
            <h1 className='text-center py-6 font-normal'>No Email Activity for campaign</h1>
          )}
          {subscriberActivity.length > 0 && (
            <table className='min-w-full divide-y divide-gray-300 '>
              <thead>
                <tr>
                  <th
                    scope='col'
                    className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0'
                  >
                    Email
                  </th>
                  <th
                    scope='col'
                    className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0'
                  >
                    Event
                  </th>
                  <th
                    scope='col'
                    className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0'
                  >
                    Event Time
                  </th>
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-200'>
                {subscriberActivity
                  ?.map((_data, index) => {
                    const event = _data.data
                    // @ts-ignore
                    const email = event.email
                    return (
                      <tr key={event.sg_event_id}>
                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-0'>
                          {email}
                        </td>
                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-0 capitalize'>
                          {event.event}
                        </td>
                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-0'>
                          {new Date(event.timestamp * 1000).toLocaleString()}
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          )}
        </div>
        <div className='flex items-center justify-center py-4 w-full'>
          <button className='text-softBlack text-center text-base font-medium py-2 px-6 rounded flex items-center focus:ring-0 hover:bg-softBlack30 transition-all' onClick={() => {
            if (isLoadingMore) return
            setPage(page + 1)
          }
          }>
            {isLoadingMore ? 'Loading...' : 'Load More'}
          </button>
        </div>
      </div>
    </div>
  )
}
