import React from 'react'
import CategoryButton from 'components/AdminSideBar/CategoryButton'

export const TYPES = [
  {
    title: 'Today',
    key: 'today',
    value: 'today'
  },
  {
    title: 'Last 7 Days',
    key: '7days',
    value: '7days'
  }
]

const LeadsTopFilterPanel = ({
  selected,
  onSelectChange,
  loading,
  length = null
}) => {
  return (
    <div className='w-full border-1.5 border-softBlack flex rounded-sm h-9.5 mt-2 divide-x-2'>
      {TYPES.map((_value) => (
        <CategoryButton
          key={_value.title}
          category={_value}
          selected={selected === _value?.value}
          setSelectedCategory={loading ? () => {} : onSelectChange}
          // @ts-ignore
          disabled={loading}
        >
          {`${_value.title} ${
            !loading && length && selected === _value?.value
              ? `(${length})`
              : ''
          }`}
        </CategoryButton>
      ))}
    </div>
  )
}

export default LeadsTopFilterPanel
