import React, { forwardRef, useEffect, useState } from 'react'
import Lottie from 'react-lottie'
import propTypes from 'prop-types'
import _ from 'lodash'

import { Wrapper } from './styles'

const LottieWrapper = forwardRef((props, ref) => {
  const {
    animation, isStopped, isPaused,
    loop, autoplay,
    onMouseEnter, onMouseLeave, onClick, style,
    direction, executeWhenFinished, id, className
  } = props

  const [localAnimation, setLocalAnimation] = useState('')

  useEffect(() => {
    const clonedAnim = _.cloneDeep(animation) // For performance issues

    setLocalAnimation(clonedAnim)
  }, [animation])

  return (
    <Wrapper
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      style={style || {}}
      id={id || null}
      className={className}
    >
      {
        animation && (
          <Lottie
            ref={ref}
            isStopped={isStopped}
            isPaused={isPaused}
            direction={direction}
            eventListeners={[
              {
                eventName: 'complete',
                callback: () => executeWhenFinished()
              }
            ]}
            options={{
              loop: loop,
              autoplay: autoplay,
              animationData: localAnimation,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
              }
            }}
          />
        )
      }
    </Wrapper>
  )
})

export default LottieWrapper

LottieWrapper.defaultProps = {
  isStopped: false,
  isPaused: false,
  loop: false,
  autoplay: true,
  onMouseEnter: () => {},
  onMouseLeave: () => {},
  onClick: () => {},
  style: {},
  direction: 1,
  executeWhenFinished: () => {}
}

LottieWrapper.propTypes = {
  animation: propTypes.object.isRequired,
  isStopped: propTypes.bool,
  isPaused: propTypes.bool,
  loop: propTypes.bool,
  autoplay: propTypes.bool,
  onMouseEnter: propTypes.func,
  onMouseLeave: propTypes.func,
  onClick: propTypes.func,
  style: propTypes.object,
  direction: propTypes.number,
  executeWhenFinished: propTypes.func,
  id: propTypes.string,
  className: propTypes.string
}
