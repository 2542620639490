import { api, ApiErrors } from 'services/api'
import { message } from 'antd'

export const getEmailTemplates = (id = '') =>
  new Promise((resolve, reject) => {
    api.get(`email-templates/${id}`).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const sendTestEmailToEmails = (id = '', subject, recipients) =>
  new Promise((resolve, reject) => {
    api.post(`email-templates/${id}/send-test`, {
      subject,
      recipients
    }).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const syncContactsToSendGrid = () => {
  return new Promise((resolve, reject) => {
    api.post('users/send-grid-sync', {}).then((response) => {
      if (response.ok) {
        message.success(response.data.message)
        resolve(response.data.message)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })
}

export const createOrUpdateEmailTemplates = (props, duplicate) =>
  new Promise((resolve, reject) => {
    const { _id, ...params } = props

    // If id, so we have to update the record, or we're gonna create
    let verb = _id ? 'put' : 'post'
    let url = _id ? `email-templates/${_id}` : 'email-templates'
    if (duplicate) {
      verb = 'post'
      url = `email-templates/${_id}/duplicate`
    }
    api[verb](url, params).then((response) => {
      if (response.ok) {
        message.success(
          `Email template was ${_id ? 'successfully updated' : 'created successfully'
          }`
        )
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const deleteEmailTemplates = (id) =>
  new Promise((resolve, reject) => {
    api.delete(`/email-templates/${id}`).then((response) => {
      if (response.ok) {
        message.success('Email template deleted successfully')
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })
