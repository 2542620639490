// @ts-nocheck
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { getProjectStatistics } from 'store/actions/offerActions'

const useGetProjectStatistics = () => {
  // const mountedRef = useRef(true)
  const appProject = useSelector((state) => state.appReducer.appProject)
  const [states, setStates] = useState({})
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (appProject) {
      getData()
    }
    // return () => {
    //   mountedRef.current = false
    // }
  }, [appProject])

  const getData = async () => {
    // if (!mountedRef.current) return null
    setLoading(true)
    try {
      const data = await getProjectStatistics(appProject)
      // if (!mountedRef.current) return null
      setStates(data)
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  return { loading, states }
}

export default useGetProjectStatistics
