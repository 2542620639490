import CustomButton from 'components/CustomButton'
import { CopyIcon } from 'lucide-react'
import React, { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

// eslint-disable-next-line space-before-function-paren
export default function CopyIt({
  content,
  size = 'base',
  title = 'Copy',
  copyTitle = 'Copied',
  onSuccess = () => { }
}) {
  const [copied, setCopied] = useState(false)
  return (
    <CopyToClipboard
      text={content}
      onCopy={() => {
        setCopied(true)
        onSuccess()
        setTimeout(() => {
          setCopied(false)
        }, 2500)
      }}
    >
      <div>
        <CustomButton variant='outline' className={`${copied ? 'border-zinc-700' : 'border-transparent'}`}>
          <span className='font-medium px-3 py-2 text-base flex gap-2 items-center'>
            {!copied && <CopyIcon size={size === 'sm' ? 12 : 16} />}
            <span className={`${size === 'sm' ? 'text-xs' : ''}`}>{copied ? copyTitle : title}</span>
          </span>
        </CustomButton>
      </div>
    </CopyToClipboard>
  )
}
