// @ts-nocheck
// import './wdyr'

import React from 'react'

import { Route, BrowserRouter } from 'react-router-dom'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
// import { ConfigProvider } from 'antd'
// import enUS from 'antd/lib/locale/en_US'

// import { ConfigProvider } from 'antd'
import { PersistGate } from 'redux-persist/integration/react'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'

import 'components'
import App from './App'

import GlobalStyle from 'assets/css/globalStyle'
import configureStore from 'store/configureStore'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import 'assets/css/main.css'
import 'assets/css/gridLayout.css'
import 'assets/css/reactResizable.css'
import 'assets/fonts/UniNeue/fonts-unineue.css'
import 'assets/fonts/MetroNovaPro/fonts-metronova.css'
import 'assets/fonts/Questrial/fonts-questrial.css'
import 'assets/fonts/ReenieBeanie/fonts-reeniebeanie.css'
import 'antd/dist/antd.css'
import 'react-perfect-scrollbar/dist/css/styles.css'

export const objStore = configureStore()

process.env.REACT_APP_SENTRY_DSN && Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0
})

const client = new QueryClient()

ReactDOM.render(
  <QueryClientProvider client={client}>
    <Provider store={objStore.store}>
      <PersistGate loading={null} persistor={objStore.persistor}>
        <GlobalStyle />
        <BrowserRouter>
          {/* <ConfigProvider locale={enUS}> */}
          <Route component={App} />
          {/* </ConfigProvider> */}
        </BrowserRouter>
      </PersistGate>
    </Provider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>,
  document.getElementById('root')
)
