export const BUYING_AS = [
  { id: 1, label: 'Yes', value: true },
  { id: 1, label: 'No', value: false }
]

export const RISK_TYPES = [
  { label: 'Low Risk', value: 'Low' },
  { label: 'Medium Risk', value: 'Medium' },
  { label: 'High Risk', value: 'High' }
]

export const AGENT_CHECKBOXES = [
  { id: 1, label: 'Residential property' },
  { id: 2, label: 'Commercial property' },
  { id: 3, label: 'Residential property for income purposes' },
  { id: 4, label: 'Land for commercial use' },
  { id: 5, label: 'Other' }
]
