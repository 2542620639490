/* eslint-disable */
import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  BROKER_DASHBOARD_PAGE,
  BROKER_HOME_PAGE,
  // BROKER_MENU_PAGE,
  BROKER_DOCUMENTS_PAGE,
  LOGOUT,
  // BROKER_CONTACTS_PAGE,
  BROKER_OPPORTUNITIES_PAGE,
  BROKER_RESERVATION_PAGE,
  // BROKER_AVAILABILITY_BOARD,
  BROKER_LANDING_PAGE
} from 'routes'
import CollapseSideBarButton from 'components/CollpaseSidebarButton'
import { isMobile } from 'react-device-detect'
import MenuItem from 'components/AdminSideBar/MenuItem'
import { TLogoutIcon } from 'components'
import { Modal } from 'ui'
import { ReactComponent as DashboardIcon } from '../../assets/icons/icn_dashboard.svg'
// import { ReactComponent as ContactsIcon } from '../../assets/icons/icn_contacts.svg'
import { ReactComponent as OpportunitiesIcon } from '../../assets/icons/icn_reduce_capacity.svg'
import { ReactComponent as ReservationsIcon } from '../../assets/icons/icn_dns.svg'
// import { ReactComponent as AvailabilityBoardIcon } from '../../assets/icons/icn_ballot.svg'
import { ReactComponent as MarketingDocumentsIcon } from '../../assets/icons/icn_sticky_note_2.svg'
import { ReactComponent as LandingPageIcon } from '../../assets/icons/icn_link.svg'
import { HomeIcon } from '@heroicons/react/solid'

const CustomHomeIcon = () => (
  <HomeIcon
    className="flex-shrink-0 h-5 w-5"
    aria-hidden="true"
  />
)

const BrokerMenus = (props) => {
  const { selectedMenuId, setSelectedMenuId, showMenu, setShowMenu, handleLogout } = props

  const [showLogoutModal, setShowLogoutModal] = useState(false)

  const history = useHistory()

  // const appProject = useSelector((state) => state.appReducer.appProject)
  const [appProject] = useState('607605e70de444001a71cfc8')
  const listOfProjects = useSelector(
    (state) => state.appReducer.listOfProjects
  )
  const selectedProject = listOfProjects.find(
    (_project) => _project._id === appProject
  )
  const logout = useCallback(() => {
    handleLogout()
    history.push(LOGOUT)
  }, [handleLogout, history])

  const confirmLogoutModal = useCallback(() => {
    return (
      <Modal
        showModal={showLogoutModal}
        onCancel={() => setShowLogoutModal(false)}
        modalType='alert'
        title='Are you sure?'
        subtitle='Do you want to quit?'
        onOk={() => logout()}
      />
    )
  }, [logout, showLogoutModal, setShowLogoutModal])

  const SIDE_BAR_MENU = [
    {
      menu: [
        {
          key: BROKER_HOME_PAGE,
          title: 'Home',
          onClick: () => history.push(BROKER_HOME_PAGE),
          visible: true,
          hasSubMenus: false,
          route: BROKER_HOME_PAGE,
          Icon: CustomHomeIcon
        },
        {
          key: BROKER_DASHBOARD_PAGE,
          title: 'Dashboard',
          onClick: () => history.push(BROKER_DASHBOARD_PAGE),
          visible: true,
          hasSubMenus: false,
          route: BROKER_DASHBOARD_PAGE,
          Icon: DashboardIcon
        },
        // {
        //   key: BROKER_CONTACTS_PAGE,
        //   title: 'Contacts',
        //   onClick: () => history.push(BROKER_CONTACTS_PAGE),
        //   visible: true,
        //   hasSubMenus: false,
        //   route: BROKER_CONTACTS_PAGE,
        //   Icon: ContactsIcon
        // },
        {
          key: BROKER_OPPORTUNITIES_PAGE,
          title: 'Opportunities',
          onClick: () => history.push(BROKER_OPPORTUNITIES_PAGE),
          visible: true,
          hasSubMenus: false,
          route: BROKER_OPPORTUNITIES_PAGE,
          Icon: OpportunitiesIcon
        },
        {
          key: BROKER_RESERVATION_PAGE,
          title: 'Reservation',
          onClick: () => history.push(BROKER_RESERVATION_PAGE),
          visible: true,
          hasSubMenus: false,
          route: BROKER_RESERVATION_PAGE,
          Icon: ReservationsIcon
        },
        // {
        //   key: BROKER_AVAILABILITY_BOARD,
        //   title: 'Availability Board',
        //   onClick: () => history.push(BROKER_AVAILABILITY_BOARD),
        //   visible: true,
        //   hasSubMenus: false,
        //   route: BROKER_AVAILABILITY_BOARD,
        //   Icon: AvailabilityBoardIcon
        // },
        // {
        //   key: BROKER_MENU_PAGE,
        //   title: 'Menus',
        //   onClick: () => history.push(BROKER_MENU_PAGE),
        //   visible: true
        //   hasSubMenus: false,
        //   route: BROKER_MENU_PAGE
        // },
        {
          key: BROKER_DOCUMENTS_PAGE,
          title: 'Marketing Documents',
          onClick: () => history.push(BROKER_DOCUMENTS_PAGE),
          visible: true,
          hasSubMenus: false,
          route: BROKER_DOCUMENTS_PAGE,
          Icon: MarketingDocumentsIcon
        },
        {
          key: BROKER_LANDING_PAGE,
          title: 'Landing Page',
          onClick: () => history.push(BROKER_LANDING_PAGE),
          visible: true,
          hasSubMenus: false,
          route: BROKER_LANDING_PAGE,
          Icon: LandingPageIcon
        }
      ]
    }
  ]

  return (
    <div className='flex flex-col h-full'>
      {confirmLogoutModal()}
      <div
        className={`h-full flex flex-col overflow-y-auto bg-gray-1 ${isMobile ? 'w-full' : 'w-70.25'
          } ml-0 p-6 relative transition-all ease-in-out ${showMenu && !isMobile ? 'border-r-2 border-black' : ''
          }`}
      >
        <CollapseSideBarButton />
        <h1 className='text-softBlack text-2xl font-bold font-sans mb-5'>
          {selectedProject?.projectName}
        </h1>
        <div className='mt-8'>
          {SIDE_BAR_MENU.map((_section, index) => (
            <div key={index}>
              <div key={index} className='flex flex-col gap-1'>
                {_section?.menu
                  ?.filter((menu) => menu.visible)
                  ?.map?.((_menu, menuIndex) => {
                    if (!_menu) {
                      return null
                    }
                    return (
                      <MenuItem menu={_menu} key={menuIndex} selectedItem={selectedMenuId} setSelectedItem={(_id, manualAction) => {
                        setSelectedMenuId(_id)
                        _menu?.onClick?.()
                        if (manualAction) {
                          setShowMenu()
                        }
                      }} />
                    )
                  })}
              </div>
            </div>
          ))}
        </div>
        <div className='flex-1 flex w-full items-end'>
          <div className='w-full'>
            <div className='my-6 h-px w-full bg-softBlack30' />
            <div
              className='flex w-full h-8 justify-around cursor-pointer'
              onClick={() => setShowLogoutModal(true)}
            >
              <span className={`font-sans text-lg whitespace-nowrap font-medium truncate text-softBlack`}>Logout</span>
              <div style={{ width: 28, height: 28 }}>
                <TLogoutIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BrokerMenus
