import React from 'react'
import { TFullFilledRightArrowIcon } from 'components/Icons'

const menuIcon = ({ Icon, selected }) => (
<Icon className={`
  ${selected
    ? 'text-softBlack'
    : 'text-softBlack_70 group-hover:text-gray-500 hover:text-gray-900'
  } mr-3.5 h-6 w-6`}
/>
)

const SubMenuItem = ({ title, onClick, selected }) => (
  <button
    onClick={onClick}
    className={`group w-full flex items-center text-left pl-11 pr-2 py-2 text-base font-medium rounded-lg
    ${selected
      ? 'bg-menuGray text-softBlack'
      : 'bg-transparent text-softBlack_70 hover:bg-menuGray hover:text-softBlack'
    }
    focus:outline-none focus:ring-2 focus:ring-indigo-500 filter-none-important spacing-[-0.24px]`}
  >
    {title}
  </button>
)

export const MenuItem = ({
  id, title, icon, onClick, hasSubMenus,
  subMenus, selectedMenuId, expandedMenus,
  expanded, setSelectedMenuId, setSelectedSubMenuId, manageExpandedMenus,
  executeAfterSelect, selectedSubMenuId
}) => {
  const selected = selectedMenuId === id
  return (
    <div className='space-y-1'>
      <button
        onClick={() => {
          if (!hasSubMenus) {
            setSelectedMenuId(id)
            setSelectedSubMenuId('')
          } else {
            manageExpandedMenus(id, expandedMenus)
          }

          if (onClick) {
            onClick()

            if (executeAfterSelect) {
              executeAfterSelect()
            }
          }
        }}
        className={`group w-full flex items-center text-left pl-2.5 pr-2.5 py-2 text-base font-medium rounded-md
        ${selected
          ? 'bg-menuGray text-softBlack'
          : 'bg-transparent text-softBlack_70 hover:bg-menuGray hover:text-softBlack'
        }
        focus:outline-none focus:ring-2 focus:ring-indigo-500 filter-none-important -spacing-1.5`}
     >
        {menuIcon({ Icon: icon, selected })}
        {title}
        {
          hasSubMenus && (
            <TFullFilledRightArrowIcon
              className={`
              ${selected
                ? 'text-gray-400'
                : 'text-gray-300'
              }
              ${expanded && 'rotate-90'}
              ml-auto h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150`}
            />
          )
        }
      </button>
      {
        expanded && hasSubMenus && (
          <div className='space-y-1'>
            {subMenus.map(menu => {
              const { key, title, onClick } = menu

              return (
                <SubMenuItem
                  key={key}
                  title={title}
                  onClick={() => {
                    // Set parent as menu selected
                    setSelectedMenuId(id)

                    // Set selected subMenu
                    setSelectedSubMenuId(key)

                    onClick()

                    if (executeAfterSelect) {
                      executeAfterSelect()
                    }
                  }}
                  selected={selectedSubMenuId === key}
                />
              )
            })}
          </div>
        )
      }
    </div>
  )
}
