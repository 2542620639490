import React from 'react'
import propTypes from 'prop-types'
import { TButton } from 'ui'

const buttonStyle = {
}

const NavigateButtons = (props) => {
  const { setSchedulerModal, setSchedulerLinksModal } = props
  return (
    <div className="space-x-3">
      <TButton className='!bg-[#18181B] hover:!bg-[#18181B]' onClick={() => props.onNavigate('PREV')} {...buttonStyle}>
        Back
      </TButton>
      <TButton className='!bg-[#18181B] hover:!bg-[#18181B]' onClick={() => props.onNavigate('TODAY')} {...buttonStyle}>
        Today
      </TButton>
      <TButton className='!bg-[#18181B] hover:!bg-[#18181B]' onClick={() => props.onNavigate('NEXT')} {...buttonStyle}>
        Next
      </TButton>
      <TButton
        className='!bg-[#18181B] hover:!bg-[#18181B]'
        onClick={() => {
          setSchedulerModal(true)
        }}
        {...buttonStyle}
      >
        Scheduler Settings
      </TButton>
      <TButton
        className='!bg-[#18181B] hover:!bg-[#18181B]'
        onClick={() => {
          setSchedulerLinksModal(true)
        }}
        {...buttonStyle}
      >
        Meeting Links
      </TButton>
    </div>
  )
}

const SelectedLabel = (props) => (
  <span className="flex-grow px-2.5 text-center text-2xl">{props.label}</span>
)

const PeriodFormat = (props) => (
  <div className="space-x-3">
    <TButton className='!bg-[#18181B] hover:!bg-[#18181B]' onClick={() => props.onView('month')} {...buttonStyle}>
      Month
    </TButton>
    <TButton className='!bg-[#18181B] hover:!bg-[#18181B]' onClick={() => props.onView('week')} {...buttonStyle}>
      Week
    </TButton>
    <TButton className='!bg-[#18181B] hover:!bg-[#18181B]' onClick={() => props.onView('day')} {...buttonStyle}>
      Day
    </TButton>
    <TButton className='!bg-[#18181B] hover:!bg-[#18181B]' onClick={() => props.onView('agenda')} {...buttonStyle}>
      Agenda
    </TButton>
  </div>
)

const CalendarToolbar = (props) => (
  <div className="flex flex-wrap justify-center items-center m-2">
    <NavigateButtons {...props} />
    <SelectedLabel {...props} />
    <PeriodFormat {...props} />
  </div>
)

export default CalendarToolbar

CalendarToolbar.propTypes = {}

SelectedLabel.propTypes = {
  label: propTypes.string
}

NavigateButtons.propTypes = {
  onNavigate: propTypes.func
}

PeriodFormat.propTypes = {
  onView: propTypes.func
}
