import React, { useContext } from 'react'
import { CreateBrokerOpportunityContext } from './context/CreateBrokerOpportunityContext'
import CreatePage from './CreatePage/CreatePage'
import SelectBuyer from './SelectBuyer/SelectBuyer'
import { STEP } from './steps'

const CreateOpportunityWrapper = () => {
  const { step } = useContext(CreateBrokerOpportunityContext)

  return (
    <div className='px-14 py-4'>
      {
        step === STEP.ONE ? <SelectBuyer /> : <CreatePage />
      }
    </div>
  )
}

export default CreateOpportunityWrapper
