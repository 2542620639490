import React, { useState } from 'react'
import InventoryContext from '../Context/InventoryContext'

import LargeUnitCard from 'components/LargeUnitCard'
import SelectUnit from 'pages/offer/components/SelectUnit'
import CustomButton from 'components/CustomButton'

const Inventory = ({ setStep, value, onNextPage }) => {
  const [selectedUnits, setSelectedUnits] = useState([])
  const onBack = () => setStep((prev) => prev - 1)
  const onNext = () => {
    onNextPage(selectedUnits)
    setStep((prev) => prev + 1)
  }

  const getUnit = (unit) => {
    setSelectedUnits((prevState) => {
      let unitFound = false
      for (let i = 0; i < prevState.length; i++) {
        const unit2 = prevState[i]
        if (unit2.id === unit.id) {
          unitFound = true
          break
        }
      }

      if (!unitFound && unit.status === 'Open') {
        return [...prevState, unit]
      }
      return prevState.filter((unit2) => unit2.id !== unit.id)
    })
  }

  const isSelected = Object.values(value).length > 0
  return (
    <div>
      {/* <SelectUnit onSelect={onSelect} /> */}
      <div className='pb-4 border-b-2 border-softBlack mb-4 flex justify-start content-start'>
        <p className='my-0 text-xl font-bold'>Inventory</p>
      </div>
      <p className='mb-10'>Select Inventory</p>
      {!isSelected && (
        <InventoryContext.Provider value={selectedUnits}>
          <SelectUnit isAllocateInventory={true} onSelect={getUnit} />
        </InventoryContext.Provider>
      )}
      {isSelected && (
        <>
          <LargeUnitCard
            imgsrc={value?.floorPlan?.image}
            unitName={`#${value?.unitNumber}`}
            noOfBedrooms={value?.numberOfBedrooms}
            noOfBathrooms={value?.numberOfBathrooms}
            balcony={value?.balcony}
            noOfParkingSlot={value?.floorPlan?.parkingQty}
            building={value?.building?.name}
            price={value?.price}
            sizeInSquareFeet={undefined}
            address={undefined}
            pricePerSq={undefined}
            tag={undefined}
          />
        </>
      )}
      <div className='flex justify-between mt-32'>
        <button
          className='py-3 px-6 text-base border border-softBlack text-softBlack rounded font-medium'
          onClick={onBack}
        >
          Back
        </button>
        <CustomButton
          handleClick={onNext}
          disabled={selectedUnits.length === 0}
        >
          <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
            Next
          </span>
        </CustomButton>
      </div>
    </div>
  )
}

export default Inventory
