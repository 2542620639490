export const data = [
  {
    unit: 'UNIT#11',
    building: 'Pacifica Tower 1',
    amount: '14459100',
    link: 'https://www.google.com'
  },
  {
    unit: 'UNIT#23',
    building: 'Pacifica Tower 2',
    amount: '32922100',
    link: 'https://www.google.com'
  },
  {
    unit: 'UNIT#43',
    building: 'Pacifica Tower 2',
    amount: '12452100',
    link: 'https://www.google.com'
  },
  {
    unit: 'UNIT#125',
    building: 'Pacifica Tower 2',
    amount: '12230230',
    link: 'https://www.google.com'
  },
  {
    unit: 'UNIT#189',
    building: 'Pacifica Tower 3',
    amount: '45228900',
    link: 'https://www.google.com'
  },
  {
    unit: 'UNIT#232',
    building: 'Pacifica Tower 3',
    amount: '83293200',
    link: 'https://www.google.com'
  },
  {
    unit: 'UNIT#332',
    building: 'Pacifica Tower 3',
    amount: '32454000',
    link: 'https://www.google.com'
  },
  {
    unit: 'UNIT#59',
    building: 'Pacifica Tower 4',
    amount: '4552800',
    link: 'https://www.google.com'
  },
  {
    unit: 'UNIT#32',
    building: 'Pacifica Tower 4',
    amount: '19472100',
    link: 'https://www.google.com'
  }
]

export const recentSales = [
  {
    unit: 'UNIT#32',
    building: 'Pacifica Tower 2',
    amount: '8452100',
    broker: {
      image:
        'https://media.istockphoto.com/photos/smiling-indian-man-looking-at-camera-picture-id1270067126?k=20&m=1270067126&s=612x612&w=0&h=ZMo10u07vCX6EWJbVp27c7jnnXM2z-VXLd-4maGePqc=',
      name: 'Omar Bator'
    },
    link: 'www.google.com'
  },
  {
    unit: 'UNIT#13',
    building: 'Pacifica Tower 2',
    amount: '32152100',
    broker: {
      image:
        'https://img.freepik.com/free-photo/young-handsome-man-with-beard-isolated-keeping-arms-crossed-frontal-position_1368-132662.jpg?w=2000',
      name: 'Charlie Press'
    },
    link: 'www.google.com'
  },
  {
    unit: 'UNIT#59',
    building: 'Pacifica Tower 1',
    amount: '12212100',
    broker: {
      image:
        'https://thumbs.dreamstime.com/b/handsome-man-hair-style-beard-beauty-face-portrait-fashion-male-model-black-hair-high-resolution-handsome-man-125031765.jpg',
      name: 'Wilson Philips'
    },
    link: 'www.google.com'
  },
  {
    unit: 'UNIT#189',
    building: 'Pacifica Tower 4',
    amount: '12452100',
    broker: {
      image:
        'https://media.istockphoto.com/photos/smiling-indian-man-looking-at-camera-picture-id1270067126?k=20&m=1270067126&s=612x612&w=0&h=ZMo10u07vCX6EWJbVp27c7jnnXM2z-VXLd-4maGePqc=',
      name: 'Madelyn Passaquindici Arcand'
    },
    link: 'www.google.com'
  },
  {
    unit: 'UNIT#11',
    building: 'Pacifica Tower 2',
    amount: '19459900',
    broker: {
      image:
        'https://media.istockphoto.com/photos/smiling-indian-man-looking-at-camera-picture-id1270067126?k=20&m=1270067126&s=612x612&w=0&h=ZMo10u07vCX6EWJbVp27c7jnnXM2z-VXLd-4maGePqc=',
      name: 'Justin Culhane'
    },
    link: 'www.google.com'
  },
  {
    unit: 'UNIT#11',
    building: 'Pacifica Tower 3',
    amount: '92452100',
    broker: {
      image:
        'https://media.istockphoto.com/photos/smiling-indian-man-looking-at-camera-picture-id1270067126?k=20&m=1270067126&s=612x612&w=0&h=ZMo10u07vCX6EWJbVp27c7jnnXM2z-VXLd-4maGePqc=',
      name: 'John Doe'
    },
    link: 'www.google.com'
  },
  {
    unit: 'UNIT#282',
    building: 'Pacifica Tower 1',
    amount: '62454100',
    broker: {
      image:
        'https://media.istockphoto.com/photos/smiling-indian-man-looking-at-camera-picture-id1270067126?k=20&m=1270067126&s=612x612&w=0&h=ZMo10u07vCX6EWJbVp27c7jnnXM2z-VXLd-4maGePqc=',
      name: 'John Doe'
    },
    link: 'www.google.com'
  },
  {
    unit: 'UNIT#119',
    building: 'Pacifica Tower 1',
    amount: '89252100',
    broker: {
      image:
        'https://media.istockphoto.com/photos/smiling-indian-man-looking-at-camera-picture-id1270067126?k=20&m=1270067126&s=612x612&w=0&h=ZMo10u07vCX6EWJbVp27c7jnnXM2z-VXLd-4maGePqc=',
      name: 'John Doe'
    },
    link: 'www.google.com'
  },
  {
    unit: 'UNIT#123',
    building: 'Pacifica Tower 1',
    amount: '10452100',
    broker: {
      image:
        'https://media.istockphoto.com/photos/smiling-indian-man-looking-at-camera-picture-id1270067126?k=20&m=1270067126&s=612x612&w=0&h=ZMo10u07vCX6EWJbVp27c7jnnXM2z-VXLd-4maGePqc=',
      name: 'John Doe'
    },
    link: 'www.google.com'
  },
  {
    unit: 'UNIT#121',
    building: 'Pacifica Tower 1',
    amount: '12252100',
    broker: {
      image:
        'https://media.istockphoto.com/photos/smiling-indian-man-looking-at-camera-picture-id1270067126?k=20&m=1270067126&s=612x612&w=0&h=ZMo10u07vCX6EWJbVp27c7jnnXM2z-VXLd-4maGePqc=',
      name: 'John Doe'
    },
    link: 'www.google.com'
  },
  {
    unit: 'UNIT#172',
    building: 'Pacifica Tower 1',
    amount: '12052100',
    broker: {
      image:
        'https://media.istockphoto.com/photos/smiling-indian-man-looking-at-camera-picture-id1270067126?k=20&m=1270067126&s=612x612&w=0&h=ZMo10u07vCX6EWJbVp27c7jnnXM2z-VXLd-4maGePqc=',
      name: 'John Doe'
    },
    link: 'www.google.com'
  },
  {
    unit: 'UNIT#122',
    building: 'Pacifica Tower 1',
    amount: '12459100',
    broker: {
      image:
        'https://media.istockphoto.com/photos/smiling-indian-man-looking-at-camera-picture-id1270067126?k=20&m=1270067126&s=612x612&w=0&h=ZMo10u07vCX6EWJbVp27c7jnnXM2z-VXLd-4maGePqc=',
      name: 'John Doe'
    },
    link: 'www.google.com'
  }
]

export const topAllocations = [
  {
    title: 'South Penthouses',
    brokers: [
      'https://media.istockphoto.com/photos/smiling-indian-man-looking-at-camera-picture-id1270067126?k=20&m=1270067126&s=612x612&w=0&h=ZMo10u07vCX6EWJbVp27c7jnnXM2z-VXLd-4maGePqc=',
      'https://media.istockphoto.com/photos/smiling-indian-man-looking-at-camera-picture-id1270067126?k=20&m=1270067126&s=612x612&w=0&h=ZMo10u07vCX6EWJbVp27c7jnnXM2z-VXLd-4maGePqc=',
      'https://media.istockphoto.com/photos/smiling-indian-man-looking-at-camera-picture-id1270067126?k=20&m=1270067126&s=612x612&w=0&h=ZMo10u07vCX6EWJbVp27c7jnnXM2z-VXLd-4maGePqc='
    ],
    percentageSold: 75,
    expirationDate: Date.now(),
    link: 'www.google.com'
  },
  {
    title: 'South Penthouses',
    brokers: [
      'https://media.istockphoto.com/photos/smiling-indian-man-looking-at-camera-picture-id1270067126?k=20&m=1270067126&s=612x612&w=0&h=ZMo10u07vCX6EWJbVp27c7jnnXM2z-VXLd-4maGePqc=',
      'https://media.istockphoto.com/photos/smiling-indian-man-looking-at-camera-picture-id1270067126?k=20&m=1270067126&s=612x612&w=0&h=ZMo10u07vCX6EWJbVp27c7jnnXM2z-VXLd-4maGePqc=',
      'https://media.istockphoto.com/photos/smiling-indian-man-looking-at-camera-picture-id1270067126?k=20&m=1270067126&s=612x612&w=0&h=ZMo10u07vCX6EWJbVp27c7jnnXM2z-VXLd-4maGePqc=',
      'https://media.istockphoto.com/photos/smiling-indian-man-looking-at-camera-picture-id1270067126?k=20&m=1270067126&s=612x612&w=0&h=ZMo10u07vCX6EWJbVp27c7jnnXM2z-VXLd-4maGePqc=',
      'https://media.istockphoto.com/photos/smiling-indian-man-looking-at-camera-picture-id1270067126?k=20&m=1270067126&s=612x612&w=0&h=ZMo10u07vCX6EWJbVp27c7jnnXM2z-VXLd-4maGePqc='
    ],
    percentageSold: 75,
    expirationDate: Date.now(),
    link: 'www.google.com'
  },
  {
    title: 'South Penthouses',
    brokers: [
      'https://media.istockphoto.com/photos/smiling-indian-man-looking-at-camera-picture-id1270067126?k=20&m=1270067126&s=612x612&w=0&h=ZMo10u07vCX6EWJbVp27c7jnnXM2z-VXLd-4maGePqc=',
      'https://media.istockphoto.com/photos/smiling-indian-man-looking-at-camera-picture-id1270067126?k=20&m=1270067126&s=612x612&w=0&h=ZMo10u07vCX6EWJbVp27c7jnnXM2z-VXLd-4maGePqc=',
      'https://media.istockphoto.com/photos/smiling-indian-man-looking-at-camera-picture-id1270067126?k=20&m=1270067126&s=612x612&w=0&h=ZMo10u07vCX6EWJbVp27c7jnnXM2z-VXLd-4maGePqc=',
      'https://media.istockphoto.com/photos/smiling-indian-man-looking-at-camera-picture-id1270067126?k=20&m=1270067126&s=612x612&w=0&h=ZMo10u07vCX6EWJbVp27c7jnnXM2z-VXLd-4maGePqc=',
      'https://media.istockphoto.com/photos/smiling-indian-man-looking-at-camera-picture-id1270067126?k=20&m=1270067126&s=612x612&w=0&h=ZMo10u07vCX6EWJbVp27c7jnnXM2z-VXLd-4maGePqc=',
      'https://media.istockphoto.com/photos/smiling-indian-man-looking-at-camera-picture-id1270067126?k=20&m=1270067126&s=612x612&w=0&h=ZMo10u07vCX6EWJbVp27c7jnnXM2z-VXLd-4maGePqc=',
      'https://media.istockphoto.com/photos/smiling-indian-man-looking-at-camera-picture-id1270067126?k=20&m=1270067126&s=612x612&w=0&h=ZMo10u07vCX6EWJbVp27c7jnnXM2z-VXLd-4maGePqc='
    ],
    percentageSold: 20,
    expirationDate: Date.now(),
    link: 'www.google.com'
  },
  {
    title: 'South Penthouses',
    brokers: [
      'https://media.istockphoto.com/photos/smiling-indian-man-looking-at-camera-picture-id1270067126?k=20&m=1270067126&s=612x612&w=0&h=ZMo10u07vCX6EWJbVp27c7jnnXM2z-VXLd-4maGePqc='
    ],
    percentageSold: 23,
    expirationDate: Date.now(),
    link: 'www.google.com'
  },
  {
    title: 'South Penthouses',
    brokers: [
      'https://media.istockphoto.com/photos/smiling-indian-man-looking-at-camera-picture-id1270067126?k=20&m=1270067126&s=612x612&w=0&h=ZMo10u07vCX6EWJbVp27c7jnnXM2z-VXLd-4maGePqc=',
      'https://media.istockphoto.com/photos/smiling-indian-man-looking-at-camera-picture-id1270067126?k=20&m=1270067126&s=612x612&w=0&h=ZMo10u07vCX6EWJbVp27c7jnnXM2z-VXLd-4maGePqc=',
      'https://media.istockphoto.com/photos/smiling-indian-man-looking-at-camera-picture-id1270067126?k=20&m=1270067126&s=612x612&w=0&h=ZMo10u07vCX6EWJbVp27c7jnnXM2z-VXLd-4maGePqc='
    ],
    percentageSold: 97,
    expirationDate: Date.now(),
    link: 'www.google.com'
  },
  {
    title: 'South Penthouses',
    brokers: [
      'https://media.istockphoto.com/photos/smiling-indian-man-looking-at-camera-picture-id1270067126?k=20&m=1270067126&s=612x612&w=0&h=ZMo10u07vCX6EWJbVp27c7jnnXM2z-VXLd-4maGePqc=',
      'https://media.istockphoto.com/photos/smiling-indian-man-looking-at-camera-picture-id1270067126?k=20&m=1270067126&s=612x612&w=0&h=ZMo10u07vCX6EWJbVp27c7jnnXM2z-VXLd-4maGePqc=',
      'https://media.istockphoto.com/photos/smiling-indian-man-looking-at-camera-picture-id1270067126?k=20&m=1270067126&s=612x612&w=0&h=ZMo10u07vCX6EWJbVp27c7jnnXM2z-VXLd-4maGePqc=',
      'https://media.istockphoto.com/photos/smiling-indian-man-looking-at-camera-picture-id1270067126?k=20&m=1270067126&s=612x612&w=0&h=ZMo10u07vCX6EWJbVp27c7jnnXM2z-VXLd-4maGePqc=',
      'https://media.istockphoto.com/photos/smiling-indian-man-looking-at-camera-picture-id1270067126?k=20&m=1270067126&s=612x612&w=0&h=ZMo10u07vCX6EWJbVp27c7jnnXM2z-VXLd-4maGePqc='
    ],
    percentageSold: 69,
    expirationDate: Date.now(),
    link: 'www.google.com'
  }
]

export const mockBrokers = [
  {
    name: 'Wilson Philips',
    company: 'MD Co.',
    amount: '1025000',
    score: 'hot'
  },
  {
    name: 'Justin Culhane',
    company: 'Descon pvt. ltd.',
    amount: '1050400',
    score: 'neutral'
  },
  {
    name: 'Omar Bator',
    company: 'Burj Associates',
    amount: '9241300',
    score: 'neutral'
  },
  {
    name: 'Charlie Press',
    company: 'Roe Estates',
    amount: '4434000',
    score: 'hot'
  },
  {
    name: 'Madelyn Passaquindici Arcand',
    company: 'MK States',
    amount: '1343500',
    score: 'hot'
  }
]

export const upcommingTasksData = [
  {
    name: 'Jason Peterson',
    task: 'Dec 14th | 2pm | Call'
  },
  {
    name: 'Alison Mcittle',
    task: 'Dec 14th | 3pm | Call'
  },
  {
    name: 'Austin Mathews',
    task: 'Dec 14th | 4pm | Call'
  },
  {
    name: 'Kimberly Chan',
    task: 'Dec 14th | Email | Presentation Centre Link'
  },
  {
    name: 'Harry Lee',
    task: 'Dec 14th | Email | Floor Plans'
  },
  {
    name: 'David Joshnson',
    task: 'Dec 14th | 2pm | Call'
  }
]
