// @ts-nocheck
/* eslint-disable */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Calendar as CalendarComponent, Views } from 'react-big-calendar';
import propTypes from 'prop-types';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import localizer from 'react-big-calendar/lib/localizers/globalize';
import globalize from 'globalize';

import 'react-big-calendar/lib/sass/styles.scss';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss';

import CalendarToolbar from './CalendarToolbar';

import { CalendarWrapper } from './styles';
import { connect, useSelector } from 'react-redux';
import {
  createOrUpdateCalendarEvent,
  getCalendarEvents,
} from 'store/actions/usersActions';
import { AnimatePresence } from 'framer-motion';
import {
  FadedAnimatedDiv,
  LoadingIcon,
  StartExternalProviderConfiguration,
} from 'components';
import { message } from 'antd';
import NewMeeting from 'components/NewMeeting';
import useBuyers from 'hooks/useGetBuyers';
import { Modal } from '@material-ui/core';
import {
  CloseIcon,
  NylasSchedulerEditor,
  NylasProvider,
  NylasIdentityRequestWrapper,
  NylasLogin,
} from '@nylas/react';
import CustomButton from 'components/CustomButton';
import CustomModal from 'components/Modal';
import { api } from 'services/api';
import SchedulerMeetings from 'pages/desktopMode/profilePage/SchedulerMeetings';
// import { NylasSessions } from '@nylas/identity/esm';

const DragAndDropCalendar = withDragAndDrop(CalendarComponent);

const globalizeLocalizer = localizer(globalize);

const Calendar = () => {
  // const identity =  new NylasSessions({
  //   clientId: process.env.REACT_APP_NYLAS_CLIENT_ID,
  //   redirectUri: `${window.location.origin}/admin/calendar`,
  //   scopes: ['calendar', 'contacts'],
  //   domain: 'https://api.us.nylas.com/v3',
  // });

  // console.log('identity', identity);

  const userObject = useSelector((state) => state.authReducer.userObject);
  const { buyers, getBuyersData } = useBuyers();
  const [events, setEvents] = useState([]);
  const [draggedEvent, setDraggedEvent] = useState({});
  const [showMeetingModal, setShowMeetingModal] = useState(false);
  const [gettingEvents, setGettingEvents] = useState(false);
  const [gettingSelectedEvent, setGettingSelectedEvent] = useState(false);
  const [editingEvent, setEditingEvent] = useState(null);
  const [schedulerModal, setSchedulerModal] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const appReducer = useSelector((state) => state.appReducer);
  const [schedulerLinksModal, setSchedulerLinksModal] = useState(false);

  useEffect(() => {
    let user = localStorage.getItem('user');
    if (user) {
      setLoggedIn(true);
    }
  }, [schedulerModal]);
  const refEvents = useRef(events);
  const refDraggedEvent = useRef(draggedEvent);

  const updateEvents = (newState) => {
    refEvents.current = newState;
    setEvents(newState);
  };

  const getEvents = useCallback(() => {
    setGettingEvents(true);
    getCalendarEvents()
      .then(({ data }) => {
        const tmpEvents = data.map((event) => ({
          id: event.id,
          title: event.title,
          start: new Date(
            event?.when?.startTime * 1000 || event?.when.startDate
          ).toDateString(),
          end: new Date(
            event?.when?.endTime * 1000 || event?.when.endDate
          ).toDateString(),
          desc: event.description,
        }));
        updateEvents(tmpEvents);
        setGettingEvents(false);
      })
      .catch(() => setGettingEvents(false));
  }, []);

  useEffect(() => {
    if (userObject.providerToken) {
      getEvents();
      getBuyersData();
    }
  }, [getEvents, userObject]);

  const updateDraggedEvent = (newState) => {
    refDraggedEvent.current = newState;
    setDraggedEvent(newState);
  };

  const moveEvent = useCallback(
    ({ event, start, end, isAllDay: droppedOnAllDaySlot }) => {
      message.loading('Updating event...');

      const updatedEvent = Object.assign({}, event, {
        _id: event.id,
        notify_participants: true,
        when: { start_time: start, end_time: end },
      });

      createOrUpdateCalendarEvent(updatedEvent);

      const events = refEvents.current;

      let allDay = event.allDay;

      if (!event.allDay && droppedOnAllDaySlot) {
        allDay = true;
      } else if (event.allDay && !droppedOnAllDaySlot) {
        allDay = false;
      }

      const nextEvents = events.map((existingEvent) => {
        return existingEvent.id === event.id
          ? { ...existingEvent, start, end, allDay }
          : existingEvent;
      });

      updateEvents(nextEvents);
    },
    []
  );

  const resizeEvent = useCallback(({ event, start, end }) => {
    message.loading('Updating event...');

    const updatedEvent = Object.assign({}, event, {
      _id: event.id,
      notify_participants: true,
      when: { start_time: start, end_time: end },
    });

    createOrUpdateCalendarEvent(updatedEvent);

    const events = refEvents.current;

    const nextEvents = events.map((existingEvent) => {
      return existingEvent.id === event.id
        ? { ...existingEvent, start, end }
        : existingEvent;
    });

    updateEvents(nextEvents);
  }, []);

  const newEvent = useCallback(
    (event) => {
      const { slots } = event;
      const _emptyEvent = Object.assign({}, event, {
        when: {
          start_time: new Date(slots[0]).getTime() / 1000,
          end_time: new Date(slots[slots.length - 1]).getTime() / 1000,
          object: 'timespan',
        },
      });

      if (!showMeetingModal) {
        setEditingEvent(_emptyEvent);
        setShowMeetingModal(true);
      }
    },
    [showMeetingModal]
  );

  const handleDragStart = useCallback((event) => {
    updateDraggedEvent(event);
  }, []);

  const executeAfterSaveDeleteCalendarEvent = useCallback(() => {
    setShowMeetingModal(false);
    setEditingEvent(null);

    getEvents();
  }, [getEvents]);

  const openEvent = useCallback((_event) => {
    setGettingSelectedEvent(true);
    getCalendarEvents(_event.id)
      .then(({ data }) => {
        setEditingEvent({ ...data, _id: data?.id });
        setShowMeetingModal(true);
      })
      .catch(() => setShowMeetingModal(false))
      .finally(() => setGettingSelectedEvent(false));
  }, []);

  useEffect(() => {
    let params = new URLSearchParams(window.location.search);
    if (params.get('code')) {
      setSchedulerModal(true);
    }
  }, []);

  const [projectLogo, setProjectLogo] = useState('');

  useEffect(() => {
    const list = appReducer?.listOfProjects;
    const selectedProjectId = appReducer?.appProject;

    if (!list || !selectedProjectId) return;

    const activeProject = list.find(
      (project) => project._id === selectedProjectId
    );
    setProjectLogo(activeProject?.logoImage);
  }, [appReducer]);

  return (
    <CalendarWrapper className='h-full w-full relative'>
      {Object.keys(userObject).length && !userObject.providerToken ? (
        <StartExternalProviderConfiguration pageName='Calendar' />
      ) : (
        <>
          <AnimatePresence>
            {(gettingEvents || gettingSelectedEvent) && (
              <FadedAnimatedDiv className='absolute top-1/3 left-2/4 z-10 shadow-xl rounded-full bg-white'>
                <LoadingIcon className='h-22 w-22 text-blue-400 p-1' />
              </FadedAnimatedDiv>
            )}
          </AnimatePresence>
          <NewMeeting
            isOpen={showMeetingModal}
            setOpen={(value) => {
              setShowMeetingModal(value);
            }}
            contact={{ ...userObject }}
            meeting={editingEvent}
            onSuccess={executeAfterSaveDeleteCalendarEvent}
            buyers={buyers}
            canDelete={true}
          />
          <DragAndDropCalendar
            selectable
            localizer={globalizeLocalizer}
            events={events}
            onEventDrop={moveEvent}
            resizable
            onEventResize={resizeEvent}
            onSelectSlot={newEvent}
            defaultView={Views.MONTH}
            defaultDate={new Date()}
            popup={true}
            handleDragStart={handleDragStart}
            // @ts-ignore
            components={{
              toolbar: (props) => (
                <CalendarToolbar
                  schedulerLinksModal={schedulerLinksModal}
                  setSchedulerLinksModal={setSchedulerLinksModal}
                  schedulerModal={schedulerModal}
                  setSchedulerModal={setSchedulerModal}
                />
              ),
            }}
            onSelectEvent={openEvent}
          />
        </>
      )}
      {schedulerModal && (
        <CustomModal
          customStyles={{
            backgroundColor: '#fcfcfd',
            display: 'flex',
            flexDirection: 'column',
          }}
          isOpen={schedulerModal}
          closeModal={() => setSchedulerModal(false)}
        >
          <div className='flex flex-col w-full h-full pb-16 gap-y-4 '>
            <NylasSchedulerEditor
              style={{
                boxShadow: 'none',
                border: '1px solid #E5E5E5',
              }}
              onSchedulerConfigCloseClicked={(e) => {
                e.preventDefault();
                setSchedulerModal(false);
              }}
              schedulerPreviewLink={`https://book.nylas.com/us/${process.env.REACT_APP_NYLAS_CLIENT_ID}/{slug}`}
              nylasSessionsConfig={{
                clientId: process.env.REACT_APP_NYLAS_CLIENT_ID,
                redirectUri: `${window.location.origin}/admin/calendar`,
                domain: 'https://api.us.nylas.com/v3',
                accessType: 'offline',
                hosted: true,
              }}
              requiresSlug={true}
              defaultSchedulerConfigState={{
                selectedConfiguration: {
                  requires_session_auth: false,
                  scheduler: {
                    rescheduling_url: `https://book.nylas.com/us/reschedule/:booking_ref`,
                    cancellation_url: `https://book.nylas.com/us/cancel/:booking_ref`,
                  },
                  appearance: {
                    company_logo_url: projectLogo,
                  },
                },
              }}
            />
          </div>
          {loggedIn && (
            <div className='px-8 pb-8 self-end'>
              <CustomButton
                handleClick={() => {
                  localStorage.removeItem('user');
                  setSchedulerModal(false);
                }}
                variant='default'
                className='flex self-end'
              >
                <span className='font-medium py-2 px-4 text-base flex gap-2 items-center'>
                  Logout
                </span>
              </CustomButton>
            </div>
          )}
        </CustomModal>
      )}
      {
        schedulerLinksModal && (
          <SchedulerMeetings
          open={schedulerLinksModal}
          handleClose={setSchedulerLinksModal}
          />
        )
      }
    </CalendarWrapper>
  );
};

const mapStateToProps = (state) => ({
  userObject: state.authReducer.userObject,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);

Calendar.propTypes = {
  userObject: propTypes.object,
};
