import React from 'react'
import propTypes from 'prop-types'
import { ContractsGeneratedCounter } from './../styles'

const ContractsCounterAndFilter = ({
  listOfContracts,
  originalListOfContracts,
  onFilter
}) => (
  <ContractsGeneratedCounter className='flex flex-col justify-center mb-0 border-solid border-softBlack border-b-2 border-r-2 p-6 z-10'>
    <span className='text-xl text-center'>
      {`${listOfContracts?.length} 
      ${listOfContracts?.length > 1 ? 'contracts' : 'contract'} 
      ${
        listOfContracts?.length !== originalListOfContracts?.length
          ? 'filtered'
          : 'generated'
      }`}
    </span>
  </ContractsGeneratedCounter>
)

export default React.memo(ContractsCounterAndFilter)

ContractsCounterAndFilter.propTypes = {
  listOfContracts: propTypes.array,
  originalListOfContracts: propTypes.array,
  onFilter: propTypes.func
}
