import React from 'react'
import { motion } from 'framer-motion'
// import propTypes from 'prop-types'

const FadedAnimatedDiv = (props) => {
  const { id, children, style, className } = props
  return (
    <motion.div
      id={id}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={style}
      className={className}
      {...props}
    >
      {children}
    </motion.div>
  )
}

export default FadedAnimatedDiv

FadedAnimatedDiv.defaultProps = {
  style: {}
}

// FadedAnimatedDiv.propTypes = {
//   key: propTypes.bool,
//   children: propTypes.node,
//   style: propTypes.object,
//   className: propTypes.string
// }
