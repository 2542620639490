import React from 'react'

const TransactionOverviewTab = () => {
  return (
    <div className="w-full min-h-full flex justify-center items-center">
      <p className='text-base font-normal text-zinc-500'>There are no items to be shown</p>
    </div>
  )
}

export default TransactionOverviewTab
