import React from 'react'
import useContactContext from 'pages/newContacts/hooks/useContactContext'

const BuyerBrokerFilter = () => {
  const { state, applyBuyerFilter, applyBrokerFilter, applyDefaultFilter } = useContactContext()

  return (
    <div className='w-full flex flex-row'>
        <div onClick={() => applyDefaultFilter()} className={`flex flex-1 justify-center border-2 border-softBlack border-r-0 p-2 cursor-pointer ${state.contactUserType === 'Default' ? 'bg-softBlack text-white' : ''}`}>All</div>
        <div onClick={() => applyBuyerFilter()} className={`flex flex-1 justify-center border-2 border-softBlack border-r-0 p-2 cursor-pointer ${state.contactUserType === 'Buyer' ? 'bg-softBlack text-white' : ''}`}>Buyer</div>
        <div onClick={() => applyBrokerFilter()} className={`flex flex-1 justify-center border-2 border-softBlack p-2 cursor-pointer ${state.contactUserType === 'LeadBroker' ? 'bg-softBlack text-white' : ''}`}>Realtor</div>
    </div>
  )
}

export default BuyerBrokerFilter
