import { useState } from 'react'
import { postLeadData } from 'store/actions/leadsPresaleActions'

const useHiddenHillFormSubmission = () => {
  const [loading, setLoading] = useState(false)

  const postData = async (data) => {
    setLoading(true)
    try {
      const result = await postLeadData(data)
      return result
    } catch (error) {
      console.error(error)
      throw error
    } finally {
      setLoading(false)
    }
  }

  return { postData, loading }
}

export default useHiddenHillFormSubmission
