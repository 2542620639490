import React, { useMemo } from 'react'

import InventoryListViewWrapper from '../InventoryListViewWrapper'
import useGetParkings from 'hooks/useGetParkings'
import { groupBy } from 'components/Inventory'

const ParkingsInventoryListView = ({ projectId, buildingId }) => {
  const { loading, parkings, refetch } = useGetParkings()

  const filteredParkings = useMemo(() => {
    const parkingsData = parkings.filter(elm => elm.building === buildingId)

    const uCards = [...parkingsData]
    const grouped = groupBy(uCards, (inventoryItem) => inventoryItem.floorNumber)
    // @ts-ignore
    const groupedKeyValuePair = {}
    grouped.forEach((value, key) => {
      return (groupedKeyValuePair[key] = value)
    })
    return ({ ...groupedKeyValuePair })
  }, [parkings, buildingId])

  return (
    <InventoryListViewWrapper
      inventoryItems={filteredParkings}
      loading={loading}
      type='Parking'
      refetch={refetch}
    />
  )
}

export default ParkingsInventoryListView
