/* eslint-disable */
// @ts-nocheck
import React, { useContext, useEffect } from 'react';
import Loader from '../Loader';
import TableBody from './TableBody';
import TableHead from './TableHead';

const Table = ({ setIsEdit, setInitialValues, data, deleteModal, setDeleteModal }) => {
  return (
    <div className='w-full mt-8 flex flex-col'>
      <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
        <div
          style={{ paddingLeft: '2.7rem', paddingRight: '1.9rem' }}
          className='inline-block min-w-full py-2 align-middle'
        >
          <div className='relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
            <table className='min-w-full table-fixed divide-y divide-gray-300'>
              <TableHead />
              <TableBody data={data} setIsEdit={setIsEdit} setInitialValues={setInitialValues} deleteModal={deleteModal}  setDeleteModal={setDeleteModal} />
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
