// @ts-nocheck
import propTypes from 'prop-types'
import { Col, Row } from 'antd'
import styled from 'styled-components'
import standardStyle from 'assets/css/standardStyle'
import { motion } from 'framer-motion'

export const ContractsWrapper = styled(Row)`
  height: 100%;
`

export const ContractNumberWrapper = styled.span`
  font-weight: 700;
  padding-right: 11px;
`
export const ContractSignatureStatusWrapper = styled.div`
  padding: 0px 12px;
  width: 240px;
  color: ${standardStyle.colors.softBlack};
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  line-height: 27px;
  font-family: ${standardStyle.fonts.openSans};
`
export const ContractThumbnailDocumentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const ContractThumbnailWrapper = styled(motion.div)`
  border-bottom: 0.2px solid silver;
  padding: 20px 15px;
  position: relative;
  overflow: hidden;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: ${standardStyle.colors.mainBackground};
  :hover {
    cursor: pointer;
  }
`

export const ContractWrapper = styled.div`
  padding-bottom: 4px;
  overflow-y: auto;
  overflow-x: auto;
  height: 100%;
  width: 100%;
`

export const ContractsCarouselWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px 10px 30px;
`

export const ContractsGeneratedCounter = styled.div``

export const DocumentPagination = styled(Row)`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`

export const LeftWrapper = styled(Col)`
  display: flex;
  flex-direction: column;
  height: 100%;
`
export const RightWrapper = styled.div`
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-bottom: 30px;
`
export const DocumentBtnWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  gap: 10px;
`
export const PreviewHeader = styled.h3`
  padding: 22.3px;
  font-size: 20px;
  font-weight: 700;
  font-family: ${standardStyle.fonts.openSans};
  border-bottom: 2px solid ${standardStyle.colors.softBlack};
  display: block;
  width: 100%;
`
export const ListOfContractsWrapper = styled.div`
  overflow-y: auto;
  height: 100%;
  border-right: 2px solid ${standardStyle.colors.softBlack};
  z-index: 5;
  border-left: 0px;
  border-top: 0px;
`

export const PageControlWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .pageControlIcon {
    font-size: 50px;
  }

  .pageControlLeftIcon {
    opacity: ${(props) => (props.pdfPageNumber === 1 ? 0.4 : 1)};
    margin-right: 10px;
    :hover {
      cursor: ${(props) => (props.pdfPageNumber === 1 ? 'normal' : 'pointer')};
    }
  }

  .pageControlRightIcon {
    opacity: ${(props) =>
      props.pdfPageNumber === props.pdfNumPages ? 0.4 : 1};
    margin-left: 10px;
    :hover {
      cursor: ${(props) =>
        props.pdfPageNumber === props.pdfNumPages ? 'normal' : 'pointer'};
    }
  }
`

export const SelectedContractTitleWrapper = styled.div`
  padding: 5px 30px 15px 30px;
`

export const ZoomControlWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: ${standardStyle.colors.primary};
  color: white;
  margin: 4px 4px 4px 4px;
`

export const ZoomIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px 10px 0px 10px;

  :hover {
    cursor: pointer;
  }
`

ContractsGeneratedCounter.propTypes = {
  children: propTypes.node
}
