import { Row } from 'antd'
import styled from 'styled-components'

export const BuyerButtonsWrapper = styled(Row)`
  width: 100%;
  height: 15%;
  align-items: center;
  /* position: sticky; */
  /* position: -webkit-sticky; */
  padding: 6px 0px;
  background: white;
  button {
    margin: 15px;
  }
`
