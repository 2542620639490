/* eslint-disable camelcase */
import React, { useEffect, useState, cloneElement } from 'react'
import propTypes from 'prop-types'
import { CustomPagination } from './styles'
import { Row, Col } from 'antd'

const mapSizeToWidth = {
  xs: 575,
  sm: 767,
  md: 991
}

const getWidth = () => window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth

function useCurrentWidth () {
  // save current window width in the state object
  const [width, setWidth] = useState(getWidth())

  // in this case useEffect will execute only once because
  // it does not have any dependencies.
  useEffect(() => {
    // timeoutId for debounce mechanism
    let timeoutId = null
    const resizeListener = () => {
      // prevent execution of previous setTimeout
      clearTimeout(timeoutId)
      // change width from the state object after 150 milliseconds
      timeoutId = setTimeout(() => setWidth(getWidth()), 150)
    }
    // set resize listener
    window.addEventListener('resize', resizeListener)

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener)
    }
  }, [])

  return width
}

// TableToCard passa as linhas da tabela
// como props.item para cada Card

export const TableToCard = props => {
  const { children, card, cardMaxSize, seeAsCards } = props
  const { dataSource, pagination } = children.props
  const size = mapSizeToWidth[cardMaxSize] || 9999
  const currentWindowSize = useCurrentWidth()
  const isTableVisible = window ? currentWindowSize > size : true
  const defaultPerPage = 10

  const paginate = (array, page_size, page_number) => {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array?.slice((page_number - 1) * page_size, page_number * page_size)
  }

  const Cards = () => {
    const SingleCard = (props) => cloneElement(card, props?.item)
    const currentCards = paginate(dataSource, defaultPerPage, pagination?.current)

    return (
      <div>
        <Row>
          {currentCards?.map?.((item, index) => {
            return (
              <Col key={index} xs={24} sm={24} md={6} lg={6} style={{ padding: 10 }}>
                <SingleCard key={index} item={item} />
              </Col>
            )
          })}
        </Row>
        {dataSource?.length > 0 && !card?.props?.loading && (
          <CustomPagination
            style={{ marginBottom: 15 }}
            defaultCurrent={pagination?.current}
            total={dataSource.length}
            onChange={(page) => {
              pagination?.onChange(page)
            }}
          />
        )}
      </div>
    )
  }

  return (
    <div style={{ width: '100%' }}>
      {((isTableVisible && !seeAsCards) || !Object.keys(card).length) ? children : Cards()}
    </div>
  )
}

TableToCard.propTypes = {
  children: propTypes.node,
  card: propTypes.node,
  cardMaxSize: propTypes.string,
  seeAsCards: propTypes.bool
}
