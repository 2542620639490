import React, {
  useEffect,
  useState,
  createContext,
  useMemo,
  useRef
} from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Table } from 'ui'
import useGetBrokerLeadsById from 'hooks/useGetBrokerLeadsById'
import useGetUsers from 'hooks/useGetUsers'
import { classNames } from 'utils'

const LoadingContext = createContext({})

const ActionButton = ({ onClick, text, value, isBlue = false }) => {
  return (
    <button
      className={classNames(
        'font-medium p-3 text-base text-black uppercase text-center w-32 cursor-pointer',
        isBlue ? 'bg-[#54B5D1]' : 'bg-volt'
      )}
      onClick={onClick}
    >
      {text}
    </button>
  )
}

const TextField = ({ value }) => (
  <span className='font-medium text-base text-softBlack capitalize'>
    {value}
  </span>
)

const MemoTable = ({
  dataSource,
  columns,
  loading,
  idBeingDeleted,
  deleting
}) => {
  return (
    <Table
      className='rounded-lg'
      dataSource={dataSource}
      columns={columns}
      tailwindTable
      loading={loading}
      idBeingDeleted={idBeingDeleted}
      deleting={deleting}
    />
  )
}

const ProjectBrokerLeads = ({ appProject, userObject }) => {
  const mountedRef = useRef(true)
  const [tableDataSource, setTableDataSource] = useState([])
  const [idBeingDeleted] = useState('')
  const [deletingBrokerLead] = useState(false)
  const { brokerLeads, loading } = useGetBrokerLeadsById()
  const { users, isLoading } = useGetUsers()
  const history = useHistory()

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  useEffect(() => {
    if (brokerLeads && users) {
      const updatedBrokerLeads = brokerLeads.map((_leadItem) => {
        return {
          broker: `${_leadItem.leadBroker.firstName} ${_leadItem.leadBroker.lastName}`,
          salesRep: `${_leadItem.salesRep.firstName} ${_leadItem.salesRep.lastName}`,
          _id: _leadItem._id,
          url: _leadItem.url
        }
      })
      setTableDataSource(updatedBrokerLeads)
    }
  }, [brokerLeads, loading, users])

  const tableColumns = useMemo(
    () => [
      {
        Title: 'Broker Name',
        accessor: 'broker',
        dataIndex: 'broker',
        Cell: (props) => <TextField value={props.cell.value} />
      },
      {
        Title: 'Sales Rep',
        accessor: 'salesRep',
        Cell: (props) => <TextField value={props.cell.value} />
      },
      {
        Title: '',
        accessor: 'url',
        Cell: (props) => (
          <ActionButton
            text='Copy Link'
            value={props.cell}
            onClick={() => navigator.clipboard.writeText(props.cell.value)}
            isBlue
          />
        )
      },
      {
        Title: '',
        accessor: '_id',
        Cell: (props) => (
          <ActionButton
            text={'View/Edit'}
            value={props.cell}
            onClick={() =>
              history.push(`/admin/brokerleads/${props.cell.value}`)
            }
          />
        )
      }
    ],
    []
  )

  const values = useMemo(() => ({}), [])

  const isContentLoading = useMemo(
    () => loading || isLoading,
    [loading, isLoading]
  )

  return (
    <LoadingContext.Provider value={values}>
      <div className='h-full w-full flex flex-col items-center'>
        <div className='mt-5 w-full lg:overflow-x-hidden'>
          {isContentLoading && (
            <div className='flex w-full justify-center items-center'>
              loading broker landing pages....
            </div>
          )}
          {!isContentLoading && (
            <MemoTable
              dataSource={tableDataSource}
              columns={tableColumns}
              loading={loading}
              idBeingDeleted={idBeingDeleted}
              deleting={deletingBrokerLead}
            />
          )}
        </div>
      </div>
    </LoadingContext.Provider>
  )
}

const mapStateToProps = (state) => ({
  appProject: state.appReducer.appProject,
  userObject: state.authReducer.userObject
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectBrokerLeads)

ProjectBrokerLeads.propTypes = {
  appProject: propTypes.string,
  userObject: propTypes.shape({
    userType: propTypes.string
  }),
  cell: propTypes.shape({
    value: propTypes.string
  }),
  row: propTypes.shape({
    original: propTypes.shape({
      _id: propTypes.string
    })
  })
}
