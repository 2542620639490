// @ts-nocheck
/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { NylasProvider, NylasSchedulerEditor } from '@nylas/react';

import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { Modal } from '@material-ui/core';

const SchedulerSettings = () => {
  const history = useHistory();
  const buttonStyle = {
    backgroundColor: '#EDDF65',
    height: '45px',
    paddingLeft: '24px',
    paddingRight: '24px',
  };
  // useEffect(() => {
  //   getNylasSchedulerAccessTokenData().then((data) => {
  //     localStorage.setItem('grant', JSON.stringify(data.accessTokenData));
  //     localStorage.setItem('user', JSON.stringify([data.userData.data]));
  //   });
  // }, []);
  return (
    <div className='p-2 flex items-center min-h-[100vh] bg-[rgb(206,206,204)] flex-col gap-y-6 pt-6'>
      <div className='flex justify-between gap-x-10'>
        <h1 className='uppercase text-[32px]'>Scheduler Settings</h1>
      </div>

      <div className='w-fit'>
        <div className='flex justify-end'>
          <button
            className='w-fit self-end mb-6'
            style={buttonStyle}
            onClick={() => {
              history.push('/admin/calendar');
            }}
          >
            Back To Calendar
          </button>
        </div>
        <Modal
          open={true}
          onClose={() => {
            history.push('/admin/calendar');
          }}
        >
          <div className='relative flex flex-col font-openSans bg-grey-8 rounded shadow-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-fit w-fit'>
            <NylasSchedulerEditor
              onSchedulerConfigCloseClicked={(e) => {
                e.preventDefault();
                setSchedulerModal(false);
              }}
              schedulerPreviewLink={`${window.location.origin}/book-meeting?config_id={config.id}`}
              nylasSessionsConfig={{
                clientId: process.env.REACT_APP_NYLAS_CLIENT_ID,
                redirectUri: `${window.location.origin}/scheduler_settings`,
                domain: 'https://api.us.nylas.com/v3',
                accessType: 'offline',
              }}
              defaultSchedulerConfigState={{
                selectedConfiguration: {
                  requires_session_auth: false,
                  /* scheduler: {
                    rescheduling_url:`${window.location.origin}/book-meeting/:booking_ref`,
                  } */
                },
              }}

              // nylasApiRequest={{
              //   request: 'POST',
              // }}
            />
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default SchedulerSettings;
