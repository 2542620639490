import React, { useState } from 'react'
import backgroundImage from '../../../assets/img/buyer-dashboard.png'
import logo from '../../../assets/img/royalLogo.png'
import bedroomIcon from '../../../assets/icons/unitBedroom.svg'
import penthouseIcon from '../../../assets/icons/icn_apartment.svg'
import editIcon from '../../../assets/icons/edit.svg'
import card from '../../../assets/img/live-view-6.png'
import lastScreenImg from '../../../assets/img/last-stage-iamge.png'
import callIcon from '../../../assets/icons/icn_phone.svg'
import { Slider } from 'antd'
import SidePanel from './SidePanel/SidePanel'

const BuyerSurveyQuestionnaire = () => {
  const [residenceTypes, setResidenceTypes] = useState([
    {
      title: 'One Bedroom',
      icon: [bedroomIcon],
      isSelected: false
    },
    {
      title: 'Two Bedroom',
      icon: [bedroomIcon, bedroomIcon],
      isSelected: false
    },
    {
      title: 'Three Bedroom',
      icon: [bedroomIcon, bedroomIcon, bedroomIcon],
      isSelected: false
    },
    {
      title: 'Penthouse',
      icon: penthouseIcon,
      isSelected: false
    }
  ])
  const [step, setStep] = useState(1)
  const [budget, setBudget] = useState([3000000, 5500000])
  const [isSearched, setIsSearched] = useState(false)
  const [sidePanelData] = useState([
    {
      id: 1,
      title: 'Viewed - Home Page',
      description: 'Vancouver, Canada - Oct 23 at 1.15 PM'
    },
    {
      id: 2,
      title: 'Viewed - Registration Page',
      description: 'Vancouver, Canada - Oct 23 at 1.17 PM'
    },
    {
      id: 3,
      title: 'Form Filled - Registration Page',
      description: 'Vancouver, Canada - Oct 23 at 1.18 PM'
    },
    {
      id: 4,
      title: 'Registration Form complete',
      description: 'Vancouver, Canada - Oct 23 at 1.18 PM'
    },
    {
      id: 5,
      title: 'Lean Data Survey - Started',
      description: 'Vancouver, Canada - Oct 23 at 1.19 PM'
    }
  ])

  const proceedToNextStep = () => setStep(step + 1)

  const selectResidence = (residence) => {
    setResidenceTypes(residenceTypes.map((elm) => {
      if (elm.title === residence) {
        return {
          ...elm,
          isSelected: !elm.isSelected
        }
      }
      return elm
    }))
  }

  return (
    <div className='w-full h-full grid grid-cols-12 bg-cover' style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className='relative col-span-8 h-full flex justify-center items-center px-14 xl:px-16'>
        {
          step === 1 &&
          <div className='bg-grey-8 rounded-md h-12/20 xl:min-w-full 2xl:min-w-4xl 2xl:max-w-5xl flex flex-col justify-between p-10'>
            <div className='w-full flex flex-col items-center'>
              <img className='h-36 mb-12' src={logo} alt='logo' />
              <h1 className='font-semibold 2xl:font-bold text-4xl 2xl:text-5xl text-center mb-0'>Got 2 Minutes Stefan?</h1>
              <h1 className='font-semibold 2xl:font-bold text-4xl 2xl:text-5xl my-12 text-center'>Add a few insights to help us serve  you better.</h1>
            </div>
            <div className='flex justify-center gap-4'>
              <button
                onClick={() => setStep(5)}
                className='text-softBlack text-center text-lg font-normal min-w-28 py-2 rounded justify-center flex items-center border border-black italic'
              >
                Skip
              </button>
              <button
                onClick={proceedToNextStep}
                className='text-softBlack text-center text-lg font-medium min-w-28 py-2 rounded justify-center flex items-center bg-volt border border-volt italic'
              >
                Continue
              </button>
            </div>
          </div>
        }
        {
          step === 2 &&
          <div className='bg-grey-8 rounded-md h-12/20 xl:min-w-full 2xl:min-w-4xl 2xl:max-w-5xl flex flex-col justify-between items-center p-10'>
            <div className='w-full flex flex-col items-center'>
              <img className='h-14 my-6' src={logo} alt='logo' />
              <h1 className='font-normal text-3xl 2xl:text-4xl mb-4'>Great Stefan!</h1>
              <h1 className='font-semibold text-3xl 2xl:text-4xl text-center'>What are your preferred Residence type(s)?</h1>
            </div>
            <div className='w-full h-52 grid grid-cols-12 gap-2 gap-x-6 my-10'>
              {
                residenceTypes.map((item) => (
                  <div
                    onClick={() => selectResidence(item.title)}
                    className={
                      `col-span-3 h-full w-full
                    ${item.isSelected ? 'bg-volt' : 'bg-gray-100'}
                    hover:border hover:border-softBlack70 transition-all rounded-md flex flex-col justify-center px-4 cursor-pointer`
                    }
                  >
                    <div className='flex justify-center gap-1'>
                      {
                        item.title.includes('Bedroom')
                          ? item.icon.map((elm) => (
                            <img className='w-16' src={elm} alt={item.title} />

                          ))
                          : <img className='w-16' src={item.icon} alt={item.title} />
                      }
                    </div>
                    <h1 className='font-semibold text-lg 2xl:text-xl text-center'>{item.title}</h1>
                  </div>
                ))
              }
            </div>
            <div className='flex gap-4'>
              <button
                onClick={proceedToNextStep}
                className='text-softBlack text-center text-lg font-normal min-w-28 py-2 rounded justify-center flex items-center border border-black italic'
              >
                Skip
              </button>
              <button
                onClick={proceedToNextStep}
                className='text-softBlack text-center text-lg font-medium min-w-28 py-2 rounded justify-center flex items-center bg-volt border border-volt italic'
              >
                Continue
              </button>
            </div>
          </div>
        }
        {
          step === 3 &&
          <div className='bg-grey-8 rounded-md h-12/20 xl:min-w-full 2xl:min-w-4xl 2xl:max-w-5xl flex flex-col justify-between items-center p-10'>
            <div className='xl:min-w-5xl flex flex-col items-center gap-6'>
              <img className='h-14' src={logo} alt='logo' />
              <h1 className='font-normal text-3xl 2xl:text-4xl mb-2'>Let's Talk Budget!</h1>
              <h1 className='font-bold text-4xl 2xl:text-5xl'>{`$${budget[0].toLocaleString('en-US')} - $${budget[1].toLocaleString('en-US')}`}</h1>
              <h1 className='font-normal text-xl 2xl:text-2xl mb-2'>Suggested budget based on your unit choices</h1>
            </div>
            <div className='w-full'>
              <Slider
                range
                min={1000000}
                max={5500000}
                step={50000}
                defaultValue={[3000000, 5500000]}
                trackStyle={{ background: '#EDDF65', height: '40px', border: '0px' }}
                railStyle={{ background: 'gray', height: '40px', border: '0px', borderRadius: '4px' }}
                handleStyle={{ background: '#2A2A2A', opacity: 1, height: '100px', borderRadius: '10px', border: '0px', transform: 'translateY(-22px)' }}
                onChange={(e) => setBudget(e)}
              />
            </div>
            <div className='flex justify-center gap-4 mt-12'>
              <button
                onClick={proceedToNextStep}
                className='text-softBlack text-center text-lg font-normal py-2 px-3 rounded flex items-center border border-black italic'
              >
                <img className='pr-2' alt='Edit Budget' src={editIcon} />
                Edit Budget
              </button>
              <button
                onClick={proceedToNextStep}
                className='text-softBlack text-center text-lg font-medium py-2 px-6 rounded flex items-center bg-volt italic'
              >
                Continue
              </button>
            </div>
          </div>
        }
        {
          step === 4 &&
          <div className='bg-grey-8 rounded-md h-12/20 xl:min-w-full 2xl:min-w-4xl 2xl:max-w-5xl flex flex-col justify-between items-center p-10'>
            <div className='xl:min-w-5xl flex flex-col items-center gap-6'>
              <img className='h-14' src={logo} alt='logo' />
              <h1 className='font-normal text-3xl 2xl:text-4xl mb-0'>Working with a Realtor?</h1>
              <h1 className='font-bold text-4xl 2xl:text-5xl mb-0'>Invite them</h1>
              <h1 className='font-normal text-xl 2xl:text-2xl mb-0'>Suggested budget based on your unit choices</h1>
              <input onChange={() => setIsSearched(true)} className='text-lg 2xl:py-7 px-4 w-7/12' placeholder='Search Realtor Name...' />
            </div>
            {
              isSearched &&
              <div>
                <img src={card} alt='card' />
              </div>
            }
            <div className='flex justify-center gap-4'>
              {
                isSearched &&
                <button
                  onClick={proceedToNextStep}
                  className='text-softBlack text-center text-lg font-medium py-2 px-6 rounded flex items-center bg-volt'
                >
                  Notify Shannon
                </button>
              }
            </div>
          </div>
        }
        {
          step === 5 &&
          <div className='bg-grey-8 rounded-md h-12/20 xl:min-w-full 2xl:min-w-4xl 2xl:max-w-5xl flex flex-col justify-between p-10'>
            <div className='xl:min-w-5xl'>
                <img className='h-14' src={logo} alt='logo' />
            </div>
            <div className='h-full grid grid-cols-10 gap-10'>
              <div className='col-span-4 flex flex-col items-start justify-center'>
                <h1 className='font-normal text-3xl 2xl:text-4xl mb-0'>Thank You Stefan!</h1>
                <h1 className='font-semibold text-4xl 2xl:text-5xl my-4'>Next Steps</h1>
                <div className='w-full flex flex-col gap-y-6'>
                  <button
                    className='text-softBlack text-center text-xl 2xl:text-2xl font-normal py-4 xl:py-7 w-full rounded bg-volt'
                    onClick={() => setStep(1)}
                  >
                    Book a Sales Call
                  </button>
                  <button
                    className='text-softBlack text-center text-xl 2xl:text-2xl font-normal py-4 xl:py-7 w-full rounded bg-volt'
                  >
                    Visit Presentation Centre
                  </button>
                  <button
                    className='text-softBlack text-center text-xl 2xl:text-2xl font-normal py-4 xl:py-7 w-full rounded bg-volt'
                  >
                    Explore Virtual Tour
                  </button>
                </div>
              </div>
              <div className='col-span-6 flex flex-col items-center justify-center'>
                <div className='border p-4 bg-white rounded-md border-softBlack_70'>
                  <div className='relative'>
                    <img className='rounded-md' src={lastScreenImg} alt='image' />
                    <div className='absolute bottom-2 w-full flex justify-center gap-3'>
                      <div className='flex flex-col items-center gap-3'>
                        <div className='h-14 w-14 bg-emerald-500 rounded-full flex justify-center items-center'>
                          <img className='h-10' src={callIcon} />
                        </div>
                        <h1 className='text-xs text-white'>SMART CALL</h1>
                      </div>
                      <div className='flex flex-col items-center gap-3'>
                        <div className='h-14 w-14 bg-white opacity-50 rounded-full flex justify-center items-center'>
                          <img className='h-10' src={callIcon} />
                        </div>
                        <h1 className='text-xs text-white'>MUTE</h1>
                      </div>
                    </div>
                  </div>
                </div>
                <h1 className='font-normal text-3xl 2xl:text-4xl my-6 text-center'>Speak to a Specialist now</h1>
                <h1 className='font-normal text-xl 2xl:text-2xl mb-0 text-center'>Carol Jenkins - <span className='font-semibold'>Online</span></h1>
              </div>
            </div>
          </div>
        }
      </div>
      <SidePanel sidePanelData={sidePanelData} />
    </div>
  )
}

export default BuyerSurveyQuestionnaire
