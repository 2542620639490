import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { getContactsCounts, getUsers } from 'store/actions/usersActions'

const useGetUsers = () => {
  // @ts-ignore
  const userObject = useSelector((state) => state.authReducer.userObject)
  const { data, isLoading, refetch } = useQuery(
    ['getUsers'],
    () => getUsers(),
    { refetchOnMount: false, refetchOnWindowFocus: false, refetchOnReconnect: false, enabled: !!userObject?._id })
  return { users: data, isLoading, refetch }
}

const useGetUsersCounts = (
  projectId = '',
  filter = ''
) => {
  // @ts-ignore
  const userObject = useSelector((state) => state.authReducer.userObject)
  const { data, isLoading, refetch } = useQuery(
    [`getUsersCounts-${projectId}`],
    () => getContactsCounts(
      projectId,
      filter
    ),
    { refetchOnMount: false, refetchOnWindowFocus: false, refetchOnReconnect: false, enabled: !!userObject?._id })
  return { count: data, isLoading, refetch }
}

export { useGetUsers, useGetUsersCounts }
export default useGetUsers
