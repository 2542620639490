// @ts-nocheck
/* eslint-disable */
import {
  ArrowNarrowRightIcon,
} from '@heroicons/react/solid';
import { TFilledEmailIcon } from 'components';
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { getUserActivity } from 'store/actions/contactActions';
import moment from 'moment';
import { message, Tooltip } from 'antd';
import { ReactComponent as SMSIcon } from '../../assets/icons/icn_text_snippet.svg';
import NotificationIcon from '../../assets/icons/notification.png';
import { Calendar, CalendarCheckIcon, ChevronDown, ChevronUp, CircleCheckBig, Mail, Phone, PhoneCallIcon, StickyNote } from 'lucide-react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as NotesIcon } from '../../assets/icons/icn_write.svg';
import { ReactComponent as TasksIcon } from '../../assets/icons/icn_check_circle.svg';
import { ReactComponent as FormIcon } from '../../assets/icons/checkform.svg';
import WebsiteIcon from '../../assets/icons/visit.svg';
import {
  bindChannel,
  subscribeToChannel,
  unbindChannel,
  unsubscribeToChannel,
} from 'services/pusher';
import { Button } from 'components/ShadcnComponents/button';
import { useSelector } from 'react-redux';
import { callStatus } from './CallModal';
import { createOrUpdateCall } from 'store/actions/callsActions';
import CustomSelect from 'components/Inventory/components/CustomSelect';
import { Oval } from 'react-loader-spinner';
import { AddUserEmailInput } from './AddUserEmailInput';
import CustomInput from 'components/CustomInput';
import RichTextField from 'components/RichTextField';
import useGetAllUserEmailContacts from 'hooks/useGetAllUserEmailContacts';
import { createOrUpdateCalendarEvent, sendEmail } from 'store/actions/usersActions';
import { getCalenderDateMomentUsingObjectType } from 'utils'
import CustomTextArea from 'components/CustomTextArea'
import { ParticipantsSelector } from 'pages/calendar/ParticipantsSelector'
import { createOrUpdateNote } from 'store/actions/notesActions';
import { statusOptions, taskTypes, tempTaskTitles } from 'pages/tasks/components/TaskModal';
import { createOrUpdateTask } from 'store/actions/taskActions';
import { DemandTab } from './DemandTab';
import useGetCurrentProject from 'hooks/useGetCurrentProject';

const activityTypes = [
  { type: 'call', label: 'Call', icon: Phone },
  { type: 'email', label: 'Email', icon: Mail },
  { type: 'meeting', label: 'Meeting', icon: Calendar },
  { type: 'note', label: 'Note', icon: StickyNote },
  { type: 'task', label: 'Task', icon: CircleCheckBig }
];

const initialEmailValues = {
  from: '',
  to: '',
  cc: '',
  bcc: '',
  subject: '',
  body: '',
};

const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const initialCallValues = {
  description: '',
  startedAt: '',
  finishedAt: '',
  notes: '',
  callStatus: '',
};

const initialMeetingValues = {
  title: '',
  location: '',
  start_at: '',
  end_at: '',
  description: '',
}

const initialNoteValues = {
  title: '',
  note: ''
}

const initialTaskValues = {
  title: '',
  description: '',
  status: '',
  notifyDate: '',
  dueDate: '',
  taskType: '',
};

const NoActivityView = () => {
  return (
    <div className='h-full flex flex-col justify-center items-center'>
      <svg
        width='24'
        height='24'
        viewBox='0 0 18 18'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M10 6.5H15V4.5H10V6.5ZM10 13.5H15V11.5H10V13.5ZM16 18H2C0.9 18 0 17.1 0 16V2C0 0.9 0.9 0 2 0H16C17.1 0 18 0.9 18 2V16C18 17.1 17.1 18 16 18ZM3 8H8V3H3V8ZM4 4H7V7H4V4ZM3 15H8V10H3V15ZM4 11H7V14H4V11Z'
          fill='#2E2B2E'
        />
      </svg>
      <div className='font-semibold mb-2 mt-4'>
        You don't have any activities yet
      </div>
    </div>
  );
};

const Label = ({ text }) => (
  <div className='text-sm font-medium'>{text}</div>
)

const LogNewCall = ({ contact, appProject, userObject, onSuccess }) => {
  const [callDetails, setCallDetails] = useState({
    ...initialCallValues,
  });
  const [isLoading, setIsLoading] = useState(false)

  const onSave = async () => {
    if (callDetails?.callStatus === '') return

    const params = {
      ...callDetails,
      date: new Date().toISOString(),
      project: appProject,
      host: userObject._id,
      participants: contact?.id ? [contact.id] : [],
    };

    setIsLoading(true);
    try {
      await createOrUpdateCall(params);
      setCallDetails({ ...initialCallValues });
      onSuccess();
      setIsLoading(false);
    } catch (err) {
      message.error('Failed to log call')
      setIsLoading(false);
    }
  };

  const onChange = (fieldName, value) => {
    setCallDetails({ ...callDetails, [fieldName]: value });
  };

  return (
    <div className='flex flex-col gap-4'>
      <h4 className='w-full text-xl font-semibold m-0 pb-3 border-b border-softBlack30'>Log Call</h4>
      <div className='flex flex-col gap-y-1'>
        <Label text='Call Status *' />
        <CustomSelect
          disabled={isLoading}
          options={callStatus}
          value={callDetails?.callStatus}
          handleChange={(value) => onChange('callStatus', value)}
          placeholder='Select call status'
        />
      </div>
      <div className='flex flex-col gap-y-1'>
        <Label text='Call Notes' />
        <textarea
          name='note'
          className='bg-transparent border border-softBlack30 focus:border-softBlack30 rounded focus:outline-none focus:ring-0 focus:ring-offset-0 appearance-none w-full resize-none overflow-auto'
          cols='70'
          rows={8}
          value={callDetails?.notes}
          placeholder={'Type your note here ...'}
          onChange={(e) => onChange('notes', e.target.value)}
          disabled={isLoading}
        />
      </div>
      <div className='flex'>
        <Button onClick={onSave} disabled={isLoading || (callDetails?.callStatus === '')} className='flex items-center gap-2'>
          {isLoading && (
            <Oval height='16' width='16' color='white' />
          )}
          Log Call
        </Button>
      </div>
    </div>
  )
}

const ComposeEmail = ({ contact, appProject, onSuccess }) => {
  const editorRef = useRef(null)
  const { contacts, loading: isContactLoading } = useGetAllUserEmailContacts();
  const [emailData, setEmailData] = useState({
    ...initialEmailValues,
    to: contact?.email ? [contact.email] : [],
  });
  const [sendingEmail, setSendingEmail] = useState(false);
  const [fileAttachments, setFileAttachments] = useState([]);

  const onChange = (fieldName, value) => {
    setEmailData({ ...emailData, [fieldName]: value });
  };

  const handleBodyChange = (value) => {
    setEmailData((data) => ({ ...data, body: value }));
  };

  const cleanHTML = (html) => {
    return html
      .replace(/<p>/g, '')
      .replace(/<\/p>/g, '<br>')
      .replace(/<p><br><\/p>/g, '<br>')
      .replace(/<p><br \/><\/p>/g, '<br>')
      .replace(/<br><br>/g, '<br>')
      .replace(/^<br>|<br>$/g, '');
  }

  const isValid = useMemo(() => {
    const emailHtml = editorRef.current?.getEditor?.().root?.innerHTML ?? ''
    const emailBody = cleanHTML(emailHtml)

    if (emailBody === '') {
      return false
    } else if (emailData?.subject && emailData.subject === '') {
      return false
    }

    return true
  }, [emailData])

  const mapEmail = (emailList) => {
    if (!emailList?.length) return [];

    const filteredEmails = emailList?.filter((email) => (email.match(validRegex)));

    if (!filteredEmails?.length) return [];

    const result =
      filteredEmails?.map((email) => {
        return {
          name: '',
          email,
        };
      }) ?? [];

    return result;
  };

  const _sendEmail = () => {
    setSendingEmail(true);

    const emailHtml = editorRef.current?.getEditor?.().root?.innerHTML ?? ''
    const emailBody = cleanHTML(emailHtml)

    sendEmail(
      emailBody,
      emailData.subject,
      mapEmail(emailData.to),
      fileAttachments,
      mapEmail(emailData.cc),
      mapEmail(emailData.bcc),
      appProject
    )
      .then(() => {
        setSendingEmail(false);
        onSuccess();
      })
      .catch(() => setSendingEmail(false));
  };

  const handleSendEmail = () => {
    if (isValid) {
      _sendEmail();
    }
  };

  return (
    <div className='w-full flex relative overflow-hidden flex-col gap-4 rounded'>
      <h4 className='w-full text-xl font-semibold m-0 pb-3 border-b border-softBlack30'>Compose Email</h4>
      <div className='p-1 flex flex-col gap-4'>
        <div className='flex flex-col gap-y-1'>
          <Label text='To' />
          <AddUserEmailInput
            contacts={contacts}
            loading={isContactLoading}
            selectedEmails={emailData.to?.length ? emailData.to : null}
            singleSelection={false}
            setSelectedEmails={(_val) => {
              onChange('to', _val);
            }}
            placeholder='Enter Receivers Email (use comma for multiple values)'
            disabled
          />
        </div>
        <div className='flex flex-col gap-y-1'>
          <Label text='CC' />
          <AddUserEmailInput
            contacts={contacts}
            loading={isContactLoading}
            selectedEmails={emailData.cc}
            setSelectedEmails={(_val) => {
              onChange('cc', _val);
            }}
            placeholder='Enter CC Email (use comma for multiple values)'
          />
        </div>
        <div className='flex flex-col gap-y-1'>
          <Label text='BCC' />
          <AddUserEmailInput
            contacts={contacts}
            loading={isContactLoading}
            selectedEmails={emailData.bcc}
            setSelectedEmails={(_val) => {
              onChange('bcc', _val);
            }}
            placeholder='Enter BCC Email (use comma for multiple values)'
          />
        </div>
        <div className='flex flex-col gap-y-1'>
          <Label text='Subject *' />
          <CustomInput
            placeholder='Enter Subject'
            value={emailData.subject}
            onChange={(e) => {
              onChange('subject', e.target.value);
            }}
            required
            containerClasses={undefined}
            classes={undefined}
          />
        </div>
        <div className='flex flex-col gap-y-1 h-[300px]'>
          <Label text='Email text *' />
          <RichTextField
            editorRef={editorRef}
            onChangeText={handleBodyChange}
            text={emailData?.body}
            placeholder={'Type text here...'}
            onChangeFileAttachments={(attachments) =>
              setFileAttachments(attachments)
            }
            height='260px'
          />
        </div>
      </div>
      <Button onClick={handleSendEmail} disabled={sendingEmail || !isValid} className='mt-7 w-fit flex items-center gap-2'>
        {sendingEmail && (
          <Oval height='18' width='18' color='white' />
        )}
        Compose
      </Button>
    </div>
  )
}

const ScheduleMeeting = ({ contact, appProject, onSuccess }) => {
  const [meeting, setMeeting] = useState({ ...initialMeetingValues })
  const [isLoading, setIsLoading] = useState(false)

  const onChange = useCallback((fieldName, value) => {
    setMeeting((prevState) => { return { ...prevState, [fieldName]: value } })
  }, [meeting])

  const onValidate = () => {
    const requiredFields = ['title', 'location', 'start_at', 'end_at']
    for (let i = 0; i < requiredFields.length; i++) {
      if (meeting[requiredFields[i]] === '') {
        return false
      }
    }
    return true
  }

  const onSave = async () => {
    if (!onValidate()) {
      message.error('Please fill all all required fields')
      return
    }

    const { firstName: name, email, phone_number } = contact
    const params = {
      project: appProject,
      participants: [{ name, email, phone_number }],
      title: meeting.title,
      location: meeting.location,
      description: meeting.description,
      notify_participants: true,
      when: {
        start_time: (new Date(meeting.start_at)),
        end_time: (new Date(meeting.end_at)),
        object: 'timespan'
      }
    }

    setIsLoading(true)
    try {
      await createOrUpdateCalendarEvent(params)
      setIsLoading(false)
      onSuccess()
      setMeeting({ ...initialMeetingValues })
    } catch (err) {
      setIsLoading(false)
      message.error('Failed to create meeting')
    }
  }

  return (
    <div className='flex flex-col gap-4'>
      <h4 className='w-full text-xl font-semibold m-0 pb-3 border-b border-softBlack30'>Schedule Meeting</h4>
      <div className='flex flex-col gap-y-1'>
        <Label text='Topic *' />
        <CustomInput
          placeholder='Enter topic'
          // label='Topic *'
          value={meeting.title}
          classes='text-base'
          onChange={(e) => onChange('title', e.target.value)}
          disabled={isLoading}
        />
      </div>
      <div className='flex flex-col gap-y-1'>
        <Label text='Location *' />
        <CustomInput
          placeholder='Enter location'
          value={meeting.location}
          classes='text-base'
          onChange={(e) => onChange('location', e.target.value)}
          disabled={isLoading}
        />
      </div>
      <div className='flex flex-col gap-y-1'>
        <Label text='Start at *' />
        <CustomInput
          type='datetime-local'
          placeholder='Enter time'
          value={(meeting.start_at)}
          classes='text-base'
          onChange={(e) => onChange('start_at', moment(e.target.value).format('YYYY-MM-DDTHH:mm'))}
          disabled={isLoading}
        />
      </div>
      <div className='flex flex-col gap-y-1'>
        <Label text='Finish at *' />
        <CustomInput
          type='datetime-local'
          placeholder='Enter time'
          value={(meeting.end_at)}
          classes='text-base'
          onChange={(e) => onChange('end_at', moment(e.target.value).format('YYYY-MM-DDTHH:mm'))}
          disabled={isLoading}
        />
      </div>
      <div className='flex flex-col gap-y-1'>
        <Label text='Description' />
        <CustomTextArea
          placeholder='Enter Description'
          value={meeting.description}
          onChange={(e) => onChange('description', e.target.value)}
          style={{ color: '#2E2B2E' }}
          disabled={isLoading}
        />
      </div>
      <div className='flex flex-col gap-y-1'>
        <Label text='Add Participants' />
        <ParticipantsSelector
          selectedParticipants={contact?.id ? [{ ...contact }] : []}
          buyers={contact?.id ? [{ ...contact }] : []}
          setSelectedParticipants={(value) => {
            onChange('participants', value)
          }}
          isHideLabel
          disabled
        />
      </div>
      <Button
        onClick={onSave}
        disabled={!onValidate() || isLoading}
        className='w-fit flex items-center gap-2'
      >
        {isLoading && <Oval height='18' width='18' color='white' />}
        Schedule
      </Button>
    </div>
  )
}

const CreateNewNote = ({ contact, appProject, onSuccess }) => {
  const [noteData, setNoteData] = useState({ ...initialNoteValues })
  const [isLoading, setIsLoading] = useState(false)

  const onSave = async (notes) => {
    setIsLoading(true)

    const notesString = JSON.stringify({
      title: noteData.title ?? '',
      notes: noteData.note ?? '',
    });

    let params = {
      notes: notesString,
      buyer: contact?._id
    };

    try {
      const resp = await createOrUpdateNote(params)
      setIsLoading(false)
      setNoteData({ ...initialNoteValues })
      message.success('Note created successfully')
      onSuccess()
    } catch {
      message.success('Failed to create note')
      setIsLoading(false)
    }
  };

  return (
    <div className='flex flex-col gap-4'>
      <h4 className='w-full text-xl font-semibold m-0 pb-3 border-b border-softBlack30'>Create Note</h4>
      <div className='flex flex-col gap-y-1'>
        <Label text='Title' />
        <CustomInput
          placeholder='Enter note title'
          value={noteData.title}
          onChange={(e) => setNoteData({ ...noteData, title: e.target.value })}
          required
          disabled={isLoading}
        />
      </div>
      <div className='flex flex-col gap-y-1'>
        <Label text='Notes *' />
        <textarea
          name='note'
          className='bg-transparent border border-softBlack30 focus:border-softBlack30 rounded focus:outline-none focus:ring-0 focus:ring-offset-0 appearance-none w-full resize-none overflow-auto'
          cols='70'
          rows={8}
          value={noteData.note}
          placeholder={'Type your note here ...'}
          onChange={(e) => setNoteData({ ...noteData, note: e.target.value })}
          disabled={isLoading}
        />
      </div>
      <Button
        onClick={onSave}
        disabled={noteData.note === '' || isLoading}
        className='w-fit flex items-center gap-2'
      >
        {isLoading && <Oval height='18' width='18' color='white' />}
        Create
      </Button>
    </div>
  )
}

const CreateNewTask = ({ contact, appProject, userObject, onSuccess }) => {
  const [taskDetails, setTaskDetails] = useState({
    ...initialTaskValues,
  });
  const [isLoading, setIsLoading] = useState(false);

  const onChange = (fieldName, value) => {
    if (fieldName === 'status' && value === 'closed' && taskStatusConfirmModal === false)
      setTaskDetails({ ...taskDetails, [fieldName]: initialTaskValues.status });
    else {
      setTaskDetails({ ...taskDetails, [fieldName]: value });
    }
  };

  const onValidate = useMemo(() => {
    for (const key in taskDetails) {
      if (
        taskDetails[key] === '' &&
        key !== 'notifyDate' &&
        key !== 'assignedTo' &&
        key !== 'description'
      ) {
        return false;
      }
    }
    return true;
  }, [taskDetails]);

  const onSave = async () => {
    let date = null;

    if (!taskDetails.dueDate) {
      const temp = new Date();
      temp.setHours(11);
      temp.setMinutes(59);
      temp.setMilliseconds(0);
      date = temp;
    }

    const params = {
      ...taskDetails,
      dueDate: new Date(taskDetails.dueDate).toISOString(),
      buyer: contact?._id || '',
      assignedTo: userObject._id || '',
      project: appProject
    };

    if (!taskDetails.dueDate) params.dueDate = date;

    setIsLoading(true);
    try {
      await createOrUpdateTask(params)
      setIsLoading(false);
      setTaskDetails({ ...initialTaskValues });
      onSuccess()
    } catch (err) {
      setIsLoading(false);
      message.error('Failed to create task')
    }
  };

  return (
    <div className='flex flex-col gap-4'>
      <h4 className='w-full text-xl font-semibold m-0 pb-3 border-b border-softBlack30'>Create Task</h4>
      <div className='flex flex-col gap-y-1'>
        <Label text='Title *' />
        <CustomInput
          placeholder='Enter title'
          labelClassName='mt-0 mb-2 text-softBlack70 text-xs font-medium uppercase'
          value={taskDetails.title}
          classes='text-sm'
          onChange={(e) => onChange('title', e.target.value)}
          disabled={isLoading}
        />
      </div>
      <p className='text-softBlack70 uppercase font-bold text-sm text-center mb-0'>
        OR
      </p>
      <div className='flex flex-col gap-y-1'>
        <Label text='Title Dropdown *' />
        <CustomSelect
          value={
            taskDetails.title === '' ? 'Select title' : taskDetails.title
          }
          handleChange={(value) => {
            onChange('title', value);
          }}
          options={tempTaskTitles}
          disabled={isLoading}
        />
      </div>
      {/* <div className='flex flex-col gap-y-1'>
        <Label text='Buyer' />
        <CustomSelect
          value={
            taskDetails.buyer === '' || !taskDetails.buyer
              ? 'Select Buyer'
              : taskDetails.buyer
          }
          options={searchedUsers}
          isName
          disabled
        />
      </div> */}
      {/* <div className='flex flex-col gap-y-1'>
        <Label text='Assigned To *' />
        <CustomSelect
          label='Assigned To'
          value={
            taskDetails.assignedTo === '' || !taskDetails.assignedTo
              ? 'Select Assigned To'
              : taskDetails.assignedTo
          }
          handleChange={(value) => onChange('assignedTo', value)}
          options={salesRepData}
          isName
          disabled={isLoading}
        />
      </div> */}
      <div className='flex flex-col gap-y-1'>
        <Label text='Description' />
        <CustomTextArea
          containerClasses='flex flex-col'
          labelClassName='text-xs'
          placeholder='Enter description'
          value={taskDetails.description}
          onChange={(e) => {
            onChange('description', e.target.value);
          }}
          classes='w-full text-sm'
          style={{ color: '#2E2B2E' }}
          disabled={isLoading}
        />
      </div>
      <div className='flex flex-col gap-y-1'>
        <Label text='Status *' />
        <CustomSelect
          value={
            taskDetails.status === '' ? 'Select status' : taskDetails.status
          }
          handleChange={(value) => onChange('status', value)}
          options={statusOptions}
          disabled={isLoading}
        />
      </div>
      <div className='flex flex-col gap-y-1'>
        <Label text='Task Type *' />
        <CustomSelect
          value={
            taskDetails.taskType === ''
              ? 'Select Task Type'
              : taskDetails.taskType
          }
          handleChange={(value) => {
            onChange('taskType', value);
          }}
          options={taskTypes}
          disabled={isLoading}
        />
      </div>
      <div className='flex flex-col gap-y-1'>
        <Label text='Due Date *' />
        <CustomInput
          labelClassName='mt-0 mb-2 text-softBlack70 text-xs font-medium uppercase'
          value={moment(taskDetails.dueDate).format('YYYY-MM-DDTHH:mm')}
          onChange={(e) => {
            let isoString = new Date(e.target.value).toISOString();
            onChange('dueDate', isoString)
          }}
          type='datetime-local'
          classes='w-full uppercase text-base'
          min={null}
          format-value="YYYY-MM-DD"
          disabled={isLoading}
        />
      </div>
      <Button onClick={onSave} disabled={!onValidate || isLoading} className='w-fit flex gap-2 items-center'>
        {isLoading && <Oval height='18' width='18' color='white' />}
        Create
      </Button>
    </div>
  )
}

const NewActivityTab = ({
  contact,
  setSelectedHeader,
  setSelectedEmailThread,
}) => {
  const appProject = useSelector((state) => state.appReducer.appProject);
  const userObject = useSelector((state) => state.authReducer.userObject);
  const [isLoadingActivity, setIsLoadingActivity] = useState(true);
  const [activities, setActivities] = useState();
  const [selectedActivityType, setSelectedActivityType] = useState(null)

  const toggleActivityLog = () => {
    setSelectedActivityType(null);
  };

  const toggleActivityType = (type) => {
    setSelectedActivityType(prevType => prevType === type ? null : type);
  };

  const fetchActivities = async () => {
    try {
      setIsLoadingActivity(true);
      const _data = await getUserActivity(contact?.id);
      setActivities(_data.data);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoadingActivity(false);
    }
  };

  const onNewCallCallBack = useCallback(
    (newActivity) => {
      setActivities((currentActivities) => [newActivity, ...currentActivities]);
    },
    [activities, setActivities]
  );

  useEffect(() => {
    let activityChannel = null;
    if (contact?.id) {
      activityChannel = subscribeToChannel(`activity-${contact?.id}`);
      if (activityChannel) {
        bindChannel(activityChannel, onNewCallCallBack);
      }
    }
    return () => {
      if (contact?.id) {
        if (activityChannel) {
          unbindChannel(activityChannel, onNewCallCallBack);
        }
        unsubscribeToChannel(`activity-${contact?.id}`);
      }
    };
  }, [contact?.id, onNewCallCallBack]);

  useEffect(() => {
    fetchActivities();
  }, []);

  const ActivityItem = ({
    item: {
      _id,
      title,
      createdAt,
      updatedAt,
      displayMessage,
      activityType,
      activity,
      stats,
      threadId,
      userActivityData,
      user
    },
  }) => {
    return (
      <div
        key={_id}
        className='flex flex-col relative z-[10] bg-white border rounded-[8px] py-4 px-3 justify-start items-start overflow-hidden w-full max-w-[1000px]'
      >
        <div className=' w-full flex flex-col justify-between gap-y-2'>
          <div className='flex flex-wrap  gap-3'>
            <div className='h-10 w-10 rounded-full flex items-center justify-center z-[50] bg-zinc-200'>
              {activityType === 'Email' ? (
                <TFilledEmailIcon className='h-6' />
              ) : activityType === 'Email-campaign' ? (
                <TFilledEmailIcon className='h-6' />
              ) : activityType === 'Event/Meeting' ? (
                <CalendarCheckIcon className='h-6' />
              ) : activityType === 'Call' ? (
                <PhoneCallIcon className='h-5' />
              ) : activityType === 'Notes Created' ? (
                <NotesIcon className='h-5' />
              ) : activityType === 'Note Created' ||
                activityType?.toLowerCase().includes('note') ? (
                <NotesIcon className='h-5' />
              ) : activityType?.toLowerCase().includes('sms') ? (
                <SMSIcon className='h-6' />
              ) : activityType === 'Task Created' ? (
                <TasksIcon className='h-5' />
              ) : activityType === 'Form Submission' ? (
                <FormIcon className='h-5' />
              ) : activityType === 'Website Activity' ? (
                <img src={WebsiteIcon} className='h-5' />
              ) : (
                <img
                  className='h-5'
                  src={NotificationIcon}
                  alt='notification'
                />
              )}
            </div>
            <Tooltip
              className='absolute right-4 top-4'
              title={moment(createdAt).format('MMM DD, YYYY [at] hh:mm a')}
            >
              <span className='pl-[1px] text-[#2E2B2E] text-opacity-70 text-[10px] md:text-[14px] font-normal leading-none truncate'>
                {moment(createdAt).fromNow()}
                {!(
                  moment(new Date()).format('MMM DD, YYYY') ===
                  moment(createdAt).format('MMM DD, YYYY')
                ) && ` ${moment(createdAt).format('[at] hh:mm a')}`}
              </span>
            </Tooltip>
            <div className='flex justify-center flex-col flex-1'>
              <div className='flex flex-col gap-y-2'>
                <span className='text-[#2E2B2E] text-base font-bold leading-none'>
                  {activityType === 'Email' ? (
                    <div>Subject: {title}</div>
                  ) : activityType === 'Email-campaign' ? (
                    <div>Email Sent - {title}</div>
                  ) : activityType === 'Event/Meeting' ? (
                    <div> {title}</div>
                  ) : activityType === 'Call' ||
                    activityType?.toLowerCase().includes('call') ? (
                    <div> {title}</div>
                  ) : activityType === 'Notes Created' ||
                    activityType === 'Note Created' ? (
                    <div> {title}</div>
                  ) : activityType?.toLowerCase().includes('sms') ? (
                    <div> {title}</div>
                  ) : activityType === 'Task Created' ? (
                    <div> {title}</div>
                  ) : activityType === 'user-created' ? (
                    <div> {title}</div>
                  ) : (
                    <div> {title}</div>
                  )}
                </span>
              </div>
              <div className='flex mt-2 flex-col gap-y-[16px] ml-4'>
                {activityType === 'Email' && activity ? (
                  activity.map((item) => {
                    return (
                      <div className='flex flex-wrap gap-x-4'>
                        <div className='flex flex-col gap-y-[4px]'>
                          <div className='flex gap-x-2 items-center'>
                            <div className='font-bold'>{item.type} </div>
                            <Tooltip
                              title={moment(item.timestamp).format(
                                'MMM DD, YYYY [at] hh:mm a'
                              )}
                            >
                              <span className='text-[#2E2B2E] text-opacity-70 text-[14px] font-normal leading-none'>
                                · {moment(item.timestamp).fromNow()}
                                {!(
                                  moment(new Date()).format('MMM DD, YYYY') ===
                                  moment(item.timestamp).format('MMM DD, YYYY')
                                ) &&
                                  ` ${moment(item.timestamp).format(
                                    '[at] hh:mm a'
                                  )}`}
                              </span>
                            </Tooltip>
                          </div>
                          <div>
                            {item.message && item.message.length > 200
                              ? item.message.slice(0, 200) + '...'
                              : item.message ?? ''}
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : activityType === 'Email-campaign' && activity ? (
                  activity.map((item) => {
                    return (
                      <div className='flex -ml-4 flex-wrap gap-x-4'>
                        <div className='flex flex-col gap-y-[4px]'>
                          <div className='flex gap-x-2 items-center'>
                            <div className='font-[500] text-[#374151] capitalize text-[16px]'>
                              {item.type === 'email-delivered'
                                ? 'Sent'
                                : item.type === 'email-processed'
                                  ? 'Processed'
                                  : item.type === 'email-open'
                                    ? 'Opened'
                                    : item.type}
                            </div>
                            <div className='text-[16px]'>
                              <Tooltip
                                title={moment(item.timestamp).format(
                                  'MMM DD, YYYY [at] hh:mm a'
                                )}
                              >
                                <span className='text-[#2E2B2E] text-opacity-70 text-[14px] font-normal leading-none'>
                                  · {moment(item.timestamp).fromNow()}
                                  {!(
                                    moment(new Date()).format('MMM DD, YYYY') ===
                                    moment(item.timestamp).format('MMM DD, YYYY')
                                  ) &&
                                    ` ${moment(item.timestamp).format(
                                      '[at] hh:mm a'
                                    )}`}
                                </span>
                              </Tooltip>
                            </div>
                          </div>
                          <div className='flex ml-2 relative flex-col mt-2 gap-y-4'>
                            {
                              stats?.map((stat) => {
                                if (item.event === 'processed') {
                                  return null;
                                }
                                return (
                                  <>
                                    <div className='h-full absolute left-[3.4px] w-[1px] z-[-1] bg-[rgb(0,0,0,0.2)]'></div>
                                    <div className='flex gap-x-4 items-center'>
                                      <div className='bg-[rgb(95,95,95)] rounded-full h-2 w-2'></div>
                                      <div className='font-[500] uppercase z-1 text-[#27272ab3] italic'>
                                        {item.event === 'delivered'
                                          ? 'Sent'
                                          : item.event === 'open'
                                            ? 'Opened'
                                            : item.event === 'click'
                                              ? 'Clicked'
                                              : item.event}
                                      </div>
                                      <div>
                                        <span className='text-[#2E2B2E] text-opacity-70 text-[12px] font-normal leading-none'>
                                          · {moment(item.timestamp).fromNow()}
                                          {!(
                                            moment(new Date()).format(
                                              'MMM DD, YYYY'
                                            ) ===
                                            moment(item.timestamp).format(
                                              'MMM DD, YYYY'
                                            )
                                          ) &&
                                            ` ${moment(item.timestamp).format(
                                              '[at] hh:mm a'
                                            )}`}
                                        </span>
                                      </div>
                                    </div>
                                  </>
                                );
                              })
                            }
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : activityType === 'Event/Meeting' && activity ? (
                  activity.map((item) => {
                    return (
                      <div className='flex gap-x-4'>
                        <div className='flex flex-col gap-y-[4px]'>
                          <div className='flex gap-x-2 items-center'>
                            <div className='font-bold'>{item.type}</div>
                            {item.timestamp && (
                              <Tooltip
                                title={moment(item?.timestamp).format(
                                  'MMM DD, YYYY [at] hh:mm a'
                                )}
                              >
                                <span className='text-[#2E2B2E] text-opacity-70 text-[14px] font-normal leading-none'>
                                  · {moment(item.timestamp).fromNow()}
                                  {!(
                                    moment(new Date()).format(
                                      'MMM DD, YYYY'
                                    ) ===
                                    moment(item.timestamp).format(
                                      'MMM DD, YYYY'
                                    )
                                  ) &&
                                    ` ${moment(item.timestamp).format(
                                      '[at] hh:mm a'
                                    )}`}
                                </span>
                              </Tooltip>
                            )}
                          </div>
                          {item.startTime && item.endTime && (
                            <div>
                              Date:{' '}
                              {moment(item.startTime).format(
                                'MMM DD, YYYY - hh:mm a'
                              )}
                              {' To '} {moment(item.endTime).format('hh:mm a')}
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })
                ) : activityType === 'Call' ||
                  activityType?.toLowerCase().includes('call') ? (
                  <div className='text-[14px] text-[#2e2b2e]'>
                    <Tooltip placement='bottomRight' title={displayMessage}>
                      {displayMessage && displayMessage.length > 40
                        ? displayMessage.slice(0, 40) + '...'
                        : displayMessage ?? ''}
                    </Tooltip>
                  </div>
                ) : activityType === 'Notes Created' ||
                  activityType === 'Note Created' ? (
                  <div className='text-[14px] text-[#2e2b2e]'>
                    <Tooltip placement='bottomRight' title={displayMessage}>
                      {displayMessage && displayMessage.length > 40
                        ? displayMessage.slice(0, 40) + '...'
                        : displayMessage ?? ''}
                    </Tooltip>
                  </div>
                ) : activityType?.toLowerCase().includes('sms') ? (
                  <div className='text-[14px] text-[#2e2b2e]'>
                    <Tooltip placement='bottomRight' title={displayMessage}>
                      {displayMessage && displayMessage.length > 40
                        ? displayMessage.slice(0, 40) + '...'
                        : displayMessage ?? ''}
                    </Tooltip>
                  </div>
                ) : activityType === 'Task Created' ? (
                  <div className='text-[14px] text-[#2e2b2e]'>
                    <Tooltip placement='bottomRight' title={displayMessage}>
                      {displayMessage && displayMessage.length > 100
                        ? displayMessage.slice(0, 100) + '...'
                        : displayMessage ?? ''}
                    </Tooltip>
                  </div>
                ) : activityType === 'user-created' ? (
                  <div className='space-y-2 text-sm text-gray-600'>
                  <div className='grid grid-cols-[120px,1fr] items-center'>
                    <span className='text-gray-500'>Contact Type</span>
                    <span className='font-medium text-gray-900'>{user?.userType}</span>
                  </div>
                  <div className='grid grid-cols-[120px,1fr] items-center'>
                    <span className='text-gray-500'>Name</span>
                    <span className='font-medium text-gray-900'>{user?.firstName || '' + ' ' + user?.lastName || ''}</span>
                  </div>
                  <div className='grid grid-cols-[120px,1fr] items-center'>
                    <span className='text-gray-500'>Email</span>
                    <span className='font-medium text-gray-900'>{user?.email}</span>
                  </div>
                  <div className='grid grid-cols-[120px,1fr] items-center'>
                    <span className='text-gray-500'>Phone</span>
                    <span className='font-medium text-gray-900'>{user?.buyerData?.phoneNumber || user?.leadBrokerData?.phoneNumber || '-'}</span>
                  </div>
                  <div className='grid grid-cols-[120px,1fr] items-center'>
                    <span className='text-gray-500'>Address</span>
                    <span className='font-medium text-gray-900'>{user?.buyerData?.address || user?.leadBrokerData?.address || '-'}</span>
                  </div>
                  <div className='grid grid-cols-[120px,1fr] items-center'>
                    <span className='text-gray-500'>Source</span>
                    <span className='font-medium text-gray-900'>{userActivityData?.source}</span>
                  </div>
                  <div className='grid grid-cols-[120px,1fr] items-center'>
                    <span className='text-gray-500'>Registered</span>
                    <span className='font-medium text-gray-900'>{moment(userActivityData?.userCreatedAt).format('MMM DD, YYYY [at] hh:mm a')}</span>
                  </div>
                  {userActivityData?.tags?.length > 0 && (
                    <div className='grid grid-cols-[120px,1fr] items-center'>
                      <span className='text-gray-500'>Tags</span>
                      <div className='flex flex-wrap gap-1'>
                        {userActivityData.tags.map((tag, index) => (
                          <span key={index} className='px-2 py-0.5 bg-gray-100 text-gray-700 rounded-full text-xs'>
                            {tag}
                          </span>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                ) : activityType === 'user-updated' ? (
                  <div className='space-y-2 text-sm text-gray-600'>
                    <div className='grid grid-cols-[120px,1fr] items-center'>
                    <span className='text-gray-500'>Re Registered At</span>
                    <span className='font-medium text-gray-900'>{moment(userActivityData?.userCreatedAt).format('MMM DD, YYYY [at] hh:mm a')}</span>
                  </div>
                  <div className='grid grid-cols-[120px,1fr] items-center'>
                    <span className='text-gray-500'>Name</span>
                    <span className='font-medium text-gray-900'>{user?.firstName || '' + ' ' + user?.lastName || ''}</span>
                  </div>
                  <div className='grid grid-cols-[120px,1fr] items-center'>
                    <span className='text-gray-500'>Email</span>
                    <span className='font-medium text-gray-900'>{user?.email}</span>
                  </div>
                  <div className='grid grid-cols-[120px,1fr] items-center'>
                    <span className='text-gray-500'>Phone</span>
                    <span className='font-medium text-gray-900'>{user?.buyerData?.phoneNumber || user?.leadBrokerData?.phoneNumber || '-'}</span>
                  </div>
                  <div className='grid grid-cols-[120px,1fr] items-center'>
                    <span className='text-gray-500'>Address</span>
                    <span className='font-medium text-gray-900'>{user?.buyerData?.address || user?.leadBrokerData?.address || '-'}</span>
                  </div>
                  </div>
                ) :
                (
                  <div className='text-[14px] text-[#2e2b2e]'>
                    <Tooltip placement='bottomRight' title={displayMessage}>
                      {displayMessage && displayMessage.length > 100
                        ? displayMessage.slice(0, 100) + '...'
                        : displayMessage ?? ''}
                    </Tooltip>
                  </div>
                )}
              </div>
            </div>
            {(activityType != 'Website Activity' ||
              activityType != 'Form Submission') && (
                <ArrowNarrowRightIcon
                  onClick={() => {
                    setSelectedEmailThread(threadId);
                    if (activityType === 'Email') {
                      setSelectedHeader('Email');
                    }
                    if (activityType === 'Event/Meeting') {
                      setSelectedHeader('Calendar');
                    }
                    if (
                      activityType === 'Call' ||
                      activityType?.toLowerCase().includes('call')
                    ) {
                      setSelectedHeader('Calls');
                    }
                    if (
                      activityType === 'Notes Created' ||
                      activityType === 'Note Created' ||
                      activityType?.toLowerCase().includes('note')
                    ) {
                      setSelectedHeader('Notes');
                    }
                    if (activityType?.toLowerCase().includes('sms')) {
                      setSelectedHeader('SMS');
                    }
                    if (activityType === 'Task Created') {
                      setSelectedHeader('Tasks');
                    }
                  }}
                  height={'20px'}
                  className='flex text-end absolute right-0 bottom-2 w-auto mr-4 cursor-pointer'
                />
              )}
          </div>
        </div>
      </div>
    );
  };

  const ActivitiesWrapper = ({ items }) => {
    return (
      <>
        {items.length && (
          <div className='self-stretch flex-col gap-y-8 justify-start items-start relative inline-flex'>
            {items.map((item, index) => (
              <div key={index} className='w-full'>
                <ActivityItem item={item} />
              </div>
            ))}
            <div className=' bg-[grey] ml-8 -bottom-4 h-full absolute top-0 w-[1px]'></div>
          </div>
        )}
      </>
    );
  };

  return (
    <div className='grid grid-cols-2 gap-6 p-6'>
      <div className='col-span-1 flex flex-col gap-6'>
        <div className='w-full border rounded-md shadow'>
          <div className='p-5'>
            <div className='flex justify-between items-center mb-4'>
              <h2 className='m-0 text-softBlack_70/100 text-xl font-semibold leading-none'>
                Activity log
              </h2>
              {selectedActivityType && (
                <Button
                  variant='ghost'
                  onClick={toggleActivityLog}
                >
                  <ChevronUp className="w-5 h-5" />
                </Button>
              )}
            </div>
            <div className='flex items-center gap-3 mb-4'>
              {activityTypes.map(({ type, label, icon: Icon, variant }) => (
                <Button
                  key={type}
                  variant={type === selectedActivityType ? 'default' : 'ghost'}
                  onClick={() => toggleActivityType(type)}
                  className="w-fit flex items-center justify-center gap-2 hover:!bg-zinc-300"
                >
                  <Icon className='w-4 h-4' />
                  {label}
                </Button>
              ))}
            </div>
            {selectedActivityType === 'call' && (
              <LogNewCall
                contact={contact}
                appProject={appProject}
                userObject={userObject}
                onSuccess={fetchActivities}
              />
            )}
            {selectedActivityType === 'email' && (
              <ComposeEmail
                contact={contact}
                appProject={appProject}
                onSuccess={fetchActivities}
              />
            )}
            {selectedActivityType === 'meeting' && (
              <ScheduleMeeting
                contact={contact}
                appProject={appProject}
                onSuccess={fetchActivities}
              />
            )}
            {selectedActivityType === 'note' && (
              <CreateNewNote
                contact={contact}
                appProject={appProject}
                onSuccess={fetchActivities}
              />
            )}
            {selectedActivityType === 'task' && (
              <CreateNewTask
                contact={contact}
                appProject={appProject}
                userObject={userObject}
                onSuccess={fetchActivities}
              />
            )}
          </div>
        </div>
        {isLoadingActivity ? (
          <div className='flex flex-col gap-6 pt-8'>
            <Skeleton height={100} />
          </div>
        ) : (
          <>
            {activities?.length > 0 ? (
              <div className='w-full p-5 border rounded-md shadow flex flex-col gap-8'>
                <h2 className='self-stretch m-0 text-softBlack_70/100 text-xl font-semibold leading-none'>
                  Past Activities
                </h2>
                <ActivitiesWrapper items={activities} />
              </div>
            ) : (
              <NoActivityView />
            )}
          </>
        )}
      </div>
      <div className='col-span-1 h-fit flex flex-col gap-8'>
        <DemandTab contact={contact} isInsideActivityTab />
      </div>
    </div>
  );
};

export default NewActivityTab;
