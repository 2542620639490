import React from 'react'
import { Row } from 'antd'
import { Document, Page } from 'react-pdf'
import propTypes from 'prop-types'

const PdfDocument = ({
  documentProps,
  pageNumber,
  pageProps,
  onLoadSuccess
}) => {
  return (
    <Row justify='center'>
      <Document
        {...documentProps}
        file={documentProps.file}
        onLoadSuccess={onLoadSuccess}
        onLoadError={(error) => console.log('pdf load error', error)}
      >
        <Page pageNumber={Number(pageNumber)} {...pageProps} />
      </Document>
    </Row>
  )
}
export default PdfDocument

PdfDocument.propTypes = {
  documentProps: propTypes.shape({
    file: propTypes.any
  }),
  pageNumber: propTypes.number,
  pageProps: propTypes.object,
  onLoadSuccess: propTypes.func
}
