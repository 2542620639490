import React from 'react'
import styled from 'styled-components'
import { MdClose } from 'react-icons/md'
import propTypes from 'prop-types'

import standardStyle from 'assets/css/standardStyle'

const ButtonRemoveIcon = ({ onClick, disabled, style, className }) => {
  return (
    <Container style={style} className={className}>
      <Content onClick={disabled ? () => {} : onClick}>
        <Span>Remover</Span>
        <IconClose />
      </Content>
    </Container>

  )
}

const Container = styled.div`
  margin-top: 25px;
  margin-left: 15px;
`

const Content = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const Span = styled.span`
  color: ${standardStyle.colors.primary};
  text-decoration: underline;
  font-size: 16px;
`

const IconClose = styled(MdClose)`
  font-size: 20px;
  color: ${standardStyle.colors.danger};
  margin-left: 10px;
`

ButtonRemoveIcon.propTypes = {
  onClick: propTypes.func.isRequired,
  disabled: propTypes.bool,
  style: propTypes.object,
  className: propTypes.string
}

export default ButtonRemoveIcon
