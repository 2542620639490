import React from 'react'
import propTypes from 'prop-types'

import { Wrapper } from './styles'

const DrawerChildrenWrapper = ({ children }) => {
  return (
    <Wrapper>
      <>
        {children}
      </>
    </Wrapper>
  )
}

export default DrawerChildrenWrapper

DrawerChildrenWrapper.propTypes = {
  children: propTypes.node
}
