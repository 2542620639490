import {
  CREATE_OFFER_CHANGE_UNIT,
  CREATE_OFFER_CHANGE_BUYER,
  CREATE_OFFER_CHANGE_BROKER,
  CREATE_OFFER_CHANGE_PARKING,
  CREATE_OFFER_CHANGE_COLOR_SCHEME,
  CREATE_OFFER_CHANGE_OPTIONS,
  CREATE_OFFER_CHANGE_FINTRAC,
  CREATE_OFFER_CLEAR_REDUCER,
  CREATE_OFFER_CHANGE_BROKER_COMMISSION
} from 'store/actions/actionTypes'

export const initialState = {
  selectedUnit: {},
  selectedBuyers: [{ _id: -1 }],
  selectedBroker: {},
  selectedParking: {},
  selectedColorScheme: {},
  selectedFintracOptions: {},
  selectedAdditionalOptions: [],
  brokerCommissionFirstRange: '',
  brokerCommissionSecondRange: ''
}

const createOfferReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_OFFER_CHANGE_UNIT:
      return {
        ...state,
        selectedUnit: action.payload
      }

    case CREATE_OFFER_CHANGE_BUYER:
      return {
        ...state,
        selectedBuyers: action.payload,
        selectedBroker: {} // Reset the selected broker to reassign the broker to all buyers
      }

    case CREATE_OFFER_CHANGE_BROKER:
      return {
        ...state,
        selectedBroker: action.payload
      }

    case CREATE_OFFER_CHANGE_PARKING:
      return {
        ...state,
        selectedParking: action.payload
      }

    case CREATE_OFFER_CHANGE_COLOR_SCHEME:
      return {
        ...state,
        selectedColorScheme: action.payload
      }

    case CREATE_OFFER_CHANGE_OPTIONS:
      return {
        ...state,
        selectedAdditionalOptions: action.payload
      }

    case CREATE_OFFER_CHANGE_FINTRAC:
      return {
        ...state,
        selectedFintracOptions: action.payload
      }

    case CREATE_OFFER_CHANGE_BROKER_COMMISSION:
      return {
        ...state,
        brokerCommissionFirstRange: action.payload.brokerCommissionFirstRange,
        brokerCommissionSecondRange: action.payload.brokerCommissionSecondRange
      }

    case CREATE_OFFER_CLEAR_REDUCER:
      return initialState

    default: return state
  }
}

export default createOfferReducer
