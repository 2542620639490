/* eslint-disable */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import propTypes from 'prop-types'
import { Carousel, Row } from 'antd'
import { useHistory } from 'react-router-dom'
import { useAnimation } from 'framer-motion'
import { PwaWrapper, ArrowLeftIcon, ArrowRightIcon } from 'components'
import { Title } from 'ui'
import { getProjects } from 'store/actions/projectActions'
import { fadeVariants } from 'utils'

import {
  ProjectWrapper,
  CarouselContentWrapper,
  CustomArrowIconWrapper
} from './styles'
import { PWA_PROJECT_SCREEN } from 'routes'
import { connect } from 'react-redux'
import { changeAppProject } from 'store/actions/appActions'
import { CgArrowRight } from 'react-icons/cg'

const Projects = ({ changeAppProject }) => {
  const [projects, setProjects] = useState([])
  const [gettingProjects, setGettingProjects] = useState(false)
  const [currentSlide, setCurrentSlide] = useState(0)

  const animatedWrapper = useAnimation()
  const history = useHistory()
  const carouselRef = useRef()

  useEffect(() => {
    if (gettingProjects) {
      animatedWrapper.start('fadeOut')
      return
    }

    animatedWrapper.start('fadeIn')
  }, [gettingProjects, animatedWrapper])

  const _getProjects = useCallback(() => {
    setGettingProjects(true)

    getProjects()
      .then((projects) => {
        setProjects(projects)
        setGettingProjects(false)
      })
      .catch(() => {
        setGettingProjects(false)
      })
  }, [])

  const LoadingProjects = () => (
    <Row justify='center' style={{ marginTop: 50 }}>
      <Title>Loading your projects...</Title>
    </Row>
  )

  const getFormattedNumber = (myNumber) => {
    return myNumber.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false
    })
  }

  const DotsPanel = ({ projectsLength, onDotClicked, currentDotSelected }) => {
    return (
      <div className='flex font-normal text-2xl text-white w-60 content-center items-center align-middle'>
        <p>{getFormattedNumber(1)}</p>
        <ul className='flex-1 text-center justify-center my-auto'>
          {Array.from(Array(projectsLength).keys()).map((value) => (
            <li
              key={value}
              className={`inline-block flex-initial box-content h-[3px] mx-0.5 my-2 p-0 text-center -indent-150vh transition-all duration-6000
              ${currentDotSelected === value ? 'w-6' : ''}`}
            >
              <button
                className={`block w-full h-[3px] p-0 text-transparent rounded-sm outline-none cursor-pointer transition-all duration-6000 bg-white 
                ${currentDotSelected === value ? 'opacity-100' : 'opacity-30'}`}
                onClick={() => {
                  console.log('onDotClicked', value)
                  onDotClicked?.(value)
                }}
              >
                {value}
              </button>
            </li>
          ))}
        </ul>
        <p>{getFormattedNumber(projectsLength)}</p>
      </div>
    )
  }

  const openProject = useCallback(() => {
    history.push({
      pathname: PWA_PROJECT_SCREEN,
      state: { projects, currentProjectIndex: currentSlide }
    })
  }, [history, projects, currentSlide])

  useEffect(() => {
    _getProjects()
  }, [_getProjects])

  return (
    <PwaWrapper
      animate={animatedWrapper}
      initial={false}
      variants={fadeVariants}
    >
      {gettingProjects && projects.length === 0 ? (
        <LoadingProjects />
      ) : (
        <>
          <CustomArrowIconWrapper onClick={() => carouselRef.current.prev()}>
            <ArrowLeftIcon />
          </CustomArrowIconWrapper>
          <Carousel
            dotPosition='bottom'
            beforeChange={(currentSlide, nextSlide) => {
              changeAppProject({
                projectId: projects[nextSlide]._id,
                developerId: projects[nextSlide].developer._id
              })
              setCurrentSlide(nextSlide)
            }}
            style={{ height: '100%' }}
            ref={(ref) => {
              carouselRef.current = ref
            }}
            dots={false}
          >
            {projects.map((project, index) => (
              <CarouselContentWrapper key={index}>
                <ProjectWrapper
                  onLoadedMetadata={() => console.log('onLoadedMetadata')}
                  style={{
                    backgroundImage: `url(${project.backgroundImage})`
                  }}
                  id='projectWrapper'
                >
                  <div className='absolute bg-black bg-opacity-30 w-full h-full top-0 left-0' />
                </ProjectWrapper>
              </CarouselContentWrapper>
            ))}
          </Carousel>
          <CustomArrowIconWrapper
            onClick={() => carouselRef.current.next()}
            style={{ right: 0 }}
          >
            <ArrowRightIcon />
          </CustomArrowIconWrapper>
          <button
            onClick={() => {
              console.log('On project Clicked')
              openProject()
            }}
            className='absolute flex justify-center items-center right-20 bottom-32 w-60 h-16 bg-volt py-4 pl-8 pr-8 text-softBlack space-x-[14.33px] rounded'
          >
            <span className='font-medium text-xl text-softBlack'>
              Open Project
            </span>
            <CgArrowRight className='h-6 w-6' />
          </button>
          <div className='absolute flex flex-col text-white left-[104px] bottom-[104px] bg-blend-overlay'>
            <DotsPanel
              projectsLength={projects.length}
              onDotClicked={(dotIndex) => {
                carouselRef.current.goTo(dotIndex, true)
              }}
              currentDotSelected={currentSlide}
            />
            <span className='font-bold text-7xl mt-8'>
              {projects?.[currentSlide]?.projectName ?? ''}
            </span>
            <span className='font-medium text-lg mt-10 max-w-[720px]'>
              {projects?.[currentSlide]?.projectDescription}
            </span>
          </div>
        </>
      )}
    </PwaWrapper>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {
  changeAppProject
}

export default connect(mapStateToProps, mapDispatchToProps)(Projects)

Projects.propTypes = {
  changeAppProject: propTypes.func
}
