import React, { useContext } from 'react'
import { CreateOpportunityContext } from '../context/CreateOpportunityContext'
import BuyerInfo from './BuyerInfo/BuyerInfo'
import { Formik } from 'formik'
import Form from './Form/Form'
// import DemandNotesForm from './DemandNotesForm/DemandNotesForm'
import { createOrUpdateOpportunity } from 'store/actions/usersActions'
import { ADMIN_OPPORTUNITIES } from 'routes'
import { useHistory } from 'react-router-dom'

const CreatePage = () => {
  const history = useHistory()
  const { selectedBuyers } = useContext(CreateOpportunityContext)
  const onSubmit = (values) => {
    createOrUpdateOpportunity(values).then(() => history.push(ADMIN_OPPORTUNITIES))
  }
  return (
        <Formik
            initialValues={{
              name: null,
              // user: selectedBuyers._id,
              user: '607602b50de444001a71cf35',
              rating: null,
              stage: null,
              floorPlansInterest: [],
              floorNumbersInterest: [],
              numberOfBedrooms: [],
              firstChoiceUnit: null,
              secondChoiceUnit: null,
              thirdChoiceUnit: null,
              minimumSquareFeet: null,
              maximumSquareFeet: null,
              budget: null,
              orientation: null,
              primaryUse: [],
              needParking: false,
              numberOfParkingSpots: null,
              upgrades: [],
              needsFinancing: false,
              notes: null,
              project: selectedBuyers.buyerData.additionalFields[0].project,
              salesRep: selectedBuyers.buyerData.assignments[0].salesRep._id
            }}
            onSubmit={onSubmit}
        >
            {() => <div className="flex flex-row w-full h-full">
            <div className="h-full flex flex-col">
                <div className='h-full flex justify-between items-start relative'>
                  {selectedBuyers && <BuyerInfo contact={selectedBuyers} />}
                </div>
                {/* <DemandNotesForm selectedBuyers={selectedBuyers} /> */}
                {/* <div className='flex flex-col gap-1'>
                    <div>Past Buyer Notes</div>
                    <div style={{ height: '400px' }} className='bg-white rounded'>
                    </div>
                </div> */}
            </div>
            <Form isEdit={false} />
        </div>}
        </Formik>
  )
}

export default CreatePage
