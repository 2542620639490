import React, { useState } from 'react'

import { ReactComponent as UserIcon } from 'assets/icons/user.svg'

import ToggleSwitch from 'components/ToggleSwitch'
import UserInfoCard from 'components/UserInfoCard'
import NewBroker from 'pages/offer/components/NewBrokerOrSalesRep'
import BuyerCard from 'components/BuyerCard'
import { LoadingDetails } from 'pages/desktopMode/inventory/Allocation/AllocateInventory/Steps/Building'

import useGetProjectBrokers from 'hooks/useGetProjectBrokers'
import useGetSalesReps from 'hooks/useGetSalesReps'
import { COLORS } from '../constants/colors'

const PWASelectBroker = ({
  setButtonState,
  selectedBroker,
  setSelectedBroker
}) => {
  const {
    loading: salesRepLoading,
    salesReps,
    refetch: getSalesReps
  } = useGetSalesReps()
  const {
    loading: brokerLoading,
    brokers,
    refetch: getBrokers
  } = useGetProjectBrokers()

  const [isBroker, setIsBroker] = useState(true)
  const [showCreateModal, setShowCreateModal] = useState(false)

  const onSuccess = () => {
    if (isBroker) {
      getBrokers()
    } else {
      getSalesReps()
    }
  }

  if (salesRepLoading || brokerLoading) {
    return (
      <div
        className='flex items-center justify-center w-full h-screen'
        style={{ gridColumn: '1 / -1' }}
      >
        <LoadingDetails title='Loading' />
      </div>
    )
  }

  const listToMap = isBroker ? brokers : salesReps

  return (
    <>
      <NewBroker
        {...{
          showCreateModal,
          setShowCreateModal,
          onSuccess,
          isBroker
        }}
      />
      <div style={{ marginBottom: 130 }} className='mx-20'>
        <div className='flex justify-between my-12 items-center'>
          <p className='text-softBlack font-bold font-openSans text-lg'>
            This Buyer has a {isBroker ? 'Broker' : 'Sales Representative'}
          </p>
          <ToggleSwitch checked={isBroker} setChecked={setIsBroker} />
        </div>
        <div className='grid grid-cols-3 gap-6 mt-10'>
          {listToMap.map((broker, index) => (
            <UserInfoCard
              {...broker}
              key={index}
              onSelect={() => {
                setSelectedBroker(broker)
                setButtonState('Default')
              }}
              // @ts-ignore
              isActive={selectedBroker?.id === broker?.id}
              className=''
              location={broker?.brokerData?.country}
              backgroundColor={COLORS.white}
            />
          ))}
        </div>
        <div className='grid grid-cols-3 gap-6 w-full justify-between mt-6'>
          <BuyerCard
            title={isBroker ? 'Create New Broker' : 'Create New Sales Rep'}
            Icon={UserIcon}
            onClick={() => setShowCreateModal(true)}
            classes=''
            backgroundColor={COLORS.white}
          />
        </div>
      </div>
    </>
  )
}

export default PWASelectBroker
