import React, { useEffect } from 'react'

import SelectReview from 'pages/offer/components/SelectReview'

const PWAReview = ({
  setButtonState,
  selectedUnit,
  selectedBuyers,
  selectedBroker,
  selectedParking,
  isThirdParty,
  riskType,
  selectedOption,
  checkedProperties,
  businessRelationshipExplanation,
  thirdPartDescription,
  thirdPartRecord,
  riskExplanation
}) => {
  useEffect(() => {
    setButtonState('Default')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const createOfferObject = {
    selectedUnit,
    selectedBuyers,
    selectedBroker,
    selectedParkings: selectedParking,
    isThirdParty,
    riskType,
    selectedOption,
    checkedProperties,
    businessRelationshipExplanation,
    thirdPartDescription,
    thirdPartRecord,
    riskExplanation
  }

  return (
    <>
      <div className='mx-20 mt-2'>
        <SelectReview
          setStep={undefined}
          createOfferObject={createOfferObject}
          contextUsed={false}
        />
      </div>
    </>
  )
}

export default PWAReview
