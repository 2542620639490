import React from 'react'
import { AdminPageWrapper, PageHeader, TCancelButton, UnitData } from 'components'
import { useHistory, useLocation } from 'react-router-dom'

const CreateEditUnit = () => {
  const location = useLocation()
  const history = useHistory()

  const {
    state: {
      _id
    }
  } = location

  const RightContent = () => (
    <TCancelButton
      onClick={() => history.goBack()}
    >
      Back
    </TCancelButton>
  )

  return (
    <>
      <PageHeader
        title={`${_id ? 'Editing' : 'Creating new'} unit`}
        rightContent={<RightContent />}
      />
      <AdminPageWrapper>
        <UnitData
          _id={_id}
        />
      </AdminPageWrapper>
    </>
  )
}

export default CreateEditUnit
