/* eslint-disable no-unused-vars */
import React, { useCallback } from 'react'
import { Select } from 'antd'

import { ReactComponent as DownIcon } from '../../assets/icons/down.svg'

export const ParticipantsSelector = ({
  selectedParticipants,
  setSelectedParticipants,
  buyers,
  isHideLabel = false,
  disabled = false
}) => {
  const handleParticipants = useCallback((values) => {
    const filteredBuyers = buyers?.filter(buyer => values.indexOf(buyer.email) !== -1).map(buyer => {
      return { name: `${buyer.firstName} ${buyer.lastName}`, email: buyer.email, comment: '', phone_number: '', status: 'noreply' }
    })

    if (filteredBuyers.length) {
      setSelectedParticipants([...filteredBuyers.slice(0)])
    }
  }, [buyers])

  return (
    <div>
      {!isHideLabel && (
        <p className='mt-0 mb-2 text-softBlack70 text-xs font-medium uppercase' style={{ letterSpacing: '0.16px' }}>
          add participants
        </p>
      )}
      <Select
        mode='multiple'
        placeholder='Select participants'
        value={buyers?.length && selectedParticipants?.length ? selectedParticipants.map((_p) => _p.email) || [] : []}
        style={{
          width: '100%',
          zIndex: 9
        }}
        showArrow
        getPopupContainer={(node) => node.parentNode}
        onChange={handleParticipants}
        loading={true}
        className='ant-picker-input1'
        suffixIcon={!buyers?.length ? undefined : <DownIcon />}
        disabled={disabled}
      >
        {buyers?.length &&
          buyers.map((participant) => (
            <Select.Option
              key={participant.email}
              label={participant.name}
              value={participant.email}
            >
              {participant.name}
            </Select.Option>
          ))}
      </Select>
    </div>
  )
}
