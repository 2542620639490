export const getDayPeriod = () => {
  const hours = new Date().getHours()

  let tmpDayPeriod = ''

  if (hours >= 0 && hours < 12) tmpDayPeriod = 'morning'
  if (hours >= 12 && hours < 18) tmpDayPeriod = 'afternoon'
  if (hours >= 18 && hours < 24) tmpDayPeriod = 'evening'

  return tmpDayPeriod
}
