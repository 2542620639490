import CustomButton from 'components/CustomButton'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { ADMIN_OPPORTUNITIES } from 'routes'

const Header = () => {
  const history = useHistory()

  return (
    <div className='flex flex-row justify-between items-center w-full p-5 border-b-2 border-black'>
      <div style={{ fontSize: '40px' }} className='font-bold'>Create Opportunity</div>
      <CustomButton
        variant='ghost'
        handleClick={() => history.replace(ADMIN_OPPORTUNITIES)}
      >
        <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
          Cancel
        </span>
      </CustomButton>
    </div>
  )
}

export default Header
