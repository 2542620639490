import React, { useState, useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
import UserData from '../../../components/UserData'
import { AdminPageWrapper, FadedAnimatedDiv, ImageIcon, ImageUpload, PageHeader, TCancelButton } from 'components'
import { createUploadFileList, getFileUrl, updateLoggedUserObject } from 'utils'
import { changeUserAvatarImage } from 'store/actions/usersActions'
import { AnimatePresence } from 'framer-motion'
import { Modal, TButton } from 'ui'
import { useHistory } from 'react-router-dom'
import useUpdateUsers from 'hooks/useUpdateUsers'

const ProfilePage = (props) => {
  const {
    userObject: {
      firstName,
      lastName,
      _id,
      userAvatar
    }
  } = props

  const history = useHistory()
  const { createOrUpdateUser } = useUpdateUsers()
  const [showChangeImageModal, setShowChangeImageModal] = useState(false)
  const [profileImages, setProfileImages] = useState([])
  const [uploadingProfileImage, setUploadingProfileImage] = useState(false)
  const [removingProfileImage, setRemovingProfileImage] = useState(false)

  useEffect(() => {
    let tmpUserAvatar = []

    if (userAvatar) {
      tmpUserAvatar = createUploadFileList(userAvatar)
    }

    setProfileImages(tmpUserAvatar)
  }, [userAvatar])

  const openUserImageModal = () => {
    setShowChangeImageModal(true)
  }

  const UserImageUploader = useCallback(() => (
    <ImageUpload
      uploadProps={{
        name: 'imageUpload',
        fileList: profileImages,
        listType: 'picture-card',
        className: 'avatar-uploader',
        showUploadList: true,
        multiple: false,
        onChange: ({ fileList }) => {
          setProfileImages(fileList)
        },
        beforeUpload: () => {
          if (!_id) {
            return false
          }
        },
        customRequest: async (componentData) => {
          if (_id) {
            const { file } = componentData
            const tmpUrl = await getFileUrl(file)
            const tmpArray = Array.from({ length: 1 }, () => ({ url: tmpUrl }))

            setProfileImages(tmpArray)
            setUploadingProfileImage(true)

            changeUserAvatarImage(file)
              .then(() => {
                setUploadingProfileImage(false)
                updateLoggedUserObject()
                setShowChangeImageModal(false)
              })
              .catch(() => {
                setUploadingProfileImage(false)
              })
          }
        },
        style: { height: '100%', width: '100%' }
      }}
      showUploadButton={profileImages.length === 0}
      uploadButtonText='Click or drag here the User Profile Image'
      uploadButtonIcon={<ImageIcon style={{ fontSize: 30 }} />}
      uploadWrapperStyle={{
        width: '100%',
        marginRight: 0
      }}
      skeletonHeight={100}
      title=''
    />
  ), [_id, profileImages])

  const removeProfileImage = useCallback(() => {
    setProfileImages([])
    setRemovingProfileImage(true)

    const params = {
      id: _id,
      userAvatar: ''
    }

    createOrUpdateUser(params, false)
      .then(() => {
        updateLoggedUserObject()
        setRemovingProfileImage(false)
      })
      .catch(() => {
        setRemovingProfileImage(false)
      })
  }, [_id])

  const RightContent = () => (
    <TCancelButton
      onClick={() => history.goBack()}
    >
      Back
    </TCancelButton>
  )

  return (
    <>
      <Modal
        showModal={showChangeImageModal}
        onCancel={() => setShowChangeImageModal(false)}
        modalType='custom'
      >
        <AnimatePresence initial={false} exitBeforeEnter>
          {
            profileImages.length
              ? (
              <FadedAnimatedDiv
                key='profileImage'
                className='w-full flex justify-center flex-col items-center'
              >
                <img alt='User' src={profileImages[0].url} className='rounded-full h-40 w-40' />
                <TButton
                  className='mt-2'
                  onClick={() => removeProfileImage()}
                  loading={uploadingProfileImage || removingProfileImage}
                >
                  {uploadingProfileImage ? 'Uploading image' : 'Remove image'}
                </TButton>
              </FadedAnimatedDiv>
                )
              : (
              <FadedAnimatedDiv
                key='profileAvatarUpload'
              >
                <UserImageUploader />
              </FadedAnimatedDiv>
                )
          }
        </AnimatePresence>
      </Modal>
      <PageHeader
        title={`Hi, ${firstName} ${lastName}`}
        showParameters
        rightContent={<RightContent />}
        image={userAvatar}
        onImageClick={() => openUserImageModal()}
        showEmptyImage
      />
      <AdminPageWrapper>
        <UserData
          id={_id}
        />
      </AdminPageWrapper>
    </>
  )
}

const mapStateToProps = (state) => ({
  userObject: state.authReducer.userObject
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage)

ProfilePage.propTypes = {
  userObject: propTypes.object
}
