import React, { useMemo } from 'react'
import { formatMoney } from 'utils'
import moment from 'moment'
import { H5 } from 'ui'
import ContractsTable from '../../ContractTable'

const DepositsTab = ({ installments }) => {
  const tableDataSource = useMemo(() => {
    return installments.map((installment) => {
      return {
        id: installment._id,
        amount: formatMoney(installment.value),
        status: installment.status,
        dueDate: installment.dueDate,
        paymentMethodType: installment.paymentMethodType
      }
    })
  }, [installments])

  const tableColumns = useMemo(
    () => [
      {
        Title: (
          <span className='flex items-center space-x-2'>
            <span className='leading-none text-sm font-normal text-[#2E2B2E] dark:text-zinc-200 text-opacity-70'>Amount</span>
          </span>
        ),
        accessor: 'amount',
        dataIndex: 'amount',
        Cell: (props) => <p className='mb-0 text-base font-normal dark:text-white'>{props.cell.value}</p>
      },
      {
        Title: (
          <span className='flex items-center space-x-2'>
            <span className='leading-none text-sm font-normal text-[#2E2B2E] dark:text-zinc-200 text-opacity-70'>Status</span>
          </span>
        ),
        accessor: 'status',
        dataIndex: 'status',
        Cell: (props) => <p className='mb-0 text-base font-normal dark:text-white'>{props.cell.value}</p>
      },
      {
        Title: (
          <span className='flex items-center space-x-2'>
            <span className='leading-none text-sm font-normal text-[#2E2B2E] text-opacity-70'>Due date</span>
          </span>
        ),
        accessor: 'dueDate',
        dataIndex: 'dueDate',
        Cell: (props) => <p className='mb-0 text-base font-normal'>{moment(props.cell.value).format('DD MMM YYYY, hh:mm a')}</p>
      },
      {
        Title: (
          <span className='flex items-center space-x-2'>
            <span className='leading-none text-sm font-normal text-[#2E2B2E] text-opacity-70'>Payment type</span>
          </span>
        ),
        accessor: 'paymentMethodType',
        dataIndex: 'paymentMethodType',
        Cell: (props) => <p className='mb-0 text-base font-normal'>{props.cell.value}</p>
      },
      {
        Title: '',
        accessor: 'id',
        Cell: (props) => (
          <div className='w-full flex flex-row justify-end items-center gap-2 text-center'>
            <button className='self-stretch px-4 py-2 bg-volt rounded justify-start items-center gap-2 flex'>
              <svg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <g clipPath='url(#clip0_100_154)'>
                  <path d='M7.49994 14L4.58327 11.0833C4.25827 10.7583 3.7416 10.7583 3.4166 11.0833C3.0916 11.4083 3.0916 11.925 3.4166 12.25L6.90827 15.7416C7.23327 16.0666 7.75827 16.0666 8.08327 15.7416L16.9166 6.91664C17.2416 6.59164 17.2416 6.07498 16.9166 5.74998C16.5916 5.42498 16.0749 5.42498 15.7499 5.74998L7.49994 14Z' fill='#2E2B2E' />
                </g>
                <defs>
                  <clipPath id='clip0_100_154'>
                    <rect width='20' height='20' fill='white' transform='translate(0 0.5)' />
                  </clipPath>
                </defs>
              </svg>
              <span className='text-center text-softBlack_70/100 text-sm font-medium leading-[21px]'>Record payment</span>
            </button>
          </div>
        )
      }
    ],
    []
  )

  const MemoTable = ({
    dataSource,
    columns,
    loading,
    idBeingDeleted,
    deleting,
    showTablePagination
  }) => {
    return (
      <ContractsTable
        className='rounded-lg'
        dataSource={dataSource}
        columns={columns}
        tailwindTable
        loading={loading}
        idBeingDeleted={idBeingDeleted}
        deleting={deleting}
        isFromContracts
        showTablePagination={showTablePagination}
        noMargin
      />
    )
  }

  return (
    <div>
      <H5 className='text-lg font-semibold mb-0'>
        Deposits
      </H5>
      <div className='mt-5 w-full'>
        <MemoTable
          dataSource={tableDataSource}
          columns={tableColumns}
          loading={false}
          idBeingDeleted={''}
          deleting={false}
          showTablePagination={false}
        />
      </div>
    </div>
  )
}

export default DepositsTab
