import React from 'react'

import DashboardTable from 'components/DashboardTable'
import { data } from './dummyData'
import Row from './Row'

const TopUnitsTable = () => {
  return (
    <DashboardTable
      title="Top-10 Units"
      columnNames={['Unit', 'Building', 'Amount', '']}
      data={data}
      RowToRender={Row}
      showMore
      showIndex
    />
  )
}

export default TopUnitsTable
