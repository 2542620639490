import ProgressBar from 'components/ProgressBar'
import React, { useMemo } from 'react'

export const StatsLarge = ({
  stats = {
    'REMAINING UNITS': 18,
    'TOTAL AMOUNT': 'CA$12,450,000',
    'AVE PRICE PER SQ. FT': 'CA$850'
  },
  progressStats = [
    {
      title: 'sold',
      value: 0.15,
      progressColor: '#2E2B2E',
      progressBackgroundColor: 'inherit'
    },
    {
      title: 'remaining',
      value: 1,
      progressColor: '#EDDF65',
      progressBackgroundColor: 'inherit'
    }
  ]
}) => {
  const length = useMemo(() => {
    return Object.keys(stats).length - 1
  }, [stats])

  const total = useMemo(() => {
    return progressStats.reduce((prev, curr) => prev + curr.value, 0)
  }, [progressStats])

  return (
    <div
      className='p-6 flex flex-col gap-6 bg-grey-8'
      style={{ width: ' 100%', borderRadius: 4 }}
    >
      <div>
        {Object.keys(stats).map((item, index) => (
          <div key={index}>
            <p
              className='font-medium text-xs text-softBlack70'
              style={{ marginBottom: 6, marginTop: index === 0 ? 0 : 20 }}
            >
              {item}
            </p>
            <p
              className={`text-xl font-bold text-softBlack mb-0 ${
                index !== length && 'border-b'
              } border-softBlack30 pb-5`}
            >
              {stats[item]}
            </p>
          </div>
        ))}
      </div>
      <div className='flex flex-col gap-2'>
        <div className='flex gap-6 mb-4 flex-wrap'>
          {progressStats.map((item, index) => (
            <div className='flex items-center gap-2' key={index}>
              <div
                className={'w-3 h-3 rounded-sm'}
                style={{ backgroundColor: item.progressColor }}
              ></div>
              <span className='font-normal text-sm capitalize'>
                {item.title}
              </span>
            </div>
          ))}
        </div>
        {progressStats.map((item, index) => (
          <ProgressBar
            key={index}
            currentPercent={item.value / total}
            progressColor={item.progressColor}
            backgroundColor={item.progressBackgroundColor}
          />
        ))}
      </div>
    </div>
  )
}
