import { useCallback, useState } from 'react'
import { getGenericGetEndPoint } from 'store/actions/usersActions'

export const useGetAPI = () => {
  const [result, setResult] = useState(null)
  const [loading, setLoading] = useState(false)

  const fetch = useCallback((endPoint) => {
    setLoading(true)
    getGenericGetEndPoint(endPoint)
      .then((data) => {
        setResult(data)
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
        console.log('Failed to load data for', endPoint, e)
      })
  }, [])

  return {
    data: result,
    loading,
    fetch
  }
}
