import React, { useState } from 'react'
import propTypes from 'prop-types'
import { AdminPageWrapper, PageHeader } from 'components'
import { TFilledPlusIcon } from 'components/Icons'
import { connect } from 'react-redux'
import ProjectSingleSendContractDocuments from 'components/ProjectSingleSendContractDocuments'
import CustomButton from 'components/CustomButton'

const SingleSendContractDocuments = (props) => {
  const { appProject } = props
  const [isCreateSinglePageCampaign, setIsCreateSinglePageCampaign] = useState(false)

  const RightContent = () => (
    <div className='flex flex-row items-center'>
      <CustomButton
        disabled={!appProject}
        handleClick={() => setIsCreateSinglePageCampaign(true)}
      >
        <span className='flex items-center gap-2 px-4 py-2'>
          <TFilledPlusIcon className='h-6 w-6' />
          Create single page campaigns
        </span>
      </CustomButton>
    </div>
  )

  return (
    <>
      <PageHeader
        title='Single Send Contract Documents'
        rightContent={<RightContent />}
      />
      <AdminPageWrapper style={{ height: 'calc(100vh - 100px)' }} fullscreen>
        <ProjectSingleSendContractDocuments
          isCreateSinglePageCampaign={isCreateSinglePageCampaign}
          setIsCreateSinglePageCampaign={setIsCreateSinglePageCampaign}
        />
      </AdminPageWrapper>
    </>
  )
}

const mapStateToProps = (state) => ({
  appProject: state.appReducer.appProject
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(SingleSendContractDocuments)

SingleSendContractDocuments.propTypes = {
  appProject: propTypes.string
}
