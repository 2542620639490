/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable */
// @ts-nocheck

import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getProjects } from 'store/actions/projectActions'

const useGetProjectParkings = () => {
    const appProject = useSelector((state) => state.appReducer.appProject)

    const [parkings, setParkings] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getData()
    }, [appProject])

    const getData = async () => {
        setLoading(true)
        try {
            const data = await getProjects(appProject)
            const { options } = data
            const parkingOptions = options
                .filter((option) => option.type === 'parking')
            setParkings(parkingOptions)
            setLoading(false)
        } catch (err) {
            setLoading(false)
        }
    }

    return { loading, parkings, refetchParkings: getData }
}

export default useGetProjectParkings
