import { api, ApiErrors } from 'services/api'
import { message } from 'antd'

export const getTasks = (id = '', filter = '') => new Promise((resolve, reject) => {
  const params = new URLSearchParams(filter).toString()
  api.get(`tasks/all/${id}?${params}`)
    .then((response) => {
      if (response.ok) {
        resolve(response.data.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const getTasksTodayCount = () => new Promise((resolve, reject) => {
  api.get('/tasks/today/count')
    .then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const getTasksBySalesRepId = (id = '', filter = '') => new Promise((resolve, reject) => {
  api.get(`tasks/salesRep/${id}${filter}`)
    .then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const createOrUpdateTask = (props) => new Promise((resolve, reject) => {
  const { _id, ...params } = props

  // If id, so we have to update the record, or we're gonna create
  const verb = _id ? 'put' : 'post'
  const url = _id ? `tasks/${_id}` : 'tasks'

  api[verb](url, params)
    .then((response) => {
      if (response.ok) {
        message.success(`Task ${_id ? 'successfully updated' : 'created successfully'}`)
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const deleteTask = (id) => new Promise((resolve, reject) => {
  api.delete(`/tasks/${id}`)
    .then((response) => {
      if (response.ok) {
        message.success('Task deleted successfully')
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const getUpcomingTasks = (id) => new Promise((resolve, reject) => {
  api.get(`/tasks/list/user/${id}`)
    .then(response => {
      if (response.ok) return resolve(response.data)
      ApiErrors(response)
      reject()
    })
})
