import styled from 'styled-components'

export const AnimatedTransitionWrapper = styled.div`
.slide-enter {
  margin-left: ${props => props.history.action === 'PUSH' ? '3000px' : '-3000px'};
}
.slide-enter.slide-enter-active {
  margin-left: 0px;
  transition: margin-left 300ms;
}
.slide-exit {
  margin-left: 0;
}
.slide-exit.slide-exit-active {
  margin-left: ${props => props.history.action === 'PUSH' ? '-3000px' : '3000px'};
  transition: margin-left 300ms;
}

.fade-enter {
    opacity: 0.01;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
}

.fade-exit {
    opacity: 1;
}

.fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
}

div.transition-group {
  position: relative;
}

section.route-section {
  width: 100%;
  top: 0;
  left: 0;
  margin-bottom: 0px;
}
`
