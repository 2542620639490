import { message } from 'antd'
import { api, ApiErrors } from 'services/api'
import { getRandomInteger } from 'utils'

export const uploadPrivateDocument = (file, user, corporation) => new Promise((resolve, reject) => {
  const formData = new FormData()
  const fileName = file.name || getRandomInteger()

  formData.append('documentName', fileName)
  formData.append('file', file)

  if (user) {
    formData.append('user', user)
  }

  if (corporation) {
    formData.append('corporation', corporation)
  }

  api.post('privatedocuments/upload', formData)
    .then((response) => {
      if (response.ok) {
        message.success(`${fileName} was uploaded successfully`)
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const getCorporationPrivateDocs = (corporationID) => new Promise((resolve, reject) => {
  api.get(`privatedocuments/corporation/${corporationID}`)
    .then((response) => {
      if (response.ok) {
        resolve(response.data.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const deletePrivateDocs = (privateDocID) => new Promise((resolve, reject) => {
  api.delete(`privatedocuments/${privateDocID}`)
    .then((response) => {
      if (response.ok) {
        message.success('Document was deleted successfully')
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
})
