import React from 'react'
import { ReactComponent as DownIcon } from 'assets/icons/down.svg'
import { useFormikContext } from 'formik'
import { Select } from 'antd'

const BooleanFormSelect = ({
  title = '',
  fieldName = '',
  placeholder = ''
}) => {
  const { setFieldValue, values } = useFormikContext()
  return (
    <div key={fieldName} className='flex flex-1 flex-col justify-left'>
      <div className='font-bold text-softBlack_70 italic'>{title}</div>
      <Select
        suffixIcon={<DownIcon />}
        showArrow
        onChange={(e) => setFieldValue(fieldName, e)}
        defaultValue={values[fieldName]}
        placeholder={placeholder}
        className='rating-select text-sm bg-white border border-softBlack30 rounded text-softBlack placeholder-softBlack70 h-10'
      >
        <Select.Option value={true}>
          <div className='flex gap-2 items-center'>
            <span className='h-full'>Yes</span>
          </div>
        </Select.Option>
        <Select.Option value={false}>
          <div className='flex gap-2 items-center'>
            <span className='h-full'>No</span>
          </div>
        </Select.Option>
      </Select>
    </div>
  )
}

export default BooleanFormSelect
