import React from 'react'
import CategoryButton from 'components/AdminSideBar/CategoryButton'

export const CATEGORY_FILTER_SALES_TYPE = [
  {
    title: 'Buyers',
    key: 'buyer',
    value: 'buyer'
  },
  {
    title: 'Brokers',
    key: 'broker',
    value: 'broker'
  },
  {
    title: 'Unassigned',
    key: 'unassigned',
    value: 'unassigned'
  }
]

const FilterBySalesType = ({
  selected,
  onSelectChange,
  loading,
  length = null,
  contacts = null
}) => {
  return (
    <div className='w-full border-1.5 border-softBlack flex rounded-sm h-9.5 mt-2 divide-x-2'>
      {CATEGORY_FILTER_SALES_TYPE.map((_value) => (
        <CategoryButton
          key={_value.title}
          category={_value}
          selected={selected === _value?.value}
          setSelectedCategory={loading ? () => {} : onSelectChange}
          // @ts-ignore
          disabled={loading}
        >
          {`${_value.title} ${
            !loading ? `(${length ?? contacts?.[_value?.value]?.length})` : ''
          }`}
        </CategoryButton>
      ))}
    </div>
  )
}

export default FilterBySalesType
