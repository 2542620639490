import React from 'react'
import { isMobile } from 'react-device-detect'

const Card = ({ item, active, index, setActive }) => {
  const handleClick = () => {
    setActive?.(index)
    item?.onClick?.()
  }

  return (
    <div
      onClick={handleClick}
      className={`${
        active ? 'bg-volt' : 'bg-white'
      } cursor-pointer flex flex-col gap-2.5 p-5 overflow-hidden rounded`}
      style={{ minWidth: isMobile ? undefined : '220px' }}
    >
      <div className='text-xs text-zinc-500 uppercase italic'>{item.title}</div>
      <span className='font-bold text-base'>{item?.number}</span>
    </div>
  )
}

export default Card
