/* eslint-disable */
// @ts-nocheck
import React, { createContext, useCallback, useEffect, useState } from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { getUnits } from 'store/actions/unitActions'
import FilterDrawer from './components/FilterDrawer'
import FilterToggle from './components/FilterToggle/FilterToggle'
import SearchBox from './components/SearchBox/SearchBox'
import { HiOutlineDownload } from 'react-icons/hi'
import useProjectMapContext from './hooks/useProjectMapContext'
import UnitInfoDrawer from './components/UnitInfoDrawer'
import {AVAILABILITY_BG_COLOR, AVAILABILITY_STATUS, classNames} from 'utils'

const filterOptions = [
  {
    value: AVAILABILITY_STATUS.open,
    bgColor: AVAILABILITY_BG_COLOR.available
  },
  {
    value: AVAILABILITY_STATUS.reservedBroker,
    bgColor: AVAILABILITY_BG_COLOR.reserved
  },
  {
    value: AVAILABILITY_STATUS.soldCommitted,
    bgColor: AVAILABILITY_BG_COLOR.sold
  },
  {
    value: AVAILABILITY_STATUS.underContract,
    bgColor: AVAILABILITY_BG_COLOR.undercontract
  },
  {
    value: AVAILABILITY_STATUS.developerHeld,
    bgColor: AVAILABILITY_BG_COLOR.developerheld
  }
]


const getValuesFromLocalStorage = () => {
  const localData = localStorage.getItem('polygonsData')
  if (!localData) {
    localStorage.setItem('polygonsData', JSON.stringify([]))
  }
  return JSON.parse(localData || []) ?? []
}

const ProjectMap = ({ appProject: projectId }) => {
  const { state, openUnitInfoDrawer } = useProjectMapContext()
  const [gettingData, setGettingData] = useState(true)
  // Units Data
  const [unitsData, setUnitsData] = useState([])

  const MAP_API_KEY = 'AIzaSyDCj_iKzflRXqK8NGXj_WUMbcqmiwTSVIU'
  const YOUR_METERS_PER_PIXEL = 0.02
  const POLYGON_YOUR_METERS_PER_PIXEL = 0.0001
  const YOUR_METERS_PER_DEGREE_LAT = 5000 // 10000
  const YOUR_METERS_PER_DEGREE_LNG = 5000

  const [map, setMap] = useState(null)
  const [drawingManager, setDrawingManager] = useState(null)
  const [polygonsData, setPolygonsData] = useState(getValuesFromLocalStorage)

  const updatePolygons = () => {
    if (!polygonsData?.length || !map) {
      return
    }
    const colorCodes = ['#FFB7FF', '#3BF4FB', '#CAFF8A', '#826AED']
    const polygons = polygonsData.map((data, index) => {
      const val = {
        coordinates: data,
        fillColor: colorCodes[index % colorCodes.length],
      }
      return val
    })

    // Add a click event listener to show a custom info-window
    const infoWindowContent =
      '<div><h3>Custom Info-Window</h3><p>Additional information goes here.</p></div>'
    const infoWindow = new window.google.maps.InfoWindow({
      content: infoWindowContent,
    })

    polygons.forEach((polygonData, index) => {
      const selectedUnitsData = unitsData?.[index] ?? {}
      const {
        sector,
        salesLotNumber,
        lotType,
        collection,
        buildableDimensions,
      } = selectedUnitsData?.lotData ?? {}

      const customPolygon = new window.google.maps.Polygon({
        paths: polygonData.coordinates,
        strokeColor: '#FF0000',
        strokeOpacity: 1, //0.8,
        strokeWeight: 2,
        fillColor: polygonData.fillColor,
        fillOpacity: 1, // 0.35,
        map: map,
      })

      window.google.maps.event.addListener(
        customPolygon,
        'click',
        function (event) {
          infoWindow.setPosition(event.latLng)
          infoWindow.setContent(`
          <div style={
            display: flex
            width: 100%
            flex-direction: column
          }>
            <span>Lot ${index + 1}</span>
            <span>(lotType:${lotType} sector:${sector}\n\n salesLotNumber:${salesLotNumber} collection:${collection} buildableDimensions:${buildableDimensions})</span>
            <span>Available</span>
            <span>$${selectedUnitsData?.price} at ${
            selectedUnitsData?.fullAddress
          }</span>
          </div>`)
          infoWindow.open(map)
          openUnitInfoDrawer();
        },
      )
    })
  }

  const overlayClickListener = (event) => {
    const cPolygon = event.overlay
      .getPath()
      .getArray()
      .map((a) => ({ lat: a.lat(), lng: a.lng() }))
    setPolygonsData([...polygonsData, cPolygon])
  }

  useEffect(() => {
    if (drawingManager) {
      google.maps.event.addListener(
        drawingManager,
        'overlaycomplete',
        overlayClickListener,
      )
    }

    if (polygonsData?.length > 0) {
      localStorage.setItem('polygonsData', JSON.stringify(polygonsData))
      updatePolygons()
    }
    return () => {
      if (drawingManager) {
        google.maps.event.clearListeners(drawingManager, 'overlaycomplete')
      }
    }
  }, [polygonsData, drawingManager])

  const initMap = () => {
    // Geocode the address to get coordinates
    const geocoder = new window.google.maps.Geocoder()
    geocoder.geocode(
      {
        address:
          '14945 S Padre Island Dr, Corpus Christi, TX 78418, United States',
      },
      (results, status) => {
        if (status === 'OK' && results[0].geometry) {
          // const location = results[0].geometry.location
          const centerPoint = {
            // lat: location.lat(),
            // lng: location.lng(),
            lat: 27.613171,
            lng: -97.22732,
          }

          // Image size in pixels
          const imageSize = { width: 1097, height: 1648 }

          // Calculate half of the width and height in meters
          const halfWidth = imageSize.width * YOUR_METERS_PER_PIXEL
          const halfHeight = imageSize.height * YOUR_METERS_PER_PIXEL

          // Calculate the southwest and northeast corners of the image
          const imageSW = {
            lat: centerPoint.lat - halfHeight / YOUR_METERS_PER_DEGREE_LAT,
            lng: centerPoint.lng - halfWidth / YOUR_METERS_PER_DEGREE_LNG,
          }

          const imageNE = {
            lat: centerPoint.lat + halfHeight / YOUR_METERS_PER_DEGREE_LAT,
            lng: centerPoint.lng + halfWidth / YOUR_METERS_PER_DEGREE_LNG,
          }

          // Output the values
          const newMap = new google.maps.Map(document.getElementById('map'), {
            zoom: 16,
            center: centerPoint,
            rotateControl: true, // Enable rotate control
            rotateControlOptions: {
              position: window.google.maps.ControlPosition.TOP_CENTER, // Position of the rotate control
            },
            heading: 90, // In
            // mapTypeId: "satellite",
          });
          // Add a marker at the geocoded location
          // const marker = new window.google.maps.Marker({
          //   position: location,
          //   map: newMap,
          //   title: 'Marker at Geocoded Location',
          // })

          // Add a custom image overlay
          const bounds = new window.google.maps.LatLngBounds(
            new window.google.maps.LatLng(imageSW.lat, imageSW.lng),
            new window.google.maps.LatLng(imageNE.lat, imageNE.lng),
          )
          // The photograph is courtesy of the U.S. Geological Survey.
          const srcImage =
            // `${window.location.origin}/abc.jpg`
            `${window.location.origin}/phase1-homesite-map.png`
          // 'https://whitecaplivingnpi.com/wp-content/uploads/2023/07/Phase1-Homesite-Map.jpg'

          // const newMap = new window.google.maps.Map(
          //   document.getElementById('map'),
          //   {
          //     center: centerPoint,
          //     zoom: 14,
          //   },
          // )

          // const customImageOverlay = new window.google.maps.GroundOverlay(
          //   // 'project-map.jpg',
          //   'https://whitecaplivingnpi.com/wp-content/uploads/2023/07/Phase1-Homesite-Map.jpg',
          //   imageBounds,
          // )
          class USGSOverlay extends google.maps.OverlayView {
            bounds_
            image_
            div_
            constructor(bounds, image) {
              super()
              // Initialize all properties.
              this.bounds_ = bounds
              this.image_ = image
              // Define a property to hold the image's div. We'll
              // actually create this div upon receipt of the onAdd()
              // method so we'll leave it null for now.
              this.div_ = null
            }
            /**
             * onAdd is called when the map's panes are ready and the overlay has been
             * added to the map.
             */
            onAdd() {
              this.div_ = document.createElement('div')
              this.div_.style.borderStyle = 'none'
              this.div_.style.borderWidth = '0px'
              this.div_.style.position = 'absolute'

              // Create the img element and attach it to the div.
              const img = document.createElement('img')

              img.src = this.image_
              img.style.width = '100%'
              img.style.height = '100%'
              img.style.position = 'absolute'
              this.div_.appendChild(img)

              // Add the element to the "overlayLayer" pane.
              const panes = this.getPanes()

              panes.overlayLayer.appendChild(this.div_)
            }
            draw() {
              // We use the south-west and north-east
              // coordinates of the overlay to peg it to the correct position and size.
              // To do this, we need to retrieve the projection from the overlay.
              const overlayProjection = this.getProjection()
              // Retrieve the south-west and north-east coordinates of this overlay
              // in LatLngs and convert them to pixel coordinates.
              // We'll use these coordinates to resize the div.
              const sw = overlayProjection.fromLatLngToDivPixel(
                this.bounds_.getSouthWest(),
              )
              const ne = overlayProjection.fromLatLngToDivPixel(
                this.bounds_.getNorthEast(),
              )

              // Resize the image's div to fit the indicated dimensions.
              if (this.div_) {
                this.div_.style.left = sw.x + 'px'
                this.div_.style.top = ne.y + 'px'
                this.div_.style.width = ne.x - sw.x + 'px'
                this.div_.style.height = sw.y - ne.y + 'px'
              }
            }
            /**
             * The onRemove() method will be called automatically from the API if
             * we ever set the overlay's map property to 'null'.
             */
            onRemove() {
              if (this.div_) {
                this.div_.parentNode.removeChild(this.div_)
                this.div_ = null
              }
            }
          }

          const overlay = new USGSOverlay(bounds, srcImage)
          overlay.setMap(newMap)

          // Add a custom polygon with info-window
          // const polygonCoordinates = [
          //   { lat: imageSW.lat, lng: imageSW.lng },
          //   // { lat: imageSW.lat, lng: imageSW.lng },
          //   // { lat: imageSW.lat, lng: imageSW.lng },
          //   // Add more polygon vertices as needed
          // ]

          function getRandomColor(value) {
            var letters = '0123456789ABCDEF'
            var color = '#'
            for (var i = 0; i < 6; i++) {
              color += letters[Math.floor((value / 10) * 16)]
            }
            return color
          }

          // let currentCenterPoint = centerPoint

          const _drawingManager = new google.maps.drawing.DrawingManager({
            drawingMode: google.maps.drawing.OverlayType.POLYGON,
            drawingControl: true,
            drawingControlOptions: {
              position: google.maps.ControlPosition.TOP_CENTER,
              drawingModes: [google.maps.drawing.OverlayType.POLYGON],
            },
            polygonOptions: {
              editable: true,
            },
          })
          _drawingManager.setMap(newMap)

          setMap(newMap)
          updatePolygons()
          setDrawingManager(_drawingManager)
        } else {
          console.error('Geocoding failed:', status)
        }
      },
    )
  }

  useEffect(() => {
    // Load the Google Maps API script dynamically
    const script = document.createElement('script')
    document.head.appendChild(script)
    if (!gettingData) {
      script.src = `https://maps.googleapis.com/maps/api/js?key=${MAP_API_KEY}&libraries=drawing`
      script.async = true
      script.onload = () => {
        initMap()
      }
    }

    // Clean up the script on component unmount
    return () => {
      document.head.removeChild(script)
    }
  }, [projectId, unitsData, gettingData])

  const _getUnits = useCallback(() => {
    setGettingData(true)
    getUnits()
      .then((units) => {
        setUnitsData(units)
        setGettingData(false)
      })
      .catch(() => {
        setGettingData(false)
      })
  }, [])

  useEffect(() => {
    _getUnits()
  }, [_getUnits])

  return (
    <div className='w-full h-full'>
      <div className='flex w-full h-full'>
        <div
          className={classNames(
            `flex flex-col w-full max-h-full overflow-auto transition-all`,
            // state.isFilterDrawerOpen ? 'ml-[280px]' : 'ml-0',
            state.isUnitInfoDrawerOpen ? 'mr-[280px]' : 'mr-0',
          )}
        >
          <div className={`flex flex-row p-4 items-center gap-4 justify-start`}>
            <FilterToggle />
            <SearchBox />
            {filterOptions.map((item, index) => {
              return (
                <button
                  value={item.value}
                  key={`${item.value}-${index}`}
                  className={`flex w-[113px] rounded-sm items-center h-full content-center capitalize`}
                  onClick={() => {
                  }}
                  style={{
                    backgroundColor: item.bgColor,
                    opacity: 1.0
                  }}
                >
                  <span
                    className={
                      'w-full text-center font-medium text-sm text-softBlack'
                    }
                  >
                    {item.value}
                  </span>
                </button>
              );
            })}
            {/* <button
                className='w-[129px] h-[45px] px-3 py-2 bg-volt rounded justify-center items-center inline-flex font-medium'
                key={index}
                onClick={onClick}
              >
                <div className='ml-2 italic'>{title}</div>
              </button>
            ))} */}
          </div>
          <div id='map' style={{ width: '100%', height: '95%' }}></div>
          <FilterDrawer />
        </div>
        <UnitInfoDrawer />
      </div>
    </div>
  );

}

const mapStateToProps = (state) => ({
  appProject: state.appReducer.appProject,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectMap)

ProjectMap.propTypes = {
  appProject: propTypes.string,
}
