// @ts-nocheck
import React, { useEffect, useMemo, useState } from 'react'
import { Oval } from 'react-loader-spinner'
import CustomInput from 'components/CustomInput'
import CustomSelect from 'components/CustomSelect'
import { getProjectUnits } from 'store/actions/unitActions'
import { useSelector } from 'react-redux'
import { ADMIN_OFFER } from 'routes'
import { useHistory } from 'react-router-dom'
import { createOrUpdateStorage } from 'store/actions/storageActions'
import ProjectFileSelector from 'components/ProjectFileSelector'
import CustomBadge from './CustomBadge'
import useInventory from '../hooks/useInventory'
import DeleteModal from 'components/DeleteModal'
import { deleteStoragePolygon } from 'store/actions/storagePolygonActions'
import { Check, ShoppingBag, Trash2 } from 'lucide-react'

const TEMP_AVAILABILITY_STATUS = {
  available: 'Available',
  assigned: 'Assigned',
  reserved: 'Reserved',
  allocated: 'Allocated',
  rescission: 'Rescission',
  sold: 'Sold'
}

const StorageEditView = ({
  editDetails,
  setEditDetails,
  refetch,
  closeStorageInfoDrawer
}) => {
  const history = useHistory()
  // @ts-ignore
  const appProject = useSelector((state) => state.appReducer.appProject)
  const userObject = useSelector((state) => state.authReducer.userObject)
  const [units, setUnits] = useState([])
  const initialEmptyValues = {
    status: '',
    unit: '',
    unitNumber: '',
    floorNumber: '',
    description: 'test description',
    price: '',
    storageLevel: null
  }
  const [value, setValues] = useState({
    ...initialEmptyValues
  })
  const { state: { statusColors, selectedInventoryView }, allStorageLevels } = useInventory()
  const [isDeleteModal, setIsDeleteModal] = useState(false)

  const STATUS_OPTIONS = [
    ...Object.values(TEMP_AVAILABILITY_STATUS).map((value) => {
      return { value: value, id: value }
    })
  ]

  const filterUniqueValues = useMemo(() => {
    const uniqueValues = {}

    Object.keys(value).forEach((key) => {
      if (value[key] !== editDetails[key]) {
        uniqueValues[key] = value[key]
      }
    })

    return uniqueValues
  }, [value])

  const handleSubmit = () => {
    // if (validate()) {
    setLoading(true)
    const updatedValues = { _id: value?._id ?? '', ...filterUniqueValues }

    createOrUpdateStorage(updatedValues)
      .then((data) => {
        refetch()
        setLoading(false)
        setEditDetails(data)
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
      })
    // }
  }

  const onChange = (fieldName, newValue) => {
    setValues({ ...value, [fieldName]: newValue })
  }
  const [loading, setLoading] = useState(false)

  // const validate = () => {
  //   for (const key in initialEmptyValues) {
  //     if (!value[key]) {
  //       return false
  //     }
  //   }
  //   return true
  // }

  useEffect(() => {
    if (editDetails?.inventoryType === 'Storage') {
      setValues({
        ...value,
        ...editDetails,
        status: STATUS_OPTIONS.filter(
          ({ id }) => id === editDetails.status
        )?.[0]?.id,
        unit: units.filter(
          ({ id }) => id === editDetails.unit
        )?.[0]?.id
      })
    } else {
      setValues(initialEmptyValues)
    }
  }, [editDetails, units])

  useEffect(() => {
    getProjectUnits(appProject).then((data) => setUnits(data.map((elm) => ({
      value: elm.unitNumber,
      id: elm._id
    }))))
  }, [appProject])

  const handleStatusChange = (id) => {
    const temp = STATUS_OPTIONS.filter((option) => option.id === id)[0]
    onChange('status', temp.id)
  }

  const handleUnitChange = (id) => {
    const temp = units.filter((option) => option.id === id)[0]
    setValues({
      ...value,
      unit: temp.id,
      unitNumber: temp.value
    })
  }

  const handleImagesChange = (contractObject) => {
    onChange('images', contractObject._id ?? contractObject.id)
  }

  const handleStorageLevelChange = (id) => {
    const temp = allStorageLevels.filter((option) => option.id === id)[0]
    onChange('storageLevel', temp.id)
  }

  const deletePolygon = () => {
    setLoading(true)
    deleteStoragePolygon(editDetails?.polygonId)
      .then(() => {
        setLoading(false)
        refetch()
        setIsDeleteModal(false)
      })
      .catch((error) => {
        setLoading(false)
        setIsDeleteModal(false)
        console.err(error)
      })
  }

  return (
    <div
      className='p-2 relative flex flex-col h-[calc(100%-32px)] overflow-hidden rounded-lg border border-black/[20%]'
    >
      <div className='p-2 flex flex-col gap-6'>
        <div>
          <h3 className='font-medium text-3xl mb-1'>#{value.unitNumber}</h3>
        </div>
        <CustomBadge
          title={value.status}
          bgColor={statusColors[value?.status?.toLowerCase()]?.borderColor ?? '#000000'}
        />
      </div>
      <div className='flex-1 flex flex-col gap-4 overflow-y-auto thin-scrollbar'>
        <CustomSelect
          label='Select Unit'
          inputClasses='h-11'
          labelClasses={undefined}
          options={units}
          selectedOption={value.unit}
          setSelectedOption={(option) => {
            handleUnitChange(option)
          }}
        />
        <CustomInput
          label='price'
          placeholder='Enter amount'
          labelClassName='italic'
          value={value.price}
          onChange={(e) => {
            onChange('price', e.target.value)
          }}
          type='number'
          // required='required'
          // errorMessage='Please enter price'
          containerClasses={undefined}
          classes={undefined}
        />
        <CustomSelect
          label='status'
          inputClasses='h-11'
          labelClasses={undefined}
          options={STATUS_OPTIONS}
          selectedOption={value.status}
          setSelectedOption={(option) => {
            handleStatusChange(option)
          }}
        />
        <CustomInput
          label='Storage description'
          placeholder='Enter description'
          labelClassName='italic'
          value={value.description}
          onChange={(e) => {
            onChange('description', e.target.value)
          }}
          // required='required'
          // errorMessage='Enter description...'
          containerClasses={undefined}
          classes={undefined}
        />
        <CustomSelect
          label='Storage Level'
          labelClasses='italic'
          inputClasses='h-11'
          options={allStorageLevels}
          setSelectedOption={(value) => {
            handleStorageLevelChange(value)
          }}
          selectedOption={value.storageLevel}
        />
        <CustomInput
          label='floor number'
          placeholder='Enter number'
          labelClassName='italic'
          value={value.floorNumber}
          onChange={(e) => {
            onChange('floorNumber', e.target.value)
          }}
          type='number'
          // required='required'
          // errorMessage='Please enter floor number'
          containerClasses={undefined}
          classes={undefined}
        />
        <ProjectFileSelector
          title={'Images'}
          onSuccess={(selectedFileData) => {
            handleImagesChange(JSON.parse(selectedFileData))
          }}
        />
      </div>
      <div className='p-2 mt-2 border-t border-softBlack/[30%] flex flex-col gap-3'>
        <div className='mt-2 flex items-center gap-3'>
          <button
            onClick={closeStorageInfoDrawer}
            className='w-full py-2.5 px-5 text-lg rounded-lg font-medium border border-[#E4E4E7]'
          >
            Cancel
          </button>
          <button
            className='w-full py-2.5 px-5 text-lg bg-[#18181B] border border-[#18181B] text-white rounded-lg font-medium m-auto flex gap-2 items-center justify-center'
            disabled={loading}
            // disabled={!validate() || loading}
            onClick={handleSubmit}
          >
            {loading ? <Oval height='23' width='23' color='white' /> : <Check height='23' width='23' color='white' />}
            <p className='mb-0'>{value?._id ? 'Update' : 'Add'}</p>
          </button>
        </div>
        {
          userObject?.userType === 'DeveloperAdmin' && selectedInventoryView === 'map'
            ? <button
              className='w-full py-2.5 px-5 text-lg bg-[#18181B] border border-[#18181B] text-white rounded-lg font-medium m-auto flex gap-2 items-center justify-center'
              disabled={loading}
              onClick={() => setIsDeleteModal(true)}
            >
              {loading ? <Oval height='23' width='23' color='white' /> : <Trash2 height='23' width='23' color='white' />}
              <p className='mb-0'>Delete</p>
            </button>
            : <button
              className='w-full py-2.5 px-5 text-lg bg-[#18181B] border border-[#18181B] text-white rounded-lg font-medium m-auto flex gap-2 items-center justify-center'
              disabled={loading}
              onClick={() => history.push(ADMIN_OFFER)}
            >
              {loading ? <Oval height='23' width='23' color='white' /> : <ShoppingBag height='23' width='23' color='white' />}
              <p className='mb-0'>Purchase</p>
            </button>
        }
      </div>
      <DeleteModal
        title='Confirm deletion?'
        description={`Are you sure? do you want to delete #${value.unitNumber} storage-polygon?`}
        confirmButtonTitle='Yes, delete it'
        cancelButtonTitle='No'
        isOpen={isDeleteModal}
        setIsOpen={setIsDeleteModal}
        onConfirm={deletePolygon}
        loading={false}
      />
    </div>
  )
}

export default StorageEditView
