import React, { useEffect, useState } from 'react'

import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg'
import { Oval } from 'react-loader-spinner'

import { Modal } from '@material-ui/core'
import CustomInput from 'components/CustomInput'
import { ImageUploaderPrimary } from 'components/ImageUploader'
import { createOrUpdateFloorPlan } from 'store/actions/floorPlanActions'
import { useSelector } from 'react-redux'
import CustomButton from 'components/CustomButton'

const AddFloorPlanModal = ({
  show,
  setShow,
  editDetails,
  setEditDetails,
  refetch
}) => {
  const { appProject: projectId } = useSelector((state) => state.appReducer)

  const initialEmptyValues = {
    _id: '',
    name: '',
    parkingQty: '',
    parkingType: '',
    floorPlanImage: '',
    project: projectId
  }
  const [value, setValues] = useState({
    ...initialEmptyValues
  })

  const onChange = (fieldName, newValue) => {
    setValues({ ...value, [fieldName]: newValue })
  }
  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    setShow(false)
    setEditDetails({})
  }

  const validate = () => {
    if (!value.name) {
      return false
    }
    if (value.floorPlanImage.length === 0) {
      return false
    }
    return true
  }

  const handleSubmit = () => {
    setLoading(true)
    if (validate()) {
      const temp = {
        ...value,
        _id: editDetails._id,
        project: projectId
      }
      createOrUpdateFloorPlan(temp)
        .then((data) => {
          refetch()
          setLoading(false)
          setShow(false)
          setEditDetails({})
        })
        .catch((err) => {
          setLoading(false)
          console.log(err)
        })
    }
  }

  useEffect(() => {
    // console.log('editDetails', editDetails);
    if (
      editDetails?.parkingQty ||
      (editDetails?.parkingType && editDetails.type === 'Floor Plan')
    ) {
      setValues({
        ...value,
        parkingQty: editDetails.parkingQty,
        parkingType: editDetails.parkingType,
        name: editDetails.name,
        _id: editDetails._id,
        floorPlanImage: editDetails.image
      })
    } else {
      setValues(initialEmptyValues)
    }
  }, [editDetails])

  return (
    <Modal
      open={show}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div
        className='font-openSans relative bg-grey-8 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-10 rounded shadow-lg'
        style={{ width: 544 }}
      >
        <button className='absolute right-10' onClick={handleClose}>
          <CrossIcon />
        </button>
        <div className='flex flex-col gap-6'>
          <h1 className='font-bold text-xl text-center p-0 m-0'>
            {editDetails?._id ? 'Update' : 'Add'} Floor Plan
          </h1>
          <CustomInput
            label='Floor Number *'
            placeholder='Enter Number'
            labelClassName='italic'
            value={value.name}
            onChange={(e) => {
              onChange('name', e.target.value)
            }}
            required='required'
            errorMessage='Please enter floor number'
            containerClasses={undefined}
            classes={undefined}
          />
          <div className='grid grid-cols-2 gap-6'>
            <CustomInput
              label='Parking Slots Qty'
              placeholder='Enter number'
              labelClassName='italic'
              value={value.parkingQty}
              onChange={(e) => {
                onChange('parkingQty', e.target.value)
              }}
              containerClasses={undefined}
              classes={undefined}
            />
            <CustomInput
              label='Parking Slot Type '
              placeholder='Select type'
              labelClassName='italic'
              value={value.parkingType}
              onChange={(e) => {
                onChange('parkingType', e.target.value)
              }}
              containerClasses={undefined}
              classes={undefined}
            />
          </div>
          <ImageUploaderPrimary
            imgTitle={value?.name}
            imgsrc={value?.floorPlanImage}
            onDelete={() => {
              onChange('floorPlanImage', '')
            }}
            isDeleted={!value.floorPlanImage}
            onFileChange={(e) => {
              onChange('floorPlanImage', e.target.files[0])
            }}
          />
          <CustomButton handleClick={handleSubmit} disabled={!validate() || loading} className='m-auto'>
            <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
              {loading && <Oval height='18' width='18' color='white' />}
              {editDetails?._id ? 'Update' : 'Add'} Floor Plan
            </span>
          </CustomButton>
        </div>
      </div>
    </Modal>
  )
}

export default AddFloorPlanModal
