import React from 'react'

import {
  Tab,
  ButtonGroup
} from './styles'

const LargeTabs = (props) => {
  const { types, active, setActive, styles = {} } = props
  return (
    <>
      <ButtonGroup>
        {types.map((type, index) => (
          <Tab
            key={type}
            active={active === type}
            onClick={() => setActive(type)}
            style={styles}
          >
            {type}
          </Tab>
        ))}
      </ButtonGroup>
    </>
  )
}

export default LargeTabs
