import React from 'react'
import { isMobile } from 'react-device-detect'
import { Drawer } from 'antd'
import FieldSelector from 'pages/contractTemplates/components/FieldSelector'

const MergeFieldsDrawer = ({ onClose, open, type }) => {
  const handleOnClose = () => {
    onClose()
  }

  return (
    <Drawer
      size={isMobile ? 'default' : 'large'}
      closable={true}
      placement="right"
      onClose={handleOnClose}
      open={open}
      title="Merge fields for communication template"
      className='[&>.ant-drawer-content-wrapper>.ant-drawer-content>.ant-drawer-wrapper-body>.ant-drawer-body]:!p-0 [&>.ant-drawer-content-wrapper]:!w-fit'
    >
      <div className='w-full h-full flex flex-col gap-2'>
        <FieldSelector
          contractType={type}
          className='!w-[400px]'
          onSuccess={handleOnClose}
        />
      </div>
    </Drawer>
  )
}

export default MergeFieldsDrawer
