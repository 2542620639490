import React from 'react'
import styled from 'styled-components'
import Skeleton from 'react-loading-skeleton'
import standardStyle from 'assets/css/standardStyle'
import propTypes from 'prop-types'

const SubTitleComponent = styled.h2`
  color: ${props => props.color === 'secundary' ? standardStyle.colors.secundary : standardStyle.colors.primary};
  font-size: ${props => props.fz || 24}px;
  font-weight: ${props => props.fw || 800};
  font-family: ${standardStyle.fonts.title};
`

export default function Subtitle (props) {
  const { color, fz, fw, loading, children } = props

  if (loading) {
    return (<Skeleton width={350} height={37.6} />)
  } else {
    return (
      <SubTitleComponent
        color={color}
        fz={fz}
        fw={fw}
      >
        {children}
      </SubTitleComponent>
    )
  }
}

Subtitle.defaultProps = {
  loading: false
}

Subtitle.propType = {
  loading: propTypes.bool,
  children: propTypes.node.isRequired
}
