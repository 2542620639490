// @ts-nocheck
/* eslint-disable */
import { api, ApiErrors } from "services/api";
export const getInventorySettings = (projectId) =>
  new Promise((resolve, reject) => {
    api.get(`inventory-settings/inventory/${projectId}`).then((response) => {
      if (response.ok) {
        resolve(response.data);
      } else {
        ApiErrors(response);
        reject();
      }
    });
  });
export const createNewInventoryType = (data) =>
  new Promise((resolve, reject) => {
    api
      .post(`inventory-manage/inventory`, {
        ...data,
      })
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          ApiErrors(response);
          reject();
        }
      });
  });

export const updateInventory = (data) =>
  new Promise((resolve, reject) => {
    let method = "post";
    let url = `inventory-settings/inventory`;
    if (data.id) {
      method = "put";
    }
    api[method](url, {
      ...data,
    }).then((response) => {
      if (response.ok) {
        resolve(response.data);
      } else {
        ApiErrors(response);
        reject();
      }
    });
  });

export const createOrUpdateInventory = (data) =>
  new Promise((resolve, reject) => {
    const { _id, ...params } = data;

    const verb = _id ? "put" : "post";
    const url = _id ? `inventory/${_id}` : "inventory";

    api[verb](url, params).then((response) => {
      if (response.ok) {
        resolve(response.data);
      } else {
        ApiErrors(response);
        reject();
      }
    });
  });

export const getInventories = (id = "") =>
  new Promise((resolve, reject) => {
    api.get(`inventory/${id}`).then((response) => {
      if (response.ok) {
        resolve(response.data);
      } else {
        ApiErrors(response);
        reject();
      }
    });
  });

export const getInventorieCountProject = (id = "") =>
  new Promise((resolve, reject) => {
    api.get(`inventory/${id}`).then((response) => {
      if (response.ok) {
        resolve(response.data);
      } else {
        ApiErrors(response);
        reject();
      }
    });
  });

export const deleteInventory = (id) =>
  new Promise((resolve, reject) => {
    api.delete(`inventory/${id}`).then((response) => {
      if (response.ok) {
        resolve(response.data);
      } else {
        ApiErrors(response);
        reject();
      }
    });
  });

export const downloadInventoryCSV = async (buildingId, projectId) => {
  try {
    const result = await getInventoryCSV({ buildingId, projectId });
    const buffer = Buffer.from(result);
    const blob = new Blob([buffer]);
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    document.body.appendChild(a);
    // a.style = "display: none"
    a.href = url;
    a.download = buildingId + "-units.csv";
    a.click();
    window.URL.revokeObjectURL(url);
  } catch (e) {
    console.error("downloadUnitsCSV Error", buildingId, e);
  }

};
export const getInventoryCSV = ({ buildingId, projectId }) =>
  new Promise((resolve, reject) => {
    api.get(`inventory/download/csv?building=${buildingId}&project=${projectId}`).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

  export const updateInventoryField = (inventoryId, inventoryData) =>
    new Promise((resolve, reject) => {
      api.put(`inventory/${inventoryId}/field-update`, { ...inventoryData }).then((response) => {
        if (response.ok) {
          resolve()
        } else {
          ApiErrors(response)
          reject()
        }
      })
    })
