import React from 'react'
import ProjectCommunicationTemplates from '../components/ProjectCommunicationTemplates'

const EditProjectCommunicationTemplates = () => {
  return (
    <div className='bg-white min-h-screen relative'>
      <div className='my-6'>
        <ProjectCommunicationTemplates />
      </div>
    </div>
  )
}

export default EditProjectCommunicationTemplates
