import React from 'react'
import PropTypes from 'prop-types'
import standardStyle from 'assets/css/standardStyle'
import { linkTo } from '@storybook/addon-links'

function Tag ({ label, type, size, state, handleClick, containerClasses = '', classes = '' }) {
  let border = 'none'
  let height = '1.688rem'
  let fontSize = '0.75rem'
  const width = 'fit-content'
  let padding = '4.5px 4px'
  let textPadding = '0 1.989px'
  const color = standardStyle.colors.softBlack
  let backgroundColor = standardStyle.colors.available

  // Type
  if (type === 'Available') {
    backgroundColor = standardStyle.colors.available
  }
  if (type === 'Reserved') {
    backgroundColor = standardStyle.colors.reserved
  }
  if (type === 'Sold') {
    backgroundColor = standardStyle.colors.sold
  }
  if (type === 'Grey') {
    backgroundColor = standardStyle.colors.mainBackground
  }
  // Size
  if (size === 'Large') {
    height = '2.313rem'
    fontSize = '0.875rem'
    padding = '0.5rem 1rem'
    textPadding = '0 2.15px'
  }
  // State
  if (state === 'Pressed') {
    border = `1px solid ${standardStyle.colors.softBlack}`
  }

  if (state === 'Pressed' && type === 'Sold') {
    border = '1px solid white'
  }

  const TextStyle = {
    padding: textPadding
  }

  const Style = {
    color,
    width,
    height,
    border,
    fontSize,
    padding,
    backgroundColor,
    display: 'flex',
    fontWeight: 500,
    letter: '0.16px',
    fontStyle: 'normal',
    borderRadius: '2px',
    alignItems: 'center',
    lineHeight: '1.125rem',
    justifyContent: 'center',
    font: standardStyle.fonts.text
  }

  return (
    <div onClick={linkTo('Tag', '')} style={Style} className={containerClasses}>
      <div style={TextStyle} className={classes}>{label.toUpperCase()}</div>
    </div>
  )
}

Tag.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
  state: PropTypes.string,
  handleClick: PropTypes.func
}

export default Tag
