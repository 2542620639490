import { useState } from 'react'
import { api, ApiErrors, getTokenRequestHeader } from 'services/api'

export const useGetContactLists = () => {
  const [loading, setLoading] = useState(false)
  const [contactsLists, setContactsLists] = useState([])

  const getContacts = async () => {
    setLoading(true)
    const token = getTokenRequestHeader()
    // @ts-ignore
    api.setHeader('Authorization', token)
    const response = await api.get('lists')
    setContactsLists(response?.data)
    setLoading(false)
    if (!response.ok) return ApiErrors(response)
  }

  return { contactsLists, loading, getContacts }
}

export const useGetFilteredContactLists = () => {
  const [loading, setLoading] = useState(false)
  const [contacts, setContacts] = useState([])
  const [controller, setController] = useState(null)

  const getContacts = async (searchParam) => {
    if (controller) {
      controller.abort()
    }
    const newController = new AbortController()
    setController(newController)
    setLoading(true)
    const token = getTokenRequestHeader()
    // @ts-ignore
    api.setHeader('Authorization', token)
    const response = await api.get(`/users/search?search=${searchParam}`, { signal: newController.signal })
    setContacts(response?.data?.data)
    setLoading(false)
    if (!response.ok) return ApiErrors(response)
  }

  return { contacts, loading, getContacts }
}
