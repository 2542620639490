import React from 'react'

const SubText = ({
  variant = 'default',
  label = '',
  className = ''
}) => {
  const subTextVariants = {
    default: 'text-zinc-900',
    light: 'text-zinc-500'
  }

  return (
    <p
      className={`font-normal leading-none mb-0
        ${subTextVariants[variant]}
        ${className}
      `}
    >
      {label}
    </p>
  )
}

export default SubText
