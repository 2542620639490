// @ts-nocheck
/* eslint-disable */
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { defaultRatingOptions } from 'utils';
import { ReactComponent as MailIcon } from 'assets/icons/bulkContact.svg';
import MessageModal from 'pages/contacts/MessageModal';
import AircallPhone from 'aircall-everywhere';
import EmailModal from 'pages/contacts/EmailModal';
import toast from 'react-hot-toast';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import { Modal } from '@material-ui/core';
import { ReactComponent as SMSIcon } from '../../../assets/icons/icn_text_snippet.svg';
import { DeleteFilled } from '@ant-design/icons';
import CustomButton from 'components/CustomButton';
import { isMobile } from 'react-device-detect';
import { updateUserDeleteStatus } from 'store/actions/usersActions';
import useContactContext from 'pages/newContacts/hooks/useContactContext';
import { formatPhoneNumber } from 'pages/newContacts/ContactsTable/UserTable/UserTableV2';
import { Tooltip } from 'antd';

// const StepsIcon = () => {
//   return (
//     <svg width='97' height='14' viewBox='0 0 97 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
//       <path d='M85.2311 6.58123L80.5966 0.0929565H90.3905C93.9738 0.0929565 96.8787 2.99786 96.8787 6.58123C96.8787 10.1646 93.9738 13.0695 90.3905 13.0695H80.5966L85.2311 6.58123Z' fill='#2E2B2E' fillOpacity='0.15' />
//       <path d='M77.9388 13.0695H64.3759L69.0104 6.58123L64.3759 0.0929565H77.9388L82.5733 6.58123L77.9388 13.0695Z' fill='#2E2B2E' fillOpacity='0.15' />
//       <path d='M48.1553 13.0695H61.7181L66.3526 6.58123L61.7181 0.0929565H48.1553L52.7898 6.58123L48.1553 13.0695Z' fill='#2E2B2E' />
//       <path d='M45.4974 13.0695H31.9346L36.5691 6.58123L31.9346 0.0929565H45.4974L50.1319 6.58123L45.4974 13.0695Z' fill='#2E2B2E' />
//       <path d='M15.7139 13.0695H29.2767L33.9112 6.58123L29.2767 0.0929565H15.7139L20.3483 6.58123L15.7139 13.0695Z' fill='#2E2B2E' />
//       <path d='M13.056 13.0695H7.12426C3.54088 13.0695 0.635986 10.1646 0.635986 6.58123C0.635986 2.99786 3.54089 0.0929565 7.12426 0.0929565H13.056L17.6905 6.58123L15.3733 9.82537L13.056 13.0695Z' fill='#2E2B2E' />
//     </svg>
//   )
// }

const MessageIcon = () => {
  return (
    <svg
      width='22'
      height='23'
      viewBox='0 0 22 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_16_86)'>
        <path
          d='M18.3979 4.30304H3.97954C2.98828 4.30304 2.18626 5.11407 2.18626 6.10534L2.17725 16.9191C2.17725 17.9104 2.98828 18.7214 3.97954 18.7214H18.3979C19.3892 18.7214 20.2002 17.9104 20.2002 16.9191V6.10534C20.2002 5.11407 19.3892 4.30304 18.3979 4.30304ZM18.0375 8.13292L11.6663 12.116C11.378 12.2962 10.9995 12.2962 10.7111 12.116L4.34 8.13292C4.11472 7.98874 3.97954 7.74543 3.97954 7.4841C3.97954 6.88033 4.63738 6.51987 5.15104 6.83527L11.1887 10.6111L17.2264 6.83527C17.7401 6.51987 18.3979 6.88033 18.3979 7.4841C18.3979 7.74543 18.2628 7.98874 18.0375 8.13292Z'
          fill='#2E2B2E'
        />
      </g>
      <defs>
        <clipPath id='clip0_16_86'>
          <rect
            width='21.6276'
            height='21.6276'
            fill='white'
            transform='translate(0.374573 0.698486)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

const CallIcon = () => {
  return (
    <svg
      width='18'
      height='19'
      viewBox='0 0 18 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_16_93)'>
        <path
          d='M5.02983 8.63995C6.06796 10.6802 7.74049 12.3455 9.78069 13.3908L11.3667 11.8048C11.5614 11.6101 11.8497 11.5453 12.1021 11.6318C12.9095 11.8985 13.7818 12.0427 14.6757 12.0427C15.0722 12.0427 15.3967 12.3671 15.3967 12.7636V15.2796C15.3967 15.6761 15.0722 16.0005 14.6757 16.0005C7.9063 16.0005 2.4201 10.5143 2.4201 3.74491C2.4201 3.3484 2.74452 3.02399 3.14102 3.02399H5.66424C6.06075 3.02399 6.38516 3.3484 6.38516 3.74491C6.38516 4.64606 6.52935 5.51116 6.79609 6.31859C6.87539 6.57091 6.81771 6.85207 6.61586 7.05393L5.02983 8.63995Z'
          fill='#2E2B2E'
        />
      </g>
      <defs>
        <clipPath id='clip0_16_93'>
          <rect
            width='17.3021'
            height='17.3021'
            fill='white'
            transform='translate(0.257385 0.861206)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

const Button = ({ title = '', Icon, onClick }) => {
  return (
    <button
      className={clsx(
        'rounded border border-zinc-800 border-opacity-50 justify-start items-center gap-2 flex',
        title?.length > 0 ? 'px-4 py-2' : 'p-2'
      )}
      onClick={onClick}
    >
      {Icon}
      {title?.length > 0 && (
        <div className='text-center text-zinc-800 text-base font-normal leading-snug'>
          {title}
        </div>
      )}
    </button>
  );
};

const NameCard = ({
  contact,
  backgroundImage,
  firstName,
  lastName,
  userType,
  rating,
  buyerEmail,
  setSelectedTabHeader,
  isShowQuickActionButton,
}) => {
  const [cardColor, setCardColor] = useState({
    color: '',
    bgColor: '',
  });
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isAircallDialogOpen, setIsAircallDialogOpen] = useState(false);
  const [showEmailBox, setShowEmailBox] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const { state, closeContactDetailDrawer } = useContactContext();

  useEffect(() => {
    setCardColor(defaultRatingOptions.filter((elm) => elm.value === rating)[0]);
  }, [defaultRatingOptions, rating]);

  const aircallPhone = new AircallPhone({
    onLogin: () => {
      aircallPhone.send(
        'dial_number',
        {
          phone_number: contact.buyerData.phoneNumber,
        },
        false
      );
    },
    onLogout: () => console.log('Logged-out successfully.'),
  });

  const handleSoftDelete = async () => {
    try {
      const response = await updateUserDeleteStatus(contact._id).then(() => {
        setDeleteModal(false);
        closeContactDetailDrawer();
      });
    } catch (error) {
      console.log('Error in deleting user', error);
    }
  };

  return (
    <>
      <div className='p-4 bg-white gap-4'>
        <div
          className='py-10 px-8 relative rounded-lg flex-col gap-4 flex'
          style={{ background: cardColor?.bgColor ?? 'white' }}
        >
          <div className='items-center w-full justify-center flex-col flex gap-4'>
            <div
              className='w-14 h-14 relative border-4 rounded-full flex justify-center item-center'
              style={{ borderColor: cardColor?.color ?? 'rgb(45, 42, 45)' }}
            >
              {backgroundImage === '' ? (
                <div className='h-12 w-12 border border-transparent rounded-full flex items-center justify-center bg-gray-200 text-text-softBlack_70/50 font-bold'>
                  {firstName[0]}
                  {lastName[0]}
                </div>
              ) : (
                <img
                  src={backgroundImage}
                  alt='Avatar'
                  className='h-14 w-14 border rounded-full'
                />
              )}
            </div>

            <div className='self-stretch flex flex-col justify-center items-center gap-1'>
              <h6 className='text-zinc-800 text-xl font-semibold leading-7 m-0 text-center'>
                {firstName + ' ' + lastName}
              </h6>
              <p className='text-zinc-800 text-opacity-70 text-[14px] font-normal leading-snug m-0'>
                {userType}
              </p>
            </div>
          </div>
          {isShowQuickActionButton && (
            <div className='gap-3 flex flex-col'>
              <div
                onClick={() => setShowEmailBox(true)}
                className='flex cursor-pointer gap-x-4 items-center'
              >
                <Button Icon={<MailIcon className='h-5 w-5' />} />
                <Tooltip
                  title={buyerEmail}
                  className='text-[16px] font-semibold truncate'
                >
                  {buyerEmail}
                </Tooltip>
              </div>
              <div
                onClick={() => setIsAircallDialogOpen(true)}
                className='flex cursor-pointer gap-x-4 items-center'
              >
                <Button Icon={<CallIcon />} />
                <div className='text-[16px] font-semibold'>
                  {formatPhoneNumber(
                    contact?.buyerData?.phoneNumber ||
                      contact?.leadBrokerData?.phoneNumber
                  )}
                </div>
              </div>
              {/* <div className='flex gap-x-4 items-center'>
                <Button
                  title='Message'
                  Icon={<SMSIcon />}
                  onClick={() => {
                    setSelectedTabHeader('SMS')
                  }}
                />
              </div> */}
            </div>
          )}
          {/* <div className='py-1.5 rounded-md justify-center items-center gap-3 inline-flex'>
            <div className='text-zinc-800 text-base font-normal leading-snug'>Contract Writing</div>
            <StepsIcon />
          </div> */}
          <DeleteFilled
            onClick={() => {
              setDeleteModal(true);
            }}
            className='absolute cursor-pointer w-6 right-3 bottom-3'
          />
        </div>
      </div>
      <MessageModal
        show={isMessageModalOpen}
        handleClose={() => setIsMessageModalOpen(!isMessageModalOpen)}
        onSuccess={() => setSelectedTabHeader('SMS')}
        contact={contact}
      />
      <Modal
        open={isAircallDialogOpen}
        onClose={() => setIsAircallDialogOpen(false)}
      >
        <div className='w-full h-screen flex justify-center items-center font-openSans absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded shadow-lg'>
          <div className='w-fit flex relative bg-grey-8 overflow-x-hidden overflow-y-auto flex-col pt-10 rounded'>
            <button
              className='absolute right-4 top-4'
              onClick={() => setIsAircallDialogOpen(false)}
            >
              <CrossIcon />
            </button>
            <div className='flex flex-col gap-6'>
              <div>
                <iframe
                  allow='microphone; autoplay; clipboard-read; clipboard-write; hid'
                  src='https://phone.aircall.io?integration=generic'
                  className='w-[376px] h-[666px]'
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {showEmailBox && (
        <EmailModal
          show={showEmailBox}
          handleClose={() => setShowEmailBox(!showEmailBox)}
          contact={contact}
          onEmailSent={() => {
            if (setSelectedTabHeader) {
              setSelectedTabHeader('Email');
            }
            toast.success('Email Sent successfully');
          }}
        />
      )}
      {deleteModal && (
        <Modal open={deleteModal}>
          <div
            style={{ width: isMobile ? '95vw' : 400 }}
            className='bg-grey-8 p-2 md:p-6 relative top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'
          >
            <button
              className='absolute right-3 top-3'
              onClick={() => setDeleteModal(false)}
            >
              <CrossIcon />
            </button>
            <div className='flex flex-col gap-y-6 justify-center items-center'>
              <div className='mt-6 text-[18px]'>
                Are you sure you want delete Contact?
              </div>
              <div className='flex gap-x-6'>
                <CustomButton
                  handleClick={() => {
                    handleSoftDelete();
                  }}
                >
                  <span className='font-medium py-2 px-4 text-base flex gap-2 items-center'>
                    Yes
                  </span>
                </CustomButton>
                <CustomButton
                  handleClick={() => {
                    setDeleteModal(false);
                  }}
                >
                  <span className='font-medium py-2 px-4 text-base flex gap-2 items-center'>
                    No
                  </span>
                </CustomButton>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default NameCard;
