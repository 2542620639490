/*eslint-disable */
// @ts-nocheck
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getProjects } from 'store/actions/projectActions';


const useGetProject = () => {
  const userId = useSelector((state) => state.authReducer.userObject)
    const { data, isLoading, refetch } = useQuery(
        ['getProjects'],
        () => getProjects(),
        { refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false, retry: false, enabled: !!userId }
      )
    return { projects: data, isLoading, refetch }
};

export default useGetProject;
