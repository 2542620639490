import React, { useMemo } from 'react'

import InventoryListViewWrapper from '../InventoryListViewWrapper'
import useGetStorages from 'hooks/useGetStorages'
import { groupBy } from 'components/Inventory'

const StorageInventoryListView = ({ projectId, buildingId }) => {
  const { loadingStorages, storages, refetchStorages } = useGetStorages()

  const filteredStorage = useMemo(() => {
    // const storagesData = storages.filter(elm => elm.building === buildingId)

    // const uCards = [...storagesData]
    const uCards = [...storages]
    const grouped = groupBy(uCards, (inventoryItem) => inventoryItem.floorNumber)
    // @ts-ignore
    const groupedKeyValuePair = {}
    grouped.forEach((value, key) => {
      return (groupedKeyValuePair[key] = value)
    })
    return ({ ...groupedKeyValuePair })
  }, [storages, buildingId])

  return (
    <InventoryListViewWrapper
      inventoryItems={filteredStorage}
      loading={loadingStorages}
      type='Storage'
      refetch={refetchStorages}
    />
  )
}

export default StorageInventoryListView
