import React from 'react'
import { ReactComponent as TickIcon } from 'assets/icons/tickk.svg'
import CustomButton from 'components/CustomButton'

const ContractSignedPanel = ({
  contract,
  onCloseScreenClicked,
  isAllSigned,
  onSignNextContractClicked
}) => {
  const documentName = contract?.contractTemplate?.name ?? null
  if (!contract) {
    return null
  }

  return (
    <div className='h-full w-2/3 flex-1 justify-center content-center items-center mx-auto'>
      <div className='bg-gray-100 flex flex-col gap-5 justify-center items-center rounded-3xl p-8'>
        <div className='flex flex-col items-center text-2xl text-center'>
          <h2>Thank you!</h2>
          {/* {userName && <h2>{`Thank you ${userName}!`}</h2>} */}
          {documentName && <h2>{documentName}</h2>}
          <h2>Signed Successfully</h2>
        </div>
        <div className='bg-volt h-24 w-24 rounded-lg flex justify-center items-center p-2'>
          <TickIcon />
        </div>
        <a
          href={contract.url}
          target='_blank'
          className='flex justify-center items-center font-bold py-4 px-12 border border-softBlack_70 rounded cursor-pointer text-softBlack decoration-softBlack'
        >
          Download a Copy
        </a>
        {isAllSigned && (
          <>
            <p className='text-md text-center justify-center content-center'>
              You have signed all your contracts
            </p>
            <p className='text-md text-center justify-center content-center'>
              Please wait for sales Representative to contact you for next steps
            </p>
          </>
        )}
        <CustomButton handleClick={isAllSigned ? onCloseScreenClicked : onSignNextContractClicked}>
          <span className='font-semibold  px-6 py-3 rounded text-lg flex items-center gap-2'>
            {isAllSigned ? 'Close this Screen' : 'Sign Next Document'}
          </span>
        </CustomButton>
      </div>
    </div>
  )
}

export default React.memo(ContractSignedPanel)
