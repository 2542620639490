import React from 'react'
import propTypes from 'prop-types'
import {
  OptionWrapper,
  TitleWrapper
} from './styles'

const Option = (props) => {
  const {
    title,
    selected,
    span,
    id,
    onClick
  } = props

  const executeOnClick = (e) => {
    onClick(e)
  }

  return (
    <div>
      <OptionWrapper
        span={span}
        selected={selected}
        onClick={executeOnClick}
        id={id}
        animate={{ scale: selected ? 1.04 : 1 }}
      >
        <TitleWrapper>
          {title}
        </TitleWrapper>
      </OptionWrapper>
    </div>
  )
}

Option.propTypes = {
  title: propTypes.string,
  selected: propTypes.bool,
  span: propTypes.number,
  id: propTypes.number,
  onClick: propTypes.func
}

export default Option
