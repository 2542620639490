import React from 'react'
import propTypes from 'prop-types'

import { FeaturesWrapper, FeatureWrapper } from './styles'
import { BathIcon, BedIcon, CarIcon } from 'components'

const Feature = ({ icon, value }) => (
  <FeatureWrapper>
    {icon}
    <span className='text-sm font-bold'>{value}</span>
  </FeatureWrapper>
)

const BuildingFeatures = (props) => {
  const {
    numberOfBedrooms,
    numberOfBathrooms,
    numberOfParking
  } = props

  return (
    <FeaturesWrapper>
      <Feature icon={<BedIcon fontSize={20} />} value={numberOfBedrooms} />
      <Feature icon={<BathIcon fontSize={20} />} value={numberOfBathrooms} />
      {
        numberOfParking
          ? <Feature icon={<CarIcon fontSize={20} />} value={numberOfParking} />
          : null
      }
    </FeaturesWrapper>
  )
}

export default BuildingFeatures

BuildingFeatures.propTypes = {
  numberOfBedrooms: propTypes.number,
  numberOfBathrooms: propTypes.number,
  numberOfParking: propTypes.number
}

Feature.propTypes = {
  icon: propTypes.node,
  value: propTypes.string
}
