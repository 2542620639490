import { api, ApiErrors } from 'services/api'

export const getFlatFileToken = () => {
  return new Promise((resolve, reject) => {
    api.get('users/provider/bulk-import/flat-files/token').then((response) => {
      if (response.ok) {
        resolve(response.data.token)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })
}

export const getUserActivity = (userId, skip = 0, limit = 0) => {
  return new Promise((resolve, reject) => {
    api
      .get(`/useractivity/user/${userId}/${skip}/${limit}`)
      .then((response) => {
        if (response.ok) {
          resolve(response.data)
        } else {
          ApiErrors(response)
          reject()
        }
      })
  })
}
