// @ts-nocheck
/* eslint-disable */
import { useState, useEffect, useRef } from 'react'
import { getInventories } from 'store/actions/inventoryActions'

const useGetInventories = (id = '') => {
  const mountedRef = useRef(true)
  const [inventories, setInventories] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getData(id)
    return () => {
      mountedRef.current = false
    }
  }, [])

  const getData = async (id = '') => {
    setLoading(true)
    try {
      const data = await getInventories(id || '')
      if (!mountedRef.current) return null
      setInventories(data)
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  return { loading, inventories, refetch: () => getData(id) }
}

export default useGetInventories
