import React, { useEffect } from 'react'

import { AGENT_CHECKBOXES, BUYING_AS } from '../constants/Fintrac'

import CustomRadio from 'components/CustomRadio'
import CustomTextArea from 'components/CustomTextArea'
import ThirdPartyRecord from 'pages/offer/components/ThirdPartyRecord'
import Checkbox from 'components/Checkbox'

import ClientRisk from 'pages/offer/components/ClientRisk'

const PWAFintrac = ({
  setButtonState,
  isThirdParty,
  setIsThirdParty,
  riskType,
  setRiskType,
  selectedOption,
  setSelectedOption,
  checkedProperties,
  setCheckedProperties,
  businessRelationshipExplanation,
  setBusinessRelationshipExplanation,
  thirdPartDescription,
  setThirdPartDescription,
  thirdPartRecord,
  setThirdPartRecord,
  riskExplanation,
  setRiskExplanation
}) => {
  const thirdPartRecrdObject = { thirdPartRecord, setThirdPartRecord }

  const handleCheckedProperties = (_option) => {
    const { id } = _option
    const index = checkedProperties.findIndex(
      (_property) => _property.id === id
    )
    if (index !== -1) {
      const filteredProperties = checkedProperties?.filter(
        (_property) => _property.id !== id
      )
      setCheckedProperties([...filteredProperties])
    } else {
      setCheckedProperties([...checkedProperties, _option])
    }
  }

  useEffect(() => {
    setButtonState('Default')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className='text-softBlack mx-20 mb-1'>
        <div className='mt-10'>
          <p className='my-0 font-medium text-lg mb-6'>
            Are you buying this Unit for a 3rd party?
          </p>
          <div className='flex gap-10 mt-6'>
            {BUYING_AS?.map((_option, index) => (
              <CustomRadio
                key={index}
                label={_option?.label}
                checked={isThirdParty === _option.value}
                onClick={() => setIsThirdParty(_option.value)}
              />
            ))}
          </div>
        </div>

        <CustomTextArea
          label='Why you think your client may be acting on behalf a 3rd party'
          containerClasses='mt-6'
          value={thirdPartDescription}
          onChange={(e) => setThirdPartDescription(e.target.value)}
          labelClasses={undefined}
          textareaClasses={undefined}
          errorMessage={undefined}
          style={undefined}
        />

        {isThirdParty && (
          <ThirdPartyRecord
            contextUsed={true}
            thirdPartRecordStateObject={thirdPartRecrdObject}
          />
        )}

        <ClientRisk
          riskType={riskType}
          setRiskType={setRiskType}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          riskExplanation={riskExplanation}
          setRiskExplanation={setRiskExplanation}
        />

        <div className='mb-8 mt-10'>
          <p className='font-bold text-xl mb-6'>Business Relationship</p>
          <p className='my-0 text-base'>
            Purpose and Intended Nature of the Business Relationship
          </p>
        </div>

        <div>
          <p className='mt-0 text-base font-bold mb-6'>
            Acting as an Agent for the purchase or sale of:
          </p>
          <div className='grid grid-cols-2 gap-6'>
            {AGENT_CHECKBOXES?.map((_option, index) => (
              <Checkbox
                containerClasses={undefined}
                iconClasses={undefined}
                {..._option}
                key={index}
                labelClasses='text-base'
                checked={
                  checkedProperties.findIndex(
                    (_prop) => _prop.id === _option.id
                  ) !== -1
                }
                handleChange={() => handleCheckedProperties(_option)}
              />
            ))}
          </div>
          {checkedProperties.findIndex((_prop) => _prop.id === 5) !== -1 && (
            <CustomTextArea
              placeholder='Describe here'
              value={businessRelationshipExplanation}
              onChange={(e) =>
                setBusinessRelationshipExplanation(e.target.value)
              }
              label={undefined}
              labelClasses={undefined}
              textareaClasses={undefined}
              containerClasses={undefined}
              errorMessage={undefined}
              style={undefined}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default PWAFintrac
