import DashboardTable from 'components/DashboardTable'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import TableHeader from 'components/TableHeader'
import { MdModeEdit, MdDelete } from 'react-icons/md'
import { DashboardContext } from 'pages/dashboard/context/DashboardContext'

const Row = ({ id, data, userId, onRowClick, showEditDeleteIcons, onEditClick, onDeleteClick }) => {
  const handleRowClick = () => {
    // @ts-ignore
    window.openTasksTab = true
    onRowClick(data?.userId, data?._id)
  }

  return (
      <tr className="group cursor-pointer">
        <td onClick={handleRowClick} className='text-softBlack text-base font-medium font-openSans py-3'>
          {id + 1}
        </td>
        <td onClick={handleRowClick} className='text-softBlack text-base font-medium font-openSans py-3'>{data.name}</td>
        <td onClick={handleRowClick} className='text-softBlack text-base font-medium font-openSans py-3'>{data.task}</td>
        {showEditDeleteIcons && <td onClick={onEditClick} className='text-softBlack text-base font-medium font-openSans py-3'><MdModeEdit /></td>}
        {showEditDeleteIcons && <td onClick={onDeleteClick} className='text-softBlack text-base font-medium font-openSans py-3'><MdDelete /></td>}
      </tr>
  )
}

const UpcomingTasks = ({
  upcomingTasks,
  rawTasks = null,
  history,
  title = 'My Upcoming Tasks',
  showEditDeleteIcons = false,
  onEditClick = (p) => {},
  onDeleteClick = (p) => {},
  setOpenSidePanel = (p) => {},
  setContactId = (p) => {},
  setTask = (p) => {}
}) => {
  return (
    <div className={`mr-5 w-full ${isMobile ? 'mb-10' : ''}`}>
        <DashboardTable
            title={title}
            columnNames={['', '', '', '', '']}
            data={upcomingTasks}
            RowToRender={Row}
            showMore
            showIndex
            eraseHeading
            onEditClick={onEditClick}
            onDeleteClick={onDeleteClick}
            showEditDeleteIcons={showEditDeleteIcons}
            onRowClick={(id, taskId) => {
              setOpenSidePanel(true)
              setContactId(id)
              setTask(rawTasks?.find?.(task => task?._id === taskId))
            }}
        />
    </div>
  )
}

const TaskTable = ({ tasks, history, onSelectedTabChange, selectedTask }) => (
  <div className='flex flex-col gap-2 w-full'>
    <TableHeader headers={['My Upcoming Tasks', 'Past tasks incomplete', 'Complete Past Tasks']} selectedHeader={selectedTask} handleSelectHeader={onSelectedTabChange} />
    <UpcomingTasks title={selectedTask} upcomingTasks={tasks ?? []} history={history} />
  </div>
)

const AdditionalStats = () => {
  const { setOpenSidePanel, setContactId, setTask, tasksList: { upcommingTasks, pendingTasks, closedTasks } } =
    useContext(DashboardContext)
  const [tasks, setTasks] = useState([])
  const [selectedTask, setSelectedTask] = useState('')

  const taskMap = useMemo(
    () => ({
      'My Upcoming Tasks': upcommingTasks,
      'Past tasks incomplete': pendingTasks,
      'Complete Past Tasks': closedTasks
    }),
    [upcommingTasks, pendingTasks, closedTasks]
  )

  const history = useHistory()

  const computeTasks = (tasks = []) => {
    return tasks?.map((task) => {
      const name = `${task?.buyer?.firstName ?? ''} ${
        task?.buyer?.lastName ?? ''
      }`
      const dueDate = moment(task.dueDate).format('MMM DD')
      const dateSymbols = { 1: 'st', 2: 'nd', 3: 'rd' }
      const dateSymbol =
        dateSymbols[dueDate.toString()[dueDate.toString().length - 1]] ?? 'th'
      const title = `${dueDate.toString()}${dateSymbol} | ${task.title}`
      return { name, task: title, userId: task?.buyer?._id, _id: task?._id }
    })
  }

  const updateTasks = useRef((tasks) => {})
  updateTasks.current = (tasks = []) => {
    const computed = tasks?.map((task) => {
      const name = `${task?.buyer?.firstName ?? ''} ${
        task?.buyer?.lastName ?? ''
      }`
      const dueDate = moment(task.dueDate).format('MMM DD')
      const dateSymbols = { 1: 'st', 2: 'nd', 3: 'rd' }
      const dateSymbol =
        dateSymbols[dueDate.toString()[dueDate.toString().length - 1]] ?? 'th'
      const title = `${dueDate.toString()}${dateSymbol} | ${task.title}`
      return { name, task: title, userId: task?.buyer?._id }
    })
    setTasks(computed ?? [])
  }

  useEffect(() => {
    updateTasks.current(
      selectedTask ? taskMap[selectedTask] : taskMap['My Upcoming Tasks']
    )
  }, [selectedTask, taskMap])

  return (
    <div className={`flex ${isMobile ? 'flex-col' : 'flex-col'} mt-5 gap-10`}>
      {isMobile && (
        <TaskTable
          tasks={tasks}
          history={history}
          onSelectedTabChange={(selectedTask) => setSelectedTask(selectedTask)}
          selectedTask={selectedTask}
        />
      )}
      {!isMobile && (
        <div className='w-full flex flex-row flex-wrap justify-center items-start gap-2 h-max-content'>
          {Object.keys(taskMap).map((key) => (
            <div className='flex-1 flex-col justify-center items-stretch gap-4'>
              <div
                className='flex-1 bg-softBlack text-white p-2 text-center'
                key={key}
              >
                {key}
              </div>
              <div className='flex-1'>
                <UpcomingTasks
                  showEditDeleteIcons={key.indexOf('Upcoming') !== -1}
                  upcomingTasks={computeTasks(taskMap[key])}
                  rawTasks={taskMap[key]}
                  title={key}
                  history={history}
                  setOpenSidePanel={setOpenSidePanel}
                  setContactId={setContactId}
                  setTask={setTask}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default AdditionalStats
