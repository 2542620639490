import React, { useEffect, useState } from 'react'
import useGetProjectDepositOptions from 'hooks/useGetProjectDepositOptions'
import DepositOptionsTable from './DepositOptionsTable'
import { createOrUpdateDepositOptions } from 'store/actions/offerActions'

const ProjectDepositOptions = () => {
  const { loading: isDepositOptionsLoading, depositOptions, refetch } = useGetProjectDepositOptions()
  const [data, setData] = useState([])

  useEffect(() => {
    if (depositOptions) {
      setData(depositOptions)
    }
  }, [depositOptions])

  const handleCreateOrUpdate = (option) => {
    createOrUpdateDepositOptions(option, true)
      .then((res) => {
        refetch()
      })
      .catch((error) => {
        console.error(error)
      })
  }

  return (
    <div className='bg-white min-h-[calc(100vh-84px)] relative mb-4'>
      <div className='p-3 rounded-xl border bg-card text-card-foreground shadow bg-white'>
        <h1 className='font-black text-lg font-openSans'>Deposit Options</h1>
        <div className='border-b-2 border-black my-2'></div>
        <h2 className='font-openSans'>
          Set up the deposit options here
        </h2>
        <DepositOptionsTable
          data={data}
          loading={isDepositOptionsLoading}
          handleCreateOrUpdate={handleCreateOrUpdate}
        />
      </div>
    </div>
  )
}

export default ProjectDepositOptions
