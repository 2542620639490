import { useQuery } from '@tanstack/react-query'
// import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'
import { getFilterContacts } from 'store/actions/usersActions'
// import { toast } from 'react-hot-toast'

const useGetFilterUsers = (search, userType, filters) => {
  // @ts-ignore
  const userObject = useSelector((state) => state.authReducer?.userObject)
  const userId = userObject?._id

  const getModifiedFilters = () => {
    // if (userObject?.userType === 'SalesRep' && !Object.keys(filters)?.length) {
    //   return {
    //     ...filters,
    //     salesRepOwner: userId
    //   }
    // } else {
    //   return filters
    // }
    // removed above condition because we need to allow all sales-reps to view all contacts
    return filters ?? {}
  }
  const onSuccess = (searchUsers) => {
    // if (searchUsers === null || !searchUsers?.data?.length) {
    //   console.error('No users were found')
    // } else {
    //   toast.success(`Found ${searchUsers?.data.length} contacts`)
    // }
  }
  const { data, isLoading, refetch } = useQuery(
    ['getFilterUsers', { search, userType, ...getModifiedFilters() }],
    () =>
      getFilterContacts({
        ...getModifiedFilters(),
        userType,
        search
      }),
    {
      onSuccess,
      retry: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      enabled:
        !!userId && !!(Object.keys(getModifiedFilters()).length || search || userType)
    }
  )
  return { filteredUsers: data, filtering: isLoading, refetchFilteredUser: refetch }
}

export default useGetFilterUsers
