/* eslint-disable no-restricted-globals */
import React, { useEffect, useMemo } from 'react'
import LeadsData, { HorizontalStats } from './components/LeadsData'
import DashboardProvider from './context/DashboardContext'
import { ContactProvider } from 'pages/contacts/context/Contacts'
import { isNumber } from 'utils/isNumber'
import ReportChat from 'pages/desktopMode/AdminReports/components/ReportChat'
import { TableListItem } from 'pages/desktopMode/AdminReports/components/AllAgentsSalesReport'
import { useGetAPI } from 'hooks/useGenericAPIs'
import moment from 'moment'
import { useSelector } from 'react-redux'
import {
  BarChart as BarChartComponent,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts'
import GraphDataWrapper from './components/GraphDataWrapper'
import AreaTotalBuyerContactsOverTime from './components/AreaTotalBuyerContactsOverTime'

const possibleLeadSourcesToShowOnGraph = [
  'New',
  'Prospect',
  'Reservation holder',
  'Active'
]
const GraphTable = ({ data, total }) => {
  return (
    <div>
      {data?.map((cItem, cIndex) => {
        return <TableListItem {...cItem} vertical={false} />
      })}
      <div className='flex justify-between flex-row'>
        <p className='text-xl pt-2 font-bold'>Total</p>
        <p className='text-xl font-bold'>{total}</p>
      </div>
    </div>
  )
}

const BarChartTotalClientsByStageAndRating = ({
  height = 400,
  width = '100%',
  data = []
}) => {
  const rating = data.reduce((acc, item) => {
    acc.push(...Object.keys(item.rating))
    return acc
  }, [])

  return (
    <div style={{ width, height }}>
      <ResponsiveContainer height={height}>
        <BarChartComponent
          className='w-full'
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5
          }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='name' />
          <YAxis />
          <Tooltip />
          <Legend />
          {[
            // @ts-ignore
            ...new Set(rating)
          ]
            .sort()
            .map((item, index) => {
              return (
                <Bar
                  dataKey={item}
                  fill={`#${Math.floor(Math.random() * 16777215).toString(16)}`}
                />
              )
            })}
        </BarChartComponent>
      </ResponsiveContainer>
    </div>
  )
}

const BarChartTotalClientsByStageAndSalesPerson = ({
  height = 400,
  width = '100%',
  data = []
}) => {
  const salesReps = data.reduce((acc, item) => {
    acc.push(...Object.keys(item.salesReps))
    return acc
  }, [])

  return (
    <div style={{ width, height }}>
      <ResponsiveContainer height={height}>
        <BarChartComponent
          className='w-full'
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5
          }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='name' />
          <YAxis />
          <Tooltip />
          <Legend />
          {[
            // @ts-ignore
            ...new Set(salesReps)
          ]
            .sort()
            .map((item, index) => {
              return (
                <Bar
                  dataKey={item}
                  fill={`#${Math.floor(Math.random() * 16777215).toString(16)}`}
                />
              )
            })}
        </BarChartComponent>
      </ResponsiveContainer>
    </div>
  )
}
const DashboardNewDesign = () => {
  // @ts-ignore
  const projectId = useSelector((state) => state.appReducer.appProject)
  // const data = useSalesrepDashboard()
  const { data: fetchedAllTimeSourceData, fetch: fetchAllTimeSourceData } =
    useGetAPI()
  const { data: fetchedAllTimeStatusData, fetch: fetchAllTimeStatusData } =
    useGetAPI()
  const { data: fetchedAllTimeRatingData, fetch: fetchAllTimeRatingData } =
    useGetAPI()
  const {
    data: fetchedAllTimeSalesByStageData,
    fetch: fetchAllTimeSalesByStageData
  } = useGetAPI()
  const {
    data: fetchedAllTimeLeadsByRatingData,
    fetch: fetchAllTimeLeadsByRatingData
  } = useGetAPI()
  const { data: fetchedFirstRow, fetch: fetchFirstRow } = useGetAPI()
  const { data: fetchedReservationData, fetch: fetchReservationData } =
    useGetAPI()

  useEffect(() => {
    fetchAllTimeSourceData(
      `/users/counts/sources/salesrep/alltime/${projectId}`
    )
    fetchAllTimeStatusData(`/users/counts/status/salesrep/alltime/${projectId}`)
    fetchAllTimeRatingData(`/users/counts/rating/today/${projectId}`)
    fetchFirstRow('/users/reports/count/contacts')
    fetchAllTimeSalesByStageData('/analytics/leads/ByStatus')
    fetchAllTimeLeadsByRatingData('/analytics/leads/ByRating')
    fetchReservationData('/analytics/reservation/metrics/by/year')
  }, [])

  const statsData1 = useMemo(() => {
    if (!fetchedFirstRow || !Object.keys(fetchedFirstRow).length) {
      return []
    }
    // @ts-ignore
    const { total, buyer, leadBroker } = fetchedFirstRow
    return [
      { title: 'Total Contacts', number: isNumber(total) },
      { title: 'Leads', number: isNumber(buyer) },
      { title: 'Brokers ', number: isNumber(leadBroker) }
    ]
  }, [fetchedFirstRow])

  const statsData2 = useMemo(() => {
    if (!fetchedReservationData?.length) {
      return
    }
    const Reservations = fetchedReservationData.reduce((acc, item) => {
      return acc + item.count
    }, 0)
    // @ts-ignore
    const { Contracts = 0, Won = 0 } = {}
    return [
      { title: 'Reservations', number: isNumber(Reservations) },
      { title: 'Contracts', number: isNumber(Contracts) },
      { title: 'Won ', number: isNumber(Won) }
    ]
  }, [fetchedReservationData])

  const allTimeLeadStatusData = useMemo(() => {
    const result = {
      title: 'All Time Lead By Status',
      type: 'Normal',
      date: moment().format('MMM DD YYYY'),
      data: [],
      total: 0
    }
    if (fetchedAllTimeStatusData?.length) {
      result.data = fetchedAllTimeStatusData.map((_item) => {
        return {
          type: 'Normal',
          title: _item?._id ?? 'NULL',
          value: _item.count
        }
      })
      result.total = result.data.reduce((acc, item) => {
        return acc + item.value
      }, 0)
    }
    return result
  }, [fetchedAllTimeStatusData])

  const allTimeLeadSourceData = useMemo(() => {
    const result = {
      title: 'All Time Leads By Source',
      type: 'Normal',
      date: moment().format('MMM DD YYYY'),
      data: [],
      total: 0
    }
    if (fetchedAllTimeSourceData?.length) {
      result.data = fetchedAllTimeSourceData.map((_item) => {
        return {
          type: 'Normal',
          title: _item?._id ?? 'NULL',
          value: _item.count
        }
      })
      result.total = result.data.reduce((acc, item) => {
        return acc + item.value
      }, 0)
    }
    return result
  }, [fetchedAllTimeSourceData])

  const allTimeRatingData = useMemo(() => {
    const result = {
      title: 'All Time Lead Score',
      type: 'Graph',
      date: moment().format('MMM DD YYYY'),
      data: []
    }
    if (fetchedAllTimeRatingData?.length) {
      result.data = fetchedAllTimeRatingData
        .filter((_item) => {
          return _item?._id?.length
        })
        .map((_item) => {
          return {
            name: _item?._id ?? 'NULL',
            value: _item.count
          }
        })
    }
    return result
  }, [fetchedAllTimeRatingData])

  const _TotalClientsByStageAndRating = useMemo(() => {
    const result = {
      title: 'Total Clients By Stage And Rating',
      type: 'Graph',
      date: moment().format('MMM DD YYYY'),
      data: []
    }

    if (fetchedAllTimeLeadsByRatingData?.length) {
      result.data = fetchedAllTimeLeadsByRatingData
        .filter((_item) => {
          return possibleLeadSourcesToShowOnGraph.includes(_item?.label)
        })
        .map((_item) => {
          return {
            name: _item?.label ?? null,
            rating: _item.rating,
            total: _item.total,
            ..._item.rating
          }
        })
    }
    return result
  }, [fetchedAllTimeLeadsByRatingData])

  const _TotalClientsByStageAndSalesPerson = useMemo(() => {
    const result = {
      title: 'Total Clients By Stage And Sales Person',
      type: 'Graph',
      date: moment().format('MMM DD YYYY'),
      data: []
    }

    if (fetchedAllTimeSalesByStageData?.length) {
      result.data = fetchedAllTimeSalesByStageData
        .filter((_item) => {
          return possibleLeadSourcesToShowOnGraph.includes(_item?.label)
        })
        .map((_item) => {
          return {
            name: _item?.label ?? null,
            salesReps: _item.salesReps,
            total: _item.total,
            ..._item.salesReps
          }
        })
    }
    return result
  }, [fetchedAllTimeSalesByStageData])

  return (
    <DashboardProvider>
      <ContactProvider>
        <div className='bg-menuGray min-h-screen font-openSans overscroll-none'>
          <h1 className='font-bold text-4xl px-8 py-6 border-b-2 border-softBlack pl-10'>
            Dashboard
          </h1>
          <div className='grid grid-cols-1 lg:grid-cols-2 min-w-full py-2 align-middle sm:px-6 lg:px-8 gap-2'>
            <div className='col-span-1'>
              <HorizontalStats data={statsData1} />
              <HorizontalStats data={statsData2} />
              <AreaTotalBuyerContactsOverTime />
              <div className='grid grid-cols-2'>
                <GraphDataWrapper {...allTimeLeadStatusData} showBorder={false}>
                  <GraphTable {...allTimeLeadStatusData} />
                </GraphDataWrapper>
                <GraphDataWrapper {...allTimeLeadSourceData} showBorder={false}>
                  <GraphTable {...allTimeLeadSourceData} />
                </GraphDataWrapper>
              </div>
              <GraphDataWrapper {...allTimeRatingData}>
                <ReportChat {...allTimeRatingData} />
              </GraphDataWrapper>
            </div>
            <div className='grid grid-cols-1 gap-2'>
              <GraphDataWrapper {..._TotalClientsByStageAndRating}>
                <BarChartTotalClientsByStageAndRating
                  {..._TotalClientsByStageAndRating}
                />
              </GraphDataWrapper>
              <GraphDataWrapper {..._TotalClientsByStageAndSalesPerson}>
                <BarChartTotalClientsByStageAndSalesPerson
                  {..._TotalClientsByStageAndSalesPerson}
                />
              </GraphDataWrapper>
            </div>
          </div>
          <LeadsData />
        </div>
      </ContactProvider>
    </DashboardProvider>
  )
}
export default DashboardNewDesign
