import { Row, Col } from 'antd'
import standardStyle from 'assets/css/standardStyle'
import styled from 'styled-components'

export const ChosingPaymentMethodWrapper = styled(Row)`
  height: 100%;
  width: 100%;
`

export const PaymentMethodAnimationTitleWrappers = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
  height: 100%;
  background: white;

  @media ${standardStyle.mediaqueries.sm}, ${standardStyle.mediaqueries.xs} {
    height: ${props => props.showPaymentMethods ? '25%' : '100%'};
    /* margin-top: -180px; */
    padding: 0px 10px;
    position: ${props => props.showPaymentMethods ? 'fixed' : 'inherit'};
    top: ${props => props.showPaymentMethods ? '0px' : 'inherit'};
    left: ${props => props.showPaymentMethods ? '0px' : 'inherit'};
    width: 100%;

    #lottieCurrency {
      display: ${props => props.showPaymentMethods ? 'none' : 'unset'};
    }
  }
`

export const PaymentMethodsWrapper = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${standardStyle.mediaqueries.sm}, ${standardStyle.mediaqueries.xs} {
    height: ${props => props.showPaymentMethods ? '75%' : '100%'};
    display: block;
    overflow: scroll;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
  }
`
