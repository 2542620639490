/* eslint-disable space-before-function-paren */
import React, { useCallback, useEffect } from 'react'
import { ReactFormBuilder, Registry } from 'react-form-builder2'
import './bootstrap.css'
import 'react-form-builder2/dist/app.css'

import '@fortawesome/fontawesome-free/css/all.min.css'
import CustomButton from 'components/CustomButton'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import useCustomForms from 'hooks/useCustomForms'
import { ArrowLeft, BarChartIcon } from 'lucide-react'
import FirstNameConfig from './components/firstName'
import LastNameConfig from './components/lastName'
import EmailConfig from './components/email'
import PostalConfig from './components/postalCode'
import PhoneNumberConfig from './components/phoneNumber'
import { getCorporatDemandNotes, GetDemandNotes } from 'store/actions/demandNotes'
import getDemandNoteOption from './components/demandNotes'
import FormSettingsDrawer from '../formSettings'

// // var items = [{
// //   key: 'Header',
// //   name: 'Header Text',
// //   icon: 'fa fa-header',
// //   static: true,
// //   content: 'Placeholder Text...'
// // },
const customInputs = [
  FirstNameConfig,
  LastNameConfig,
  EmailConfig,
  PhoneNumberConfig,
  PostalConfig
]

const _supportedFields = [
  ...customInputs.map((input) => {
    return input.setting
  }), {
    key: 'TextInput'
  }, {
    key: 'TextArea'
  }, {
    key: 'RadioButtons'
  }, {
    key: 'Checkboxes'
  }
]

// eslint-disable-next-line space-before-function-paren
export default function FormsCreate() {
  const { getCustomForm, updateCustomForm } = useCustomForms()
  const [loadingDemandNotes, setLoadingDemandNotes] = React.useState(true)
  const [demandNotes, setDemandNotes] = React.useState([])
  const [supportedFields, setSupportedFields] = React.useState(_supportedFields)
  const [setupDone, setSetupDone] = React.useState(false)
  useEffect(() => {
    const promises = []
    promises.push(GetDemandNotes())
    promises.push(getCorporatDemandNotes())
    Promise.all(promises).then((response) => {
      const all = [...response[0].map((item) => {
        return {
          ...item,
          type: 'project'
        }
      })]
      all.push(...response[1].map((item) => {
        return {
          ...item,
          type: 'corporate'
        }
      }))
      setDemandNotes(all)
    }).catch((error) => {
      console.error('error', error)
    }).finally(() => {
      setLoadingDemandNotes(false)
    })
  }, [])

  useEffect(() => {
    customInputs.forEach((input) => {
      // @ts-ignore
      if (!Registry.get(input.id)) {
        Registry.register(input.id, input.component)
      }
    })
    // create custom fields for demand notes
    const demandNoteFields = []
    for (let i = 0; i < demandNotes?.length; i++) {
      const item = demandNotes?.[i]
      const setting = getDemandNoteOption(item)
      if (!Registry.get(setting.id)) {
        Registry.register(setting.id, setting.setting.component)
        demandNoteFields.push(setting.setting)
      }
    }
    setSupportedFields((e) => {
      return [
        ...e,
        ...demandNoteFields
      ]
    })
    setSetupDone(true)
  }, [demandNotes])

  // get formId from query params using react-router-dom
  const history = useHistory()
  const formId = new URLSearchParams(history.location.search).get('formId')
  const [form, setForm] = React.useState(null)
  const [saving, setSaving] = React.useState(false)
  const [loading, setLoading] = React.useState(true)

  const [editing, setEditing] = React.useState(false)
  const [formName, setFormName] = React.useState('')
  const [updating, setUpdating] = React.useState(false)

  const getFormData = useCallback(() => {
    return new Promise((resolve, reject) => {
      if (formId) {
        setLoading(true)
        getCustomForm(formId).then((response) => {
          resolve(response.data)
          setFormName(response?.data?.name)
        }).finally(() => {
          setLoading(false)
        })
      } else {
        resolve(null)
      }
    })
  }, [formId, getCustomForm])

  function saveForm() {
    if (saving) {
      return
    }
    setSaving(true)
    updateCustomForm(formId, { fields: form.fields }).then((response) => {
      alert('Form saved successfully')
    }).catch((error) => {
      console.error('error', error)
    }).finally(() => {
      setSaving(false)
    })
  }
  useEffect(() => {
    getFormData().then((response) => {
      setForm(response)
    })
  }, [getFormData, formId])

  function updateFormName(formName) {
    if (updating) { return }
    setUpdating(true)
    updateCustomForm(formId, { name: formName }).then((response) => {
      setFormName(formName)
    }).finally(() => {
      setEditing(false)
      setUpdating(false)
    })
  }

  if (loading || loadingDemandNotes || !setupDone) {
    return <div>Loading...</div>
  }
  return (
    <div className='pb-4 py-2 flex flex-col'>
      <div className='flex items-center justify-between w-full px-4'>
        <div className='flex items-center justify-center gap-x-2'>
          <div
            className='flex items-center justify-center cursor-pointer'
            onClick={() => {
              history.goBack()
            }}
          >
            <ArrowLeft size={12} /> <span>
              Go back
            </span>
          </div>
          <h1 className='text-2xl font-bold'>
            {editing && (
              <div className='flex items-center justify-center gap-x-2'>
                <input type='text' className='min-w-[300px]' value={formName}
                  disabled={updating}
                  onChange={(e) => {
                    if (updating) return
                    setFormName(e.target.value)
                  }} />
                <button
                  className='py-0.5 px-1.5 text-sm bg-blue-500 text-white rounded-md'
                  disabled={updating}
                  onClick={() => {
                    updateFormName(formName)
                  }}
                >
                  Update
                </button>
                <button
                  className='py-0.5 px-1.5 text-sm bg-red-500 text-white rounded-md'
                  disabled={updating}
                  onClick={() => {
                    setFormName(form?.name)
                    setEditing(false)
                  }}
                >
                  cancel
                </button>
              </div>
            )}
            {!editing && <div className='flex items-center justify-center gap-x-2'>
              <div>
                {formName}
              </div>
              <div>
                <button
                  className='py-0.5 px-1.5 text-sm bg-blue-500 text-white rounded-md'
                  onClick={() => {
                    setEditing(true)
                  }}>Edit</button>
              </div>
            </div>}
          </h1>
        </div>
        <div className='flex items-center gap-x-2'>
          <a href={`/admin/forms/analytics?formId=${formId}`}>
            <CustomButton
              disabled={saving}
              className='py-1 px-2'
              variant='outline'
            >
              <BarChartIcon size={15} />  Analytics
            </CustomButton>
          </a>
          <CustomButton
            disabled={saving}
            className='py-1 px-2'
            handleClick={() => {
              saveForm()
            }}
          >
            {saving ? 'Saving...' : 'Save Form'}
          </CustomButton>
          <FormSettingsDrawer formId={formId} onClose={() => { }} />
        </div>
      </div>
      <div className='border border-border h-[1px] bg-black w-full mb-9 mt-1'>
      </div>
      <ReactFormBuilder
        locale='en'
        editMode={true}
        // @ts-ignore
        toolbarItems={supportedFields}
        data={form?.fields || []}
        onPost={(data) => {
          setForm({
            ...form,
            fields: data.task_data
          })
        }}
      />
    </div >
  )
}
