export const countryList = [
  { value: 'Afghanistan', id: 'af' },
  { value: 'Aland Islands', id: 'ax' },
  { value: 'Albania', id: 'al' },
  { value: 'Algeria', id: 'dz' },
  { value: 'American Samoa', id: 'as' },
  { value: 'Andorra', id: 'ad' },
  { value: 'Angola', id: 'ao' },
  { value: 'Anguilla', id: 'ai' },
  { value: 'Antigua', id: 'ag' },
  { value: 'Argentina', id: 'ar' },
  { value: 'Armenia', id: 'am' },
  { value: 'Aruba', id: 'aw' },
  { value: 'Australia', id: 'au' },
  { value: 'Austria', id: 'at' },
  { value: 'Azerbaijan', id: 'az' },
  { value: 'Bahamas', id: 'bs' },
  { value: 'Bahrain', id: 'bh' },
  { value: 'Bangladesh', id: 'bd' },
  { value: 'Barbados', id: 'bb' },
  { value: 'Belarus', id: 'by' },
  { value: 'Belgium', id: 'be' },
  { value: 'Belize', id: 'bz' },
  { value: 'Benin', id: 'bj' },
  { value: 'Bermuda', id: 'bm' },
  { value: 'Bhutan', id: 'bt' },
  { value: 'Bolivia', id: 'bo' },
  { value: 'Bosnia', id: 'ba' },
  { value: 'Botswana', id: 'bw' },
  { value: 'Bouvet Island', id: 'bv' },
  { value: 'Brazil', id: 'br' },
  { value: 'British Virgin Islands', id: 'vg' },
  { value: 'Brunei', id: 'bn' },
  { value: 'Bulgaria', id: 'bg' },
  { value: 'Burkina Faso', id: 'bf' },
  { value: 'Burma', id: 'mm', alias: 'Myanmar' },
  { value: 'Burundi', id: 'bi' },
  { value: 'Caicos Islands', id: 'tc' },
  { value: 'Cambodia', id: 'kh' },
  { value: 'Cameroon', id: 'cm' },
  { value: 'Canada', id: 'ca' },
  { value: 'Cape Verde', id: 'cv' },
  { value: 'Cayman Islands', id: 'ky' },
  { value: 'Central African Republic', id: 'cf' },
  { value: 'Chad', id: 'td' },
  { value: 'Chile', id: 'cl' },
  { value: 'China', id: 'cn' },
  { value: 'Christmas Island', id: 'cx' },
  { value: 'Cocos Islands', id: 'cc' },
  { value: 'Colombia', id: 'co' },
  { value: 'Comoros', id: 'km' },
  { value: 'Congo', id: 'cd' },
  { value: 'Congo Brazzaville', id: 'cg' },
  { value: 'Cook Islands', id: 'ck' },
  { value: 'Costa Rica', id: 'cr' },
  { value: 'Cote Divoire', id: 'ci' },
  { value: 'Croatia', id: 'hr' },
  { value: 'Cuba', id: 'cu' },
  { value: 'Cyprus', id: 'cy' },
  { value: 'Czech Republic', id: 'cz' },
  { value: 'Denmark', id: 'dk' },
  { value: 'Djibouti', id: 'dj' },
  { value: 'Dominica', id: 'dm' },
  { value: 'Dominican Republic', id: 'do' },
  { value: 'Ecuador', id: 'ec' },
  { value: 'Egypt', id: 'eg' },
  { value: 'El Salvador', id: 'sv' },
  { value: 'England', id: 'gb' },
  { value: 'Equatorial Guinea', id: 'gq' },
  { value: 'Eritrea', id: 'er' },
  { value: 'Estonia', id: 'ee' },
  { value: 'Ethiopia', id: 'et' },
  { value: 'Europeanunion', id: 'eu' },
  { value: 'Falkland Islands', id: 'fk' },
  { value: 'Faroe Islands', id: 'fo' },
  { value: 'Fiji', id: 'fj' },
  { value: 'Finland', id: 'fi' },
  { value: 'France', id: 'fr' },
  { value: 'French Guiana', id: 'gf' },
  { value: 'French Polynesia', id: 'pf' },
  { value: 'French Territories', id: 'tf' },
  { value: 'Gabon', id: 'ga' },
  { value: 'Gambia', id: 'gm' },
  { value: 'Georgia', id: 'ge' },
  { value: 'Germany', id: 'de' },
  { value: 'Ghana', id: 'gh' },
  { value: 'Gibraltar', id: 'gi' },
  { value: 'Greece', id: 'gr' },
  { value: 'Greenland', id: 'gl' },
  { value: 'Grenada', id: 'gd' },
  { value: 'Guadeloupe', id: 'gp' },
  { value: 'Guam', id: 'gu' },
  { value: 'Guatemala', id: 'gt' },
  { value: 'Guinea', id: 'gn' },
  { value: 'Guinea-Bissau', id: 'gw' },
  { value: 'Guyana', id: 'gy' },
  { value: 'Haiti', id: 'ht' },
  { value: 'Heard Island', id: 'hm' },
  { value: 'Honduras', id: 'hn' },
  { value: 'Hong Kong', id: 'hk' },
  { value: 'Hungary', id: 'hu' },
  { value: 'Iceland', id: 'is' },
  { value: 'India', id: 'in' },
  { value: 'Indian Ocean Territory', id: 'io' },
  { value: 'Indonesia', id: 'id' },
  { value: 'Iran', id: 'ir' },
  { value: 'Iraq', id: 'iq' },
  { value: 'Ireland', id: 'ie' },
  { value: 'Israel', id: 'il' },
  { value: 'Italy', id: 'it' },
  { value: 'Jamaica', id: 'jm' },
  { value: 'Jan Mayen', id: 'sj', alias: 'Svalbard' },
  { value: 'Japan', id: 'jp' },
  { value: 'Jordan', id: 'jo' },
  { value: 'Kazakhstan', id: 'kz' },
  { value: 'Kenya', id: 'ke' },
  { value: 'Kiribati', id: 'ki' },
  { value: 'Kuwait', id: 'kw' },
  { value: 'Kyrgyzstan', id: 'kg' },
  { value: 'Laos', id: 'la' },
  { value: 'Latvia', id: 'lv' },
  { value: 'Lebanon', id: 'lb' },
  { value: 'Lesotho', id: 'ls' },
  { value: 'Liberia', id: 'lr' },
  { value: 'Libya', id: 'ly' },
  { value: 'Liechtenstein', id: 'li' },
  { value: 'Lithuania', id: 'lt' },
  { value: 'Luxembourg', id: 'lu' },
  { value: 'Macau', id: 'mo' },
  { value: 'Macedonia', id: 'mk' },
  { value: 'Madagascar', id: 'mg' },
  { value: 'Malawi', id: 'mw' },
  { value: 'Malaysia', id: 'my' },
  { value: 'Maldives', id: 'mv' },
  { value: 'Mali', id: 'ml' },
  { value: 'Malta', id: 'mt' },
  { value: 'Marshall Islands', id: 'mh' },
  { value: 'Martinique', id: 'mq' },
  { value: 'Mauritania', id: 'mr' },
  { value: 'Mauritius', id: 'mu' },
  { value: 'Mayotte', id: 'yt' },
  { value: 'Mexico', id: 'mx' },
  { value: 'Micronesia', id: 'fm' },
  { value: 'Moldova', id: 'md' },
  { value: 'Monaco', id: 'mc' },
  { value: 'Mongolia', id: 'mn' },
  { value: 'Montenegro', id: 'me' },
  { value: 'Montserrat', id: 'ms' },
  { value: 'Morocco', id: 'ma' },
  { value: 'Mozambique', id: 'mz' },
  { value: 'Namibia', id: 'na' },
  { value: 'Nauru', id: 'nr' },
  { value: 'Nepal', id: 'np' },
  { value: 'Netherlands', id: 'nl' },
  { value: 'Netherlandsantilles', id: 'an' },
  { value: 'New Caledonia', id: 'nc' },
  { value: 'New Guinea', id: 'pg' },
  { value: 'New Zealand', id: 'nz' },
  { value: 'Nicaragua', id: 'ni' },
  { value: 'Niger', id: 'ne' },
  { value: 'Nigeria', id: 'ng' },
  { value: 'Niue', id: 'nu' },
  { value: 'Norfolk Island', id: 'nf' },
  { value: 'North Korea', id: 'kp' },
  { value: 'Northern Mariana Islands', id: 'mp' },
  { value: 'Norway', id: 'no' },
  { value: 'Oman', id: 'om' },
  { value: 'Pakistan', id: 'pk' },
  { value: 'Palau', id: 'pw' },
  { value: 'Palestine', id: 'ps' },
  { value: 'Panama', id: 'pa' },
  { value: 'Paraguay', id: 'py' },
  { value: 'Peru', id: 'pe' },
  { value: 'Philippines', id: 'ph' },
  { value: 'Pitcairn Islands', id: 'pn' },
  { value: 'Poland', id: 'pl' },
  { value: 'Portugal', id: 'pt' },
  { value: 'Puerto Rico', id: 'pr' },
  { value: 'Qatar', id: 'qa' },
  { value: 'Reunion', id: 're' },
  { value: 'Romania', id: 'ro' },
  { value: 'Russia', id: 'ru' },
  { value: 'Rwanda', id: 'rw' },
  { value: 'Saint Helena', id: 'sh' },
  { value: 'Saint Kitts and Nevis', id: 'kn' },
  { value: 'Saint Lucia', id: 'lc' },
  { value: 'Saint Pierre', id: 'pm' },
  { value: 'Saint Vincent', id: 'vc' },
  { value: 'Samoa', id: 'ws' },
  { value: 'San Marino', id: 'sm' },
  { value: 'Sandwich Islands', id: 'gs' },
  { value: 'Sao Tome', id: 'st' },
  { value: 'Saudi Arabia', id: 'sa' },
  { value: 'Scotland', id: 'gb sct' },
  { value: 'Senegal', id: 'sn' },
  { value: 'Serbia', id: 'cs' },
  { value: 'Serbia', id: 'rs' },
  { value: 'Seychelles', id: 'sc' },
  { value: 'Sierra Leone', id: 'sl' },
  { value: 'Singapore', id: 'sg' },
  { value: 'Slovakia', id: 'sk' },
  { value: 'Slovenia', id: 'si' },
  { value: 'Solomon Islands', id: 'sb' },
  { value: 'Somalia', id: 'so' },
  { value: 'South Africa', id: 'za' },
  { value: 'South Korea', id: 'kr' },
  { value: 'Spain', id: 'es' },
  { value: 'Sri Lanka', id: 'lk' },
  { value: 'Sudan', id: 'sd' },
  { value: 'Suriname', id: 'sr' },
  { value: 'Swaziland', id: 'sz' },
  { value: 'Sweden', id: 'se' },
  { value: 'Switzerland', id: 'ch' },
  { value: 'Syria', id: 'sy' },
  { value: 'Taiwan', id: 'tw' },
  { value: 'Tajikistan', id: 'tj' },
  { value: 'Tanzania', id: 'tz' },
  { value: 'Thailand', id: 'th' },
  { value: 'Timorleste', id: 'tl' },
  { value: 'Togo', id: 'tg' },
  { value: 'Tokelau', id: 'tk' },
  { value: 'Tonga', id: 'to' },
  { value: 'Trinidad', id: 'tt' },
  { value: 'Tunisia', id: 'tn' },
  { value: 'Turkey', id: 'tr' },
  { value: 'Turkmenistan', id: 'tm' },
  { value: 'Tuvalu', id: 'tv' },
  { value: 'U.A.E.', id: 'ae', alias: 'United Arab Emirates' },
  { value: 'Uganda', id: 'ug' },
  { value: 'Ukraine', id: 'ua' },
  { value: 'United Kingdom', id: 'gb', alias: 'uk' },
  { value: 'United States', id: 'us', alias: 'America' },
  { value: 'Uruguay', id: 'uy' },
  { value: 'US Minor Islands', id: 'um' },
  { value: 'US Virgin Islands', id: 'vi' },
  { value: 'Uzbekistan', id: 'uz' },
  { value: 'Vanuatu', id: 'vu' },
  { value: 'Vatican City', id: 'va' },
  { value: 'Venezuela', id: 've' },
  { value: 'Vietnam', id: 'vn' },
  { value: 'Wales', id: 'gb wls' },
  { value: 'Wallis and Futuna', id: 'wf' },
  { value: 'Western Sahara', id: 'eh' },
  { value: 'Yemen', id: 'ye' },
  { value: 'Zambia', id: 'zm' },
  { value: 'Zimbabwe', id: 'zw' }
]
