/* eslint-disable */
// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { classNames } from 'utils'
import InventoryEditDrawer from './InventoryEditDrawer'
import useInventory from '../hooks/useInventory'
import AssignPolygonToInventoryItemModal from './AssignPolygonToInventoryItemModal'
import CustomLoading from './CustomLoading'
import InventoryMapHeader from './InventoryMapHeader'
import { useSelector } from 'react-redux'

const MAP_API_KEY = 'AIzaSyCLOpolCuSLdtA4oybC6dF8RdbLWP4K8wM'

const YOUR_METERS_PER_PIXEL = 0.02
const YOUR_METERS_PER_DEGREE_LAT = 5000
const YOUR_METERS_PER_DEGREE_LNG = 5000

const CustomMap = ({
  mapInfo,
  selectedLevel = '',
  inventoryData,
  polygonsData,
  setPolygonsData,
  isLoading,
  statusCount,
  polygons,
  refetchInventoryData,
  refetchPolygonsData
}) => {
  const userObject = useSelector((state) => state.authReducer.userObject)
  const { state: { statusColors } } = useInventory()
  const [isInfoDrawerOpen, setInfoDrawerOpen] = useState(false)
  const [map, setMap] = useState(null)
  const [drawingManager, setDrawingManager] = useState(null)
  const [showAssignPolygonToInventoryModal, setShowAssignPolygonToInventoryModal] = useState(false)
  const [newPolygonsData, setNewPolygonsData] = useState([])
  const [editDetails, setEditDetails] = useState({})
  const [isMapLoading, setIsMapLoading] = useState(false)

  const updatePolygons = () => {
    setIsMapLoading(true)
    if (!polygonsData?.length || !map || !inventoryData?.length) {
      setIsMapLoading(false)
      return
    }

    // Add a click event listener to show a custom info-window
    const infoWindowContent = '<div><h3>Custom Info-Window</h3><p>Additional information goes here.</p></div>'
    const infoWindow = new window.google.maps.InfoWindow({
      content: infoWindowContent,
    })

    polygons.forEach((polygonData) => {
      const selectedInventoriesData = { ...inventoryData.filter((inventory) => inventory._id === polygonData.inventoryId)[0], polygonId: polygonData.polygonId }

      const customPolygon = new window.google.maps.Polygon({
        paths: polygonData.coordinates,
        strokeColor: statusColors[selectedInventoriesData?.status?.toLowerCase()]?.borderColor ?? '#EDDF65',
        strokeOpacity: 1, //0.8,
        strokeWeight: 2,
        fillColor: statusColors[selectedInventoriesData?.status?.toLowerCase()]?.bgColor ?? '#EDDF65',
        fillOpacity: 1, // 0.35,
        map: map,
      })

      window.google.maps.event.addListener(
        customPolygon,
        // 'mouseover',
        'click',
        function (event) {
          infoWindow.setPosition(event.latLng)
          infoWindow.setContent(`
            <div style='inset: 0px auto auto 0px;'>
              <div style='display: flex; flex-direction: column; color: #2E2B2E;'>
                <div style='font-weight: bold; font-size: 12px; padding: 4.5px 8px; display: flex; justify-content: center; align-items: center; width: 171px; height: 27px; background-color: ${statusColors[selectedInventoriesData?.status?.toLowerCase()]?.bgColor}; color: ${statusColors[selectedInventoriesData?.status?.toLowerCase()]?.textColor}; border: 1px solid ${statusColors[selectedInventoriesData?.status?.toLowerCase()].borderColor}; text-transform: uppercase;'>${selectedInventoriesData.status}</div>
                <div style='font-weight: bold; font-size: 24px; margin-top: 8px;'>${mapInfo.type} #${mapInfo.type === 'Parking' ? selectedInventoriesData.stall : selectedInventoriesData.unitNumber}</div>
                <div style='font-weight: medium; font-size: 16px; margin-top: 9px;'>$${selectedInventoriesData.price}</div>
              </div>
            </div>
          `)
          infoWindow.open(map)
        },
      )
      window.google.maps.event.addListener(
        customPolygon,
        'click',
        function () {
          setEditDetails(selectedInventoriesData)
          setInfoDrawerOpen(true)
        },
      )
    })
    setIsMapLoading(false)
  }

  const overlayClickListener = (event) => {
    setShowAssignPolygonToInventoryModal(true)
    const cPolygon = event.overlay
      .getPath()
      .getArray()
      .map((a) => ({ lat: a.lat(), lng: a.lng() }))
    setPolygonsData([...polygonsData, { polygon: cPolygon }])
    setNewPolygonsData([...cPolygon])
  }

  useEffect(() => {
    if (drawingManager) {
      google.maps.event.addListener(
        drawingManager,
        'overlaycomplete',
        overlayClickListener,
      )
    }

    if (polygonsData?.length > 0) {
      updatePolygons()
    }
    return () => {
      if (drawingManager) {
        google.maps.event.clearListeners(drawingManager, 'overlaycomplete')
      }
    }
  }, [drawingManager, polygonsData])

  const initMap = () => {
    // Geocode the address to get coordinates
    const geocoder = new window.google.maps.Geocoder()
    geocoder.geocode(
      {
        address: mapInfo.address,
      },
      (results, status) => {
        if (status === 'OK' && results[0].geometry) {
          const centerPoint = {
            lat: mapInfo.centerPointLocation_lat,
            lng: mapInfo.centerPointLocation_lng,
          }

          // Image size in pixels
          const imageSize = { width: mapInfo.width, height: mapInfo.height }

          // Calculate half of the width and height in meters
          const halfWidth = imageSize.width * YOUR_METERS_PER_PIXEL
          const halfHeight = imageSize.height * YOUR_METERS_PER_PIXEL

          // Calculate the southwest and northeast corners of the image
          const imageSW = {
            lat: centerPoint.lat - halfHeight / YOUR_METERS_PER_DEGREE_LAT,
            lng: centerPoint.lng - halfWidth / YOUR_METERS_PER_DEGREE_LNG,
          }

          const imageNE = {
            lat: centerPoint.lat + halfHeight / YOUR_METERS_PER_DEGREE_LAT,
            lng: centerPoint.lng + halfWidth / YOUR_METERS_PER_DEGREE_LNG,
          }

          // Output the values
          const newMap = new google.maps.Map(document.getElementById('map'), {
            zoom: mapInfo.initialZoomLevel,
            center: centerPoint,
            rotateControl: true,
            rotateControlOptions: {
              position: window.google.maps.ControlPosition.TOP_CENTER,
            },
            heading: 90,
          });

          // Add a custom image overlay
          const bounds = new window.google.maps.LatLngBounds(
            new window.google.maps.LatLng(imageSW.lat, imageSW.lng),
            new window.google.maps.LatLng(imageNE.lat, imageNE.lng),
          )
          // The photograph is courtesy of the U.S. Geological Survey.
          const srcImage = mapInfo.imgSrc

          class USGSOverlay extends google.maps.OverlayView {
            bounds_
            image_
            div_
            constructor(bounds, image) {
              super()
              // Initialize all properties.
              this.bounds_ = bounds
              this.image_ = image
              // Define a property to hold the image's div. We'll
              // actually create this div upon receipt of the onAdd()
              // method so we'll leave it null for now.
              this.div_ = null
            }
            /**
             * onAdd is called when the map's panes are ready and the overlay has been
             * added to the map.
             */
            onAdd() {
              this.div_ = document.createElement('div')
              this.div_.style.borderStyle = 'none'
              this.div_.style.borderWidth = '0px'
              this.div_.style.position = 'absolute'

              // Create the img element and attach it to the div.
              const img = document.createElement('img')

              img.src = this.image_
              img.style.width = '100%'
              img.style.height = '100%'
              img.style.position = 'absolute'
              this.div_.appendChild(img)

              // Add the element to the 'overlayLayer' pane.
              const panes = this.getPanes()

              panes.overlayLayer.appendChild(this.div_)
            }
            draw() {
              // We use the south-west and north-east
              // coordinates of the overlay to peg it to the correct position and size.
              // To do this, we need to retrieve the projection from the overlay.
              const overlayProjection = this.getProjection()
              // Retrieve the south-west and north-east coordinates of this overlay
              // in Lat/Lng and convert them to pixel coordinates.
              // We'll use these coordinates to resize the div.
              const sw = overlayProjection.fromLatLngToDivPixel(
                this.bounds_.getSouthWest(),
              )
              const ne = overlayProjection.fromLatLngToDivPixel(
                this.bounds_.getNorthEast(),
              )

              // Resize the image's div to fit the indicated dimensions.
              if (this.div_) {
                this.div_.style.left = sw.x + 'px'
                this.div_.style.top = ne.y + 'px'
                this.div_.style.width = ne.x - sw.x + 'px'
                this.div_.style.height = sw.y - ne.y + 'px'
              }
            }
            /**
             * The onRemove() method will be called automatically from the API if
             * we ever set the overlay's map property to 'null'.
             */
            onRemove() {
              if (this.div_) {
                this.div_.parentNode.removeChild(this.div_)
                this.div_ = null
              }
            }
          }

          const overlay = new USGSOverlay(bounds, srcImage)
          overlay.setMap(newMap)

          const _drawingManager = new google.maps.drawing.DrawingManager({
            drawingMode: userObject?.userType === 'DeveloperAdmin' ? google.maps.drawing.OverlayType.POLYGON : false,
            drawingControl: !!(userObject?.userType === 'DeveloperAdmin'),
            drawingControlOptions: {
              position: google.maps.ControlPosition.TOP_CENTER,
              drawingModes: [google.maps.drawing.OverlayType.POLYGON],
            },
            polygonOptions: {
              editable: !!(userObject?.userType === 'DeveloperAdmin')
            },
          })
          _drawingManager.setMap(newMap)

          setMap(newMap)
          updatePolygons()
          setDrawingManager(_drawingManager)
        } else {
          console.error('Geocoding failed:', status)
        }
      },
    )
  }

  useEffect(() => {
    // Load the Google Maps API script dynamically
    const script = document.createElement('script')
    document.head.appendChild(script)
    if (!isLoading) {
      script.src = `https://maps.googleapis.com/maps/api/js?key=${MAP_API_KEY}&libraries=drawing`
      script.async = true
      script.onload = () => {
        initMap()
      }
    }

    // Clean up the script on component unmount
    return () => {
      document.head.removeChild(script)
    }
  }, [inventoryData, isLoading, polygonsData, selectedLevel, mapInfo, userObject])

  const handleAssignPolygonCancelation = () => {
    setPolygonsData(polygonsData.filter(({ polygon }) => (polygon[0].lat !== newPolygonsData[0].lat && polygon[0].lng !== newPolygonsData[0].lng)))
  }

  return (
    <div className='w-full h-full'>
      {
        mapInfo?._id || isLoading
          ? <div className='flex w-full h-full border-t border-[#E4E4E7]'>
            <div
              className={classNames(
                `flex flex-col w-full max-h-full overflow-auto transition-all mx-7.5 gap-4 py-4`,
                isInfoDrawerOpen ? 'mr-[370px]' : 'mr-7.5',
              )}
            >
              <InventoryMapHeader
                type={mapInfo.type}
                totalCount={inventoryData.length}
                statusCountsData={statusCount}
                isLoading={isMapLoading}
              />
              <div className='relative w-full h-full overflow-hidden bg-white rounded-xl'>
                {
                  isLoading || isMapLoading &&
                  <div className='absolute top-0 left-0 h-full w-full bg-white/[90%] z-[2] flex justify-center items-center rounded-xl'>
                    <CustomLoading />
                  </div>
                }
                <div id='map' className='border border-zinc-200 shadow-sm rounded-xl' style={{ width: '100%', height: '100%' }}></div>
              </div>
            </div>
            <InventoryEditDrawer
              editDetails={editDetails}
              setEditDetails={setEditDetails}
              closeInfoDrawer={() => setInfoDrawerOpen(false)}
              isInfoDrawerOpen={isInfoDrawerOpen}
              isAbleToPurchase
              refetch={() => {
                refetchInventoryData()
                refetchPolygonsData()
              }}
              type={mapInfo.type}
            />
            {showAssignPolygonToInventoryModal && (
              <AssignPolygonToInventoryItemModal
                show={showAssignPolygonToInventoryModal}
                setShow={setShowAssignPolygonToInventoryModal}
                refetch={refetchPolygonsData}
                inventoryItemsData={inventoryData}
                polygonsData={newPolygonsData}
                handleCancel={handleAssignPolygonCancelation}
                allItemsPolygonsData={polygonsData}
                type={mapInfo.type}
              />
            )}
          </div>
          : <div className='w-full h-full flex justify-center items-center text-lg font-medium'>Please, add map-inventory data</div>
      }
    </div>
  )
}

export default CustomMap
