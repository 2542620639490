import { useQueryClient } from '@tanstack/react-query'
import MobileCardSidebar from 'components/HorizontalCardProfile/MobileCardSidebar'
import useBuyerScore from 'hooks/useBuyerScore'
import useBuyers from 'hooks/useGetBuyers'
import useGetLeadBrokers from 'hooks/useGetLeadBrokers'
import useGetSalesReps from 'hooks/useGetSalesReps'
import useLeadStatus from 'hooks/useLeadStatus'
import useUpdateUsers from 'hooks/useUpdateUsers'
import ContactItem from 'pages/contacts/ContactItem'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { createBuyerToSalesRepAssignment, deleteUser, updateBuyerToSalesRepAssignment, updateLeadStatus } from 'store/actions/usersActions'

const ContactCard = ({ fullScreen, setSelectedContact, contact, tasksList, contactId }) => {
  const { buyers, getBuyersData } = useBuyers()
  const { createOrUpdateUser } = useUpdateUsers()
  const { formattedLeadStatuses: leadStatuses } = useLeadStatus()
  const { formattedScores: buyerScores } = useBuyerScore()
  // @ts-ignore
  const userObject = useSelector((state) => state.authReducer.userObject)
  // @ts-ignore
  const projectId = useSelector((state) => state.appReducer.appProject)
  const [salesrep, setSalesRep] = useState(null)
  const [, setOpenAddToList] = useState(false)
  const client = useQueryClient()

  const { data: brokers, refetchBrokers } = useGetLeadBrokers()
  const { salesReps, refetch } = useGetSalesReps()

  useEffect(() => {
    getBuyersData()
  }, [])

  useEffect(() => {
    if (brokers?.length) return
    refetchBrokers()
  }, [brokers, refetchBrokers])

  useEffect(() => {
    if (salesReps?.length) return
    refetch()
  }, [salesReps, refetch])

  useEffect(() => {
    if (contactId?.length) {
      setSelectedContact?.(contactId)
    }
  }, [contactId])

  useEffect(() => {
    if (!brokers?.length || !salesReps?.length || !contact?._id) return
    let rep = contact?.salesRep
    if (!rep) {
      rep = salesReps?.find?.(
        (rep) => rep?._id === contact?.buyerData?.assignments?.[0]?.salesRep
      )
    }
    setSalesRep(rep)
    if (!rep) {
      const assign = contact?.leadBrokerData?.assignments?.[0]?.salesRep
      const rep = salesReps.find((obj) => obj?._id === assign)
      setSalesRep(rep)
    }
  }, [contact, brokers, salesReps, setSalesRep, projectId])

  const invalidateQuery = () =>
    client.invalidateQueries({
      predicate: (query) =>
        query.queryKey[0] === 'getUserById' && query.queryKey[1] === contactId
    })

  const handleLeadStatusChange = async (type) => {
    const newUserData = { ...contact }
    if (newUserData.buyerData && Object.keys(newUserData.buyerData).length) {
      newUserData.buyerData.leadStatus = type
    }

    try {
      await updateLeadStatus(type, contact._id)
      invalidateQuery()
    } catch (err) {
      console.log(err)
    }
  }

  const handleRatingChange = async (value) => {
    const newUserData = { ...contact }
    if (newUserData.buyerData && Object.keys(newUserData.buyerData).length) {
      newUserData.buyerData.rating = value
    }

    try {
      await createOrUpdateUser(newUserData, false, false)
      invalidateQuery()
    } catch (err) {
      console.log(err)
    }
  }

  const handleSalesRepChange = (type, params) => {
    if (type?.create) createBuyerToSalesRepAssignment(params)
    else updateBuyerToSalesRepAssignment(params)
    invalidateQuery()
  }

  const handleContactDelete = (id) => {
    deleteUser(id)
  }

  return (
    <>
      {fullScreen
        ? <ContactItem
            handleContactDelete={null}
            isSalesRep={userObject?.userType === 'SalesRep'}
            showBackButton={false}
          />
        : <MobileCardSidebar
            leadStatuses={leadStatuses}
            scores={buyerScores}
            onClickDelete={handleContactDelete}
            email={contact?.email}
            firstName={contact?.firstName}
            lastName={contact?.lastName}
            leadStatus={contact?.buyerData?.leadStatus || 'New Lead'}
            handleLeadStatusChange={handleLeadStatusChange}
            handleRatingChange={handleRatingChange}
            rating={contact?.buyerData?.rating}
            userType={contact?.userType}
            buyerData={contact?.buyerData}
            buyerEmail={contact?.email}
            developerAdminId={userObject?._id}
            handleSalesRepChange={handleSalesRepChange}
            projectId={projectId}
            salesRep={salesReps}
            salesrep={salesrep}
            setSalesRep={setSalesRep}
            setOpenAddToList={setOpenAddToList}
            forceMobile
            showDeleteButton={false}
            contact={contact}
            buyers={buyers}
            tasksList={tasksList}
          />
      }
    </>
  )
}

export default ContactCard
