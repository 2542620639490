import dompurify from 'dompurify'

// ONLY NUMBERS
export const onlyNumbers = (value = '') => Number(String(value).replace(/[^0-9]/g, ''))

// CLEAN CLASSES SPACES
export const classNames = (...classes) => classes.filter(Boolean).join(' ')

// CLEAN LAST , ON STRINGS FINAL
export const removeLastCommaFromStrings = (value) => {
  const trimmedValue = value.trim()

  if (trimmedValue[trimmedValue.length - 1] === ',') {
    return trimmedValue.slice(0, -1)
  }
  return trimmedValue
}

// SANITIZE code
export const sanitizeCode = (value) => {
  const sanitizer = dompurify.sanitize

  return sanitizer(value)
}
