import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

export const HistoryContext = React.createContext({ lastAction: '', setLastAction: (action) => {} })

const HistoryProvider = ({ children }) => {
  const [lastAction, setLastAction] = useState('')
  const history = useHistory()

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      setLastAction(action)
    })
    return () => unlisten()
  }, [history])

  return <HistoryContext.Provider value={{ lastAction, setLastAction }}>
    {children}
  </HistoryContext.Provider>
}

export default HistoryProvider
