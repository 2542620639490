import React from 'react'

const SelectButtonWrapper = ({ children }) => {
  return (
    <div className='flex w-full h-11 border-b-softBlack border-b items-center'>
      {children}
    </div>
  )
}

export default SelectButtonWrapper
