import { api, ApiErrors } from 'services/api'
import { message } from 'antd'

export const getAllEmailCampaigns = (query = '') =>
  new Promise((resolve, reject) => {
    api.get(`/campaigns?${query}`).then((response) => {
      if (response.ok) {
        resolve(response.data.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getEmailCampaigns = (_id, campaignId = '') =>
  new Promise((resolve, reject) => {
    api.get(`/email-templates/${_id}/campaigns/${campaignId}`).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const syncCampaignsReport = () =>
  new Promise((resolve, reject) => {
    api.get('/email-campaigns/sync-reports').then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const createOrUpdateEmailCampaign = (props) =>
  new Promise((resolve, reject) => {
    const { _id, campaignId, ...params } = props

    // If id, so we have to update the record, or we're gonna create
    const verb = campaignId ? 'put' : 'post'
    const url = campaignId ? `email-templates/${_id}/campaigns/${campaignId}` : `email-templates/${_id}/campaigns`

    api[verb](url, params).then((response) => {
      if (response.ok) {
        message.success(
          `Email campaign ${campaignId ? 'updated successfully' : 'created successfully'
          }`
        )
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const deleteEmailCampaigns = (_id, campaignId) =>
  new Promise((resolve, reject) => {
    api.delete(`/email-templates/${_id}/campaigns/${campaignId}`).then((response) => {
      if (response.ok) {
        message.success('Email campaign deleted successfully')
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })
