import React from 'react'
import ContactDetailDrawer from './ContactDetailDrawer/ContactDetailDrawer'
import ContactsTable from './ContactsTable/ContactsTable'
// import MainContactProvider from './context/MainContactContext'
// import FilterDrawer from './FilterDrawer/FilterDrawer'
import { ContactProvider } from 'pages/contacts/context/Contacts'
import AddToListDrawer from './AddToListDrawer/AddToListDrawer'
import AssignSalesRepDrawer from './AssignSalesRepDrawer/AssignSalesRepDrawer'

const NewContacts = () => {
  return (
    <div className='flex bg-white'>
      {/* <FilterDrawer /> */}
      <ContactsTable />
      <ContactProvider>
        <ContactDetailDrawer />
      </ContactProvider>
      <AddToListDrawer />
      <AssignSalesRepDrawer />
    </div>
  )
}

export default NewContacts
