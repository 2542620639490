/* eslint-disable */
// @ts-nocheck
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "../../../assets/css/ag-theme-balham.css";
import { classNames } from "utils";
import { Card, Modal } from "@material-ui/core";
import useGetProjectUnits from "hooks/useGetProjectUnits";
import { groupBy } from "..";
import { getFilteredDeals } from "store/actions/dealAction";
import {
  getUnitsByBuildingId,
  getUnitsByBuildingIdTableView,
} from "store/actions/unitActions";
import { useState } from "react";
import moment from "moment";
import { IoMdClose } from "react-icons/io";
import CustomSelect from "components/CustomSelect";
import CustomInput from "components/CustomInput";
import { getParkingInventory } from "store/actions/parkingActions";
import InventoryEditDrawer from "./InventoryEditDrawer";
import Skeleton from "react-loading-skeleton";
import { api } from "services/api";
import useGetInventories from "hooks/useGetInventories";
import { getInventorySettings, updateInventoryField } from "store/actions/inventoryActions";
import { useSelector } from "react-redux";
import Select from 'react-select';


const TEMP_AVAILABILITY_STATUS = {
  available: "available",
  open: "Open",
  sold: "sold",
  soldFirm: "SoldFirm",
  developerHeld: "DeveloperHeld",
  reserved: "reserved",
  reservedBroker: "ReservedBroker",
  reservedBuyer: "ReservedBuyer",
  closed: "Closed",
  underContract: "UnderContract",
  offerAccepted: "OfferAccepted",
};


const STATUS_OPTIONS = [
  ...Object.values(TEMP_AVAILABILITY_STATUS).map((value) => {
    return { value: value, label: value.toUpperCase() };
  }),
];

const DropdownCellEditor = forwardRef((props, ref) => {
  const [value, setValue] = useState(props.value);

  const tableRef = props.tableRef;

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value;
      },
      isCancelBeforeStart() {
        return false;
      },
      isCancelAfterEnd() {
        return false;
      },
    };
  });

  const handleChange = (event) => {
    setValue(event.value);
    if (props.onValueChange) {
      props.onValueChange(event.value, props.data);
    }
    setTimeout(() => {
      props.api.stopEditing();
    }, 0);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      props.api.stopEditing();
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [props.api]);


  return (
    <Select
      ref={ref}
      value={value}
      onChange={handleChange}
      options={STATUS_OPTIONS}
      className="w-full"
      isSearchable={false}
      menuPortalTarget={document.body}
      value={STATUS_OPTIONS.find((option) => option.value === value)}
      menuIsOpen={props.api.getEditingCells().length > 0}
      styles={{
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        menu: (provided) => ({
          ...provided,
          marginTop: 0,
          marginBottom: 0,
        }),
        menuList: (provided) => ({
          ...provided,
          paddingTop: 0,
          paddingBottom: 0,
        }),
        option: (provided) => ({
          ...provided,
          backgroundColor: "transparent",
          color: "inherit",
          cursor: "pointer",
          ":hover": {
            backgroundColor: "rgba(0, 0, 0, 0.1)",
          },
        }),
        control: (provided) => ({
          ...provided,
          minWidth: 120, // Adjust as needed
        }),
      }}
    ></Select>
  );
});

export default function TableViewInventory({
  selectedBuilding,
  projectId,
  showCards = true,
}) {
  const { inventories, refetch, loading } = useGetInventories(
    `building/${selectedBuilding}`
  );
  const appProject = useSelector((state) => state.appReducer.appProject);
  const [filteredUnits, setFilteredUnits] = useState([]);
  const [tempTypesOfAllocatedParking, setTempTypesOfAllocatedParking] =
    useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [typesOfAllocatedParking, setTypesOfAllocatedParking] = useState([]);
  const [parkingType, setParkingType] = useState([]);
  const [allocationModal, setAllocationModal] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [editDetails, setEditDetails] = useState({});
  const [tempRefetch, setTempRefetch] = useState(false);
  const [parkingTypeData, setParkingTypeData] = useState([{}]);
  const [parkingAllocationValues, setParkingAllocationValues] = useState({});
  const parkingColor = ["#59b099", "red", "yellow", "black"];
  const [totalAssignedParkingAllocate, setTotalAssignedParkingAllocate] =
    useState(null);
  const [inventoryFields, setInventoryFields] = useState([]);
  const [inventoryType, setInventoryType] = useState("");
  function formatHeaderName(key) {
    let formatted = key.charAt(0).toUpperCase() + key.slice(1);  
    return formatted;
  }
  const gridRef = useRef(null)
  useEffect(() => {
      getInventorySettings(appProject).then((data) => {
        let type = data[0].type;
        setInventoryType(type);
        let fields = Object.keys(data[0][type + "Data"]).reduce((acc, key) => {
          if (key === "additionalFields") {
            const additionalFieldsMapped = data[0][type + "Data"][key].map(
              (item) => {
                if (item.enabled === true) {
                  return {
                    flag: "additionalFields",
                    headerName: formatHeaderName(item.name),
                    field: type+'Data.additionalFields.'+item.id,
                  };
                }
              }
            );
            return [...acc, ...additionalFieldsMapped];
          } else {
            if (data[0][type + "Data"][key].enabled === true) {
              return [
                ...acc,
                {
                  headerName: formatHeaderName(key),
                  field: type + "Data." + key,
                  editable: true,
                  cellEditor: data[0][type + "Data"][key].type === 'dropdown' ? DropdownCellEditor : null,
                  cellEditorParams: data[0][type + "Data"][key].type === 'dropdown' ?
                  {
                    tableRef: gridRef,
                  } : null,
                  cellRenderer:  data[0][type + "Data"][key].type === 'dropdown' ? ({ value }) => {
                    const status = value;
                    const availableTags = [
                      ["available", "bg-blue-500 text-white"],
                      ["soldfirm", "bg-red-500 text-white"],
                      ["closed", "bg-red-500 text-white"],
                      ["sold", "bg-red-500 text-white"],
                      ["open", "bg-green-500 text-white"],
                      ["assigned", "bg-green-500 text-white"],
                      ["reserved", "bg-green-500 text-white"],
                      ["reservedBroker", "bg-green-500 text-white"],
                      ["reservedBuyer", "bg-green-500 text-white"],
                      ["under contract", "bg-orange-500 text-white"],
                      ["underContract", "bg-orange-500 text-white"],
                      ["offerAccepted", "bg-orange-500 text-white"],
                      ["developerheld", "bg-orange-500 text-white"],                     
                    ];
                    return (
                      <div>
                        {availableTags?.map(([tag, color], index) => {
                          if (status?.toLowerCase() === tag?.toLowerCase()) {
                            return (
                              <div key={index} className={`capitalize text-gray-800 border border-gray-300 px-2 py-1 rounded-full text-xs ${color}`}>
                                {tag}
                              </div>
                            );
                          }
                        })}
                      </div>
                    );
                  } : null
                },
              ];
            } else {
              return acc;
            }
          }
        }, []);
        let filter = fields.filter((item) => item !== undefined);
        setInventoryFields(filter);
      });
    }, [appProject]);

    
  const parkingTypeArray = () => {
    const parkingStatus = [
      { name: "Available", value: value.totalParkings || 0 },
      { name: "Sold", value: 0 },
      { name: "Allocated", value: 0 },
      { name: "Assigend", value: 0 },
    ];
    const parkingData = parkingStatus.map((parking, index) => {
      const color = parkingColor[index % parkingColor.length];
      return { name: parking.name, value: parking.value, color: color };
    });
    setParkingTypeData(parkingData);
  };
  //   const handleFilteredDeals = async () => {
  //     let data = await getFilteredDeals({});
  //     console.log(data);
  //   };
  const initialEmptyValues = {
    building: selectedBuilding,
    options: [],
    parkingLevels: ["P1", "P2"],
    totalParkings: null,
  };
  const [value, setValues] = useState({
    ...initialEmptyValues,
  });

  const handleTotalParkingsAllocate = () => {
    const totalParking = parkingAllocationValues?.options?.reduce(
      (sum, obj) => {
        const objSum = obj.parkingTypes.reduce((objSum, parkingType) => {
          const typeSum = parkingType.options.reduce(
            (typeSum, option) => typeSum + Number(option.value),
            0
          );
          return objSum + typeSum;
        }, 0);
        return sum + objSum;
      },
      0
    );
    setTotalAssignedParkingAllocate(totalParking);
  };

  const parkingAllocationStatus = [
    {
      name: "Allocated",
      value: totalAssignedParkingAllocate,
    },
    {
      name: "Unallocated",
      value:
        parkingAllocationValues.allocatedParkings -
        totalAssignedParkingAllocate,
    },
  ];

  useEffect(() => {
    const handleParkingInventory = async () => {
      const { data } = await api.get(
        `/parking/${selectedBuilding}/allocateInventory`
      );
      const parkingLevelsAllocate = data.data[0].parkingLevels;
      const parkingLevels = value.parkingLevels;
      const fetchedOptions = data.data[0].options;

      setParkingAllocationValues({
        ...data.data[0],
      });
    };
    const handleBuildingInventory = async () => {
      try {
        const { data } = await api.get(
          `/parking/${selectedBuilding}/inventory`
        );
        if (data.data && data.data.length > 0) {
          setValues(data.data[0]);
        } else {
          setValues({ ...initialEmptyValues });
        }
      } catch (error) {
        console.error("Error fetching building inventory:", error);
      }
    };
    handleBuildingInventory();
    handleParkingInventory();
    parkingTypeArray();
  }, []);

  useEffect(() => {
    handleTotalParkingsAllocate();
  }, [parkingAllocationValues]);

  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      filter: true,
      resizable: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      editable: true
    };
  }, []);

  const _getParkingType = async (parking) => {
    let parkingInventory = await getParkingInventory(selectedBuilding);
    parkingInventory = parkingInventory.map((parking) => ({
      parkingType: parking.options.map((op) => op.name),
    }));
    if (parkingInventory.length) {
      setParkingType(
        parkingInventory[0].parkingType.map((pt) => ({
          value: pt,
          id: pt,
        }))
      );
    }
  };

  useEffect(() => {
    _getParkingType();
  }, [selectedBuilding]);

  const handleAddParking = () => {
    const newId = tempTypesOfAllocatedParking.length + 1;
    const newBgColor = colors[newId % colors.length]; // Rotate through colors
    const newAllocation = {
      id: newId,
      count: 1,
      title: "",
      bgColor: newBgColor,
    };

    setTempTypesOfAllocatedParking((prev) => [...prev, newAllocation]);
  };

  const openAllocationModal = () => {
    setAllocationModal(true);
  };
  const colors = ["#FFBE9A", "#16A34A40", "#FFD700", "#FF0000"];
  useEffect(() => {
    setIsLoading(true);
    const units = getUnitsByBuildingIdTableView(selectedBuilding).then(
      (data) => {
        let mapped = data.map((unit) => {
          return {
            ...unit,
            unitNumber: unit.unitNumber,
            buyerName: unit.buyerName,
            soldAt: unit.avesdoData?.acceptedDate,
            floorNumber: unit.floorNumber,
            unitType: unit.unitType,
            status: unit.status,
            area: unit.area,
            isOnWaitlist: unit?.avesdoData,
            acceptedDate: unit?.avesdoData?.acceptedDate,
            totalParkingStalls:
              (unit?.numberParkingIncluded || 0) +
              (unit?.numberParkingOptional || 0),
          };
        });
        setFilteredUnits(mapped);
        setIsLoading(false);
      }
    );
  }, [selectedBuilding, tempRefetch]);

  const getRowId = useCallback((params) => String(params.data._id), []);

  return (
    <div className="flex w-full h-full">
      {isLoading ? (
        <div className="h-full w-full flex flex-col gap-y-4">
          <Skeleton height={130} />
          <Skeleton height={130} />
          <Skeleton height={130} />
          <Skeleton height={130} />
          <Skeleton height={130} />
        </div>
      ) : (
        <div className="w-full flex flex-col gap-y-5">
          <div
            className={classNames(
              "h-full ag-theme-alpine w-full transition-all relative",
              isDrawerOpen ? "mr-[350px]" : "mr-0"
            )}
          >
            <AgGridReact
              domLayout="autoHeight"
              defaultColDef={defaultColDef}
              rowHeight={40}
              ref={gridRef}
              columnDefs={inventoryFields}
              rowData={inventories}
              getRowId={getRowId}
              onCellValueChanged={(e) => {
                if (e.column.colId !== 'lotdata.status') {
                  let newValue = e.newValue
                  let colId = e.column.colId
                  e.api.applyTransaction({
                    update: [{
                      ...e.data,
                      [colId]: newValue
                    }],
                  });

                  e.api.refreshCells({
                    force: true,
                  });

                  updateInventoryField(e.data._id, {
                    [colId]: newValue,
                    type: inventoryType
                  })
                  return
                }
              }}
            />
          </div>
        </div>
      )}
      <div className="absolute right-0 top-0 bottom-0 z-[999]">
        <InventoryEditDrawer
          editDetails={editDetails}
          setEditDetails={setEditDetails}
          closeInfoDrawer={() => setIsDrawerOpen(false)}
          isInfoDrawerOpen={isDrawerOpen}
          isAbleToPurchase
          refetch={() => {
            setTempRefetch(!tempRefetch);
          }}
          type={"Unit"}
          selectedBuilding={selectedBuilding}
          isFullHeight={false}
        />
      </div>
    </div>
  );
}
