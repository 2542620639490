// @ts-nocheck
/* eslint-disable */
import CustomModal from 'components/Modal';
import React, { useState, useEffect, useCallback, useContext } from 'react';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import { api } from 'services/api';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';

import { ContactContext } from 'pages/contacts/context/Contacts';
import CustomButton from 'components/CustomButton';
export default function DownloadContactModal({
  show,
  handleClose,
  setWaitingModal,
}) {
  const [checkBoxOption, setCheckBoxOption] = useState('project_contacts');
  const projectId = useSelector((state) => state.appReducer.appProject);
  const [isLoading, setIsLoading] = useState(false);
  let id = localStorage.getItem('device_id');
  const [contactsCount, setContactsCount] = useState({
    projectContacts: 0,
    last7DaysContacts: 0,
    allContacts: 0,
  });

  useEffect(() => {
    const getContactsCount = async (projectId) => {
      const response = await api.get(
        `/reports/contacts/count?projectId=${projectId}`
      );
      if (response.ok) {
        setContactsCount(response.data);
      }
    };
    if (projectId) {
      getContactsCount(projectId);
    }
  }, [projectId]);

  const handleDownload = async () => {
    handleClose();
    setWaitingModal(true);
    setTimeout(() => {
      setWaitingModal(false);
    }, 10000);
    try {
      setIsLoading(true);
      let query;
      if (
        checkBoxOption === 'project_contacts' ||
        checkBoxOption === 'last_7_days'
      ) {
        query = `?report_type=${checkBoxOption}&projectId=${projectId}&deviceId=${id}`;
      } else {
        query = `?report_type=${checkBoxOption}&deviceId=${id}`;
      }
      const response = await api.get(
        `/reports/contacts${query}`,
        {},
        { responseType: 'blob' }
      );
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <CustomModal isOpen={show} closeModal={handleClose}>
      <div className='font-openSans min-w-[500px] relative bg-grey-8 p-10 rounded shadow-lg w-full'>
        <h2 className='text-2xl font-semibold text-center text-softBlack'>
          Download Contacts
        </h2>
        <button className='absolute right-6 top-4' onClick={handleClose}>
          <CrossIcon />
        </button>
        <div className='flex flex-col gap-y-3 mt-8'>
          <div className='flex flex-col gap-4'>
            <div className='flex items-center gap-4'>
              <input
                onChange={(e) => {
                  setCheckBoxOption(
                    e.target.checked ? 'project_contacts' : null
                  );
                  //   setCheckAddressStatus('priamry');
                }}
                checked={checkBoxOption === 'project_contacts'}
                type='checkbox'
                className=''
              />
              <div className='text-[18px]'>
                Project Contacts ({contactsCount.projectContacts})
              </div>
            </div>
          </div>
          <div className='flex flex-col gap-4'>
            <div className='flex items-center gap-4'>
              <input
                onChange={(e) => {
                  setCheckBoxOption(e.target.checked ? 'last_7_days' : null);
                }}
                checked={checkBoxOption === 'last_7_days'}
                type='checkbox'
                className=''
              />
              <div className='text-[18px]'>
                Last 7 Days Contacts ({contactsCount.last7DaysContacts})
              </div>
            </div>
          </div>
          <div className='w-full h-[1px] bg-[rgb(0,0,0,0.5)]'></div>
          <div className='flex flex-col gap-4'>
            <div className='flex items-center gap-4'>
              <input
                onChange={(e) => {
                  setCheckBoxOption(e.target.checked ? 'all_contacts' : null);
                }}
                checked={checkBoxOption === 'all_contacts'}
                type='checkbox'
                className=''
              />
              <div className='text-[18px]'>
                All Contacts ({contactsCount.allContacts})
              </div>
            </div>
          </div>
        </div>
        <div className='flex items-center justify-center mt-8'>
          <CustomButton handleClick={handleDownload} className='md:w-[150px] h-[30px] md:h-[40px]'>
            <span className='font-medium px-3 py-2 text-base flex gap-2 items-center'>
              {isLoading ? <CircularProgress size={20} /> : 'Download'}
            </span>
          </CustomButton>
        </div>
      </div>
    </CustomModal>
  );
}
