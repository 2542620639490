import React, { useCallback, useEffect, useState } from 'react'
import clsx from 'clsx'
import { formatMoney } from 'utils/formatters'
import { AVAILABILITY_BG_COLOR } from 'utils'
import { getUnitEventEmitter } from 'hooks/useRealtimeUnitUpdate'
import Attribute from './Attribute'
import imgSrc from 'assets/img/sample-storage.jpeg'

const StorageCardView = ({
  _id: id,
  name: unitName,
  attributes,
  onViewDetails,
  totalValue,
  isActive,
  tag: tagData,
  onUpdate
}) => {
  const [tag, setTag] = useState(tagData)

  useEffect(() => {
    setTag(tagData)
  }, [tagData])

  const onUnitRealtimeUpdate = useCallback(
    (newData) => {
      if (id === newData.id || id === newData._id) {
        onUpdate?.(newData)
        setTag(newData.status)
      }
    },
    [tag, id]
  )

  useEffect(() => {
    getUnitEventEmitter()?.addListener('unit-update', onUnitRealtimeUpdate)
    return () => {
      getUnitEventEmitter()?.removeListener('unit-update', onUnitRealtimeUpdate)
    }
  }, [])

  return (
    <div
      className={clsx(' ', {
        'border-2 border-softBlack': isActive
      })}
    >
      <div className='h-60 bg-white'>
        <img className='w-full h-full object-fill' src={imgSrc} alt='inventory' />
      </div>
      <div className='border-2 border-softBlack my-4 w-full' />
      {tag && (
        <div
          className={
            'rounded uppercase text-xs font-medium text-softBlack h-6.75 p-1 flex justify-center items-center min-w-20 w-fit px-2 mb-1'
          }
          style={{
            backgroundColor: AVAILABILITY_BG_COLOR[tag.toLowerCase()]
          }}
        >
          {tag}
        </div>
      )}
      <p className='font-bold text-2xl my-0'>Storage #{unitName}</p>
      <p className='text-sm font-medium mt-1'>
        {formatMoney(totalValue)}
      </p>
      {attributes?.length > 0 && (
        <div className='mt-4 flex items-center gap-4'>
          {attributes?.map((props, index) => (
            <Attribute {...props} key={index} />
          ))}
        </div>
      )}
      {onViewDetails && (
        <button onClick={onViewDetails} className='text-base font-medium mt-4'>
          View Details
        </button>
      )}
    </div>
  )
}

export default StorageCardView
