import { message } from 'antd'
import { api, ApiErrors } from 'services/api'

export const getCommunicationTemplate = (projectId = '') =>
  new Promise((resolve, reject) => {
    api.get('/communication-templates').then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getCommunicationTemplateById = (id = '') =>
  new Promise((resolve, reject) => {
    api.get(`/communication-templates/${id}`).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const deleteCommunicationTemplate = (id) =>
  new Promise((resolve, reject) => {
    api.delete(`/communication-templates/${id}`).then((response) => {
      if (response.ok) {
        message.success('Communication Template deleted successfully')
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const createOrUpdateCommunicationTemplate = (props) =>
  new Promise((resolve, reject) => {
    const { _id: id, ...params } = props

    // If id, so we have to update the record, or we're gonna create
    const verb = id ? 'put' : 'post'
    const url = id
      ? `communication-templates/${id}`
      : 'communication-templates'

    api[verb](url, params).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getCommunicationTemplateByCategory = (category = '') =>
  new Promise((resolve, reject) => {
    api
      .get(`/communication-templates/category/${category}`)
      .then((response) => {
        if (response.ok) {
          resolve(response.data)
        } else {
          ApiErrors(response)
          reject()
        }
      })
  })

export const getCommunicationTemplateByType = (type = '') =>
  new Promise((resolve, reject) => {
    api.get(`/communication-templates/type/${type}`).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })
