import React, { useState, useCallback } from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import Skeleton from 'react-loading-skeleton'
import {
  Wrapper, ImgWrapper, Thumbnail,
  DetailsWrapper, RowDetail, OfferTitle, UnitStatusWrapper,
  CheckButtonWrapper
} from './styles'
import { OfferPrice, BuildingFeatures } from 'components'
import { Modal, TButton } from 'ui'
import { getUnitStatus, listOfStatus, pluralWords } from 'utils'

const ContentWrapper = ({ children, className, onClick }) => (
  <div onClick={onClick && onClick} className={`flex flex-col ${className}`}>
    {children}
  </div>
)

const isHidden = (array) => {
  return array.filter((element) => element === 'HIDDEN').length
}

const getReservationQueueStatus = (reservationQueueItem) => {
  const { brokers, buyers, type } = reservationQueueItem

  const elementIsHidden = isHidden(brokers) || isHidden(buyers)

  const objectReturn = {
    description: elementIsHidden ? listOfStatus[type].value : `Your ${type === 'ReservedBuyer' ? "buyer's" : 'personal'} reservation`,
    hidden: elementIsHidden
  }
  return objectReturn
}

const ReservationQueue = ({ data, index, renderingFromModal }) => {
  const status = getReservationQueueStatus(data)
  const { type: reservationType, brokers, buyers, days } = data

  const reservationInterested = reservationType === 'ReservedBroker' ? brokers : buyers

  return (
    <div className={`flex flex-col text-base ${renderingFromModal && 'mb-5'} ${!status.hidden && ' text-lg text-green-600 font-semibold'}`}>
      <div className='flex flex-row'>
        <span>{++index}-</span>
        <span className='ml-1'>{status.description}</span>
      </div>

      {
        !status.hidden && renderingFromModal && reservationType === 'ReservedBuyer' && (
          <div className={`flex flex-col ml-8 text-base ${renderingFromModal && 'mb-3'}`}>
            {reservationInterested.map((interested) => {
              const { firstName, lastName, email } = interested

              return <span>{`${firstName} ${lastName} - ${email}`}</span>
            })}
          </div>
        )
      }

      <span className={`text-sm font-normal ${!renderingFromModal && 'hidden'}`}>{`will last ${days} days`}</span>
    </div>
  )
}

const UnitSummarizedData = (props) => {
  const {
    unit: {
      unitNumber,
      price,
      priceCurrencyType,
      numberOfBedrooms,
      numberOfBathrooms,
      floorPlan,
      status,
      reservationQueue
    },
    loading,
    mainButtonClick,
    mainButtonText,
    mainButtonSubText,
    wrapperClassname,
    hasPermission,
    userObject
  } = props

  const [showReservartionQueueModal, setShowReservartionQueueModal] = useState(false)

  let numberOfParking = 0
  let floorPlanImage = ''

  if (floorPlan) {
    numberOfParking = floorPlan.parkingQty
    floorPlanImage = floorPlan.image
  }

  const renderUnitStatus = useCallback((unit) => {
    const { _id } = userObject

    return (
      <UnitStatusWrapper className={'rounded-md py-1 px-4'}>
        <span
          className={`text-${getUnitStatus(unit, hasPermission, _id).primary} text-lg font-bold`}
        >
          Unit Status: {getUnitStatus(unit, hasPermission, _id).value}
        </span>
      </UnitStatusWrapper>
    )
  }, [userObject, hasPermission])

  const renderWaitList = useCallback((renderingFromModal) => (
      <div className='flex flex-col h-full overflow-y-auto items-center justify-center'>
        <div className={`flex flex-row items-center ${renderingFromModal && 'justify-center'}`}>
          <span className={`${renderingFromModal ? 'text-2xl' : 'text-xl'} font-bold`}>Waitlist</span>
          {!renderingFromModal && <span className='ml-1 underline font-bold'>- Click to detailed view</span>}
        </div>
        {renderingFromModal &&
          <span className='w-full text-center'>
            {`${reservationQueue && reservationQueue.length} ${pluralWords(reservationQueue && reservationQueue.length, 'round')} of waiting`}
          </span>
        }

        <div className='h-full overflow-y-auto mt-5'>
          {reservationQueue && reservationQueue.map((element, index) =>
            <ReservationQueue
              key={index}
              index={index}
              data={element}
              renderingFromModal={renderingFromModal}
            />
          )}
        </div>
      </div>
  ), [reservationQueue])

  const renderReservationQueueModal = useCallback(() => (
    <Modal
      modalType='custom'
      showModal={showReservartionQueueModal}
      onCancel={() => setShowReservartionQueueModal(false)}
    >
      <div className='h-96 w-96'>
        { renderWaitList(true) }
      </div>
    </Modal>
  ), [showReservartionQueueModal, renderWaitList])

  return (
    <Wrapper className={`${wrapperClassname} flex flex-col rounded shadow-xl px-3 h-full w-full`}>
      {renderReservationQueueModal()}
      {loading
        ? (
          <Skeleton height={350} width={420} />
          )
        : (
          <>
            {
              floorPlan && (
                <ImgWrapper className='flex justify-center h-16/20'>
                  <Thumbnail
                    src={floorPlanImage}
                    className='object-contain px-20'
                  />
                </ImgWrapper>
              )
            }

            <DetailsWrapper className='flex flex-col justify-end mt-2 xl:mt-0'>
              <RowDetail className='flex flex-row h-full'>
                <ContentWrapper>
                  <OfferTitle className='text-lg'>{unitNumber}</OfferTitle>
                  <OfferPrice
                    style={{ marginTop: -5 }}
                    priceCurrencyType={priceCurrencyType}
                  >
                    {price}
                  </OfferPrice>
                  <BuildingFeatures
                    numberOfBathrooms={numberOfBathrooms}
                    numberOfBedrooms={numberOfBedrooms}
                    numberOfParking={numberOfParking || 0}
                  />
                </ContentWrapper>
                <ContentWrapper className='text-center pl-28 max-w-xs items-center justify-center'>
                  {
                    status ? renderUnitStatus(props.unit) : null
                  }
                </ContentWrapper>
                <ContentWrapper
                  className='hover:cursor-pointer flex-1'
                  onClick={() => setShowReservartionQueueModal(true)}
                >
                  {reservationQueue && Boolean(reservationQueue.length) && renderWaitList(false)}
                </ContentWrapper>
              </RowDetail>

              {
                mainButtonClick && (
                  <CheckButtonWrapper justify='center'>
                    <TButton
                      className='mt-3 w-full h-10 flex flex-col'
                      onClick={mainButtonClick}
                    >
                      <span className='text-lg lg:text-base xl:text-lg'>{mainButtonText}</span>
                      <span className='text-sm lg:text-xs xl:text-sm -mt-1.5'>{mainButtonSubText}</span>
                    </TButton>
                  </CheckButtonWrapper>)
              }
            </DetailsWrapper>
          </>
          )
      }
    </Wrapper>
  )
}

const mapStateToProps = (state) => ({
  userObject: state.authReducer.userObject,
  developerColor: state.appReducer.developerColor,
  developerHoverColor: state.appReducer.developerHoverColor
})

export default connect(mapStateToProps, {})(UnitSummarizedData)

UnitSummarizedData.defaultProps = {
  mainButtonText: 'Sign the docs',
  mainButtonSubText: '',
  wrapperClassname: 'h-full',
  hasPermission: true,
  reservationQueue: []
}

UnitSummarizedData.propTypes = {
  unit: propTypes.shape({
    unitNumber: propTypes.string,
    price: propTypes.string,
    priceCurrencyType: propTypes.string,
    numberOfBathrooms: propTypes.string,
    numberOfBedrooms: propTypes.string,
    numberOfParking: propTypes.string,
    floorPlan: propTypes.string,
    status: propTypes.string,
    reservationQueue: propTypes.array
  }),
  loading: propTypes.bool,
  mainButtonClick: propTypes.func,
  mainButtonText: propTypes.string,
  mainButtonSubText: propTypes.string,
  wrapperClassname: propTypes.string,
  hasPermission: propTypes.string,
  userObject: propTypes.shape({
    _id: propTypes.string
  })
}

ContentWrapper.defaultProps = {
  className: ''
}

ContentWrapper.propTypes = {
  children: propTypes.node,
  className: propTypes.string,
  onClick: propTypes.func
}

ReservationQueue.propTypes = {
  data: propTypes.shape({
    type: propTypes.string,
    brokers: propTypes.array,
    buyers: propTypes.array,
    days: propTypes.number
  }),
  index: propTypes.string,
  renderingFromModal: propTypes.bool
}
