// @ts-nocheck
import React, { useContext } from 'react'

import CustomInput from 'components/CustomInput'
import CustomTextArea from 'components/CustomTextArea'

import { FadedAnimatedDiv } from 'components'

import { CreateOfferContext } from '../context/CreateOfferContext'

const ThirdPartyRecord = ({
  contextUsed = false,
  thirdPartRecordStateObject = {}
}) => {
  const { thirdPartRecord, setThirdPartRecord } = contextUsed
    ? thirdPartRecordStateObject
    // eslint-disable-next-line operator-linebreak
    :
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useContext(CreateOfferContext)

  const onChange = (fieldName, value) => {
    setThirdPartRecord({ ...thirdPartRecord, [fieldName]: value })
  }

  return (
    <FadedAnimatedDiv className='mt-10'>
      <h1 className='font-bold text-lg mb-8'>Third Party Record</h1>
      <div>
        <div className='grid grid-cols-2 gap-6 pb-6'>
          <CustomInput
            label='Name of other entity *'
            placeholder='Enter name'
            onChange={(e) => onChange('name', e.target.value)}
          />
          <CustomInput
            label='Address *'
            placeholder='Enter address'
            onChange={(e) => onChange('address', e.target.value)}
          />
        </div>
        <div className='grid grid-cols-2 gap-6 pb-6'>
          <CustomInput
            type='phone'
            label='phone number *'
            placeholder='Enter phone number'
            onChange={(e) => onChange('phoneNumber', e.target.value)}
          />
          <CustomInput
            label='date of birth *'
            placeholder='DD/MM/YYYY'
            type='date'
            onChange={(e) => onChange('birthday', e.target.value)}
            classes='uppercase'
          />
        </div>
        <CustomTextArea
          label='Nature of Principal Business or Occupation *'
          containerClasses='mb-6'
          value={thirdPartRecord.occupation}
          onChange={(e) => onChange('occupation', e.target.value)}
        />
        <div className='grid grid-cols-3 gap-6 pb-6'>
          <CustomInput
            label='Registration or Incorporation Number'
            placeholder='Enter number'
            onChange={(e) => onChange('incorporationNumber', e.target.value)}
          />
          <CustomInput
            label='Jurisdiction that issued that number'
            placeholder='Enter name'
            onChange={(e) =>
              onChange('incorporationJurisdiction', e.target.value)
            }
          />
          <CustomInput
            label='Country that issued that number'
            placeholder='Enter country'
            onChange={(e) => onChange('incorporationCountry', e.target.value)}
          />
        </div>
        <CustomTextArea
          label='Relationship between Third Party and Client *'
          value={thirdPartRecord.relationship}
          onChange={(e) => onChange('relationship', e.target.value)}
        />
      </div>
    </FadedAnimatedDiv>
  )
}

export default ThirdPartyRecord
