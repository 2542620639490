/* eslint-disable */
// @ts-nocheck
import React, { useContext } from "react";
import { Button } from "@material-ui/core";
import CustomModal from "components/Modal";
import {
  deleteCorporateDemandNote,
  deleteDemandNote,
} from "store/actions/demandNotes";
import CustomButton from "components/CustomButton";

const ConfirmDelete = ({
  deleteModal,
  setDeleteModal,
  deleteNoteId,
  refetch,
  setInitialValues,
}) => {
  return (
    <CustomModal isOpen={deleteModal} closeModal={() => setDeleteModal(false)}>
      <div className="flex flex-col justify-center items-center p-5 gap-5">
        <div className="text-lg font-bold">
          Are you sure you want to delete?
        </div>
        <div className="flex flex-row gap-4 justify-center items-center">
          <CustomButton
            handleClick={() => {
              setDeleteModal(false);
            }}
            variant="outline"
          >
            <span className="font-medium py-2 px-4 text-base flex gap-2 items-center">
              No
            </span>
          </CustomButton>
          <CustomButton
            handleClick={async () => {
              await deleteCorporateDemandNote(deleteNoteId);
              setDeleteModal(false);
              setInitialValues({
                label: "",
                options: [],
                isMulti: false,
              });
              refetch();
            }}
          >
            <span className="font-medium py-2 px-4 text-base flex gap-2 items-center">
              Delete
            </span>
          </CustomButton>
        </div>
      </div>
    </CustomModal>
  );
};

export default ConfirmDelete;
