// @ts-nocheck
/* eslint-disable no-unused-vars */
import TableBody from 'components/TailwindTable/components/TableBody'
import TableHead from 'components/TailwindTable/components/TableHead'
import React, { useContext, useState } from 'react'
import { ContactContext } from '../context/Contacts'
import AddOrEditSingleContact from 'components/AddOrEditContact'

const BrokerClientsTable = ({ clients = [] }) => {
  const [page] = useState(1)
  const [open, setOpen] = useState(false)
  const { setSelectedContact, selectedContact, getUsersData } = useContext(ContactContext)
  return (
    <div className='mt-8 flex flex-col'>
    <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
      <div
        style={{ paddingLeft: '2.7rem', paddingRight: '1.9rem' }}
        className='inline-block min-w-full py-2 align-middle'
      >
        <AddOrEditSingleContact
          show={open}
          setShow={setOpen}
          contact={null}
          onSuccess={() => getUsersData()}
          broker={selectedContact}
          userType={'Buyer'}
        />
        <button
          onClick={() => setOpen(true)}
          type='button'
          style={{ fontStyle: 'italic', padding: '10px' }}
          className='mb-8 inline-flex items-center justify-left rounded-md border border-transparent bg-volt px-4 py-2 text-sm font-medium text-black shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:w-auto mt-1'
        >
          <span style={{ marginLeft: '10px' }}></span>
          <span
            style={{
              marginLeft: '10px',
              fontSize: '16px',
              marginRight: '10px'
            }}
          >
            + Create New Buyer Contact
          </span>
        </button>
        <div className='relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
          <table className='min-w-full table-fixed divide-y divide-gray-300'>
            <TableHead page={page} />
            <TableBody
              page={page}
              customContacts={clients}
            />
          </table>
        </div>
      </div>
    </div>
  </div>
  )
}

export default BrokerClientsTable
