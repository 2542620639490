/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import TableHeader from 'components/TableHeader'
import { ContactContext } from 'pages/contacts/context/Contacts'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { CreateOpportunityContext } from '../context/CreateOpportunityContext'
import { STEP } from '../steps'
import BuyerCard from './BuyerCard/BuyerCard'
import Header from './Header/Header'
import CustomButton from 'components/CustomButton'

const SelectBuyer = () => {
  const { selectedBuyers, setSelectedBuyers, setStep } = useContext(CreateOpportunityContext)
  const { contacts } = useContext(ContactContext)
  const [selectedHeader, setSelectedHeader] = useState('')
  const [limit] = useState(10)
  const [skip, setSkip] = useState(0)
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState([])
  const [search, setSearch] = useState('')

  const getTableHeaders = useCallback(() => {
    const headers = ['Buyers', 'Corporations']
    return headers
  }, [])

  const onNextClick = () => setStep(STEP.TWO)

  useEffect(() => {
    if (!contacts) return
    setPages([...Array.from({ length: Math.ceil(contacts?.filter?.(ct => search ? ct?.userType === 'Buyer' && nameMatches(ct) : ct?.userType === 'Buyer').length / limit) }, (value, index) => index + 1)])
  }, [setPages, contacts, search])

  const nameMatches = (contact) => (
    contact?.firstName?.toLowerCase?.()?.indexOf?.(search.toLowerCase()) !== -1 ||
    contact?.lastName?.toLowerCase?.()?.indexOf?.(search.toLowerCase()) !== -1 ||
    (`${contact?.firstName?.toLowerCase?.()}${contact?.lastName?.toLowerCase?.()}`)?.indexOf?.(search.toLowerCase()) !== -1 ||
    (`${contact?.firstName?.toLowerCase?.()} ${contact?.lastName?.toLowerCase?.()}`)?.indexOf?.(search.toLowerCase()) !== -1
  )

  return (
    <div>
      <Header />
      <div style={{ height: '85vh' }} className='w-full p-4'>
        <div style={{ height: 'max' }} className='w-full h-full p-6 bg-white flex flex-col gap-10'>
          <div className='w-full border-b-2 border-black p-3 text-lg pl-0 font-bold flex flex-row items-center justify-between'>
            Select Buyer
            <div className='flex justify-center items-center'>
              <CustomButton handleClick={onNextClick} disabled={!selectedBuyers?._id}>
                <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
                  Next Step
                </span>
              </CustomButton>
            </div>
          </div>
          <div className='w-full relative'>
            <input value={search} onChange={e => setSearch(e.currentTarget.value)} style={{ width: '50%' }} placeholder="Search Buyers" className='border-black rounded active:border-black outline-none p-2' />
          </div>
          <TableHeader
            headers={getTableHeaders()}
            handleSelectHeader={(header) => setSelectedHeader(header)}
            selectedHeader={selectedHeader}
          />
          <div className='flex flex-row gap-4 flex-wrap items-stretch overflow-y-auto'>
            {contacts?.filter?.(ct => search ? ct?.userType === 'Buyer' && nameMatches(ct) : ct?.userType === 'Buyer').slice(skip, limit * page).map((buyer, index) => <BuyerCard selectedBuyerId={selectedBuyers?._id} onClick={(contact) => setSelectedBuyers(contact)} buyer={buyer} />)}
          </div>
          <div className='w-full flex flex-row justify-center items-center'>
            <div className='w-max-content flex flex-row gap-1 cursor-pointer flex-wrap justify-center items-center'>
              {[pages.map((pg, index) => pg !== 0 && <div onClick={() => { setSkip(limit * index); setPage(pg) }} style={pg === page ? { color: 'black', border: '1px solid black' } : { color: 'gray' }} className='border-2 border-gray-1 rounded p-2'>{pg}</div>)]}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SelectBuyer
