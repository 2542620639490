import React, { useEffect, useState, useCallback } from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { TSwitch } from 'ui'
import { updateDarkModeClasses } from 'utils'
import { objStore } from 'index'
import { CHANGE_USER_OBJECT } from 'store/actions/actionTypes'
import useUpdateUsers from 'hooks/useUpdateUsers'

const DarkModeSideMenuSelector = (props) => {
  const [darkModeSelected, setDarkModeSelected] = useState(false)
  const { createOrUpdateUser } = useUpdateUsers()
  const { userObject: { _id: id, darkMode }, userObject } = props

  useEffect(() => {
    setDarkModeSelected(darkMode)
  }, [darkMode])

  const updateClasses = (darkModeSelected) => {
    updateDarkModeClasses(darkModeSelected)
  }

  const updateUser = useCallback((darkModeSelected) => {
    createOrUpdateUser({ id, darkMode: darkModeSelected }, false)
  }, [id])

  const updateUserObjectReducer = useCallback((darkModeSelected) => {
    const newUserObject = Object.assign({}, userObject, { darkMode: darkModeSelected })

    objStore.store.dispatch({
      type: CHANGE_USER_OBJECT,
      payload: newUserObject
    })
  }, [userObject])

  const changeDarkMode = useCallback(() => {
    const selected = !darkModeSelected

    setDarkModeSelected(selected)
    updateClasses(selected)
    updateUser(selected)
    updateUserObjectReducer(selected)
  }, [darkModeSelected, updateUser, updateUserObjectReducer])

  return (
    <div className='bg-white md:bg-gray-100 flex md:py-2 justify-end px-3'>
      <TSwitch
        title='Dark Mode'
        checked={darkModeSelected}
        onClick={() => changeDarkMode()}
        titleColor='text-gray-900'
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  userObject: state.authReducer.userObject
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(DarkModeSideMenuSelector)

DarkModeSideMenuSelector.propTypes = {
  userObject: propTypes.object
}
