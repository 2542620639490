// @ts-nocheck
/* eslint-disable */
import React, { useContext } from 'react';
import ContactDetailDrawer from './ContactDetailDrawer/ContactDetailDrawer';
// import MainContactProvider from './context/MainContactContext'
// import FilterDrawer from './FilterDrawer/FilterDrawer'
import { ContactContext, ContactProvider } from 'pages/contacts/context/Contacts';
import AddToListDrawer from './AddToListDrawer/AddToListDrawer';
import ContactsTableV2 from './ContactsTable/ContactsTableV2';
import ContactDetailDrawerV2 from './ContactDetailDrawer/ContactDetailDrawerV2';
import useContactContext from './hooks/useContactContext';
import AddToProjectDrawer from './AddToProjectDrawer/AddToProjectDrawer';
import AssignSalesRepDrawer from './AssignSalesRepDrawer/AssignSalesRepDrawer';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const NewContactsV2 = () => {
  const {
    view,
    closeContactDetailDrawer,
    state
  } = useContactContext();
  const history = useHistory();

  const {
    setSelectedContact,
  } = useContext(ContactContext);

  const handleContainerClick = (event) => {
    if (!state.isContactDetailDrawerOpen) return;
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.delete('_id');
      const newSearch = searchParams.toString();
      const newPath = history.location.pathname 
      closeContactDetailDrawer(newPath);
      setSelectedContact(null);
  };

  return (
    <div
      onClick={handleContainerClick}
      className='flex bg-white h-full'
    >
      {/* <FilterDrawer /> */}
      <ContactsTableV2 />
      <ContactDetailDrawerV2 resetPath={
        view === 'corporate' ? '/admin/corporate/contacts' : '/admin/contacts'
      } />
      <AddToListDrawer />
      <AssignSalesRepDrawer />
      <AddToProjectDrawer />
    </div>
  );
};

export default NewContactsV2;
