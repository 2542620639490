// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react'

import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg'
import { Oval } from 'react-loader-spinner'

import { Modal } from '@material-ui/core'
import CustomInput from 'components/CustomInput'
import CustomSelect from 'components/CustomSelect'
import { createOrUpdateUnit } from 'store/actions/unitActions'
import useGetFloorPlans from 'hooks/useGetFloorPlans'
import useGetUnitGroups from 'hooks/useGetUnitGroups'
import { useSelector } from 'react-redux'
import { isObject } from 'lodash'
import useCurrencyList from 'hooks/useCurrencyList'
import ProjectFileSelector from 'components/ProjectFileSelector'
import CustomButton from 'components/CustomButton'

const AddUnitModal = ({
  show,
  setShow,
  editDetails,
  setEditDetails,
  refetch,
  building
}) => {
  // @ts-ignore
  const appProject = useSelector((state) => state.appReducer.appProject)
  const mountedRef = useRef(true)
  const { floorPlans } = useGetFloorPlans()
  const { unitGroups } = useGetUnitGroups()
  const { formattedCurrencyList: currencyData } = useCurrencyList()
  const initialEmptyValues = {
    unitNumber: '',
    fullAddress: '',
    strataLotNumber: '',
    status: '',
    price: '',
    priceCurrencyType: '',
    unitGroup: '',
    floorPlan: '',
    floorNumber: '',
    numberOfBedrooms: '',
    numberOfBathrooms: '',
    balcony: '',
    building
    // lotData: {
    //   devLotNumber: '',
    //   plat: '',
    //   block: '',
    //   platLotNumber: '',
    //   salesLotNumber: '',
    //   lotType: '',
    //   collection: '',
    //   squareFootage: null,
    //   dimensions: '',
    //   buildableSquareFootage: '',
    //   buildableDimensions: '',
    //   frontYardDimension: null,
    //   leftYardDimension: null,
    //   rightYardDimension: null,
    //   rearYardDimension: null,
    //   mooringDimension: null,
    //   waterAccess: null,
    //   dockType: '',
    //   homeHeight: '',
    //   pool: null,
    //   sector: '',
    //   STR: null
    // },
    // images: ['']
  }
  const [value, setValues] = useState({
    ...initialEmptyValues
  })

  const TEMP_AVAILABILITY_STATUS = {
    available: 'available',
    open: 'Open',
    sold: 'sold',
    soldFirm: 'SoldFirm',
    developerHeld: 'DeveloperHeld',
    reserved: 'reserved',
    reservedBroker: 'ReservedBroker',
    reservedBuyer: 'ReservedBuyer',
    closed: 'Closed',
    underContract: 'UnderContract',
    offerAccepted: 'OfferAccepted'
  }

  const STATUS_OPTIONS = [
    ...Object.values(TEMP_AVAILABILITY_STATUS).map((value) => {
      return { value: value, id: value }
    })
  ]

  const balconyOptions = [
    { id: 'true', value: 'Yes' },
    { id: 'false', value: 'No' }
  ]

  const LOT_TYPE_OPTION = [
    { id: 'Large', value: 'Large' },
    { id: 'Standard', value: 'Standard' },
    { id: 'Medium', value: 'Medium' },
    { id: 'Villa', value: 'Villa' }
  ]

  const COLLECTION_OPTION = [
    { id: 'Manor', value: 'Manor' },
    { id: 'Estate', value: 'Estate' },
    { id: 'Villa', value: 'Villa' }
  ]

  const DOCK_TYPE_OPTION = [
    { id: 'dock-type-1', value: 'Dock type 1' },
    { id: 'dock-type-2', value: 'Dock type 2' },
    { id: 'dock-type-3', value: 'Dock type 3' }
  ]

  const YES_NO_OPTION = [
    { id: true, value: 'Yes' },
    { id: false, value: 'No' }
  ]

  const STR_OPTION = [
    { id: 'STR', value: 'STR' },
    { id: 'NON-STR', value: 'NON-STR' }
  ]

  const MOORING_LINE_TYPE_OPTIONS = [
    { id: 'B', value: 'B' },
    { id: 'C', value: 'C' },
    { id: 'D', value: 'D' },
    { id: 'E', value: 'E' },
    { id: 'N/A', value: 'N/A' }

  ]

  const handleSubmit = () => {
    if (validate()) {
      setLoading(true)
      const temp = {
        // @ts-ignore
        _id: value?._id ?? '',
        unitNumber: value.unitNumber,
        fullAddress: value.fullAddress,
        strataLotNumber: value.strataLotNumber,
        status: value.status,
        price: value.price,
        priceCurrencyType: value.priceCurrencyType,
        unitGroup: isObject(value.unitGroup)
          ? value.unitGroup?._id
          : value.unitGroup,
        floorPlan: isObject(value.floorPlan)
          ? value.floorPlan?._id
          : value.floorPlan,
        floorNumber: value.floorNumber,
        numberOfBedrooms: value.numberOfBedrooms,
        numberOfBathrooms: value.numberOfBathrooms,
        balcony: value.balcony ?? false,
        building: isObject(value.building)
          ? value.building?._id
          : value.building,
        project: appProject,
        contractPdf: value.contractPdf ?? '',
        parkingPdf: value.parkingPdf ?? '',
        lotData: {
          ...value.lotData
        },
        images: value.images ?? ['']
      }
      createOrUpdateUnit(temp)
        .then((data) => {
          refetch()
          setLoading(false)
          setShow(false)
          setEditDetails(data)
        })
        .catch((err) => {
          setLoading(false)
          console.log(err)
        })
    }
  }

  const onChange = (fieldName, newValue) => {
    setValues({ ...value, [fieldName]: newValue })
  }

  const onLotDataChange = (fieldName, newValue) => {
    setValues({
      ...value,
      lotData: {
        ...value.lotData,
        [fieldName]: newValue
      }
    })
  }
  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    setShow(false)
    setEditDetails({})
  }

  const validate = () => {
    for (const key in initialEmptyValues) {
      if (!value[key]) {
        return false
      }
    }
    return true
  }

  useEffect(() => {
    if (!mountedRef.current) return null
    if (editDetails?.type === 'Units') {
      setValues({
        ...value,
        ...editDetails,
        status: STATUS_OPTIONS.filter(
          ({ id }) => id === editDetails.status
        )?.[0]?.id,
        balcony: editDetails.balcony ? 'true' : 'false'
      })
    } else {
      setValues(initialEmptyValues)
    }
  }, [editDetails])

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  const handleStatusChange = (id) => {
    const temp = STATUS_OPTIONS.filter((option) => option.id === id)[0]
    onChange('status', temp.id)
  }

  const handleContractPDFChange = (contractObject) => {
    onChange('contractPdf', contractObject._id ?? contractObject.id)
  }

  const handleParkingPDFChange = (parkingObject) => {
    onChange('parkingPdf', parkingObject._id ?? parkingObject.id)
  }

  const handleCurrencyChange = (id) => {
    const temp = currencyData.filter((option) => option.id === id)[0]
    onChange('priceCurrencyType', temp.id)
  }

  const handleBalconyChange = (id) => {
    const temp = balconyOptions.filter((option) => option.id === id)[0]
    onChange('balcony', temp.id)
  }

  const handleImageChange = (image) => {
    onChange('images', [image.url])
  }

  const handleLotDataSelectChange = (collection, fieldName, id) => {
    const temp = collection.filter((option) => option.id === id)[0]
    onLotDataChange(fieldName, temp.id)
  }

  return (
    <Modal
      open={show}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div
        className='font-openSans relative bg-grey-8 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-10 px-8 rounded shadow-lg max-h-[calc(100vh-150px)] flex flex-col'
        style={{ width: 1015 }}
      >
        <button className='absolute right-10' onClick={handleClose}>
          <CrossIcon />
        </button>
        <h1 className='font-bold text-xl text-center p-0 m-0 mb-6'>
          {!value?._id ? 'Add' : 'Update'} Unit
        </h1>
        <div className='w-full px-2 h-full overflow-y-auto thin-scrollbar'>
          <div className='grid grid-cols-2 gap-6 mb-6'>
            <CustomInput
              label='Name *'
              placeholder='Enter name'
              labelClassName='italic'
              value={value.unitNumber}
              onChange={(e) => {
                onChange('unitNumber', e.target.value)
              }}
              required='required'
              errorMessage='Please enter name'
              containerClasses={undefined}
              classes={undefined}
            />
            <CustomInput
              label='unit address *'
              placeholder='Enter address'
              labelClassName='italic'
              value={value.fullAddress}
              onChange={(e) => {
                onChange('fullAddress', e.target.value)
              }}
              required='required'
              errorMessage='Please enter unit address'
              containerClasses={undefined}
              classes={undefined}
            />
            <div className='grid grid-cols-2 gap-6'>
              <CustomInput
                label='strata lot number *'
                placeholder='Enter number'
                labelClassName='italic'
                value={value.strataLotNumber}
                onChange={(e) => {
                  onChange('strataLotNumber', e.target.value)
                }}
                required='required'
                errorMessage='Please enter lot number'
                containerClasses={undefined}
                classes={undefined}
              />
              <CustomSelect
                label='status *'
                inputClasses='h-11'
                labelClasses={undefined}
                options={STATUS_OPTIONS}
                selectedOption={value.status}
                setSelectedOption={(option) => {
                  handleStatusChange(option)
                }}
              />
            </div>
            <div className='grid grid-cols-2 gap-6'>
              <CustomInput
                label='price *'
                placeholder='Enter amount'
                labelClassName='italic'
                value={value.price}
                onChange={(e) => {
                  onChange('price', e.target.value)
                }}
                type='number'
                required='required'
                errorMessage='Please enter price'
                containerClasses={undefined}
                classes={undefined}
              />
              <CustomSelect
                label='currency *'
                inputClasses='h-11'
                options={currencyData}
                selectedOption={value.priceCurrencyType}
                setSelectedOption={(option) => {
                  handleCurrencyChange(option)
                }}
                labelClasses={undefined}
              />
            </div>
            <CustomSelect
              label='unit group *'
              inputClasses='h-11'
              labelClasses={undefined}
              options={unitGroups}
              selectedOption={
                // @ts-ignore
                isObject(value.unitGroup) ? value.unitGroup?._id : value.unitGroup
              }
              toIterateField='name'
              setSelectedOption={(newValue) => {
                onChange('unitGroup', newValue)
              }}
            />
            <CustomSelect
              label='floor plan *'
              options={floorPlans}
              inputClasses='h-11'
              selectedOption={
                // @ts-ignore
                isObject(value.floorPlan) ? value.floorPlan?._id : value.floorPlan
              }
              toIterateField='name'
              setSelectedOption={(newValue) => {
                onChange('floorPlan', newValue)
              }}
              labelClasses={undefined}
            />
            <div className='grid grid-cols-2 gap-6'>
              <CustomInput
                label='floor number *'
                placeholder='Enter number'
                labelClassName='italic'
                value={value.floorNumber}
                onChange={(e) => {
                  onChange('floorNumber', e.target.value)
                }}
                type='number'
                required='required'
                errorMessage='Please enter floor number'
                containerClasses={undefined}
                classes={undefined}
              />
              <CustomInput
                label='number of bedrooms *'
                value={value.numberOfBedrooms}
                onChange={(e) => {
                  onChange('numberOfBedrooms', e.target.value)
                }}
                type='number'
                required='required'
                errorMessage='Please enter number of bedrooms'
                containerClasses={undefined}
                classes={undefined}
                placeholder={undefined}
              />
            </div>
            <div className='grid grid-cols-2 gap-6'>
              <CustomInput
                label='number of bathrooms *'
                placeholder='Enter number'
                labelClassName='italic'
                value={value.numberOfBathrooms}
                onChange={(e) => {
                  onChange('numberOfBathrooms', e.target.value)
                }}
                type='number'
                required='required'
                errorMessage='Please enter number of bathrooms'
                containerClasses={undefined}
                classes={undefined}
              />
              <CustomSelect
                label='balcony *'
                labelClasses='italic'
                inputClasses='h-11'
                options={balconyOptions}
                setSelectedOption={(value) => {
                  handleBalconyChange(value)
                }}
                selectedOption={value.balcony}
              />
            </div>
            <ProjectFileSelector
              title={'Contract'}
              onSuccess={(selectedFileData) => {
                handleContractPDFChange(JSON.parse(selectedFileData))
              }}
            />
            <ProjectFileSelector
              title={'Parking'}
              onSuccess={(selectedFileData) => {
                handleParkingPDFChange(JSON.parse(selectedFileData))
              }}
            />
          </div>
          <h2 className='font-semibold text-lg p-0 m-0 mb-4'>
            Lot Data
          </h2>
          <div className='grid grid-cols-2 gap-6 mb-6'>
            <div className='grid grid-cols-2 gap-6'>
              <CustomInput
                label='Lot Number*'
                placeholder='Enter lot number'
                labelClassName='italic'
                value={value?.lotData?.devLotNumber}
                onChange={(e) => {
                  onLotDataChange('devLotNumber', e.target.value)
                }}
                required='required'
                errorMessage='Please enter lot number'
                containerClasses={undefined}
                classes={undefined}
              />
              <CustomInput
                label='plat *'
                placeholder='Enter plat'
                labelClassName='italic'
                value={value?.lotData?.plat}
                onChange={(e) => {
                  onLotDataChange('plat', e.target.value)
                }}
                required='required'
                errorMessage='Please enter plat'
                containerClasses={undefined}
                classes={undefined}
              />
            </div>
            <div className='grid grid-cols-2 gap-6'>
              <CustomInput
                label='block *'
                placeholder='Enter block'
                labelClassName='italic'
                value={value?.lotData?.block}
                onChange={(e) => {
                  onLotDataChange('block', e.target.value)
                }}
                required='required'
                errorMessage='Please enter block'
                containerClasses={undefined}
                classes={undefined}
              />
              <CustomSelect
                label='Lot type *'
                fieldName='lot type'
                inputClasses='h-11'
                labelClasses={undefined}
                options={LOT_TYPE_OPTION}
                selectedOption={value?.lotData?.lotType}
                setSelectedOption={(option) => {
                  handleLotDataSelectChange(LOT_TYPE_OPTION, 'lotType', option)
                }}
              />
            </div>
            <div className='grid grid-cols-2 gap-6'>
              <CustomInput
                label='Square Footage *'
                placeholder='Enter Square Footage'
                labelClassName='italic'
                value={value?.lotData?.squareFootage}
                onChange={(e) => {
                  onLotDataChange('squareFootage', e.target.value)
                }}
                type='text'
                required='required'
                errorMessage='Please enter Square Footage'
                containerClasses={undefined}
                classes={undefined}
              />
              <CustomSelect
                label='collection *'
                fieldName='collection'
                inputClasses='h-11'
                options={COLLECTION_OPTION}
                selectedOption={value?.lotData?.collection ?? ''}
                setSelectedOption={(option) => {
                  handleLotDataSelectChange(COLLECTION_OPTION, 'collection', option)
                }}
                labelClasses={undefined}
              />
            </div>
            <div className='grid grid-cols-2 gap-6'>
              <CustomInput
                label='Plat Lot Number *'
                placeholder='Enter plat lot number'
                labelClassName='italic'
                value={value?.lotData?.platLotNumber}
                onChange={(e) => {
                  onLotDataChange('platLotNumber', e.target.value)
                }}
                type='text'
                required='required'
                errorMessage='Please enter plat lot number'
                containerClasses={undefined}
                classes={undefined}
              />
              <CustomInput
                label='Sales Lot Number *'
                placeholder='Enter sales lot number'
                labelClassName='italic'
                value={value?.lotData?.salesLotNumber}
                onChange={(e) => {
                  onLotDataChange('salesLotNumber', e.target.value)
                }}
                type='text'
                required='required'
                errorMessage='Please sales lot number'
                containerClasses={undefined}
                classes={undefined}
              />
            </div>
            <div className='grid grid-cols-2 gap-6'>
              <CustomInput
                label='dimensions *'
                placeholder='Enter dimensions'
                labelClassName='italic'
                value={value?.lotData?.dimensions}
                onChange={(e) => {
                  onLotDataChange('dimensions', e.target.value)
                }}
                type='text'
                required='required'
                errorMessage='Please enter dimensions'
                containerClasses={undefined}
                classes={undefined}
              />
              <CustomInput
                label='Buildable Square Footage *'
                placeholder='Enter buildable square footage'
                labelClassName='italic'
                value={value?.lotData?.buildableSquareFootage}
                onChange={(e) => {
                  onLotDataChange('buildableSquareFootage', e.target.value)
                }}
                type='text'
                required='required'
                errorMessage='Please enter buildable square footage'
                containerClasses={undefined}
                classes={undefined}
              />
            </div>
            <div className='grid grid-cols-2 gap-6'>
              <CustomInput
                label='Buildable Dimensions *'
                placeholder='Enter buildable dimensions'
                labelClassName='italic'
                value={value?.lotData?.buildableDimensions}
                onChange={(e) => {
                  onLotDataChange('buildableDimensions', e.target.value)
                }}
                type='text'
                required='required'
                errorMessage='Please enter buildable dimensions'
                containerClasses={undefined}
                classes={undefined}
              />
              <CustomInput
                label='Front Yard Dimension *'
                placeholder='Enter front yard dimension'
                labelClassName='italic'
                value={value?.lotData?.frontYardDimension}
                onChange={(e) => {
                  onLotDataChange('frontYardDimension', e.target.value)
                }}
                type='number'
                required='required'
                errorMessage='Please enter front yard dimension'
                containerClasses={undefined}
                classes={undefined}
              />
            </div>
            <div className='grid grid-cols-2 gap-6'>
              <CustomInput
                label='Left Yard dimension *'
                placeholder='Enter left yard dimension'
                labelClassName='italic'
                value={value?.lotData?.leftYardDimension}
                onChange={(e) => {
                  onLotDataChange('leftYardDimension', e.target.value)
                }}
                type='number'
                required='required'
                errorMessage='Please enter left yard dimension'
                containerClasses={undefined}
                classes={undefined}
              />
              <CustomInput
                label='Right Yard dimension *'
                placeholder='Enter right yard dimension'
                labelClassName='italic'
                value={value?.lotData?.rightYardDimension}
                onChange={(e) => {
                  onLotDataChange('rightYardDimension', e.target.value)
                }}
                type='number'
                required='required'
                errorMessage='Please enter right yard dimension'
                containerClasses={undefined}
                classes={undefined}
              />
            </div>
            <div className='grid grid-cols-2 gap-6'>
              <CustomSelect
                label='Does it have water access *'
                fieldName='water access'
                inputClasses='h-11'
                options={YES_NO_OPTION}
                selectedOption={value?.lotData?.waterAccess ?? ''}
                setSelectedOption={(option) => {
                  handleLotDataSelectChange(YES_NO_OPTION, 'waterAccess', option)
                }}
                labelClasses={undefined}
              />
              <CustomSelect
                label='Dock Type *'
                fieldName='dock type'
                inputClasses='h-11'
                options={DOCK_TYPE_OPTION}
                selectedOption={value?.lotData?.dockType ?? ''}
                setSelectedOption={(option) => {
                  handleLotDataSelectChange(DOCK_TYPE_OPTION, 'dockType', option)
                }}
                labelClasses={undefined}
              />
            </div>

            <div className='grid grid-cols-2 gap-6'>
              <CustomInput
                label='Rear Yard dimension *'
                placeholder='Enter rear yard dimension'
                labelClassName='italic'
                value={value?.lotData?.rearYardDimension}
                onChange={(e) => {
                  onLotDataChange('rearYardDimension', e.target.value)
                }}
                type='number'
                required='required'
                errorMessage='Please enter rear yard dimension'
                containerClasses={undefined}
                classes={undefined}
              />
              <CustomInput
                label='Mooring dimension *'
                placeholder='Enter mooring dimension'
                labelClassName='italic'
                value={value?.lotData?.mooringDimension}
                onChange={(e) => {
                  onLotDataChange('mooringDimension', e.target.value)
                }}
                type='number'
                required='required'
                errorMessage='Please enter mooring dimension'
                containerClasses={undefined}
                classes={undefined}
              />
            </div>
            <div className='grid grid-cols-2 gap-6'>
              <CustomSelect
                label='Does it allowed dock *'
                fieldName='dockAllowed'
                inputClasses='h-11'
                options={YES_NO_OPTION}
                selectedOption={value?.lotData?.dockAllowed ?? ''}
                setSelectedOption={(option) => {
                  handleLotDataSelectChange(YES_NO_OPTION, 'dockAllowed', option)
                }}
                labelClasses={undefined}
              />
              <CustomInput
                label='Lot Width *'
                placeholder='Enter Lot Width'
                labelClassName='italic'
                value={value?.lotData?.lotWidth}
                onChange={(e) => {
                  onLotDataChange('lotWidth', e.target.value)
                }}
                type='text'
                required='required'
                errorMessage='Please enter lot width'
                containerClasses={undefined}
                classes={undefined}
              />
            </div>
            <div className='grid grid-cols-2 gap-6'>
              <CustomSelect
                label='does it have pool *'
                fieldName='pool'
                inputClasses='h-11'
                options={YES_NO_OPTION}
                selectedOption={value?.lotData?.pool ?? ''}
                setSelectedOption={(option) => {
                  handleLotDataSelectChange(YES_NO_OPTION, 'pool', option)
                }}
                labelClasses={undefined}
              />
              <CustomSelect
                label='does it have STR *'
                fieldName='STR'
                inputClasses='h-11'
                options={STR_OPTION}
                selectedOption={value?.lotData?.STR ?? ''}
                setSelectedOption={(option) => {
                  handleLotDataSelectChange(STR_OPTION, 'STR', option)
                }}
                labelClasses={undefined}
              />
            </div>
            <div className='grid grid-cols-2 gap-6'>
              <CustomSelect
                label='Mooring Line Type Options*'
                fieldName='mooringLineType'
                inputClasses='h-11'
                options={MOORING_LINE_TYPE_OPTIONS}
                selectedOption={value?.lotData?.mooringLineType ?? ''}
                setSelectedOption={(option) => {
                  handleLotDataSelectChange(MOORING_LINE_TYPE_OPTIONS, 'mooringLineType', option)
                }}
                labelClasses={undefined}
              />
              <CustomInput
                label='Mooring Line *'
                placeholder='Enter Mooring Line'
                labelClassName='italic'
                value={value?.lotData?.mooringLine}
                onChange={(e) => {
                  onLotDataChange('mooringLine', e.target.value)
                }}
                type='text'
                required='required'
                errorMessage='Please enter mooring line'
                containerClasses={undefined}
                classes={undefined}
              />
            </div>
            <div className='grid grid-cols-2 gap-6'>
              <CustomInput
                label='Home Height *'
                placeholder='Enter home height'
                labelClassName='italic'
                value={value?.lotData?.homeHeight}
                onChange={(e) => {
                  onLotDataChange('homeHeight', e.target.value)
                }}
                type='text'
                required='required'
                errorMessage='Please enter home height'
                containerClasses={undefined}
                classes={undefined}
              />
              <CustomInput
                label='sector *'
                placeholder='Enter sector'
                labelClassName='italic'
                value={value?.lotData?.sector}
                onChange={(e) => {
                  onLotDataChange('sector', e.target.value)
                }}
                type='text'
                required='required'
                errorMessage='Please enter sector'
                containerClasses={undefined}
                classes={undefined}
              />
            </div>
            <ProjectFileSelector
              title={'Image'}
              onSuccess={(selectedFileData) => {
                handleImageChange(JSON.parse(selectedFileData))
              }}
            />
          </div>
        </div>
        <CustomButton handleClick={handleSubmit} disabled={!validate() || loading} className='m-auto'>
          <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
            {loading && <Oval height='18' width='18' color='white' />}
            {value?._id ? 'Update' : 'Add'} Unit
          </span>
        </CustomButton>
      </div>
    </Modal>
  )
}

export default AddUnitModal
