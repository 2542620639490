import { motion } from 'framer-motion'
import styled from 'styled-components'

export const CameraWrapper = styled(motion.div)`
  display: flex;
  width: -webkit-fill-available;
  justify-content: center;
  position: relative;
  margin-bottom: 15px;
`

export const CameraButtonWrapper = styled.div`
   bottom: 10px; 
   padding: 20px; 
   border-radius: 50%; 
   background: rgba(0,0,0,0.4); 
   position: absolute;

   :hover {
     cursor: pointer;
   }
`

export const CloseButtonWrapper = styled.div`
   top: 10px; 
   right: 10px; 
   padding: 7px; 
   border-radius: 50%; 
   background: rgba(0,0,0,0.4); 
   position: absolute;
   display: flex;

   :hover {
     cursor: pointer;
   }
`
