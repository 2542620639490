import React from 'react'
import styled from 'styled-components'
import { DatePicker, TimePicker } from 'antd'
// import propTypes from 'prop-types'
import moment from 'moment'
import Skeleton from 'react-loading-skeleton'
import { classNames, getRandomInteger } from 'utils'

import Select from './select'

import InputMask from 'react-input-mask'

// import { TextArea } from './inputs'
import { InputNumber } from 'ui'
import standardStyle from 'assets/css/standardStyle'

const baseStyle = 'pt-1 pb-1 pl-3 border rounded-md text-left'

const defaultStyle = `block w-full md:text-base text-sm border-gray-300
focus:ring-indigo-500 focus:border-indigo-500`

const inlineInputStyle = `flex-grow w-full ring-1 ring-gray-300 border-transparent bg-white text-sm
focus:outline-none
focus:ring-1
focus:ring-indigo-500
focus:border-indigo-500`

const inputStyles = {
  defaultInput: defaultStyle,
  inlineInput: inlineInputStyle
}

const InputLabel = (props) => {
  const {
    xs, sm, md, lg, label, type, name, id, value, onChange, onBlur,
    maskType, typeInput, data, /* selected, */ disabled,
    maxLength, readOnly, loading, autoFocus, required,
    /* ref, */ disabledDate, validateKey, mode, placeholder,
    prefix, thousandSeparator,
    /* showSearch, onSearch, filterOption, showArrow,
    showExtraContent, extraContent, onDropdownVisibleChange, optionFilterProp,
    showLabelMarginBottom, textAlign, hintMessage, */ inputBackgroundColor,
    onKeyPress,
    className, inputWrapperClassName, onClick, inputStyle,
    validateExtraMessage, showTime, showSecond
  } = props

  let content = ''
  switch (typeInput) {
    case 'select':
      content = loading
        ? (<SkeletonContainer><Skeleton height={36} /></SkeletonContainer>)
        : (
          <Select
            value={value}
            onChange={onChange}
            data={data}
            disabled={disabled}
            mode={mode}
            // type={type}
            // name={name}
            // id={id}
            // defaultValue={defaultValue}
            // onBlur={onBlur}
            // required={required}
            // mode={mode}
            // showSearch={showSearch}
            // onSearch={onSearch}
            // filterOption={filterOption}
            // showArrow={showArrow}
            placeholder={placeholder}
            // onDropdownVisibleChange={onDropdownVisibleChange}
            // optionFilterProp={optionFilterProp}
            className={`${validateKey}Component validateSelect
            ${className}
          `}

          />
          )
      break

    case 'timepicker':
      content = loading
        ? (<SkeletonContainer><Skeleton height={35.6} /></SkeletonContainer>)
        : (
          <TimePicker
            type={type}
            name={name}
            id={id}
            use12Hours
            onChange={onChange}
            onBlur={onBlur}
            disabled={disabled}
            disabledDate={disabledDate}
            value={value ? moment(value) : ''}
            required={required}
            placeholder={placeholder}
            showSecond={showSecond}
            className={classNames(`${validateKey}Component
          ${baseStyle}
          ${inputStyles[inputStyle]}
          ${className}
          `)}
          />
          )
      break

    case 'datepicker':
      content = loading
        ? (<SkeletonContainer><Skeleton height={35.6} /></SkeletonContainer>)
        : (
          <DatePicker
            type={type}
            name={name}
            id={id}
            use12Hours
            onChange={onChange}
            onBlur={onBlur}
            disabled={disabled}
            disabledDate={disabledDate}
            defaultPickerValue=''
            value={value ? moment(value) : ''}
            required={required}
            placeholder={placeholder}
            showTime={showTime ? { format: 'hh:mm A', showSecond } : false}
            format={`DD-MM-YYYY${showTime ? ' hh:mm A' : ''}`}
            className={`${validateKey}Component
          ${baseStyle}
          ${inputStyles[inputStyle]}
          ${className}
          `}
          />
          )
      break

    case 'numberinput':
      content = loading
        ? (<SkeletonContainer><Skeleton height={57.2} /></SkeletonContainer>)
        : (
          <InputNumber
            name={name}
            id={id}
            onValueChange={onChange}
            value={value}
            required={required}
            placeholder={placeholder}
            disabled={disabled}
            prefix={prefix}
            onKeyPress={onKeyPress}
            thousandSeparator={thousandSeparator}
            validateExtraMessage={validateExtraMessage}
            className={`${validateKey}Component
          ${baseStyle}
          ${inputStyles[inputStyle]}
          ${className}
          `}
          />
          )
      break

    case 'textarea':
      content = loading
        ? (<SkeletonContainer><Skeleton height={57.2} /></SkeletonContainer>)
        : (
          <textarea
            name={name}
            id={id}
            // autoSize={autoSize}
            onChange={onChange}
            value={value}
            required={required}
            placeholder={placeholder}
            disabled={disabled}
            onKeyPress={onKeyPress}
            className={`${validateKey}Component
          ${baseStyle}
          ${inputStyles[inputStyle]}
          ${className}
          `}
          />
          )
      break

    default:
      content = loading
        ? (<SkeletonContainer><Skeleton height={34.8} /></SkeletonContainer>)
        : (
          <InputMask
            mask={maskType}
            value={value}
            // defaultValue={defaultValue}
            onChange={onChange}
            type={type}
            name={name}
            id={id}
            onBlur={onBlur}
            disabled={disabled}
            maxLength={maxLength}
            readOnly={readOnly}
            autoFocus={autoFocus}
            required={required}
            onKeyPress={onKeyPress}
            placeholder={placeholder}
            style={{ background: inputBackgroundColor }}
            className={`${validateKey}Component
          ${baseStyle}
          ${inputStyles[inputStyle]}
          ${className}
          `}
          />
          )
      break
  }

  let textLabel = label && `${label}:`

  if (required && textLabel) {
    textLabel = (
      <>
        <Asterisk className='mr-1'>*</Asterisk>
        {' '}
        {label}:
      </>
    )
  }

  // const renderHint = () => {
  //   if (hintMessage) {
  //     return (
  //       <Tooltip title={hintMessage} trigger={['hover', 'click']}>
  //         <InfoIcon />
  //       </Tooltip>
  //     )
  //   }
  // }

  return (
    <div
      onClick={onClick}
      className={classNames(
        validateKey,
        'w-full',
        inputWrapperClassName,
        lg && `col-span-${lg}`,
        md && `md:col-span-${md}`,
        sm && `sm:col-span-${sm}`,
        xs && `xs:col-span-${xs}`
      )}
    >
      <div className={`flex ${inputStyle === 'inlineInput' && 'sr-only'}`}>
        {loading && textLabel ? <Skeleton width={getRandomInteger(60, 120)} /> : textLabel}
      </div>
      <div className={`${textLabel ? 'mt-1' : ''} w-full`}>
        {content}
      </div>
    </div>
  )
}

const SkeletonContainer = styled.div`
  margin-top: -5px;
`

InputLabel.defaultProps = {
  showCheckbox: false,
  data: [{
    id: 1,
    value: 'Faltou colocar o data no select input...'
  }],
  onChange: () => { },
  onClick: () => { },
  value: '',
  // '',
  required: false,
  mode: '',
  thousandSeparator: true,
  showLabelMarginBottom: true,
  textAlign: 'start',
  hintMessage: '',
  inputBackgroundColor: '',
  type: 'text',
  className: '',
  inputWrapperClassName: '',
  inputStyle: 'defaultInput',
  validateExtraMessage: '',
  validateKey: 'validateKey',
  showTime: false,
  showSecond: false
}

export const Label = styled.label`
  font-size: 14px;
  color: ${standardStyle.colors.primary};
  font-family: ${standardStyle.fonts.text};
  display: block;
  margin-bottom: ${props => props.showLabelMarginBottom === 'true' ? '25px' : '0px'};
`
const Asterisk = styled.span`
  color: ${standardStyle.colors.danger};
`
// InputLabel.propTypes = {
//   xs: propTypes.number,
//   sm: propTypes.number,
//   md: propTypes.number,
//   lg: propTypes.number,

//   label: propTypes.string,
//   type: propTypes.string,
//   name: propTypes.string,
//   id: propTypes.string,
//   inputBackgroundColor: propTypes.string,
//   value: propTypes.any,
//   propTypes.oneOfType([
//     propTypes.string,
//     propTypes.number,
//     propTypes.instanceOf(Date)
//   ]),
//   maskType: propTypes.string,
//   typeInput: propTypes.string,
//   // selected: propTypes.string,

//   required: propTypes.bool,

//   onChange: propTypes.func,
//   onBlur: propTypes.func,
//   onClick: propTypes.func,

//   data: propTypes.arrayOf(propTypes.shape({
//     id: propTypes.oneOfType([
//       propTypes.string,
//       propTypes.number
//     ]),
//     value: propTypes.string
//   })),

//   disabled: propTypes.bool,
//   autoSize: propTypes.bool,
//   maxLength: propTypes.number,
//   readOnly: propTypes.bool,
//   loading: propTypes.bool,
//   autoFocus: propTypes.bool,
//   // ref: propTypes.string,
//   disabledDate: propTypes.bool,
//   validateKey: propTypes.string,
//   mode: propTypes.string,
//   placeholder: propTypes.string,
//   prefix: propTypes.string,
//   thousandSeparator: propTypes.bool,
//   onKeyPress: propTypes.func,
//   className: propTypes.string,
//   inputWrapperClassName: propTypes.string,
//   inputStyle: propTypes.string,
//   validateExtraMessage: propTypes.string,
//   showTime: propTypes.bool,
//   showSecond: propTypes.bool
// }

export default InputLabel
